import PropTypes from 'prop-types'
import styled from 'styled-components'
import { P, Wrapper } from '@farewill/ui'
import { BORDER, COLOR, FONT, GTR } from '@farewill/ui/tokens'
import { getWidthOfContainerColumns } from '@farewill/ui/helpers/layout'
import isEmpty from 'lodash/isEmpty'
import isObject from 'lodash/isObject'
import map from 'lodash/map'
import { useSelector } from 'react-redux'

import { formatApiError } from 'lib/formatting/errors'

const getStateColors = (isWarning) => ({
  accent: isWarning ? COLOR.STATE.WARNING : COLOR.STATE.ERROR,
  background: isWarning ? COLOR.WHITE : COLOR.STATE.ERROR_10,
})

const StyledFixedWrapper = styled(Wrapper)`
  border-radius: ${BORDER.RADIUS.S};
  position: fixed;
  bottom: ${GTR.S};
  z-index: 1;
`

const StyledAlertWrapper = styled(Wrapper)`
  background: ${({ $warning }) => getStateColors($warning).background};
  padding: ${GTR.S} ${GTR.XXL} ${GTR.S} ${GTR.S};
  border: 2px solid ${({ $warning }) => getStateColors($warning).accent};
  font-size: ${FONT.SIZE.S};
  width: ${getWidthOfContainerColumns(8, 'L', GTR.M)}px;
  margin-bottom: ${GTR.S};
  position: relative;
`

const StyledSuccessWrapper = styled(StyledFixedWrapper)`
  background: ${COLOR.GREY.LIGHT};
  padding: ${GTR.XS};
  opacity: 0.95;
`

const StyledP = styled(P)`
  font-size: ${FONT.SIZE.XXS};
`

const StyledIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: ${({ $warning }) => getStateColors($warning).accent};
  color: #fff;
  font-size: ${FONT.SIZE.M};
  font-weight: ${FONT.WEIGHT.BOLD};
  position: absolute;
  top: ${GTR.S};
  right: ${GTR.S};

  &::after {
    content: '!';
  }
`

const StyledList = styled.ol`
  list-style: decimal;
  padding-left: ${GTR.S};
`

const StyledLink = styled.span`
  text-decoration: underline;
  cursor: pointer;
`

const handleClick = (name) => {
  const inputEl = document.querySelector(`input[name="${name}"]`)
  if (!inputEl) return false

  const topOfSection = inputEl.getBoundingClientRect().top + window.scrollY
  const pointToScrollTo = topOfSection - parseInt(GTR.XL)
  window.scrollTo(0, pointToScrollTo)
}

const getErrors = (errors, prefix) => {
  return map(errors, (value, name) => {
    if (isObject(value)) return getErrors(value, name)
    const fullName = [prefix, name].join('.')
    return <Error key={name} name={fullName} value={value} />
  })
}

const Error = ({ name, value }) => (
  <li>
    <StyledLink onClick={() => handleClick(name)}>{value}</StyledLink>
  </li>
)

const RequestStatus = ({
  apiErrors,
  apiMessages,
  isSaving,
  validationErrors,
}) => {
  const requestsPending = useSelector((state) => state.requestsPending)
  const stateErrors = useSelector((state) => state.errors)
  const stateMessages = useSelector((state) => state.messages)

  const contactSaving = requestsPending.includes('UPDATE_CONTACT')
  const leadSaving = requestsPending.includes('UPDATE_LEAD')
  const funeralSaving = requestsPending.includes('UPDATE_FUNERAL')
  const probateSaving = requestsPending.includes('UPDATE_PROBATE_CASE')
  const willCaseSaving = requestsPending.includes('UPDATE_WILL_CASE')

  const allApiErrors = [...apiErrors, ...stateErrors]
  const allApiMessages = [...apiMessages, ...stateMessages]
  const showSavingMessage =
    isSaving ||
    contactSaving ||
    leadSaving ||
    funeralSaving ||
    probateSaving ||
    willCaseSaving

  const hasValidationErrors = !isEmpty(validationErrors)
  const hasApiErrors = !isEmpty(allApiErrors)
  const hasApiMessages = !isEmpty(allApiMessages)

  if (!hasApiErrors && !hasValidationErrors && !hasApiMessages)
    return (
      <StyledSuccessWrapper>
        <StyledP>
          {showSavingMessage ? 'Saving...' : 'All changes saved'}
        </StyledP>
      </StyledSuccessWrapper>
    )

  return (
    <StyledFixedWrapper>
      {hasApiMessages && (
        <StyledAlertWrapper $warning>
          <StyledIcon $warning />
          {allApiMessages.map((message, index) => (
            <P size="S" key={index}>
              {formatApiError(message)}
            </P>
          ))}
        </StyledAlertWrapper>
      )}

      {(hasApiErrors || hasValidationErrors) && (
        <StyledAlertWrapper>
          <StyledIcon />
          <P size="S" margin={[0, 0, 'XS']}>
            <strong>Cannot save changes.</strong> Please fix the following
            errors:
          </P>
          {hasValidationErrors && (
            <StyledList>{getErrors(validationErrors)}</StyledList>
          )}
          {allApiErrors.map((error, index) => (
            <P size="S" key={index}>
              {formatApiError(error)}
            </P>
          ))}
        </StyledAlertWrapper>
      )}
    </StyledFixedWrapper>
  )
}

RequestStatus.propTypes = {
  apiErrors: PropTypes.array,
  apiMessages: PropTypes.array,
  isSaving: PropTypes.bool,
  validationErrors: PropTypes.object,
}

RequestStatus.defaultProps = {
  apiErrors: [],
  apiMessages: [],
  isSaving: false,
  validationErrors: {},
}

export default RequestStatus
