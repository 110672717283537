import { Grid } from '@farewill/ui'

import useNamespace from 'lib/formik/namespace'
import InputFloating from 'components/form/input-floating'
import SelectInput from 'components/form/select-input'

import { UpdateGiftFn } from './types'
import { GIFT_BENEFICIARY_TYPE_OPTIONS } from '../constants'

type Props = {
  hasGiftBeneficiary: boolean
  namespace: string
  updateGift: UpdateGiftFn
  disabled?: boolean
}

const ResiduaryGift = ({
  hasGiftBeneficiary,
  namespace,
  updateGift,
  disabled,
}: Props): React.ReactElement => {
  const { withNamespace } = useNamespace(`${namespace}.`)

  return (
    <Grid>
      <Grid.Item span={7}>
        <InputFloating
          type="number"
          name={withNamespace('residuary.share')}
          label="Share of estate (%)"
          handleSave={updateGift}
          disabled={disabled}
        />
      </Grid.Item>
      <Grid.Item span={7}>
        <SelectInput
          name={withNamespace('giftBeneficiaryType')}
          label="Gift level"
          floating
          options={GIFT_BENEFICIARY_TYPE_OPTIONS}
          disabled={disabled || hasGiftBeneficiary}
        />
      </Grid.Item>
    </Grid>
  )
}

export default ResiduaryGift
