import React from 'react'

export function useVisibilityChange() {
  const [isTabVisible, setTabVisibility] = React.useState(!document.hidden)

  React.useEffect(() => {
    const handleVisibilityChange = () => {
      setTabVisibility(!document.hidden)
    }

    document.addEventListener('visibilitychange', handleVisibilityChange)

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange)
    }
  }, [])

  return isTabVisible
}
