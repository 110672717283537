import { QuoteResponseDataProbate } from 'lib/types'
import QuoteSummary from './quote-summary'
import { UseSectionsCompleteResponse } from './helpers/complete-sections/types'
import { Button, H, TickIcon } from '@farewill/ui'
import styled from 'styled-components'
import { BORDER, BORDER_RADIUS, COLOR, GTR } from '@farewill/ui/tokens'

const StyledButtonWrapper = styled.div`
  position: absolute;
  right: -80px;
  top: 50%;
  transform: translateY(-50%) rotate(-90deg);

  button {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
  }
`

const StyledWrapper = styled.div<{ $isOpen: boolean }>`
  position: fixed;
  left: -320px;
  top: 50%;
  transform: translateY(-50%)
    translateX(${({ $isOpen }) => ($isOpen ? 0 : -55)}px);
  width: 320px;
  background: ${COLOR.WHITE};
  border-top-right-radius: ${BORDER_RADIUS.M};
  border-bottom-right-radius: ${BORDER_RADIUS.M};
  padding: ${GTR.M};
  box-shadow: ${BORDER.SHADOW.M};
  transition: transform 0.3s ease-in-out;
  z-index: 100;

  &:hover {
    transform: translateY(-50%) translateX(320px);
  }
`

type QuoteReadyBoxProps = {
  quote: QuoteResponseDataProbate | null
  completedSections: Partial<UseSectionsCompleteResponse>
}

const QuoteReadyBox = ({
  quote,
  completedSections: {
    allSectionsComplete,
    sectionsComplete,
    sectionsIncomplete,
  },
}: QuoteReadyBoxProps) => {
  return (
    <StyledWrapper $isOpen={!!allSectionsComplete}>
      <H tag="h2" size="XS" centered>
        Quotes
      </H>
      <QuoteSummary
        quote={quote}
        completedSections={{
          allSectionsComplete,
          sectionsComplete,
          sectionsIncomplete,
        }}
        vertical
      />
      <StyledButtonWrapper>
        <Button.Primary size="S" icon={<TickIcon size="S" />}>
          Quotes
        </Button.Primary>
      </StyledButtonWrapper>
    </StyledWrapper>
  )
}

export default QuoteReadyBox
