import { useContext, useEffect, useState } from 'react'
import { Wrapper, P } from '@farewill/ui'
import styled from 'styled-components'

import useApi from 'lib/effects/api'
import { FAREWILL_PRODUCTS } from 'utils/enums'
import { Context, withProvider } from './context'

import ProductHeader from 'components/product-header'
import NavigationTabs from 'components/navigation-tabs'
import RequestStatus from 'components/request-status'
import PrivateRoute from 'components/private-route'
import Summary from './summary'
import FactFind from './fact-find'
import OutstandingInfo from './outstanding-info'
import InternalChecks from './internal-checks'
import AuditTrail from './audit-trail'
import ApproveForReferral from './approve-for-referral'
import ProbateApplication from './probate-application'
import FWLSBannner from 'components/fwls-banner'
import TasksPanel from 'components/tasks-panel'
import { TasksProvider } from 'components/tasks-panel/context'

const StyledWrapper = styled(Wrapper)`
  position: relative;
`

const getNavLinks = (probateCaseId, probateEstateId) => {
  const baseUrl = `/probate/cases/${probateCaseId}`
  const navLinks = [
    { label: 'Case details', url: baseUrl },
    { label: 'Activity', url: `${baseUrl}/activity` },
    {
      label: 'The estate',
      url: `/probate/estates/${probateEstateId}`,
      exact: false,
    },
    { label: 'Documents', url: `${baseUrl}/documents` },
    { label: 'Errors', url: `${baseUrl}/errors` },
    { label: 'Forms', url: `${baseUrl}/forms` },
    { label: 'Sale', url: `${baseUrl}/sale` },
  ]

  return navLinks
}

const getNavSecondaryLinks = (probateEstateId) => {
  const baseUrl = `/probate/estates/${probateEstateId}`
  return [
    { label: 'Summary', url: `${baseUrl}/summary` },
    { label: 'Fact find', url: `${baseUrl}/fact-find` },
    // { label: 'Outstanding info', url: `${baseUrl}/outstanding-info` },
    { label: 'Internal checks', url: `${baseUrl}/internal-checks` },
    // { label: 'Audit trail', url: `${baseUrl}/audit-trail` }
    { label: 'Approve for referral', url: `${baseUrl}/approve-for-referral` },
    { label: 'Probate application', url: `${baseUrl}/probate-application` },
  ]
}

const ProbateEstate = ({ match }) => {
  const probateEstateId = match.params.id
  const [probateEstate, setProbateEstate] = useState()
  const [probateCase, setProbateCase] = useState()

  const [{ data: fetchedProbateCase }, fetchProbateCase] = useApi()
  const [{ data: fetchedProbateEstate }, fetchProbateEstate] = useApi()
  const [
    { data: updatedProbateCase, isLoading: isCaseUpdating, errors: caseErrors },
    updateProbateCase,
  ] = useApi()
  const [
    {
      data: updatedProbateEstate,
      isLoading: isEstateUpdating,
      errors: estateErrors,
    },
    updateProbateEstate,
  ] = useApi()

  const saveProbateCaseField = ({ name, value }) => {
    updateProbateCase({
      url: `/api/probate-cases/${probateCase.id}`,
      method: 'PATCH',
      data: {
        data: {
          type: 'probate_cases',
          id: probateCase.id,
          attributes: { [name]: value || null },
        },
      },
    })
  }

  const saveProbateEstateField = ({ name, value }) => {
    updateProbateEstate({
      url: `/api/probate-estates/${probateEstateId}`,
      method: 'PATCH',
      data: {
        data: {
          type: 'probate_estates',
          id: probateEstateId,
          attributes: { [name]: value || null },
        },
      },
    })
  }

  useEffect(() => {
    setProbateCase(fetchedProbateCase)
  }, [fetchedProbateCase])

  useEffect(() => {
    setProbateCase(updatedProbateCase)
  }, [updatedProbateCase])

  useEffect(() => {
    setProbateEstate(updatedProbateEstate)
  }, [updatedProbateEstate])

  useEffect(() => {
    fetchProbateEstate({ url: `/api/probate-estates/${probateEstateId}` })
  }, [fetchProbateEstate, probateEstateId])

  useEffect(() => {
    const probateCaseId = fetchedProbateEstate?.attributes?.probateCaseId
    if (!probateCaseId) return
    setProbateEstate(fetchedProbateEstate)
    fetchProbateCase({ url: `/api/probate-cases/${probateCaseId}` })
  }, [fetchProbateCase, fetchedProbateEstate])

  const {
    errors,
    isLoading,
    setErrors,
    setIsLoading,
    highlightEmpty,
    setHighlightEmpty,
  } = useContext(Context)

  useEffect(() => {
    setErrors(caseErrors)
  }, [setErrors, caseErrors])

  useEffect(() => {
    setIsLoading(isCaseUpdating)
  }, [setIsLoading, isCaseUpdating])

  useEffect(() => {
    setErrors(estateErrors)
  }, [setErrors, estateErrors])

  useEffect(() => {
    setIsLoading(isEstateUpdating)
  }, [setIsLoading, isEstateUpdating])

  if (!probateEstate || !probateCase) return <P size="L">Loading...</P>

  return (
    <StyledWrapper data-sticky-content="probate-estate">
      <ProductHeader
        attributes={probateCase.attributes}
        productType="probate"
        showExternalLinks={false}
        showLastActivityAt={false}
      />
      <Wrapper margin={['XL', 0]}>
        <NavigationTabs
          links={getNavLinks(probateCase.id, probateEstateId)}
          hasSecondary
        />
        <NavigationTabs
          links={getNavSecondaryLinks(probateEstateId)}
          isSecondary
        />
        <FWLSBannner
          referredToFWLS={
            probateCase.attributes.referredTo === 'farewill_legal_services'
          }
        />
      </Wrapper>
      <TasksPanel
        showFactFindMenu
        productRow={probateEstate}
        saveProductRow={saveProbateEstateField}
        product="probate"
        productId={{ probateCaseId: probateCase.id }}
        stickyContent="probate-estate"
        queryParams={{
          'filter[probateCaseId]': probateCase.id,
          'filter[isNeeded]': true,
          'page[size]': 200,
        }}
        highlightEmpty={highlightEmpty}
        setHighlightEmpty={setHighlightEmpty}
      />
      <Wrapper margin={[0, 0, 'XL']}>
        <PrivateRoute
          path={`${match.path}/summary`}
          component={Summary}
          probateEstate={probateEstate}
        />
        <PrivateRoute
          path={`${match.path}/fact-find`}
          component={FactFind}
          probateCase={probateCase}
          probateEstate={probateEstate}
          saveProbateEstateField={saveProbateEstateField}
          saveProbateCaseField={saveProbateCaseField}
        />
        <PrivateRoute
          path={`${match.path}/outstanding-info`}
          component={OutstandingInfo}
        />
        <PrivateRoute
          path={`${match.path}/internal-checks`}
          component={InternalChecks}
          probateCase={probateCase}
          probateEstate={probateEstate}
          saveProbateEstateField={saveProbateEstateField}
        />
        <PrivateRoute
          path={`${match.path}/audit-trail`}
          component={AuditTrail}
        />
        <PrivateRoute
          path={`${match.path}/approve-for-referral`}
          component={ApproveForReferral}
          probateEstate={probateEstate}
          saveProbateEstateField={saveProbateEstateField}
        />
        <PrivateRoute
          path={`${match.path}/probate-application`}
          component={ProbateApplication}
          probateCase={probateCase}
          saveProbateCaseField={saveProbateCaseField}
        />
        <RequestStatus apiErrors={errors} isSaving={isLoading} />
      </Wrapper>
    </StyledWrapper>
  )
}

const ProbateEstateWithProviders = (props) => (
  <TasksProvider product={FAREWILL_PRODUCTS.PROBATE}>
    <ProbateEstate {...props} />
  </TasksProvider>
)

export default withProvider(ProbateEstateWithProviders)
