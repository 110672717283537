import styled from 'styled-components'
import { SvgIcon, Wrapper } from '@farewill/ui'
import { GTR } from '@farewill/ui/tokens'

import { Uppercase } from 'components/styled/uppercase'

const Icon = () => (
  <SvgIcon
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.7721 4.32898L16.3244 9.44494L20.7585 12.4323L16.3723 15.4894L14.9012 20.6294L10.638 17.4028L5.29472 17.5921L7.04615 12.5409L5.21493 7.51795L10.5606 7.62261L14.7721 4.32898Z"
      fill="#FFDF4E"
    />
    <path
      d="M19.0382 7.34939L17.3299 12.4154L19.2039 17.4225L13.8576 17.3635L9.6743 20.6929L8.07837 15.5904L3.61896 12.641L7.97894 9.54648L9.40616 4.39422L13.6967 7.58426L19.0382 7.34939Z"
      fill="#FFDF4E"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.6509 10.0989C15.8041 10.2393 15.8145 10.4773 15.6741 10.6305L11.535 15.1458C11.4657 15.2215 11.3684 15.2655 11.2658 15.2678C11.1632 15.27 11.0642 15.2302 10.9916 15.1576L9.11021 13.2763C8.96326 13.1293 8.96326 12.8911 9.11021 12.7441C9.25715 12.5972 9.4954 12.5972 9.64234 12.7441L11.2458 14.3476L15.1193 10.122C15.2597 9.96883 15.4977 9.95848 15.6509 10.0989Z"
      fill="black"
    />
  </SvgIcon>
)

const StyledWrapper = styled(Wrapper)`
  display: flex;
  align-items: center;
  gap: ${GTR.XXS};
`

const MemberBadge = ({ className }: { className?: string }) => (
  <StyledWrapper className={className}>
    <Icon />
    <Uppercase>Member</Uppercase>
  </StyledWrapper>
)

export default MemberBadge
