import { LpaLeadAttributes } from 'lib/models/lead'
import {
  RELATIONSHIP_STATUS_WITH_PARTNER,
  RESIDENCE_OPTIONS,
  WILL_RELATIONSHIP_STATUS_OPTIONS,
  YES_NO_OPTIONS,
} from 'utils/enums'

import { OUTCOME_TYPE, OUTCOME_HEADING } from '../constants'
import { Question } from './types'

const isVisiblePartnerData = (values: LpaLeadAttributes) => {
  if (!values.quoteAttributes?.lpaType) return false

  return values.quoteAttributes.lpaType === 'couple'
}

export const relationshipStatus: Question = {
  name: 'quoteAttributes.relationshipStatus',
  label: 'What is your relationship status?',
  summaryLabel: 'Relationship status',
  type: 'select',
  options: WILL_RELATIONSHIP_STATUS_OPTIONS,
  span: 6,
  startRow: 2,
  confirmationRequired: true,
  formattedAnswer: (values: LpaLeadAttributes) => {
    const { relationshipStatus } = values.quoteAttributes

    const option = WILL_RELATIONSHIP_STATUS_OPTIONS.find(
      (option) => option.value === relationshipStatus
    )
    return option ? option.label : undefined
  },
}

export const partnerWantsLPA: Question = {
  name: 'quoteAttributes.lpaType',
  label: 'Does your partner need an LPA too?',
  summaryLabel: 'Partner needs LPA',
  type: 'radio',
  options: [
    { value: 'couple', label: 'Yes' },
    { value: 'single', label: 'No' },
  ],
  isVisible: (values: LpaLeadAttributes) => {
    if (!values.quoteAttributes?.relationshipStatus) return false

    return RELATIONSHIP_STATUS_WITH_PARTNER.includes(
      values.quoteAttributes.relationshipStatus
    )
  },
  span: 12,
  startRow: 3,
  formattedAnswer: (values: LpaLeadAttributes) => {
    const { lpaType } = values.quoteAttributes

    if (lpaType === 'couple') {
      return 'Yes'
    }

    if (lpaType === 'single') {
      return 'No'
    }

    return lpaType
  },
  confirmationRequired: (values: LpaLeadAttributes) =>
    values.quoteAttributes.lpaType === 'couple',
}

export const partnerTitle: Question = {
  name: 'quoteAttributes.partner.title',
  label: 'Title',
  type: 'text',
  span: 2,
  startRow: 4,
  isVisible: isVisiblePartnerData,
}

export const partnerFirstName: Question = {
  name: 'quoteAttributes.partner.firstName',
  label: 'First name',
  type: 'text',
  span: 5,
  startRow: 4,
  isVisible: isVisiblePartnerData,
}

export const partnerLastName: Question = {
  name: 'quoteAttributes.partner.lastName',
  label: 'Last name',
  type: 'text',
  span: 5,
  startRow: 4,
  isVisible: isVisiblePartnerData,
}

export const partnerPhoneNumber: Question = {
  name: 'quoteAttributes.partner.phoneNumber',
  label: 'Phone number',
  type: 'text',
  span: 6,
  startRow: 5,
  isVisible: isVisiblePartnerData,
}

export const partnerEmail: Question = {
  name: 'quoteAttributes.partner.email',
  label: 'Email',
  type: 'text',
  span: 6,
  startRow: 5,
  isVisible: isVisiblePartnerData,
}

export const partnerDateOfBirth: Question = {
  name: 'quoteAttributes.partner.dateOfBirth',
  label: 'Date of birth',
  type: 'date',
  span: 6,
  startRow: 6,
  isVisible: isVisiblePartnerData,
}

export const partnerAddressFields: Question = {
  name: 'quoteAttributes.partner.addressFields',
  label: 'Address',
  type: 'address',
  isVisible: isVisiblePartnerData,
}

export const residence: Question = {
  name: 'quoteAttributes.residence',
  label: 'Where in the UK do you live?',
  summaryLabel: 'Residence',
  type: 'radio',
  options: RESIDENCE_OPTIONS,
  formattedAnswer: (values: LpaLeadAttributes) => {
    const { residence } = values.quoteAttributes

    if (residence === 'england') {
      return 'England'
    }

    if (residence === 'scotland') {
      return 'Scotland'
    }

    if (residence === 'northern_ireland') {
      return 'Northern Ireland'
    }

    if (residence === 'outside_uk') {
      return 'Outside the UK'
    }

    return residence
  },
  outcome: (values: LpaLeadAttributes) => {
    const { residence } = values.quoteAttributes

    if (residence === 'scotland') {
      return {
        complete: true,
        type: OUTCOME_TYPE.CANNOT_HELP,
        heading: OUTCOME_HEADING.CANNOT_HELP,
        message:
          'Because they live in Scotland. They can turn to the Office of the Public Guardian (Scotland) for help.',
      }
    }

    if (residence === 'northern_ireland') {
      return {
        complete: true,
        type: OUTCOME_TYPE.CANNOT_HELP,
        heading: OUTCOME_HEADING.CANNOT_HELP,
        message:
          'Because they live in Northern Ireland. They can turn to the Department of Justice (Northern Ireland) for help.',
      }
    }

    if (residence === 'outside_uk') {
      return {
        complete: true,
        type: OUTCOME_TYPE.CANNOT_HELP,
        heading: OUTCOME_HEADING.CANNOT_HELP,
        message: 'Because they live outside of the UK.',
      }
    }
  },
}

export const largePrint: Question = {
  name: 'quoteAttributes.wantsLargePrint',
  label: 'Do you need your LPA in larger print?',
  summaryLabel: 'Large print',
  formattedAnswer: (values: LpaLeadAttributes) => {
    const { wantsLargePrint } = values.quoteAttributes

    return wantsLargePrint ? 'Yes' : 'No'
  },
  type: 'radio',
  options: YES_NO_OPTIONS,
}

export const allQuestions = [
  relationshipStatus,
  partnerWantsLPA,
  partnerTitle,
  partnerFirstName,
  partnerLastName,
  partnerPhoneNumber,
  partnerEmail,
  partnerDateOfBirth,
  partnerAddressFields,
  residence,
  largePrint,
]
