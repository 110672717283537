import { GTR } from '@farewill/ui/tokens'

export const scrollToDataId = (scrollId) => {
  const section = document.querySelector(`[data-scroll-id="${scrollId}"]`)
  if (!section) return false

  const topOfSection = section.getBoundingClientRect().top + window.scrollY
  const pointToScrollTo = topOfSection - parseInt(GTR.XL)
  window.scrollTo(0, pointToScrollTo)
}
