import { P } from '@farewill/ui'

import SimpleList from 'components/styled/simple-list'
import Tooltip from 'components/tooltip'
import { WillLeadAttributes } from 'lib/models/lead'
import { YES_NO_UNSURE_OPTIONS } from 'utils/enums'

import { OUTCOME_TYPE, PHONE_WILL_COMPLEXITY } from '../constants'
import { CompleteOutcome, Outcome } from '../types'
import { isUnsupportedResidence } from '../helpers/is-unsupported-residence'
import { getCountryLabelFromValue } from '../helpers/form-utils'

export const BusinessGift = {
  name: 'quoteAttributes.wantsToGiftBusiness',
  label: (
    <>
      Do you want to{' '}
      <Tooltip content="Extra guidance on: what’s best for their type of business, how they can pass on their business interests.">
        leave a business to someone
      </Tooltip>
      ?
    </>
  ),
  type: 'radio',
  options: YES_NO_UNSURE_OPTIONS,
  isOptional: (outcomeForPersonalAndAssetQuestions: Outcome) =>
    outcomeForPersonalAndAssetQuestions?.phoneWillComplexity ===
    PHONE_WILL_COMPLEXITY.COMPLEX,
  reasonForPhoneWill: (values: WillLeadAttributes) => {
    if (
      values.quoteAttributes?.wantsToGiftBusiness === 'yes' ||
      values.quoteAttributes?.wantsToGiftBusiness === 'not_sure'
    ) {
      return (
        <>
          <P strong>Because they want to gift a business.</P>
          <P>
            We can include a specific clause in their will and give them extra
            guidance on:
          </P>
          <SimpleList bullets>
            <li>what’s best for their type of business</li>
            <li>how they can pass on their business interests</li>
          </SimpleList>
        </>
      )
    }
  },
  reasonForComplexWill: (values: WillLeadAttributes) => {
    if (
      values.quoteAttributes?.wantsToGiftBusiness === 'yes' ||
      values.quoteAttributes?.wantsToGiftBusiness === 'not_sure'
    ) {
      return (
        <>
          <P strong>Because they want to gift a business.</P>
          <P>
            We can include a specific clause in their will and give them extra
            guidance on:
          </P>
          <SimpleList bullets>
            <li>what’s best for their type of business</li>
            <li>how they can pass on their business interests</li>
          </SimpleList>
        </>
      )
    }
  },
  outcome: (values: WillLeadAttributes) => {
    const value =
      values.quoteAttributes?.wantsToGiftBusiness === 'yes' ||
      values.quoteAttributes?.wantsToGiftBusiness === 'not_sure'

    const outcomeCondition = value && isUnsupportedResidence(values)

    if (outcomeCondition) {
      return {
        complete: true,
        type: OUTCOME_TYPE.CANNOT_HELP,
        message: `they want to gift a business${
          isUnsupportedResidence(values)
            ? ` while they live in ${getCountryLabelFromValue(
                values.quoteAttributes.permanentResidence ||
                  values.quoteAttributes.residence ||
                  ''
              )}`
            : ''
        }`,
      } as CompleteOutcome
    }
  },
}

export const PropertyGift = {
  name: 'quoteAttributes.wantsToGiftProperty',
  label: (
    <>
      Do you want to{' '}
      <Tooltip content="Extra guidance on: how to leave a property to more than one person, how the property will be owned, who will pay the mortgage.">
        leave a property to someone
      </Tooltip>
      ?
    </>
  ),
  type: 'radio',
  options: YES_NO_UNSURE_OPTIONS,
  isOptional: (outcomeForPersonalAndAssetQuestions: Outcome) =>
    outcomeForPersonalAndAssetQuestions?.phoneWillComplexity ===
    PHONE_WILL_COMPLEXITY.COMPLEX,
  reasonForPhoneWill: (values: WillLeadAttributes) => {
    if (
      values.quoteAttributes?.wantsToGiftProperty === 'yes' ||
      values.quoteAttributes?.wantsToGiftProperty === 'not_sure'
    ) {
      return (
        <>
          <P strong>Because they want to gift a property.</P>
          <P>
            We can include a specific clause in their will and give them extra
            guidance on:
          </P>
          <SimpleList bullets>
            <li>how to leave a property to more than one person</li>
            <li>how the property will be owned</li>
            <li>who will pay the mortgage</li>
          </SimpleList>
        </>
      )
    }
  },
  reasonForComplexWill: (values: WillLeadAttributes) => {
    if (
      values.quoteAttributes?.wantsToGiftProperty === 'yes' ||
      values.quoteAttributes?.wantsToGiftProperty === 'not_sure'
    ) {
      return (
        <>
          <P strong>Because they want to gift a property.</P>
          <P>
            We can include a specific clause in their will and give them extra
            guidance on:
          </P>
          <SimpleList bullets>
            <li>how to leave a property to more than one person</li>
            <li>how the property will be owned</li>
            <li>who will pay the mortgage</li>
          </SimpleList>
        </>
      )
    }
  },
  outcome: (values: WillLeadAttributes) => {
    if (
      (values.quoteAttributes?.wantsToGiftProperty === 'yes' ||
        values.quoteAttributes?.wantsToGiftProperty === 'not_sure') &&
      isUnsupportedResidence(values)
    ) {
      return {
        complete: true,
        type: OUTCOME_TYPE.CANNOT_HELP,
        message: `they want to gift a property while they live in ${getCountryLabelFromValue(
          values.quoteAttributes.permanentResidence ||
            values.quoteAttributes.residence ||
            ''
        )}`,
      }
    }
  },
}

export const DisabledOrMeansTestedBeneficiary = {
  name: 'quoteAttributes.hasDisabledOrMeansTestedBeneficiary',
  label: (
    <>
      Do you want to{' '}
      <Tooltip content="Guidance on setting up a trust to protect their inheritance and make sure it’s used for extra support, rather than day-to-day living costs.">
        leave anything to a person who is disabled or gets means tested benefits
      </Tooltip>
      ?
    </>
  ),
  type: 'radio',
  options: YES_NO_UNSURE_OPTIONS,
  isOptional: (outcomeForPersonalAndAssetQuestions: Outcome) =>
    outcomeForPersonalAndAssetQuestions?.phoneWillComplexity ===
    PHONE_WILL_COMPLEXITY.COMPLEX,
  reasonForPhoneWill: (values: WillLeadAttributes) => {
    if (
      values.quoteAttributes?.hasDisabledOrMeansTestedBeneficiary === 'yes' ||
      values.quoteAttributes?.hasDisabledOrMeansTestedBeneficiary === 'not_sure'
    ) {
      return (
        <>
          <P strong>
            Because they want to leave something to a person who is disabled or
            gets means tested benefits.{' '}
          </P>
          <P>
            We can set up a trust to make sure people still get their benefits.
            This helps to protect their inheritance and makes sure it’s used for
            extra support, rather than day-to-day living costs.
          </P>
        </>
      )
    }
  },
  reasonForComplexWill: (values: WillLeadAttributes) => {
    if (
      values.quoteAttributes?.hasDisabledOrMeansTestedBeneficiary === 'yes' ||
      values.quoteAttributes?.hasDisabledOrMeansTestedBeneficiary === 'not_sure'
    ) {
      return (
        <>
          <P strong>
            Because they want to leave something to a person who is disabled or
            gets means tested benefits.{' '}
          </P>
          <P>
            We can set up a trust to make sure people still get their benefits.
            This helps to protect their inheritance and makes sure it’s used for
            extra support, rather than day-to-day living costs.
          </P>
        </>
      )
    }
  },
  outcome: (values: WillLeadAttributes) => {
    const value =
      values.quoteAttributes?.hasDisabledOrMeansTestedBeneficiary === 'yes' ||
      values.quoteAttributes?.hasDisabledOrMeansTestedBeneficiary === 'not_sure'

    const outcomeCondition = value && isUnsupportedResidence(values)

    if (outcomeCondition) {
      return {
        complete: true,
        type: OUTCOME_TYPE.CANNOT_HELP,
        message: `they want to leave something to a person who is disabled or
        gets means tested benefits ${
          isUnsupportedResidence(values)
            ? `while they live in ${getCountryLabelFromValue(
                values.quoteAttributes.permanentResidence ||
                  values.quoteAttributes.residence ||
                  ''
              )}`
            : ''
        }`,
      }
    }
  },
}
