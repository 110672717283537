import { useReducer, useCallback } from 'react'
import { ProbateEstateItem } from 'lib/models/probate-estate'

type State = ProbateEstateItem[]

type Action =
  | {
      type: 'SET_ITEMS'
      payload: ProbateEstateItem[]
    }
  | {
      type: 'ADD_ITEM'
      payload: ProbateEstateItem
    }
  | {
      type: 'REMOVE_ITEM'
      payload: ProbateEstateItem
    }
  | {
      type: 'REPLACE_ITEM'
      payload: ProbateEstateItem
    }

const itemsReducer = (state: State, action: Action) => {
  switch (action.type) {
    case 'SET_ITEMS':
      return action.payload

    case 'ADD_ITEM':
      return [...state, action.payload]

    case 'REMOVE_ITEM':
      return state.filter(({ id }) => id !== action.payload.id)

    case 'REPLACE_ITEM':
      return state.map((item) => {
        if (item.id === action.payload.id) {
          return action.payload
        } else {
          return item
        }
      })

    default:
      throw new Error()
  }
}

const useItemState = (initialItems: ProbateEstateItem[] = []) => {
  const [state, dispatch] = useReducer(itemsReducer, initialItems)

  const setItems = useCallback(
    (items = []) => {
      dispatch({ type: 'SET_ITEMS', payload: items })
    },
    [dispatch]
  )

  const addItem = useCallback(
    (item) => {
      dispatch({ type: 'ADD_ITEM', payload: item })
    },
    [dispatch]
  )

  const removeItem = useCallback(
    (item) => {
      dispatch({ type: 'REMOVE_ITEM', payload: item })
    },
    [dispatch]
  )

  const replaceItem = useCallback(
    (item) => {
      dispatch({ type: 'REPLACE_ITEM', payload: item })
    },
    [dispatch]
  )

  return { items: state, setItems, addItem, removeItem, replaceItem }
}

export default useItemState
