import { Button, Grid } from '@farewill/ui'
import { GTR } from '@farewill/ui/tokens'

import store from 'state/create-store'
import { showModal } from 'state/actions'

import EstateCard from 'components/estate-card'

import namespace from 'lib/formik/namespace'

import NotesInput from 'components/notes-input'

import { Input } from 'components/form'
import { ProbateLeadAttributes } from 'lib/models/lead'
import { HandleSaveProps, ItemProps } from '../assets/types'
import { useFormikContext } from 'formik'
import {
  PROBATE_LEAD_FORM_SECTIONS,
  PROBATE_LEAD_FORM_VALUES,
} from '../constants'
import {
  PROBATE_ESTATE_ITEM_TYPES,
  ProbateEstateItem,
} from 'lib/models/probate-estate'
import { YES_NO_OPTIONS } from 'utils/enums'
import { QuestionConfig } from 'lib/types/form'
import Tooltip from 'components/tooltip'
import { strToBool } from 'utils/helpers'
import Checkbox from 'components/form/checkbox'
import DeleteModal from '../delete-modal'
import withForm from '../with-form'
import { Question } from './question'
import { useItems } from '../helpers/items-context'
import SectionHeading from '../section-heading'

const Item = ({ item, index, listLength }: ItemProps) => {
  const { withNamespace, withoutNamespace } = namespace(
    `giftBenefitted.${item.id}.`
  )
  const { updateItem, deleteItem } = useItems()

  const handleSave = ({ name, value }: HandleSaveProps) => {
    updateItem(
      {
        id: item.id,
        attributes: { [withoutNamespace(name)]: value || null },
      },
      PROBATE_ESTATE_ITEM_TYPES.GIFT_BENEFITTED
    )
  }

  const onTrashClick = () => {
    store.dispatch(
      showModal({
        component: DeleteModal,
        headingText: 'Delete gift',
        config: {
          deleteCallback: () => {
            deleteItem(item.id)
          },
          type: 'gift',
          resourceId: item.id,
        },
      })
    )
  }

  return (
    <EstateCard
      onTrashClick={onTrashClick}
      listLength={listLength}
      listPosition={index + 1}
      key={item.id}
      type="gift"
    >
      <Grid>
        <Grid.Item span={7}>
          <Input
            name={withNamespace(PROBATE_LEAD_FORM_VALUES.VALUE)}
            label="At the date of death, what was the value (£) of the gift they benefitted from?"
            handleSave={handleSave}
            isCurrency
          />
        </Grid.Item>
        <Grid.Item>
          <Checkbox
            name={withNamespace(PROBATE_LEAD_FORM_VALUES.IS_ESTIMATED_VALUE)}
            label="Estimate"
            handleSave={handleSave}
          />
        </Grid.Item>
        <Grid.Item span={10}>
          <Input
            name={withNamespace(PROBATE_LEAD_FORM_VALUES.DESCRIPTION)}
            label="Description"
            handleSave={handleSave}
          />
        </Grid.Item>
        <Grid.Item>
          <NotesInput
            name={withNamespace(PROBATE_LEAD_FORM_VALUES.NOTES)}
            handleSave={handleSave}
          />
        </Grid.Item>
      </Grid>
    </EstateCard>
  )
}

const ItemWithForm = withForm(Item)

const CONTINUED_BENEFIT_GIFTS_QUESTION: QuestionConfig = {
  name: 'quoteAttributes.madeGiftsWithReservationOfBenefit',
  shortLabel: 'gifts with reservation of benefit',
  label: (
    <>
      Did they make{' '}
      <Tooltip
        content="Known as a ‘gift with reservation of benefit’. For example, if they gave their house away but carried on living there without paying rent, or gave away a painting but kept it in their living room.
  "
      >
        gifts which they continued to benefit from
      </Tooltip>
      ?
    </>
  ),
  options: YES_NO_OPTIONS,
}

const GiftsContinuedBenefit = ({ isComplete }: { isComplete: boolean }) => {
  const { values } = useFormikContext<ProbateLeadAttributes>()
  const { groupedItems, createItem } = useItems()
  const { giftBenefitted: giftBenefittedItems = [] } = groupedItems
  return (
    <Grid name={PROBATE_LEAD_FORM_SECTIONS.GIFTS_CONTINUED_BENEFIT}>
      <SectionHeading
        title="8.2 Gifts they continued to benefit from"
        scrollId="8.2"
        isComplete={isComplete}
      />
      <Grid.Item>
        <Question question={CONTINUED_BENEFIT_GIFTS_QUESTION} />
      </Grid.Item>
      {strToBool(values.quoteAttributes.madeGiftsWithReservationOfBenefit) && (
        <>
          <Grid.Item>
            <Grid margin={['M', 0, 'M', 'M']}>
              {giftBenefittedItems.map(
                (item: ProbateEstateItem, index: number) => (
                  <Grid.Item key={item.id} span={11}>
                    <ItemWithForm
                      enableReinitialize={false}
                      index={index}
                      initialValues={{
                        giftBenefitted: { [item.id]: item.attributes },
                      }}
                      item={item}
                      listLength={giftBenefittedItems.length}
                    />
                  </Grid.Item>
                )
              )}
            </Grid>
            <Grid.Item>
              <Button.Secondary
                style={{ paddingTop: GTR.XXS, paddingBottom: GTR.XXS }}
                type="button"
                onClick={() => {
                  createItem({
                    itemType: PROBATE_ESTATE_ITEM_TYPES.GIFT_BENEFITTED,
                  })
                }}
              >
                Add gift
              </Button.Secondary>
            </Grid.Item>
          </Grid.Item>
        </>
      )}
    </Grid>
  )
}

export default GiftsContinuedBenefit
