import { ReactNode } from 'react'
import styled from 'styled-components'
import { Wrapper } from '@farewill/ui'
import { BORDER, COLOR, FONT, GTR } from '@farewill/ui/tokens'

const StatusHeader = styled(({ highlight, ...rest }) => <Wrapper {...rest} />)`
  background-color: var(--box-color);
  font-weight: ${FONT.WEIGHT.BOLD};
  font-size: ${FONT.SIZE.S};
  padding: ${GTR.XS} ${GTR.S};
  border-top-left-radius: ${BORDER.RADIUS.S};
  border-top-right-radius: ${BORDER.RADIUS.S};
  ${(props) => props.highlight && `color: ${COLOR.BLACK};`}
`

const StatusBox = styled(({ highlight, sticky, stickyGap, ...rest }) => (
  <Wrapper {...rest} />
))`
  --box-color: ${COLOR.GREY.LIGHT};
  --border-color: ${COLOR.GREY.LIGHT};
  border: 2px solid var(--border-color);
  border-radius: ${BORDER.RADIUS.M};
  display: flex;
  flex-direction: column;

  ${(props) => props.sticky && `position: sticky; top: ${props.stickyGap};`}

  ${(props) =>
    props.highlight &&
    `--box-color: ${COLOR.ACCENT.PRIMARY_20}; --border-color: ${COLOR.ACCENT.PRIMARY_120}`}
`

const StatusBody = styled(Wrapper)`
  background-color: ${COLOR.WHITE};
  padding: ${GTR.M} ${GTR.S};
  border-bottom-left-radius: ${BORDER.RADIUS.M};
  border-bottom-right-radius: ${BORDER.RADIUS.M};
  flex-grow: 1;
`

export type StatusPanelProps = {
  children: ReactNode
  heading: ReactNode
  highlight?: boolean
  sticky?: boolean
  stickyGap?: keyof typeof GTR
}

export const StatusPanel = ({
  children,
  heading,
  highlight,
  sticky,
  stickyGap,
}: StatusPanelProps): React.ReactElement => (
  <StatusBox
    highlight={highlight}
    sticky={sticky}
    stickyGap={GTR[stickyGap ?? 'S']}
  >
    <StatusHeader highlight={highlight}>{heading}</StatusHeader>
    <StatusBody>
      <Wrapper>{children}</Wrapper>
    </StatusBody>
  </StatusBox>
)

StatusPanel.defaultProps = {
  highlight: false,
  sticky: false,
  stickyGap: 'S',
}

export default StatusPanel
