import { createContext, useContext, useCallback } from 'react'
import useApiHelpers from 'lib/effects/api-helpers'
import { useState } from 'react'
import filter from 'lodash/filter'
import axiosRequest from 'lib/axios/api-request'

const DocumentsContext = createContext()

const useDocumentsContext = () => {
  const context = useContext(DocumentsContext)
  if (context === undefined) {
    throw new Error(
      'useDocumentsContext must be used within an DocumentsProvider'
    )
  }
  return context
}

const DocumentsProvider = ({ children }) => {
  const {
    items: documents,
    fetchItems: fetchDocuments,
    isFetching: isFetchingDocuments,
    createItem: createDocument,
    updateItem: updateDocument,
    deleteItem: deleteDocument,
    isDeleting: isDeletingDocument,
  } = useApiHelpers({
    baseUrl: '/api/documents',
    type: 'documents',
  })

  const { createItem: createFile, updateItem: updateFile } = useApiHelpers({
    baseUrl: '/api/files',
    type: 'files',
  })

  const [isUploading, setIsUploading] = useState(false)

  const attachFiles = useCallback(
    async (documentId, files) => {
      try {
        setIsUploading(true)

        return await Promise.all(
          files.map(async (file, orderIndex) => {
            const createdFile = await createFile({
              source: 'upload',
              type: file.type,
              documentId,
              orderIndex,
            })

            await axiosRequest({
              url: createdFile.attributes.url,
              method: 'PUT',
              data: file,
              headers: { 'Content-Type': file.type },
              sendToken: false,
            })

            return createdFile
          })
        )
      } finally {
        setIsUploading(false)
      }
    },
    [setIsUploading, createFile]
  )

  const values = {
    documents,
    fetchDocuments,
    isFetchingDocuments,
    createDocument,
    updateDocument,
    deleteDocument,
    isDeletingDocument,
    createFile,
    attachFiles,
    isUploading,
    updateFile,
    publishedDocuments: filter(documents, ['attributes.status', 'published']),
  }

  return (
    <DocumentsContext.Provider value={values}>
      {children}
    </DocumentsContext.Provider>
  )
}

export { DocumentsProvider, useDocumentsContext }
