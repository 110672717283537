import {
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react'

import apiRequest from 'lib/axios/api-request'
import {
  ProbateEstateProperty,
  ProbateEstatePropertiesAttributes,
} from 'lib/models/probate-estate'

interface PropertiesContextProps {
  properties: ProbateEstateProperty[]
  createProperty: (
    attributes?: Partial<ProbateEstatePropertiesAttributes>
  ) => Promise<void>
  updateProperty: (property: Partial<ProbateEstateProperty>) => Promise<void>
  deleteProperty: (id: number) => Promise<void>
}

const PropertiesContext = createContext<PropertiesContextProps>({
  properties: [],
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  createProperty: async () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  updateProperty: async () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  deleteProperty: async () => {},
})

const PropertiesProvider = ({
  children,
  probateEstateId,
}: PropsWithChildren<{
  probateEstateId: number
}>) => {
  const [properties, setProperties] = useState<ProbateEstateProperty[]>([])

  useEffect(() => {
    if (!probateEstateId) return

    apiRequest({
      url: `/api/probate-estates/${probateEstateId}/properties`,
      method: 'GET',
    }).then((response) => {
      setProperties(response.data.data)
    })
  }, [probateEstateId])

  const createProperty = async (
    attributes?: Partial<ProbateEstatePropertiesAttributes>
  ) => {
    const apiResponse = await apiRequest({
      url: `/api/probate-estates/${probateEstateId}/properties`,
      method: 'POST',
      data: {
        data: {
          type: 'probate_estate_properties',
          attributes: { ...attributes },
        },
      },
    })

    setProperties([...properties, apiResponse.data.data])
  }

  const updateProperty = async (property: Partial<ProbateEstateProperty>) => {
    return apiRequest({
      url: `/api/probate-estates/${probateEstateId}/properties/${property.id}`,
      method: 'PATCH',
      data: {
        data: {
          type: 'probate_estate_properties',
          id: property.id,
          attributes: property.attributes,
        },
      },
    }).then(({ data }) => {
      const updatedPropertyData: ProbateEstateProperty = data.data
      setProperties((prevProperties) =>
        prevProperties.map((propertyItem) =>
          propertyItem.id === updatedPropertyData.id
            ? updatedPropertyData
            : propertyItem
        )
      )
    })
  }

  const deleteProperty = async (id: number) => {
    await apiRequest({
      url: `/api/probate-estates/${probateEstateId}/properties/${id}`,
      method: 'DELETE',
    }).then(() => {
      const updatedProperties = properties.filter((p) => p.id !== id)
      setProperties(updatedProperties)
    })
  }

  return (
    <PropertiesContext.Provider
      value={{ properties, createProperty, updateProperty, deleteProperty }}
    >
      {children}
    </PropertiesContext.Provider>
  )
}

const useProperties = () => useContext(PropertiesContext)

export { PropertiesProvider, useProperties }
