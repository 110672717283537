import { H, Wrapper } from '@farewill/ui'
import DatetimePicker from 'components/form/datetime-picker'
import { Formik, Form } from 'formik'
import { useSelector, useDispatch } from 'react-redux'
import { getWidthOfContainerColumns } from '@farewill/ui/helpers/layout'

import { formatValuesForFormik, formatDateTimeToUTC } from 'utils/helpers'
import { updateProbateCase, updateLpaCase } from 'state/actions'

const FactFind = ({ productType }) => {
  const dispatch = useDispatch()
  const productCase = productType === 'lpa' ? 'lpaCase' : 'probateCase'

  const product = useSelector((state) => state[productCase])

  const handleSave = ({ value }) => {
    const attributes = { factFindCallAt: formatDateTimeToUTC(value) }
    if (productType === 'lpa') {
      return dispatch(updateLpaCase(product.id, attributes))
    }
    return dispatch(updateProbateCase(product.id, attributes))
  }

  return (
    <Wrapper>
      <H size="S">Fact find call</H>
      <Wrapper maxWidth={getWidthOfContainerColumns(4, 'L')}>
        <Formik initialValues={formatValuesForFormik(product.attributes)}>
          <Form>
            <DatetimePicker
              label="Date / time for fact-find call"
              name="factFindCallAt"
              handleSave={handleSave}
            />
          </Form>
        </Formik>
      </Wrapper>
    </Wrapper>
  )
}

export default FactFind
