import { Fragment } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { P } from '@farewill/ui'
import { GTR } from '@farewill/ui/tokens'

import ScrollTo from 'components/scroll-to'
import PinkBox from 'components/styled/pink-box'

const StyledList = styled.ul`
  margin: 0;
  overflow: hidden;

  ${({ isTable }) =>
    isTable &&
    `
      display: grid;
      grid-column-gap: ${GTR.L};
      grid-template-columns: max-content auto;
    `}
`

const StyledListItem = styled.li`
  ${({ isTable }) =>
    !isTable &&
    `
      float: left;

      &:nth-child(odd) {
        clear: left;
        margin-right: ${GTR.XXS};
      }
    `}
`

const LinkToSection = ({ isLink, probateEstateId, section }) => {
  if (!section) return null

  if (isLink && probateEstateId)
    return (
      <>
        (
        <Link to={`/probate/estates/${probateEstateId}/fact-find#${section}`}>
          {section}
        </Link>
        )
      </>
    )

  return (
    <>
      (<ScrollTo scrollId={section}>{section}</ScrollTo>)
    </>
  )
}

const SummaryBox = ({ isTable, items, probateEstateId, stretch, title }) => (
  <PinkBox stretch={stretch}>
    {title && <P>{title}</P>}
    <StyledList isTable={isTable}>
      {items.map(
        ({ label, isLink, section, showIf, value }) =>
          (!showIf || showIf()) && (
            <Fragment key={label}>
              <StyledListItem isTable={isTable}>
                <strong>
                  {label}
                  {!isTable && ':'}
                </strong>
              </StyledListItem>
              <StyledListItem isTable={isTable}>
                {value || <i>Not specified</i>}{' '}
                <LinkToSection
                  isLink={isLink}
                  probateEstateId={probateEstateId}
                  section={section}
                />
              </StyledListItem>
            </Fragment>
          )
      )}
    </StyledList>
  </PinkBox>
)

SummaryBox.propTypes = {
  isTable: PropTypes.bool,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      isLink: PropTypes.bool,
      section: PropTypes.string,
      showIf: PropTypes.func,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ).isRequired,
  probateEstateId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  stretch: PropTypes.bool,
}

SummaryBox.defaultProps = {
  isTable: false,
  stretch: false,
}

export default SummaryBox
