import PropTypes from 'prop-types'
import { useFormikContext } from 'formik'
import { H, Grid, P } from '@farewill/ui'
import Label from 'components/styled/label'

import InputFloating from 'components/form/input-floating'
import NotesInput from 'components/notes-input'
import NoWillMessage from '../../../internal-checks/no-will-message'

import { getEstateChildrenVisibleFields } from '../form-helpers'
import {
  LIVING_CHILDREN_UNDER_EIGHTEEN,
  LIVING_CHILDREN_OVER_EIGHTEEN,
  DECEASED_CHILDREN_OVER_EIGHTEEN,
  DECEASED_CHILDREN_UNDER_EIGHTEEN,
  GRANDCHILDREN_WITH_DECEASED_PARENT,
  CHILDREN_NOTES,
} from '../field-names'

const ChildrenOfThePersonWhosDied = ({
  probateEstateId,
  saveProbateEstateField,
}) => {
  const { values } = useFormikContext()
  const { showChildrenSection, showGrandchildrenWithDeceasedParent } =
    getEstateChildrenVisibleFields({ values })

  return (
    <Grid data-scroll-id="1.5" style={{ gridAutoFlow: 'row' }}>
      <Grid.Item>
        <H size="S">1.5 Children of the person who’s died</H>
      </Grid.Item>
      {showChildrenSection && (
        <>
          <Grid.Item>
            <P size="S">Including adopted children</P>
          </Grid.Item>
          <Grid.Item>
            <Label>How many living children?</Label>
            <Grid>
              <Grid.Item span={3} startColumn={1}>
                <InputFloating
                  name={LIVING_CHILDREN_UNDER_EIGHTEEN}
                  label="Aged under 18"
                  handleSave={saveProbateEstateField}
                />
              </Grid.Item>
              <Grid.Item span={3}>
                <InputFloating
                  name={LIVING_CHILDREN_OVER_EIGHTEEN}
                  label="Aged over 18"
                  handleSave={saveProbateEstateField}
                />
              </Grid.Item>
            </Grid>
          </Grid.Item>
          <Grid.Item>
            <Label>How many children that have died?</Label>
            <Grid>
              <Grid.Item span={3} startColumn={1}>
                <InputFloating
                  name={DECEASED_CHILDREN_UNDER_EIGHTEEN}
                  label="Aged under 18"
                  handleSave={saveProbateEstateField}
                />
              </Grid.Item>
              <Grid.Item span={3}>
                <InputFloating
                  name={DECEASED_CHILDREN_OVER_EIGHTEEN}
                  label="Aged over 18"
                  handleSave={saveProbateEstateField}
                />
              </Grid.Item>
            </Grid>
          </Grid.Item>
          {showGrandchildrenWithDeceasedParent && (
            <>
              <Grid.Item>
                <Label>
                  You’ve confirmed that the person who died had children who
                  died. How many children did the child or children who died
                  have? (i.e. grandchildren to the person to died)
                </Label>
              </Grid.Item>
              <Grid.Item span={3} startColumn={1}>
                <InputFloating
                  name={GRANDCHILDREN_WITH_DECEASED_PARENT}
                  label="Children"
                  handleSave={saveProbateEstateField}
                  hint="Children of deceased parents"
                />
              </Grid.Item>
            </>
          )}
          <Grid.Item>
            <NotesInput
              name={CHILDREN_NOTES}
              handleSave={saveProbateEstateField}
            />
          </Grid.Item>
        </>
      )}
      {!showChildrenSection && (
        <Grid.Item>
          <NoWillMessage probateEstateId={probateEstateId} />
        </Grid.Item>
      )}
    </Grid>
  )
}

ChildrenOfThePersonWhosDied.propTypes = {
  saveProbateEstateField: PropTypes.func.isRequired,
}

export default ChildrenOfThePersonWhosDied
