export const TENURE = 'tenure'
export const VALUE = 'value'
export const IS_ESTIMATED_VALUE = 'isEstimatedValue'
export const REGISTRY_CHECK_STATUS = 'registryCheckStatus'
export const OWNERSHIP = 'ownership'
export const JOINT_OWNERSHIP_TYPE = 'jointOwnershipType'
export const JOINT_OWNER_NAME = 'jointOwnerName'
export const JOINT_OWNER_RELATIONSHIP = 'jointOwnerRelationship'
export const DECEASED_SHARE_OF_JOINT_OWNERSHIP = 'deceasedShareOfJointOwnership'
export const INTENTIONS = 'intentions'
export const NOTES = 'notes'
export const ADDRESS = 'address'
export const CHANGED_PRIVATE_PENSIONS_BEFORE_DEATH =
  'changedPrivatePensionsBeforeDeath'
export const PRIVATE_PENSION_CHANGE_NOTES = 'privatePensionChangeNotes'
export const HOUSEHOLD_POSSESSIONS_VALUE = 'householdPossessionsValue'

export const PROPERTY_SECTION_ACTIVE = 'propertySectionActive'
export const BANK_ACCOUNTS_SECTION_ACTIVE = 'bankAccountsSectionActive'
export const CASH_SECTION_ACTIVE = 'cashSectionActive'
export const NATIONAL_SAVINGS_AND_INVESTMENTS_SECTION_ACTIVE =
  'nationalSavingsAndInvestmentsSectionActive'
export const PUBLIC_SHAREHOLDINGS_SECTION_ACTIVE =
  'publicShareholdingsSectionActive'
export const INVESTMENTS_SECTION_ACTIVE = 'investmentsSectionActive'
export const LIFE_INSURANCE_SECTION_ACTIVE = 'lifeInsuranceSectionActive'
export const PRIVATE_PENSIONS_SECTION_ACTIVE = 'privatePensionsSectionActive'
export const VEHICLES_AND_POSESSIONS_SECTION_ACTIVE =
  'vehiclesAndPossessionsSectionActive'
export const BUSINESS_OR_AGRICULTURAL_ASSETS_SECTION_ACTIVE =
  'businessOrAgriculturalAssetsSectionActive'
export const OVERSEAS_ASSETS_SECTION_ACTIVE = 'overseasAssetsSectionActive'
export const MONEY_OWED_SECTION_ACTIVE = 'moneyOwedSectionActive'
