import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import lowerCase from 'lodash/lowerCase'

import { Button, ButtonGroup, P, Wrapper } from '@farewill/ui'

import { hideModal } from 'state/actions'
import { FAREWILL_PRODUCT_IDS } from 'utils/enums'

const SetAutomatedDueDatesModal = ({
  config: { actionTask, task, taskConfig, fetchTasks, product },
}) => {
  const dispatch = useDispatch()

  const handleCancel = () => dispatch(hideModal())
  const handleConfirm = async () => {
    await actionTask(task.id, 'complete')

    const productId = FAREWILL_PRODUCT_IDS[product]
    await fetchTasks({
      queryParams: {
        [`filter[${productId}]`]: task.attributes[productId],
        'filter[isNeeded]': true,
        'page[size]': 200,
      },
    })

    dispatch(hideModal())

    toast('Task marked as complete and due dates set.', {
      toastId: 'task-marked-as-complete',
    })
  }

  return (
    <>
      <Wrapper>
        <P size="S">
          By completing this task,{' '}
          {taskConfig.automateDueDates
            .map(
              (task) =>
                `we’ll set the due date for the ${lowerCase(
                  task.coreTask
                )} task for ${task.days} working days from today (or ${
                  task.daysIfUrgent
                } if the case is urgent)`
            )
            .join(' and ')}
          .
        </P>
      </Wrapper>
      <ButtonGroup margin={['L', 0, 0]}>
        <Button.Primary type="button" onClick={handleConfirm}>
          Confirm
        </Button.Primary>
        <Button.Underline type="button" onClick={handleCancel}>
          Cancel
        </Button.Underline>
      </ButtonGroup>
    </>
  )
}

export default SetAutomatedDueDatesModal
