import { useContext } from 'react'

import AddressInput from 'components/address'
import { Address as AddressType } from 'lib/models/address'

import { Context } from '../../context'

type Props = {
  data: AddressType
  label: string
  name: string
}

const Address = ({ data, label, name }: Props): React.ReactElement => {
  const { setErrors, setIsLoading } = useContext(Context)

  return (
    <AddressInput
      data={data}
      label={label}
      name={`${name}.${data.id}`}
      setIsLoading={setIsLoading}
      setErrors={setErrors}
    />
  )
}

export default Address
