import { Dispatch, SetStateAction } from 'react'
import { Button, Checkbox, ChevronRightIcon } from '@farewill/ui'
import { COLOR, FONT, GTR } from '@farewill/ui/tokens'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

import { Section as SectionType, SubSection } from './config'

const StyledSection = styled.div<{ $complete: boolean }>`
  background-color: ${COLOR.BACKGROUND.FOG};
  margin-bottom: ${GTR.XS};
  padding: ${GTR.XS} ${GTR.S} ${GTR.XS} 30px;
  position: relative;
  font-size: 12px;

  ${({ $complete }) =>
    $complete && `background-color: ${COLOR.STATE.SUCCESS_10};`}
`

const StyledHeader = styled.div`
  display: flex;
  justify-content: space-between;
`

const StyledSectionLink = styled(Link)`
  font-weight: ${FONT.WEIGHT.BOLD};
  font-size: ${FONT.SIZE.S};
`

const StyledArrow = styled(Button)`
  margin-right: ${GTR.XS};
  position: absolute;
  top: 12px;
  left: ${GTR.XS};

  ${({ open }) => open && `transform: rotate(90deg)`}
`

const StyledList = styled.ul`
  margin: ${GTR.XS} 0;
`

const StyledListItem = styled.li`
  &:not(:last-of-type) {
    margin-bottom: ${GTR.XXS};
  }
`

const StyledCheckbox = styled(Checkbox)`
  margin: 0;
  width: 100%;

  > div {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;

    > span {
      margin: 0;
    }
  }
`

type SubSectionsProps = {
  linkTo: string
  subSections: SubSection[]
}

const SubSections = ({
  linkTo,
  subSections,
}: SubSectionsProps): React.ReactElement => (
  <StyledList>
    {subSections.map((subSection) => (
      <StyledListItem key={subSection.scrollId}>
        <Link to={`${linkTo}#${subSection.scrollId}`}>
          {subSection.scrollId} {subSection.label}
        </Link>
      </StyledListItem>
    ))}
  </StyledList>
)

type SectionProps = {
  isOpen: boolean
  isComplete: boolean
  handleArrowClick: Dispatch<SetStateAction<{ [key: string]: boolean }>>
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  linkTo: string
  section: SectionType
}

const Section = ({
  isOpen,
  isComplete,
  handleArrowClick,
  handleChange,
  linkTo,
  section,
}: SectionProps): React.ReactElement => (
  <StyledSection $complete={isComplete}>
    {section.subSections && (
      <StyledArrow
        onClick={() => handleArrowClick({ [section.name]: !isOpen })}
        open={isOpen}
        type="button"
      >
        <ChevronRightIcon size="S" />
      </StyledArrow>
    )}
    <StyledHeader>
      <StyledCheckbox
        checked={isComplete}
        label={
          <StyledSectionLink to={`${linkTo}#${section.scrollId}`}>
            {section.scrollId}. {section.label}
          </StyledSectionLink>
        }
        name={section.name}
        onChange={handleChange}
        size="S"
        color={COLOR.STATE.SUCCESS}
      />
    </StyledHeader>
    {isOpen && section.subSections && (
      <SubSections subSections={section.subSections} linkTo={linkTo} />
    )}
  </StyledSection>
)

export default Section
