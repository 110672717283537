import { Divider, Grid } from '@farewill/ui'

import withForm from 'routes/lead/probate-form/with-form'

import BankAccounts from './bank-accounts'
import BusinessOrAgriculturalAssets from './business-or-agricultural-assets'
import LifeInsurance from './life-insurance'
import NationalSavingsAndInvestments from './national-savings-and-investments'
import Other from './other'
import OverseasAssets from './overseas-assets'
import PrivatePensions from './private-pensions'
import SharesAndInvestments from './shares-and-investments'
import { useItems } from '../helpers/items-context'
import SectionHeading from '../section-heading'
import { useProbateEstate } from '../helpers/probate-estate-context'
import LegacyLeadMessageBox from '../legacy-lead-message-box'
import { SECTION_NAMES } from '../helpers/complete-sections'

const Assets = ({
  isComplete,
  checkIfComplete,
}: {
  isComplete: boolean
  checkIfComplete: (name: string) => boolean
}) => {
  const { items } = useItems()
  const { probateEstateId } = useProbateEstate()

  return items ? (
    <Grid>
      <SectionHeading
        title="6. Assets"
        scrollId="assets"
        isComplete={isComplete}
        hideIcon
      />

      {probateEstateId ? (
        <Grid.Item span={11} startColumn={2}>
          <BankAccounts
            isComplete={checkIfComplete(
              SECTION_NAMES.BANK_AND_BUILDING_SOCIETY_ACCOUNTS
            )}
          />
          <Divider margin={['L', 0]} />
          <NationalSavingsAndInvestments
            isComplete={checkIfComplete(SECTION_NAMES.NS_AND_I)}
          />
          <Divider margin={['L', 0]} />
          <SharesAndInvestments
            isComplete={checkIfComplete(
              SECTION_NAMES.PUBLIC_SHAREHOLDINGS_AND_INVESTMENTS
            )}
          />
          <Divider margin={['L', 0]} />
          <LifeInsurance
            isComplete={checkIfComplete(SECTION_NAMES.LIFE_INSURANCE)}
          />
          <Divider margin={['L', 0]} />
          <PrivatePensions
            isComplete={checkIfComplete(SECTION_NAMES.PRIVATE_PENSIONS)}
          />
          <Divider margin={['L', 0]} />
          <BusinessOrAgriculturalAssets
            isComplete={checkIfComplete(
              SECTION_NAMES.BUSINESS_AND_AGRICULTURAL_ASSETS
            )}
          />
          <Divider margin={['L', 0]} />
          <OverseasAssets
            isComplete={checkIfComplete(SECTION_NAMES.OVERSEAS_ASSETS)}
          />
          <Divider margin={['L', 0]} />
          <Other isComplete={checkIfComplete(SECTION_NAMES.OTHER)} />
        </Grid.Item>
      ) : (
        <Grid.Item startColumn={2}>
          <LegacyLeadMessageBox />
        </Grid.Item>
      )}
    </Grid>
  ) : null
}

export default withForm(Assets)
