import { Divider, Grid, H, P } from '@farewill/ui'

import LegalTeamApproval from './legal-team-approval'
import CheckAndApprove from './check-and-approve'

const ApproveForReferral = ({ probateEstate, saveProbateEstateField }) => {
  return (
    <Grid gap="XL">
      <Grid.Item span={8}>
        <H size="M">Approve for referral</H>
        <P margin={[0, 0, 'XL']}>Approve fact find ready for referral</P>
        <LegalTeamApproval
          initialValues={probateEstate.attributes}
          saveProbateEstateField={saveProbateEstateField}
        />
        <Divider margin={['S', 0, 'L']} />
        <CheckAndApprove
          initialValues={probateEstate.attributes}
          saveProbateEstateField={saveProbateEstateField}
        />
      </Grid.Item>
    </Grid>
  )
}

export default ApproveForReferral
