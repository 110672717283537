import { P } from '@farewill/ui'

import Tooltip from 'components/tooltip'
import { WillLeadAttributes } from 'lib/models/lead'
import { YES_NO_OPTIONS } from 'utils/enums'

export const LargePrint = {
  name: 'quoteAttributes.wantsLargePrint',
  label: (
    <>
      Do you need your will in{' '}
      <Tooltip content="Makes sense if you have vision loss.">
        larger print
      </Tooltip>
      ?
    </>
  ),
  options: YES_NO_OPTIONS,
  type: 'radio',
  reasonForPhoneWill: (values: WillLeadAttributes) => {
    if (values.quoteAttributes?.wantsLargePrint === 'true') {
      return (
        <P strong>Because they want to receive their will in large print.</P>
      )
    }
  },
  phoneWillOnly: true,
}
