import { Divider } from '@farewill/ui'
import { useState } from 'react'
import merge from 'lodash/merge'
import { Formik, useFormikContext } from 'formik'

import { PARTNER_TYPE } from 'config/partners'
import ENV from 'config/environment'
import { WillLeadAttributes } from 'lib/models/lead'
import { WILL_LEAD_SCHEMA } from 'lib/formik/schemata'
import { formatValuesForFormik } from 'utils/helpers'
import { FAREWILL_PRODUCTS } from 'utils/enums'

import { OUTCOME_TYPE } from '../constants'
import { useLeadContext } from '../context'
import QuoteForm from '../quote-form'
import TakePayment from '../take-payment'
import FindATime from '../find-a-time'

import CantHelp from './cant-help'
import {
  BLANK_VALUES,
  DIAGNOSTIC_HEADINGS,
  DIAGNOSTIC_SUBHEADINGS,
  LARGE_PRINT,
  OPTIONAL,
  PERSONAL_AND_ASSETS,
  TRUST_LEAD_LANDING_PATH,
  WISHES,
} from './constants'
import CallerDetails from './components/caller-details'
import Diagnosis from './components/diagnosis'
import OutcomeNew from './components/outcome-new'
import formUtils, { formatAttributesForForm } from './helpers/form-utils'
import CaptureCardDetailsForLater from '../capture-card-details-for-later'

type WillFormProps = {
  attributes: WillLeadAttributes
  disabled?: boolean
}

const InnerForm = ({ disabled }: { disabled?: boolean }) => {
  const { errors, values } = useFormikContext<WillLeadAttributes>()

  const {
    calculatedPrice,
    calendlyUrl,
    propertyTrustFactFindCalendlyUrl,
    canPay,
    canTakePayment,
    shouldCaptureCardForLater,
    freeWillEligible,
    outcomeForAllQuestions,
    showWishesQuestions,
    visibleQuestionsInSections,
  } = formUtils(values, errors)

  const isCharity = values.partnerType === PARTNER_TYPE.CHARITY
  const handleTrustFactFind =
    ENV.FF_LIMIT_TRUST_LEADS_TO_WILL_WRITERS_ENABLED &&
    values.landingPath === TRUST_LEAD_LANDING_PATH
  const [
    showPropertyTrustFactFindBookingButton,
    setShowPropertyTrustFactFindBookingButton,
  ] = useState(handleTrustFactFind)

  return (
    <QuoteForm disabled={disabled}>
      <CallerDetails product={FAREWILL_PRODUCTS.WILL} />
      <Divider margin={['XL', 0]} />
      <Diagnosis
        title={DIAGNOSTIC_HEADINGS[PERSONAL_AND_ASSETS]}
        questions={visibleQuestionsInSections[PERSONAL_AND_ASSETS]}
      />

      <Divider />
      {showWishesQuestions && (
        <>
          <Diagnosis
            title={DIAGNOSTIC_HEADINGS[WISHES]}
            questions={visibleQuestionsInSections[WISHES]}
          />

          <Diagnosis
            title={DIAGNOSTIC_HEADINGS[OPTIONAL]}
            subHeading={DIAGNOSTIC_SUBHEADINGS[OPTIONAL]}
            questions={visibleQuestionsInSections[OPTIONAL]}
            smallTitle
          />

          {visibleQuestionsInSections[OPTIONAL].length > 0 && <Divider />}

          <Diagnosis questions={visibleQuestionsInSections[LARGE_PRINT]} />
        </>
      )}

      {outcomeForAllQuestions?.type === OUTCOME_TYPE.CANNOT_HELP ? (
        <CantHelp
          reason={outcomeForAllQuestions?.message as string} // we can assert this is string because whenever outcome type is cannot_help, the message will be string
          isCharity={isCharity}
        />
      ) : (
        <OutcomeNew
          outcome={outcomeForAllQuestions}
          freeWillEligible={freeWillEligible}
          isCharity={isCharity}
        />
      )}

      {/* We will show TakePayment component as long as something must be paid */}
      {canPay && calculatedPrice > 0 && (
        <>
          <Divider margin={['XL', 0]} />
          <TakePayment
            calculatedPrice={calculatedPrice}
            skip={!canTakePayment}
          />
        </>
      )}
      {ENV.FF_TELEPHONE_WILL_CANCELLATION_FEE_ENABLED &&
        shouldCaptureCardForLater && (
          <>
            <Divider margin={['XL', 0]} />
            <CaptureCardDetailsForLater
              contactId={values.contactId}
              titleText="Capture card details for cancellation fee"
            />
          </>
        )}

      <FindATime
        calendlyUrl={
          showPropertyTrustFactFindBookingButton
            ? propertyTrustFactFindCalendlyUrl
            : (calendlyUrl as string)
        }
        handleTrustFactFind={handleTrustFactFind}
        showPropertyTrustFactFindBookingButton={
          showPropertyTrustFactFindBookingButton
        }
        setShowPropertyTrustFactFindBookingButton={
          setShowPropertyTrustFactFindBookingButton
        }
      />
    </QuoteForm>
  )
}

const WillForm = ({ attributes, disabled }: WillFormProps) => {
  const formattedValues = formatValuesForFormik(
    formatAttributesForForm(attributes)
  )
  const initialValues = merge({}, BLANK_VALUES, formattedValues)
  const { onSubmit } = useLeadContext()

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={WILL_LEAD_SCHEMA}
      validateOnMount
      validateOnChange={false}
    >
      <InnerForm disabled={disabled} />
    </Formik>
  )
}

export default WillForm
