import { ReactElement, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { Image, P, Wrapper } from '@farewill/ui'
import { COLOR, GTR } from '@farewill/ui/tokens'

import ENV from 'config/environment'
import AIGeneratedNotesSummary from 'components/activity/ai-summary'
import AircallEvent from 'components/aircall-event'
import LeadEventItem from 'components/lead-event'
import { AircallEventAttributes } from 'lib/models/aircall-event'
import { Event } from 'lib/models/event'
import { LeadEvent } from 'lib/models/lead-event'
import { Lead } from 'lib/models/lead'
import {
  fetchLeadEvents,
  fetchContactEvents,
  fetchAircallEventsForCustomer,
} from 'state/actions'

const LeadEventWrapper = styled(Wrapper)`
  background: ${COLOR.WHITE};
  border: 1px solid ${COLOR.GREY.LIGHT};
  margin-bottom: ${GTR.M};
  padding: ${GTR.S};
  width: 100%;
`

const LoadingWrapper = styled(LeadEventWrapper)`
  padding: ${GTR.L};
`

/** Either a leadId or contactId will be passed to this component, which is the History tab for both Lead and Contact pages */
const LeadEventHistory = ({
  params,
  lead,
}: {
  params: { leadId?: string; contactId?: string }
  lead?: Lead
}): ReactElement => {
  const [isLoading, setIsLoading] = useState(true)

  const leadEvents = useSelector<{ leadEvents: LeadEvent[] }>(
    (state) => state.leadEvents
  )
  const contactEvents = useSelector<{ contactEvents: Event[] }>(
    (state) => state.contactEvents
  )
  const aircallEvents = useSelector<{
    aircallEvents: AircallEventAttributes[]
  }>((state) => state.aircallEvents)
  const dispatch = useDispatch()

  useEffect(() => {
    const fetchEvents = async () => {
      setIsLoading(true)
      // fetchLeadEvents can take either leadId or contactId
      await dispatch(fetchLeadEvents({ params }))
      // fetchContactEvents can only take contactId
      params.contactId && (await dispatch(fetchContactEvents({ params })))
      await dispatch(
        fetchAircallEventsForCustomer({
          contactId: params.contactId ? Number(params.contactId) : null,
          leadId: params.leadId ? Number(params.leadId) : null,
        })
      )
      setIsLoading(false)
    }
    fetchEvents()
  }, [dispatch, params])

  if (isLoading) {
    return (
      <LoadingWrapper centered>
        <p>Loading...</p>
      </LoadingWrapper>
    )
  }

  // leadEvents use attributes.happenedAt, contactEvents use attributes.updatedAt, aircallEvents use started_at * 1000
  const allEvents = [
    ...(leadEvents as LeadEvent[]),
    ...(contactEvents as Event[]),
    ...(aircallEvents as AircallEventAttributes[]),
  ].sort((a, b) => {
    let dateA: number | string = ''
    let dateB: number | string = ''

    if ((a as LeadEvent)?.attributes?.happenedAt) {
      dateA = (a as LeadEvent).attributes.happenedAt
    } else if ((a as Event)?.attributes?.updatedAt) {
      dateA = (a as Event).attributes.updatedAt
    } else if ((a as AircallEventAttributes)?.started_at) {
      dateA = (a as AircallEventAttributes).started_at * 1000
    }

    if ((b as LeadEvent)?.attributes?.happenedAt) {
      dateB = (b as LeadEvent).attributes.happenedAt
    } else if ((b as Event)?.attributes?.updatedAt) {
      dateB = (b as Event).attributes.updatedAt
    } else if ((b as AircallEventAttributes)?.started_at) {
      dateB = (b as AircallEventAttributes).started_at * 1000
    }

    return new Date(dateB).getTime() - new Date(dateA).getTime()
  })

  if (allEvents.length === 0) {
    return (
      <Wrapper centered>
        <Image path="illustrations/cropped/tortoise" width={80} />{' '}
        <P>No events recorded yet</P>
      </Wrapper>
    )
  }

  return (
    <>
      <Wrapper separator>
        {ENV.FF_AI_NOTES_ENABLED && lead && (
          <Wrapper margin={[0, 0, 'L']}>
            <AIGeneratedNotesSummary
              lead={lead}
              leadEvents={leadEvents as LeadEvent[]}
            />
          </Wrapper>
        )}
        {allEvents.map((event) =>
          'attributes' in event ? (
            <LeadEventItem event={event} key={event.id} />
          ) : (
            // aircallEvents do not have attributes
            <AircallEvent
              event={event}
              key={event.id}
              leadId={Number(params.leadId)}
            />
          )
        )}
      </Wrapper>
    </>
  )
}

export default LeadEventHistory
