import { Grid, P } from '@farewill/ui'
import { useFormikContext } from 'formik'
import { FormEvent, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import ContactEmailInput from 'components/form/contact-email-input'
import Input from 'components/form/input'
import SelectInput from 'components/form/select-input'
import { Selected } from 'components/form/select-input/types'
import MessageBox from 'components/message-box'
import { formatCurrency } from 'lib/formatting/currency'
import { ProbateLeadAttributes } from 'lib/models/lead'
import { LeadEventsResponseData } from 'lib/types'
import { SelectOptions } from 'lib/types/common'
import { fetchLeadEvents } from 'state/actions'
import {
  FAREWILL_PRODUCTS,
  LEAD_SOURCE_IDENTIFIER_OPTIONS,
  LEAD_SOURCE_TYPE_OPTIONS,
  LEAD_SOURCE_TYPES,
} from 'utils/enums'

import { useLeadContext } from '../context'
import PartnersAndDiscounts from '../partners-and-discounts'
import { showRelationshipToCustomerWithLegacy } from '../helpers'
import SectionHeading from './section-heading'

const KeyInfo = ({
  leadId,
  isComplete,
}: {
  leadId: number
  isComplete: boolean
}) => {
  const dispatch = useDispatch()

  const { values, handleSubmit, setFieldValue } =
    useFormikContext<ProbateLeadAttributes>()
  const { updateContact, lead } = useLeadContext()
  const [originalQuotedPrices, setOriginalQuotedPrices] = useState<{
    probateOnly: number | null
    estateAdmin: number | null
  }>({
    probateOnly: null,
    estateAdmin: null,
  })

  /* In order to show the quoted prices the customer was shown online, we check the quotedPrices saved on the 'contact_requested' lead event */
  useEffect(() => {
    const fetchEvents = async () => {
      const result = await dispatch(fetchLeadEvents({ params: { leadId } }))
      const events = (result as unknown as LeadEventsResponseData).value.data
        .data

      const requestedEvent = events.find(
        (event) => event.attributes.type === 'contact_requested'
      )

      if (!requestedEvent) return
      const quotedPrices = requestedEvent?.attributes?.metadata?.quotedPrices

      if (quotedPrices) {
        setOriginalQuotedPrices(quotedPrices)
      }
    }
    fetchEvents()
  }, [dispatch, leadId])

  const hasFuneralCaseId = !!(
    'funeralId' in lead.attributes.quoteAttributes &&
    lead.attributes.quoteAttributes.funeralId
  )

  const sourceIdentifierOptions: SelectOptions =
    LEAD_SOURCE_IDENTIFIER_OPTIONS[
      hasFuneralCaseId &&
      values.sourceType === LEAD_SOURCE_TYPES.internal_referral
        ? 'cross_sell'
        : (values.sourceType as keyof typeof LEAD_SOURCE_IDENTIFIER_OPTIONS)
    ]

  const resetSourceIdentifierAndSubmit = (value: {
    name: string
    value: Selected
  }) => {
    setFieldValue('sourceIdentifier', null)
    handleSubmit(value as unknown as FormEvent<HTMLFormElement>)
  }

  const isOnlineProspect = [
    'online_quote_probate',
    'online_quote_probate_callback',
  ].includes(values?.sourceIdentifier)

  return (
    <Grid style={{ gridAutoFlow: 'row' }}>
      {isOnlineProspect && (
        <Grid.Item margin={['M', 0]}>
          <MessageBox>
            <P>Customer came via the online quote flow.</P>
            {originalQuotedPrices.probateOnly !== null &&
            originalQuotedPrices.estateAdmin !== null ? (
              <P>
                Their online estimate was{' '}
                {formatCurrency({
                  showPence: false,
                  value: originalQuotedPrices.probateOnly,
                  valueInPence: true,
                })}{' '}
                for the grant of probate and{' '}
                {formatCurrency({
                  showPence: false,
                  value: originalQuotedPrices.estateAdmin,
                  valueInPence: true,
                })}{' '}
                for estate administration.
              </P>
            ) : (
              <P>
                They did not give enough information online to receive an
                estimate.
              </P>
            )}
          </MessageBox>
        </Grid.Item>
      )}
      <SectionHeading
        title="1. The caller"
        scrollId="keyInfo"
        isComplete={isComplete}
      />
      <Grid.Item spanFromL={2}>
        <Input
          label="Caller's title"
          name="contact.title"
          handleSave={updateContact}
        />
      </Grid.Item>
      <Grid.Item spanFromL={5}>
        <Input
          label="First + middle names"
          data-testid="firstNames"
          name="contact.firstName"
          handleSave={updateContact}
          allowCopyToClipboard
        />
      </Grid.Item>
      <Grid.Item spanFromL={5}>
        <Input
          label="Last name"
          data-testid="lastName"
          name="contact.lastName"
          handleSave={updateContact}
          allowCopyToClipboard
        />
      </Grid.Item>
      <Grid.Item spanFromL={6}>
        <ContactEmailInput
          inputName="contact.email"
          contactId={values.contact.id}
          hasRelatedAccount={!!values.contact.accountUuid}
          handleSave={async ({ name, value }) => {
            const updatedLeadResponse = await updateContact({ name, value })

            const updatedContact = updatedLeadResponse.data.attributes.contact

            /**
             * This means the email change has been recognised as already in use
             * and triggered the contact to be merged into an existing contact.
             * We need to update Formik's value for the contactId, otherwise it
             * will refer to the deleted contact.
             *
             * TODO: each product has its own version of this component so this
             * implementation is currently duplicated across all of them.
             */
            if (values.contact.id !== updatedContact.id) {
              setFieldValue('contactId', updatedContact.id)
              setFieldValue('contact', updatedContact)
            }
          }}
          allowCopyToClipboard
        />
      </Grid.Item>
      <Grid.Item spanFromL={6}>
        <Input
          label="Telephone number"
          name="contact.phoneNumber"
          handleSave={updateContact}
          allowCopyToClipboard
        />
      </Grid.Item>
      <Grid.Item spanFromL={6}>
        <SelectInput
          label="Lead Type"
          name="sourceType"
          handleSave={resetSourceIdentifierAndSubmit}
          options={LEAD_SOURCE_TYPE_OPTIONS}
        />
      </Grid.Item>

      {sourceIdentifierOptions && (
        <Grid.Item spanFromL={6}>
          <SelectInput
            label="Source"
            name="sourceIdentifier"
            options={sourceIdentifierOptions}
            handleSave={() => handleSubmit()}
          />
        </Grid.Item>
      )}
      <Grid.Item>
        <PartnersAndDiscounts product={FAREWILL_PRODUCTS.PROBATE} />
      </Grid.Item>
      <Grid.Item spanFromL={6}>
        <SelectInput
          label="The person who died is the caller’s..."
          name="quoteAttributes.relationshipToCustomer"
          options={showRelationshipToCustomerWithLegacy(
            values.quoteAttributes.relationshipToCustomer
          )}
          hint="E.g. if the caller’s father has died, choose Father"
          handleSave={() => handleSubmit()}
        />
      </Grid.Item>
      {values.quoteAttributes.relationshipToCustomer === 'other' && (
        <Grid.Item spanFromL={6}>
          <Input
            label="Other relationship"
            name="quoteAttributes.otherRelationshipDescription"
            handleSave={() => handleSubmit()}
          />
        </Grid.Item>
      )}
      <Grid.Item>
        <MessageBox>
          Tell the customer that we record all of our calls for training and
          quality purposes
        </MessageBox>
      </Grid.Item>
    </Grid>
  )
}

export default KeyInfo
