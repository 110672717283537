import PropTypes from 'prop-types'
import styled from 'styled-components'
import { HelpIcon } from '@farewill/ui'
import { COLOR, FONT, GTR } from '@farewill/ui/tokens'
import Tippy from '@tippy.js/react'

const StyledTooltip = styled(Tippy)`
  padding: 2px ${GTR.XXS};
  font-size: ${FONT.SIZE.XS};
`

const HelpNeededHand = ({ enableTooltip, currentHelper, size }) => {
  return enableTooltip ? (
    <>
      <StyledTooltip
        animation="fade"
        arrow={false}
        duration={150}
        distance={parseInt(GTR.XS)}
        appendTo={document.body}
        placement="top"
        content={currentHelper}
      >
        <span>
          <HelpIcon
            size={size}
            color={COLOR.WHITE}
            background={COLOR.ACCENT.SECONDARY}
          />
        </span>
      </StyledTooltip>
    </>
  ) : (
    <HelpIcon
      size={size}
      color={COLOR.WHITE}
      background={COLOR.ACCENT.SECONDARY}
    />
  )
}

HelpNeededHand.propTypes = {
  enableTooltip: PropTypes.bool,
  size: PropTypes.string,
  currentHelper: PropTypes.string,
}

HelpNeededHand.defaultProps = {
  enableTooltip: false,
  size: 'S',
  currentHelper: null,
}

export default HelpNeededHand
