import { Button, ButtonGroup } from '@farewill/ui'
import { useDispatch } from 'react-redux'
import mapValues from 'lodash/mapValues'
import { Formik, Form } from 'formik'
import { useQueryClient } from 'react-query'

import { hideModal } from 'state/actions'
import { Organisation, OrganisationAttributes } from 'lib/models/organisation'
import { formatValuesForFormik } from 'utils/helpers'
import { RESOURCES, useUpdateResource } from 'api'
import MessageBox from 'components/message-box'

import OrganisationFormFields from '../forms/organisation'

type Props = {
  config: { organisation: OrganisationAttributes; willId: number }
}

const EditOrganisationModal = ({
  config: { organisation, willId },
}: Props): React.ReactElement => {
  const cache = useQueryClient()
  const dispatch = useDispatch()
  const organisationMutation = useUpdateResource<Organisation>()

  const handleSubmit = async (values: OrganisationAttributes) => {
    const attributes = mapValues(values, (value) => value || null)
    await organisationMutation.mutateAsync(
      {
        id: organisation.id,
        attributes,
        resource: RESOURCES.ORGANISATIONS,
      },
      {
        onSuccess: () => {
          /* In the future we may want to invalidate other queries that contain organisation relations */
          if (willId) {
            const queryKey = [RESOURCES.GIFTS, `filter[willId]=${willId}`]
            cache.invalidateQueries(queryKey)
          }
        },
      }
    )
    dispatch(hideModal())
  }

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={formatValuesForFormik(organisation)}
    >
      <Form>
        <MessageBox warning margin={[0, 0, 'M']}>
          Editing these details will update all the instances in the fact find
          form.
        </MessageBox>
        <OrganisationFormFields />
        <ButtonGroup margin={['M', 0, 0]}>
          <Button.Primary type="submit">Save</Button.Primary>
          <Button.Underline
            stretch
            type="button"
            onClick={() => dispatch(hideModal())}
          >
            Close
          </Button.Underline>
        </ButtonGroup>
      </Form>
    </Formik>
  )
}

export default EditOrganisationModal
