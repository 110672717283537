import { useQueryClient } from 'react-query'

import { P, Button, Wrapper } from '@farewill/ui'
import { toast } from 'react-toastify'

import { formatDate } from 'lib/formatting/dates'
import { useCreateResource, RESOURCES } from 'api'
import { WillCase } from 'lib/models/will-case'

import SimpleTelephoneWillLoginButton from './login-button'

type Props = {
  onlineAccountCreatedAt: string | null
  externalUserId: string | null
  isPartner: boolean
  partnerUtmSource: string | null
  willCaseId: number
}

const OnlineAccount = ({
  onlineAccountCreatedAt,
  externalUserId,
  isPartner,
  partnerUtmSource,
  willCaseId,
}: Props): React.ReactElement => {
  const telephoneWillUserMutation = useCreateResource<WillCase>()
  const cache = useQueryClient()

  const createTelephoneWillUser = async () => {
    const userData = { willCaseId, utmSource: partnerUtmSource, isPartner }
    telephoneWillUserMutation.mutate(
      {
        attributes: userData,
        resource: RESOURCES.TELEPHONE_WILL_USERS,
      },
      {
        onSuccess: (data) => {
          cache.setQueryData([RESOURCES.WILL_CASES, willCaseId], data)
        },
      }
    )
    toast(`Success! A new account was created.`, {
      toastId: 'new-account-created',
    })
  }

  return (
    <Wrapper>
      {!externalUserId && (
        <Button.Secondary
          onClick={() => createTelephoneWillUser()}
          wide
          loading={telephoneWillUserMutation.isLoading}
        >
          Create account
        </Button.Secondary>
      )}
      {externalUserId && (
        <P>
          Account created {formatDate(onlineAccountCreatedAt)}.{' '}
          <SimpleTelephoneWillLoginButton externalUserId={externalUserId} />
        </P>
      )}
    </Wrapper>
  )
}

export default OnlineAccount
