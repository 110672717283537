import PropTypes from 'prop-types'
import { Grid, H, P } from '@farewill/ui'
import styled from 'styled-components'
import { GTR } from '@farewill/ui/tokens'
import { useFormikContext } from 'formik'
import { Link } from 'react-router-dom'

import Tooltip from 'components/tooltip'
import CircularRadioGroup from 'components/form/circular-radio-group'
import DateSplit from 'components/form/date-split'
import InputFloating from 'components/form/input-floating'
import NotesInput from 'components/notes-input'
import MessageBox from 'components/message-box'

import { NOT_ASKED_AND_UNSURE_OPTIONS } from 'utils/enums'

import { getVisibleFields } from './form-helpers'
import {
  SPOUSE_PROBATE_OBTAINED,
  SPOUSE_PROBATE_REGISTRY,
  SPOUSE_GRANT,
  SPOUSE_HAD_WILL,
  SPOUSE_LEFT_ALL_TO_DECEASED,
  SPOUSE_WILL_AVAILABLE,
  SPOUSE_DOMICILED_IN_UK_AT_DEATH,
  SPOUSE_ESTATE_TAX_EXEMPT,
  SPOUSE_HAD_JOINT_ASSETS_WITH_SOMEONE_ELSE,
  SPOUSE_MADE_NON_IHT_EXEMPT_GIFTS_OR_TRANSFERS,
  SPOUSE_HAD_AGRICULTURAL_OR_BUSINESS_RELIEF_DEDUCTED,
  SPOUSE_MADE_GIFTS_WITH_RESERVATION_OF_BENEFIT_TO_SOMEONE_ELSE,
  SPOUSE_BENEFITTED_FROM_TRUST,
  SPOUSE_ESTATE_NOTES,
} from './field-names'

const StyledLabel = styled.label`
  margin-bottom: ${GTR.S};
`

const PROBATE_TOOLTIP = (
  <span>
    If necessary we can search for probate{' '}
    <a
      href="https://probatesearch.service.gov.uk/#wills"
      target="_blank"
      rel="noopener noreferrer"
    >
      here
    </a>
    .
  </span>
)

const IHT_EXEMPT_TOOLTIP =
  'i.e. did everything pass to surviving spouse or charity?'

const GIFTS_WITH_RESERVATION_OF_BENEFIT_TOOLTIP =
  'For example, if they gave their house away but carried on living there without paying rent, or gave away a painting but kept it in their living room.'

const DOMICILED_AT_DEATH_TOOLTIP =
  'We cannot determine customer’s domicile status for them. For guidance you are domiciled in the country in which you have your permanent home, or in which you consider to be your ‘real’ home. For UK purposes, domicile is not the same as residence or nationality, although both may be important in determining domicile.'

const NilRateBandWarning = () => (
  <Grid.Item>
    <MessageBox warning>
      You must use form IHT400 to claim for a transfer of nil-rate band
    </MessageBox>
  </Grid.Item>
)

const Estate = ({ probateEstateId, saveProbateEstateField }) => {
  const { values } = useFormikContext()
  const {
    showSpouseGrantAndSpouseProbateRegistry,
    showSpouseLeftAllToDeceased,
    showSpouseWillAvailable,
  } = getVisibleFields({ values })

  return (
    <Grid data-scroll-id="3.2">
      <Grid.Item>
        <H size="S">3.2 The spouse’s estate</H>
      </Grid.Item>
      <Grid.Item>
        <StyledLabel>
          <Tooltip content={PROBATE_TOOLTIP}>Was probate obtained?</Tooltip>
        </StyledLabel>
        <CircularRadioGroup
          name={SPOUSE_PROBATE_OBTAINED}
          options={NOT_ASKED_AND_UNSURE_OPTIONS}
          handleSave={saveProbateEstateField}
          inline
        />
      </Grid.Item>
      {showSpouseGrantAndSpouseProbateRegistry && (
        <>
          <Grid.Item span={5}>
            <DateSplit
              name={SPOUSE_GRANT}
              label="Date of grant"
              handleSave={saveProbateEstateField}
            />
          </Grid.Item>
          <Grid.Item span={5} style={{ alignSelf: 'flex-end' }}>
            <InputFloating
              name={SPOUSE_PROBATE_REGISTRY}
              label="Probate registry"
              handleSave={saveProbateEstateField}
            />
          </Grid.Item>
        </>
      )}
      <Grid.Item>
        <CircularRadioGroup
          name={SPOUSE_HAD_WILL}
          label="Did the spouse have a will?"
          options={NOT_ASKED_AND_UNSURE_OPTIONS}
          handleSave={saveProbateEstateField}
          inline
        />
      </Grid.Item>
      {showSpouseLeftAllToDeceased && (
        <>
          <Grid.Item>
            <CircularRadioGroup
              name={SPOUSE_LEFT_ALL_TO_DECEASED}
              label="Does the spouse’s will leave everything to the person who’s died?"
              options={NOT_ASKED_AND_UNSURE_OPTIONS}
              handleSave={saveProbateEstateField}
              inline
            />
          </Grid.Item>
          {values[SPOUSE_LEFT_ALL_TO_DECEASED] === 'no' && (
            <NilRateBandWarning />
          )}
        </>
      )}
      {showSpouseWillAvailable && (
        <Grid.Item>
          <CircularRadioGroup
            name={SPOUSE_WILL_AVAILABLE}
            label="Is a copy of their will available?"
            options={[
              { label: 'Not asked', value: '' },
              { label: 'Yes (ask for a copy)', value: 'yes' },
              { label: 'No', value: 'no' },
              { label: 'They’re unsure', value: 'unsure' },
            ]}
            handleSave={saveProbateEstateField}
            inline
          />
        </Grid.Item>
      )}
      <Grid.Item>
        <StyledLabel>
          Were they{' '}
          <Tooltip content={DOMICILED_AT_DEATH_TOOLTIP}>domiciled</Tooltip> in
          the UK at their death?
        </StyledLabel>
        <CircularRadioGroup
          name={SPOUSE_DOMICILED_IN_UK_AT_DEATH}
          options={NOT_ASKED_AND_UNSURE_OPTIONS}
          handleSave={saveProbateEstateField}
          inline
        />
      </Grid.Item>
      <Grid.Item>
        <StyledLabel>
          Was their estate{' '}
          <Tooltip content={IHT_EXEMPT_TOOLTIP}>
            entirely exempt from IHT?
          </Tooltip>
        </StyledLabel>
        <CircularRadioGroup
          name={SPOUSE_ESTATE_TAX_EXEMPT}
          options={NOT_ASKED_AND_UNSURE_OPTIONS}
          handleSave={saveProbateEstateField}
          inline
        />
      </Grid.Item>
      <Grid.Item>
        <CircularRadioGroup
          name={SPOUSE_HAD_JOINT_ASSETS_WITH_SOMEONE_ELSE}
          label="Did they have joint assets with someone other than the surviving spouse?"
          options={NOT_ASKED_AND_UNSURE_OPTIONS}
          handleSave={saveProbateEstateField}
          inline
        />
      </Grid.Item>
      {values[SPOUSE_HAD_JOINT_ASSETS_WITH_SOMEONE_ELSE] === 'yes' && (
        <NilRateBandWarning />
      )}
      <Grid.Item>
        <CircularRadioGroup
          name={SPOUSE_MADE_NON_IHT_EXEMPT_GIFTS_OR_TRANSFERS}
          label="Did they make gifts/transfers in 7 years before they died that weren’t IHT-exempt?"
          options={NOT_ASKED_AND_UNSURE_OPTIONS}
          handleSave={saveProbateEstateField}
          inline
        />
      </Grid.Item>
      {values[SPOUSE_MADE_NON_IHT_EXEMPT_GIFTS_OR_TRANSFERS] === 'yes' && (
        <NilRateBandWarning />
      )}
      <Grid.Item>
        <CircularRadioGroup
          name={SPOUSE_HAD_AGRICULTURAL_OR_BUSINESS_RELIEF_DEDUCTED}
          label="Was agricultural or business relief deducted from their estate?"
          options={NOT_ASKED_AND_UNSURE_OPTIONS}
          handleSave={saveProbateEstateField}
          inline
        />
      </Grid.Item>
      {values[SPOUSE_HAD_AGRICULTURAL_OR_BUSINESS_RELIEF_DEDUCTED] ===
        'yes' && <NilRateBandWarning />}
      <Grid.Item>
        <StyledLabel>
          Did they make{' '}
          <Tooltip content={GIFTS_WITH_RESERVATION_OF_BENEFIT_TOOLTIP}>
            gifts with reservation of benefit
          </Tooltip>{' '}
          other than to surviving spouse?
        </StyledLabel>
        <CircularRadioGroup
          name={SPOUSE_MADE_GIFTS_WITH_RESERVATION_OF_BENEFIT_TO_SOMEONE_ELSE}
          options={NOT_ASKED_AND_UNSURE_OPTIONS}
          handleSave={saveProbateEstateField}
          inline
        />
      </Grid.Item>
      {values[SPOUSE_MADE_GIFTS_WITH_RESERVATION_OF_BENEFIT_TO_SOMEONE_ELSE] ===
        'yes' && <NilRateBandWarning />}
      <Grid.Item>
        <CircularRadioGroup
          name={SPOUSE_BENEFITTED_FROM_TRUST}
          label="Did they benefit from a trust during their lifetime?"
          options={NOT_ASKED_AND_UNSURE_OPTIONS}
          handleSave={saveProbateEstateField}
          inline
        />
      </Grid.Item>
      {values[SPOUSE_BENEFITTED_FROM_TRUST] === 'yes' && <NilRateBandWarning />}
      <Grid.Item>
        <P size="S">
          Remember to complete the{' '}
          <Link to={`/probate/estates/${probateEstateId}/internal-checks`}>
            internal transfer of nil-rate band checklist
          </Link>
        </P>
      </Grid.Item>
      <Grid.Item>
        <NotesInput
          name={SPOUSE_ESTATE_NOTES}
          handleSave={saveProbateEstateField}
        />
      </Grid.Item>
    </Grid>
  )
}

Estate.propTypes = {
  probateEstateId: PropTypes.string.isRequired,
  saveProbateEstateField: PropTypes.func.isRequired,
}

export default Estate
