import get from 'lodash/get'

import { strToBool } from 'utils/helpers'
import namespace from 'lib/formik/namespace'

import { NONE, LASTING_POWER_OF_ATTORNEY } from 'utils/enums'

import {
  IS_CUSTOMER,
  IS_POINT_OF_CONTACT,
  IS_PERSONAL_REPRESENTATIVE,
  IS_INCLUDED_IN_COMMS,
  NAME_MATCHES_WILL,
  ADDRESS_MATCHES_WILL,
  PR_ROLE,
  LAST_PERMANENT_ADDRESS_MATCHES_WILL,
  MARITAL_STATUS,
  DECEASED_RELATIONSHIP_TO_PERSON,
  IS_ACTING_ON_BEHALF_OF_SOMEONE_ELSE,
  IS_ACTING_ON_BEHALF_OF_SOMEONE_WITH_CAPACITY_TO_ACT,
  POWER_OF_ATTORNEY_REGISTERED,
  DECEASED_CHILDREN_OVER_EIGHTEEN,
  DECEASED_CHILDREN_UNDER_EIGHTEEN,
  POWER_OF_ATTORNEY_OR_ORDER_TYPE,
} from './field-names'

import { MARRIED, WIDOWED, DIVORCED, JUDICIALLY_SEPARATED } from 'utils/enums'

export const getPersonVisibleFields = ({ hasWill, prefix, values }) => {
  const { withNamespace } = namespace(prefix)

  const isCustomer = strToBool(get(values, withNamespace(IS_CUSTOMER)))
  const isPointOfContact = strToBool(
    get(values, withNamespace(IS_POINT_OF_CONTACT))
  )
  const isPersonalRepresentative = strToBool(
    get(values, withNamespace(IS_PERSONAL_REPRESENTATIVE))
  )
  const isIncludedInComms = strToBool(
    get(values, withNamespace(IS_INCLUDED_IN_COMMS))
  )
  const personalRepresentativeRole = get(values, withNamespace(PR_ROLE))
  const isActingOnBehalfOfSomeoneWithCapacityToAct = get(
    values,
    withNamespace(IS_ACTING_ON_BEHALF_OF_SOMEONE_WITH_CAPACITY_TO_ACT)
  )
  const powerOfAttorneyOrOrderType = get(
    values,
    withNamespace(POWER_OF_ATTORNEY_OR_ORDER_TYPE)
  )
  const powerOfAttorneyRegistered = get(
    values,
    withNamespace(POWER_OF_ATTORNEY_REGISTERED)
  )

  const showCustomDeceasedRelationshipToPerson =
    get(values, withNamespace(DECEASED_RELATIONSHIP_TO_PERSON)) === 'other'

  const showAddressMismatchNotes =
    get(values, withNamespace(ADDRESS_MATCHES_WILL)) === 'false'

  const showAuthorizedToDealWithCase =
    isPointOfContact && !isCustomer && !isPersonalRepresentative

  const showAuthorizedToBeIncludedInComms =
    isIncludedInComms &&
    !isPointOfContact &&
    !isCustomer &&
    !isPersonalRepresentative

  const showDateOfBirth = isPointOfContact || isCustomer

  const showNameAndAddressMatchWill =
    strToBool(hasWill) && isPersonalRepresentative

  const showNameMismatchNotes =
    get(values, withNamespace(NAME_MATCHES_WILL)) === 'false'

  const showRoleAndIsInsolvent = isPersonalRepresentative

  const showDeceasedRoleWarning = personalRepresentativeRole === 'dead'

  const showCustomerRoleWarning =
    isCustomer &&
    personalRepresentativeRole &&
    personalRepresentativeRole !== 'acting'

  const showNonCustomerRoleWarning =
    !isCustomer && personalRepresentativeRole === 'acting'

  const showIsActingOnBehalfOfSomeoneElse =
    !personalRepresentativeRole || personalRepresentativeRole === 'acting'

  const showIsActingOnBehalfOfSomeoneWithCapacity =
    showIsActingOnBehalfOfSomeoneElse &&
    get(values, withNamespace(IS_ACTING_ON_BEHALF_OF_SOMEONE_ELSE)) === 'true'

  const showCapacityWarning =
    showIsActingOnBehalfOfSomeoneWithCapacity &&
    isActingOnBehalfOfSomeoneWithCapacityToAct === 'true'

  const showPowerOfAttorneyOrOrderType =
    showIsActingOnBehalfOfSomeoneWithCapacity &&
    isActingOnBehalfOfSomeoneWithCapacityToAct === 'false'

  const showNoPowerOfAttorneyOrOrderWarning =
    showPowerOfAttorneyOrOrderType && powerOfAttorneyOrOrderType === NONE

  const showPowerOfAttorneyRegistered =
    showPowerOfAttorneyOrOrderType &&
    powerOfAttorneyOrOrderType &&
    powerOfAttorneyOrOrderType !== NONE

  const showRegisteredPowerOfAttorneyInfo =
    showPowerOfAttorneyRegistered &&
    powerOfAttorneyOrOrderType === LASTING_POWER_OF_ATTORNEY

  const showNotRegisteredWarning =
    showPowerOfAttorneyRegistered && powerOfAttorneyRegistered === 'false'

  const showRegisteredInfo =
    showPowerOfAttorneyRegistered && powerOfAttorneyRegistered === 'true'

  const showApplyingOnBehalfOf = showIsActingOnBehalfOfSomeoneWithCapacity

  return {
    showCustomDeceasedRelationshipToPerson,
    showAddressMismatchNotes,
    showAuthorizedToDealWithCase,
    showAuthorizedToBeIncludedInComms,
    showDateOfBirth,
    showNameAndAddressMatchWill,
    showNameMismatchNotes,
    showRoleAndIsInsolvent,
    showDeceasedRoleWarning,
    showCustomerRoleWarning,
    showNonCustomerRoleWarning,
    showIsActingOnBehalfOfSomeoneElse,
    showIsActingOnBehalfOfSomeoneWithCapacity,
    showCapacityWarning,
    showPowerOfAttorneyOrOrderType,
    showNoPowerOfAttorneyOrOrderWarning,
    showPowerOfAttorneyRegistered,
    showRegisteredPowerOfAttorneyInfo,
    showNotRegisteredWarning,
    showRegisteredInfo,
    showApplyingOnBehalfOf,
  }
}

export const getEstateVisibleFields = ({ values }) => {
  const isMarried = values[MARITAL_STATUS] === MARRIED

  const isWidowed = values[MARITAL_STATUS] === WIDOWED

  const isDivorced = values[MARITAL_STATUS] === DIVORCED

  const isJudiciallySeparated = values[MARITAL_STATUS] === JUDICIALLY_SEPARATED

  const showLastPermanentAddressMatchesWill = strToBool(values.hasWill)

  const showLastPermanentAddressMismatchNotes =
    values[LAST_PERMANENT_ADDRESS_MATCHES_WILL] === 'false'

  const showSpouseName = isMarried

  const showMarriageDate = isMarried && strToBool(values.hasWill)

  const showSpouseDateOfDeath = isWidowed

  const showSpouseIsPermanentUkResident = isMarried

  const showMaritalDocumentsRequested = isMarried || isWidowed || isDivorced

  const showMarriageCertificateRequested = isMarried || isWidowed

  const showDivorceAbsoluteRequested = isDivorced

  const showDecreeIssuedByAndDateOfSeperation =
    isDivorced || isJudiciallySeparated

  return {
    showLastPermanentAddressMatchesWill,
    showLastPermanentAddressMismatchNotes,
    showSpouseName,
    showMarriageDate,
    showSpouseDateOfDeath,
    showSpouseIsPermanentUkResident,
    showMaritalDocumentsRequested,
    showMarriageCertificateRequested,
    showDivorceAbsoluteRequested,
    showDecreeIssuedByAndDateOfSeperation,
  }
}

export const getEstateChildrenVisibleFields = ({ values }) => {
  const showChildrenSection = strToBool(values.hasWill)
  const showGrandchildrenWithDeceasedParent =
    parseInt(values[DECEASED_CHILDREN_UNDER_EIGHTEEN]) > 0 ||
    parseInt(values[DECEASED_CHILDREN_OVER_EIGHTEEN]) > 0

  return {
    showChildrenSection,
    showGrandchildrenWithDeceasedParent,
  }
}
