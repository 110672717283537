import { Formik, Form } from 'formik'
import { H, Wrapper, P, Divider } from '@farewill/ui'

import ENV from 'config/environment'
import { useFetchResource, useUpdateResource, RESOURCES } from 'api'
import CasePrioritySwitch from 'components/case-priority-switch'
import CriticalNotes from 'components/critical-notes'
import RequestStatus from 'components/request-status'
import SimpleList from 'components/styled/simple-list'
import { WillCase } from 'lib/models/will-case'
import { getPermanentResidence, getTemporaryResidence } from 'lib/will/lead'
import { getCountryLabelFromValue } from 'routes/lead/will-form/helpers/form-utils'
import { formatValuesForFormik, strToBool } from 'utils/helpers'

import CaseOwner from './case-owner'
import CaseStatus from './case-status'
import CustomerPreferences from './customer-preferences'
import Drafting from './drafting'
import FactFindCalls from './fact-find-calls'
import { getComplexityAndType, getWishTexts } from './helpers'
import LPACrossSell from './lpa-crosssell'

type UpdateWillCaseAttributes = {
  notes?: string | null
  isUrgent?: boolean
  urgentNotes?: string | null
  caseOwnerId?: number | null
  holidayCoverOwnerId?: number | null
  criticalNotes?: string | null
  deliveryMethodDraft?: 'post' | 'email'
  deliveryMethodFinal?: 'post' | 'email'
  drafterId?: number | null
  timeSpentDrafting?: number | null
  grade?: string | null
  lpaCheckAttributes: Record<string, string>
}

const CaseDetails = ({ id }: { id: number }) => {
  const willCaseQuery = useFetchResource({
    id,
    resource: RESOURCES.WILL_CASES,
  }) as { data: WillCase; isLoading: boolean; isIdle: boolean }
  const willCaseMutation = useUpdateResource()

  const handleSubmit = (values: UpdateWillCaseAttributes) => {
    const attributes = {
      isUrgent: strToBool(values.isUrgent),
      urgentNotes: values.urgentNotes || null,
      caseOwnerId: values.caseOwnerId || null,
      holidayCoverOwnerId: values.holidayCoverOwnerId || null,
      criticalNotes: values.criticalNotes || null,
      deliveryMethodDraft: values.deliveryMethodDraft,
      deliveryMethodFinal: values.deliveryMethodFinal,
      drafterId: values.drafterId || null,
      timeSpentDrafting: values.timeSpentDrafting || null,
      lpaCheckAttributes: values.lpaCheckAttributes || {},
    }
    willCaseMutation.mutate({
      id,
      attributes,
      resource: RESOURCES.WILL_CASES,
    })
  }

  if (willCaseQuery.isLoading || willCaseQuery.isIdle)
    return <P size="L">Loading...</P>

  if (!willCaseQuery.data) return <P size="L">There is no data to display.</P>

  const willCase = willCaseQuery.data.attributes
  const { lead } = willCase

  const initialValues = formatValuesForFormik(willCase)
  const complexityAndType = getComplexityAndType(willCase)
  const permanentResidence = getPermanentResidence(lead.quoteAttributes)
  const temporaryResidence = getTemporaryResidence(lead.quoteAttributes)
  const wishes = getWishTexts(lead)

  return (
    <Wrapper>
      <H size="M">Case details</H>
      {complexityAndType}
      {lead.partnerType === 'charity' && (
        <P>Charity partner: {lead.partnerUtmSource} (free will)</P>
      )}
      {lead.partnerType === 'other_partner' && (
        <P>Other partner: {lead.partnerUtmSource} (discounted will)</P>
      )}
      {permanentResidence && (
        <P>
          Permanent residence: {getCountryLabelFromValue(permanentResidence)}{' '}
          {temporaryResidence &&
            `(Temporary residence: ${getCountryLabelFromValue(
              temporaryResidence
            )})`}
        </P>
      )}
      {wishes.length > 0 && (
        <>
          <P>Wishes:</P>
          <SimpleList bullets>
            {wishes.map((wish, index) => (
              <li key={index}>{wish}</li>
            ))}
          </SimpleList>
        </>
      )}
      <P>
        Will in large print:{' '}
        {lead.quoteAttributes.wantsLargePrint ? 'Yes' : 'No'}
      </P>
      <Formik onSubmit={handleSubmit} initialValues={initialValues}>
        <Form>
          <RequestStatus />
          <CriticalNotes />
          <Divider margin={['L', 0]} />
          <CasePrioritySwitch
            label="Urgent case"
            keyName="isUrgent"
            notesLabel="Reasons for urgency"
            notesKeyName="urgentNotes"
          />
          <Divider margin={['L', 0]} />
          <CaseOwner />
          <Divider margin={['L', 0]} />
          <FactFindCalls willCase={willCaseQuery.data} />
          <Divider margin={['L', 0]} />
          <CustomerPreferences id={willCaseQuery.data.id} />
          {ENV.FF_LPAS_ENABLED && (
            <>
              <Divider margin={['L', 0]} />
              <LPACrossSell id={willCaseQuery.data.id} />
            </>
          )}
          <Divider margin={['L', 0]} />
          <Drafting />
          <Divider margin={['L', 0]} />
          <CaseStatus id={willCaseQuery.data.id} />
        </Form>
      </Formik>
    </Wrapper>
  )
}

export default CaseDetails
