import PropTypes from 'prop-types'
import { Divider, H, Grid } from '@farewill/ui'

import withForm from '../components/with-form'
import ReservationOfBenefit from './reservation-of-benefit'
import Trusts from './trusts'
import InsurancePremiums from './insurance-premiums'
import Grants from './grants'
import GiftsGiven from './gifts-given'
import NotesNotCoveredElsewhere from './notes-not-covered-elsewhere'

const GiftsAndOther = ({
  probateEstateId,
  saveProbateEstateField,
  estateItems,
  leadId,
}) => (
  <Grid gap="XL" data-scroll-id="6">
    <Grid.Item span={8}>
      <H size="M">6. Gifts and other</H>
      <ReservationOfBenefit saveProbateEstateField={saveProbateEstateField} />
      <Divider margin={['L', 0]} />
      <GiftsGiven
        initialItems={estateItems.giftGiven}
        probateEstateId={probateEstateId}
        saveProbateEstateField={saveProbateEstateField}
        leadId={leadId}
      />
      <Divider margin={['L', 0]} />
      <Trusts saveProbateEstateField={saveProbateEstateField} />
      <Divider margin={['S', 0, 'L']} />
      <InsurancePremiums saveProbateEstateField={saveProbateEstateField} />
      <Divider margin={['S', 0, 'L']} />
      <Grants saveProbateEstateField={saveProbateEstateField} />
      <Divider margin={['L', 0]} />
      <NotesNotCoveredElsewhere
        saveProbateEstateField={saveProbateEstateField}
      />
    </Grid.Item>
  </Grid>
)

GiftsAndOther.propTypes = {
  saveProbateEstateField: PropTypes.func.isRequired,
}

export default withForm(GiftsAndOther)
