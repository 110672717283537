import { useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import every from 'lodash/every'
import styled from 'styled-components'
import { P, Wrapper } from '@farewill/ui'
import { BORDER, COLOR, FONT, GTR } from '@farewill/ui/tokens'
import { useDropzone } from 'react-dropzone'

const StyledDropZone = styled.div`
  border-color: ${(props) =>
    props.isValid ? COLOR.GREY.LIGHT : COLOR.STATE.ERROR};
  border-radius: ${BORDER.RADIUS.S};
  border-style: dashed;
  border-width: 2px;
  cursor: pointer;
  margin: ${GTR.XL} 0;
  outline: none;
  padding: ${GTR.XL};
`

const StyledUploadHeader = styled(P)`
  font-weight: ${FONT.WEIGHT.BOLD};
  font-size: ${FONT.SIZE.L};
`

const StyledBrowseLink = styled(P)`
  text-decoration: underline;
`

const StyledAttachFromOverlay = styled.div`
  border: 3px solid ${COLOR.GREY.LIGHT};
  background-color: ${COLOR.WHITE};
  box-shadow: ${BORDER.SHADOW.M};
  border-radius: 0 0 ${BORDER.RADIUS.S} ${BORDER.RADIUS.S};
  padding: 0;
  min-width: 240px;
  display: block;
  position: fixed;
  overflow: auto;
  transform: translate(-25%, ${GTR.M});
`

const StyledBrowseHeader = styled(P)`
  font-weight: ${FONT.WEIGHT.BOLD};
  text-transform: uppercase;
  font-size: ${FONT.SIZE.XS};
  letter-spacing: 1.2px;
  color: ${COLOR.GREY.DARK};
  padding: ${GTR.XS};
  margin-bottom: 0;
`

const StyledPickerOption = styled(P)`
  cursor: pointer;
  margin: 0;
  padding: ${GTR.XS} ${GTR.S};
  border-top: 2px solid ${COLOR.GREY.LIGHT};
  text-align: left;
`

const AttachFromOverlay = ({
  overlayRef,
  openSystemFilePrompt,
  setGoogleDriveMode,
  close,
  style,
}) => {
  const handleSetGoogleDriveMode = () => {
    setGoogleDriveMode(true)
    close()
  }
  return (
    <StyledAttachFromOverlay ref={overlayRef} style={style}>
      <StyledBrowseHeader>Attach From&hellip;</StyledBrowseHeader>
      <StyledPickerOption onClick={openSystemFilePrompt}>
        Computer
      </StyledPickerOption>
      <StyledPickerOption onClick={handleSetGoogleDriveMode}>
        Google Drive
      </StyledPickerOption>
    </StyledAttachFromOverlay>
  )
}

const allImages = (files) =>
  every(files, (file) => file.type.startsWith('image/'))

const isValidSelection = (files) => files.length === 1 || allImages(files)

const DropZone = ({ onDrop, setGoogleDriveMode }) => {
  const [overlayPosition, setOverlayPosition] = useState(null)
  const isShowingOverlay = !!overlayPosition
  const overlayRef = useRef(null)

  const handleDrop = (files) => {
    if (isValidSelection(files)) {
      onDrop(files)
    }
  }

  const handleDropzoneClick = (e) => {
    setOverlayPosition({ top: e.pageY - window.scrollY, left: e.pageX })
  }

  const { getRootProps, getInputProps, isDragActive, draggedFiles, open } =
    useDropzone({
      onDrop: handleDrop,
      noKeyboard: true,
      noClick: true,
    })

  const isValidDrag = isValidSelection(draggedFiles)

  useEffect(() => {
    const handleCloseClick = (e) => {
      if (
        isShowingOverlay &&
        overlayRef?.current &&
        !overlayRef.current.contains(e.target)
      ) {
        setOverlayPosition(null)
      }
    }

    document.addEventListener('click', handleCloseClick)
    return () => document.removeEventListener('click', handleCloseClick)
  }, [overlayRef, isShowingOverlay])

  return (
    <>
      <StyledDropZone
        {...getRootProps({ isDragActive })}
        isValid={isValidDrag}
        onClick={handleDropzoneClick}
      >
        <input {...getInputProps()} />
        <Wrapper centered>
          <StyledUploadHeader>
            {!isDragActive &&
              'Drag and drop files here to attach a new document'}

            {isDragActive &&
              (isValidDrag
                ? 'Drop the files here to attach'
                : 'All files must be images')}
          </StyledUploadHeader>
          <StyledBrowseLink>Or click to browse for files</StyledBrowseLink>
        </Wrapper>
      </StyledDropZone>
      {isShowingOverlay && (
        <AttachFromOverlay
          style={{
            left: overlayPosition.left,
            top: overlayPosition.top,
          }}
          overlayRef={overlayRef}
          openSystemFilePrompt={open}
          setGoogleDriveMode={setGoogleDriveMode}
          close={() => setOverlayPosition(null)}
        />
      )}
    </>
  )
}

DropZone.propTypes = {
  onDrop: PropTypes.func.isRequired,
}

export default DropZone
