import { Grid, H, P } from '@farewill/ui'

import withForm from '../fact-find/components/with-form'
import CircularRadioGroup from 'components/form/circular-radio-group'
import Input from 'components/form/input'

const DocumentsPrepared = ({ saveProbateCaseField }) => {
  return (
    <Grid margin={['XL', 0]}>
      <Grid.Item>
        <H size="S">Documents prepared for customer</H>
      </Grid.Item>
      <Grid.Item>
        <CircularRadioGroup
          name="applicationTypePrepared"
          label="Type of probate application prepared"
          options={[
            { value: '', label: 'Not specified' },
            { value: 'form_pa1', label: 'Form PA1' },
            { value: 'online', label: 'Online' },
            { value: 'statement_of_truth', label: 'Statement of truth' },
          ]}
          handleSave={saveProbateCaseField}
          inline
        />
      </Grid.Item>
      <Grid.Item margin={['L', 0, 0, 0]}>
        <P>Other documents (specify number)</P>
      </Grid.Item>
      <Grid.Item span={3}>
        <Input
          label="IHT205"
          name="totalIht205"
          type="number"
          handleSave={saveProbateCaseField}
        />
      </Grid.Item>
      <Grid.Item span={3}>
        <Input
          label="IHT217"
          name="totalIht217"
          type="number"
          handleSave={saveProbateCaseField}
        />
      </Grid.Item>
      <Grid.Item span={3}>
        <Input
          label="IHT400"
          name="totalIht400"
          type="number"
          handleSave={saveProbateCaseField}
        />
      </Grid.Item>
      <Grid.Item span={3}>
        <Input
          label="IHT400 schedules"
          name="totalIht400Schedules"
          type="number"
          handleSave={saveProbateCaseField}
        />
      </Grid.Item>
      <Grid.Item span={3}>
        <Input
          label="Notices to PRs"
          name="totalNoticesToPrs"
          type="number"
          handleSave={saveProbateCaseField}
        />
      </Grid.Item>
      <Grid.Item span={3}>
        <Input
          label="Renunciations"
          name="totalRedunciations"
          type="number"
          handleSave={saveProbateCaseField}
        />
      </Grid.Item>
      <Grid.Item span={3}>
        <Input
          label="Affidavits"
          name="totalAffidavits"
          type="number"
          handleSave={saveProbateCaseField}
        />
      </Grid.Item>
      <Grid.Item span={3}>
        <Input
          label="Other (see notes)"
          name="totalOtherDocuments"
          type="number"
          handleSave={saveProbateCaseField}
        />
      </Grid.Item>
    </Grid>
  )
}

export default withForm(DocumentsPrepared)
