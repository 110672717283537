import PropTypes from 'prop-types'
import { useFormikContext } from 'formik'
import { Grid, P } from '@farewill/ui'
import { Link } from 'react-router-dom'
import lowerCase from 'lodash/lowerCase'

import { CONFIRMED_AND_CHECKED_OPTIONS, WIDOWED } from 'utils/enums'
import { getMomentDate } from 'lib/formik/dates'

import CircularRadioGroup from 'components/form/circular-radio-group'
import InputFloating from 'components/form/input-floating'
import PinkBox from 'components/styled/pink-box'
import IHT400Warning from './iht-400-warning'
import MessageBox from 'components/message-box'

import { formatDate, getDateIsOnOrAfter19741113 } from '../../helpers'

import {
  MARITAL_STATUS,
  SPOUSE_DEATH,
} from '../../fact-find/people/field-names'
import { SPOUSE_DOMICILED_IN_UK_AT_DEATH } from '../../fact-find/spouses-estate/field-names'

import {
  CONFIRMED_HAD_PREDECEASED_SPOUSE,
  CONFIRMED_HAD_PREDECEASED_SPOUSE_NOTES,
  CONFIRMED_SPOUSE_DOMICILED_IN_UK_AT_DEATH,
  CONFIRMED_SPOUSE_DOMICILED_IN_UK_AT_DEATH_NOTES,
  CONFIRMED_SPOUSE_DEATH_ON_OF_AFTER_19741113,
  CONFIRMED_SPOUSE_DEATH_ON_OF_AFTER_19741113_NOTES,
} from '../field-names'

const SpouseChecks = ({ factFindUrl, saveProbateEstateField }) => {
  const { values } = useFormikContext()

  const maritalStatus = values[MARITAL_STATUS]
  const isWidowed = maritalStatus === WIDOWED
  const spouseDomiciledInUkAtDeath = values[SPOUSE_DOMICILED_IN_UK_AT_DEATH]
  const spouseDateOfDeath = getMomentDate({ prefix: SPOUSE_DEATH, values })

  if (!spouseDateOfDeath) {
    return (
      <MessageBox warning>
        Spouse’s date of death is missing or incomplete - please update before
        continuing (<Link to={factFindUrl + '#3.1'}>3.1</Link>)
      </MessageBox>
    )
  }

  const spouseDiedOnOrAfter19741113 =
    getDateIsOnOrAfter19741113(spouseDateOfDeath)

  return (
    <>
      <Grid gap={['S', 'M']} margin={[0, 0, 'L']}>
        <Grid.Item>
          <P strong>Did they have a spouse who died before them?</P>
          <PinkBox stretch>
            According to fact find, <strong>{isWidowed ? 'yes' : 'no'}</strong>{' '}
            - their marital status is {lowerCase(maritalStatus)} (
            <Link to={factFindUrl + '#1.4'}>1.4</Link>)
          </PinkBox>
        </Grid.Item>
        <Grid.Item>
          <CircularRadioGroup
            name={CONFIRMED_HAD_PREDECEASED_SPOUSE}
            options={CONFIRMED_AND_CHECKED_OPTIONS}
            handleSave={saveProbateEstateField}
            inline
          />
        </Grid.Item>
        <Grid.Item span={9}>
          <InputFloating
            name={CONFIRMED_HAD_PREDECEASED_SPOUSE_NOTES}
            label="How have you confirmed this?"
            handleSave={saveProbateEstateField}
          />
        </Grid.Item>
      </Grid>
      <Grid gap={['S', 'M']} margin={[0, 0, 'L']}>
        <Grid.Item>
          <P strong>Was the spouse resident in the UK?</P>
          <PinkBox stretch>
            According to fact find,{' '}
            <strong>
              {spouseDomiciledInUkAtDeath || <i>not specified</i>}
            </strong>{' '}
            (<Link to={factFindUrl + '#3.2'}>3.2</Link>)
          </PinkBox>
        </Grid.Item>
        <Grid.Item>
          <CircularRadioGroup
            name={CONFIRMED_SPOUSE_DOMICILED_IN_UK_AT_DEATH}
            options={CONFIRMED_AND_CHECKED_OPTIONS}
            handleSave={saveProbateEstateField}
            inline
          />
        </Grid.Item>
        <Grid.Item span={9}>
          <InputFloating
            name={CONFIRMED_SPOUSE_DOMICILED_IN_UK_AT_DEATH_NOTES}
            label="How have you confirmed this?"
            handleSave={saveProbateEstateField}
          />
        </Grid.Item>
        {spouseDomiciledInUkAtDeath !== 'yes' && (
          <Grid.Item span={9}>
            <IHT400Warning />
          </Grid.Item>
        )}
      </Grid>
      <Grid gap={['S', 'M']} margin={[0, 0, 'L']}>
        <Grid.Item>
          <P strong>Did the spouse die on or after 13 Nov 1974?</P>
          <PinkBox stretch>
            According to fact find,{' '}
            <strong>{spouseDiedOnOrAfter19741113 ? 'yes' : 'no'}</strong> - the
            spouse died on {formatDate(spouseDateOfDeath)} (
            <Link to={factFindUrl + '#3.1'}>3.1</Link>)
          </PinkBox>
        </Grid.Item>
        <Grid.Item>
          <CircularRadioGroup
            name={CONFIRMED_SPOUSE_DEATH_ON_OF_AFTER_19741113}
            options={CONFIRMED_AND_CHECKED_OPTIONS}
            handleSave={saveProbateEstateField}
            inline
          />
        </Grid.Item>
        <Grid.Item span={9}>
          <InputFloating
            name={CONFIRMED_SPOUSE_DEATH_ON_OF_AFTER_19741113_NOTES}
            label="How have you confirmed this?"
            handleSave={saveProbateEstateField}
          />
        </Grid.Item>
        {!spouseDiedOnOrAfter19741113 && (
          <Grid.Item span={9}>
            <IHT400Warning />
          </Grid.Item>
        )}
      </Grid>
    </>
  )
}

SpouseChecks.propTypes = {
  factFindUrl: PropTypes.string.isRequired,
  saveProbateEstateField: PropTypes.func.isRequired,
}

export default SpouseChecks
