import styled from 'styled-components'
import ENV from 'config/environment'
import { Wrapper } from '@farewill/ui'
import { COLOR, FONT } from '@farewill/ui/tokens'

const SHOW_BANNER_ENVIRONMENTS = ['development', 'staging', 'review']

const Banner = styled.div`
  background-color: ${COLOR.ACCENT.PRIMARY};
  font-size: ${FONT.SIZE.XS};
  text-align: center;
`

const Environment = () => {
  const showBanner = SHOW_BANNER_ENVIRONMENTS.includes(ENV.APP_ENV)
  if (!showBanner) return null

  return (
    <Banner>
      <Wrapper container containerPaddingTop="XS" containerPaddingBottom="XS">
        You are currently using a <strong>{ENV.APP_ENV}</strong> version of this
        app.
      </Wrapper>
    </Banner>
  )
}

export default Environment
