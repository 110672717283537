import { Dispatch, SetStateAction, useEffect, useState } from 'react'

import { Divider, Grid, P } from '@farewill/ui'
import { useFormikContext } from 'formik'
import InputFloating from 'components/form/input-floating'
import CircularRadioGroup from 'components/form/circular-radio-group'
import { getFuneralTotal } from 'routes/probate-estate/fact-find/get-totals'
import { NOT_ASKED_AND_UNSURE_OPTIONS, YES_NO_OPTIONS } from 'utils/enums'
import RadioGroup from 'components/form/radio-group'
import { ProbateLeadAttributes } from 'lib/models/lead'
import MessageBox from 'components/message-box'
import { showModal } from 'state/actions'
import store from 'state/create-store'
import DeleteModal from 'components/form/radio-group-delete-modal'
import SectionHeading from '../section-heading'

const FUNERAL_COSTS_FIELDS = {
  HAS_MADE_FUNERAL_ARRANGEMENTS: 'hasMadeFuneralArrangements',
  FUNERAL_COST_KNOWN: 'funeralCostKnown',
  FUNERAL_BILL_AMOUNT: 'funeralBillAmount',
  FUNERAL_WAKE_AMOUNT: 'funeralWakeAmount',
  FUNERAL_FLOWERS_AMOUNT: 'funeralFlowersAmount',
  FUNERAL_OTHER_AMOUNT: 'funeralOtherAmount',
  FUNERAL_AMOUNT_NOTES: 'funeralAmountNotes',
  FUNERAL_HAD_PRE_PAID_PLAN: 'funeralHadPrePaidPlan',
  FUNERAL_AMOUNT_CONVERED_BY_PLAN: 'funeralAmountCoveredByPlan',
  FUNERAL_HAS_BEEN_PAID: 'funeralHasBeenPaid',
} as const

type FuneralCostsProbateEstateItemsProps = {
  setTotalAmount: Dispatch<SetStateAction<string>>
}

const FuneralCostsInner = ({
  setTotalAmount,
}: FuneralCostsProbateEstateItemsProps) => {
  const { values, handleSubmit } = useFormikContext<ProbateLeadAttributes>()

  useEffect(() => {
    setTotalAmount(getFuneralTotal(values.quoteAttributes))
  }, [setTotalAmount, values])

  return (
    <Grid>
      <Grid.Item span={3}>
        <InputFloating
          name={`quoteAttributes.${FUNERAL_COSTS_FIELDS.FUNERAL_BILL_AMOUNT}`}
          label="Funeral bill (£)"
          handleSave={() => handleSubmit()}
          isCurrency
        />
      </Grid.Item>
      <Grid.Item span={3}>
        <InputFloating
          name={`quoteAttributes.${FUNERAL_COSTS_FIELDS.FUNERAL_WAKE_AMOUNT}`}
          label="Wake (£)"
          handleSave={() => handleSubmit()}
          isCurrency
        />
      </Grid.Item>
      <Grid.Item span={3}>
        <InputFloating
          name={`quoteAttributes.${FUNERAL_COSTS_FIELDS.FUNERAL_FLOWERS_AMOUNT}`}
          label="Flowers (£)"
          handleSave={() => handleSubmit()}
          isCurrency
        />
      </Grid.Item>
      <Grid.Item span={3}>
        <InputFloating
          name={`quoteAttributes.${FUNERAL_COSTS_FIELDS.FUNERAL_OTHER_AMOUNT}`}
          label="Other costs (£)"
          handleSave={() => handleSubmit()}
          isCurrency
        />
      </Grid.Item>
      <Grid.Item>
        <InputFloating
          name={`quoteAttributes.${FUNERAL_COSTS_FIELDS.FUNERAL_AMOUNT_NOTES}`}
          label="Description of costs"
          handleSave={() => handleSubmit()}
        />
      </Grid.Item>
      <Grid.Item>
        <CircularRadioGroup
          name={`quoteAttributes.${FUNERAL_COSTS_FIELDS.FUNERAL_HAS_BEEN_PAID}`}
          label="Have these costs been paid?"
          options={NOT_ASKED_AND_UNSURE_OPTIONS}
          handleSave={() => handleSubmit()}
          inline
        />
      </Grid.Item>
      <Grid.Item>
        <CircularRadioGroup
          name={`quoteAttributes.${FUNERAL_COSTS_FIELDS.FUNERAL_HAD_PRE_PAID_PLAN}`}
          label="Was there a pre-paid funeral plan?"
          options={NOT_ASKED_AND_UNSURE_OPTIONS}
          handleSave={() => handleSubmit()}
          inline
        />
      </Grid.Item>
      {values.quoteAttributes[
        FUNERAL_COSTS_FIELDS.FUNERAL_HAD_PRE_PAID_PLAN
      ] === 'yes' && (
        <Grid.Item span={5}>
          <InputFloating
            name={`quoteAttributes.${FUNERAL_COSTS_FIELDS.FUNERAL_AMOUNT_CONVERED_BY_PLAN}`}
            label="Amount covered by plan (£)"
            handleSave={() => handleSubmit()}
            isCurrency
          />
        </Grid.Item>
      )}
    </Grid>
  )
}

const getConfirmationModalConfig = ({
  name,
  value,
  setFieldValue,
  handleSubmit,
}: {
  name: string
  value: string
  setFieldValue: (
    field: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    value: any,
    shouldValidate?: boolean | undefined
  ) => void
  handleSubmit: (e?: React.FormEvent<HTMLFormElement> | undefined) => void
}) => ({
  component: DeleteModal,
  headingText: 'You are about to remove some information',
  config: {
    deleteCallback: () => {
      if (value === 'false') {
        setFieldValue(
          `quoteAttributes.${FUNERAL_COSTS_FIELDS.FUNERAL_BILL_AMOUNT}`,
          ''
        )
        setFieldValue(
          `quoteAttributes.${FUNERAL_COSTS_FIELDS.FUNERAL_WAKE_AMOUNT}`,
          ''
        )
        setFieldValue(
          `quoteAttributes.${FUNERAL_COSTS_FIELDS.FUNERAL_FLOWERS_AMOUNT}`,
          ''
        )
        setFieldValue(
          `quoteAttributes.${FUNERAL_COSTS_FIELDS.FUNERAL_OTHER_AMOUNT}`,
          ''
        )
        setFieldValue(
          `quoteAttributes.${FUNERAL_COSTS_FIELDS.FUNERAL_AMOUNT_NOTES}`,
          ''
        )
        setFieldValue(
          `quoteAttributes.${FUNERAL_COSTS_FIELDS.FUNERAL_HAS_BEEN_PAID}`,
          ''
        )
        setFieldValue(
          `quoteAttributes.${FUNERAL_COSTS_FIELDS.FUNERAL_HAD_PRE_PAID_PLAN}`,
          ''
        )
        setFieldValue(
          `quoteAttributes.${FUNERAL_COSTS_FIELDS.FUNERAL_AMOUNT_CONVERED_BY_PLAN}`,
          ''
        )

        if (
          name ===
          `quoteAttributes.${FUNERAL_COSTS_FIELDS.HAS_MADE_FUNERAL_ARRANGEMENTS}`
        ) {
          setFieldValue(
            `quoteAttributes.${FUNERAL_COSTS_FIELDS.FUNERAL_COST_KNOWN}`,
            '',
            false
          )
        }
      }
      setFieldValue(name, value, false)
      handleSubmit()
    },
    bodyText: (
      <P>
        Changing the answer to this question will clear the answers to questions
        below. Do you want to continue?
      </P>
    ),
    buttonLabel: 'Continue',
  },
})

const FuneralCosts = ({ isComplete }: { isComplete: boolean }) => {
  const { values, setFieldValue, handleSubmit } =
    useFormikContext<ProbateLeadAttributes>()
  const [totalAmount, setTotalAmount] = useState('0')

  return (
    <Grid>
      <Grid.Item margin={[0, 0, 0, '62px']} noTrim>
        <Divider margin={['L', 0]} />
        <SectionHeading
          title="7.3 Funeral costs"
          scrollId="7.3"
          isComplete={isComplete}
        />
      </Grid.Item>
      <Grid.Item span={11} margin={[0, 0, 0, '62px']} noTrim>
        <Grid>
          {values.quoteAttributes[
            FUNERAL_COSTS_FIELDS.HAS_MADE_FUNERAL_ARRANGEMENTS
          ] === 'true' &&
            values.quoteAttributes[FUNERAL_COSTS_FIELDS.FUNERAL_COST_KNOWN] ===
              'true' && (
              <Grid.Item data-testid="funeral-costs-total">
                <P size="S">Total: {totalAmount}</P>
              </Grid.Item>
            )}
          <Grid.Item>
            <RadioGroup
              label="Has the caller made funeral arrangements?"
              name={`quoteAttributes.${FUNERAL_COSTS_FIELDS.HAS_MADE_FUNERAL_ARRANGEMENTS}`}
              options={YES_NO_OPTIONS}
              confirmationRequired
              handleChange={(value) => {
                if (value === 'false') {
                  store.dispatch(
                    showModal(
                      getConfirmationModalConfig({
                        name: `quoteAttributes.${FUNERAL_COSTS_FIELDS.HAS_MADE_FUNERAL_ARRANGEMENTS}`,
                        value,
                        setFieldValue,
                        handleSubmit,
                      })
                    )
                  )
                } else {
                  setFieldValue(
                    `quoteAttributes.${FUNERAL_COSTS_FIELDS.HAS_MADE_FUNERAL_ARRANGEMENTS}`,
                    value,
                    false
                  )
                  handleSubmit()
                }
              }}
            />
          </Grid.Item>
          {values.quoteAttributes[
            FUNERAL_COSTS_FIELDS.HAS_MADE_FUNERAL_ARRANGEMENTS
          ] === 'false' && (
            <Grid.Item margin={['M', 0]}>
              <MessageBox>
                Speak to the caller about Farewill Cremation and ask if they’d
                like a quote.
              </MessageBox>
            </Grid.Item>
          )}
          {values.quoteAttributes[
            FUNERAL_COSTS_FIELDS.HAS_MADE_FUNERAL_ARRANGEMENTS
          ] === 'true' && (
            <>
              <Grid.Item>
                <RadioGroup
                  label="Do you know the cost of the funeral?"
                  hint="We ask this question because it helps us to calculate the inheritance tax"
                  name={`quoteAttributes.${FUNERAL_COSTS_FIELDS.FUNERAL_COST_KNOWN}`}
                  options={YES_NO_OPTIONS}
                  confirmationRequired
                  handleChange={(value) => {
                    if (value === 'false') {
                      store.dispatch(
                        showModal(
                          getConfirmationModalConfig({
                            name: `quoteAttributes.${FUNERAL_COSTS_FIELDS.FUNERAL_COST_KNOWN}`,
                            value,
                            setFieldValue,
                            handleSubmit,
                          })
                        )
                      )
                    } else {
                      setFieldValue(
                        `quoteAttributes.${FUNERAL_COSTS_FIELDS.FUNERAL_COST_KNOWN}`,
                        value,
                        false
                      )
                      handleSubmit()
                    }
                  }}
                />
              </Grid.Item>
              {values.quoteAttributes[
                FUNERAL_COSTS_FIELDS.FUNERAL_COST_KNOWN
              ] === 'true' && (
                <Grid.Item>
                  <FuneralCostsInner setTotalAmount={setTotalAmount} />
                </Grid.Item>
              )}
            </>
          )}
        </Grid>
      </Grid.Item>
    </Grid>
  )
}

export default FuneralCosts
