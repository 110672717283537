import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Button, P, Wrapper } from '@farewill/ui'
import { FONT, GTR } from '@farewill/ui/tokens'
import { useDispatch } from 'react-redux'

import { hideModal } from 'state/actions'

const StyledWrapper = styled(Wrapper)`
  display: flex;
  flex-direction: column;
`

const StyledCancelButton = styled(Button)`
  font-weight: ${FONT.WEIGHT.REGULAR};
  text-decoration: underline;
  align-self: center;
  margin-top: ${GTR.XS};

  &:hover {
    text-decoration: underline;
  }
`

const Modal = ({ config }) => {
  const dispatch = useDispatch()

  const handleDeleteClick = async () => {
    await config.deleteInvoice()
    dispatch(hideModal())
  }

  return (
    <Wrapper>
      <P>You should only delete an invoice if it was created by mistake.</P>
      <P margin={[0, 0, 'L']}>
        Do not delete the invoice if you are issuing a refund.
      </P>
      <StyledWrapper>
        <Button.Primary type="button" onClick={handleDeleteClick}>
          Delete invoice
        </Button.Primary>
        <StyledCancelButton onClick={() => dispatch(hideModal())}>
          Cancel
        </StyledCancelButton>
      </StyledWrapper>
    </Wrapper>
  )
}

Modal.propTypes = {
  config: PropTypes.shape({
    deleteInvoice: PropTypes.func.isRequired,
  }).isRequired,
}

export default Modal
