import { Grid } from '@farewill/ui'
import { FarewillProduct } from 'utils/enums'

import Partners from './partners'
import Discounts from './discounts'

type PartnersAndDiscountsProps = {
  product: FarewillProduct
}

const PartnersAndDiscounts = ({ product }: PartnersAndDiscountsProps) => {
  return (
    <Grid>
      <Partners product={product} />
      <Discounts product={product} />
    </Grid>
  )
}

export default PartnersAndDiscounts
