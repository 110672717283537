import { useEffect } from 'react'
import CustomerInfoSummary from './components/CustomerInfoSummary'
import FinancialSummary from './components/FinancialSummary'

import { Grid, H, Divider, P } from '@farewill/ui'

import useApi from 'lib/effects/api'

const Summary = ({ probateEstate }) => {
  const [{ data, isLoading: isGetLoading }, makeGetRequest] = useApi({
    data: {},
    isLoading: true,
  })

  useEffect(() => {
    const url = `/api/probate-estates/${probateEstate.id}/summary`
    makeGetRequest({ url })
  }, [makeGetRequest, probateEstate])

  if (isGetLoading) return <P size="L">Loading...</P>

  return (
    <Grid>
      <Grid.Item span={8}>
        <H size="M">Summary</H>
        <CustomerInfoSummary probateEstate={probateEstate} data={data} />
        <Divider margin={['XXL', 0]} />
        <H size="S">Financial</H>
        <FinancialSummary data={data} />
      </Grid.Item>
    </Grid>
  )
}
export default Summary
