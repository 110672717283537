export const hasScheduledEmailTrigger = (task) => {
  return !!task.attributes.startEmailTriggers.some(
    (trigger) => trigger.initialDelay
  )
}

export const hasRepeatEmailTrigger = (task) => {
  return !!task.attributes.startEmailTriggers[0]?.repeatCycle
}

export const hasImmediateEmailTrigger = (task) => {
  return (
    hasStartEmailTrigger(task) &&
    !task.attributes.startEmailTriggers[0]?.initialDelay
  )
}

export const hasStartEmailTrigger = (task) => {
  return !!task.attributes.startEmailTriggers[0]
}

export const hasMultipleStartEmailTriggers = (task) => {
  return task.attributes.startEmailTriggers.length > 1
}

export const hasStopEmailTrigger = (task) => {
  return !!task.attributes.stopEmailTriggers[0]
}

export const hasEmails = (task) => {
  return task.attributes.emails.length > 0
}

export const hasProcessedEmail = (task) => {
  return !!task.attributes.emails.find((email) => email.status === 'processed')
}

export const hasScheduledEmail = (task) => {
  return !!task.attributes.emails.find((email) => email.status === 'scheduled')
}

export const findScheduledEmailTrigger = (task) => {
  return task.attributes.startEmailTriggers.find(
    (trigger) => !!trigger.initialDelay
  )
}

export const findImmediateEmailTrigger = (task) => {
  return task.attributes.startEmailTriggers.find(
    (trigger) => !trigger.initialDelay
  )
}
