import styled, { css } from 'styled-components'
import { CalendarIcon } from '@farewill/ui'
import { COLOR, GTR } from '@farewill/ui/tokens'

import Badge from '../styled/badge'

const StyledDate = styled(Badge)<{ date: string }>`
  white-space: nowrap;
  color: ${COLOR.GREY.DARK};
  background: ${COLOR.GREY.LIGHT};

  ${({ date }) =>
    date.includes('today') &&
    css`
      color: ${COLOR.STATE.SUCCESS_120};
      background: ${COLOR.STATE.SUCCESS_20};
    `}

  ${({ date }) =>
    (['⚠️ no due date ⚠️', 'yesterday'].includes(date) ||
      date.includes('ago')) &&
    css`
      color: ${COLOR.STATE.ERROR_120};
      background: ${COLOR.STATE.ERROR_20};
    `}

  ${({ date }) =>
    date.includes('tomorrow') &&
    css`
      color: ${COLOR.ACCENT.SECONDARY_120};
      background: ${COLOR.ACCENT.SECONDARY_20};
    `}
`

const StyledCalendarIcon = styled(CalendarIcon)`
  margin-right: ${GTR.XXS};
  margin-top: -3px;
`

type Props = {
  date?: string | null
  showCalendarIcon?: boolean
}

const DateBadge = ({
  date,
  showCalendarIcon,
}: Props): React.ReactElement | null => {
  if (!date) return null

  return (
    <StyledDate date={date}>
      {showCalendarIcon && <StyledCalendarIcon size="S" />}
      {date}
    </StyledDate>
  )
}

export default DateBadge
