import {
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react'

import apiRequest from 'lib/axios/api-request'
import {
  ProbateEstatePerson,
  ProbateEstatePeopleAttributes,
} from 'lib/models/probate-estate'

interface PeopleContextProps {
  people: ProbateEstatePerson[]
  createPerson: (
    attributes: Partial<ProbateEstatePeopleAttributes>
  ) => Promise<void>
  updatePerson: (person: Partial<ProbateEstatePerson>) => Promise<void>
  deletePerson: (id: number) => Promise<void>
  bulkDeletePeople: (personalRepresentativeType: string) => Promise<void>
}

const PeopleContext = createContext<PeopleContextProps>({
  people: [],
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  createPerson: async () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  updatePerson: async () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  deletePerson: async () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  bulkDeletePeople: async () => {},
  // setPeople: async () => {},
})

const PeopleProvider = ({
  children,
  probateEstateId,
}: PropsWithChildren<{
  probateEstateId: number
}>) => {
  const [people, setPeople] = useState<ProbateEstatePerson[]>([])

  useEffect(() => {
    if (!probateEstateId) return

    apiRequest({
      url: `/api/probate-estates/${probateEstateId}/people`,
      method: 'GET',
    }).then((response) => {
      setPeople(response.data.data)
    })
  }, [probateEstateId])

  const createPerson = async (
    attributes: Partial<ProbateEstatePeopleAttributes>
  ) => {
    const apiResponse = await apiRequest({
      url: `/api/probate-estates/${probateEstateId}/people`,
      method: 'POST',
      data: {
        data: {
          type: 'probate_estate_people',
          attributes: { ...attributes },
        },
      },
    })

    setPeople([...people, apiResponse.data.data])
  }

  const updatePerson = async (person: Partial<ProbateEstatePerson>) => {
    const apiResponse = await apiRequest({
      url: `/api/probate-estates/${probateEstateId}/people/${person.id}`,
      method: 'PATCH',
      data: {
        data: {
          type: 'probate_estate_people',
          id: person.id,
          attributes: person.attributes,
        },
      },
    })

    const updatedPersonData: ProbateEstatePerson = apiResponse.data.data
    const updatedPeople = people.map((peopleItem) =>
      peopleItem.id === updatedPersonData.id ? updatedPersonData : peopleItem
    )

    setPeople(updatedPeople)
  }

  const deletePerson = async (id: number) => {
    await apiRequest({
      url: `/api/probate-estates/${probateEstateId}/people/${id}`,
      method: 'DELETE',
    }).then(() => {
      const updatedPeople = people.filter((p) => p.id !== id)
      setPeople(updatedPeople)
    })
  }

  const bulkDeletePeople = async (personalRepresentativeType: string) => {
    await apiRequest({
      url: `/api/probate-estates/${probateEstateId}/people/bulk-delete`,
      method: 'POST',
      data: {
        data: {
          type: 'probate_estate_people',
          attributes: { personalRepresentativeType },
        },
      },
    }).then(() => {
      const updatedPeople = people.filter(
        (p) =>
          p.attributes.personalRepresentativeType !== personalRepresentativeType
      )
      setPeople(updatedPeople)
    })
  }

  return (
    <PeopleContext.Provider
      value={{
        people,
        createPerson,
        updatePerson,
        deletePerson,
        bulkDeletePeople,
      }}
    >
      {children}
    </PeopleContext.Provider>
  )
}

const usePeople = () => useContext(PeopleContext)

export { PeopleProvider, usePeople }
