import { useQuery } from 'react-query'

import {
  SelectInput,
  Props as SelectInputProps,
} from 'components/form/select-input'
import axiosRequest from 'lib/axios/api-request'

import { transformPartnerType } from './helpers'

const ONE_DAY = 24 * 60 * 60 * 1000

interface PartnerSelectProps extends SelectInputProps {
  partnerType: string
}

const PartnerSelect = ({
  partnerType,
  ...rest
}: PartnerSelectProps): React.ReactElement => {
  const {
    data: partners = [],
    isLoading,
    error,
  } = useQuery(
    ['partners'],
    async () => {
      const { data } = await axiosRequest({ url: `/api/partners` })

      return transformPartnerType(data?.data)
    },
    { cacheTime: ONE_DAY, staleTime: ONE_DAY }
  )

  const filteredPartners = partners.filter(
    ({ attributes }) => attributes.type === partnerType
  )

  const options = filteredPartners.map(({ attributes }) => {
    return {
      value: attributes.id,
      label: attributes.name,
      disabled: !attributes.products.some(({ product }) => {
        return product === 'telephone_will'
      }),
    }
  })

  const noOptionsMessage = (): string | undefined => {
    if (isLoading) {
      return 'loading…'
    } else if (error) {
      return 'error loading partners'
    }
  }

  return (
    <SelectInput
      options={options}
      noOptionsMessage={noOptionsMessage as () => string}
      isOptionDisabled={(partner) => partner.disabled || false}
      {...rest}
    />
  )
}

PartnerSelect.displayName = 'PartnerSelect'

export default PartnerSelect
