export const DEATH_CERTIFICATE_CHECK_STATUS = 'deathCertificateCheckStatus'
export const MARRIAGE_CERTIFICATE_CHECK_STATUS =
  'marriageCertificateCheckStatus'
export const WILL_CHECK_STATUS = 'willCheckStatus'
export const CODICILS_CHECK_STATUS = 'codicilsCheckStatus'
export const LASTING_POWER_OF_ATTORNEY_STATUS = 'lastingPowerOfAttorneyStatus'
export const ENDURING_POWER_OF_ATTORNEY_STATUS = 'enduringPowerOfAttorneyStatus'
export const DEPUTYSHIP_ORDER_STATUS = 'deputyshipOrderStatus'
export const ADOPTION_CERTIFICATE_STATUS = 'adoptionCertificateStatus'
export const DOCUMENTS_CHECKLIST_NOTES = 'documentsChecklistNotes'

export const WILL_BELONGS_TO_DECEASED = 'willBelongsToDeceased'
export const WILL_DATE_IS_CORRECT = 'willDateIsCorrect'
export const WILL_IDENTITY_NOTES = 'willIdentityNotes'

export const WILL_IS_UNDATED_OR_UNSIGNED = 'willIsUndatedOrUnsigned'
export const WILL_IS_UNBOUND = 'willIsUnbound'
export const WILL_HAS_INCORRECT_STATEMENTS_ABOUT_WITNESSES =
  'willHasIncorrectStatementsAboutWitnesses'
export const WILL_HAS_TRUSTS = 'willHasTrusts'
export const WILL_IS_DIY = 'willIsDiy'
export const WILL_HAS_SHAKY_SIGNATURE = 'willHasShakySignature'
export const WILL_HAS_TEAR_OR_MARKS = 'willHasTearsOrMarks'
export const WILL_FEELS_UNSIGNED = 'willFeelsUnsigned'
export const WILL_HAS_HANDWRITTEN_AMENDMENTS = 'willHasHandwrittenAmendments'
export const WILL_HAS_UNINITIALLED_AMENDMENTS = 'willHasUninitialledAmendments'
export const WILL_HAS_IN_CONTEMPLATION_OF_MARRIAGE_CLAUSE =
  'willHasInContemplationOfMarriageClause'
export const WILL_HAS_ISSUES_WITH_CONDITION = 'willHasIssuesWithCondition'
export const WILL_HAS_CODICIL_REPLACING_EXECUTORS =
  'willHasCodicilReplacingExecutors'
export const WILL_PROBLEMS_AND_COMPLEXITIES_NOTES =
  'willProblemsAndComplexitiesNotes'

export const EXECUTOR_APPOINTMENT_VOID_FOR_UNCERTAINTY =
  'executorAppointmentVoidForUncertainty'
export const EXECUTOR_IS_PREDECEASED_OR_RENOUNCED_OR_UNABLE_TO_ACT =
  'executorIsPredeceasedOrRenouncedOrUnableToAct'
export const EXECUTOR_WANTS_TO_APPOINT_POWER_OF_ATTORNEY =
  'executorWantsToAppointPowerOfAttorney'
export const EXECUTOR_IS_SOLE_AND_MINOR = 'executorIsSoleAndMinor'
export const EXEUCUTOR_IS_PROFESSIONAL = 'executorIsProfessional'
export const PROFESSIONAL_EXECUTOR_NO_LONGER_EXISTS =
  'professionalExecutorNoLongerExists'
export const PROFESSIONAL_EXECUTOR_WILL_RENOUNCE =
  'professionalExecutorWillRenounce'
export const EXECUTOR_NOTES = 'executorNotes'

export const CONFIRMED_DEATH_ON_OR_AFTER_20100406 =
  'confirmedDeathOnOrAfter20100406'
export const CONFIRMED_DEATH_ON_OR_AFTER_20100406_NOTES =
  'confirmedDeathOnOrAfter20100406Notes'
export const CONFIRMED_ESTATE_VALUE_BETWEEN_325000_AND_650000 =
  'confirmedEstateValueBetween325000And650000'
export const CONFIRMED_ESTATE_VALUE_BETWEEN_325000_AND_650000_NOTES =
  'confirmedEstateValueBetween325000And650000Notes'
export const CONFIRMED_MADE_GIFTS_OR_BENEFITTED_FROM_TRUST =
  'confirmedMadeGiftsOrBenefittedFromTrust'
export const CONFIRMED_MADE_GIFTS_OR_BENEFITTED_FROM_TRUST_NOTES =
  'confirmedMadeGiftsOrBenefittedFromTrustNotes'
export const CONFIRMED_HAD_BUSINESS_OR_AGRICULTURAL_ASSETS =
  'confirmedHadBusinessOrAgriculturalAssets'
export const CONFIRMED_HAD_BUSINESS_OR_AGRICULTURAL_ASSETS_NOTES =
  'confirmedHadBusinessOrAgriculturalAssetsNotes'
export const CONFIRMED_HAD_PREDECEASED_SPOUSE = 'confirmedHadPredeceasedSpouse'
export const CONFIRMED_HAD_PREDECEASED_SPOUSE_NOTES =
  'confirmedHadPredeceasedSpouseNotes'
export const CONFIRMED_SPOUSE_DOMICILED_IN_UK_AT_DEATH =
  'confirmedSpouseDomiciledInUkAtDeath'
export const CONFIRMED_SPOUSE_DOMICILED_IN_UK_AT_DEATH_NOTES =
  'confirmedSpouseDomiciledInUkAtDeathNotes'
export const CONFIRMED_SPOUSE_DEATH_ON_OF_AFTER_19741113 =
  'confirmedSpouseDeathOnOrAfter19741113'
export const CONFIRMED_SPOUSE_DEATH_ON_OF_AFTER_19741113_NOTES =
  'confirmedSpouseDeathOnOrAfter19741113Notes'
export const CLAIM_TO_TRANSFER_NIL_RATE_BAND_NOTES =
  'claimToTransferNilRateBandNotes'

export const INTESTACY_STATUTORY_LEGACY_NOTES = 'intestacyStatutoryLegacyNotes'
