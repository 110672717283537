import { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Formik, useFormikContext } from 'formik'
import { Button, Grid, P } from '@farewill/ui'
import { BORDER, COLOR, FONT, GTR } from '@farewill/ui/tokens'
import get from 'lodash/get'
import set from 'lodash/set'

import useDebounce from 'lib/effects/debounce'
import useApi from 'lib/effects/api'
import axiosRequest from 'lib/axios/api-request'
import { formatValuesForFormik } from 'utils/helpers'

import {
  FloatingWrapper,
  FloatingLabel,
  FloatingInput,
  FloatingHint,
} from 'components/styled/helpers/floating'
import InputFloating from 'components/form/input-floating'
import { formatLoqateAddress, getFieldState } from 'components/form/helpers'

const StyledFloatingHint = styled(FloatingHint)`
  margin-top: ${GTR.XXS};
`

const StyledInputMethodButton = styled(Button)`
  text-decoration: underline;
  font-weight: ${FONT.WEIGHT.REGULAR};
  font-size: ${FONT.SIZE.XS};

  &:hover {
    text-decoration: underline;
  }
`

const ResultsList = styled.ul`
  position: absolute;
  top: 58px;
  width: 100%;
  border: 1px solid ${COLOR.GREY.LIGHT};
  background-color: ${COLOR.WHITE};
  border-radius: ${BORDER.RADIUS.S};
  box-shadow: ${BORDER.SHADOW.M};
  padding: 0;
  max-height: 400px;
  overflow: auto;
  z-index: 1;

  &:before {
    content: '';
    display: block;
    height: 1px;
    background-color: ${COLOR.GREY.LIGHT};
    width: calc(100% - ${GTR.L});
    position: absolute;
    top: 0;
    left: ${GTR.S};
  }
`

const ResultLink = styled.li`
  padding: ${GTR.XS} ${GTR.S};
  width: 100%;
  text-decoration: none;

  &:hover,
  &:focus {
    background-color: ${COLOR.BACKGROUND.FOG};
    cursor: pointer;
    text-decoration: none;
  }
`

const SearchLoadingMessage = styled.li`
  padding: ${GTR.XS} ${GTR.S};
  width: 100%;
  text-decoration: none;
`

const NotFoundResultLink = styled(ResultLink)`
  text-align: right;
  font-weight: ${FONT.WEIGHT.REGULAR};
  font-size: ${FONT.SIZE.XS};
`

const ResultDescription = styled.div`
  font-weight: ${FONT.WEIGHT.REGULAR};
  font-size: ${FONT.SIZE.XS};
`

const addressIsEmpty = (values) => {
  if (!values) return true
  const { lineOne, lineTwo, city, postalCode, countryCode } = values
  return !lineOne && !lineTwo && !city && !postalCode && !countryCode
}

const Hint = ({ isSearching, setIsSearching }) => (
  <StyledFloatingHint>
    {isSearching ? 'Start typing and choose from the list, or ' : 'Or '}
    <StyledInputMethodButton
      type="button"
      onClick={() => setIsSearching(!isSearching)}
    >
      {isSearching ? 'enter the address manually' : 'search for address'}
    </StyledInputMethodButton>
  </StyledFloatingHint>
)

const AddressFields = ({ label, name, onAddressChanged, disabled }) => {
  const { values } = useFormikContext()
  const handleSave = () => onAddressChanged(get(values, name).addressFields)

  return (
    <>
      <P margin={[0, 0, 'XS']}>{label}</P>
      <Grid gap={['S', 'M']}>
        <Grid.Item>
          <InputFloating
            name={`${name}.addressFields.lineOne`}
            label="Address line 1"
            handleSave={handleSave}
            disabled={disabled}
            allowCopyToClipboard
          />
        </Grid.Item>
        <Grid.Item>
          <InputFloating
            name={`${name}.addressFields.lineTwo`}
            label="Address line 2"
            handleSave={handleSave}
            disabled={disabled}
            allowCopyToClipboard
          />
        </Grid.Item>
        <Grid.Item span={4}>
          <InputFloating
            name={`${name}.addressFields.city`}
            label="Town / city"
            handleSave={handleSave}
            disabled={disabled}
            allowCopyToClipboard
          />
        </Grid.Item>
        <Grid.Item span={4}>
          {/* TODO: use our new select component, country names mapped to codes */}
          <InputFloating
            name={`${name}.addressFields.countryCode`}
            label="Country"
            handleSave={handleSave}
            disabled={disabled}
            allowCopyToClipboard
          />
        </Grid.Item>
        <Grid.Item span={4}>
          <InputFloating
            name={`${name}.addressFields.postalCode`}
            label="Postcode"
            handleSave={handleSave}
            disabled={disabled}
            allowCopyToClipboard
          />
        </Grid.Item>
      </Grid>
    </>
  )
}

const AddressForm = ({
  onAddressChanged,
  addressValues,
  label,
  name,
  parentFormStatus,
  disabled,
}) => {
  const formik = useFormikContext()
  const { handleChange, setFieldValue, setStatus, values } = formik
  useEffect(() => setStatus(parentFormStatus), [parentFormStatus, setStatus])

  const searchEl = useRef(null)

  const [isSearching, setIsSearching] = useState(addressIsEmpty(addressValues))
  const [focused, setFocused] = useState(false)
  const [searchQuery, setSearchQuery] = useState({})
  const debouncedSearchQuery = useDebounce(searchQuery, 200)

  const [{ data: searchResponse, isLoading }, makeRequest] = useApi()

  useEffect(() => {
    setFieldValue(name, {
      search: '',
      addressFields: addressValues,
    })
    if (!addressIsEmpty(addressValues)) {
      setIsSearching(false)
    }
  }, [setFieldValue, name, addressValues])

  useEffect(() => {
    if (debouncedSearchQuery.text || debouncedSearchQuery.container) {
      makeRequest({
        url: '/api/address-lookups/admin',
        method: 'POST',
        data: {
          data: {
            type: 'address_lookups',
            attributes: debouncedSearchQuery,
          },
        },
      })
    }
  }, [debouncedSearchQuery, makeRequest])

  useEffect(() => {
    const handleCloseClick = (e) => {
      if (
        focused &&
        searchEl?.current &&
        !searchEl.current.contains(e.target)
      ) {
        setFocused(false)
      }
    }

    document.addEventListener('click', handleCloseClick)
    return () => document.removeEventListener('click', handleCloseClick)
  }, [focused])

  const onSearchResultClick = async ({ result, setFieldValue }) => {
    if (result.attributes.type === 'Address') {
      const address = await axiosRequest({
        url: `/api/address-lookups/admin/${result.id}`,
      })
      const { attributes: addressData } = address.data.data
      const formattedAddress = formatLoqateAddress(addressData)

      setIsSearching(false)
      setFieldValue(name, {
        search: '',
        addressFields: formattedAddress,
      })
      onAddressChanged(formattedAddress)
    } else {
      setSearchQuery({
        ...searchQuery,
        container: result.id,
      })
    }
  }

  const showResultsList = focused && get(values, name).search
  const hasCurrentSearchResults = !isLoading && searchResponse
  const isEnteringManually = !isSearching
  const { hasValue, hasHighlight } = getFieldState({
    formik,
    name: `${name}.search`,
  })

  return (
    <>
      {isSearching && (
        <div ref={searchEl}>
          <FloatingWrapper highlight={hasHighlight}>
            <FloatingInput
              highlight={hasHighlight}
              id={`${name}.search`}
              name={`${name}.search`}
              onFocus={() => setFocused(true)}
              onChange={(e) => {
                handleChange(e)
                setFocused(true)
                setSearchQuery({ text: e.target.value })
              }}
              disabled={disabled}
            />

            {showResultsList && (
              <ResultsList data-testid="address-results-list">
                {isLoading && (
                  <SearchLoadingMessage>Loading...</SearchLoadingMessage>
                )}

                {hasCurrentSearchResults &&
                  searchResponse.map((result) => (
                    <ResultLink
                      key={result.id}
                      onClick={() => {
                        onSearchResultClick({ result, setFieldValue })
                      }}
                    >
                      <div>{result.attributes.text}</div>
                      <ResultDescription>
                        {result.attributes.description}
                      </ResultDescription>
                    </ResultLink>
                  ))}

                <NotFoundResultLink onClick={() => setIsSearching(false)}>
                  Not found? <u>Enter the address manually</u>
                </NotFoundResultLink>
              </ResultsList>
            )}

            <FloatingLabel htmlFor={`${name}.search`} full={hasValue}>
              {label}
            </FloatingLabel>
          </FloatingWrapper>
        </div>
      )}

      {isEnteringManually && (
        <AddressFields
          name={name}
          label={label}
          onAddressChanged={onAddressChanged}
          disabled={disabled}
        />
      )}

      <Hint isSearching={isSearching} setIsSearching={setIsSearching} />
    </>
  )
}

const AddressInput = ({
  addressValues,
  onAddressChanged,
  label,
  name,
  disabled,
}) => {
  const { status } = useFormikContext()
  const addressFields = formatValuesForFormik({
    countryCode: 'GB',
    ...addressValues,
  })

  return (
    <Formik initialValues={set({}, name, { search: '', addressFields })}>
      <AddressForm
        addressValues={addressValues}
        onAddressChanged={onAddressChanged}
        label={label}
        name={name}
        parentFormStatus={status}
        disabled={disabled}
      />
    </Formik>
  )
}

AddressInput.propTypes = {
  name: PropTypes.string.isRequired,
  onAddressChanged: PropTypes.func.isRequired,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  addressValues: PropTypes.shape({
    id: PropTypes.number,
    lineOne: PropTypes.string,
    lineTwo: PropTypes.string,
    city: PropTypes.string,
    countryCode: PropTypes.string,
    postalCode: PropTypes.string,
  }),
  parentFormStatus: PropTypes.object,
}

AddressInput.defaultProps = {
  label: 'Address',
  disabled: false,
  addressValues: {
    lineOne: '',
    lineTwo: '',
    city: '',
    countryCode: '',
    postalCode: '',
  },
  parentFormStatus: {},
}

export default AddressInput
