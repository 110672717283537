import { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { Divider, H, P } from '@farewill/ui'
import { GTR } from '@farewill/ui/tokens'

import { RESOURCES, useFetchResource } from 'api'
import PaymentsSummary from 'components/payments/invoices'
import {
  formatAmountToPence,
  getInitialValuesFromInvoice,
} from 'components/payments/invoices/methods'
import Overview from 'components/payments/overview'
import PaymentModal, {
  FormOutputValues,
} from 'components/payments/payment-modal'
import { useTasksContext } from 'components/tasks-panel/context'
import { LpaCase, LpaCaseAttributes } from 'lib/models/lpa-case'
import useApi from 'lib/effects/api'
import useApiHelpers from 'lib/effects/api-helpers'
import { Invoice, InvoiceAttributes } from 'lib/models/invoice'
import useInvoices from 'hooks/useInvoices'
import {
  SlimBorderedButton,
  SlimPrimaryButton,
} from 'routes/funerals/components/sale/buttons'
import { hideModal, showModal } from 'state/actions'
import { INVOICE_LINE_ITEMS, VAT_RATE } from 'utils/enums'

import LegacyLpaCaseMessageBox from '../legacy-lpa-case-message-box'
import { MODAL_MAX_WIDTH, PAYMENT_PROVIDER, PAYMENT_TYPE } from './constants'
import CurrentQuote from './editable-quote/current-quote'
import EditableQuote from './editable-quote/editable-quote'
import { LpaCaseQuoteAttributes } from './editable-quote/types'

const Row = styled.div`
  display: grid;
  grid-template-columns: max-content 1fr;
  align-items: center;
`
const Actions = styled.div`
  justify-self: end;
  & > * {
    margin-left: ${GTR.S};
  }
`

const Quote = ({ lpaCaseId }: { lpaCaseId: number }): React.ReactElement => {
  const dispatch = useDispatch()
  const { fetchTasks } = useTasksContext()
  const [showPaymentsSummary, setShowPaymentsSummary] = useState(false)

  const {
    items: quote,
    fetchItems: fetchQuote,
    isFetching,
  } = useApiHelpers({
    baseUrl: `/api/lpa-cases/${lpaCaseId}/quote`,
    type: 'lpa_case_quote',
  })

  const { data: lpaCaseData, refetch: refetchLpaCase } =
    useFetchResource<LpaCase>({
      id: lpaCaseId,
      resource: RESOURCES.LPA_CASES,
    })

  useEffect(() => {
    if (lpaCaseId) {
      fetchQuote()
    }
  }, [fetchQuote, lpaCaseId])

  const {
    invoices,
    fetchInvoices,
    createInvoice,
    updateInvoice,
    updateLineItem,
    deleteInvoice,
  } = useInvoices()

  useEffect(() => {
    if (lpaCaseId) {
      fetchInvoices({ queryParams: { 'filter[lpaCaseId]': lpaCaseId } })
    }
  }, [fetchInvoices, lpaCaseId])

  useEffect(() => {
    if (invoices.length > 0) {
      setShowPaymentsSummary(true)
    }
  }, [invoices])

  const refetchTasks = useCallback(
    () =>
      fetchTasks({
        queryParams: {
          'filter[lpaCaseId]': lpaCaseId,
          'filter[isNeeded]': true,
          'page[size]': 200,
        },
      }),
    [fetchTasks, lpaCaseId]
  )

  const currentQuote = quote?.attributes
  const cost = currentQuote?.totalPriceInPence

  const lpaCase = (lpaCaseData as { attributes: LpaCaseAttributes })?.attributes

  const { createdAt } = lpaCase
  /** We launched the new LPA product in January 2024, so any LPA cases created
   * before 2024 will be legacy LPA cases.
   */
  const isLegacyLpaCase = createdAt < '2024-01-01T00:00:00.000Z'

  const editCardPayment = (invoice: Invoice) => {
    dispatch(
      showModal({
        component: PaymentModal,
        headingText: 'Edit payment',
        maxWidth: MODAL_MAX_WIDTH,
        config: {
          type: PAYMENT_TYPE,
          values: getInitialValuesFromInvoice({ type: PAYMENT_TYPE, invoice }),
          hideModal: () => dispatch(hideModal()),
          deletePayment: async () => {
            await deleteInvoice(invoice.id)
            refetchTasks()
          },
          savePayment: async ({
            reference,
            date,
            amount,
          }: FormOutputValues) => {
            const attributes = {
              reference,
              issuedDate: date,
            } as Partial<InvoiceAttributes>

            attributes.paidOn = attributes.issuedDate

            const price = formatAmountToPence(amount)
            await updateLineItem(invoice.attributes.lineItems[0], {
              price,
            })

            await updateInvoice(invoice.id, attributes)

            refetchTasks()
          },
        },
      })
    )
  }

  const addCardPayment = () => {
    dispatch(
      showModal({
        component: PaymentModal,
        headingText: 'Add payment',
        maxWidth: MODAL_MAX_WIDTH,
        config: {
          type: PAYMENT_TYPE,
          values: getInitialValuesFromInvoice({ type: PAYMENT_TYPE }),
          hideModal: () => dispatch(hideModal()),
          savePayment: async ({
            reference,
            date,
            amount,
          }: FormOutputValues) => {
            const price = formatAmountToPence(amount)

            await createInvoice({
              lpaCaseId,
              paidTo: 'farewill',
              reference,
              issuedDate: date,
              paidOn: date,
              paymentProvider: PAYMENT_PROVIDER,
              lineItems: [
                {
                  description: INVOICE_LINE_ITEMS.LPA,
                  price,
                  taxRate: VAT_RATE,
                },
              ],
            })

            refetchTasks()
          },
        },
      })
    )
  }

  const [, makeRequest] = useApi()

  const updateLpaCase = async ({
    attributes,
  }: {
    attributes: Partial<LpaCaseAttributes>
  }): Promise<void> => {
    await makeRequest({
      url: `/api/lpa-cases/${lpaCaseId}`,
      method: 'PATCH',
      data: {
        data: {
          type: 'lpa_cases',
          id: lpaCaseId,
          attributes,
        },
      },
    })
  }

  const [isEditingQuote, setIsEditingQuote] = useState(false)

  const saveQuote = async (quoteAttributes: LpaCaseQuoteAttributes) => {
    await updateLpaCase({
      attributes: quoteAttributes,
    })

    refetchLpaCase()
    fetchQuote()
  }

  const editableQuoteControls = (
    <Actions>
      {isEditingQuote ? (
        <SlimBorderedButton onClick={() => setIsEditingQuote(false)}>
          Cancel
        </SlimBorderedButton>
      ) : (
        <SlimPrimaryButton onClick={() => setIsEditingQuote(true)}>
          Edit quote
        </SlimPrimaryButton>
      )}
    </Actions>
  )

  if (isFetching) {
    return <P>Loading...</P>
  }

  const initialValues = { type: lpaCase.type || '' }
  return (
    <>
      {isLegacyLpaCase ? (
        <LegacyLpaCaseMessageBox />
      ) : (
        <>
          <H size="M">Sale</H>
          {showPaymentsSummary && (
            <>
              <Overview cost={cost} invoices={invoices} />
              <Divider margin={['L', 0]} />
              <PaymentsSummary
                invoices={invoices}
                onEdit={editCardPayment}
                actions={
                  <SlimPrimaryButton onClick={addCardPayment}>
                    Add card payment
                  </SlimPrimaryButton>
                }
              />
            </>
          )}
          <Divider margin={['L', 0]} />
          {lpaCase && (
            <>
              <Row>
                <H size="S">Quote</H>
                {editableQuoteControls}
              </Row>
              {isEditingQuote ? (
                <EditableQuote
                  lpaCaseId={lpaCaseId}
                  values={initialValues}
                  saveQuote={(quoteAttributes: LpaCaseQuoteAttributes) =>
                    saveQuote(quoteAttributes)
                  }
                  exitEditableQuote={() => setIsEditingQuote(false)}
                />
              ) : (
                <CurrentQuote currentQuote={currentQuote} lpaCase={lpaCase} />
              )}
            </>
          )}
        </>
      )}
    </>
  )
}

export default Quote
