import { useRef, useEffect } from 'react'
import { Button, Grid, H, P, Wrapper } from '@farewill/ui'
import { Formik, Form, useFormikContext } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import Dinero from 'dinero.js'
import { GTR } from '@farewill/ui/tokens'
import { Link } from 'react-router-dom'

import PAYMENTS from 'config/payments'
import PRICING from 'config/pricing'
import {
  NEW_LAW,
  LAW_FIRM_NAMES_OPTIONS,
  LAW_FIRM_NAMES,
  INVOICE_LINE_ITEMS,
} from 'utils/enums'
import {
  generatePaymentReference,
  findDepositInvoice,
  findLineItemByDescription,
  findTask,
} from 'utils/helpers'
import { NEW_DEPOSIT_INVOICE_SCHEMA } from 'lib/formik/schemata'
import { formatDate } from 'lib/formatting/dates'
import { showModal } from 'state/actions'

import InputFloating from 'components/form/input-floating'
import FakeInput from 'components/fake-input'
import IconList from 'components/icon-list'
import ExternalLink from 'components/external-link'
import { useTasksContext } from 'components/tasks-panel/context'

import { useInvoicesContext } from './context'
import DeleteInvoiceModal from './delete-invoice-modal'

const getListItems = ({ isNewLawReferral, reference }) => {
  let items = [
    'Pause call recording before taking payment details',
    'Do not accept payment on the deceased’s card',
    <>
      Use {isNewLawReferral ? 'order' : 'cart'} ID: <strong>{reference}</strong>
    </>,
  ]

  if (isNewLawReferral) {
    items = [
      <>
        Use the <strong>NewLaw iPad</strong> to take the deposit via Global
        Payments
      </>,
      'Tell the customer that we are taking the deposit on behalf of NewLaw',
      ...items,
    ]
  } else {
    items = [
      <>
        Take the deposit via{' '}
        <ExternalLink href={PAYMENTS.WORLDPAY_DASHBOARD_URL}>
          Worldpay
        </ExternalLink>
      </>,
      ...items,
    ]
  }
  return items
}

const PaymentInstructions = ({ leadId }) => {
  const { values, setFieldValue } = useFormikContext()
  const generatedReference = useRef(generatePaymentReference())
  const isNewLawReferral = values.paidTo === NEW_LAW

  useEffect(() => {
    const reference = isNewLawReferral ? leadId : generatedReference.current
    setFieldValue('reference', reference)
  }, [isNewLawReferral, leadId, setFieldValue])

  if (!values.paidTo) return null

  return (
    <Grid.Item>
      <IconList
        items={getListItems({ isNewLawReferral, reference: values.reference })}
      />
    </Grid.Item>
  )
}

const NewDepositForm = () => {
  const { fetchTasks } = useTasksContext()
  const { createInvoice, isCreatingInvoice } = useInvoicesContext()
  const probateCase = useSelector((state) => state.probateCase)
  const probateCaseId = probateCase.id

  const handleSubmit = async ({ paidTo, reference }) => {
    const lineItems = [
      {
        description: INVOICE_LINE_ITEMS.DEPOSIT,
        price: PRICING.PROBATE_PRICING.DEPOSIT,
      },
    ]
    await createInvoice({ probateCaseId, paidTo, reference, lineItems })
    return fetchTasks({
      queryParams: {
        'filter[probateCaseId]': probateCaseId,
        'filter[isNeeded]': true,
        'page[size]': 200,
      },
    })
  }

  return (
    <Formik
      initialValues={{ paidTo: '' }}
      onSubmit={handleSubmit}
      validationSchema={NEW_DEPOSIT_INVOICE_SCHEMA}
    >
      <Form>
        <Grid>
          <Grid.Item span={6}>
            <InputFloating
              component="select"
              name="paidTo"
              options={LAW_FIRM_NAMES_OPTIONS}
              label="Paid to"
            />
          </Grid.Item>
          <PaymentInstructions leadId={probateCase.attributes.leadId} />
          <Grid.Item>
            <Button.Primary type="submit" loading={isCreatingInvoice}>
              Deposit paid
            </Button.Primary>
          </Grid.Item>
        </Grid>
      </Form>
    </Formik>
  )
}

const EditDepositForm = ({ depositInvoice }) => {
  const dispatch = useDispatch()
  const { tasks, fetchTasks } = useTasksContext()
  const depositTask = findTask(tasks, 'deposit_taken')
  const { deleteInvoice, isDeletingInvoice } = useInvoicesContext()
  const depositLineItem = findLineItemByDescription({
    invoice: depositInvoice,
    description: INVOICE_LINE_ITEMS.DEPOSIT,
  })

  const handleDeleteClick = () => {
    const { probateCaseId } = depositInvoice.attributes
    dispatch(
      showModal({
        component: DeleteInvoiceModal,
        headingText: 'Delete this invoice?',
        config: {
          deleteInvoice: async () => {
            await deleteInvoice(depositInvoice.id)
            return fetchTasks({
              queryParams: {
                'filter[probateCaseId]': probateCaseId,
                'filter[isNeeded]': true,
                'page[size]': 200,
              },
            })
          },
        },
      })
    )
  }

  return (
    <Grid>
      <Grid.Item>
        <P>
          <Link
            to={`/probate/cases/${depositInvoice.attributes.probateCaseId}/progress`}
          >
            <strong>Deposit taken</strong>{' '}
            {formatDate(depositTask?.attributes?.completedAt)}
          </Link>
        </P>
      </Grid.Item>
      <Grid.Item span={6}>
        <FakeInput
          label="Paid to"
          value={LAW_FIRM_NAMES[depositInvoice.attributes.paidTo]}
        />
      </Grid.Item>
      <Grid.Item span={3}>
        <FakeInput
          label="Amount (£)"
          value={Dinero({ amount: depositLineItem.price }).toUnit()}
        />
      </Grid.Item>
      <Grid.Item span={6}>
        <FakeInput
          label="Payment reference"
          value={depositInvoice.attributes.reference}
        />
      </Grid.Item>
      <Grid.Item>
        <Button.Secondary
          type="button"
          onClick={handleDeleteClick}
          loading={isDeletingInvoice}
          style={{ paddingTop: GTR.XXS, paddingBottom: GTR.XXS }}
        >
          Delete deposit
        </Button.Secondary>
      </Grid.Item>
    </Grid>
  )
}

const Deposit = () => {
  const { invoices } = useInvoicesContext()
  const depositInvoice = findDepositInvoice(invoices)

  return (
    <Wrapper>
      <H size="S">Deposit</H>
      {depositInvoice ? (
        <EditDepositForm depositInvoice={depositInvoice} />
      ) : (
        <NewDepositForm />
      )}
    </Wrapper>
  )
}

export default Deposit
