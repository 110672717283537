import styled from 'styled-components'
import upperFirst from 'lodash/upperFirst'
import { ArrowRightIcon } from '@farewill/ui'
import { FONT } from '@farewill/ui/tokens'

import StyledGridRow from 'components/list/grid-row'
import StyledLink from 'components/list/link'
import StyledLoadingRow from 'components/list/loading-row'
import NoResults from 'components/list/no-results'
import StyledSortableHeader from 'components/list/sortable-header'
import SortArrow from 'components/sort-arrow'

import {
  FuneralPlan,
  FuneralPlanSortableColumn,
  SortOrder,
} from 'api/generated/graphql'
import { getFullNameWithPreferredName } from 'utils/helpers'
import { Contact } from 'lib/models/contact'

import { getDaysFromToday } from '../helpers'
import { SetPage, SetSort, SetSortOrder, Sort } from '../types'

const GRID_TEMPLATE_COLUMNS = '1fr 1fr 1fr 1fr 18px'

const StyledGridColumn = styled.li`
  font-size: ${FONT.SIZE.S};
`

const Row = ({
  funeralPlan,
}: {
  funeralPlan: FuneralPlan
}): React.ReactElement => (
  <StyledLink to={`/funeral-plans/${funeralPlan.id}`}>
    <StyledGridRow gridTemplateColumns={GRID_TEMPLATE_COLUMNS}>
      <StyledGridColumn>
        {getFullNameWithPreferredName(funeralPlan.purchaser as Contact)}
      </StyledGridColumn>
      <StyledGridColumn>{funeralPlan.package?.name}</StyledGridColumn>
      <StyledGridColumn>
        {getDaysFromToday(funeralPlan.lastActivityAt)}
      </StyledGridColumn>
      <StyledGridColumn>
        {upperFirst(funeralPlan.status as string)}
      </StyledGridColumn>
      <li>
        <ArrowRightIcon size="S" />
      </li>
    </StyledGridRow>
  </StyledLink>
)

type Props = {
  funeralPlans: FuneralPlan[]
  isLoading: boolean
  sort: Sort
  setSort: SetSort
  sortOrder: SortOrder | null | undefined
  setSortOrder: SetSortOrder
  setPage: SetPage
}

const FuneralPlans = ({
  funeralPlans,
  isLoading,
  sort,
  setSort,
  sortOrder,
  setSortOrder,
  setPage,
}: Props): React.ReactElement => (
  <>
    <StyledGridRow header gridTemplateColumns={GRID_TEMPLATE_COLUMNS}>
      <StyledGridColumn>Customer</StyledGridColumn>
      <StyledGridColumn>Funeral plan type</StyledGridColumn>
      <StyledSortableHeader
        onClick={() => {
          setSort(FuneralPlanSortableColumn.Activity)
          setSortOrder(
            sortOrder === SortOrder.Desc ? SortOrder.Asc : SortOrder.Desc
          )
          setPage({})
        }}
      >
        Activity
        <SortArrow
          sorted={sort === FuneralPlanSortableColumn.Activity}
          asc={sortOrder === SortOrder.Desc}
        />
      </StyledSortableHeader>
      <StyledGridColumn>Case status</StyledGridColumn>
    </StyledGridRow>

    {isLoading && <StyledLoadingRow>Loading...</StyledLoadingRow>}
    {!isLoading && !funeralPlans && <NoResults type="cases" />}
    {!isLoading &&
      funeralPlans?.map((funeralPlan) => (
        <Row key={funeralPlan.id} funeralPlan={funeralPlan} />
      ))}
  </>
)

export default FuneralPlans
