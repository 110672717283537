import ConfirmedValueLabel from './components/confirmed-value-label'

export const PROBATE_ESTATE_PROPERTY_ID = 'probateEstatePropertyId'
export const DECEASED_SHARE_OF_JOINT_OWNERSHIP = 'deceasedShareOfJointOwnership'
export const DESCRIPTION = 'description'
export const IS_ESTIMATED_VALUE = 'isEstimatedValue'
export const JOINT_OWNER_NAME = 'jointOwnerName'
export const NOTES = 'notes'
export const OWNERSHIP = 'ownership'
export const PROVIDER_NAME = 'providerName'
export const VALUE = 'value'

export const IS_ESTIMATED_VALUE_OPTIONS = [
  { label: 'Estimate', value: 'true' },
  {
    label: <ConfirmedValueLabel />,
    value: 'false',
  },
]

export const IS_INSURANCE_PAID_OUT = 'isInsurancePaidOut'
export const IS_LUMP_SUM_PENSION_PAYOUT = 'isLumpSumPensionPayout'
export const LUMP_SUM_PAYABLE_TO = 'lumpSumPayableTo'
export const GIFT_RECIPIENT = 'giftRecipient'
export const GIFT = 'gift'
