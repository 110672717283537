import { AdditionalCharge, FuneralAttributes } from 'lib/models/funeral'
import { formatToHuman, formatTreeName } from 'utils/helpers'
import {
  DELIVER,
  DIGNITY,
  FAST_TRACK_DELIVER,
  SCATTER,
  TREE_NONE,
} from 'utils/enums'

export const getService = (): string => {
  return 'Direct cremation'
}

export const getCelebrant = (funeral: FuneralAttributes): string => {
  const { celebrant } = funeral

  if (celebrant) return celebrant.name

  return 'Not answered'
}
/** Key elements are displayed on a Funeral case's "Case Details" tab */
export const getKeyElements = (funeral: FuneralAttributes): string[] => {
  const { coffinType, dressingService, treeType, urnType, provider, urnTypes } =
    funeral

  const noUrnSelected = !urnType && (!urnTypes || urnTypes.length === 0)

  const keyElements = []

  if (coffinType) {
    keyElements.push(formatToHuman(coffinType))
  }

  if (dressingService) {
    keyElements.push('Dressing service')
  }

  if (treeType && treeType !== TREE_NONE) {
    keyElements.push(`${formatToHuman(formatTreeName(treeType))} tree`)
  }

  if ('ashesInstruction' in funeral && funeral.ashesInstruction === SCATTER) {
    keyElements.push('Scatter at crematorium')
  }

  if (
    funeral.ashesInstruction &&
    [DELIVER, FAST_TRACK_DELIVER].includes(funeral.ashesInstruction)
  ) {
    if (noUrnSelected) {
      keyElements.push('Ashes delivered - no urn selected')
    } else {
      urnType && keyElements.push(`${formatToHuman(urnType)}`)

      urnTypes?.map((urnType) => keyElements.push(`${formatToHuman(urnType)}`))
    }
  }

  if (
    !funeral.ashesInstruction ||
    !coffinType ||
    (provider !== DIGNITY && dressingService === null) ||
    noUrnSelected ||
    !treeType
  )
    keyElements.push('Additional options not answered')

  return keyElements
}

export const getSpecialRequests = (
  charges: AdditionalCharge[] | null | undefined
): string[] => {
  if (!charges || !charges.length) return ['Not answered']
  return charges.map((charge) => charge.description)
}

export const getDeathCountryName = (funeral: FuneralAttributes) => {
  const deathCountryName = funeral.deathCountry?.name

  if (deathCountryName === 'Scotland') return 'Scotland'
  if (deathCountryName === 'England' || deathCountryName === 'Wales')
    return 'England and Wales'
  return 'Not answered'
}
