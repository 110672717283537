import { useFormikContext } from 'formik'
import { H } from '@farewill/ui'
import { useQueryClient } from 'react-query'

import { formatAdminUserOptions } from 'utils/helpers'
import SelectInput from 'components/form/select-input'
import { AdminUser } from 'lib/models/admin-user'
import { LpaCaseAttributes } from 'lib/models/lpa-case'

const CaseOwner = () => {
  const cache = useQueryClient()
  const adminUsers = cache.getQueryData<AdminUser[]>('adminUsers')
  const adminUserOptions = formatAdminUserOptions(adminUsers || [])

  const { handleSubmit } = useFormikContext<LpaCaseAttributes>()

  return (
    <>
      <H size="S">Case owner </H>
      <SelectInput
        label="Fact find appointment"
        name="ownedByAdminUserId"
        options={adminUserOptions}
        handleSave={() => handleSubmit()}
        isClearable
      />
    </>
  )
}

export default CaseOwner
