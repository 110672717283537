import { Button, ButtonGroup, P } from '@farewill/ui'
import { useDispatch } from 'react-redux'
import { hideModal } from 'state/actions'

import { RESOURCES, useDeleteResource } from 'api'

type Props = { config: { beneficiaryId: number; willId: number } }

const DeleteBeneficiaryModal = ({
  config: { beneficiaryId, willId },
}: Props): React.ReactElement => {
  const dispatch = useDispatch()
  const deleteMutation = useDeleteResource({
    invalidateQueryKeys: [RESOURCES.GIFTS, `filter[willId]=${willId}`],
  })

  const deleteGiftBeneficiary = async () => {
    await deleteMutation.mutateAsync({
      id: beneficiaryId,
      resource: RESOURCES.GIFT_BENEFICIARIES,
    })
    dispatch(hideModal())
  }

  return (
    <>
      <P>
        Are you sure you want to delete this gift beneficiary? All details will
        be lost.
      </P>
      <ButtonGroup horizontal>
        <Button.Primary
          type="button"
          onClick={deleteGiftBeneficiary}
          loading={deleteMutation.isLoading}
        >
          Delete
        </Button.Primary>
        <Button.Bordered type="button" onClick={() => dispatch(hideModal())}>
          Cancel
        </Button.Bordered>
      </ButtonGroup>
    </>
  )
}

export default DeleteBeneficiaryModal
