import { useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { getUserIdFromToken } from 'lib/authentication/token'
import { Button, P, Wrapper } from '@farewill/ui'
import { BORDER, COLOR, FONT, GTR } from '@farewill/ui/tokens'
import styled from 'styled-components'
import { Formik, Form } from 'formik'
import { useQueryClient } from 'react-query'

import MentionsInput from 'components/form/mentions-input'
import AdminIcon from 'components/admin-icon'
import Switch from 'components/switch'

import { useActivityContext } from './context'

const StyledWrapper = styled(Wrapper)`
  display: flex;
  align-items: flex-start;
  margin-bottom: ${GTR.L};
`

const StyledForm = styled(Form)`
  width: 100%;
`

const StyledFakeFormWrapper = styled(Wrapper)`
  display: flex;
  justify-content: space-between;
  background-color: ${COLOR.WHITE};
  border: 2px solid ${COLOR.GREY.LIGHT};
  border-top: 0;
  padding: 0 ${GTR.S} ${GTR.S};
  border-radius: 0 0 ${BORDER.RADIUS.S} ${BORDER.RADIUS.S};
  margin-top: -2px;
  position: relative;
`

const StyledSwitchWrapper = styled(Wrapper)`
  display: flex;
  align-items: flex-end;
`

const StyledSaveButton = styled(Button.Secondary)`
  font-size: ${FONT.SIZE.M};
  padding: ${GTR.XXS} ${GTR.M};
  margin-right: ${GTR.M};
`

const StyledCancelButton = styled(Button)`
  font-size: ${FONT.SIZE.S};
  text-decoration: underline;
  color: ${COLOR.GREY.MEDIUM};

  && {
    padding: 0;
  }

  &:hover {
    text-decoration: underline;
  }
`

const CommentForm = ({
  handleSubmit,
  iconSize,
  placeholder,
  initialValues,
  isEdit,
  handleCancelClick,
  onBlur,
  hasDraftState,
  showIsCallSwitch,
}) => {
  const cache = useQueryClient()
  const adminUsers = cache.getQueryData('adminUsers')
  const { isCreatingComment, isUpdatingComment } = useActivityContext()
  const [isActiveMode, setIsActiveMode] = useState(isEdit)
  const adminUserId = useSelector((state) => getUserIdFromToken(state.token))
  const adminUser = adminUsers.find((user) => user.id === adminUserId)
  const wrapperEl = useRef(null)

  const mentionsData = adminUsers.map((user) => {
    return { id: user.id, display: user.attributes.name }
  })

  const onSubmit = async (values, formik) => {
    await handleSubmit(values)

    if (!isEdit) {
      formik.resetForm()
      setIsActiveMode(false)
    }
  }

  useEffect(() => {
    const setCommentClosed = (e) => {
      if (
        !wrapperEl.current.contains(e.target) &&
        !e.target.className.includes?.('react-mentions')
      ) {
        setIsActiveMode(false)
      }
    }

    window.addEventListener('click', setCommentClosed)
    return () => window.removeEventListener('click', setCommentClosed)
  }, [])

  return (
    <StyledWrapper>
      {!isEdit && adminUser && (
        <AdminIcon user={adminUser.attributes} size={iconSize} />
      )}
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={onSubmit}
      >
        {({ resetForm, setFieldValue, values }) => (
          <StyledForm ref={wrapperEl}>
            <MentionsInput
              data={mentionsData}
              name="content"
              placeholder={placeholder}
              onFocus={() => setIsActiveMode(true)}
              onBlur={onBlur}
            />
            {hasDraftState && !isActiveMode && (
              <StyledFakeFormWrapper>
                <P size="S" color={COLOR.GREY.MEDIUM}>
                  Draft comment saved
                </P>
              </StyledFakeFormWrapper>
            )}
            {isActiveMode && (
              <StyledFakeFormWrapper>
                {showIsCallSwitch && (
                  <StyledSwitchWrapper>
                    <Switch
                      color={COLOR.STATE.WARNING}
                      handleToggle={() => {
                        setFieldValue('isCall', !values.isCall)
                        onBlur &&
                          onBlur({
                            content: values.content,
                            mentions: values.mentions,
                            isCall: values.isCall,
                          })
                      }}
                      isOn={values.isCall}
                    />
                    <P size="S" margin={[0, 0, 0, 'S']}>
                      Included customer contact
                    </P>
                  </StyledSwitchWrapper>
                )}
                <Wrapper>
                  <StyledSaveButton
                    type="submit"
                    loading={isCreatingComment || isUpdatingComment}
                  >
                    Save
                  </StyledSaveButton>
                  <StyledCancelButton
                    type="button"
                    onClick={() => {
                      setTimeout(() => {
                        handleCancelClick && handleCancelClick()
                        setIsActiveMode(false)
                        resetForm()
                      })
                    }}
                  >
                    Cancel
                  </StyledCancelButton>
                </Wrapper>
              </StyledFakeFormWrapper>
            )}
          </StyledForm>
        )}
      </Formik>
    </StyledWrapper>
  )
}

CommentForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  iconSize: PropTypes.oneOf(['M', 'L']),
  placeholder: PropTypes.string,
  isEdit: PropTypes.bool,
  initialValues: PropTypes.shape({
    content: PropTypes.string,
    mentions: PropTypes.arrayOf(PropTypes.string),
  }),
  handleCancelClick: PropTypes.func,
  onBlur: PropTypes.func,
  hasDraftState: PropTypes.bool,
}

CommentForm.defaultProps = {
  iconSize: 'L',
  isEdit: false,
  initialValues: { content: '', mentions: [] },
  placeholder: '',
  handleCancelClick: null,
  onBlur: null,
  hasDraftState: false,
}

export default CommentForm
