const FETCH_SEARCH_RESULTS = 'FETCH_SEARCH_RESULTS'

export default function searchResults(state = [], action = {}) {
  switch (action.type) {
    case FETCH_SEARCH_RESULTS + '_FULFILLED':
      return action.payload.data.data

    case FETCH_SEARCH_RESULTS + '_REJECTED':
      return []

    default:
      return state
  }
}
