import { Grid, Wrapper } from '@farewill/ui'
import { useQueryClient } from 'react-query'
import { formatAdminUserOptions } from 'utils/helpers'

import SelectInput from 'components/form/select-input'
import { AdminUser } from 'lib/models/admin-user'

const CaseOwners = ({ handleSave }: { handleSave: () => void }) => {
  const cache = useQueryClient()
  const adminUsers = cache.getQueryData('adminUsers') as AdminUser[]
  const adminUserOptions = formatAdminUserOptions(adminUsers)

  return (
    <>
      <Grid.Item maxWidthInColumns={4}>
        <Wrapper margin={['M', 0]}>
          <SelectInput
            options={adminUserOptions}
            label="Case owner"
            name="caseOwnerId"
            handleSave={handleSave}
            isClearable
          />
        </Wrapper>

        <Wrapper>
          <SelectInput
            options={adminUserOptions}
            label="Holiday cover"
            name="holidayCoverOwnerId"
            handleSave={handleSave}
            isClearable
          />
        </Wrapper>
      </Grid.Item>
    </>
  )
}

export default CaseOwners
