import { Grid, H } from '@farewill/ui'
import { QuestionConfig } from '../types'
import Question from './question'

type DiagnosisProps = {
  title?: string
  smallTitle?: boolean
  subHeading?: string
  questions?: QuestionConfig[]
}

const Diagnosis = ({
  title,
  smallTitle,
  subHeading,
  questions,
}: DiagnosisProps) => {
  if (questions && questions.length === 0) return null

  return (
    <Grid margin={['L', 0]}>
      {title && (
        <Grid.Item>
          <H margin={['M', 0, 'XS']} size={smallTitle ? 'S' : 'M'}>
            {title}
          </H>
          {subHeading && <p>{subHeading}</p>}
        </Grid.Item>
      )}
      {questions?.map((question) => (
        <Question key={question.name} data={question} />
      ))}
    </Grid>
  )
}

export default Diagnosis
