import styled from 'styled-components'
import { Button, Divider, Wrapper, P } from '@farewill/ui'
import { BORDER, COLOR, GTR } from '@farewill/ui/tokens'

const StyledPreviewWrapper = styled(Wrapper)`
  background-color: ${COLOR.BACKGROUND.FOG};
  border: 1px solid ${COLOR.GREY.LIGHT};
  border-radius: ${BORDER.RADIUS.S};
  padding: ${GTR.M};
`

const StyledImageWrapper = styled(Wrapper)`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`

const StyledPreviewImage = styled.img`
  width: 200px;
`

type Props = {
  blob?: Blob | null
  existingUploadUrl?: string
  name: string
  onDelete: (isUnsavedImage: boolean) => void
}

// TODO: Make this generic of Wills

/**
 * In this component we rely on the implict behaviour that if the blob field is set we haven't uploaded the image yet
 * (i.e. it hasn't made it to the database) and if a URL is provided that means that this image has previously
 * been set and saved.
 * If that changes (we use this component and save on blur for instance without updating the formik state!)
 * then this component will not function correctly.
 * @param props See props type
 * @returns React component
 */
const Preview = ({
  blob,
  existingUploadUrl,
  name,
  onDelete,
}: Props): React.ReactElement => {
  const isNewImage = !!blob
  const previewUrl = isNewImage ? URL.createObjectURL(blob) : existingUploadUrl

  if (!previewUrl) {
    throw new Error('Unable to display image as no image specified')
  }

  return (
    <StyledPreviewWrapper>
      <StyledImageWrapper>
        <StyledPreviewImage src={previewUrl} alt="Image preview" />
        <Button.Underline
          type="button"
          onClick={() => onDelete(isNewImage)}
          flush
        >
          {blob ? 'Replace' : 'Delete'}
        </Button.Underline>
      </StyledImageWrapper>
      <Divider margin={['M', 0, 'XS']} />
      <P size="S">
        This is how the {name} will be displayed to customers as part of the
        online wills journey.
      </P>
    </StyledPreviewWrapper>
  )
}

export default Preview
