import { Button, Grid, H } from '@farewill/ui'
import { useDispatch, useSelector } from 'react-redux'
import { useFormikContext } from 'formik'

import { showModal } from 'state/actions'
import { LAW_FIRM_NAMES } from 'utils/enums'

import Input from 'components/form/input'
import MessageBox from 'components/message-box'
import { useTasksContext } from 'components/tasks-panel/context'
import { ProbateCase } from 'lib/models/probate-case'
import { Task } from 'lib/models/task'

import ReferralModal from './referral-modal'

const Referral = () => {
  const { handleSubmit } = useFormikContext()

  const dispatch = useDispatch()
  const { tasks, fetchTasks } = useTasksContext()
  const probateCase: ProbateCase = useSelector(
    (state: { probateCase: ProbateCase }) => state.probateCase
  )

  const referralMadeTask = tasks.find(
    (task: Task) => task.attributes.coreTask === 'referral_made'
  )

  const MODAL_HEADING = 'Who has this case been referred to?'

  const { referredTo } = probateCase.attributes
  return (
    <>
      <Grid.Item>
        <H size="S">Referral</H>
      </Grid.Item>
      <Grid.Item>
        <MessageBox>
          If there are any discounts, add the final agreed price below
        </MessageBox>
      </Grid.Item>
      <Grid.Item>
        <Input
          name="criticalNotes"
          label="Handover notes"
          rows={3}
          component="textarea"
          handleSave={() => handleSubmit()}
        />
      </Grid.Item>
      <Grid.Item>
        {referredTo ? (
          <Button.Underline
            onClick={() =>
              dispatch(
                showModal({
                  component: ReferralModal,
                  headingText: MODAL_HEADING,
                  config: { probateCase, referralMadeTask, fetchTasks },
                })
              )
            }
            flush
          >
            Referred to {LAW_FIRM_NAMES[referredTo]}
          </Button.Underline>
        ) : (
          <Button.Primary
            onClick={() =>
              dispatch(
                showModal({
                  component: ReferralModal,
                  headingText: MODAL_HEADING,
                  config: { probateCase, referralMadeTask, fetchTasks },
                })
              )
            }
          >
            Make referral
          </Button.Primary>
        )}
      </Grid.Item>
    </>
  )
}

export default Referral
