import { createContext, useContext } from 'react'
import useApiHelpers from 'lib/effects/api-helpers'

const CrematoriaContext = createContext()

const useCrematoriaContext = () => {
  const context = useContext(CrematoriaContext)
  if (context === undefined) {
    throw new Error(
      'useCrematoriaContext must be used within a CrematoriaProvider'
    )
  }
  return context
}

const CrematoriaProvider = ({ children }) => {
  const {
    items: crematoria,
    fetchItems: fetchCrematoria,
    isFetching: isFetchingCrematoria,
    updateItem: updateCrematorium,
    isUpdatingItem: isUpdatingCrematorium,
    createItem: createCrematorium,
    isCreating: isCreatingCrematorium,
    deleteItem: deleteCrematorium,
  } = useApiHelpers({
    baseUrl: `/api/crematoria`,
    type: 'crematoria',
  })

  const value = {
    crematoria,
    fetchCrematoria,
    isFetchingCrematoria,
    updateCrematorium,
    isUpdatingCrematorium,
    createCrematorium,
    isCreatingCrematorium,
    deleteCrematorium,
  }

  return (
    <CrematoriaContext.Provider value={value}>
      {children}
    </CrematoriaContext.Provider>
  )
}
export { CrematoriaProvider, useCrematoriaContext }
