import styled from 'styled-components'
import { FONT, GTR } from '@farewill/ui/tokens'

export const Uppercase = styled.h6`
  font-weight: ${FONT.WEIGHT.BOLD};
  text-transform: uppercase;
  font-size: ${FONT.SIZE.XS};
  letter-spacing: 1.2px;
  margin: 0 0 ${GTR.XS};

  ${({ color }) => color && `color: ${color}`}
`
