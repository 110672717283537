import { P, Wrapper } from '@farewill/ui'
import { GTR } from '@farewill/ui/tokens'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

import AdminIcon from 'components/admin-icon'
import ActivityTimestamp from 'components/activity-timestamp'
import { ERROR_MADE_BY } from './constants'
import { isEdited } from './helpers'

const StyledWrapper = styled(Wrapper)`
  display: flex;
  margin-bottom: ${GTR.L};
`

const StyledLink = styled(Link)`
  font-weight: bold;
`

const Error = ({ error }) => {
  const { adminUser, probateCaseId, createdAt, updatedAt, madeBy } =
    error.attributes

  const { edited, lastInteractedWith } = isEdited(createdAt, updatedAt)

  return (
    <StyledWrapper>
      <AdminIcon user={adminUser} size="L" />
      <Wrapper>
        <P margin={0} size="S">
          <strong>{adminUser.name}</strong> logged{' '}
          <StyledLink to={`/probate/cases/${probateCaseId}/errors`}>
            an error
          </StyledLink>
          {ERROR_MADE_BY[madeBy] && `, made by ${ERROR_MADE_BY[madeBy]}`}
        </P>
        <ActivityTimestamp datetime={lastInteractedWith} isEdited={edited} />
      </Wrapper>
    </StyledWrapper>
  )
}

export default Error
