import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Divider, H, Wrapper } from '@farewill/ui'
import ProductSummary from 'components/product-summary'
import FactFind from 'components/fact-find-case-page/fact-find-call-at'
import { useSelector, shallowEqual } from 'react-redux'
import { InvoicesProvider, useInvoicesContext } from './context'

import Price from './price'
import Deposit from './deposit'
import ReferralEmailSummary from './referral-email-summary'
import RequestStatus from 'components/request-status'

const ProbateCaseSale = () => {
  const { id: probateCaseId } = useParams()
  const { fetchInvoices, isUpdatingLineItem, updateLineItemErrors } =
    useInvoicesContext()
  const probateCase = useSelector((state) => state.probateCase)
  const referralEmailSent = !!probateCase.attributes.newLawReferral

  useEffect(() => {
    fetchInvoices({ queryParams: { 'filter[probateCaseId]': probateCaseId } })
  }, [fetchInvoices, probateCaseId])

  const { events, lead } = useSelector(
    (state) => ({
      events: state.productEvents,
      lead: state.lead.attributes && state.lead,
    }),
    shallowEqual
  )

  return (
    <Wrapper>
      <H size="M" margin={[0, 0, 'XL', 0]}>
        Sale
      </H>
      <Price />
      <Divider margin={['XL', 0]} />
      <Deposit />
      <Divider margin={['XL', 0]} />
      <FactFind productType="probate" />
      <Divider margin={['XL', 0]} />
      <H size="S" margin={['XL', 0]}>
        Quote
      </H>
      {referralEmailSent && <ReferralEmailSummary />}
      <ProductSummary
        productType="probate"
        productEvents={events}
        lead={lead}
      />
      <RequestStatus
        isSaving={isUpdatingLineItem}
        apiErrors={updateLineItemErrors}
      />
    </Wrapper>
  )
}

const SaleWithProvider = ({ match }) => (
  <InvoicesProvider probateCaseId={match.params.id}>
    <ProbateCaseSale />
  </InvoicesProvider>
)

export default SaleWithProvider
