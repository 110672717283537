const CREATE_LEAD_IMPORT = 'CREATE_LEAD_IMPORT'

export default function leadImports(state = {}, action = {}) {
  switch (action.type) {
    case 'RESET_' + CREATE_LEAD_IMPORT + '_ERRORS':
      return { errors: [] }

    case CREATE_LEAD_IMPORT + '_FULFILLED':
      return { errors: [] }

    case CREATE_LEAD_IMPORT + '_REJECTED':
      return { errors: action.payload.response.data.errors || [] }

    default:
      return state
  }
}
