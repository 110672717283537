import PropTypes from 'prop-types'
import { Grid, H, Wrapper, Image } from '@farewill/ui'
import { useFormikContext } from 'formik'

import DatetimePicker from 'components/form/datetime-picker'
import Skip from 'components/skip'

const BookFactFind = ({ skip }) => {
  const { handleSubmit } = useFormikContext()
  return (
    <Grid data-scroll-id="book-fact-find">
      <Grid.Item span={6}>
        <Grid>
          <Grid.Item>
            <H size="S">Book fact-find</H>
          </Grid.Item>
          <Grid.Item>
            {skip ? (
              <Skip next="caller-details">Skip this section</Skip>
            ) : (
              <DatetimePicker
                label="Date and time for fact-find call"
                name="quoteAttributes.factFindCallAt"
                handleSave={handleSubmit}
              />
            )}
          </Grid.Item>
        </Grid>
      </Grid.Item>
      <Grid.Item span={6}>
        <Wrapper style={{ textAlign: 'right' }}>
          <Image
            path="illustrations/calendar"
            width={200}
            style={{ margin: '-40px -30px 0px 0px' }}
          />
        </Wrapper>
      </Grid.Item>
    </Grid>
  )
}

BookFactFind.propTypes = {
  skip: PropTypes.bool,
}

PropTypes.defaultProps = {
  skip: false,
}

export default BookFactFind
