import { useEffect } from 'react'
import styled from 'styled-components'
import Dinero from 'dinero.js'
import { useDispatch } from 'react-redux'
import Tippy from '@tippy.js/react'

import GridRow from 'components/list/grid-row'
import { showModal } from 'state/actions'

import { Button, H, P, Wrapper } from '@farewill/ui'
import { headingS } from '@farewill/ui/helpers/text'
import { COLOR, FONT, GTR } from '@farewill/ui/tokens'

import { CrematoriaProvider, useCrematoriaContext } from './context'
import EditModal from './edit-modal'

const StyledGridHeaderRow = styled(GridRow)`
  position: sticky;
  top: 0;
  background-color: ${COLOR.BACKGROUND.FOG};
  z-index: 1;
`

const StyledGridRow = styled(GridRow)`
  cursor: initial;
`
const StyledGridColumn = styled.li`
  font-size: ${FONT.SIZE.S};
  transform: none;
`

const Address = styled(P)`
  font-size: ${FONT.SIZE.XS};
`

const StyledCount = styled(Wrapper)`
  ${headingS}
`

const GRID_TEMPLATE_COLUMNS = '1.1fr 0.5fr 0.5fr 0.5fr 0.5fr 0.1fr'

export const CrematoriaList = () => {
  const dispatch = useDispatch()
  const { crematoria, fetchCrematoria, isFetching, updateCrematorium } =
    useCrematoriaContext()

  useEffect(() => {
    fetchCrematoria()
  }, [fetchCrematoria])

  const showEditModal = (crematorium) => {
    dispatch(
      showModal({
        component: EditModal,
        config: {
          updateCrematorium: (attributes) =>
            updateCrematorium(crematorium.id, attributes),
          crematorium,
        },
        headingText: 'Edit crematorium',
      })
    )
  }

  return (
    <CrematoriaProvider>
      <Wrapper padding={[GTR.L, 0, 0]}>
        <H size="L" decorative>
          Crematoria
        </H>
      </Wrapper>
      <StyledCount tag="p" margin={['L', 0]}>
        {crematoria.length} crematoria
      </StyledCount>

      <StyledGridHeaderRow header gridTemplateColumns={GRID_TEMPLATE_COLUMNS}>
        <StyledGridColumn>Name</StyledGridColumn>
        <StyledGridColumn>Off-peak fee</StyledGridColumn>
        <StyledGridColumn>Off-peak length</StyledGridColumn>
        <StyledGridColumn>Peak fee</StyledGridColumn>
        <StyledGridColumn>Peak length</StyledGridColumn>
        <StyledGridColumn />
      </StyledGridHeaderRow>

      {isFetching && <div>...Loading</div>}
      {crematoria &&
        crematoria.map((c) => {
          return (
            <Row
              key={c.id || c.attributes.externalId}
              c={c}
              onEdit={() => showEditModal(c)}
            />
          )
        })}
    </CrematoriaProvider>
  )
}

const Row = ({ c, onEdit }) => {
  const inDatabase = c.id
  const {
    name,
    address,
    earlyMorningPrice,
    earlyMorningServiceLength,
    regularPrice,
    regularServiceLength,
  } = c.attributes

  return (
    <StyledGridRow gridTemplateColumns={GRID_TEMPLATE_COLUMNS}>
      <StyledGridColumn>
        <P margin={[0]}>{name}</P>
        <Address margin={[0]}>
          {address !== '' ? address : 'No address'}
        </Address>
      </StyledGridColumn>
      <StyledGridColumn>
        <Price amount={earlyMorningPrice} />
      </StyledGridColumn>
      <StyledGridColumn>
        <ServiceLength value={earlyMorningServiceLength} />
      </StyledGridColumn>
      <StyledGridColumn>
        <Price amount={regularPrice} />
      </StyledGridColumn>
      <StyledGridColumn>
        <ServiceLength value={regularServiceLength} />
      </StyledGridColumn>
      <StyledGridColumn>
        {inDatabase ? (
          <Button.Underline onClick={onEdit}>edit</Button.Underline>
        ) : (
          <Warning content="Not in database" />
        )}
      </StyledGridColumn>
    </StyledGridRow>
  )
}

const Price = ({ amount }) => {
  return amount != null
    ? Dinero({
        amount: amount,
        currency: 'GBP',
      }).toFormat('$0,0.00')
    : '-'
}

const ServiceLength = ({ value }) => {
  return value != null ? `${value} mins` : '-'
}

const Warning = ({ content }) => {
  return (
    <Tippy content={content}>
      <span>⚠️</span>
    </Tippy>
  )
}
