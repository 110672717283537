import styled from 'styled-components'
import { useFormikContext } from 'formik'
import Label from 'components/styled/label'
import { P } from '@farewill/ui'
import { BORDER, COLOR, FONT, GTR } from '@farewill/ui/tokens'

import AutosizeInput from 'components/form/autosize-input'

export type CriticalNotesProps = {
  label?: string
}

const StyledField = styled(AutosizeInput)`
  font-size: ${FONT.SIZE.M};
  color: ${COLOR.BLACK};
  width: 100%;
  padding: ${GTR.S};
  border: 2px solid ${COLOR.GREY.LIGHT};
  border-radius: ${BORDER.RADIUS.S};

  &:focus {
    outline: none;
  }
`

export const CriticalNotes = ({ label }: CriticalNotesProps): JSX.Element => {
  const { handleSubmit } = useFormikContext()

  return (
    <>
      <P margin={[0, 0, 'XS']}>Critical notes</P>
      <Label $error={false} htmlFor="critical-notes-textfield">
        {label}
      </Label>
      <StyledField
        id="critical-notes-textfield"
        name="criticalNotes"
        placeholder="Critical notes"
        onBlur={handleSubmit}
      />
    </>
  )
}

export default CriticalNotes
