import styled from 'styled-components'
import { Grid, H } from '@farewill/ui'
import { useFormikContext } from 'formik'

import Checkbox from 'components/form/checkbox'
import MessageBox from 'components/message-box'
import { Contact } from 'lib/models/contact'
import {
  LIMC_CONSENT_TYPE,
  LIMC_CONTACT_TYPE,
} from 'lib/models/legitimate-interest-marketing-consent'
import type { StringKeyOf } from 'utils/types/string-key-of'

const StyledCheckboxGridItem = styled(Grid.Item)`
  display: flex;
`

const MarketingConsents = ({
  contact,
  consents,
  handleSubmit,
}: {
  contact: Contact
  consents: Record<
    StringKeyOf<typeof LIMC_CONSENT_TYPE>,
    Record<StringKeyOf<typeof LIMC_CONTACT_TYPE>, boolean>
  >
  handleSubmit: () => void
}): React.ReactElement | null => {
  const { setFieldValue } = useFormikContext()

  if (!contact.phoneNumber && !contact.email && !contact.addresses.length) {
    return null
  }

  const isConsentUniformAcrossChannels =
    new Set(Object.values(consents.general)).size === 1

  const onConsentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.checked
    setFieldValue('marketingConsents.general.email', value)
    setFieldValue('marketingConsents.general.phoneNumber', value)
    setFieldValue('marketingConsents.general.postal', value)
  }

  return (
    <>
      <Grid.Item margin={['S', 0, 0, 0]}>
        <H size="XS">Marketing consents</H>

        {contact.doNotContact && (
          <MessageBox stretch margin={['S', 0]}>
            This person’s marketing consent cannot be updated as they have
            requested not to be contacted.
          </MessageBox>
        )}
      </Grid.Item>

      {isConsentUniformAcrossChannels ? (
        <StyledCheckboxGridItem>
          <Checkbox
            name="marketingConsents.general.all"
            label="Opted in to marketing"
            checked={consents.general.email}
            disabled={contact.doNotContact}
            onChange={onConsentChange}
            handleSave={() => handleSubmit()}
          />
        </StyledCheckboxGridItem>
      ) : (
        <>
          {contact.phoneNumber && (
            <StyledCheckboxGridItem>
              <Checkbox
                name="marketingConsents.general.phoneNumber"
                label="Telephone number"
                checked={consents.general.phoneNumber}
                disabled={contact.doNotContact}
                onChange={onConsentChange}
                handleSave={() => handleSubmit()}
              />
            </StyledCheckboxGridItem>
          )}
          {contact.email && (
            <StyledCheckboxGridItem>
              <Checkbox
                name="marketingConsents.general.email"
                label="Email"
                checked={consents.general.email}
                disabled={contact.doNotContact}
                onChange={onConsentChange}
                handleSave={() => handleSubmit()}
              />
            </StyledCheckboxGridItem>
          )}
          {contact.addresses.length > 0 && (
            <StyledCheckboxGridItem>
              <Checkbox
                name="marketingConsents.general.postal"
                label="Postal"
                checked={consents.general.postal}
                disabled={contact.doNotContact}
                onChange={onConsentChange}
                handleSave={() => handleSubmit()}
              />
            </StyledCheckboxGridItem>
          )}
        </>
      )}
    </>
  )
}

export default MarketingConsents
