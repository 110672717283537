import React, { useEffect, useState, useRef } from 'react'
import styled from 'styled-components'
import { Button, ChevronDownIcon } from '@farewill/ui'
import { COLOR, FONT, GTR } from '@farewill/ui/tokens'

import Dropdown from 'components/dropdown'

interface DropdownItem {
  text: string
  onClick: () => void
}

export type Props = {
  buttonText: string
  dropdownItems: DropdownItem[]
}

const StyledList = styled.ul`
  margin: 0;
`

const StyledButton = styled(Button.Secondary)`
  min-width: 276px;
`

const StyledDropdownButton = styled(Button.Plain)`
  margin: 0;
  padding: ${GTR.XS} ${GTR.S};
  font-weight: ${FONT.WEIGHT.REGULAR};
  justify-content: flex-start;

  &:hover,
  &:focus:not([disabled]),
  &:active:not([disabled]) {
    box-shadow: none;
    background-color: ${COLOR.BACKGROUND.SMOKE};
  }
`

const DropdownButton = ({
  buttonText,
  dropdownItems,
}: Props): React.ReactElement => {
  const [showDropdown, setShowDropdown] = useState(false)
  const listElementRef = useRef<HTMLUListElement>(null)

  const toggleDropdown = () => {
    setShowDropdown((currentState) => !currentState)
  }

  useEffect(() => {
    const handleKeydown = (event: KeyboardEvent) => {
      if (
        showDropdown &&
        ['Escape', 'Enter', 'ArrowDown', 'ArrowUp'].includes(event.key)
      ) {
        event.preventDefault()

        if (event.key === 'Escape') toggleDropdown()

        const optionElements = listElementRef.current?.childNodes
        if (!optionElements || !optionElements.length) return
        const optionButtons = Array.from(optionElements).map(
          (element) => element.firstChild as HTMLButtonElement
        )
        const currentIndex = document.activeElement
          ? optionButtons.indexOf(document.activeElement as HTMLButtonElement)
          : -1

        if (event.key === 'Enter' && currentIndex >= 0) {
          dropdownItems[currentIndex].onClick()
          toggleDropdown()
        }

        if (event.key === 'ArrowDown' || event.key === 'ArrowUp') {
          let newIndex = 0

          if (
            event.key === 'ArrowDown' &&
            currentIndex >= 0 &&
            currentIndex < optionButtons.length - 1
          ) {
            newIndex = currentIndex + 1
          }

          if (event.key === 'ArrowUp' && currentIndex >= 0) {
            newIndex = currentIndex
              ? currentIndex - 1
              : optionButtons.length - 1
          }

          optionButtons[newIndex].focus()
        }
      }
    }

    document.addEventListener('keydown', handleKeydown)
    return () => document.removeEventListener('keydown', handleKeydown)
  }, [showDropdown, dropdownItems])

  return (
    <>
      <Dropdown
        trigger={
          <StyledButton type="button" icon={<ChevronDownIcon />} separateIcon>
            {buttonText}
          </StyledButton>
        }
        content={
          <StyledList ref={listElementRef}>
            {dropdownItems.map((item) => (
              <li key={item.text}>
                <StyledDropdownButton
                  type="button"
                  onClick={() => {
                    item.onClick()
                    toggleDropdown()
                  }}
                  stretch
                >
                  {item.text}
                </StyledDropdownButton>
              </li>
            ))}
          </StyledList>
        }
        alignment="left"
        isVisible={showDropdown}
        toggleCallback={toggleDropdown}
      />
    </>
  )
}

export default DropdownButton
