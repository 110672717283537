import styled from 'styled-components'
import { Button, P, Wrapper } from '@farewill/ui'
import { useDispatch } from 'react-redux'

import { hideModal } from 'state/actions'

const StyledButtonWrapper = styled(Wrapper)`
  display: flex;
  justify-content: space-between;
`

type DeleteModalProps = {
  config: {
    deleteCallback: () => void
    bodyText?: string
    buttonLabel?: string
  }
}

const DeleteModal = ({ config }: DeleteModalProps) => {
  const dispatch = useDispatch()

  const onConfirmDelete = async () => {
    config.deleteCallback()
    dispatch(hideModal())
  }

  return (
    <Wrapper>
      <P>{config.bodyText}</P>

      <StyledButtonWrapper margin={['M', 0]}>
        <Button.Bordered type="button" onClick={() => dispatch(hideModal())}>
          Cancel
        </Button.Bordered>

        <Button.Primary type="button" onClick={onConfirmDelete}>
          {config.buttonLabel}
        </Button.Primary>
      </StyledButtonWrapper>
    </Wrapper>
  )
}

export default DeleteModal
