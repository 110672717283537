import { Wrapper, Button, H, Grid, P } from '@farewill/ui'
import { Divider } from '@farewill/ui'
import { COLOR, FONT } from '@farewill/ui/tokens'

import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import moment from 'moment'
import capitalize from 'lodash/capitalize'

import { aircallUrlFromCallId, formatSeconds } from 'utils/helpers'
import { formatDate } from 'lib/formatting/dates'
import { Task } from 'lib/models/task'
import { WillCase } from 'lib/models/will-case'
import { showModal } from 'state/actions'
import ExternalLink from 'components/external-link'
import { useTasksContext } from 'components/tasks-panel/context.js'
import DropdownButton from 'components/form/dropdown-button'
import getWillBookingURL, { BookingType } from 'lib/will/get-will-booking-url'
import { CustomerCall } from 'lib/models/customer-call.js'
import AddCall from './add-call-modal.js'
import DeleteCall from './delete-call-modal'

const StyledWrapper = styled(Wrapper)`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const StyledButton = styled(Button)`
  font-weight: ${FONT.WEIGHT.BOLD};
  text-decoration: underline;
`

const FactFindCalls = ({
  willCase,
}: {
  willCase: WillCase
}): React.ReactElement => {
  const { tasks } = useTasksContext()
  const { id: willCaseId } = useParams<{ id: string }>()
  const { customerCalls, lead } = willCase.attributes
  const sortedCustomerCalls = customerCalls.sort((currentCall, nextCall) => {
    const currentCallSortDate = currentCall.startTime || currentCall.createdAt
    const nextCallSortDate = nextCall.startTime || nextCall.createdAt
    return currentCallSortDate > nextCallSortDate ? 1 : -1
  })
  const dispatch = useDispatch()

  const showEditModal = (
    status: 'completed' | 'missed',
    cancellationFee?: number
  ) => {
    dispatch(
      showModal({
        component: AddCall,
        config: {
          willCaseId,
          status: status === 'completed' ? 'connected' : 'missed',
          cancellationFee,
        },
        headingText: `Add ${status} call`,
      })
    )
  }

  const showDeleteModal = (customerCallId: number) => {
    dispatch(
      showModal({
        component: DeleteCall,
        config: {
          willCaseId,
          customerCallId,
        },
        headingText: 'Delete call',
      })
    )
  }

  const completeFactFindTask = tasks.find(
    (task: Task) =>
      task.attributes.coreTask === 'complete_fact_find_appointment'
  )

  const factFindCallAt =
    completeFactFindTask?.attributes.dueOn ||
    lead.quoteAttributes?.factFindCallAt

  const factFindCall = customerCalls?.find(
    (call) => call.status === 'scheduled' && call.type === 'fact_find'
  )

  return (
    <Grid>
      <Grid.Item>
        <H size="S">Fact find calls</H>
      </Grid.Item>
      {factFindCallAt && !factFindCall && (
        <Grid.Item>
          <StyledWrapper padding={0}>
            <P>
              Fact find booked for: {formatDate(factFindCallAt) || 'Not booked'}
            </P>
          </StyledWrapper>
        </Grid.Item>
      )}

      {sortedCustomerCalls.map((customerCall) => {
        const aircallUrl = aircallUrlFromCallId(
          customerCall.externalAircallCallId
        )

        const callType =
          customerCall.type === 'fact_find' ? 'fact find' : 'follow-up'

        const callStartTimeSnippet = customerCall.startTime
          ? moment(customerCall.startTime).format('Do MMMM YYYY, h:mm a')
          : 'Unable to retrieve details'

        const callDurationSnippet = customerCall.duration
          ? `${formatSeconds(customerCall.duration, 'long')}`
          : 'Unable to retrieve details'

        const connectedCustomerCallsOfType = sortedCustomerCalls.filter(
          (call) =>
            call.status === 'connected' && call.type === customerCall.type
        )

        const ConnectedFactFindCall = () => (
          <>
            <StyledWrapper padding={['M', 0]}>
              <H margin={0} color={COLOR.GREY.DARK}>
                {capitalize(callType)} call{' '}
                {connectedCustomerCallsOfType.length > 1 &&
                  connectedCustomerCallsOfType.indexOf(customerCall) + 1}
              </H>
              <StyledButton
                type="button"
                onClick={() => showDeleteModal(customerCall.id)}
              >
                Delete
              </StyledButton>
            </StyledWrapper>

            <P>Date/time: {callStartTimeSnippet}</P>
            <P>Call Duration: {callDurationSnippet}</P>
            <P>
              Aircall link:{' '}
              <ExternalLink href={aircallUrl}>{aircallUrl}</ExternalLink>
            </P>
          </>
        )

        const MissedOrCancelledFactFindCall = ({
          status,
        }: {
          status: CustomerCall['status']
        }) => (
          <StyledWrapper padding={['M', 0, 0]}>
            <P margin={0}>
              {status === 'missed' ? 'Missed' : 'Cancelled'} {callType} call:{' '}
              {callStartTimeSnippet}
            </P>
            <StyledButton
              type="button"
              onClick={() => showDeleteModal(customerCall.id)}
            >
              Delete
            </StyledButton>
          </StyledWrapper>
        )

        const ScheduledFactFindCall = () => (
          <StyledWrapper padding={['M', 0, 0]}>
            <P margin={0}>
              {capitalize(callType)} booked for: {callStartTimeSnippet}
            </P>
            <StyledButton
              type="button"
              href={getWillBookingURL({
                type: BookingType.FACT_FIND_MANAGE,
                leadUuid: lead.uuid,
                calendlyEventId: customerCall.calendlyEventId,
                startTime: customerCall.startTime as unknown as string,
              })}
              target="_blank"
              rel="noopener noreferrer"
            >
              Manage booking
            </StyledButton>
          </StyledWrapper>
        )

        return ['connected', 'scheduled', 'missed', 'cancelled'].includes(
          customerCall.status
        ) ? (
          <Grid.Item key={customerCall.id}>
            <Divider />
            {customerCall.status === 'connected' && <ConnectedFactFindCall />}
            {customerCall.status === 'scheduled' && <ScheduledFactFindCall />}
            {(customerCall.status === 'missed' ||
              customerCall.status === 'cancelled') && (
              <MissedOrCancelledFactFindCall status={customerCall.status} />
            )}
          </Grid.Item>
        ) : null
      })}
      <Grid.Item>
        <Divider margin={[0, 0, 'M']} />
        <DropdownButton
          buttonText="Add call"
          dropdownItems={[
            {
              text: 'Completed call',
              onClick: () => showEditModal('completed'),
            },
            {
              text: 'Missed call',
              onClick: () =>
                showEditModal('missed', lead.quoteAttributes?.cancellationFee),
            },
          ]}
        />
      </Grid.Item>
    </Grid>
  )
}
export default FactFindCalls
