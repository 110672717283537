import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'

import { Button, ButtonGroup, Wrapper } from '@farewill/ui'

import { hideModal } from 'state/actions'

import EmailSentRecipients from 'components/email-sent-recipients'

const TaskEmailSentModal = ({ config: { actionTask, task } }) => {
  const dispatch = useDispatch()

  const handleCancel = () => dispatch(hideModal())
  const handleConfirm = async () => {
    await actionTask({ sendEmail: false })

    dispatch(hideModal())

    toast('Task marked as complete.', {
      toastId: 'task-marked-as-complete',
    })
  }

  return (
    <>
      <Wrapper>
        <EmailSentRecipients task={task} />
      </Wrapper>
      <ButtonGroup gap="S" margin={['L', 0, 0]}>
        <Button.Primary type="button" stretch onClick={handleConfirm}>
          Confirm
        </Button.Primary>
        <Button.Underline type="button" onClick={handleCancel}>
          Cancel
        </Button.Underline>
      </ButtonGroup>
    </>
  )
}

export default TaskEmailSentModal
