import styled from 'styled-components'
import { Button, P, Wrapper } from '@farewill/ui'
import { useDispatch } from 'react-redux'

import { hideModal } from 'state/actions'

const StyledButtonWrapper = styled(Wrapper)`
  display: flex;
  justify-content: space-between;
`

const DeleteModal = ({
  config: { documentId, title, isDeletingDocument, deleteDocument },
}) => {
  const dispatch = useDispatch()

  const onConfirmDelete = async () => {
    await deleteDocument(documentId)
    dispatch(hideModal())
  }

  return (
    <Wrapper>
      <P>Are you sure you want to delete this document?</P>
      <P>
        <strong>{title}</strong> will be deleted.
      </P>

      <StyledButtonWrapper margin={['M', 0]}>
        <Button.Bordered
          type="button"
          disabled={isDeletingDocument}
          onClick={() => dispatch(hideModal())}
        >
          Cancel
        </Button.Bordered>

        <Button.Primary
          type="button"
          loading={isDeletingDocument}
          onClick={onConfirmDelete}
        >
          Delete document
        </Button.Primary>
      </StyledButtonWrapper>
    </Wrapper>
  )
}

export default DeleteModal
