import { P, Wrapper } from '@farewill/ui'
import { RESOURCES, useFetchArrayResource } from 'api'
import SelectInput from 'components/form/select-input'
import { Organisation } from 'lib/models/organisation'
import OrganisationFormFields from '../../forms/organisation'

type Props = {
  isManualEntry: boolean
}

const OrganisationFields = ({ isManualEntry }: Props): React.ReactElement => {
  const organisationsQuery = useFetchArrayResource<Organisation>({
    resource: RESOURCES.ORGANISATIONS,
  })

  if (organisationsQuery.isLoading || organisationsQuery.isIdle) {
    return <P>Loading...</P>
  }

  const options = organisationsQuery?.data?.data.map((o) => {
    return { value: o.id, label: o.attributes.name }
  })

  return (
    <Wrapper>
      {isManualEntry ? (
        <OrganisationFormFields namespace="organisation" />
      ) : (
        <SelectInput
          floating
          name="organisation.willBeneficiaryId"
          options={options}
          label="Organisation name"
        />
      )}
    </Wrapper>
  )
}

export default OrganisationFields
