import { P } from '@farewill/ui'
import SelectInput from 'components/form/select-input'
import { CASE_STATUSES_OPTIONS, FAREWILL_PRODUCTS } from 'utils/enums'

import CaseFeatureFilters from '../shared/case-feature-filters'
import FilterWrapper from '../shared/filter-wrapper'

const FuneralPlanFilters = (): React.ReactElement => {
  return (
    <>
      <FilterWrapper>
        <P size="S" strong>
          Case status
        </P>
        <SelectInput
          options={CASE_STATUSES_OPTIONS.funeral_plan}
          name="status"
          isMulti
          small
        />
      </FilterWrapper>
      <FilterWrapper>
        <CaseFeatureFilters product={FAREWILL_PRODUCTS.FUNERAL_PLAN} />
      </FilterWrapper>
    </>
  )
}

export default FuneralPlanFilters
