const FETCH_FUNERAL_PLAN_PAYMENT_DETAILS = 'FETCH_FUNERAL_PLAN_PAYMENT_DETAILS'

export default function funeralPlanPaymentDetails(state = {}, action) {
  switch (action.type) {
    case FETCH_FUNERAL_PLAN_PAYMENT_DETAILS + '_FULFILLED':
      return action.payload.data.data
    case FETCH_FUNERAL_PLAN_PAYMENT_DETAILS + '_PENDING':
      return state
    case FETCH_FUNERAL_PLAN_PAYMENT_DETAILS + '_REJECTED':
      return {}

    default:
      return state
  }
}
