import { ToastContainer } from 'react-toastify'
import styled from 'styled-components'
import { FONT, COLOR, GTR, BORDER } from '@farewill/ui/tokens'
import { toast } from 'react-toastify'
import { Slide } from 'react-toastify'
import { CrossIcon } from '@farewill/ui'

const StyledToastContainer = styled(ToastContainer).attrs({
  className: 'container',
  toastClassName: 'toast',
  bodyClassName: 'body',
})`
  .container {
    display: flex;
    justify-content: center;
  }

  .toast {
    box-shadow: ${BORDER.SHADOW.M};
    padding: ${GTR.XS};
    margin: 0;
    min-height: 0;
    border-radius: ${BORDER.RADIUS.S};
    border: 1px solid ${COLOR.GREY.MEDIUM};
    font-size: ${GTR.S};
    color: ${COLOR.BLACK};

    svg {
      margin-top: 2px;
      min-width: 16px;
      padding-left: ${GTR.XS};
    }

    display: flex;
    align-items: center;
  }

  .Toastify__toast-body > div:last-child {
    flex: initial;
  }

  .body {
    font-family: ${FONT.FAMILY.BODY};
    text-align: center;
  }
`

const CloseButton = ({ closeToast }: { closeToast: () => void }) => (
  <CrossIcon onClick={closeToast} width={12} color={COLOR.GREY.DARK} />
)

const ToastConfiguration = () => (
  <StyledToastContainer
    autoClose={2500}
    draggablePercent={60}
    closeButton={CloseButton}
    hideProgressBar
    position={toast.POSITION.TOP_CENTER}
    transition={Slide}
    limit={1}
  />
)

export default ToastConfiguration
