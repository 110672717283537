import { FormEvent } from 'react'
import { Grid, H } from '@farewill/ui'
import PropTypes from 'prop-types'
import { useFormikContext } from 'formik'

import { WillLeadAttributes } from 'lib/models/lead'
import { SelectOptions } from 'lib/types/common'
import Input from 'components/form/input'
import SelectInput from 'components/form/select-input'
import ContactEmailInput from 'components/form/contact-email-input'
import { Selected } from 'components/form/select-input/types'
import AddressInputWrapper from 'components/address'
import { useLeadContext } from 'routes/lead/context'
import PartnersAndDiscounts from 'routes/lead/partners-and-discounts'
import {
  LEAD_SOURCE_IDENTIFIER_OPTIONS,
  LEAD_SOURCE_TYPE_OPTIONS,
  FAREWILL_PRODUCTS,
  FarewillProduct,
} from 'utils/enums'

import { QuizResults } from './quiz-results'

const CallerDetails = ({ product }: { product: FarewillProduct }) => {
  const { handleSubmit, values, setFieldValue } =
    useFormikContext<WillLeadAttributes>()
  const { updateContact } = useLeadContext()
  const { contact, sourceType } = values

  const resetSourceIdentifierAndSubmit = (value: {
    name: string
    value: Selected
  }) => {
    setFieldValue('sourceIdentifier', null)
    handleSubmit(value as unknown as FormEvent<HTMLFormElement>)
  }

  const sourceIdentifierOptions: SelectOptions =
    LEAD_SOURCE_IDENTIFIER_OPTIONS[
      values.sourceType as keyof typeof LEAD_SOURCE_IDENTIFIER_OPTIONS
    ]

  const sourceTypeOptions = (
    LEAD_SOURCE_TYPE_OPTIONS as [{ value: string; label: string }]
  ).concat({
    value: 'unknown',
    label: 'Unknown',
  })

  const isWill = product === FAREWILL_PRODUCTS.WILL
  return (
    <Grid data-scroll-id="keyInfo">
      <Grid.Item>
        {isWill && <QuizResults {...values.quoteAttributes} />}
        <H size="S">A few details</H>
      </Grid.Item>
      <Grid.Item spanFromL={6}>
        <Input
          label="First name"
          name="contact.firstName"
          handleSave={updateContact}
          allowCopyToClipboard
        />
      </Grid.Item>
      <Grid.Item spanFromL={6}>
        <Input
          label="Last name"
          name="contact.lastName"
          handleSave={updateContact}
          allowCopyToClipboard
        />
      </Grid.Item>
      <Grid.Item spanFromL={6}>
        <Input
          label="Phone number"
          name="contact.phoneNumber"
          handleSave={updateContact}
          allowCopyToClipboard
        />
      </Grid.Item>
      <Grid.Item spanFromL={6}>
        <ContactEmailInput
          contactId={contact.id}
          handleSave={async ({ name, value }) => {
            const updatedLeadResponse = await updateContact({ name, value })

            const updatedContact = updatedLeadResponse.data.attributes.contact

            /**
             * This means the email change has been recognised as already in use
             * and triggered the contact to be merged into an existing contact.
             * We need to update Formik's value for the contactId, otherwise it
             * will refer to the deleted contact.
             *
             * TODO: each product has its own version of this component so this
             * implementation is currently duplicated across all of them.
             */
            if (contact.id !== updatedContact.id) {
              setFieldValue('contactId', updatedContact.id)
              setFieldValue('contact', updatedContact)
            }
          }}
          hasRelatedAccount={!!contact.accountUuid}
          inputName="contact.email"
          allowCopyToClipboard
        />
      </Grid.Item>
      <Grid.Item spanFromL={6}>
        <Input
          label="Date of birth"
          handleSave={updateContact}
          name="contact.dateOfBirth"
          type="date"
        />
      </Grid.Item>
      <Grid.Item spanFromL={6}> </Grid.Item>
      <Grid.Item span={12}>
        <AddressInputWrapper
          data={values.contact.addresses[0]}
          label="Address"
          name="contact.addresses"
          newAddressAttrs={{
            relatedResource: 'contact',
            relatedId: values.contactId,
            relation: 'addresses',
          }}
        />
      </Grid.Item>
      <Grid.Item spanFromL={6}>
        <SelectInput
          label="Lead Type"
          name="sourceType"
          handleSave={resetSourceIdentifierAndSubmit}
          options={sourceTypeOptions}
        />
      </Grid.Item>
      {sourceIdentifierOptions && (
        <Grid.Item span={6}>
          <SelectInput
            key={sourceType}
            label="Source"
            name="sourceIdentifier"
            options={sourceIdentifierOptions}
            handleSave={() => handleSubmit()}
          />
        </Grid.Item>
      )}
      <Grid.Item>
        <PartnersAndDiscounts product={product} />
      </Grid.Item>
    </Grid>
  )
}

CallerDetails.propTypes = {
  product: PropTypes.oneOf(Object.values(FAREWILL_PRODUCTS)),
}

export default CallerDetails
