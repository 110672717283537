import { useEffect, useState } from 'react'
import { useQueryClient } from 'react-query'
import { Form, useField, useFormikContext } from 'formik'
import styled from 'styled-components'
import { Button, Checkbox, Divider, P, Wrapper } from '@farewill/ui'
import { COLOR, FONT, GTR } from '@farewill/ui/tokens'

import SelectInput from 'components/form/select-input'
import FilterPanelWrapper from 'components/list/filter-panel-wrapper'
import PartnerSelect from 'components/partner-select'
import Switch from 'components/switch'
import useLocalStorage from 'hooks/useLocalStorage'
import { AdminUser } from 'lib/models/admin-user'
import { Constants } from 'lib/models/constants'
import {
  FAREWILL_PRODUCTS_OPTIONS,
  FAREWILL_PRODUCTS,
  FUNERAL_BLOCKED_REASONS_OPTIONS,
  FUNERAL_LEAD_STATUS,
  LEAD_PARTNER_TYPES,
  LEAD_SOURCE_IDENTIFIER_OPTIONS,
  LEAD_SOURCE_TYPE_OPTIONS,
  LEAD_STATUS,
  LPA_UNSUITABLE_REASONS_OPTIONS,
} from 'utils/enums'
import {
  showFuneralBlockedReasonSelect,
  showPartnerSelect,
  showUnsuitableReasonSelect,
} from 'utils/helpers'

import LastActivityFilter from './last-activity-filter'
import { LeadListPage, SORT } from '.'

const StyledFilterSwitch = styled(Wrapper)`
  display: flex;
  gap: ${GTR.XS};
`

const FilterHeaders = styled(Wrapper)`
  display: flex;
  align-items: center;
  margin-bottom: ${GTR.S};
  padding-bottom: ${GTR.XS};
  position: relative;
`

const StyledFilter = styled(Wrapper)`
  margin-bottom: ${GTR.L};
`

const StyledOwnerHeader = styled(Wrapper)`
  display: flex;
  justify-content: space-between;
`

const StyledFilterButton = styled(Button.Underline)`
  padding: 0;
  font-size: ${GTR.S};
`

const StyledPersistFiltersButton = styled(Button.Plain)<{
  $isActive: boolean
}>`
  padding: 0;

  span {
    font-weight: ${FONT.WEIGHT.REGULAR};
    font-size: ${FONT.SIZE.S};
  }
`

const FocusCallsTodayCheckbox = () => {
  const [field] = useField({ name: 'callToday', type: 'checkbox' })
  return <StyledCheckbox label="Call scheduled today" size="S" {...field} />
}

const MissedLastCallCheckbox = () => {
  const [field] = useField({ name: 'missedLastCall', type: 'checkbox' })
  return <StyledCheckbox label="Missed last call" size="S" {...field} />
}

const LastChanceToConnectCheckbox = () => {
  const [field] = useField({ name: 'lastChance', type: 'checkbox' })
  return <StyledCheckbox label="Last chance to connect" size="S" {...field} />
}

const TimeSelectedCheckbox = () => {
  const [field] = useField({
    name: 'scheduledNextCallAtTimeSet',
    type: 'checkbox',
  })
  return <StyledCheckbox label="Time selected" size="S" {...field} />
}

type FilterPanelProps = {
  sort: typeof SORT
  page: LeadListPage
  setPage: (page: LeadListPage) => void
  loggedInAdminUserId: number
}

type Reason = {
  label: string
  value: string
}

type Filters = {
  adminUserIds: string[]
  partnerId: string
  product: string[]
  sourceIdentifier: string
  origin: string
  status: string
  unsuitableReason: string
  blockedReason: string
  callToday: boolean
  missedLastCall: boolean
  lastChance: boolean
  scheduledNextCallAtTimeSet: boolean
  lastActivityAt: {
    lte: string
    gte: string
    option: string
  }
}

const StyledCheckbox = styled(Checkbox)`
  margin: ${GTR.S} 0 !important;
`

const ProductCheckbox = ({
  value,
  label,
}: {
  value: string
  label: string
}) => {
  const { setFieldValue, values } = useFormikContext<Filters>()

  const handleUpdatingProductsList = () => {
    if (values.product.includes(value)) {
      setFieldValue(
        'product',
        values.product.filter((product) => product !== value)
      )
    } else {
      setFieldValue('product', [...values.product, value])
    }
  }

  return (
    <StyledCheckbox
      label={label}
      size="S"
      onChange={handleUpdatingProductsList}
      checked={values.product.includes(value)}
    />
  )
}

const FilterPanel = ({
  sort,
  page,
  setPage,
  loggedInAdminUserId,
}: FilterPanelProps) => {
  const cache = useQueryClient()
  const constants = cache.getQueryData('constants') as Constants
  const leadUnsuitableReasonsOptions =
    constants?.attributes?.leadUnsuitableReasonsOptions
  const adminUsers = cache.getQueryData<AdminUser[]>('adminUsers')
  const { setFieldValue, handleSubmit, values } = useFormikContext<Filters>()
  const [unsuitableReasons, setUnsuitableReasons] = useState<Reason[]>([])
  const [localStorageFiltersUsed, setLocalStorageFiltersUsed] =
    useLocalStorage<string>('leadListFiltersUsed', '')
  const isLocalStorageFilterUsed = localStorageFiltersUsed === 'true'
  const blockedReasons = FUNERAL_BLOCKED_REASONS_OPTIONS

  // Submit on page change
  useEffect(() => {
    handleSubmit()
  }, [handleSubmit, page])

  // Reset page when filters or sort are changed before submitting
  useEffect(() => {
    setPage({})
    handleSubmit()
  }, [handleSubmit, setPage, sort, values])

  useEffect(() => {
    const selectedProducts = values?.product // Array of selected products
    let allProducts
    // If there are no selectedProducts, we define allProducts
    if (!selectedProducts || selectedProducts.length === 0) {
      allProducts = Object.values(FAREWILL_PRODUCTS)
    }
    const products = allProducts ?? selectedProducts

    const funeralUnsuitableReasonsOptions =
      leadUnsuitableReasonsOptions?.funeral ?? []
    const funeralPlanUnsuitableReasonsOptions =
      leadUnsuitableReasonsOptions?.funeral_plan ?? []
    const probateUnsuitableReasonsOptions =
      leadUnsuitableReasonsOptions?.probate ?? []
    const willUnsuitableReasonsOptions =
      leadUnsuitableReasonsOptions?.will ?? []

    const unsuitableReasonsOptions = [] as Reason[]
    products.forEach((product) => {
      switch (product) {
        case FAREWILL_PRODUCTS.WILL:
          return unsuitableReasonsOptions.push(...willUnsuitableReasonsOptions)

        case FAREWILL_PRODUCTS.PROBATE:
          return unsuitableReasonsOptions.push(
            ...probateUnsuitableReasonsOptions
          )

        case FAREWILL_PRODUCTS.FUNERAL:
          return unsuitableReasonsOptions.push(
            ...funeralUnsuitableReasonsOptions
          )

        case FAREWILL_PRODUCTS.LPA:
          return unsuitableReasonsOptions.push(
            ...LPA_UNSUITABLE_REASONS_OPTIONS
          )

        case FAREWILL_PRODUCTS.FUNERAL_PLAN:
          return unsuitableReasonsOptions.push(
            ...funeralPlanUnsuitableReasonsOptions
          )

        default:
          return unsuitableReasonsOptions.push(...[])
      }
    })

    // Get an array of unique values (duplicate values are removed)
    const uniqueUnsuitableReasonsOptionsValues = Array.from(
      new Set(unsuitableReasonsOptions.map((reason) => reason.value))
    )

    // Get an array of unique options
    const uniqueOptions = uniqueUnsuitableReasonsOptionsValues.map((value) => {
      return unsuitableReasonsOptions.find((option) => option.value === value)
    })

    setUnsuitableReasons(uniqueOptions as Reason[])
  }, [values?.product, setUnsuitableReasons, leadUnsuitableReasonsOptions])

  const sourceIdentifierOptions =
    LEAD_SOURCE_IDENTIFIER_OPTIONS[
      values.origin as keyof typeof LEAD_SOURCE_IDENTIFIER_OPTIONS
    ]

  const originOptions = LEAD_SOURCE_TYPE_OPTIONS.concat(
    { value: 'd2c', label: 'D2C' },
    { value: LEAD_PARTNER_TYPES.charity, label: 'Charity' },
    { value: LEAD_PARTNER_TYPES.other_partner, label: 'Partner' }
  )

  const adminUserOptions = adminUsers
    ?.filter(({ id }) => id !== loggedInAdminUserId)
    .map((user) => ({
      value: String(user.id),
      label: user.attributes.name,
    }))

  adminUserOptions?.unshift(
    { label: 'My leads', value: String(loggedInAdminUserId) },
    { label: 'No-one', value: 'null' }
  )

  const clearFields = (fields: string[]) =>
    fields.forEach((field) => setFieldValue(field, ''))

  const handleAssignYourself = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    setFieldValue('adminUserIds', [String(loggedInAdminUserId)])
  }

  const handlePersistFilters = () => {
    setLocalStorageFiltersUsed(isLocalStorageFilterUsed ? 'false' : 'true')
  }

  return (
    <FilterPanelWrapper>
      <Form>
        <FilterHeaders>
          <P size="M" margin={0} strong>
            Filters
          </P>
        </FilterHeaders>
        <StyledFilter>
          <StyledOwnerHeader margin={[0, 0, 'S']}>
            <P strong size="S" margin={0}>
              Owner
            </P>
            <StyledFilterButton
              disabled={values.adminUserIds.includes(
                loggedInAdminUserId.toString()
              )}
              type="button"
              onClick={handleAssignYourself}
            >
              Filter by me
            </StyledFilterButton>
          </StyledOwnerHeader>
          <SelectInput
            name="adminUserIds"
            options={adminUserOptions}
            isMulti
            small
          />
        </StyledFilter>
        <StyledFilter>
          <P strong margin={[0, 0, 'XS']} size="S">
            Product
          </P>
          {FAREWILL_PRODUCTS_OPTIONS.map((product) => (
            <ProductCheckbox
              key={`${product.value}-filter`}
              label={product.label as string}
              value={product.value as string}
            />
          ))}
        </StyledFilter>
        <StyledFilter>
          <P strong margin={[0, 0, 'XS']} size="S">
            Focus on
          </P>
          <FocusCallsTodayCheckbox />
          <LastChanceToConnectCheckbox />
          <TimeSelectedCheckbox />
          <MissedLastCallCheckbox />
        </StyledFilter>

        <StyledFilter>
          <P strong margin={[0, 0, 'XS']} size="S">
            Status
          </P>
          <SelectInput
            name="status"
            onChange={(e) => {
              if (!e) {
                clearFields(['status', 'unsuitableReason', 'blockedReason'])
              } else if (e && 'value' in e && e?.value === LEAD_STATUS.LOST) {
                setFieldValue('status', LEAD_STATUS.LOST)
              } else {
                e && 'value' in e && setFieldValue('status', e.value)
                clearFields(['unsuitableReason', 'blockedReason'])
              }
            }}
            options={[
              { label: 'Open', value: LEAD_STATUS.OPEN },
              { label: 'Qualified', value: LEAD_STATUS.QUALIFIED },
              { label: 'Lost', value: LEAD_STATUS.LOST },
              { label: 'Blocked', value: FUNERAL_LEAD_STATUS.BLOCKED },
            ]}
            small
            isClearable
          />
        </StyledFilter>
        {showUnsuitableReasonSelect(values.status) && (
          <StyledFilter>
            <P strong margin={[0, 0, 'XS']} size="S">
              Unsuitable reason
            </P>
            <SelectInput
              name="unsuitableReason"
              options={unsuitableReasons}
              small
              isClearable
            />
          </StyledFilter>
        )}
        {showFuneralBlockedReasonSelect(values.status) && (
          <StyledFilter>
            <P strong margin={[0, 0, 'XS']} size="S">
              Blocked reason
            </P>
            <SelectInput
              name="blockedReason"
              options={blockedReasons}
              small
              isClearable
            />
          </StyledFilter>
        )}
        <StyledFilter>
          <P strong margin={[0, 0, 'XS']} size="S">
            Origin
          </P>
          <SelectInput
            name="origin"
            onChange={(e) => {
              if (!e) {
                setFieldValue('origin', '')
                clearFields(['sourceIdentifier', 'partnerId'])
              } else if (
                e &&
                'value' in e &&
                typeof e.value === 'string' &&
                [
                  'other_partner',
                  'outbound_call',
                  'internal_referral',
                  'charity',
                ].includes(e.value)
              ) {
                setFieldValue('origin', e.value)
              } else {
                e && 'value' in e && setFieldValue('origin', e.value)
                clearFields(['sourceIdentifier', 'partnerId'])
              }
            }}
            options={originOptions}
            small
            isClearable
          />
        </StyledFilter>
        {sourceIdentifierOptions && (
          <StyledFilter>
            <P strong margin={[0, 0, 'XS']} size="S">
              Source identifier
            </P>
            <SelectInput
              name="sourceIdentifier"
              options={sourceIdentifierOptions}
              small
              isClearable
            />
          </StyledFilter>
        )}
        {showPartnerSelect(values.origin) && (
          <StyledFilter>
            <P strong margin={[0, 0, 'XS']} size="S">
              Partner name
            </P>
            <PartnerSelect
              name="partnerId"
              partnerType={values.origin}
              small
              isClearable
            />
          </StyledFilter>
        )}
        <StyledFilter>
          <P size="S" strong>
            Last activity
          </P>
          <LastActivityFilter name="lastActivityAt" />
        </StyledFilter>
        <Divider margin={['L', 0]} />
        <StyledFilterSwitch>
          <Switch
            isOn={isLocalStorageFilterUsed}
            color={COLOR.ACCENT.SECONDARY}
            handleToggle={handlePersistFilters}
          />
          <StyledPersistFiltersButton onClick={handlePersistFilters}>
            <span>
              {localStorageFiltersUsed === 'true'
                ? 'Filters saved'
                : 'Filters not saved'}
            </span>
          </StyledPersistFiltersButton>
        </StyledFilterSwitch>
      </Form>
    </FilterPanelWrapper>
  )
}

export default FilterPanel
