import { useField } from 'formik'
import { Checkbox, P } from '@farewill/ui'
import { FAREWILL_PRODUCTS, FUNERAL_PLAN_FEATURE_OPTIONS } from 'utils/enums'

const FeatureCheckbox = ({ label, name }) => {
  const [field] = useField({ name, type: 'checkbox' })
  return <Checkbox label={label} size="S" {...field} />
}

const CaseFeatureFilters = ({ product }) => {
  return (
    <>
      <P strong margin={[0, 0, 'XS']} size="S">
        Features
      </P>
      {product === FAREWILL_PRODUCTS.PROBATE && (
        <>
          <FeatureCheckbox label="Priority cases" name="isPriority" />
          <FeatureCheckbox label="Tasks needing help" name="helpNeeded" />
          <FeatureCheckbox
            label="Not NewLaw referral"
            name="hideNewLawReferrals"
          />
        </>
      )}

      {product === FAREWILL_PRODUCTS.FUNERAL && (
        <>
          <FeatureCheckbox label="Difficult case" name="isDifficult" />
          <FeatureCheckbox
            label="Green / form 6 pending"
            name="hasGreenFormPending"
          />
          <FeatureCheckbox
            label="Prepare paperwork"
            name="hasPaperworkPending"
          />
          <FeatureCheckbox label="Feedback pending" name="hasFeedbackPending" />
          <FeatureCheckbox
            label="< 7 days until service"
            name="hasServiceWithinSevenDays"
          />
          <FeatureCheckbox
            label="> 6 days without contact"
            name="hasSixDaysWithoutContact"
          />
        </>
      )}

      {product === FAREWILL_PRODUCTS.WILL && (
        <>
          <FeatureCheckbox label="Urgent case" name="isUrgent" />
          <FeatureCheckbox label="Tasks needing help" name="helpNeeded" />
        </>
      )}

      {product === FAREWILL_PRODUCTS.FUNERAL_PLAN &&
        FUNERAL_PLAN_FEATURE_OPTIONS.map((option) => {
          return (
            <FeatureCheckbox
              key={option.value}
              label={option.label}
              name={option.value}
            />
          )
        })}
    </>
  )
}

export default CaseFeatureFilters
