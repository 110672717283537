import { createContext, useContext, useCallback, useState } from 'react'

import { flattenAttributes } from 'utils/helpers'
import useApi from 'lib/effects/api'

const ContactContext = createContext()

const useContactContext = () => {
  const context = useContext(ContactContext)
  if (context === undefined) {
    throw new Error(
      'useContactContext must be used within an ContactContextProvider'
    )
  }
  return context
}

const ContactContextProvider = ({ children, contactId }) => {
  const [contact, setContact] = useState({})

  const [{ isLoading: isFetchingContact }, fetchContactRequest] = useApi({
    isLoading: true,
  })

  const fetchContact = useCallback(async () => {
    const response = await fetchContactRequest({
      url: `/api/contacts/${contactId}`,
    })
    setContact(response.data)
  }, [fetchContactRequest, contactId])

  const [
    {
      isLoading: isUpdatingContact,
      errors: updateContactErrors,
      meta: updateContactMeta,
    },
    updateContactRequest,
  ] = useApi()

  const updateContact = useCallback(
    async (attributes) => {
      const response = await updateContactRequest({
        url: `/api/contacts/${contactId}`,
        method: 'PATCH',
        data: { data: { type: 'contacts', id: contactId, attributes } },
      })
      setContact(response.data)
    },
    [contactId, updateContactRequest]
  )

  const [{ isLoading: isCreatingLead }, createLeadRequest] = useApi()

  const createLead = useCallback(
    async (attributes) => {
      const response = await createLeadRequest({
        url: `/api/leads`,
        method: 'POST',
        data: { data: { type: 'leads', attributes } },
      })

      const lead = flattenAttributes(response.data)
      const leads = [lead, ...contact.attributes.leads]
      setContact({ ...contact, attributes: { ...contact.attributes, leads } })
    },
    [createLeadRequest, contact]
  )

  const value = {
    contact,
    fetchContact,
    isFetchingContact,
    updateContact,
    isUpdatingContact,
    updateContactErrors,
    updateContactMeta,
    createLead,
    isCreatingLead,
  }

  return (
    <ContactContext.Provider value={value}>{children}</ContactContext.Provider>
  )
}

export { ContactContextProvider, useContactContext }
