import upperFirst from 'lodash/upperFirst'
import lowerCase from 'lodash/lowerCase'

export const WILL_BENEFICIARY_TYPE = {
  CHARITY: 'charity',
  ORGANISATION: 'organisation',
} as const

export const willBeneficiaryTypeOptions = Object.values(
  WILL_BENEFICIARY_TYPE
).map((value) => {
  return {
    value: value,
    label: upperFirst(lowerCase(value)),
  }
})

export const FAREWILL_SERVICES = {
  FAREWILL_CREMATION: 'farewill_cremation',
  FAREWILL_TRUSTEES_SOLE: 'farewill_trustees_sole',
  FAREWILL_TRUSTEES_BACKUP: 'farewill_trustees_backup',
  FAREWILL_TRUSTEES_JOINTLY: 'farewill_trustees_jointly',
  FAREWILL_FUNERAL_PLAN: 'farewill_funeral_plan',
}

export const FAREWILL_SERVICES_OPTIONS = [
  {
    value: FAREWILL_SERVICES.FAREWILL_CREMATION,
    label: 'Farewill cremation',
  },
  {
    value: FAREWILL_SERVICES.FAREWILL_TRUSTEES_SOLE,
    label: 'Farewill Trustees - sole',
  },
  {
    value: FAREWILL_SERVICES.FAREWILL_TRUSTEES_BACKUP,
    label: 'Farewill Trustees - backup',
  },
  {
    value: FAREWILL_SERVICES.FAREWILL_TRUSTEES_JOINTLY,
    label: 'Farewill Trustees - jointly',
  },
  {
    value: FAREWILL_SERVICES.FAREWILL_FUNERAL_PLAN,
    label: 'Farewill funeral plan',
  },
]

export const GIFT_BENEFICIARY_TYPE = {
  PRINCIPAL: 'principal',
  BACKUP: 'backup',
  LIFE_TENANT: 'life_tenant',
  REMAINDERMAN: 'remainderman',
  DISCRETIONARY_TRUST: 'discretionary_trust',
  /* Temporary - this will likely become a separate checkbox on the gift */
  SECOND_DEATH: 'second_death',
}

export const GIFT_BENEFICIARY_TYPE_OPTIONS = [
  { label: 'Principal', value: GIFT_BENEFICIARY_TYPE.PRINCIPAL },
  {
    label: 'Principal backup',
    value: GIFT_BENEFICIARY_TYPE.BACKUP,
  },
]

export const GIFT_TYPE = {
  MONEY: 'money',
  RESIDUARY: 'residuary',
}
