import { ReactElement } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Field, useField } from 'formik'
import { P, Wrapper } from '@farewill/ui'
import { COLOR, FONT, GTR } from '@farewill/ui/tokens'
import NumberFormat from 'react-number-format'

import { removeCommas } from 'utils/helpers'

import Label from '../styled/label'
import InputHint from '../styled/input-hint'
import { input } from '../styled/helpers/form'
import CopyToClipboard from './copy-to-clipboard'
import Error from './error'

const StyledField = styled(Field)`
  ${input}

  ${({ $small }) =>
    $small &&
    `
    font-size: ${FONT.SIZE.S};
    padding: ${GTR.XS};
  `}

  ${({ $hasSuffix }) =>
    $hasSuffix &&
    `
    padding-right: ${GTR.L};
  `}
`

const StyledCounterText = styled(P)`
  margin-top: ${GTR.XXS};
  margin-bottom: 0;
  color: ${(props) =>
    props.isError ? COLOR.STATE.ERROR_60 : COLOR.GREY.MEDIUM};
  font-size: ${FONT.SIZE.S};
`

const StyledSuffix = styled.span`
  color: ${COLOR.GREY.MEDIUM};
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: ${GTR.S};
`

const CharacterCounter = ({
  currentCount,
  maxCount,
}: {
  currentCount: number
  maxCount: number
}) => {
  const isError = currentCount > maxCount
  return (
    <StyledCounterText isError={isError}>
      ({currentCount || '0'} / {maxCount})
    </StyledCounterText>
  )
}

type Option = {
  label: string
  value: string
}

export type SaveData = {
  name: string
  value: string
}

export type InputProps = {
  component?: string
  disabled?: boolean
  handleSave?: (data: SaveData) => void
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void
  value?: string
  isCurrency?: boolean
  label?: React.ReactNode
  options?: Readonly<Option[]>
  name: string
  hint?: React.ReactNode
  small?: boolean
  includeEmptyOption?: boolean
  rows?: number
  type?:
    | 'button'
    | 'checkbox'
    | 'date'
    | 'email'
    | 'number'
    | 'password'
    | 'radio'
    | 'search'
    | 'submit'
    | 'text'
    | 'time'
  allowCopyToClipboard?: boolean
  maxCharacters?: number
  suffix?: string
}

const InputPropTypes = {
  component: PropTypes.string,
  handleSave: PropTypes.func,
  isCurrency: PropTypes.bool,
  label: PropTypes.node,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ),
  name: PropTypes.string.isRequired,
  hint: PropTypes.node,
  small: PropTypes.bool,
  includeEmptyOption: PropTypes.bool,
  allowCopyToClipboard: PropTypes.bool,
}

const Input = ({
  component,
  handleSave,
  isCurrency,
  label,
  options,
  name,
  hint,
  small,
  includeEmptyOption,
  allowCopyToClipboard,
  type,
  maxCharacters,
  suffix,
  ...rest
}: InputProps): ReactElement => {
  const isSelect = component === 'select'
  const isText = !isSelect && !isCurrency
  const [field, meta] = useField(name)
  const hasError = Boolean(meta.error && meta.touched)

  return (
    <Wrapper>
      {label && (
        <Label $error={hasError} htmlFor={name}>
          {label}
        </Label>
      )}
      {isSelect && (
        <StyledField
          {...rest}
          type={type}
          $error={hasError}
          name={name}
          id={name}
          component={component}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            field.onChange(e)
            const value = e.target.value
            handleSave && handleSave({ name, value })
          }}
        >
          {includeEmptyOption && <option value="">Select one</option>}
          {options?.map((option: Option, index: number) => (
            <option value={option.value} key={index}>
              {option.label}
            </option>
          ))}
        </StyledField>
      )}
      <CopyToClipboard
        allowCopyToClipboard={allowCopyToClipboard}
        name={name}
        label={label}
      >
        {isCurrency && (
          <NumberFormat
            {...rest}
            $error={hasError}
            $small={small}
            customInput={StyledField}
            decimalScale={2}
            defaultValue={meta.value}
            name={name}
            id={name}
            onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
              field.onBlur(e)
              const value = removeCommas(e.target.value) ?? ''
              handleSave && handleSave({ name, value })
            }}
            onChange={field.onChange}
            thousandSeparator
          />
        )}
        {isText && (
          <>
            <StyledField
              {...rest}
              type={type}
              $error={hasError}
              $small={small}
              $hasSuffix={suffix}
              name={name}
              id={name}
              component={component}
              onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
                field.onBlur(e)
                const value = e.target.value
                handleSave && handleSave({ name, value })
              }}
              // We want to disable suggestion to use 1password on Input fields
              data-1p-ignore
            />
            {suffix && <StyledSuffix>{suffix}</StyledSuffix>}
          </>
        )}
      </CopyToClipboard>
      {hint && <InputHint>{hint}</InputHint>}
      {isText && maxCharacters && (
        <CharacterCounter
          currentCount={meta?.value?.length}
          maxCount={maxCharacters}
        />
      )}
      <Error name={name} />
    </Wrapper>
  )
}

Input.propTypes = InputPropTypes

Input.defaultProps = {
  component: 'input',
  isCurrency: false,
  label: '',
  options: [],
  hint: '',
  small: false,
  includeEmptyOption: true,
}

export default Input
