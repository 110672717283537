import { useEffect } from 'react'
import styled from 'styled-components'
import { Button, H, Wrapper } from '@farewill/ui'
import { FONT } from '@farewill/ui/tokens'
import { Form, useFormikContext } from 'formik'
import { useQueryClient } from 'react-query'

import useAdminUser from 'hooks/useAdminUser'
import { SelectOptions } from 'lib/types/common'
import { AdminUser } from 'lib/models/admin-user'
import { formatAdminUserOptions } from 'utils/helpers'
import {
  CASE_STATUSES_OPTIONS,
  CASE_NEXT_STAGE_OPTIONS,
  FAREWILL_PRODUCTS,
  FarewillProduct,
} from 'utils/enums'
import { CORE_TASKS_IN_ORDER } from 'components/tasks-panel/constants'

import { NextCoreTaskSelectOptions, Page, Sort } from '../types'
import { VIEWS, View } from '../views'
import TasksViewFilters from './tasks'
import WillFilters from './will'
import ProbateFilters from './probate'
import FuneralFilters from './funeral'
import LpaFilters from './lpa'
import FuneralPlanFilters from './funeral-plan'

const FilterHeaders = styled(Wrapper)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const StyledButton = styled(Button.Underline)`
  font-size: ${FONT.SIZE.S};
`

const CASE_VIEW_FILTER_COMPONENTS = {
  [FAREWILL_PRODUCTS.WILL]: WillFilters,
  [FAREWILL_PRODUCTS.PROBATE]: ProbateFilters,
  [FAREWILL_PRODUCTS.FUNERAL]: FuneralFilters,
  [FAREWILL_PRODUCTS.LPA]: LpaFilters,
  [FAREWILL_PRODUCTS.FUNERAL_PLAN]: FuneralPlanFilters,
}

export const optionsForProduct = (product: FarewillProduct) => {
  let nextStageOptions: SelectOptions,
    nextCoreTaskOptions: NextCoreTaskSelectOptions
  const statusOptions =
    product !== FAREWILL_PRODUCTS.LPA ? CASE_STATUSES_OPTIONS[product] : []
  if (product !== FAREWILL_PRODUCTS.FUNERAL_PLAN) {
    nextStageOptions = CASE_NEXT_STAGE_OPTIONS[product]
    nextCoreTaskOptions = CORE_TASKS_IN_ORDER[product]?.map((stage) => ({
      label: stage.title,
      options: stage.tasks,
    }))
  } else {
    nextStageOptions = []
    nextCoreTaskOptions = []
  }
  return {
    statusOptions,
    nextStageOptions,
    nextCoreTaskOptions,
  }
}

type FilterPanelProps = {
  page: Page
  setPage: React.Dispatch<React.SetStateAction<Page>>
  sort: Sort
  setSort: React.Dispatch<React.SetStateAction<Sort>>
  view: View
  product: FarewillProduct
  onApplyFilters: () => void
  onResetFilters: (value: React.SetStateAction<boolean>) => void
}

const FilterPanel = ({
  page,
  setPage,
  sort,
  setSort,
  view,
  product,
  onApplyFilters,
  onResetFilters,
}: FilterPanelProps) => {
  const { handleSubmit, setFieldValue } = useFormikContext()

  const { id: loggedInAdminUserId } = useAdminUser() || {}

  const isTasksView = view === VIEWS.TASKS
  const isMyTasksView = view === VIEWS.MY_TASKS
  const isMyCasesView = view === VIEWS.MY_CASES
  const isWillDraftingView = view === VIEWS.WILL_DRAFTING

  const { statusOptions, nextStageOptions, nextCoreTaskOptions } =
    optionsForProduct(product)

  useEffect(() => {
    handleSubmit()
  }, [handleSubmit, page, sort])

  useEffect(() => {
    if (product === FAREWILL_PRODUCTS.PROBATE && isMyCasesView) {
      setFieldValue('status', ['open', 'referral_made', 'case_booked'])
    }

    if (product === FAREWILL_PRODUCTS.FUNERAL && isMyCasesView) {
      setFieldValue('status', ['open'])
    }

    setPage({})
    setSort(null)

    return () => {
      setFieldValue('status', [])
    }
  }, [setPage, setSort, product, setFieldValue, view, isMyCasesView])

  const cache = useQueryClient()
  const adminUsers = cache.getQueryData('adminUsers') as AdminUser[]
  const adminUserOptions = formatAdminUserOptions(
    adminUsers,
    loggedInAdminUserId
  )

  if (product === FAREWILL_PRODUCTS.FUNERAL) {
    adminUserOptions.unshift({
      label: 'No-one',
      value: 'null',
    })
  }

  if (product === FAREWILL_PRODUCTS.WILL) {
    adminUserOptions.unshift({ label: 'Unassigned', value: 'null' })
  }

  loggedInAdminUserId &&
    adminUserOptions.unshift({ label: 'My cases', value: loggedInAdminUserId })

  const CaseViewFilterComponent = CASE_VIEW_FILTER_COMPONENTS[product]

  return (
    <Form>
      <FilterHeaders>
        <H size="S">Filters</H>
        <StyledButton type="button" onClick={onResetFilters} flush>
          Reset
        </StyledButton>
      </FilterHeaders>
      {isTasksView || isMyTasksView ? (
        <TasksViewFilters
          isTasksView={isTasksView}
          adminUserOptions={adminUserOptions}
          nextCoreTaskOptions={nextCoreTaskOptions}
          statusOptions={statusOptions}
        />
      ) : (
        <CaseViewFilterComponent
          product={product}
          isMyCasesView={isMyCasesView}
          isWillDraftingView={isWillDraftingView}
          adminUserOptions={adminUserOptions}
          statusOptions={statusOptions}
          nextStageOptions={nextStageOptions}
          nextCoreTaskOptions={nextCoreTaskOptions}
        />
      )}
      <Button.Primary
        stretch
        type="button"
        onClick={() => {
          setPage({})
          onApplyFilters()
        }}
      >
        Apply filters
      </Button.Primary>
    </Form>
  )
}

export default FilterPanel
