import { useEffect } from 'react'
import get from 'lodash/get'
import { useParams } from 'react-router-dom'

import {
  formatValuesForFormik,
  findDepositInvoice,
  findLineItemByDescription,
} from 'utils/helpers'
import { INVOICE_LINE_ITEMS } from 'utils/enums'
import { formatCurrency } from 'lib/formatting/currency'
import useApi from 'lib/effects/api'
import { QuestionConfig } from 'lib/types/form'
import { Invoice } from 'lib/models/invoice'
import { ProbateLeadAttributes } from 'lib/models/lead'
import {
  ALL_QUESTIONS,
  getQuestionsToShow,
} from 'routes/lead/probate-form/diagnosis'

import { formatAnswer, formatDateToDMY } from '../helpers'
import Table from './table'

export const formatDiagnosisAnswer = ({
  question,
  answer,
}: {
  question: QuestionConfig
  answer: string | number | boolean
}) => {
  if (question.isCurrency) {
    if (!answer) return 'Not Answered'
    return formatCurrency({ value: answer as number })
  }

  return formatAnswer(answer)
}

const DiagnosisRow = ({
  attributes,
  question,
}: {
  attributes: ProbateLeadAttributes
  question: QuestionConfig
}) => {
  const answer = get(attributes, question.name)
  return (
    <Table.Row>
      <Table.Cell>
        {question.getLabel ? question.getLabel(attributes) : question.label}
      </Table.Cell>
      <Table.Cell>
        <strong>{formatDiagnosisAnswer({ question, answer })}</strong>
      </Table.Cell>
    </Table.Row>
  )
}

const ProbateSummary = ({
  attributes,
}: {
  attributes: ProbateLeadAttributes
}) => {
  const { id: probateCaseId } = useParams<{ id: string }>()
  const [{ data: invoices }, makeRequest] = useApi<Invoice[]>({ data: [] })

  useEffect(() => {
    makeRequest({
      url: `/api/invoices?filter[probateCaseId]=${probateCaseId}`,
    })
  }, [makeRequest, probateCaseId])

  if (!invoices) return <></>

  const depositInvoice = findDepositInvoice(invoices)
  const depositLineItem = findLineItemByDescription({
    invoice: depositInvoice,
    description: INVOICE_LINE_ITEMS.DEPOSIT,
  })

  const diagnosisQuestions = getQuestionsToShow({
    questions: ALL_QUESTIONS,
    values: formatValuesForFormik(attributes),
  })

  return (
    <>
      <Table>
        <Table.Body>
          <Table.Row>
            <Table.Cell colSpan={2}>
              <h4>Customer</h4>
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell nowrap>Name:</Table.Cell>
            <Table.Cell width="90%">
              <strong>
                {attributes.contact.firstName} {attributes.contact.lastName}
              </strong>
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell nowrap>Phone:</Table.Cell>
            <Table.Cell width="90%">
              <strong>{attributes.contact.phoneNumber}</strong>
            </Table.Cell>
          </Table.Row>
          {attributes.quoteAttributes.factFindCallPhoneNumber && (
            <Table.Row>
              <Table.Cell nowrap>Phone number for fact find:</Table.Cell>
              <Table.Cell width="90%">
                <strong>
                  {formatAnswer(
                    attributes.quoteAttributes.factFindCallPhoneNumber
                  )}
                </strong>
              </Table.Cell>
            </Table.Row>
          )}
          <Table.Row>
            <Table.Cell nowrap>Email:</Table.Cell>
            <Table.Cell width="90%">
              <strong>{attributes.contact.email}</strong>
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell nowrap>Reference:</Table.Cell>
            <Table.Cell width="90%">
              <strong>{attributes.probateCase.reference}</strong>
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
      <br />
      <Table>
        <Table.Body>
          <Table.Row>
            <Table.Cell colSpan={2}>
              <h4>Person who's died</h4>
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell nowrap>First Name:</Table.Cell>
            <Table.Cell width="90%">
              <strong>
                {formatAnswer(attributes.quoteAttributes.deceasedFirstName)}
              </strong>
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell nowrap>Last Name:</Table.Cell>
            <Table.Cell width="90%">
              <strong>
                {formatAnswer(attributes.quoteAttributes.deceasedLastName)}
              </strong>
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell nowrap>Date of Death:</Table.Cell>
            <Table.Cell width="90%">
              <strong>
                {attributes.quoteAttributes.dateOfDeath
                  ? formatDateToDMY(attributes.quoteAttributes.dateOfDeath)
                  : 'Not Answered'}
              </strong>
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell width="80%">
              What was their legal marital status?
            </Table.Cell>
            <Table.Cell nowrap>
              <strong>
                {formatAnswer(attributes.quoteAttributes.maritalStatus)}
              </strong>
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell nowrap>Relationship to customer:</Table.Cell>
            <Table.Cell width="90%">
              <strong>
                {formatAnswer(
                  attributes.quoteAttributes.relationshipToCustomer
                )}
              </strong>
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell nowrap>Funeral arrangements made:</Table.Cell>
            <Table.Cell width="90%">
              <strong>
                {formatAnswer(
                  attributes.quoteAttributes.hasMadeFuneralArrangements
                )}
              </strong>
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
      <br />
      <Table>
        <Table.Body>
          <Table.Row>
            <Table.Cell>
              <h4>Is probate needed?</h4>
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell width="80%">
              Have you already found out that you need probate?
            </Table.Cell>
            <Table.Cell nowrap>
              <strong>
                {formatAnswer(
                  attributes.quoteAttributes.customerAlreadyKnowsProbateRequired
                )}
              </strong>
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>
              Did the person who died own their own property?
            </Table.Cell>
            <Table.Cell nowrap>
              <strong>
                {formatAnswer(attributes.quoteAttributes.ownedProperty)}
              </strong>
            </Table.Cell>
          </Table.Row>
          {attributes.quoteAttributes.ownedProperty && (
            <Table.Row>
              <Table.Cell>How did they own their property?</Table.Cell>
              <Table.Cell nowrap>
                <strong>
                  {formatAnswer(
                    attributes.quoteAttributes.propertyOwnershipType
                  )}
                </strong>
              </Table.Cell>
            </Table.Row>
          )}
          <Table.Row>
            <Table.Cell>
              Did the person who died have any bank accounts or shares worth
              more than the probate thresholds?
            </Table.Cell>
            <Table.Cell nowrap>
              <strong>
                {formatAnswer(
                  attributes.quoteAttributes.aboveAccountOrShareThreshold
                )}
              </strong>
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
      <br />
      <Table>
        <Table.Body>
          <Table.Row>
            <Table.Cell>
              <h4>Are there any disputes?</h4>
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell width="80%">
              Has anyone raised concerns in relation to the will, assets or
              their inheritance?
            </Table.Cell>
            <Table.Cell nowrap>
              <strong>
                {formatAnswer(attributes.quoteAttributes.isDisputed)}
              </strong>
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Is this an insolvent estate?</Table.Cell>
            <Table.Cell nowrap>
              <strong>
                {formatAnswer(attributes.quoteAttributes.isInsolvent)}
              </strong>
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
      <br />
      <Table>
        <Table.Body>
          <Table.Row>
            <Table.Cell>
              <h4>Is there a will?</h4>
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell width="80%">Is there a will?</Table.Cell>
            <Table.Cell nowrap>
              <strong>
                {formatAnswer(attributes.quoteAttributes.hadWill)}
              </strong>
            </Table.Cell>
          </Table.Row>
          {attributes.quoteAttributes.hadWill ? (
            <>
              <Table.Row>
                <Table.Cell>Is the caller an executor 2?</Table.Cell>
                <Table.Cell nowrap>
                  <strong>
                    {formatAnswer(
                      attributes.quoteAttributes.customerIsExecutor
                    )}
                  </strong>
                </Table.Cell>
              </Table.Row>
              {!attributes.quoteAttributes.customerIsExecutor && (
                <>
                  <Table.Row>
                    <Table.Cell>Main executor's name:</Table.Cell>
                    <Table.Cell nowrap>
                      <strong>
                        {attributes.quoteAttributes.mainExecutorFullName ||
                          'Not Answered'}
                      </strong>
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Main executor's phone number:</Table.Cell>
                    <Table.Cell nowrap>
                      <strong>
                        {attributes.quoteAttributes.mainExecutorPhoneNumber}
                      </strong>
                    </Table.Cell>
                  </Table.Row>
                </>
              )}
            </>
          ) : (
            <Table.Row>
              <Table.Cell>Who are the closest relatives?</Table.Cell>
              <Table.Cell nowrap>
                <strong>
                  {formatAnswer(attributes.quoteAttributes.intestacyDetails)}
                </strong>
              </Table.Cell>
            </Table.Row>
          )}
        </Table.Body>
      </Table>
      <br />
      <Table>
        <Table.Body>
          <Table.Row>
            <Table.Cell>
              <h4>Complexity</h4>
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell width="80%">
              In the 7 years before they died, did they make any gifts worth
              over £3,000 in total?{' '}
            </Table.Cell>
            <Table.Cell nowrap>
              <strong>
                {formatAnswer(
                  attributes.quoteAttributes.giftsGivenOver3kThreshold
                )}
              </strong>
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>
              Did they make gifts which they continued to benefit from?
            </Table.Cell>
            <Table.Cell nowrap>
              <strong>
                {formatAnswer(
                  attributes.quoteAttributes.madeGiftsWithReservationOfBenefit
                )}
              </strong>
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>
              Did they have the right to benefit from any trusts?
            </Table.Cell>
            <Table.Cell nowrap>
              <strong>
                {formatAnswer(attributes.quoteAttributes.benefittedFromTrust)}
              </strong>
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
      <br />
      <Table>
        <Table.Body>
          <Table.Row>
            <Table.Cell>
              <h4>Diagnosis</h4>
            </Table.Cell>
          </Table.Row>
          {diagnosisQuestions.map((question) => (
            <DiagnosisRow
              attributes={attributes}
              question={question}
              key={question.name}
            />
          ))}
          <Table.Row>
            <Table.Cell>Quoted price:</Table.Cell>
            <Table.Cell>
              <strong>
                {attributes.calculatedPrice
                  ? formatCurrency({
                      value: attributes.calculatedPrice,
                      valueInPence: true,
                    })
                  : 'Not Quoted'}
              </strong>
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Deposit taken:</Table.Cell>
            <Table.Cell>
              <strong>
                {depositLineItem
                  ? formatCurrency({
                      value: depositLineItem.price,
                      valueInPence: true,
                    })
                  : 'Not Taken'}
              </strong>
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
      <br />
      <Table>
        <Table.Body>
          <Table.Row>
            <Table.Cell>
              <h4>Essential Probate or Complete Probate</h4>
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell width="80%">
              Is the caller interested in Complete Probate?
            </Table.Cell>
            <Table.Cell nowrap>
              <strong>
                {formatAnswer(
                  attributes.quoteAttributes.interestedInEstateAdmin
                )}
              </strong>
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    </>
  )
}

export default ProbateSummary
