import styled from 'styled-components'
import { useFormikContext } from 'formik'
import { Grid, H } from '@farewill/ui'

import { getYearsElapsed, strToBool } from 'utils/helpers'

import Checkbox from 'components/form/checkbox'
import Input from 'components/form/input'
import CircularRadioGroup from 'components/form/circular-radio-group'

import { showRelationshipToCustomerWithLegacy } from '../helpers'
import { FormikValues } from './helpers'

const StyledGrid = styled(Grid)`
  grid-auto-flow: row;
`

const ALREADY_DECEASED_OPTIONS = [
  { value: 'true', label: "Someone who's died" },
  { value: 'false', label: "Someone who's expected to die soon" },
]

const WhoFuneralIsFor = (): React.ReactElement => {
  const { handleSubmit, setFieldValue, setValues, values } =
    useFormikContext<FormikValues>()

  const clearFormFields = () => {
    const updatedValues: FormikValues = {
      ...values,
      quoteAttributes: {
        ...values.quoteAttributes,
        firstName: '',
        lastName: '',
        relationshipToCustomer: '',
        customerRefersToThemAs: '',
      },
    }

    setValues(updatedValues)
  }

  const { dateOfBirth, dateOfDeath } = values.quoteAttributes

  const ageAtDateOfDeath =
    dateOfDeath && dateOfBirth
      ? getYearsElapsed({ dateOfBirth, dateOfDeath })
      : null

  return (
    <StyledGrid>
      <Grid.Item>
        <H size="S">Who the funeral is for</H>
      </Grid.Item>
      <Grid.Item
        onClick={() =>
          setFieldValue(
            'quoteAttributes.customerIsArrangingTheirOwnFuneral',
            false
          )
        }
      >
        <CircularRadioGroup
          label="The caller wants to arrange a funeral for:"
          name="quoteAttributes.alreadyDeceased"
          options={ALREADY_DECEASED_OPTIONS}
          handleSave={() => handleSubmit()}
        />
      </Grid.Item>
      {values.quoteAttributes.alreadyDeceased === 'false' && (
        <Grid.Item onClick={clearFormFields}>
          <Checkbox
            name="quoteAttributes.customerIsArrangingTheirOwnFuneral"
            label="The caller is arranging their own future funeral"
            checked={strToBool(
              values.quoteAttributes.customerIsArrangingTheirOwnFuneral
            )}
            handleSave={() => handleSubmit()}
          />
        </Grid.Item>
      )}
      {!strToBool(
        values.quoteAttributes.customerIsArrangingTheirOwnFuneral
      ) && (
        <>
          <Grid.Item span={2}>
            <Input
              label="Their title"
              name="quoteAttributes.title"
              handleSave={() => handleSubmit()}
            />
          </Grid.Item>
          <Grid.Item span={4}>
            <Input
              label="Their first name"
              name="quoteAttributes.firstName"
              handleSave={() => handleSubmit()}
              allowCopyToClipboard
            />
          </Grid.Item>
          <Grid.Item span={6}>
            <Input
              label="Their last name"
              name="quoteAttributes.lastName"
              handleSave={() => handleSubmit()}
              allowCopyToClipboard
            />
          </Grid.Item>
          <Grid.Item span={6} startColumn={1}>
            <Input
              label="The funeral is for the caller’s..."
              name="quoteAttributes.relationshipToCustomer"
              component="select"
              options={showRelationshipToCustomerWithLegacy(
                values.quoteAttributes.relationshipToCustomer
              )}
              hint="E.g. if they are the caller’s father, choose Father"
              handleSave={() => handleSubmit()}
            />
          </Grid.Item>
          <Grid.Item span={6}>
            <Input
              label="The caller refers to this person as their..."
              name="quoteAttributes.customerRefersToThemAs"
              hint="E.g. “mum”"
              handleSave={() => handleSubmit()}
            />
          </Grid.Item>
        </>
      )}
      <Grid.Item span={6}>
        <Input
          type="date"
          name="quoteAttributes.dateOfBirth"
          label="Date of birth"
          handleSave={() => handleSubmit()}
        />
      </Grid.Item>
      {values.quoteAttributes.alreadyDeceased === 'true' && (
        <>
          <Grid.Item span={6}>
            <Input
              type="date"
              name="quoteAttributes.dateOfDeath"
              label="Date of death"
              hint={
                ageAtDateOfDeath && `Age at date of death: ${ageAtDateOfDeath}`
              }
              handleSave={() => handleSubmit()}
            />
          </Grid.Item>
        </>
      )}
    </StyledGrid>
  )
}

export default WhoFuneralIsFor
