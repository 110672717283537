import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'

import { Button, ButtonGroup, Wrapper } from '@farewill/ui'

import { hideModal } from 'state/actions'

const TaskCompleteGenericModal = ({
  config: { actionTask, task, toastText, description },
}) => {
  const dispatch = useDispatch()

  const handleCancel = () => dispatch(hideModal())
  const handleConfirm = async () => {
    await actionTask(task.id, 'complete')

    dispatch(hideModal())

    toast(toastText, {
      toastId: typeof toastText === 'string' && toastText.replace(/ /g, '-'),
    })
  }

  return (
    <>
      <Wrapper>{description}</Wrapper>
      <ButtonGroup gap="S" margin={['L', 0, 0]}>
        <Button.Primary type="button" onClick={handleConfirm}>
          Confirm
        </Button.Primary>
        <Button.Underline type="button" onClick={handleCancel}>
          Cancel
        </Button.Underline>
      </ButtonGroup>
    </>
  )
}

export default TaskCompleteGenericModal
