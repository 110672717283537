import { ReactElement } from 'react'
import styled from 'styled-components'
import { P } from '@farewill/ui'

import GridRow from 'components/list/grid-row'
import { formatCurrency } from 'lib/formatting/currency'
import { LpaCaseAttributes } from 'lib/models/lpa-case'
import { LpaCaseQuoteResult } from 'routes/lpa-case/quote/editable-quote/types'
import { formatToHuman } from 'utils/helpers'

const gridTemplateColumns = 'repeat(2, 50%)'

const StyledGridRow = styled(GridRow)`
  padding-left: 0;
  padding-right: 0;
`

const StyledTotalPriceContainer = styled.div`
  p {
    margin-top: 8px;
    text-align: center;
  }
`

const StyledUnselectableGridRow = styled(StyledGridRow)`
  cursor: default;
`

const QuoteRow = ({
  label,
  priceInPence,
}: {
  label: string
  priceInPence: number
}): ReactElement => {
  return (
    <StyledUnselectableGridRow gridTemplateColumns={gridTemplateColumns}>
      <li>{label}</li>
      <li>
        {formatCurrency({
          value: priceInPence,
          valueInPence: true,
        })}
      </li>
    </StyledUnselectableGridRow>
  )
}

const CurrentQuote = ({
  currentQuote,
  lpaCase,
}: {
  currentQuote: LpaCaseQuoteResult
  lpaCase: LpaCaseAttributes
}): ReactElement => {
  const { type } = lpaCase

  return (
    <>
      <StyledGridRow header gridTemplateColumns={gridTemplateColumns}>
        <li>Description</li>
        <li>Amount</li>
      </StyledGridRow>

      {currentQuote && (
        <>
          <QuoteRow
            label={`${formatToHuman(type)} LPA`}
            priceInPence={currentQuote.totalPriceInPence}
          />
        </>
      )}

      <StyledTotalPriceContainer>
        <P strong margin={['S', 0, 0]}>
          Total quoted:{' '}
          {currentQuote &&
            formatCurrency({
              value: currentQuote.totalPriceInPence || 0,
              valueInPence: true,
            })}
        </P>
      </StyledTotalPriceContainer>
    </>
  )
}

export default CurrentQuote
