import { useEffect } from 'react'
import { useFormikContext } from 'formik'

import { useWillFactFindContext } from './context'

const FormStatus = (): null => {
  const { highlightEmpty } = useWillFactFindContext()
  const { setStatus } = useFormikContext()

  useEffect(() => {
    setStatus({ highlightEmpty })
  }, [highlightEmpty, setStatus])

  return null
}

export default FormStatus
