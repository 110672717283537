import Dinero from 'dinero.js'
import { useFormikContext } from 'formik'
import React, { ReactElement } from 'react'
import { useQueryClient } from 'react-query'
import { Grid, Wrapper } from '@farewill/ui'

import RadioGroup from 'components/form/radio-group'
import SelectInput from 'components/form/select-input'
import Input from 'components/form/input'
import useFeatureFlag from 'hooks/useFeatureFlag'
import { Constants } from 'lib/models/constants'
import { Country } from 'lib/models/country'
import {
  AdditionalCharge,
  CoffinType,
  DeceasedLocationType,
} from 'lib/models/funeral'
import {
  COFFINS_WITH_PRICE,
  CREMATION,
  DECEASED_LOCATIONS,
  DELIVER,
  DIGNITY_FEATURE_FLAG_CONFIG,
  SCOTLAND_FEATURE_FLAG_CONFIG,
  NO_YES_TO_BOOLEAN_OPTIONS,
  SCATTER,
  YES_NO_TO_BOOLEAN_OPTIONS,
  BURIAL,
  TREE_NONE,
} from 'utils/enums'
import {
  CountryOption,
  formatAshesInstructionsOptions,
  formatCountryOptions,
  formatTreeOptions,
  formatUrnOptions,
  StringValueFormOption,
} from 'utils/helpers'

Dinero.globalLocale = 'en-GB'
Dinero.defaultCurrency = 'GBP'

export type FuneralQuoteFormFieldsProps = Record<string, unknown>

type SaveData = {
  name: string
  value: string
}

type QuestionProps = {
  label: string
  name: string
  hint?: string
  options?: OptionTypes
  disabled?: boolean
  handleSave: (data: SaveData) => void
}

type AnswerComponent = React.ComponentType<QuestionProps>

export type Crematorium = {
  id: string
  name: string
  timeSlot: 'early-morning' | 'regular' | ''
  earlyMorningPrice: number
  earlyMorningServiceLength: number
  regularPrice: number
  regularServiceLength: number
}

export type QuoteAttributes = {
  funeralType?: typeof CREMATION | typeof BURIAL
  ashesInstruction: typeof DELIVER | typeof SCATTER
  ashesSplitting?: 'true' | 'false' | null
  ashesSplittingPortions: number | null
  treeType: string | ''
  treePlaqueMessage: string | ''
  urnType: string | '' | null
  urnTypes?: string[]
  attendedService?: 'true' | 'false'
  hasHazardousImplant: string
  deceasedReferredToCoroner?: 'true' | 'false' | null
  coffinType: CoffinType | ''
  dressingService?: 'true' | 'false' | null
  deceasedLocationCategory: DeceasedLocationType | ''
  deceasedInMortuary?: 'true' | 'false' | null
  deceasedWeighsOver20Stone?: 'true' | 'false' | null
  additionalCharges: AdditionalCharge[]
  deathCountryId: number | null
}

type FormValues = {
  quoteAttributes: QuoteAttributes
  isEditableQuote?: boolean
}

type OptionTypes =
  | typeof YES_NO_TO_BOOLEAN_OPTIONS
  | typeof NO_YES_TO_BOOLEAN_OPTIONS
  | typeof COFFINS_WITH_PRICE
  | typeof DECEASED_LOCATIONS
  | StringValueFormOption[]

type QuestionType = {
  name: string
  label: string
  options?: OptionTypes
  component?: JSX.Element
  showIf?: (values: FormValues) => boolean
  disabled?: boolean
  hint?: string
  type?: string
}

type QuestionsType = QuestionType[]

const getQuestions = (
  formValues: FormValues,
  isDignitySelected: boolean | undefined,
  isDignityEnabled: boolean,
  isScotlandEnabled: boolean,
  ashesInstructionsOptions: StringValueFormOption[],
  countryOptions: CountryOption[],
  treeOptions: StringValueFormOption[],
  urnOptions: StringValueFormOption[],
  isEditableQuote?: boolean
) => {
  const isDeathCountryScotland =
    countryOptions.find(
      (country: CountryOption) =>
        country.value === formValues?.quoteAttributes?.deathCountryId
    )?.label === 'Scotland'

  const deceasedReferredToCoronerLabel = isDeathCountryScotland
    ? 'Has there been a post-mortem or has the person been referred to the procurator fiscal?'
    : 'Has there been a post-mortem or has the person been referred to the coroner?'

  const urnTypeFirstQuestionLabel =
    formValues?.quoteAttributes?.ashesSplitting === 'true'
      ? 'Which urn would they like for portion 1?'
      : 'Which urn would they like?'

  return [
    {
      name: 'quoteAttributes.deathCountryId',
      label: 'Where did the person die?',
      options: countryOptions,
      component: SelectInput,
      showIf: (values: FormValues) =>
        (!isEditableQuote && isScotlandEnabled) ||
        (isEditableQuote && (isDeathCountryScotland || isScotlandEnabled)),
      disabled: isEditableQuote,
    },
    {
      name: 'quoteAttributes.coffinType',
      label: 'What coffin would they prefer?',
      options:
        (!isEditableQuote && isDignityEnabled) ||
        (isEditableQuote && isDignitySelected)
          ? COFFINS_WITH_PRICE.filter((coffin) => coffin.value === 'oak_veneer')
          : COFFINS_WITH_PRICE,
      component: SelectInput,
    },
    {
      name: 'quoteAttributes.treeType',
      label: 'Which tree would they like?',
      options: treeOptions,
      component: SelectInput,
    },
    {
      name: 'quoteAttributes.treePlaqueMessage',
      label: 'What would they like the tree plaque to say?',
      showIf: (values: FormValues) =>
        values.quoteAttributes?.treeType &&
        values.quoteAttributes.treeType !== TREE_NONE,
    },
    {
      name: 'quoteAttributes.ashesInstruction',
      label: 'What would they like to do with the ashes?',
      options: ashesInstructionsOptions,
      component: SelectInput,
    },
    {
      name: 'quoteAttributes.ashesSplitting',
      label: 'Would they need the ashes split in 2 or more portions?',
      options: YES_NO_TO_BOOLEAN_OPTIONS,
    },
    {
      name: 'quoteAttributes.ashesSplittingPortions',
      label: 'How many portions do they need the ashes to be split into?',
      type: 'number',
      showIf: (values: FormValues) =>
        values.quoteAttributes?.ashesSplitting === 'true',
    },
    {
      name: 'quoteAttributes.urnTypes[0]',
      label: urnTypeFirstQuestionLabel,
      options: urnOptions,
      showIf: (values: FormValues) =>
        'ashesInstruction' in values.quoteAttributes &&
        values.quoteAttributes.ashesInstruction !== SCATTER,
      component: SelectInput,
    },
    {
      name: 'quoteAttributes.urnTypes[1]',
      label: 'Which urn would they like for portion 2?',
      options: urnOptions,
      showIf: (values: FormValues) =>
        values.quoteAttributes?.ashesSplitting === 'true',
      component: SelectInput,
    },
    {
      name: 'quoteAttributes.urnTypes[2]',
      label: 'Which urn would they like for portion 3?',
      options: urnOptions,
      showIf: (values: FormValues) =>
        values.quoteAttributes?.ashesSplittingPortions &&
        values.quoteAttributes?.ashesSplittingPortions >= 3,
      component: SelectInput,
    },
    {
      name: 'quoteAttributes.urnTypes[3]',
      label: 'Which urn would they like for portion 4?',
      options: urnOptions,
      showIf: (values: FormValues) =>
        values.quoteAttributes?.ashesSplittingPortions &&
        values.quoteAttributes?.ashesSplittingPortions >= 4,
      component: SelectInput,
    },
    {
      name: 'quoteAttributes.deceasedLocationCategory',
      label: 'Where are they now?',
      options: DECEASED_LOCATIONS,
      component: SelectInput,
      showIf: (values: FormValues) => !values.isEditableQuote,
    },
    {
      name: 'quoteAttributes.deceasedInMortuary',
      label: 'Do we need to be there urgently?',
      options: NO_YES_TO_BOOLEAN_OPTIONS,
      hint: 'Select ‘No’ if the person is already in a mortuary',
    },
    {
      name: 'quoteAttributes.deceasedReferredToCoroner',
      label: deceasedReferredToCoronerLabel,
      options: YES_NO_TO_BOOLEAN_OPTIONS,
    },
    {
      name: 'quoteAttributes.deceasedWeighsOver20Stone',
      label: 'Does the person weigh over 20 stone?',
      options: YES_NO_TO_BOOLEAN_OPTIONS,

      // Remove !isDignityEnabled when Dignity is launched
      // When on the editable quote we only want to show this question when Dignity is not the provider on the case
      // When on the lead quote, we only want to show this question when dignity is not enabled
      showIf: (values: FormValues) =>
        (isEditableQuote && !isDignitySelected) ||
        (!isEditableQuote && !isDignityEnabled),
    },
    {
      name: 'quoteAttributes.hasHazardousImplant',
      label:
        'Do they have a pacemaker or other hazardous implant that needs to be removed?',
      options: [
        { value: 'yes', label: 'Yes' },
        { value: 'no', label: 'No' },
        { value: 'not_sure', label: 'Unsure' },
      ],
      showIf: (values: FormValues) =>
        (isEditableQuote && isDignitySelected) ||
        (!isEditableQuote && isDignityEnabled),
    },
  ] as QuestionsType
}

const FuneralQuoteFormFields = ({
  isEditableQuote,
  isDignitySelected,
  isLeadFuneralPlanRedemption,
}: {
  isEditableQuote?: boolean
  isDignitySelected?: boolean
  isLeadFuneralPlanRedemption?: boolean
}): ReactElement => {
  const isDignityEnabled =
    useFeatureFlag(DIGNITY_FEATURE_FLAG_CONFIG) === 'false' ? false : true

  const isScotlandEnabled =
    useFeatureFlag(SCOTLAND_FEATURE_FLAG_CONFIG) === 'false' ? false : true

  const { handleSubmit, setFieldValue, ...context } = useFormikContext()
  const values = context.values as FormValues

  const handleSave = ({ name, value }: SaveData) => {
    if (name === 'quoteAttributes.ashesInstruction' && value === SCATTER) {
      setFieldValue('quoteAttributes.urnType', '')
      setFieldValue('quoteAttributes.urnTypes[0]', '')
      setFieldValue('quoteAttributes.urnTypes[1]', '')
      setFieldValue('quoteAttributes.urnTypes[2]', '')
      setFieldValue('quoteAttributes.urnTypes[3]', '')
    }

    if (name === 'quoteAttributes.deceasedInMortuary' && value === 'false') {
      setFieldValue('quoteAttributes.deceasedReferredToCoroner', 'false')
    }

    if (name === 'quoteAttributes.treeType' && value === TREE_NONE) {
      setFieldValue('quoteAttributes.treePlaqueMessage', '')
    }

    if (name === 'quoteAttributes.ashesSplitting' && value === 'false') {
      setFieldValue('quoteAttributes.ashesSplittingPortions', '')
      setFieldValue('quoteAttributes.urnTypes[1]', '')
      setFieldValue('quoteAttributes.urnTypes[2]', '')
      setFieldValue('quoteAttributes.urnTypes[3]', '')
    }

    if (name === 'quoteAttributes.ashesSplitting' && value === 'true') {
      setFieldValue('quoteAttributes.ashesSplittingPortions', 2)
    }

    if (
      name === 'quoteAttributes.ashesSplittingPortions' &&
      Number(value) < 3
    ) {
      setFieldValue('quoteAttributes.urnTypes[2]', '')
      setFieldValue('quoteAttributes.urnTypes[3]', '')
    }

    if (
      name === 'quoteAttributes.ashesSplittingPortions' &&
      Number(value) < 4
    ) {
      setFieldValue('quoteAttributes.urnTypes[3]', '')
    }

    handleSubmit()
  }

  const cache = useQueryClient()
  const countries = cache.getQueryData('countries') as Country[]
  const countryOptions = formatCountryOptions(countries)

  const constants = cache.getQueryData('constants') as Constants

  const ashesInstructionsOptions = formatAshesInstructionsOptions({
    ashesDeliveryPrice: isLeadFuneralPlanRedemption
      ? 0
      : constants?.attributes?.ashesDeliveryPrice,
    ashesFastTrackDeliveryPrice:
      constants?.attributes?.ashesFastTrackDeliveryPrice,
  })
  const treeOptions = formatTreeOptions(constants?.attributes?.treePrices)
  const urnOptions = formatUrnOptions(constants?.attributes?.urnPrices)

  const questions = getQuestions(
    values,
    isDignitySelected,
    isDignityEnabled,
    isScotlandEnabled,
    ashesInstructionsOptions,
    countryOptions,
    treeOptions,
    urnOptions,
    isEditableQuote
  )

  return (
    <>
      {questions
        .filter(
          ({ showIf }) => !showIf || showIf({ ...values, isEditableQuote })
        )
        .map((question) => {
          const Component = (question.component ??
            RadioGroup) as AnswerComponent

          return (
            <Grid.Item key={question.name}>
              {question.options ? (
                <Component
                  label={question.label}
                  name={question.name}
                  options={question.options}
                  handleSave={handleSave}
                  hint={question.hint}
                  disabled={question.disabled}
                />
              ) : question.type === 'number' ? (
                <Wrapper
                  tag={Input}
                  maxWidth="100px"
                  type="number"
                  label={question.label}
                  name={question.name}
                  handleSave={handleSave}
                />
              ) : (
                <Input
                  label={question.label}
                  name={question.name}
                  handleSave={handleSave}
                  component="textarea"
                />
              )}
            </Grid.Item>
          )
        })}
    </>
  )
}

export default FuneralQuoteFormFields
