import { useCallback } from 'react'

import useApi from 'lib/effects/api'
import { Funeral, FuneralAttributes } from 'lib/models/funeral'
import { QuoteResult, QuoteAttributes } from 'lib/models/funerals/quote'

import EditableQuote from './editable-quote'

export type EditableQuoteProps = {
  funeral: Funeral
  funeralQuote: QuoteResult
  onSave: (attributes: Partial<FuneralAttributes>) => Promise<void>
}

const useEditedQuote = (funeralId: number) => {
  const [{ data }, makeRequest] = useApi()

  return {
    createQuote: useCallback(
      async (attributes: Partial<QuoteAttributes>) => {
        makeRequest({
          url: `/api/funerals/${funeralId}/quote`,
          method: 'POST',
          data: {
            data: {
              type: 'funeral_quote',
              attributes,
            },
          },
        })
      },
      [funeralId, makeRequest]
    ),
    editedQuote: (data as { attributes?: QuoteResult })?.attributes,
  }
}

const EditableQuoteWithData = (
  props: EditableQuoteProps
): React.ReactElement => {
  const { createQuote, editedQuote } = useEditedQuote(props.funeral.id)
  return (
    <EditableQuote
      {...props}
      createQuote={createQuote}
      editedQuote={editedQuote}
    />
  )
}

export default EditableQuoteWithData
