import styled from 'styled-components'
import { Checkbox, Divider, Wrapper } from '@farewill/ui'
import { GTR } from '@farewill/ui/tokens'

const StyledWrapper = styled(Wrapper)`
  display: flex;
`

const StyledCheckbox = styled(Checkbox)`
  margin: 0;

  &:not(:last-of-type) {
    margin-right: ${GTR.M};
  }
`

const FilterActivity = ({
  activityFilters,
  setActivityFilters,
  showEmailFilter,
  showCallsFilter,
  showPaymentsFilter,
}) => {
  const toggleFilter = (filter) =>
    setActivityFilters({
      ...activityFilters,
      [filter]: !activityFilters[filter],
    })
  return (
    <Wrapper margin={[0, 0, 'L']}>
      <StyledWrapper>
        <StyledCheckbox
          size="S"
          name="comments"
          label="Comments"
          checked={activityFilters.comments}
          onChange={() => toggleFilter('comments')}
        />
        <StyledCheckbox
          size="S"
          name="tasks"
          label="Tasks"
          checked={activityFilters.tasks}
          onChange={() => toggleFilter('tasks')}
        />
        {showCallsFilter && (
          <StyledCheckbox
            size="S"
            name="calls"
            label="Contacts"
            checked={activityFilters.calls}
            onChange={() => toggleFilter('calls')}
          />
        )}
        {showEmailFilter && (
          <StyledCheckbox
            size="S"
            name="emails"
            label="Emails"
            checked={activityFilters.emails}
            onChange={() => toggleFilter('emails')}
          />
        )}
        {showPaymentsFilter && (
          <StyledCheckbox
            size="S"
            name="payments"
            label="Payments"
            checked={activityFilters.payments}
            onChange={() => toggleFilter('payments')}
          />
        )}
      </StyledWrapper>
      <Divider margin={['L', 0]} />
    </Wrapper>
  )
}

export default FilterActivity
