import Dinero from 'dinero.js'
import styled from 'styled-components'

import { YES_NO_OPTIONS } from 'utils/enums'
import { strToBool } from 'utils/helpers'

import { showWidowedQuestions } from './helpers'
import { ProbateLeadAttributes } from 'lib/models/lead'
import { QuestionConfig } from 'lib/types/form'

import Tooltip from 'components/tooltip'

import SimpleList from 'components/styled/simple-list'

Dinero.globalLocale = 'en-GB'
Dinero.defaultCurrency = 'GBP'

const TooltipList = styled(SimpleList)`
  margin-bottom: 0;

  li:not(:last-of-type) {
    margin-bottom: 0;
  }
`

const getValueThresholds = (values: ProbateLeadAttributes) => {
  const diedAfter2021 = values.quoteAttributes.dateOfDeath
    ? new Date(values.quoteAttributes.dateOfDeath) >= new Date('2022-01-01')
    : strToBool(values.quoteAttributes.diedAfter2021)

  let estateValueThreshold = '£325,000'

  if (values.quoteAttributes.maritalStatus === 'married_or_civil_partnership') {
    estateValueThreshold = diedAfter2021 ? '£3,000,000' : '£1,000,000'
  } else if (values.quoteAttributes.maritalStatus === 'widowed') {
    estateValueThreshold = '£650,000'
  }

  const giftValueThreshold = diedAfter2021 ? '£250,000' : '£150,000'

  return { estateValueThreshold, giftValueThreshold }
}

const getEstateValueThresholdLabel = (values: ProbateLeadAttributes) => {
  const { estateValueThreshold } = getValueThresholds(values)

  return <>Is the estate worth more than {estateValueThreshold}?</>
}

const getGiftValueThresholdLabel = (values: ProbateLeadAttributes) => {
  const { giftValueThreshold } = getValueThresholds(values)

  return (
    <>
      Did they make any{' '}
      <Tooltip content="A loan doesn’t count as a gift.">
        gifts in the 7 years before they died
      </Tooltip>{' '}
      worth over {giftValueThreshold} in total?
    </>
  )
}
const MARRIED_QUESTIONS: QuestionConfig[] = [
  {
    name: 'quoteAttributes.leftAllToPartner',
    shortLabel: 'Left everything to surviving spouse/civil partner',
    label:
      'Have they left everything to their surviving spouse or civil partner?',
    options: YES_NO_OPTIONS,
    showIf: (values: ProbateLeadAttributes) =>
      values.quoteAttributes.maritalStatus === 'married_or_civil_partnership',
  },
  {
    name: 'quoteAttributes.aboveEstatePassingThreshold',
    shortLabel: 'Amount passing to other people over £325,000',
    label: 'Is the amount passing to other people worth more than £325,000?',
    options: YES_NO_OPTIONS,
    showIf: (values: ProbateLeadAttributes) =>
      !strToBool(values.quoteAttributes.leftAllToPartner),
  },
  {
    name: 'quoteAttributes.estateNotPassingToSpouseValue',
    shortLabel: 'Total amount passing to other people',
    label: 'What is the amount passing to other people?',
    isCurrency: true,
    showIf: (values: ProbateLeadAttributes) =>
      !!strToBool(values.quoteAttributes.aboveEstatePassingThreshold),
    hint: 'This value can be an estimate.',
  },
]

const WIDOWED_QUESTIONS: QuestionConfig[] = [
  {
    name: 'quoteAttributes.widowSpouseLeftWill',
    shortLabel: 'First person to die left a will',
    label: 'Did the first person to die leave a will?',
    options: YES_NO_OPTIONS,
    showIf: showWidowedQuestions,
  },
  {
    name: 'quoteAttributes.widowSpouseLeftEverythingInWill',
    shortLabel: 'First person to die left everything to second person',
    label:
      'Did the first person to die leave everything to the person who died?',
    options: YES_NO_OPTIONS,
    showIf: showWidowedQuestions,
  },
  {
    name: 'quoteAttributes.widowSpouseEstateNotPassedToDeceasedValue',
    shortLabel: `Total amount of first person to die's estate passed to other people`,
    label:
      'How much of the first person to die’s estate passed to other people? (£) ',
    isCurrency: true,
    hint: 'This value can be an estimate.',
    showIf: (values: ProbateLeadAttributes) =>
      strToBool(values.quoteAttributes.widowSpouseLeftEverythingInWill) ===
      false,
  },
]

const ESTATE_THRESHOLD_QUESTION: QuestionConfig = {
  name: 'quoteAttributes.aboveEstateValueThreshold',
  shortLabel: 'Estate value is over threshold',
  getLabel: (values) => getEstateValueThresholdLabel(values),
  options: YES_NO_OPTIONS,
}

const ESTATE_VALUE_QUESTION: QuestionConfig = {
  name: 'quoteAttributes.estateValue',
  shortLabel: 'Approximate estate value',
  label: (
    <>
      How much is the{' '}
      <Tooltip
        content={
          <>
            <span>
              Include all assets (especially share of joint assets even if held
              with spouse), such as:
            </span>
            <TooltipList bullets>
              <li>house</li>
              <li>bank accounts</li>
              <li>shares and investments</li>
              <li>lump sums from insurance policies</li>
              <li>gifts with reservation of benefit</li>
              <li>foreign assets etc</li>
            </TooltipList>
            <span>
              and estimates for assets the caller hasn’t already valued.
            </span>
          </>
        }
      >
        gross estate worth
      </Tooltip>
      ?
    </>
  ),
  hint: 'This value can be an estimate. Exclude gift amounts.',
  isCurrency: true,
}
//  TODO MOVE TO Section 2 Person who has died
// const PERMANENT_HOME_QUESTION: QuestionConfig = {
//   name: 'quoteAttributes.livedInEnglandOrWales',
//   shortLabel: 'Permanent home in England or Wales',
//   label: (
//     <>
//       Was their permanent home in{' '}
//       <Tooltip content="Answer ‘no’ if Scotland, Northern Ireland, Ireland, Isle of Man, Channel Islands or elsewhere.">
//         England or Wales
//       </Tooltip>
//       ?
//     </>
//   ),
//   options: YES_NO_OPTIONS,
// }

const OTHER_QUESTIONS: QuestionConfig[] = [
  {
    name: 'quoteAttributes.hadOverseasAssets',
    shortLabel: 'Had overseas assets',
    label: (
      <>
        Did they have any{' '}
        <Tooltip content="Includes any asset, such as property and bank accounts (movable and immovable)">
          overseas assets
        </Tooltip>
        ?
      </>
    ),
    options: YES_NO_OPTIONS,
  },
  {
    name: 'quoteAttributes.aboveOverseasThreshold',
    shortLabel: 'Had overseas assets worth over £100,000',
    label: 'Were their overseas assets worth over £100,000 in total?',
    options: YES_NO_OPTIONS,
    showIf: (values: ProbateLeadAttributes) =>
      !!strToBool(values.quoteAttributes.hadOverseasAssets),
  },
  {
    name: 'quoteAttributes.totalAssetsAndDebts',
    shortLabel: 'Total assets and debts',
    label: (
      <>
        How many separate{' '}
        <Tooltip content="How many separate bank accounts, shareholdings, savings accounts, credit cards and properties.">
          assets and debts
        </Tooltip>{' '}
        did they have?
      </>
    ),
  },
  {
    name: 'quoteAttributes.debtValue',
    shortLabel: 'Approximate debt value',
    label: 'What is the value of the debt?',
    isCurrency: true,
    hint: 'Used to calculate the net estate value.',
  },
  {
    name: 'quoteAttributes.giftsGivenOverThreshold',
    shortLabel:
      'Made gifts worth more than threshold in 7 years before they died',
    getLabel: (values) => getGiftValueThresholdLabel(values),
    options: YES_NO_OPTIONS,
  },
  {
    name: 'quoteAttributes.hadBusinessOrAgriculturalAssets',
    shortLabel: 'Had business or agricultural property',
    label: (
      <>
        Did they have any{' '}
        <Tooltip content="For example, farmland, a business partnership or shares in a private (limited) company.">
          business or agricultural property
        </Tooltip>
        ?
      </>
    ),
    options: YES_NO_OPTIONS,
  },
  {
    name: 'quoteAttributes.trustAssetsValue',
    shortLabel: 'Total value of trust assets',
    label: (
      <>
        What’s the total value of{' '}
        <Tooltip content="For example, the value of the property they were living in, if it was held on trust for them.">
          trust assets
        </Tooltip>
        ? (£)
      </>
    ),
    hint: 'Optional. Leave blank if caller is unsure.',
    isCurrency: true,
    showIf: (values: ProbateLeadAttributes) =>
      !!strToBool(values.quoteAttributes.benefittedFromTrust),
  },
  {
    name: 'quoteAttributes.estatePassingToDirectDescendant',
    shortLabel: 'Estate passing to a direct descendant',
    label: (
      <>
        Is the estate passing to a{' '}
        <Tooltip content="Including: a child, grandchild or great-grandchild (including step, adopted and fostered), or a spouse or civil partner of any of those.">
          direct descendant
        </Tooltip>
        , such as a child or grandchild?
      </>
    ),
    options: YES_NO_OPTIONS,
    showIf: (values: ProbateLeadAttributes) =>
      !strToBool(values.quoteAttributes.hadWill) &&
      values.quoteAttributes.maritalStatus !== 'married_or_civil_partnership',
  },
  {
    name: 'quoteAttributes.totalProperties',
    shortLabel: 'Total properties',
    label: 'How many properties did the person who died own?',
    showIf: (values: ProbateLeadAttributes) =>
      !!strToBool(values.quoteAttributes.ownedProperty),
  },
  {
    name: 'quoteAttributes.totalPropertiesTransferringToBeneficiary',
    shortLabel: 'Total properties transferring to a beneficiary',
    label: 'How many of the properties are to be transferred to a beneficiary?',
    showIf: (values: ProbateLeadAttributes) =>
      !!strToBool(values.quoteAttributes.ownedProperty),
  },
  {
    name: 'quoteAttributes.propertyPassingToDirectDescendant',
    shortLabel: 'Property passing to a direct descendant',
    label: (
      <>
        Is a property (or part of a property) passing to a{' '}
        <Tooltip content="Including: a child, grandchild or great-grandchild (including step, adopted and fostered), or a spouse or civil partner of any of those.">
          direct descendant
        </Tooltip>
        , such as child or grandchild?
      </>
    ),
    options: YES_NO_OPTIONS,
  },
  {
    name: 'quoteAttributes.mainResidencePassingToDirectDescendant',
    shortLabel: 'Main residence passing to a direct descendant',
    label:
      'Was the property (that is passing to a direct descendant) a main residence of the person who died?',
    options: YES_NO_OPTIONS,
    showIf: (values: ProbateLeadAttributes) =>
      !!strToBool(values.quoteAttributes.propertyPassingToDirectDescendant),
  },
  {
    name: 'quoteAttributes.propertyPassingToDirectDescendantValue',
    shortLabel: 'Value of property passing to direct descendant',
    label:
      'What is the value of the property (or part of the property) that is passing to a direct descendant? (£)',
    isCurrency: true,
    hint: 'E.g. if the property is worth £300,000 and 50% is passing to the child put £150,000',
    showIf: (values: ProbateLeadAttributes) =>
      !!strToBool(
        values.quoteAttributes.mainResidencePassingToDirectDescendant
      ),
  },
]

const MARITAL_STATUS_QUESTIONS = [...MARRIED_QUESTIONS, ...WIDOWED_QUESTIONS]

export const ALL_QUESTIONS = [
  ESTATE_THRESHOLD_QUESTION,
  ESTATE_VALUE_QUESTION,
  ...MARITAL_STATUS_QUESTIONS,
  ...OTHER_QUESTIONS,
]

export const getQuestionsToShow = ({
  questions,
  values,
}: {
  questions?: QuestionConfig[]
  values: ProbateLeadAttributes
}) => {
  return questions?.filter(({ showIf }) => !showIf || showIf(values)) || []
}
