import PropTypes from 'prop-types'
import get from 'lodash/get'
import lowerCase from 'lodash/lowerCase'
import { P } from '@farewill/ui'

import { RELATIONSHIP_TO_CUSTOMER } from 'utils/enums'
import { getFullName } from 'utils/helpers'

const getRelationshipLabel = (value) => {
  const foundOption = RELATIONSHIP_TO_CUSTOMER.find(
    (option) => option.value === value
  )
  return lowerCase(get(foundOption, 'label', ''))
}

const SummaryOfLead = ({ attributes }) => {
  const { contact, product, quoteAttributes } = attributes
  if (!quoteAttributes) return null

  const customerFirstName = contact.firstName

  const customerRefersToThemAs =
    quoteAttributes.customerRefersToThemAs ||
    getRelationshipLabel(quoteAttributes.relationshipToCustomer)

  const relationshipToCustomer =
    customerFirstName && customerRefersToThemAs
      ? `(${customerFirstName}'s ${customerRefersToThemAs})`
      : null

  if (product === 'funeral') {
    if (quoteAttributes.customerIsArrangingTheirOwnFuneral) {
      return <P margin={['S', 0, 0]}>Arranging their own funeral</P>
    }

    const funeralOf = getFullName(quoteAttributes)
    if (!funeralOf) return null

    return (
      <P margin={['S', 0, 0]}>
        Funeral of {funeralOf} {relationshipToCustomer}
      </P>
    )
  }

  if (product === 'probate') {
    if (!quoteAttributes.deceasedFirstName) return null
    const estateOf = `${quoteAttributes.deceasedFirstName} ${quoteAttributes.deceasedLastName}`

    return (
      <P margin={['S', 0, 0]}>
        Estate of {estateOf} {relationshipToCustomer}
      </P>
    )
  }

  return null
}

SummaryOfLead.propTypes = {
  attributes: PropTypes.object.isRequired,
}

export default SummaryOfLead
