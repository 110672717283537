import { Component } from 'react'
import PropTypes from 'prop-types'
import { H, P } from '@farewill/ui'
import { Link } from 'react-router-dom'

import IconList from 'components/icon-list'

import { getAircallContactName, getFullName } from 'utils/helpers'

const ResultsList = ({ searchResults }) => {
  const hasResults = searchResults.length > 0
  if (!hasResults) return <P>No results</P>

  return (
    <IconList
      items={searchResults.map((result) => (
        <span key={result.id}>
          <Link to={`/contacts/${result.id}`}>
            {getFullName(result.attributes)}
          </Link>
          <P size="S">{result.attributes.email || 'No email address'}</P>
        </span>
      ))}
    />
  )
}

class Suggestions extends Component {
  state = {
    isLoading: true,
  }

  componentDidMount = () => {
    this.setState({ isLoading: true })
    this.props.fetchSearchResults()
    this.setState({ isLoading: false })
  }

  render() {
    const { isLoading } = this.state
    const { call, searchResults } = this.props
    if (!call.contact) return null

    return (
      <>
        <H size="S" decorative>
          Searching existing contacts for "{getAircallContactName(call.contact)}
          "
        </H>
        {isLoading ? (
          <P>Loading...</P>
        ) : (
          <ResultsList searchResults={searchResults} />
        )}
      </>
    )
  }
}

Suggestions.propTypes = {
  call: PropTypes.object.isRequired,
  fetchSearchResults: PropTypes.func.isRequired,
  searchResults: PropTypes.array.isRequired,
}

export default Suggestions
