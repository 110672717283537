import { Grid } from '@farewill/ui'

import useNamespace from 'lib/formik/namespace'
import InputFloating from 'components/form/input-floating'

type Props = {
  namespace?: string
}

const CharityFormFields = ({ namespace }: Props): React.ReactElement => {
  const { withNamespace } = useNamespace(namespace ? `${namespace}.` : '')
  return (
    <Grid gap="XS">
      <Grid.Item>
        <InputFloating name={withNamespace('legalName')} label="Legal name" />
      </Grid.Item>
      <Grid.Item>
        <InputFloating name={withNamespace('commonName')} label="Common name" />
      </Grid.Item>
      <Grid.Item>
        <InputFloating name={withNamespace('number')} label="Number" />
      </Grid.Item>
    </Grid>
  )
}

export default CharityFormFields
