import { Grid, H } from '@farewill/ui'
import { useFormikContext } from 'formik'

import CharityInput from 'components/form/charity-input'
import RadioGroup from 'components/form/radio-group'
import { FuneralPlanLeadAttributes } from 'lib/models/lead'
import { YES_NO_OPTIONS } from 'utils/enums'

const FORM_FIELDS_NAMES = {
  WANT_TO_RAISE_MONEY_FOR_CHARITY: 'wantToRaiseMoneyForCharity',
  SECONDARY_CHARITY_VALUE: 'secondaryCharityValue',
} as const

export type SingleCharityValue = {
  name: string
  number?: string
}

export type HandleCharityChange = (charity: SingleCharityValue) => void

const RaisingMoneyForCharity = () => {
  const { values, handleSubmit, setFieldValue } =
    useFormikContext<FuneralPlanLeadAttributes>()

  const handleCharityChange: HandleCharityChange = (charity) => {
    setFieldValue(`quoteAttributes.charity`, charity)
    handleSubmit()
  }

  return (
    <Grid.Item data-testId="raising-money-for-charity">
      <H size="S" margin={['XL', 0, 'S']}>
        Raising money for charity
      </H>
      <Grid style={{ gridAutoFlow: 'row' }}>
        <Grid.Item>
          <RadioGroup
            name={`quoteAttributes.${FORM_FIELDS_NAMES.WANT_TO_RAISE_MONEY_FOR_CHARITY}`}
            label="Do they want their loved ones to raise money for a charity in their memory?"
            handleSave={(values) => {
              if (['false', 'none'].includes(values.value)) {
                setFieldValue(`quoteAttributes.charity`, null)
              }
              handleSubmit()
            }}
            options={YES_NO_OPTIONS}
          />
        </Grid.Item>
        {['true', 'different'].includes(
          values.quoteAttributes[
            FORM_FIELDS_NAMES.WANT_TO_RAISE_MONEY_FOR_CHARITY
          ] || ''
        ) && (
          <Grid.Item>
            <CharityInput
              name="charity"
              label="Search for charity name"
              onCharityChanged={handleCharityChange}
              charityValues={values.quoteAttributes['charity']}
            />
          </Grid.Item>
        )}
      </Grid>
    </Grid.Item>
  )
}

export default RaisingMoneyForCharity
