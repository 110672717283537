import styled from 'styled-components'

const StyledImage = styled.img`
  width: 50px;
  height: 50px;
  object-fit: cover;
`

const AircallIcon = ({ className }: { className?: string }) => {
  return (
    <StyledImage
      src="/images/aircall-logo.png"
      role="presentation"
      className={className}
    />
  )
}

export default AircallIcon
