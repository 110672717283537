import { MouseEvent, useState } from 'react'
import { Button, Divider, H, P, Wrapper } from '@farewill/ui'
import { BORDER, COLOR, FONT, GTR } from '@farewill/ui/tokens'
import styled, { css } from 'styled-components'
import { useFormikContext } from 'formik'
import * as Sentry from '@sentry/react'

import AIIcon from 'components/ai-icon'
import { Input } from 'components/form/index'
import useApi from 'lib/effects/api'

import { CustomLeadEvent } from './types'

const StyledNotesWrapper = styled(Wrapper)`
  textarea {
    font-size: ${FONT.SIZE.S};
  }

  &:focus-within {
    textarea,
    div {
      border-color: ${COLOR.GREY.MEDIUM};
    }
  }
`

const StyledNoteInput = styled(Input)<{ $isAlreadyImproved: boolean }>`
  ${({ $isAlreadyImproved }) => css`
    ${!$isAlreadyImproved &&
    `
      border-bottom: 0;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    `}
  `}

  &:focus {
    border-color: ${COLOR.GREY.LIGHT};
  }
`

const StyledImproveNotesWrapper = styled(Wrapper)<{ $yellow: boolean }>`
  ${({ $yellow }) => css`
    border: 2px solid ${$yellow ? COLOR.ACCENT.PRIMARY : COLOR.GREY.LIGHT};
    border-bottom-left-radius: ${BORDER.RADIUS.S};
    border-bottom-right-radius: ${BORDER.RADIUS.S};
    ${$yellow &&
    `background: linear-gradient(180deg, ${COLOR.ACCENT.PRIMARY_10}, #ffffff);`}
  `}
  border-top: 0;
  transition: border-color 0.2s ease-in-out 0s;

  button {
    font-size: ${FONT.SIZE.S};

    span {
      padding-left: ${GTR.XS};
    }
  }
`

const StyledFlex = styled(Wrapper)`
  display: flex;
  gap: ${GTR.S};
`

const StyledTransparentDivider = styled(Wrapper)`
  height: 16px;
  border-left: 2px solid ${COLOR.GREY.LIGHT};
  border-right: 2px solid ${COLOR.GREY.LIGHT};
  transition: border-color 0.2s ease-in-out 0s;
`

type OpenAIResponse = {
  type: string
  attributes: {
    improvedNote: string[]
  }
}

const Note = ({
  metadata,
}: {
  metadata: {
    notes: string
    disposition: string
  }
}) => {
  const [{ isLoading, errors }, makeRequest] = useApi<OpenAIResponse>()
  const [aiGeneratedNote, setAiGeneratedNote] = useState<{
    improvedNote: string[]
  }>()
  const [isAlreadyImproved, setIsAlreadyImproved] = useState(false)
  const { setFieldValue } = useFormikContext<CustomLeadEvent>()
  const isButtonDisabled = metadata.notes.length < 65

  const handleImproveNoteInit = async (e: MouseEvent) => {
    e.preventDefault()

    try {
      const response = await makeRequest<{
        data: {
          attributes: { improvedNote: string[] }
          type: string
        }
      }>({
        url: `/api/lead-events/notes/improvement`,
        method: 'POST',
        data: {
          note: metadata.notes,
        },
      })

      setAiGeneratedNote(response?.data?.attributes)
    } catch (err) {
      Sentry.captureException(new Error(`Note AI improvement failed - ${err}`))
    }
  }

  const handleAcceptNote = (e: MouseEvent) => {
    e.preventDefault()
    setFieldValue('metadata.notes', aiGeneratedNote?.improvedNote.join('\n\n'))
    setAiGeneratedNote(undefined)
    setIsAlreadyImproved(true)
  }

  const handleDiscardNote = (e: MouseEvent) => {
    e.preventDefault()
    setAiGeneratedNote(undefined)
  }

  return (
    <StyledNotesWrapper>
      <StyledNoteInput
        name="metadata.notes"
        component="textarea"
        label="Notes"
        rows={8}
        $isAlreadyImproved={isAlreadyImproved}
      />

      {!isAlreadyImproved && (
        <>
          <StyledTransparentDivider />

          <StyledImproveNotesWrapper $yellow={!!aiGeneratedNote}>
            <Wrapper padding={[0, 'S']}>
              <Divider
                margin={[0, 0, 'S']}
                color={
                  aiGeneratedNote ? COLOR.ACCENT.PRIMARY : COLOR.GREY.LIGHT
                }
              />
            </Wrapper>
            {aiGeneratedNote && (
              <Wrapper padding={[0, 'S']}>
                <H tag="h3" size="XS" padding={['S', 0]} margin={0}>
                  <AIIcon /> <span>Suggested note</span>
                </H>

                {aiGeneratedNote.improvedNote.map((note, index) => (
                  <P size="S" key={index}>
                    {note}
                  </P>
                ))}

                <Divider margin={[0, 0, 'S']} color={COLOR.ACCENT.PRIMARY} />
              </Wrapper>
            )}
            <Wrapper padding="S">
              {aiGeneratedNote ? (
                <StyledFlex>
                  <Button.Primary size="S" onClick={handleAcceptNote}>
                    Accept
                  </Button.Primary>
                  <Button.Bordered size="S" onClick={handleDiscardNote}>
                    Discard
                  </Button.Bordered>
                </StyledFlex>
              ) : (
                <Button.Bordered
                  onClick={handleImproveNoteInit}
                  size="S"
                  disabled={isButtonDisabled}
                >
                  <AIIcon disabled={isButtonDisabled} />{' '}
                  {isButtonDisabled ? (
                    <span>Note too short to improve</span>
                  ) : isLoading ? (
                    <span>Loading improvement...</span>
                  ) : (
                    <span>Improve note</span>
                  )}
                </Button.Bordered>
              )}
              {errors.length > 0 && (
                <P
                  margin={0}
                  padding={['S', 0, 0]}
                  size="S"
                  color={COLOR.STATE.ERROR}
                >
                  Something went wrong, try again!
                </P>
              )}
            </Wrapper>
          </StyledImproveNotesWrapper>
        </>
      )}
    </StyledNotesWrapper>
  )
}

export default Note
