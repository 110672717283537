import { Button, Grid, H, P, Wrapper } from '@farewill/ui'
import { GTR } from '@farewill/ui/tokens'
import MessageBox from 'components/message-box'
import useApi from 'lib/effects/api'
import { useEffect, useState } from 'react'

type CircleCIResponse = {
  id: string
  number: number
  state: string
  created_at: string
}

const SiteManagement = () => {
  const [hasCookie, setHasCookie] = useState(false)
  const [hasLaunchedRebuild, setHasLaunchedRebuild] = useState(false)
  const [hasError, setHasError] = useState(false)
  const [{ isLoading }, rebuildFarewillCom] = useApi()

  useEffect(() => {
    if (document.cookie.includes('websiteRebuild')) {
      setHasCookie(true)
    }
  }, [])

  const handleWebsiteRebuild = async () => {
    try {
      const res = await rebuildFarewillCom<{
        data: { attributes: CircleCIResponse; id: string }
      }>({
        url: '/api/farewill-com-rebuild',
      })

      if (res.data.attributes.state === undefined) {
        throw new Error('Something went wrong')
      }

      document.cookie = `websiteRebuild=${res.data.id};max-age=900`
      setHasCookie(true)
      setHasLaunchedRebuild(true)
    } catch (error) {
      console.error(error)
      setHasError(true)
    }
  }

  return (
    <Grid padding={[GTR.XL, 0, 0]}>
      <Grid.Item span={6}>
        <H size="XL" decorative>
          Farewill.com Management
        </H>
        {hasError && (
          <MessageBox margin={[0, 0, 'L']} error>
            Something went wrong! Talk to engineering team
          </MessageBox>
        )}
        <P>
          If you've added a new role in Lever and want it to be reflected in our
          marketing website, click the button below to rebuild the website.
        </P>

        {hasLaunchedRebuild ? (
          <MessageBox success>
            You've launched rebuild of marketing website. It should take
            aproximately 15 minutes for it to rebuild.
          </MessageBox>
        ) : (
          <>
            <Wrapper margin={['L', 0, 'XL']}>
              <Button.Primary
                disabled={hasCookie || hasError || isLoading}
                onClick={handleWebsiteRebuild}
                loading={isLoading}
              >
                Rebuild Farewill.com
              </Button.Primary>
            </Wrapper>

            {!hasError && (
              <MessageBox warning>
                {hasCookie ? (
                  <>
                    You've requested rebuild of the website in last 15 minutes.
                    <br /> <strong>Come back later</strong>.
                  </>
                ) : (
                  <>
                    Make sure to only run it once. It will take approximately 15
                    min for website to rebuild. Don't run it multiple times in
                    that period.
                  </>
                )}
              </MessageBox>
            )}
          </>
        )}
      </Grid.Item>
    </Grid>
  )
}

export default SiteManagement
