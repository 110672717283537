import {
  createStore as reduxCreateStore,
  applyMiddleware,
  compose,
} from 'redux'
import thunk from 'redux-thunk'
import promise from 'redux-promise-middleware'
import reducer from './reducers'

let composeEnhancers = compose
if (process.env.NODE_ENV === 'development') {
  composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
}

const createStore = (initialState) =>
  reduxCreateStore(
    reducer,
    initialState,
    composeEnhancers(applyMiddleware(promise, thunk))
  )

const store = createStore()
export default store
