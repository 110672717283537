import { Grid, H, P } from '@farewill/ui'
import InputFloating from 'components/form/input-floating'
import { SaveProp } from './types'

const InternalNotes = ({ handleSave }: { handleSave: SaveProp }) => {
  return (
    <Grid>
      <Grid.Item>
        <H size="M">8. Internal notes</H>
        <P>General notes about the fact find from our team</P>
      </Grid.Item>
      <Grid.Item>
        <InputFloating
          name="internalNotes"
          label="Notes"
          component="textarea"
          handleSave={handleSave}
        />
      </Grid.Item>
    </Grid>
  )
}

export default InternalNotes
