import { createContext, useContext } from 'react'

import useApiHelpers from 'lib/effects/api-helpers'
import useInvoices from 'hooks/useInvoices'

const ActivityContext = createContext()

const useActivityContext = () => {
  const context = useContext(ActivityContext)
  if (context === undefined) {
    throw new Error(
      'useActivityContext must be used within an ActivityProvider'
    )
  }
  return context
}

const ActivityProvider = ({ children }) => {
  const {
    items: comments,
    fetchItems: fetchComments,
    isFetching: isFetchingComments,
    createItem: createComment,
    isCreating: isCreatingComment,
    updateItem: updateComment,
    isUpdating: isUpdatingComment,
    deleteItem: deleteComment,
  } = useApiHelpers({
    baseUrl: `/api/comments`,
    type: 'comments',
  })

  const {
    items: emails,
    fetchItems: fetchEmails,
    actionItem: actionEmail,
    isActionLoading: isActioningEmail,
  } = useApiHelpers({
    baseUrl: '/api/emails',
    type: 'emails',
  })

  const { invoices, fetchInvoices } = useInvoices()

  const { items: statusTransitions, fetchItems: fetchStatusTransitions } =
    useApiHelpers({
      baseUrl: '/api/case-status-transitions',
      type: 'case_status_transitions',
    })

  const value = {
    comments,
    fetchComments,
    isFetchingComments,
    createComment,
    isCreatingComment,
    updateComment,
    isUpdatingComment,
    deleteComment,
    emails,
    fetchEmails,
    actionEmail,
    isActioningEmail,
    invoices,
    fetchInvoices,
    statusTransitions,
    fetchStatusTransitions,
  }

  return (
    <ActivityContext.Provider value={value}>
      {children}
    </ActivityContext.Provider>
  )
}

export { ActivityProvider, useActivityContext }
