import { useCallback, useEffect, useState } from 'react'
import { Wrapper } from '@farewill/ui'
import { Formik, Form } from 'formik'
import { toast } from 'react-toastify'
import { useQueryClient } from 'react-query'

import { formatAdminUserOptions } from 'utils/helpers'

import Dropdown from 'components/dropdown'
import SelectInput from 'components/form/select-input'

import { useKeys } from './keyboard-shortcut-manager'

const OWNERSHIP_ATTRIBUTES = [
  { key: 'caseOwnerId', label: 'Owner' },
  { key: 'holidayCoverOwnerId', label: 'Cover' },
]

const getLabelForField = (name) =>
  OWNERSHIP_ATTRIBUTES.find((attr) => attr.key === name)?.label

const OwnerAssignment = ({
  children,
  caseAttributes,
  loggedInAdminUserId,
  updateCase,
  autoFocusField,
  onOpenChange,
}) => {
  const { updatedAt } = caseAttributes
  const [isUpdating, setIsUpdating] = useState(false)
  const cache = useQueryClient()
  const adminUsers = cache.getQueryData('adminUsers')
  const initialValues = {}
  const [isOpen, setIsOpen] = useState(false)

  OWNERSHIP_ATTRIBUTES.forEach(({ key }) => {
    initialValues[key] = caseAttributes[key] ?? null
  })

  const adminUserOptions = formatAdminUserOptions(
    adminUsers,
    loggedInAdminUserId
  )

  const handleEscapeClose = useCallback(() => {
    setIsOpen(false)
    onOpenChange && onOpenChange(false)
  }, [onOpenChange, setIsOpen])

  useKeys(['Escape'], handleEscapeClose, {
    eventName: 'keydown',
    enabled: isOpen,
  })

  const handleSave = async ({ name, value }) => {
    setIsUpdating(true)
    await updateCase({ [name]: value ?? null })

    const label = getLabelForField(name)
    let message = `${label} was unassigned`
    if (value != null) {
      const displayName = adminUsers.find((user) => user.id === value)
        ?.attributes?.name
      message = `${label} assigned to ${displayName}`
    }

    toast(message, {
      toastId: 'owner-assignement',
    })
    setIsUpdating(false)
  }

  useEffect(() => {
    setIsOpen(autoFocusField != null)
  }, [autoFocusField])

  return (
    <div
      onClick={(e) => {
        e.preventDefault()
      }}
    >
      <Dropdown
        updatedAt={updatedAt}
        trigger={children}
        toggleCallback={() => setIsOpen(!isOpen)}
        isVisible={isOpen}
        content={
          isOpen && (
            <Formik initialValues={initialValues}>
              <Form>
                <Wrapper padding="S">
                  {OWNERSHIP_ATTRIBUTES.map(({ key, label }) => {
                    return (
                      <Wrapper padding="XS" key={key}>
                        <SelectInput
                          label={label}
                          name={key}
                          options={adminUserOptions}
                          small
                          handleSave={handleSave}
                          disabled={isUpdating}
                          isClearable
                          autoFocus={autoFocusField === key}
                        />
                      </Wrapper>
                    )
                  })}
                </Wrapper>
              </Form>
            </Formik>
          )
        }
      />
    </div>
  )
}

export default OwnerAssignment
