import { useEffect } from 'react'
import { FormStatusMessage, P } from '@farewill/ui'
import styled from 'styled-components'
import useApi from 'lib/effects/api'
import { useParams } from 'react-router-dom'
import { formatToHuman } from 'utils/helpers'

const StyledIframe = styled.iframe`
  display: block;
`

export const Download = ({ title }: { title: string }) => {
  const { id: funeralPlanId } = useParams<{ id: string }>()
  const [{ data, isLoading, errors }, makeRequest] = useApi()
  const url = (data as { url: string } | null)?.url

  useEffect(() => {
    makeRequest({
      url: `/api/funeral-plans/${funeralPlanId}/documents/${title}`,
    })
  }, [funeralPlanId, makeRequest, title])

  return (
    <>
      {errors.length > 0 ? (
        <FormStatusMessage status="error" title="Sorry, Something went wrong">
          <P>
            Unable to download document. Please try again or contact IT
            department.
          </P>
        </FormStatusMessage>
      ) : (
        <>
          {!isLoading && url && (
            <StyledIframe
              title={`Document download for ${formatToHuman(title)}`}
              aria-hidden="true"
              frameBorder="0"
              width="1"
              height="1"
              src={url}
            />
          )}
        </>
      )}
    </>
  )
}
