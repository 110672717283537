import { Button, H, P, Wrapper } from '@farewill/ui'

import useAdminUserId from 'hooks/useAdminUserId'
import useApi from 'lib/effects/api'
import store from 'state/create-store'
import { showModal } from 'state/actions'
import { FAREWILL_PRODUCTS } from 'utils/enums'
import { getTimeDescriptionFromTimestamp } from 'utils/helpers'

import { useLeadContext } from '../context'
import BrochureModal from './brochure-modal'

const Brochure = () => {
  const { lead, fetchLead } = useLeadContext()
  const {
    contact,
    funeralPlanGuideLastRequestedAt,
    funeralPlanGuideLastPostedAt,
  } = lead.attributes
  const hasName = !!contact?.firstName && !!contact?.lastName
  const hasAddress = !!contact?.addresses?.[0]?.postalCode
  const loggedInAdminUserId = useAdminUserId()
  const [{ isLoading: isBrochureOrderLoading }, sendBrochure] = useApi()

  /** Only show lastRequestedAt info if
   * 1. There is no lastPostedAt info OR
   * 2. There is lastPostedAt info, but it is older than lastRequestedAt
   */
  const showLastRequested =
    funeralPlanGuideLastRequestedAt &&
    (!funeralPlanGuideLastPostedAt ||
      funeralPlanGuideLastPostedAt < funeralPlanGuideLastRequestedAt)
  /** Only show lastPostedAt info if
   * 1. There is no lastRequestedAt info OR
   * 2. There is lastRequestedAt info, but it is older or the same age as lastPostedAt
   */
  const showLastPosted =
    funeralPlanGuideLastPostedAt &&
    (!funeralPlanGuideLastRequestedAt ||
      funeralPlanGuideLastRequestedAt <= funeralPlanGuideLastPostedAt)
  /** Only show "Never posted to customer" when we have no relevant lead events */
  const showNeverPosted =
    !funeralPlanGuideLastRequestedAt && !funeralPlanGuideLastPostedAt

  const handleSendBrochure = async () => {
    store.dispatch(
      showModal({
        component: BrochureModal,
        headingText: 'You are about to send a brochure',
        config: {
          address: contact.addresses?.[0],
          confirmCallback: () => {
            sendBrochure({
              url: `/api/print-requests`,
              method: 'POST',
              data: {
                data: {
                  attributes: {
                    product: FAREWILL_PRODUCTS.FUNERAL_PLAN,
                    contact: {
                      fullName:
                        `${contact.firstName} ${contact.lastName}`.trim(),
                    },
                    lead: { id: lead.id, uuid: lead.attributes.uuid },
                    address: contact.addresses?.[0],
                    adminUserId: loggedInAdminUserId,
                  },
                },
              },
            }).then(() => fetchLead())
          },
        },
      })
    )
  }

  return (
    <>
      <H size="S">Funeral plan guide</H>
      <P>If the customer asks, we can post them a guide:</P>
      <Button.Primary
        onClick={handleSendBrochure}
        disabled={!hasAddress || !hasName}
        loading={isBrochureOrderLoading}
      >
        Post a guide
      </Button.Primary>
      <Wrapper margin={['XS', 0]}>
        {showLastRequested && (
          <P size="S">
            {`Last requested ${getTimeDescriptionFromTimestamp(
              funeralPlanGuideLastRequestedAt,
              true
            )}
              `}
          </P>
        )}
        {showLastPosted && (
          <P size="S">
            {`Last posted ${getTimeDescriptionFromTimestamp(
              funeralPlanGuideLastPostedAt,
              true
            )}
            `}
          </P>
        )}
        {showNeverPosted && <P size="S">No request made yet</P>}
      </Wrapper>
    </>
  )
}

export default Brochure
