import PropTypes from 'prop-types'
import { Grid, H, P } from '@farewill/ui'
import { useFormikContext } from 'formik'
import moment from 'moment'
import { Link } from 'react-router-dom'

import { strToBool, formatDateToISO } from 'utils/helpers'

import NotesInput from 'components/notes-input'
import CircularRadioGroup from 'components/form/circular-radio-group'
import Tooltip from 'components/tooltip'
import MessageBox from 'components/message-box'

import { NOT_CHECKED_OPTIONS } from 'utils/enums'

import withForm from '../fact-find/components/with-form'
import {
  HAS_WILL,
  HAS_BENEFICIARIES_UNDER_EIGHTEEN,
  AT_LEAST_ONE_EXECUTOR_ABLE_TO_APPLY,
} from '../fact-find/will-or-intestacy/field-names'
import { MARITAL_STATUS } from '../fact-find/people/field-names'
import NoWillMessage from './no-will-message'

import {
  WILL_IS_UNDATED_OR_UNSIGNED,
  WILL_IS_UNBOUND,
  WILL_HAS_INCORRECT_STATEMENTS_ABOUT_WITNESSES,
  WILL_HAS_TRUSTS,
  WILL_IS_DIY,
  WILL_HAS_SHAKY_SIGNATURE,
  WILL_HAS_TEAR_OR_MARKS,
  WILL_FEELS_UNSIGNED,
  WILL_HAS_HANDWRITTEN_AMENDMENTS,
  WILL_HAS_UNINITIALLED_AMENDMENTS,
  WILL_HAS_IN_CONTEMPLATION_OF_MARRIAGE_CLAUSE,
  WILL_HAS_ISSUES_WITH_CONDITION,
  WILL_HAS_CODICIL_REPLACING_EXECUTORS,
  WILL_PROBLEMS_AND_COMPLEXITIES_NOTES,
} from './field-names'

const NOTES_TOOLTIP_CONTENT =
  'E.g. description of tears, marks, obliterations, additional staple holes, paperclip indentations, anything attached to the will by paperclip, was it unbound / loose leaf? Do they know how this came about or who does? Needed so that we know who we need to ask to complete an affidavit. When did this happen - after the death or before? With the tears we want to ensure that it was not the intention for the deceased to have revoked it.'

const ProblemsWithTheWill = ({ probateEstateId, saveProbateEstateField }) => {
  const { values } = useFormikContext()

  const factFindUrl = `/probate/estates/${probateEstateId}/fact-find`

  const hasWill = strToBool(values[HAS_WILL])
  const isMarried = values[MARITAL_STATUS] === 'married'

  const {
    willDay,
    willMonth,
    willYear,
    marriageDay,
    marriageMonth,
    marriageYear,
  } = values

  const formattedWillDate = formatDateToISO(willYear, willMonth, willDay)

  const formattedMarriageDate = formatDateToISO(
    marriageYear,
    marriageMonth,
    marriageDay
  )

  const willPrecedesMarriage = moment(formattedWillDate, 'YYYY-MM-DD').isBefore(
    moment(formattedMarriageDate, 'YYYY-MM-DD')
  )

  const showWillPrecedesMarriageWarning =
    hasWill && isMarried && willPrecedesMarriage

  const hasBeneficiariesUnderEighteen = strToBool(
    values[HAS_BENEFICIARIES_UNDER_EIGHTEEN]
  )

  const noExecutorsAbleToApply =
    values[AT_LEAST_ONE_EXECUTOR_ABLE_TO_APPLY] === 'false'

  const showNoExecutorsApplyingWarning =
    hasBeneficiariesUnderEighteen && noExecutorsAbleToApply

  return (
    <Grid gap="XL">
      <Grid.Item span={8}>
        <H size="S" margin={[0, 0, 'M']}>
          3. Problems and complexities with the will
        </H>
        {hasWill && (
          <Grid gap={['L', 'M']}>
            <Grid.Item span={6}>
              <CircularRadioGroup
                name={WILL_IS_UNDATED_OR_UNSIGNED}
                label="Undated / unsigned by testator or witness"
                options={NOT_CHECKED_OPTIONS}
                handleSave={saveProbateEstateField}
                inline
              />
            </Grid.Item>
            <Grid.Item span={6}>
              <CircularRadioGroup
                name={WILL_IS_UNBOUND}
                label="Unbound"
                options={NOT_CHECKED_OPTIONS}
                handleSave={saveProbateEstateField}
                inline
              />
            </Grid.Item>
            <Grid.Item span={6}>
              <CircularRadioGroup
                name={WILL_HAS_INCORRECT_STATEMENTS_ABOUT_WITNESSES}
                label="Incorrect statements about witnesses"
                options={NOT_CHECKED_OPTIONS}
                handleSave={saveProbateEstateField}
                inline
              />
            </Grid.Item>
            <Grid.Item span={6}>
              <CircularRadioGroup
                name={WILL_HAS_TRUSTS}
                label="There are trusts in the will"
                options={NOT_CHECKED_OPTIONS}
                handleSave={saveProbateEstateField}
                inline
              />
            </Grid.Item>
            <Grid.Item span={6}>
              <CircularRadioGroup
                name={WILL_IS_DIY}
                label="DIY will"
                options={NOT_CHECKED_OPTIONS}
                handleSave={saveProbateEstateField}
                inline
              />
            </Grid.Item>
            <Grid.Item span={6}>
              <CircularRadioGroup
                name={WILL_HAS_SHAKY_SIGNATURE}
                label="Shaky signature"
                options={NOT_CHECKED_OPTIONS}
                handleSave={saveProbateEstateField}
                inline
              />
            </Grid.Item>
            <Grid.Item span={6}>
              <CircularRadioGroup
                name={WILL_HAS_TEAR_OR_MARKS}
                label="Tears or marks"
                options={NOT_CHECKED_OPTIONS}
                handleSave={saveProbateEstateField}
                inline
              />
            </Grid.Item>
            <Grid.Item span={6}>
              <CircularRadioGroup
                name={WILL_FEELS_UNSIGNED}
                label="Feels like it wasn’t signed"
                options={NOT_CHECKED_OPTIONS}
                handleSave={saveProbateEstateField}
                inline
              />
            </Grid.Item>
            <Grid.Item span={6}>
              <CircularRadioGroup
                name={WILL_HAS_HANDWRITTEN_AMENDMENTS}
                label="Handwritten amendments"
                options={NOT_CHECKED_OPTIONS}
                handleSave={saveProbateEstateField}
                inline
              />
            </Grid.Item>
            <Grid.Item span={6}>
              <CircularRadioGroup
                name={WILL_HAS_UNINITIALLED_AMENDMENTS}
                label="Amendments not initialled"
                options={NOT_CHECKED_OPTIONS}
                handleSave={saveProbateEstateField}
                inline
              />
            </Grid.Item>
            <Grid.Item span={6}>
              <CircularRadioGroup
                name={WILL_HAS_IN_CONTEMPLATION_OF_MARRIAGE_CLAUSE}
                label="“In contemplation of marriage”"
                options={NOT_CHECKED_OPTIONS}
                handleSave={saveProbateEstateField}
                inline
              />
            </Grid.Item>
            <Grid.Item span={6}>
              <CircularRadioGroup
                name={WILL_HAS_ISSUES_WITH_CONDITION}
                label="Other issues with the will’s condition"
                options={NOT_CHECKED_OPTIONS}
                handleSave={saveProbateEstateField}
                inline
              />
            </Grid.Item>
            <Grid.Item span={6}>
              <CircularRadioGroup
                name={WILL_HAS_CODICIL_REPLACING_EXECUTORS}
                label="Codicil which replaces executors"
                options={NOT_CHECKED_OPTIONS}
                handleSave={saveProbateEstateField}
                inline
              />
            </Grid.Item>
            <Grid.Item>
              <P size="S">
                If yes to anything above,{' '}
                <Tooltip content={NOTES_TOOLTIP_CONTENT}>
                  describe in the notes below
                </Tooltip>
              </P>
            </Grid.Item>
            {showWillPrecedesMarriageWarning && (
              <Grid.Item span={9}>
                <MessageBox warning>
                  The date of the will is earlier than the date of the marriage.
                  See sections <Link to={factFindUrl + '#1.4'}>1.4</Link> and{' '}
                  <Link to={factFindUrl + '#2'}>2</Link> for more information.
                </MessageBox>
              </Grid.Item>
            )}
            {showNoExecutorsApplyingWarning && (
              <Grid.Item span={9}>
                <MessageBox warning>
                  None of the executors are applying and there are beneficiaries
                  aged under 18. See section{' '}
                  <Link to={factFindUrl + '#2'}>2</Link> for more information.
                </MessageBox>
              </Grid.Item>
            )}
            <Grid.Item>
              <NotesInput
                name={WILL_PROBLEMS_AND_COMPLEXITIES_NOTES}
                handleSave={saveProbateEstateField}
              />
            </Grid.Item>
          </Grid>
        )}
        {!hasWill && <NoWillMessage probateEstateId={probateEstateId} />}
      </Grid.Item>
    </Grid>
  )
}

ProblemsWithTheWill.propTypes = {
  probateEstateId: PropTypes.number.isRequired,
  saveProbateEstateField: PropTypes.func.isRequired,
}

export default withForm(ProblemsWithTheWill)
