export const HAS_WILL = 'hasWill'

export const WILL = 'will'
export const HAS_BOUND_WILL = 'hasBoundWill'
export const HAS_ORIGINAL_WILL = 'hasOriginalWill'
export const HAS_FOREIGN_WILLS = 'hasForeignWills'
export const HAS_CODICILS = 'hasCodicils'
export const AT_LEAST_ONE_EXECUTOR_ABLE_TO_APPLY =
  'atLeastOneExecutorAbleToApply'
export const WAS_ADOPTED = 'wasAdopted'

export const HAS_BENEFICIARIES_UNDER_EIGHTEEN = 'hasBeneficiariesUnderEighteen'

export const SPOUSE_SURVIVED_TWENTY_EIGHT_DAYS = 'spouseSurvivedTwentyEightDays'
export const HAS_LIVING_CHILDREN_OR_GRANDCHILDREN =
  'hasLivingChildrenOrGrandchildren'
export const HAS_LIVING_PARENTS = 'hasLivingParents'
export const HAS_LIVING_SIBLINGS_OR_DESCENDANTS_OF =
  'hasLivingSiblingsOrDescendantsOf'
export const HAS_LIVING_HALF_SIBLINGS_OR_DESCENDANTS_OF =
  'hasLivingHalfSiblingsOrDescendantsOf'
export const HAS_LIVING_GRANDPARENTS = 'hasLivingGrandparents'
export const HAS_LIVING_AUNTS_OR_UNCLES_OR_DESCENDANTS_OF =
  'hasLivingAuntsOrUnclesOrDescendantsOf'
export const HAS_LIVING_HALF_AUNTS_OR_UNCLES_OR_CHILDREN_OF =
  'hasLivingHalfAuntsOrUnclesOrChildrenOf'

export const WILL_OR_INTESTACY_NOTES = 'willOrIntestacyNotes'
