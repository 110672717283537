import styled, { css } from 'styled-components'
import { GTR, FONT, COLOR } from '@farewill/ui/tokens'
import { alpha } from '@farewill/ui/helpers/colors'
import { Button } from '@farewill/ui'
import { connect, useField, useFormikContext } from 'formik'

const StyledList = styled.ul`
  margin: 0;
  display: flex;
  border-bottom: 1px solid ${COLOR.GREY.LIGHT};
`

const StyledListItem = styled.li`
  &:not(:last-of-type) {
    margin-right: ${GTR.L};
  }
`

const StyledNavLink = styled(Button.Plain)<{ $active: boolean }>`
  display: block;
  position: relative;
  text-decoration: none;
  color: ${COLOR.BLACK};
  font-weight: ${FONT.WEIGHT.REGULAR};
  font-size: ${FONT.SIZE.M};
  padding-left: 0;
  padding-right: 0;

  ${({ $active }) =>
    $active
      ? css`
          position: relative;
          font-weight: ${FONT.WEIGHT.BOLD};

          &:after {
            background-color: ${COLOR.ACCENT.SECONDARY};
          }
        `
      : `
    &:hover {
      &:after {
        background-color: ${alpha(COLOR.GREY.DARK, 0.15)};
      }
    }

    &:focus {
      &:after {
        background-color: ${alpha(COLOR.GREY.DARK, 0.35)};
      }
    }
    `}

  &:hover,
  &:focus {
    outline: 0;
    text-decoration: none;
  }

  &:after {
    content: '';
    display: block;
    height: 4px;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  }
`

export type Option = {
  label: string
  value: string
  tooltipContent?: string
  isOptionDisabled?: boolean
}

type TabsProps = {
  className?: string
  options: Option[]
  name: string
}

const Tabs = ({ className, name, options }: TabsProps) => {
  const { setFieldValue } = useFormikContext()
  const [meta] = useField(name)

  const handleClick = (
    e: React.MouseEvent<Element, MouseEvent>,
    buttonValue: string
  ) => {
    e.preventDefault()

    setFieldValue(name, buttonValue)
  }

  return (
    <nav>
      <StyledList className={className}>
        {options.map(({ label, value }) => (
          <StyledListItem key={value}>
            <StyledNavLink
              onClick={(e: React.MouseEvent<Element, MouseEvent>) =>
                handleClick(e, value)
              }
              $active={meta.value === value}
            >
              {label}
            </StyledNavLink>
          </StyledListItem>
        ))}
      </StyledList>
    </nav>
  )
}

export default connect(Tabs)
