import pickBy from 'lodash/pickBy'
import mapValues from 'lodash/mapValues'
import { boolToStr, convertPoundsToPence, strToBool } from 'utils/helpers'
import { QuoteAttributes } from 'components/funeral-quote-form-fields'
import { FuneralAttributes } from 'lib/models/funeral'
import { QuoteAttributes as CreateQuoteAttributes } from 'lib/models/funerals/quote'

const parseExtra = (extra: Extra): Extra => ({
  id: extra.id,
  description: extra.description,
  price: extra.price / 100,
})

type Extra = {
  id?: number
  description: string
  price: number
}

const formatExtra = (extra: Extra): Extra => ({
  ...extra,
  price: convertPoundsToPence(extra.price) ?? 0,
})

export const getQuoteAttributes = (
  attributes: FuneralAttributes
): QuoteAttributes => {
  const urnTypes = attributes.urnTypes?.filter(Boolean) || []
  const funeralAttributes = {
    attendedService: attributes.attendedService ? 'true' : 'false',
    coffinType: attributes.coffinType ?? '',
    dressingService: boolToStr(attributes.dressingService),
    funeralType: attributes.funeralType ?? undefined,
    treeType: attributes.treeType ?? '',
    treePlaqueMessage: attributes.treePlaqueMessage ?? '',
    urnType: attributes.urnType ?? '',
    urnTypes,
    deceasedLocationCategory: attributes.deceasedLocationCategory ?? '',
    deceasedInMortuary: boolToStr(!attributes.isUrgentCollection),
    deceasedReferredToCoroner: boolToStr(attributes.referredToCoroner),
    deceasedWeighsOver20Stone: boolToStr(attributes.isBariatric),
    hasHazardousImplant: attributes.hasHazardousImplant ?? '',
    additionalCharges: attributes.additionalCharges?.map(parseExtra) ?? [],
    deathCountryId: attributes.deathCountry?.id ?? null,
    ashesInstruction: attributes.ashesInstruction,
    ashesSplitting: attributes.ashesSplitting ? 'true' : 'false',
    ashesSplittingPortions: attributes?.ashesSplittingPortions ?? null,
  }

  return funeralAttributes as QuoteAttributes
}
// remove any empty string values from urnTypes
const getUrnTypes = (
  changes: Partial<QuoteAttributes>
): string[] | null | undefined => {
  return 'urnTypes' in changes
    ? changes.urnTypes?.filter((urnType) => urnType !== '')
    : null
}

export const getSaveAttributes = (
  changes: Partial<QuoteAttributes>
): Partial<FuneralAttributes> => {
  const updated = {
    ashesSplitting:
      'ashesSplitting' in changes
        ? strToBool(changes.ashesSplitting)
        : undefined,
    ashesSplittingPortions: changes.ashesSplittingPortions || null,
    attendedService:
      'attendedService' in changes
        ? strToBool(changes.attendedService)
        : undefined,
    coffinType: changes.coffinType,
    dressingService: strToBool(changes.dressingService),
    treeType: 'treeType' in changes ? changes.treeType : undefined,
    treePlaqueMessage:
      'treePlaqueMessage' in changes ? changes.treePlaqueMessage : null,
    urnType: 'urnType' in changes ? changes.urnType : undefined,
    urnTypes: getUrnTypes(changes),
    deceasedLocationCategory: changes.deceasedLocationCategory,
    isUrgentCollection: !strToBool(changes.deceasedInMortuary),
    referredToCoroner:
      'deceasedReferredToCoroner' in changes
        ? strToBool(changes.deceasedReferredToCoroner)
        : undefined,
    isBariatric: strToBool(changes.deceasedWeighsOver20Stone),
    additionalCharges: changes.additionalCharges?.map(formatExtra),
    ashesInstruction:
      'ashesInstruction' in changes ? changes.ashesInstruction : undefined,
    funeralType: changes.funeralType ?? undefined,
    hasHazardousImplant:
      'hasHazardousImplant' in changes
        ? changes.hasHazardousImplant || null
        : null,
    deathCountryId: changes.deathCountryId ?? null,
  }

  return pickBy(updated, (value) => value !== '' && value !== undefined)
}

export const getUpdateAttributes = (
  changes: Partial<QuoteAttributes>
): void | CreateQuoteAttributes => {
  const updated = mapValues(
    {
      attendedService:
        'attendedService' in changes
          ? strToBool(changes.attendedService)
          : null,
      ashesSplitting:
        'ashesSplitting' in changes ? strToBool(changes.ashesSplitting) : null,
      ashesSplittingPortions: changes?.ashesSplittingPortions,
      coffinType: changes.coffinType,
      ashesInstruction:
        'ashesInstruction' in changes ? changes.ashesInstruction : null,
      treeType: 'treeType' in changes ? changes.treeType : null,
      treePlaqueMessage:
        'treePlaqueMessage' in changes ? changes.treePlaqueMessage : null,
      urnType: 'urnType' in changes ? changes.urnType : null,
      urnTypes: getUrnTypes(changes),
      dressingService: strToBool(changes.dressingService),
      referredToCoroner:
        'deceasedReferredToCoroner' in changes
          ? strToBool(changes.deceasedReferredToCoroner)
          : null,
      inMortuary: strToBool(changes.deceasedInMortuary),
      isBariatric: strToBool(changes.deceasedWeighsOver20Stone),
      additionalCharges: changes.additionalCharges?.map(formatExtra),
      funeralType: changes.funeralType ?? undefined,
      hasHazardousImplant:
        'hasHazardousImplant' in changes
          ? changes.hasHazardousImplant || null
          : null,
      deathCountryId: changes.deathCountryId,
    },
    castToNull
  ) as CreateQuoteAttributes

  return updated
}

function castToNull<T>(value: T | undefined | '' | null): T | null {
  return value === '' || value === undefined ? null : value
}
