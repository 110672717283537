import upperFirst from 'lodash/upperFirst'
import { Status } from 'api/generated/graphql'

export const FAREWILL_PRODUCTS = {
  FUNERAL: 'funeral',
  FUNERAL_PLAN: 'funeral_plan',
  LPA: 'lpa',
  PROBATE: 'probate',
  WILL: 'will',
} as const

export type FarewillProduct =
  (typeof FAREWILL_PRODUCTS)[keyof typeof FAREWILL_PRODUCTS]

export const FAREWILL_PRODUCTS_OPTIONS = [
  { value: FAREWILL_PRODUCTS.WILL, label: 'Wills' },
  { value: FAREWILL_PRODUCTS.PROBATE, label: 'Probate' },
  { value: FAREWILL_PRODUCTS.FUNERAL, label: 'Funeral' },
  { value: FAREWILL_PRODUCTS.LPA, label: 'LPA' },
  { value: FAREWILL_PRODUCTS.FUNERAL_PLAN, label: 'Funeral plan' },
]

export const FAREWILL_PRODUCT_IDS = {
  will: 'willCaseId',
  probate: 'probateCaseId',
  funeral: 'funeralId',
  lpa: 'lpaCaseId',
  funeral_plan: 'funeralPlanId',
}

export const RELATIONSHIP_TO_CUSTOMER: {
  value: string
  label: string
  legacy?: boolean
}[] = [
  { value: 'husband', label: 'Husband' },
  { value: 'wife', label: 'Wife' },
  { value: 'civil_partner', label: 'Civil partner' },
  { value: 'partner_unmarried', label: 'Partner (unmarried)' },
  { value: 'father', label: 'Father' },
  { value: 'mother', label: 'Mother' },
  /**
   * This option is being replaced with Son/Daughter options,
   * but for the customers that have selected this option in the past,
   * we need to keep it for historical data.
   */
  { value: 'child', label: 'Child', legacy: true },
  { value: 'son', label: 'Son' },
  { value: 'daughter', label: 'Daughter' },
  /** ---  */
  { value: 'grandmother', label: 'Grandmother' },
  { value: 'grandfather', label: 'Grandfather' },
  { value: 'aunt', label: 'Aunt' },
  { value: 'uncle', label: 'Uncle' },
  /**
   * This option is being replaced with Brother/Sister options,
   * but for the customers that have selected this option in the past,
   * we need to keep it for historical data.
   */
  { value: 'sibling', label: 'Sibling', legacy: true },
  { value: 'brother', label: 'Brother' },
  { value: 'sister', label: 'Sister' },
  /** ---  */
  { value: 'niece', label: 'Niece' },
  { value: 'nephew', label: 'Nephew' },
  { value: 'cousin', label: 'Cousin' },
  { value: 'client', label: 'Client' },
  { value: 'other', label: 'Other' },
]

export type CustomerRelationship =
  (typeof RELATIONSHIP_TO_CUSTOMER)[number]['value']

export const CASE_LOST_OPTIONS: {
  value: string
  label: string
  product?: FarewillProduct
}[] = [
  { value: 'diy', label: 'DIY' },
  { value: 'customer_drop_off', label: 'Customer drop-off' },
  { value: 'went_with_someone_else', label: 'Went with someone else' },
  { value: 'too_expensive', label: 'Too expensive' },
  {
    value: 'new_law_incident',
    label: 'Lost to New Law reviews/incident',
    product: FAREWILL_PRODUCTS.PROBATE,
  },
  { value: 'other', label: 'Other' },
]

export const CASE_DECLINED_OPTIONS = [
  { value: 'contentious', label: 'Contentious' },
  { value: 'cross_jurisdictional', label: 'Cross-jurisdictional' },
  { value: 'no_probate', label: 'No probate' },
  { value: 'person_still_alive', label: 'Person still alive' },
  { value: 'insolvency', label: 'Insolvency' },
  { value: 'other', label: 'Other' },
]

export const LPA_CASE_STATUS_OPTIONS = {
  LOST: CASE_LOST_OPTIONS.filter(
    ({ product }) => !product || product === FAREWILL_PRODUCTS.LPA
  ),
  DECLINED: [
    {
      value: 'contentious',
      label: 'Contentious',
    },
    { value: 'other', label: 'Other' },
  ],
}

export const FUNERAL_CASE_LOST_STATUS_OPTIONS = [
  { value: 'created_by_mistake', label: 'Created by mistake' },
  { value: 'dispute_in_family', label: 'Dispute in family' },
  { value: 'duplicate', label: 'Duplicate' },
  { value: 'too_expensive', label: 'Too expensive' },
  { value: 'unable_to_pay', label: 'Unable to pay' },
  { value: 'other', label: 'Other' },
  {
    value: 'went_with_someone_else_local',
    label: 'Went with someone else - local',
  },
  {
    value: 'transport_to_the_crematorium',
    label: "We can't offer this - transport to the crematorium",
  },
  { value: 'repatriation', label: "We can't offer this - repatriation" },
  {
    value: 'pay_in_installments',
    label: "We can't offer this - wanted to pay in installments",
  },
  {
    value: 'viewing',
    label: "We can't offer this - viewing of their loved one",
  },
  {
    value: 'not_happy_travel_distance',
    label: 'Not happy with travel distance - i.e. travel to Bmgham, East Devon',
  },
  {
    value: 'went_with_someone_else_viewing',
    label: 'Went with someone else - viewing',
  },
  { value: 'decided_on_burial', label: "We can't offer this - Burial" },
  {
    value: 'decided_on_attended',
    label: "We can't offer this - Attended Cremation",
  },
  {
    value: 'cultural_faith_requirement',
    label: "We can't offer this - cultural/faith requirement",
  },
  { value: 'embalming', label: "We can't offer this - embalming" },
]

export const FUNERAL_CASE_STATUS_OPTIONS = {
  LOST: FUNERAL_CASE_LOST_STATUS_OPTIONS,
}

export const FUNERAL_CASE_PROBATE_CHECK_INTERESTED = {
  YES: 'Yes',
  NO: 'No',
  LATER: 'Later',
  NOT_ASKED_URGENT_COLLECTION: 'Not_asked_urgent_collection',
} as const

export const FUNERAL_CASE_PROBATE_CHECK_INTERESTED_OPTIONS = [
  {
    label: 'Yes',
    value: FUNERAL_CASE_PROBATE_CHECK_INTERESTED.YES,
  },
  {
    label: 'No',
    value: FUNERAL_CASE_PROBATE_CHECK_INTERESTED.NO,
  },
  {
    label: 'Later',
    value: FUNERAL_CASE_PROBATE_CHECK_INTERESTED.LATER,
  },
  {
    label: 'Not asked (urgent collection)',
    value: FUNERAL_CASE_PROBATE_CHECK_INTERESTED.NOT_ASKED_URGENT_COLLECTION,
  },
]

export const FUNERAL_CASE_PROBATE_NOT_INTERESTED_REASON = {
  HAS_OWN_SOLICITOR: 'has_own_solicitor',
  SOMEONE_ELSE_ORGANISING: 'someone_else_organising',
  TOLD_NOT_NEEDED: 'told_not_needed',
  NOT_IN_SERVICE_AREA: 'not_in_service_area',
  OTHER: 'other',
} as const

export const FUNERAL_CASE_PROBATE_NOT_INTERESTED_REASON_OPTIONS = [
  {
    value: FUNERAL_CASE_PROBATE_NOT_INTERESTED_REASON.HAS_OWN_SOLICITOR,
    label: 'Already sorting with their own solicitor',
  },
  {
    value: FUNERAL_CASE_PROBATE_NOT_INTERESTED_REASON.SOMEONE_ELSE_ORGANISING,
    label: 'Someone else organising probate',
  },
  {
    value: FUNERAL_CASE_PROBATE_NOT_INTERESTED_REASON.TOLD_NOT_NEEDED,
    label: 'Customer assured probate is not needed',
  },
  {
    value: FUNERAL_CASE_PROBATE_NOT_INTERESTED_REASON.NOT_IN_SERVICE_AREA,
    label: 'Not in our service area for probate',
  },
  { value: FUNERAL_CASE_PROBATE_NOT_INTERESTED_REASON.OTHER, label: 'Other' },
]

export const INLINE_FUNERAL_EVENTS = [
  {
    name: 'case_lost',
    label: 'Case lost',
    metadata: [
      {
        name: 'caseLostReason',
        label: 'Reason for case lost',
        options: FUNERAL_CASE_LOST_STATUS_OPTIONS,
      },
    ],
  },
]

export const INLINE_PROBATE_CASE_EVENTS = [
  {
    name: 'case_lost',
    label: 'Case lost',
    metadata: [
      {
        name: 'caseLostReason',
        label: 'Reason case lost',
        options: CASE_LOST_OPTIONS.filter(
          ({ product }) => !product || product === FAREWILL_PRODUCTS.PROBATE
        ),
      },
    ],
  },
  {
    name: 'case_declined',
    label: 'Case declined',
    metadata: [
      {
        name: 'caseDeclinedReason',
        label: 'Reason case declined',
        options: CASE_DECLINED_OPTIONS,
      },
    ],
  },
]

export const ESTATE_ADMIN_REQUIRED_OPTIONS = [
  { value: 'false', label: 'Essential Probate' },
  { value: 'true', label: 'Complete Probate' },
]

export const TAX_FORM_OPTIONS = [
  { value: 'iht205', label: 'IHT205' },
  { value: 'iht217', label: 'IHT217' },
  { value: 'iht400_no_tax', label: 'IHT400 (no tax)' },
  { value: 'iht400_tax', label: 'IHT400 (tax)' },
]

export const NEW_LAW = 'new_law'
export const FAREWILL_LEGAL_SERVICES = 'farewill_legal_services'

export const LAW_FIRM_NAMES = {
  [NEW_LAW]: 'New Law',
  [FAREWILL_LEGAL_SERVICES]: 'Farewill Legal Services',
}

export const LAW_FIRM_NAMES_OPTIONS = Object.entries(LAW_FIRM_NAMES).map(
  ([value, label]) => {
    return { value, label }
  }
)

export const LEAD_EVENT_TYPES = {
  ADDED: 'added',
  CALL_MADE: 'call_made',
  COMMENT_ADDED: 'comment_added',
  CONTACT_REQUESTED: 'contact_requested',
  OWNER_CHANGED: 'owner_changed',
}

export const LEAD_EVENT_TYPE_OPTIONS = [
  { value: 'call_made', label: 'Call' },
  { value: 'comment_added', label: 'Note' },
]

export const LEAD_STATUS = {
  OPEN: 'open',
  QUALIFIED: 'qualified',
  CONVERTED: 'converted',
  LOST: 'lost',
}

export const FUNERAL_LEAD_STATUS = {
  ...LEAD_STATUS,
  BLOCKED: 'blocked',
}

export const LEAD_EVENT_OUTCOME_OPTIONS = [
  { value: LEAD_STATUS.OPEN, label: 'Open' },
  { value: LEAD_STATUS.QUALIFIED, label: 'Qualified' },
  { value: LEAD_STATUS.CONVERTED, label: 'Converted' },
  { value: LEAD_STATUS.LOST, label: 'Lost' },
]

export const FUNERAL_LEAD_EVENT_OUTCOME_OPTIONS = [
  ...LEAD_EVENT_OUTCOME_OPTIONS,
  {
    value: FUNERAL_LEAD_STATUS.BLOCKED,
    label: 'Blocked',
  },
]

export const LEAD_EVENT_DISPOSITION_OPTIONS = [
  { value: 'connected', label: 'Connected' },
  { value: 'no_answer', label: 'No answer' },
]

export const LEAD_CONVERSION_SCORE_OPTIONS = [
  { value: '1', label: '1', tooltipContent: "They're happy with our quote" },
  {
    value: '2',
    label: '2',
    tooltipContent: "They need to check we're right for them",
  },
  {
    value: '3',
    label: '3',
    tooltipContent: 'We still need to provide the quote',
  },
]

export const PROBATE_LEAD_SCORE_OPTIONS = [
  { value: '1', label: '1', tooltipContent: 'Executor, complex' },
  { value: '2', label: '2', tooltipContent: 'Not executor, complex' },
  { value: '3', label: '3', tooltipContent: 'Executor, simple' },
  { value: '4', label: '4', tooltipContent: 'Not executor, simple' },
  { value: '5', label: '5', tooltipContent: 'Other' },
]

export const PROBATE_CASE_SCORE_OPTIONS = [
  { value: '1', label: '1', tooltipContent: 'Ready to sign!' },
  {
    value: '2',
    label: '2',
    tooltipContent: 'Good intent – may need to speak to New Law before signing',
  },
  {
    value: '3',
    label: '3',
    tooltipContent:
      "It's complicated, they definitely need to speak to New Law first",
  },
]

export const PROBATE_CASE_STATUSES = {
  case_lost: 'lost',
  case_declined: 'declined',
  case_booked: 'booked',
  case_closed: 'closed',
  open: 'open',
  referral_made: 'referred',
} as const

export const PROBATE_CASE_STATUSES_OPTIONS = [
  { value: 'open', label: 'Open' },
  { value: 'referral_made', label: 'Referred' },
  { value: 'case_booked', label: 'Booked' },
  { value: 'case_closed', label: 'Closed' },
  { value: 'case_lost', label: 'Lost' },
  { value: 'case_declined', label: 'Declined' },
]

export const FUNERAL_PLANS_STATUSES = {
  open: 'open',
  cancelled: 'cancelled',
  active: 'active',
  closed: 'closed',
  lost: 'lost',
} as const

export const FUNERAL_CASE_STATUSES_OPTIONS = [
  { value: 'open', label: 'Open' },
  { value: 'closed', label: 'Closed' },
  { value: 'unpaid', label: 'Unpaid' },
  { value: 'case_lost', label: 'Lost' },
]

export const WILL_CASE_STATUSES_OPTIONS = [
  { value: 'open', label: 'Open' },
  { value: 'active', label: 'Active' },
  { value: 'closed', label: 'Closed' },
  { value: 'cold', label: 'Cold' },
  { value: 'cancelled', label: 'Cancelled' },
]

export const CASE_STATUSES_OPTIONS = {
  funeral: FUNERAL_CASE_STATUSES_OPTIONS,
  probate: PROBATE_CASE_STATUSES_OPTIONS,
  will: WILL_CASE_STATUSES_OPTIONS,
  funeral_plan: Object.values(Status).map((status) => {
    return { value: status, label: upperFirst(status) }
  }),
}

export const FUNERAL_CASE_NEXT_STAGE = {
  sale: 'Sale',
  urgent_collection: 'Urgent collection',
  prepare_paperwork: 'Prepare paperwork',
  confirm_collection_date: 'Confirm collection date',
  confirm_burial_details: 'Confirm burial details',
  confirm_cremation_date: 'Confirm cremation date',
  confirm_service_details: 'Confirm service details',
  confirm_delivery_date: 'Confirm delivery date',
  feedback_and_close_case: 'Feedback and close case',
}

export const LPA_CASE_NEXT_STAGE = {
  sale: 'Sale',
  fact_find: 'Fact find',
  prepare_application: 'Prepare application',
  close_case: 'Close case',
}

export const PROBATE_CASE_NEXT_STAGE = {
  complete_fact_find: 'Fact-find',
  make_referral: 'Referral',
  book_case: 'Book case',
  prepare_application: 'Prepare application',
  submit_application_to_registry: 'Submit application',
  receive_clearance_from_hmrc: 'Receive HMRC clearance',
  send_probate_to_customer: 'Send probate',
  close_case: 'Close case',
}

export const WILL_CASE_NEXT_STAGE = {
  sale: 'Sale',
  pre_appointment_checklist: 'Pre-appointment checklist',
  fact_find: 'Fact find',
  draft_documents: 'Draft documents',
  send_documents: 'Send documents',
  close_case: 'Close case',
}

export const FUNERAL_CASE_NEXT_STAGE_OPTIONS = Object.entries(
  FUNERAL_CASE_NEXT_STAGE
).map(([value, label]) => {
  return { value, label }
})

export const LPA_CASE_NEXT_STAGE_OPTIONS = Object.entries(
  LPA_CASE_NEXT_STAGE
).map(([value, label]) => {
  return { value, label }
})

export const PROBATE_CASE_NEXT_STAGE_OPTIONS = Object.entries(
  PROBATE_CASE_NEXT_STAGE
).map(([value, label]) => {
  return { value, label }
})

export const WILL_CASE_NEXT_STAGE_OPTIONS = Object.entries(
  WILL_CASE_NEXT_STAGE
).map(([value, label]) => {
  return { value, label }
})

export const CASE_NEXT_STAGE_OPTIONS = {
  probate: PROBATE_CASE_NEXT_STAGE_OPTIONS,
  lpa: LPA_CASE_NEXT_STAGE_OPTIONS,
  funeral: FUNERAL_CASE_NEXT_STAGE_OPTIONS,
  will: WILL_CASE_NEXT_STAGE_OPTIONS,
}

export const FUNERAL_BLOCKED_REASONS_OPTIONS = [
  { label: 'DWP', value: 'dwp' },
  { label: 'Bank invoice', value: 'bank_invoice' },
  { label: 'Disputes', value: 'disputes' },
  { label: "Coroner's case", value: 'coroners_case' },
  { label: 'Other', value: 'other' },
]

export const LPA_UNSUITABLE_REASONS_OPTIONS = [
  { label: 'Created by mistake', value: 'created_by_mistake' },
  { label: 'Customer drop off', value: 'customer_drop_off' },
  { label: 'Too expensive', value: 'too_expensive' },
  { label: 'Uncontactable', value: 'uncontactable' },
  { label: 'Went with someone else', value: 'went_with_someone_else' },
  { label: 'General enquiry', value: 'general_enquiry' },
  {
    label: 'Wrong contact details',
    value: 'wrong_contact_details',
  },
  { label: 'Hearing difficulties', value: 'hearing_difficulties' },
  { label: 'No capacity', value: 'no_capacity' },
  { label: 'DIY', value: 'diy' },
  { label: 'Duplicate', value: 'duplicate' },
  { label: 'Other', value: 'other' },
]

export const LEAD_SOURCE_TYPES = {
  inbound_call: 'inbound_call',
  outbound_call: 'outbound_call',
  contact_request: 'contact_request',
  callback_request: 'callback_request',
  external_referral: 'external_referral',
  internal_referral: 'internal_referral',
  other: 'other',
} as const

export const LEAD_SOURCE_TYPE_OPTIONS = [
  { value: LEAD_SOURCE_TYPES.inbound_call, label: 'Inbound call' },
  { value: LEAD_SOURCE_TYPES.outbound_call, label: 'Outbound call' },
  { value: LEAD_SOURCE_TYPES.contact_request, label: 'Contact request' },
  { value: LEAD_SOURCE_TYPES.callback_request, label: 'Callback request' },
  {
    value: LEAD_SOURCE_TYPES.external_referral,
    label: 'External referral',
  },
  { value: LEAD_SOURCE_TYPES.internal_referral, label: 'Internal referral' },
  { value: LEAD_SOURCE_TYPES.other, label: 'Other' },
] as {
  value: string
  label: string
}[]

export const LEAD_PARTNER_TYPES = {
  none: 'none',
  charity: 'charity',
  other_partner: 'other_partner',
}

export const LEAD_PARTNER_TYPE_OPTIONS = [
  { value: LEAD_PARTNER_TYPES.none, label: 'None' },
  { value: LEAD_PARTNER_TYPES.charity, label: 'Charity' },
  { value: LEAD_PARTNER_TYPES.other_partner, label: 'Other partner' },
]

export const PROBATE_LEAD_PARTNER_TYPE_OPTIONS = [
  { value: LEAD_PARTNER_TYPES.none, label: 'None (default)' },
  { value: LEAD_PARTNER_TYPES.other_partner, label: 'Partner' },
]

export const PARTNER_TERM_PATHS = {
  'The Royal British Legion': '/terms/partner-rbl-phone',
}

export const LEAD_SOURCE_IDENTIFIERS = {
  /** Chooseable when  manually creating a lead on Backstage */
  farewill_trustees: 'farewill_trustees',
  funeral: 'funeral',
  probate: 'probate',
  will: 'will',
  online_will: 'online_will',
  started_online_will: 'started_online_will',
  failed_online_will: 'failed_online_will',
  death_notification: 'death_notification',

  /**
   * NOT manually chooseable: reserved for Probate leads generated from the Funeral
   * fact-find, Funeral leads generated from a Funeral Plan redemption or LPA generated
   * from a Will case
   * */
  funeral_cross_sell: 'funeral_cross_sell',
  funeral_plan_redemption: 'funeral_plan_redemption',
  wills_cross_sell: 'wills_cross_sell',
} as const

/**
 * Options when creating a lead on Backstage. internal_referral and outbound_call
 * are manually choosable, the `cross_sell` options will be automatically preselected
 * based on set of requirements.
 * -`funeral_cross_sell` - when Probate lead has funeralId within quoteAttributes
 * -`wills_cross_sell` - when LPA lead has willCaseId within quoteAttributes
 * */
export const LEAD_SOURCE_IDENTIFIER_OPTIONS = {
  internal_referral: [
    { value: LEAD_SOURCE_IDENTIFIERS.will, label: 'Farewill Wills' },
    { value: LEAD_SOURCE_IDENTIFIERS.probate, label: 'Farewill Probate' },
    { value: LEAD_SOURCE_IDENTIFIERS.funeral, label: 'Farewill Cremation' },
    {
      value: LEAD_SOURCE_IDENTIFIERS.farewill_trustees,
      label: 'Farewill Trustees',
    },
    {
      value: LEAD_SOURCE_IDENTIFIERS.started_online_will,
      label: 'Started Online Will',
    },
    {
      value: LEAD_SOURCE_IDENTIFIERS.failed_online_will,
      label: 'Failed Online Will',
    },
    {
      value: LEAD_SOURCE_IDENTIFIERS.death_notification,
      label: 'Death Notification',
    },
  ],
  cross_sell: [
    {
      value: LEAD_SOURCE_IDENTIFIERS.funeral_cross_sell,
      label: 'Funeral cross sell',
    },
    {
      value: LEAD_SOURCE_IDENTIFIERS.wills_cross_sell,
      label: 'Will cross sell',
    },
  ],
  outbound_call: [
    {
      value: LEAD_SOURCE_IDENTIFIERS.online_will,
      label: 'Farewill Online Will',
    },
  ],
}

export const NOT_ASKED_OPTIONS = [
  { label: 'Not asked', value: '' },
  { label: 'Yes', value: 'true' },
  { label: 'No', value: 'false' },
]

export const NOT_CHECKED_OPTIONS = [
  { label: 'Not checked', value: '' },
  { label: 'Yes', value: 'true' },
  { label: 'No', value: 'false' },
]

export const NOT_ASKED_AND_UNSURE_OPTIONS = [
  { label: 'Not asked', value: '' },
  { label: 'Yes', value: 'yes' },
  { label: 'No', value: 'no' },
  { label: 'They’re unsure', value: 'unsure' },
]

export const YES_NO_OPTIONS = [
  { label: 'Yes', value: 'true' },
  { label: 'No', value: 'false' },
]

export const YES_NO_UNSURE_OPTIONS = [
  { value: 'yes', label: 'Yes' },
  { value: 'no', label: 'No' },
  { value: 'not_sure', label: 'Not sure' },
]

export const YES_NO_DOES_NOT_KNOW_OPTIONS = [
  { label: 'Yes', value: 'true' },
  { label: 'No', value: 'false' },
  { label: 'The customer doesn’t know', value: '' },
]

export const CONFIRMED_AND_CHECKED_OPTIONS = [
  { value: 'false', label: 'Not checked' },
  { value: 'true', label: 'Checked and confirmed' },
]

export const PR_ROLE_OPTIONS = [
  { value: 'acting', label: 'Acting' },
  { value: 'power_reserved', label: 'Power reserved' },
  { value: 'renouncing', label: 'Renouncing' },
  {
    value: 'unable_to_act',
    label: 'Unable to act (e.g. capacity, ex spouse)',
  },
  { value: 'dead', label: 'Dead' },
]

export const LASTING_POWER_OF_ATTORNEY = 'lasting_power_of_attorney'
export const ENDURING_POWER_OF_ATTORNEY = 'enduring_power_of_attorney'
export const DEPUTYSHIP_ORDER = 'deputyship_order'
export const NONE = 'none'

export const POWER_OF_ATTORNEY_OR_ORDER_TYPE_OPTIONS = [
  {
    label: 'Lasting Power of Attorney for Property and Financial Affairs',
    value: LASTING_POWER_OF_ATTORNEY,
  },
  {
    label: 'Enduring Power of Attorney',
    value: ENDURING_POWER_OF_ATTORNEY,
  },
  {
    label: 'Deputyship Order from Court of Protection',
    value: DEPUTYSHIP_ORDER,
  },
  {
    label: 'None',
    value: NONE,
  },
]

export const MARRIED = 'married'
export const MARRIED_OR_CIVIL_PARTNERSHIP = 'married_or_civil_partnership'
export const WIDOWED = 'widowed'
export const DIVORCED = 'divorced'
export const NEVER_MARRIED = 'never_married'
export const SINGLE = 'single'
export const JUDICIALLY_SEPARATED = 'judicially_separated'
// additional relationship statuses for Wills
export const LIVING_WITH_PARTNER = 'living_with_partner'
export const CIVIL_PARTNERSHIP = 'civil_partnership'
export const ENGAGED = 'engaged'
export const MARRIED_BUT_SEPARATED = 'married_but_separated'
export const CIVIL_PARTNERSHIP_BUT_SEPARATED = 'civil_partnership_but_separated'
export const DISSOLVED = 'dissolved'
export const WIDOWED_AND_REMARRIED = 'widowed_and_remarried'
export const COHABITING = 'co-habiting'

export const MARITAL_STATUS_OPTIONS = [
  { label: 'Married', value: MARRIED },
  { label: 'Widowed', value: WIDOWED },
  { label: 'Divorced', value: DIVORCED },
  { label: 'Never married', value: NEVER_MARRIED },
  { label: 'Judicially separated', value: JUDICIALLY_SEPARATED },
]

export const PROBATE_MARITAL_STATUS_OPTIONS = [
  { label: 'Married / Civil partner', value: MARRIED_OR_CIVIL_PARTNERSHIP },
  { label: 'Widowed', value: WIDOWED },
  { label: 'Divorced', value: DIVORCED },
  { label: 'Single (including living with partner)', value: SINGLE },
]

export const WILL_RELATIONSHIP_STATUS_OPTIONS = [
  { label: 'Single', value: SINGLE },
  { label: 'Living with partner', value: LIVING_WITH_PARTNER },
  { label: 'Married', value: MARRIED },
  { label: 'Civil partnership', value: CIVIL_PARTNERSHIP },
  { label: 'Engaged', value: ENGAGED },
  { label: 'Married but separated', value: MARRIED_BUT_SEPARATED },
  {
    label: 'Civil partnership but separated',
    value: CIVIL_PARTNERSHIP_BUT_SEPARATED,
  },
  { label: 'Divorced', value: DIVORCED },
  { label: 'Dissolved', value: DISSOLVED },
  { label: 'Widowed', value: WIDOWED },
  { label: 'Widowed and remarried', value: WIDOWED_AND_REMARRIED },
]

export const RELATIONSHIP_STATUS_WITH_PARTNER = [
  LIVING_WITH_PARTNER,
  MARRIED,
  CIVIL_PARTNERSHIP,
  ENGAGED,
  WIDOWED_AND_REMARRIED,
]

export const RELATIONSHIP_STATUS_WITHOUT_PARTNER = [
  SINGLE,
  WIDOWED,
  MARRIED_BUT_SEPARATED,
  CIVIL_PARTNERSHIP_BUT_SEPARATED,
  DISSOLVED,
  DIVORCED,
]

export const SEPARATION_DECREE_LABELS = {
  [DIVORCED]: 'Court where divorce decree was issued',
  [JUDICIALLY_SEPARATED]: 'Court where decree was issued',
}

export const SEPARATION_DATE_LABELS = {
  [DIVORCED]: 'Date of divorce',
  [JUDICIALLY_SEPARATED]: 'Date of judicial separation',
}

export const FUNERAL_MARITAL_STATUS_OPTIONS = [
  { label: 'Single', value: 'single' },
  {
    label: 'Married or in civil partnership',
    value: 'married_or_civil_partnership',
  },
  { label: 'Widowed', value: 'widowed' },
]

export const DEATH_ADDRESS_TYPES = [
  { label: 'Their home', value: 'home' },
  { label: 'Hospital', value: 'hospital' },
  { label: 'Hotel', value: 'hotel' },
  { label: 'Nursing home', value: 'nursing_home' },
  { label: 'Hospice', value: 'hospice' },
  { label: 'Other', value: 'other' },
]

export const PROBATE_CASE_ERROR_MADE_BY_OPTIONS = [
  { value: 'customer', label: 'Customer' },
  { value: 'farewill', label: 'Farewill, before referral' },
  {
    value: 'farewill_legal_services',
    label: 'FWLS, after referral',
  },
  {
    value: 'probate_registry',
    label: 'Probate registry',
  },
  {
    value: 'other',
    label: 'Other (please describe)',
  },
]

export const PROBATE_CASE_ERROR_TYPE_OPTIONS = [
  { value: 'transposing', label: 'Transposing' },
  { value: 'legal', label: 'Legal' },
  { value: 'factual', label: 'Factual' },
  { value: 'data_breach', label: 'Data breach' },
  { value: 'missing_documents', label: 'Missing documents' },
  {
    value: 'info_not_collected_or_given',
    label: 'Info not collected / given',
  },
]

export const PROBATE_PROPERTY_OWNERSHIP_TYPE = {
  SOLELY_OWNED: 'solely_owned',
  JOINT_TENANTS: 'joint_tenants',
  TENANTS_IN_COMMON: 'tenants_in_common',
  UNSURE: 'unsure',
} as const

export const PROBATE_PROPERTY_OWNERSHIP_TYPE_OPTIONS = [
  {
    value: PROBATE_PROPERTY_OWNERSHIP_TYPE.SOLELY_OWNED,
    label: 'Solely owned',
  },
  {
    value: PROBATE_PROPERTY_OWNERSHIP_TYPE.JOINT_TENANTS,
    label: 'Joint tenants',
  },
  {
    value: PROBATE_PROPERTY_OWNERSHIP_TYPE.TENANTS_IN_COMMON,
    label: 'Tenants in common',
  },
  { value: PROBATE_PROPERTY_OWNERSHIP_TYPE.UNSURE, label: 'Unsure' },
]

export const PROBATE_THRESHOLD_LINK =
  'https://www.notion.so/farewill/Probate-limits-958006ab7e48420a8674973d329fdf41'

export const DIGNITY = 'dignity'
export const CHAPTER_RESPONSE = 'chapter_response'

export const FUNERAL_PROVIDERS = [
  { value: 'rowland_brothers', label: 'Rowland Brothers', retired: true },
  { value: CHAPTER_RESPONSE, label: 'Chapter Response', retired: true },
  { value: DIGNITY, label: 'Dignity' },
]

export const FUNERAL_SERVICE_TYPES = [
  { value: 'direct', label: 'Direct cremation' },
  { value: 'service', label: 'Funeral service' },
  { value: 'burial', label: 'Burial' },
]

export const TITLE_OPTIONS = [
  { value: 'MR', label: 'Mr' },
  { value: 'MRS', label: 'Mrs' },
  { value: 'MISS', label: 'Miss' },
  { value: 'MS', label: 'Ms' },
]

export const DOCUMENT_CATEGORIES = [
  { value: 'wills', label: 'Wills' },
  { value: 'certificates', label: 'Certificates' },
  { value: 'correspondence', label: 'Correspondence' },
  { value: 'probate_applications', label: 'Probate applications' },
  { value: 'other', label: 'Other' },
]

export const INVOICE_LINE_ITEMS = {
  DEPOSIT: 'deposit',
  SERVICE_FEE: 'service_fee',
  COURT_FEE: 'court_fee',
  GRANT_COPIES: 'additional_grant_copies',
  LPA: 'lpa',
}

export const VAT_RATE = 0.2

export const DELIVER = 'deliver'
export const FAST_TRACK_DELIVER = 'fast_track_deliver'
export const SCATTER = 'scatter'
export const COLLECT = 'collect'
export const PEAK_TIME_SLOT = 'regular'
export const OFF_PEAK_TIME_SLOT = 'early-morning'

export const TREE_NONE = 'tree_none'

export const BURIAL = 'burial'
export const CREMATION = 'cremation'

export const YES_NO_TO_BOOLEAN_OPTIONS = [
  { value: 'true', label: 'Yes' },
  { value: 'false', label: 'No' },
] as const

export const NO_YES_TO_BOOLEAN_OPTIONS = [
  { value: 'false', label: 'Yes' },
  { value: 'true', label: 'No' },
] as const

export const FUNERAL_ASHES_INSTRUCTION_NOTES = {
  COLLECT: 'The applicant will collect the ashes from the crematorium',
  DELIVER: 'A representative of Farewill Ltd will collect the ashes',
  SCATTER: `I wish for the ashes to be scattered in the crematorium grounds. I understand that this will happen 28 days after the cremation and accept that I won't be there to witness the scattering.

The crematorium will keep a record of where the ashes are scattered, and they'll write to me to confirm that they've done it. But I accept that they won't be able to tell me the exact location.`,
}

export const COFFINS = [
  { value: 'oak_veneer', label: 'Oak veneer' },
  { value: 'willow_wicker', label: 'New Willow wicker' },
  { value: 'coffin_life_art_mahogany', label: 'Life Art Mahogany' },
  { value: 'coffin_life_art_natural', label: 'Life Art Natural' },
  { value: 'undecided', label: 'Coffin to be decided' },
  { value: 'willow_wicker_legacy', label: 'Willow wicker (Legacy)' },
] as const

export const COFFINS_WITH_PRICE = [
  { value: 'oak_veneer', label: 'Oak veneer (price included)' },
  { value: 'willow_wicker_new', label: 'New Willow wicker (+£250)' },
  { value: 'coffin_life_art_mahogany', label: 'Life Art Mahogany (+£250)' },
  { value: 'coffin_life_art_natural', label: 'Life Art Natural (+£250)' },
  { value: 'undecided', label: 'Coffin to be decided (+£250)' },
  { value: 'willow_wicker', label: 'Willow wicker (+£450 - Legacy)' },
] as const

export const DECEASED_LOCATIONS = [
  { value: 'private_residence', label: 'Private residence' },
  { value: 'hospital', label: 'Hospital' },
  { value: 'coroner', label: 'Coroner' },
  { value: 'care_home', label: 'Care home' },
  { value: 'hospice', label: 'Hospice' },
  { value: 'funeral_home', label: 'Funeral home' },
  { value: 'not_sure', label: 'Not sure' },
] as const

export const APPLICATION_TYPE_OPTIONS = [
  { value: 'paper', label: 'Paper application' },
  { value: 'online', label: 'Online application' },
  { value: '', label: 'Not specified' },
]

export const CONVERSION_SCORE_OPTIONS = [
  { value: 1, label: '1' },
  { value: 2, label: '2' },
  { value: 3, label: '3' },
]

export const EMAIL_STATUSES = {
  PENDING: 'pending',
  SCHEDULED: 'scheduled',
  CANCELLED: 'cancelled',
  PROCESSED: 'processed',
}

export const WILL_CASE_STATUSES = {
  OPEN: 'open',
  COLD: 'cold',
  CANCELLED: 'cancelled',
}

export const WILL_GRADE_OPTIONS = [
  { label: 'Not specified', value: '' },
  { label: 'Hot will', value: 'hot' },
  { label: 'Grade A', value: 'a' },
  { label: 'Grade B', value: 'b' },
  { label: 'Grade C', value: 'c' },
]

export const WILL_FEATURE_OPTIONS = [
  { label: 'Foreign assets', value: 'foreign_assets' },
  { label: 'Property trust', value: 'property_trust' },
  { label: 'Nil-rate band trust', value: 'nil_rate_band_trust' },
  {
    label: 'Disabled beneficiary/section 89 trust',
    value: 'disabled_beneficiary',
  },
  { label: 'Business property trust', value: 'business_property_trust' },
  {
    label: 'Different executors/trustees',
    value: 'different_executors_and_trustees',
  },
  { label: 'Gift of nil-rate band', value: 'gift_of_nil_rate_band' },
  {
    label: 'Life interest trust (residuary estate)',
    value: 'life_interest_trust_residuary',
  },
  { label: 'Discretionary trust', value: 'discretionary_trust' },
  {
    label: 'Notice of Severance (unilateral)',
    value: 'notice_of_severance_unilateral',
  },
  { label: 'Disinheritance', value: 'disinheritance' },
  { label: 'Unequal benefit', value: 'unequal_benefit' },
  { label: 'Gifts of items', value: 'gifts_of_items' },
  { label: 'Gifts of shares', value: 'gifts_of_shares' },
  { label: 'Gifts of money', value: 'gifts_of_money' },
  { label: '18 to 25 trust', value: '18_to_25_trust' },
  {
    label: 'Life interest trust (property)',
    value: 'life_interest_trust_property',
  },
  { label: 'Gift of property', value: 'gift_of_property' },
  { label: 'Digital assets', value: 'digital_assets' },
  {
    label: 'Notice of severance (mutual)',
    value: 'notice_of_severance_mutual',
  },
  { label: 'Letter of wishes (Trust)', value: 'letter_of_wishes_trust' },
  { label: 'Backup executor(s)', value: 'backup_executors' },
  { label: 'Backup guardian(s)', value: 'backup_guardians' },
  { label: 'Backup beneficiaries ', value: 'backup_beneficiaries ' },
  { label: 'Catastrophe clause', value: 'catastrophe_clause' },
  {
    label: 'Letter of wishes (messages)',
    value: 'letter_of_wishes_messages',
  },
]

export const FUNERAL_PLAN_FEATURE_OPTIONS = [
  { label: 'Has phone number', value: 'hasPhoneNumber' },
  { label: 'Includes messages', value: 'includesMessages' },
  { label: 'Includes wishes', value: 'includesWishes' },
]

export enum TRIBUTE_PAGE_STATUSES {
  NOT_ASKED = 'not_asked',
  NOT_INTERESTED = 'not_interested',
  IN_PROGRESS = 'in_progress',
  LIVE = 'live',
  DELETED = 'deleted',
}

export const DIGNITY_FEATURE_FLAG_CONFIG = {
  name: 'dignity',
  acceptedValues: ['true', 'false'],
}

export const SCOTLAND_FEATURE_FLAG_CONFIG = {
  name: 'scotland',
  acceptedValues: ['true', 'false'],
}

export const RESIDENCE_OPTIONS = [
  { value: 'england', label: 'England' },
  { value: 'wales', label: 'Wales' },
  { value: 'scotland', label: 'Scotland' },
  { value: 'northern_ireland', label: 'Northern Ireland' },
  { value: 'outside_uk', label: 'Outside UK' },
]

export const WILL_TYPE_OPTIONS = [
  { value: 'couple', label: 'Yes' },
  { value: 'single', label: 'No' },
]

export const CUSTOMER_CALL_TYPE = {
  FACT_FIND: 'fact_find',
  FOLLOW_UP: 'follow_up',
  SALES: 'sales',
} as const

export const FUNERAL_PLAN_LEAD_HEARD_ABOUT_FAREWILL_VIA_OPTIONS = [
  { value: 'farewill_will', label: 'Farewill: Will' },
  { value: 'farewill_probate', label: 'Farewill: Probate' },
  { value: 'farewill_funeral', label: 'Farewill: Funeral' },
  { value: 'google', label: 'Google' },
  { value: 'daily_mail_advert', label: 'Daily Mail advert' },
  { value: 'tv_advert', label: 'TV advert' },
  { value: 'word_of_mouth', label: 'Word of mouth' },
  { value: 'somewhere_else', label: 'Somewhere else' },
]

/**
 * These values should be only used in the copy that is descriptive,
 * and never be used as part of quoteing or pricing logic.
 * These should be coming from server side configuration or API requests only.
 */
export const MEMBERSHIP_VOUCHER_VALUES = {
  [FAREWILL_PRODUCTS.PROBATE]: 20000,
  [FAREWILL_PRODUCTS.FUNERAL]: 10000,
  [FAREWILL_PRODUCTS.WILL]: 2000,
  [FAREWILL_PRODUCTS.FUNERAL_PLAN]: 15000,
}
