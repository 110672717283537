import { useState } from 'react'
import PropTypes from 'prop-types'
import { H, P, TickIcon, Wrapper } from '@farewill/ui'
import { COLOR, FONT, GTR } from '@farewill/ui/tokens'
import styled from 'styled-components'

import Task from './task'
import NewTask from './new-task'

const StyledStageWrapper = styled(Wrapper)`
  position: relative;
  display: flex;
  flex-direction: column;
  padding-top: ${({ theme }) => (theme.isExpanded ? GTR.S : GTR.M)};
  ${({ theme }) => theme.isExpanded && `padding-left: ${GTR.S}`};
  ${({ theme }) => !theme.isExpanded && `cursor: pointer`};

  &:before {
    content: '';
    width: 1px;
    background-color: ${COLOR.GREY.MEDIUM};
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
  }

  &:after {
    content: '';
    width: 5px;
    height: 2px;
    background-color: ${COLOR.GREY.MEDIUM};
    position: absolute;
    top: ${({ theme }) => `calc(${theme.isExpanded ? GTR.S : GTR.M} + 10px)`};
    left: -2px;
  }

  &:first-of-type {
    padding-top: 0;

    &:before,
    &:after {
      top: 10px;
    }
  }

  &:last-of-type {
    &:before {
      bottom: ${({ theme }) => (theme.isExpanded ? '28px' : '10px')};
    }
  }
`

const StyledStageHeading = styled(H)`
  font-size: ${FONT.SIZE.S};
  color: ${COLOR.GREY.DARK};
  font-weight: ${FONT.WEIGHT.BOLD};
  margin-bottom: ${GTR.S};
  margin-left: ${GTR.S};
  position: relative;
  z-index: 1;

  ${({ theme }) =>
    theme.isExpanded &&
    `
    &:after {
      content: '';
      position: absolute;
      top: -${GTR.S};
      left: -${GTR.S};
      bottom: -${GTR.S};
      right: 0;
      z-index: -1;
      transition: background-color 0.3s;
    }
  `}

  ${({ $isHighlighted }) =>
    $isHighlighted &&
    `
      &:after {
        background-color: ${COLOR.STATE.WARNING_10};
      }
    `}
`

const StyledList = styled.ul`
  border-style: solid;
  border-color: ${COLOR.GREY.LIGHT};
  border-width: 1px 0;
  margin-bottom: 0;
`

const StyledP = styled(P)`
  padding: ${GTR.S};
  padding-left: 60px;
  font-size: ${FONT.SIZE.S};
  border-style: solid;
  border-color: ${COLOR.GREY.LIGHT};
  border-width: 0 0 1px;
`

const StyledTickIcon = styled(TickIcon)`
  position: absolute;
  left: calc(-${({ theme }) => (theme.isExpanded ? GTR.L : GTR.S)} + -14px);
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
`

const TasksStage = ({
  tasks,
  title,
  enableCommenting,
  stage,
  isStageExpanded,
  isPanelExpanded,
  setPanelExpanded,
  productId,
}) => {
  const stageIsComplete = tasks.every((task) => task.attributes.completedAt)
  const [isHighlighted, setIsHighlighed] = useState(false)

  return (
    <StyledStageWrapper
      data-stage-name={title}
      onClick={(e) => {
        if (isPanelExpanded || e.target.tagName === 'INPUT') return

        setTimeout(() => setPanelExpanded(true))
        const stage = document.querySelector(`[data-stage-name="${title}"]`)
        setTimeout(() => stage.scrollIntoView({ behavior: 'smooth' }), 300)
      }}
    >
      <StyledStageHeading
        size="XL"
        $isComplete={stageIsComplete}
        $isHighlighted={isHighlighted}
        onClick={() => {
          if (isPanelExpanded) return
          setIsHighlighed(true)
          setTimeout(() => setIsHighlighed(false), 3000)
        }}
      >
        {stageIsComplete && (
          <StyledTickIcon
            size="S"
            color={COLOR.STATE.SUCCESS}
            background={COLOR.BACKGROUND.SMOKE}
          />
        )}
        {title}
      </StyledStageHeading>
      {isStageExpanded && (
        <>
          <StyledList>
            {tasks.map((task) => (
              <Task
                enableCommenting={enableCommenting}
                task={task}
                isPanelExpanded={isPanelExpanded}
                key={task.id}
                productId={productId}
              />
            ))}
          </StyledList>
          {isPanelExpanded ? (
            <NewTask stage={stage} productId={productId} />
          ) : (
            <StyledP>Expand to add new task...</StyledP>
          )}
        </>
      )}
    </StyledStageWrapper>
  )
}

TasksStage.propTypes = {
  tasks: PropTypes.array,
  title: PropTypes.string.isRequired,
  isStageExpanded: PropTypes.bool.isRequired,
  setPanelExpanded: PropTypes.func.isRequired,
  productId: PropTypes.object.isRequired,
  enableCommenting: PropTypes.bool.isRequired,
}

TasksStage.defaultProps = {
  tasks: [],
}

export default TasksStage
