import styled from 'styled-components'
import { ArrowDownIcon, P } from '@farewill/ui'
import SectionStatus from 'components/section-status'
import { QuoteResponseDataProbate } from 'lib/types'
import { scrollToDataId } from 'lib/window/scroll'
import { formatCurrency, hasDecimals } from 'lib/formatting/currency'

const StyledLink = styled.a`
  text-decoration: underline;
  cursor: pointer;
`

const QuoteBox = ({ quote }: { quote: QuoteResponseDataProbate | null }) => {
  const probateOnlyQuote = quote?.attributes.grantOnly.amountInPence || 0
  const completeProbateQuote = quote?.attributes.estateAdmin.amountInPence || 0

  return (
    <SectionStatus heading="Quote based on fact find" complete>
      <P size="S">
        Grant only:{' '}
        <strong>
          {formatCurrency({
            value: probateOnlyQuote,
            showPence: hasDecimals(probateOnlyQuote / 100),
            valueInPence: true,
          })}
        </strong>
      </P>
      <P size="S">
        Complete probate:{' '}
        <strong>
          {formatCurrency({
            value: completeProbateQuote,
            valueInPence: true,
            showPence: hasDecimals(completeProbateQuote / 100),
          })}
        </strong>
      </P>
      <P size="S">
        <ArrowDownIcon inline size="S" />{' '}
        <StyledLink onClick={() => scrollToDataId('quotes')}>
          Jump to quote
        </StyledLink>
      </P>
    </SectionStatus>
  )
}

export default QuoteBox
