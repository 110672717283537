import { getUserIdFromToken } from 'lib/authentication/token'
import { useSelector } from 'react-redux'

const useAdminUserId = (): number => {
  const adminUserId = useSelector((state: { token: string }) =>
    getUserIdFromToken(state.token)
  )
  return adminUserId
}

export default useAdminUserId
