import { FormStatusMessage, Wrapper } from '@farewill/ui'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { addGlobalError } from 'state/actions'

const GlobalErrorMessage = () => {
  const location = useLocation()
  const dispatch = useDispatch()

  const error = useSelector(
    (state: { errorsGlobal: string }) => state.errorsGlobal
  )

  // Clear error message when changing route
  useEffect(() => {
    dispatch(addGlobalError(null))
  }, [location.pathname, dispatch])

  return (
    <>
      {error && (
        <Wrapper container centered maxWidth={700}>
          <FormStatusMessage status="error">{error}</FormStatusMessage>
        </Wrapper>
      )}
    </>
  )
}

export default GlobalErrorMessage
