import {
  FarewillProduct,
  FAREWILL_PRODUCTS,
  FUNERAL_CASE_NEXT_STAGE,
  LPA_CASE_NEXT_STAGE,
  PROBATE_CASE_NEXT_STAGE,
  WILL_CASE_NEXT_STAGE,
} from 'utils/enums'
import { formatToHuman } from 'utils/helpers'
import { Case } from '../types'

export const getCaseLinkForProduct = (product: FarewillProduct, id: number) => {
  switch (product) {
    case FAREWILL_PRODUCTS.PROBATE:
      return `/probate/cases/${id}`
    case FAREWILL_PRODUCTS.LPA:
      return `/lpa-cases/${id}`
    case FAREWILL_PRODUCTS.FUNERAL:
      return `/funerals/${id}`
    case FAREWILL_PRODUCTS.WILL:
      return `/will-cases/${id}`
    default:
      throw new Error(`invalid product "${product}`)
  }
}

export const getDaysInStageText = (product: FarewillProduct, row: Case) => {
  const { daysInStage, nextStage, status } = row.attributes as unknown as {
    daysInStage: number
    nextStage: string
    status: string
  }
  const labels = getNextStageLabels(product) as Record<string, string>

  if (getIsLostOrDeclined(status)) return formatToHuman(status)

  let daysInStageString = `${daysInStage}`
  if (nextStage) {
    daysInStageString += ` in ${labels[nextStage as keyof typeof labels]}`
  }

  return daysInStageString
}

export const getIsLostOrDeclined = (status: string) =>
  ['case_lost', 'case_declined'].includes(status)

const getNextStageLabels = (product: string) => {
  switch (product) {
    case FAREWILL_PRODUCTS.PROBATE:
      return PROBATE_CASE_NEXT_STAGE
    case FAREWILL_PRODUCTS.FUNERAL:
      return FUNERAL_CASE_NEXT_STAGE
    case FAREWILL_PRODUCTS.LPA:
      return LPA_CASE_NEXT_STAGE
    case FAREWILL_PRODUCTS.WILL:
      return WILL_CASE_NEXT_STAGE
    default:
      return null
  }
}
