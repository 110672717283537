import PropTypes from 'prop-types'
import { Grid, P } from '@farewill/ui'
import { useDispatch } from 'react-redux'
import { useFormikContext } from 'formik'
import get from 'lodash/get'
import lowerCase from 'lodash/lowerCase'

import { showModal } from 'state/actions'
import namespace from 'lib/formik/namespace'
import { getMomentDate } from 'lib/formik/dates'

import DateSplit from 'components/form/date-split'
import InputFloating from 'components/form/input-floating'
import NotesInput from 'components/notes-input'
import EstateCard from 'components/estate-card'
import CircularRadioGroup from 'components/form/circular-radio-group'
import MessageBox from 'components/message-box'

import { NOT_ASKED_OPTIONS } from 'utils/enums'

import { getRelativeVisibleFields } from '../form-helpers'
import DeleteModal from '../../components/delete-modal'
import withForm from '../../components/with-form'
import { getIsOverEighteen } from '../../../helpers'

import { useRelativesContext } from './context'
import {
  BIRTH,
  FULL_NAME,
  NOTES,
  PARENTAL_RESPONSIBILITY_OF,
  IS_ALIVE,
  IS_OVER_EIGHTEEN,
  DIED_BEFORE_DECEASED,
  HAS_LIVING_CHILDREN,
} from './field-names'

const OverEighteenWarning = () => (
  <Grid.Item span={7} style={{ display: 'flex', alignItems: 'flex-end' }}>
    <MessageBox warning>
      <P size="S">This date of birth would mean they are over 18</P>
    </MessageBox>
  </Grid.Item>
)

const Relative = ({
  relative,
  listLength,
  listPosition,
  relationshipToDeceased,
}) => {
  const dispatch = useDispatch()
  const relativeId = relative.id
  const prefix = `relative.${relativeId}`
  const { withNamespace, withoutNamespace } = namespace(prefix + '.')
  const { values } = useFormikContext()
  const relationshipToDeceasedLowerCase = lowerCase(relationshipToDeceased)

  const {
    showIsOverEighteen,
    showUnderEighteenQuestions,
    showDiedBeforeDeceased,
    showHasLivingChildren,
    showChildrenGuidance,
  } = getRelativeVisibleFields({
    values: get(values, prefix),
  })

  const { updateRelative, deleteRelative } = useRelativesContext()

  const handleSave = ({ name, value }) => {
    const attributes = { [withoutNamespace(name)]: value || null }
    return updateRelative(relativeId, attributes)
  }

  const handleDeleteClick = () => {
    dispatch(
      showModal({
        component: DeleteModal,
        headingText: `Delete ${relationshipToDeceasedLowerCase}`,
        config: {
          deleteCallback: () => deleteRelative(relativeId),
          type: relationshipToDeceasedLowerCase,
          resourceId: relativeId,
        },
      })
    )
  }

  const isOverEighteen = getIsOverEighteen(
    getMomentDate({ prefix: BIRTH, values: get(values, prefix) })
  )

  return (
    <EstateCard
      onTrashClick={() => handleDeleteClick(relativeId)}
      listLength={listLength}
      listPosition={listPosition}
      type={relationshipToDeceasedLowerCase}
    >
      <Grid style={{ gridAutoFlow: 'row' }}>
        <Grid.Item span={6}>
          <InputFloating
            name={withNamespace(FULL_NAME)}
            label="Full name"
            handleSave={handleSave}
          />
        </Grid.Item>
        <Grid.Item>
          <CircularRadioGroup
            name={withNamespace(IS_ALIVE)}
            label="Is this person alive?"
            options={NOT_ASKED_OPTIONS}
            handleSave={handleSave}
            inline
          />
        </Grid.Item>
        {showIsOverEighteen && (
          <Grid.Item>
            <CircularRadioGroup
              name={withNamespace(IS_OVER_EIGHTEEN)}
              label="How old are they?"
              options={[
                { label: 'Not asked', value: '' },
                { label: 'Over 18', value: 'true' },
                { label: 'Under 18', value: 'false' },
              ]}
              handleSave={handleSave}
              inline
            />
          </Grid.Item>
        )}
        {showUnderEighteenQuestions && (
          <>
            <Grid.Item span={5}>
              <DateSplit
                name={withNamespace(BIRTH)}
                label="Date of birth"
                handleSave={handleSave}
              />
            </Grid.Item>
            {isOverEighteen && <OverEighteenWarning />}
            <Grid.Item span={6} startColumn={1}>
              <InputFloating
                name={withNamespace(PARENTAL_RESPONSIBILITY_OF)}
                label="Person with parental responsibility"
                handleSave={handleSave}
              />
            </Grid.Item>
          </>
        )}
        {showDiedBeforeDeceased && (
          <Grid.Item>
            <CircularRadioGroup
              name={withNamespace(DIED_BEFORE_DECEASED)}
              label="Did they die before or after the person who’s died?"
              options={[
                { label: 'Not asked', value: '' },
                { label: 'Before', value: 'true' },
                { label: 'After', value: 'false' },
              ]}
              handleSave={handleSave}
              inline
            />
          </Grid.Item>
        )}
        {showHasLivingChildren && (
          <Grid.Item>
            <CircularRadioGroup
              name={withNamespace(HAS_LIVING_CHILDREN)}
              label="Did they have children who are still alive?"
              options={NOT_ASKED_OPTIONS}
              handleSave={handleSave}
              inline
            />
          </Grid.Item>
        )}
        {showChildrenGuidance && (
          <Grid.Item span={10}>
            <P>
              Please add full names of all this person’s living children in the
              notes below. For any of these children under 18, also add their
              date of birth and the person with parental responsibility.
            </P>
          </Grid.Item>
        )}
        <Grid.Item>
          <NotesInput name={withNamespace(NOTES)} handleSave={handleSave} />
        </Grid.Item>
      </Grid>
    </EstateCard>
  )
}

Relative.propTypes = {
  relative: PropTypes.object.isRequired,
  listLength: PropTypes.number.isRequired,
  listPosition: PropTypes.number.isRequired,
  relationshipToDeceased: PropTypes.string.isRequired,
}

export default withForm(Relative)
