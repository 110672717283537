import { useState } from 'react'
import { Route, useHistory } from 'react-router-dom'
import axiosRequest from 'lib/axios/api-request'

import { Form, Formik, FormikHelpers } from 'formik'
import { PARTNER_SCHEMA } from 'lib/formik/schemata'

import UpdateOrCreateForm from 'routes/partners/components/form/partner-form'
import Result from 'routes/partners/components/result'
import Review from 'routes/partners/components/form/review'
import {
  CreateApiResponse,
  Operation,
  Values,
} from 'routes/partners/interfaces'
import { CREATE_INITIAL_VALUES } from 'routes/partners/constants'
import { constructPartnerFormData } from '../helpers'

const NewPartner = (): React.ReactElement => {
  const [operation] = useState<Operation>('create')
  const [createOperationResponse, setCreateOperationResponse] =
    useState<CreateApiResponse | null>(null)
  const [formValues] = useState<Values>(CREATE_INITIAL_VALUES)

  const [showErrorMessage, setShowErrorMessage] = useState(false)

  const history = useHistory()

  const handleSubmit = async (
    values: Values,
    helpers: FormikHelpers<Values>
  ) => {
    const formData = constructPartnerFormData(values)

    const apiResponse = await axiosRequest({
      url: '/api/partners',
      method: 'POST',
      data: formData,
    })

    setCreateOperationResponse(apiResponse.data.data.attributes)

    history.push('/partners/new/result')

    helpers.resetForm()
  }

  return (
    <Formik
      initialValues={formValues as Values}
      enableReinitialize
      onSubmit={handleSubmit}
      validationSchema={PARTNER_SCHEMA}
    >
      <Form>
        <Route
          path="/partners/new"
          exact
          render={() => (
            <UpdateOrCreateForm
              operation="create"
              showErrorMessage={showErrorMessage}
              setShowErrorMessage={setShowErrorMessage}
            />
          )}
        />

        <Route
          path="/partners/new/review"
          render={() => <Review operation={operation} />}
        />

        {createOperationResponse && (
          <Route
            path="/partners/new/result"
            render={(props) => (
              <Result
                {...props}
                status={createOperationResponse.status}
                attributes={createOperationResponse.attributes}
                contentfulId={createOperationResponse.contentfulId}
              />
            )}
          />
        )}
      </Form>
    </Formik>
  )
}
export default NewPartner
