import { useCallback, useRef, useState } from 'react'
import { AxiosError } from 'axios'
import axiosRequest from 'lib/axios/api-request'
import uniqueId from 'lodash/uniqueId'

import { ResponseData, ResponseMeta, ResponseErrors } from 'lib/types'

export const isAxiosError = (error: unknown): error is AxiosError =>
  (error as AxiosError).response?.data?.errors !== undefined

type InitialState<T> = {
  data?: T
  isLoading?: boolean
}

export type StateType<T = ResponseData> = {
  data: T | null
  meta: ResponseMeta | null
  errors: ResponseErrors
  isLoading: boolean
}

export type MakeRequestFn = <Type>(config: {
  url: string
  method?: string
  data?: { [key: string]: unknown }
  headers?: { [key: string]: string }
  sendToken?: boolean
}) => Promise<Type>

const useApi = <T = ResponseData>(
  initialState: InitialState<T> = {}
): [StateType<T>, MakeRequestFn] => {
  const [data, setData] = useState<T | null>(initialState.data ?? null)
  const [meta, setMeta] = useState(null)
  const [errors, setErrors] = useState([])
  const [isLoading, setIsLoading] = useState(initialState.isLoading || false)
  const lastRequestId = useRef<string>()

  const makeRequest = useCallback(async (config) => {
    const requestId = uniqueId('use-api-')
    lastRequestId.current = requestId

    setIsLoading(true)
    setErrors([])

    try {
      const response = await axiosRequest(config)

      if (lastRequestId.current !== requestId) {
        return response.data
      }

      setData(response.data.data)
      setMeta(response.data.meta)
      setIsLoading(false)
      return response.data
    } catch (error) {
      const errors = isAxiosError(error) ? error.response?.data?.errors : []
      setErrors(errors)
      setIsLoading(false)
      throw error
    }
  }, [])

  return [{ data, errors, isLoading, meta }, makeRequest]
}

export default useApi
