import { Formik, Form } from 'formik'
import { Button, ButtonGroup, Divider, Grid } from '@farewill/ui'
import { useDispatch } from 'react-redux'
import Dinero from 'dinero.js'

import { useFetchResource, RESOURCES } from 'api'

import { CUSTOMER_CALL_SCHEMA } from 'lib/formik/schemata'
import useApi from 'lib/effects/api'
import { getCallIdFromAircallUrl } from 'utils/helpers'
import { hideModal } from 'state/actions'
import Input from 'components/form/input'
import DatetimePicker from 'components/form/datetime-picker'
import Checkbox from 'components/form/checkbox'
import { FAREWILL_PRODUCTS } from 'utils/enums'

const AddCall = ({ config: { willCaseId, status, cancellationFee } }) => {
  const dispatch = useDispatch()
  const [{ isLoading }, makeRequest] = useApi()
  const willCaseQuery = useFetchResource({
    id: Number(willCaseId),
    resource: RESOURCES.WILL_CASES,
  })
  const handleSubmit = async ({
    externalAircallCallUrl,
    date,
    type,
    chargeCancellationFee,
  }) => {
    try {
      const externalAircallCallId = getCallIdFromAircallUrl(
        externalAircallCallUrl
      )

      await makeRequest({
        method: 'POST',
        url: '/api/customer-calls',
        data: {
          data: {
            type: 'customer_calls',
            attributes: {
              willCaseId,
              externalAircallCallId,
              startTime: date || null,
              type: type,
              status,
              product: FAREWILL_PRODUCTS.WILL,
              chargeCancellationFee,
            },
          },
        },
      })

      willCaseQuery.refetch()
      dispatch(hideModal())
    } catch (error) {
      dispatch(hideModal())
    }
  }

  return (
    <Formik
      initialValues={{
        externalAircallCallUrl: '',
        chargeCancellationFee: !!cancellationFee,
      }}
      onSubmit={handleSubmit}
      validationSchema={CUSTOMER_CALL_SCHEMA}
    >
      <Form>
        <Grid>
          <Grid.Item>
            <Input
              label="Call type"
              name="type"
              component="select"
              options={[
                { label: 'Fact find call', value: 'fact_find' },
                { label: 'Follow up call', value: 'follow_up' },
              ]}
              includeEmptyOption={false}
            />
          </Grid.Item>
          <Grid.Item>
            <Divider />
          </Grid.Item>
          <Grid.Item>
            {status === 'connected' ? (
              <Input
                label="Link to Aircall call"
                name="externalAircallCallUrl"
              />
            ) : (
              <DatetimePicker label="Date / time of call" name="date" />
            )}
          </Grid.Item>
          {status === 'missed' && cancellationFee && (
            <Grid.Item>
              <Checkbox
                name="chargeCancellationFee"
                label={`Charge this customer a ${Dinero({
                  amount: cancellationFee,
                  currency: 'GBP',
                }).toFormat('$0,0')} cancellation fee`}
              />
            </Grid.Item>
          )}
          <Grid.Item>
            <ButtonGroup gap="S">
              <Button.Primary type="submit" loading={isLoading}>
                Save
              </Button.Primary>
              <Button type="button" onClick={() => dispatch(hideModal())}>
                Cancel
              </Button>
            </ButtonGroup>
          </Grid.Item>
        </Grid>
      </Form>
    </Formik>
  )
}

export default AddCall
