import { useFormikContext } from 'formik'
import get from 'lodash/get'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Button, Grid, H, P, Wrapper } from '@farewill/ui'
import { COLOR } from '@farewill/ui/tokens'

import RadioGroup from 'components/form/radio-group'
import MessageBox from 'components/message-box'
import SectionStatus from 'components/section-status'
import ENV from 'config/environment'
import { formatCurrency, hasDecimals } from 'lib/formatting/currency'
import { FuneralPlanLeadAttributes } from 'lib/models/lead'
import { QuoteResponseDataFuneralPlanAttributes } from 'lib/types'
import { createPaymentProviderCustomer, showModal } from 'state/actions'

import TakePayment from '../take-payment'
import PaymentModal from './payment-modal'

const ErrorMessage = () => (
  <MessageBox error margin={['M', 0, 0]}>
    Something went wrong with processing user data, please contact engineering
    team.
  </MessageBox>
)

const PaymentQuestions = ({
  quote,
  reference,
}: {
  quote?: QuoteResponseDataFuneralPlanAttributes
  reference: string
}): React.ReactElement => {
  const { values, handleSubmit } = useFormikContext<FuneralPlanLeadAttributes>()
  const [hasError, setHasError] = useState<boolean>()
  const dispatch = useDispatch()

  const handlePaymentClick = async (): Promise<void> => {
    try {
      const response = await dispatch(
        createPaymentProviderCustomer({
          contactId: values.contactId,
          paymentProvider: 'square',
        })
      )

      const externalCustomerId = get(response, 'value.data.data.id')
      window.open(
        `${ENV.SQUARE_DASHBOARD_URL}/invoices/series/new?contactToken=${externalCustomerId}`
      )
    } catch (err) {
      setHasError(true)
    }
  }

  const handleTakeAPayment = ({
    reference,
    amountInPence,
  }: {
    reference: string
    amountInPence: number
  }): void => {
    dispatch(
      showModal({
        component: PaymentModal,
        headingText: 'Take payment on Square',
        config: {
          reference,
          amountInPence,
          handlePaymentClick,
        },
      })
    )
  }

  /**
   * For payments by instalment we currently do not support a single transaction
   * and two leads will be created. Discounts are applied differently depending
   * on which lead it is. The quote response will contain a top-level total
   * across both plans, and a `costBreakdown` array. To display the individual
   * quote for the relevant lead, we need to read the correct entry out of the
   * `costBreakdown` array.
   */
  const instalmentsQuote = quote?.costBreakdown[0]

  return (
    <>
      <H size="S">Payment</H>
      <Grid>
        <Grid.Item>
          <RadioGroup
            name="quoteAttributes.paymentType"
            options={[
              { label: 'In monthly instalments', value: 'instalments' },
              { label: 'Upfront', value: 'in_full' },
            ]}
            label="How would they like to pay?"
            handleSave={() => handleSubmit()}
          />
        </Grid.Item>
        {quote?.paymentType === 'in_full' && (
          <>
            <Grid.Item>
              <SectionStatus heading="Price in full" complete>
                <P color={COLOR.BLACK} size="L">
                  Price to pay:{' '}
                  {formatCurrency({
                    showPence: hasDecimals(
                      quote.totalPayableAmountInPence / 100
                    ),
                    value: quote.totalPayableAmountInPence,
                    valueInPence: true,
                  })}
                </P>
                <P color={COLOR.BLACK}>
                  Funeral Plan price:{' '}
                  {formatCurrency({
                    showPence: hasDecimals(quote.packagePriceInPence / 100),
                    value: quote.packagePriceInPence,
                    valueInPence: true,
                  })}
                </P>
                <P color={COLOR.BLACK}>
                  Discount: -
                  {formatCurrency({
                    showPence: hasDecimals(
                      quote.totalDiscountAmountInPence / 100
                    ),
                    value: quote.totalDiscountAmountInPence,
                    valueInPence: true,
                  })}
                </P>
              </SectionStatus>
            </Grid.Item>
            {quote && (
              <Grid.Item>
                <Wrapper margin={['M', 0, 0]}>
                  <TakePayment
                    calculatedPrice={quote.totalPayableAmountInPence}
                    editablePaymentReference
                  />
                </Wrapper>
              </Grid.Item>
            )}
          </>
        )}
        {quote?.paymentType === 'instalments' &&
          instalmentsQuote &&
          'monthlyPayableAmountInPence' in instalmentsQuote && (
            <>
              <Grid.Item>
                <SectionStatus heading="Price for monthly instalments" complete>
                  <P color={COLOR.BLACK} size="L">
                    {formatCurrency({
                      showPence: hasDecimals(
                        instalmentsQuote.monthlyPayableAmountInPence / 100
                      ),
                      value: instalmentsQuote.monthlyPayableAmountInPence,
                      valueInPence: true,
                    })}{' '}
                    per month for {instalmentsQuote.numberOfInstalments} months
                  </P>
                  <P color={COLOR.BLACK}>
                    Funeral Plan price:{' '}
                    {formatCurrency({
                      showPence: hasDecimals(
                        instalmentsQuote.packagePriceInPence / 100
                      ),
                      value: instalmentsQuote.packagePriceInPence,
                      valueInPence: true,
                    })}
                  </P>
                  <P color={COLOR.BLACK}>
                    Instalment fee:{' '}
                    {formatCurrency({
                      showPence: hasDecimals(
                        instalmentsQuote.instalmentsFeeInPence / 100
                      ),
                      value: instalmentsQuote.instalmentsFeeInPence,
                      valueInPence: true,
                    })}
                  </P>
                  <P color={COLOR.BLACK}>
                    Discount:{' '}
                    {instalmentsQuote.totalDiscountAmountInPence > 0 ? '-' : ''}
                    {formatCurrency({
                      showPence: hasDecimals(
                        instalmentsQuote.totalDiscountAmountInPence / 100
                      ),
                      value: instalmentsQuote.totalDiscountAmountInPence,
                      valueInPence: true,
                    })}
                  </P>
                </SectionStatus>
              </Grid.Item>
              {instalmentsQuote && (
                <Grid.Item>
                  <Wrapper margin={['M', 0, 0]}>
                    <Button.Primary
                      onClick={() =>
                        handleTakeAPayment({
                          reference,
                          amountInPence:
                            instalmentsQuote.monthlyPayableAmountInPence,
                        })
                      }
                    >
                      Take a payment
                    </Button.Primary>
                  </Wrapper>
                  {hasError && <ErrorMessage />}
                </Grid.Item>
              )}
            </>
          )}
      </Grid>
    </>
  )
}

export default PaymentQuestions
