import { Button, Grid } from '@farewill/ui'
import { GTR } from '@farewill/ui/tokens'

import { useFormikContext } from 'formik'

import NotesInput from 'components/notes-input'
import EstateCard from 'components/estate-card'
import { Input, Checkbox } from 'components/form'
import Tooltip from 'components/tooltip'
import namespace from 'lib/formik/namespace'
import { ProbateLeadAttributes } from 'lib/models/lead'
import {
  PROBATE_ESTATE_ITEM_TYPES,
  ProbateEstateItem,
} from 'lib/models/probate-estate'
import { QuestionConfig } from 'lib/types/form'
import { YES_NO_OPTIONS } from 'utils/enums'
import { strToBool } from 'utils/helpers'
import store from 'state/create-store'
import { showModal } from 'state/actions'
import { HandleSaveProps, ItemProps } from '../assets/types'
import {
  PROBATE_LEAD_FORM_SECTIONS,
  PROBATE_LEAD_FORM_VALUES,
} from '../constants'
import DeleteModal from '../delete-modal'
import withForm from '../with-form'
import { Question } from './question'
import { useItems } from '../helpers/items-context'
import SectionHeading from '../section-heading'

const Item = ({ item, index, listLength }: ItemProps) => {
  const { withNamespace, withoutNamespace } = namespace(
    `trustBenefitted.${item.id}.`
  )
  const { updateItem, deleteItem } = useItems()

  const handleSave = ({ name, value }: HandleSaveProps) => {
    updateItem(
      {
        id: item.id,
        attributes: { [withoutNamespace(name)]: value || null },
      },
      PROBATE_ESTATE_ITEM_TYPES.TRUST_BENEFITTED
    )
  }

  const onTrashClick = () => {
    store.dispatch(
      showModal({
        component: DeleteModal,
        headingText: 'Delete trust',
        config: {
          deleteCallback: () => {
            deleteItem(item.id)
          },
          type: 'trust',
          resourceId: item.id,
        },
      })
    )
  }

  return (
    <EstateCard
      onTrashClick={onTrashClick}
      listLength={listLength}
      listPosition={index + 1}
      key={item.id}
      type="trust"
    >
      <Grid>
        <Grid.Item span={7}>
          <Input
            name={withNamespace(PROBATE_LEAD_FORM_VALUES.VALUE)}
            label="At the date of death, what was the value (£) of the trust they benefitted from?"
            handleSave={handleSave}
            isCurrency
          />
        </Grid.Item>
        <Grid.Item>
          <Checkbox
            name={withNamespace(PROBATE_LEAD_FORM_VALUES.IS_ESTIMATED_VALUE)}
            label="Estimate"
            handleSave={handleSave}
          />
        </Grid.Item>
        <Grid.Item span={10}>
          <Input
            name={withNamespace(PROBATE_LEAD_FORM_VALUES.DESCRIPTION)}
            label="Description"
            handleSave={handleSave}
          />
        </Grid.Item>
        <Grid.Item>
          <NotesInput
            name={withNamespace(PROBATE_LEAD_FORM_VALUES.NOTES)}
            handleSave={handleSave}
          />
        </Grid.Item>
      </Grid>
    </EstateCard>
  )
}

const ItemWithForm = withForm(Item)

const BENEFITTED_FROM_TRUSTS_QUESTION: QuestionConfig = {
  name: 'quoteAttributes.benefittedFromTrust',
  shortLabel: 'benefit from any trusts',
  label: (
    <>
      Did they have{' '}
      <Tooltip content="For example, if they lived in a house without paying rent under an ‘interest in possession’ trust, if they were paid income from trust assets, or if there was a ‘disabled person’s interest’ trust.">
        the right to benefit from any trusts
      </Tooltip>
      ?
    </>
  ),
  options: YES_NO_OPTIONS,
}

const BenefittedFromTrusts = ({ isComplete }: { isComplete: boolean }) => {
  const { values } = useFormikContext<ProbateLeadAttributes>()
  const { groupedItems, createItem } = useItems()
  const { trustBenefitted: trustBenefittedItems = [] } = groupedItems

  return (
    <Grid name={PROBATE_LEAD_FORM_SECTIONS.BENEFITTED_FROM_TRUSTS}>
      <SectionHeading
        title="8.3 Trusts"
        scrollId="8.3"
        isComplete={isComplete}
      />
      <Grid.Item>
        <Question question={BENEFITTED_FROM_TRUSTS_QUESTION} />
      </Grid.Item>
      <Grid.Item>
        {strToBool(values.quoteAttributes.benefittedFromTrust) && (
          <>
            <Grid margin={['M', 0, 'M', 'M']}>
              {trustBenefittedItems.map(
                (item: ProbateEstateItem, index: number) => (
                  <Grid.Item span={11} key={item.id}>
                    <ItemWithForm
                      enableReinitialize={false}
                      index={index}
                      initialValues={{
                        trustBenefitted: { [item.id]: item.attributes },
                      }}
                      item={item}
                      key={item.id}
                      listLength={trustBenefittedItems.length}
                    />
                  </Grid.Item>
                )
              )}
            </Grid>
            <Grid.Item>
              <Button.Secondary
                style={{ paddingTop: GTR.XXS, paddingBottom: GTR.XXS }}
                type="button"
                onClick={() => {
                  createItem({
                    itemType: PROBATE_ESTATE_ITEM_TYPES.TRUST_BENEFITTED,
                  })
                }}
              >
                Add trust
              </Button.Secondary>
            </Grid.Item>
          </>
        )}
      </Grid.Item>
    </Grid>
  )
}

export default BenefittedFromTrusts
