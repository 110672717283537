import styled from 'styled-components'
import { DropdownIcon, ChevronUpIcon, ChevronDownIcon } from '@farewill/ui'
import { GTR, COLOR } from '@farewill/ui/tokens'

const IconWrapper = styled.span`
  margin-left: ${GTR.XS};
  color: ${COLOR.GREY.DARK};
`

const SortArrow = ({ sorted, asc }) => (
  <IconWrapper>
    {sorted ? (
      asc ? (
        <ChevronUpIcon size="S" inline />
      ) : (
        <ChevronDownIcon size="S" inline />
      )
    ) : (
      <DropdownIcon size="S" inline />
    )}
  </IconWrapper>
)

export default SortArrow
