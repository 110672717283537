import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Grid, H, P } from '@farewill/ui'
import { COLOR, FONT, GTR } from '@farewill/ui/tokens'
import { useFormikContext } from 'formik'

import { NOT_ASKED_AND_UNSURE_OPTIONS } from 'utils/enums'

import Tooltip from 'components/tooltip'
import CircularRadioGroup from 'components/form/circular-radio-group'
import InputFloating from 'components/form/input-floating'
import NotesInput from 'components/notes-input'

import { getTrustsTotal } from '../get-totals'

import {
  HAD_RIGHT_TO_BENEFIT_FROM_TRUST_ASSETS,
  TRUST_ASSETS_AMOUNT,
  GAVE_UP_RIGHT_TO_BENEFIT_FROM_TRUST_ASSETS,
  TRUST_ASSETS_NOTES,
} from './field-names'

const StyledLabel = styled.label`
  margin-bottom: ${GTR.S};
`

export const StyledHint = styled.div`
  margin-top: ${GTR.XXS};
  margin-left: ${GTR.S};
  color: ${COLOR.GREY.MEDIUM};
  font-size: ${FONT.SIZE.XS};
`

const HAD_RIGHT_TOOLTIP =
  'For example, if they lived in a house without paying rent under an ‘interest in possession’ trust, if they were paid income from trust assets, or if there was a ‘disabled person’s interest’ trust.'

const VERIFICATION_TOOLTIP =
  'This valuation needs verification - it’s the trustees that should value it, and there are particular steps they should take'

const GAVE_UP_RIGHT_TOOLTIP =
  'Did they receive an income from a trust in someone’s will or were living in a house that was left in trust in a will for them to live in during their lifetime (‘interest in possession’)? And if so, did they stop receiving the income or stop living in the house in the last 7 years? For example, perhaps they were living in a house left in trust in a will, but agreed with the ultimate benefitiary that they could have the house early. If ‘yes’, leave notes explaining why.'

const Trusts = ({ saveProbateEstateField }) => {
  const { values } = useFormikContext()
  return (
    <Grid data-scroll-id="6.3">
      <Grid.Item>
        <H size="S">6.3 Trusts</H>
        <P>Total: {getTrustsTotal(values)}</P>
      </Grid.Item>
      <Grid.Item>
        <StyledLabel>
          Did they have{' '}
          <Tooltip content={HAD_RIGHT_TOOLTIP}>
            the right to benefit from trust assets
          </Tooltip>
          ?
        </StyledLabel>
        <CircularRadioGroup
          name={HAD_RIGHT_TO_BENEFIT_FROM_TRUST_ASSETS}
          options={NOT_ASKED_AND_UNSURE_OPTIONS}
          handleSave={saveProbateEstateField}
          inline
        />
      </Grid.Item>
      <Grid.Item span={5}>
        <InputFloating
          name={TRUST_ASSETS_AMOUNT}
          label="Value of trust assets (£)"
          handleSave={saveProbateEstateField}
          isCurrency
        />
        <StyledHint>
          This needs to be{' '}
          <Tooltip content={VERIFICATION_TOOLTIP}>verified</Tooltip>
        </StyledHint>
      </Grid.Item>
      <Grid.Item>
        <StyledLabel>
          Did they{' '}
          <Tooltip content={GAVE_UP_RIGHT_TOOLTIP}>
            give up the right to benefit from trust assets
          </Tooltip>{' '}
          in the 7 years before death?
        </StyledLabel>
        <CircularRadioGroup
          name={GAVE_UP_RIGHT_TO_BENEFIT_FROM_TRUST_ASSETS}
          options={NOT_ASKED_AND_UNSURE_OPTIONS}
          handleSave={saveProbateEstateField}
          inline
        />
      </Grid.Item>
      <Grid.Item>
        <NotesInput
          name={TRUST_ASSETS_NOTES}
          handleSave={saveProbateEstateField}
        />
      </Grid.Item>
    </Grid>
  )
}

Trusts.propTypes = {
  saveProbateEstateField: PropTypes.func.isRequired,
}

export default Trusts
