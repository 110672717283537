import {
  useQuery,
  UseQueryResult,
  useMutation,
  UseMutationResult,
  useQueryClient,
} from 'react-query'
import axiosRequest from 'lib/axios/api-request'
import { ResponseMeta } from 'lib/types/api-response'

export const RESOURCES = {
  ACCOUNTS: 'accounts',
  ACCOUNTS_UPDATE_EMAIL: 'accounts_update_email',
  CHARITIES: 'charities',
  CONTACTS: 'contacts',
  EVENTS: 'events',
  FUNERAL_PLANS: 'funeral-plans',
  GIFTS: 'gifts',
  GIFT_BENEFICIARIES: 'gift-beneficiaries',
  LEADS: 'leads',
  LPA_CASES: 'lpa-cases',
  ORGANISATIONS: 'organisations',
  PACKAGES: 'packages',
  PARTNERS: 'partners',
  TELEPHONE_WILL_USERS: 'telephone-will-users',
  TPS: 'tps',
  WILLS: 'wills',
  WILL_CASES: 'will-cases',
  WILL_UPDATES: 'will-updates',
} as const

type ResourceValue = (typeof RESOURCES)[keyof typeof RESOURCES]

type FetchProps = {
  id: number
  resource: ResourceValue
}

type FetchArrayProps = {
  resource: ResourceValue
  params?: string
  endpoint?: string
  queryKey?: unknown[]
}

type UpdateProps = {
  id: number
  attributes: Record<string, unknown>
  resource: ResourceValue
}

type DeleteProps = {
  id: number
  resource: ResourceValue
  endpoint?: string
}

type UpdateArrayProps = {
  id: number
  attributes: Record<string, unknown>
  resource: ResourceValue
  endpoint?: string
}

type DeleteArrayProps = {
  id: number
  resource: ResourceValue
  endpoint?: string
}

type OptionsProps = {
  enabled?: boolean
  refetchInterval?: false | number
  keepPreviousData?: boolean
  staleTime?: number
  initialData?: Record<string, unknown>
}

type CreateProps = {
  attributes: Record<string, unknown>
  resource: ResourceValue
  endpoint?: string
}

type CreateArrayProps = {
  attributes: Record<string, unknown>
  resource: ResourceValue
  endpoint?: string
}

type ArrayResourceProps =
  | {
      resource: ResourceValue
      params?: string
      identifier: string
    }
  | {
      resource: ResourceValue
      params: string
      identifier?: string
    }

type HasId = { id: number }

type ApiArrayResponse<Result> = { data: Result[]; meta?: ResponseMeta }

export type UseUpdateArrayResourceResult<Result> = UseMutationResult<
  Result,
  Error,
  UpdateArrayProps
>

export type UseDeleteArrayResourceResult = UseMutationResult<
  null,
  Error,
  DeleteArrayProps
>

export type UseCreateArrayResourceResult<Result> = UseMutationResult<
  Result,
  Error,
  CreateArrayProps
>

export const defaultOptions: OptionsProps = {
  enabled: true,
  refetchInterval: false,
  keepPreviousData: false,
  staleTime: 5000,
}

export const useFetchResource = <Result>(
  { id, resource }: FetchProps,
  options?: OptionsProps
): UseQueryResult<Result, Error> =>
  useQuery(
    [resource, id],
    async () => {
      const { data: response } = await axiosRequest({
        url: `/api/${resource}/${id}`,
      })
      return response.data
    },
    {
      ...defaultOptions,
      ...options,
    }
  )

export const useUpdateResource = <Result>(): UseMutationResult<
  Result,
  Error,
  UpdateProps
> => {
  const cache = useQueryClient()

  return useMutation(
    async ({ attributes, id, resource }: UpdateProps) => {
      const { data: response } = await axiosRequest({
        url: `/api/${resource}/${id}`,
        method: 'PATCH',
        data: {
          data: {
            id,
            type: resource.replace(/-/g, '_'),
            attributes,
          },
        },
      })

      return response.data
    },
    {
      onSuccess: (data, variables) =>
        cache.setQueryData([variables.resource, variables.id], data),
    }
  )
}

export const useDeleteResource = ({
  invalidateQueryKeys,
}: {
  invalidateQueryKeys?: (string | string[])[]
}): UseMutationResult<null, Error, DeleteProps> => {
  const cache = useQueryClient()
  return useMutation(
    async ({ id, resource, endpoint }: DeleteProps) => {
      await axiosRequest({
        url: endpoint || `/api/${resource}/${id}`,
        method: 'DELETE',
      })

      return null
    },
    {
      onSuccess: () => {
        invalidateQueryKeys?.map((queryKey) =>
          cache.invalidateQueries(queryKey)
        )
      },
    }
  )
}

export const useDeleteArrayResource = <Result extends HasId>({
  queryKey,
}: {
  queryKey: unknown[] | string
}): UseDeleteArrayResourceResult => {
  const cache = useQueryClient()

  return useMutation(
    async ({ id, resource, endpoint }: DeleteArrayProps) => {
      await axiosRequest({
        url: endpoint ?? `/api/${resource}/${id}`,
        method: 'DELETE',
      })
      return null
    },
    {
      onSuccess: (_data, variables) => {
        cache.setQueryData<
          (prev: ApiArrayResponse<Result>) => ApiArrayResponse<Result>
        >(queryKey, (prev: ApiArrayResponse<Result>) => {
          const newData = prev.data.filter((item) => item.id !== variables.id)

          return { ...prev, data: newData }
        })
      },
    }
  )
}

export const useCreateResource = <Result>(): UseMutationResult<
  Result,
  Error,
  CreateProps
> => {
  const cache = useQueryClient()

  return useMutation(
    async ({ attributes, resource, endpoint }: CreateProps) => {
      const { data: response } = await axiosRequest({
        url: endpoint || `/api/${resource}`,
        method: 'POST',
        data: {
          data: {
            type: resource.replace(/-/g, '_'),
            attributes,
          },
        },
      })

      return response.data
    },
    {
      onSuccess: (data, variables) => {
        if (data?.id) {
          cache.setQueryData([variables.resource, data.id], data)
        }
      },
    }
  )
}

export const useFetchArrayResource = <Result>(
  { resource, params, endpoint, queryKey }: FetchArrayProps,
  options?: OptionsProps
): UseQueryResult<ApiArrayResponse<Result>, Error> => {
  const key = queryKey || [resource, params]
  return useQuery(
    key,
    async () => {
      const { data: response } = await axiosRequest({
        url: endpoint ?? `/api/${resource}?${params}`,
      })
      return { data: response.data, meta: response.meta }
    },
    {
      ...defaultOptions,
      ...options,
    }
  )
}

export const useUpdateArrayResource = <Result extends HasId>({
  queryKey,
}: {
  queryKey: unknown[] | string
}): UseUpdateArrayResourceResult<Result> => {
  const cache = useQueryClient()

  return useMutation(
    async ({ attributes, id, resource, endpoint }: UpdateArrayProps) => {
      const { data: response } = await axiosRequest({
        url: endpoint ?? `/api/${resource}/${id}`,
        method: 'PATCH',
        data: {
          data: {
            id,
            type: resource.replace(/-/g, '_'),
            attributes,
          },
        },
      })
      return response.data
    },
    {
      onSuccess: (data) => {
        cache.setQueryData<
          (prev: ApiArrayResponse<Result>) => ApiArrayResponse<Result>
        >(queryKey, (prev: ApiArrayResponse<Result>) => {
          const newData = prev.data.map((item) => {
            if (item.id === data.id) {
              return data
            } else {
              return item
            }
          })

          return { ...prev, data: newData }
        })
      },
    }
  )
}

export const useCreateArrayResource = <Result extends HasId>({
  queryKey,
}: {
  queryKey: unknown[] | string
}): UseCreateArrayResourceResult<Result> => {
  const cache = useQueryClient()

  return useMutation(
    async ({ attributes, resource, endpoint }: CreateArrayProps) => {
      const { data: response } = await axiosRequest({
        url: endpoint ?? `/api/${resource}`,
        method: 'POST',
        data: {
          data: {
            type: resource.replace(/-/g, '_'),
            attributes,
          },
        },
      })
      return response.data
    },
    {
      onSuccess: (data: Result) => {
        cache.setQueryData<
          (prev: ApiArrayResponse<Result>) => ApiArrayResponse<Result>
        >(queryKey, (prev: ApiArrayResponse<Result>) => {
          const newData = [...prev.data, data]
          return { ...prev, data: newData }
        })
      },
    }
  )
}

// A helper to be used when you need to perform multiple
// operations on a resource.
export const useArrayResource = <Result extends HasId>(
  { resource, params, identifier }: ArrayResourceProps,
  options?: OptionsProps
): {
  query: UseQueryResult<ApiArrayResponse<Result>, Error>
  updateMutation: UseUpdateArrayResourceResult<Result>
  createMutation: UseCreateArrayResourceResult<Result>
  deleteMutation: UseDeleteArrayResourceResult
  isLoading: boolean
} => {
  const queryKey = [resource, params || identifier]
  const query = useFetchArrayResource<Result>(
    { resource, params, queryKey },
    options
  )
  const updateMutation = useUpdateArrayResource<Result>({ queryKey })
  const createMutation = useCreateArrayResource<Result>({ queryKey })
  const deleteMutation = useDeleteArrayResource({ queryKey })

  return {
    query,
    updateMutation,
    createMutation,
    deleteMutation,
    isLoading: !![query, updateMutation, createMutation, deleteMutation].find(
      (operator) => operator.isLoading
    ),
  }
}
