import PropTypes from 'prop-types'
import { Grid, H, P } from '@farewill/ui'
import { Link } from 'react-router-dom'

import withForm from '../fact-find/components/with-form'

const ReferralToLegalTeam = ({ probateEstateId }) => {
  return (
    <Grid>
      <Grid.Item>
        <H size="S">7. Referral to legal team</H>
      </Grid.Item>
      <Grid.Item>
        <P>
          Proceed to{' '}
          <Link to={`/probate/estates/${probateEstateId}/approve-for-referral`}>
            Approve for referral
          </Link>{' '}
        </P>
      </Grid.Item>
    </Grid>
  )
}

ReferralToLegalTeam.propTypes = {
  probateEstateId: PropTypes.number.isRequired,
}

export default withForm(ReferralToLegalTeam)
