const getJwtPayload = (token) => {
  try {
    return JSON.parse(atob(token.split('.')[1]))
  } catch {
    return null
  }
}

export const getUserIdFromToken = (token) => {
  const payload = getJwtPayload(token)
  return payload?.id
}
