import { Link } from 'react-router-dom'
import { Button, Grid, H, P, Wrapper } from '@farewill/ui'
import { useFormikContext } from 'formik'

import { Selected } from 'components/form/select-input/types'
import { CircularRadioGroup } from 'components/form'
import { YES_NO_OPTIONS } from 'utils/enums'

import { createLpaLead } from './helpers'

interface FormFields {
  lpaCheckAttributes: {
    lpaLeadId: number
    interestedInLpa?: 'true' | 'false'
  }
}

const LPACrossSell = ({ id }: { id: number }) => {
  const { values, handleSubmit, setFieldValue } = useFormikContext<FormFields>()
  const { lpaLeadId, interestedInLpa } = values.lpaCheckAttributes

  const hasAlreadyCreatedLPALead = !!lpaLeadId

  const handleSaveLPACheckAttributes = (event: {
    name: string
    value: string | number | Selected
  }): void => {
    const name = event.name.split('.')[1]

    setFieldValue('lpaCheckAttributes', {
      ...(name !== 'interestedInProbate' && {
        ...values.lpaCheckAttributes,
      }),
      [name]: event.value,
    })
    handleSubmit()
  }

  const handleCreateLpaLead = async (): Promise<void> => {
    const { lpaLeadId } = await createLpaLead({ values, willCaseId: id })

    setFieldValue('lpaCheckAttributes', {
      ...values.lpaCheckAttributes,
      lpaLeadId,
    })
    handleSubmit()
  }

  return (
    <>
      <H size="S" margin={[0, 0, 'M']}>
        Is an LPA needed?
      </H>
      {hasAlreadyCreatedLPALead ? (
        <Grid.Item>
          <P>
            We’ve created a LPA lead for this customer.{' '}
            <Link to={`/leads/${lpaLeadId}`}>Go to LPA lead</Link>
          </P>
        </Grid.Item>
      ) : (
        <>
          <CircularRadioGroup
            name="lpaCheckAttributes.interestedInLpa"
            label="Does the customer want purchase an LPA?"
            options={YES_NO_OPTIONS}
            handleSave={handleSaveLPACheckAttributes}
            inline
          />

          {interestedInLpa === 'true' && (
            <Wrapper margin={['L', 0]}>
              <Button.Secondary onClick={() => handleCreateLpaLead()}>
                Create LPA lead
              </Button.Secondary>
            </Wrapper>
          )}
        </>
      )}
    </>
  )
}

export default LPACrossSell
