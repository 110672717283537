import styled from 'styled-components'
import { Grid, Wrapper, P } from '@farewill/ui'
import { COLOR, GTR, BORDER } from '@farewill/ui/tokens'
import { Link } from 'react-router-dom'

import { LEAD_STATUS } from 'utils/enums'
import { Lead } from 'lib/models/lead'

import { PRODUCTS } from './constants'

const StyledMessageWrapper = styled(Wrapper)`
  border: 2px solid ${COLOR.STATE.SUCCESS};
  border-radius: ${BORDER.RADIUS.S};
  padding: ${GTR.XS};
`

const ConvertedMessage = ({ lead }: { lead: Lead }) => {
  const { product, status } = lead.attributes
  const isConverted = status === LEAD_STATUS.CONVERTED
  const options = PRODUCTS[product]
  const productId = lead.attributes[options?.leadRelation]?.id

  if (!isConverted || !productId) return null

  const url = `${options.baseUrl}/${productId}`

  return (
    <Grid.Item>
      <StyledMessageWrapper>
        <P size="S">
          This lead has been converted.
          <br />
          <Link to={url}>View the customer page</Link>.
        </P>
      </StyledMessageWrapper>
    </Grid.Item>
  )
}

export default ConvertedMessage
