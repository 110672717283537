import { Grid, P } from '@farewill/ui'
import { COLOR } from '@farewill/ui/tokens'
import { useFormikContext } from 'formik'

import MessageBox from 'components/message-box'
import { IndentedCheckbox } from 'components/form'
import { ProbateLeadAttributes } from 'lib/models/lead'
import { getGiftTaxYears } from 'lib/probate/helpers'
import { QuestionConfig } from 'lib/types/form'
import { YES_NO_OPTIONS } from 'utils/enums'
import { strToBool } from 'utils/helpers'
import { Question } from './question'
import { PROBATE_LEAD_FORM_SECTIONS } from '../constants'
import SectionHeading from '../section-heading'

const YearlyGiftsQuestions = () => {
  const { handleSubmit, values } = useFormikContext<ProbateLeadAttributes>()
  const { dateOfDeath } = values.quoteAttributes

  const giftTaxYears = getGiftTaxYears(dateOfDeath)

  const names = [
    'quoteAttributes.giftsYearOne',
    'quoteAttributes.giftsYearTwo',
    'quoteAttributes.giftsYearThree',
    'quoteAttributes.giftsYearFour',
    'quoteAttributes.giftsYearFive',
    'quoteAttributes.giftsYearSix',
    'quoteAttributes.giftsYearSeven',
  ]

  return (
    <>
      <P margin={[0, 0, 'XS']}>Estimate for total amount gifted per year</P>
      <P size="S" color={COLOR.GREY.MEDIUM}>
        Tax year and amount can be estimates but it’s used to calculate the
        estate value. If the total is more £250,000, it makes the case complex.
      </P>

      {dateOfDeath ? (
        names.map((name, i) => {
          return (
            <IndentedCheckbox
              key={name}
              name={name}
              valueName="Value"
              label={giftTaxYears[i]}
              handleSave={handleSubmit}
              placeholder="Total (£)"
              isCurrency
            />
          )
        })
      ) : (
        <MessageBox warning>
          Date of death must be entered before gifts can be added.
        </MessageBox>
      )}
    </>
  )
}

export const GIFT_QUESTIONS: QuestionConfig[] = [
  {
    name: 'quoteAttributes.giftsGivenOver3kThreshold',
    shortLabel: 'Made gifts worth over £3,000 in 7 years before they died',
    label:
      'In the 7 years before they died, did they make any gifts worth over £3,000 in total?',
    options: YES_NO_OPTIONS,
    followUpQuestions: [
      {
        name: 'quoteAttributes.gifts',
        component: YearlyGiftsQuestions,
        showIf: (values: ProbateLeadAttributes) =>
          !!strToBool(values.quoteAttributes.giftsGivenOver3kThreshold),
      },
    ],
  },
]

const GiftsGivenOver3k = ({ isComplete }: { isComplete: boolean }) => {
  const { values } = useFormikContext<ProbateLeadAttributes>()

  const { followUpQuestions } = GIFT_QUESTIONS[0]
  const followUpQuestionsToShow =
    followUpQuestions?.filter(({ showIf }) => !showIf || showIf(values)) || []

  return (
    <Grid name={PROBATE_LEAD_FORM_SECTIONS.GIFTS_OVER_3K}>
      <SectionHeading
        title="8.1 Gifts over £3,000"
        scrollId="8.1"
        isComplete={isComplete}
      />
      <Grid.Item>
        <Question question={GIFT_QUESTIONS[0]} />
      </Grid.Item>
      {followUpQuestionsToShow.map((followUpQuestion) => (
        <Grid.Item key={followUpQuestion.name}>
          <Question question={followUpQuestion} />
        </Grid.Item>
      ))}
    </Grid>
  )
}

export default GiftsGivenOver3k
