import { useFormikContext } from 'formik'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import { Button, Checkbox, Grid, H, P } from '@farewill/ui'
import { GTR } from '@farewill/ui/tokens'

import { InputFloating, SelectInput } from 'components/form'
import CircularRadioGroup from 'components/form/circular-radio-group'
import DatetimePicker from 'components/form/datetime-picker'
import { Selected } from 'components/form/select-input/types'
import StatusPanel from 'components/status-panel'
import {
  FAREWILL_PRODUCTS,
  FUNERAL_CASE_PROBATE_CHECK_INTERESTED,
  FUNERAL_CASE_PROBATE_CHECK_INTERESTED_OPTIONS,
  FUNERAL_CASE_PROBATE_NOT_INTERESTED_REASON,
  FUNERAL_CASE_PROBATE_NOT_INTERESTED_REASON_OPTIONS,
  MEMBERSHIP_VOUCHER_VALUES,
  PROBATE_PROPERTY_OWNERSHIP_TYPE_OPTIONS,
  PROBATE_THRESHOLD_LINK,
  YES_NO_OPTIONS,
} from 'utils/enums'

import { SaveProp, FormikValues } from '../types'
import { allowProbateLeadBooking, createProbateLead } from './helpers'
import { formatCurrency } from 'lib/formatting/currency'

const IsProbateNeeded = ({
  funeralId,
  handleSave,
}: {
  funeralId: number
  handleSave: SaveProp
}) => {
  const { values }: { values: FormikValues } = useFormikContext()
  const {
    probateLeadId,
    interestedInProbate,
    ownedProperty,
    scheduledNextCallAt,
  } = values.probateCheckAttributes
  const hasAlreadyBookedProbateLead = !!probateLeadId
  const interestedInProbateYes =
    (interestedInProbate as unknown as string) ===
    FUNERAL_CASE_PROBATE_CHECK_INTERESTED.YES
  const interestedInProbateNo =
    (interestedInProbate as unknown as string) ===
    FUNERAL_CASE_PROBATE_CHECK_INTERESTED.NO
  const ownsProperty = ownedProperty === 'true'
  const today = new Date().toISOString()
  const hasChosenTime = !!scheduledNextCallAt
  const [hasNoTimePreference, setHasNoTimePreference] = useState(false)

  const handleSaveProbateCheckAttributes = (event: {
    name: string
    value: string | Selected
  }): void => {
    /** To get the name, split the event name by the dot and take the latter half, 
    eg "probateCheckAttributes.interestedInProbate" becomes "interestedInProbate" */
    const name = event.name.split('.')[1]

    return handleSave({
      name: 'probateCheckAttributes',
      /** We will not preserve existing probateCheckAttributes if interestedInProbate value is changed,
       * since which other probateCheckAttributes are relevant depends on interestedInProbate's value.
       */
      value: {
        ...(name !== 'interestedInProbate' && {
          ...values.probateCheckAttributes,
        }),
        [name]: event.value,
      },
    })
  }

  const handleCreateProbateLead = async (): Promise<void> => {
    const { probateLeadId } = await createProbateLead({ values, funeralId })
    return handleSave({
      name: 'probateCheckAttributes',
      value: {
        ...values.probateCheckAttributes,
        probateLeadId,
      },
    })
  }

  const probateVoucherPrice = formatCurrency({
    valueInPence: true,
    showPence: false,
    value: MEMBERSHIP_VOUCHER_VALUES[FAREWILL_PRODUCTS.PROBATE],
  })

  return (
    <Grid>
      <Grid.Item>
        <H size="M">6. Is probate needed?</H>
        <StatusPanel
          heading={`Reminder: ${probateVoucherPrice} member discount`}
          highlight
        >
          <P>
            "Because we’re helping with the funeral I can also get you{' '}
            {probateVoucherPrice} off our probate service. Would you like me to
            tell you if you need probate?"
          </P>
        </StatusPanel>
      </Grid.Item>
      {hasAlreadyBookedProbateLead && (
        <Grid.Item>
          <P>
            We’ve booked a probate callback for this customer.{' '}
            <Link to={`/leads/${probateLeadId}`}>Go to probate lead</Link>
          </P>
        </Grid.Item>
      )}
      {!hasAlreadyBookedProbateLead && (
        <>
          <Grid.Item>
            <CircularRadioGroup
              name="probateCheckAttributes.interestedInProbate"
              label="Would they like to know if they need probate?"
              options={FUNERAL_CASE_PROBATE_CHECK_INTERESTED_OPTIONS}
              handleSave={handleSaveProbateCheckAttributes}
              inline
            />
          </Grid.Item>

          {interestedInProbateYes && (
            <>
              <Grid.Item>
                <CircularRadioGroup
                  name="probateCheckAttributes.ownedProperty"
                  label="Did the person who died own their own property?"
                  options={YES_NO_OPTIONS}
                  handleSave={handleSaveProbateCheckAttributes}
                  inline
                />
              </Grid.Item>
              {ownsProperty && (
                <Grid.Item>
                  <CircularRadioGroup
                    name="probateCheckAttributes.propertyOwnershipType"
                    label="How did they own their own property (or their main residence, if more than one)?"
                    options={PROBATE_PROPERTY_OWNERSHIP_TYPE_OPTIONS}
                    handleSave={handleSaveProbateCheckAttributes}
                    inline
                  />
                </Grid.Item>
              )}
              <Grid.Item>
                <CircularRadioGroup
                  name="probateCheckAttributes.aboveAccountOrShareThreshold"
                  label={
                    <>
                      Did the person who died have any bank accounts or shares
                      worth more than{' '}
                      <a
                        href={PROBATE_THRESHOLD_LINK}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        the probate thresholds
                      </a>
                      ?
                    </>
                  }
                  options={YES_NO_OPTIONS}
                  handleSave={handleSaveProbateCheckAttributes}
                  inline
                />
              </Grid.Item>
              <Grid.Item>
                <CircularRadioGroup
                  name="probateCheckAttributes.customerAlreadyKnowsProbateRequired"
                  label="Has an organisation asked you for a grant of probate?"
                  options={YES_NO_OPTIONS}
                  handleSave={handleSaveProbateCheckAttributes}
                  inline
                />
              </Grid.Item>
            </>
          )}
          {interestedInProbateNo && (
            <>
              <Grid.Item>
                <SelectInput
                  name="probateCheckAttributes.notInterestedReason"
                  label="Why is the caller not interested?"
                  options={FUNERAL_CASE_PROBATE_NOT_INTERESTED_REASON_OPTIONS}
                  handleSave={handleSaveProbateCheckAttributes}
                  isClearable
                />
              </Grid.Item>
              {values.probateCheckAttributes?.notInterestedReason ===
                (FUNERAL_CASE_PROBATE_NOT_INTERESTED_REASON.OTHER as unknown as typeof FUNERAL_CASE_PROBATE_NOT_INTERESTED_REASON) && (
                <Grid.Item>
                  <InputFloating
                    name="probateCheckAttributes.notes"
                    label="Other reason"
                    handleSave={handleSaveProbateCheckAttributes}
                  />
                </Grid.Item>
              )}
            </>
          )}
          {allowProbateLeadBooking(values) === false && (
            <Grid.Item margin={[GTR.S, 0]}>
              <P strong>No probate required.</P>
            </Grid.Item>
          )}
          {allowProbateLeadBooking(values) === true && (
            <>
              <Grid.Item margin={[GTR.S, 0]}>
                <P strong>
                  {interestedInProbateYes && 'Probate is required.'} Book a
                  probate callback for the customer:
                </P>
                <DatetimePicker
                  name="probateCheckAttributes.scheduledNextCallAt"
                  label="Pick a callback time"
                  handleSave={handleSaveProbateCheckAttributes}
                  min={today}
                  disabled={hasNoTimePreference}
                />
                <Checkbox
                  size="M"
                  name="hasNoTimePreference"
                  label="No preferred time (customer will be called back ASAP)"
                  checked={hasNoTimePreference}
                  onChange={() => {
                    handleSaveProbateCheckAttributes({
                      name: 'probateCheckAttributes.scheduledNextCallAt',
                      value: undefined as unknown as string,
                    })
                    setHasNoTimePreference(!hasNoTimePreference)
                  }}
                />
              </Grid.Item>
              <Grid.Item>
                <InputFloating
                  name="probateCheckAttributes.notes"
                  label="Notes"
                  hint="Any notes you want to add to the probate lead (optional)"
                  handleSave={
                    handleSaveProbateCheckAttributes as (event: {
                      name: string
                      value: string | number | null
                    }) => void
                  }
                />
              </Grid.Item>

              {(hasChosenTime || hasNoTimePreference) && (
                <Grid.Item margin={[GTR.S, 0]}>
                  <Button.Primary onClick={() => handleCreateProbateLead()}>
                    Create Probate Lead
                  </Button.Primary>
                </Grid.Item>
              )}
            </>
          )}
        </>
      )}
    </Grid>
  )
}

export default IsProbateNeeded
