const FETCH_WILL_PDF_URL = 'FETCH_WILL_PDF_URL'

export default function will(state = {}, action = {}) {
  switch (action.type) {
    case FETCH_WILL_PDF_URL + '_FULFILLED':
      return action.payload.data

    case FETCH_WILL_PDF_URL + '_PENDING':
    case FETCH_WILL_PDF_URL + '_REJECTED':
      return {}

    default:
      return state
  }
}
