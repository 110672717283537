import { useState } from 'react'
import PropTypes from 'prop-types'
import styled, { css, keyframes } from 'styled-components'
import { Wrapper, P } from '@farewill/ui'
import { COLOR, GTR } from '@farewill/ui/tokens'
import ActivityTimestamp from 'components/activity-timestamp'
import AdminIcon from 'components/admin-icon'
import { useActivityContext } from './context'
import { Link, useLocation } from 'react-router-dom'

import CommentContent from 'components/comment-content'
import ReplyButton from './reply-button'
import CommentActions from './comment-actions'
import ReplyForm from './reply-form'
import DeletedComment from './deleted-comment'
import { isEdited } from './helpers'
import CommentForm from './comment-form'

const fadeOut = keyframes`
  from {
    background-color: ${COLOR.STATE.WARNING_10};
    border: 1px dashed ${COLOR.ACCENT.PRIMARY};
  }

  to {
    background-color: transparent;
    border-color: transparent;
  }
`

const StyledCommentWrapper = styled(Wrapper)`
  display: flex;
  margin-bottom: ${GTR.L};
  position: relative;

  ${({ $highlighted }) =>
    $highlighted &&
    css`
      &::before {
        content: '';
        display: block;
        top: -${GTR.S};
        left: -${GTR.S};
        right: -${GTR.S};
        bottom: -${GTR.S};
        border: 1px dashed ${COLOR.ACCENT.PRIMARY};
        position: absolute;
        background-color: ${COLOR.STATE.WARNING_10};
        z-index: -2;
        animation: ${fadeOut} 0.5s;
        animation-delay: 3s;
        animation-fill-mode: forwards;
      }
    `}
`

const StyledBodyWrapper = styled(Wrapper)`
  width: 100%;
`

const StyledReplyWrapper = styled(StyledCommentWrapper)`
  z-index: 0;

  &::after {
    content: '';
    display: block;
    position: absolute;
    z-index: -1;
    top: 46px;
    bottom: -26px;
    left: 18px;
    width: 4px;
    background-color: ${COLOR.GREY.LIGHT};
  }
`

const getIsHighlighted = (location, id) => location.hash === `#comment-${id}`

const CommentReply = ({ isDeletable, reply, queryParams }) => {
  const location = useLocation()
  const [isEditMode, setIsEditMode] = useState(false)
  const { updateComment, fetchComments } = useActivityContext()
  const { content, mentions } = reply

  const { edited, lastInteractedWith } = isEdited(
    reply.createdAt,
    reply.updatedAt
  )

  const handleUpdate = async (values) => {
    await updateComment(reply.id, values)
    await fetchComments({ queryParams })
    setIsEditMode(false)
  }

  return (
    <StyledReplyWrapper
      data-scroll-id={`comment-${reply.id}`}
      $highlighted={getIsHighlighted(location, reply.id)}
    >
      <AdminIcon user={reply.adminUser} />
      <StyledBodyWrapper>
        {isEditMode ? (
          <CommentForm
            handleSubmit={handleUpdate}
            initialValues={{ content, mentions }}
            iconSize="M"
            isEdit
            handleCancelClick={() => setIsEditMode(false)}
          />
        ) : (
          <>
            <P margin={0} size="S">
              <strong>{reply.adminUser.name}</strong>
            </P>
            <ActivityTimestamp
              datetime={lastInteractedWith}
              isEdited={edited}
            />
            <CommentActions
              commentId={reply.id}
              adminUserId={reply.adminUser.id}
              isDeletable={isDeletable}
              setIsEditMode={setIsEditMode}
              queryParams={queryParams}
            />
            <CommentContent content={reply.content} />
          </>
        )}
      </StyledBodyWrapper>
    </StyledReplyWrapper>
  )
}

const CommentReplies = ({
  enableCommenting,
  parentId,
  probateCaseId,
  willCaseId,
  replies,
  taskId,
  queryParams,
}) => (
  <Wrapper padding={['M', 0, 0]}>
    {replies.map((reply, index) => (
      <CommentReply
        reply={reply}
        key={reply.id}
        isDeletable={index === replies.length - 1}
        queryParams={queryParams}
      />
    ))}

    {enableCommenting && (
      <ReplyForm
        parentId={parentId}
        probateCaseId={probateCaseId}
        willCaseId={willCaseId}
        taskId={taskId}
      />
    )}
  </Wrapper>
)

const CommentThread = ({ enableCommenting, comment, queryParams }) => {
  const location = useLocation()
  const repliesExpandedOnMount = comment.attributes.replies.find(({ id }) =>
    getIsHighlighted(location, id)
  )
  const [repliesExpanded, setRepliesExpanded] = useState(repliesExpandedOnMount)
  const [isEditMode, setIsEditMode] = useState(false)
  const { updateComment } = useActivityContext()

  const {
    adminUser,
    content,
    mentions,
    createdAt,
    updatedAt,
    isDeleted,
    probateCaseId,
    willCaseId,
    replies,
    task,
    isCall,
  } = comment.attributes

  const { edited, lastInteractedWith } = isEdited(createdAt, updatedAt)
  const handleUpdate = async (values) => {
    await updateComment(comment.id, values)
    setIsEditMode(false)
  }

  if (isDeleted) return <DeletedComment comment={comment} />

  return (
    <StyledCommentWrapper
      data-scroll-id={`comment-${comment.id}`}
      $highlighted={getIsHighlighted(location, comment.id)}
    >
      <AdminIcon user={adminUser} size="L" />
      <StyledBodyWrapper>
        {isEditMode ? (
          <CommentForm
            handleSubmit={handleUpdate}
            initialValues={{ content, isCall, mentions }}
            iconSize="M"
            isEdit
            showIsCallSwitch
            handleCancelClick={() => setIsEditMode(false)}
          />
        ) : (
          <>
            <P margin={0} size="S">
              <strong>{adminUser.name}</strong>{' '}
              {task ? (
                <>
                  commented on the task{' '}
                  <Link to={`#task-${task.id}`}>
                    <strong>{task.title}</strong>
                  </Link>
                </>
              ) : isCall ? (
                'made contact with customer'
              ) : (
                'left a comment'
              )}
            </P>
            <ActivityTimestamp
              datetime={lastInteractedWith}
              isEdited={edited}
            />
            <CommentActions
              commentId={comment.id}
              adminUserId={adminUser.id}
              isDeletable={replies.length === 0}
              setIsEditMode={setIsEditMode}
              queryParams={queryParams}
            />
            <CommentContent content={content} />
          </>
        )}
        <ReplyButton
          expanded={repliesExpanded}
          handleClick={() => setRepliesExpanded(!repliesExpanded)}
          replies={replies}
        />
        {repliesExpanded && (
          <CommentReplies
            enableCommenting={enableCommenting}
            parentId={comment.id}
            probateCaseId={probateCaseId}
            willCaseId={willCaseId}
            replies={replies}
            taskId={task?.id}
            queryParams={queryParams}
          />
        )}
      </StyledBodyWrapper>
    </StyledCommentWrapper>
  )
}

CommentThread.propTypes = {
  enableCommenting: PropTypes.bool.isRequired,
  comment: PropTypes.object.isRequired,
  queryParams: PropTypes.shape({
    probateCaseId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    funeralId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    willCaseId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    taskId: PropTypes.number,
  }).isRequired,
}

export default CommentThread
