import find from 'lodash/find'
import styled from 'styled-components'
import { P } from '@farewill/ui'
import { FONT } from '@farewill/ui/tokens'

import { RELATIONSHIP_TO_CUSTOMER } from 'utils/enums'
import { FuneralAttributes } from 'lib/models/funeral'

const VisuallyBold = styled.span`
  font-weight: ${FONT.WEIGHT.BOLD};
`

type DeceasedRelationshipProps = {
  attributes: FuneralAttributes
}

const DeceasedRelationship = ({
  attributes,
}: DeceasedRelationshipProps): React.ReactElement => {
  const { firstNames, lastName, knownAs, relationshipToCustomer } = attributes

  const hasDeceasedName = firstNames != null || lastName != null
  const name = hasDeceasedName ? (
    <>
      {firstNames} {lastName}
    </>
  ) : (
    'unknown'
  )

  const relationship =
    (
      find(RELATIONSHIP_TO_CUSTOMER, {
        value: relationshipToCustomer,
      }) as (typeof RELATIONSHIP_TO_CUSTOMER)[number]
    )?.label ?? 'unknown'

  return (
    <>
      <P>
        The person who’s died is <VisuallyBold>{name}</VisuallyBold>, the
        customer’s <VisuallyBold>{relationship}</VisuallyBold>
      </P>

      {knownAs && (
        <P>
          The customer refers to them as <VisuallyBold>{knownAs}</VisuallyBold>
        </P>
      )}
    </>
  )
}

export default DeceasedRelationship
