import { Grid, Wrapper } from '@farewill/ui'
import { CircularRadioGroup } from 'components/form'
import { connect, useFormikContext } from 'formik'
import { ProbateLeadAttributes } from 'lib/models/lead'

const OPTIONS = [
  {
    label: 'The spouse, children or grandchildren',
    value: 'true',
  },
  {
    label: 'The parents, siblings, grandparents or remoter issue',
    value: 'true',
  },
]
/**
 * This component is unusual, we are using single Radio button, to change state
 * of two different fields. The only way to do it with our Radio button from UI
 * was to break it down into two radio buttons, and use them as a trigger to change the value
 * of both fields at the same time.
 */
const EstatePassing = () => {
  const { setFieldValue, handleSubmit } =
    useFormikContext<ProbateLeadAttributes>()

  return (
    <Grid.Item>
      <Wrapper
        tag={CircularRadioGroup}
        margin={[0, 0, 'XS']}
        label="Is the estate passing to either:"
        name="quoteAttributes.intestacyPassingToCloseRelative"
        options={[OPTIONS[0]]}
        handleSave={({ value }: { value: string }) => {
          setFieldValue(
            'quoteAttributes.intestacyPassingToCloseRelative',
            value,
            false
          )
          setFieldValue(
            'quoteAttributes.intestacyPassingToRemoteRelative',
            'false',
            false
          )
          handleSubmit()
        }}
      />
      <CircularRadioGroup
        name="quoteAttributes.intestacyPassingToRemoteRelative"
        hint="Remoter issue - Relatives who are more than one generation away. Eg. grandchildren, great grandchildren."
        options={[OPTIONS[1]]}
        handleSave={({ value }) => {
          setFieldValue(
            'quoteAttributes.intestacyPassingToRemoteRelative',
            value,
            false
          )
          setFieldValue(
            'quoteAttributes.intestacyPassingToCloseRelative',
            'false',
            false
          )
          setFieldValue(
            'quoteAttributes.intestacyAmountPassingToChildrenAbove325k',
            '',
            false
          )
          handleSubmit()
        }}
      />
    </Grid.Item>
  )
}

export default connect(EstatePassing)
