import { useEffect } from 'react'
import { ReactElement } from 'react-markdown/lib/react-markdown'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'
import styled from 'styled-components'
import { H, P, Divider, Wrapper, Button, Grid } from '@farewill/ui'

import ExternalLink from 'components/external-link'
import {
  formatAmountToPence,
  getInitialValuesFromInvoice,
} from 'components/payments/invoices/methods'
import PaymentModal, {
  FormOutputValues,
} from 'components/payments/payment-modal'
import Table from 'components/table'
import useInvoices from 'hooks/useInvoices'
import { formatCurrency } from 'lib/formatting/currency'
import { formatDate } from 'lib/formatting/dates'
import { FuneralPlan, FuneralPlanPaymentDetails } from 'lib/models/funeral-plan'
import { Invoice, InvoiceAttributes } from 'lib/models/invoice'
import InvoiceFlowPaymentModal from 'routes/lead/funeral-plan-form/payment-modal'
import {
  cancelFuneralPlan,
  fetchFuneralPlanPaymentDetails,
  hideModal,
  showModal,
} from 'state/actions'
import {
  formatToHuman,
  squareUrlForCreateNewInvoicePage,
  squareUrlForCustomerPage,
  squareUrlForInvoicePage,
  squareUrlForTransactionPage,
  stripeUrlForCustomerPage,
  stripeUrlForInvoicePage,
  stripeUrlForTransactionPage,
} from 'utils/helpers'
import CancelPlanModal from './cancel-plan-modal'

const StyledFlexEndWrapper = styled(Wrapper)`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`

const StyledCustomerButton = styled(Button.Primary)`
  margin-top: 10px;
  min-width: 215px;
`

const SquareLink = ({
  reference,
  paymentProviderInvoiceId,
}: {
  reference?: string
  paymentProviderInvoiceId?: string
}): ReactElement => {
  const description = 'Card payment'

  if (paymentProviderInvoiceId) {
    return (
      <ExternalLink href={squareUrlForInvoicePage(paymentProviderInvoiceId)}>
        {description}
      </ExternalLink>
    )
  }

  if (!paymentProviderInvoiceId && reference) {
    return (
      <ExternalLink href={squareUrlForTransactionPage(reference)}>
        {description}
      </ExternalLink>
    )
  }

  return <P>No Square link</P>
}

const StripeLink = ({
  reference,
  paymentProviderInvoiceId,
}: {
  reference?: string
  paymentProviderInvoiceId?: string
}): ReactElement => {
  const description = 'Card payment'

  if (reference) {
    return (
      <ExternalLink href={stripeUrlForTransactionPage(reference)}>
        {description}
      </ExternalLink>
    )
  }

  /** reference takes precedence over invoiceId - all paid invoices will have a reference, and invoiceId will be used for unpaid ones */
  if (paymentProviderInvoiceId) {
    return (
      <ExternalLink href={stripeUrlForInvoicePage(paymentProviderInvoiceId)}>
        {description}
      </ExternalLink>
    )
  }
  return <P>No Stripe link</P>
}

const PaymentLink = ({
  paymentProvider,
  reference,
  paymentProviderInvoiceId,
}: {
  paymentProvider: string
  reference?: string
  paymentProviderInvoiceId?: string
}): ReactElement => {
  if (paymentProvider === 'square') {
    return (
      <SquareLink
        reference={reference}
        paymentProviderInvoiceId={paymentProviderInvoiceId}
      />
    )
  }

  if (paymentProvider === 'stripe') {
    return (
      <StripeLink
        reference={reference}
        paymentProviderInvoiceId={paymentProviderInvoiceId}
      />
    )
  }

  return <P>`No ${formatToHuman(paymentProvider)} link`</P>
}

const getInvoiceStatus = (
  attributes: Invoice['attributes']
): 'Paid' | 'Planned' | 'Overdue' | 'Refund in progress' | 'Refunded' => {
  const { lineItems, paidOn, issuedDate } = attributes

  if (lineItems.find((item) => item.description === 'refund')) {
    return 'Refunded'
  }
  if (lineItems.find((item) => item.description === 'refund in progress')) {
    return 'Refund in progress'
  }
  if (paidOn) {
    return 'Paid'
  }
  return new Date(issuedDate as string) < new Date() ? 'Overdue' : 'Planned'
}

const getInvoiceAmount = (
  lineItems: Invoice['attributes']['lineItems']
): string => {
  const totalPrice = lineItems.reduce(
    (acc, lineItem) => acc + lineItem.price,
    0
  )

  return formatCurrency({
    showPence: true,
    value: totalPrice,
    valueInPence: true,
  })
}

const Payment = (): React.ReactElement => {
  const { id: funeralPlanId } = useParams<{ id: string }>()
  const dispatch = useDispatch()
  const { funeralPlan, funeralPlanPaymentDetails } = useSelector(
    (state: {
      funeralPlanCase: FuneralPlan
      funeralPlanPaymentDetails: FuneralPlanPaymentDetails
    }) => ({
      funeralPlan: state.funeralPlanCase,
      funeralPlanPaymentDetails: state.funeralPlanPaymentDetails,
    }),
    shallowEqual
  )

  useEffect(() => {
    dispatch(fetchFuneralPlanPaymentDetails(funeralPlanId))
  }, [funeralPlanId, dispatch])

  const {
    invoices,
    fetchInvoices,
    updateInvoice,
    updateLineItem,
    deleteInvoice,
  } = useInvoices()

  useEffect(() => {
    if (funeralPlanId) {
      fetchInvoices({ queryParams: { 'filter[funeralPlanId]': funeralPlanId } })
    }
  }, [fetchInvoices, funeralPlanId])

  if (!funeralPlanPaymentDetails.type) {
    return <></>
  }

  const handleGoToCustomerSquare = async () => {
    if (!funeralPlanPaymentDetails.attributes.squareCustomerId) return

    window.open(
      squareUrlForCustomerPage(
        funeralPlanPaymentDetails.attributes.squareCustomerId
      )
    )
  }

  const handleGoToCustomerStripe = async () => {
    if (!funeralPlanPaymentDetails.attributes.stripeCustomerId) return

    window.open(
      stripeUrlForCustomerPage(
        funeralPlanPaymentDetails.attributes.stripeCustomerId
      )
    )
  }

  const handleCancelPlan = async () => {
    try {
      await dispatch(cancelFuneralPlan(funeralPlanId))
      await dispatch(fetchFuneralPlanPaymentDetails(funeralPlanId))
      window.scrollTo(0, 0)
    } catch {
      // handle the error
    }
  }

  const handleCancelPlanModal = ({
    amountToRefundInPence,
    cancellationFee,
  }: {
    amountToRefundInPence: number
    cancellationFee?: number
  }): void => {
    dispatch(
      showModal({
        component: CancelPlanModal,
        headingText: 'Cancel the Funeral Plan',
        config: {
          amountToRefundInPence,
          cancellationFee,
          handleCancelPlan,
        },
      })
    )
  }

  const handlePaymentClick = async () => {
    if (!funeralPlanPaymentDetails.attributes.squareCustomerId) return

    window.open(
      squareUrlForCreateNewInvoicePage(
        funeralPlanPaymentDetails.attributes.squareCustomerId
      )
    )
  }

  const handlePayBalanceModal = ({
    reference,
    amountInPence,
  }: {
    reference: string
    amountInPence: number
  }): void => {
    dispatch(
      showModal({
        component: InvoiceFlowPaymentModal,
        headingText: 'Take balance payment on Square',
        config: {
          reference,
          amountInPence,
          handlePaymentClick,
        },
      })
    )
  }

  const editPayment = (invoice: Invoice) => {
    const formType = 'card'
    const MODAL_MAX_WIDTH = 650

    dispatch(
      showModal({
        component: PaymentModal,
        headingText: 'Edit payment',
        maxWidth: MODAL_MAX_WIDTH,
        config: {
          type: formType,
          values: getInitialValuesFromInvoice({ type: formType, invoice }),
          hideModal: () => dispatch(hideModal()),
          deletePayment: async () => {
            await deleteInvoice(invoice.id)
            dispatch(fetchFuneralPlanPaymentDetails(funeralPlanId))
            fetchInvoices({
              queryParams: { 'filter[funeralPlanId]': funeralPlanId },
            })
          },
          savePayment: async ({
            reference,
            date,
            amount,
          }: FormOutputValues) => {
            const attributes = {
              reference,
              issuedDate: date,
            } as Partial<InvoiceAttributes>

            attributes.paidOn = attributes.issuedDate

            const price = formatAmountToPence(amount)
            await updateLineItem(invoice.attributes.lineItems[0], {
              price,
            })

            await updateInvoice(invoice.id, attributes)
            dispatch(fetchFuneralPlanPaymentDetails(funeralPlanId))
            fetchInvoices({
              queryParams: { 'filter[funeralPlanId]': funeralPlanId },
            })
          },
        },
      })
    )
  }

  return (
    <Wrapper>
      <H size="M">Payments</H>
      <Grid>
        <Grid.Item span={6}>
          <H tag="h3" size="S">
            Sale
          </H>
          <Table>
            <Table.Row gridTemplateCols="1fr 1fr">
              <Table.Col>Left to pay</Table.Col>
              <Table.Col strong>
                {formatCurrency({
                  showPence: true,
                  value: funeralPlanPaymentDetails.attributes.leftToPayInPence,
                  valueInPence: true,
                })}
              </Table.Col>
            </Table.Row>
            <Table.Row gridTemplateCols="1fr 1fr">
              <Table.Col>Unpaid invoices</Table.Col>
              <Table.Col>
                {formatCurrency({
                  showPence: true,
                  value:
                    funeralPlanPaymentDetails.attributes.unpaidInvoicesInPence,
                  valueInPence: true,
                })}
              </Table.Col>
            </Table.Row>
            <Table.Row gridTemplateCols="1fr 1fr">
              <Table.Col>Total quoted</Table.Col>
              <Table.Col>
                {formatCurrency({
                  showPence: true,
                  value: funeralPlanPaymentDetails.attributes.totalPriceInPence,
                  valueInPence: true,
                })}
              </Table.Col>
            </Table.Row>
            <Table.Row gridTemplateCols="1fr 1fr">
              <Table.Col>Total paid</Table.Col>
              <Table.Col strong>
                {formatCurrency({
                  showPence: true,
                  value: funeralPlanPaymentDetails.attributes.totalPaidInPence,
                  valueInPence: true,
                })}
              </Table.Col>
            </Table.Row>
          </Table>
        </Grid.Item>
        <Grid.Item span={6}>
          <H tag="h3" size="S">
            Status
          </H>
          <Table>
            <Table.Row gridTemplateCols="1fr 1fr">
              <Table.Col>Status</Table.Col>
              <Table.Col strong>
                {funeralPlanPaymentDetails.attributes.status}
              </Table.Col>
            </Table.Row>
            <Table.Row gridTemplateCols="1fr 1fr">
              <Table.Col>Type</Table.Col>
              <Table.Col>{funeralPlanPaymentDetails.attributes.type}</Table.Col>
            </Table.Row>
            {funeralPlanPaymentDetails.attributes.type === 'Instalments' && (
              <Table.Row gridTemplateCols="1fr 1fr">
                <Table.Col>Instalments paid</Table.Col>
                <Table.Col>
                  {funeralPlanPaymentDetails.attributes.numberOfInstalmentsPaid}{' '}
                  of{' '}
                  {
                    funeralPlanPaymentDetails.attributes
                      .numberOfInstalmentsTotal
                  }
                </Table.Col>
              </Table.Row>
            )}
          </Table>
          <StyledFlexEndWrapper margin={['L', 0, 0]}>
            {funeralPlanPaymentDetails.attributes.squareCustomerId && (
              <StyledCustomerButton onClick={() => handleGoToCustomerSquare()}>
                Customer on Square
              </StyledCustomerButton>
            )}
            {funeralPlanPaymentDetails.attributes.stripeCustomerId && (
              <StyledCustomerButton onClick={() => handleGoToCustomerStripe()}>
                Customer on Stripe
              </StyledCustomerButton>
            )}
          </StyledFlexEndWrapper>
        </Grid.Item>
      </Grid>

      {invoices && (
        <>
          <Divider margin={['XL', 0]} />

          <Table withHeaders>
            <H size="S">Payments</H>
            <Table.Row gridTemplateCols="1fr 1fr 1.5fr 1fr 1fr 0.5fr">
              <Table.Col strong>Date</Table.Col>
              <Table.Col strong>Provider</Table.Col>
              <Table.Col strong>Description</Table.Col>
              <Table.Col strong>Status</Table.Col>
              <Table.Col strong>Amount</Table.Col>
            </Table.Row>
            {invoices.map((invoice: Invoice) => {
              if (!invoice) return null

              const { attributes } = invoice
              const status = getInvoiceStatus(attributes)
              const amount = getInvoiceAmount(attributes.lineItems)
              return (
                <Table.Row
                  gridTemplateCols="1fr 1fr 1.5fr 1fr 1fr 0.5fr"
                  key={invoice.id}
                >
                  <P>{formatDate(attributes.issuedDate)}</P>
                  <P>{formatToHuman(attributes.paymentProvider)}</P>
                  <PaymentLink
                    paymentProvider={attributes?.paymentProvider}
                    reference={attributes?.reference}
                    paymentProviderInvoiceId={
                      attributes?.paymentProviderInvoiceId
                    }
                  />
                  <P>{status}</P>
                  <P>{amount}</P>
                  {/* we will only allow manual editing of Square Virtual Terminal invoices, 
                  as refunds for these won't be recorded automatically */}
                  {attributes.paymentProvider === 'square' &&
                    !attributes?.paymentProviderInvoiceId && (
                      <Button onClick={() => editPayment(invoice)}>edit</Button>
                    )}
                </Table.Row>
              )
            })}
          </Table>
        </>
      )}
      {['open', 'active'].includes(funeralPlan.attributes.status) && (
        <StyledFlexEndWrapper margin={['L', 0, 0]}>
          <div>
            <P strong>
              Total paid:{' '}
              {formatCurrency({
                showPence: true,
                value: funeralPlanPaymentDetails.attributes.totalPaidInPence,
                valueInPence: true,
              })}
            </P>
            <Button.Danger
              onClick={() =>
                handleCancelPlanModal({
                  amountToRefundInPence:
                    funeralPlanPaymentDetails.attributes.amountToRefundInPence,
                  cancellationFee:
                    funeralPlanPaymentDetails?.attributes.cancellationFee,
                })
              }
            >
              Cancel Funeral Plan
            </Button.Danger>
          </div>
        </StyledFlexEndWrapper>
      )}

      <Divider margin={['XL', 0]} />
      <Wrapper>
        <Table withHeaders>
          <H size="S">Quote</H>
          <Table.Row gridTemplateCols="1fr 2fr 0.5fr">
            <Table.Col strong>Description</Table.Col>
            <Table.Col strong>Units</Table.Col>
            <Table.Col strong>Amount</Table.Col>
          </Table.Row>
          <Table.Row gridTemplateCols="1fr 2fr 0.5fr">
            <Table.Col>Funeral plan price</Table.Col>
            <Table.Col>1</Table.Col>
            <Table.Col>
              {formatCurrency({
                showPence: true,
                value: funeralPlanPaymentDetails.attributes.packagePrice,
                valueInPence: true,
              })}
            </Table.Col>
          </Table.Row>
          {funeralPlanPaymentDetails.attributes.discountAmountInPence && (
            <Table.Row gridTemplateCols="1fr 2fr 0.5fr">
              <Table.Col>Discount</Table.Col>
              <Table.Col>1</Table.Col>
              <Table.Col>
                -
                {formatCurrency({
                  showPence: true,
                  value:
                    funeralPlanPaymentDetails.attributes.discountAmountInPence,
                  valueInPence: true,
                })}
              </Table.Col>
            </Table.Row>
          )}
          {funeralPlanPaymentDetails.attributes.type === 'Instalments' &&
            funeralPlanPaymentDetails.attributes.numberOfInstalmentsTotal && (
              <>
                <Table.Row gridTemplateCols="1fr 2fr 0.5fr">
                  <Table.Col>Instalments fee</Table.Col>
                  <Table.Col>1</Table.Col>
                  <Table.Col>
                    {formatCurrency({
                      showPence: true,
                      value:
                        funeralPlanPaymentDetails.attributes.instalmentsFee,
                      valueInPence: true,
                    })}
                  </Table.Col>
                </Table.Row>
                <Table.Row gridTemplateCols="1fr 2fr 0.5fr">
                  <Table.Col>Single Instalment</Table.Col>
                  <Table.Col>
                    {
                      funeralPlanPaymentDetails.attributes
                        .numberOfInstalmentsTotal
                    }
                  </Table.Col>
                  <Table.Col>
                    {formatCurrency({
                      showPence: true,
                      value:
                        funeralPlanPaymentDetails.attributes
                          .monthlyPriceInPence,
                      valueInPence: true,
                    })}
                  </Table.Col>
                </Table.Row>
              </>
            )}
        </Table>
        {/* We can only take balance payments from customers set up with Square */}
        <StyledFlexEndWrapper margin={['L', 0, 0]}>
          {!['Paid', 'Refunded'].includes(
            funeralPlanPaymentDetails.attributes.status
          ) &&
            funeralPlanPaymentDetails.attributes.squareCustomerId && (
              <Button.Primary
                onClick={() =>
                  handlePayBalanceModal({
                    reference: `${funeralPlan.attributes.reference}-BALANCE`,
                    amountInPence:
                      funeralPlanPaymentDetails.attributes.leftToPayInPence -
                      (funeralPlanPaymentDetails?.attributes.instalmentsFee ||
                        0),
                  })
                }
              >
                Pay balance now
              </Button.Primary>
            )}
        </StyledFlexEndWrapper>
      </Wrapper>
    </Wrapper>
  )
}

export default Payment
