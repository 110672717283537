import get from 'lodash/get'

const initialState = {
  default: { visible: false },
  callForm: { visible: false },
}

export default function modal(state = initialState, action = {}) {
  switch (action.type) {
    case 'SHOW_MODAL': {
      const type = get(action, 'payload.type', 'default')

      return { ...state, [type]: { visible: true, ...action.payload } }
    }

    case 'HIDE_MODAL': {
      const type = get(action, 'payload.type', 'default')

      return { ...state, [type]: { visible: false } }
    }

    default:
      return state
  }
}
