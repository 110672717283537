import { Button, ButtonGroup, P, Wrapper } from '@farewill/ui'
import { useDispatch } from 'react-redux'
import { Formik, Form } from 'formik'

import { hideModal, updateLpaCase } from 'state/actions'
import { LPA_CASE_STATUS_OPTIONS } from 'utils/enums'
import CircularRadioGroup from 'components/form/circular-radio-group'
import Input from 'components/form/input'
import { useFetchResource, RESOURCES } from 'api'
import { LpaCase, LpaCaseAttributes } from 'lib/models/lpa-case'

type FormFields = Pick<
  LpaCaseAttributes,
  'caseStatus' | 'caseLostReason' | 'caseDeclinedReason'
>

const getInitialValues = (lpaCaseAttributes: LpaCaseAttributes): FormFields => {
  const caseLost = lpaCaseAttributes.caseLostAt ? 'caseLostAt' : ''
  const caseDeclined = lpaCaseAttributes.caseDeclinedAt ? 'caseDeclinedAt' : ''
  return {
    caseStatus: caseLost || caseDeclined,
    caseLostReason: lpaCaseAttributes.caseLostReason,
    caseDeclinedReason: lpaCaseAttributes.caseDeclinedReason,
  }
}

const validate = ({
  caseStatus,
  caseLostReason,
  caseDeclinedReason,
}: FormFields) => {
  const errors = {} as FormFields

  if (caseStatus === 'caseLostAt' && !caseLostReason) {
    errors.caseLostReason = 'Must select a reason the case is lost'
  }

  if (caseStatus === 'caseDeclinedAt' && !caseDeclinedReason) {
    errors.caseDeclinedReason = 'Must select a reason the case is declined'
  }

  return errors
}

const Modal = ({
  config: { lpaCaseId },
}: {
  config: {
    lpaCaseId: string
  }
}) => {
  const dispatch = useDispatch()
  const lpaCaseQuery = useFetchResource<LpaCase>({
    id: parseInt(lpaCaseId),
    resource: RESOURCES.LPA_CASES,
  })

  if (lpaCaseQuery.isLoading || lpaCaseQuery.isIdle)
    return <P size="L">Loading...</P>

  if (!lpaCaseQuery.data) return <P size="L">There is no data to display.</P>

  const { attributes } = lpaCaseQuery.data

  const handleSubmit = async (values: FormFields) => {
    const patchData = {
      caseLostAt: null,
      caseLostReason: null,
      caseDeclinedAt: null,
      caseDeclinedReason: null,
    } as Pick<
      LpaCaseAttributes,
      'caseLostAt' | 'caseLostReason' | 'caseDeclinedAt' | 'caseDeclinedReason'
    >

    if (values.caseStatus === 'caseLostAt') {
      patchData.caseLostAt = new Date().toString()
      patchData.caseLostReason = values.caseLostReason
    }

    if (values.caseStatus === 'caseDeclinedAt') {
      patchData.caseDeclinedAt = new Date().toString()
      patchData.caseDeclinedReason = values.caseDeclinedReason
    }

    await dispatch(updateLpaCase(lpaCaseId, patchData))
    return dispatch(hideModal())
  }

  return (
    <Formik
      initialValues={getInitialValues(attributes)}
      onSubmit={handleSubmit}
      validate={validate}
    >
      {({ isSubmitting, values }) => (
        <Form>
          <Wrapper margin={[0, 0, 'M']}>
            <CircularRadioGroup
              name="caseStatus"
              options={[
                { value: '', label: 'Case active' },
                { value: 'caseLostAt', label: 'Case lost' },
                { value: 'caseDeclinedAt', label: 'Case declined' },
              ]}
            />
          </Wrapper>
          {values.caseStatus === 'caseLostAt' && (
            <Wrapper margin={['S', 0]}>
              <Input
                label="Reason case lost"
                name="caseLostReason"
                component="select"
                options={LPA_CASE_STATUS_OPTIONS.LOST}
              />
            </Wrapper>
          )}
          {values.caseStatus === 'caseDeclinedAt' && (
            <Wrapper margin={['S', 0]}>
              <Input
                label="Reason case declined"
                name="caseDeclinedReason"
                component="select"
                options={LPA_CASE_STATUS_OPTIONS.DECLINED}
              />
            </Wrapper>
          )}

          <ButtonGroup gap="S">
            <Button.Primary type="submit" loading={isSubmitting}>
              Save
            </Button.Primary>
            <Button.Underline onClick={() => dispatch(hideModal())}>
              Cancel
            </Button.Underline>
          </ButtonGroup>
        </Form>
      )}
    </Formik>
  )
}

export default Modal
