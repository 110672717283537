import {
  createContext,
  useCallback,
  useContext,
  useState,
  useEffect,
} from 'react'

const useFocusManager = () => {
  const [currentlyFocused, setCurrentlyFocused] = useState(null)

  const createHandlers = (id) => {
    const onFocus = () => {
      setCurrentlyFocused(id)
    }

    const onBlur = () => {
      if (currentlyFocused === id) {
        setCurrentlyFocused(null)
      }
    }

    const onMouseEnter = () => {
      setCurrentlyFocused(id)
    }

    const onMouseLeave = () => {
      if (currentlyFocused === id) {
        setCurrentlyFocused(null)
      }
    }

    return { onBlur, onFocus, onMouseEnter, onMouseLeave }
  }

  return {
    currentlyFocused,
    createHandlers,
  }
}

export const useKeys = (
  keys,
  handler,
  { eventName = 'keypress', enabled = true } = {}
) => {
  const serialisedKeys = keys.join(KeySeparatorToken)

  useEffect(() => {
    if (enabled === false) {
      return
    }

    const individualKeys = serialisedKeys.split(KeySeparatorToken)

    const handlePress = (event) => {
      if (individualKeys.includes(event.key)) {
        handler(event.key)
      }
    }

    window.addEventListener(eventName, handlePress)

    return () => {
      window.removeEventListener(eventName, handlePress)
    }
  }, [serialisedKeys, handler, enabled, eventName])
}

const KeyboardShortcutContext = createContext({})

const noop = () => {}
const KeySeparatorToken = '@@'

export const KeyboardShortcutProvider = ({ children, enabled }) => {
  const { currentlyFocused, createHandlers } = useFocusManager()
  const [shortcuts, setShortcuts] = useState({})

  const handleShortcut = useCallback(
    (key) => {
      const handlers = shortcuts[key]?.[currentlyFocused]

      if (handlers) {
        handlers.forEach((handler) => handler(key))
      }
    },
    [currentlyFocused, shortcuts]
  )

  useKeys(Object.keys(shortcuts), handleShortcut)

  const registerShortcut = useCallback(
    (key, id, handler) => {
      const exists = shortcuts[key]?.[id]?.includes(handler)
      if (exists) {
        return
      }

      setShortcuts((previous) => {
        const updated = { ...previous }
        if (updated[key] == null) {
          updated[key] = {
            [id]: [handler],
          }
        } else if (updated[key][id] == null) {
          updated[key][id] = [handler]
        } else {
          updated[key][id] = [...updated[key][id], handler]
        }

        return updated
      })
    },
    [shortcuts]
  )

  const unregisterShortcut = useCallback(
    (key, id, handler) => {
      const exists = shortcuts[key]?.[id]?.includes(handler)
      if (!exists) {
        return
      }

      setShortcuts((previous) => {
        const updated = {
          ...previous,
          [key]: {
            ...previous[key],
            [id]: previous[key][id].filter((h) => h !== handler),
          },
        }

        return updated
      })
    },
    [shortcuts]
  )

  const values = enabled
    ? { createHandlers, registerShortcut, unregisterShortcut }
    : { createHandlers: noop, registerShortcut: noop, unregisterShortcut: noop }

  return (
    <KeyboardShortcutContext.Provider value={values}>
      {children}
    </KeyboardShortcutContext.Provider>
  )
}

export const useKeyboardShortcutContext = () => {
  return useContext(KeyboardShortcutContext)
}
