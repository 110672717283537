import { P } from '@farewill/ui'
import SelectInput from 'components/form/select-input'
import { SelectOptions } from 'lib/types/common'
import { AdminUserSelectOptions } from 'routes/case-list/types'
import { FUNERAL_SERVICE_TYPES } from 'utils/enums'
import CaseFeatureFilters from '../shared/case-feature-filters'
import FilterWrapper from '../shared/filter-wrapper'

type Props = {
  product: string
  isMyCasesView: boolean
  adminUserOptions: AdminUserSelectOptions
  statusOptions: SelectOptions
  nextStageOptions: SelectOptions
}

const FuneralFilters = ({
  product,
  isMyCasesView,
  adminUserOptions,
  statusOptions,
  nextStageOptions,
}: Props): React.ReactElement => (
  <>
    {!isMyCasesView && (
      <FilterWrapper>
        <P strong margin={[0, 0, 'XS']} size="S">
          Case owner
        </P>
        <SelectInput
          options={adminUserOptions}
          name="caseOwnerId"
          isMulti
          small
        />
      </FilterWrapper>
    )}
    <FilterWrapper>
      <P strong margin={[0, 0, 'XS']} size="S">
        Service type
      </P>
      <SelectInput
        options={FUNERAL_SERVICE_TYPES}
        name="serviceType"
        isMulti
        small
      />
    </FilterWrapper>

    {statusOptions && (
      <FilterWrapper>
        <P size="S" strong>
          Case status
        </P>
        <SelectInput options={statusOptions} name="status" isMulti small />
      </FilterWrapper>
    )}

    <FilterWrapper>
      <CaseFeatureFilters product={product} />
    </FilterWrapper>

    {nextStageOptions && (
      <FilterWrapper>
        <P strong margin={[0, 0, 'XS']} size="S">
          Current stage
        </P>

        <SelectInput
          name="nextStage"
          isMulti
          options={nextStageOptions}
          small
        />
      </FilterWrapper>
    )}
  </>
)

export default FuneralFilters
