import { P } from '@farewill/ui'
import { OrganisationAttributes } from 'lib/models/organisation'

type Props = { organisation: OrganisationAttributes }

const OrganisationDetails = ({ organisation }: Props): React.ReactElement => (
  <>
    <P strong margin="0">
      {organisation.name}
    </P>
    <P size="S" margin="0">
      {organisation.address || <i>Registered address not added</i>}
    </P>
    <P size="S" margin="0">
      {organisation.number || <i>Registration number not added</i>}
    </P>
    {organisation.notes && (
      <P size="S" margin={['XS', 0, 0]}>
        {organisation.notes}
      </P>
    )}
  </>
)

export default OrganisationDetails
