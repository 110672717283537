import { Button, Grid, H, P } from '@farewill/ui'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'

import { RESOURCES, useFetchResource } from 'api'
import { showModal } from 'state/actions'
import { LPA_CASE_STATUS_OPTIONS } from 'utils/enums'
import { LpaCase } from 'lib/models/lpa-case'

import LostOrDeclinedModal from './lost-or-declined-modal'

const getReasonText = (reason?: string) => {
  return reason ? `for reason: ${reason}` : ''
}

const getLabel = (
  options: (typeof LPA_CASE_STATUS_OPTIONS)[keyof typeof LPA_CASE_STATUS_OPTIONS],
  value: string | null
) => {
  return options.find((option) => option.value === value)?.label
}

const getButtonText = ({
  caseLostReason,
  caseDeclinedReason,
}: {
  caseLostReason: string | null
  caseDeclinedReason: string | null
}) => {
  if (caseLostReason) {
    const reason = getLabel(LPA_CASE_STATUS_OPTIONS.LOST, caseLostReason)
    return `Case lost ${getReasonText(reason)}`
  }

  if (caseDeclinedReason) {
    const reason = getLabel(
      LPA_CASE_STATUS_OPTIONS.DECLINED,
      caseDeclinedReason
    )
    return `Case declined ${getReasonText(reason)}`
  }

  return 'Mark as lost or declined'
}

const LostOrDeclined = () => {
  const { id: lpaCaseId } = useParams<{ id: string }>()
  const dispatch = useDispatch()
  const lpaCaseQuery = useFetchResource<LpaCase>({
    id: parseInt(lpaCaseId),
    resource: RESOURCES.LPA_CASES,
  })

  if (lpaCaseQuery.isLoading || lpaCaseQuery.isIdle)
    return <P size="L">Loading...</P>

  if (!lpaCaseQuery.data) return <P size="L">There is no data to display.</P>

  const { attributes } = lpaCaseQuery.data

  const caseLostReason = attributes.caseLostAt && attributes.caseLostReason
  const caseDeclinedReason =
    attributes.caseDeclinedAt && attributes.caseDeclinedReason

  return (
    <>
      <Grid.Item>
        <H size="S">Lost / declined</H>
      </Grid.Item>
      <Grid.Item margin={['M', 0]}>
        <Button.Underline
          flush
          type="button"
          onClick={() =>
            dispatch(
              showModal({
                component: LostOrDeclinedModal,
                headingText: 'Mark as lost or declined',
                config: { lpaCaseId },
              })
            )
          }
        >
          {getButtonText({ caseLostReason, caseDeclinedReason })}
        </Button.Underline>
      </Grid.Item>
    </>
  )
}

export default LostOrDeclined
