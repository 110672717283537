import { useState } from 'react'
import { useFormikContext } from 'formik'
import every from 'lodash/every'
import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'
import pick from 'lodash/pick'
import styled from 'styled-components'
import { Grid, H, Divider, P } from '@farewill/ui'
import { screenMax } from '@farewill/ui/helpers/responsive'

import InputFloating from 'components/form/input-floating'
import Checkbox from 'components/form/checkbox'
import { strToBool } from 'utils/helpers'
import CircularRadioGroup from 'components/form/circular-radio-group'
import AddressInput from 'components/form/address-input'
import MessageBox from 'components/message-box'
import axiosRequest from 'lib/axios/api-request'
import { use2024Form1 as use2024Form1Context } from 'routes/funerals/use2024Form1'

const getDoctorForComparison = (values, doctorPrefix) => {
  return {
    name: values[`${doctorPrefix}DoctorName`],
    telephone: values[`${doctorPrefix}DoctorTelephone`],
    address: pick(values[`${doctorPrefix}DoctorAddress`], [
      'lineOne',
      'lineTwo',
      'city',
      'postalCode',
      'countryCode',
    ]),
  }
}

const StyledSpacer = styled(Grid.Item)`
  ${screenMax.m`
    display: none;
  `}
`

const areDoctorsTheSame = (values, doctorPrefix, otherDoctorPrefix) => {
  const doctor = getDoctorForComparison(values, doctorPrefix)
  const otherDoctor = getDoctorForComparison(values, otherDoctorPrefix)

  return isEqual(doctor, otherDoctor)
}

const isDoctorBlank = (values, doctorPrefix) => {
  const doctor = getDoctorForComparison(values, doctorPrefix)
  const isAddressBlank = every(Object.values(doctor.address), isEmpty)

  return isEmpty(doctor.name) && isEmpty(doctor.telephone) && isAddressBlank
}

const DoctorDetailsForm = ({
  prefix,
  values,
  handleSaveField,
  handleSaveAddress,
}) => {
  const onAddressChanged = (attributes) => {
    handleSaveAddress({
      name: `${prefix}DoctorAddress`,
      value: attributes,
    })
  }

  return (
    <Grid>
      <Grid.Item>
        <Grid>
          <Grid.Item span={6}>
            <InputFloating
              name={`${prefix}DoctorName`}
              label="Name"
              handleSave={handleSaveField}
            />
          </Grid.Item>
          <Grid.Item>
            <AddressInput
              label="Address"
              name={`${prefix}DoctorAddress`}
              addressValues={values[`${prefix}DoctorAddress`]}
              onAddressChanged={onAddressChanged}
            />
          </Grid.Item>
        </Grid>
      </Grid.Item>
      <Grid.Item span={6}>
        <InputFloating
          name={`${prefix}DoctorTelephone`}
          label="Telephone"
          handleSave={handleSaveField}
        />
      </Grid.Item>
    </Grid>
  )
}

const UsualDoctor = ({
  values,
  handleSaveDoctorField,
  handleSaveDoctorAddress,
}) => {
  return (
    <>
      <Grid.Item>
        <H size="S">4.1 The usual doctor of the person who has died</H>
      </Grid.Item>
      <Grid.Item>
        <DoctorDetailsForm
          prefix="usual"
          values={values}
          handleSaveField={handleSaveDoctorField}
          handleSaveAddress={handleSaveDoctorAddress}
        />
      </Grid.Item>
      <Grid.Item>
        <Divider />
      </Grid.Item>
    </>
  )
}

const AttendingDoctor = ({
  isAttendingDoctorTheirUsualDoctor,
  handleChangeIsAttendingDoctorTheirUsualDoctor,
  values,
  handleSaveDoctorField,
  handleSaveDoctorAddress,
}) => (
  <>
    <Grid.Item>
      <H size="S">4.2 The doctor who attended during their final illness</H>
    </Grid.Item>
    <Grid.Item>
      <Checkbox
        name="isAttendingDoctorTheirUsualDoctor"
        label="Copy details of their usual doctor"
        checked={isAttendingDoctorTheirUsualDoctor}
        handleSave={handleChangeIsAttendingDoctorTheirUsualDoctor}
      />
    </Grid.Item>
    {!isAttendingDoctorTheirUsualDoctor && (
      <Grid.Item>
        <DoctorDetailsForm
          prefix="attending"
          values={values}
          handleSaveField={handleSaveDoctorField}
          handleSaveAddress={handleSaveDoctorAddress}
        />
      </Grid.Item>
    )}
    <Grid.Item>
      <Divider />
    </Grid.Item>
  </>
)

const SecondAttendingDoctor = ({
  hasSecondAttendingDoctor,
  handleSave,
  values,
  handleSaveDoctorField,
  handleSaveDoctorAddress,
}) => (
  <>
    <Grid.Item>
      <H size="S">4.3 Second doctor to attend</H>
    </Grid.Item>
    <Grid.Item>
      <Checkbox
        name="hasSecondAttendingDoctor"
        label="A second doctor attended during their final illness"
        checked={hasSecondAttendingDoctor}
        handleSave={handleSave}
      />
    </Grid.Item>
    {hasSecondAttendingDoctor && (
      <Grid.Item>
        <DoctorDetailsForm
          prefix="secondAttending"
          values={values}
          handleSaveField={handleSaveDoctorField}
          handleSaveAddress={handleSaveDoctorAddress}
        />
      </Grid.Item>
    )}
    <Grid.Item>
      <Divider />
    </Grid.Item>
  </>
)

const Pacemaker = ({ handleSave, values }) => {
  const {
    deathCountry,
    hasHazardousImplant,
    isHazardousImplantRemoved,
    hasExplosiveImplant,
    hasOtherHazardousImplant,
  } = values
  const use2024Form1 = use2024Form1Context({ dateOfDeath: values.dateOfDeath })

  if (use2024Form1 === undefined) {
    return null
  }

  const title = deathCountry?.name === 'Scotland' ? 'Hazards' : 'Pacemaker'

  return (
    <>
      <Grid.Item>
        <H size="S">
          {use2024Form1 ? '4.2' : '4.4'} {title}
        </H>
      </Grid.Item>
      {deathCountry?.name !== 'Scotland' && (
        <>
          <Grid.Item>
            <P>Do they have a pacemaker or other hazardous implant?</P>
            <P size="S">
              Something which could be hazardous during the funeral. For
              example, a pacemaker, radioactive device, battery powered device
              or "Fixion" intramedullary nailing system.
            </P>
          </Grid.Item>
          <Grid.Item>
            <CircularRadioGroup
              name="hasHazardousImplant"
              options={[
                { label: 'Not asked', value: '' },
                { label: 'No', value: 'no' },
                { label: 'Yes', value: 'yes' },
                { label: "The customer doesn't know", value: 'not_sure' },
              ]}
              handleSave={handleSave}
              inline
            />
          </Grid.Item>
          {use2024Form1 && hasHazardousImplant === 'yes' && (
            <Grid.Item>
              <P>Has the implant already been removed?</P>
              <CircularRadioGroup
                name="isHazardousImplantRemoved"
                options={[
                  { label: 'No', value: 'no' },
                  { label: 'Yes', value: 'yes' },
                  { label: "The customer doesn't know", value: 'not_sure' },
                ]}
                handleSave={handleSave}
                inline
              />
            </Grid.Item>
          )}
        </>
      )}
      {deathCountry?.name === 'Scotland' && (
        <>
          <Grid.Item>
            <P>
              Does the body pose a risk to public health: for example did the
              deceased have a notifiable infectious disease or was their body
              contaminated immediately before death?
            </P>
            <CircularRadioGroup
              name="isBodyRiskToPublicHealth"
              options={[
                { label: 'Not asked', value: '' },
                { label: 'No', value: 'false' },
                { label: 'Yes', value: 'true' },
              ]}
              handleSave={handleSave}
              inline
            />
          </Grid.Item>
          <Grid.Item>
            <P>
              Is there a pacemaker or any other potentially explosive device
              currently present in or on the body?
            </P>
            <CircularRadioGroup
              name="hasExplosiveImplant"
              options={[
                { label: 'Not asked', value: '' },
                { label: 'No', value: 'false' },
                { label: 'Yes', value: 'true' },
              ]}
              handleSave={handleSave}
              inline
            />
          </Grid.Item>
          <Grid.Item>
            <P>
              Is there radioactive material or any other hazardous implant
              currently present in or on the body?
            </P>
            <CircularRadioGroup
              name="hasOtherHazardousImplant"
              options={[
                { label: 'Not asked', value: '' },
                { label: 'No', value: 'false' },
                { label: 'Yes', value: 'true' },
              ]}
              handleSave={handleSave}
              inline
            />
          </Grid.Item>
        </>
      )}
      {((hasHazardousImplant === 'yes' &&
        (use2024Form1
          ? isHazardousImplantRemoved === 'no' ||
            isHazardousImplantRemoved === 'not_sure'
          : true)) ||
        [hasExplosiveImplant, hasOtherHazardousImplant].includes('true')) && (
        <Grid.Item>
          <InputFloating
            name="hazardousImplantNotes"
            label={
              deathCountry?.name === 'Scotland'
                ? 'Description of implant and/or radioactive material'
                : use2024Form1
                ? 'Details of the device and its location'
                : 'Description of implant'
            }
            component="textarea"
            rows={4}
            handleSave={handleSave}
          />
        </Grid.Item>
      )}
    </>
  )
}

const DoctorsAndMedical = ({ fetchFuneral, handleSave }) => {
  const { values } = useFormikContext()
  const use2024Form1 = use2024Form1Context({ dateOfDeath: values.dateOfDeath })

  const [
    isAttendingDoctorTheirUsualDoctor,
    setIsAttendingDoctorTheirUsualDoctor,
  ] = useState()

  const hasSecondAttendingDoctor = strToBool(values.hasSecondAttendingDoctor)

  const updateAddress = (id, attributes) => {
    return axiosRequest({
      url: `/api/addresses/${id}`,
      method: 'PATCH',
      data: {
        data: {
          type: 'addresses',
          id,
          attributes,
        },
      },
    })
  }

  const handleChangeIsAttendingDoctorTheirUsualDoctor = ({ value }) => {
    setIsAttendingDoctorTheirUsualDoctor(strToBool(value))

    if (strToBool(value)) {
      handleSave({
        name: 'attendingDoctorName',
        value: values.usualDoctorName,
      })
      handleSave({
        name: 'attendingDoctorTelephone',
        value: values.usualDoctorTelephone,
      })
      handleSaveDoctorAddress({
        name: 'attendingDoctorAddress',
        value: values.usualDoctorAddress,
      })
    } else {
      fetchFuneral()
    }
  }

  const handleSaveDoctorField = ({ name, value }) => {
    handleSave({ name, value })

    if (isAttendingDoctorTheirUsualDoctor && name.startsWith('usualDoctor')) {
      handleSave({
        name: name.replace('usual', 'attending'),
        value,
      })
    }
  }

  const handleSaveDoctorAddress = ({ name, value }) => {
    updateAddress(values[name].id, value)

    if (isAttendingDoctorTheirUsualDoctor && name.startsWith('usualDoctor')) {
      updateAddress(values.attendingDoctorAddress.id, value)
    }
  }

  if (isAttendingDoctorTheirUsualDoctor === undefined && values.reference) {
    const isUsualDoctorBlank = isDoctorBlank(values, 'usual')
    const isUsualAndAttendingDoctorTheSame = areDoctorsTheSame(
      values,
      'usual',
      'attending'
    )

    setIsAttendingDoctorTheirUsualDoctor(
      !isUsualDoctorBlank && isUsualAndAttendingDoctorTheSame
    )
  }

  return (
    <Grid>
      <Grid.Item>
        <H size="M">4. Doctors and medical</H>
      </Grid.Item>
      {use2024Form1 === undefined && (
        <Grid.Item>
          <MessageBox warning>
            The details collected in this section depend on which version of
            Form 1 paperwork to use. Select the <strong>date of death</strong>{' '}
            for this funeral so we can show the relevant questions.
          </MessageBox>
        </Grid.Item>
      )}
      {use2024Form1 === true && (
        <>
          <Grid.Item>
            <H size="S">4.1 The doctor who attended the deceased person</H>
          </Grid.Item>
          <Grid.Item>
            <Grid>
              <Grid.Item>
                <Grid>
                  <Grid.Item span={6}>
                    <InputFloating
                      name="attendingDoctorName"
                      label="Full name"
                      handleSave={handleSave}
                    />
                  </Grid.Item>
                  <Grid.Item>
                    <AddressInput
                      label="Address"
                      name="attendingDoctorAddress"
                      addressValues={values.attendingDoctorAddress}
                      onAddressChanged={(attributes) => {
                        updateAddress(
                          values.attendingDoctorAddress.id,
                          attributes
                        )
                      }}
                    />
                  </Grid.Item>
                </Grid>
              </Grid.Item>
              <Grid.Item spanFromL={6}>
                <InputFloating
                  name="attendingDoctorTelephone"
                  label="Phone number"
                  handleSave={handleSave}
                />
              </Grid.Item>
              <StyledSpacer spanFromL={6} />
              <Grid.Item spanFromL={6}>
                <InputFloating
                  name="attendingDoctorEmail"
                  label="Email"
                  handleSave={handleSave}
                />
              </Grid.Item>
            </Grid>
          </Grid.Item>
          <Grid.Item>
            <Divider />
          </Grid.Item>
        </>
      )}
      {use2024Form1 === false && (
        <>
          <UsualDoctor
            values={values}
            handleSaveDoctorField={handleSaveDoctorField}
            handleSaveDoctorAddress={handleSaveDoctorAddress}
          />
          <AttendingDoctor
            values={values}
            handleSaveDoctorField={handleSaveDoctorField}
            handleSaveDoctorAddress={handleSaveDoctorAddress}
            isAttendingDoctorTheirUsualDoctor={
              isAttendingDoctorTheirUsualDoctor
            }
            handleChangeIsAttendingDoctorTheirUsualDoctor={
              handleChangeIsAttendingDoctorTheirUsualDoctor
            }
          />
          <SecondAttendingDoctor
            values={values}
            hasSecondAttendingDoctor={hasSecondAttendingDoctor}
            handleSaveDoctorField={handleSaveDoctorField}
            handleSaveDoctorAddress={handleSaveDoctorAddress}
            handleSave={handleSave}
          />
        </>
      )}
      <Pacemaker values={values} handleSave={handleSave} />
    </Grid>
  )
}

export default DoctorsAndMedical
