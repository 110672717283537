import mapValues from 'lodash/mapValues'
import { strToBool } from 'utils/helpers'
import { UseSectionsCompleteProps } from '.'

export const checkIsIsProbateNeededComplete = ({
  values,
}: UseSectionsCompleteProps) => {
  const {
    customerAlreadyKnowsProbateRequired,
    aboveAccountOrShareThreshold,
    ownedProperty,
    propertyOwnershipNeedsProbate,
  } = mapValues(values.quoteAttributes, strToBool)

  if (
    customerAlreadyKnowsProbateRequired ||
    aboveAccountOrShareThreshold ||
    ownedProperty === false
  ) {
    return true
  }

  return ownedProperty && propertyOwnershipNeedsProbate !== undefined
}
