import { Grid, P } from '@farewill/ui'

import SectionStatus from 'components/section-status'
import { OUTCOME_HEADING } from './constants'

const CantHelp = ({
  isCharity,
  reason,
}: {
  isCharity: boolean
  reason: string
}) => (
  <Grid margin={['L', 0]}>
    <Grid.Item>
      <SectionStatus complete heading={OUTCOME_HEADING.CANNOT_HELP}>
        <P>
          {' '}
          {isCharity
            ? 'Please refer this person back to the charity, because '
            : 'Please refer the customer to STEP, because '}
          {reason}.
        </P>
      </SectionStatus>
    </Grid.Item>
  </Grid>
)

export default CantHelp
