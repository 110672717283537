import { memo } from 'react'
import { useQueryClient } from 'react-query'

const AdminUser = ({ userId }) => {
  const cache = useQueryClient()
  const adminUsers = cache.getQueryData('adminUsers')

  const user = adminUsers.find((user) => user.id === userId)

  return user?.attributes.name || 'Unassigned'
}

export default memo(AdminUser)
