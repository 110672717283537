import {
  useCallback,
  useEffect,
  useState,
  useRef,
  Dispatch,
  SetStateAction,
} from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import {
  ArrowRightIcon,
  ArrowUpIcon,
  Grid,
  P,
  PencilIcon,
  PhoneMissedSolidIcon,
  PhoneSolidIcon,
  Wrapper,
} from '@farewill/ui'
import { screenMin } from '@farewill/ui/helpers/responsive'
import { COLOR, FONT, GTR } from '@farewill/ui/tokens'
import moment from 'moment'
import { Formik } from 'formik'
import { isEmpty, isArray, isPlainObject } from 'lodash'
import { getUserIdFromToken } from 'lib/authentication/token'
import styled from 'styled-components'

import {
  getFullNameWithPreferredName,
  strToBool,
  dateIsToday,
  formatToHuman,
  truncateText,
  formatProductName,
  getTimeDescriptionFromTimestamp,
} from 'utils/helpers'

import { store, getStore } from 'lib/storage/sessionStorage'
import { LEAD_EVENT_TYPES } from 'utils/enums'
import { useFetchArrayResource, RESOURCES } from 'api'

import StyledGridRow from 'components/list/grid-row'
import StyledLink from 'components/list/link'
import NoResults from 'components/list/no-results'
import ErrorResults from 'components/list/error-results'
import StyledLoadingRow from 'components/list/loading-row'
import DateBadge from 'components/date-badge'
import PageButtons from 'components/list/page-buttons'
import Tooltip from 'components/tooltip'
import StyledSortableHeader from 'components/list/sortable-header'
import SortArrow from 'components/sort-arrow'

import FilterPanel from './filter-panel'
import ConversionScoreIcon from 'components/conversion-score-icon'
import OwnerAssignment from './owner-assignment'
import { LeadEventAttributes } from 'lib/models/lead-event'
import useLocalStorage from 'hooks/useLocalStorage'
import { Lead } from 'lib/models/lead'

const LastActivityIcon = ({ type }: { type: string }) => {
  switch (type) {
    case 'added':
      return <ArrowUpIcon size="S" />
    case 'call_made_connected':
      return <PhoneSolidIcon size="S" />
    case 'call_made_no_answer':
      return <PhoneMissedSolidIcon size="S" />
    case 'comment_added':
      return <PencilIcon size="S" />
    default:
      return null
  }
}

const StyledGridColumn = styled.li`
  font-size: ${FONT.SIZE.S};
  display: inline-flex;
`
const StyledActivityWrapper = styled(Wrapper)`
  display: flex;
`

const StyledIconWrapper = styled(Wrapper)`
  display: flex;
  align-items: center;
  color: ${COLOR.GREY.MEDIUM};
`

const LastActivityTime = styled.span`
  margin-left: ${GTR.XS};
`

const StyledTooltip = styled(Tooltip)`
  padding: ${GTR.XXS};
  background-color: ${COLOR.GREY.DARK};
`

const StyledTooltipP = styled(P)`
  margin: 0;
  color: ${COLOR.WHITE};
  font-size: ${FONT.SIZE.S};
`

const StyledSpan = styled.span`
  font-weight: ${FONT.WEIGHT.BOLD};
`

const StyledP = styled(P)`
  text-align: right;
`

const StyledLeadsListHeader = styled(Wrapper)`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`

const StyledCallCountSummary = styled(Wrapper)`
  display: flex;
  flex-direction: row;
  gap: ${GTR.M};

  ${screenMin.s`
    justify-content: flex-end;
  `}
`

const GRID_TEMPLATE_COLUMNS = '1.1fr 1fr 0.75fr 0.75fr 1fr 0.5fr 1fr 30px'

const PAGE_SIZE = 30

const SORTABLE_COLUMNS = {
  'Call scheduled': { sortColumn: 'scheduledNextCallAt', reverseNulls: 'true' },
  'Last activity': {
    sortColumn: 'lastLeadEventHappenedAt',
    reverseNulls: 'false',
  },
  Score: {
    sortColumn: 'conversionScore',
    reverseNulls: 'false',
  },
  Stage: {
    sortColumn: 'stageNumber',
    reverseNulls: 'false',
  },
} as const

const formatLastActivityTime = (date: string) => {
  if (dateIsToday({ date })) return moment(date).format('HH:mm')
  const timeAgo = moment(date).from(moment().startOf('day'))
  return timeAgo === 'a day ago' ? '1 day ago' : timeAgo
}

const getLastLeadEventIconType = (lastLeadEvent: LeadEventAttributes) => {
  return lastLeadEvent.type === LEAD_EVENT_TYPES.CALL_MADE
    ? `${lastLeadEvent.type}_${lastLeadEvent.metadata.disposition}`
    : lastLeadEvent.type
}

const SortableColumn = ({
  column,
  sort,
  setSort,
}: {
  column: keyof typeof SORTABLE_COLUMNS
  sort: SortType
  setSort: Dispatch<SetStateAction<SortType>>
}) => {
  const sorted = SORTABLE_COLUMNS[column].sortColumn === sort.sortColumn
  const sortOrder = sorted && sort.sortOrder === 'asc' ? 'desc' : 'asc'

  return (
    <StyledSortableHeader
      onClick={() => setSort({ ...SORTABLE_COLUMNS[column], sortOrder })}
    >
      {column}
      <SortArrow sorted={sorted} asc={sortOrder === 'asc'} />
    </StyledSortableHeader>
  )
}
const getValueFromParams = (
  urlParams: URLSearchParams,
  filter: string,
  initalValue: (typeof FILTERS)[keyof typeof FILTERS]
) => {
  const urlParamsValue = urlParams.get(`filter[${filter}]`)

  if (urlParamsValue === 'true' || urlParamsValue === 'false') {
    return strToBool(urlParamsValue)
  }
  if (Array.isArray(initalValue)) return urlParamsValue?.split(',')

  return urlParamsValue
}

const LeadGrid = ({
  leads = [],
  sort,
  setSort,
  isLoading,
  isError,
  loggedInAdminUserId,
  setEnabled,
}: {
  leads: Lead[]
  sort: SortType
  setSort: Dispatch<SetStateAction<SortType>>
  isLoading: boolean
  isError: boolean
  loggedInAdminUserId: number
  setEnabled: Dispatch<SetStateAction<boolean>>
}) => {
  if (isError) {
    return <ErrorResults type="leads" />
  }

  return (
    <>
      <StyledGridRow header gridTemplateColumns={GRID_TEMPLATE_COLUMNS}>
        <SortableColumn column="Call scheduled" sort={sort} setSort={setSort} />
        <StyledGridColumn>Customer</StyledGridColumn>
        <StyledGridColumn>Product</StyledGridColumn>
        <SortableColumn column="Stage" sort={sort} setSort={setSort} />
        <SortableColumn column="Last activity" sort={sort} setSort={setSort} />
        <SortableColumn column="Score" sort={sort} setSort={setSort} />
        <StyledGridColumn>Owner</StyledGridColumn>
        <StyledGridColumn />
      </StyledGridRow>

      {isLoading && <StyledLoadingRow>Loading...</StyledLoadingRow>}
      {!isLoading && leads.length === 0 && (
        <StyledLoadingRow>
          <NoResults type="leads" />
        </StyledLoadingRow>
      )}

      {leads.map((row) => (
        <LeadRow
          key={row.id}
          row={row}
          loggedInAdminUserId={loggedInAdminUserId}
          setEnabled={setEnabled}
        />
      ))}
    </>
  )
}

const LeadRow = ({
  row,
  loggedInAdminUserId,
  setEnabled,
}: {
  row: Lead
  loggedInAdminUserId: number
  setEnabled: Dispatch<SetStateAction<boolean>>
}) => {
  let lastLeadEventIconType

  const {
    lastLeadEvent,
    conversionScore,
    stageName,
    scheduledNextCallAtTimeSet,
    scheduledNextCallAt,
  } = row.attributes
  const contactName = getFullNameWithPreferredName(row.attributes.contact)
  const productName = formatProductName(row.attributes.product)

  if (lastLeadEvent) {
    lastLeadEventIconType = getLastLeadEventIconType(lastLeadEvent)
  }

  return (
    <StyledLink to={`/leads/${row.id}/history`}>
      <StyledGridRow gridTemplateColumns={GRID_TEMPLATE_COLUMNS}>
        <StyledGridColumn>
          <StyledTooltip
            enabled={scheduledNextCallAtTimeSet}
            underlined={false}
            content={<StyledTooltipP>Time selected</StyledTooltipP>}
          >
            <DateBadge
              date={getTimeDescriptionFromTimestamp(
                scheduledNextCallAt || undefined,
                scheduledNextCallAtTimeSet
              )}
            />
          </StyledTooltip>
        </StyledGridColumn>
        <StyledGridColumn>{contactName}</StyledGridColumn>
        <StyledGridColumn>{productName}</StyledGridColumn>
        <StyledGridColumn>{stageName}</StyledGridColumn>
        <StyledGridColumn>
          {lastLeadEvent && (
            <StyledTooltip
              maxWidth={300}
              underlined={false}
              content={
                <>
                  <StyledTooltipP strong>
                    {formatToHuman(lastLeadEvent.type)} by{' '}
                    {lastLeadEvent?.adminUser?.name}
                  </StyledTooltipP>
                  {lastLeadEvent.metadata.notes && (
                    <StyledTooltipP>
                      {truncateText(lastLeadEvent.metadata.notes as string, 75)}
                    </StyledTooltipP>
                  )}
                </>
              }
            >
              <StyledActivityWrapper>
                <StyledIconWrapper>
                  {lastLeadEventIconType && (
                    <LastActivityIcon type={lastLeadEventIconType} />
                  )}
                </StyledIconWrapper>
                <LastActivityTime>
                  {formatLastActivityTime(lastLeadEvent.happenedAt)}
                </LastActivityTime>
              </StyledActivityWrapper>
            </StyledTooltip>
          )}
        </StyledGridColumn>
        <StyledGridColumn>
          <ConversionScoreIcon conversionScore={conversionScore} />
        </StyledGridColumn>
        <StyledGridColumn>
          <OwnerAssignment
            lead={row}
            loggedInAdminUserId={loggedInAdminUserId}
            setEnabled={setEnabled}
          />
        </StyledGridColumn>
        <StyledGridColumn>
          <ArrowRightIcon size="S" />
        </StyledGridColumn>
      </StyledGridRow>
    </StyledLink>
  )
}

export const FILTERS = {
  adminUserIds: [],
  partnerId: '',
  product: [],
  sourceIdentifier: '',
  origin: '',
  status: '',
  unsuitableReason: '',
  blockedReason: '',
  callToday: false,
  missedLastCall: false,
  lastChance: false,
  scheduledNextCallAtTimeSet: false,
  lastActivityAt: {
    lte: '',
    gte: '',
    option: 'any',
  },
} as const

type Sort = {
  sortColumn: string
  reverseNulls: string
  sortOrder: string
}

type SortType = typeof SORT

export const SORT: Sort = {
  ...SORTABLE_COLUMNS['Call scheduled'],
  sortOrder: 'asc',
}

export type LeadListPage = {
  after?: string
  before?: string
  sortColumn?: string
  reverseNulls?: boolean
  sortOrder?: string
}

const LeadList = () => {
  const history = useHistory()
  const isFirstRender = useRef(true)
  const [page, setPage] = useState<LeadListPage>({})
  const [sort, setSort] = useState(SORT)
  const [filters, setFilters] = useState(FILTERS)
  const [searchParams, setSearchParams] = useState('')
  const [enabled, setEnabled] = useState(true)
  const [localStorageFilters, setLocalStorageFilters] = useLocalStorage<string>(
    'leadListFilters',
    ''
  )
  const [localStorageFiltersUsed] = useLocalStorage<string>(
    'leadListFiltersUsed',
    ''
  )

  const token = useSelector((state: { token: string }) => state.token)
  const loggedInAdminUserId = getUserIdFromToken(token)

  const leadsQuery = useFetchArrayResource<Lead>(
    {
      resource: RESOURCES.LEADS,
      params: searchParams,
    },
    {
      enabled,
      refetchInterval: 10000,
      keepPreviousData: true,
    }
  )

  const handleFilterSubmit = useCallback(
    (values) => {
      const urlSearchParams = new URLSearchParams()
      const apiSearchParams = new URLSearchParams({
        'page[size]': PAGE_SIZE.toString(),
      })

      Object.keys(FILTERS).forEach((filter) => {
        const value = values[filter]
        store(`leadList.filters.${filter}`, value)

        const filterHasValue = isArray(value) ? !isEmpty(value) : !!value

        if (filterHasValue && !isPlainObject(value)) {
          urlSearchParams.set(`filter[${filter}]`, value)
          apiSearchParams.set(`filter[${filter}]`, value)
        } else if (filterHasValue && isPlainObject(value)) {
          const { gte, lte, option } = values[filter]
          if (gte && lte) {
            urlSearchParams.set(`filter[${filter}][option]`, option)

            apiSearchParams.set(`filter[${filter}][gte]`, gte)
            apiSearchParams.set(`filter[${filter}][lte]`, lte)
          }
        }
      })

      Object.keys(SORT).forEach((param) => {
        const sortParam = param as keyof SortType
        const value = sort[sortParam]
        store(`leadList.page.${sortParam}`, value)
        urlSearchParams.set(`page[${sortParam}]`, value)
        apiSearchParams.set(`page[${sortParam}]`, value)
      })

      if (page.after) {
        apiSearchParams.set('page[after]', page.after)
      }

      if (page.before) {
        apiSearchParams.set('page[before]', page.before)
      }
      setLocalStorageFilters(apiSearchParams.toString())
      setSearchParams(apiSearchParams.toString())
      history.replace({ search: urlSearchParams.toString() })
    },
    [page.after, page.before, setLocalStorageFilters, history, sort]
  )

  useEffect(() => {
    if (isFirstRender.current) {
      const savedLocalStorageFilters =
        localStorageFiltersUsed === 'true'
          ? new URLSearchParams(localStorageFilters)
          : undefined

      const urlParams = new URLSearchParams(
        savedLocalStorageFilters || history.location.search
      )

      const filters = Object.entries(FILTERS).reduce(
        (acc, [filter, initalValue]) => {
          const valueFromParams = getValueFromParams(
            urlParams,
            filter,
            initalValue
          )

          const valueFromStore = getStore(`leadList.filters.${filter}`)

          return {
            ...acc,
            [filter]: valueFromParams || valueFromStore || initalValue,
          }
        },
        {}
      ) as typeof FILTERS

      const sort = Object.entries(SORT).reduce((acc, [param, initalValue]) => {
        const valueFromParams = urlParams.get(`page[${param}]`)
        const valueFromStore = getStore(`leadList.page.${param}`)

        return {
          ...acc,
          [param]: valueFromParams || valueFromStore || initalValue,
        }
      }, {}) as SortType

      setFilters(filters)
      setSort(sort)
      isFirstRender.current = false
    }
  }, [history.location.search, localStorageFilters, localStorageFiltersUsed])

  return (
    <Wrapper noTrim>
      <Grid
        gap="L"
        marginFromM={[0, 0, `-${GTR.XL}`]}
        marginFromL={[0, 0, `-${GTR.XXL}`]}
      >
        <Grid.Item span={3}>
          <Formik
            initialValues={filters}
            onSubmit={handleFilterSubmit}
            enableReinitialize
          >
            <FilterPanel
              sort={sort}
              page={page}
              setPage={setPage}
              loggedInAdminUserId={loggedInAdminUserId}
            />
          </Formik>
        </Grid.Item>
        <Grid.Item span={9} padding={['L', 0, 0]}>
          <StyledLeadsListHeader gap={0}>
            {leadsQuery?.data?.meta && (
              <P size="L" margin={[0, 0, 'M']} strong>
                {leadsQuery?.data?.meta.totalCount === 1
                  ? '1 lead'
                  : `${leadsQuery.data?.meta.totalCount} leads`}
              </P>
            )}
            {leadsQuery.data?.meta?.callCountSummary && (
              <StyledCallCountSummary margin={[0, 0, 'M']}>
                <StyledP size="S" strong>
                  Scheduled calls:
                </StyledP>
                <Tooltip
                  content="Leads with a scheduled call that are now in the past."
                  underlined={false}
                >
                  <StyledP size="S">
                    <StyledSpan>
                      {leadsQuery.data?.meta?.callCountSummary.overdueCount}{' '}
                    </StyledSpan>
                    overdue
                  </StyledP>
                </Tooltip>
                <Tooltip
                  content="Leads with a call scheduled for today that are in the future."
                  underlined={false}
                >
                  <StyledP size="S">
                    <StyledSpan>
                      {leadsQuery.data?.meta?.callCountSummary.todayCount}{' '}
                    </StyledSpan>
                    today
                  </StyledP>
                </Tooltip>
                <Tooltip
                  content="Leads with a call scheduled for tomorrow."
                  underlined={false}
                >
                  <StyledP size="S">
                    <StyledSpan>
                      {leadsQuery.data?.meta?.callCountSummary.tomorrowCount}{' '}
                    </StyledSpan>
                    tomorrow
                  </StyledP>
                </Tooltip>
                <Tooltip
                  content="Leads with a call scheduled after tomorrow."
                  underlined={false}
                >
                  <StyledP size="S">
                    <StyledSpan>
                      {leadsQuery.data?.meta.callCountSummary.inFutureCount}{' '}
                    </StyledSpan>
                    in the future
                  </StyledP>
                </Tooltip>
              </StyledCallCountSummary>
            )}
          </StyledLeadsListHeader>
          <LeadGrid
            leads={leadsQuery.data?.data || []}
            sort={sort}
            setSort={setSort}
            isLoading={leadsQuery.isLoading}
            isError={leadsQuery.isError}
            loggedInAdminUserId={loggedInAdminUserId}
            setEnabled={setEnabled}
          />
          <PageButtons
            disabled={leadsQuery.isPreviousData}
            cursors={leadsQuery.data?.meta?.cursors}
            setPage={setPage}
          />
        </Grid.Item>
      </Grid>
    </Wrapper>
  )
}

export default LeadList
