import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Grid, H } from '@farewill/ui'
import { GTR } from '@farewill/ui/tokens'

import { NOT_ASKED_AND_UNSURE_OPTIONS } from 'utils/enums'

import Tooltip from 'components/tooltip'
import CircularRadioGroup from 'components/form/circular-radio-group'
import NotesInput from 'components/notes-input'

import {
  PAID_INSURNCE_PREMIUMS_ON_POLICY_PAID_TO_SOMEONE_ELSE,
  INSURANCE_PREMIUM_NOTES,
} from './field-names'

const StyledLabel = styled.label`
  margin-bottom: ${GTR.S};
`

const TOOLTIP_TITLE =
  'It’s only ‘yes’ if all of the following are true: the policy is held in trust; it doesn’t pay out to their spouse; they bought an annuity; and the premiums aren’t covered by an annual exemption.'

const InsurancePremiums = ({ saveProbateEstateField }) => (
  <Grid data-scroll-id="6.4">
    <Grid.Item>
      <H size="S">6.4 Insurance premiums</H>
    </Grid.Item>
    <Grid.Item>
      <StyledLabel>
        Did they pay insurance premiums on{' '}
        <Tooltip content={TOOLTIP_TITLE}>
          a policy that pays out to someone else?
        </Tooltip>
      </StyledLabel>
      <CircularRadioGroup
        name={PAID_INSURNCE_PREMIUMS_ON_POLICY_PAID_TO_SOMEONE_ELSE}
        options={NOT_ASKED_AND_UNSURE_OPTIONS}
        handleSave={saveProbateEstateField}
        inline
      />
    </Grid.Item>
    <Grid.Item>
      <NotesInput
        name={INSURANCE_PREMIUM_NOTES}
        handleSave={saveProbateEstateField}
      />
    </Grid.Item>
  </Grid>
)

InsurancePremiums.propTypes = {
  saveProbateEstateField: PropTypes.func.isRequired,
}

export default InsurancePremiums
