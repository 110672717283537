import styled from 'styled-components'
import { EmailIcon, EmailPendingIcon, P, Wrapper } from '@farewill/ui'
import { COLOR, FONT, GTR } from '@farewill/ui/tokens'

import Tooltip from 'components/tooltip'
import ConditionalWrapper from 'components/conditional-wrapper'
import { EMAIL_STATUSES } from 'utils/enums'
import { hasScheduledEmailTrigger } from './helpers'

const StyledIcon = styled(({ scheduled, ...rest }) => {
  const Component = scheduled ? EmailPendingIcon : EmailIcon
  return <Component {...rest} />
})`
  color: ${({ $isSentOrScheduled }) =>
    $isSentOrScheduled ? COLOR.GREY.DARK : COLOR.GREY.LIGHT};
`

const StyledIconWrapper = styled(Wrapper)`
  display: flex;
`

const StyledTooltip = styled(Tooltip)`
  padding: ${GTR.XXS};
  background-color: ${COLOR.GREY.DARK};
`

const StyledTooltipP = styled(P)`
  margin: 0;
  color: ${COLOR.WHITE};
  font-size: ${FONT.SIZE.S};
`

const TooltipWrapper = (tooltipContent) => (children) =>
  (
    <StyledTooltip
      placement="bottom"
      content={<StyledTooltipP strong>{tooltipContent}</StyledTooltipP>}
    >
      {children}
    </StyledTooltip>
  )

const getLastEmail = (emails) => {
  const notCancelledEmails = emails.filter(
    (email) => email.status !== EMAIL_STATUSES.CANCELLED
  )

  return notCancelledEmails[notCancelledEmails.length - 1]
}

const TaskEmail = ({ task }) => {
  const { emails } = task.attributes

  const isScheduledEmail = hasScheduledEmailTrigger(task)
  const lastEmail = getLastEmail(emails)
  const isSent = lastEmail?.status === EMAIL_STATUSES.PROCESSED
  const isScheduled = lastEmail?.status === EMAIL_STATUSES.SCHEDULED
  const tooltipContent = isSent ? 'Email sent' : 'Email scheduled'

  return (
    <ConditionalWrapper
      showWrapper={isSent || isScheduled}
      wrapper={TooltipWrapper(tooltipContent)}
    >
      <StyledIconWrapper>
        <StyledIcon
          scheduled={isScheduledEmail}
          $isSentOrScheduled={isSent || isScheduled}
        />
      </StyledIconWrapper>
    </ConditionalWrapper>
  )
}

export default TaskEmail
