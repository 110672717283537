import PropTypes from 'prop-types'
import { Divider, Grid, H, P } from '@farewill/ui'

import MessageBox from 'components/message-box'
import DocumentsChecklist from './documents-checklist'
import IdentityOfTheWill from './identity-of-the-will'
import ProblemsWithTheWill from './problems-with-the-will'
import Executors from './executors'
import ClaimToTransferNilRateBand from './claim-to-transfer-nil-rate-band'
import IntestacyStatutoryLegacy from './intestacy-statutory-legacy'
import ReferralToLegalTeam from './referral-to-legal-team'

const InternalChecks = ({
  probateCase,
  probateEstate,
  saveProbateEstateField,
}) => {
  const isIncomplete = probateEstate.attributes?.completedSections?.length < 6
  return (
    <>
      <Grid gap="XL">
        <Grid.Item span={8}>
          <Grid>
            <Grid.Item>
              <H size="M">Internal checks</H>
              <P margin={[0, 0, 'XS']}>Fact-checking the fact find.</P>
              <P>Complete these checks to prepare the case for referral.</P>
            </Grid.Item>

            <Grid.Item span={9}>
              <MessageBox warning>
                {isIncomplete
                  ? 'This page includes some information from fact find. Fact find isn’t complete so this information may not be final.'
                  : 'This page includes some information from fact find. Fact find has been marked as complete, but the information may still need to be checked.'}
              </MessageBox>
            </Grid.Item>
          </Grid>
          <Divider margin={['XL', 0]} />
          <DocumentsChecklist
            initialValues={probateEstate.attributes}
            saveProbateEstateField={saveProbateEstateField}
          />
        </Grid.Item>
      </Grid>
      <Divider margin={['S', 0, 'L']} />
      <IdentityOfTheWill
        initialValues={probateEstate.attributes}
        probateCase={probateCase}
        probateEstateId={probateEstate.id}
        saveProbateEstateField={saveProbateEstateField}
      />
      <Divider margin={['S', 0, 'L']} />
      <ProblemsWithTheWill
        initialValues={probateEstate.attributes}
        probateEstateId={probateEstate.id}
        saveProbateEstateField={saveProbateEstateField}
      />
      <Divider margin={['S', 0, 'L']} />
      <Executors
        initialValues={probateEstate.attributes}
        probateEstateId={probateEstate.id}
        saveProbateEstateField={saveProbateEstateField}
      />
      <Divider margin={['S', 0, 'L']} />
      <ClaimToTransferNilRateBand
        initialValues={probateEstate.attributes}
        probateEstateId={probateEstate.id}
        saveProbateEstateField={saveProbateEstateField}
      />
      <Divider margin={['S', 0, 'L']} />
      <IntestacyStatutoryLegacy
        initialValues={probateEstate.attributes}
        probateEstateId={probateEstate.id}
        saveProbateEstateField={saveProbateEstateField}
      />
      <Divider margin={['S', 0, 'L']} />
      <ReferralToLegalTeam
        initialValues={probateEstate.attributes}
        probateEstateId={probateEstate.id}
        saveProbateEstateField={saveProbateEstateField}
      />
    </>
  )
}

InternalChecks.propTypes = {
  probateCase: PropTypes.object.isRequired,
  probateEstate: PropTypes.object.isRequired,
  saveProbateEstateField: PropTypes.func.isRequired,
}

export default InternalChecks
