import { useEffect } from 'react'
import { P, Wrapper } from '@farewill/ui'
import { COLOR, GTR } from '@farewill/ui/tokens'
import { getWidthOfContainerColumns } from '@farewill/ui/helpers/layout'
import styled from 'styled-components'
import { Link, useLocation } from 'react-router-dom'

import { useFetchResource, RESOURCES } from 'api'
import { scrollToDataId } from 'lib/window/scroll'
import { WillCase } from 'lib/models/will-case'

const StyledWrapper = styled(Wrapper)`
  background-color: ${COLOR.ACCENT.PRIMARY};
  padding: ${GTR.S};
  text-align: center;
  margin-top: ${GTR.S};
`

type Props = {
  willCaseId: number
}

const CaseStatusBanner = ({ willCaseId }: Props): React.ReactElement | null => {
  const location = useLocation()

  const willCaseQuery = useFetchResource<WillCase>({
    id: willCaseId,
    resource: RESOURCES.WILL_CASES,
  })

  useEffect(() => {
    if (location.hash) {
      scrollToDataId(location.hash.replace('#', ''))
    }
  }, [location.hash])

  const status = willCaseQuery.data?.attributes.status

  if (!status || !['cold', 'closed', 'cancelled'].includes(status)) return null

  return (
    <StyledWrapper maxWidth={getWidthOfContainerColumns(8, 'L')}>
      {status === 'closed' ? (
        <P size="S">
          This case is marked as <strong>closed</strong>. If this case has been
          re-opened, please uncheck the 'Close case' task and add any new tasks
          you’re completing to the task list.
        </P>
      ) : (
        <P size="S">
          This case is marked as <strong>{status}</strong>. If you want to make
          edits please{' '}
          <Link to={`/will-cases/${willCaseId}#case-status`}>
            change case status to open
          </Link>{' '}
          first.
        </P>
      )}
    </StyledWrapper>
  )
}

export default CaseStatusBanner
