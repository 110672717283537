export const WILLSUITE_STATUS = {
  AWAITING_FACT_FIND: 'awaiting_fact_find',
  SENT_TO_LEGAL: 'sent_to_legal',
  UPDATES_REQUIRED: 'updates_required',
  APPROVED_BY_LEGAL: 'approved_by_legal',
  DRAFT_SENT_TO_CUSTOMER: 'draft_sent_to_customer',
  COMPLETE: 'complete',
} as const

export type WillSuiteStatus =
  (typeof WILLSUITE_STATUS)[keyof typeof WILLSUITE_STATUS]

export type WillSuiteEvent = {
  id: string
  createdAt: string
  updatedAt: string
  status: WillSuiteStatus
  willId: number | null
  payload: Record<string, unknown>
  lpaId: string | null
}
