import PropTypes from 'prop-types'
import styled from 'styled-components'
import { COLOR } from '@farewill/ui/tokens'

const StyledLiveIndicator = styled.span`
  color: ${COLOR.STATE.SUCCESS};
  position: relative;
  padding-left: 20px;

  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    height: 10px;
    width: 10px;
    background-color: ${COLOR.STATE.SUCCESS};
    border-radius: 50%;
  }
`

const LiveIndicator = ({ children }) => (
  <StyledLiveIndicator>{children}</StyledLiveIndicator>
)

LiveIndicator.propTypes = {
  children: PropTypes.node.isRequired,
}

export default LiveIndicator
