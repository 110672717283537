import { Fieldset, Grid, H } from '@farewill/ui'
import { COLOR, FONT } from '@farewill/ui/tokens'
import styled from 'styled-components'
import { useFormikContext } from 'formik'
import { useParams } from 'react-router-dom'

import Address from 'components/address'
import Checkbox from 'components/form/checkbox'
import CircularRadioGroup from 'components/form/circular-radio-group'

import { WillAttributes } from 'lib/models/will'
import { strToBool } from 'utils/helpers'
import { NOT_ASKED_OPTIONS } from 'utils/enums'

import { UpdateResourceFn } from './types'

const StyledFieldset = styled(Fieldset)`
  legend {
    font-weight: ${FONT.WEIGHT.REGULAR};
    color: ${COLOR.GREY.DARK};
  }
`

type Props = {
  updateWill: UpdateResourceFn
}

const ContactPermissions = ({ updateWill }: Props): React.ReactElement => {
  const { willId } = useParams<{ willId: string }>()
  const { values } = useFormikContext<WillAttributes>()

  return (
    <>
      <H size="S">2.4 Contact permissions</H>
      <Grid>
        <Grid.Item>
          <CircularRadioGroup
            name="redemptionPartnerMarketingPermissionsGiven"
            label="Are they happy for the redemption partner (whose voucher code they used) to contact them?"
            options={NOT_ASKED_OPTIONS}
            handleSave={updateWill}
            inline
          />
          {strToBool(values.redemptionPartnerMarketingPermissionsGiven) && (
            <StyledFieldset
              legend="They are happy to be contacted by:"
              horizontal
            >
              <Checkbox
                name="redemptionPartnerMarketingPermissionsEmail"
                label="Email"
                checked={strToBool(
                  values.redemptionPartnerMarketingPermissionsEmail
                )}
                handleSave={updateWill}
              />
              <Checkbox
                name="redemptionPartnerMarketingPermissionsPhone"
                label="Phone"
                checked={strToBool(
                  values.redemptionPartnerMarketingPermissionsPhone
                )}
                handleSave={updateWill}
              />
              <Checkbox
                name="redemptionPartnerMarketingPermissionsPost"
                label="Post"
                checked={strToBool(
                  values.redemptionPartnerMarketingPermissionsPost
                )}
                handleSave={updateWill}
              />
            </StyledFieldset>
          )}
        </Grid.Item>
        {strToBool(values.redemptionPartnerMarketingPermissionsGiven) &&
          strToBool(values.redemptionPartnerMarketingPermissionsPost) && (
            <Grid.Item>
              <Address
                label="Postal address for charity contact"
                name="address"
                data={values.partnerContactPostalAddress}
                newAddressAttrs={{
                  relatedId: Number(willId),
                  relatedResource: 'will',
                  relation: 'partnerContactPostalAddress',
                }}
              />
            </Grid.Item>
          )}
      </Grid>
    </>
  )
}

export default ContactPermissions
