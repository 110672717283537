import moment from 'moment'
import { ReactElement } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { P, Wrapper } from '@farewill/ui'
import { COLOR, FONT, GTR } from '@farewill/ui/tokens'

import LeadEventIcon from 'components/lead-event/icon'
import { AircallEventAttributes } from 'lib/models/aircall-event'
import { formatToHuman } from 'utils/helpers'

const EventWrapper = styled(Wrapper)`
  display: flex;
  margin-bottom: ${GTR.L};
`

const EventSummary = styled(P)`
  font-size: ${FONT.SIZE.S};
  margin-bottom: 0;
  text-decoration: none;
`

const EventListItem = styled.li`
  font-size: ${FONT.SIZE.S};

  &:not(:last-of-type) {
    margin-bottom: ${GTR.XS};
  }
`

const StyledGreyText = styled.span`
  font-size: ${FONT.SIZE.S};
  color: ${COLOR.GREY.MEDIUM};
`

const StyledList = styled.ul`
  margin-top: ${GTR.XS};
`

// returns a span with a sentence like "Inbound call taken by John Doe"
const formatEventSummary = ({
  event,
}: {
  event: AircallEventAttributes
}): ReactElement => {
  const { direction } = event
  const formattedDirection = formatToHuman(direction)
  const userName = event.user?.name

  if (!userName) {
    return <span>{formattedDirection} call</span>
  }

  const userNameAttribution = direction === 'inbound' ? 'taken' : 'made'

  return (
    <span>
      {formattedDirection} call {userNameAttribution} by{' '}
      <strong>{userName}</strong>
    </span>
  )
}

const formatLeadLink = ({
  leadId,
}: {
  leadId?: number
}): ReactElement | null => {
  if (!leadId) {
    return null
  }

  return (
    <StyledGreyText>
      &bull; <Link to={`/leads/${leadId}`}>View lead</Link>
    </StyledGreyText>
  )
}

const formatEventNotes = ({
  event,
}: {
  event: AircallEventAttributes
}): ReactElement => {
  const { answered_at, direction, ended_at, missed_call_reason, voicemail } =
    event

  let notes

  if (!ended_at) {
    notes = 'The call is in progress.'
  }

  if (missed_call_reason) {
    notes = `We did not pick up the call: ${missed_call_reason}. ${
      voicemail ? 'The customer left a voicemail.' : ''
    }`.trim()
  }

  return (
    <StyledList>
      {event.number?.name && (
        <EventListItem>
          <strong>Call {direction === 'inbound' ? 'to' : 'from'}</strong>{' '}
          {event.number.name}
        </EventListItem>
      )}
      <EventListItem>
        <strong>Outcome</strong> {answered_at ? 'Connected' : 'No answer'}
      </EventListItem>
      {notes && (
        <EventListItem>
          <strong>Notes</strong> {notes}
        </EventListItem>
      )}
    </StyledList>
  )
}

const AircallEvent = ({
  event,
  leadId,
}: {
  event: AircallEventAttributes
  leadId?: number
}): ReactElement => {
  const time = event.started_at * 1000 // JavaScript needs the Unix timestamp in milliseconds
  const formattedTime = moment(time).format('D MMM YYYY, HH:mm')

  return (
    <EventWrapper>
      <LeadEventIcon
        direction={event.direction}
        isAnswered={!!event.answered_at}
      />
      <Wrapper>
        <EventSummary>{formatEventSummary({ event })}</EventSummary>
        <StyledGreyText>{formattedTime}</StyledGreyText>{' '}
        {formatLeadLink({ leadId })}
        {formatEventNotes({ event })}
        {event.asset && (
          <P size="S">
            <a href={event.asset} target="_blank" rel="noopener noreferrer">
              Go to Aircall recording
            </a>
          </P>
        )}
      </Wrapper>
    </EventWrapper>
  )
}

export default AircallEvent
