import PropTypes from 'prop-types'
import { Grid, P } from '@farewill/ui'
import { COLOR } from '@farewill/ui/tokens'
import { Formik, Form } from 'formik'
import moment from 'moment'

import namespace from 'lib/formik/namespace'

import SelectInput from 'components/form/select-input'
import NotesInput from 'components/notes-input'
import EstateCard from 'components/estate-card'

import { formatValuesForFormik } from 'utils/helpers'
import {
  PROBATE_CASE_ERROR_MADE_BY_OPTIONS,
  PROBATE_CASE_ERROR_TYPE_OPTIONS,
} from 'utils/enums'

import { MADE_BY, NOTES, STAGE, TYPES } from './field-names'
import { CORE_TASKS_IN_ORDER } from 'components/tasks-panel/constants'

import { useErrorsContext } from './context'

const STAGE_OPTIONS = CORE_TASKS_IN_ORDER.probate.map((stage) => ({
  label: stage.title,
  options: stage.tasks,
}))

const Error = ({ error, handleDeleteClick, listLength, listPosition }) => {
  const errorId = error.id
  const prefix = `errors.${errorId}.`
  const { withNamespace, withoutNamespace } = namespace(prefix)
  const { updateError } = useErrorsContext()

  const handleSave = ({ name, value }) => {
    const attributes = { [withoutNamespace(name)]: value || null }
    return updateError(errorId, attributes)
  }

  const initialValues = {
    errors: {
      [errorId]: formatValuesForFormik(error.attributes),
    },
  }

  return (
    <EstateCard
      onTrashClick={() => handleDeleteClick(errorId)}
      listLength={listLength}
      listPosition={listPosition}
      type="error"
    >
      <Formik initialValues={initialValues}>
        <Form>
          <Grid>
            <Grid.Item span={6}>
              <SelectInput
                name={withNamespace(MADE_BY)}
                label="Who made the error?"
                handleSave={handleSave}
                options={PROBATE_CASE_ERROR_MADE_BY_OPTIONS}
              />
            </Grid.Item>
            <Grid.Item span={6}>
              <SelectInput
                name={withNamespace(STAGE)}
                label="At what stage did the error occur?"
                handleSave={handleSave}
                options={STAGE_OPTIONS}
              />
            </Grid.Item>
            <Grid.Item span={8}>
              <SelectInput
                name={withNamespace(TYPES)}
                label="Type"
                handleSave={handleSave}
                options={PROBATE_CASE_ERROR_TYPE_OPTIONS}
                isMulti
              />
              <P size="S" color={COLOR.GREY.MEDIUM} margin={['XXS', 0, 0]}>
                Select one or more
              </P>
            </Grid.Item>
            <Grid.Item>
              <P margin={[0, 0, 'XXS']}>
                Logged on{' '}
                {moment(error.attributes.createdAt).format('D MMM YYYY')} by{' '}
                {error.attributes.adminUser.name}.
              </P>
              <P>Please describe the error in the notes below.</P>
            </Grid.Item>
            <Grid.Item>
              <NotesInput name={withNamespace(NOTES)} handleSave={handleSave} />
            </Grid.Item>
          </Grid>
        </Form>
      </Formik>
    </EstateCard>
  )
}

Error.propTypes = {
  error: PropTypes.object.isRequired,
  handleDeleteClick: PropTypes.func.isRequired,
  listLength: PropTypes.number.isRequired,
  listPosition: PropTypes.number.isRequired,
}

export default Error
