import PropTypes from 'prop-types'
import styled from 'styled-components'
import Tippy, { TippyProps } from '@tippy.js/react'
import { BORDER_RADIUS, COLOR, FONT, GTR } from '@farewill/ui/tokens'

const StyledContent = styled.span<{ $underlined: boolean }>`
  text-decoration: ${({ $underlined }) => ($underlined ? 'underline' : 'none')};
  cursor: pointer;
`

const StyledTippy = styled(Tippy)`
  background-color: ${COLOR.WHITE};
  border: 1px solid ${COLOR.GREY.DARK};
  color: ${COLOR.GREY.DARK};
  padding: ${GTR.XS};
  border-radius: ${BORDER_RADIUS.S};
  font-size: ${FONT.SIZE.S};
`

type TooltipProps = Omit<TippyProps, 'children'> & {
  underlined: boolean
  children: React.ReactNode
}

const Tooltip = ({
  underlined = false,
  children,
  content,
  ...rest
}: TooltipProps) => (
  <StyledTippy
    content={content}
    animation="fade"
    arrow={false}
    duration={150}
    distance={parseInt(GTR.XS)}
    interactive
    maxWidth={450}
    appendTo={document.body}
    {...rest}
  >
    <StyledContent $underlined={underlined}>{children}</StyledContent>
  </StyledTippy>
)

Tooltip.propTypes = {
  underlined: PropTypes.bool,
}

Tooltip.defaultProps = {
  underlined: true,
}

export default Tooltip
