import { H, P } from '@farewill/ui'
import { GTR } from '@farewill/ui/tokens'
import { useFormikContext } from 'formik'
import styled from 'styled-components'

import { useFetchResource, RESOURCES } from 'api'
import { WillCase } from 'lib/models/will-case'

import Address from 'components/address'
import { Address as AddressType } from 'lib/models/address'
import CircularRadioGroup from 'components/form/circular-radio-group'

const StyledCircularRadioGroup = styled(CircularRadioGroup)`
  margin-bottom: ${GTR.L};
`

interface FormFields {
  postalAddress: AddressType
  deliveryMethodDraft: string
  deliveryMethodFinal: string
}

const CustomerPreferences = ({ id }: { id: number }): React.ReactElement => {
  const { values, handleSubmit } = useFormikContext<FormFields>()
  const willCaseQuery = useFetchResource<WillCase>({
    id,
    resource: RESOURCES.WILL_CASES,
  })
  const wantsWillPosted =
    values.deliveryMethodDraft === 'post' ||
    values.deliveryMethodFinal === 'post'

  if (willCaseQuery.isLoading || willCaseQuery.isIdle)
    return <P size="L">Loading...</P>

  if (!willCaseQuery.data) return <P size="L">There is no data to display.</P>

  return (
    <>
      <H size="S" margin={[0, 0, 'M']}>
        Customer preferences
      </H>
      <StyledCircularRadioGroup
        name="deliveryMethodDraft"
        label="How does the customer want to receive the will draft?"
        options={[
          { label: 'Email', value: 'email' },
          { label: 'Post', value: 'post' },
        ]}
        handleSave={() => handleSubmit()}
        inline
      />
      <StyledCircularRadioGroup
        name="deliveryMethodFinal"
        label="How does the customer want to receive the final will?"
        options={[
          { label: 'Email', value: 'email' },
          { label: 'Post', value: 'post' },
        ]}
        handleSave={() => handleSubmit()}
        inline
      />
      {wantsWillPosted && (
        <Address
          label="Will postal address "
          name="postalAddress"
          data={values.postalAddress}
          newAddressAttrs={{
            relatedId: willCaseQuery.data.id,
            relatedResource: 'willCase',
            relation: 'postalAddress',
          }}
        />
      )}
    </>
  )
}

export default CustomerPreferences
