export const LIMC_CONTACT_TYPE = {
  email: 'email',
  phoneNumber: 'phone_number',
  postal: 'postal',
} as const

export const LIMC_CONSENT_TYPE = {
  general: 'general',
} as const

export const LIMC_CONSENT_VALUE = {
  subscribed: 'subscribed',
  unsubscribed: 'unsubscribed',
} as const

type LimcContactType = keyof typeof LIMC_CONTACT_TYPE
type LimcConsentType = keyof typeof LIMC_CONSENT_TYPE
export type LimcConsentValue = keyof typeof LIMC_CONSENT_VALUE

export type LegitimateInterestMarketingConsent = {
  contactType: LimcContactType
  consentType: LimcConsentType
  consentValue: LimcConsentValue
}
