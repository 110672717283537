import { useEffect } from 'react'
import { useField, useFormikContext } from 'formik'
import styled from 'styled-components'

import { Wrapper } from '@farewill/ui'
import { GTR, COLOR } from '@farewill/ui/tokens'
import Input from './input'
import Checkbox from './checkbox'
import { strToBool } from 'utils/helpers'

const CheckboxWrapper = styled(Wrapper)`
  margin-top: ${GTR.S};
`

const StyledWrapper = styled(Wrapper)`
  margin: ${GTR.S} 0 0 12px;
  padding-left: ${GTR.M};
  border-left: 4px solid ${COLOR.GREY.LIGHT};
`

const IndentedInput = ({ name, placeholder, handleSave, isCurrency }) => {
  const { values, setFieldValue } = useFormikContext()

  useEffect(() => {
    if (!strToBool(values.quoteAttributes.giftsGivenOver3kThreshold)) {
      return () => setFieldValue(name, '', false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name, setFieldValue])

  return (
    <Input
      small
      name={name}
      placeholder={placeholder}
      handleSave={handleSave}
      isCurrency={isCurrency}
    />
  )
}

const IndentedCheckbox = ({
  name,
  valueName,
  label,
  handleSave,
  placeholder,
  isCurrency,
}) => {
  // The initialValue must be a boolean so that Formik treats the Checkbox value as a boolean rather than an array of strings
  const [field] = useField({
    name,
    type: 'checkbox',
  })

  const { setFieldValue } = useFormikContext()

  useEffect(() => {
    return () => setFieldValue(name, field.value, false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name, setFieldValue])

  return (
    <>
      <CheckboxWrapper>
        <Checkbox
          label={label}
          handleSave={handleSave}
          name={name}
          checked={strToBool(field.value)}
        />
      </CheckboxWrapper>
      <StyledWrapper>
        {strToBool(field.value) && (
          <IndentedInput
            name={name + valueName}
            placeholder={placeholder}
            handleSave={handleSave}
            isCurrency={isCurrency}
          />
        )}
      </StyledWrapper>
    </>
  )
}

export default IndentedCheckbox
