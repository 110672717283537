import styled from 'styled-components'

import { Button } from '@farewill/ui'
import { paragraphS } from '@farewill/ui/helpers/text'

export const SlimPrimaryButton = styled(Button.Primary)`
  ${paragraphS}
  line-height: 1;
`

export const SlimSecondaryButton = styled(Button.Secondary)`
  ${paragraphS}
  line-height: 1;
  display: inline-block;
`

export const SlimBorderedButton = styled(Button.Bordered)`
  ${paragraphS}
  line-height: 1;
  display: inline-block;
`
