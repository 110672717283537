import { Contact } from 'lib/models/contact'
import { LpaAttributes } from 'lib/models/lpa'
import { LpaCaseAttributes } from 'lib/models/lpa-case'

/** Given the lpaCase, we can compare the linked LPAs with the contactId on the lpaCase (which is always shared with the lead LPA),
 * and determine which LPA in the array is the lead and which the partner, without relying on position in the array. We can also
 * take the contacts linked to the lpaCase and assign them to the lpas.
 */
export const sortLeadAndPartnerLpas = (
  lpaCase: LpaCaseAttributes
): {
  leadLpas: LpaAttributes[]
  partnerLpas: LpaAttributes[]
} => {
  const { contactId: leadContactId, contacts, lpas } = lpaCase

  if (!contacts || !lpas) {
    return { leadLpas: [], partnerLpas: [] }
  }

  const leadLpas = lpas.filter((lpa) => lpa.contactId === leadContactId)

  if (leadLpas) {
    leadLpas.forEach((lpa) => {
      lpa.contact = contacts.find(
        (contact) => contact.id === lpa.contactId
      ) as Contact
    })
  }

  const partnerLpas =
    lpas.length > 1 ? lpas.filter((lpa) => lpa.contactId !== leadContactId) : []

  if (partnerLpas) {
    partnerLpas.forEach((lpa) => {
      lpa.contact = contacts.find(
        (contact) => contact.id === lpa.contactId
      ) as Contact
    })
  }

  return { leadLpas, partnerLpas }
}
