import PropTypes from 'prop-types'
import { Divider, Grid, H } from '@farewill/ui'

import withForm from '../components/with-form'
import Funeral from './funeral'
import Mortgages from './mortgages'
import CreditCards from './credit-cards'
import Loans from './loans'
import UtilitiesAndOtherDebts from './utilities-and-other-debts'

const FuneralAndDebts = ({
  probateEstateId,
  saveProbateEstateField,
  estateItems,
}) => (
  <Grid gap="XL" data-scroll-id="5">
    <Grid.Item span={8}>
      <H size="M">5. Funeral and debts</H>
      <Funeral saveProbateEstateField={saveProbateEstateField} />
      <Divider margin={['L', 0]} />
      <Mortgages
        saveProbateEstateField={saveProbateEstateField}
        probateEstateId={probateEstateId}
        initialItems={estateItems.mortgage}
      />
      <Divider margin={['L', 0]} />
      <CreditCards
        saveProbateEstateField={saveProbateEstateField}
        probateEstateId={probateEstateId}
        initialItems={estateItems.creditCard}
      />
      <Divider margin={['L', 0]} />
      <Loans
        saveProbateEstateField={saveProbateEstateField}
        probateEstateId={probateEstateId}
        initialItems={estateItems.loan}
      />
      <Divider margin={['L', 0]} />
      <UtilitiesAndOtherDebts
        saveProbateEstateField={saveProbateEstateField}
        probateEstateId={probateEstateId}
        initialItems={estateItems.utilityOrOtherDebt}
      />
    </Grid.Item>
  </Grid>
)

FuneralAndDebts.propTypes = {
  saveProbateEstateField: PropTypes.func.isRequired,
  probateEstateId: PropTypes.string.isRequired,
}

export default withForm(FuneralAndDebts)
