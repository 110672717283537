import { P, Wrapper } from '@farewill/ui'
import { GTR } from '@farewill/ui/tokens'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import ActivityTimestamp from 'components/activity-timestamp'
import { Invoice } from 'lib/models/invoice'
import AdminIcon from 'components/admin-icon'

type PaymentProps = {
  invoice: Invoice
}

const StyledWrapper = styled(Wrapper)`
  display: flex;
  align-items: center;
  margin-bottom: ${GTR.L};
`

const Payment = ({ invoice }: PaymentProps): JSX.Element => {
  const { createdAt, paymentProvider, funeralId } = invoice.attributes
  return (
    <StyledWrapper>
      <AdminIcon user={{ name: 'Admin User' }} size="L" />
      <Wrapper>
        <P margin={0} size="S">
          <strong>Admin User</strong> recorded{' '}
          <Link to={`/funerals/${funeralId}/sale`}>
            <strong>
              {paymentProvider === 'square' ? 'a card payment' : 'an invoice'}
            </strong>
          </Link>
        </P>
        <ActivityTimestamp datetime={createdAt} />
      </Wrapper>
    </StyledWrapper>
  )
}

export default Payment
