import PropTypes from 'prop-types'
import { Button, Wrapper } from '@farewill/ui'
import { GTR } from '@farewill/ui/tokens'
import lowerCase from 'lodash/lowerCase'

import Relative from './relative'
import { useRelativesContext } from './context'

const Relatives = ({ relationshipToDeceased }) => {
  const { relatives, createRelative, isCreatingRelative } =
    useRelativesContext()

  const filteredRelatives = relatives.filter(
    ({ attributes }) =>
      attributes.relationshipToDeceased === relationshipToDeceased &&
      !attributes.isAdopted
  )

  return (
    <>
      <Wrapper margin={filteredRelatives.length ? [0, 0, 'M'] : 0}>
        {filteredRelatives.map((relative, index) => (
          <Relative
            relative={relative}
            key={relative.id}
            initialValues={{ relative: { [relative.id]: relative.attributes } }}
            listLength={filteredRelatives.length}
            listPosition={index + 1}
            relationshipToDeceased={relationshipToDeceased}
          />
        ))}
      </Wrapper>
      <Button.Secondary
        style={{ paddingTop: GTR.XXS, paddingBottom: GTR.XXS }}
        loading={isCreatingRelative}
        type="button"
        onClick={() => createRelative({ relationshipToDeceased })}
      >
        Add {lowerCase(relationshipToDeceased)}
      </Button.Secondary>
    </>
  )
}

Relatives.propTypes = {
  relationshipToDeceased: PropTypes.string.isRequired,
}

export default Relatives
