import styled from 'styled-components'
import { Button, ButtonGroup, HelpIcon, Wrapper } from '@farewill/ui'
import { COLOR, FONT, GTR } from '@farewill/ui/tokens'
import { useFormikContext } from 'formik'
import { toast } from 'react-toastify'
import { useQueryClient } from 'react-query'

import useApiHelpers from 'lib/effects/api-helpers'
import { formatAdminUserOptions } from 'utils/helpers'

import HelpNeededHand from 'components/help-needed-hand'
import SelectInput from 'components/form/select-input'

import { useTasksContext } from './context'
import Dropdown from 'components/dropdown'
import { TASK_ASSIGNMENT_ROLES } from './constants'

const StyledAskForHelpButton = styled(Button.Secondary)`
  && {
    padding: ${GTR.XS} ${GTR.L};
  }
`

const StyledRemoveHelpButton = styled(Button.Underline)`
  font-size: ${FONT.SIZE.S};
`

const HelpNeededAssignment = ({ task, fetchEventsForTask }) => {
  const { values } = useFormikContext()
  const cache = useQueryClient()
  const adminUsers = cache.getQueryData('adminUsers')
  const { updateTask } = useTasksContext()
  const hasHelper = task.attributes.currentHelper
  const showHelpButton =
    task.attributes.currentHelperId !== values.currentHelperId
  const adminUserOptions = formatAdminUserOptions(adminUsers)

  const {
    createItem: createAssignment,
    isCreating: isCreatingAssignment,
    deleteItem: deleteTaskAssignment,
    isDeletingItem: isDeletingTaskAssignment,
  } = useApiHelpers({
    baseUrl: `/api/task-assignments`,
    type: 'task_assignments',
  })

  const handleCreateClick = async () => {
    await createAssignment({
      adminUserId: values.currentHelperId,
      role: TASK_ASSIGNMENT_ROLES.HELP_REQUESTED,
      taskId: task.id,
    })
    await updateTask(task.id, {})
    await fetchEventsForTask()
    toast('Help assigned.', {
      toastId: 'help-assigned',
    })
  }

  const handleDeleteClick = async () => {
    await deleteTaskAssignment(task.attributes.currentHelpRequestId)
    await updateTask(task.id, {})
    await fetchEventsForTask()
    toast('Help removed.', {
      toastId: 'help-removed',
    })
  }

  return (
    <Dropdown
      alignment="right"
      updatedAt={task.attributes.updatedAt}
      trigger={
        hasHelper ? (
          <HelpNeededHand
            currentHelper={task.attributes.currentHelper.name}
            size="M"
            enableTooltip
          />
        ) : (
          <HelpIcon
            size="M"
            color={COLOR.WHITE}
            background={COLOR.GREY.LIGHT}
          />
        )
      }
      content={
        <Wrapper padding="S">
          <SelectInput
            label="Ask for help from"
            name="currentHelperId"
            options={adminUserOptions}
            small
          />
          <ButtonGroup margin={['S', 0, 0]}>
            {showHelpButton && (
              <StyledAskForHelpButton
                type="button"
                onClick={handleCreateClick}
                loading={isCreatingAssignment}
              >
                Ask for help
              </StyledAskForHelpButton>
            )}
            {hasHelper && (
              <StyledRemoveHelpButton
                type="button"
                onClick={handleDeleteClick}
                loading={isDeletingTaskAssignment}
              >
                Mark as resolved
              </StyledRemoveHelpButton>
            )}
          </ButtonGroup>
        </Wrapper>
      }
    />
  )
}

export default HelpNeededAssignment
