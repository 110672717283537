import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Grid, H } from '@farewill/ui'
import { GTR } from '@farewill/ui/tokens'

import { NOT_ASKED_AND_UNSURE_OPTIONS } from 'utils/enums'

import Tooltip from 'components/tooltip'
import CircularRadioGroup from 'components/form/circular-radio-group'
import InputFloating from 'components/form/input-floating'

import {
  MADE_GIFTS_WITH_RESERVATION_OF_BENEFIT,
  GIFTS_WITH_RESERVATION_OF_BENEFIT_NOTES,
} from './field-names'

const StyledLabel = styled.label`
  margin-bottom: ${GTR.S};
`

const TOOLTIP_TITLE =
  'Known as a ‘gift with reservation of benefit’. For example, if they gave their house away but carried on living there without paying rent, or gave away a painting but kept it in their living room.'

const ReservationOfBenefit = ({ saveProbateEstateField }) => (
  <Grid data-scroll-id="6.1">
    <Grid.Item>
      <H size="S">6.1 Gifts where they continued to benefit</H>
    </Grid.Item>
    <Grid.Item>
      <StyledLabel>
        Did they give something away but{' '}
        <Tooltip content={TOOLTIP_TITLE}>continue to benefit from it</Tooltip>?
      </StyledLabel>
      <CircularRadioGroup
        name={MADE_GIFTS_WITH_RESERVATION_OF_BENEFIT}
        options={NOT_ASKED_AND_UNSURE_OPTIONS}
        handleSave={saveProbateEstateField}
        inline
      />
    </Grid.Item>
    <Grid.Item>
      <InputFloating
        name={GIFTS_WITH_RESERVATION_OF_BENEFIT_NOTES}
        label="Notes"
        handleSave={saveProbateEstateField}
        component="textarea"
        rows={4}
      />
    </Grid.Item>
  </Grid>
)

ReservationOfBenefit.propTypes = {
  saveProbateEstateField: PropTypes.func.isRequired,
}

export default ReservationOfBenefit
