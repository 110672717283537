export const ESTATE_PEOPLE_ATTRIBUTES = {
  IS_CUSTOMER: 'isCustomer',
  IS_POINT_OF_CONTACT: 'isPointOfContact',
  PERSONAL_REPRESENTATIVE_TYPE: 'personalRepresentativeType',
  PERSONAL_REPRESENTATIVE_ROLE: 'personalRepresentativeRole',
  ADDRESS: 'address',
  DATE_OF_BIRTH: 'dateOfBirth',
  NOTES: 'notes',
  TITLE: 'title',
  FIRST_NAMES: 'firstNames',
  LAST_NAME: 'lastName',
  EMAIL: 'email',
  PHONE_NUMBER: 'phoneNumber',
} as const
