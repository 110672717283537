export default function mode(state = 'edit', action = {}) {
  const REQUEST_FULFILLED = action.type.includes('_FULFILLED')

  switch (true) {
    case REQUEST_FULFILLED: {
      const mode = action.payload.data.meta?.mode
      return mode || state
    }

    default:
      return state
  }
}
