import { connect } from 'react-redux'
import WillPdf from '../components/'
import { fetchWillPdfUrl } from 'state/actions'

const mapStateToProps = (state, ownProps) => ({
  willKey: ownProps.match.params.key,
  url: state.will.url,
})

const mapDispatchToProps = {
  fetchWillPdfUrl,
}

export default connect(mapStateToProps, mapDispatchToProps)(WillPdf)
