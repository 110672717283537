import GridWithToggle from 'components/grid-with-toggle'
import { PropsWithChildren } from 'react'
import { TooltipComplete, TooltipIncomplete } from './section-heading'

type SubsectionHeadingProps = {
  disabled: boolean
  name: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onToggle: (...args: any[]) => any
  scrollId: string
  title: string
  isComplete: boolean
}

const SubsectionHeading = ({
  children,
  disabled,
  name,
  onToggle,
  scrollId,
  title,
  isComplete,
}: PropsWithChildren<SubsectionHeadingProps>) => {
  return (
    <GridWithToggle
      disabled={disabled}
      name={name}
      onToggle={onToggle}
      scrollId={scrollId}
      title={title}
      headingIcon={
        isComplete ? (
          <TooltipComplete scrollId={scrollId} />
        ) : (
          <TooltipIncomplete scrollId={scrollId} />
        )
      }
    >
      {children as React.FC}
    </GridWithToggle>
  )
}

export default SubsectionHeading
