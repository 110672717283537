import { Divider, H, Wrapper, P } from '@farewill/ui'
import { RouteComponentProps } from 'react-router-dom'
import { Formik, Form } from 'formik'
import { formatValuesForFormik } from 'utils/helpers'

import { useFetchResource, useUpdateResource, RESOURCES } from 'api'
import { WillCase } from 'lib/models/will-case'
import { Will } from 'lib/models/will'
import RequestStatus from 'components/request-status'

import SimpleTelephoneWill from 'routes/will-case/simple-telephone-will'
import DemographicData from './demographic-data'
import FarewillServices from './farewill-services'
import { UpdateResourceFn } from './types'
import Gifts from './gifts'
import { useWillFactFindContext, WillFactFindProvider } from './context'
import HighlightEmptySwitch from './highlight-empty-switch'
import FormStatus from './form-status'

type Props = RouteComponentProps<{ willId: string; id: string }>

const FactFind = ({ match }: Props): React.ReactElement => {
  const { highlightEmpty } = useWillFactFindContext()
  const willCaseQuery = useFetchResource<WillCase>({
    id: Number(match.params.id),
    resource: RESOURCES.WILL_CASES,
  })

  const willQuery = useFetchResource<Will>({
    id: Number(match.params.willId),
    resource: RESOURCES.WILLS,
  })

  const mutation = useUpdateResource()

  if (
    willQuery.isLoading ||
    willQuery.isIdle ||
    willCaseQuery.isLoading ||
    willCaseQuery.isIdle
  )
    return <div>Loading...</div>

  if (!willQuery.data || !willCaseQuery.data)
    return <P size="L">There is no data to display.</P>

  const isPartner =
    willQuery.data.attributes?.contactId !==
    willCaseQuery.data.attributes?.contactId

  const updateWill: UpdateResourceFn = ({ name, value }) => {
    const attributes = { [name]: value || null }
    mutation.mutate({
      id: willQuery.data.id,
      attributes,
      resource: RESOURCES.WILLS,
    })
  }

  const updateContact: UpdateResourceFn = ({ name, value }) => {
    const attributes = { [name]: value || null }
    mutation.mutate({
      id: willQuery.data.attributes.contactId,
      attributes,
      resource: RESOURCES.CONTACTS,
    })
  }

  return (
    <Wrapper>
      <H size="M">Fact Find</H>
      <SimpleTelephoneWill
        willCaseId={willCaseQuery.data.id}
        isPartner={isPartner}
      />
      <Divider margin={['L', 0, 'M']} />
      <Formik
        enableReinitialize
        initialValues={{
          ...formatValuesForFormik(willQuery.data.attributes),
          ...formatValuesForFormik(willQuery.data.attributes.contact),
        }}
        onSubmit={() => undefined}
        initialStatus={{ highlightEmpty }}
      >
        <Form>
          <FormStatus />
          <HighlightEmptySwitch />
          <Divider margin={['M', 0, 'L']} />
          <DemographicData
            updateWill={updateWill}
            updateContact={updateContact}
          />
          <Divider margin={['L', 0]} />
          <Gifts updateWill={updateWill} />
          <Divider margin={['L', 0]} />
          <FarewillServices updateWill={updateWill} />
        </Form>
      </Formik>
      <RequestStatus isSaving={mutation.isLoading} />
    </Wrapper>
  )
}

const FactFindWithProvider = (props: Props): React.ReactElement => (
  <WillFactFindProvider>
    <FactFind {...props} />
  </WillFactFindProvider>
)

export default FactFindWithProvider
