import { ReactElement } from 'react'
import { useDispatch } from 'react-redux'

import { Button, Grid, H, P } from '@farewill/ui'

import { createEvent, showModal } from 'state/actions'
import { useContactContext } from './context'
import PartnerDataSharingModal from './partner-data-sharing-modal'

const PartnerDataSharing = (): ReactElement => {
  const dispatch = useDispatch()
  const { contact, updateContact } = useContactContext()
  const objectsToPartnerDataSharing =
    contact.attributes.partnerDataSharing === 'objected'

  return (
    <>
      <Grid.Item margin={['S', 0, 0, 0]}>
        <H size="XS">Partner data sharing</H>
      </Grid.Item>
      <Grid.Item>
        {objectsToPartnerDataSharing ? (
          <P>Objects to data sharing</P>
        ) : (
          <>
            <span>We're sharing this person's data: </span>
            <Button.Underline
              flush
              type="button"
              onClick={() => {
                dispatch(
                  showModal({
                    component: PartnerDataSharingModal,
                    headingText: 'Partner data sharing',
                    config: {
                      objectsToPartnerDataSharing,
                      handleSubmit: async ({
                        objectsToPartnerDataSharing,
                      }: {
                        objectsToPartnerDataSharing: boolean
                      }) => {
                        /** We only need to update the contact if they object to partner data sharing.
                         * We currently do not support a contact opting back in to partner data sharing.
                         */
                        if (!objectsToPartnerDataSharing) return

                        await updateContact({
                          doNotContact: true,
                          partnerDataSharing: 'objected',
                        })

                        const eventAttributes = {
                          contactId: contact.id,
                          date: new Date(),
                          name: 'objected_to_partner_data_sharing',
                        }

                        return dispatch(createEvent(eventAttributes))
                      },
                    },
                  })
                )
              }}
            >
              Stop data sharing
            </Button.Underline>
          </>
        )}
      </Grid.Item>
    </>
  )
}

export default PartnerDataSharing
