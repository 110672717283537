export type InvoiceLineItem = {
  price: number
  id: number
  invoiceId: number
  description?: string
}

export type InvoiceAttributes = {
  createdAt: string
  funeralId?: number
  issuedDate: string | null
  paymentProvider: string
  paidOn?: string | null
  paidBy?: 'dwp' | 'other'
  reference?: string
  externalReferenceType?: 'xero'
  externalReferenceId?: string
  paymentProviderInvoiceId?: string
  lineItems: InvoiceLineItem[]
}

export type Invoice = {
  id: number
  attributes: InvoiceAttributes
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const invoiceTotalPrice = (invoice: Invoice) =>
  invoice.attributes.lineItems.reduce(
    (acc, lineItem) => acc + lineItem.price,
    0
  )

export type FuneralInvoice = {
  amount: number | ''
  date: string | null
  externalReferenceId: string
  paidBy: 'dwp' | 'other'
  reference: string
  isPaid?: 'yes' | 'no'
}
