import moment from 'moment'

const END_TAX_MONTH_AND_DAY = '-04-05'
const START_TAX_MONTH_AND_DAY = '-04-06'
const TAX_YEAR_FORMAT = 'D MMMM YYYY'

export const getGiftTaxYears = (dateOfDeath) => {
  const yearOfDeath = moment(dateOfDeath).year()
  let startTaxYear = moment(`${yearOfDeath - 1}${START_TAX_MONTH_AND_DAY}`)
  let endTaxYear = moment(`${yearOfDeath}${END_TAX_MONTH_AND_DAY}`)

  if (moment(dateOfDeath) > endTaxYear) {
    startTaxYear = moment(`${yearOfDeath}${START_TAX_MONTH_AND_DAY}`)
    endTaxYear = moment(`${yearOfDeath + 1}${END_TAX_MONTH_AND_DAY}`)
  }

  const taxDates = []
  for (let i = 0; i < 8; i++) {
    const startDate = startTaxYear.format(TAX_YEAR_FORMAT)
    const endDate = endTaxYear.format(TAX_YEAR_FORMAT)
    taxDates.push(`${startDate} - ${endDate}`)

    startTaxYear = startTaxYear.subtract(1, 'year')
    endTaxYear = endTaxYear.subtract(1, 'year')
  }

  return taxDates
}
