import { Component } from 'react'
import PropTypes from 'prop-types'
import { P } from '@farewill/ui'

class WillPdf extends Component {
  state = {
    isLoading: true,
  }

  fetchWillPdfUrl = async () => {
    this.setState({ isLoading: true })
    await this.props.fetchWillPdfUrl(this.props.willKey)
    this.setState({ isLoading: false })
  }

  componentDidMount() {
    this.fetchWillPdfUrl()
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.url && this.props.url) {
      window.location.assign(this.props.url)
    }
  }

  render() {
    if (this.state.isLoading) {
      return <P>Loading...</P>
    }

    return null
  }
}

WillPdf.propTypes = {
  willKey: PropTypes.string.isRequired,
  url: PropTypes.string,
}

export default WillPdf
