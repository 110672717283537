import { Link } from 'react-router-dom'
import upperFirst from 'lodash/upperFirst'
import identity from 'lodash/identity'
import { Button } from '@farewill/ui'

import { showModal } from 'state/actions'

import EstatePersonModal from './estate-person-modal'

import {
  APPLICATION_TYPE_OPTIONS,
  TAX_FORM_OPTIONS,
} from '../../../../utils/enums'

export const getFullName = (firstName, lastName) => {
  return [firstName, lastName].filter(identity).join(' ')
}

export const isNotAuthorizedToBeIncludedInComms = (person) => {
  const isIncludedInCommsOnly =
    person.isIncludedInComms &&
    !person.isCustomer &&
    !person.isPersonalRepresentative &&
    !person.isPointOfContact

  return isIncludedInCommsOnly && !person.authorizedToBeIncludedInComms
}

const createContactString = (person) => {
  let contactString = []

  if (person.isCustomer) contactString.push('customer')
  if (person.isPointOfContact) contactString.push('point of contact')
  if (person.isPersonalRepresentative)
    contactString.push('personal representative')
  if (person.isIncludedInComms) contactString.push('include in all comms')

  return upperFirst(contactString.join(' and '))
}

export const getContactSentence = (dispatch, person, deceasedFirstName) => {
  if (
    !person.isCustomer &&
    !person.isPointOfContact &&
    !person.isPersonalRepresentative &&
    !person.isIncludedInComms
  )
    return []

  let contactSentence = []

  contactSentence.push(`${createContactString(person)}: `)

  contactSentence.push(
    <Button.Underline
      type="button"
      onClick={() =>
        dispatch(
          showModal({
            component: EstatePersonModal,
            headingText:
              getFullName(person.firstNames, person.lastName) ||
              'No name provided',
            config: { person },
          })
        )
      }
    >
      {isNotAuthorizedToBeIncludedInComms(person) && '🚨'}
      {getFullName(person.firstNames, person.lastName) || 'No name provided'}.
    </Button.Underline>
  )

  if (person.deceasedRelationshipToPerson) {
    const { customDeceasedRelationshipToPerson, deceasedRelationshipToPerson } =
      person

    const relationship =
      customDeceasedRelationshipToPerson || deceasedRelationshipToPerson

    if (relationship === 'other') {
      contactSentence.push(' (No relationship specified)')
    } else {
      contactSentence.push(
        ` ${deceasedFirstName} is ${person.firstNames}'s ${relationship}.`
      )
    }
  }

  if (person.isActingOnBehalfOfSomeoneElse) {
    contactSentence.push(
      ` ${person.firstNames} is acting on behalf of someone else.`
    )
  }

  return contactSentence
}

export const getProbateEstateDetails = (probateEstate, probateCase) => {
  if (!probateEstate) return []

  const {
    probateCaseId,
    hasWill,
    hasForeignWills,
    hasCodicils,
    willIsUndatedOrUnsigned,
    willIsUnbound,
    willHasIncorrectStatementsAboutWitnesses,
    willHasTrusts,
    willIsDiy,
    willHasShakySignature,
    willHasTearsOrMarks,
    willFeelsUnsigned,
    willHasHandwrittenAmendments,
    willHasUninitialledAmendments,
    willHasInContemplationOfMarriageClause,
    willHasIssuesWithCondition,
    willHasCodicilReplacingExecutors,
    wasAdopted,
  } = probateEstate.attributes

  const {
    taxForm,
    estateAdministrationRequired,
    applicationTypeSubmitted,
    applicationReferenceNumber,
    hasError,
  } = probateCase

  let probateEstateDetails = []

  const willOrIntestacy = hasWill
    ? 'Will'
    : hasWill === false
    ? 'Intestacy'
    : null

  const taxFormType = TAX_FORM_OPTIONS.find(
    (option) => option.value === taxForm
  )?.label

  const grantOrEstateAdmin = estateAdministrationRequired
    ? 'Complete Probate'
    : estateAdministrationRequired === false
    ? 'Essential Probate'
    : null

  const applicationType = APPLICATION_TYPE_OPTIONS.find(
    (option) => option.value === applicationTypeSubmitted
  )?.label

  if (willOrIntestacy)
    probateEstateDetails.push(
      <Link to={`/probate/estates/${probateEstate.id}/fact-find#2`}>
        {willOrIntestacy}
      </Link>
    )
  if (hasForeignWills === 'yes')
    probateEstateDetails.push(
      <Link to={`/probate/estates/${probateEstate.id}/fact-find#2`}>
        Foreign will
      </Link>
    )
  if (hasCodicils === 'yes')
    probateEstateDetails.push(
      <Link to={`/probate/estates/${probateEstate.id}/fact-find#2`}>
        Codicil
      </Link>
    )
  if (willIsUndatedOrUnsigned)
    probateEstateDetails.push(
      <Link to={`/probate/estates/${probateEstate.id}/internal-checks`}>
        Undated / unsigned
      </Link>
    )
  if (willIsUnbound)
    probateEstateDetails.push(
      <Link to={`/probate/estates/${probateEstate.id}/internal-checks`}>
        Unbound
      </Link>
    )
  if (willHasIncorrectStatementsAboutWitnesses)
    probateEstateDetails.push(
      <Link to={`/probate/estates/${probateEstate.id}/internal-checks`}>
        Incorrect statements about witnesses
      </Link>
    )
  if (willHasTrusts)
    probateEstateDetails.push(
      <Link to={`/probate/estates/${probateEstate.id}/internal-checks`}>
        Trusts in will
      </Link>
    )
  if (willIsDiy)
    probateEstateDetails.push(
      <Link to={`/probate/estates/${probateEstate.id}/internal-checks`}>
        DIY will
      </Link>
    )
  if (willHasShakySignature)
    probateEstateDetails.push(
      <Link to={`/probate/estates/${probateEstate.id}/internal-checks`}>
        Shaky signature
      </Link>
    )
  if (willHasTearsOrMarks)
    probateEstateDetails.push(
      <Link to={`/probate/estates/${probateEstate.id}/internal-checks`}>
        Tears or marks
      </Link>
    )
  if (willFeelsUnsigned)
    probateEstateDetails.push(
      <Link to={`/probate/estates/${probateEstate.id}/internal-checks`}>
        Feels unsigned
      </Link>
    )
  if (willHasHandwrittenAmendments)
    probateEstateDetails.push(
      <Link to={`/probate/estates/${probateEstate.id}/internal-checks`}>
        Handwritten amendments
      </Link>
    )
  if (willHasUninitialledAmendments)
    probateEstateDetails.push(
      <Link to={`/probate/estates/${probateEstate.id}/internal-checks`}>
        Amendments not initialled
      </Link>
    )
  if (willHasInContemplationOfMarriageClause)
    probateEstateDetails.push(
      <Link to={`/probate/estates/${probateEstate.id}/internal-checks`}>
        In contemplation of marriage
      </Link>
    )
  if (willHasIssuesWithCondition)
    probateEstateDetails.push(
      <Link to={`/probate/estates/${probateEstate.id}/internal-checks`}>
        Issues with will’s condition
      </Link>
    )
  if (willHasCodicilReplacingExecutors)
    probateEstateDetails.push(
      <Link to={`/probate/estates/${probateEstate.id}/internal-checks`}>
        Codicil replacing executors
      </Link>
    )
  if (wasAdopted)
    probateEstateDetails.push(
      <Link to={`/probate/estates/${probateEstate.id}/fact-find#2`}>
        Adopted
      </Link>
    )
  if (taxFormType) probateEstateDetails.push(taxFormType)
  if (grantOrEstateAdmin) probateEstateDetails.push(grantOrEstateAdmin)
  if (applicationType || applicationReferenceNumber)
    probateEstateDetails.push(
      <Link to={`/probate/estates/${probateEstate.id}/probate-application`}>
        {applicationType}
        {applicationReferenceNumber &&
          ` (application reference ${applicationReferenceNumber})`}
      </Link>
    )
  if (hasError)
    probateEstateDetails.push(
      <Link to={`/probate/cases/${probateCaseId}/errors`}>Error logged</Link>
    )

  return probateEstateDetails
}
