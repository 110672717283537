export default function requestsPending(state = ['EXISTING'], action = {}) {
  const REQUEST_PENDING = action.type.includes('_PENDING')
  const REQUEST_FULFILLED = action.type.includes('_FULFILLED')
  const REQUEST_REJECTED = action.type.includes('_REJECTED')

  switch (true) {
    case REQUEST_PENDING:
      return [...state, action.type.replace('_PENDING', '')]

    case REQUEST_FULFILLED:
      return state.filter((type) => type + '_FULFILLED' !== action.type)

    case REQUEST_REJECTED:
      return state.filter((type) => type + '_REJECTED' !== action.type)

    default:
      return state
  }
}
