import styled from 'styled-components'
import { Grid, Wrapper, P } from '@farewill/ui'
import { COLOR, GTR, BORDER } from '@farewill/ui/tokens'
import { Link } from 'react-router-dom'

import { FuneralPlan } from 'lib/models/funeral-plan'

const StyledMessageWrapper = styled(Wrapper)`
  border: 2px solid ${COLOR.STATE.SUCCESS};
  border-radius: ${BORDER.RADIUS.S};
  padding: ${GTR.XS};
`

const ConvertedMessage = ({ funeralPlan }: { funeralPlan: FuneralPlan }) => {
  const url = `/leads/${funeralPlan.attributes.funeralLead?.id}`

  return (
    <Grid.Item>
      <StyledMessageWrapper>
        <P size="S">
          This funeral plan has been converted.
          <br />
          <Link to={url}>View the Funeral lead</Link>
        </P>
      </StyledMessageWrapper>
    </Grid.Item>
  )
}

export default ConvertedMessage
