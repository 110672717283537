import styled from 'styled-components'
import { P, Wrapper } from '@farewill/ui'
import { GTR } from '@farewill/ui/tokens'
import map from 'lodash/map'
import lowerCase from 'lodash/lowerCase'
import moment from 'moment'

import AdminIcon from 'components/admin-icon'
import ActivityTimestamp from 'components/activity-timestamp'

import { TASK_ASSIGNMENT_ROLES } from './constants'

const ACTIONS = {
  dueOn: 'set',
}

const ATTRIBUTES = {
  dueOn: 'due date',
  completedAt: 'completed date',
}

const getFormattedValue = (key, value) => {
  if (['dueOn', 'completedAt'].includes(key)) {
    return moment(value).format('D MMM YYYY')
  }
  return value
}

const getChangesText = (changes) => {
  return map(changes, (value, key) => {
    const action = ACTIONS[key] || 'updated'
    const attribute = ATTRIBUTES[key] || lowerCase(key)
    const formattedValue = getFormattedValue(key, value)

    if (!value) return <span key={key}>removed the {attribute}</span>

    return (
      <span key={key}>
        {action} the {attribute} to <strong>{formattedValue}</strong>
      </span>
    )
  })
}

const getCreatedText = (taskEvent) => {
  const { assignment, changes, resourceType } = taskEvent.attributes
  switch (resourceType) {
    case 'tasks':
      return (
        <>
          added this task{' '}
          {changes.title ? (
            <>
              with the title <strong>{changes.title}</strong>
            </>
          ) : (
            'when the case converted'
          )}
        </>
      )
    case 'task_assignments': {
      const action =
        assignment.role === TASK_ASSIGNMENT_ROLES.HELP_REQUESTED
          ? 'asked for help from'
          : 'assigned this task to'
      return (
        <>
          {action} <strong>{assignment.adminUser.name}</strong>
        </>
      )
    }
    default:
      return ''
  }
}

const getDeletedText = (taskEvent) => {
  const { assignment, resourceType } = taskEvent.attributes
  switch (resourceType) {
    case 'task_assignments':
      return assignment.role === TASK_ASSIGNMENT_ROLES.HELP_REQUESTED ? (
        'marked the request for help as resolved'
      ) : (
        <>
          removed <strong>{assignment.adminUser.name}</strong> from this task
        </>
      )
    default:
      return ''
  }
}

const getTaskEventText = (taskEvent) => {
  const { action, changes } = taskEvent.attributes

  switch (action) {
    case 'created':
      return getCreatedText(taskEvent)
    case 'completed':
      return 'completed this task'
    case 'uncompleted':
      return 'marked this task as incomplete'
    case 'updated':
      return getChangesText(changes)
    case 'deleted':
      return getDeletedText(taskEvent)
    default:
      return ''
  }
}

const StyledWrapper = styled(Wrapper)`
  display: flex;
  margin-bottom: ${GTR.M};
`

const TaskEvent = ({ taskEvent }) => (
  <StyledWrapper>
    <AdminIcon user={taskEvent.attributes.adminUser} size="L" />
    <Wrapper>
      <P size="S" margin={0}>
        <strong>{taskEvent.attributes.adminUser.name}</strong>{' '}
        {getTaskEventText(taskEvent)}
      </P>
      <ActivityTimestamp datetime={taskEvent.attributes.createdAt} />
    </Wrapper>
  </StyledWrapper>
)

export default TaskEvent
