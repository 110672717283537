import { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'

import { formatDate } from 'lib/formatting/dates'
import CopyToClipboard from 'components/copy-to-clipboard'

import ProbateSummary from './probate'
import WillCaseSummary from './will-case'
import Notes from './notes'
import { fetchLeadEvents } from 'state/actions'

const PRODUCT_SUMMARY_COMPONENTS = {
  probate: ProbateSummary,
  will: WillCaseSummary,
}

const SummaryAndNotes = ({
  lead,
  productEvents,
  leadEventsWithNotes,
  productType,
}) => {
  const SummaryComponent = PRODUCT_SUMMARY_COMPONENTS[productType]
  return (
    <>
      <SummaryComponent
        attributes={lead.attributes}
        productEvents={productEvents}
      />
      <br />
      <Notes leadEvents={leadEventsWithNotes} />
    </>
  )
}

const ProductSummary = ({
  lead,
  productEvents,
  productType,
  showCopyToClipboardButton,
}) => {
  const dispatch = useDispatch()
  const leadEvents = useSelector((state) => state.leadEvents)

  useEffect(() => {
    dispatch(fetchLeadEvents({ params: { leadId: lead.id } }))
  }, [dispatch, lead.id])

  const leadEventsWithNotes = leadEvents.filter(
    (event) => event.attributes.metadata.notes
  )

  return showCopyToClipboardButton ? (
    <CopyToClipboard
      buttonText="Copy quote to clipboard"
      hiddenChildren={
        <>
          <h3>Farewill Quote</h3>
          <p>Last Updated: {formatDate(lead.attributes.updatedAt)}</p>
        </>
      }
      visibleChildren={
        <SummaryAndNotes
          lead={lead}
          productEvents={productEvents}
          leadEventsWithNotes={leadEventsWithNotes}
          productType={productType}
        />
      }
    />
  ) : (
    <SummaryAndNotes
      lead={lead}
      productEvents={productEvents}
      leadEventsWithNotes={leadEventsWithNotes}
      productType={productType}
    />
  )
}

ProductSummary.propTypes = {
  lead: PropTypes.shape({
    id: PropTypes.number,
    attributes: PropTypes.object,
  }),
  productEvents: PropTypes.arrayOf(
    PropTypes.shape({
      attributes: PropTypes.shape({
        metadata: PropTypes.object,
      }),
    })
  ),
  productType: PropTypes.string,
  showCopyToClipboardButton: PropTypes.bool,
}

ProductSummary.defaultProps = {
  showCopyToClipboardButton: true,
}

export default ProductSummary
