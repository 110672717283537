import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'

import styled from 'styled-components'
import { H, Grid, Wrapper, Button } from '@farewill/ui'
import { COLOR, GTR } from '@farewill/ui/tokens'

import StyledLoader from 'components/loader'
import MessageBox from 'components/message-box'
import { Uppercase } from 'components/styled/uppercase'
import { PartnerSummary } from 'lib/models/partner'
import AccountsTable from 'routes/partners/components/accounts-table'
import { AddAccountModal } from 'routes/partners/components/accounts-table/add-account-modal'
import ProductPills from 'routes/partners/components/product-pills'
import PartnerSummaryTable from 'routes/partners/components/summary-table'
import { showModal } from 'state/actions'

const StyledAccountsHeader = styled(H)`
  border-top: 1px solid ${COLOR.GREY.LIGHT};
`

const StyledAddUserButton = styled(Button.Underline)`
  padding: ${GTR.M} 0;
`

interface OverviewTypes {
  partner?: PartnerSummary
  isLoading: boolean
}

const Overview = ({
  partner,
  isLoading,
}: OverviewTypes): React.ReactElement => {
  const dispatch = useDispatch()
  if (isLoading) return <StyledLoader />
  if (!partner) return <MessageBox error>Something has gone wrong.</MessageBox>
  const types = []
  if (partner.charity) types.push('Charity')
  if (partner.organisation) types.push('Organisation')
  const isPartnerDataSharingEnabledPartner = !!partner.dataSharingReportType

  const onAddClick = () => {
    dispatch(
      showModal({
        component: AddAccountModal,
        headingText: 'Add a Spotlight user',
        config: {
          partnerId: partner.id,
          isPartnerDataSharingEnabledPartner,
        },
      })
    )
  }

  return (
    <Wrapper padding={[GTR.L, 0, 0]}>
      <Grid>
        <Grid.Item spanFromL={7}>
          <Grid.Item>
            <Uppercase>{types.join(' & ')}</Uppercase>
          </Grid.Item>
          <H size="XL" decorative>
            <Grid.Item>{`${
              partner.charity?.legalName || partner.organisation?.name
            }`}</Grid.Item>
          </H>
          <H size="S">
            <Grid.Item>Partner details</Grid.Item>
          </H>
        </Grid.Item>
        {!!partner.products.length && (
          <ProductPills products={partner.products} />
        )}
        <Grid.Item spanFromL={7}>
          <PartnerSummaryTable values={partner} />
        </Grid.Item>

        <Grid.Item>
          <Button.Primary tag={Link} to={`/partners/${partner.id}/update`}>
            Edit partner details
          </Button.Primary>
        </Grid.Item>

        <Grid.Item spanFromL={7}>
          <StyledAccountsHeader size="S" padding={[GTR.XL, 0, 0]}>
            <Grid.Item>Spotlight users</Grid.Item>
          </StyledAccountsHeader>
          <Grid.Item spanFromL={7}>
            <AccountsTable
              accounts={partner.accounts}
              isPartnerDataSharingEnabledPartner={
                isPartnerDataSharingEnabledPartner
              }
            />
          </Grid.Item>
          <Grid.Item>
            <StyledAddUserButton onClick={onAddClick}>
              Add a new user
            </StyledAddUserButton>
          </Grid.Item>
        </Grid.Item>
      </Grid>
    </Wrapper>
  )
}
export default Overview
