export const ESTATE_PROPERTIES_ATTRIBUTES = {
  ADDRESS: 'address',
  REGISTRY_CHECK_STATUS: 'registryCheckStatus',
  VALUE: 'value',
  IS_ESTIMATED_VALUE: 'isEstimatedValue',
  OWNERSHIP: 'ownership',
  JOINT_OWNERSHIP_TYPE: 'jointOwnershipType',
  DECEASED_SHARE_OF_JOINT_OWNERSHIP: 'deceasedShareOfJointOwnership',
  HAS_CHARGES: 'hasCharges',
  DECEASED_SHARE_OF_CHARGES_VALUE: 'deceasedShareOfChargesValue',
  INTENTIONS: 'intentions',
  IS_MAIN_RESIDENCE: 'isMainResidence',
  NOTES: 'notes',
} as const
