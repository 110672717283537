import { Grid } from '@farewill/ui'

import useNamespace from 'lib/formik/namespace'
import InputFloating from 'components/form/input-floating'

type Props = {
  namespace?: string
}

const OrganisationFormFields = ({ namespace }: Props): React.ReactElement => {
  const { withNamespace } = useNamespace(namespace ? `${namespace}.` : '')
  return (
    <Grid gap="XS">
      <Grid.Item>
        <InputFloating name={withNamespace('name')} label="Name" />
      </Grid.Item>
      <Grid.Item>
        <InputFloating
          name={withNamespace('number')}
          label="Registration number"
        />
      </Grid.Item>
      <Grid.Item>
        <InputFloating
          name={withNamespace('address')}
          label="Registered address"
        />
      </Grid.Item>
      <Grid.Item>
        <InputFloating name={withNamespace('notes')} label="Notes" />
      </Grid.Item>
    </Grid>
  )
}

export default OrganisationFormFields
