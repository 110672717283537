import { ReactNode } from 'react'
import { Wrapper } from '@farewill/ui'
import Skip from 'components/skip'
import StatusPanel from 'components/status-panel'

export type SectionStatusProps = {
  complete: boolean
  children?: ReactNode
  heading: ReactNode
  incompleteHeading?: string
  incompleteText?: string
  incompleteContent?: ReactNode
  next?: string
}

export const SectionStatus = ({
  complete,
  children,
  heading,
  incompleteHeading,
  incompleteText,
  incompleteContent,
  next,
}: SectionStatusProps): React.ReactElement => {
  const IncompleteContent = incompleteContent
  return (
    <StatusPanel
      highlight={complete}
      heading={complete ? heading : incompleteHeading}
    >
      {complete && <Wrapper>{children}</Wrapper>}
      {!complete && (
        <Wrapper>
          {incompleteContent ? IncompleteContent : incompleteText}
        </Wrapper>
      )}
      {complete && next && (
        <Wrapper margin={['L', 0, 0]}>
          <Skip next={next}>Continue to next section</Skip>
        </Wrapper>
      )}
    </StatusPanel>
  )
}

SectionStatus.defaultProps = {
  complete: false,
  incompleteHeading: 'More info required',
  incompleteText: 'Please complete the questions to the left',
}

export default SectionStatus
