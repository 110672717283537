import { FormikContextType } from 'formik'
import { LookupAddress } from 'lib/models/address'
import get from 'lodash/get'
import identity from 'lodash/identity'

interface GetFieldStateProps<T> {
  formik: Partial<FormikContextType<T>>
  name: string
}

export const getFieldState = <T>({ formik, name }: GetFieldStateProps<T>) => {
  const { errors, touched, status, values } = formik

  const hasError = !!get(errors, name) && !!get(touched, name)
  const value = get(values, name)
  const hasValue = value?.toString()?.length
  const hasHighlight = status?.highlightEmpty && !hasValue

  return { hasError, hasValue, hasHighlight }
}

export const formatLoqateAddress = (address: LookupAddress) => {
  if (address.type !== 'Commercial' || !address.company) {
    return address
  }

  return {
    ...address,
    lineOne: address.company,
    lineTwo: [address.lineOne, address.lineTwo].filter(identity).join(', '),
  }
}
