export const PROBATE_LEAD_FORM_VALUES = {
  NOTES: 'notes',
  DECEASED_SHARE_OF_JOINT_OWNERSHIP: 'deceasedShareOfJointOwnership',
  DESCRIPTION: 'description',
  JOINT_OWNER_NAME: 'jointOwnerName',
  LUMP_SUM_PAYABLE_TO: 'lumpSumPayableTo',
  OWNERSHIP: 'ownership',
  PROVIDER_NAME: 'providerName',
  VALUE: 'value',
  HOUSEHOLD_POSSESSIONS_VALUE: 'householdPossessionsValue',
  IS_ESTIMATED_VALUE: 'isEstimatedValue',
  IS_INSURANCE_PAID_OUT: 'isInsurancePaidOut',
  IS_LUMP_SUM_PENSION_PAYOUT: 'isLumpSumPensionPayout',
  HAS_THE_POLICY_PAID_OUT: 'isInsurancePaidOut',
  WHO_IS_PENSION_PAYABLE_TO: 'lumpSumPayableTo',
} as const

// Sections
export const PROBATE_LEAD_FORM_SECTIONS = {
  BANK_ACCOUNTS: 'bankAccountsSectionActive',
  LIFE_INSURANCE: 'lifeInsuranceSectionActive',
  NATIONAL_SAVINGS_AND_INVESTMENTS:
    'nationalSavingsAndInvestmentsSectionActive',
  OVERSEAS_ASSETS: 'overseasAssetsSectionActive',
  VEHICLES_AND_POSESSIONS: 'vehiclesAndPossessionsSectionActive',
  SHARES_AND_INVESTMENTS: 'publicShareholdingsSectionActive',
  PRIVATE_PENSIONS: 'privatePensionsSectionActive',
  BUSINESS_OR_AGRICULTURAL_ASSETS: 'businessOrAgriculturalAssetsSectionActive',
  GIFTS_OVER_3K: 'giftsOver3kSection',
  CREDIT_CARDS_SECTION_ACTIVE: 'creditCardsSectionActive',
  UTILITIES_AND_OTHER_DEBTS_SECTION_ACTIVE:
    'utilitiesAndOtherDebtsSectionActive',
  FUNERAL_SECTION_ACTIVE: 'funeralSectionActive',
  GIFTS_CONTINUED_BENEFIT: 'giftsContinuedBenefitSection',
  BENEFITTED_FROM_TRUSTS: 'benefittedFromTrustsSection',
  PROPERTIES: 'propertySectionActive',
} as const
