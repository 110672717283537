import PropTypes from 'prop-types'
import { useFormikContext } from 'formik'
import { Grid, P } from '@farewill/ui'
import { Link } from 'react-router-dom'
import moment from 'moment'

import { CONFIRMED_AND_CHECKED_OPTIONS } from 'utils/enums'

import CircularRadioGroup from 'components/form/circular-radio-group'
import InputFloating from 'components/form/input-floating'
import PinkBox from 'components/styled/pink-box'
import MessageBox from 'components/message-box'
import IHT400Warning from './iht-400-warning'

import {
  formatDate,
  getDateOfDeath,
  getDateOfDeathIsOnOrAfter20100406,
} from '../../helpers'

import {
  CONFIRMED_DEATH_ON_OR_AFTER_20100406,
  CONFIRMED_DEATH_ON_OR_AFTER_20100406_NOTES,
} from '../field-names'

const DateOfDeathChecks = ({ factFindUrl, saveProbateEstateField }) => {
  const { values } = useFormikContext()
  const dateOfDeath = getDateOfDeath(values)

  if (!dateOfDeath) {
    return (
      <MessageBox warning>
        Date of death is missing or incomplete - please update before continuing
        (<Link to={factFindUrl + '#1.3'}>1.3</Link>)
      </MessageBox>
    )
  }

  const formattedDateOfDeath = formatDate(dateOfDeath)
  const dateOfDeathIsOnOrAfter20100406 =
    getDateOfDeathIsOnOrAfter20100406(values)
  const fullMonthsSinceDateOfDeath = moment().diff(dateOfDeath, 'months')
  const dateOfDeathIsWithinTwoYears = fullMonthsSinceDateOfDeath < 24

  return (
    <>
      <Grid gap={['S', 'M']} margin={[0, 0, 'L']}>
        <Grid.Item>
          <P strong>Did they die on or after 6 April 2010</P>
          <PinkBox stretch>
            According to fact find,{' '}
            <strong>{dateOfDeathIsOnOrAfter20100406 ? 'yes' : 'no'}</strong> –
            they died on {formattedDateOfDeath} (
            <Link to={factFindUrl + '#1.3'}>1.3</Link>)
          </PinkBox>
        </Grid.Item>
        <Grid.Item>
          <CircularRadioGroup
            name={CONFIRMED_DEATH_ON_OR_AFTER_20100406}
            options={CONFIRMED_AND_CHECKED_OPTIONS}
            handleSave={saveProbateEstateField}
            inline
          />
        </Grid.Item>
        <Grid.Item span={9}>
          <InputFloating
            name={CONFIRMED_DEATH_ON_OR_AFTER_20100406_NOTES}
            label="How have you confirmed this?"
            handleSave={saveProbateEstateField}
          />
        </Grid.Item>
        {!dateOfDeathIsOnOrAfter20100406 && (
          <Grid.Item span={9}>
            <IHT400Warning />
          </Grid.Item>
        )}
      </Grid>
      <Grid gap={['S', 'M']}>
        <Grid.Item>
          <P strong>Is it less than 24 months since they died?</P>
        </Grid.Item>
        <Grid.Item>
          <PinkBox>
            According to fact find,{' '}
            <strong>{dateOfDeathIsWithinTwoYears ? 'yes' : 'no'}</strong> – from
            the date of death ({formattedDateOfDeath}) to today (
            {formatDate(moment())}) is {fullMonthsSinceDateOfDeath} full months{' '}
            (assuming date of death is correct - see above)
          </PinkBox>
        </Grid.Item>
        {!dateOfDeathIsWithinTwoYears && (
          <Grid.Item span={9}>
            <IHT400Warning />
          </Grid.Item>
        )}
      </Grid>
    </>
  )
}

DateOfDeathChecks.propTypes = {
  factFindUrl: PropTypes.string.isRequired,
  saveProbateEstateField: PropTypes.func.isRequired,
}

export default DateOfDeathChecks
