import { P, Wrapper } from '@farewill/ui'
import { GTR } from '@farewill/ui/tokens'
import styled from 'styled-components'
import omit from 'lodash/omit'
import isObject from 'lodash/isObject'
import isEmpty from 'lodash/isEmpty'

import AdminIcon from 'components/admin-icon'
import ActivityTimestamp from 'components/activity-timestamp'

import { Event as EventType } from 'lib/models/event'
import { formatToHuman } from 'utils/helpers'

const StyledWrapper = styled(Wrapper)`
  display: flex;
  align-items: center;
  margin-bottom: ${GTR.L};
`

const getToFromValue = (value: string | Record<string, string>): string => {
  if (isObject(value)) {
    /**
     * If the to/from objects are objects, we don't need to print the
     * id, we can just print the other values in the object. There
     * will likely only be one other value in the object, like the
     * package name.
     */
    return Object.entries(omit(value, 'id'))
      .map(([_key, value]) => value)
      .join(', ')
  }

  return value
}

const formatMetadataValue = (value: string | string[] | object): string => {
  if (Array.isArray(value)) {
    const formattedStrings = value.map((string) => {
      return formatToHuman(string)
    })
    return formattedStrings.join(', ')
  }

  if (typeof value === 'object') {
    return ''
  }

  return formatToHuman(value)
}

const Event = ({ event }: { event: EventType }): React.ReactElement => {
  const { createdAt, adminUser, name, metadata } = event.attributes
  const additionalMetadata = omit(metadata, ['to', 'from'])

  return (
    <StyledWrapper>
      <AdminIcon user={adminUser} size="L" />
      <Wrapper>
        <P margin={0} size="S">
          {formatToHuman(name)}{' '}
          {metadata?.from && <>from {getToFromValue(metadata.from)} </>}
          {metadata?.to && (
            <>
              to <strong>{getToFromValue(metadata.to)}</strong>{' '}
            </>
          )}
          by <strong>{adminUser.name}</strong>
        </P>
        {!isEmpty(additionalMetadata) && (
          <P margin={0} size="S">
            {Object.entries(additionalMetadata).map(([key, value], index) => (
              <span key={key}>
                {index > 0 && ', '}
                {formatToHuman(key)}: {formatMetadataValue(value)}
              </span>
            ))}
          </P>
        )}
        <ActivityTimestamp datetime={createdAt} />
      </Wrapper>
    </StyledWrapper>
  )
}

export default Event
