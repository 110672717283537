import { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { P } from '@farewill/ui'
import { COLOR, GTR } from '@farewill/ui/tokens'

import styled from 'styled-components'
import useApi from 'lib/effects/api'
import { formatCurrency } from 'lib/formatting/currency'

const DEFAULT_NUM_UK_GRANTS = 6
const DEFAULT_NUM_OVERSEAS_GRANTS = 0

const StyledListItem = styled.li`
  display: flex;
  padding: ${GTR.S} 0;
  border-bottom: 1px solid ${COLOR.GREY.LIGHT};

  ${({ $isTitle }) => $isTitle && `padding-top: 0`}

  &:last-child {
    border-bottom: 0;
    padding-bottom: 0;
  }
`

const StyledP = styled(P)`
  flex-basis: 90%;
  margin: 0;
`

const StyledParagraph = styled(P)`
  flex-basis: 10%;
  margin: 0;

  ${({ $isTitle }) => $isTitle && `margin-left: auto`}
`

const getGrantPrice = (numberOfGrants, grantCopyPrice) => {
  return numberOfGrants * grantCopyPrice
}

const getLineItemPrice = ({ invoice, description }) => {
  return (
    invoice?.attributes.lineItems.find(
      (lineItem) => lineItem.description === description
    )?.price || 0
  )
}

const RegistryFees = ({ invoice, probateEstateId }) => {
  const courtFeePrice = getLineItemPrice({ invoice, description: 'court_fee' })
  const grantCopyPrice = getLineItemPrice({
    invoice,
    description: 'additional_grant_copies',
  })

  const [{ data: summary }, fetchSummary] = useApi()

  const netEstateString =
    summary?.attributes.financials.netEstateForGrant.value > 5000
      ? 'Estate over £5000'
      : 'Estate under £5000'

  const [{ data: probateEstate }, fetchProbateEstate] = useApi()

  const numOfUKGrants = probateEstate?.attributes.defaultNumberOfGrantsRequested
    ? DEFAULT_NUM_UK_GRANTS
    : probateEstate?.attributes.numberOfGrantsRequested
  const ukGrantPrice = getGrantPrice(numOfUKGrants, grantCopyPrice)
  const numOfOverseasGrants = probateEstate?.attributes.overseasGrantsRequested
    ? probateEstate?.attributes.numberOfOverseasGrantsRequested
    : DEFAULT_NUM_OVERSEAS_GRANTS
  const overseaGrantPrice = getGrantPrice(numOfOverseasGrants, grantCopyPrice)

  const totalPrice = courtFeePrice + ukGrantPrice + overseaGrantPrice

  useEffect(() => {
    const url = `/api/probate-estates/${probateEstateId}/summary`
    fetchSummary({ url })
  }, [fetchSummary, probateEstateId])

  useEffect(() => {
    fetchProbateEstate({ url: `/api/probate-estates/${probateEstateId}` })
  }, [fetchProbateEstate, probateEstateId])

  return (
    <>
      <P strong margin={0}>
        Registry fees
      </P>
      <ul>
        <StyledListItem $isTitle>
          <StyledParagraph $isTitle>Cost</StyledParagraph>
        </StyledListItem>
        <StyledListItem>
          <StyledP>
            Probate registry fee (includes 1 copy of the grant):{' '}
            {netEstateString}
          </StyledP>
          <StyledParagraph>
            {formatCurrency({
              value: courtFeePrice || 0,
              valueInPence: true,
            })}
          </StyledParagraph>
        </StyledListItem>
        <StyledListItem>
          <StyledP>
            <Link to={`/probate/estates/${probateEstateId}/fact-find#6.5`}>
              Additional copies for UK
            </Link>
            : {numOfUKGrants}
          </StyledP>
          <StyledParagraph>
            {formatCurrency({
              value: ukGrantPrice || 0,
              valueInPence: true,
            })}
          </StyledParagraph>
        </StyledListItem>
        <StyledListItem>
          <StyledP>
            <Link to={`/probate/estates/${probateEstateId}/fact-find#6.5`}>
              Additional copies for outside the UK
            </Link>
            : {numOfOverseasGrants}
          </StyledP>
          <StyledParagraph>
            {formatCurrency({
              value: overseaGrantPrice || 0,
              valueInPence: true,
            })}
          </StyledParagraph>
        </StyledListItem>
        <StyledListItem>
          <StyledP strong>Total</StyledP>
          <StyledParagraph strong>
            {formatCurrency({ value: totalPrice || 0, valueInPence: true })}
          </StyledParagraph>
        </StyledListItem>
      </ul>
    </>
  )
}

export default RegistryFees
