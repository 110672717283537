import { PropsWithChildren, useEffect } from 'react'
import { Form, useFormikContext } from 'formik'
import styled, { css } from 'styled-components'
import { Wrapper } from '@farewill/ui'
import { COLOR, GTR, BORDER } from '@farewill/ui/tokens'
import { Link } from 'react-router-dom'

import RequestStatus from 'components/request-status'

import { useLeadContext } from './context'
import { PRODUCTS } from './constants'

const DisableableForm = styled(Form)<{ disabled?: boolean }>`
  ${(props) =>
    props.disabled &&
    css`
      pointer-events: none;
    `}
`

const StyledMessageWrapper = styled(Wrapper)`
  border: 2px solid ${COLOR.STATE.SUCCESS};
  border-radius: ${BORDER.RADIUS.S};
  padding: ${GTR.XS};
  background-color: ${COLOR.WHITE};
`

type QuoteFormProps = {
  disabled?: boolean
}

const QuoteForm = ({
  disabled,
  children,
}: PropsWithChildren<QuoteFormProps>) => {
  const { errors, setTouched } = useFormikContext()
  const {
    lead,
    isContactUpdating,
    updateContactErrors,
    isLeadUpdating,
    updateLeadErrors,
    updateLeadMeta,
  } = useLeadContext()

  const { product } = lead.attributes
  const options = PRODUCTS[product]
  const productId = lead.attributes[options?.leadRelation]?.id

  const url = `${options.baseUrl}/${productId}`

  useEffect(() => {
    setTouched(errors)
  }, [errors, setTouched])

  return (
    <>
      {disabled ? (
        <StyledMessageWrapper separator>
          This lead has converted, so the quote is no longer editable.
          <br />
          <Link to={url}>View the customer page</Link>
        </StyledMessageWrapper>
      ) : (
        <RequestStatus
          validationErrors={errors}
          apiErrors={[...updateLeadErrors, ...updateContactErrors]}
          apiMessages={updateLeadMeta?.messages}
          isSaving={isContactUpdating || isLeadUpdating}
        />
      )}
      <DisableableForm disabled={disabled}>{children}</DisableableForm>
    </>
  )
}

export default QuoteForm
