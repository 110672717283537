import { useContext, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Formik, useFormikContext } from 'formik'

import { formatValuesForFormik } from 'utils/helpers'

import { Context } from '../../context'

const InnerForm = ({ component: Component, ...rest }) => {
  const { highlightEmpty } = useContext(Context)
  const { errors, setStatus, setTouched } = useFormikContext()

  useEffect(() => {
    setStatus({ highlightEmpty })
  }, [highlightEmpty, setStatus])

  useEffect(() => {
    setTouched(errors)
  }, [errors, setTouched])

  return <Component {...rest} />
}

const withForm = (Component) => {
  const OuterForm = ({ enableReinitialize, initialValues, ...rest }) => {
    const { highlightEmpty } = useContext(Context)
    return (
      <Formik
        enableReinitialize={enableReinitialize}
        initialStatus={{ highlightEmpty }}
        initialValues={formatValuesForFormik(initialValues)}
        validateOnChange={false}
        validateOnMount
      >
        <InnerForm component={Component} {...rest} />
      </Formik>
    )
  }

  OuterForm.propTypes = {
    initialValues: PropTypes.object.isRequired,
    enableReinitialize: PropTypes.bool,
  }

  OuterForm.defaultProps = {
    enableReinitialize: true,
  }

  return OuterForm
}

withForm.propTypes = {
  Component: PropTypes.element.isRequired,
}

export default withForm
