import { useEffect } from 'react'
import { Grid, H, P } from '@farewill/ui'
import { Link } from 'react-router-dom'
import { useFormikContext } from 'formik'
import { strToBool } from 'utils/helpers'
import useApi from 'lib/effects/api'
import NotesInput from 'components/notes-input'
import withForm from '../fact-find/components/with-form'
import MessageBox from 'components/message-box'

import { HAS_WILL } from '../fact-find/will-or-intestacy/field-names'
import { getDateOfDeathIsOnOrAfter20200206 } from '../helpers'
import { INTESTACY_STATUTORY_LEGACY_NOTES } from './field-names'

const IntestacyStatutoryLegacy = ({
  probateEstateId,
  saveProbateEstateField,
}) => {
  const { values } = useFormikContext()
  const hasWill = strToBool(values[HAS_WILL])
  const dateOfDeathAfterFeb62020 = getDateOfDeathIsOnOrAfter20200206(values)

  const [{ data: summary, isLoading }, getProbateEstateSummary] = useApi({
    isLoading: true,
  })

  useEffect(() => {
    getProbateEstateSummary({
      url: `/api/probate-estates/${probateEstateId}/summary`,
    })
  }, [probateEstateId, getProbateEstateSummary])

  if (isLoading) {
    return (
      <P size="L">
        Loading <strong>estate summary</strong>...
      </P>
    )
  }

  const valueThreshold = dateOfDeathAfterFeb62020 ? 270000 : 250000
  const valueThresholdString = dateOfDeathAfterFeb62020 ? '270k' : '250k'

  const netEstateValueIsOverThreshold =
    summary?.attributes?.financials?.netEstateForGrant?.value >= valueThreshold

  const showNotApplicable = hasWill ? true : !netEstateValueIsOverThreshold
  const notApplicableText = hasWill
    ? 'there is a will'
    : 'the net estate is under ' + valueThresholdString
  const notApplicableLink = hasWill ? 2 : 4

  return (
    <Grid>
      <Grid.Item>
        <H size="S">6. Intestacy - statutory legacy</H>
      </Grid.Item>
      {showNotApplicable && (
        <Grid.Item>
          <P>
            Not applicable because {notApplicableText} (
            <Link
              to={`/probate/estates/${probateEstateId}/fact-find#${notApplicableLink}`}
            >
              {notApplicableLink}
            </Link>
            )
          </P>
          <P size="S">↓ Continue to next section</P>
        </Grid.Item>
      )}
      {!showNotApplicable && (
        <Grid.Item span={9}>
          <MessageBox warning>
            This is an intestacy and IHT box F (net estate) is £
            {valueThresholdString} or more
          </MessageBox>
        </Grid.Item>
      )}
      <Grid.Item>
        <NotesInput
          name={INTESTACY_STATUTORY_LEGACY_NOTES}
          handleSave={saveProbateEstateField}
        />
      </Grid.Item>
    </Grid>
  )
}

export default withForm(IntestacyStatutoryLegacy)
