import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { Divider, H, Wrapper } from '@farewill/ui'
import { GTR } from '@farewill/ui/tokens'

import PhoneAndEmailContacts from 'components/phone-email-contacts'
import { getFullNameWithPreferredName } from 'utils/helpers'
import { FuneralAttributes } from 'lib/models/funeral'

import DeceasedDetails from './deceased-details'
import { Attributes } from '.'

const StyledH = styled(H)`
  margin-bottom: ${GTR.XS};
  display: flex;
  align-items: center;
`

type CustomerDetailsProps = {
  attributes: Attributes
  showDeceased?: boolean
}

const CustomerDetails = ({
  attributes,
  showDeceased,
}: CustomerDetailsProps) => {
  const contact = 'contact' in attributes ? attributes.contact : undefined
  const contacts = 'contacts' in attributes ? attributes.contacts : undefined
  const contactId = 'contactId' in attributes ? attributes.contactId : undefined
  const purchaser = 'purchaser' in attributes ? attributes.purchaser : undefined
  const purchaserId =
    'purchaserId' in attributes ? attributes.purchaserId : undefined

  const primaryContactId = contactId || purchaserId
  const primaryContact = contact || purchaser

  const allContacts =
    contacts && contacts.length > 0 ? contacts : [primaryContact]
  const sortedContacts = allContacts.sort((contact) =>
    contact?.id === primaryContactId ? -1 : 1
  )

  return (
    <>
      {sortedContacts.map(
        (contact) =>
          contact && (
            <Wrapper key={contact.id} margin={[0, 0, 'S']}>
              <StyledH size="L" decorative margin={[0, 0, 'S']}>
                <Link to={`/contacts/${contact.id}`}>
                  {getFullNameWithPreferredName(contact)}
                </Link>
              </StyledH>
              {showDeceased && (
                <Wrapper margin={[0, 0, 'M', 0]}>
                  <DeceasedDetails
                    attributes={attributes as FuneralAttributes}
                  />
                  <Wrapper margin={['M', 0]}>
                    <Divider maxWidthInColumns={3} />
                  </Wrapper>
                </Wrapper>
              )}
              <Wrapper>
                <PhoneAndEmailContacts contact={contact} withLink />
              </Wrapper>
            </Wrapper>
          )
      )}
    </>
  )
}

export default CustomerDetails
