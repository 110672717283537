import { Grid } from '@farewill/ui'
import { useFormikContext } from 'formik'

import { SelectInput } from 'components/form'
import { Selected } from 'components/form/select-input/types'
import PartnerSelect from 'components/partner-select'
import { LeadAttributes } from 'lib/models/lead'
import {
  FAREWILL_PRODUCTS,
  FarewillProduct,
  LEAD_PARTNER_TYPES,
  LEAD_PARTNER_TYPE_OPTIONS,
} from 'utils/enums'
import { showPartnerSelect } from 'utils/helpers'

import { productsWithoutPartners } from './constants'

const getPartnerTypeOptionsForProduct = (product: FarewillProduct) => {
  switch (product) {
    case FAREWILL_PRODUCTS.WILL:
      return LEAD_PARTNER_TYPE_OPTIONS
    case FAREWILL_PRODUCTS.FUNERAL:
    case FAREWILL_PRODUCTS.PROBATE:
      return LEAD_PARTNER_TYPE_OPTIONS.filter(
        (option) => option.value !== LEAD_PARTNER_TYPES.charity
      )
    default:
      return LEAD_PARTNER_TYPE_OPTIONS
  }
}

type PartnersProps = {
  product: FarewillProduct
}

const Partners = ({ product }: PartnersProps) => {
  const { handleSubmit, values, setFieldValue } =
    useFormikContext<LeadAttributes>()

  const showPartners = !productsWithoutPartners.includes(product)

  const resetPartnerIdAndVoucherCodeAndSubmit = (value: {
    name: string
    value: Selected
  }) => {
    setFieldValue('voucherCode', '')

    // Wills apply automatically 100% custom discount for charities,
    // and the logic is tightly coupled with the voucherType in outcome-new component
    if (product === FAREWILL_PRODUCTS.WILL) {
      setFieldValue(
        'voucherType',
        value.value === 'charity' ? 'custom_discount' : ''
      )
    } else {
      setFieldValue(
        'voucherType',
        value.value === 'charity' ? 'other_voucher' : ''
      )
    }

    /**
     * Only update the field when the user selects 'none' to clear up the Partners/Charities,
     * otherwise, wait until the Partner/Charity is picked, and this will trigger saving the form.
     */
    if (value.value === 'none') {
      setFieldValue('partnerId', null)
      handleSubmit()
    }
  }

  /** This async/await is solving a weird problem with Formik and its yup validation.
   * Previously, the form would require partnerId field to be selected multiple times,
   * before saving, waiting for setFieldValue to return the value from promise is resolving the issue.
   * I (@iamarek) wasn't able to exactly understand why is this happening, but this is a working solution.
   */
  const savePartnerId = async ({ value }: { value: Selected }) => {
    await setFieldValue('partnerId', value)
    handleSubmit()
  }

  return showPartners ? (
    <Grid.Item>
      <Grid>
        <Grid.Item spanFromL={6}>
          <SelectInput
            label="Partner type"
            name="partnerType"
            handleSave={resetPartnerIdAndVoucherCodeAndSubmit}
            options={getPartnerTypeOptionsForProduct(product)}
          />
        </Grid.Item>
        {showPartnerSelect(values.partnerType) && (
          <Grid.Item spanFromL={6}>
            <PartnerSelect
              label="Partner name"
              name="partnerId"
              handleSave={savePartnerId}
              partnerType={values.partnerType as string}
            />
          </Grid.Item>
        )}
      </Grid>
    </Grid.Item>
  ) : null
}

export default Partners
