import { Wrapper } from '@farewill/ui'
import { useFormikContext } from 'formik'

import SelectInput from 'components/form/select-input'
import Input from 'components/form/input'

import { NewTransitionFormFields, CaseStatusFields } from './types'

type ReasonFieldProps = {
  status: keyof CaseStatusFields
  options: { label: string; value: string }[]
}

const CancelledOrColdReasonFields = ({
  status,
  options,
}: ReasonFieldProps): React.ReactElement => {
  const { values } = useFormikContext<NewTransitionFormFields>()
  const isOther = values[status].reason === 'other'

  return (
    <>
      <SelectInput
        name={`${status}.reason`}
        options={options}
        label={`Reason case ${status}`}
      />
      {isOther && (
        <Wrapper margin={['S', 0, 0]}>
          <Input name={`${status}.reasonDescription`} label="Describe reason" />
        </Wrapper>
      )}
    </>
  )
}

export default CancelledOrColdReasonFields
