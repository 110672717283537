import { ReactElement, useState } from 'react'
import { Formik, Form } from 'formik'
import { Grid, Wrapper } from '@farewill/ui'

import {
  WillCaseCustomerNames,
  WillCaseQuoteAttributes,
  WillCaseQuoteFormValues,
  WillCaseQuoteResult,
} from './types'
import WillCaseQuoteFormFields from './will-case-quote-form-fields'

const WillCaseQuoteForm = ({
  isLoading,
  currentQuote,
  willCaseId,
  customerNames,
}: {
  isLoading: boolean
  currentQuote: WillCaseQuoteResult
  willCaseId: number
  customerNames: WillCaseCustomerNames
}): ReactElement => {
  return (
    <Form>
      <Wrapper>
        <Grid>
          <WillCaseQuoteFormFields
            isLoading={isLoading}
            currentQuote={currentQuote}
            willCaseId={willCaseId}
            customerNames={customerNames}
          />
        </Grid>
      </Wrapper>
    </Form>
  )
}

const EditableQuote = ({
  customerNames,
  currentQuote,
  willCaseId,
  values,
  saveQuote,
  exitEditableQuote,
}: {
  customerNames: WillCaseCustomerNames
  currentQuote: WillCaseQuoteResult
  willCaseId: number
  values: WillCaseQuoteFormValues
  saveQuote: (attributes: WillCaseQuoteAttributes) => Promise<void>
  exitEditableQuote: () => void
}): ReactElement => {
  const initialValues = values

  const [isLoading, setIsLoading] = useState(false)

  const onSubmit = async (values: WillCaseQuoteFormValues) => {
    const quoteAttributesValues = values as WillCaseQuoteAttributes
    try {
      setIsLoading(true)
      if (values.complexities === '') {
        quoteAttributesValues.complexities = null
      }
      await saveQuote({
        ...quoteAttributesValues,
      })

      setIsLoading(false)
      exitEditableQuote && exitEditableQuote()
    } catch (error) {
      setIsLoading(false)
    }
  }

  return (
    <Wrapper>
      <Formik initialValues={initialValues} onSubmit={onSubmit}>
        <WillCaseQuoteForm
          isLoading={isLoading}
          currentQuote={currentQuote}
          willCaseId={willCaseId}
          customerNames={customerNames}
        />
      </Formik>
    </Wrapper>
  )
}

export default EditableQuote
