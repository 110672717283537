import { FuneralPlanAttributes } from 'lib/models/funeral-plan'
import { FAREWILL_PRODUCTS } from 'utils/enums'

type FuneralPlanProduct = FuneralPlanAttributes & {
  path: string
  product: 'funeral_plan'
  coveredIndividualName?: string
  purchaserName?: string
}

export const getFuneralPlans = (
  funeralPlansAsPurchaser: FuneralPlanAttributes[],
  funeralPlansAsCoveredIndividual: FuneralPlanAttributes[]
): FuneralPlanProduct[] => {
  const funeralPlans: FuneralPlanProduct[] = []

  funeralPlansAsPurchaser.forEach((funeralPlanAsPurchaser) => {
    const funeralPlan: FuneralPlanProduct = {
      ...funeralPlanAsPurchaser,
      path: `/funeral-plans/${funeralPlanAsPurchaser.id}`,
      product: FAREWILL_PRODUCTS.FUNERAL_PLAN,
    }

    const {
      purchaserId,
      purchaser,
      coveredIndividualId,
      coveredIndividual,
      coveredIndividualDetails,
    } = funeralPlanAsPurchaser

    const purchaserName = `${purchaser.firstName} ${purchaser.lastName}`
    const coveredIndividualName = coveredIndividualId
      ? `${coveredIndividual.firstName} ${coveredIndividual.lastName}`
      : `${coveredIndividualDetails.firstName} ${coveredIndividualDetails.lastName}`

    /**
     * If the funeral plan purchaser and covered individual are the same don't show the covered
     * individual name in the list of products. If the funeral plan is a legacy one don't show
     * the covered individual name if the contact ID matches the contact ID for the purchaser.
     */
    const purchaserIsCoveredIndividual =
      (coveredIndividualDetails && coveredIndividualName === purchaserName) ||
      (coveredIndividualId && purchaserId === coveredIndividualId)

    if (!purchaserIsCoveredIndividual) {
      funeralPlan.coveredIndividualName = coveredIndividualName
    }
    funeralPlans.push(funeralPlan)
  })

  funeralPlansAsCoveredIndividual.forEach((funeralPlanAsCoveredIndividual) => {
    if (
      funeralPlanAsCoveredIndividual.purchaserId !==
      funeralPlanAsCoveredIndividual.coveredIndividualId
    ) {
      funeralPlans.push({
        ...funeralPlanAsCoveredIndividual,
        path: `/funeral-plans/${funeralPlanAsCoveredIndividual.id}`,
        product: FAREWILL_PRODUCTS.FUNERAL_PLAN,
        purchaserName: `${funeralPlanAsCoveredIndividual.purchaser.firstName} ${funeralPlanAsCoveredIndividual.purchaser.lastName}`,
      })
    }
  })

  return funeralPlans
}
