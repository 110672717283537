import { useQueryClient } from 'react-query'
import styled from 'styled-components'
import { Divider, P, Wrapper } from '@farewill/ui'
import { COLOR, GTR } from '@farewill/ui/tokens'

import { formatDate } from 'lib/formatting/dates'
import { AdminUser } from 'lib/models/admin-user'
import { CustomerCall } from 'lib/models/customer-call'
import { Lead } from 'lib/models/lead'
import { Uppercase } from 'components/styled/uppercase'
import { useLeadContext } from './context'
import getWillBookingURL, { BookingType } from 'lib/will/get-will-booking-url'
import { useEffect } from 'react'
import { useVisibilityChange } from 'hooks/useVisibilityChange'

const StyledBox = styled.div<{ $warning?: boolean }>`
  padding: ${GTR.XXS} 0;
  font-variant-numeric: tabular-nums;
`

// Check if date is in the future, with 1h tolerance,
// since we still want to display the call if sales person was
// slightly late to the call or for any other reason
function isDateInFuture(date: Date | string | null): boolean {
  const currentDate = new Date().getTime()
  const dateToCheck = new Date(date as unknown as string)
  dateToCheck?.setHours(dateToCheck.getHours() + 1)

  if (dateToCheck && dateToCheck.getTime() > currentDate) {
    return true
  }

  return false
}

type ScheduledCall = {
  id: number
  startTime: Date | string | null
  hasTime: boolean
  adminUser?: string
  type: string
  inFuture: boolean
  url?: string
}

const scheduledCallsMapper = (
  calls: CustomerCall[],
  lead: Lead,
  adminUsers?: AdminUser[]
) => {
  return calls.map((call, i) => ({
    id: i + 1,
    startTime: call.startTime,
    hasTime: true,
    adminUser: adminUsers?.find((user) => user?.id === call.adminUserId)
      ?.attributes.name,
    type: call.type,
    url: getWillBookingURL({
      type:
        call.type === 'fact_find'
          ? BookingType.FACT_FIND_MANAGE
          : BookingType.SALES_MANAGE,
      leadUuid: lead.attributes.uuid,
      calendlyEventId: call.calendlyEventId,
      startTime: call.startTime as unknown as string,
    }),
    inFuture: isDateInFuture(call.startTime),
  }))
}

const CallbackRow = ({ call }: { call: ScheduledCall }) => {
  return (
    <StyledBox key={call.id} $warning={!call.inFuture}>
      <P margin={0} color={call.inFuture ? undefined : COLOR.GREY.MEDIUM}>
        <strong>
          {formatDate(
            call.startTime,
            undefined,
            call.hasTime ? 'D MMM YYYY, HH:mm' : 'D MMM YYYY'
          )}
        </strong>{' '}
        |{' '}
        {call.url ? (
          <a href={call.url} target="_blank" rel="noopener noreferrer">
            Calendly booking
          </a>
        ) : (
          <span>Scroll for details</span>
        )}
      </P>
      <P color={COLOR.GREY.MEDIUM} size="S" margin={0}>
        {call.type === 'sales' ? 'Sales call' : 'Fact find'} with{' '}
        {call.adminUser}
      </P>
    </StyledBox>
  )
}

const CallbackInfoBox = () => {
  const cache = useQueryClient()
  const adminUsers = cache.getQueryData<AdminUser[]>('adminUsers')
  const { lead, fetchLead } = useLeadContext()
  const isTabVisible = useVisibilityChange()

  // Sales team member is being redirected to account app to book a callback,
  // after the callback is booked, it updates the lead. We want to make sure that
  // when the user comes back to the lead page, the lead is updated and it displayes
  // the date of the callback.
  useEffect(() => {
    if (isTabVisible) {
      fetchLead()
    }
  }, [isTabVisible, fetchLead])

  // All the scheduled calls from customer_calls table for this lead
  const scheduledCalls: ScheduledCall[] = scheduledCallsMapper(
    lead.attributes.customerCalls?.filter(
      (call) => call.status === 'scheduled' && !!call.startTime
    ),
    lead,
    adminUsers
  )

  /**
   * Backstage callback from leads table
   *
   * When scheduling new call through Calendly, both new customer calls row is added
   * but also scheduledNextCallAt in lead is added, so to avoid duplicating this, we
   * only show scheduledNextCallAt if it's different than any Calendly callback.
   */
  const scheduledCallback: ScheduledCall | undefined =
    lead.attributes.scheduledNextCallAt &&
    !scheduledCalls.find(
      (call) => call.startTime === lead.attributes.scheduledNextCallAt
    )
      ? {
          id: 1,
          startTime: lead.attributes.scheduledNextCallAt,
          hasTime: lead.attributes.scheduledNextCallAtTimeSet,
          adminUser: adminUsers?.find(
            (user) => user?.id === lead.attributes.ownedByAdminUserId
          )?.attributes.name,
          type: 'sales',
          inFuture: isDateInFuture(lead.attributes.scheduledNextCallAt),
        }
      : undefined

  // Combine customerCalls and backstage callback into one array and sort by date
  const scheduledCallsAndCallback = scheduledCalls
    .concat(scheduledCallback ? [scheduledCallback] : [])
    .sort((a, b) => {
      if (a.startTime === null || b.startTime === null) {
        return 1
      }

      return a.startTime > b.startTime ? 1 : -1
    })

  return (
    <>
      {scheduledCallsAndCallback?.length > 0 && (
        <Wrapper margin={[0, 0, 'M']}>
          <Wrapper margin={[0, 0, 'S']}>
            <Uppercase>Booked calls</Uppercase>
          </Wrapper>
          {scheduledCallsAndCallback.map((call, index) => (
            <>
              <CallbackRow call={call} />
              {index < scheduledCallsAndCallback.length - 1 && (
                <Divider margin={['XXS', 0]} />
              )}
            </>
          ))}
        </Wrapper>
      )}
    </>
  )
}

export default CallbackInfoBox
