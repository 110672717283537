const FETCH_FUNERAL_PLAN = 'FETCH_FUNERAL_PLAN'
const REDEEM_FUNERAL_PLAN = 'REDEEM_FUNERAL_PLAN'
const UPDATE_FUNERAL_PLAN = 'UPDATE_FUNERAL_PLAN'
const CANCEL_FUNERAL_PLAN = 'CANCEL_FUNERAL_PLAN'

export default function funeralPlanCase(state = {}, action) {
  switch (action.type) {
    case FETCH_FUNERAL_PLAN + '_FULFILLED':
    case UPDATE_FUNERAL_PLAN + '_FULFILLED':
    case REDEEM_FUNERAL_PLAN + '_FULFILLED':
    case CANCEL_FUNERAL_PLAN + '_FULFILLED':
      return action.payload.data.data
    case FETCH_FUNERAL_PLAN + '_PENDING':
    case UPDATE_FUNERAL_PLAN + '_PENDING':
    case REDEEM_FUNERAL_PLAN + '_PENDING':
    case CANCEL_FUNERAL_PLAN + '_PENDING':
      return state
    case UPDATE_FUNERAL_PLAN + '_REJECTED':
      return {
        ...state,
        errors: action.payload.response.data.errors,
      }
    case FETCH_FUNERAL_PLAN + '_REJECTED':
    case REDEEM_FUNERAL_PLAN + '_REJECTED':
    case CANCEL_FUNERAL_PLAN + '_REJECTED':
      return {}

    default:
      return state
  }
}
