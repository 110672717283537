import { P } from '@farewill/ui'

import { WillLeadAttributes } from 'lib/models/lead'
import { Option } from 'components/form/radio-group'
import Tooltip from 'components/tooltip'
import {
  WILL_RELATIONSHIP_STATUS_OPTIONS,
  WILL_TYPE_OPTIONS,
  RESIDENCE_OPTIONS,
  YES_NO_OPTIONS,
  CIVIL_PARTNERSHIP,
  COHABITING,
  LIVING_WITH_PARTNER,
  MARRIED,
  MARRIED_OR_CIVIL_PARTNERSHIP,
  WIDOWED_AND_REMARRIED,
  ENGAGED,
} from 'utils/enums'

import { COUNTRIES, OUTCOME_TYPE } from '../constants'
import { getCountryLabelFromValue } from '../helpers/form-utils'
import { isUnsupportedResidence } from '../helpers/is-unsupported-residence'

export const RelationshipStatus = {
  name: 'quoteAttributes.relationshipStatus',
  label: 'What is your relationship status?',
  options: WILL_RELATIONSHIP_STATUS_OPTIONS,
  type: 'select',
}

export const PartnerWantsWill = {
  name: 'quoteAttributes.willType',
  label: 'Does your partner need a will too?',
  options: WILL_TYPE_OPTIONS,
  type: 'radio',
  isVisible: (values: WillLeadAttributes) =>
    [
      MARRIED,
      MARRIED_OR_CIVIL_PARTNERSHIP,
      LIVING_WITH_PARTNER,
      CIVIL_PARTNERSHIP,
      COHABITING,
      WIDOWED_AND_REMARRIED,
      ENGAGED,
    ].includes(values.quoteAttributes?.relationshipStatus || ''),
  infoMessage:
    'Check that they are comfortable with speaking about their wishes in front of each other and their interests align.',
  showInfoMessage: (values: WillLeadAttributes) =>
    values.quoteAttributes?.willType === 'couple',
}

export const PartnerFirstName = {
  name: 'quoteAttributes.partner.firstName',
  label: 'Partner’s first name',
  type: 'input',
  isVisible: (values: WillLeadAttributes) =>
    values.quoteAttributes?.willType === 'couple',
}

export const PartnerLastName = {
  name: 'quoteAttributes.partner.lastName',
  label: 'Partner’s last name',
  type: 'input',
  isVisible: (values: WillLeadAttributes) =>
    values.quoteAttributes?.willType === 'couple',
}

export const PartnerEmail = {
  name: 'quoteAttributes.partner.email',
  label: 'Partner’s email',
  type: 'input',
  isVisible: (values: WillLeadAttributes) =>
    values.quoteAttributes?.willType === 'couple',
}

export const PartnerPhoneNumber = {
  name: 'quoteAttributes.partner.phoneNumber',
  label: 'Partner’s phone number',
  type: 'input',
  isVisible: (values: WillLeadAttributes) =>
    values.quoteAttributes?.willType === 'couple',
}

export const PartnerAddress = {
  name: 'quoteAttributes.partner.addressFields',
  value: (values: WillLeadAttributes) =>
    values.quoteAttributes?.partner?.addressFields,
  field: 'quoteAttributes.partner.addressFields',
  label: 'Address',
  type: 'address',
  isVisible: (values: WillLeadAttributes) =>
    values.quoteAttributes?.willType === 'couple',
}

export const PartnerDateOfBirth = {
  name: 'quoteAttributes.partner.dateOfBirth',
  label: 'Partner’s date of birth',
  type: 'date',
  isVisible: (values: WillLeadAttributes) =>
    values.quoteAttributes?.willType === 'couple',
}

export const Residence = {
  name: 'quoteAttributes.residence',
  label: 'Where in the UK do you live?',
  options: RESIDENCE_OPTIONS,
  type: 'radio',
  outcome: (values: WillLeadAttributes) => {
    if (values.quoteAttributes?.residence === 'outside_uk') {
      return {
        complete: true,
        type: OUTCOME_TYPE.CANNOT_HELP,
        message: 'they live outside the UK',
      }
    }
  },
  reasonForPhoneWill: (values: WillLeadAttributes) => {
    if (
      !values.quoteAttributes?.permanentResidence &&
      (values.quoteAttributes?.residence === COUNTRIES.SCOTLAND ||
        values.quoteAttributes?.residence === COUNTRIES.NORTHERN_IRELAND)
    ) {
      return (
        <>
          <P strong>
            Because they live in{' '}
            {getCountryLabelFromValue(values.quoteAttributes.residence)}.
          </P>
          <P>
            Our online service doesn’t cover customers living outside England or
            Wales, so a phone will is the only option.
          </P>
        </>
      )
    }
  },
  reasonForComplexWill: (values: WillLeadAttributes) => {
    if (
      !values.quoteAttributes?.permanentResidence &&
      (values.quoteAttributes?.residence === COUNTRIES.SCOTLAND ||
        values.quoteAttributes?.residence === COUNTRIES.NORTHERN_IRELAND)
    ) {
      return (
        <>
          <P strong>
            Because they live in{' '}
            {getCountryLabelFromValue(values.quoteAttributes.residence)}.
          </P>
          <P>
            Our online service doesn’t cover customers living outside England or
            Wales, so a phone will is the only option.
          </P>
        </>
      )
    }
  },
  phoneWillOnly: true,
}

export const ResidenceIsPermanentAddress = {
  name: 'quoteAttributes.residenceIsPermanentAddress',
  label: 'Do you live here permanently?',
  options: YES_NO_OPTIONS,
  type: 'radio',
  isVisible: (values: WillLeadAttributes) =>
    values.quoteAttributes?.residence !== COUNTRIES.OUTSIDE_UK,
}

export const PermanentResidence = {
  name: 'quoteAttributes.permanentResidence',
  label: 'Where is your permanent home?',
  options: (values: WillLeadAttributes) => {
    const optionsWithoutResidence = RESIDENCE_OPTIONS.filter(
      (option) => option.value !== values.quoteAttributes.residence
    ) as Option[]

    return optionsWithoutResidence
  },
  type: 'radio',
  isVisible: (values: WillLeadAttributes) =>
    values.quoteAttributes?.residenceIsPermanentAddress === 'false',
  reasonForPhoneWill: (values: WillLeadAttributes) => {
    if (
      values.quoteAttributes?.permanentResidence === COUNTRIES.SCOTLAND ||
      values.quoteAttributes?.permanentResidence === COUNTRIES.NORTHERN_IRELAND
    ) {
      return (
        <>
          <P strong>
            Because they live in{' '}
            {getCountryLabelFromValue(
              values.quoteAttributes.permanentResidence
            )}
            .
          </P>
          <P>
            Our online service doesn’t cover customers living outside England or
            Wales, so a phone will is the only option.
          </P>
        </>
      )
    }
  },
  reasonForComplexWill: (values: WillLeadAttributes) => {
    if (isUnsupportedResidence(values)) {
      return (
        <>
          <P strong>
            Because they live in{' '}
            {values.quoteAttributes.permanentResidence &&
              getCountryLabelFromValue(
                values.quoteAttributes.permanentResidence
              )}
            .
          </P>
          <P>
            Our online service doesn’t cover customers living outside England or
            Wales, so a phone will is the only option.
          </P>
        </>
      )
    }
  },
  outcome: (values: WillLeadAttributes) => {
    if (
      values.quoteAttributes?.permanentResidence === COUNTRIES.OUTSIDE_UK &&
      values.quoteAttributes?.residenceIsPermanentAddress === 'false'
    ) {
      return {
        complete: true,
        type: OUTCOME_TYPE.CANNOT_HELP,
        message: `their permanent home is outside the UK`,
      }
    }
  },
  phoneWillOnly: true,
}

export const EverythingInUK = {
  name: 'quoteAttributes.everythingInUK',
  label: (
    <>
      Is everything you own{' '}
      <Tooltip content="Northern Ireland is part of the UK but the Republic of Ireland is not. Assets in the Republic of Ireland are considered as foreign assets.">
        in the UK
      </Tooltip>
      ?
    </>
  ),
  type: 'radio',
  options: YES_NO_OPTIONS,
  reasonForPhoneWill: (values: WillLeadAttributes) => {
    if (values.quoteAttributes?.everythingInUK === 'false') {
      return (
        <>
          <P strong>Because they have assets abroad.</P>
          <P>
            Our online service doesn’t cover assets outside the UK, so a phone
            will is the best option.
          </P>
        </>
      )
    }
  },
  reasonForComplexWill: (values: WillLeadAttributes) => {
    if (values.quoteAttributes?.everythingInUK === 'false') {
      return (
        <>
          <P strong>Because they have assets abroad.</P>
          <P>
            Our online service doesn’t cover assets outside the UK, so a phone
            will is the best option.
          </P>
        </>
      )
    }
  },
  phoneWillOnly: true,
}

export const StepRelations = {
  name: 'quoteAttributes.stepRelations',
  label:
    'Do either you or your partner have children from previous relationships?',
  type: 'radio',
  options: YES_NO_OPTIONS,
  isVisible: (values: WillLeadAttributes) =>
    [
      MARRIED,
      MARRIED_OR_CIVIL_PARTNERSHIP,
      LIVING_WITH_PARTNER,
      CIVIL_PARTNERSHIP,
      COHABITING,
      ENGAGED,
      WIDOWED_AND_REMARRIED,
    ].includes(values.quoteAttributes?.relationshipStatus || ''),
  reasonForPhoneWill: (values: WillLeadAttributes) => {
    if (values.quoteAttributes?.stepRelations === 'true') {
      return (
        <>
          <P strong>Because they have step relations and a current partner.</P>
          <P>
            We can set up a trust to ensure their partner is looked after and
            their step relations will inherit eventually.
          </P>
        </>
      )
    }
  },
  reasonForComplexWill: (values: WillLeadAttributes) => {
    if (values.quoteAttributes?.stepRelations === 'true') {
      return (
        <>
          <P strong>Because they have step relations and a current partner.</P>
          <P>
            We can set up a trust to ensure their partner is looked after and
            their step relations will inherit eventually.
          </P>
        </>
      )
    }
  },
  outcome: (values: WillLeadAttributes) => {
    if (
      values.quoteAttributes?.stepRelations === 'true' &&
      isUnsupportedResidence(values)
    ) {
      return {
        complete: true,
        type: OUTCOME_TYPE.CANNOT_HELP,
        message: `they have step relations and a current partner while they live in ${getCountryLabelFromValue(
          values.quoteAttributes.permanentResidence ||
            values.quoteAttributes.residence ||
            ''
        )}`,
      }
    }
  },
}

export const HighValueAssets = {
  name: 'quoteAttributes.hasHighValueAssets',
  label: 'Is your entire estate worth more than £2 million?',
  type: 'radio',
  options: YES_NO_OPTIONS,
}

export const OwnsFarm = {
  name: 'quoteAttributes.ownsFarm',
  label: 'Do you own a farm?',
  type: 'radio',
  options: YES_NO_OPTIONS,
  outcome: (values: WillLeadAttributes) => {
    if (values.quoteAttributes?.ownsFarm === 'true') {
      return {
        complete: true,
        type: OUTCOME_TYPE.CANNOT_HELP,
        message: `they own a farm`,
      }
    }
  },
}
