import { GraphQLClient } from 'graphql-request'
import { RequestInit } from 'graphql-request/dist/types.dom'
import {
  useQuery,
  UseQueryOptions,
  useMutation,
  UseMutationOptions,
} from 'react-query'
export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K]
}
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>
}
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>
}

function fetcher<TData, TVariables>(
  client: GraphQLClient,
  query: string,
  variables?: TVariables,
  headers?: RequestInit['headers']
) {
  return async (): Promise<TData> =>
    client.request<TData, TVariables>(query, variables, headers)
}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
  DateTime: string
}

/** Contains log in credentials and account state */
export type Account = {
  __typename?: 'Account'
  /** The contact related to the account */
  contact?: Maybe<Contact>
  /** The date the account was created at */
  createdAt?: Maybe<Scalars['DateTime']>
  /** The date the account was deactivated at */
  deactivatedAt?: Maybe<Scalars['DateTime']>
  /** The email used to log in to the account */
  email?: Maybe<Scalars['String']>
  /** The error relating to this query */
  error?: Maybe<Error>
  /** The id of the account */
  id?: Maybe<Scalars['String']>
  /** The uuid of the account */
  uuid?: Maybe<Scalars['String']>
}

/** An address */
export type Address = {
  __typename?: 'Address'
  /** The city of the address */
  city?: Maybe<Scalars['String']>
  /** The country code of the country the address is in */
  countryCode?: Maybe<Scalars['String']>
  /** The error relating to this query */
  error?: Maybe<Error>
  id?: Maybe<Scalars['Int']>
  /** The first line of the address */
  lineOne?: Maybe<Scalars['String']>
  /** The second line of the address */
  lineTwo?: Maybe<Scalars['String']>
  /** The post code of the address */
  postalCode?: Maybe<Scalars['String']>
  /** The type of address */
  type?: Maybe<AddressType>
}

export enum AddressType {
  Postal = 'postal',
}

/** A category, used to group items and wishes */
export type Category = {
  __typename?: 'Category'
  /** The slug of the category */
  slug: Scalars['String']
}

/** A contact */
export type Contact = {
  __typename?: 'Contact'
  /** The account related to the contact */
  account?: Maybe<Account>
  /** The addresses related to the contact */
  addresses?: Maybe<Array<Maybe<Address>>>
  /** The date of birth of the contact */
  dateOfBirth?: Maybe<Scalars['String']>
  /** The email of the contact */
  email?: Maybe<Scalars['String']>
  /** The error relating to this query */
  error?: Maybe<Error>
  /** The first name of the contact */
  firstName?: Maybe<Scalars['String']>
  /** The full legal name of the contact */
  fullLegalName?: Maybe<Scalars['String']>
  /** The funeral plans where the contact is the covered individual */
  funeralPlansAsCoveredIndividual?: Maybe<Array<Maybe<FuneralPlan>>>
  /** The id of the contact */
  id?: Maybe<Scalars['Int']>
  /** The last name of the contact */
  lastName?: Maybe<Scalars['String']>
  /** The phone number of the contact */
  phoneNumber?: Maybe<Scalars['String']>
  /** The preferred name of the contact */
  preferredName?: Maybe<Scalars['String']>
}

export type Edges = {
  __typename?: 'Edges'
  node?: Maybe<Array<Maybe<FuneralPlan>>>
}

export type Error = {
  __typename?: 'Error'
  code?: Maybe<ErrorCodeEnum>
  message?: Maybe<Scalars['String']>
}

export enum ErrorCodeEnum {
  InvalidState = 'INVALID_STATE',
  NotFound = 'NOT_FOUND',
  Unauthorised = 'UNAUTHORISED',
  Validation = 'VALIDATION',
}

/** A funeral plan for the current logged in user */
export type FuneralPlan = {
  __typename?: 'FuneralPlan'
  /** The critical notes related to the plan */
  criticalNotes?: Maybe<Scalars['String']>
  /** The error relating to this query */
  error?: Maybe<Error>
  /** The id of the funeral plan */
  id?: Maybe<Scalars['String']>
  /** The items chosen for this plan */
  items?: Maybe<Array<Maybe<Item>>>
  /** The date of the last comment on or staff change made to the plan */
  lastActivityAt?: Maybe<Scalars['DateTime']>
  /** The id of the lead that is related to the plan */
  leadId?: Maybe<Scalars['Int']>
  /** The messages related to the plan */
  messages?: Maybe<Array<Maybe<Message>>>
  /** The package the plan is using */
  package?: Maybe<Package>
  /** A list of any payment transactions made for this plan */
  paymentTransactions?: Maybe<Array<Maybe<PaymentTransaction>>>
  /** The person who has purchased the plan */
  purchaser?: Maybe<Contact>
  /** The unique reference for the plan */
  reference?: Maybe<Scalars['String']>
  /** The status of the plan */
  status?: Maybe<Scalars['String']>
  /** The latest terms and conditions that this funeral plan holder has accepted */
  termsAndConditions?: Maybe<TermsAndConditions>
  /** The wishes that have been filled in for this plan */
  wishes?: Maybe<Array<Maybe<FuneralPlanWish>>>
}

export type FuneralPlanFilterType = {
  /** If omitted or false, returns all funeral plans regardless of whether we have the purchaser phone number. If true, returns only funeral plans where we have the purchaser phone number. */
  hasPhoneNumber?: InputMaybe<Scalars['Boolean']>
  /** If omitted or false, returns all funeral plans regardless of whether customer has added messages. If true, returns only funeral plans where customer has added messages. */
  includesMessages?: InputMaybe<Scalars['Boolean']>
  /** If omitted or false, returns all funeral plans regardless of whether customer has added wishes. If true, returns only funeral plans where customer has added wishes. */
  includesWishes?: InputMaybe<Scalars['Boolean']>
  /** If omitted, returns funeral plans with any status. If provided, returns funeral plans with that particular status. */
  status?: InputMaybe<Array<InputMaybe<Status>>>
}

export type FuneralPlanSortType = {
  /** Column to sort by. If omitted, items will be sorted by date created. */
  field?: InputMaybe<FuneralPlanSortableColumn>
  /** Sort order. If omitted, items will be returned in descending order. */
  order?: InputMaybe<SortOrder>
}

export enum FuneralPlanSortableColumn {
  Activity = 'activity',
}

/** A wish describing a customer's preferences */
export type FuneralPlanWish = {
  __typename?: 'FuneralPlanWish'
  /** The category of the wish */
  category: Scalars['String']
  /** The id of the wish */
  id?: Maybe<Scalars['String']>
  /** The name of the wish */
  name: Scalars['String']
  /** The slug of the wish */
  slug: Scalars['String']
  /** The text entered by the creator of the wish */
  value?: Maybe<Scalars['String']>
}

export type FuneralPlans = {
  __typename?: 'FuneralPlans'
  edges?: Maybe<Edges>
  pageInfo?: Maybe<PageInfo>
  totalCount?: Maybe<Scalars['Int']>
}

export type Mutation = {
  __typename?: 'Mutation'
  updateFuneralPlan?: Maybe<FuneralPlan>
  updateFuneralPlanPackage?: Maybe<FuneralPlan>
  updateFuneralPlanStatus?: Maybe<FuneralPlan>
}

export type MutationUpdateFuneralPlanArgs = {
  criticalNotes?: InputMaybe<Scalars['String']>
  id: Scalars['String']
}

export type MutationUpdateFuneralPlanPackageArgs = {
  id: Scalars['String']
  packageId: Scalars['String']
}

export type MutationUpdateFuneralPlanStatusArgs = {
  id: Scalars['String']
  reason?: InputMaybe<Scalars['String']>
  status: Status
}

/** A package consisting of various products */
export type Package = {
  __typename?: 'Package'
  /** The available categories that can be customised for plans of the package */
  categories?: Maybe<Array<Maybe<Category>>>
  /** What the package contains */
  description?: Maybe<Scalars['String']>
  /** The id of the package */
  id: Scalars['String']
  /** The available items that can be customised for plans of the package */
  items?: Maybe<Array<Maybe<Item>>>
  /** The name of the package */
  name: Scalars['String']
  /** The base price of the package */
  price?: Maybe<Price>
  /** The slug of the package */
  slug: Scalars['String']
  /** The status of the package */
  status?: Maybe<Scalars['String']>
  /** The available wishes that can be customised for plans of the package */
  wishes?: Maybe<Array<Maybe<Wish>>>
}

export type PackageFilterType = {
  /** If omitted, returns packages with any status. If provided, returns packages with that particular status. */
  status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type PageInfo = {
  __typename?: 'PageInfo'
  endCursor?: Maybe<Scalars['String']>
  startCursor?: Maybe<Scalars['String']>
}

export enum PaymentProviderEnumType {
  FuneralSafe = 'funeralSafe',
  Stripe = 'stripe',
}

/** A model of a payment made to or from Farewill */
export type PaymentTransaction = {
  __typename?: 'PaymentTransaction'
  /** The id of the transaction on a third party payment platform such as Stripe */
  externalTransactionId?: Maybe<Scalars['String']>
  /** The id of the transaction */
  id?: Maybe<Scalars['String']>
  /** The payment provider used for this transaction */
  paymentProvider?: Maybe<PaymentProviderEnumType>
  /** The transactions price, containing the amount and currency denomination */
  price?: Maybe<Price>
  /** The type of transaction */
  type?: Maybe<TransactionEnumType>
}

/** A price with currency and amount in cents */
export type Price = {
  __typename?: 'Price'
  /** The amount in cents */
  amountInCents: Scalars['Int']
  /** The IBAN country currency code */
  currency: Scalars['String']
  /** The id of the price */
  id: Scalars['String']
}

export enum ProductEnum {
  Funeral = 'funeral',
  FuneralPlan = 'funeralPlan',
  Lpa = 'lpa',
  OnlineWill = 'onlineWill',
  Probate = 'probate',
  TelephoneWill = 'telephoneWill',
  Will = 'will',
}

export type Query = {
  __typename?: 'Query'
  account?: Maybe<Account>
  funeralPlan?: Maybe<FuneralPlan>
  funeralPlans?: Maybe<FuneralPlans>
  packages?: Maybe<Array<Maybe<Package>>>
}

export type QueryAccountArgs = {
  deactivatedAt?: InputMaybe<Scalars['DateTime']>
  email?: InputMaybe<Scalars['String']>
  uuid?: InputMaybe<Scalars['String']>
}

export type QueryFuneralPlanArgs = {
  id: Scalars['String']
}

export type QueryFuneralPlansArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  filter?: InputMaybe<FuneralPlanFilterType>
  first?: InputMaybe<Scalars['Int']>
  sort?: InputMaybe<FuneralPlanSortType>
}

export type QueryPackagesArgs = {
  filter?: InputMaybe<PackageFilterType>
}

export enum SortOrder {
  Asc = 'asc',
  Desc = 'desc',
}

export enum Status {
  Active = 'active',
  Cancelled = 'cancelled',
  Closed = 'closed',
  Lost = 'lost',
  Open = 'open',
}

/** An object representing terms and conditions */
export type TermsAndConditions = {
  __typename?: 'TermsAndConditions'
  /** The id of the terms */
  id?: Maybe<Scalars['String']>
  /** The date the terms came into effect */
  inEffectFrom?: Maybe<Scalars['String']>
  /** The product that the terms are related to */
  product?: Maybe<ProductEnum>
}

export enum TransactionEnumType {
  AppliedForFinancing = 'appliedForFinancing',
  Instalment = 'instalment',
  LumpSum = 'lumpSum',
  Refund = 'refund',
  Rejected = 'rejected',
}

/** A wish describing a customer's preferences */
export type Wish = {
  __typename?: 'Wish'
  /** The category of the wish */
  category: Scalars['String']
  /** The id of the wish */
  id?: Maybe<Scalars['String']>
  /** The name of the wish */
  name: Scalars['String']
  /** The slug of the wish */
  slug: Scalars['String']
}

/** A particular item or generic item type */
export type Item = {
  __typename?: 'item'
  /** The category of the item */
  category: Scalars['String']
  /** The description of the item */
  description: Scalars['String']
  /** The id of the item */
  id?: Maybe<Scalars['String']>
  /** The name of the item */
  name: Scalars['String']
  /** The item's price */
  price?: Maybe<Price>
}

/** A message related to funeral plan */
export type Message = {
  __typename?: 'message'
  /** The content of the message */
  content?: Maybe<Scalars['String']>
  /** The id of the message */
  id?: Maybe<Scalars['String']>
  /** The recipient of the message */
  recipient?: Maybe<Scalars['String']>
}

export type AccountByEmailQueryVariables = Exact<{
  email: Scalars['String']
}>

export type AccountByEmailQuery = {
  __typename?: 'Query'
  account?: {
    __typename?: 'Account'
    id?: string | null
    uuid?: string | null
    contact?: { __typename?: 'Contact'; id?: number | null } | null
    error?: { __typename?: 'Error'; code?: ErrorCodeEnum | null } | null
  } | null
}

export type AccountByUuidQueryVariables = Exact<{
  uuid: Scalars['String']
}>

export type AccountByUuidQuery = {
  __typename?: 'Query'
  account?: {
    __typename?: 'Account'
    id?: string | null
    email?: string | null
    createdAt?: string | null
    contact?: { __typename?: 'Contact'; id?: number | null } | null
    error?: { __typename?: 'Error'; code?: ErrorCodeEnum | null } | null
  } | null
}

export type PackagesQueryVariables = Exact<{
  status?: InputMaybe<
    Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>
  >
}>

export type PackagesQuery = {
  __typename?: 'Query'
  packages?: Array<{
    __typename?: 'Package'
    id: string
    name: string
    status?: string | null
    slug: string
    price?: {
      __typename?: 'Price'
      id: string
      currency: string
      amountInCents: number
    } | null
  } | null> | null
}

export type FuneralPlansQueryVariables = Exact<{
  status?: InputMaybe<Array<InputMaybe<Status>> | InputMaybe<Status>>
  field?: InputMaybe<FuneralPlanSortableColumn>
  order?: InputMaybe<SortOrder>
  first?: InputMaybe<Scalars['Int']>
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  hasPhoneNumber?: InputMaybe<Scalars['Boolean']>
  includesWishes?: InputMaybe<Scalars['Boolean']>
  includesMessages?: InputMaybe<Scalars['Boolean']>
}>

export type FuneralPlansQuery = {
  __typename?: 'Query'
  funeralPlans?: {
    __typename?: 'FuneralPlans'
    totalCount?: number | null
    edges?: {
      __typename?: 'Edges'
      node?: Array<{
        __typename?: 'FuneralPlan'
        id?: string | null
        status?: string | null
        lastActivityAt?: string | null
        purchaser?: {
          __typename?: 'Contact'
          firstName?: string | null
          lastName?: string | null
          preferredName?: string | null
        } | null
        package?: {
          __typename?: 'Package'
          id: string
          status?: string | null
          name: string
        } | null
      } | null> | null
    } | null
    pageInfo?: {
      __typename?: 'PageInfo'
      startCursor?: string | null
      endCursor?: string | null
    } | null
  } | null
}

export type FuneralPlanQueryVariables = Exact<{
  id: Scalars['String']
}>

export type FuneralPlanQuery = {
  __typename?: 'Query'
  funeralPlan?: {
    __typename?: 'FuneralPlan'
    id?: string | null
    status?: string | null
    criticalNotes?: string | null
    leadId?: number | null
    reference?: string | null
    purchaser?: {
      __typename?: 'Contact'
      id?: number | null
      firstName?: string | null
      lastName?: string | null
      preferredName?: string | null
      fullLegalName?: string | null
      dateOfBirth?: string | null
      phoneNumber?: string | null
      email?: string | null
      addresses?: Array<{
        __typename?: 'Address'
        lineOne?: string | null
        lineTwo?: string | null
        city?: string | null
        postalCode?: string | null
        countryCode?: string | null
        type?: AddressType | null
      } | null> | null
      account?: {
        __typename?: 'Account'
        id?: string | null
        createdAt?: string | null
        email?: string | null
        uuid?: string | null
      } | null
    } | null
    package?: {
      __typename?: 'Package'
      id: string
      status?: string | null
      name: string
      slug: string
      price?: { __typename?: 'Price'; amountInCents: number } | null
    } | null
    wishes?: Array<{
      __typename?: 'FuneralPlanWish'
      slug: string
      name: string
      category: string
      value?: string | null
    } | null> | null
    items?: Array<{
      __typename?: 'item'
      name: string
      description: string
      category: string
    } | null> | null
    error?: {
      __typename?: 'Error'
      message?: string | null
      code?: ErrorCodeEnum | null
    } | null
  } | null
}

export type UpdateFuneralPlanMutationVariables = Exact<{
  id: Scalars['String']
  criticalNotes?: InputMaybe<Scalars['String']>
}>

export type UpdateFuneralPlanMutation = {
  __typename?: 'Mutation'
  updateFuneralPlan?: {
    __typename?: 'FuneralPlan'
    id?: string | null
    criticalNotes?: string | null
    error?: {
      __typename?: 'Error'
      message?: string | null
      code?: ErrorCodeEnum | null
    } | null
  } | null
}

export type UpdateFuneralPlanStatusMutationVariables = Exact<{
  id: Scalars['String']
  status: Status
  reason: Scalars['String']
}>

export type UpdateFuneralPlanStatusMutation = {
  __typename?: 'Mutation'
  updateFuneralPlanStatus?: {
    __typename?: 'FuneralPlan'
    status?: string | null
  } | null
}

export type UpdateFuneralPlanPackageMutationVariables = Exact<{
  id: Scalars['String']
  packageId: Scalars['String']
}>

export type UpdateFuneralPlanPackageMutation = {
  __typename?: 'Mutation'
  updateFuneralPlanPackage?: {
    __typename?: 'FuneralPlan'
    id?: string | null
  } | null
}

export const AccountByEmailDocument = `
    query accountByEmail($email: String!) {
  account(email: $email, deactivatedAt: null) {
    id
    uuid
    contact {
      id
    }
    error {
      code
    }
  }
}
    `
export const useAccountByEmailQuery = <
  TData = AccountByEmailQuery,
  TError = unknown
>(
  client: GraphQLClient,
  variables: AccountByEmailQueryVariables,
  options?: UseQueryOptions<AccountByEmailQuery, TError, TData>,
  headers?: RequestInit['headers']
) =>
  useQuery<AccountByEmailQuery, TError, TData>(
    ['accountByEmail', variables],
    fetcher<AccountByEmailQuery, AccountByEmailQueryVariables>(
      client,
      AccountByEmailDocument,
      variables,
      headers
    ),
    options
  )
export const AccountByUuidDocument = `
    query accountByUuid($uuid: String!) {
  account(uuid: $uuid) {
    id
    email
    createdAt
    contact {
      id
    }
    error {
      code
    }
  }
}
    `
export const useAccountByUuidQuery = <
  TData = AccountByUuidQuery,
  TError = unknown
>(
  client: GraphQLClient,
  variables: AccountByUuidQueryVariables,
  options?: UseQueryOptions<AccountByUuidQuery, TError, TData>,
  headers?: RequestInit['headers']
) =>
  useQuery<AccountByUuidQuery, TError, TData>(
    ['accountByUuid', variables],
    fetcher<AccountByUuidQuery, AccountByUuidQueryVariables>(
      client,
      AccountByUuidDocument,
      variables,
      headers
    ),
    options
  )
export const PackagesDocument = `
    query packages($status: [String]) {
  packages(filter: {status: $status}) {
    id
    name
    status
    price {
      id
      currency
      amountInCents
    }
    slug
  }
}
    `
export const usePackagesQuery = <TData = PackagesQuery, TError = unknown>(
  client: GraphQLClient,
  variables?: PackagesQueryVariables,
  options?: UseQueryOptions<PackagesQuery, TError, TData>,
  headers?: RequestInit['headers']
) =>
  useQuery<PackagesQuery, TError, TData>(
    variables === undefined ? ['packages'] : ['packages', variables],
    fetcher<PackagesQuery, PackagesQueryVariables>(
      client,
      PackagesDocument,
      variables,
      headers
    ),
    options
  )
export const FuneralPlansDocument = `
    query funeralPlans($status: [Status], $field: FuneralPlanSortableColumn, $order: SortOrder, $first: Int, $before: String, $after: String, $hasPhoneNumber: Boolean, $includesWishes: Boolean, $includesMessages: Boolean) {
  funeralPlans(
    filter: {status: $status, hasPhoneNumber: $hasPhoneNumber, includesWishes: $includesWishes, includesMessages: $includesMessages}
    sort: {field: $field, order: $order}
    first: $first
    before: $before
    after: $after
  ) {
    totalCount
    edges {
      node {
        id
        status
        purchaser {
          firstName
          lastName
          preferredName
        }
        package {
          id
          status
          name
        }
        lastActivityAt
      }
    }
    pageInfo {
      startCursor
      endCursor
    }
  }
}
    `
export const useFuneralPlansQuery = <
  TData = FuneralPlansQuery,
  TError = unknown
>(
  client: GraphQLClient,
  variables?: FuneralPlansQueryVariables,
  options?: UseQueryOptions<FuneralPlansQuery, TError, TData>,
  headers?: RequestInit['headers']
) =>
  useQuery<FuneralPlansQuery, TError, TData>(
    variables === undefined ? ['funeralPlans'] : ['funeralPlans', variables],
    fetcher<FuneralPlansQuery, FuneralPlansQueryVariables>(
      client,
      FuneralPlansDocument,
      variables,
      headers
    ),
    options
  )
export const FuneralPlanDocument = `
    query funeralPlan($id: String!) {
  funeralPlan(id: $id) {
    id
    status
    criticalNotes
    leadId
    reference
    purchaser {
      id
      firstName
      lastName
      preferredName
      fullLegalName
      dateOfBirth
      phoneNumber
      email
      addresses {
        lineOne
        lineTwo
        city
        postalCode
        countryCode
        type
      }
      account {
        id
        createdAt
        email
        uuid
      }
    }
    package {
      id
      status
      name
      slug
      price {
        amountInCents
      }
    }
    wishes {
      slug
      name
      category
      value
    }
    items {
      name
      description
      category
    }
    error {
      message
      code
    }
  }
}
    `
export const useFuneralPlanQuery = <TData = FuneralPlanQuery, TError = unknown>(
  client: GraphQLClient,
  variables: FuneralPlanQueryVariables,
  options?: UseQueryOptions<FuneralPlanQuery, TError, TData>,
  headers?: RequestInit['headers']
) =>
  useQuery<FuneralPlanQuery, TError, TData>(
    ['funeralPlan', variables],
    fetcher<FuneralPlanQuery, FuneralPlanQueryVariables>(
      client,
      FuneralPlanDocument,
      variables,
      headers
    ),
    options
  )
export const UpdateFuneralPlanDocument = `
    mutation updateFuneralPlan($id: String!, $criticalNotes: String) {
  updateFuneralPlan(id: $id, criticalNotes: $criticalNotes) {
    id
    criticalNotes
    error {
      message
      code
    }
  }
}
    `
export const useUpdateFuneralPlanMutation = <
  TError = unknown,
  TContext = unknown
>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    UpdateFuneralPlanMutation,
    TError,
    UpdateFuneralPlanMutationVariables,
    TContext
  >,
  headers?: RequestInit['headers']
) =>
  useMutation<
    UpdateFuneralPlanMutation,
    TError,
    UpdateFuneralPlanMutationVariables,
    TContext
  >(
    'updateFuneralPlan',
    (variables?: UpdateFuneralPlanMutationVariables) =>
      fetcher<UpdateFuneralPlanMutation, UpdateFuneralPlanMutationVariables>(
        client,
        UpdateFuneralPlanDocument,
        variables,
        headers
      )(),
    options
  )
export const UpdateFuneralPlanStatusDocument = `
    mutation updateFuneralPlanStatus($id: String!, $status: Status!, $reason: String!) {
  updateFuneralPlanStatus(id: $id, status: $status, reason: $reason) {
    status
  }
}
    `
export const useUpdateFuneralPlanStatusMutation = <
  TError = unknown,
  TContext = unknown
>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    UpdateFuneralPlanStatusMutation,
    TError,
    UpdateFuneralPlanStatusMutationVariables,
    TContext
  >,
  headers?: RequestInit['headers']
) =>
  useMutation<
    UpdateFuneralPlanStatusMutation,
    TError,
    UpdateFuneralPlanStatusMutationVariables,
    TContext
  >(
    'updateFuneralPlanStatus',
    (variables?: UpdateFuneralPlanStatusMutationVariables) =>
      fetcher<
        UpdateFuneralPlanStatusMutation,
        UpdateFuneralPlanStatusMutationVariables
      >(client, UpdateFuneralPlanStatusDocument, variables, headers)(),
    options
  )
export const UpdateFuneralPlanPackageDocument = `
    mutation updateFuneralPlanPackage($id: String!, $packageId: String!) {
  updateFuneralPlanPackage(id: $id, packageId: $packageId) {
    id
  }
}
    `
export const useUpdateFuneralPlanPackageMutation = <
  TError = unknown,
  TContext = unknown
>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    UpdateFuneralPlanPackageMutation,
    TError,
    UpdateFuneralPlanPackageMutationVariables,
    TContext
  >,
  headers?: RequestInit['headers']
) =>
  useMutation<
    UpdateFuneralPlanPackageMutation,
    TError,
    UpdateFuneralPlanPackageMutationVariables,
    TContext
  >(
    'updateFuneralPlanPackage',
    (variables?: UpdateFuneralPlanPackageMutationVariables) =>
      fetcher<
        UpdateFuneralPlanPackageMutation,
        UpdateFuneralPlanPackageMutationVariables
      >(client, UpdateFuneralPlanPackageDocument, variables, headers)(),
    options
  )
