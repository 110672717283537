import upperCase from 'lodash/upperCase'
import moment from 'moment'

import { AdminUser } from 'lib/models/admin-user'
import { PropertyChange } from 'lib/models/lead-event'
import { getDefinedTimeslot } from 'routes/lead/helpers'
import { formatToHuman } from 'utils/helpers'

const formatDateTime = (dateTime: string): string | null => {
  if (!dateTime) return null

  const isFormattedAlready = !moment(dateTime, moment.ISO_8601, true).isValid()

  if (isFormattedAlready) return dateTime
  // If the user schedules a callback with a date and no time, we set a datetime in the database with 23:59:59.
  if (dateTime.includes('23:59:59') || dateTime.includes('22:59:59'))
    return moment(dateTime).format('D MMM YYYY')

  const definedTimePeriod = getDefinedTimeslot(dateTime)
  if (definedTimePeriod) {
    return (
      moment(dateTime).format(`D MMM YYYY`) +
      `, ${definedTimePeriod.label.toLowerCase()}`
    )
  }
  return moment(dateTime).format('D MMM YYYY, HH:mm')
}

export const formatScheduledCallLeadChange = (
  change: PropertyChange
): PropertyChange => {
  change.from = change.from ? formatDateTime(change.from as string) : ''
  change.to = change.to ? formatDateTime(change.to as string) : ''
  return change
}

export const formatProductName = (key: string): string => {
  switch (key) {
    case 'lpa':
      return upperCase(key)

    default:
      return key
  }
}

const formatOwnedByLeadChange = (
  change: PropertyChange,
  adminUsers: AdminUser[]
): PropertyChange => {
  // If 'from' or 'to' are already strings, it means the owner names have already been set, so just return the change
  if (typeof change.from === 'string' || typeof change.to === 'string')
    return change

  change.from =
    adminUsers.find((adminUser) => adminUser.id === change.from)?.attributes
      ?.name || null
  change.to =
    adminUsers.find((adminUser) => adminUser.id === change.to)?.attributes
      ?.name || null
  return change
}

const formatOtherLeadChanges = (change: PropertyChange): PropertyChange => {
  change.from = formatToHuman(change.from as string)
  change.to = formatToHuman(change.to as string)
  return change
}

export const formatLeadChanges = (
  leadChanges: PropertyChange[],
  adminUsers: AdminUser[]
): PropertyChange[] => {
  return leadChanges
    .filter((change) => change.property !== 'scheduledNextCallAtTimeSet')
    .map((change) => {
      switch (change.property) {
        case 'ownedByAdminUserId':
          return formatOwnedByLeadChange(change, adminUsers)
        case 'scheduledNextCallAt':
          return formatScheduledCallLeadChange(change)
        default:
          return formatOtherLeadChanges(change)
      }
    })
}
