import { Grid, H } from '@farewill/ui'
import MessageBox from 'components/message-box'
import { Input } from 'components/form/index'
import { CaseDetailsSectionProps } from './types'

const Vulnerabilities = ({ handleSave }: CaseDetailsSectionProps) => {
  return (
    <>
      <H size="S">Caller vulnerabilities</H>
      <MessageBox warning margin={['S', 0, 'M']}>
        If you have explicit consent, note the detail of the vulnerability and
        any reasonable adjustment we should make.
      </MessageBox>
      <Grid style={{ gridAutoFlow: 'row' }}>
        <Grid.Item>
          <Input
            name="vulnerabilities"
            component="textarea"
            label="Vulnerabilities *"
            rows={3}
            handleSave={handleSave}
          />
        </Grid.Item>
      </Grid>
    </>
  )
}

export default Vulnerabilities
