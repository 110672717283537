import styled, { css } from 'styled-components'
import { NavLink } from 'react-router-dom'
import { GTR, FONT, COLOR } from '@farewill/ui/tokens'
import { alpha } from '@farewill/ui/helpers/colors'

const StyledList = styled.ul<{ $secondary: boolean; $hasBorder: boolean }>`
  margin: 0;
  display: flex;
  ${({ $hasBorder }) =>
    $hasBorder && `border-bottom: 1px solid ${COLOR.GREY.LIGHT};`}

  ${({ $secondary }) => $secondary && `margin-top: ${GTR.M};`}
`

const StyledListItem = styled.li`
  &:not(:last-of-type) {
    margin-right: ${GTR.L};
  }
`

const StyledNavLink = styled(NavLink)<{
  $secondary: boolean
  $paddingBottom: string
  $fontWeight?: string
  $borderColor: string
  $boldActiveLink: boolean
  $arrow: boolean
}>`
  display: block;
  padding-bottom: ${({ $paddingBottom }) => $paddingBottom};
  position: relative;
  text-decoration: none;
  color: ${COLOR.BLACK};
  font-weight: ${({ $secondary, $fontWeight }) =>
    $secondary ? FONT.WEIGHT.REGULAR : $fontWeight};
  font-size: ${({ $secondary }) => ($secondary ? FONT.SIZE.S : FONT.SIZE.M)};

  &:hover,
  &:focus {
    outline: 0;
    text-decoration: none;
  }

  &:after {
    content: '';
    display: block;
    height: 4px;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  }

  &:hover {
    &:after {
      background-color: ${alpha(COLOR.GREY.DARK, 0.15)};
    }
  }

  &:focus {
    &:after {
      background-color: ${alpha(COLOR.GREY.DARK, 0.35)};
    }
  }

  &.active {
    position: relative;
    ${({ $boldActiveLink }) =>
      $boldActiveLink && `font-weight: ${FONT.WEIGHT.BOLD};`}

    &:after {
      background-color: ${({ $borderColor }) => $borderColor};
    }

    ${({ $arrow, $borderColor }) =>
      $arrow &&
      css`
        &:before {
          content: '';
          width: 0;
          height: 0;
          border-left: 9px solid transparent;
          border-right: 9px solid transparent;
          border-top: 9px solid ${$borderColor};
          position: absolute;
          bottom: -9px;
          left: 50%;
          transform: translateX(-50%);
        }
      `}
  }
`

type NavigationTabsProps = {
  className?: string
  links: {
    label: string
    url: string
    exact?: boolean
    isActive?: () => boolean
  }[]
  isSecondary?: boolean
  hasSecondary?: boolean
  paddingBottom?: string
  hasBorder?: boolean
  borderColor?: string
  fontWeight?: number
  boldActiveLink?: boolean
}

const NavigationTabs = ({
  className,
  links = [],
  isSecondary = false,
  hasSecondary = false,
  paddingBottom = GTR.S,
  hasBorder = true,
  borderColor = COLOR.ACCENT.PRIMARY,
  boldActiveLink = true,
}: NavigationTabsProps) => (
  <nav>
    <StyledList
      $secondary={isSecondary}
      className={className}
      $hasBorder={hasBorder}
    >
      {links.map(({ label, url, exact = true, isActive }) => (
        <StyledListItem key={url}>
          <StyledNavLink
            exact={exact}
            to={url}
            activeClassName="active"
            $arrow={hasSecondary}
            $secondary={isSecondary}
            $paddingBottom={paddingBottom}
            $borderColor={borderColor}
            $boldActiveLink={boldActiveLink}
            isActive={isActive}
          >
            {label}
          </StyledNavLink>
        </StyledListItem>
      ))}
    </StyledList>
  </nav>
)

export default NavigationTabs
