import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import lowerCase from 'lodash/lowerCase'

import { Button, ButtonGroup, P, Wrapper } from '@farewill/ui'

import { hideModal } from 'state/actions'

const TaskEmailStopModal = ({ config: { actionTask, task } }) => {
  const dispatch = useDispatch()
  const isComplete = !!task.attributes.completedAt
  const stopTrigger = task.attributes.stopEmailTriggers[0]

  const contentScheduleEmailStopOnUncomplete = (
    <>
      <P size="S">Are you sure you want to stop the weekly updates?</P>
      <P size="S">
        The weekly updates will restart when the task is ticked off.
      </P>
    </>
  )

  const contentScheduledEmailStopOnComplete = (
    <>
      {stopTrigger && (
        <P size="S">
          Marking this task as complete will stop{' '}
          {lowerCase(stopTrigger.emailTemplateName)} email.
        </P>
      )}
      <P size="S"> Weekly emails cannot be restarted.</P>
    </>
  )

  const handleCancel = () => dispatch(hideModal())
  const handleConfirm = async () => {
    await actionTask({ sendEmail: false })

    dispatch(hideModal())

    toast(
      `Task marked as ${
        isComplete ? 'incomplete' : 'complete'
      } and emails stopped.`,
      {
        toastId: `task-marked-as-${isComplete ? 'incomplete' : 'complete'}`,
      }
    )
  }

  return (
    <>
      <Wrapper>
        {isComplete
          ? contentScheduleEmailStopOnUncomplete
          : contentScheduledEmailStopOnComplete}
      </Wrapper>
      <ButtonGroup gap="S" margin={['L', 0, 0]}>
        <Button.Primary type="button" onClick={handleConfirm}>
          Confirm
        </Button.Primary>
        <Button.Underline type="button" onClick={handleCancel}>
          Cancel
        </Button.Underline>
      </ButtonGroup>
    </>
  )
}

export default TaskEmailStopModal
