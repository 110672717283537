import styled, { css } from 'styled-components'
import { Wrapper } from '@farewill/ui'
import { GTR, BORDER, COLOR } from '@farewill/ui/tokens'

const CallBox = styled(({ live, ...rest }) => <Wrapper {...rest} />)`
  border: 2px solid ${COLOR.GREY.LIGHT};
  border-radius: ${BORDER.RADIUS.S};
  padding: ${GTR.S};
  display: inline-block;

  ${({ live }) =>
    live &&
    css`
      border-color: ${COLOR.STATE.SUCCESS};
      color: ${COLOR.STATE.SUCCESS};
    `}
`

export default CallBox
