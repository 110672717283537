import { Wrapper } from '@farewill/ui'
import { COLOR, GTR } from '@farewill/ui/tokens'
import React from 'react'
import styled from 'styled-components'

const SlackChannel = styled.span`
  padding: ${GTR.XS};
  background-color: ${COLOR.ACCENT.SECONDARY};
  border-radius: 2px;
  color: white;
  white-space: nowrap;
`

const FuneralsFallback = (): React.ReactElement => (
  <Wrapper background={COLOR.GREY.LIGHT} padding={GTR.S} centered>
    Something went wrong! Please message us in{' '}
    <SlackChannel>#funerals-backstage-feedback</SlackChannel>
  </Wrapper>
)

export default FuneralsFallback
