import find from 'lodash/find'
import { QuoteResult, QuoteItem } from 'lib/models/funerals/quote'

export type QuoteChangeItem = QuoteItem & {
  costDifference: number | null
}

export type QuoteChanges = {
  costDifference: number
  items: QuoteChangeItem[]
}

const hasChanged = (
  editedItem: QuoteItem,
  currentItem?: QuoteItem
): boolean => {
  if (currentItem == null) {
    return true
  }

  const answerHasChanged = currentItem.answer !== editedItem.answer
  const costHasChanged = currentItem.cost !== editedItem.cost

  return answerHasChanged || costHasChanged
}

export function calculateChanges(
  current: QuoteResult,
  edited: QuoteResult
): QuoteChanges {
  const changes: QuoteChanges = {
    costDifference: edited.totalCost - current.totalCost,
    items: [],
  }

  edited.items.forEach((editedItem) => {
    const currentItem = find(current.items, { id: editedItem.id })

    if (hasChanged(editedItem, currentItem)) {
      changes.items.push({
        ...editedItem,
        costDifference: difference(editedItem.cost, currentItem?.cost),
      })
    }
  })

  const deletedItems = current.items.filter(
    (currentItem) => !find(edited.items, { id: currentItem.id })
  )

  changes.items.push(
    ...deletedItems.map((item) => ({
      ...item,
      costDifference: -(item.cost ?? 0),
    }))
  )

  return changes
}

function difference(
  a: number | undefined = 0,
  b: number | undefined = 0
): number | null {
  return a - b
}
