import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Button, ChevronRightIcon } from '@farewill/ui'
import { COLOR, FONT, GTR } from '@farewill/ui/tokens'
import groupBy from 'lodash/groupBy'
import sortBy from 'lodash/sortBy'

import useApi from 'lib/effects/api'

const Table = styled.table`
  border-collapse: collapse;
  margin-left: -${GTR.S};
`

const TR = styled.tr`
  &:nth-child(even) > td {
    background-color: ${COLOR.BACKGROUND.SMOKE};
  }
`

const TH = styled.th`
  font-size: ${({ $subhead }) => ($subhead ? FONT.SIZE.M : FONT.SIZE.S)};
  font-weight: ${({ $subhead }) => ($subhead ? 'bold' : 'normal')};
  text-align: left;
  font-feature-settings: 'tnum';
  vertical-align: bottom;
  border-bottom: 1px solid ${COLOR.GREY.LIGHT};
  padding: ${GTR.S} ${GTR.XS};
  padding-top: ${({ $subhead }) => ($subhead ? GTR.M : GTR.S)};

  /* Rest of cells are divided by remaining space */
  width: 11%;

  /* Totals cells */
  &:nth-last-child(1),
  &:nth-last-child(2) {
    width: 8%;
  }

  /* Name cell */
  &:first-child {
    width: 16%;
    padding: ${GTR.S};
  }
`

const THead = styled.thead`
  ${({ $visuallyHidden }) =>
    $visuallyHidden
      ? `
  clip: rect(0 0 0 0); 
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap; 
  width: 1px;
  `
      : ''}
`

const TD = styled.td`
  font-size: ${FONT.SIZE.S};
  text-align: left;
  font-feature-settings: 'tnum';
  border-bottom: 1px solid ${COLOR.GREY.LIGHT};
  padding: ${GTR.S};

  & > * {
    text-align: left;
  }

  /* The button has it's own padding that we need to account for */
  &:first-child {
    padding: ${GTR.S};
  }
`

const ArrowIcon = styled(ChevronRightIcon)`
  margin-left: ${GTR.XS};
  color: ${COLOR.GREY.MEDIUM};
  ${({ open }) => open && `transform: rotate(90deg);`}
`

const CaseLoadButton = styled.button`
  border: none;
  background: none;
  font-weight: ${FONT.WEIGHT.BOLD};
  font-size: ${FONT.SIZE.S};
`

const AdminUserButton = styled(Button.Underline)`
  font-weight: normal;
  font-size: ${FONT.SIZE.S};
`

const CaseLoadTable = ({ cases, onSelectUser }) => {
  const [open, setOpen] = useState(false)
  const [{ data: caseOwners, isLoading }, fetchItems] = useApi({
    isLoading: true,
  })

  const groupedByType =
    caseOwners &&
    groupBy(caseOwners, (item) => {
      const { caseTypes } = item.attributes
      if (caseTypes.direct > 0 && caseTypes.attended > 0) {
        return 'both'
      } else if (caseTypes.attended > 0) {
        return 'attended'
      } else if (caseTypes.direct > 0) {
        return 'direct'
      }
    })

  useEffect(() => {
    if (open === true) {
      fetchItems({ url: '/api/case-owners/funerals' })
    }
  }, [fetchItems, open, cases])

  return (
    <>
      <CaseLoadButton
        size="S"
        type="button"
        strong
        onClick={() => {
          setOpen(!open)
        }}
      >
        Case load
        <ArrowIcon size="S" open={open} inline />
      </CaseLoadButton>

      {open && !isLoading && (
        <Table>
          <thead>
            <TR>
              <TH>Team member</TH>
              <TH>Paperwork outstanding</TH>
              <TH>Waiting on collection date</TH>
              <TH>Waiting on cremation date</TH>
              <TH>Waiting on delivery date</TH>
              <TH>Feedback call outstanding</TH>
              <TH>Close case</TH>
              <TH>Total</TH>
              <TH></TH>
            </TR>
          </thead>

          <Group
            title="Attended services and direct cremations"
            visuallyHideTitle
            caseOwners={groupedByType.both}
            onSelectUser={onSelectUser}
          />
          <Group
            title="Attended services"
            caseOwners={groupedByType.attended}
            onSelectUser={onSelectUser}
          />
          <Group
            title="Direct cremations"
            caseOwners={groupedByType.direct}
            onSelectUser={onSelectUser}
            showWaitingForCremationDate
          />
        </Table>
      )}
    </>
  )
}

export default CaseLoadTable

function Group({
  title,
  caseOwners,
  onSelectUser,
  showWaitingForCremationDate = false,
  visuallyHideTitle = false,
}) {
  return (
    <>
      <THead $visuallyHidden={visuallyHideTitle}>
        <TR>
          <TH $subhead colSpan="8">
            {title}
          </TH>
          <TH>{showWaitingForCremationDate && 'Total (no crem date)'}</TH>
        </TR>
      </THead>
      <tbody>
        {sortBy(caseOwners, 'attributes.adminUser.name')?.map((caseOwner) => {
          return (
            <TR key={caseOwner.id}>
              <TD>
                <AdminUserButton
                  flush
                  onClick={() => {
                    onSelectUser(caseOwner.id)
                  }}
                >
                  {caseOwner.attributes.adminUser.name}
                </AdminUserButton>
              </TD>
              <TD>{caseOwner.attributes.paperworkOutstanding}</TD>
              <TD>{caseOwner.attributes.waitingOnCollectionDate}</TD>
              <TD>{caseOwner.attributes.waitingOnCremationDate}</TD>
              <TD>{caseOwner.attributes.waitingOnDeliveryDate}</TD>
              <TD>{caseOwner.attributes.feedbackCallOutstanding}</TD>
              <TD>{caseOwner.attributes.closeCase}</TD>
              <TD>{caseOwner.attributes.totalCases}</TD>
              <TD>
                {showWaitingForCremationDate &&
                  caseOwner.attributes.paperworkOutstanding +
                    caseOwner.attributes.waitingOnCollectionDate +
                    caseOwner.attributes.waitingOnCremationDate}
              </TD>
            </TR>
          )
        })}
      </tbody>
    </>
  )
}
