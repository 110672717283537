import { useFormikContext } from 'formik'

import { Input, RadioGroup } from 'components/form'
import { ProbateLeadAttributes } from 'lib/models/lead'
import { QuestionConfig } from 'lib/types/form'

export const Question = ({ question }: { question: QuestionConfig }) => {
  const { handleSubmit, values } = useFormikContext<ProbateLeadAttributes>()

  return question.options ? (
    <RadioGroup
      name={question.name}
      label={question.getLabel ? question.getLabel(values) : question.label}
      options={question.options}
      handleSave={() => handleSubmit()}
      hint={question.hint}
    />
  ) : question.component ? (
    <question.component />
  ) : (
    <Input
      name={question.name}
      label={question.getLabel ? question.getLabel(values) : question.label}
      handleSave={() => handleSubmit()}
      hint={question.hint}
      isCurrency={question.isCurrency}
    />
  )
}
