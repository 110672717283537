import PropTypes from 'prop-types'
import { Grid, H } from '@farewill/ui'
import { useFormikContext } from 'formik'

import { strToBool } from 'utils/helpers'
import { NOT_ASKED_OPTIONS } from 'utils/enums'

import CircularRadioGroup from 'components/form/circular-radio-group'
import NotesInput from 'components/notes-input'

import withForm from '../components/with-form'
import Will from './will'
import Intestacy from './intestacy'

import { HAS_WILL, WILL_OR_INTESTACY_NOTES } from './field-names'

const WillOrIntestacy = ({ probateEstateId, saveProbateEstateField }) => {
  const { values } = useFormikContext()
  const hasWillAnswered = Boolean(values[HAS_WILL])
  const hasWill = strToBool(values[HAS_WILL])

  return (
    <Grid gap="XL" data-scroll-id="2">
      <Grid.Item span={8}>
        <Grid>
          <Grid.Item>
            <H size="M">2. Will or intestacy</H>
            <CircularRadioGroup
              label="Is there a will, whether or not it’s valid?"
              name={HAS_WILL}
              options={NOT_ASKED_OPTIONS}
              handleSave={saveProbateEstateField}
              inline
            />
          </Grid.Item>
          {hasWillAnswered && (
            <Grid.Item>
              {hasWill ? (
                <Will
                  probateEstateId={probateEstateId}
                  saveProbateEstateField={saveProbateEstateField}
                />
              ) : (
                <Intestacy
                  probateEstateId={probateEstateId}
                  saveProbateEstateField={saveProbateEstateField}
                />
              )}
            </Grid.Item>
          )}
          <Grid.Item>
            <NotesInput
              name={WILL_OR_INTESTACY_NOTES}
              handleSave={saveProbateEstateField}
            />
          </Grid.Item>
        </Grid>
      </Grid.Item>
    </Grid>
  )
}

WillOrIntestacy.propTypes = {
  probateEstateId: PropTypes.string.isRequired,
  saveProbateEstateField: PropTypes.func.isRequired,
}

export default withForm(WillOrIntestacy)
