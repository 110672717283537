import { useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Checkbox } from '@farewill/ui'
import { GTR } from '@farewill/ui/tokens'
import { useField, useFormikContext } from 'formik'

import IndentedRadioGroup from 'components/form/indented-radio-group'
import DatetimePicker from 'components/form/datetime-picker'

import { getDateRange, getDateRangePresetOptions } from '../../helpers'

const StyledCheckbox = styled(Checkbox)`
  margin-bottom: ${GTR.XS};
  margin-top: ${GTR.XS};
`

const StyledDatetimePicker = styled(DatetimePicker)`
  margin-bottom: ${GTR.S};
`

const PresetCheckbox = ({ label, name }) => {
  const [field] = useField({ name, type: 'checkbox' })
  return (
    <StyledCheckbox key={name} name={name} label={label} size="S" {...field} />
  )
}

const DateRangeFilter = ({ name }) => {
  const { setFieldValue, values, setFieldTouched } = useFormikContext()
  const { option, presets } = values[name]

  useEffect(() => {
    if (option === 'any') {
      setFieldValue(`${name}.gte`, '')
      setFieldValue(`${name}.lte`, '')
    }

    if (option === 'preset') {
      const dateRange = getDateRange(presets)
      setFieldValue(`${name}.gte`, dateRange.gte)
      setFieldValue(`${name}.lte`, dateRange.lte)
    }

    if (option === 'custom' && !values[name].gte && !values[name].lte) {
      setFieldValue(`${name}.gte`, '')
      setFieldValue(`${name}.lte`, '')
      setFieldTouched(`${name}.gte`, false)
      setFieldTouched(`${name}.lte`, false)
    }
  }, [name, option, presets, values, setFieldValue, setFieldTouched])

  return (
    <IndentedRadioGroup
      name={`${name}.option`}
      options={[
        { value: 'any', label: 'Any' },
        {
          value: 'preset',
          label: 'Preset',
          indentedContent: (
            <>
              {getDateRangePresetOptions().map(
                ({ label, name: optionName }) => (
                  <PresetCheckbox
                    label={label}
                    name={`${name}.presets.${optionName}`}
                    key={optionName}
                  />
                )
              )}
            </>
          ),
        },
        {
          value: 'custom',
          label: 'Custom',
          indentedContent: (
            <>
              <StyledDatetimePicker
                label="Start date"
                name={`${name}.gte`}
                time={false}
                max={values[name].lte}
              />
              <StyledDatetimePicker
                label="End date"
                name={`${name}.lte`}
                time={false}
                min={values[name].gte}
              />
            </>
          ),
        },
      ]}
    />
  )
}

DateRangeFilter.propTypes = {
  name: PropTypes.string.isRequired,
}

export default DateRangeFilter
