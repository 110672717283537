import { useCallback } from 'react'
import { useDropzone } from 'react-dropzone'
import styled from 'styled-components'
import { ErrorMessage, useFormikContext } from 'formik'
import { Wrapper, P } from '@farewill/ui'
import { BORDER, COLOR, FONT, GTR } from '@farewill/ui/tokens'

const ACCEPTED_FILE_TYPES = ['.png', '.svg', '.jpg', '.jpeg', '.pdf']

const StyledDropZone = styled.div`
  background-color: ${COLOR.WHITE};
  border-color: ${COLOR.GREY.LIGHT};
  border-radius: ${BORDER.RADIUS.S};
  border-style: dashed;
  border-width: 2px;
  cursor: pointer;
  padding: ${GTR.L};
  outline: none;

  &:focus {
    box-shadow: 0 0 0 3px ${COLOR.STATE.ACTIVE};
  }
`

const StyledErrorMessage = styled(ErrorMessage)`
  font-size: ${FONT.SIZE.S};
  color: ${COLOR.STATE.ERROR};
`

type Props = {
  formikField: string
  name: string
  imageWidth?: number
  imageHeight?: number
}

const DropZone = ({
  formikField,
  name,
  imageWidth,
  imageHeight,
}: Props): React.ReactElement => {
  const { setFieldValue, touched, setTouched, setErrors, errors } =
    useFormikContext()

  const onDropAccepted = useCallback(
    (files) => {
      const file = files[0]
      const image = new Image()
      image.src = window.URL.createObjectURL(file)
      image.onload = () => {
        if (imageWidth && image.width !== imageWidth) {
          setTouched({ ...touched, [formikField]: true }, false)
          setErrors({
            ...errors,
            [formikField]: `Image too ${
              image.width < imageWidth ? 'narrow' : 'wide'
            }. Please upload an image exactly ${imageWidth}px wide`,
          })
        } else if (imageHeight && image.height !== imageHeight) {
          setTouched({ ...touched, [formikField]: true }, false)
          setErrors({
            ...errors,
            [formikField]: `Image too ${
              image.height < imageHeight ? 'short' : 'tall'
            }. Please upload an image exactly ${imageHeight}px tall`,
          })
        } else {
          setFieldValue(formikField, file)
          setTouched({ ...touched, [formikField]: true })
        }
      }
    },
    [
      formikField,
      imageWidth,
      imageHeight,
      setTouched,
      setFieldValue,
      touched,
      errors,
      setErrors,
    ]
  )

  const onDropRejected = useCallback(
    (files) => {
      setTouched({ ...touched, [formikField]: true }, false)
      setErrors({
        ...errors,
        [formikField]: files[0].errors
          .map((error: { message: string }) => error.message)
          .join(', '),
      })
    },
    [errors, setErrors, formikField, touched, setTouched]
  )

  const { getRootProps, getInputProps } = useDropzone({
    onDropAccepted,
    onDropRejected,
    maxSize: 500_000, // 500KB
    multiple: false,
    accept: ACCEPTED_FILE_TYPES,
  })

  return (
    <StyledDropZone {...getRootProps()}>
      <input {...getInputProps()} />
      <Wrapper centered>
        <P strong>Drag and drop file here to add the {name}</P>
        <P>Or click to browse for files</P>
        <StyledErrorMessage name={formikField} component={P} />
      </Wrapper>
    </StyledDropZone>
  )
}

export default DropZone
