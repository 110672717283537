import PropTypes from 'prop-types'
import styled from 'styled-components'
import { scrollToDataId } from 'lib/window/scroll'

const StyledLink = styled.span`
  text-decoration: underline;
  cursor: pointer;
`

const ScrollTo = ({ children, scrollId }) => (
  <StyledLink onClick={() => scrollToDataId(scrollId)}>{children}</StyledLink>
)

ScrollTo.propTypes = {
  children: PropTypes.node.isRequired,
  scrollId: PropTypes.string.isRequired,
}

export default ScrollTo
