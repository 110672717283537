import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import useApi from 'lib/effects/api'
import { getMomentDate } from 'lib/formik/dates'
import { formatDate } from 'lib/formatting/dates'
import { Grid, P } from '@farewill/ui'
import { Link } from 'react-router-dom'

import {
  getFullName,
  getContactSentence,
  getProbateEstateDetails,
} from './helpers'

const CaseDetailsSummary = ({ probateCase }) => {
  const dispatch = useDispatch()
  const lead = useSelector((state) => state.lead)
  const caseScore = lead.attributes?.caseScore

  const [
    { isLoading: isProbateEstatePeopleLoading, data: probateEstatePeople },
    getProbateEstatePeople,
  ] = useApi({ data: [] })

  const [
    { isLoading: isProbateEstateLoading, data: probateEstate },
    fetchProbateEstate,
  ] = useApi({ isLoading: true })

  const dateOfDeath = probateEstate
    ? formatDate(
        getMomentDate({ prefix: 'death', values: probateEstate.attributes })
      ) || 'Not added'
    : undefined

  useEffect(() => {
    getProbateEstatePeople({
      url: `/api/probate-estates/${probateCase.estate.id}/people`,
    })
  }, [getProbateEstatePeople, probateCase.estate.id])

  useEffect(() => {
    fetchProbateEstate({
      url: `/api/probate-estates/${probateCase.estate.id}`,
    })
  }, [fetchProbateEstate, probateCase.estate.id])

  const estatePeopleSentences = probateEstatePeople
    .map((person) =>
      getContactSentence(
        dispatch,
        person.attributes,
        probateCase.deceasedFirstName
      )
    )
    .filter((sentence) => sentence.length > 0)

  const estateDetails = getProbateEstateDetails(probateEstate, probateCase)

  return (
    <>
      {(probateCase.deceasedFirstName || probateCase.deceasedLastName) && (
        <Grid.Item>
          <P>
            The estate of{' '}
            <strong>
              <Link
                to={`/probate/estates/${probateCase.estate.id}/fact-find#1.2`}
              >
                {getFullName(
                  probateCase.deceasedFirstName,
                  probateCase.deceasedLastName
                )}
              </Link>
            </strong>
            .
          </P>
        </Grid.Item>
      )}
      <Grid.Item>
        <P>
          Date of death:{' '}
          {isProbateEstateLoading ? (
            'Loading estate...'
          ) : (
            <Link
              to={`/probate/estates/${probateCase.estate.id}/fact-find#1.3`}
            >
              {dateOfDeath}
            </Link>
          )}
        </P>
      </Grid.Item>
      <Grid.Item>
        <P>
          Case score:{' '}
          {isProbateEstateLoading
            ? 'Loading estate...'
            : caseScore || 'Not added'}
        </P>
      </Grid.Item>
      {isProbateEstatePeopleLoading ? (
        <Grid.Item>
          <P size="S">Loading contacts...</P>
        </Grid.Item>
      ) : (
        estatePeopleSentences.length > 0 && (
          <Grid.Item>
            {estatePeopleSentences.map((sentence, index) => (
              <P key={index}>
                {sentence.map((sentencePart, index) => (
                  <span key={index}>{sentencePart}</span>
                ))}
              </P>
            ))}
          </Grid.Item>
        )
      )}
      {isProbateEstateLoading ? (
        <Grid.Item>
          <P size="S">Loading estate...</P>
        </Grid.Item>
      ) : (
        estateDetails.length > 0 && (
          <Grid.Item>
            {estateDetails.map((estateDetail, index) => (
              <span key={index}>
                {index > 0 && ' · '}
                {estateDetail}
              </span>
            ))}
          </Grid.Item>
        )
      )}
    </>
  )
}

export default CaseDetailsSummary
