import { Button, P, Wrapper } from '@farewill/ui'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import apiRequest from 'lib/axios/api-request'
import { hideModal } from 'state/actions'

interface RemoveAccountModalProps {
  config: { id: string; email: string }
}

export const RemoveAccountModal = ({ config }: RemoveAccountModalProps) => {
  const { id, email } = config
  const dispatch = useDispatch()
  const history = useHistory()

  const onConfirmClick = async () => {
    try {
      await apiRequest({ url: `/api/partner-accounts/${id}`, method: 'DELETE' })
      dispatch(hideModal())
      history.go(0) // Refresh page to re-retrieve accounts after delete
    } catch (err) {
      /*
       * It is a better user experience to catch all our errors rather than
       * leaving them unhandled as it that will show an Unhandled Runtime Error.
       * As we are using `apiRequest`, this will display a toast with the error
       * by default. We can, therefore, just log the error for debuggin purposes
       * and rely on Sentry to alert us to any errors in the server.
       */
      console.error(`Deleting partner account: ${id}`, err)
    }
  }

  return (
    <Wrapper>
      <P margin={[0, 0, 'L']}>
        Please confirm you want to remove {email} from Spotlight.
      </P>
      <Button.Primary type="button" stretch onClick={onConfirmClick}>
        Confirm and remove user
      </Button.Primary>
    </Wrapper>
  )
}
