import { forwardRef } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Wrapper } from '@farewill/ui'
import { BORDER, COLOR, GTR } from '@farewill/ui/tokens'
import { getWidthOfContainerColumns } from '@farewill/ui/helpers/layout'

import useSticky from 'lib/effects/sticky'

const getWidth = (isExpanded) =>
  getWidthOfContainerColumns(isExpanded ? 10 : 4, 'L', GTR.XL)

const StyledOuterWrapper = styled.div`
  position: absolute;
  top: 0;
  height: 0;
  z-index: 3;
  width: ${({ theme }) => getWidth(theme.isExpanded)}px;
`

const StyledDecorativeWrapper = styled.div`
  position: absolute;
  top: 0;
  height: 100vh;
  width: ${({ theme }) => getWidth(theme.isExpanded)}px;

  ${({ $sticky }) => $sticky && `position: fixed;`}

  &:after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    width: calc((100vw - ${getWidthOfContainerColumns(12, 'L')}px) / 2);
    background-color: ${COLOR.BACKGROUND.SMOKE};
    transform: translateX(100%);
  }
`

const StyledStickyWrapper = styled(Wrapper)`
  background-color: ${COLOR.BACKGROUND.SMOKE};
  box-shadow: ${BORDER.SHADOW.M};
  padding: ${GTR.S};
  width: ${({ theme }) => getWidth(theme.isExpanded)}px;
  height: 100%;
  overflow: scroll;

  ${({ $sticky }) =>
    $sticky &&
    `
      position: fixed;
      top: 0;
      bottom: 0;
  `}
`

const StickyWrapper = forwardRef(({ children, stickyContent }, ref) => {
  const [sticky] = useSticky(ref, stickyContent, 0)
  return (
    <StyledOuterWrapper ref={ref}>
      <StyledDecorativeWrapper $sticky={sticky}>
        <StyledStickyWrapper $sticky={sticky}>{children}</StyledStickyWrapper>
      </StyledDecorativeWrapper>
    </StyledOuterWrapper>
  )
})

StickyWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  isExpanded: PropTypes.bool.isRequired,
  stickyContent: PropTypes.oneOf([
    'probate-case',
    'probate-estate',
    'lpa-case',
    'funeral-case',
    'will-case',
  ]).isRequired,
}

export default StickyWrapper
