export const LEAD_EVENTS = {
  added: 'added a lead',
  call_made: 'made a call',
  comment_added: 'left a comment',
  contact_requested: 'requested a contact',
}

export const ERROR_MADE_BY = {
  customer: 'a customer',
  farewill: 'Farewill, before referral',
  farewill_legal_services: 'Farewill Legal Services, after referral',
  probate_registry: 'the probate registry',
}
