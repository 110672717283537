import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import find from 'lodash/find'

import { H } from '@farewill/ui'
import List from './list'
import Upload from './upload'
import PrivateRoute from 'components/private-route/containers'
import PreviewOverlay from './preview-overlay'
import { DocumentsProvider, useDocumentsContext } from './context'
import Edit from './edit'

const Documents = () => {
  const { id: probateCaseId } = useParams()
  const [documentIdForEdit, setDocumentIdForEdit] = useState(null)
  const isEditMode = !!documentIdForEdit
  const {
    publishedDocuments: documents,
    fetchDocuments,
    isFetchingDocuments,
  } = useDocumentsContext()

  const isLoading = isFetchingDocuments && documents.length === 0

  useEffect(() => {
    fetchDocuments({ queryParams: { 'filter[probateCaseId]': probateCaseId } })
  }, [fetchDocuments, probateCaseId, documentIdForEdit])

  return (
    <>
      <PrivateRoute
        path="/probate/cases/:probateCaseId/documents/:id"
        component={PreviewOverlay}
      />
      <H size="M">Documents</H>
      {isEditMode ? (
        <Edit
          document={find(documents, { id: documentIdForEdit })}
          setDocumentIdForEdit={setDocumentIdForEdit}
        />
      ) : (
        <Upload probateCaseId={probateCaseId} />
      )}
      <List
        documents={documents}
        isLoading={isLoading}
        setDocumentIdForEdit={setDocumentIdForEdit}
      />
    </>
  )
}

const DocumentsWithProvider = () => (
  <DocumentsProvider>
    <Documents />
  </DocumentsProvider>
)

export default DocumentsWithProvider
