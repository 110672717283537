import { connect } from 'react-redux'
import Header from '../components'

const mapStateToProps = (state) => {
  return {
    errors: state.errors,
    messages: state.messages,
  }
}

export default connect(mapStateToProps, null)(Header)
