import PropTypes from 'prop-types'
import styled from 'styled-components'
import { P } from '@farewill/ui'
import { GTR, COLOR, FONT } from '@farewill/ui/tokens'
import { Link } from 'react-router-dom'
import { formatToHuman } from 'utils/helpers'
import uniqBy from 'lodash/uniqBy'

const ProductBadgeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`
const ProductBadge = styled.span`
  padding: 2px 8px;
  margin: 4px 0;
  color: ${COLOR.GREY.DARK};
  background: ${COLOR.ACCENT.PRIMARY};
  border-radius: 12px;
  font-size: ${FONT.SIZE.XXS};
  font-weight: ${FONT.WEIGHT.MEDIUM};
  font-family: ${FONT.FAMILY.BODY};
`

const CustomerName = styled(P)`
  font-weight: ${FONT.WEIGHT.BOLD};
  margin-bottom: ${GTR.XXS};
`

const ResultHighlight = styled.span`
  font-size: ${FONT.SIZE.XS};

  em {
    font-style: unset;
    font-weight: ${FONT.WEIGHT.MEDIUM};
  }
`

const FieldHighlightLabel = styled.span`
  text-transform: uppercase;
  color: ${COLOR.GREY.MEDIUM};
  font-family: ${FONT.FAMILY.BODY};
  font-size: ${FONT.SIZE.XXS};
  font-weight: ${FONT.WEIGHT.BOLD};
`

const ResultsListItem = styled.li`
  width: 100%;
  border-top: 1px solid ${COLOR.GREY.LIGHT};

  &:first-of-type {
    border-top: none;
  }
`

const ResultsLink = styled(Link)`
  display: flex;
  justify-content: space-between;
  padding: ${GTR.S} ${GTR.S};
  width: 100%;
  text-decoration: none;

  &:first-of-type {
    border-top: none;
  }

  &:hover,
  &:focus {
    text-decoration: none;
    background-color: ${COLOR.ACCENT.PRIMARY_20};
    outline-offset: -3px;
  }
`

const ProductBadgeWithCount = ({ product, count }) => {
  if (!count) return null

  return (
    <ProductBadge>
      {product}
      {count > 1 && ` x${count}`}
    </ProductBadge>
  )
}

const ProductBadges = ({ contact }) => {
  const activeLeads = contact.attributes.leads
    .filter((lead) => lead.status !== 'converted' && lead.status !== 'lost')
    .reduce(
      (acc, lead) => {
        acc[lead.product] = acc[lead.product] + 1
        return acc
      },
      { funeral: 0, probate: 0, will: 0, lpa: 0, funeral_plan: 0 }
    )

  const {
    funerals,
    funeralPlansAsCoveredIndividual,
    funeralPlansAsPurchaser,
    lpaCases,
    probateCases,
    willCases,
    account,
  } = contact.attributes

  const productCounts = {
    funeral: activeLeads.funeral + funerals.length,
    probate: activeLeads.probate + probateCases.length,
    will: activeLeads.will + willCases.length,
    lpa: activeLeads.lpa + lpaCases.length,
    funeral_plan:
      activeLeads.funeral_plan +
      uniqBy(
        [...funeralPlansAsCoveredIndividual, ...funeralPlansAsPurchaser],
        'id'
      ).length,
    online_will: account?.products.filter(
      (product) =>
        product.name === 'online_will' && product.purchasedAt !== null
    ).length,
  }

  return (
    <ProductBadgeWrapper>
      {['funeral', 'probate', 'will', 'lpa', 'funeral_plan', 'online_will'].map(
        (product, index) => (
          <ProductBadgeWithCount
            key={index}
            product={formatToHuman(product)}
            count={productCounts[product]}
          />
        )
      )}
    </ProductBadgeWrapper>
  )
}

const Result = ({ contact, onLinkClick }) => {
  return (
    <ResultsLink to={`/contacts/${contact.id}`} onClick={onLinkClick}>
      <div>
        <CustomerName>
          {contact.attributes.firstName} {contact.attributes.lastName}
        </CustomerName>

        {Object.entries(contact.attributes.searchHighlights)
          .filter(([field]) => field !== 'name')
          .map(([field, content], index) => (
            <ResultHighlight key={index}>
              <FieldHighlightLabel>{formatToHuman(field)}</FieldHighlightLabel>
              <div dangerouslySetInnerHTML={{ __html: content }}></div>
            </ResultHighlight>
          ))}
      </div>

      <ProductBadges contact={contact} />
    </ResultsLink>
  )
}

const ResultsList = ({ searchResults, onLinkClick }) =>
  searchResults.map((searchResult) => (
    <ResultsListItem key={searchResult.id}>
      <Result contact={searchResult} onLinkClick={onLinkClick} />
    </ResultsListItem>
  ))

ResultsList.propTypes = {
  searchResults: PropTypes.array,
  onLinkClick: PropTypes.func.isRequired,
}

ResultsList.defaultProps = {
  searchResults: [],
}

export default ResultsList
