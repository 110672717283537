import { useState } from 'react'
import { Formik, Form } from 'formik'
import { Button, ButtonGroup, P, Wrapper } from '@farewill/ui'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import lowerCase from 'lodash/lowerCase'
import upperFirst from 'lodash/upperFirst'
import moment from 'moment'

import { hideModal } from 'state/actions'

import {
  hasRepeatEmailTrigger,
  hasScheduledEmailTrigger,
  hasMultipleStartEmailTriggers,
  hasProcessedEmail,
  hasEmails,
  findScheduledEmailTrigger,
  findImmediateEmailTrigger,
} from './helpers'

import IndentedRadioGroup from 'components/form/indented-radio-group'
import EmailRecipients from 'components/email-recipients'
import EmailSentRecipients from 'components/email-sent-recipients'

const getInitialDelayDate = (trigger) => {
  return moment().add(trigger.initialDelay, 'milliseconds').format('D MMM YYYY')
}

const getToastMessage = ({
  sendEmail,
  isScheduled,
  hasMultipleStartTriggers,
  hadEmailsSent,
}) => {
  if (sendEmail === 'false') return 'Task marked as complete.'
  if (isScheduled && hasMultipleStartTriggers && hadEmailsSent)
    return 'Task complete and email scheduled.'
  if (isScheduled && hasMultipleStartTriggers)
    return 'Email sent and email scheduled.'
  if (isScheduled) return 'Email scheduled.'
  return 'Email sent.'
}

const TaskEmailModal = ({ config: { actionTask, updateTask, task } }) => {
  const [isLoading, setIsLoading] = useState(false)
  const dispatch = useDispatch()
  const probateCaseId = task.attributes.probateCaseId
  const scheduledTrigger = findScheduledEmailTrigger(task)
  const immediateTrigger = findImmediateEmailTrigger(task)
  const isScheduled = hasScheduledEmailTrigger(task)
  const hasMultipleStartTriggers = hasMultipleStartEmailTriggers(task)
  const hadEmailsSent = hasEmails(task)

  const handleSubmit = async (values) => {
    setIsLoading(true)

    try {
      await actionTask(values)
      toast(
        getToastMessage(
          {
            sendEmail: values.sendEmail,
            isScheduled,
            hasMultipleStartTriggers,
            hadEmailsSent,
          },
          {
            toastId: 'email-modal',
          }
        )
      )
    } catch {
      await updateTask(task.id, {})
    }

    dispatch(hideModal())
    setIsLoading(false)
  }

  return (
    <Formik initialValues={{ sendEmail: 'true' }} onSubmit={handleSubmit}>
      <Form>
        {isScheduled ? (
          <Wrapper margin={[0, 0, 'M']}>
            {hasMultipleStartTriggers && !hasProcessedEmail(task) && (
              <P size="S">
                We'll send the email '
                {upperFirst(lowerCase(immediateTrigger.template.name))}'.
              </P>
            )}
            {hasMultipleStartTriggers && hasProcessedEmail(task) && (
              <EmailSentRecipients task={task} />
            )}
            <P size="S">
              We'll tick this task off your list and schedule the{' '}
              {lowerCase(scheduledTrigger.template.name)} to send{' '}
              {hasRepeatEmailTrigger(task) ? 'from' : 'on'}{' '}
              {getInitialDelayDate(scheduledTrigger)}.
            </P>
            <EmailRecipients probateCaseId={probateCaseId} />
          </Wrapper>
        ) : (
          <>
            <IndentedRadioGroup
              name="sendEmail"
              options={[
                {
                  label: 'Send default email now',
                  value: 'true',
                  indentedContent: (
                    <Wrapper margin={[0, 0, 0, 20]}>
                      <EmailRecipients probateCaseId={probateCaseId} />
                    </Wrapper>
                  ),
                },
                { label: 'Send custom email', value: 'false' },
              ]}
            />
            <P size="S" margin={['M', 0]}>
              We’ll tick this task off your list.
            </P>
          </>
        )}

        <ButtonGroup gap="S">
          <Button.Primary type="submit" stretch loading={isLoading}>
            Confirm
          </Button.Primary>
          <Button.Underline type="button" onClick={() => dispatch(hideModal())}>
            Cancel
          </Button.Underline>
        </ButtonGroup>
      </Form>
    </Formik>
  )
}

export default TaskEmailModal
