const FETCH_LEAD_EVENTS = 'FETCH_LEAD_EVENTS'

export default function leadEvents(state = [], action = {}) {
  switch (action.type) {
    case FETCH_LEAD_EVENTS + '_FULFILLED':
      return action.payload.data.data

    case FETCH_LEAD_EVENTS + '_REJECTED':
      return []

    default:
      return state
  }
}
