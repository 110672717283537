const FETCH_PROBATE_CASE = 'FETCH_PROBATE_CASE'
const UPDATE_PROBATE_CASE = 'UPDATE_PROBATE_CASE'

export default function probateCase(state = {}, action = {}) {
  switch (action.type) {
    case 'UNSET_PRODUCT':
      return action.payload === 'probate' ? {} : state

    case FETCH_PROBATE_CASE + '_FULFILLED':
    case UPDATE_PROBATE_CASE + '_FULFILLED':
      return action.payload.data.data

    case FETCH_PROBATE_CASE + '_PENDING':
    case FETCH_PROBATE_CASE + '_REJECTED':
      return {}

    default:
      return state
  }
}
