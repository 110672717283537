import styled from 'styled-components'
import { Wrapper } from '@farewill/ui'
import { GTR } from '@farewill/ui/tokens'

const FilterPanelWrapper = styled(Wrapper)`
  padding: ${GTR.L};
  padding-left: 0;
  height: 100%;
  position: relative;
  font-size: ${GTR.S};
  box-shadow: 6px -2px 4px -2px rgba(0, 0, 0, 0.1);
`

export default FilterPanelWrapper
