import { Button, DeleteIcon, P, Wrapper } from '@farewill/ui'
import { BORDER, COLOR, FONT, GTR } from '@farewill/ui/tokens'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'
import { showModal } from 'state/actions'

import { GiftBeneficiaryAttributes } from 'lib/models/gift-beneficiary'

import EditCharityModal from '../modals/edit-charity'
import EditOrganisationModal from '../modals/edit-organisation'
import DeleteBeneficiaryModal from './delete-beneficiary-modal'
import ViewBeneficiaryModal from './view-beneficiary-modal'

const StyledWrapper = styled(Wrapper)`
  background-color: ${COLOR.ACCENT.SECONDARY_10};
  padding: ${GTR.S};
  border-radius: ${BORDER.RADIUS.S};
  margin-bottom: ${GTR.XS};
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const StyledBeneficiaryIdentifer = styled.span`
  font-weight: ${FONT.WEIGHT.MEDIUM};
  color: ${COLOR.BLACK};
`

const StyledButton = styled(Button.Underline)`
  font-size: ${FONT.SIZE.S};

  && {
    margin-bottom: -11px;
  }
`

type Props = {
  beneficiary: GiftBeneficiaryAttributes
  willId: number
  disabled?: boolean
}

const GiftBeneficiaryCard = ({
  beneficiary,
  willId,
  disabled,
}: Props): React.ReactElement => {
  const dispatch = useDispatch()

  const openViewBeneficiaryModal = () => {
    dispatch(
      showModal({
        component: ViewBeneficiaryModal,
        headingText: 'View beneficiary',
        config: { beneficiary },
      })
    )
  }

  const openEditBeneficiaryModal = () => {
    let args

    if (beneficiary.charity) {
      args = {
        component: EditCharityModal,
        headingText: 'Edit charity',
        config: { charity: beneficiary.charity, willId },
      }
    }

    if (beneficiary.organisation) {
      args = {
        component: EditOrganisationModal,
        headingText: 'Edit organisation',
        config: { organisation: beneficiary.organisation, willId },
      }
    }

    dispatch(showModal(args))
  }

  const isViewOnly =
    beneficiary.charity?.utmSource || beneficiary.organisation?.utmSource

  return (
    <StyledWrapper>
      <Wrapper>
        <P margin="0">
          <StyledBeneficiaryIdentifer>
            {beneficiary.charity?.legalName || beneficiary.organisation?.name}
          </StyledBeneficiaryIdentifer>
          {beneficiary.charity && ', Charity'}
          {beneficiary.organisation && ', Organisation'}
        </P>
        {disabled || isViewOnly ? (
          <StyledButton flush type="button" onClick={openViewBeneficiaryModal}>
            View
          </StyledButton>
        ) : (
          <StyledButton flush type="button" onClick={openEditBeneficiaryModal}>
            Edit
          </StyledButton>
        )}
      </Wrapper>
      {!disabled && (
        <Button.Plain
          type="button"
          onClick={() =>
            dispatch(
              showModal({
                component: DeleteBeneficiaryModal,
                headingText: 'Delete gift beneficiary',
                config: { beneficiaryId: beneficiary.id, willId },
              })
            )
          }
        >
          <DeleteIcon
            size="S"
            title="Delete gift beneficiary"
            color={COLOR.GREY.DARK}
          />
        </Button.Plain>
      )}
    </StyledWrapper>
  )
}

export default GiftBeneficiaryCard
