import { connect } from 'react-redux'
import { setCall, fetchLatestCalls } from '../../../state/actions'
import Calls from '../components'

const mapStateToProps = (state, ownProps) => {
  return {
    latestCalls: state.latestCalls,
    isLoading: state.requestsPending.includes('FETCH_LATEST_CALLS'),
    history: ownProps.history,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setCall: (call) => dispatch(setCall(call)),
    fetchLatestCalls: () => dispatch(fetchLatestCalls()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Calls)
