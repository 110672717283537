import { useState } from 'react'
import { Button, ButtonGroup, Wrapper, P } from '@farewill/ui'
import { useDispatch } from 'react-redux'

import { hideModal } from 'state/actions'

const Modal = ({ config: { task, deleteTask } }) => {
  const dispatch = useDispatch()
  const [isSubmitting, setIsSubmitting] = useState(false)

  const handleClick = async () => {
    setIsSubmitting(true)

    try {
      await deleteTask(task.id)
      dispatch(hideModal())
    } catch {
      setIsSubmitting(false)
    }
  }

  return (
    <Wrapper>
      <P>
        Are you sure you want to delete the task{' '}
        <strong>‘{task.attributes.title}’</strong>?
      </P>
      <P margin={[0, 0, 'M']}>This can’t be undone.</P>
      <ButtonGroup>
        <Button.Primary
          type="button"
          onClick={handleClick}
          loading={isSubmitting}
          stretch
        >
          Delete
        </Button.Primary>
        <Button.Underline onClick={() => dispatch(hideModal())}>
          Cancel
        </Button.Underline>
      </ButtonGroup>
    </Wrapper>
  )
}

export default Modal
