import styled from 'styled-components'
import { Wrapper, P } from '@farewill/ui'
import { COLOR, GTR } from '@farewill/ui/tokens'
import { useSelector } from 'react-redux'
import moment from 'moment'

const StyledEmailWrapper = styled(Wrapper)`
  background-color: ${COLOR.WHITE};
  padding: ${GTR.S};
  border: 1px solid ${COLOR.GREY.LIGHT};
`

const StyledEmailNotes = styled(P)`
  white-space: pre-wrap;
`

const ReferralEmailSummary = () => {
  const probateCase = useSelector((state) => state.probateCase)

  if (!probateCase.attributes.newLawReferral.referralEmailSentAt) {
    return null
  }

  return (
    <Wrapper margin={[0, 0, 'L']}>
      <P>
        Referral email sent to NewLaw by{' '}
        {probateCase.attributes.newLawReferral.referralEmailSentBy.name}
      </P>
      <StyledEmailWrapper>
        <P size="S" color={COLOR.GREY.MEDIUM}>
          {moment(
            probateCase.attributes.newLawReferral.referralEmailSentAt
          ).format('DD MMM YYYY, HH:mm')}
        </P>
        <StyledEmailNotes size="S">
          {probateCase.attributes.newLawReferral.referralEmailNotes}
        </StyledEmailNotes>
      </StyledEmailWrapper>
    </Wrapper>
  )
}

export default ReferralEmailSummary
