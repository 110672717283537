import { useEffect } from 'react'
import { Divider } from '@farewill/ui'
import merge from 'lodash/merge'
import omit from 'lodash/omit'

import { Formik, FormikErrors } from 'formik'

import useApi from 'lib/effects/api'
import { formatValuesForFormik } from 'utils/helpers'
import { LPA_LEAD_SCHEMA } from 'lib/formik/schemata'
import { LpaLeadAttributes, LpaQuoteAttributes, Partner } from 'lib/models/lead'
import { WillCase } from 'lib/models/will-case'
import { QuoteResponseDataLpa } from 'lib/types/api-response'
import { useLeadContext } from 'routes/lead/context'
import TakePayment from 'routes/lead/take-payment'
import QuoteForm from 'routes/lead/quote-form'
import { RESOURCES, useFetchResource } from 'api'

import {
  findNonEmptyString,
  formatAttributesForForm,
  getPartnerDataFromWillCase,
  outcomeForQuestions,
  visibleQuestions,
} from './helpers'
import Diagnosis from './diagnosis'
import ContactDetails from './contact-details'
import Outcome from './outcome'
import { allQuestions } from './questions'

const BLANK_VALUES = {
  contact: {
    email: '',
    firstName: '',
    lastName: '',
    phoneNumber: '',
  },
  quoteAttributes: {
    lpaType: 'single',
    price: '',
    partner: {
      title: '',
      firstName: '',
      lastName: '',
      dateOfBirth: '',
      email: '',
      phoneNumber: '',
      addressFields: {
        lineOne: '',
        lineTwo: '',
        city: '',
        postalCode: '',
        countryCode: '',
      },
    },
  },
}

const LpaForm = ({
  attributes,
  disabled,
}: {
  attributes: LpaLeadAttributes
  disabled?: boolean
}) => {
  const willCaseId = attributes.quoteAttributes.willCaseId
  const willCaseQuery = useFetchResource(
    {
      id: willCaseId || 0,
      resource: RESOURCES.WILL_CASES,
    },
    { enabled: !!willCaseId }
  ) as { data: WillCase; isLoading: boolean; isIdle: boolean }

  const willCase = willCaseQuery?.data?.attributes

  const relationshipStatusFromWillCase =
    willCase?.lead?.quoteAttributes?.relationshipStatus
  const partnerDataFromWillCase = getPartnerDataFromWillCase(willCase)
  const hasPartnerDataModifiedInLpaLeadForm =
    attributes.quoteAttributes.partner &&
    findNonEmptyString<Partner>(attributes.quoteAttributes.partner)

  const formattedValues = formatValuesForFormik(
    formatAttributesForForm({
      ...attributes,
      quoteAttributes: {
        ...attributes.quoteAttributes,
        relationshipStatus:
          attributes.quoteAttributes.relationshipStatus ||
          relationshipStatusFromWillCase,
        partner: hasPartnerDataModifiedInLpaLeadForm || partnerDataFromWillCase,
      },
    })
  )
  const initialValues = merge({}, BLANK_VALUES, formattedValues)
  const { onSubmit, lead } = useLeadContext()

  const [{ data: quote }, makeRequest] = useApi<QuoteResponseDataLpa>()

  const packagePriceInPence = quote?.attributes?.packagePriceInPence

  const leadId = lead?.id
  const lpaType = (lead?.attributes?.quoteAttributes as LpaQuoteAttributes)
    ?.lpaType
  useEffect(() => {
    if (leadId && lpaType) {
      makeRequest({
        url: '/api/quotes',
        method: 'POST',
        data: {
          data: {
            type: 'quotes',
            attributes: {
              product: 'lpa',
              leadId,
            },
          },
        },
      })
    }
  }, [leadId, lpaType, makeRequest])

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={LPA_LEAD_SCHEMA}
      validateOnMount
      validateOnChange={false}
      enableReinitialize
    >
      {({
        values,
        errors,
      }: {
        values: LpaLeadAttributes
        errors: FormikErrors<LpaLeadAttributes>
      }) => {
        const errorsBeforePayment = omit(errors, 'squareTransactionUrl')
        const canTakePayment =
          packagePriceInPence && Object.values(errorsBeforePayment).length === 0

        const questions = visibleQuestions({
          questions: allQuestions,
          values,
        })
        const outcome = outcomeForQuestions({
          questions,
          values,
        })
        const showTakePayment =
          ['single', 'couple'].includes(outcome.type) && packagePriceInPence

        return (
          <QuoteForm disabled={disabled}>
            <ContactDetails />
            <Divider margin={['XL', 0]} />
            <Diagnosis />
            <Outcome
              outcome={outcome}
              quotePriceInPence={packagePriceInPence}
            />
            {showTakePayment && (
              <>
                <Divider margin={['XL', 0]} />
                <TakePayment
                  calculatedPrice={packagePriceInPence}
                  skip={!canTakePayment}
                />
              </>
            )}
          </QuoteForm>
        )
      }}
    </Formik>
  )
}

export default LpaForm
