import styled from 'styled-components'
import { FONT } from '@farewill/ui/tokens'

const StyledEstateOfText = styled.span`
  display: block;
  font-size: ${FONT.SIZE.XS};
`

const EstateOfText = ({ probateCase }) => {
  if (!probateCase?.deceasedFirstName && !probateCase?.deceasedLastName) {
    return null
  }

  return (
    <StyledEstateOfText>
      Estate of {probateCase?.deceasedFirstName} {probateCase?.deceasedLastName}
    </StyledEstateOfText>
  )
}

export default EstateOfText
