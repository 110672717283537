import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useField } from 'formik'
import { Grid, H, Wrapper } from '@farewill/ui'
import { COLOR } from '@farewill/ui/tokens'

import { boolToStr, strToBool } from 'utils/helpers'

import Switch from 'components/switch'
import Tooltip from 'components/tooltip'

const StyledWrapper = styled(Wrapper)`
  position: relative;
`

const StyledSwitchWrapper = styled(Wrapper)`
  position: absolute;
  top: 3px;
  left: -65px;
`

const StyledHeadingWrapper = styled(Grid.Item)`
  ${({ $hasIcon }) =>
    $hasIcon &&
    `
    display: flex;
    justify-content: space-between;
    align-items: center;
  `}
`

type GridWithToggleProps = {
  children: React.ReactNode
  name: string
  onToggle: ({
    name,
    value,
  }: {
    name: string
    value: string | number | null
  }) => void
  scrollId: string
  title: string
  disabled?: boolean
  titleSize?: 'S' | 'M'
  headingIcon?: React.ReactNode
}

const GridWithToggle = ({
  children,
  disabled = false,
  name,
  onToggle,
  scrollId,
  title,
  titleSize = 'S',
  headingIcon,
}: GridWithToggleProps) => {
  const [isLoading, setIsLoading] = useState(false)
  const [field] = useField(name)
  const isOn = strToBool(field.value)
  const color = isOn ? '' : COLOR.GREY.MEDIUM

  useEffect(() => setIsLoading(false), [isOn])

  const handleToggle = () => {
    if (disabled) return false

    setIsLoading(true)
    onToggle({ name, value: boolToStr(!isOn) as string | null })
  }

  return (
    <StyledWrapper data-scroll-id={scrollId}>
      <StyledSwitchWrapper>
        {disabled ? (
          <Tooltip content="To turn off this section please delete the data within it.">
            <Switch
              isOn={isOn}
              handleToggle={handleToggle}
              disabled
              testId={name}
            />
          </Tooltip>
        ) : (
          <Switch
            isOn={isOn}
            handleToggle={handleToggle}
            disabled={isLoading}
            testId={name}
          />
        )}
      </StyledSwitchWrapper>
      <Grid>
        <StyledHeadingWrapper $hasIcon={!!headingIcon} margin={[0, 0, 'XS']}>
          <H size={titleSize} color={color} margin={0}>
            {scrollId} {title} {!isOn && '- none'}
          </H>
          {headingIcon && headingIcon}
        </StyledHeadingWrapper>
        {isOn && children}
      </Grid>
    </StyledWrapper>
  )
}

export default GridWithToggle
