import { useEffect } from 'react'
import styled from 'styled-components'
import { Button, Grid, H } from '@farewill/ui'
import { GTR } from '@farewill/ui/tokens'
import Dinero from 'dinero.js'
import { useFormikContext } from 'formik'

import CircularRadioGroup from 'components/form/circular-radio-group'
import RadioGroup from 'components/form/radio-group'
import Input from 'components/form/input'
import FakeInput from 'components/fake-input'
import IconList from 'components/icon-list'
import ExternalLink from 'components/external-link'
import MessageBox from 'components/message-box'
import PaymentFormFields from 'components/payment-form-fields'
import StatusPanel from 'components/status-panel'
import ENV from 'config/environment'
import {
  FuneralLeadAttributes,
  FuneralPlanLeadAttributes,
  FuneralQuoteAttributes,
  LeadAttributes,
  LpaLeadAttributes,
  WillLeadAttributes,
} from 'lib/models/lead'
import { FAREWILL_PRODUCTS } from 'utils/enums'

import PaymentIdentifierForm from './payment-identifier-form'
import { PAYMENT_METHODS } from './helpers'

Dinero.globalLocale = 'en-GB'
Dinero.defaultCurrency = 'GBP'

type TakePaymentProps = {
  calculatedPrice: number
  skip?: boolean
  editablePaymentReference?: boolean
  allowInvoicePayment?: boolean
}

const TakePayment = ({
  calculatedPrice,
  skip,
  editablePaymentReference = false,
  allowInvoicePayment,
}: TakePaymentProps) => {
  const { values, handleSubmit, setFieldValue } = useFormikContext()

  const {
    quoteAttributes: { paymentMethod = PAYMENT_METHODS.card },
    product,
  } = values as
    | FuneralLeadAttributes
    | WillLeadAttributes
    | LpaLeadAttributes
    | FuneralPlanLeadAttributes

  let isDwpCase = false,
    dwpNotConfirmed,
    isAttendedService = false

  if (product === FAREWILL_PRODUCTS.FUNERAL) {
    const {
      quoteAttributes: {
        attendedService,
        needsSupportFromDwp,
        dwpSupportConfirmed,
      },
    } = values as FuneralLeadAttributes
    isDwpCase = needsSupportFromDwp === 'true'
    dwpNotConfirmed = isDwpCase && dwpSupportConfirmed === 'false'
    isAttendedService = attendedService === 'true'
  }

  const allowsMultiplePaymentMethods = allowInvoicePayment === true
  const invoiceOptions = isAttendedService
    ? [{ label: 'DWP', value: 'dwp' }]
    : [
        { label: 'DWP', value: 'dwp' },
        { label: 'Someone else', value: 'other' },
      ]

  useEffect(() => {
    if (product === FAREWILL_PRODUCTS.FUNERAL_PLAN) {
      setFieldValue('quoteAttributes.paymentAmount', calculatedPrice)
    }

    if (isAttendedService && isDwpCase) {
      setFieldValue('quoteAttributes.paymentMethod', PAYMENT_METHODS.invoice)
      setFieldValue('quoteAttributes.invoice.paidBy', 'dwp')
    }
  }, [calculatedPrice, isAttendedService, isDwpCase, product, setFieldValue])

  return (
    <Grid>
      <Grid.Item span={10}>
        <Grid.Item>
          <H size="S">Take payment</H>
        </Grid.Item>
        {skip ? (
          <Grid.Item>↑ Complete sections above first</Grid.Item>
        ) : (
          <>
            <Grid margin={['M', 0]}>
              <Grid.Item span={6}>
                <FakeInput
                  label="Amount (£)"
                  className="quoteAttributes.paymentAmount"
                  value={Dinero({
                    amount: calculatedPrice || 0,
                  }).toFormat('$0,0')}
                />
              </Grid.Item>

              {allowsMultiplePaymentMethods && (
                <Grid.Item>
                  <PaymentMethodOptions
                    calculatedPrice={calculatedPrice}
                    handleSave={() => handleSubmit()}
                  />
                </Grid.Item>
              )}
            </Grid>
            {[PAYMENT_METHODS.invoice, PAYMENT_METHODS.invoiceAndCard].includes(
              paymentMethod
            ) && (
              <Grid>
                {paymentMethod === PAYMENT_METHODS.invoiceAndCard && (
                  <Grid.Item>
                    <H>Invoice</H>
                  </Grid.Item>
                )}
                <PaymentFormFields
                  namespace="quoteAttributes.invoice"
                  type="invoice"
                  handleSave={() => handleSubmit()}
                  invoiceOptions={invoiceOptions}
                />
                {!isDwpCase && InvoiceReminderMessage}
              </Grid>
            )}
            {[PAYMENT_METHODS.card, PAYMENT_METHODS.invoiceAndCard].includes(
              paymentMethod
            ) && (
              <Grid margin={['XL', 0]}>
                {paymentMethod === PAYMENT_METHODS.invoiceAndCard && (
                  <>
                    <Grid.Item>
                      <H>Card</H>
                    </Grid.Item>
                    <Grid.Item spanFromL={6}>
                      <Input
                        label="Amount"
                        isCurrency
                        name="quoteAttributes.paymentAmount"
                        handleSave={() => handleSubmit()}
                      />
                    </Grid.Item>
                  </>
                )}
                <SquarePayment
                  editablePaymentReference={editablePaymentReference}
                />
              </Grid>
            )}
          </>
        )}
      </Grid.Item>

      {isDwpCase && (
        <>
          <Grid.Item span={6} startRow={2}>
            <RadioGroup
              label="Do you have confirmation that DWP are paying for the funeral?"
              name="quoteAttributes.dwpSupportConfirmed"
              options={[
                { value: 'true', label: 'Yes' },
                { value: 'false', label: 'No' },
              ]}
              handleSave={() => handleSubmit()}
            />
          </Grid.Item>

          {dwpNotConfirmed && (
            <Grid.Item startColumn={8} span={5} startRow={2}>
              <StatusPanel highlight heading="DWP payment not confirmed">
                We can't take on a case until we have confirmation that DWP are
                paying for the funeral.
              </StatusPanel>
            </Grid.Item>
          )}
        </>
      )}
    </Grid>
  )
}

export default TakePayment

const StyledPaymentMethodGridItem = styled(Grid.Item)`
  width: max-content;
`

const directPaymentOptions = [
  {
    label: 'Card',
    value: PAYMENT_METHODS.card,
  },
  {
    label: 'Invoice',
    value: PAYMENT_METHODS.invoice,
  },
  {
    label: 'Invoice and card',
    value: PAYMENT_METHODS.invoiceAndCard,
  },
]

const attendedPaymentOptions = [
  {
    label: 'Invoice',
    value: PAYMENT_METHODS.invoice,
  },
  {
    label: 'Invoice and card',
    value: PAYMENT_METHODS.invoiceAndCard,
  },
]

const InvoiceReminderMessage = (
  <Grid.Item margin={['M', 0]}>
    <MessageBox>
      Once the invoice is paid you must confirm that payment has been received
      on the case.
    </MessageBox>
  </Grid.Item>
)

const PaymentMethodOptions = ({
  calculatedPrice,
  handleSave,
}: {
  calculatedPrice: number
  handleSave: (data: { name: string; value: string }) => void
}) => {
  const { values, setFieldValue } = useFormikContext<LeadAttributes>()

  return (
    <StyledPaymentMethodGridItem margin={['M', 0, 'L', 0]}>
      <CircularRadioGroup
        label="Method of payment"
        name="quoteAttributes.paymentMethod"
        inline
        options={
          (values.quoteAttributes as FuneralQuoteAttributes).attendedService ===
          'true'
            ? attendedPaymentOptions
            : directPaymentOptions
        }
        handleSave={(data) => {
          if (data.value === 'invoice') {
            if (
              (values.quoteAttributes as FuneralQuoteAttributes).invoice
                ?.amount == null
            ) {
              setFieldValue(
                'quoteAttributes.invoice.amount',
                calculatedPrice / 100
              )
            }
          } else if (data.value === 'card') {
            setFieldValue(
              'quoteAttributes.paymentAmount',
              calculatedPrice / 100
            )
          }
          handleSave(data)
        }}
      />
    </StyledPaymentMethodGridItem>
  )
}

const SquarePayment = ({
  editablePaymentReference,
}: {
  editablePaymentReference: boolean
}) => {
  return (
    <>
      <Grid.Item>
        <IconList
          items={[
            'Pause call recording before taking payment details',
            <>
              Once the payment has been made,{' '}
              <ExternalLink
                href={`${ENV.SQUARE_DASHBOARD_URL}/sales/transactions`}
              >
                find the transaction in Square
              </ExternalLink>
              , click in to it, and then copy the URL in to the field below.
            </>,
          ]}
        />

        <Button.Primary
          style={{ paddingTop: GTR.XXS, paddingBottom: GTR.XXS }}
          type="button"
          onClick={() => {
            window.open(ENV.SQUARE_DASHBOARD_URL, '_blank')
          }}
        >
          Take payment
        </Button.Primary>
      </Grid.Item>
      <Grid.Item>
        <PaymentIdentifierForm
          editablePaymentReference={editablePaymentReference}
        />
      </Grid.Item>
    </>
  )
}
