import { ReactElement, useState } from 'react'
import styled from 'styled-components'
import { Button, Divider, Grid, P } from '@farewill/ui'
import { GTR } from '@farewill/ui/tokens'

import { SelectInput } from 'components/form'
import StatusPanel from 'components/status-panel'
import useApi from 'lib/effects/api'
import { formatCurrency } from 'lib/formatting/currency'

import { LPA_CASE_QUOTE_TYPE } from '../constants'
import { LpaCaseQuoteAttributes, LpaCaseQuoteResult } from './types'

const QuoteRow = styled.div`
  display: flex;
  justify-content: space-between;
`

const LpaTypesOptions = [
  { value: LPA_CASE_QUOTE_TYPE.SINGLE, label: 'Single' },
  { value: LPA_CASE_QUOTE_TYPE.COUPLE, label: 'Couple' },
]

export const LpaCaseQuoteFormFields = ({
  isLoading,
  lpaCaseId,
}: {
  isLoading: boolean
  lpaCaseId: number
}): ReactElement => {
  const [hasMadeChanges, setHasMadeChanges] = useState(false)
  const [newQuoteTotal, setNewQuoteTotal] = useState<number | undefined>(
    undefined
  )

  const [, makeRequest] = useApi()

  const fetchNewQuote = async (attributes: LpaCaseQuoteAttributes) => {
    const newQuoteData: { data: { attributes: LpaCaseQuoteResult } } =
      await makeRequest({
        url: `/api/lpa-cases/${lpaCaseId}/quote`,
        method: 'POST',
        data: {
          data: {
            type: 'lpa_case_quote',
            attributes,
          },
        },
      })

    const newQuote = newQuoteData?.data?.attributes

    setNewQuoteTotal(newQuote?.totalPriceInPence)
    setHasMadeChanges(true)
  }

  return (
    <>
      <Grid.Item span={7}>
        <Grid.Item margin={[0, 0, GTR.L]}>
          <SelectInput
            options={LpaTypesOptions}
            label="Type of LPA"
            name="type"
            handleSave={async (e) => {
              await fetchNewQuote({
                type: e.value as LpaCaseQuoteAttributes['type'],
              })
            }}
          />
        </Grid.Item>
      </Grid.Item>
      <Grid.Item span={5}>
        <StatusPanel heading="Edit quote" sticky>
          {hasMadeChanges ? (
            <>
              <P strong>1 change</P>
              <Divider margin={[0, 0, 'M', 0]} />
              <QuoteRow>
                <P>Revised quote:</P>
                <P>
                  {formatCurrency({
                    value: newQuoteTotal,
                    valueInPence: true,
                  })}
                </P>
              </QuoteRow>
            </>
          ) : (
            <P>No changes made</P>
          )}
          <Button.Primary
            stretch
            disabled={!hasMadeChanges}
            loading={isLoading}
          >
            Save quote
          </Button.Primary>
        </StatusPanel>
      </Grid.Item>
    </>
  )
}

export default LpaCaseQuoteFormFields
