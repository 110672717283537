import { Grid, H } from '@farewill/ui'
import CircularRadioGroup from 'components/form/circular-radio-group'
import Tooltip from 'components/tooltip'
import { SaveProp } from './types'

const SIGN_DIGITALLY_TOOLTIP =
  'Signing the forms digitally is Farewill’s preferred option. For most customers, it’s faster and simpler than sending the forms by post. The customer will receive an email with the cremation forms and instructions on how to sign them digitally. It’s simple and takes just a couple of minutes. '

const LABEL = (
  <>
    Is the caller happy to{' '}
    <Tooltip content={SIGN_DIGITALLY_TOOLTIP}>
      sign the cremation forms electronically
    </Tooltip>
    ?
  </>
)

const SigningTheForms = ({ handleSave }: { handleSave: SaveProp }) => {
  return (
    <Grid>
      <Grid.Item>
        <H size="M">7. Signing the forms</H>
      </Grid.Item>
      <Grid.Item>
        <CircularRadioGroup
          name="requiresPostedForms"
          label={LABEL}
          options={[
            { label: 'Not asked', value: '' },
            {
              label: 'Yes - use electronic signature',
              value: 'false',
            },
            { label: 'No - forms to be sent by post', value: 'true' },
          ]}
          handleSave={handleSave}
          inline
        />
      </Grid.Item>
    </Grid>
  )
}

export default SigningTheForms
