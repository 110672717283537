import Calls from 'routes/calls'
import CaseList from 'routes/case-list'
import Contact from 'routes/contact'
import Funeral from 'routes/funerals'
import FuneralPlan from 'routes/funeral-plans'
import Crematoria from 'routes/crematoria'
import Lead from 'routes/lead'
import LeadList from 'routes/lead-list'
import LpaCase from 'routes/lpa-case'
import NewContact from 'routes/new-contact'
import PartnersList from 'routes/partners/partners-list'
import NewPartner from 'routes/partners/new-partner'
import Partner from 'routes/partners/partner-detail'
import ProbateCase from 'routes/probate-case'
import ProbateEstate from 'routes/probate-estate'
import WillPdf from 'routes/will-pdf'
import WillCase from 'routes/will-case'
import { VIEWS } from 'routes/case-list/views'
import SiteManagement from 'routes/site-management'

/**
 * If you want to only allow certain users to access a route,
 * provide the roles that are required for the user to access it.
 * Use REQUIRE_ROLES: [] to provide required roles.
 * If the user matches at least one of them, the access will be provided.
 */

const PAGES = {
  SALES: {
    CALLS: {
      PATH: '/calls',
      VIEW: Calls,
    },
    NEW_CONTACT_FORM: {
      PATH: '/contacts/new',
      VIEW: NewContact,
    },
    CONTACT: {
      PATH: '/contacts/:id',
      VIEW: Contact,
    },
    LEAD: {
      PATH: '/leads/:id',
      VIEW: Lead,
    },
    LEAD_LIST: {
      PATH: '/leads',
      VIEW: LeadList,
    },
    CREMATORIA_LIST: {
      PATH: '/crematoria',
      VIEW: Crematoria,
    },
  },
  CASES: {
    CASE_LIST: {
      PATH: `/customers/:product/:view(${Object.values(VIEWS).join('|')})`,
      VIEW: CaseList,
    },
    FUNERAL: {
      PATH: '/funerals/:id',
      VIEW: Funeral,
    },
    LPA_CASE: {
      PATH: '/lpa-cases/:id',
      VIEW: LpaCase,
    },
    PROBATE_CASE: {
      PATH: '/probate/cases/:id',
      VIEW: ProbateCase,
    },
    PROBATE_ESTATE: {
      PATH: '/probate/estates/:id',
      VIEW: ProbateEstate,
    },
    WILL_PDF: {
      PATH: '/will-pdfs/:key',
      VIEW: WillPdf,
    },
    WILL: {
      PATH: '/will-cases/:id',
      VIEW: WillCase,
    },
    FUNERAL_PLAN: {
      PATH: '/funeral-plans/:id',
      VIEW: FuneralPlan,
      REQUIRE_ROLES: ['funeral_plans_editor'],
    },
  },
  PARTNERS: {
    ADD_PARTNER: {
      PATH: '/partners/new',
      VIEW: NewPartner,
      REQUIRE_ROLES: ['partnerships_admin'],
    },
    PARTNER: {
      PATH: '/partners/:id',
      VIEW: Partner,
      REQUIRE_ROLES: ['partnerships_admin'],
    },
    LIST_PARTNERS: {
      PATH: '/partners',
      VIEW: PartnersList,
      REQUIRE_ROLES: ['partnerships_admin'],
    },
  },
  GENERAL: {
    SITE_MANAGEMENT: {
      PATH: '/site-management',
      VIEW: SiteManagement,
    },
  },
}

export default PAGES

/**
 * PATHS is an object with each page PATH:
 * {
 *   CALLS: '/calls',
 *   LEAD_LIST: '/leads',
 *   ...
 * }
 */

export const PATHS = Object.values(PAGES).reduce(
  (acc, obj) => ({
    ...acc,
    ...Object.entries(obj).reduce(
      (acc, [key, value]) => ({ ...acc, [key]: value.PATH }),
      {}
    ),
  }),
  {}
)
