import Properties from './properties'
import { ProbateLeadAttributes } from 'lib/models/lead'
import { useFormikContext } from 'formik'
import { useProbateEstate } from '../helpers/probate-estate-context'

const PropertiesWrapper = ({ isComplete }: { isComplete: boolean }) => {
  const { values } = useFormikContext<ProbateLeadAttributes>()
  const { probateEstate } = useProbateEstate()
  const initialValues = probateEstate.attributes

  return (
    <Properties
      initialValues={initialValues}
      numberOfProperties={values.quoteAttributes.numberOfProperties || 0}
      isComplete={isComplete}
    />
  )
}

export default PropertiesWrapper
