import React from 'react'
import { useDispatch } from 'react-redux'
import { Button, ButtonGroup, P, Wrapper } from '@farewill/ui'

import { RESOURCES, UseDeleteArrayResourceResult } from 'api'
import { hideModal } from 'state/actions'

type Props = {
  config: {
    willUpdateId: number
    willUpdatesDeleteMutation: UseDeleteArrayResourceResult
  }
}

const DeleteWillUpdateModal = ({
  config: { willUpdateId, willUpdatesDeleteMutation },
}: Props): React.ReactElement => {
  const dispatch = useDispatch()

  const deleteWillUpdate = async () => {
    await willUpdatesDeleteMutation.mutateAsync({
      id: Number(willUpdateId),
      resource: RESOURCES.WILL_UPDATES,
    })
    dispatch(hideModal())
  }

  return (
    <Wrapper>
      <P>You should only delete this will update if it was created in error.</P>
      <P>Are you sure you want to delete this will update?</P>
      <ButtonGroup horizontal>
        <Button.Primary onClick={() => deleteWillUpdate()}>
          Delete will update
        </Button.Primary>
        <Button.Bordered onClick={() => dispatch(hideModal())}>
          Cancel
        </Button.Bordered>
      </ButtonGroup>
    </Wrapper>
  )
}

export default DeleteWillUpdateModal
