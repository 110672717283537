import PropTypes from 'prop-types'
import { Grid, P } from '@farewill/ui'
import { useFormikContext } from 'formik'

import { NOT_ASKED_AND_UNSURE_OPTIONS } from 'utils/enums'

import InputFloating from 'components/form/input-floating'
import CircularRadioGroup from 'components/form/circular-radio-group'
import GridWithToggle from 'components/grid-with-toggle'

import SummaryBox from '../components/summary-box'
import { getFuneralTotal } from '../get-totals'
import { getDateOfDeath, formatDate } from '../../helpers'

import {
  FUNERAL_BILL_AMOUNT,
  FUNERAL_WAKE_AMOUNT,
  FUNERAL_FLOWERS_AMOUNT,
  FUNERAL_OTHER_AMOUNT,
  FUNERAL_AMOUNT_NOTES,
  FUNERAL_HAD_PRE_PAID_PLAN,
  FUNERAL_AMOUNT_CONVERED_BY_PLAN,
  FUNERAL_SECTION_ACTIVE,
} from './field-names'

const Funeral = ({ saveProbateEstateField }) => {
  const { values } = useFormikContext()
  const summaryItems = [
    {
      label: 'Date of death',
      value: formatDate(getDateOfDeath(values)),
      section: '1.3',
    },
  ]

  const toggleDisabled = [
    FUNERAL_BILL_AMOUNT,
    FUNERAL_WAKE_AMOUNT,
    FUNERAL_FLOWERS_AMOUNT,
    FUNERAL_OTHER_AMOUNT,
    FUNERAL_AMOUNT_NOTES,
    FUNERAL_HAD_PRE_PAID_PLAN,
    FUNERAL_AMOUNT_CONVERED_BY_PLAN,
  ].some((field) => !!values[field])

  const total = getFuneralTotal(values)

  return (
    <GridWithToggle
      disabled={toggleDisabled}
      name={FUNERAL_SECTION_ACTIVE}
      onToggle={saveProbateEstateField}
      scrollId="5.1"
      title="Funeral"
    >
      <Grid.Item>
        <P size="S">Total: {total}</P>
      </Grid.Item>
      <Grid.Item>
        <SummaryBox items={summaryItems} />
      </Grid.Item>
      <Grid.Item span={3}>
        <InputFloating
          name={FUNERAL_BILL_AMOUNT}
          label="Funeral bill (£)"
          handleSave={saveProbateEstateField}
          isCurrency
        />
      </Grid.Item>
      <Grid.Item span={3}>
        <InputFloating
          name={FUNERAL_WAKE_AMOUNT}
          label="Wake (£)"
          handleSave={saveProbateEstateField}
          isCurrency
        />
      </Grid.Item>
      <Grid.Item span={3}>
        <InputFloating
          name={FUNERAL_FLOWERS_AMOUNT}
          label="Flowers (£)"
          handleSave={saveProbateEstateField}
          isCurrency
        />
      </Grid.Item>
      <Grid.Item span={3}>
        <InputFloating
          name={FUNERAL_OTHER_AMOUNT}
          label="Other costs (£)"
          handleSave={saveProbateEstateField}
          isCurrency
        />
      </Grid.Item>
      <Grid.Item>
        <InputFloating
          name={FUNERAL_AMOUNT_NOTES}
          label="Description of costs"
          handleSave={saveProbateEstateField}
        />
      </Grid.Item>
      <Grid.Item>
        <CircularRadioGroup
          name={FUNERAL_HAD_PRE_PAID_PLAN}
          label="Was there a pre-paid funeral plan?"
          options={NOT_ASKED_AND_UNSURE_OPTIONS}
          handleSave={saveProbateEstateField}
          inline
        />
      </Grid.Item>
      {values[FUNERAL_HAD_PRE_PAID_PLAN] === 'yes' && (
        <Grid.Item span={5}>
          <InputFloating
            name={FUNERAL_AMOUNT_CONVERED_BY_PLAN}
            label="Amount covered by plan (£)"
            handleSave={saveProbateEstateField}
            isCurrency
          />
        </Grid.Item>
      )}
    </GridWithToggle>
  )
}

Funeral.propTypes = {
  saveProbateEstateField: PropTypes.func.isRequired,
}

export default Funeral
