import { useSelector, useDispatch } from 'react-redux'
import { Formik, Form } from 'formik'
import { H, P, Grid, Divider } from '@farewill/ui'

import { formatValuesForFormik } from 'utils/helpers'
import { updateLpaCase } from 'state/actions'
import { useFetchResource, RESOURCES } from 'api'
import { Lead, LpaLeadAttributes } from 'lib/models/lead'
import { LpaCase, LpaCaseAttributes } from 'lib/models/lpa-case'

import Input from 'components/form/input'
import RequestStatus from 'components/request-status'
import CriticalNotes from 'components/critical-notes'

import LostOrDeclined from './lost-or-declined'
import CaseOwner from './case-owner'
import SimpleList from 'components/styled/simple-list'

const CaseOverview = ({ id }: { id: number }) => {
  const dispatch = useDispatch()
  const lpaCaseQuery = useFetchResource<LpaCase>({
    id,
    resource: RESOURCES.LPA_CASES,
  })
  const lpaLead = useSelector((state: { lead: Lead }) => state.lead)

  if (lpaCaseQuery.isLoading || lpaCaseQuery.isIdle)
    return <P size="L">Loading...</P>

  if (!lpaCaseQuery.data) return <P size="L">There is no data to display.</P>
  const lpaCase = lpaCaseQuery.data
  const lpaLeadAttributes = lpaLead.attributes as LpaLeadAttributes

  const initialValues = formatValuesForFormik(lpaCase.attributes)

  const handleSubmit = async (values: LpaCaseAttributes) => {
    const patchData = {
      ownedByAdminUserId: values.ownedByAdminUserId || null,
      aircallRecording: values.aircallRecording || null,
      criticalNotes: values.criticalNotes || null,
    }
    return dispatch(updateLpaCase(lpaCase.id, patchData))
  }

  const wantsLargePrint = lpaLeadAttributes.quoteAttributes.wantsLargePrint

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit}>
      {({ handleSubmit }) => (
        <Form>
          <RequestStatus />
          <Grid>
            <Grid.Item>
              <H size="M">Case details</H>
            </Grid.Item>
            <Grid.Item>
              <SimpleList bullets>
                {wantsLargePrint && <li>Wants an LPA in larger print </li>}
              </SimpleList>
            </Grid.Item>
            <Grid.Item margin={['M', 0]}>
              <CriticalNotes label="For example, customer has a will with Farewill, wants a couples LPA, partners details etc." />
            </Grid.Item>
            <Grid.Item>
              <Divider />
            </Grid.Item>
            <Grid.Item margin={['S', 0, 'M']} span={7}>
              <CaseOwner />
            </Grid.Item>
            <Grid.Item>
              <Divider />
            </Grid.Item>
            <Grid.Item>
              <H margin={['S', 0, 'L']} size="S">
                Aircall appointment recording{' '}
              </H>
              <Input
                handleSave={() => handleSubmit()}
                name="aircallRecording"
                label="Link to Aircall case"
              />
            </Grid.Item>
            <Grid.Item>
              <Divider />
            </Grid.Item>
            <Grid.Item margin={['M', 0]}>
              <LostOrDeclined />
            </Grid.Item>
          </Grid>
        </Form>
      )}
    </Formik>
  )
}

export default CaseOverview
