import { Component } from 'react'
import { connect, Form } from 'formik'
import PropTypes from 'prop-types'
import { ArrowRightIcon, Button, Grid, H } from '@farewill/ui'

import CheckboxGroup from 'components/form/checkbox-group'
import DatetimePicker from 'components/form/datetime-picker'
import Input from 'components/form/input'
import SelectInput from 'components/form/select-input'
import PartnerSelect from 'components/partner-select'
import ENV from 'config/environment'
import {
  FAREWILL_PRODUCTS_OPTIONS,
  LEAD_SOURCE_TYPE_OPTIONS,
  LEAD_SOURCE_IDENTIFIER_OPTIONS,
  LEAD_PARTNER_TYPE_OPTIONS,
} from 'utils/enums'
import { showPartnerSelect } from 'utils/helpers'

const AVAILABLE_PRODUCTS = ENV.FF_LPAS_ENABLED
  ? FAREWILL_PRODUCTS_OPTIONS
  : FAREWILL_PRODUCTS_OPTIONS.filter((product) => product.label !== 'LPA')
class NewContactForm extends Component {
  componentDidUpdate = (prevProps) => {
    const newPartnershipType = this.props.formik.values.partnerType
    const oldPartnershipType = prevProps.formik.values.partnerType

    const isPartnershipTypeChanging = newPartnershipType !== oldPartnershipType

    if (isPartnershipTypeChanging) {
      this.props.formik.setValues({
        ...this.props.formik.values,
        notes: '',
        referredBy: '',
        scheduledNextCallAt: '',
        sourceIdentifier: '',
        partnerId: '',
      })
    }
  }

  render() {
    const { call, formik } = this.props
    const { isSubmitting, values } = formik

    const callSelected = call.id
    const isPartnership = values.partnerType === 'other_partner'
    const sourceIdentifierOptions =
      LEAD_SOURCE_IDENTIFIER_OPTIONS[values.sourceType]

    return (
      <Form>
        <Grid>
          <Grid.Item>
            <H size="XS" margin={0}>
              Choose product
            </H>
            <CheckboxGroup
              name="interestedInProducts"
              options={AVAILABLE_PRODUCTS}
            />
          </Grid.Item>
          {!callSelected && (
            <Grid.Item>
              <Grid>
                <Grid.Item span={6}>
                  <SelectInput
                    label="Type"
                    name="sourceType"
                    options={LEAD_SOURCE_TYPE_OPTIONS}
                  />
                </Grid.Item>
                {sourceIdentifierOptions && (
                  <Grid.Item span={6}>
                    <SelectInput
                      label="Source"
                      name="sourceIdentifier"
                      options={sourceIdentifierOptions}
                    />
                  </Grid.Item>
                )}
              </Grid>
              <Grid margin={['L', 0]}>
                <Grid.Item span={6}>
                  <SelectInput
                    label="Partner type"
                    name="partnerType"
                    options={LEAD_PARTNER_TYPE_OPTIONS}
                  />
                </Grid.Item>
                {showPartnerSelect(values.partnerType) && (
                  <Grid.Item span={6}>
                    <PartnerSelect
                      label="Partner Name"
                      name="partnerId"
                      partnerType={values.partnerType}
                    />
                  </Grid.Item>
                )}
              </Grid>
              <Grid>
                {isPartnership && (
                  <>
                    <Grid.Item span={6}>
                      <Input
                        label="Referred by"
                        name="referredBy"
                        hint="The name of the person at the partner company who owns this lead"
                      />
                    </Grid.Item>
                    <Grid.Item span={6}>
                      <DatetimePicker
                        label="Scheduled callback time"
                        name="scheduledNextCallAt"
                        hint="Leave this blank if a call hasn't been scheduled with the customer"
                      />
                    </Grid.Item>
                    <Grid.Item>
                      <Input
                        label="Notes"
                        name="notes"
                        component="textarea"
                        rows={4}
                      />
                    </Grid.Item>
                  </>
                )}
              </Grid>
            </Grid.Item>
          )}
          <Grid.Item>
            <Button.Primary
              icon={<ArrowRightIcon inline />}
              type="submit"
              disabled={isSubmitting}
            >
              Create lead
            </Button.Primary>
          </Grid.Item>
        </Grid>
      </Form>
    )
  }
}

NewContactForm.propTypes = {
  call: PropTypes.object.isRequired,
}

export default connect(NewContactForm)
