import useApiHelpers from 'lib/effects/api-helpers'
import { LeadEvent } from 'lib/models/lead-event'

type LeadEventHookResult = {
  leadEvents: LeadEvent[]
  fetchLeadEvents: () => Promise<void>
}

const useLeadEvents = (leadId: number): LeadEventHookResult => {
  const { items: leadEvents, fetchItems: fetchLeadEvents } = useApiHelpers({
    baseUrl: `/api/lead-events?leadId=${leadId}`,
    type: 'leadEvents',
  })

  return {
    leadEvents,
    fetchLeadEvents,
  }
}

export default useLeadEvents
