import { ReactElement } from 'react'
import styled from 'styled-components'
import { P } from '@farewill/ui'

import GridRow from 'components/list/grid-row'
import { formatCurrency } from 'lib/formatting/currency'
import { WillCaseAttributes } from 'lib/models/will-case'
import { WillCaseQuoteResult } from 'routes/will-case/quote/editable-quote/types'
import { formatToHuman } from 'utils/helpers'

import {
  getWillCaseQuoteComplexities,
  sortLeadAndPartnerWills,
} from './methods'

const gridTemplateColumns = 'repeat(2, 50%)'

const StyledGridRow = styled(GridRow)`
  padding-left: 0;
  padding-right: 0;
`

const StyledTotalPriceContainer = styled.div`
  p {
    margin-top: 8px;
    text-align: center;
  }
`

const StyledUnselectableGridRow = styled(StyledGridRow)`
  cursor: default;
`

const getComplexitiesLabel = (willCase: WillCaseAttributes): string => {
  const willCaseQuoteComplexities = getWillCaseQuoteComplexities({
    willCase,
  })

  let label = ''

  const { leadWill, partnerWill } = sortLeadAndPartnerWills(willCase)

  if (willCaseQuoteComplexities === 'lead') {
    label = `(${
      leadWill?.contact?.firstName || willCaseQuoteComplexities
    }’s will)`
  }

  if (willCaseQuoteComplexities === 'partner') {
    label = `(${
      partnerWill?.contact?.firstName || willCaseQuoteComplexities
    }’s will)`
  }

  if (willCaseQuoteComplexities === 'both') {
    label = '(both wills)'
  }

  return `Complexities ${label}`.trim()
}

const QuoteRow = ({
  label,
  priceInPence,
}: {
  label: string
  priceInPence: number
}): ReactElement => {
  return (
    <StyledUnselectableGridRow gridTemplateColumns={gridTemplateColumns}>
      <li>{label}</li>
      <li>
        {formatCurrency({
          value: priceInPence,
          valueInPence: true,
        })}
      </li>
    </StyledUnselectableGridRow>
  )
}

const CurrentQuote = ({
  currentQuote,
  willCase,
}: {
  currentQuote: WillCaseQuoteResult
  willCase: WillCaseAttributes
}): ReactElement => {
  const { willType } = willCase

  const complexitiesLabel = getComplexitiesLabel(willCase)

  return (
    <>
      <StyledGridRow header gridTemplateColumns={gridTemplateColumns}>
        <li>Description</li>
        <li>Amount</li>
      </StyledGridRow>

      {currentQuote && (
        <>
          <QuoteRow
            label={`${formatToHuman(willType)} case`}
            priceInPence={currentQuote.calculatedBasePriceInPence}
          />
          <QuoteRow
            label="Discount"
            priceInPence={currentQuote.discountValueInPence}
          />
          <QuoteRow
            label={`${complexitiesLabel}`}
            priceInPence={currentQuote.complexityPriceInPence}
          />
        </>
      )}

      <StyledTotalPriceContainer>
        <P strong margin={['S', 0, 0]}>
          Total quoted:{' '}
          {currentQuote &&
            formatCurrency({
              value: currentQuote.totalPriceInPence || 0,
              valueInPence: true,
            })}
        </P>
      </StyledTotalPriceContainer>
    </>
  )
}

export default CurrentQuote
