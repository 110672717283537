import type { StringValueFormOption } from 'utils/helpers'
import MemberBadge from 'components/member-badge'
import { VoucherType } from 'lib/models/voucher'
import type { LeadVoucher } from 'lib/models/lead'

export const formatVoucherCodeOptions = ({
  voucherCodes,
}: {
  voucherCodes: LeadVoucher[]
}): StringValueFormOption[] => {
  return (voucherCodes || []).map((voucher) => {
    const suffix = voucher.mechanism === 'membership' ? <MemberBadge /> : ''
    const discount =
      voucher.discountType === VoucherType.valueInPence
        ? `-£${voucher.discountValue / 100}`
        : `-${voucher.discountValue}%`

    return {
      label: `${voucher.code} (${discount})`,
      suffix,
      value: voucher.code,
    }
  })
}
