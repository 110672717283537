import { useFormikContext } from 'formik'
import { H, Wrapper, P } from '@farewill/ui'

import SimpleList from 'components/styled/simple-list'

import Preview from './image-upload/preview'
import DropZone from './image-upload/drop-zone'

const Logo = (): React.ReactElement => {
  const { values, setFieldValue } = useFormikContext<{
    logo: Blob
    logoUrl: string
  }>()

  return (
    <Wrapper>
      <Wrapper margin={[0, 0, 'M']}>
        <H size="S">Logo</H>
        <P>
          Add a high resolution logo exactly 400px wide that fits image
          requirements. Acceptable image formats:
        </P>
        <SimpleList bullets>
          <li>SVG</li>
          <li>PNG with transparent background</li>
          <li>
            PNG/JPEG with a non-transparent background (any colour), cropped to
            fit on a pale grey background
          </li>
        </SimpleList>
      </Wrapper>

      {values.logo || values.logoUrl ? (
        <Preview
          blob={values.logo}
          existingUploadUrl={values.logoUrl}
          name="logo"
          onDelete={(isUnsavedImage: boolean) => {
            if (isUnsavedImage) {
              setFieldValue('logo', null)
            } else {
              setFieldValue('logoUrl', null)
            }
          }}
        />
      ) : (
        <DropZone formikField="logo" name="logo" imageWidth={400} />
      )}
    </Wrapper>
  )
}

export default Logo
