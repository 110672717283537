import { ProbateEstateItem } from 'lib/models/probate-estate'
import { RequiredArray } from './types'
import { UseSectionsCompleteProps } from '.'

const checkIsStandardAssetSectionComplete = (
  items: ProbateEstateItem[] = []
) => {
  const propertiesFiltered = items.filter((item) => {
    const {
      value,
      ownership,
      deceasedShareOfJointOwnership,
      itemType,
      lumpSumPayableTo,
      isLumpSumPensionPayout,
    } = item.attributes
    const requiredFieldsForJointOwnership: RequiredArray =
      ownership === 'joint' ? [deceasedShareOfJointOwnership] : []

    const requiredFieldsForLifeInsurance: RequiredArray =
      itemType === 'life_insurance' ? [lumpSumPayableTo] : []

    const requiredFieldsForPrivatePension: RequiredArray =
      itemType === 'private_pension'
        ? [isLumpSumPensionPayout, lumpSumPayableTo]
        : []

    const requiredFields: RequiredArray = [value, ownership]

    const requiredFieldsCombined = requiredFields.concat(
      requiredFieldsForJointOwnership,
      requiredFieldsForLifeInsurance,
      requiredFieldsForPrivatePension
    )

    // PrivatePension  don't have ownership field
    if (itemType === 'private_pension') {
      requiredFieldsCombined.splice(
        requiredFieldsCombined.indexOf(ownership),
        1
      )
    }

    return !(
      requiredFieldsCombined.find((field) => field === '' || field === null) ===
      undefined
    )
  })

  return propertiesFiltered.length === 0
}

export const checkIsBankAccountsComplete = ({
  groupedItems,
}: Partial<UseSectionsCompleteProps>) => {
  return checkIsStandardAssetSectionComplete(groupedItems?.bankAccount)
}

export const checkIsNSIComplete = ({
  groupedItems,
}: Partial<UseSectionsCompleteProps>) => {
  return checkIsStandardAssetSectionComplete(
    groupedItems?.nationalSavingsAndInvestments
  )
}

export const checkIsPublicShareholdingComplete = ({
  groupedItems,
}: Partial<UseSectionsCompleteProps>) => {
  return checkIsStandardAssetSectionComplete(groupedItems?.publicShareholding)
}

export const checkIsLifeInsuranceComplete = ({
  groupedItems,
}: Partial<UseSectionsCompleteProps>) => {
  return checkIsStandardAssetSectionComplete(groupedItems?.lifeInsurance)
}

export const checkIsPrivatePensionComplete = ({
  groupedItems,
}: Partial<UseSectionsCompleteProps>) => {
  return checkIsStandardAssetSectionComplete(groupedItems?.privatePension)
}

export const checkIsBusinessOrAgriculturalAssetComplete = ({
  groupedItems,
}: Partial<UseSectionsCompleteProps>) => {
  return checkIsStandardAssetSectionComplete(
    groupedItems?.businessOrAgriculturalAsset
  )
}

export const checkIsOverseasAssetsComplete = ({
  groupedItems,
}: Partial<UseSectionsCompleteProps>) => {
  return checkIsStandardAssetSectionComplete(groupedItems?.overseasAsset)
}

export const checkIsVehiclesOrPossesionsComplete = ({
  groupedItems,
}: Partial<UseSectionsCompleteProps>) => {
  return checkIsStandardAssetSectionComplete(groupedItems?.vehicleOrPossession)
}

export const checkIsAssetsComplete = ({
  groupedItems,
}: UseSectionsCompleteProps) => {
  const isBankAccountsComplete = checkIsBankAccountsComplete({ groupedItems })
  if (!isBankAccountsComplete) return false

  const isNSIComplete = checkIsNSIComplete({ groupedItems })
  if (!isNSIComplete) return false

  const isPublicShareholdingComplete = checkIsPublicShareholdingComplete({
    groupedItems,
  })
  if (!isPublicShareholdingComplete) return false

  const isLifeInsuranceComplete = checkIsLifeInsuranceComplete({ groupedItems })
  if (!isLifeInsuranceComplete) return false

  const isPrivatePensionComplete = checkIsPrivatePensionComplete({
    groupedItems,
  })
  if (!isPrivatePensionComplete) return false

  const isBusinessOrAgriculturalAsset =
    checkIsBusinessOrAgriculturalAssetComplete({ groupedItems })
  if (!isBusinessOrAgriculturalAsset) return false

  const isOverseasAssetsComplete = checkIsOverseasAssetsComplete({
    groupedItems,
  })
  if (!isOverseasAssetsComplete) return false

  const isOtherComplete = checkIsVehiclesOrPossesionsComplete({ groupedItems })
  if (!isOtherComplete) return false

  return true
}
