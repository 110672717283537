import { P } from '@farewill/ui'

import ExternalLink from 'components/external-link'
import MessageBox from 'components/message-box'

import { isNotAuthorizedToBeIncludedInComms } from './helpers'

const EstatePersonModal = ({ config: { person } }) => {
  const frontBaseUrl =
    'https://app.frontapp.com/inboxes/teams/4610822/inbox/unassigned/0/search/global'
  const searchUrl = `${frontBaseUrl}/${encodeURI(person.email)}`

  return (
    <>
      {person.email ? (
        <ExternalLink href={searchUrl}>Email: {person.email}</ExternalLink>
      ) : (
        <P>Email: No email provided</P>
      )}
      <P>Phone number: {person.phoneNumber || 'No phone number provided'}</P>
      {isNotAuthorizedToBeIncludedInComms(person) && (
        <MessageBox warning>
          This person is <strong>not</strong> marked as authorized to be
          included in comms in fact find.
        </MessageBox>
      )}
    </>
  )
}

export default EstatePersonModal
