import { ProbateLeadAttributes } from 'lib/models/lead'
import { WIDOWED } from 'utils/enums'
import { ensureNumberOrNull } from 'utils/helpers'

const NIL_RATE_BAND = 325_000

export const showWidowedQuestions = (values: ProbateLeadAttributes) => {
  const { maritalStatus, estateValue } = values.quoteAttributes
  const estateValueAsNum = ensureNumberOrNull(estateValue) as number
  return (
    maritalStatus === WIDOWED &&
    estateValueAsNum > NIL_RATE_BAND &&
    estateValueAsNum < NIL_RATE_BAND * 2
  )
}
