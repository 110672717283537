import PropTypes from 'prop-types'
import styled from 'styled-components'
import { COLOR } from '@farewill/ui/tokens'

const StyledInput = styled.span`
  content: '';
  position: absolute;
  top: 3px;
  left: 3px;
  width: 18px;
  height: 18px;
  border-radius: 18px;
  background: rgba(255, 255, 255, 0.9);
`

const StyledLabel = styled.label`
  position: relative;
  cursor: pointer;
  width: 42px;
  height: 24px;
  background-color: ${COLOR.GREY.LIGHT};
  border-radius: 24px;
  opacity: 1;

  ${({ disabled }) =>
    disabled &&
    `opacity: 0.5;
    cursor: default;
  `}
`

const StyledCheckbox = styled.input`
  display: none;

  &:checked + ${StyledInput} {
    left: calc(100% - 3px);
    transform: translateX(-100%);
  }
`

const Switch = ({ color, disabled, handleToggle, isOn, testId }) => (
  <StyledLabel style={{ backgroundColor: isOn && color }} disabled={disabled}>
    <StyledCheckbox
      type="checkbox"
      onChange={handleToggle}
      checked={isOn}
      data-testid={testId}
    />
    <StyledInput />
  </StyledLabel>
)

Switch.propTypes = {
  color: PropTypes.string,
  handleToggle: PropTypes.func.isRequired,
  isOn: PropTypes.bool,
  disabled: PropTypes.bool,
  testId: PropTypes.string,
}

Switch.defaultProps = {
  color: COLOR.STATE.SUCCESS,
  isOn: false,
  disabled: false,
  testId: '',
}

export default Switch
