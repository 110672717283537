import styled from 'styled-components'
import { Button, P, Wrapper } from '@farewill/ui'
import { FONT, GTR } from '@farewill/ui/tokens'
import { useDispatch } from 'react-redux'

import { hideModal } from 'state/actions'

const StyledWrapper = styled(Wrapper)`
  display: flex;
  flex-direction: column;
`

const StyledCancelButton = styled(Button)`
  font-weight: ${FONT.WEIGHT.REGULAR};
  text-decoration: underline;
  align-self: center;
  margin-top: ${GTR.XS};
  &:hover {
    text-decoration: underline;
  }
`

type ModalProps = {
  config: {
    resetNominatedRepresentative: () => Promise<void>
  }
}

const ResetNominatedRepresentativeModal = ({ config }: ModalProps) => {
  const dispatch = useDispatch()

  const handleResetNominatedRepresentative = async () => {
    await config.resetNominatedRepresentative()
    dispatch(hideModal())
  }

  return (
    <Wrapper>
      <P>
        You are about to remove all the data for Nominated Representative. Are
        you sure you want to continue?
      </P>

      <StyledWrapper>
        <Button.Primary
          type="button"
          onClick={handleResetNominatedRepresentative}
        >
          Continue
        </Button.Primary>
        <StyledCancelButton onClick={() => dispatch(hideModal())}>
          Cancel
        </StyledCancelButton>
      </StyledWrapper>
    </Wrapper>
  )
}

export default ResetNominatedRepresentativeModal
