import { ReactElement } from 'react'
import { Button, ButtonGroup, Wrapper } from '@farewill/ui'
import { useDispatch } from 'react-redux'
import camelCase from 'lodash/camelCase'
import { Formik, Form } from 'formik'

import { hideModal, createEvent, updateEvent, deleteEvent } from 'state/actions'
import { INLINE_FUNERAL_EVENTS } from 'utils/enums'
import { findEvent } from 'utils/helpers'

import Checkbox from 'components/form/checkbox'
import Input from 'components/form/input'
import { Event } from 'lib/models/event'
import { LOST_OR_DECLINED_SCHEMA } from 'lib/formik/schemata'

const getInitialValues = (events: Event[]): EventValues => {
  return INLINE_FUNERAL_EVENTS.reduce((formatted, event) => {
    const existingEvent = findEvent(events, event.name)
    const eventName = camelCase(event.name)

    if (existingEvent) {
      const value = {
        id: existingEvent.id,
        complete: true,
        metadata: existingEvent?.attributes?.metadata || {},
      }
      return { ...formatted, [eventName]: value }
    } else {
      const metadata = event.metadata.reduce(
        (acc, { name }) => ({ ...acc, [name]: '' }),
        {}
      )
      const value = { complete: false, date: new Date(), metadata }
      return { ...formatted, [eventName]: value }
    }
  }, {})
}

type ModalProps = {
  config: {
    funeralId: number
    events: Event[]
  }
}

type EventValue = {
  id: number
  complete: boolean
  metadata: {
    caseLostReason: string
  }
  date: string
}

type EventValues = {
  caseLost?: EventValue
}

const Modal = ({ config: { events, funeralId } }: ModalProps): ReactElement => {
  const dispatch = useDispatch()

  const handleSubmit = async (values: EventValues) => {
    await Promise.all(
      INLINE_FUNERAL_EVENTS.map(async ({ name }) => {
        const eventName: keyof EventValues = camelCase(
          name
        ) as keyof EventValues
        const { id, date, complete, metadata } = values[eventName] as EventValue
        const attributes = { date, metadata, name, funeralId }

        if (complete && !id) {
          await dispatch(createEvent(attributes))
        } else if (complete && !!id) {
          await dispatch(updateEvent(id, attributes))
        } else if (!complete && !!id) {
          await dispatch(deleteEvent(id))
        }
      })
    )
    return dispatch(hideModal())
  }

  return (
    <Formik
      initialValues={getInitialValues(events)}
      validationSchema={LOST_OR_DECLINED_SCHEMA}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting, values }) => (
        <Form>
          {INLINE_FUNERAL_EVENTS.map((event, index) => {
            const eventName: keyof EventValues = camelCase(
              event.name
            ) as keyof EventValues
            const checked = values[eventName]?.complete
            return (
              <Wrapper key={index} margin={[0, 0, 'M']}>
                <Checkbox
                  name={`${eventName}.complete`}
                  label={event.label}
                  // eslint-disable-next-line react/jsx-boolean-value
                  value={true}
                  checked={checked}
                />
                {checked &&
                  event.metadata.map(({ name, label, options }, index) => (
                    <Wrapper key={index} margin={['S', 0]}>
                      <Input
                        label={label}
                        name={`${eventName}.metadata.${name}`}
                        component="select"
                        options={options}
                      />
                    </Wrapper>
                  ))}
              </Wrapper>
            )
          })}
          <ButtonGroup gap="S">
            <Button.Primary type="submit" loading={isSubmitting}>
              Save
            </Button.Primary>
            <Button.Underline onClick={() => dispatch(hideModal())}>
              Cancel
            </Button.Underline>
          </ButtonGroup>
        </Form>
      )}
    </Formik>
  )
}

export default Modal
