import { useQueryClient } from 'react-query'
import styled from 'styled-components'
import { P } from '@farewill/ui'
import { FONT, GTR } from '@farewill/ui/tokens'

import PRICING from 'config/pricing'

import SectionStatus from 'components/section-status'

import Dinero from 'dinero.js'
import { Constants } from 'lib/models/constants'
import { QuoteResponseDataProbate } from 'lib/types'
Dinero.globalLocale = 'en-GB'
Dinero.defaultCurrency = 'GBP'

const StyledPrice = styled(P)`
  font-size: ${FONT.SIZE.XL};
  margin-bottom: 0;
  font-weight: ${FONT.WEIGHT.MEDIUM};
`

const StyledList = styled.ul`
  font-size: ${GTR.S};

  li {
    padding: ${GTR.XXS} 0;

    &::before {
      content: '•';
      margin-right: 0.5rem;
    }
  }
`

const ProbateOnlyQuote = ({
  quote,
}: {
  quote: QuoteResponseDataProbate | null
}) => {
  const cache = useQueryClient()
  const constants = cache.getQueryData('constants') as Constants
  const probateCourtFeePrice = constants?.attributes?.probateCourtFeePrice || 0

  const complexity = quote?.attributes.grantOnly?.isComplex
    ? 'complex'
    : 'simple'
  const probateOnlyQuote = quote?.attributes.grantOnly?.amountInPence || 0
  const discountAmountProbateOnlyQuote =
    quote?.attributes.grantOnly.discountAmountInPence || 0

  return (
    <SectionStatus
      heading={`Essential Probate (${complexity})`}
      incompleteHeading="Essential Probate"
      complete
    >
      <P size="S">
        This is a {complexity} estate so our quote for getting probate is:
      </P>
      <StyledPrice>
        {Dinero({ amount: probateOnlyQuote }).toFormat('$0,0')}
      </StyledPrice>
      <P size="S" margin={[0, 0, 'XS']}>
        Including VAT
      </P>
      {discountAmountProbateOnlyQuote > 0 && (
        <P size="S">
          Including a discount of{' '}
          {Dinero({ amount: discountAmountProbateOnlyQuote }).toFormat('$0,0')}
        </P>
      )}
      <P size="S" margin={[0, 0, 'XS']}>
        Additional costs:
      </P>
      <StyledList>
        <li>
          {Dinero({ amount: probateCourtFeePrice || 0 }).toFormat('$0,0')}{' '}
          probate registry fee
        </li>
        <li>
          {Dinero({ amount: PRICING.GRANT_COPY_PRICE }).toFormat()} per copy of
          the grant (usually we get 6)
        </li>
      </StyledList>
    </SectionStatus>
  )
}

export default ProbateOnlyQuote
