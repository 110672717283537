import { connect } from 'react-redux'

import { getAircallContactName } from 'utils/helpers'
import { fetchSearchResults } from 'state/actions'

import Suggestions from '../components/suggestions'

const mapStateToProps = (state) => {
  return {
    call: state.call,
    searchResults: state.searchResults,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchSearchResults: (call) => () => {
      const contactName = getAircallContactName(call.contact)
      if (!contactName) return null
      return dispatch(fetchSearchResults(contactName))
    },
  }
}

const mergeProps = (stateProps, dispatchProps) => {
  return {
    ...stateProps,
    ...dispatchProps,
    fetchSearchResults: dispatchProps.fetchSearchResults(stateProps.call),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(Suggestions)
