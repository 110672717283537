import { P } from '@farewill/ui'
import SelectInput from 'components/form/select-input'
import { Option } from 'components/form/select-input/types'
import { SelectOptions } from 'lib/types/common'
import {
  AdminUserSelectOptions,
  NextCoreTaskSelectOptions,
} from 'routes/case-list/types'
import FilterWrapper from '../shared/filter-wrapper'
import CaseFeatureFilters from '../shared/case-feature-filters'
import DateRangeFilter from '../shared/date-range-filter'

type Props = {
  product: string
  isMyCasesView: boolean
  adminUserOptions: AdminUserSelectOptions
  statusOptions: SelectOptions
  nextStageOptions: SelectOptions
  nextCoreTaskOptions: NextCoreTaskSelectOptions
}

const ProbateFilters = ({
  product,
  isMyCasesView,
  adminUserOptions,
  statusOptions,
  nextStageOptions,
  nextCoreTaskOptions,
}: Props): React.ReactElement => (
  <>
    {!isMyCasesView && (
      <FilterWrapper>
        <P strong margin={[0, 0, 'XS']} size="S">
          Case owner
        </P>
        <SelectInput
          options={adminUserOptions}
          name="currentOwnerId"
          isMulti
          small
        />
      </FilterWrapper>
    )}

    {nextStageOptions && (
      <FilterWrapper>
        <P strong margin={[0, 0, 'XS']} size="S">
          Current stage
        </P>

        <SelectInput
          name="nextStage"
          isMulti
          options={nextStageOptions}
          small
        />
      </FilterWrapper>
    )}

    {nextCoreTaskOptions && (
      <FilterWrapper>
        <P strong margin={[0, 0, 'XS']} size="S">
          Current core task
        </P>

        <SelectInput
          name="nextCoreTask"
          isMulti
          options={nextCoreTaskOptions as Option[]}
          small
        />
      </FilterWrapper>
    )}

    {statusOptions && (
      <FilterWrapper>
        <P strong margin={[0, 0, 'XS']} size="S">
          Case status
        </P>
        <SelectInput name="status" isMulti options={statusOptions} small />
      </FilterWrapper>
    )}

    <FilterWrapper>
      <CaseFeatureFilters product={product} />
    </FilterWrapper>

    <FilterWrapper>
      <P size="S" strong>
        Next task due
      </P>
      <DateRangeFilter name="nextTaskDueOn" />
    </FilterWrapper>
  </>
)

export default ProbateFilters
