import styled from 'styled-components'
import { useField } from 'formik'
import { PencilIcon } from '@farewill/ui'
import { FONT, GTR, COLOR } from '@farewill/ui/tokens'

import AutosizeInput from 'components/form/autosize-input'

const StyledNotesWrapper = styled.div<{ $showTopBorder: boolean }>`
  display: flex;
  align-items: flex-start;

  ${({ $showTopBorder }) =>
    $showTopBorder &&
    `
    padding-top: ${GTR.S};
    border-top: 1px dashed ${COLOR.GREY.MEDIUM};
  `}
`

const StyledAutosizeInput = styled(AutosizeInput)`
  width: 100%;
  font-size: ${FONT.SIZE.S};
  margin-left: ${GTR.XXS};
  padding: 0 ${GTR.XXS};
  background: transparent;

  &:focus {
    outline: none;
  }

  &:hover:not(:focus) {
    ${({ $hasValue }) => $hasValue && `background-color: ${COLOR.GREY.LIGHT};`};
  }
`

type Props = {
  name: string
  handleSave?: ({ name, value }: { name: string; value: string }) => void
  showTopBorder?: boolean
  disabled?: boolean
}

const NotesInput = ({
  handleSave,
  showTopBorder = true,
  name,
  disabled,
}: Props) => {
  const [field] = useField(name)

  return (
    <StyledNotesWrapper $showTopBorder={showTopBorder}>
      <PencilIcon />
      <StyledAutosizeInput
        $hasValue={!!field.value}
        placeholder="Add note..."
        name={name}
        onBlur={(e: Event) => {
          field.onBlur(e)
          handleSave && handleSave({ name, value: field.value })
        }}
        disabled={disabled}
      />
    </StyledNotesWrapper>
  )
}

export default NotesInput
