import { combineReducers } from 'redux'
import aircallEvents from './aircall-events'
import call from './call'
import contact from './contact'
import contactEvents from './contact-events'
import funeral from './cremation'
import funeralPlanCase from './funeral-plans-case'
import funeralPlanPaymentDetails from './funeral-plans-payment-details'
import funeralPlanDocuments from './funeral-plans-documents'
import errors from './errors'
import errorsGlobal from './errors-global'
import latestCalls from './latest-calls'
import lead from './lead'
import leadEvents from './lead-events'
import leadImports from './lead-imports'
import lpaCase from './lpa-case'
import messages from './messages'
import modal from './modal'
import mode from './mode'
import probateCase from './probate-case'
import productEvents from './product_events'
import requestsPending from './requests-pending'
import searchResults from './search-results'
import token from './token'
import will from './will'

export default combineReducers({
  aircallEvents,
  call,
  contact,
  contactEvents,
  funeral,
  funeralPlanCase,
  funeralPlanPaymentDetails,
  funeralPlanDocuments,
  errors,
  errorsGlobal,
  latestCalls,
  lead,
  leadEvents,
  leadImports,
  lpaCase,
  messages,
  modal,
  mode,
  probateCase,
  productEvents,
  requestsPending,
  searchResults,
  token,
  will,
})
