import PropTypes from 'prop-types'
import { Grid, H } from '@farewill/ui'
import { useFormikContext } from 'formik'

import CircularRadioGroup from 'components/form/circular-radio-group'
import InputFloating from 'components/form/input-floating'
import Tooltip from 'components/tooltip'

import {
  DEFAULT_NUMBER_OF_GRANTS_REQUESTED,
  NUMBER_OF_GRANTS_REQUESTED,
  OVERSEAS_GRANTS_REQUESTED,
  NUMBER_OF_OVERSEAS_GRANTS_REQUESTED,
} from './field-names'

const Grants = ({ saveProbateEstateField }) => {
  const { values } = useFormikContext()
  return (
    <Grid data-scroll-id="6.5">
      <Grid.Item>
        <H size="S">6.5 Grants</H>
      </Grid.Item>

      <Grid.Item>
        <CircularRadioGroup
          name={DEFAULT_NUMBER_OF_GRANTS_REQUESTED}
          label={
            <>
              Is the customer happy for us to decide{' '}
              <Tooltip content="They will automatically receive 1 grant with their application. It’s £1.50 per copy.">
                how many additional UK grant copies
              </Tooltip>{' '}
              to get?
            </>
          }
          options={[
            { label: 'Yes', value: 'true' },
            { label: 'No', value: 'false' },
          ]}
          handleSave={saveProbateEstateField}
          inline
        />
      </Grid.Item>
      {values[DEFAULT_NUMBER_OF_GRANTS_REQUESTED] === 'false' && (
        <Grid.Item span={5}>
          <InputFloating
            name={NUMBER_OF_GRANTS_REQUESTED}
            label="How many copies would they like?"
            handleSave={saveProbateEstateField}
          />
        </Grid.Item>
      )}
      <Grid.Item>
        <CircularRadioGroup
          name={OVERSEAS_GRANTS_REQUESTED}
          label="Does the customer need grants for use outside the UK?"
          options={[
            { label: 'Not asked', value: '' },
            { label: 'Yes', value: 'true' },
            { label: 'No', value: 'false' },
          ]}
          handleSave={saveProbateEstateField}
          inline
        />
      </Grid.Item>
      {values[OVERSEAS_GRANTS_REQUESTED] === 'true' && (
        <Grid.Item span={5} startRow={5}>
          <InputFloating
            name={NUMBER_OF_OVERSEAS_GRANTS_REQUESTED}
            label="How many grants would they like?"
            handleSave={saveProbateEstateField}
          />
        </Grid.Item>
      )}
    </Grid>
  )
}

Grants.propTypes = {
  saveProbateEstateField: PropTypes.func.isRequired,
}

export default Grants
