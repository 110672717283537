import Liabilities from './liabilities'
import FuneralCosts from './funeral-costs'
import { useProbateEstate } from '../helpers/probate-estate-context'
import { SECTION_NAMES } from '../helpers/complete-sections'

const LiabilitiesWrapper = ({
  isComplete,
  checkIfComplete,
}: {
  isComplete: boolean
  checkIfComplete: (name: string) => boolean
}) => {
  const { probateEstate } = useProbateEstate()
  const initialValues = probateEstate.attributes

  return (
    <>
      <Liabilities
        initialValues={initialValues}
        isComplete={isComplete}
        checkIfComplete={checkIfComplete}
      />
      <FuneralCosts isComplete={checkIfComplete(SECTION_NAMES.FUNERAL_COSTS)} />
    </>
  )
}

export default LiabilitiesWrapper
