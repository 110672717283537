import { SvgIcon } from '@farewill/ui'
import { SvgIconProps } from '@farewill/ui/components/Icon/types'

export const CopyToClipboardIcon = ({
  className,
  ...rest
}: {
  className?: string
} & SvgIconProps) => (
  <SvgIcon className={className} {...rest}>
    <g fillRule="evenodd" stroke="none" strokeWidth="1">
      <path
        d="M14,0 L2,0 C0.9,0 0,0.9 0,2 L0,16 L2,16 L2,2 L14,2 L14,0 L14,0 Z M17,4 L6,4 C4.9,4 4,4.9 4,6 L4,20 C4,21.1 4.9,22 6,22 L17,22 C18.1,22 19,21.1 19,20 L19,6 C19,4.9 18.1,4 17,4 L17,4 Z M17,20 L6,20 L6,6 L17,6 L17,20 L17,20 Z"
        id="Shape"
      />
    </g>
  </SvgIcon>
)
