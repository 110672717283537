import cloneDeep from 'lodash/cloneDeep'
import Dinero from 'dinero.js'

import PRICING from 'config/pricing'
import { MORE_DETAILS_NEEDED } from 'routes/lead/form-helpers'
import { LpaLeadAttributes, Partner } from 'lib/models/lead'
import { WillCaseAttributes } from 'lib/models/will-case'

import { Outcome, Question } from './types'

Dinero.globalLocale = 'en-GB'
Dinero.defaultCurrency = 'GBP'

export const SINGLE_LPA = {
  complete: true,
  type: 'single',
  heading: 'Single LPA',
  message: `Government fee for registration is ${Dinero({
    amount: PRICING.LPA_PRICING.REGISTRATION_FEE,
  }).toFormat(
    '$0,0'
  )} per LPA and is not included in our fee. They have to do the registration themselves and can choose to register either or both LPAs.`,
}

export const COUPLES_LPA = {
  complete: true,
  type: 'couple',
  heading: 'Couples LPA',
  message: `Government fee for registration is ${Dinero({
    amount: PRICING.LPA_PRICING.REGISTRATION_FEE,
  }).toFormat(
    '$0,0'
  )} per LPA and is not included in our fee. They have to do the registration themselves and can choose to register either or both LPAs.`,
}

export const formatAttributesForForm = (attributes: LpaLeadAttributes) => {
  const clonedAttributes = cloneDeep(attributes)
  const priceInPence = attributes?.quoteAttributes?.price

  if (priceInPence) {
    const priceInPounds = Dinero({ amount: priceInPence }).toUnit()
    clonedAttributes.quoteAttributes.price = priceInPounds
  }

  return clonedAttributes
}

// If the `parter` object in quoteAttributes has any field as non-empty string, it means that the user has already
// modified the partner details and we should not override them with the data from the linked Will Case
export function findNonEmptyString<T>(obj: T): T | undefined {
  for (const key in obj) {
    const value = obj[key]

    if (typeof value === 'string' && value.length > 0) {
      return obj
    }

    if (typeof value === 'object' && value !== null) {
      const result = findNonEmptyString(value)
      if (result !== undefined) {
        return obj
      }
    }
  }

  return undefined
}

// Returns only the questions that should be visible based on the answers given
export const visibleQuestions = ({
  questions,
  values,
}: {
  questions: Question[]
  values: LpaLeadAttributes
}) => {
  let earlyOutcome: Outcome | undefined | null
  return questions.filter((Question) => {
    const isVisible = Question.isVisible ? Question.isVisible(values) : true
    if (!earlyOutcome && isVisible) {
      earlyOutcome = Question.outcome ? Question.outcome(values) : null
      return true
    } else {
      return false
    }
  })
}

// Returns outcome for LPA (either Singla or Couples) based on the answers given
export const outcomeForQuestions = ({
  questions,
  values,
}: {
  questions: Question[]
  values: LpaLeadAttributes
}) => {
  const earlyOutcome = questions.reduce<Outcome | undefined>(
    (result, Question) => result || Question?.outcome?.(values),
    undefined
  )

  if (earlyOutcome) {
    return earlyOutcome
  }

  type NestedObject = {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: any
  }

  const withAddressQuestions = (questions: Question[]) =>
    values.quoteAttributes.lpaType === 'single'
      ? questions.map((question) => question.name)
      : questions
          .filter(
            (question) =>
              !question.name.includes('addressFields') &&
              !question.name.includes('partner.title')
          )
          .map((question) => question.name)
          .concat([
            'quoteAttributes.partner.addressFields.lineOne',
            'quoteAttributes.partner.addressFields.city',
            'quoteAttributes.partner.addressFields.countryCode',
            'quoteAttributes.partner.addressFields.postalCode',
          ])

  const allQuestionsAnswered = withAddressQuestions(questions).every((name) => {
    const keys = name.split('.')
    let currentObj: NestedObject = values

    for (let i = 0; i < keys.length; i++) {
      if (!(keys[i] in currentObj)) {
        return false
      }

      currentObj = currentObj[keys[i]]
    }

    if (typeof currentObj === 'string' && currentObj === '') {
      return false
    }

    // Check if the value is not empty
    return currentObj !== null && currentObj !== undefined
  })

  if (!allQuestionsAnswered) {
    return MORE_DETAILS_NEEDED
  }

  if (values.quoteAttributes.lpaType === 'couple') {
    return COUPLES_LPA
  }

  return SINGLE_LPA
}

// Returns partner data from linked Will Case. If there is no linked Will Case, returns undefined
export const getPartnerDataFromWillCase = (
  values: WillCaseAttributes
): Partner | undefined => {
  if (!values) return undefined

  const leadContactId = values.contactId
  const partnerContactData = values.contacts?.filter(
    (contact) => contact.id !== leadContactId
  )[0]

  return {
    title: partnerContactData?.title || '',
    firstName: partnerContactData?.firstName || '',
    lastName: partnerContactData?.lastName || '',
    email: partnerContactData?.email || '',
    phoneNumber: partnerContactData?.phoneNumber || '',
    dateOfBirth: partnerContactData?.dateOfBirth || '',
    addressFields: {
      lineOne: partnerContactData?.addresses?.[0]?.lineOne || '',
      lineTwo: partnerContactData?.addresses?.[0]?.lineTwo || '',
      city: partnerContactData?.addresses?.[0]?.city || '',
      postalCode: partnerContactData?.addresses?.[0]?.postalCode || '',
      countryCode: partnerContactData?.addresses?.[0]?.countryCode || '',
    },
  }
}
