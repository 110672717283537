import styled from 'styled-components'
import { Button, ChevronRightIcon, Wrapper } from '@farewill/ui'
import { COLOR, FONT, GTR } from '@farewill/ui/tokens'
import uniqBy from 'lodash/uniqBy'

import AdminIcon from 'components/admin-icon'
import ActivityTimestamp from 'components/activity-timestamp'

const StyledButton = styled(Button.Bordered)`
  padding: ${GTR.XXS} ${GTR.XS};
  font-size: ${FONT.SIZE.S};
  color: ${COLOR.GREY.MEDIUM};

  ${({ $expanded }) => $expanded && `background-color: ${COLOR.WHITE};`}

  &:hover {
    background-color: ${COLOR.WHITE};
  }
`

const StyledArrow = styled(ChevronRightIcon)`
  margin-left: ${GTR.XXS};

  ${({ $expanded }) => $expanded && `transform: rotate(90deg);`}
`

const StyledAdminIcons = styled(Wrapper)`
  display: flex;
  margin-right: ${GTR.XS};
`

const StyledAdminIcon = styled(AdminIcon)`
  &:not(:first-child) {
    margin-left: -${GTR.XXS};
  }
`

const StyledLastReplyAt = styled.span`
  color: ${COLOR.GREY.MEDIUM};
  font-weight: ${FONT.WEIGHT.REGULAR};
  margin-left: ${GTR.XXS};
  margin-right: ${GTR.L};
`

const StyledBullet = styled.span`
  color: ${COLOR.GREY.LIGHT};
`

const getButtonText = (replies) => {
  if (replies.length === 0) return 'Reply'
  if (replies.length === 1) return '1 Reply'
  return `${replies.length} Replies`
}

const AdminIcons = ({ replies }) => {
  if (replies.length === 0) return null

  return (
    <StyledAdminIcons>
      {uniqBy(replies, 'adminUser.id').map(({ adminUser }) => (
        <StyledAdminIcon user={adminUser} size="S" key={adminUser.id} />
      ))}
    </StyledAdminIcons>
  )
}

const LastReplyAt = ({ replies }) => {
  if (replies.length === 0) return null
  const [lastReply] = replies.slice(-1)

  return (
    <StyledLastReplyAt>
      <StyledBullet>&bull;</StyledBullet> last reply{' '}
      <ActivityTimestamp datetime={lastReply.createdAt} />
    </StyledLastReplyAt>
  )
}

const ReplyButton = ({ expanded, handleClick, replies }) => (
  <StyledButton $expanded={expanded} onClick={handleClick}>
    <AdminIcons replies={replies} />
    {getButtonText(replies)}
    <LastReplyAt replies={replies} />
    <StyledArrow $expanded={expanded} size="S" />
  </StyledButton>
)

export default ReplyButton
