import { connect } from 'react-redux'
import PrivateRoute from '../components'

const mapStateToProps = (state: { token: string }) => {
  return {
    token: state.token,
  }
}

export default connect(mapStateToProps, null)(PrivateRoute)
