import styled from 'styled-components'
import PropTypes from 'prop-types'
import { H, Grid } from '@farewill/ui'
import { COLOR, FONT, GTR } from '@farewill/ui/tokens'
import { useFormikContext } from 'formik'

import { nationalInsurance as isValidNationalInsurance } from 'lib/formik/validation'

import InputFloating from 'components/form/input-floating'
import CircularRadioGroup from 'components/form/circular-radio-group'
import NotesInput from 'components/notes-input'
import DateSplit from 'components/form/date-split'
import Tooltip from 'components/tooltip'
import Address from '../../components/address'

import AgeAtDeath from './age-at-death'

import { getEstateVisibleFields } from '../form-helpers'
import { NOT_ASKED_OPTIONS } from 'utils/enums'
import {
  BIRTH,
  DEATH,
  LAST_PERMANENT_ADDRESS,
  LAST_PERMANENT_ADDRESS_MATCHES_WILL,
  LAST_PERMANENT_ADDRESS_MISMATCH_NOTES,
  LAST_POSTAL_ADDRESS,
  IDENTIFICATION_NOTES,
  NATIONAL_INSURANCE_NUMBER,
  OCCUPATION,
} from '../field-names'

const StyledTooltipHint = styled.span`
  display: block;
  color: ${COLOR.GREY.MEDIUM};
  font-size: ${FONT.SIZE.XS};
  padding-left: ${GTR.S};
  margin-top: ${GTR.XXS};
`

const NI_TOOLTIP =
  'Found on letter from DWP or Pension Service, or if receiving state pension or benefit it will be the ref number for payment on bank statement.'

const IdentifyingPersonWhosDied = ({ saveProbateEstateField }) => {
  const { values } = useFormikContext()
  const {
    showLastPermanentAddressMatchesWill,
    showLastPermanentAddressMismatchNotes,
  } = getEstateVisibleFields({ values })

  return (
    <Grid data-scroll-id="1.3">
      <Grid.Item>
        <H size="S">1.3 Identifying the person who’s died</H>
      </Grid.Item>
      <Grid.Item span={5}>
        <DateSplit
          name={BIRTH}
          label="Date of birth"
          handleSave={saveProbateEstateField}
          hint="For example, 24 2 2020"
        />
      </Grid.Item>
      <Grid.Item span={5}>
        <DateSplit
          name={DEATH}
          label="Date of death"
          handleSave={saveProbateEstateField}
          hint="For example, 24 2 2020"
        />
      </Grid.Item>
      <Grid.Item span={2}>
        <AgeAtDeath />
      </Grid.Item>
      <Grid.Item span={9}>
        <Address
          label="Last permanent (home) address"
          name={LAST_PERMANENT_ADDRESS}
          data={values[LAST_PERMANENT_ADDRESS]}
        />
      </Grid.Item>
      {showLastPermanentAddressMatchesWill && (
        <>
          <Grid.Item>
            <CircularRadioGroup
              name={LAST_PERMANENT_ADDRESS_MATCHES_WILL}
              label="Is the last permanent address given above the same as the address in the will?"
              options={NOT_ASKED_OPTIONS}
              handleSave={saveProbateEstateField}
              inline
            />
          </Grid.Item>
          {showLastPermanentAddressMismatchNotes && (
            <Grid.Item span={10}>
              <InputFloating
                name={LAST_PERMANENT_ADDRESS_MISMATCH_NOTES}
                label="Describe the difference in addresses"
                handleSave={saveProbateEstateField}
                component="textarea"
              />
            </Grid.Item>
          )}
        </>
      )}
      <Grid.Item span={9}>
        <Address
          label="Last postal address, e.g. care home"
          name={LAST_POSTAL_ADDRESS}
          data={values[LAST_POSTAL_ADDRESS]}
        />
      </Grid.Item>
      <Grid.Item span={5}>
        <InputFloating
          name={NATIONAL_INSURANCE_NUMBER}
          label="NI number"
          handleSave={saveProbateEstateField}
          validate={isValidNationalInsurance}
        />
        <StyledTooltipHint>
          Must be format AB123456A.{' '}
          <Tooltip content={NI_TOOLTIP}>Found here.</Tooltip>
        </StyledTooltipHint>
      </Grid.Item>
      <Grid.Item span={5}>
        <InputFloating
          name={OCCUPATION}
          label="Occupation"
          handleSave={saveProbateEstateField}
        />
      </Grid.Item>
      <Grid.Item>
        <NotesInput
          name={IDENTIFICATION_NOTES}
          handleSave={saveProbateEstateField}
        />
      </Grid.Item>
    </Grid>
  )
}

IdentifyingPersonWhosDied.propTypes = {
  saveProbateEstateField: PropTypes.func.isRequired,
}

export default IdentifyingPersonWhosDied
