import { isEmpty } from 'lodash'
import { Button, Image, P, Wrapper } from '@farewill/ui'
import { BORDER_RADIUS, COLOR, FONT, GTR } from '@farewill/ui/tokens'
import styled from 'styled-components'
import { useFormikContext } from 'formik'
import { useQueryClient } from 'react-query'

import { formatAdminUserOptions } from 'utils/helpers'

import SelectInput from 'components/form/select-input'
import { Lead } from 'lib/models/lead'
import { AdminUser } from 'lib/models/admin-user'

const StyledOwnerWrapper = styled(Wrapper)`
  background: ${COLOR.ACCENT.PRIMARY_20};
  border: 2px solid ${COLOR.ACCENT.PRIMARY_120};
  border-radius: ${BORDER_RADIUS.S};
  padding: ${GTR.S};
`

const StyledInnerWrapper = styled(Wrapper)`
  display: flex;
  align-items: center;
  column-gap: ${GTR.XS};
`

const StyledChangeOwnerButton = styled(Button)`
  text-decoration: underline;
  font-weight: ${FONT.WEIGHT.REGULAR};

  &:hover {
    text-decoration: underline;
  }
`

const StyledAvatar = styled(Image)`
  width: 32px;
  height: 32px;
  border-radius: 50px;
`

const OwnerAssignment = ({
  lead,
  loggedInAdminUserId,
}: {
  lead: Lead
  loggedInAdminUserId: number
}) => {
  const cache = useQueryClient()
  const adminUsers = cache.getQueryData<AdminUser[]>('adminUsers')
  const { values, setFieldValue } = useFormikContext<{
    changeAssignedOwner: boolean
  }>()
  const { changeAssignedOwner } = values

  const currentOwnerId = lead.attributes.ownedByAdminUserId
  const currentOwner = adminUsers?.find((user) => user?.id === currentOwnerId)

  const adminUserOptions =
    adminUsers && formatAdminUserOptions(adminUsers, loggedInAdminUserId)

  const inputVisible = changeAssignedOwner || !currentOwnerId

  return inputVisible ? (
    <Wrapper margin={['S', 0, 0]}>
      <SelectInput
        name="leadChanges.ownedByAdminUserId"
        label="Select owner"
        options={adminUserOptions}
      />
    </Wrapper>
  ) : (
    <fieldset>
      <P margin={[0, 0, 'XS']}>Lead owner</P>
      <StyledOwnerWrapper>
        <StyledInnerWrapper margin={[0, 0, 'XS']}>
          {currentOwner?.attributes.picture && (
            <StyledAvatar
              path={currentOwner?.attributes.picture}
              formatPath={({ path }: { path: string }) => path}
            />
          )}
          <P margin={0}>
            {!isEmpty(adminUsers)
              ? currentOwner?.attributes.name
              : 'Loading...'}
          </P>
        </StyledInnerWrapper>

        <StyledChangeOwnerButton
          type="button"
          onClick={() => {
            setFieldValue('changeAssignedOwner', true)
          }}
        >
          Reassign
        </StyledChangeOwnerButton>
      </StyledOwnerWrapper>
    </fieldset>
  )
}

export default OwnerAssignment
