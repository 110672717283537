import sortBy from 'lodash/sortBy'
import styled from 'styled-components'

import { Button, H, P } from '@farewill/ui'
import { COLOR, GTR } from '@farewill/ui/tokens'

import ExternaLink from 'components/external-link'
import GridRow from 'components/list/grid-row'
import { formatCurrency } from 'lib/formatting/currency'
import { formatDate } from 'lib/formatting/dates'
import { Invoice, invoiceTotalPrice } from 'lib/models/invoice'
import { squareUrlFromTransactionId } from 'utils/helpers'

import { getTotalInvoicesAmountInPounds } from './methods'

interface InvoicesProps {
  invoices: Invoice[]
  onEdit?: (invoice: Invoice) => void
  actions?: React.ReactNode
}

const StyledTotalPriceContainer = styled.div`
  p {
    margin-top: 8px;
    text-align: center;
  }
`

const StyledSingleGridRow = styled(P)`
  display: grid;
`

const StyledGridRow = styled(GridRow)`
  padding-left: 0;
  padding-right: 0;
`

const StyledUnselectableGridRow = styled(StyledGridRow)`
  cursor: default;
`

const StyledEllipsisText = styled.li`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const NoInvoices = () => <StyledSingleGridRow>No payments</StyledSingleGridRow>

const StyledUnpaidText = styled.span`
  color: ${COLOR.STATE.ERROR};
`

const Row = styled.div`
  display: grid;
  grid-template-columns: max-content 1fr;
  align-items: center;
`

const Actions = styled.div`
  justify-self: end;
  & > * {
    margin-left: ${GTR.S};
  }
`

const CardPaymentLink = ({ reference }: { reference?: string }) => {
  const content = 'Card payment'
  return reference ? (
    <ExternaLink href={squareUrlFromTransactionId(reference)}>
      {content}
    </ExternaLink>
  ) : (
    <>{content}</>
  )
}

const PaymentsSummary = ({
  invoices,
  onEdit,
  actions,
}: InvoicesProps): React.ReactElement => {
  const gridTemplateColumns = 'repeat(3, 1fr) 0.5fr 0.3fr'
  const totalInvoicesAmountInPounds = getTotalInvoicesAmountInPounds(invoices)
  const orderedInvoices = sortBy(
    invoices,
    ({ attributes }) => attributes.issuedDate
  )

  return (
    <>
      <Row>
        <H size="S">Payments</H>
        <Actions>{actions}</Actions>
      </Row>

      <StyledGridRow header gridTemplateColumns={gridTemplateColumns}>
        <li>Date</li>
        <li>Description</li>
        <li>Amount</li>
        <li>Status</li>
        <li></li>
      </StyledGridRow>

      {invoices.length === 0 && <NoInvoices />}

      {orderedInvoices.map((invoice) => {
        const { attributes, id } = invoice
        const paidInvoiceItem =
          attributes.paymentProvider === 'square' || attributes?.paidOn !== null
        const totalInvoiceLineItemsInPounds = formatCurrency({
          value: invoiceTotalPrice(invoice),
          valueInPence: true,
        })

        return (
          <StyledUnselectableGridRow
            gridTemplateColumns={gridTemplateColumns}
            key={id}
          >
            <li>{formatDate(attributes.issuedDate)}</li>
            {attributes?.paymentProvider === 'square' ? (
              <StyledEllipsisText>
                <CardPaymentLink reference={attributes?.reference} />
              </StyledEllipsisText>
            ) : (
              <StyledEllipsisText>
                {attributes.externalReferenceId ?? 'Invoice'}
              </StyledEllipsisText>
            )}

            <li>
              {paidInvoiceItem ? (
                totalInvoiceLineItemsInPounds
              ) : (
                <StyledUnpaidText>
                  {totalInvoiceLineItemsInPounds}
                </StyledUnpaidText>
              )}
            </li>

            <li>
              {paidInvoiceItem ? (
                'Paid'
              ) : (
                <StyledUnpaidText>Unpaid</StyledUnpaidText>
              )}
            </li>

            <li>
              {onEdit && <Button onClick={() => onEdit(invoice)}>edit</Button>}
            </li>
          </StyledUnselectableGridRow>
        )
      })}
      <StyledTotalPriceContainer>
        <P strong>Total paid: {totalInvoicesAmountInPounds}</P>
      </StyledTotalPriceContainer>
    </>
  )
}

export default PaymentsSummary
