import { useEffect, useRef } from 'react'
import { Divider } from '@farewill/ui'
import { Formik, useFormikContext } from 'formik'
import merge from 'lodash/merge'
import get from 'lodash/get'
import set from 'lodash/set'
import isNumber from 'lodash/isNumber'
import PropTypes from 'prop-types'

import FuneralUnsupportedWarning from 'components/funeral-unsupported-warning'
import useFeatureFlag from 'hooks/useFeatureFlag'
import useApi from 'lib/effects/api'
import { FUNERAL_LEAD_SCHEMA } from 'lib/formik/schemata'
import { FuneralLeadAttributes } from 'lib/models/lead'
import { QuoteResponseDataFunerals } from 'lib/types'
import { BURIAL, CREMATION, SCOTLAND_FEATURE_FLAG_CONFIG } from 'utils/enums'
import { formatValuesForFormik } from 'utils/helpers'
import { useLeadContext } from '../context'
import TakePayment from '../take-payment'
import QuoteForm from '../quote-form'
import CallerDetails from './caller-details'
import ConvertCase from './convert-case'
import Disputes from './disputes'
import Dwp from './dwp'
import {
  FormikValues,
  hasAgreementToProceed,
  quoteIsComplete,
  skipPayment,
} from './helpers'
import Quote from './quote'
import WhoFuneralIsFor from './who-funeral-is-for'
import AccountDetails from './account-details'

const BLANK_VALUES = {
  calculatedPrice: '',
  contact: {
    title: '',
    email: '',
    firstName: '',
    lastName: '',
    phoneNumber: '',
  },
  partnerType: '',
  partnerId: undefined,
  quoteAttributes: {
    funeralType: CREMATION,
    attendedService: '',
    deceasedReferredToCoroner: '',
    deceasedInMortuary: '',
    paymentAmount: '',
    paymentReference: '',
    alreadyDeceased: 'true',
    customerIsArrangingTheirOwnFuneral: false,
    title: '',
    firstName: '',
    lastName: '',
    relationshipToCustomer: '',
    customerRefersToThemAs: '',
    dateOfBirth: '',
    dateOfDeath: '',
    treeType: '',
    treePlaqueMessage: '',
    urnType: '',
    urnTypes: [],
    flowersType: '',
    ordersOfService: '',
    dressingService: '',
    deceasedLocationCategory: '',
    ashesInstruction: '',
    ashesSplitting: null,
    ashesSplittingPortions: null,
    hasObjectionsToFuneral: null,
    needsSupportFromDwp: null,
    dwpSupportConfirmed: null,
    crematoriumLocation: {
      id: '',
      label: '',
    },
    crematorium: {
      id: '',
      label: '',
      timeSlot: '',
    },
    paymentMethod: 'card',
    invoice: null,
    hearseId: '',
    hearseNotes: '',
    hearseAdditionalCost: 0,
    servicePreferences: '',
    deathCountryId: '',
    voucherCode: '',
  },
}

type FuneralFormWrapperProps = {
  attributes: FuneralLeadAttributes
  disabled: boolean
}

const CURRENCY_QUOTE_ATTRIBUTES_PATHS = [
  'quoteAttributes.paymentAmount',
  'quoteAttributes.invoice.amount',
  'quoteAttributes.hearseAdditionalCost',
] as const

const FuneralFormWrapper = ({
  attributes,
  disabled,
}: FuneralFormWrapperProps): React.ReactElement => {
  /**
   * For funerals existing before the feature to allow multiple urns, an urn
   * would have been added on urnType rather than urnTypes. If this is the case,
   * we need to translate the value to urnTypes before getting the initial form
   * values so that the urn appears in the UI form.
   * There was a bug where both urnType and urnTypes could be set on the lead so
   * we only want to overwrite urnTypes if it has not been set yet. urnTypes is
   * the source of truth when available.
   * */
  if (
    attributes.quoteAttributes.urnType &&
    !attributes.quoteAttributes.urnTypes?.length
  ) {
    attributes.quoteAttributes.urnTypes = [attributes.quoteAttributes.urnType]
    attributes.quoteAttributes.urnType = ''
  }

  const formattedValues = formatValuesForFormik(attributes)
  const initialValues = merge({}, BLANK_VALUES, formattedValues)

  // Convert the following paths from pence to pounds
  CURRENCY_QUOTE_ATTRIBUTES_PATHS.forEach((path) => {
    const amount = get(initialValues, path)
    if (isNumber(amount)) {
      set(initialValues, path, amount / 100)
    }
  })

  const { onSubmit, leadId } = useLeadContext()

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={FUNERAL_LEAD_SCHEMA}
      validateOnMount
      validateOnChange={false}
    >
      <FuneralForm leadId={leadId} disabled={disabled} />
    </Formik>
  )
}

type FuneralFormProps = {
  disabled: boolean
  leadId: string
}

const FuneralForm = ({ leadId, disabled }: FuneralFormProps) => {
  const { handleSubmit, setFieldValue, values } =
    useFormikContext<FormikValues>()
  const { lead } = useLeadContext()
  const { calculatedPrice } = values
  const [{ data: quote }, makeRequest] = useApi<QuoteResponseDataFunerals>()
  const isFirstRender = useRef(true)

  const {
    ashesInstruction,
    ashesSplitting,
    dateOfDeath,
    deceasedReferredToCoroner,
    deceasedInMortuary,
    deceasedWeighsOver20Stone,
    hasHazardousImplant,
    treeType,
    treePlaqueMessage,
    urnType,
    urnTypes,
    coffinType,
    funeralType,
    deathCountryId,
  } = values.quoteAttributes

  const isScotlandEnabled =
    useFeatureFlag(SCOTLAND_FEATURE_FLAG_CONFIG) === 'false' ? false : true

  const isQuoteComplete = quoteIsComplete({ values, isScotlandEnabled })

  const isReferredAndPaid = !!(
    lead.attributes.funeralPlanId && calculatedPrice === 0
  )

  useEffect(() => {
    if (isQuoteComplete && !isFirstRender.current) {
      makeRequest({
        url: '/api/quotes',
        method: 'POST',
        data: {
          data: {
            type: 'quotes',
            attributes: {
              product: 'funeral',
              leadId,
              quoteAttributes: {
                ashesInstruction,
                ashesSplitting: ashesSplitting === 'true',
                dateOfDeath,
                funeralType: CREMATION,
                attendedService: false,
                deceasedInMortuary: deceasedInMortuary === 'true',
                deceasedReferredToCoroner: deceasedReferredToCoroner === 'true',
                deceasedWeighsOver20Stone: deceasedWeighsOver20Stone === 'true',
                treeType: treeType || null,
                treePlaqueMessage: treePlaqueMessage || null,
                urnType: urnType || null,
                urnTypes: urnTypes || null,
                coffinType: coffinType || null,
                hasHazardousImplant: hasHazardousImplant || null,
                deathCountryId: deathCountryId || null,
              },
            },
          },
        },
      })
    }
  }, [
    ashesInstruction,
    ashesSplitting,
    dateOfDeath,
    deceasedInMortuary,
    deceasedReferredToCoroner,
    deceasedWeighsOver20Stone,
    isQuoteComplete,
    makeRequest,
    coffinType,
    treeType,
    treePlaqueMessage,
    urnType,
    urnTypes,
    leadId,
    funeralType,
    hasHazardousImplant,
    deathCountryId,
    lead.attributes.voucherCode,
  ])

  useEffect(() => {
    if (!isFirstRender.current) {
      const calculatedPrice =
        typeof quote?.attributes.amountInPence === 'number'
          ? quote?.attributes.amountInPence
          : null
      setFieldValue('calculatedPrice', calculatedPrice, false)
      handleSubmit()
    }
  }, [handleSubmit, quote, setFieldValue])

  useEffect(() => {
    isFirstRender.current = false
  }, [])

  return (
    <QuoteForm disabled={disabled}>
      {(values.quoteAttributes.funeralType === BURIAL ||
        values.quoteAttributes.attendedService === 'true') && (
        <>
          <FuneralUnsupportedWarning attributes={values.quoteAttributes} />
          <Divider margin={['XL', 0]} />{' '}
        </>
      )}
      <CallerDetails />
      <AccountDetails />
      <Divider margin={['XL', 0]} />
      <WhoFuneralIsFor />
      <Divider margin={['XL', 0]} />
      <Quote isComplete={isQuoteComplete} />
      <Divider margin={['XL', 0]} />
      <Disputes />
      <Divider margin={['XL', 0]} />
      <Dwp skip={hasAgreementToProceed(values) !== true} />
      {!isReferredAndPaid && (
        <>
          <Divider margin={['XL', 0]} />
          <TakePayment
            calculatedPrice={calculatedPrice}
            editablePaymentReference
            allowInvoicePayment
            skip={skipPayment({ values, isScotlandEnabled })}
          />
        </>
      )}
      <Divider margin={['XL', 0]} />
      <ConvertCase isReferredAndPaid={isReferredAndPaid} />
    </QuoteForm>
  )
}

FuneralFormWrapper.propTypes = {
  attributes: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
}

export default FuneralFormWrapper
