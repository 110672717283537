import { Dispatch, SetStateAction, useState } from 'react'
import { P, Wrapper } from '@farewill/ui'
import { COLOR, GTR } from '@farewill/ui/tokens'
import styled from 'styled-components'
import pull from 'lodash/pull'

import Switch from 'components/switch'
import { Uppercase } from 'components/styled/uppercase'

import { SECTIONS_CONFIG } from './config'
import Section from './section'
import { FAREWILL_PRODUCTS } from 'utils/enums'

const StyledSwitchWrapper = styled(Wrapper)`
  display: flex;
  margin-bottom: ${GTR.S};
`

type Props = {
  product: keyof typeof FAREWILL_PRODUCTS
  linkTo: string
  sections: string[]
  saveSections: (sections: string) => void
  highlightEmpty: boolean
  setHighlightEmpty: Dispatch<SetStateAction<boolean>>
}

const SideNavigationForm = ({
  product,
  linkTo,
  sections,
  saveSections,
  highlightEmpty,
  setHighlightEmpty,
}: Props): React.ReactElement => {
  const [completedSections, setCompletedSections] = useState<string[]>(
    sections || []
  )
  const [openSections, setOpenSections] = useState<{ [key: string]: boolean }>(
    {}
  )

  const totalComplete = completedSections.length || 0
  const totalSections = SECTIONS_CONFIG[product].length

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = [...completedSections]
    if (e.target.checked) {
      newValue.push(e.target.name)
    } else {
      pull(newValue, e.target.name)
    }
    setCompletedSections(newValue)
    saveSections(JSON.stringify(newValue))
  }

  return (
    <Wrapper>
      <Uppercase color={COLOR.GREY.MEDIUM}>
        {totalComplete}/{totalSections} fact find sections completed
      </Uppercase>
      <Wrapper margin={[0, 0, 'S']}>
        {SECTIONS_CONFIG[product].map((section, index) => (
          <Section
            handleArrowClick={setOpenSections}
            handleChange={handleChange}
            isOpen={openSections[section.name]}
            isComplete={completedSections.includes(section.name)}
            key={section.scrollId}
            linkTo={linkTo}
            section={section}
          />
        ))}
      </Wrapper>
      {setHighlightEmpty && (
        <StyledSwitchWrapper>
          <Switch
            color={COLOR.STATE.WARNING}
            handleToggle={() => setHighlightEmpty(!highlightEmpty)}
            isOn={highlightEmpty}
          />
          <P margin={[0, 0, 0, 'XS']} size="S">
            Highlight missing details
          </P>
        </StyledSwitchWrapper>
      )}
    </Wrapper>
  )
}

export default SideNavigationForm
