import { MouseEvent, PropsWithChildren, ReactElement, ReactNode } from 'react'
import styled from 'styled-components'
import { Button, Wrapper } from '@farewill/ui'
import { COLOR, GTR } from '@farewill/ui/tokens'
import { useField } from 'formik'
import { toast } from 'react-toastify'

import { CopyToClipboardIcon } from 'components/custom-icons'

const StyledButton = styled(Button.Plain)`
  position: absolute;
  top: 50%;
  transform: translateY(-50%) translateX(${GTR.XS});
  right: ${GTR.S};
  opacity: 0;
  transition: opacity 0.2s ease-in-out, transform 0.2s ease-in-out;
  transition-delay: 0.1s;
  cursor: pointer;
  padding: ${GTR.XS};

  &:not(:disabled):focus,
  &:not(:disabled):active {
    outline: none;
    box-shadow: none;
  }

  g {
    fill: ${COLOR.GREY.MEDIUM};
  }

  &:hover {
    g {
      fill: ${COLOR.BLACK};
    }
  }
`

const StyledCopyToClipboardIcon = styled(CopyToClipboardIcon)`
  transition: transform 0.2s ease-in-out;

  &:active {
    transform: translateY(-3px);
  }
`

const StyledWrapper = styled(Wrapper)`
  position: relative;

  &:hover button {
    opacity: 1;
    transform: translateY(-50%) translateX(0);
  }
`

const CopyToClipboard = ({
  children,
  allowCopyToClipboard,
  name,
  label,
}: PropsWithChildren<{
  allowCopyToClipboard?: boolean
  name: string
  label?: ReactNode
}>): ReactElement | null => {
  const [field] = useField(name)
  const handleCopyValueToClipboard = (e: MouseEvent) => {
    e.preventDefault()

    try {
      navigator.clipboard.writeText(field.value)
      toast(`${label || 'Field value'} copied to clipboard.`, {
        toastId: 'copied-to-clipboard',
      })
    } catch (err) {
      toast('Failed to copy to clipboard.', {
        toastId: 'failed-to-copy-to-clipboard',
      })
    }
  }

  if (allowCopyToClipboard) {
    return (
      <StyledWrapper>
        {children}

        {field.value?.length > 0 && (
          <StyledButton onClick={handleCopyValueToClipboard} tabindex="-1">
            <StyledCopyToClipboardIcon
              height="22px"
              viewBox="0 0 21 22"
              width="21px"
            />
          </StyledButton>
        )}
      </StyledWrapper>
    )
  }

  return <>{children}</>
}

export default CopyToClipboard
