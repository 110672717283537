import styled from 'styled-components'
import { Button, P, Wrapper } from '@farewill/ui'

import store from 'state/create-store'
import { hideModal } from 'state/actions'

const StyledWrapper = styled(Wrapper)`
  display: flex;
  justify-content: space-between;
`

type FindATimeModalConfig = {
  config: {
    confirmCallback: () => void
  }
}

const FindATimeModal = ({ config }: FindATimeModalConfig) => {
  const handleContinueClick = async () => {
    await config.confirmCallback()
    store.dispatch(hideModal())
  }

  return (
    <Wrapper>
      <P>Click "Continue" after booking a fact find call.</P>
      <StyledWrapper>
        <Button.Primary type="button" onClick={handleContinueClick}>
          Continue
        </Button.Primary>
      </StyledWrapper>
    </Wrapper>
  )
}

export default FindATimeModal
