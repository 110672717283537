import PropTypes from 'prop-types'
import styled from 'styled-components'
import { COLOR, FONT, GTR } from '@farewill/ui/tokens'
import Tippy from '@tippy.js/react'

const DIMENSIONS = { S: '28px', M: '36px', L: '48px' }
const FONT_SIZES = { S: FONT.SIZE.XXS, M: FONT.SIZE.M, L: FONT.SIZE.L }
const MARGIN_WIDTHS = { S: 0, M: GTR.S, L: GTR.M }

const StyledTooltip = styled(Tippy)`
  padding: 2px ${GTR.XXS};
  font-size: ${FONT.SIZE.XS};
`

const StyledImage = styled.img`
  display: inline-block;
  width: ${({ $size }) => DIMENSIONS[$size]};
  height: ${({ $size }) => DIMENSIONS[$size]};
  flex-shrink: 0;
  object-fit: cover;
  border-radius: 50%;
  margin-right: ${({ $size }) => MARGIN_WIDTHS[$size]};
`

const StyledInitials = styled.figure`
  width: ${({ $size }) => DIMENSIONS[$size]};
  height: ${({ $size }) => DIMENSIONS[$size]};
  flex-shrink: 0;
  border-radius: 50%;
  margin: 0;
  margin-right: ${({ $size }) => MARGIN_WIDTHS[$size]};
  background-color: ${COLOR.ACCENT.SECONDARY};
  color: ${COLOR.WHITE};
  font-size: ${({ $size }) => FONT_SIZES[$size]};
  line-height: 40px;
  font-weight: ${FONT.WEIGHT.BOLD};
  display: inline-flex;
  justify-content: center;
  align-items: center;
`
const getInitials = (name) =>
  name
    .split(' ')
    .map((word) => word[0])
    .join('')

const AdminIcon = ({
  className,
  user,
  enableTooltip,
  size,
  tooltipContent,
}) => {
  if (!user) return null

  const icon = user.picture ? (
    <StyledImage
      src={user.picture}
      $size={size}
      alt={user.name}
      className={className}
    />
  ) : (
    <StyledInitials $size={size} className={className}>
      {getInitials(user.name)}
    </StyledInitials>
  )

  return enableTooltip ? (
    <StyledTooltip
      animation="fade"
      arrow={false}
      duration={150}
      distance={parseInt(GTR.XS)}
      appendTo={document.body}
      placement="top"
      content={tooltipContent ?? user.name}
    >
      {icon}
    </StyledTooltip>
  ) : (
    icon
  )
}

AdminIcon.propTypes = {
  className: PropTypes.string,
  enableTooltip: PropTypes.bool,
  size: PropTypes.oneOf(['S', 'M', 'L']),
  tooltipContent: PropTypes.string,
  user: PropTypes.object,
}

AdminIcon.defaultProps = {
  className: '',
  enableTooltip: false,
  size: 'M',
  tooltipContent: null,
  user: null,
}

export default AdminIcon
