import { useState, useEffect } from 'react'

import ENV from 'config/environment'

import { FORM_1_DATE_OF_DEATH_CUTOFF } from './constants'

export const use2024Form1 = ({
  dateOfDeath,
}: {
  dateOfDeath?: string | Date
}) => {
  const [use2024Form1, setUse2024Form] = useState(undefined)

  useEffect(() => {
    if (!dateOfDeath) {
      return
    }

    setUse2024Form(
      ENV.FF_CREMATION_FORM_1_2024_ENABLED &&
        new Date(dateOfDeath).getTime() >=
          new Date(FORM_1_DATE_OF_DEATH_CUTOFF).getTime()
    )
  }, [dateOfDeath])

  return use2024Form1
}
