import get from 'lodash/get'

export default function errors(state = [], action = {}) {
  const REQUEST_PENDING = action.type.includes('_PENDING')
  const REQUEST_REJECTED = action.type.includes('_REJECTED')

  switch (action.type) {
    case 'ADD_ERROR':
      return [action.payload, ...state]

    default:
      break
  }

  switch (true) {
    case REQUEST_PENDING:
      return []

    case REQUEST_REJECTED: {
      // These are handled explicitly in the relevant route.
      // TODO: a nicer way of doing this?!
      if (
        action.type === 'CREATE_LEAD_IMPORT_REJECTED' &&
        action.payload?.response?.status === 422
      ) {
        return [
          'There are errors with this import, so no leads have been created. ' +
            ' Correct the errors, then try again.',
          ...state,
        ]
      }

      if (
        action.type === 'UPDATE_FUNERAL_PLAN_REJECTED' &&
        action.payload?.response?.status === 422
      ) {
        return [...state]
      }

      const errors = get(action, 'payload.response.data.errors', [
        'Something went wrong. Please try refreshing.',
      ])

      return errors
    }

    default:
      return state
  }
}
