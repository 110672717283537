import { Component } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Wrapper, Grid, P } from '@farewill/ui'
import { COLOR, FONT, GTR } from '@farewill/ui/tokens'
import { Formik } from 'formik'
import get from 'lodash/get'
import has from 'lodash/has'
import { Link } from 'react-router-dom'
import moment from 'moment'
import CallBox from 'components/styled/call-box'
import LiveIndicator from 'components/live-indicator'
import Suggestions from '../containers/suggestions'
import NewContactForm from './form'

import {
  LEAD_SOURCE_IDENTIFIER_OPTIONS,
  LEAD_PARTNER_TYPES,
  LEAD_SOURCE_TYPES,
} from 'utils/enums'
import { getAircallContactName } from 'utils/helpers'

const validate = (values) => {
  let errors = {}
  const {
    interestedInProducts,
    sourceType,
    sourceIdentifier,
    partnerType,
    partnerId,
  } = values

  if (get(interestedInProducts, 'length', 0) === 0) {
    errors.interestedInProducts = 'Please select at least one product.'
  }

  if (!sourceType) {
    errors.sourceType = 'Please select a source type.'
  }

  if (has(LEAD_SOURCE_IDENTIFIER_OPTIONS, sourceType) && !sourceIdentifier) {
    errors.sourceIdentifier = 'Please select a source identifier.'
  }

  if (
    sourceType === LEAD_SOURCE_TYPES.external_referral &&
    partnerType === LEAD_PARTNER_TYPES.none
  ) {
    errors.partnerType = 'Please select the partner that referred this lead.'
  }

  if (partnerType !== LEAD_PARTNER_TYPES.none && !partnerId) {
    errors.partnerId = 'Please select the name of the partner'
  }

  return errors
}

const StyledBackLink = styled(Link)`
  color: ${COLOR.GREY.MEDIUM};
  font-size: ${FONT.SIZE.S};
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
`

class NewContact extends Component {
  componentWillUnmount = () => {
    this.props.unsetCall()
  }

  render() {
    const { call, handleSubmit } = this.props
    const callSelected = call.id
    const isLive = !call.ended_at

    const initialValues = {
      interestedInProducts: [],
      sourceType: 'inbound_call',
      sourceIdentifier: '',
      referredBy: '',
      scheduledNextCallAt: '',
      notes: '',
      partnerType: 'none',
    }

    if (call.number) {
      const product = call.number.name.split(' ')[0].toLowerCase()

      const lineNameToProductName = {
        wills: 'will',
        probate: 'probate',
        funeral: 'funeral',
      }

      if (Object.keys(lineNameToProductName).includes(product)) {
        initialValues.interestedInProducts.push(lineNameToProductName[product])
      }
    }

    return (
      <Grid>
        <Grid.Item span={8} padding={[GTR.L, 0, 0]}>
          {callSelected && (
            <Wrapper separator>
              <StyledBackLink to="/calls">
                ← Back to inbound calls
              </StyledBackLink>
            </Wrapper>
          )}
          {callSelected && (
            <Wrapper separator>
              <CallBox live={isLive}>
                <P>
                  {isLive ? (
                    <LiveIndicator>Live call </LiveIndicator>
                  ) : (
                    moment(new Date(call.started_at * 1000)).format(
                      'ddd, HH[:]mm'
                    )
                  )}{' '}
                  &bull; {call.number.name} &bull;{' '}
                  {getAircallContactName(call.contact) || 'Unknown'} &bull;{' '}
                  {call.user?.name}
                </P>
              </CallBox>
            </Wrapper>
          )}
          <Wrapper separator>
            <Formik
              onSubmit={handleSubmit}
              initialValues={initialValues}
              validate={validate}
            >
              <NewContactForm call={call} />
            </Formik>
          </Wrapper>
        </Grid.Item>
        <Grid.Item span={4}>
          <Suggestions />
        </Grid.Item>
      </Grid>
    )
  }
}

NewContact.propTypes = {
  call: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  unsetCall: PropTypes.func.isRequired,
}

export default NewContact
