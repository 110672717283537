import Dinero from 'dinero.js'
import moment from 'moment'

import { formatCurrency } from 'lib/formatting/currency'
import { Invoice, invoiceTotalPrice } from 'lib/models/invoice'

import { FormInitialValues } from '../payment-modal'

Dinero.globalLocale = 'en-GB'
Dinero.defaultCurrency = 'GBP'

export const formatAmountToPence = (amount: number) =>
  Dinero({ amount: Math.round(amount * 100) }).getAmount()

const formatAmountToPounds = (amount: number) => Dinero({ amount }).toUnit()

type PaymentType = 'card' | 'invoice'

export const getInitialValuesFromInvoice = ({
  type,
  invoice,
}: {
  type: PaymentType
  invoice?: Invoice
}): Partial<FormInitialValues> | void => {
  if (invoice == null && type === 'card') {
    return {
      amount: '',
      reference: '',
      date: moment(new Date()).format('YYYY-MM-DD'),
    }
  } else if (invoice == null && type === 'invoice') {
    return {
      amount: '',
      reference: '',
      externalReferenceId: '',
      date: moment(new Date()).format('YYYY-MM-DD'),
    }
  }

  if (invoice == null) {
    throw new Error(`Invoice type is not specified`)
  }

  const lineItems = invoice.attributes.lineItems
  const amount = lineItems.reduce((total, item) => total + item.price, 0)
  const date = moment(invoice?.attributes.issuedDate, true)

  return {
    amount: formatAmountToPounds(amount),
    date: date.isValid() ? date.format('YYYY-MM-DD') : null,
    reference: invoice?.attributes.reference ?? '',
    externalReferenceId: invoice?.attributes.externalReferenceId ?? '',
    paidBy: invoice?.attributes.paidBy,
    isPaid: invoice?.attributes.paidOn ? 'yes' : 'no',
  }
}

const getTotalInvoicesAmountInPence = (invoices: Invoice[]): number => {
  const totalPaidInvoices = invoices.filter(
    (invoice) => invoice.attributes?.paidOn !== null
  )
  return totalPaidInvoices.reduce(
    (acc, invoice) => acc + invoiceTotalPrice(invoice),
    0
  )
}

export const getTotalInvoicesAmountInPounds = (invoices: Invoice[]): string => {
  const totalInvoicesAmountInPence = getTotalInvoicesAmountInPence(invoices)
  return formatCurrency({
    value: totalInvoicesAmountInPence,
    valueInPence: true,
  })
}
