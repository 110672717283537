import { useContext, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Grid } from '@farewill/ui'

import namespace from 'lib/formik/namespace'
import useApi from 'lib/effects/api'

import InputFloating from 'components/form/input-floating'
import withForm from '../../../components/with-form'

import { Context } from '../../../../context'
import { FULL_NAME, EXAMPLE_ASSET } from '../../field-names'

const Alias = ({ aliasId, probateEstateId }) => {
  const [{ isLoading, errors }, makeRequest] = useApi()
  const { setErrors, setIsLoading } = useContext(Context)

  useEffect(() => {
    setErrors(errors)
  }, [setErrors, errors])

  useEffect(() => {
    setIsLoading(isLoading)
  }, [setIsLoading, isLoading])

  const prefix = `aliases.${aliasId}.`
  const { withNamespace, withoutNamespace } = namespace(prefix)

  const handleSave = ({ name, value }) => {
    makeRequest({
      url: `/api/probate-estates/${probateEstateId}/aliases/${aliasId}`,
      method: 'PATCH',
      data: {
        data: {
          type: 'probate_estate_aliases',
          id: aliasId,
          attributes: { [withoutNamespace(name)]: value || null },
        },
      },
    })
  }

  return (
    <Grid>
      <Grid.Item span={6}>
        <InputFloating
          name={withNamespace(FULL_NAME)}
          label="Alias (full name)"
          handleSave={handleSave}
        />
      </Grid.Item>
      <Grid.Item span={6}>
        <InputFloating
          name={withNamespace(EXAMPLE_ASSET)}
          label="Example of asset in this name"
          handleSave={handleSave}
        />
      </Grid.Item>
    </Grid>
  )
}

Alias.propTypes = {
  aliasId: PropTypes.number.isRequired,
  probateEstateId: PropTypes.string.isRequired,
}

export default withForm(Alias)
