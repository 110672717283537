import axiosRequest from 'lib/axios/api-request'
import {
  FAREWILL_PRODUCTS,
  FUNERAL_CASE_PROBATE_CHECK_INTERESTED,
  LEAD_PARTNER_TYPES,
  LEAD_SOURCE_IDENTIFIERS,
  LEAD_SOURCE_TYPES,
  PROBATE_PROPERTY_OWNERSHIP_TYPE,
} from 'utils/enums'
import { FormikValues } from '../types'

export const allowProbateLeadBooking = (
  values: FormikValues
): boolean | undefined => {
  const {
    interestedInProbate,
    ownedProperty,
    propertyOwnershipType,
    aboveAccountOrShareThreshold,
    customerAlreadyKnowsProbateRequired,
  } = values.probateCheckAttributes

  /** Do not show any message if this question is 1. not answered, 2. is no, or 3. is urgent collection */
  if (
    !interestedInProbate ||
    (
      [
        FUNERAL_CASE_PROBATE_CHECK_INTERESTED.NO,
        FUNERAL_CASE_PROBATE_CHECK_INTERESTED.NOT_ASKED_URGENT_COLLECTION,
      ] as unknown as (typeof FUNERAL_CASE_PROBATE_CHECK_INTERESTED)[]
    ).includes(interestedInProbate)
  )
    return undefined

  /** Show callback booking if customer wants to talk later */
  if (
    (interestedInProbate as unknown as string) ===
    FUNERAL_CASE_PROBATE_CHECK_INTERESTED.LATER
  )
    return true

  /** From here, customer has said they are interested in probate */
  const solelyOwnedOrTenantsInCommonOrUnsure =
    ownedProperty === 'true' &&
    (
      [
        PROBATE_PROPERTY_OWNERSHIP_TYPE.SOLELY_OWNED,
        PROBATE_PROPERTY_OWNERSHIP_TYPE.TENANTS_IN_COMMON,
        PROBATE_PROPERTY_OWNERSHIP_TYPE.UNSURE,
      ] as unknown as (typeof PROBATE_PROPERTY_OWNERSHIP_TYPE)[]
    ).includes(propertyOwnershipType)

  /** Show callback booking if any of the below are true */
  if (
    solelyOwnedOrTenantsInCommonOrUnsure ||
    aboveAccountOrShareThreshold === 'true' ||
    customerAlreadyKnowsProbateRequired === 'true'
  )
    return true

  /** Do not show message if there are unanswered questions */
  if (
    !ownedProperty ||
    (ownedProperty === 'true' && !propertyOwnershipType) ||
    !aboveAccountOrShareThreshold ||
    !customerAlreadyKnowsProbateRequired
  )
    return undefined

  /** Answers indicate that customer does not require probate */
  return false
}

export const createProbateLead = async ({
  funeralId,
  values,
}: {
  funeralId: number
  values: FormikValues
}): Promise<{ probateLeadId: number }> => {
  const {
    contactId,
    firstNames,
    lastName,
    knownAs,
    maritalStatus,
    dateOfDeath,
    relationshipToCustomer,
  } = values

  const {
    ownedProperty,
    propertyOwnershipType,
    aboveAccountOrShareThreshold,
    customerAlreadyKnowsProbateRequired,
    scheduledNextCallAt,
    notes,
  } = values.probateCheckAttributes

  const data = {
    data: {
      type: 'leads',
      attributes: {
        contactId,
        product: FAREWILL_PRODUCTS.PROBATE,
        sourceType: LEAD_SOURCE_TYPES.internal_referral,
        sourceIdentifier: LEAD_SOURCE_IDENTIFIERS.funeral_cross_sell,
        partnerType: LEAD_PARTNER_TYPES.none,
        scheduledNextCallAt,
        scheduledNextCallAtTimeSet: !!scheduledNextCallAt,
        quoteAttributes: {
          funeralId,
          hasMadeFuneralArrangements: 'true',
          notes,
          // probate information
          ownedProperty,
          propertyOwnershipType,
          aboveAccountOrShareThreshold,
          customerAlreadyKnowsProbateRequired,
          // deceased person's non-probate information
          deceasedFirstName: firstNames,
          deceasedLastName: lastName,
          customerRefersToThemAs: knownAs,
          ...(maritalStatus !== '' && {
            maritalStatus,
          }),
          ...(dateOfDeath && { dateOfDeath }),
          ...(relationshipToCustomer !== '' && {
            relationshipToCustomer,
          }),
        },
      },
    },
  }

  const apiResponse = await axiosRequest({
    url: '/api/leads',
    method: 'POST',
    data,
  })

  return Promise.resolve({ probateLeadId: apiResponse.data.data.id })
}
