import { Grid, H, P } from '@farewill/ui'
import { useParams } from 'react-router-dom'
import { useQueryClient } from 'react-query'

import ContactBirthDate from 'components/contact-birth-date'
import CustomerNames from 'components/customer-names'
import Address from 'components/address'
import InputFloating from 'components/form/input-floating'
import SelectInput from 'components/form/select-input'
import ContactEmailInput from 'components/form/contact-email-input'
import { Will } from 'lib/models/will'
import { WillCase } from 'lib/models/will-case'
import { RESOURCES } from 'api'
import ENV from 'config/environment'

import { UpdateResourceFn } from './types'
import { useWillFactFindContext } from './context'
import { WILL_RELATIONSHIP_STATUS_OPTIONS } from 'utils/enums'

type Props = {
  updateWill: UpdateResourceFn
  updateContact: UpdateResourceFn
}

const DemographicData = ({
  updateWill,
  updateContact,
}: Props): React.ReactElement => {
  const cache = useQueryClient()
  const { willId } = useParams<{ willId: string }>()
  const will = cache.getQueryData<Will>([RESOURCES.WILLS, Number(willId)])
  const willCase = cache.getQueryData<WillCase>([
    RESOURCES.WILL_CASES,
    will?.attributes.willCaseId,
  ])
  const { highlightEmpty } = useWillFactFindContext()

  if (!will || !willCase) return <P>Loading...</P>

  const { contact, homeAddress } = will.attributes
  const hasWillSuiteCase =
    ENV.FF_WILLSUITE_ENABLED && !!willCase.attributes.willSuiteUuid

  return (
    <>
      <H size="M">1. Demographic data</H>
      <Grid>
        <Grid.Item>
          <CustomerNames
            id={contact.id}
            firstName={contact.firstName}
            lastName={contact.lastName}
            fullLegalName={contact.fullLegalName}
            preferredName={contact.preferredName}
            pronouns={contact.pronouns}
            disabled={hasWillSuiteCase}
          />
        </Grid.Item>
        <Grid.Item>
          <ContactBirthDate
            contactId={contact.id}
            dateOfBirth={contact.dateOfBirth}
            highlightEmpty={highlightEmpty}
            disabled={hasWillSuiteCase}
          />
        </Grid.Item>
        <Grid.Item>
          <InputFloating
            label="Phone number"
            name="phoneNumber"
            type="text"
            handleSave={updateContact}
            disabled={hasWillSuiteCase}
          />
        </Grid.Item>
        <Grid.Item>
          <ContactEmailInput
            contactId={contact.id}
            handleSave={updateContact}
            hasRelatedAccount={!!contact.accountUuid}
            disabled={hasWillSuiteCase}
          />
        </Grid.Item>
        <Grid.Item>
          <Address
            label="Address"
            name="address"
            data={homeAddress}
            newAddressAttrs={{
              relatedId: will.id,
              relatedResource: 'will',
              relation: 'homeAddress',
            }}
            disabled={hasWillSuiteCase}
          />
        </Grid.Item>
        <Grid.Item span={8}>
          <SelectInput
            name="maritalStatus"
            label="Marital status"
            options={WILL_RELATIONSHIP_STATUS_OPTIONS}
            handleSave={updateWill}
            floating
            isClearable
            disabled={hasWillSuiteCase}
          />
        </Grid.Item>
        <Grid.Item span={4}>
          <InputFloating
            name="numberOfChildren"
            label="Number of children"
            type="number"
            component="input"
            handleSave={updateWill}
          />
        </Grid.Item>
      </Grid>
    </>
  )
}

export default DemographicData
