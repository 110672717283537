import get from 'lodash/get'

export default function messages(state = [], action = {}) {
  const REQUEST_PENDING = action.type.includes('_PENDING')
  const REQUEST_FULFILLED = action.type.includes('_FULFILLED')

  switch (action.type) {
    case 'ADD_MESSAGE':
      return [action.payload, ...state]

    default:
      break
  }

  switch (true) {
    case REQUEST_PENDING:
      return []

    case REQUEST_FULFILLED: {
      const messages = get(action, 'payload.data.meta.messages', [])
      return [...messages, ...state]
    }

    default:
      return state
  }
}
