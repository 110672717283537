import { P, Wrapper } from '@farewill/ui'
import { getWidthOfContainerColumns } from '@farewill/ui/helpers/layout'
import { COLOR } from '@farewill/ui/tokens'

import { useFetchResource, RESOURCES } from 'api'
import MessageBox from 'components/message-box'
import StatusBadge from 'components/status-badge'
import ENV from 'config/environment'
import { WILLSUITE_STATUS } from 'lib/models/willsuite-event'
import { WillCase, WillCaseAttributes } from 'lib/models/will-case'
import { formatWillSuiteStatus } from 'utils/helpers'
import { sortLeadAndPartnerWills } from './quote/editable-quote/methods'

type WillNamesAndStatuses = {
  leadWill: { name: string; status: string }
  partnerWill?: { name: string; status: string }
}

const getWillNamesAndStatuses = (
  willCase: WillCaseAttributes
): WillNamesAndStatuses => {
  const { leadWill, partnerWill } = sortLeadAndPartnerWills(willCase)

  const leadWillName = leadWill?.contact?.firstName as string
  /** The BE sends us the willSuiteEvents array with the newest row first.
   * In event that no willSuiteEvents exist, default to 'awaiting_fact_find'.
   */

  const leadWillStatus =
    leadWill?.willSuiteEvents[0]?.status || WILLSUITE_STATUS.AWAITING_FACT_FIND

  let result: WillNamesAndStatuses = {
    leadWill: {
      name: leadWillName,
      status: formatWillSuiteStatus(leadWillStatus),
    },
  }

  if (partnerWill) {
    const partnerWillName = partnerWill.contact?.firstName as string
    const partnerWillStatus =
      partnerWill.willSuiteEvents[0]?.status ||
      WILLSUITE_STATUS.AWAITING_FACT_FIND
    result = {
      ...result,
      partnerWill: {
        name: partnerWillName,
        status: formatWillSuiteStatus(partnerWillStatus),
      },
    }
  }

  return result
}

type Props = {
  willCaseId: number
}

const TelephoneWillStatusBanner = ({
  willCaseId,
}: Props): React.ReactElement | null => {
  const willCaseQuery = useFetchResource<WillCase>({
    id: willCaseId,
    resource: RESOURCES.WILL_CASES,
  })

  const willCase = willCaseQuery.data?.attributes as WillCaseAttributes
  const { willSuiteUuid } = willCase

  /** If there is no WillSuite UUID, do not show the banner at all. */
  if (!willSuiteUuid) return null

  const { leadWill, partnerWill } = getWillNamesAndStatuses(willCase)

  /**
   * 1. A will case can have 1 (single) or 2 (couple) wills attached.
   * 2. For a WillSuite case to be closed, all wills attached to the will case need their latest WillSuiteEvent to have status 'complete'.
   */
  const willSuiteCaseClosed = partnerWill
    ? leadWill.status === WILLSUITE_STATUS.COMPLETE &&
      partnerWill.status === WILLSUITE_STATUS.COMPLETE
    : leadWill.status === WILLSUITE_STATUS.COMPLETE

  return (
    <Wrapper maxWidth={getWidthOfContainerColumns(7, 'L')} margin={[0, 0, 'M']}>
      <MessageBox warning hideIcon>
        <P color={COLOR.BLACK}>
          The details of this case are in WillSuite, and cannot be edited here.
          <br />
          <P
            tag="a"
            href={`${ENV.WILLSUITE_PLATFORM_URL}/${willSuiteUuid}`}
            target="_blank"
            rel="noopener noreferrer"
            strong
          >
            View or update this case in WillSuite
          </P>
        </P>
        <P>
          {leadWill.name}’s will: <StatusBadge status={leadWill.status} />
        </P>
        {partnerWill && (
          <P>
            {partnerWill.name}’s will:{' '}
            <StatusBadge status={partnerWill.status} />
          </P>
        )}

        {willSuiteCaseClosed && (
          <P size="S">
            This case is marked as closed in WillSuite. You can still change
            this will by clicking on the WillSuite link above, and updating the
            case’s status.
          </P>
        )}
      </MessageBox>
    </Wrapper>
  )
}

export default TelephoneWillStatusBanner
