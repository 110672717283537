import { useEffect } from 'react'
import { H, Button, P, Wrapper } from '@farewill/ui'
import { GTR } from '@farewill/ui/tokens'
import { useDispatch } from 'react-redux'

import { showModal } from 'state/actions'

import RequestStatus from 'components/request-status'
import Modal from './modal'
import Error from './error'
import { ErrorsProvider, useErrorsContext } from './context'

const Errors = () => {
  const dispatch = useDispatch()

  const {
    errors,
    fetchErrors,
    isFetchingErrors,
    createError,
    isCreatingError,
    createErrors,
    isUpdatingError,
    updateErrors,
    deleteError,
  } = useErrorsContext()

  useEffect(() => {
    fetchErrors()
  }, [fetchErrors])

  const handleDeleteClick = (errorId) => {
    dispatch(
      showModal({
        component: Modal,
        headingText: 'Delete error',
        config: { deleteError: () => deleteError(errorId), errorId },
      })
    )
  }

  if (isFetchingErrors) {
    return (
      <P size="L">
        Loading <strong>errors</strong>...
      </P>
    )
  }

  return (
    <>
      <H size="L">Errors</H>
      <Wrapper margin={errors.length ? [0, 0, 'M'] : 0}>
        {errors.map((error, index) => (
          <Error
            error={error}
            handleDeleteClick={handleDeleteClick}
            key={error.id}
            listLength={errors.length}
            listPosition={index + 1}
          />
        ))}
      </Wrapper>
      <Button.Secondary
        style={{ paddingTop: GTR.XXS, paddingBottom: GTR.XXS }}
        loading={isCreatingError}
        type="button"
        onClick={() => createError({})}
      >
        Add error
      </Button.Secondary>
      <RequestStatus
        isSaving={isCreatingError || isUpdatingError}
        apiErrors={[...createErrors, ...updateErrors]}
      />
    </>
  )
}

const ErrorsWithProvider = ({ match }) => (
  <ErrorsProvider probateCaseId={match.params.id}>
    <Errors />
  </ErrorsProvider>
)

export default ErrorsWithProvider
