import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Field, useField } from 'formik'
import { Wrapper } from '@farewill/ui'
import { COLOR, GTR, BORDER, FONT } from '@farewill/ui/tokens'

import Label from 'components/styled/label'
import Error from 'components/form/error'

const StyledWrapper = styled(Wrapper)`
  display: flex;
`

const StyledField = styled(Field)`
  position: absolute;
  inset: 0;
  opacity: 0;
  z-index: 1;

  &:checked {
    + label {
      border-color: ${COLOR.ACCENT.SECONDARY};
      background-color: ${COLOR.ACCENT.SECONDARY};
      color: ${COLOR.WHITE};
    }
  }

  &:focus {
    + label {
      box-shadow: 0 0 0 4px ${COLOR.GREY.LIGHT};
    }
  }

  &:focus:checked {
    + label {
      box-shadow: 0 0 0 4px ${COLOR.STATE.HIGHLIGHT};
    }
  }
`

const StyledCheckbox = styled.div`
  width: auto;
  position: relative;

  &:not(:last-of-type) {
    margin-right: ${GTR.M};
  }
`

const StyledLabel = styled.label`
  position: relative;
  font-size: ${FONT.SIZE.M};
  font-weight: ${FONT.WEIGHT.MEDIUM};
  cursor: pointer;
  padding: 15px ${GTR.XL};
  border-radius: ${BORDER.RADIUS.S};
  border: 2px solid ${COLOR.GREY.LIGHT};
  outline: 0;
  color: ${COLOR.BLACK};
  min-width: 100px;
  text-align: center;
  margin-top: ${GTR.S};
`

const CheckboxGroup = ({ label, name, options }) => {
  const [, meta] = useField(name)
  return (
    <>
      {label && <Label $error={meta.error && meta.touched}>{label}</Label>}
      <StyledWrapper>
        {options.map(({ value, label }) => (
          <StyledCheckbox key={value}>
            <StyledField
              name={name}
              id={name + '-' + value}
              type="checkbox"
              value={value}
            />
            <StyledLabel htmlFor={name + '-' + value}>{label}</StyledLabel>
          </StyledCheckbox>
        ))}
      </StyledWrapper>
      <Error name={name} />
    </>
  )
}

CheckboxGroup.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    })
  ),
}

CheckboxGroup.defaultProps = {
  label: '',
}

export default CheckboxGroup
