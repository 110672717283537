const FETCH_CONTACT_EVENTS = 'FETCH_CONTACT_EVENTS'

export default function contactEvents(state = [], action = {}) {
  switch (action.type) {
    case FETCH_CONTACT_EVENTS + '_FULFILLED': {
      return action.payload.data.data
    }

    case FETCH_CONTACT_EVENTS + '_REJECTED':
      return []

    default:
      return state
  }
}
