import { useEffect } from 'react'
import { Divider, Grid, H } from '@farewill/ui'

import withForm from '../fact-find/components/with-form'
import CircularRadioGroup from 'components/form/circular-radio-group'
import Input from 'components/form/input'

import { APPLICATION_TYPE_OPTIONS } from '../../../utils/enums'
import { findCaseBookedInvoice } from 'utils/helpers'
import useApi from 'lib/effects/api'
import RegistryFees from './registry-fees'

const ApplicationSubmitted = ({
  saveProbateCaseField,
  probateCaseId,
  probateEstateId,
}) => {
  const [{ data: invoices }, fetchInvoices] = useApi({ data: [] })

  const invoice = findCaseBookedInvoice(invoices)

  useEffect(() => {
    fetchInvoices({
      url: `/api/invoices?filter[probateCaseId]=${probateCaseId}`,
    })
  }, [fetchInvoices, probateCaseId])

  return (
    <Grid margin={['XL', 0]}>
      <Grid.Item margin={[0, 0, 'M']}>
        <H size="S">Application submitted to registry</H>
      </Grid.Item>
      {invoice && (
        <>
          <Grid.Item>
            <RegistryFees invoice={invoice} probateEstateId={probateEstateId} />
          </Grid.Item>
          <Grid.Item margin={['M', 0]}>
            <Divider />
          </Grid.Item>
        </>
      )}
      <Grid.Item>
        <CircularRadioGroup
          name="applicationTypeSubmitted"
          label="Type of probate application submitted"
          options={APPLICATION_TYPE_OPTIONS}
          handleSave={saveProbateCaseField}
          inline
        />
      </Grid.Item>
      <Grid.Item span={6}>
        <Input
          label="Application reference number"
          name="applicationReferenceNumber"
          handleSave={saveProbateCaseField}
        />
      </Grid.Item>
    </Grid>
  )
}

export default withForm(ApplicationSubmitted)
