import { createContext, useContext } from 'react'

import useApiHelpers from 'lib/effects/api-helpers'

const TasksContext = createContext()
const TaskEventsContext = createContext()

const useTasksContext = () => {
  const context = useContext(TasksContext)
  if (context === undefined) {
    throw new Error('useTasksContext must be used within an TasksProvider')
  }
  return context
}

const TasksProvider = ({ children, product }) => {
  const {
    items: tasks,
    fetchItems: fetchTasks,
    isFetching: isFetchingTasks,
    updateItem: updateTask,
    isUpdating: isUpdatingTask,
    createItem: createTask,
    isCreating: isCreatingTask,
    actionItem: actionTask,
    deleteItem: deleteTask,
  } = useApiHelpers({
    baseUrl: `/api/tasks`,
    type: 'tasks',
  })

  const value = {
    tasks,
    fetchTasks,
    isFetchingTasks,
    updateTask,
    isUpdatingTask,
    createTask,
    isCreatingTask,
    actionTask,
    deleteTask,
    product,
  }

  return <TasksContext.Provider value={value}>{children}</TasksContext.Provider>
}

const useTaskEventsContext = () => {
  const context = useContext(TaskEventsContext)
  if (context === undefined) {
    throw new Error(
      'useTaskEventsContext must be used within an TaskEventsProvider'
    )
  }
  return context
}

const TaskEventsProvider = ({ children }) => {
  const {
    items: taskEvents,
    fetchItems: fetchTaskEvents,
    isFetching: isFetchingTaskEvents,
  } = useApiHelpers({
    baseUrl: `/api/task-events`,
    type: 'task_events',
  })

  const value = {
    taskEvents,
    fetchTaskEvents,
    isFetchingTaskEvents,
  }

  return (
    <TaskEventsContext.Provider value={value}>
      {children}
    </TaskEventsContext.Provider>
  )
}

export {
  TasksProvider,
  useTasksContext,
  TaskEventsProvider,
  useTaskEventsContext,
}
