import axios from 'axios'
import get from 'lodash/get'

export const removeToken = () => ({
  type: 'REMOVE_TOKEN',
})

export const setCall = (call) => ({
  type: 'SET_CALL',
  payload: call,
})

export const showModal = (data) => ({
  type: 'SHOW_MODAL',
  payload: data,
})

export const hideModal = (data) => ({
  type: 'HIDE_MODAL',
  payload: data,
})

export const addMessage = (message) => ({
  type: 'ADD_MESSAGE',
  payload: message,
})

export const addError = (error) => ({
  type: 'ADD_ERROR',
  payload: error,
})

export const addGlobalError = (error) => ({
  type: 'ADD_ERROR_GLOBAL',
  payload: error,
})

export const unsetContact = () => ({
  type: 'UNSET_CONTACT',
})

export const unsetLead = () => ({
  type: 'UNSET_LEAD',
})

export const unsetProduct = (productType) => ({
  type: 'UNSET_PRODUCT',
  payload: productType,
})

export const fetchProbateCaseEvents = (id) => {
  return (dispatch) => {
    return dispatch({
      type: 'FETCH_PROBATE_CASE_EVENTS',
      payload: dispatch(
        axiosRequest({
          url: '/api/events?probateCaseId=' + id,
        })
      ),
    })
  }
}

export const createEvent = (attributes) => {
  return (dispatch) => {
    return dispatch({
      type: 'CREATE_EVENT',
      payload: dispatch(
        axiosRequest({
          url: '/api/events',
          method: 'POST',
          data: {
            data: {
              type: 'events',
              attributes,
            },
          },
        })
      ),
    })
  }
}

export const updateEvent = (id, attributes) => {
  return (dispatch) => {
    return dispatch({
      type: 'UPDATE_EVENT',
      payload: dispatch(
        axiosRequest({
          url: '/api/events/' + id,
          method: 'PATCH',
          data: {
            data: {
              type: 'events',
              id,
              attributes,
            },
          },
        })
      ),
    })
  }
}

export const deleteEvent = (id) => {
  return (dispatch) => {
    return dispatch({
      type: 'DELETE_EVENT',
      meta: { id },
      payload: dispatch(
        axiosRequest({
          url: '/api/events/' + id,
          method: 'DELETE',
        })
      ),
    })
  }
}

export function fetchLatestCalls() {
  return (dispatch) => {
    return dispatch({
      type: 'FETCH_LATEST_CALLS',
      payload: dispatch(
        axiosRequest({
          url: '/api/aircall/get-latest-calls',
        })
      ),
    })
  }
}

export function fetchAircallEventsForCustomer(params) {
  const query = new URLSearchParams(params).toString()
  return (dispatch) => {
    return dispatch({
      type: 'FETCH_AIRCALL_EVENTS_FOR_CUSTOMER',
      payload: dispatch(
        axiosRequest({
          url: '/api/aircall/get-aircall-events-for-customer?' + query,
        })
      ),
    })
  }
}

export function login(attributes) {
  return (dispatch) => {
    return dispatch({
      type: 'LOGIN',
      payload: dispatch(
        axiosRequest({
          url: '/api/access-tokens',
          method: 'POST',
          sendToken: false,
          data: {
            data: {
              type: 'access_tokens',
              attributes,
            },
          },
        })
      ),
    })
  }
}

export function fetchProbateCase(id) {
  return (dispatch) => {
    return dispatch({
      type: 'FETCH_PROBATE_CASE',
      payload: dispatch(
        axiosRequest({
          url: '/api/probate-cases/' + id,
        })
      ),
    })
  }
}

export function updateProbateCase(id, attributes) {
  return (dispatch) => {
    return dispatch({
      type: 'UPDATE_PROBATE_CASE',
      payload: dispatch(
        axiosRequest({
          url: '/api/probate-cases/' + id,
          method: 'PATCH',
          data: {
            data: {
              id,
              type: 'probate_cases',
              attributes,
            },
          },
        })
      ),
    })
  }
}

export function fetchContact(id) {
  return (dispatch) => {
    return dispatch({
      type: 'FETCH_CONTACT',
      payload: dispatch(
        axiosRequest({
          url: '/api/contacts/' + id,
        })
      ),
    })
  }
}

export function createContact(attributes) {
  return (dispatch) => {
    return dispatch({
      type: 'CREATE_CONTACT',
      payload: dispatch(
        axiosRequest({
          url: '/api/contacts',
          method: 'POST',
          data: { data: { type: 'contacts', attributes } },
        })
      ),
    })
  }
}

export function updateContact(id, attributes) {
  return (dispatch) => {
    return dispatch({
      type: 'UPDATE_CONTACT',
      payload: dispatch(
        axiosRequest({
          url: '/api/contacts/' + id,
          method: 'PATCH',
          data: {
            data: {
              id,
              type: 'contacts',
              attributes,
            },
          },
        })
      ),
    })
  }
}

export function fetchLead(id) {
  return (dispatch) => {
    return dispatch({
      type: 'FETCH_LEAD',
      payload: dispatch(
        axiosRequest({
          url: '/api/leads/' + id,
        })
      ),
    })
  }
}

export function fetchLeadEvents({ params }) {
  const query = new URLSearchParams(params).toString()
  return (dispatch) => {
    return dispatch({
      type: 'FETCH_LEAD_EVENTS',
      payload: dispatch(
        axiosRequest({
          url: '/api/lead-events?' + query,
        })
      ),
    })
  }
}

export function createLead(attributes) {
  return (dispatch) => {
    return dispatch({
      type: 'CREATE_LEAD',
      payload: dispatch(
        axiosRequest({
          url: '/api/leads',
          method: 'POST',
          data: {
            data: {
              type: 'leads',
              attributes,
            },
          },
        })
      ),
    })
  }
}

export function updateLead(id, attributes) {
  return (dispatch) => {
    return dispatch({
      type: 'UPDATE_LEAD',
      payload: dispatch(
        axiosRequest({
          url: '/api/leads/' + id,
          method: 'PATCH',
          data: {
            data: {
              id,
              type: 'leads',
              attributes,
            },
          },
        })
      ),
    })
  }
}

export function createLeadEvent(attributes) {
  return (dispatch) => {
    return dispatch({
      type: 'CREATE_LEAD_EVENT',
      payload: dispatch(
        axiosRequest({
          url: '/api/lead-events',
          method: 'POST',
          data: { data: { type: 'lead_events', attributes } },
        })
      ),
    })
  }
}

export function fetchFuneral(id) {
  return (dispatch) => {
    return dispatch({
      type: 'FETCH_FUNERAL',
      payload: dispatch(
        axiosRequest({
          url: '/api/funerals/' + id,
        })
      ),
    })
  }
}

export function updateFuneral(id, attributes) {
  return (dispatch) => {
    return dispatch({
      type: 'UPDATE_FUNERAL',
      payload: dispatch(
        axiosRequest({
          url: '/api/funerals/' + id,
          method: 'PATCH',
          data: {
            data: {
              id,
              type: 'funerals',
              attributes,
            },
          },
        })
      ),
    })
  }
}

export function fetchFuneralPlan(id) {
  return (dispatch) => {
    return dispatch({
      type: 'FETCH_FUNERAL_PLAN',
      payload: dispatch(
        axiosRequest({
          url: '/api/funeral-plans/' + id,
        })
      ),
    })
  }
}

export function fetchFuneralPlanPaymentDetails(id) {
  return (dispatch) => {
    return dispatch({
      type: 'FETCH_FUNERAL_PLAN_PAYMENT_DETAILS',
      payload: dispatch(
        axiosRequest({
          url: `/api/funeral-plans/${id}/payment-details`,
        })
      ),
    })
  }
}

export function cancelFuneralPlan(id) {
  return (dispatch) => {
    return dispatch({
      type: 'CANCEL_FUNERAL_PLAN',
      payload: dispatch(
        axiosRequest({
          url: `/api/funeral-plans/${id}/cancel`,
          method: 'POST',
          sendToken: true,
          data: {},
        })
      ),
    })
  }
}

export function fetchRedeemFuneralPlan(id) {
  return (dispatch) => {
    return dispatch({
      type: 'REDEEM_FUNERAL_PLAN',
      payload: dispatch(
        axiosRequest({
          url: `/api/funeral-plans/${id}/redeem`,
          method: 'POST',
          sendToken: true,
          data: {},
        })
      ),
    })
  }
}

export function updateFuneralPlan(id, attributes) {
  return async (dispatch) => {
    try {
      const response = await dispatch(
        axiosRequest({
          url: '/api/funeral-plans/' + id,
          method: 'PATCH',
          data: {
            data: {
              id,
              type: 'funeral_plans',
              attributes,
            },
          },
        })
      )

      dispatch({
        type: 'UPDATE_FUNERAL_PLAN_FULFILLED',
        payload: response,
      })
    } catch (error) {
      dispatch({
        type: 'UPDATE_FUNERAL_PLAN_REJECTED',
        payload: error,
      })
      error.response.data.errors.forEach((error) => {
        dispatch(addError(error))
      })
    }
  }
}

export function fetchFuneralPlanDocuments(id) {
  return (dispatch) => {
    return dispatch({
      type: 'FETCH_FUNERAL_PLAN_DOCUMENTS',
      payload: dispatch(
        axiosRequest({
          url: `/api/events?funeralPlanId=${id}&name=documents_generated`,
        })
      ),
    })
  }
}

export function generateFuneralPlanDocuments(id) {
  return (dispatch) => {
    return dispatch({
      type: 'GENERATE_FUNERAL_PLAN_DOCUMENTS',
      payload: dispatch(
        axiosRequest({
          url: `/api/funeral-plans/${id}/generate-documents`,
          method: 'POST',
          sendToken: true,
        })
      ),
    })
  }
}

export const fetchContactEvents = ({ params }) => {
  const query = new URLSearchParams(params).toString()

  return (dispatch) => {
    return dispatch({
      type: 'FETCH_CONTACT_EVENTS',
      payload: dispatch(
        axiosRequest({
          url: '/api/events?' + query,
        })
      ),
    })
  }
}

export const fetchFuneralEvents = (id) => {
  return (dispatch) => {
    return dispatch({
      type: 'FETCH_FUNERAL_EVENTS',
      payload: dispatch(
        axiosRequest({
          url: '/api/events?funeralId=' + id,
        })
      ),
    })
  }
}

export const fetchWillCaseEvents = (id) => {
  return (dispatch) => {
    return dispatch({
      type: 'FETCH_WILL_CASE_EVENTS',
      payload: dispatch(
        axiosRequest({
          url: '/api/events?willCaseId=' + id,
        })
      ),
    })
  }
}

export function fetchLpaCase(id) {
  return (dispatch) => {
    return dispatch({
      type: 'FETCH_LPA_CASE',
      payload: dispatch(
        axiosRequest({
          url: '/api/lpa-cases/' + id,
        })
      ),
    })
  }
}

export function updateLpaCase(id, attributes) {
  return (dispatch) => {
    return dispatch({
      type: 'UPDATE_LPA_CASE',
      payload: dispatch(
        axiosRequest({
          url: '/api/lpa-cases/' + id,
          method: 'PATCH',
          data: {
            data: {
              id,
              type: 'lpa_cases',
              attributes,
            },
          },
        })
      ),
    })
  }
}

export function createPaymentProviderCustomer(attributes) {
  return (dispatch) => {
    return dispatch({
      type: 'CREATE_PAYMENT_PROVIDER_CUSTOMER',
      payload: dispatch(
        axiosRequest({
          url: '/api/payment-provider-customers',
          method: 'POST',
          data: { data: { type: 'payment_provider_customers', attributes } },
        })
      ),
    })
  }
}

export const fetchWillPdfUrl = (key) => {
  return (dispatch) => {
    return dispatch({
      type: 'FETCH_WILL_PDF_URL',
      payload: dispatch(
        axiosRequest({
          url: `/api/will-pdfs/${key}`,
        })
      ),
    })
  }
}

export function fetchSearchResults(query) {
  let url = `/api/search?query=${encodeURI(query)}`
  return (dispatch) => {
    return dispatch({
      type: 'FETCH_SEARCH_RESULTS',
      payload: dispatch(axiosRequest({ url })),
    })
  }
}

export function axiosRequest({ url, method = 'GET', data, sendToken = true }) {
  return async (dispatch, getState) => {
    const { token } = getState()
    const headers = {}
    if (sendToken) headers.Authorization = `Bearer ${token}`

    return axios({ url, method, data, headers }).catch((error) => {
      const status = get(error, 'response.status')

      if (status === 401) {
        dispatch(removeToken())
        dispatch(addError({ detail: 'Please log in' }))
      }

      throw error
    })
  }
}

export function logout() {
  return (dispatch) => {
    return dispatch({
      type: 'LOGOUT',
      payload: dispatch(
        axiosRequest({
          url: '/api/access-tokens/self/actions/expire',
          method: 'POST',
        })
      ),
    })
  }
}
