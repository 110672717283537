import { Button, Grid, P } from '@farewill/ui'
import { GTR } from '@farewill/ui/tokens'

import store from 'state/create-store'
import { showModal } from 'state/actions'
import namespace from 'lib/formik/namespace'
import {
  PROBATE_ESTATE_ITEM_TYPES,
  ProbateEstateItem,
} from 'lib/models/probate-estate'
import EstateCard from 'components/estate-card'
import InputFloating from 'components/form/input-floating'
import CircularRadioGroup from 'components/form/circular-radio-group'
import NotesInput from 'components/notes-input'
import DeleteModal from 'routes/lead/probate-form/delete-modal'
import withForm from 'routes/lead/probate-form/with-form'
import { getItemsTotals } from 'routes/probate-estate/fact-find/get-totals'
import {
  PROBATE_LEAD_FORM_SECTIONS,
  PROBATE_LEAD_FORM_VALUES,
} from 'routes/lead/probate-form/constants'

import { HandleSaveProps, ItemProps } from './types'
import { useItems } from '../helpers/items-context'
import { useProbateEstate } from '../helpers/probate-estate-context'
import { scrollToDataId } from 'lib/window/scroll'
import styled from 'styled-components'
import SubsectionHeading from '../subsection-heading'

const StyledButton = styled.button`
  text-decoration: underline;
`

const Item = ({ item, index, listLength }: ItemProps) => {
  const {
    NOTES,
    VALUE,
    IS_LUMP_SUM_PENSION_PAYOUT,
    HAS_THE_POLICY_PAID_OUT,
    WHO_IS_PENSION_PAYABLE_TO,
  } = PROBATE_LEAD_FORM_VALUES
  const { withNamespace, withoutNamespace } = namespace(
    `privatePensions.${item.id}.`
  )
  const { updateItem, deleteItem } = useItems()
  const handleSave = ({ name, value }: HandleSaveProps) => {
    updateItem(
      {
        id: item.id,
        attributes: { [withoutNamespace(name)]: value || null },
      },
      PROBATE_ESTATE_ITEM_TYPES.PRIVATE_PENSION
    )
  }

  const onTrashClick = () => {
    store.dispatch(
      showModal({
        component: DeleteModal,
        headingText: 'Delete private pension',
        config: {
          deleteCallback: () => {
            deleteItem(item.id)
          },
          type: 'private pension',
          resourceId: item.id,
        },
      })
    )
  }

  return (
    <EstateCard
      onTrashClick={onTrashClick}
      listLength={listLength}
      listPosition={index + 1}
      key={item.id}
      type="private pension"
    >
      <Grid>
        <Grid.Item span={9}>
          <InputFloating
            name={withNamespace(PROBATE_LEAD_FORM_VALUES.DESCRIPTION)}
            label="Provider and description"
            handleSave={handleSave}
          />
        </Grid.Item>
        <Grid.Item span={6}>
          <InputFloating
            name={withNamespace(VALUE)}
            label="Total value (£)"
            hint="For joint assets, the £ value of their share will be calculated automatically."
            handleSave={handleSave}
            isCurrency
          />
        </Grid.Item>
        <Grid.Item span={12}>
          <CircularRadioGroup
            name={withNamespace(IS_LUMP_SUM_PENSION_PAYOUT)}
            label="Is this a lump sum payout"
            options={[
              { label: 'Not asked', value: '' },
              { label: 'Yes', value: 'true' },
              { label: 'No', value: 'false' },
            ]}
            handleSave={handleSave}
            inline
          />
        </Grid.Item>
        <Grid.Item span={12}>
          <CircularRadioGroup
            name={withNamespace(HAS_THE_POLICY_PAID_OUT)}
            label="Has the policy paid out?"
            options={[
              { label: 'Not asked', value: '' },
              { label: 'Yes', value: 'true' },
              { label: 'No', value: 'false' },
            ]}
            handleSave={handleSave}
            inline
          />
        </Grid.Item>
        <Grid.Item span={12}>
          <CircularRadioGroup
            name={withNamespace(WHO_IS_PENSION_PAYABLE_TO)}
            label="Who is the pension payable to?"
            options={[
              { label: 'Not asked', value: '' },
              { label: 'Estate', value: 'estate' },
              { label: 'Annuity (Spouses)', value: 'annuity' },
              { label: 'Nominated beneficiaries', value: 'beneficiaries' },
              { label: 'Not part of the estate', value: 'not_part_of_estate' },
            ]}
            handleSave={handleSave}
            inline
          />
        </Grid.Item>
        <Grid.Item>
          <NotesInput name={withNamespace(NOTES)} handleSave={handleSave} />
        </Grid.Item>
      </Grid>
    </EstateCard>
  )
}

const ItemWithForm = withForm(Item)

const PrivatePensions = ({ isComplete }: { isComplete: boolean }) => {
  const { PRIVATE_PENSIONS } = PROBATE_LEAD_FORM_SECTIONS
  const { saveProbateEstateField } = useProbateEstate()
  const { groupedItems, createItem } = useItems()
  const { privatePension: privatePensionItems = [] } = groupedItems
  const { total } = getItemsTotals(privatePensionItems)

  return (
    <SubsectionHeading
      disabled={privatePensionItems.length > 0}
      name={PRIVATE_PENSIONS}
      onToggle={saveProbateEstateField}
      scrollId="6.5"
      title="Private pensions"
      isComplete={isComplete}
    >
      <Grid.Item>
        <P size="S" data-testid="items-value-summary">
          Total: {total}
        </P>

        <P size="S" span={11}>
          Not including state pension. If there's an overpayment (a debt), add
          it at{' '}
          <StyledButton onClick={() => scrollToDataId('7.2')}>
            section 7.2 - Utilities and other debts
          </StyledButton>
        </P>

        {privatePensionItems.map((item: ProbateEstateItem, index: number) => (
          <ItemWithForm
            enableReinitialize={false}
            index={index}
            initialValues={{ privatePensions: { [item.id]: item.attributes } }}
            item={item}
            key={item.id}
            listLength={privatePensionItems.length}
          />
        ))}
      </Grid.Item>
      <Grid.Item>
        <Button.Secondary
          style={{ paddingTop: GTR.XXS, paddingBottom: GTR.XXS }}
          type="button"
          onClick={() => {
            createItem({ itemType: PROBATE_ESTATE_ITEM_TYPES.PRIVATE_PENSION })
          }}
        >
          Add private pension
        </Button.Secondary>
      </Grid.Item>
    </SubsectionHeading>
  )
}

export default PrivatePensions
