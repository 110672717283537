import { Button, Grid, P, Wrapper } from '@farewill/ui'
import { Formik, setNestedObjectValues } from 'formik'
import { useDispatch } from 'react-redux'
import set from 'lodash/set'
import * as yup from 'yup'

import EstateCard from 'components/estate-card'
import NotesInput from 'components/notes-input'

import { showModal } from 'state/actions'
import { Gift } from 'lib/models/gift'
import useNamespace from 'lib/formik/namespace'
import { formatValuesForFormik } from 'utils/helpers'
import {
  RESOURCES,
  UseDeleteArrayResourceResult,
  UseUpdateArrayResourceResult,
} from 'api'

import { useWillFactFindContext } from '../context'
import FormStatus from '../form-status'
import MoneyGift from './money'
import ResiduaryGift from './residuary'
import { UpdateGiftFn } from './types'
import { GIFT_BENEFICIARY_TYPE, GIFT_TYPE } from '../constants'
import AddBeneficiaryModal from './add-beneficiary-modal'
import DeleteGiftModal from './delete-gift-modal'
import BeneficiaryCard from './beneficiary-card'

type Props = {
  cardType: string
  gift: Gift
  giftDetails: typeof MoneyGift | typeof ResiduaryGift
  listLength: number
  listPosition: number
  giftsUpdateMutation: UseUpdateArrayResourceResult<Gift>
  giftsDeleteMutation: UseDeleteArrayResourceResult
  giftType: (typeof GIFT_TYPE)[keyof typeof GIFT_TYPE]
  addNewBeneficiaryLabel: string
  isCouplesWillCase: boolean
  disabled?: boolean
}

const getInitialGiftBeneficiaryType = (gift: Gift): string => {
  if (gift.attributes?.money?.isConditionalOnPartnerDeath) {
    return GIFT_BENEFICIARY_TYPE.SECOND_DEATH
  }

  return gift.attributes?.beneficiaries?.[0]?.type
}

const GiftCard = ({
  cardType,
  gift,
  giftDetails: GiftDetails,
  listLength,
  listPosition,
  giftsUpdateMutation,
  giftsDeleteMutation,
  giftType,
  addNewBeneficiaryLabel,
  isCouplesWillCase,
  disabled,
}: Props): React.ReactElement => {
  const dispatch = useDispatch()
  const namespace = `gifts-${gift.id}`
  const { withNamespace, withoutNamespace } = useNamespace(`${namespace}.`)
  const { highlightEmpty } = useWillFactFindContext()

  const updateGift: UpdateGiftFn = ({ name, value }) => {
    const { type } = gift.attributes
    const attributes = { type }

    /**
     * We need to send the id of the related gift row as part of the
     * PATCH request, so we set that here first.
     */
    set(attributes, `${type}.id`, gift.attributes[type].id)
    /**
     * Then we can set the key/value pair of the field that has just
     * been updated, first removing the field name prefix (namespace).
     */
    set(attributes, withoutNamespace(name), value)

    giftsUpdateMutation.mutate({
      id: gift.id,
      attributes,
      resource: RESOURCES.GIFTS,
    })
  }

  const deleteGift = () =>
    dispatch(
      showModal({
        component: DeleteGiftModal,
        headingText: 'Delete gift',
        config: {
          giftId: gift.id,
          giftsDeleteMutation,
        },
      })
    )

  const openAddNewGiftBeneficiaryModal = (giftBeneficiaryType: string) =>
    dispatch(
      showModal({
        maxWidth: 500,
        component: AddBeneficiaryModal,
        headingText: 'Add someone new',
        config: {
          giftId: gift.id,
          willId: gift.attributes.willId,
          /**
           * If the user has selected "Second death" we consider the
           * beneficiary a "principal" beneficiary, but with a
           * conditional clause - this condition is stored against the
           * gift on the money_gifts table - rather than the
           * beneficiary, as there can be multiple beneficiaries for
           * a single gift with this conditional clause.
           */
          giftBeneficiaryType:
            giftBeneficiaryType === GIFT_BENEFICIARY_TYPE.SECOND_DEATH
              ? GIFT_BENEFICIARY_TYPE.PRINCIPAL
              : giftBeneficiaryType,
        },
      })
    )

  /**
   * We add a prefix (namespace) to the field names so that there are
   * no duplicate fields with the same name (meaning each gift has a
   * unique set of field names).
   */
  const initialValues = {
    [namespace]: {
      ...formatValuesForFormik(gift.attributes),
      /**
       * This is a temporary field that will be removed once we add
       * the full backup functionality to a gift card in V1.
       */
      giftBeneficiaryType: getInitialGiftBeneficiaryType(gift),
    },
  }

  const showAddNewButton = !disabled && !gift.attributes.beneficiaries?.length

  return (
    <EstateCard
      onTrashClick={disabled ? undefined : deleteGift}
      listLength={listLength}
      listPosition={listPosition}
      type={cardType}
    >
      <Formik
        initialValues={initialValues}
        initialStatus={{ highlightEmpty }}
        onSubmit={() => undefined}
        validationSchema={yup.object().shape({
          [namespace]: yup.object().shape({
            giftBeneficiaryType: yup
              .string()
              .oneOf([
                GIFT_BENEFICIARY_TYPE.PRINCIPAL,
                GIFT_BENEFICIARY_TYPE.BACKUP,
                GIFT_BENEFICIARY_TYPE.SECOND_DEATH,
              ])
              .required('Select gift level before adding a beneficiary.'),
          }),
        })}
      >
        {({ values, validateForm, setTouched }) => (
          <Grid>
            <Grid.Item>
              <FormStatus />
              <GiftDetails
                namespace={namespace}
                updateGift={updateGift}
                hasGiftBeneficiary={gift.attributes.beneficiaries?.length > 0}
                isCouplesWillCase={isCouplesWillCase}
                disabled={disabled}
              />
            </Grid.Item>
            <Grid.Item>
              <P>{addNewBeneficiaryLabel}</P>
              <Wrapper margin={[0, 0, 'S']}>
                {gift.attributes.beneficiaries?.map((beneficiary) => (
                  <BeneficiaryCard
                    beneficiary={beneficiary}
                    key={beneficiary.id}
                    willId={gift.attributes.willId}
                    disabled={disabled}
                  />
                ))}
              </Wrapper>
              {showAddNewButton && (
                <Button.Bordered
                  size="S"
                  type="button"
                  onClick={async () => {
                    const { giftBeneficiaryType } = values[namespace]

                    if (!giftBeneficiaryType) {
                      const errors = await validateForm()
                      setTouched(setNestedObjectValues(errors, true))
                      return
                    }

                    openAddNewGiftBeneficiaryModal(giftBeneficiaryType)
                  }}
                >
                  Add new
                </Button.Bordered>
              )}
            </Grid.Item>
            {(!disabled || gift.attributes.notes) && (
              <Grid.Item>
                <NotesInput
                  name={withNamespace('notes')}
                  handleSave={updateGift}
                  disabled={disabled}
                />
              </Grid.Item>
            )}
          </Grid>
        )}
      </Formik>
    </EstateCard>
  )
}

export default GiftCard
