import PropTypes from 'prop-types'
import styled from 'styled-components'
import { COLOR, FONT } from '@farewill/ui/tokens'

const StyledScore = styled.div`
  width: 26px;
  height: 26px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  color: ${COLOR.GREY.DARK};
  background: ${COLOR.GREY.LIGHT};
  font-size: ${FONT.SIZE.XXS};
  font-weight: ${FONT.WEIGHT.BOLD};
`

const ConversionScoreIcon = ({ conversionScore }) => {
  if (!conversionScore) return null

  return <StyledScore>{conversionScore}</StyledScore>
}

ConversionScoreIcon.propTypes = {
  conversionScore: PropTypes.number,
}

ConversionScoreIcon.defaultProps = {
  conversionScore: null,
}

export default ConversionScoreIcon
