import styled from 'styled-components'
import { P, Wrapper } from '@farewill/ui'
import { COLOR, GTR } from '@farewill/ui/tokens'

import ActivityTimestamp from 'components/activity-timestamp'

const StyledCommentWrapper = styled(Wrapper)`
  padding-left: ${GTR.XXL};
  margin-bottom: ${GTR.L};
`

const DeletedComment = ({ comment }) => (
  <StyledCommentWrapper>
    <P margin={0} size="S" color={COLOR.GREY.MEDIUM}>
      <strong>{comment.attributes.adminUser.name}</strong> deleted a comment
    </P>
    <ActivityTimestamp datetime={comment.attributes.createdAt} />
  </StyledCommentWrapper>
)

export default DeletedComment
