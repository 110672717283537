import {
  createContext,
  useContext,
  useCallback,
  useEffect,
  useState,
} from 'react'
import includes from 'lodash/includes'

import useApi from 'lib/effects/api'
import { Lead, LeadAttributes } from 'lib/models/lead'
import { productDataFunctions } from './helpers'
import { ResponseErrors, ResponseMeta } from 'lib/types'
import {
  CIVIL_PARTNERSHIP_BUT_SEPARATED,
  DISSOLVED,
  DIVORCED,
  MARRIED_BUT_SEPARATED,
  SINGLE,
  WIDOWED,
} from 'utils/enums'

const LeadContext = createContext<LeadContextType | undefined>(undefined)

export type LeadContextType = {
  fetchLead: () => Promise<{ data: Lead }>
  isContactUpdating: boolean
  isLeadLoading: boolean
  isLeadUpdating: boolean
  lead: Lead
  leadId: string
  onSubmit: (values: LeadAttributes) => Promise<void>
  updateContact: (values: {
    name: string
    value: string
  }) => Promise<{ data: Lead }>
  updateContactErrors: ResponseErrors
  updateLeadErrors: ResponseErrors
  updateLeadMeta: ResponseMeta | null
}

const useLeadContext = (): LeadContextType => {
  const context = useContext(LeadContext)
  if (context === undefined) {
    throw new Error('useLeadContext must be used within an LeadContextProvider')
  }
  return context
}

const LeadContextProvider = ({
  children,
  leadId,
}: {
  children: React.ReactNode
  leadId: string
}): React.ReactElement => {
  const [lead, setLead] = useState<Lead | null>(null)
  const contactId = lead?.attributes.contactId

  const [{ isLoading: isLeadLoading, data: fetchedLead }, fetchLeadRequest] =
    useApi({ isLoading: true })

  const fetchLead = useCallback(
    () => fetchLeadRequest({ url: `/api/leads/${leadId}` }),
    [fetchLeadRequest, leadId]
  )

  useEffect(() => {
    setLead(fetchedLead as Lead | null)
  }, [fetchedLead])

  const [
    { isLoading: isContactUpdating, errors: updateContactErrors },
    updateContactRequest,
  ] = useApi()

  const updateContact = useCallback(
    async ({ name, value }) => {
      const key = name.split('contact.')[1]
      const attributes = { [key]: value || null }

      await updateContactRequest({
        url: `/api/contacts/${contactId}`,
        method: 'PATCH',
        data: { data: { type: 'contacts', id: contactId, attributes } },
      })
      return fetchLead()
    },
    [contactId, fetchLead, updateContactRequest]
  )

  const [
    {
      data: updatedLead,
      isLoading: isLeadUpdating,
      errors: updateLeadErrors,
      meta: updateLeadMeta,
    },
    updateLeadRequest,
  ] = useApi()

  const updateLead = useCallback(
    (id, attributes) =>
      updateLeadRequest({
        url: `/api/leads/${id}`,
        method: 'PATCH',
        data: { data: { type: 'leads', id, attributes } },
      }),
    [updateLeadRequest]
  )

  useEffect(() => {
    setLead(updatedLead as Lead | null)
  }, [updatedLead])

  const onSubmit = useCallback(
    async (values) => {
      if (
        includes(
          [
            SINGLE,
            WIDOWED,
            MARRIED_BUT_SEPARATED,
            CIVIL_PARTNERSHIP_BUT_SEPARATED,
            DIVORCED,
            DISSOLVED,
          ],
          values.quoteAttributes.relationshipStatus
        )
      ) {
        values.quoteAttributes.willType = 'single'
      }

      const { product } = values as LeadAttributes
      const leadPatchData = productDataFunctions[product](values)

      await updateLead(leadId, leadPatchData)
    },
    [leadId, updateLead]
  )

  const value = {
    lead,
    leadId,
    fetchLead,
    isLeadLoading,
    onSubmit,
    isContactUpdating,
    updateContactErrors,
    isLeadUpdating,
    updateLeadErrors,
    updateLeadMeta,
    updateContact,
  } as LeadContextType

  return <LeadContext.Provider value={value}>{children}</LeadContext.Provider>
}

export { LeadContextProvider, useLeadContext }
