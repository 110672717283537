import { useCallback, useContext, useEffect } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { useFormikContext } from 'formik'
import { Grid, Wrapper } from '@farewill/ui'
import { GTR } from '@farewill/ui/tokens'
import get from 'lodash/get'

import { strToBool } from 'utils/helpers'
import namespace from 'lib/formik/namespace'
import useApi from 'lib/effects/api'
import { email as isValidEmail } from 'lib/formik/validation'

import { Context } from '../../../context'
import InputFloating from 'components/form/input-floating'
import Checkbox from 'components/form/checkbox'
import CircularRadioGroup from 'components/form/circular-radio-group'
import DateSplit from 'components/form/date-split'
import NotesInput from 'components/notes-input'
import Address from '../../components/address'
import withForm from '../../components/with-form'

import { getPersonVisibleFields } from '../form-helpers'

import { NOT_ASKED_OPTIONS } from 'utils/enums'

import {
  IS_CUSTOMER,
  IS_POINT_OF_CONTACT,
  IS_PERSONAL_REPRESENTATIVE,
  IS_INCLUDED_IN_COMMS,
  TITLE,
  FIRST_NAMES,
  LAST_NAME,
  PREFERRED_NAME,
  NAME_MATCHES_WILL,
  NAME_MISMATCH_NOTES,
  ADDRESS,
  ADDRESS_MATCHES_WILL,
  ADDRESS_MISMATCH_NOTES,
  DECEASED_RELATIONSHIP_TO_PERSON,
  CUSTOM_DECEASED_RELATIONSHIP_TO_PERSON,
  EMAIL,
  PHONE_NUMBER,
  BIRTH,
  IS_INSOLVENT,
  AUTHORIZED_TO_DEAL_WITH_CASE,
  AUTHORIZED_TO_BE_INCLUDED_IN_COMMS,
  NOTES,
} from '../field-names'
import PersonalRepresentativeQuestions from './personal-representative-questions'
import { showRelationshipToCustomerWithLegacy } from 'routes/lead/helpers'

const StyledCheckboxesWrapper = styled(Wrapper)`
  display: flex;
  flex-wrap: wrap;

  && {
    margin-top: -${GTR.S};
  }
`

const StyledCheckboxWrapper = styled(Wrapper)`
  margin-right: ${GTR.L};

  && {
    margin-top: ${GTR.S};
  }
`

const Person = ({ hasWill, personId, probateEstateId }) => {
  const { setFieldValue, values } = useFormikContext()
  const [{ isLoading, errors }, makeRequest] = useApi()
  const { setErrors, setIsLoading } = useContext(Context)

  useEffect(() => {
    setErrors(errors)
  }, [setErrors, errors])

  useEffect(() => {
    setIsLoading(isLoading)
  }, [setIsLoading, isLoading])

  const prefix = `people.${personId}.`
  const { withNamespace, withoutNamespace } = namespace(prefix)

  const {
    showCustomDeceasedRelationshipToPerson,
    showAddressMismatchNotes,
    showAuthorizedToDealWithCase,
    showDateOfBirth,
    showNameAndAddressMatchWill,
    showNameMismatchNotes,
    showRoleAndIsInsolvent,
    showAuthorizedToBeIncludedInComms,
  } = getPersonVisibleFields({ hasWill, prefix, values })

  const handleSave = useCallback(
    ({ name, value }) => {
      makeRequest({
        url: `/api/probate-estates/${probateEstateId}/people/${personId}`,
        method: 'PATCH',
        data: {
          data: {
            type: 'probate_estate_people',
            id: personId,
            attributes: { [withoutNamespace(name)]: value || null },
          },
        },
      })
    },
    [makeRequest, personId, probateEstateId, withoutNamespace]
  )

  const isCustomer = strToBool(get(values, withNamespace(IS_CUSTOMER)))
  const isContact = strToBool(get(values, withNamespace(IS_POINT_OF_CONTACT)))
  const isPR = strToBool(get(values, withNamespace(IS_PERSONAL_REPRESENTATIVE)))
  const isIncludedInComms = strToBool(
    get(values, withNamespace(IS_INCLUDED_IN_COMMS))
  )

  useEffect(() => {
    if (isCustomer && !isPR) {
      const isPrFieldName = withNamespace(IS_PERSONAL_REPRESENTATIVE)
      setFieldValue(isPrFieldName, true)
      handleSave({ name: isPrFieldName, value: true })
    }
  }, [handleSave, isCustomer, isPR, setFieldValue, withNamespace])

  return (
    <Grid style={{ gridAutoFlow: 'row' }}>
      <Grid.Item>
        <StyledCheckboxesWrapper>
          <StyledCheckboxWrapper>
            <Checkbox
              name={withNamespace(IS_CUSTOMER)}
              label="Customer"
              checked={isCustomer}
              handleSave={handleSave}
            />
          </StyledCheckboxWrapper>
          <StyledCheckboxWrapper>
            <Checkbox
              name={withNamespace(IS_POINT_OF_CONTACT)}
              label="Point of contact"
              checked={isContact}
              handleSave={handleSave}
            />
          </StyledCheckboxWrapper>
          <StyledCheckboxWrapper>
            <Checkbox
              name={withNamespace(IS_PERSONAL_REPRESENTATIVE)}
              label="Personal representative or attorney"
              checked={isPR}
              handleSave={handleSave}
              disabled={isCustomer && isPR}
            />
          </StyledCheckboxWrapper>
          <StyledCheckboxWrapper>
            <Checkbox
              name={withNamespace(IS_INCLUDED_IN_COMMS)}
              label="Include in all comms"
              checked={isIncludedInComms}
              handleSave={handleSave}
            />
          </StyledCheckboxWrapper>
        </StyledCheckboxesWrapper>
      </Grid.Item>
      {showAuthorizedToBeIncludedInComms && (
        <Grid.Item>
          <CircularRadioGroup
            name={withNamespace(AUTHORIZED_TO_BE_INCLUDED_IN_COMMS)}
            label="Do we have authority from the customer to include this person in all comms?"
            options={NOT_ASKED_OPTIONS}
            handleSave={handleSave}
            inline
          />
        </Grid.Item>
      )}
      <Grid.Item span={2}>
        <InputFloating
          name={withNamespace(TITLE)}
          label="Title"
          handleSave={handleSave}
        />
      </Grid.Item>
      <Grid.Item span={5}>
        <InputFloating
          name={withNamespace(FIRST_NAMES)}
          label="First + middle names (or company)"
          handleSave={handleSave}
        />
      </Grid.Item>
      <Grid.Item span={5}>
        <InputFloating
          name={withNamespace(LAST_NAME)}
          label="Last name"
          handleSave={handleSave}
        />
      </Grid.Item>
      <Grid.Item span={7}>
        <InputFloating
          name={withNamespace(PREFERRED_NAME)}
          label="Preferred name (optional)"
          handleSave={handleSave}
          hint="How the person likes to be addressed on calls and by email."
        />
      </Grid.Item>
      {showNameAndAddressMatchWill && (
        <>
          <Grid.Item>
            <CircularRadioGroup
              name={withNamespace(NAME_MATCHES_WILL)}
              label="Is this the same as the name in the will?"
              options={NOT_ASKED_OPTIONS}
              handleSave={handleSave}
              inline
            />
          </Grid.Item>
          {showNameMismatchNotes && (
            <Grid.Item>
              <InputFloating
                name={withNamespace(NAME_MISMATCH_NOTES)}
                label="Describe the difference in names"
                handleSave={handleSave}
                component="textarea"
              />
            </Grid.Item>
          )}
        </>
      )}
      <Grid.Item span={9}>
        <Address
          name={withNamespace(ADDRESS)}
          data={get(values, withNamespace(ADDRESS))}
        />
      </Grid.Item>
      {showNameAndAddressMatchWill && (
        <>
          <Grid.Item>
            <CircularRadioGroup
              name={withNamespace(ADDRESS_MATCHES_WILL)}
              label="Is this the same as the address in the will?"
              options={NOT_ASKED_OPTIONS}
              handleSave={handleSave}
              inline
            />
          </Grid.Item>
          {showAddressMismatchNotes && (
            <Grid.Item>
              <InputFloating
                name={withNamespace(ADDRESS_MISMATCH_NOTES)}
                label="Describe the difference in addresses"
                handleSave={handleSave}
                component="textarea"
              />
            </Grid.Item>
          )}
        </>
      )}
      <Grid.Item span={6}>
        <InputFloating
          name={withNamespace(EMAIL)}
          label="Email"
          type="email"
          handleSave={handleSave}
          validate={isValidEmail}
        />
      </Grid.Item>
      <Grid.Item span={6}>
        <InputFloating
          name={withNamespace(PHONE_NUMBER)}
          label="Phone number"
          handleSave={handleSave}
        />
      </Grid.Item>

      <Grid.Item span={6}>
        <InputFloating
          label="We’re getting probate for their..."
          name={withNamespace(DECEASED_RELATIONSHIP_TO_PERSON)}
          component="select"
          options={showRelationshipToCustomerWithLegacy(
            get(values, withNamespace(DECEASED_RELATIONSHIP_TO_PERSON))
          )}
          handleSave={handleSave}
          hint="E.g. if the customer’s father has died, choose ‘Father’"
        />
      </Grid.Item>
      {showCustomDeceasedRelationshipToPerson && (
        <Grid.Item span={6}>
          <InputFloating
            label="Specify ‘Other’ relationship"
            name={withNamespace(CUSTOM_DECEASED_RELATIONSHIP_TO_PERSON)}
            handleSave={handleSave}
          />
        </Grid.Item>
      )}
      <PersonalRepresentativeQuestions
        handleSave={handleSave}
        hasWill={hasWill}
        personId={personId}
      />
      {showDateOfBirth && (
        <Grid.Item span={6} startColumn={1}>
          <DateSplit
            name={withNamespace(BIRTH)}
            label="Date of birth"
            handleSave={handleSave}
            hint="For example, 24 2 2020. Needed for ID check"
          />
        </Grid.Item>
      )}
      {showRoleAndIsInsolvent && (
        <Grid.Item>
          <CircularRadioGroup
            name={withNamespace(IS_INSOLVENT)}
            label="Is this PR bankrupt / insolvent?"
            options={NOT_ASKED_OPTIONS}
            handleSave={handleSave}
            inline
          />
        </Grid.Item>
      )}
      {showAuthorizedToDealWithCase && (
        <Grid.Item>
          <CircularRadioGroup
            name={withNamespace(AUTHORIZED_TO_DEAL_WITH_CASE)}
            label="Do we have authority from the customer to deal with this point of contact?"
            options={NOT_ASKED_OPTIONS}
            handleSave={handleSave}
            inline
          />
        </Grid.Item>
      )}
      <Grid.Item>
        <NotesInput name={withNamespace(NOTES)} handleSave={handleSave} />
      </Grid.Item>
    </Grid>
  )
}

Person.propTypes = {
  hasWill: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]).isRequired,
  personId: PropTypes.number.isRequired,
  probateEstateId: PropTypes.string.isRequired,
}

export default withForm(Person)
