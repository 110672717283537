import { Celebrant } from 'lib/types/celebrant'
import { Country } from 'lib/types/country'
import {
  BURIAL,
  COFFINS,
  COLLECT,
  CREMATION,
  DECEASED_LOCATIONS,
  DELIVER,
  SCATTER,
  TRIBUTE_PAGE_STATUSES,
  FAST_TRACK_DELIVER,
} from 'utils/enums'

import { Address } from './address'
import { AdminUser } from './admin-user'
import { Contact } from './contact'
import { Lead } from './lead'
import { Event } from './event'
import { Task } from './task'

type RequiredCremationAttributes = {
  createdAt: string
  updatedAt: string
}

export type AdditionalCharge = {
  id?: number
  description: string
  price: number
}

export type AshesInstruction =
  | typeof DELIVER
  | typeof FAST_TRACK_DELIVER
  | typeof SCATTER
  | typeof COLLECT
export type CoffinType = (typeof COFFINS)[number]['value']
export type DeceasedLocationType = (typeof DECEASED_LOCATIONS)[number]['value']

type OptionalCremationAttributes = Partial<{
  celebrant: Celebrant | null
  celebrantId: number
  attendedService: boolean
  coffinType: CoffinType
  deceasedLocationCategory: (typeof DECEASED_LOCATIONS)[number]['value']
  dressingService: boolean
  isBariatric: boolean
  isUrgentCollection: boolean
  referredToCoroner?: boolean
  treeType: string
  treePlaqueMessage: string
  urnType: string
  urnTypes: string[]
  ashesInstruction: AshesInstruction
  ashesNotes: string
  ashesSplitting: boolean
  ashesSplittingPortions: number
  attendingDoctorAddress: Address
  attendingDoctorAddressId: number
  attendingDoctorName: string
  attendingDoctorEmail: string
  attendingDoctorTelephone: string
  contactId: number
  contact: Contact
  caseOwner: AdminUser
  holidayCoverOwner: AdminUser
  isCustomerNearRelative: boolean
  isCustomerExecutor: boolean
  isCustomerNearRelativeOrExecutor: boolean // DEPRECATED
  lastActivityAt: string
  relationshipNotes: string
  criticalNotes: string
  customerAddress: Address
  customerAddressId: number
  customerTitle: string
  customerFirstName: string
  customerLastName: string
  customerEmail: string
  funeralId: number
  dateOfBirth: Date
  dateOfDeath: Date
  occupation: string
  maritalStatus: string
  deathAddress: Address
  deathAddressId: number
  deathAddressType: string
  deathAddressNotes: string
  collectionAddress: Address
  collectionAddressId: number
  isCollectionDifferentToDeathAddress: boolean
  isExaminationRequired: boolean
  deceasedTitle: string
  deceasedFirstName: string
  deceasedLastName: string
  deceasedAddress: string
  deceasedSex: string
  deceasedDateOfBirth: Date
  destinationCountry: string
  examinationRequiredNotes: string
  firstNames: string
  hasHazardousImplant: string
  isHazardousImplantRemoved: string
  hasSecondAttendingDoctor: boolean // DEPRECATED
  hazardousImplantNotes: string
  internalNotes: string
  isUnnaturalDeath: boolean
  lastName: string
  personWhoHasDiedAddress: Address
  personWhoHasDiedAddressId: number
  provider: string
  providerInstructions: string
  reference: string
  relationshipToCustomer: string
  requiresPostedForms: boolean
  sex: string
  secondAttendingDoctorAddress: Address // DEPRECATED
  secondAttendingDoctorAddressId: number // DEPRECATED
  secondAttendingDoctorName: string // DEPRECATED
  secondAttendingDoctorTelephone: string // DEPRECATED
  shouldReturnMetalsPostCremation: boolean
  timeOfDeath: string
  travelMethod: string
  unnaturalDeathNotes: string
  usualDoctorAddress: Address // DEPRECATED
  usualDoctorAddressId: number // DEPRECATED
  usualDoctorName: string // DEPRECATED
  usualDoctorTelephone: string // DEPRECATED
  isDifficult: boolean
  difficultReason: string | null
  collectionDate: Date
  cremationDate: Date
  cremationTime: string
  deliveryDate: Date
  nextStage: string
  daysInStage: number
  knownAs: string
  leadId: number
  lastCallAt: string
  location: string
  locationId: string
  additionalCharges: AdditionalCharge[]
  lead: Lead
  deathCountry: Country | null
  hasProcuratorFiscalInvestigated: boolean
  hasProcuratorFiscalApprovedCremation: boolean
  hasProcuratorFiscalProvidedForm: boolean
  isBodyRiskToPublicHealth: boolean
  hasExplosiveImplant: boolean
  hasOtherHazardousImplant: boolean
  tributePageStatus: TRIBUTE_PAGE_STATUSES
  tributePageDeleteDate: Date
  tributePageName: string
  comments: Comment[]
  events: Event[]
  tasks: Task[]
  caseOwnerId: number
  holidayCoverOwnerId: number
  signatureRequestId: number
}>

type NullableCremationAttributes = {
  [Property in keyof OptionalCremationAttributes]:
    | OptionalCremationAttributes[Property]
    | null
}

export interface FuneralAttributes
  extends RequiredCremationAttributes,
    NullableCremationAttributes {
  funeralType?: typeof CREMATION | typeof BURIAL
}

export interface CremationAttributes extends FuneralAttributes {
  funeralType?: typeof CREMATION
}

export type Funeral = {
  id: number
  attributes: FuneralAttributes
}

export function isCremation(funeral?: Funeral | FuneralAttributes): boolean {
  if (funeral == null) {
    return false
  } else if ('attributes' in funeral) {
    return funeral.attributes.funeralType === CREMATION
  } else {
    return funeral.funeralType === CREMATION
  }
}
