import PropTypes from 'prop-types'
import styled from 'styled-components'
import { ErrorMessage } from 'formik'
import { COLOR, FONT, GTR } from '@farewill/ui/tokens'

const StyledErrorMessage = styled.div`
  margin-left: ${GTR.S};
  color: ${COLOR.STATE.ERROR};
  font-size: ${FONT.SIZE.XS};
`

const ErrorSmall = ({ name }) => (
  <ErrorMessage name={name}>
    {(msg) => <StyledErrorMessage>{msg}</StyledErrorMessage>}
  </ErrorMessage>
)

ErrorSmall.propTypes = {
  name: PropTypes.string.isRequired,
}

export default ErrorSmall
