import get from 'lodash/get'

const FETCH_LATEST_CALLS = 'FETCH_LATEST_CALLS'

export default function latestCalls(state = [], action) {
  switch (action.type) {
    case FETCH_LATEST_CALLS + '_FULFILLED':
      return get(action, 'payload.data.calls', [])

    case FETCH_LATEST_CALLS + '_PENDING':
    case FETCH_LATEST_CALLS + '_REJECTED':
      return []

    default:
      return state
  }
}
