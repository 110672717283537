import { ReactElement } from 'react'
import { useDispatch } from 'react-redux'
import { Button, Grid, H } from '@farewill/ui'

import { showModal } from 'state/actions'
import { findEvent } from 'utils/helpers'
import { FUNERAL_CASE_STATUS_OPTIONS } from 'utils/enums'
import { Event } from 'lib/models/event'

import LostModal from './lost-modal'

type CaseLostOptions = {
  value: string
  label: string
}

const getReasonText = (reason: string | undefined): string => {
  return reason ? `for reason: ${reason}` : ''
}

const getLabel = (
  options: CaseLostOptions[],
  value: string
): string | undefined => {
  return options.find((option) => option.value === value)?.label
}

const getButtonText = ({
  caseLostEvent,
}: {
  caseLostEvent: Event | undefined
}): string => {
  const caseLostReason = caseLostEvent?.attributes.metadata?.caseLostReason
  if (caseLostReason) {
    const reason = getLabel(FUNERAL_CASE_STATUS_OPTIONS.LOST, caseLostReason)
    return `Case lost ${getReasonText(reason)}`
  }

  return 'Mark as lost'
}

const Lost = ({
  events,
  funeralId,
}: {
  events: Event[]
  funeralId: string
}): ReactElement => {
  const dispatch = useDispatch()
  const caseLostEvent = findEvent(events, 'case_lost')

  return (
    <>
      <Grid.Item>
        <H size="S">Lost</H>
        <Button.Underline
          flush
          type="button"
          onClick={() =>
            dispatch(
              showModal({
                component: LostModal,
                headingText: 'Mark as lost',
                config: { events, funeralId },
              })
            )
          }
        >
          {getButtonText({ caseLostEvent })}
        </Button.Underline>
      </Grid.Item>
    </>
  )
}

export default Lost
