import { Component } from 'react'
import PropTypes from 'prop-types'
import { Wrapper } from '@farewill/ui'
import styled, { css } from 'styled-components'
import { COLOR, FONT, GTR } from '@farewill/ui/tokens'

import { formatApiError } from 'lib/formatting/errors'

import SimpleList from 'components/styled/simple-list'

const Outer = styled.div`
  background-color: #efefef;
`

const Inner = styled(Wrapper)`
  padding-top: 20px;
  padding-bottom: 20px;
`

const Icon = styled.div`
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: ${COLOR.WHITE};
  font-weight: ${FONT.WEIGHT.BOLD};
  margin-right: ${GTR.XS};
  background-color: ${COLOR.GREY.MEDIUM};

  ${(props) =>
    props.error &&
    css`
      background-color: ${COLOR.STATE.ERROR};
    `}

  ${(props) =>
    props.warning &&
    css`
      background-color: ${COLOR.STATE.WARNING};
    `}
`

class Alert extends Component {
  componentDidUpdate = () => {
    if (!this.isVisible()) return

    window.scrollTo(0, 0)
  }

  isVisible = () => {
    const { errors, messages } = this.props
    return [...errors, ...messages].length !== 0
  }

  render() {
    const { errors, messages } = this.props
    if (!this.isVisible()) return null

    return (
      <Outer>
        <Inner container>
          <SimpleList>
            {errors.map((error, index) => (
              <li key={index}>
                <Icon error>!</Icon> {formatApiError(error)}
              </li>
            ))}
            {messages.map((message, index) => (
              <li key={index}>
                {message.status === 'warning' && <Icon warning>!</Icon>}
                {formatApiError(message)}
              </li>
            ))}
          </SimpleList>
        </Inner>
      </Outer>
    )
  }
}

Alert.defaultProps = {
  errors: [],
  messages: [],
}

Alert.propTypes = {
  errors: PropTypes.array,
  messages: PropTypes.array,
}

export default Alert
