import { FormikErrors } from 'formik'
import omit from 'lodash/omit'
import cloneDeep from 'lodash/cloneDeep'
import Dinero from 'dinero.js'

import ENV from 'config/environment'
import { WillLeadAttributes, WillType } from 'lib/models/lead'
import { getPermanentResidence } from 'lib/will/lead'
import { Outcome, PhoneWillComplexity } from 'routes/lead/types'
import {
  LEAD_PARTNER_TYPES,
  PARTNER_TERM_PATHS,
  RESIDENCE_OPTIONS,
} from 'utils/enums'
import { getFullName } from 'utils/helpers'

import { QuestionConfig } from '../types'
import {
  CALENDLY_DOMAIN,
  COUNTRIES,
  FAREWILL_SERVICES,
  LARGE_PRINT,
  OPTIONAL,
  PERSONAL_AND_ASSETS,
  WISHES,
} from '../constants'
import { QUESTIONS } from '../config'

import getOutcomeForQuestions from './get-outcome-for-questions'

type GetPathType = {
  permanentResidence: string
  willComplexity: PhoneWillComplexity
  isCharity: boolean
  willType: WillType
}

const getPath = ({
  permanentResidence,
  willComplexity,
  isCharity,
  willType,
}: GetPathType) => {
  if (
    permanentResidence === COUNTRIES.SCOTLAND ||
    permanentResidence === COUNTRIES.NORTHERN_IRELAND
  ) {
    return `farewill-phone-wills/simple-phone-will-${willType}-${
      isCharity ? 'charity-' : ''
    }${permanentResidence.replace(/_/g, '-').toLowerCase()}`
  }

  const type = isCharity
    ? `-charity-will-${willType}`
    : `${willType === 'couple' ? '-couple' : ''}`

  return `farewill-phone-wills/${willComplexity}${type}`
}

type GetCalendlyUrlType = {
  willComplexity?: PhoneWillComplexity
  willType?: WillType
  sourceIdentifier: string
  partnerTermPaths: Record<string, string>
  name: string | null
  email: string | null
  isCharity: boolean
  permanentResidence: string
}

export const getPropertyTrustFactFindCalendlyUrl = (): string => {
  const path =
    ENV.APP_ENV === 'production'
      ? 'property-trust-fact-find'
      : 'property-trust-fact-find-test'

  return `${CALENDLY_DOMAIN}/${path}`
}

export const getCalendlyUrl = ({
  willComplexity,
  willType,
  sourceIdentifier,
  partnerTermPaths,
  name,
  email,
  isCharity,
  permanentResidence,
}: GetCalendlyUrlType) => {
  if (!willComplexity || !willType || !permanentResidence) return

  const path =
    ENV.APP_ENV === 'production'
      ? getPath({ permanentResidence, willComplexity, isCharity, willType })
      : 'farewill-testing/phone-wills-fact-find-call-test'

  const params = new URLSearchParams({
    a3: partnerTermPaths[sourceIdentifier] || '/terms',
    name: name || '',
    email: email || '',
  }).toString()

  return `${CALENDLY_DOMAIN}/${path}?${params}`
}

/** Return human readable name of country based on the value passed */
export const getCountryLabelFromValue = (country: string) => {
  if (!country) return
  return RESIDENCE_OPTIONS.find((option) => country === option.value)?.label
}

/** Format prices using Dinero, to print them in more human readable way (with units) */
export const formatAttributesForForm = (attributes: WillLeadAttributes) => {
  const clonedAttributes = cloneDeep(attributes)
  const priceInPence = attributes?.quoteAttributes?.price

  if (priceInPence) {
    const priceInPounds = Dinero({ amount: priceInPence }).toUnit()
    clonedAttributes.quoteAttributes.price = priceInPounds
  }

  return clonedAttributes
}

/** Helper function for getAllVisibleQuestions, to get all available questions for single section */
export const visibleQuestions = ({
  questions,
  values,
}: {
  questions: QuestionConfig[]
  values: WillLeadAttributes
}) => {
  let earlyOutcome: Outcome = null
  const filteredQuestions = questions.filter((question) => {
    const isVisible = question.isVisible ? question.isVisible?.(values) : true

    if (!earlyOutcome && isVisible) {
      earlyOutcome = question.outcome?.(values) || null
      return true
    } else {
      return false
    }
  })

  return { filteredQuestions, hasEarlyOutcome: !!earlyOutcome }
}

/**
 * Each question has to meet certain conditions (in isVisible function within it's config) to be visible in the form,
 * this function checks each of the questions, and if any of them has an early outcome available, it doesn't allow
 * to show any other questions after it.
 */
const getAllVisibleQuestions = (values: WillLeadAttributes) => {
  const {
    filteredQuestions: personalAndAssetQuestions,
    hasEarlyOutcome: hasPersonalAndAssetEarlyOutcome,
  } = visibleQuestions({
    questions: QUESTIONS[PERSONAL_AND_ASSETS],
    values,
  })

  const {
    filteredQuestions: wishesQuestions,
    hasEarlyOutcome: hasWishesEarlyOutcome,
  } = visibleQuestions({
    questions: QUESTIONS[WISHES],
    values,
  })

  const optionalQuestions =
    hasPersonalAndAssetEarlyOutcome || hasWishesEarlyOutcome
      ? []
      : QUESTIONS[OPTIONAL]
  const largePrintQuestions =
    hasPersonalAndAssetEarlyOutcome || hasWishesEarlyOutcome
      ? []
      : QUESTIONS[LARGE_PRINT]

  const allVisibleQuestions = [
    ...personalAndAssetQuestions,
    ...wishesQuestions,
    ...optionalQuestions,
    ...largePrintQuestions,
  ]

  const visibleQuestionsInSections = {
    [PERSONAL_AND_ASSETS]: personalAndAssetQuestions,
    [WISHES]: wishesQuestions,
    [OPTIONAL]: optionalQuestions,
    [LARGE_PRINT]: largePrintQuestions,
  }

  return { allVisibleQuestions, visibleQuestionsInSections }
}

/** Checks what type of will should the form return (phone, online / simple, complex) and provides UI for the information  */
const getOutcome = (values: WillLeadAttributes) => {
  const { allVisibleQuestions, visibleQuestionsInSections } =
    getAllVisibleQuestions(values)

  const outcomeForPersonalAndAssetQuestions = getOutcomeForQuestions({
    questions: visibleQuestionsInSections[PERSONAL_AND_ASSETS],
    values,
  })

  const outcomeForAllQuestions = getOutcomeForQuestions({
    questions: allVisibleQuestions,
    values,
    outcomeForPersonalAndAssetQuestions,
  })

  return {
    outcomeForPersonalAndAssetQuestions,
    outcomeForAllQuestions,
  }
}

/** If the customer answers in the way that it's possible for Farewill to help them, show wishes */
const checkIfCanShowWishesQuestions = (
  outcomeForPersonalAndAssetQuestions: Outcome
) => {
  return !(
    outcomeForPersonalAndAssetQuestions?.complete &&
    !FAREWILL_SERVICES.includes(outcomeForPersonalAndAssetQuestions.type)
  )
}

/** Helper function to get all the data needed for the form */
const formUtils = (
  values: WillLeadAttributes,
  errors: FormikErrors<WillLeadAttributes>
) => {
  const { price, willComplexity, willType, numberOfComplexWills } =
    values.quoteAttributes
  const { visibleQuestionsInSections } = getAllVisibleQuestions(values)
  const { outcomeForAllQuestions, outcomeForPersonalAndAssetQuestions } =
    getOutcome(values)
  const noErrorsInForm =
    Object.values(omit(errors, 'squareTransactionUrl')).length === 0
  const canTakePayment = price && noErrorsInForm
  const calculatedPrice = Number(((price as number) * 100).toFixed(0))
  const showWishesQuestions = checkIfCanShowWishesQuestions(
    outcomeForPersonalAndAssetQuestions
  )
  const canPay = FAREWILL_SERVICES.includes(outcomeForAllQuestions?.type || '')
  const isCharity = values.partnerType === LEAD_PARTNER_TYPES.charity
  const freeWillEligible = isCharity
  const propertyTrustFactFindCalendlyUrl = getPropertyTrustFactFindCalendlyUrl()

  const calendlyUrl = getCalendlyUrl({
    willComplexity: willComplexity
      ? willComplexity
      : numberOfComplexWills === 0
      ? 'simple'
      : 'complex',
    willType,
    name: getFullName(values.contact),
    email: values.contact.email,
    sourceIdentifier: values.sourceIdentifier,
    partnerTermPaths: PARTNER_TERM_PATHS,
    isCharity,
    permanentResidence: getPermanentResidence(values.quoteAttributes),
  })
  const shouldCaptureCardForLater = !price && noErrorsInForm && canPay
  return {
    canTakePayment,
    shouldCaptureCardForLater,
    calculatedPrice,
    visibleQuestionsInSections,
    outcomeForAllQuestions,
    showWishesQuestions,
    canPay,
    freeWillEligible,
    calendlyUrl,
    propertyTrustFactFindCalendlyUrl,
  }
}

export default formUtils
