import { Button, Grid, H, P } from '@farewill/ui'
import { GTR } from '@farewill/ui/tokens'

import store from 'state/create-store'
import { showModal } from 'state/actions'
import namespace from 'lib/formik/namespace'
import {
  PROBATE_ESTATE_ITEM_TYPES,
  ProbateEstateItem,
} from 'lib/models/probate-estate'
import EstateCard from 'components/estate-card'
import InputFloating from 'components/form/input-floating'
import NotesInput from 'components/notes-input'
import { SelectInput } from 'components/form'
import DeleteModal from 'routes/lead/probate-form/delete-modal'
import withForm from 'routes/lead/probate-form/with-form'
import { getItemsTotals } from 'routes/probate-estate/fact-find/get-totals'
import {
  PROBATE_LEAD_FORM_SECTIONS,
  PROBATE_LEAD_FORM_VALUES,
} from 'routes/lead/probate-form/constants'

import { HandleSaveProps, ItemProps } from './types'
import { useItems } from '../helpers/items-context'
import { useProbateEstate } from '../helpers/probate-estate-context'
import SubsectionHeading from '../subsection-heading'

const Item = ({ item, index, listLength }: ItemProps) => {
  const { NOTES } = PROBATE_LEAD_FORM_VALUES
  const { withNamespace, withoutNamespace } = namespace(
    `businessOrAgriculturalAssets.${item.id}.`
  )
  const { updateItem, deleteItem } = useItems()

  const handleSave = ({ name, value }: HandleSaveProps) => {
    updateItem(
      {
        id: item.id,
        attributes: { [withoutNamespace(name)]: value || null },
      },
      PROBATE_ESTATE_ITEM_TYPES.BUSINESS_OR_AGRICULTURAL_ASSET
    )
  }

  const onTrashClick = () => {
    store.dispatch(
      showModal({
        component: DeleteModal,
        headingText: 'Delete business or agricultural asset',
        config: {
          deleteCallback: () => {
            deleteItem(item.id)
          },
          type: 'asset',
          resourceId: item.id,
        },
      })
    )
  }

  return (
    <EstateCard
      onTrashClick={onTrashClick}
      listLength={listLength}
      listPosition={index + 1}
      key={item.id}
      type="business or agricultural asset"
    >
      <Grid>
        <Grid.Item span={9}>
          <InputFloating
            name={withNamespace(PROBATE_LEAD_FORM_VALUES.DESCRIPTION)}
            label="Description"
            handleSave={handleSave}
          />
        </Grid.Item>
        <Grid.Item>
          <SelectInput
            name={withNamespace(PROBATE_LEAD_FORM_VALUES.OWNERSHIP)}
            label="Business type"
            options={[
              { label: 'Sole trader', value: 'sole_trader' },
              { label: 'Partnership', value: 'partnership' },
              { label: 'Limited company', value: 'limited_company' },
              { label: 'Other', value: 'other' },
            ]}
            handleSave={handleSave}
          />
        </Grid.Item>
        <Grid.Item>
          <H size="XS">Value of deceased's share of the business</H>
          <P size="S">
            This will be the deceased's share of the open market value of the
            business as at the date of death.
          </P>
          <P size="S">
            <strong>For a sole trader/ partnership</strong>: to find the value
            of the business assets and liabilities at the date of death, check
            the balance sheet in the accounts. Within the valuation include
            assets like business-owned premises, land, equipment, plant and
            machinery, motor vehicles.
          </P>
        </Grid.Item>
        <Grid.Item span={6}>
          <InputFloating
            name={withNamespace(PROBATE_LEAD_FORM_VALUES.VALUE)}
            label="Total value (£)"
            hint="Add the total value. For joint assets, the £ value of their share will be calculated automatically."
            handleSave={handleSave}
            isCurrency
          />
        </Grid.Item>
        <Grid.Item>
          <NotesInput name={withNamespace(NOTES)} handleSave={handleSave} />
        </Grid.Item>
      </Grid>
    </EstateCard>
  )
}

const ItemWithForm = withForm(Item)

const BusinessOrAgriculturalAssets = ({
  isComplete,
}: {
  isComplete: boolean
}) => {
  const { BUSINESS_OR_AGRICULTURAL_ASSETS } = PROBATE_LEAD_FORM_SECTIONS
  const { saveProbateEstateField } = useProbateEstate()
  const { groupedItems, createItem } = useItems()
  const { businessOrAgriculturalAsset: businessOrAgriculturalAssetItems = [] } =
    groupedItems
  const { total } = getItemsTotals(businessOrAgriculturalAssetItems)

  return (
    <SubsectionHeading
      disabled={businessOrAgriculturalAssetItems.length > 0}
      name={BUSINESS_OR_AGRICULTURAL_ASSETS}
      onToggle={saveProbateEstateField}
      scrollId="6.6"
      title="Business and agricultural assets"
      isComplete={isComplete}
    >
      <Grid.Item span={11}>
        <P size="S" data-testid="items-value-summary">
          Total: {total}
        </P>

        {businessOrAgriculturalAssetItems.map(
          (item: ProbateEstateItem, index: number) => (
            <ItemWithForm
              enableReinitialize={false}
              index={index}
              initialValues={{
                businessOrAgriculturalAssets: { [item.id]: item.attributes },
              }}
              item={item}
              key={item.id}
              listLength={businessOrAgriculturalAssetItems.length}
            />
          )
        )}
      </Grid.Item>
      <Grid.Item>
        <Button.Secondary
          style={{ paddingTop: GTR.XXS, paddingBottom: GTR.XXS }}
          type="button"
          onClick={() => {
            createItem({
              itemType:
                PROBATE_ESTATE_ITEM_TYPES.BUSINESS_OR_AGRICULTURAL_ASSET,
            })
          }}
        >
          Add business or agricultural asset
        </Button.Secondary>
      </Grid.Item>
    </SubsectionHeading>
  )
}

export default BusinessOrAgriculturalAssets
