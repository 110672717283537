import { useReducer, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import { Button, Grid, P } from '@farewill/ui'
import { GTR } from '@farewill/ui/tokens'

import useApi from 'lib/effects/api'
import apiRequest from 'lib/axios/api-request'
import { formatCurrency } from 'lib/formatting/currency'

import store from 'state/create-store'
import { showModal } from 'state/actions'

import EstateCard from 'components/estate-card'
import Property from './property'
import GridWithToggle from 'components/grid-with-toggle'

import DeleteModal from '../../components/delete-modal'
import TextSpacer from '../../components/text-spacer'

import { PROPERTY_SECTION_ACTIVE } from '../field-names'

const propertiesReducer = (state, action) => {
  switch (action.type) {
    case 'SET_PROPERTIES':
      return { ...state, properties: action.payload }
    case 'ADD_PROPERTY':
      return { ...state, properties: [...state.properties, action.payload] }
    case 'REMOVE_PROPERTY': {
      const properties = state.properties.filter(
        ({ id }) => id !== action.payload
      )
      return { ...state, properties }
    }
    case 'REPLACE_PROPERTY':
      return {
        ...state,
        properties: state.properties.map((property) => {
          if (property.id === action.payload.id) {
            return action.payload
          }
          return property
        }),
      }
    default:
      throw new Error()
  }
}

const Properties = ({ probateEstateId, saveProbateEstateField }) => {
  const [state, dispatch] = useReducer(propertiesReducer)

  const [
    { data: existingProperties, isLoading: isGetLoading },
    makeGetRequest,
  ] = useApi({ data: [], isLoading: true })

  useEffect(() => {
    const url = `/api/probate-estates/${probateEstateId}/properties`
    makeGetRequest({ url })
  }, [makeGetRequest, probateEstateId])

  useEffect(
    () => dispatch({ type: 'SET_PROPERTIES', payload: existingProperties }),
    [dispatch, existingProperties]
  )

  const [{ data: newProperty, isLoading: isCreateLoading }, makeCreateRequest] =
    useApi()

  useEffect(() => {
    if (newProperty) dispatch({ type: 'ADD_PROPERTY', payload: newProperty })
  }, [dispatch, newProperty])

  const replaceProperty = useCallback((updatedProperty) => {
    dispatch({ type: 'REPLACE_PROPERTY', payload: updatedProperty })
  }, [])

  const deleteProperty = (propertyId) => {
    const url = `/api/probate-estates/${probateEstateId}/properties/${propertyId}`
    return apiRequest({ url, method: 'DELETE' }).then(() => {
      dispatch({ type: 'REMOVE_PROPERTY', payload: propertyId })
    })
  }

  const handleTrashClick = (propertyId) => {
    store.dispatch(
      showModal({
        component: DeleteModal,
        headingText: 'Delete property',
        config: {
          deleteCallback: deleteProperty,
          type: 'property',
          resourceId: propertyId,
        },
      })
    )
  }

  const propertyTotals = (properties) => {
    let solelyOwned = 0
    let shareOfJointlyOwned = 0

    properties.forEach((property) => {
      const {
        value,
        ownership,
        jointOwnerRelationship,
        deceasedShareOfJointOwnership,
      } = property.attributes

      const shareCalculation = value * (deceasedShareOfJointOwnership / 100)

      if (ownership === 'sole') solelyOwned += value

      if (ownership === 'joint') {
        if (jointOwnerRelationship === 'other') {
          shareOfJointlyOwned += shareCalculation * 0.9
        } else {
          shareOfJointlyOwned += shareCalculation
        }
      }
    })

    const total = solelyOwned + shareOfJointlyOwned

    return {
      total: formatCurrency({ value: total }),
      solelyOwned: formatCurrency({ value: solelyOwned }),
      shareOfJointlyOwned: formatCurrency({ value: shareOfJointlyOwned }),
    }
  }

  if (isGetLoading) {
    return (
      <P size="L">
        Loading <strong>properties</strong>...
      </P>
    )
  }

  const { total, solelyOwned, shareOfJointlyOwned } = propertyTotals(
    state.properties
  )

  return (
    <GridWithToggle
      disabled={state.properties.length > 0}
      name={PROPERTY_SECTION_ACTIVE}
      onToggle={saveProbateEstateField}
      scrollId="4.1"
      title="Property"
    >
      <Grid.Item>
        <P size="S">
          Total: {total} <TextSpacer /> Solely owned: {solelyOwned}{' '}
          <TextSpacer /> Share of jointly owned: {shareOfJointlyOwned}
        </P>
        {state.properties.map((property, index) => (
          <EstateCard
            key={property.id}
            type="property"
            listPosition={index + 1}
            listLength={state.properties.length}
            onTrashClick={() => handleTrashClick(property.id)}
          >
            <Property
              initialValues={{
                properties: { [property.id]: property.attributes },
              }}
              probateEstateId={probateEstateId}
              propertyId={property.id}
              replaceProperty={replaceProperty}
            />
          </EstateCard>
        ))}
      </Grid.Item>
      <Grid.Item>
        <Button.Secondary
          style={{ paddingTop: GTR.XXS, paddingBottom: GTR.XXS }}
          loading={isCreateLoading}
          type="button"
          onClick={() => {
            makeCreateRequest({
              url: `/api/probate-estates/${probateEstateId}/properties`,
              method: 'POST',
              data: {
                data: { type: 'probate_estate_properties', attributes: {} },
              },
            })
          }}
        >
          Add property
        </Button.Secondary>
      </Grid.Item>
    </GridWithToggle>
  )
}

Properties.propTypes = {
  probateEstateId: PropTypes.string.isRequired,
  saveProbateEstateField: PropTypes.func.isRequired,
}

export default Properties
