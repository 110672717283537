import { Link } from 'react-router-dom'

import { P, Wrapper } from '@farewill/ui'
import { GTR } from '@farewill/ui/tokens'
import styled from 'styled-components'

import AdminIcon from 'components/admin-icon'
import ActivityTimestamp from 'components/activity-timestamp'

const StyledWrapper = styled(Wrapper)`
  display: flex;
  align-items: center;
  margin-bottom: ${GTR.L};
`

const Task = ({ task }) => {
  const { completedBy, completedAt, title } = task.attributes

  return (
    <StyledWrapper>
      <AdminIcon user={completedBy} size="L" />
      <Wrapper>
        <P margin={0} size="S">
          <strong>{completedBy.name}</strong> completed the task{' '}
          <Link to={`#task-${task.id}`}>
            <strong>{title}</strong>
          </Link>
        </P>
        <ActivityTimestamp datetime={completedAt} />
      </Wrapper>
    </StyledWrapper>
  )
}

export default Task
