import styled from 'styled-components'
import { Button, ButtonGroup, PersonIcon, Wrapper } from '@farewill/ui'
import { COLOR, FONT, GTR } from '@farewill/ui/tokens'
import { useFormikContext } from 'formik'
import { toast } from 'react-toastify'
import { useSelector } from 'react-redux'
import { useQueryClient } from 'react-query'

import useApiHelpers from 'lib/effects/api-helpers'
import { getUserIdFromToken } from 'lib/authentication/token'
import { formatAdminUserOptions } from 'utils/helpers'

import SelectInput from 'components/form/select-input'
import AdminIcon from 'components/admin-icon'
import Label from 'components/styled/label'

import { useTasksContext } from './context'
import Dropdown from 'components/dropdown'
import { TASK_ASSIGNMENT_ROLES } from './constants'

const StyledAssignTaskButton = styled(Button.Secondary)`
  && {
    padding: ${GTR.XS} ${GTR.L};
  }
`

const StyledRemoveAssigneeButton = styled(Button.Underline)`
  font-size: ${FONT.SIZE.S};
`

const StyledAdminIcon = styled(AdminIcon)`
  margin-right: 0;
`

const StyledTakeItButton = styled(Button)`
  font-size: ${FONT.SIZE.S};
`

const TaskAssignment = ({ task, fetchEventsForTask }) => {
  const { values } = useFormikContext()
  const cache = useQueryClient()
  const adminUsers = cache.getQueryData('adminUsers')
  const { updateTask } = useTasksContext()
  const adminUserId = useSelector((state) => getUserIdFromToken(state.token))
  const hasAssignee = task.attributes.currentAssignee
  const showAssignButton =
    task.attributes.currentAssigneeId !== values.currentAssigneeId
  const showTakeItButton = task.attributes.currentAssigneeId !== adminUserId
  const adminUserOptions = formatAdminUserOptions(adminUsers)

  const {
    createItem: createAssignment,
    isCreating: isCreatingAssignment,
    deleteItem: deleteTaskAssignment,
    isDeletingItem: isDeletingTaskAssignment,
  } = useApiHelpers({
    baseUrl: `/api/task-assignments`,
    type: 'task_assignments',
  })

  const handleCreateClick = async ({ adminUserId }) => {
    await createAssignment({
      adminUserId: adminUserId || values.currentAssigneeId,
      role: TASK_ASSIGNMENT_ROLES.ASSIGNED,
      taskId: task.id,
    })
    await updateTask(task.id, {})
    await fetchEventsForTask()
    toast('Task assigned.', {
      toastId: 'task-assigned',
    })
  }

  const handleDeleteClick = async () => {
    await deleteTaskAssignment(task.attributes.currentAssignmentId)
    await updateTask(task.id, {})
    await fetchEventsForTask()
    toast('Assignment removed.', {
      toastId: 'assignment-removed',
    })
  }

  return (
    <Dropdown
      updatedAt={task.attributes.updatedAt}
      trigger={
        hasAssignee ? (
          <StyledAdminIcon
            user={task.attributes.currentAssignee}
            enableTooltip
          />
        ) : (
          <PersonIcon color={COLOR.WHITE} background={COLOR.GREY.LIGHT} />
        )
      }
      content={
        <Wrapper padding="S">
          <Label>
            Select task owner{' '}
            {showTakeItButton && (
              <>
                or{' '}
                <StyledTakeItButton
                  type="button"
                  onClick={() => handleCreateClick({ adminUserId })}
                >
                  take it
                </StyledTakeItButton>
              </>
            )}
          </Label>
          <SelectInput
            name="currentAssigneeId"
            options={adminUserOptions}
            small
          />
          <ButtonGroup margin={['S', 0, 0]}>
            {showAssignButton && (
              <StyledAssignTaskButton
                type="button"
                onClick={handleCreateClick}
                loading={isCreatingAssignment}
              >
                Assign task
              </StyledAssignTaskButton>
            )}
            {hasAssignee && (
              <StyledRemoveAssigneeButton
                type="button"
                onClick={handleDeleteClick}
                loading={isDeletingTaskAssignment}
              >
                Remove assignee
              </StyledRemoveAssigneeButton>
            )}
          </ButtonGroup>
        </Wrapper>
      }
    />
  )
}

export default TaskAssignment
