import { FormEvent } from 'react'
import { Grid, H } from '@farewill/ui'
import { useFormikContext } from 'formik'

import { Input, SelectInput } from 'components/form'
import ContactEmailInput from 'components/form/contact-email-input'
import { Selected } from 'components/form/select-input/types'
import { LeadAttributes } from 'lib/models/lead'
import { SelectOptions } from 'lib/types/common'
import {
  FAREWILL_PRODUCTS,
  LEAD_SOURCE_TYPE_OPTIONS,
  LEAD_SOURCE_IDENTIFIER_OPTIONS,
  LEAD_SOURCE_IDENTIFIERS,
} from 'utils/enums'

import { useLeadContext } from '../context'
import PartnersAndDiscounts from '../partners-and-discounts'

const CallerDetails = (): React.ReactElement => {
  const {
    updateContact,
    lead: { attributes },
  } = useLeadContext()
  const { handleSubmit, values, setFieldValue } =
    useFormikContext<LeadAttributes>()

  const sourceIdentifierOptions: SelectOptions = attributes.funeralPlanId
    ? [
        {
          value: LEAD_SOURCE_IDENTIFIERS.funeral_plan_redemption,
          label: 'Funeral plan redemption',
        },
      ]
    : LEAD_SOURCE_IDENTIFIER_OPTIONS[
        values.sourceType as keyof typeof LEAD_SOURCE_IDENTIFIER_OPTIONS
      ]

  const resetSourceIdentifierAndSubmit = (value: {
    name: string
    value: Selected
  }) => {
    setFieldValue('sourceIdentifier', null)
    handleSubmit(value as unknown as FormEvent<HTMLFormElement>)
  }

  return (
    <Grid data-scroll-id="keyInfo">
      <Grid.Item>
        <H size="S">The caller</H>
      </Grid.Item>
      <Grid.Item spanFromL={2}>
        <Input label="Title" name="contact.title" handleSave={updateContact} />
      </Grid.Item>
      <Grid.Item spanFromL={4}>
        <Input
          label="First name"
          name="contact.firstName"
          handleSave={updateContact}
          allowCopyToClipboard
        />
      </Grid.Item>
      <Grid.Item spanFromL={6}>
        <Input
          label="Last name"
          name="contact.lastName"
          handleSave={updateContact}
          allowCopyToClipboard
        />
      </Grid.Item>
      <Grid.Item spanFromL={6}>
        <Input
          label="Phone number"
          name="contact.phoneNumber"
          handleSave={updateContact}
          allowCopyToClipboard
        />
      </Grid.Item>
      <Grid.Item spanFromL={6}>
        <ContactEmailInput
          contactId={values.contact.id}
          handleSave={async ({ name, value }) => {
            const updatedLeadResponse = await updateContact({ name, value })

            const updatedContact = updatedLeadResponse.data.attributes.contact

            /**
             * This means the email change has been recognised as already in use
             * and triggered the contact to be merged into an existing contact.
             * We need to update Formik's value for the contactId, otherwise it
             * will refer to the deleted contact.
             *
             * TODO: each product has its own version of this component so this
             * implementation is currently duplicated across all of them.
             */
            if (values.contact.id !== updatedContact.id) {
              setFieldValue('contactId', updatedContact.id)
              setFieldValue('contact', updatedContact)
            }
          }}
          hasRelatedAccount={!!values.contact.accountUuid}
          inputName="contact.email"
          allowCopyToClipboard
        />
      </Grid.Item>
      <Grid.Item spanFromL={6}>
        <SelectInput
          label="Lead Type"
          name="sourceType"
          handleSave={resetSourceIdentifierAndSubmit}
          options={LEAD_SOURCE_TYPE_OPTIONS}
        />
      </Grid.Item>
      {sourceIdentifierOptions && (
        <Grid.Item spanFromL={6}>
          <SelectInput
            label="Source"
            name="sourceIdentifier"
            options={sourceIdentifierOptions}
            handleSave={() => handleSubmit()}
          />
        </Grid.Item>
      )}
      <Grid.Item>
        <PartnersAndDiscounts product={FAREWILL_PRODUCTS.FUNERAL} />
      </Grid.Item>
    </Grid>
  )
}

export default CallerDetails
