import { Link } from 'react-router-dom'
import { Wrapper } from '@farewill/ui'
import styled, { css } from 'styled-components'
import { COLOR, BORDER, GTR } from '@farewill/ui/tokens'

const LabelStyles = css`
  display: block;
  margin-bottom: ${GTR.XS};
`

const StyledLink = styled(Link)`
  ${LabelStyles}
`

const StyledSpan = styled.span`
  ${LabelStyles}
`

const StyledBox = styled.div`
  background-color: ${COLOR.GREY.LIGHT};
  padding: ${GTR.S};
  border-radius: ${BORDER.RADIUS.S};
  color: ${COLOR.BLACK};
  min-height: 59px;
`

type Props = {
  className?: string
  label: string
  link?: string
  value: string | number | null
}

const FakeInput = ({
  className,
  label,
  link,
  value,
}: Props): React.ReactElement => (
  <Wrapper className={className}>
    {link ? (
      <StyledLink to={link}>{label}</StyledLink>
    ) : (
      <StyledSpan>{label}</StyledSpan>
    )}
    <StyledBox>{value}</StyledBox>
  </Wrapper>
)

export default FakeInput
