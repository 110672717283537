import { ReactElement } from 'react'
import styled from 'styled-components'
import { H, P, Wrapper } from '@farewill/ui'

import { ReasonId } from 'routes/lead/will-form/types'
import { REASONS } from 'routes/lead/will-form/constants'
import { WillLeadAttributes } from 'lib/models/lead'

const StyledList = styled('ul')`
  --bullet-size: 4px;
  list-style-type: none;

  li {
    margin-inline-start: 0.7em;
    padding-inline-start: calc(0.7em + var(--bullet-size));
    position: relative;
  }

  li::before {
    --optical-adjustment: 5px;
    background-color: currentColor;
    border-radius: var(--bullet-size);
    content: '';
    display: inline-block;
    height: var(--bullet-size);
    left: 0;
    position: absolute;
    top: calc(0.5em - (var(--bullet-size) / 2) + var(--optical-adjustment));
    width: var(--bullet-size);
  }
`

const Reasons = ({ reasons }: { reasons: ReasonId[] }): ReactElement => (
  <StyledList>
    {reasons.map((reasonId) => (
      <li key={reasonId}>{REASONS[reasonId]}</li>
    ))}
  </StyledList>
)

const formatResult = (
  triage: WillLeadAttributes['quoteAttributes']['triage']
): string | ReactElement => {
  if (!triage) {
    return 'The user did not take the quiz or see the new landing page.'
  }

  if (!('answers' in triage)) {
    return `User selected a ${triage.preselectedService.toLowerCase()} will, and did not take the quiz.`
  }

  if (triage.recommendedService === 'ESSENTIAL') {
    return 'The user took the quiz and was offered an essential will, but decided to request a callback instead.'
  }

  if (triage.preselectedService) {
    return (
      <>
        <P>
          User selected an essential will, but was offered a{' '}
          {triage.recommendedService.toLowerCase()} will because they:
        </P>
        <Reasons reasons={triage.reasons} />
      </>
    )
  }

  return (
    <>
      <P>
        User took the quiz and was offered a{' '}
        {triage.recommendedService.toLowerCase()} will because they:
      </P>
      <Reasons reasons={triage.reasons} />
    </>
  )
}

export const QuizResults = ({
  triage,
}: WillLeadAttributes['quoteAttributes']): ReactElement => {
  const result = formatResult(triage)

  return (
    <Wrapper separator>
      <H size="S">Quiz results</H>
      {typeof result === 'string' ? <P>{result}</P> : result}
      {triage && 'answers' in triage && triage.answers.PARTNER && (
        <P>
          They have a partner so check if they want to write their wills
          together.
        </P>
      )}
    </Wrapper>
  )
}
