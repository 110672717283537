import { strToBool } from 'utils/helpers'
import { UseSectionsCompleteProps } from '.'
import { StringBoolean } from 'lib/types/common'

const isGiftYearsComplete = (
  isYear: StringBoolean | undefined,
  yearValue: '' | number | undefined
) => {
  const requiredFields = strToBool(isYear) ? [yearValue] : []

  return (
    requiredFields.find((field) => field === '' || field === null) === undefined
  )
}

export const checkIsGiftsOver3kComplete = ({
  values,
}: Partial<UseSectionsCompleteProps>) => {
  if (!values) return false

  const {
    giftsGivenOver3kThreshold,
    giftsYearOne,
    giftsYearOneValue,
    giftsYearTwo,
    giftsYearTwoValue,
    giftsYearThree,
    giftsYearThreeValue,
    giftsYearFour,
    giftsYearFourValue,
    giftsYearFive,
    giftsYearFiveValue,
    giftsYearSix,
    giftsYearSixValue,
    giftsYearSeven,
    giftsYearSevenValue,
  } = values.quoteAttributes

  if (
    strToBool(giftsGivenOver3kThreshold) &&
    (!isGiftYearsComplete(giftsYearOne, giftsYearOneValue) ||
      !isGiftYearsComplete(giftsYearTwo, giftsYearTwoValue) ||
      !isGiftYearsComplete(giftsYearThree, giftsYearThreeValue) ||
      !isGiftYearsComplete(giftsYearFour, giftsYearFourValue) ||
      !isGiftYearsComplete(giftsYearFive, giftsYearFiveValue) ||
      !isGiftYearsComplete(giftsYearSix, giftsYearSixValue) ||
      !isGiftYearsComplete(giftsYearSeven, giftsYearSevenValue))
  ) {
    return false
  }

  const requiredFields = [giftsGivenOver3kThreshold]

  return (
    requiredFields.find((field) => field === '' || field === null) === undefined
  )
}

export const checkIsGiftsTheyBenefitFromComplete = ({
  values,
  groupedItems,
}: Partial<UseSectionsCompleteProps>) => {
  if (!values || !groupedItems) return false

  const { madeGiftsWithReservationOfBenefit } = values.quoteAttributes

  if (strToBool(madeGiftsWithReservationOfBenefit)) {
    const giftsFiltered = groupedItems?.giftBenefitted?.filter((item) => {
      const requiredFields = [item.attributes.value]
      return !(
        requiredFields.find((field) => field === '' || field === null) ===
        undefined
      )
    })

    if (giftsFiltered?.length > 0) {
      return false
    }
  }

  const requiredFields = [madeGiftsWithReservationOfBenefit]

  return (
    requiredFields.find((field) => field === '' || field === null) === undefined
  )
}

export const checkIsTrustsComplete = ({
  values,
  groupedItems,
}: Partial<UseSectionsCompleteProps>) => {
  if (!values || !groupedItems) return false

  const { benefittedFromTrust } = values.quoteAttributes

  if (strToBool(benefittedFromTrust)) {
    const trustsFiltered = groupedItems?.trustBenefitted?.filter((item) => {
      const requiredFields = [item.attributes.value]
      return !(
        requiredFields.find((field) => field === '' || field === null) ===
        undefined
      )
    })

    if (trustsFiltered?.length > 0) {
      return false
    }
  }

  const requiredFields = [benefittedFromTrust]

  return (
    requiredFields.find((field) => field === '' || field === null) === undefined
  )
}

export const checkIsComplexityComplete = ({
  values,
  groupedItems,
}: UseSectionsCompleteProps) => {
  if (
    !checkIsGiftsOver3kComplete({ values }) ||
    !checkIsGiftsTheyBenefitFromComplete({ values, groupedItems }) ||
    !checkIsTrustsComplete({ values, groupedItems })
  ) {
    return false
  }

  return true
}
