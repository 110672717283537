import { Link } from 'react-router-dom'
import { P, Wrapper } from '@farewill/ui'
import { GTR } from '@farewill/ui/tokens'
import styled from 'styled-components'

import { formatToHuman } from 'utils/helpers'
import { EMAIL_STATUSES } from 'utils/enums'
import ActivityTimestamp from 'components/activity-timestamp'
import BotIcon from 'components/bot-icon'

const StyledWrapper = styled(Wrapper)`
  display: flex;
  align-items: center;
  margin-bottom: ${GTR.L};
`

const StyledBotIcon = styled(BotIcon)`
  margin-right: ${GTR.M};
  flex-shrink: 0;
`

const TaskEmail = ({ email }) => {
  const { createdAt, probateCaseId, recipients, status, taskId, trigger } =
    email.attributes

  if (status !== EMAIL_STATUSES.PROCESSED) return null

  // Because of the async nature of the email queue we can optimistically
  // say processed emails were sent at the point they were created if the
  // recipients array is empty (meaning the recipients are still 'queued').
  const sentAt = recipients[0]?.sentAt || createdAt

  return (
    <StyledWrapper>
      <StyledBotIcon />
      <Wrapper>
        <P margin={0} size="S">
          <strong>Farewill Bot</strong> sent email{' '}
          <Link to={`/probate/cases/${probateCaseId}/activity#task-${taskId}`}>
            <strong>{formatToHuman(trigger.template.name)}</strong>
          </Link>
        </P>
        <ActivityTimestamp datetime={sentAt} />
      </Wrapper>
    </StyledWrapper>
  )
}

export default TaskEmail
