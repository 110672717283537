import { useCallback, useEffect, useState } from 'react'
import { ReactElement } from 'react-markdown/lib/react-markdown'
import styled from 'styled-components'
import { ArrowRightIcon } from '@farewill/ui'
import { FONT } from '@farewill/ui/tokens'

import AdminIcon from 'components/admin-icon'
import DateBadge from 'components/date-badge'
import StyledGridRow from 'components/list/grid-row'
import StyledLink from 'components/list/link'
import useAdminUserId from 'hooks/useAdminUserId'
import { Funeral } from 'lib/models/funeral'
import { LpaCaseAttributes } from 'lib/models/lpa-case'
import { ProbateCase, ProbateCaseAttributes } from 'lib/models/probate-case'
import { WillCase } from 'lib/models/will-case'
import { FAREWILL_PRODUCTS, FarewillProduct } from 'utils/enums'
import { nextTaskDueOnString } from 'utils/helpers'

import { getDaysFromToday } from '../helpers'
import { useKeyboardShortcutContext } from '../keyboard-shortcut-manager'
import { Case } from '../types'

import {
  getCaseIconColumn,
  getDeceasedNameColumn,
  getFuneralColumns,
  getLpaColumns,
  getNameColumn,
  getOwnerAssignmentColumn,
  getProbateColumns,
  getWillColumns,
  getWillComplexityColumn,
} from './columns'
import {
  getCaseLinkForProduct,
  getDaysInStageText,
  getIsLostOrDeclined,
} from './helpers'

const StyledGridColumn = styled.li`
  font-size: ${FONT.SIZE.S};
`

const Row = ({
  gridTemplateColumns,
  row,
  product,
  updateCase,
}: {
  gridTemplateColumns: string
  row: Case
  product: FarewillProduct
  updateCase: (id: number, attributes: unknown) => Promise<void>
}): ReactElement => {
  const loggedInAdminUserId = useAdminUserId()

  let dateString
  if ('nextTaskDueOn' in row.attributes) {
    dateString = nextTaskDueOnString(row.attributes?.nextTaskDueOn as string)
  }
  let isLostOrDeclined
  if ('status' in row.attributes) {
    isLostOrDeclined = getIsLostOrDeclined(row.attributes?.status as string)
  }
  const hideDateBadge = isLostOrDeclined && !dateString
  const caseLink = getCaseLinkForProduct(product, row.id)
  const [autoFocusField, setAutoFocusField] = useState<string | null>(null)

  const { createHandlers, registerShortcut, unregisterShortcut } =
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    useKeyboardShortcutContext() as any

  const handleShortcut = useCallback(
    (key) => {
      if (key === 'o') {
        setAutoFocusField('caseOwnerId')
      } else if (key === 'c') {
        setAutoFocusField('holidayCoverOwnerId')
      }
    },
    [setAutoFocusField]
  )

  useEffect(() => {
    registerShortcut('o', row.id, handleShortcut)
    registerShortcut('c', row.id, handleShortcut)

    return () => {
      unregisterShortcut('o', row.id, handleShortcut)
      unregisterShortcut('c', row.id, handleShortcut)
    }
  }, [handleShortcut, registerShortcut, row.id, unregisterShortcut])

  const onCaseOwnershipOpenChange = useCallback(() => {
    setAutoFocusField(null)
  }, [setAutoFocusField])

  if (!dateString) dateString = '⚠️ no due date ⚠️'

  const AdminIconColumn = (
    <li>
      <AdminIcon
        user={
          (row.attributes as ProbateCaseAttributes).currentOwner ||
          (row.attributes as LpaCaseAttributes).owner
        }
        enableTooltip
      ></AdminIcon>
    </li>
  )

  const CaseIconColumn = getCaseIconColumn(row as ProbateCase | WillCase)

  const DaysInStageColumn = (
    <StyledGridColumn>{getDaysInStageText(product, row)}</StyledGridColumn>
  )

  const DeceasedNameColumn = getDeceasedNameColumn(row as Funeral | ProbateCase)

  const LastActivityColumn = (
    <>
      <StyledGridColumn>
        {!isLostOrDeclined && (
          <>{getDaysFromToday(row.attributes.lastActivityAt)}</>
        )}
      </StyledGridColumn>
      <li>{!hideDateBadge && <DateBadge date={dateString} />}</li>
    </>
  )

  const NameColumn = getNameColumn({ product, row })

  const OwnerAssignmentColumn = getOwnerAssignmentColumn({
    autoFocusField,
    loggedInAdminUserId,
    onCaseOwnershipOpenChange,
    row: row as Funeral | WillCase,
    updateCase,
  })

  const WillComplexityColumn = getWillComplexityColumn(row as WillCase)

  const FuneralColumns = getFuneralColumns({
    row,
    DaysInStageColumn,
    DeceasedNameColumn,
    NameColumn,
    OwnerAssignmentColumn,
  })

  const LpaColumns = getLpaColumns({
    row,
    AdminIconColumn,
    LastActivityColumn,
    NameColumn,
  })

  const ProbateColumns = getProbateColumns({
    AdminIconColumn,
    CaseIconColumn,
    DaysInStageColumn,
    DeceasedNameColumn,
    LastActivityColumn,
    NameColumn,
  })

  const WillColumns = getWillColumns({
    CaseIconColumn,
    DaysInStageColumn,
    LastActivityColumn,
    NameColumn,
    OwnerAssignmentColumn,
    WillComplexityColumn,
  })

  return (
    <StyledLink to={caseLink} {...createHandlers(row.id)}>
      <StyledGridRow gridTemplateColumns={gridTemplateColumns}>
        {product === FAREWILL_PRODUCTS.FUNERAL && FuneralColumns}
        {product === FAREWILL_PRODUCTS.PROBATE && ProbateColumns}
        {product === FAREWILL_PRODUCTS.LPA && LpaColumns}
        {product === FAREWILL_PRODUCTS.WILL && WillColumns}
        <li>
          <ArrowRightIcon size="S" />
        </li>
      </StyledGridRow>
    </StyledLink>
  )
}

export default Row
