import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { useLeadContext } from 'routes/lead/context'
import MessageBox from 'components/message-box'

const AlwaysClickableLink = styled(Link)`
  pointer-events: auto;
`

const AccountDetails = () => {
  const { lead } = useLeadContext()
  const { funeralPlanId } = lead.attributes
  return (
    <>
      {funeralPlanId && (
        <MessageBox stretch margin={['L', 0, 0]}>
          This is the redemption of a funeral plan -{' '}
          <AlwaysClickableLink to={`/funeral-plans/${funeralPlanId}`}>
            Go to funeral plan
          </AlwaysClickableLink>
          .
        </MessageBox>
      )}
    </>
  )
}

export default AccountDetails
