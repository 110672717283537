import {
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useEffect,
} from 'react'
import PropTypes from 'prop-types'
import { Formik, useFormikContext } from 'formik'

import { formatValuesForFormik } from 'utils/helpers'

import { Context } from 'routes/probate-estate/context'
import {
  ProbateEstateItem,
  ProbateEstatePerson,
  ProbateEstateProperty,
} from 'lib/models/probate-estate'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const InnerForm = ({ component, ...rest }: { component: React.FC<any> }) => {
  const context = useContext(Context)
  const Component = component

  const { errors, setStatus, setTouched } = useFormikContext()

  useEffect(() => {
    if (context?.highlightEmpty) {
      setStatus({ highlightEmpty: context.highlightEmpty })
    }
  }, [context?.highlightEmpty, setStatus])

  useEffect(() => {
    setTouched(errors)
  }, [errors, setTouched])

  return <Component {...rest} />
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const withForm = (Component: React.FC<any>) => {
  const OuterForm = ({
    enableReinitialize = true,
    initialValues,
    ...rest
  }: {
    enableReinitialize?: boolean
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    initialValues: Record<string, any>
    index?: number
    person?: ProbateEstatePerson
    item?: ProbateEstateItem
    property?: ProbateEstateProperty
    listLength?: number
    listPosition?: number
    probateEstateId?: number
    estateItems?: Record<string, ProbateEstateItem[]>
    numberOfProperties?: number
    setTotalAmount?: Dispatch<SetStateAction<string>>
    extraFields?: ReactNode
    isComplete?: boolean
    checkIfComplete?: (name: string) => boolean
  }) => {
    const context = useContext(Context)
    return (
      <Formik
        enableReinitialize={enableReinitialize}
        initialStatus={{ highlightEmpty: context?.highlightEmpty }}
        initialValues={formatValuesForFormik(initialValues)}
        validateOnChange={false}
        validateOnMount
        onSubmit={() => undefined}
      >
        <InnerForm component={Component} {...rest} />
      </Formik>
    )
  }

  return OuterForm
}

withForm.propTypes = {
  Component: PropTypes.element.isRequired,
}

export default withForm
