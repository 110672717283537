import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect, useHistory, useLocation } from 'react-router'
import { Formik, Form } from 'formik'
import { Button, Grid, H, Wrapper } from '@farewill/ui'

import InputFloating from 'components/form/input-floating'
import ENV from 'config/environment'
import { login } from 'state/actions'
import { GTR } from '@farewill/ui/tokens'

const isReviewApp = ENV.APP_ENV === 'review'
const isTestApp = ENV.APP_ENV === 'test'

/** Documentation on how we use Sign In with Google is at https://www.notion.so/farewill/Logging-in-to-Backstage-UI-with-Google-57f222f47bb747778a5fded891c65b9d  */
const Login = (): React.ReactElement => {
  const usePassword = isReviewApp || isTestApp
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { google } = window as any
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()
  const token = useSelector((state: { token: string }) => state.token)
  const loginPending = useSelector((state: { requestsPending: string[] }) =>
    state.requestsPending.includes('LOGIN')
  )

  const handleLogin = useCallback(
    async ({
      credential,
      password,
    }: {
      credential?: string
      password?: string
    }): Promise<void> => {
      try {
        await dispatch(login({ google_id_token: credential, password }))
        history.push(location.from.pathname === '/' ? '/leads' : location.from)
      } catch (error) {
        console.error(error)
      }
    },
    [dispatch, history, location.from]
  )

  useEffect(() => {
    if (!usePassword && google) {
      google.accounts.id.initialize({
        client_id: ENV.GOOGLE_CLIENT_ID as string,
        callback: handleLogin,
      })

      google.accounts.id.renderButton(
        document.getElementById('googleLoginButton'),
        {
          type: 'standard',
          theme: 'outline',
          size: 'large',
          text: 'signin_with',
          shape: 'pill',
        }
      )
    }
  }, [usePassword, google, handleLogin])

  if (token && !loginPending) return <Redirect to="/leads" />

  if (usePassword) {
    return (
      <Grid padding={[GTR.XL, 0, 0]}>
        <Grid.Item spanFromM={4} startColumnFromM={5}>
          <H size="M">Log in to Backstage</H>
        </Grid.Item>
        <Grid.Item spanFromM={4} startColumnFromM={5}>
          <Formik
            initialValues={{
              password: isTestApp ? 'cypress' : '',
              credential: undefined,
            }}
            onSubmit={handleLogin}
          >
            <Form>
              {isReviewApp && (
                <Wrapper margin={[0, 0, 'L']}>
                  <InputFloating
                    label="Password"
                    name="password"
                    type="password"
                    hint="This saved in 1Password as ‘Backstage Review Apps’"
                  />
                </Wrapper>
              )}
              <Button.Primary type="submit">
                {isTestApp ? 'Log in' : 'Submit'}
              </Button.Primary>
            </Form>
          </Formik>
        </Grid.Item>
      </Grid>
    )
  }

  return (
    <Grid padding={[GTR.XL, 0, 0]}>
      <Grid.Item spanFromM={4} startColumnFromM={5}>
        <H size="M">Log in using your Farewill Google account</H>
      </Grid.Item>
      <Grid.Item spanFromM={4} startColumnFromM={5}>
        <div id="googleLoginButton"></div>
      </Grid.Item>
    </Grid>
  )
}

export default Login
