export const MADE_GIFTS_WITH_RESERVATION_OF_BENEFIT =
  'madeGiftsWithReservationOfBenefit'
export const GIFTS_WITH_RESERVATION_OF_BENEFIT_NOTES =
  'giftsWithReservationOfBenefitNotes'

export const HAD_RIGHT_TO_BENEFIT_FROM_TRUST_ASSETS =
  'hadRightToBenefitFromTrustAssets'
export const TRUST_ASSETS_AMOUNT = 'trustAssetsAmount'
export const GAVE_UP_RIGHT_TO_BENEFIT_FROM_TRUST_ASSETS =
  'gaveUpRightToBenefitFromTrustAssets'
export const TRUST_ASSETS_NOTES = 'trustAssetsNotes'

export const PAID_INSURNCE_PREMIUMS_ON_POLICY_PAID_TO_SOMEONE_ELSE =
  'paidInsurancePremiumsOnPolicyPaidToSomeoneElse'
export const INSURANCE_PREMIUM_NOTES = 'insurancePremiumsNotes'

export const DEFAULT_NUMBER_OF_GRANTS_REQUESTED =
  'defaultNumberOfGrantsRequested'
export const NUMBER_OF_GRANTS_REQUESTED = 'numberOfGrantsRequested'
export const OVERSEAS_GRANTS_REQUESTED = 'overseasGrantsRequested'
export const NUMBER_OF_OVERSEAS_GRANTS_REQUESTED =
  'numberOfOverseasGrantsRequested'

export const GIFTS_SECTION_ACTIVE = 'giftsSectionActive'
export const NOTES_NOT_COVERED_ELSEWHERE = 'notesNotCoveredElsewhere'
