import { P } from '@farewill/ui'
import { useFormikContext } from 'formik'

import { getMomentDate } from 'lib/formik/dates'

import { BIRTH, DEATH } from '../field-names'

const AgeAtDeath = () => {
  const { values } = useFormikContext()
  const dateOfBirth = getMomentDate({ prefix: BIRTH, values })
  const dateOfDeath = getMomentDate({ prefix: DEATH, values })

  if (!dateOfBirth || !dateOfDeath) return null

  const ageAtDeath = dateOfDeath.diff(dateOfBirth, 'years')
  return <P>Age at death: {ageAtDeath}</P>
}

export default AgeAtDeath
