import { MARRIED_OR_CIVIL_PARTNERSHIP } from 'utils/enums'
import { UseSectionsCompleteProps } from '.'
import { strToBool } from 'utils/helpers'

export const hasWillSectionComplete = (
  values: UseSectionsCompleteProps['values']
) => {
  const {
    willDraftedByProfessional,
    willHasIssues,
    willContested,
    willNumberOfExecutors,
    willLeftAllToPartnerOrCharity,
    willHasTrusts,
    totalBeneficiaries,
    hasBeneficiariesUnder18,
    willAmountPassingAbove325k,
    maritalStatus,
  } = values.quoteAttributes

  const isMarriedOrCivilPartnership =
    maritalStatus === MARRIED_OR_CIVIL_PARTNERSHIP

  // Some fields are required only if person is married or in civil partnership,
  // other only if they are not married or in civil partnership
  const requiredFieldsIfMarried = isMarriedOrCivilPartnership
    ? [willLeftAllToPartnerOrCharity]
    : [totalBeneficiaries, hasBeneficiariesUnder18]

  // Some of the fields are required only if the person has not left everything to their partner or charity
  const requiredFieldsIfWillLeftAllToPartnerOrCharity = strToBool(
    willLeftAllToPartnerOrCharity
  )
    ? []
    : [totalBeneficiaries, hasBeneficiariesUnder18, willAmountPassingAbove325k]

  // Combine all required fields
  const requiredFields = [
    willDraftedByProfessional,
    willHasIssues,
    willContested,
    willNumberOfExecutors,
    willHasTrusts,
  ].concat(
    requiredFieldsIfMarried,
    // only require these fields if married or civil partnership
    isMarriedOrCivilPartnership
      ? requiredFieldsIfWillLeftAllToPartnerOrCharity
      : []
  )

  // If any of required fields is empty, then the section is not complete
  return requiredFields.find((field) => field === '') === undefined
}

const hasIntestacySectionComplete = (
  values: UseSectionsCompleteProps['values']
) => {
  const {
    intestacyNumberOfAdministrators,
    totalBeneficiaries,
    hasBeneficiariesUnder18,
    intestacyPassingToCloseRelative,
    intestacyPassingToRemoteRelative,
    intestacyContested,
    intestacyAmountPassingToChildrenAbove325k,
    maritalStatus,
  } = values.quoteAttributes

  const conditionallyRequiredField =
    intestacyPassingToCloseRelative !== '' ||
    intestacyPassingToRemoteRelative !== ''

  const requiredFields = [
    intestacyNumberOfAdministrators,
    totalBeneficiaries,
    hasBeneficiariesUnder18,
    intestacyContested,
    maritalStatus === 'married_or_civil_partnership' &&
    intestacyPassingToCloseRelative === 'true'
      ? intestacyAmountPassingToChildrenAbove325k
      : null,
  ]

  return (
    requiredFields.find((field) => field === '') === undefined &&
    conditionallyRequiredField
  )
}

export const checkIsWillOrIntestacyComplete = ({
  values,
}: UseSectionsCompleteProps) => {
  const hadWill = values.quoteAttributes.hadWill

  if (hadWill === '') return false

  return strToBool(hadWill)
    ? hasWillSectionComplete(values)
    : hasIntestacySectionComplete(values)
}
