import { PARTNER_TYPE } from 'config/partners'
import { Partner } from 'lib/models/partner'

/**
 * Previously, we used a hybrid type in the API `CHARITY_OR_ORGANISATION` to
 * represent both charities and organisations who gift free wills.
 * We have now changed that to strictly use one or the other when returned from the database.
 * But we still need to differentiate between free will partners and "other" (commercial, not free will)
 * partners. This helper is intended to update that nuance.
 *
 * An organisation without charitable status is a "commercial partner" or `OTHER`
 * A charity or organisation with charitable status is `CHARITY`
 */

export const transformPartnerType = (partners: Partner[]) => {
  return partners.map((partner) => {
    if (partner.attributes.type === 'organisation') {
      return partner.attributes.hasExemptCharitableStatus
        ? {
            ...partner,
            attributes: { ...partner.attributes, type: PARTNER_TYPE.CHARITY },
          }
        : {
            ...partner,
            attributes: { ...partner.attributes, type: PARTNER_TYPE.OTHER },
          }
    }
    return { ...partner, type: PARTNER_TYPE.CHARITY }
  })
}
