import { ReactElement } from 'react'
import styled from 'styled-components'
import { Field, connect, FormikHandlers } from 'formik'
import { Wrapper } from '@farewill/ui'
import { COLOR, GTR, BORDER } from '@farewill/ui/tokens'
import Error from './error'

const COLORS = {
  blue: {
    background: COLOR.ACCENT.SECONDARY,
    border: COLOR.ACCENT.SECONDARY,
    shadow: COLOR.STATE.HIGHLIGHT,
  },
  green: {
    background: COLOR.STATE.SUCCESS,
    border: COLOR.STATE.SUCCESS,
    shadow: COLOR.STATE.SUCCESS_10,
  },
}

const Label = styled.label<{ disabled: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
  touch-action: manipulation;
  white-space: nowrap;

  ${({ disabled }) => disabled && `cursor: not-allowed;`}
`

const Input = styled(Field)`
  position: absolute;
  left: 0;
  top: 0;
  width: calc(${GTR.M} + 4px);
  height: calc(${GTR.M} + 4px);
  opacity: 0;
  cursor: pointer;

  &:disabled {
    cursor: not-allowed;
  }
`

const StyledCheckbox = styled.span<{
  $color: keyof typeof COLORS
  noLabel: boolean
}>`
  display: flex;
  width: ${GTR.M};
  max-width: ${GTR.M};
  height: ${GTR.M};
  flex: 0 0 ${GTR.M};
  margin-right: ${GTR.S};
  border: 2px solid ${COLOR.GREY.MEDIUM};
  border-radius: ${BORDER.RADIUS.S};
  background-repeat: no-repeat;
  background-position: center;
  background-size: 80%;
  background-color: ${COLOR.WHITE};

  ${Input}:focus + && {
    background-color: ${COLOR.WHITE};
    border-color: ${({ $color }) => COLORS[$color].border};
    box-shadow: 0 0 0 4px ${({ $color }) => COLORS[$color].shadow};
  }

  ${Input}:checked + && {
    background-image: url('/images/tick.svg');
    background-color: ${({ $color }) => COLORS[$color].background};
    border-color: ${({ $color }) => COLORS[$color].border};
  }

  ${Input}:disabled + && {
    background-color: ${COLOR.BACKGROUND.FOG};
  }

  ${Input}:disabled:checked + && {
    background-color: ${COLOR.GREY.LIGHT};
    border-color: transparent;
  }

  ${({ noLabel }) => noLabel && `margin-right: 0;`}
`

type SaveData = {
  name: string
  value: string
}

type CheckboxProps = {
  disabled?: boolean
  checked?: boolean
  color?: keyof typeof COLORS
  component?: string
  handleSave?: (data: SaveData) => void
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void
  value?: boolean
  label?: React.ReactNode
  useFormik?: boolean
  formik?: FormikHandlers
  name: string
}

const Checkbox = ({
  disabled = false,
  formik,
  color = 'blue',
  handleSave,
  label = '',
  name,
  onChange,
  useFormik = true,
  ...rest
}: CheckboxProps): ReactElement => (
  <Wrapper>
    <Label disabled={disabled}>
      <Input
        disabled={disabled}
        type="checkbox"
        name={name}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          e.persist()
          onChange && onChange(e)
          formik && formik.handleChange(e)

          const value = e.target.checked.toString()
          handleSave && handleSave({ name, value })
        }}
        {...rest}
      />
      <StyledCheckbox noLabel={!label} $color={color} />
      {label}
    </Label>
    <Error name={name} />
  </Wrapper>
)

export default connect(Checkbox)
