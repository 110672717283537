import styled from 'styled-components'
import { Wrapper } from '@farewill/ui'
import { BREAKPOINT, COLOR, GTR } from '@farewill/ui/tokens'
import { screenMax } from '@farewill/ui/helpers/responsive'

const StyledOuterWrapper = styled(({ isComplete, ...rest }) => (
  <Wrapper {...rest} />
))`
  width: 100vw;
  transform: translateX(calc(-100vw / 2 + ${BREAKPOINT.L}px / 2 - ${GTR.XL}));
  ${screenMax.l`transform: translateX(-${GTR.XL});`}
  ${screenMax.m`transform: translateX(-${GTR.L});`}
  ${screenMax.s`transform: translateX(-${GTR.M});`}

  &:first-of-type {
    margin-top: -${GTR.XL};
  }
  &:last-of-type {
    margin-bottom: -${GTR.XXL};
  }

  ${({ isComplete }) =>
    isComplete &&
    `
      background-color: ${COLOR.STATE.SUCCESS_10};
      border-width: 1px 0;
      border-style: solid;
      border-color: ${COLOR.GREY.LIGHT};
      margin-top: -1px;
      margin-bottom: -1px;

      &:first-of-type { margin-top: -${parseInt(GTR.XL) + 1}px; }
      &:last-of-type { margin-bottom: -${parseInt(GTR.XXL) + 1}px; }
  `}
`

const SectionWrapper = ({ children, isComplete, ...rest }) => (
  <StyledOuterWrapper isComplete={isComplete} {...rest}>
    <Wrapper container containerPaddingTop={0} containerPaddingBottom={0}>
      {children}
    </Wrapper>
  </StyledOuterWrapper>
)

export default SectionWrapper
