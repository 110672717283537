import PropTypes from 'prop-types'
import styled from 'styled-components'
import { P, Grid, Wrapper } from '@farewill/ui'
import { GTR } from '@farewill/ui/tokens'
import moment from 'moment'
import lowerCase from 'lodash/lowerCase'

import {
  FAREWILL_PRODUCTS,
  FarewillProduct,
  PROBATE_CASE_STATUSES,
  FUNERAL_PLANS_STATUSES,
} from 'utils/enums'
import { formatToHuman } from 'utils/helpers'
import { Task } from 'lib/models/task'
import { ProbateCaseAttributes } from 'lib/models/probate-case'
import { FuneralAttributes } from 'lib/models/funeral'
import { FuneralPlanAttributes } from 'lib/models/funeral-plan'
import { WillCaseAttributes } from 'lib/models/will-case'
import { LpaCaseAttributes } from 'lib/models/lpa-case'

import { Uppercase } from 'components/styled/uppercase'
import StatusBadge from 'components/status-badge'

import CustomerDetails from './customer-details'
import {
  ProbateExternalLinks,
  FuneralExternalLinks,
  WillExternalLinks,
} from './external-links'

export type Attributes =
  | ProbateCaseAttributes
  | FuneralAttributes
  | FuneralPlanAttributes
  | WillCaseAttributes
  | LpaCaseAttributes

const StyledWrapper = styled(Wrapper)`
  display: flex;
  align-items: center;
  margin-bottom: ${GTR.XS};
`

const StyledUppercase = styled(Uppercase)`
  margin: 0 ${GTR.XXS} 0 0;
`

const getDaysFromToday = (date: moment.MomentInput) => {
  const daysAgo = moment().diff(moment(date), 'days')

  if (daysAgo === 1) return '1 day ago'
  return `${daysAgo} days ago`
}

type ProductHeaderProps = {
  attributes: Attributes
  productType: FarewillProduct
  showDeceased?: boolean
  showExternalLinks?: boolean
  showLastActivityAt?: boolean
  showLastCallAt?: boolean
  tasks?: Task[]
}

const ProductHeader = ({
  attributes,
  productType,
  showDeceased,
  showExternalLinks,
  showLastActivityAt,
  showLastCallAt,
  tasks,
}: ProductHeaderProps): React.ReactElement => {
  const lastActivityAt =
    'lastActivityAt' in attributes ? attributes.lastActivityAt : undefined
  const lastCallAt = 'lastCallAt' in attributes && attributes.lastCallAt
  const status = 'status' in attributes && attributes.status
  const reference = 'reference' in attributes && attributes.reference

  const isProbate = productType === FAREWILL_PRODUCTS.PROBATE
  const isFuneral = productType === FAREWILL_PRODUCTS.FUNERAL
  const isFuneralPlan = productType === FAREWILL_PRODUCTS.FUNERAL_PLAN
  const isWill = productType === FAREWILL_PRODUCTS.WILL
  const lastActivity = `${getDaysFromToday(lastActivityAt)} (${moment(
    lastActivityAt
  ).format('D MMM YYYY')})`

  const lastCall = lastCallAt
    ? `${getDaysFromToday(lastCallAt)} (${moment(lastCallAt).format(
        'D MMM YYYY'
      )})`
    : 'No recent contact'

  const caseStatus = status
    ? isProbate
      ? PROBATE_CASE_STATUSES[status as keyof typeof PROBATE_CASE_STATUSES]
      : isFuneralPlan
      ? FUNERAL_PLANS_STATUSES[status as keyof typeof FUNERAL_PLANS_STATUSES]
      : lowerCase(status || '')
    : undefined

  return (
    <Grid>
      <Grid.Item span={7}>
        <StyledWrapper>
          <StyledUppercase>
            Customer &bull; {formatToHuman(productType)} &bull; {reference}{' '}
            &bull;
          </StyledUppercase>
          {caseStatus && <StatusBadge status={caseStatus} />}
        </StyledWrapper>
        <CustomerDetails attributes={attributes} showDeceased={showDeceased} />
        {showExternalLinks && isProbate && (
          <ProbateExternalLinks
            attributes={attributes as ProbateCaseAttributes}
          />
        )}
        {showExternalLinks && isFuneral && (
          <FuneralExternalLinks
            attributes={attributes as FuneralAttributes}
            tasks={tasks}
          />
        )}
        {showExternalLinks && isWill && (
          <WillExternalLinks attributes={attributes as WillCaseAttributes} />
        )}
        {showLastActivityAt && (
          <P size="S" margin={0}>
            Last activity: {lastActivity}
          </P>
        )}
        {showLastCallAt && (
          <P size="S" margin={['XS', 0, 0]}>
            Last contact: {lastCall}
          </P>
        )}
      </Grid.Item>
    </Grid>
  )
}

ProductHeader.propTypes = {
  attributes: PropTypes.object.isRequired,
  productType: PropTypes.string.isRequired,
  showExternalLinks: PropTypes.bool,
  showLastActivityAt: PropTypes.bool,
  showLastCallAt: PropTypes.bool,
}

ProductHeader.defaultProps = {
  showExternalLinks: true,
  showLastActivityAt: true,
  showLastCallAt: false,
}

export default ProductHeader
