import Dinero from 'dinero.js'

import { CustomerRelationship } from 'utils/enums'
import { QuoteAttributes } from 'components/funeral-quote-form-fields'

export const disputesQuestions = [
  {
    name: 'quoteAttributes.hasObjectionsToFuneral',
    label:
      'Has the caller confirmed they’re the decision maker and the family is in agreement?',
    options: [
      { value: 'true', label: 'Yes' },
      { value: 'false', label: 'No' },
    ],
  },
]

export const financialSupportQuestions = [
  {
    name: 'quoteAttributes.needsSupportFromDwp',
    label: 'Do they need financial support from DWP?',
    options: [
      { value: 'true', label: 'Yes' },
      { value: 'false', label: 'No' },
    ],
  },
]

type FormikBoolean = 'true' | 'false' | ''

export type LeadQuoteAttributes = QuoteAttributes & {
  alreadyDeceased: FormikBoolean
  ashesSplitting: FormikBoolean
  ashesSplittingPortions?: number | null
  attendedService?: FormikBoolean
  customerIsArrangingTheirOwnFuneral: FormikBoolean
  deceasedReferredToCoroner: FormikBoolean
  customerRefersToThemAs: string
  dateOfBirth: string
  dateOfDeath: string
  dwpSupportConfirmed: FormikBoolean
  title: string
  firstName: string
  lastName: string
  treeType?: string
  treePlaqueMessage?: string
  urnType?: string
  hasHazardousImplant: string | null
  hasObjectionsToFuneral: FormikBoolean
  needsSupportFromDwp: FormikBoolean
  relationshipToCustomer: CustomerRelationship | ''
}

export type FormikValues = {
  calculatedPrice: number
  quoteAttributes: LeadQuoteAttributes
  sourceIdentifier: string
  quotedPrices: {
    price: number
  }
  voucherCode?: string
}

type DisputeOutcome = {
  complete: boolean
  heading: string
  message: string
}

const DISPUTE_MESSAGES = {
  CANNOT_PROCEED: {
    complete: true,
    heading: 'Disputed',
    message:
      "We can't take on a case unless everyone in the family is in agreement",
  },
  CAN_PROCEED: {
    complete: true,
    heading: 'No disputes',
    message: 'Proceed to next section',
  },
}

export const hasAgreementToProceed = (
  values: FormikValues
): true | false | null => {
  const { hasObjectionsToFuneral } = values.quoteAttributes

  // Due to a bug, hasObjectionsToFuneral is 'true' when we can proceed and 'false' when we can't.
  // This is the opposite to what's expected.
  if (hasObjectionsToFuneral === 'true') {
    return true
  } else if (hasObjectionsToFuneral === 'false') {
    return false
  }

  return null
}

export const outcomeForDisputes = ({
  values,
}: {
  values: FormikValues
}): DisputeOutcome | Record<string, never> => {
  if (hasAgreementToProceed(values) === true) {
    return DISPUTE_MESSAGES.CAN_PROCEED
  } else if (hasAgreementToProceed(values) === false) {
    return DISPUTE_MESSAGES.CANNOT_PROCEED
  }

  return {}
}

const hasAnsweredFinancialSupport = (values: FormikValues) => {
  return ['true', 'false'].includes(values.quoteAttributes.needsSupportFromDwp)
}

export const skipPayment = ({
  values,
  isScotlandEnabled,
}: {
  values: FormikValues
  isScotlandEnabled: boolean
}): boolean => {
  if (hasAgreementToProceed(values) === false) {
    return true
  } else if (
    !quoteIsComplete({ values, isScotlandEnabled }) ||
    !hasAnsweredFinancialSupport(values) ||
    hasAgreementToProceed(values) == null
  ) {
    return true
  }

  return false
}

export const quoteIsComplete = ({
  values,
  isScotlandEnabled,
}: {
  values: FormikValues
  isScotlandEnabled: boolean
}): boolean => {
  const {
    deceasedReferredToCoroner,
    deceasedInMortuary,

    deathCountryId,
  } = values.quoteAttributes

  if (isScotlandEnabled) {
    return (
      (deceasedInMortuary === 'false' ||
        (deceasedInMortuary === 'true' && !!deceasedReferredToCoroner)) &&
      !!deathCountryId
    )
  }
  return (
    deceasedInMortuary === 'false' ||
    (deceasedInMortuary === 'true' && !!deceasedReferredToCoroner)
  )
}

export const formatPrice = (price: number): string => {
  return `+${Dinero({
    amount: price,
  }).toFormat('$0,0')}`
}

export const formatOptionalPrice = (price?: number): string => {
  if (!price) return '-'

  return formatPrice(price)
}

export const formatDistance = (distanceKm: number): string => {
  const distanceInMiles = Math.round(distanceKm / 1.609)

  if (distanceInMiles < 1) {
    return '< 1 mile'
  }

  if (distanceInMiles === 1) {
    return '1 mile'
  }

  return `${distanceInMiles} miles`
}
