import { ProbateLeadAttributes } from 'lib/models/lead'
import {
  ProbateEstate,
  ProbateEstateItem,
  ProbateEstatePerson,
  ProbateEstateProperty,
} from 'lib/models/probate-estate'
import { checkIsTheCallerComplete } from './section-the-caller'
import { checkIsPersonWhoHasDiedComplete } from './section-person-who-has-died'
import {
  checkIsAssetsComplete,
  checkIsBankAccountsComplete,
  checkIsBusinessOrAgriculturalAssetComplete,
  checkIsLifeInsuranceComplete,
  checkIsNSIComplete,
  checkIsOverseasAssetsComplete,
  checkIsPrivatePensionComplete,
  checkIsPublicShareholdingComplete,
  checkIsVehiclesOrPossesionsComplete,
} from './section-assets'
import {
  checkIsComplexityComplete,
  checkIsGiftsOver3kComplete,
  checkIsGiftsTheyBenefitFromComplete,
  checkIsTrustsComplete,
} from './section-complexity'
import { checkIsIsProbateNeededComplete } from './section-is-probate-needed'
import {
  checkIsCreditCardsComplete,
  checkIsFuneralCostsSectionComplete,
  checkIsLiabilitiesComplete,
  checkIsUtilitiesComplete,
} from './section-liability'
import { checkIsPropertiesComplete } from './section-properties'
import { checkIsWillOrIntestacyComplete } from './section-will-or-intestacy'
import { UseSectionsCompleteResponse } from './types'

export type UseSectionsCompleteProps = {
  values: ProbateLeadAttributes
  probateEstate: ProbateEstate
  groupedItems: Record<string, ProbateEstateItem[]>
  people: ProbateEstatePerson[]
  properties: ProbateEstateProperty[]
}

export const SECTION_NAMES = {
  THE_CALLER: '1. The caller',
  PERSON_WHO_HAS_DIED: '2. The person who has died',
  WILL_OR_INTESTACY: '3. Will or intestacy',
  IS_PROBATE_NEEDED: '4. Is probate needed',
  PROPERTIES: '5. Properties',
  ASSETS: '6. Assets',
  BANK_AND_BUILDING_SOCIETY_ACCOUNTS: '6.1 Bank and building society accounts',
  NS_AND_I: '6.2 NS&I',
  PUBLIC_SHAREHOLDINGS_AND_INVESTMENTS:
    '6.3 Public shareholdings and investments',
  LIFE_INSURANCE: '6.4 Life insurance',
  PRIVATE_PENSIONS: '6.5 Private pensions',
  BUSINESS_AND_AGRICULTURAL_ASSETS: '6.6 Business and agricultural assets',
  OVERSEAS_ASSETS: '6.7 Overseas assets',
  OTHER: '6.8 Other',
  LIABILITIES: '7. Liabilities',
  CREDIT_CARDS_AND_STORECARDS: '7.1 Credit cards and storecards',
  UTILITIES_AND_OTHER_DEBTS: '7.2 Utilities and other debts',
  FUNERAL_COSTS: '7.3 Funeral costs',
  COMPLEXITY: '8. Complexity',
  GIFTS_OVER_3000: '8.1 Gifts over £3,000',
  GIFTS_THEY_CONTINUED_TO_BENEFIT_FROM:
    '8.2 Gifts they continued to benefit from',
  TRUSTS: '8.3 Trusts',
} as const

type Section = {
  name: string
  scrollId: string
  subSections?: {
    subSectionsComplete: { name: string; scrollId: string }[] | []
    subSectionsIncomplete:
      | {
          name: string
          scrollId: string
        }[]
      | []
  }
}

const SECTIONS = [
  {
    name: SECTION_NAMES.THE_CALLER,
    scrollId: 'keyInfo',
    isComplete: checkIsTheCallerComplete,
  },
  {
    name: SECTION_NAMES.PERSON_WHO_HAS_DIED,
    scrollId: 'person-who-died',
    isComplete: checkIsPersonWhoHasDiedComplete,
  },
  {
    name: SECTION_NAMES.WILL_OR_INTESTACY,
    scrollId: 'will-or-intestacy',
    isComplete: checkIsWillOrIntestacyComplete,
  },
  {
    name: SECTION_NAMES.IS_PROBATE_NEEDED,
    scrollId: 'probate-required',
    isComplete: checkIsIsProbateNeededComplete,
  },
  {
    name: SECTION_NAMES.PROPERTIES,
    scrollId: 'properties',
    isComplete: checkIsPropertiesComplete,
  },
  {
    name: SECTION_NAMES.ASSETS,
    scrollId: 'assets',
    isComplete: checkIsAssetsComplete,
    subSections: [
      {
        name: SECTION_NAMES.BANK_AND_BUILDING_SOCIETY_ACCOUNTS,
        scrollId: '6.1',
        isComplete: checkIsBankAccountsComplete,
      },
      {
        name: SECTION_NAMES.NS_AND_I,
        scrollId: '6.2',
        isComplete: checkIsNSIComplete,
      },
      {
        name: SECTION_NAMES.PUBLIC_SHAREHOLDINGS_AND_INVESTMENTS,
        scrollId: '6.3',
        isComplete: checkIsPublicShareholdingComplete,
      },
      {
        name: SECTION_NAMES.LIFE_INSURANCE,
        scrollId: '6.4',
        isComplete: checkIsLifeInsuranceComplete,
      },
      {
        name: SECTION_NAMES.PRIVATE_PENSIONS,
        scrollId: '6.5',
        isComplete: checkIsPrivatePensionComplete,
      },
      {
        name: SECTION_NAMES.BUSINESS_AND_AGRICULTURAL_ASSETS,
        scrollId: '6.6',
        isComplete: checkIsBusinessOrAgriculturalAssetComplete,
      },
      {
        name: SECTION_NAMES.OVERSEAS_ASSETS,
        scrollId: '6.7',
        isComplete: checkIsOverseasAssetsComplete,
      },
      {
        name: SECTION_NAMES.OTHER,
        scrollId: '6.8',
        isComplete: checkIsVehiclesOrPossesionsComplete,
      },
    ],
  },
  {
    name: SECTION_NAMES.LIABILITIES,
    scrollId: 'liabilities',
    isComplete: checkIsLiabilitiesComplete,
    subSections: [
      {
        name: SECTION_NAMES.CREDIT_CARDS_AND_STORECARDS,
        scrollId: '7.1',
        isComplete: checkIsCreditCardsComplete,
      },
      {
        name: SECTION_NAMES.UTILITIES_AND_OTHER_DEBTS,
        scrollId: '7.2',
        isComplete: checkIsUtilitiesComplete,
      },
      {
        name: SECTION_NAMES.FUNERAL_COSTS,
        scrollId: '7.3',
        isComplete: checkIsFuneralCostsSectionComplete,
      },
    ],
  },
  {
    name: SECTION_NAMES.COMPLEXITY,
    scrollId: 'complexity',
    isComplete: checkIsComplexityComplete,
    subSections: [
      {
        name: SECTION_NAMES.GIFTS_OVER_3000,
        scrollId: '8.1',
        isComplete: checkIsGiftsOver3kComplete,
      },
      {
        name: SECTION_NAMES.GIFTS_THEY_CONTINUED_TO_BENEFIT_FROM,
        scrollId: '8.2',
        isComplete: checkIsGiftsTheyBenefitFromComplete,
      },
      {
        name: SECTION_NAMES.TRUSTS,
        scrollId: '8.3',
        isComplete: checkIsTrustsComplete,
      },
    ],
  },
] as {
  name: string
  scrollId: string
  isComplete: (props: UseSectionsCompleteProps) => boolean
  subSections?: {
    name: string
    scrollId: string
    isComplete: (props: UseSectionsCompleteProps) => boolean
  }[]
}[]

export const useSectionsComplete = ({
  values,
  probateEstate,
  groupedItems,
  people,
  properties,
}: UseSectionsCompleteProps): UseSectionsCompleteResponse => {
  const sectionsComplete: Section[] = []
  const sectionsIncomplete: Section[] = []

  SECTIONS.forEach((section) => {
    const subSectionsComplete: Pick<Section, 'name' | 'scrollId'>[] = []

    const subSectionsIncomplete: {
      name: string
      scrollId: string
    }[] = []

    section.subSections?.forEach((subSection) => {
      subSection.isComplete({
        values,
        probateEstate,
        groupedItems,
        people,
        properties,
      })
        ? subSectionsComplete.push({
            name: subSection.name,
            scrollId: subSection.scrollId,
          })
        : subSectionsIncomplete.push({
            name: subSection.name,
            scrollId: subSection.scrollId,
          })
    })

    section.isComplete({
      values,
      probateEstate,
      groupedItems,
      people,
      properties,
    })
      ? sectionsComplete.push({
          name: section.name,
          scrollId: section.scrollId,
          subSections: section.subSections
            ? {
                subSectionsComplete,
                subSectionsIncomplete,
              }
            : undefined,
        })
      : sectionsIncomplete.push({
          name: section.name,
          scrollId: section.scrollId,
          subSections: section.subSections
            ? {
                subSectionsComplete,
                subSectionsIncomplete,
              }
            : undefined,
        })
  })

  const checkIfComplete = (name: string) => {
    const allSections = sectionsComplete.concat(sectionsIncomplete)
    const isSection = allSections.some((section) => section.name === name)

    if (isSection) {
      return sectionsComplete.some((section) => section.name === name)
    }

    const isSubSection = allSections.some((section) => {
      const allSubsections = (
        section.subSections?.subSectionsIncomplete || []
      ).concat(section?.subSections?.subSectionsComplete || [])
      return allSubsections.some((subSection) => subSection.name === name)
    })

    if (isSubSection) {
      return !sectionsIncomplete.some((section) =>
        section.subSections?.subSectionsIncomplete.some(
          (subSection) => subSection.name === name
        )
      )
    }

    return false
  }

  return {
    allSectionsComplete: sectionsIncomplete.length === 0,
    sectionsComplete,
    sectionsIncomplete,
    checkIfComplete,
  }
}
