import { Fragment, ReactElement } from 'react'
import { Formik, Form, useFormikContext } from 'formik'
import styled from 'styled-components'
import { Divider, Grid, Wrapper } from '@farewill/ui'
import { COLOR, GTR } from '@farewill/ui/tokens'
import { paragraphS } from '@farewill/ui/helpers/text'
import * as yup from 'yup'

import FuneralQuoteFormFields, {
  QuoteAttributes,
} from 'components/funeral-quote-form-fields'
import StatusPanel from 'components/status-panel'
import { formatCurrency } from 'lib/formatting/currency'
import { Funeral, FuneralAttributes } from 'lib/models/funeral'
import {
  QuoteResult,
  QuoteAttributes as CreateQuoteAttributes,
} from 'lib/models/funerals/quote'
import { DIGNITY } from 'utils/enums'

import { SlimPrimaryButton } from '../buttons'
import AdditionalCharges from './additional-charges'
import {
  getQuoteAttributes,
  getUpdateAttributes,
  getSaveAttributes,
} from './data-handlers'
import { calculateChanges, QuoteChangeItem } from './helpers'

export type EditableQuoteProps = {
  funeral: Funeral
  funeralQuote: QuoteResult
  editedQuote?: QuoteResult
  onSave: (attributes: Partial<FuneralAttributes>) => Promise<void>
  createQuote: (attributes: Partial<CreateQuoteAttributes>) => Promise<void>
}

type FormValues = { quoteAttributes: QuoteAttributes }

const StyledList = styled.dl`
  display: grid;
  grid-template-columns: 1fr 1fr;
  color: ${COLOR.BLACK};
  ${paragraphS}
  margin: ${GTR.XS} 0;
`

const Text = styled.p`
  color: ${COLOR.BLACK};
  ${paragraphS}
  margin: 0;
`

const StyledDescription = styled.dt`
  margin: 0;
`

const StyledCost = styled.dd`
  margin: 0;
  font-feature-settings: 'tnum';
  text-align: right;
`

const validationSchema = yup.object({
  quoteAttributes: yup.object({
    attendedService: yup.bool().required('Select one of the options'),
    deceasedInMortuary: yup.bool().required('Select one of the options'),
    additionalCharges: yup.array().of(
      yup.object().shape({
        description: yup.string().required('Required'),
      })
    ),
  }),
})

export const EditableQuote = ({
  funeral,
  onSave,
  funeralQuote,
  editedQuote,
  createQuote,
}: EditableQuoteProps): ReactElement => {
  const { attributes } = funeral

  const quoteAttributes: QuoteAttributes = getQuoteAttributes(attributes)
  const isDignitySelected = attributes.provider === DIGNITY

  const handleSave = (changes: Partial<QuoteAttributes>) => {
    return onSave(getSaveAttributes(changes))
  }

  const handleUpdate = (changes: Partial<QuoteAttributes>) => {
    const updated = getUpdateAttributes(changes)
    return updated ? createQuote(updated) : undefined
  }

  /**
   * For funerals existing before the feature to allow multiple urns, an urn
   * would have been added on urnType rather than urnTypes. If this is the case,
   * we need to translate the value to urnTypes before getting the initial form
   * values so that the urn appears in the UI form.
   * There was a bug where both urnType and urnTypes could be set on the lead so
   * we only want to overwrite urnTypes if it has not been set yet.
   */
  if (quoteAttributes.urnType && !quoteAttributes.urnTypes?.length) {
    quoteAttributes.urnTypes = [quoteAttributes.urnType]
    quoteAttributes.urnType = null
  }

  return (
    <Formik
      initialValues={{ quoteAttributes }}
      onSubmit={(values) => {
        handleUpdate(values.quoteAttributes)
      }}
      validationSchema={validationSchema}
    >
      <Grid>
        <Grid.Item span={7}>
          <Form>
            <Grid>
              <FuneralQuoteFormFields
                isEditableQuote
                isDignitySelected={isDignitySelected}
              />
              <Grid.Item margin={['XL', 0, 0, 0]}>
                <AdditionalCharges />
              </Grid.Item>
            </Grid>
          </Form>
        </Grid.Item>
        <Grid.Item span={5}>
          <Changes
            onSave={handleSave}
            funeralQuote={funeralQuote}
            editedQuote={editedQuote}
          />
        </Grid.Item>
      </Grid>
    </Formik>
  )
}

export default EditableQuote

const formatChange = ({
  id,
  description,
  costDifference,
}: QuoteChangeItem): React.ReactElement => {
  return (
    <Fragment key={id}>
      <StyledDescription>{description}</StyledDescription>
      <StyledCost>{formatCostDifference(costDifference)}</StyledCost>
    </Fragment>
  )
}

type ChangesProps = {
  onSave: (changes: Partial<QuoteAttributes>) => Promise<void>
  funeralQuote: QuoteResult
  editedQuote?: QuoteResult
}

const Changes = ({ onSave, funeralQuote, editedQuote }: ChangesProps) => {
  const { values, isValid } = useFormikContext<FormValues>()
  const changes =
    editedQuote != null ? calculateChanges(funeralQuote, editedQuote) : null

  const items = changes?.items ?? []
  const hasChanges = items.length > 0
  const canSave = hasChanges && isValid

  const handleSave = () => onSave(values.quoteAttributes)

  return (
    <StatusPanel heading="Edit quote" sticky>
      {!hasChanges ? (
        <Text>No changes made</Text>
      ) : (
        <>
          <Text>{formatNumberOfChanges(items.length)}</Text>
          <StyledList>{items.map(formatChange)}</StyledList>
          <Divider margin={['S', 0]} />
          <StyledList>
            <StyledDescription>Revised quote:</StyledDescription>
            <StyledCost>
              {formatCurrency({
                value: editedQuote?.totalCost,
                valueInPence: true,
              })}
            </StyledCost>
          </StyledList>
        </>
      )}

      <Wrapper margin={['S', 0, 0, 0]}>
        <SlimPrimaryButton disabled={!canSave} stretch onClick={handleSave}>
          Save quote
        </SlimPrimaryButton>
      </Wrapper>
    </StatusPanel>
  )
}

function formatNumberOfChanges(num: number) {
  return `${num} change${num !== 1 ? 's' : ''} made`
}

function formatCostDifference(value: number | null | undefined) {
  if (value == null || value === 0) {
    return '-'
  }

  const formatted = formatCurrency({
    value,
    valueInPence: true,
  })

  return value > 0 ? `+${formatted}` : formatted
}
