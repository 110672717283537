import * as yup from 'yup'
import has from 'lodash/has'

import { parsePhoneNumberFromString } from 'libphonenumber-js'

import {
  getTransactionIdFromSquareUrl,
  getCallIdFromAircallUrl,
} from 'utils/helpers'
import {
  LEAD_SOURCE_IDENTIFIER_OPTIONS,
  LEAD_PARTNER_TYPES,
  LEAD_SOURCE_TYPES,
} from 'utils/enums'
import {
  CAUSE_AREAS,
  CONFIGURATION,
  reportTypes,
  PARTNER_PRODUCTS,
} from 'lib/models/partner'

export const DATE_REGEX = /([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/

// Regex used in type="email" from WHATWG
// https://html.spec.whatwg.org/multipage/input.html#e-mail-state-(type=email)
const EMAIL_REGEX =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/

const PAYMENT_REFERENCE_REGEX = /^[0-9a-zA-Z]+$/

const INVOICE_REFERENCE_REGEX = /INV-[0-9]+/

export const isValidEmail = (email: string | undefined | null): boolean =>
  email === null || email === undefined || !!EMAIL_REGEX.test(email)

const emailValidation = [
  'valid-email',
  'Email is invalid.',
  isValidEmail,
] as const

const isValidPaymentReference = (
  paymentReference: string | null | undefined
): boolean => {
  if (!paymentReference) return true
  const isValid =
    PAYMENT_REFERENCE_REGEX.test(paymentReference) &&
    paymentReference.length > 20
  return isValid
}

export const paymentReferenceValidation = [
  'valid-payment-reference',
  'Square payment reference is invalid. Make sure you have copied and pasted the payment reference from Square.',
  isValidPaymentReference,
] as const

const isValidInvoiceReference = (
  invoiceReference: string | null | undefined
): boolean => {
  if (!invoiceReference) return true
  const isValid =
    INVOICE_REFERENCE_REGEX.test(invoiceReference) &&
    invoiceReference.length === 8
  return isValid
}

export const invoiceReferenceValidation = [
  'valid-invoice-reference',
  'Invoice reference is invalid. Make sure you have copied and pasted the invoice reference from the invoice.',
  isValidInvoiceReference,
] as const

const isValidPhoneNumber = (phoneNumber: string | undefined): boolean => {
  if (!phoneNumber) return true
  return !!parsePhoneNumberFromString(phoneNumber, 'GB')?.isValid()
}

const phoneNumberValidation = [
  'valid-phone-number',
  'Phone number is invalid.',
  isValidPhoneNumber,
] as const

const integerValidation = yup
  .number()
  .typeError('Must be a number')
  .integer('Must be a whole number')

export const CONTACT_SCHEMA = yup.object().shape({
  phoneNumber: yup.string().test(...phoneNumberValidation),
  email: yup.string().test(...emailValidation),
})

export const FUNERAL_LEAD_SCHEMA = yup.object().shape({
  contact: yup.object().shape({
    phoneNumber: yup.string().test(...phoneNumberValidation),
    email: yup.string().test(...emailValidation),
  }),
  quoteAttributes: yup.object().shape({
    paymentReference: yup.string().test(...paymentReferenceValidation),
    invoice: yup
      .object()
      .nullable()
      .shape({
        externalReferenceId: yup.string().test(...invoiceReferenceValidation),
      }),
  }),
})

export const PROBATE_LEAD_SCHEMA = yup.object().shape({
  partnerType: yup
    .string()
    .oneOf([LEAD_PARTNER_TYPES.other_partner, LEAD_PARTNER_TYPES.none, null])
    .nullable(),
  partnerId: yup
    .string()
    .uuid()
    .nullable()
    .when('partnerType', {
      is: (partnerType: typeof LEAD_PARTNER_TYPES.other_partner) =>
        [LEAD_PARTNER_TYPES.other_partner].includes(partnerType),
      then: yup.string().nullable().required('Partner must be provided'),
      otherwise: yup.string().nullable(),
    }),
  contact: yup.object().shape({
    phoneNumber: yup.string().test(...phoneNumberValidation),
    email: yup.string().test(...emailValidation),
  }),
  quoteAttributes: yup.object().shape({
    factFindCallPhoneNumber: yup.string().test(...phoneNumberValidation),
    totalBeneficiaries: integerValidation,
    totalPropertiesTransferringToBeneficiary: integerValidation,
    totalAssetsAndDebts: integerValidation,
    numberOfProperties: integerValidation,
  }),
})

export const LPA_LEAD_SCHEMA = yup.object().shape({
  contact: yup.object().shape({
    phoneNumber: yup.string().test(...phoneNumberValidation),
    email: yup.string().test(...emailValidation),
  }),
  quoteAttributes: yup.object().shape({
    price: yup.number().positive().typeError('Price must be a number.'),
  }),
  squareTransactionUrl: yup
    .string()
    .nullable()
    .trim()
    .test(
      'isValid',
      'Must be a Square transaction URL',
      (value) => !value || !!getTransactionIdFromSquareUrl(value)
    ),
  partnerId: yup
    .string()
    .uuid()
    .nullable()
    .when('partnerType', {
      is: (partnerType: string) => partnerType !== LEAD_PARTNER_TYPES.none,
      then: yup.string().required('Partner must be provided'),
    }),
})

export const CUSTOMER_CALL_SCHEMA = yup.object().shape({
  externalAircallCallUrl: yup
    .string()
    .trim()
    .test(
      'isValid',
      'Must be an Aircall call URL',
      (value) => !value || !!getCallIdFromAircallUrl(value)
    ),
})

export const WILL_LEAD_SCHEMA = yup.object().shape({
  contact: yup.object().shape({
    phoneNumber: yup.string().test(...phoneNumberValidation),
    email: yup.string().test(...emailValidation),
  }),
  quoteAttributes: yup.object().shape({
    price: yup.number().min(0).typeError('Price must be 0 or greater.'),
    partner: yup.object().shape({
      email: yup.string().test(...emailValidation),
      phoneNumber: yup.string().test(...phoneNumberValidation),
    }),
  }),
  squareTransactionUrl: yup
    .string()
    .nullable()
    .trim()
    .test(
      'isValid',
      'Must be a Square transaction URL',
      (value) => !value || !!getTransactionIdFromSquareUrl(value)
    ),
  partnerId: yup
    .string()
    .uuid()
    .nullable()
    .when('partnerType', {
      is: (partnerType: string) => partnerType !== LEAD_PARTNER_TYPES.none,
      then: yup.string().required('Partner must be provided'),
    }),
})

export const NEW_LEAD_SCHEMA = yup.object().shape({
  product: yup.string().required('Product is required'),
  sourceType: yup.string().required('Type is required'),
  sourceIdentifier: yup
    .string()
    .when('sourceType', (sourceType, schema) =>
      has(LEAD_SOURCE_IDENTIFIER_OPTIONS, sourceType)
        ? schema.required('Source is required')
        : schema
    ),
  partnerType: yup
    .string()
    .required('Partner is required')
    .when(['sourceType'], {
      is: (sourceType: string) =>
        sourceType === LEAD_SOURCE_TYPES.external_referral,
      then: (schema) =>
        yup
          .string()
          .notOneOf(
            [LEAD_PARTNER_TYPES.none],
            'Please select the partner that referred this lead'
          ),
    }),
  partnerId: yup
    .string()
    .uuid()
    .nullable()
    .when('partnerType', {
      is: (partnerType: string) => partnerType !== LEAD_PARTNER_TYPES.none,
      then: (schema) => yup.string().required('Partner must be provided'),
    }),
})

export const NEW_DEPOSIT_INVOICE_SCHEMA = yup.object().shape({
  paidTo: yup
    .string()
    .required('Please select who the deposit is being paid to'),
})

export const LOST_OR_DECLINED_SCHEMA = yup.object().shape({
  caseLost: yup.object().shape({
    complete: yup.boolean(),
    metadata: yup.object().when('complete', {
      is: true,
      then: yup.object().shape({
        caseLostReason: yup.string().required('Reason case lost is required'),
      }),
    }),
  }),
  caseDeclined: yup.object().shape({
    complete: yup.boolean(),
    metadata: yup.object().when('complete', {
      is: true,
      then: yup.object().shape({
        caseDeclinedReason: yup
          .string()
          .required('Reason case declined is required'),
      }),
    }),
  }),
})

export const CASE_LIST_FILTERS_SCHEMA = yup.object().shape({
  nextTaskDueOn: yup.object().shape({
    option: yup.string().oneOf(['any', 'preset', 'custom']),
    gte: yup
      .string()
      .matches(DATE_REGEX)
      .when('option', {
        is: 'custom',
        then: (schema) => yup.string().required('Start date is required'),
      }),
    lte: yup
      .string()
      .matches(DATE_REGEX)
      .when('option', {
        is: 'custom',
        then: (schema) => yup.string().required('End date is required'),
      }),
  }),
})

export const LEAD_EVENT_FORM_SCHEMA = yup.object().shape({
  type: yup.string(),
  metadata: yup.object().when('type', {
    is: 'call_made',
    then: yup.object().shape({
      disposition: yup.string().required('Please select an outcome.'),
    }),
  }),
  leadChanges: yup.object().shape({
    scheduledNextCallAtTimeSet: yup.boolean(),
    scheduledNextCallDate: yup.string().nullable(),
  }),
})

const CASE_STATUS_REASON_FIELDS = yup.object().shape({
  reason: yup.string().required('Reason is required.'),
  reasonDescription: yup.string().when('reason', {
    is: 'other',
    then: (schema) => yup.string().required('Reason is required.'),
  }),
})

export const CASE_STATUS_TRANSITION_SCHEMA = yup.object().shape({
  status: yup.string(),
  open: yup.object().when('status', {
    is: 'open',
    then: (schema) => CASE_STATUS_REASON_FIELDS,
  }),
  closed: yup.object().when('status', {
    is: 'closed',
    then: (schema) => CASE_STATUS_REASON_FIELDS,
  }),
  cold: yup.object().when('status', {
    is: 'cold',
    then: (schema) => CASE_STATUS_REASON_FIELDS,
  }),
})

const CHARITY_SCHEMA = yup.object().shape({
  legalName: yup.string().trim().max(500).required('Legal name is required'),
  commonName: yup.string().nullable().trim().max(500),
  number: yup.string().trim().max(500).required('Charity number is required'),
})

const ORGANISATION_SCHEMA = yup.object().shape({
  name: yup.string().trim().max(500).required('Name is required'),
  address: yup.string().trim().max(1000).required('Address is required'),
  number: yup.string().nullable().trim().max(500),
  hasExemptCharitableStatus: yup
    .boolean()
    .required('Please state whether this organisation a charitable status'),
})

export const PARTNER_SCHEMA = yup.object().shape({
  type: yup.string().oneOf(['charity', 'organisation']).required(),
  utmSource: yup.string().trim().max(500).required('UTM source is required'),
  hubspotUrl: yup.string().trim().max(500).nullable(),
  causeArea: yup
    .string()
    .nullable()
    .oneOf([...CAUSE_AREAS, null]),
  charity: yup.object().when('type', {
    is: 'charity',
    then: CHARITY_SCHEMA,
    otherwise: yup.object().nullable(),
  }),
  organisation: yup.object().when('type', {
    is: 'organisation',
    then: ORGANISATION_SCHEMA,
    otherwise: yup.object().nullable(),
  }),
  partnerDataSharing: yup.string().oneOf(['enabled', 'disabled']),
  dataSharingReportType: yup.string().when('partnerDataSharing', {
    is: 'enabled',
    then: yup
      .string()
      .oneOf([...reportTypes])
      .typeError('Please choose one of the above to proceed.'),
    otherwise: yup.string().nullable(),
  }),
  products: yup
    .array()
    .ensure()
    .of(yup.string().oneOf(Object.values(PARTNER_PRODUCTS))),
  onlineWillConfiguration: yup
    .array()
    .ensure()
    .of(yup.string().oneOf(Object.values(CONFIGURATION))),
  logoUrl: yup.string().nullable().notRequired(),
  logo: yup.mixed().when('logoUrl', {
    is: (logoUrl: string) => !logoUrl,
    then: (schema) => schema.required('Please upload a logo'),
    otherwise: (schema) => schema.nullable().notRequired(),
  }),
  isGiftPrompt: yup.bool(),
  giftPromptDescription: yup
    .string()
    .nullable()
    .trim()
    .max(160, 'Please reduce the length of your description to 160 characters')
    .when('isGiftPrompt', {
      is: true,
      then: (schema) => schema.required('Please add a gift prompt description'),
      otherwise: (schema) => schema.notRequired(),
    }),
  giftPromptImageUrl: yup.string().notRequired().nullable(),
  giftPromptImage: yup.mixed().when('isGiftPrompt', {
    is: true,
    then: yup.mixed().when('giftPromptImageUrl', {
      is: (giftPromptImageUrl: string) => !giftPromptImageUrl,
      then: yup.mixed().required('Please upload a gift prompt image'),
      otherwise: yup.mixed().nullable().notRequired(),
    }),
    otherwise: yup.mixed().nullable().notRequired(),
  }),
})

export const EDIT_ACCOUNT_DETAILS_SCHEMA = yup.object().shape({
  email: yup
    .string()
    .email('Please enter a valid email address')
    .required('Please enter a new email address'),
  confirmEmail: yup
    .string()
    .when('email', (email, field) =>
      email
        ? field.oneOf(
            [yup.ref('email')],
            'Please enter a matching email address'
          )
        : field
    )
    .required('Please confirm the new email address'),
})

const PARTNER_ACCOUNT_PREFERENCES = {
  weeklyPledgeEmail: yup
    .boolean()
    .required('Select whether to subscribe to weekly pledge emails'),
  partnerDataSharingReports: yup
    .boolean()
    .required(
      'Select whether access is needed for monthly partner data sharing reports'
    ),
}

export const CREATE_PARTNER_ACCOUNT_SCHEMA = yup.object().shape({
  ...PARTNER_ACCOUNT_PREFERENCES,
  email: yup
    .string()
    .email('Enter a valid email address')
    .required('Enter a valid email address'),
})

export const UPDATE_PARTNER_ACCOUNT_SCHEMA = yup
  .object()
  .shape(PARTNER_ACCOUNT_PREFERENCES)
