import PropTypes from 'prop-types'
import { Grid } from '@farewill/ui'

import withForm from '../../components/with-form'
import InputFloating from 'components/form/input-floating'

import { DECEASED_FIRST_NAME, DECEASED_LAST_NAME } from '../field-names'

const FirstAndLastName = ({ saveProbateCaseField }) => (
  <Grid>
    <Grid.Item span={6}>
      <InputFloating
        name={DECEASED_FIRST_NAME}
        label="First and middle name"
        handleSave={saveProbateCaseField}
      />
    </Grid.Item>
    <Grid.Item span={6}>
      <InputFloating
        name={DECEASED_LAST_NAME}
        label="Last name"
        handleSave={saveProbateCaseField}
      />
    </Grid.Item>
  </Grid>
)

FirstAndLastName.propTypes = {
  saveProbateCaseField: PropTypes.func.isRequired,
}

export default withForm(FirstAndLastName)
