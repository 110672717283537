export const CORE_TASKS_IN_ORDER = {
  probate: [
    {
      name: 'sale',
      title: 'Sale',
      tasks: [
        { value: 'deposit_taken', label: 'Take deposit' },
        { value: 'fact_find_booked', label: 'Book fact find call' },
      ],
    },
    {
      name: 'fact_find',
      title: 'Fact find',
      tasks: [
        {
          value: 'fact_find_intro_call_made',
          label: 'Send introduction email',
        },
        { value: 'fact_find_call_completed', label: 'Complete fact find call' },
        { value: 'documents_received', label: 'Receive all documents' },
        { value: 'submit_referral', label: 'Submit referral' },
      ],
    },
    {
      name: 'referral',
      title: 'Referral',
      tasks: [
        { value: 'referral_checked', label: 'Check referral' },
        { value: 'fact_find_completed', label: 'Approve referral' },
        { value: 'referral_made', label: 'Make referral' },
      ],
    },
    {
      name: 'book_case',
      title: 'Book case',
      tasks: [
        {
          value: 'client_care_letter_drafted',
          label: 'Draft client care letter',
        },
        { value: 'terms_issued', label: 'Send terms of service' },
        {
          value: 'case_booked',
          label: 'Confirm terms accepted and case booked',
        },
        { value: 'id_check_completed', label: 'Complete ID check' },
      ],
    },
    {
      name: 'prepare_application',
      title: 'Prepare application',
      tasks: [
        { value: 'application_drafted', label: 'Draft application' },
        {
          value: 'draft_application_reviewed',
          label: 'Review draft application',
        },
        {
          value: 'documents_sent_to_customer',
          label: 'Send application to customer',
        },
      ],
    },
    {
      name: 'submit_application',
      title: 'Submit application',
      tasks: [
        {
          value: 'completed_documents_received_from_customer',
          label: 'Receive documents from customer',
        },
        {
          value: 'documents_from_customer_confirmed_correct',
          label: 'Review documents',
        },
        {
          value: 'application_submitted_to_registry',
          label: 'Submit application to probate registry',
        },
      ],
    },
    {
      name: 'hmrc',
      title: 'HM Revenue & Customs',
      tasks: [
        {
          value: 'documents_submitted_to_hmrc',
          label: 'Submit documents to HMRC',
        },
        {
          value: 'receipt_received_from_hmrc',
          label: 'Confirm receipt sent from HMRC to probate registry',
        },
        {
          value: 'clearance_request_sent_to_hmrc',
          label: 'Send clearance request to HMRC',
        },
        {
          value: 'clearance_received_from_hmrc',
          label: 'Receive clearance from HMRC',
        },
      ],
    },
    {
      name: 'receive_probate',
      title: 'Receive probate',
      tasks: [
        { value: 'grants_issued', label: 'Confirm grants issued' },
        { value: 'probate_received', label: 'Receive probate' },
        {
          value: 'probate_confirmed_correct',
          label: 'Check probate and confirm correct',
        },
        {
          value: 'probate_sent_to_customer',
          label: 'Send probate to customer',
        },
        {
          value: 'invoice_sent_to_customer',
          label: 'Send invoice to customer',
        },
      ],
    },
    {
      name: 'close_case',
      title: 'Close case',
      tasks: [
        { value: 'payment_received_in_full', label: 'Receive payment in full' },
        {
          value: 'accounts_checked_and_approved',
          label: 'Check and approve accounts',
        },
        { value: 'case_closed', label: 'Close case' },
      ],
    },
  ],
  lpa: [
    {
      name: 'sale',
      title: 'Sale',
      tasks: [
        { value: 'payment_received_in_full', label: 'Take payment' },
        { value: 'fact_find_booked', label: 'Book fact find call' },
      ],
    },
    {
      name: 'fact_find',
      title: 'Fact find',
      tasks: [
        { value: 'fact_find_call_completed', label: 'Complete fact find call' },
        {
          value: 'aircall_link_attached',
          label: 'Link to Aircall recording in Backstage',
        },
      ],
    },
    {
      name: 'prepare_application',
      title: 'Prepare application',
      tasks: [
        {
          value: 'lpa_generated_and_downloaded',
          label: 'Generate and download LPA',
        },
        {
          value: 'lpa_saved_to_customer_folder',
          label: 'Save LPA in customer folder',
        },
        {
          value: 'lpa_emailed_to_customer',
          label: 'Send customer email with LPA to review',
        },
        {
          value: 'confirmed_no_changes_needed',
          label: "Confirm customer doesn't need any changes to LPA",
        },
        {
          value: 'lpa_sent_to_print_house',
          label: 'Send LPA and cover letter to print house to be posted',
        },
        {
          value: 'customer_notified_of_posting',
          label: 'Email customer that LPA has been posted',
        },
      ],
    },
    {
      name: 'close_case',
      title: 'Close case',
      tasks: [
        {
          value: 'registration_reminder_sent',
          label: 'Remind customer to register the LPA with the OPG',
        },
        {
          value: 'nps_requested',
          label: 'Send NPS request',
        },
        {
          value: 'case_closed',
          label: 'Close case',
        },
      ],
    },
  ],
  funeral: [
    {
      name: 'sale',
      title: 'Sale',
      tasks: [
        {
          value: 'take_payment',
          label: 'Payment has been taken',
        },
        {
          value: 'fact_find_completed',
          label: 'Fact find has been completed',
        },
        {
          value: 'form_1_sent_to_customer',
          label: 'Form 1 has been sent to the customer',
        },
      ],
    },
    {
      name: 'urgent_collection',
      title: 'Urgent collection',
      tasks: [
        {
          value: 'urgent_complete_provider_form',
          label:
            'Provisionally complete provider form and email it to provider',
        },
        {
          value: 'organise_collection',
          label: 'Organise urgent collection with provider',
        },
      ],
    },
    {
      name: 'prepare_paperwork',
      title: 'Prepare paperwork',
      tasks: [
        { value: 'make_welcome_call', label: 'Make welcome call' },
        {
          value: 'ask_memorialisation_preferences',
          label: 'Ask memorialisation preferences',
        },
        { value: 'check_dressing_service', label: 'Check dressing service' },
        {
          value: 'form_1_received_and_checked',
          label: 'Receive and check signed Form 1',
        },
        { value: 'upload_form_1', label: 'Upload Form 1' },
        {
          value: 'send_form_1_confirmation_email',
          label: 'Send Welcome Email',
        },
        {
          value: 'green_/_form_6_received_and_checked',
          label: 'Receive and Check Green Form OR Form 6',
        },
        {
          value: 'upload_green_/_form_6',
          label: 'Upload Green Form OR Form 6',
        },
        {
          value: 'crem_4_received',
          label: 'Receive and check Crem 4',
        },
        {
          value: 'form_4_received_and_checked',
          label: 'Form 4 received and checked',
        },
        {
          value: 'form_4_uploaded',
          label: 'Form 4 uploaded',
        },
        { value: 'complete_provider_form', label: 'Complete provider form' },
        { value: 'send_provider_form', label: 'Upload/Send provider form' },
      ],
    },
    {
      name: 'confirm_collection_date',
      title: 'Confirm collection date',
      tasks: [
        {
          value: 'document_collection_date_in_backstage',
          label: 'Copy and confirm collection date',
        },
        {
          value: 'call_customer_to_confirm_collection',
          label: 'Call customer to confirm collection',
        },
        {
          value: 'send_collection_confirmation_email',
          label: 'Send collection confirmation email',
        },
      ],
    },
    {
      name: 'confirm_cremation_date',
      title: 'Confirm cremation date',
      tasks: [
        {
          value: 'document_cremation_date_in_backstage',
          label: 'Copy and confirm cremation date',
        },
        {
          value: 'call_customer_to_confirm_cremation',
          label: 'Call customer to confirm cremation date',
        },
        {
          value: 'send_cremation_confirmation_email',
          label: 'Send cremation confirmation email',
        },
      ],
    },
    {
      name: 'confirm_service_details',
      title: 'Confirm service details',
      tasks: [
        {
          value: 'book_funeral_with_crematorium',
          label: 'Book funeral date with crematorium',
        },
        { value: 'upload_preliminary_doc', label: 'Upload preliminary doc' },
        {
          value: 'send_funeral_confirmation_email',
          label: 'Send funeral confirmation email',
        },
        {
          value: 'notify_provider_of_any_extras',
          label: 'Confirm funeral date and notify provider of any extras',
        },
        {
          value: 'email_paperwork_to_crematorium',
          label: 'Email preliminary and funeral paperwork to crematorium',
        },
        {
          value: 'send_booking_form_to_provider',
          label: 'Send booking form to provider',
        },
        {
          value: 'make_arrangements_for_music_and_funeral',
          label: 'Make arrangements for music and funeral',
        },
      ],
    },
    {
      name: 'confirm_delivery_date',
      title: 'Confirm delivery date',
      tasks: [
        {
          value: 'document_delivery_date_in_backstage',
          label: 'Copy and confirm delivery date',
        },
        {
          value: 'send_delivery_email',
          label: 'Send delivery date confirmation email',
        },
      ],
    },
    {
      name: 'feedback_and_close_case',
      title: 'Feedback and close case',
      tasks: [
        { value: 'make_feedback_call', label: 'Make feedback call' },
        { value: 'send_delighted_email', label: 'Send NPS email' },
        { value: 'send_cross-sell_email', label: 'Send cross-sell email' },
        { value: 'receive_payment_in_full', label: 'Receive payment in full' },
        { value: 'close_case', label: 'Close case' },
      ],
    },
  ],
  will: [
    {
      name: 'sale',
      title: 'Sale',
      tasks: [
        {
          value: 'book_fact_find_appointment',
          label: 'Book fact find appointment',
        },
      ],
    },
    {
      name: 'pre_appointment_checklist',
      title: 'Pre-appointment checklist',
      tasks: [
        {
          value: 'receive_domicile_questionnaire',
          label: 'Receive domicile questionnaire',
        },
        {
          value: 'receive_conflict_of_interest_form',
          label: 'Receive conflict of interest form (if applicable)',
        },
        {
          value: 'receive_assets_and_liability_grid',
          label: 'Receive assets and liability grid',
        },
      ],
    },
    {
      name: 'fact_find',
      title: 'Fact find',
      tasks: [
        {
          value: 'complete_fact_find_appointment',
          label: 'Complete fact find appointment',
        },
        {
          value: 'log_appointment_details',
          label: 'Log appointment details and customer preferences',
        },
        {
          value: 'send_post_appointment_email',
          label: 'Send post-appointment email',
        },
        {
          value: 'log_will_features',
          label: 'Log will features and add will grade',
        },
        {
          value: 'receive_all_information',
          label:
            'Receive all information (completing this task sets documents due date)',
          automateDueDates: [
            {
              coreTask: 'send_documents_to_customer',
              days: 10,
              daysIfUrgent: 2,
            },
          ],
        },
      ],
    },
    {
      name: 'draft_documents',
      title: 'Draft documents',
      tasks: [
        { value: 'draft_will_documents', label: 'Draft will documents' },
        { value: 'log_drafting_time', label: 'Log drafting time' },
        { value: 'log_reporting_data', label: 'Log reporting data' },
      ],
    },
    {
      name: 'send_documents',
      title: 'Send documents',
      tasks: [
        {
          value: 'send_documents_to_customer',
          label:
            'Send documents to customer (completing this task sets case closed due date)',
          automateDueDates: [
            {
              coreTask: 'close_case',
              days: 10,
              daysIfUrgent: 2,
            },
          ],
        },
      ],
    },
    {
      name: 'close_case',
      title: 'Close case',
      tasks: [
        { value: 'close_case', label: 'Close case' },
        { value: 'send_nps_request', label: 'Send NPS request' },
      ],
    },
  ],
}

export const PRODUCTS_WITH_TASKS = Object.keys(CORE_TASKS_IN_ORDER)

export const TASK_ASSIGNMENT_ROLES = {
  ASSIGNED: 'assigned',
  HELP_REQUESTED: 'help_requested',
}
