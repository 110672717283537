export const IS_CUSTOMER = 'isCustomer'
export const IS_POINT_OF_CONTACT = 'isPointOfContact'
export const IS_PERSONAL_REPRESENTATIVE = 'isPersonalRepresentative'
export const IS_INCLUDED_IN_COMMS = 'isIncludedInComms'

export const FIRST_NAMES = 'firstNames'
export const LAST_NAME = 'lastName'
export const PREFERRED_NAME = 'preferredName'

export const NAME_MATCHES_WILL = 'nameMatchesWill'
export const NAME_MISMATCH_NOTES = 'nameMismatchNotes'
export const ADDRESS = 'address'
export const ADDRESS_MATCHES_WILL = 'addressMatchesWill'
export const ADDRESS_MISMATCH_NOTES = 'addressMismatchNotes'

export const DECEASED_RELATIONSHIP_TO_PERSON = 'deceasedRelationshipToPerson'
export const CUSTOM_DECEASED_RELATIONSHIP_TO_PERSON =
  'customDeceasedRelationshipToPerson'
export const PR_TYPE = 'personalRepresentativeType'
export const PR_ROLE = 'personalRepresentativeRole'
export const IS_ACTING_ON_BEHALF_OF_SOMEONE_ELSE =
  'isActingOnBehalfOfSomeoneElse'
export const IS_ACTING_ON_BEHALF_OF_SOMEONE_WITH_CAPACITY_TO_ACT =
  'isActingOnBehalfOfSomeoneWithCapacityToAct'
export const POWER_OF_ATTORNEY_OR_ORDER_TYPE = 'powerOfAttorneyOrOrderType'
export const POWER_OF_ATTORNEY_REGISTERED = 'powerOfAttorneyRegistered'
export const APPLYING_ON_BEHALF_OF = 'applyingOnBehalfOf'
export const NOTES = 'notes'

export const EMAIL = 'email'
export const PHONE_NUMBER = 'phoneNumber'
export const BIRTH = 'birth'
export const DEATH = 'death'

export const IS_INSOLVENT = 'isInsolvent'
export const AUTHORIZED_TO_DEAL_WITH_CASE = 'authorizedToDealWithCase'
export const AUTHORIZED_TO_BE_INCLUDED_IN_COMMS =
  'authorizedToBeIncludedInComms'

export const ALL_EXECUTORS_ADDED = 'allExecutorsAdded'

export const TITLE = 'title'
export const DECEASED_FIRST_NAME = 'deceasedFirstName'
export const DECEASED_LAST_NAME = 'deceasedLastName'

export const NAME_MATCHES_DEATH_CERTIFICATE = 'nameMatchesDeathCertificate'
export const KNOWN_AS = 'knownAs'

export const FULL_NAME = 'fullName'
export const EXAMPLE_ASSET = 'exampleAsset'

export const NAME_NOTES = 'nameNotes'

export const LAST_PERMANENT_ADDRESS = 'lastPermanentAddress'
export const LAST_PERMANENT_ADDRESS_MATCHES_WILL =
  'lastPermanentAddressMatchesWill'
export const LAST_PERMANENT_ADDRESS_MISMATCH_NOTES =
  'lastPermanentAddressMismatchNotes'
export const LAST_POSTAL_ADDRESS = 'lastPostalAddress'
export const IDENTIFICATION_NOTES = 'identificationNotes'
export const NATIONAL_INSURANCE_NUMBER = 'nationalInsuranceNumber'
export const OCCUPATION = 'occupation'

export const MARITAL_STATUS = 'maritalStatus'
export const SPOUSE_FIRST_NAMES = 'spouseFirstNames'
export const SPOUSE_LAST_NAME = 'spouseLastName'
export const MARRIAGE = 'marriage'
export const SPOUSE_DEATH = 'spouseDeath'
export const SPOUSE_IS_PERMANENT_UK_RESIDENT = 'spouseIsPermanentUkResident'
export const MARRIAGE_CERTIFICATE_REQUESTED = 'marriageCertificateRequested'
export const DECREE_ABSOLUTE_REQUESTED = 'decreeAbsoluteRequested'
export const MARITAL_STATUS_NOTES = 'maritalStatusNotes'
export const DECREE_ISSUED_BY = 'decreeIssuedBy'
export const SEPARATION = 'separation'

export const LIVING_CHILDREN_OVER_EIGHTEEN = 'livingChildrenOverEighteen'
export const LIVING_CHILDREN_UNDER_EIGHTEEN = 'livingChildrenUnderEighteen'
export const DECEASED_CHILDREN_OVER_EIGHTEEN = 'deceasedChildrenOverEighteen'
export const DECEASED_CHILDREN_UNDER_EIGHTEEN = 'deceasedChildrenUnderEighteen'
export const GRANDCHILDREN_WITH_DECEASED_PARENT =
  'grandchildrenWithDeceasedParent'
export const CHILDREN_NOTES = 'childrenNotes'
