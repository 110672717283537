import { ArrowDownIcon, P } from '@farewill/ui'
import SectionStatus from 'components/section-status'
import Dinero from 'dinero.js'
import { QuoteResponseDataProbate } from 'lib/types'
import styled from 'styled-components'
import { scrollToDataId } from 'lib/window/scroll'

const StyledLink = styled.a`
  text-decoration: underline;
  cursor: pointer;
`

const EstateValueBox = ({
  quote,
}: {
  quote: QuoteResponseDataProbate | null
}) => {
  const isComplex = quote?.attributes.grantOnly.isComplex
  const grossEstateValueInPence =
    quote?.attributes?.estateValues?.grossEstateValueInPence || 0
  const netEstateValueInPence =
    quote?.attributes?.estateValues?.netEstateValueInPence || 0
  const assetsValueInPence =
    quote?.attributes?.estateValues?.assetsValueInPence || 0
  const liabilitiesValueInPence =
    quote?.attributes?.estateValues?.liabilitiesValueInPence || 0

  return (
    <SectionStatus heading="Estate value summary" complete>
      {isComplex && <P size="S">This is complex estate</P>}
      <P size="S">
        Gross value:{' '}
        <strong>
          {Dinero({ amount: grossEstateValueInPence }).toFormat('$0,0')}
        </strong>
      </P>
      <P size="S">
        Net value:{' '}
        <strong>
          {Dinero({ amount: netEstateValueInPence }).toFormat('$0,0')}
        </strong>
      </P>
      <P size="S">
        <ArrowDownIcon inline size="S" />{' '}
        <StyledLink onClick={() => scrollToDataId('assets')}>
          Assets:
        </StyledLink>{' '}
        total value,{' '}
        <strong>
          {Dinero({ amount: assetsValueInPence }).toFormat('$0,0')}
        </strong>
      </P>
      <P size="S">
        <ArrowDownIcon inline size="S" />{' '}
        <StyledLink onClick={() => scrollToDataId('liabilities')}>
          Liabilities:
        </StyledLink>{' '}
        total value,{' '}
        <strong>
          {Dinero({ amount: liabilitiesValueInPence }).toFormat('$0,0')}
        </strong>
      </P>
    </SectionStatus>
  )
}

export default EstateValueBox
