import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Formik, Form, useFormikContext } from 'formik'
import { Button, ButtonGroup, P, Wrapper } from '@farewill/ui'

import EmailRecipients from 'components/email-recipients'
import EmailSentRecipients from 'components/email-sent-recipients'
import IndentedRadioGroup from 'components/form/indented-radio-group'
import Input from 'components/form/input'
import MessageBox from 'components/message-box'
import { hasStartEmailTrigger } from 'components/tasks-panel/helpers'
import useApi from 'lib/effects/api'
import { hideModal, updateProbateCase } from 'state/actions'
import {
  FAREWILL_LEGAL_SERVICES,
  NEW_LAW,
  LAW_FIRM_NAMES,
  LAW_FIRM_NAMES_OPTIONS,
} from 'utils/enums'
import { findDepositInvoice } from 'utils/helpers'

const DepositMismatchWarning = ({ probateCaseId }) => {
  const { values } = useFormikContext()
  const [{ data: invoices }, makeRequest] = useApi({ data: [] })

  useEffect(() => {
    makeRequest({ url: `/api/invoices?filter[probateCaseId]=${probateCaseId}` })
  }, [makeRequest, probateCaseId])

  const depositInvoice = findDepositInvoice(invoices)

  if (!depositInvoice || !values.referredTo) return null

  const { paidTo: depositPaidTo } = depositInvoice.attributes
  const showWarning = values.referredTo !== depositPaidTo

  if (!showWarning) return null

  return (
    <Wrapper margin={[0, 0, 'S']}>
      <MessageBox warning>
        A deposit has already been taken for {LAW_FIRM_NAMES[depositPaidTo]}
      </MessageBox>
    </Wrapper>
  )
}

const Modal = ({ config: { probateCase, referralMadeTask, fetchTasks } }) => {
  const dispatch = useDispatch()
  const referralAutomatedEmailSent = !!referralMadeTask.attributes.emails.length
  const referralComplete = !!referralMadeTask.attributes.completedAt
  const referralCompleteToNewLaw =
    referralComplete && probateCase.attributes.referredTo === NEW_LAW
  const referralTaskHasEmailTriggers = hasStartEmailTrigger(referralMadeTask)

  // Show referral email form if the referral task has associated start email triggers,
  // the case is being referred to FWLS,
  // the referral email hasn't been sent and
  // the referral is being made for the first time or the referral has already been made to New Law
  const showReferralEmailForm =
    referralTaskHasEmailTriggers &&
    !referralAutomatedEmailSent &&
    (!referralComplete || referralCompleteToNewLaw)

  const handleSubmit = async (values) => {
    const attributes = {
      referredTo: values.referredTo || null,
      sendReferralEmail:
        values.referredTo === FAREWILL_LEGAL_SERVICES
          ? values.sendReferralEmail
          : false,
    }

    try {
      await dispatch(updateProbateCase(probateCase.id, attributes))
      await fetchTasks({
        queryParams: {
          'filter[probateCaseId]': probateCase.id,
          'filter[isNeeded]': true,
          'page[size]': 200,
        },
      })
      dispatch(hideModal())
    } catch {
      dispatch(hideModal())
    }
  }

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={{
        referredTo: probateCase.attributes.referredTo || '',
        sendReferralEmail: 'true',
      }}
    >
      {({ values, isSubmitting }) => {
        const isBeingReferredToFwls =
          values.referredTo === FAREWILL_LEGAL_SERVICES
        const isBeingReferredToNewLaw = values.referredTo === NEW_LAW
        const { estateAdministrationRequired, lead } = probateCase.attributes
        const { estateAdmin, probateOnly } = lead.quotedPrices
        const hasPrice = estateAdministrationRequired
          ? !!estateAdmin
          : !!probateOnly

        return (
          <Form>
            <Wrapper margin={[0, 0, 'M']}>
              <Input
                label="Referred to"
                name="referredTo"
                component="select"
                options={LAW_FIRM_NAMES_OPTIONS}
              />
            </Wrapper>
            {isBeingReferredToNewLaw &&
              (hasPrice ? (
                <P>
                  Clicking "Confirm" will automatically send the pricing
                  spreadsheet to New Law by email.
                </P>
              ) : (
                <P>
                  ⚠️ Before clicking "Confirm", please email New Law with the
                  required information about this case. ⚠️
                </P>
              ))}
            {isBeingReferredToFwls && showReferralEmailForm && (
              <Wrapper margin={[0, 0, 'M']}>
                <IndentedRadioGroup
                  name="sendReferralEmail"
                  options={[
                    {
                      label: 'Send default email',
                      value: 'true',
                      indentedContent: (
                        <Wrapper margin={[0, 0, 0, 20]}>
                          <EmailRecipients
                            probateCaseId={probateCase.id}
                            probateEstateId={probateCase.attributes.estate.id}
                          />
                        </Wrapper>
                      ),
                    },
                    { label: 'Send custom email', value: 'false' },
                  ]}
                />
              </Wrapper>
            )}
            {referralAutomatedEmailSent && (
              <Wrapper margin={[0, 0, 'M']}>
                <EmailSentRecipients task={referralMadeTask} />
              </Wrapper>
            )}

            <DepositMismatchWarning probateCaseId={probateCase.id} />

            <ButtonGroup gap="S">
              <Button.Primary type="submit" loading={isSubmitting}>
                Confirm
              </Button.Primary>
              <Button.Underline onClick={() => dispatch(hideModal())}>
                Cancel
              </Button.Underline>
            </ButtonGroup>
          </Form>
        )
      }}
    </Formik>
  )
}

export default Modal
