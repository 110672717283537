import styled from 'styled-components'
import { Wrapper } from '@farewill/ui'
import { COLOR, GTR, BORDER_RADIUS } from '@farewill/ui/tokens'

const PinkBox = styled(({ stretch, ...rest }) => <Wrapper {...rest} />)`
  background-color: ${COLOR.STATE.ERROR_10};
  padding: ${GTR.S} ${GTR.XL} ${GTR.S} ${GTR.M};
  border-radius: ${BORDER_RADIUS.S};
  display: inline-block;

  ${({ stretch }) => stretch && `display: block;`}
`

export default PinkBox
