import { useEffect } from 'react'
import { Formik, useFormikContext } from 'formik'
import { Divider, P } from '@farewill/ui'
import merge from 'lodash/merge'
import isEqual from 'lodash/isEqual'

import useApi from 'lib/effects/api'
import usePrevious from 'lib/effects/use-previous'
import { PROBATE_LEAD_SCHEMA } from 'lib/formik/schemata'
import { ProbateLeadAttributes } from 'lib/models/lead'
import { QuoteResponseDataProbate } from 'lib/types'
import { formatValuesForFormik } from 'utils/helpers'

import QuoteForm from '../quote-form'
import { useLeadContext } from '../context'
import CallerDetails from './caller-details'
import ProbateRequired from './probate-required'
import TheWill from './the-will'
import Quotes from './quotes'
import PersonWhoDied from './person-who-died'
import Assets from './assets'
import Liabilities from './liabilities'
import Complexity from './complexity'
import { withProvider } from 'routes/probate-estate/context'
import Properties from './properties'
import {
  ProbateEstateProvider,
  useProbateEstate,
} from './helpers/probate-estate-context'
import { PeopleProvider, usePeople } from './helpers/people-context'
import { ItemsProvider, useItems } from './helpers/items-context'
import { PropertiesProvider, useProperties } from './helpers/properties-context'
import { SECTION_NAMES, useSectionsComplete } from './helpers/complete-sections'
import QuoteReadyBox from './quote-ready-box'

const BLANK_VALUES = {
  unsuitableReason: '',
  blockedReason: '',
  contact: {
    email: '',
    firstName: '',
    lastName: '',
  },
  quoteAttributes: {
    // key-info
    factFindCallPhoneNumber: '',
    relationshipToCustomer: '',
    customerRefersToThemAs: '',
    otherRelationshipDescription: '',
    // probate-required
    customerAlreadyKnowsProbateRequired: '',
    aboveAccountOrShareThreshold: '',
    ownedProperty: '',
    propertyOwnershipType: '',
    // able-to-apply
    hadWill: '',
    customerIsExecutor: '',
    customerIsAdministrator: '',
    customerIsNextOfKin: '',
    // quote
    dateOfDeath: '',
    dateOfBirth: '',
    maritalStatus: '',
    leftAllToPartner: '',
    aboveEstateValueThreshold: '',
    aboveEstatePassingThreshold: '',
    widowSpouseLeftEverythingInWill: '',
    livedInEnglandOrWales: '',
    giftsGivenOverThreshold: '',
    giftsGivenOver3kThreshold: '',
    giftsYearOne: false,
    giftsYearTwo: false,
    giftsYearThree: false,
    giftsYearFour: false,
    giftsYearFive: false,
    giftsYearSix: false,
    giftsYearSeven: false,
    giftsYearEight: false,
    giftsYearOneValue: '',
    giftsYearTwoValue: '',
    giftsYearThreeValue: '',
    giftsYearFourValue: '',
    giftsYearFiveValue: '',
    giftsYearSixValue: '',
    giftsYearSevenValue: '',
    giftsYearEightValue: '',
    madeGiftsWithReservationOfBenefit: '',
    hadBusinessOrAgriculturalAssets: '',
    totalBeneficiaries: '',
    willHasTrusts: '',
    benefittedFromTrust: '',
    // estate-admin
    interestedInEstateAdmin: '',
    // book-fact-find
    factFindCallAt: '',
    // next steps
    deceasedTitle: '',
    deceasedFirstName: '',
    deceasedLastName: '',
    willDraftedByProfessional: '',
    willHasIssues: '',
    willContested: '',
    customerIsNotExecutorNotes: '',
    willLeftAllToPartnerOrCharity: '',
    hasBeneficiariesUnder18: '',
    willAmountPassingAbove325k: '',
    willNumberOfExecutors: '',
    intestacyNumberOfAdministrators: '',
    intestacyPassingToRemoteRelative: '',
    intestacyPassingToCloseRelative: '',
    intestacyContested: '',
    intestacyAmountPassingToChildrenAbove325k: '',
    funeralBillAmount: '',
    funeralWakeAmount: '',
    funeralFlowersAmount: '',
    funeralOtherAmount: '',
    funeralAmountNotes: '',
    funeralHadPrePaidPlan: '',
    funeralHasBeenPaid: '',
    funeralAmountCoveredByPlan: '',
    propertyOwnershipNeedsProbate: '',
    hasMadeFuneralArrangements: '',
    ihtType: '',
    funeralCostKnown: '',
  },
}

const InnerForm = ({
  leadId,
  disabled,
}: {
  leadId: number
  disabled: boolean
}) => {
  const { handleSubmit, setFieldValue, values } =
    /* Unfortunately, we use type "any" here as we don't yet have a type for the formatted object returned by formatValuesForFormik  */
    /* eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any */
    useFormikContext<any>()

  const [{ data: quote }, makeRequest] = useApi<QuoteResponseDataProbate>()
  const prevQuote = usePrevious(quote) as unknown as QuoteResponseDataProbate
  const { lead } = useLeadContext()
  const prevLead = usePrevious(lead)
  const hasLeadChanged = !isEqual(lead, prevLead)
  const { probateEstate, probateEstateId, isLoading } = useProbateEstate()
  const { groupedItems } = useItems()
  const prevGroupedItems = usePrevious(groupedItems)
  const { people } = usePeople()
  const { properties } = useProperties()
  const prevProperties = usePrevious(properties)
  const hasPropertiesChanged = !isEqual(properties, prevProperties)
  const hasItemsChanged = !isEqual(groupedItems, prevGroupedItems)
  const {
    allSectionsComplete,
    sectionsComplete,
    sectionsIncomplete,
    checkIfComplete,
  } = useSectionsComplete({
    values,
    probateEstate,
    groupedItems,
    people,
    properties,
  })

  useEffect(() => {
    if (
      allSectionsComplete &&
      (hasLeadChanged || hasPropertiesChanged || hasItemsChanged)
    ) {
      const url = '/api/quotes'
      makeRequest({
        url,
        method: 'POST',
        data: {
          data: {
            type: 'quotes',
            attributes: {
              product: 'probate',
              leadId,
              showEstateAdminBreakdown: true,
            },
          },
        },
      })
    }
  }, [
    allSectionsComplete,
    hasLeadChanged,
    hasItemsChanged,
    hasPropertiesChanged,
    leadId,
    makeRequest,
  ])

  useEffect(() => {
    if (quote && !isEqual(quote, prevQuote)) {
      const { grantOnly, estateAdmin, ihtForm } = quote.attributes
      setFieldValue('quotedPrices.estateAdmin', estateAdmin?.amountInPence)
      setFieldValue('quotedPrices.probateOnly', grantOnly?.amountInPence)
      setFieldValue('quoteAttributes.ihtForm', ihtForm)
      handleSubmit()
    }
  }, [quote, prevQuote, setFieldValue, handleSubmit])

  if (!probateEstate || isLoading) return <P size="L">Loading...</P>

  return (
    <QuoteForm disabled={disabled}>
      {probateEstateId && (
        <QuoteReadyBox
          quote={quote}
          completedSections={{
            allSectionsComplete,
            sectionsComplete,
            sectionsIncomplete,
          }}
        />
      )}

      <CallerDetails
        leadId={leadId}
        isComplete={checkIfComplete(SECTION_NAMES.THE_CALLER)}
      />
      <Divider margin={['XL', 0]} />
      <PersonWhoDied
        isComplete={checkIfComplete(SECTION_NAMES.PERSON_WHO_HAS_DIED)}
      />
      <Divider margin={['XL', 0]} />
      <TheWill isComplete={checkIfComplete(SECTION_NAMES.WILL_OR_INTESTACY)} />
      <Divider margin={['XL', 0]} />
      <ProbateRequired
        isComplete={checkIfComplete(SECTION_NAMES.IS_PROBATE_NEEDED)}
      />
      <Divider margin={['XL', 0]} />
      <Properties isComplete={checkIfComplete(SECTION_NAMES.PROPERTIES)} />
      <Divider margin={['XL', 0]} />
      <Assets
        isComplete={checkIfComplete(SECTION_NAMES.ASSETS)}
        checkIfComplete={checkIfComplete}
      />
      <Divider margin={['XL', 0]} />
      <Liabilities
        isComplete={checkIfComplete(SECTION_NAMES.LIABILITIES)}
        checkIfComplete={checkIfComplete}
      />
      <Divider margin={['XL', 0]} />
      <Complexity
        isComplete={checkIfComplete(SECTION_NAMES.COMPLEXITY)}
        checkIfComplete={checkIfComplete}
      />
      <Divider margin={['XL', 0]} />
      {probateEstateId && (
        <Quotes
          quote={quote}
          completedSections={{
            allSectionsComplete,
            sectionsComplete,
            sectionsIncomplete,
          }}
        />
      )}
    </QuoteForm>
  )
}

const ProbateForm = ({
  leadId,
  attributes,
  disabled = false,
}: {
  leadId: number
  attributes: ProbateLeadAttributes
  disabled: boolean
}) => {
  const formattedValues = formatValuesForFormik(attributes)
  const initialValues = merge({}, BLANK_VALUES, formattedValues)
  const probateEstateId = attributes.probateEstate?.id
  const { onSubmit } = useLeadContext()

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={PROBATE_LEAD_SCHEMA}
      validateOnMount
      validateOnChange={false}
    >
      <ProbateEstateProvider probateEstateId={probateEstateId}>
        <PeopleProvider probateEstateId={probateEstateId}>
          <ItemsProvider probateEstateId={probateEstateId}>
            <PropertiesProvider probateEstateId={probateEstateId}>
              <InnerForm leadId={leadId} disabled={disabled} />
            </PropertiesProvider>
          </ItemsProvider>
        </PeopleProvider>
      </ProbateEstateProvider>
    </Formik>
  )
}

export default withProvider(ProbateForm)
