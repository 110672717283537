import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import DropZone from './drop-zone'
import DocumentCard from './document-card'
import { useDocumentsContext } from './context'
import { arrayMoveImmutable } from 'array-move'
import { getGoogleDriveFileIdFromUrl } from 'utils/helpers'

const Upload = ({ probateCaseId }) => {
  const [files, setFiles] = useState([])
  const [isGoogleDriveMode, setGoogleDriveMode] = useState(false)
  const {
    createDocument,
    updateDocument,
    attachFiles,
    createFile,
    isUploading,
  } = useDocumentsContext()

  const handleFileOrderChanged = (oldIndex, newIndex) => {
    setFiles(arrayMoveImmutable(files, oldIndex, newIndex))
  }

  const handleFilesDropped = (files) => {
    setFiles(
      files.map((file) =>
        Object.assign(file, { preview: URL.createObjectURL(file) })
      )
    )
  }

  const handleCancel = () => {
    files.forEach((file) => URL.revokeObjectURL(file.preview))
    setFiles([])
    setGoogleDriveMode(false)
  }

  const handleSubmit = async (values) => {
    const createdDocument = await createDocument({
      ...values,
      probateCaseId,
    })

    if (isGoogleDriveMode) {
      await createFile({
        url: values.url,
        source: 'google_drive',
        orderIndex: 1,
        documentId: createdDocument.id,
        externalId: getGoogleDriveFileIdFromUrl(values.url),
      })
    } else {
      await attachFiles(createdDocument.id, files)
    }

    await updateDocument(createdDocument.id, { status: 'published' })

    setFiles([])
    setGoogleDriveMode(false)
  }

  useEffect(() => {
    return () => files.forEach((file) => URL.revokeObjectURL(file.preview))
  }, []) /* eslint-disable-line react-hooks/exhaustive-deps */

  if (files.length === 0 && !isGoogleDriveMode) {
    return (
      <DropZone
        onDrop={handleFilesDropped}
        setGoogleDriveMode={setGoogleDriveMode}
      />
    )
  }

  return (
    <DocumentCard
      onCancel={handleCancel}
      onSubmit={handleSubmit}
      isSubmitting={isUploading}
      files={files}
      onFileOrderChanged={handleFileOrderChanged}
      isGoogleDriveMode={isGoogleDriveMode}
    />
  )
}

Upload.propTypes = {
  probateCaseId: PropTypes.string.isRequired,
}

export default Upload
