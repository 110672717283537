import { FAREWILL_SERVICES_OPTIONS } from './constants'
import { strToBool } from 'utils/helpers'
import { NOT_ASKED_OPTIONS } from 'utils/enums'

import { Grid, H } from '@farewill/ui'
import { useFormikContext } from 'formik'
import { Link } from 'react-router-dom'

import Input from 'components/form/input'
import SelectInput from 'components/form/select-input'
import CircularRadioGroup from 'components/form/circular-radio-group'
import PinkBox from 'components/styled/pink-box'

import { RESOURCES, useFetchResource } from 'api'
import { WillAttributes } from 'lib/models/will'
import { Contact } from 'lib/models/contact'

import { UpdateResourceFn } from './types'

type Props = {
  updateWill: UpdateResourceFn
}

const FarewillServices = ({ updateWill }: Props): React.ReactElement => {
  const { values } = useFormikContext<WillAttributes>()

  const contactQuery = useFetchResource<{ attributes: Contact }>({
    id: values.contact.id,
    resource: RESOURCES.CONTACTS,
  })

  const farewillFuneralPlan =
    contactQuery.data?.attributes?.funeralPlansAsCoveredIndividual?.[0]

  return (
    <>
      <H size="M">3. Other Services</H>
      <Grid>
        {farewillFuneralPlan && (
          <Grid.Item>
            <PinkBox>
              This customer has a Farewill funeral plan with the reference{' '}
              <Link to={`/funeral-plans/${farewillFuneralPlan.id}`}>
                {farewillFuneralPlan.reference}
              </Link>
              .
            </PinkBox>
          </Grid.Item>
        )}
        <Grid.Item>
          <CircularRadioGroup
            label={`Do they have ${
              farewillFuneralPlan ? 'any other' : 'a'
            } funeral plan in place?`}
            name="hasFuneralPlan"
            options={NOT_ASKED_OPTIONS}
            inline
            handleSave={updateWill}
          />
        </Grid.Item>

        {strToBool(values.hasFuneralPlan) && (
          <Grid.Item>
            <Input
              name="funeralPlanProvider"
              label="Provider"
              handleSave={updateWill}
            />
          </Grid.Item>
        )}
        <Grid.Item>
          <CircularRadioGroup
            label="Are any of the Farewill services included in the will?"
            name="hasFarewillServices"
            options={NOT_ASKED_OPTIONS}
            inline
            handleSave={updateWill}
          />
        </Grid.Item>
        {strToBool(values.hasFarewillServices) && (
          <Grid.Item>
            <SelectInput
              name="farewillServices"
              label="Farewill services options"
              options={FAREWILL_SERVICES_OPTIONS}
              handleSave={updateWill}
              isClearable
              isMulti
            />
          </Grid.Item>
        )}
      </Grid>
    </>
  )
}

export default FarewillServices
