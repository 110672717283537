import { useEffect, useCallback, lazy, Suspense } from 'react'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import styled from 'styled-components'
import { Switch } from 'react-router-dom'

import { Wrapper, P, Grid } from '@farewill/ui'
import Header from 'components/product-header'
import NavigationTabs from 'components/navigation-tabs'
import PrivateRoute from 'components/private-route/containers'
import NotFoundMessage from 'components/not-found-message'
import CaseOverview from './case-overview'
import Activity from 'components/activity'
import FuneralUnsupportedWarning from 'components/funeral-unsupported-warning'
import TasksPanel from 'components/tasks-panel'
import { TasksProvider, useTasksContext } from 'components/tasks-panel/context'
import FactFind from './fact-find'
import Sale from './sale'
import { withProvider } from 'routes/probate-estate/context'
import useNamespace from 'lib/formik/namespace'
import { FAREWILL_PRODUCTS } from 'utils/enums'
import * as Sentry from '@sentry/react'
import { isCremation } from 'lib/models/funeral'

import {
  fetchFuneral,
  fetchFuneralEvents,
  fetchLead,
  updateContact,
  updateFuneral,
  unsetProduct,
} from 'state/actions'
import FuneralsFallback from 'components/errors/FuneralsFallback'
import useAdminUser from 'hooks/useAdminUser'
import { GTR } from '@farewill/ui/tokens'

const Forms = lazy(() => import('./forms'))

const StyledWrapper = styled(Wrapper)`
  position: relative;
`

const getNavLinks = (funeralId, funeral) => {
  return [
    { label: 'Case details', url: `/funerals/${funeralId}` },
    { label: 'Activity', url: `/funerals/${funeralId}/activity` },
    { label: 'Fact find', url: `/funerals/${funeralId}/fact-find` },
    { label: 'Forms', url: `/funerals/${funeralId}/forms` },
    { label: 'Quote and sale', url: `/funerals/${funeralId}/sale` },
  ].filter((item) => item != null)
}

const StyledMainContent = styled(Grid.Item)`
  max-width: 744px;
`

const Funeral = ({ match }) => {
  const dispatch = useDispatch()
  const { tasks, actionTask, fetchTasks } = useTasksContext()
  const { events, lead, funeral } = useSelector(
    (state) => ({
      funeral: state.funeral.attributes && state.funeral,
      events: state.productEvents,
      lead: state.lead.attributes && state.lead,
    }),
    shallowEqual
  )

  const funeralId = match.params.id
  const leadId = funeral?.attributes.leadId
  const contactId = funeral?.attributes.contactId

  const { withoutNamespace } = useNamespace('contact.')

  const saveContactField = ({ name, value }) => {
    const attributes = { [withoutNamespace(name)]: value }
    dispatch(updateContact(contactId, attributes)).then(() => {
      dispatch(fetchFuneral(funeralId))
    })
  }

  const saveFuneralField = ({ name, value }, resetAttributes = []) => {
    const fieldAttribute = { [name]: value !== '' ? value : null }

    const attributes = resetAttributes.reduce(
      (acc, a) => ({ ...acc, [a]: null }),
      fieldAttribute
    )
    /* if we are resetting urnTypes, we need to save it to Funeral as an empty array rather than null */
    if (attributes.urnTypes === null) {
      attributes.urnTypes = []
    }
    dispatch(updateFuneral(funeralId, attributes))
  }

  const saveFuneralFields = (attributes) => {
    return dispatch(updateFuneral(funeralId, attributes))
  }

  const refetchFuneral = useCallback(() => {
    dispatch(fetchFuneral(funeralId))
  }, [dispatch, funeralId])

  useEffect(() => {
    dispatch(fetchFuneral(funeralId))
    return () => dispatch(unsetProduct('funeral'))
  }, [dispatch, funeralId])

  useEffect(() => {
    dispatch(fetchFuneralEvents(funeralId))
  }, [dispatch, funeralId])

  useEffect(() => {
    if (leadId) dispatch(fetchLead(leadId))
  }, [dispatch, leadId])

  if (!funeral || !events || !lead) return <P size="L">Loading...</P>

  const taskQueryParams = {
    'filter[funeralCaseId]': funeral.id,
    'filter[isNeeded]': true,
    'page[size]': 200,
  }

  const refetchTasks = () => {
    fetchTasks({ queryParams: taskQueryParams })
  }
  const { attributes } = funeral
  /**
   * For funerals existing before the feature to allow multiple urns, an urn
   * would have been added on urnType rather than urnTypes. If this is the case,
   * we need to translate the value to urnTypes before getting the initial form
   * values so that the urn appears in the UI form.
   * There was a bug where both urnType and urnTypes could be set on the lead so
   * we only want to overwrite urnTypes if it has not been set yet.
   */
  if (attributes.urnType && !attributes.urnTypes?.length) {
    attributes.urnTypes = [attributes.urnType]
  }
  const initialValues = {
    ...attributes,
  }
  return (
    <StyledWrapper data-sticky-content="funeral-case" padding={[GTR.XL, 0, 0]}>
      <TasksPanel
        queryParams={taskQueryParams}
        product={FAREWILL_PRODUCTS.FUNERAL}
        productId={{ funeralCaseId: funeral.id }}
        stickyContent="funeral-case"
      />
      <Header
        attributes={funeral.attributes}
        tasks={tasks}
        productType="funeral"
        showDeceased
        showLastCallAt
      />
      <Wrapper separator>
        <NavigationTabs links={getNavLinks(funeralId, funeral)} />
      </Wrapper>
      <Grid separator>
        <StyledMainContent span={8}>
          {(!isCremation(funeral) || funeral.attributes.attendedService) && (
            <FuneralUnsupportedWarning attributes={funeral.attributes} />
          )}
          <Suspense fallback={<div>Loading...</div>}>
            <Switch>
              <PrivateRoute
                path="/funerals/:id"
                component={CaseOverview}
                initialValues={funeral.attributes}
                lead={lead}
                events={events}
                saveFields={saveFuneralFields}
                exact
              />
              <PrivateRoute
                path="/funerals/:id/activity"
                component={Activity}
                leadId={leadId}
                product="funeral"
                showCallsFilter
                showPaymentsFilter
              />
              <PrivateRoute
                path="/funerals/:id/fact-find"
                component={FactFind}
                initialValues={initialValues}
                fetchFuneral={refetchFuneral}
                funeralId={funeralId}
                saveContactField={saveContactField}
                saveFuneralField={saveFuneralField}
                saveFuneralFields={saveFuneralFields}
              />
              <PrivateRoute
                path="/funerals/:id/forms"
                component={Forms}
                funeral={funeral}
                tasks={tasks}
                actionTask={actionTask}
                refetchTasks={refetchTasks}
              />
              <PrivateRoute
                path="/funerals/:id/sale"
                component={Sale}
                events={events}
                lead={lead}
                funeralId={funeralId}
                funeral={funeral}
                saveFuneral={saveFuneralFields}
              />
              <PrivateRoute component={NotFoundMessage} />
            </Switch>
          </Suspense>
        </StyledMainContent>
      </Grid>
    </StyledWrapper>
  )
}

const FuneralWithTasksProvider = (props) => {
  const adminUser = useAdminUser()

  return (
    <Sentry.ErrorBoundary
      fallback={<FuneralsFallback />}
      showDialog
      dialogOptions={{
        user: {
          name: adminUser?.attributes.name,
          email: adminUser?.attributes.email,
        },
      }}
    >
      <TasksProvider product={FAREWILL_PRODUCTS.FUNERAL}>
        <Funeral {...props} />
      </TasksProvider>
    </Sentry.ErrorBoundary>
  )
}

export default withProvider(FuneralWithTasksProvider)
