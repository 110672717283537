import React from 'react'
import styled from 'styled-components'

import { Wrapper, P } from '@farewill/ui'
import { COLOR } from '@farewill/ui/tokens'

import { formatCurrency } from 'lib/formatting/currency'
import { Invoice, invoiceTotalPrice } from 'lib/models/invoice'

const format = (value: number) =>
  formatCurrency({
    value,
    valueInPence: true,
  })

const StyledContainer = styled.div`
  border-top: solid 1px ${COLOR.GREY.LIGHT};
  width: 50%;
`
const StyledWrapper = styled(Wrapper)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: solid 1px ${COLOR.GREY.LIGHT};
  padding: 4px 0;
  margin: 0;
`

const StyledP = styled(P)`
  font-weight: 400;
  margin: 0;
`

const StyledFormattedAmount = styled(StyledP)`
  font-weight: inherit;
  font-feature-settings: 'tnum';
`

const StyledUnpaidPayment = styled.span`
  color: ${COLOR.STATE.ERROR};
  font-weight: 700;
  font-feature-settings: 'tnum';
`
const StyledPaidPayment = styled(StyledUnpaidPayment)`
  color: ${COLOR.STATE.SUCCESS};
`
type CostSummary = {
  leftToPay: number
  totalPaid: number
  totalInvoiced: number
}

export const calculateSummary = (
  cost: number,
  invoices: Invoice[]
): CostSummary => {
  return invoices.reduce(
    (acc, invoice) => {
      const invoiceAmount = invoiceTotalPrice(invoice)
      if (invoice.attributes.paidOn) {
        return {
          ...acc,
          leftToPay: acc.leftToPay - invoiceAmount,
          totalPaid: acc.totalPaid + invoiceAmount,
        }
      } else {
        return {
          ...acc,
          totalInvoiced: acc.totalInvoiced + invoiceAmount,
        }
      }
    },
    {
      totalInvoiced: 0,
      totalPaid: 0,
      leftToPay: cost,
    }
  )
}

type Props = {
  invoices: Invoice[]
  cost: number
}

const Overview = ({ cost, invoices }: Props): React.ReactElement => {
  const { leftToPay, totalPaid, totalInvoiced } = calculateSummary(
    cost,
    invoices
  )

  const leftToPayStatus = (leftToPay: number) => {
    const amount = (
      <StyledFormattedAmount>{format(leftToPay)}</StyledFormattedAmount>
    )
    if (leftToPay === 0) {
      return amount
    }

    if (leftToPay > 0) {
      return <StyledUnpaidPayment>{amount}</StyledUnpaidPayment>
    }

    if (leftToPay < 0) {
      return <StyledPaidPayment>{amount}</StyledPaidPayment>
    }
  }

  return (
    <StyledContainer>
      <StyledWrapper>
        <StyledP>Left to pay</StyledP>
        {leftToPayStatus(leftToPay)}
      </StyledWrapper>
      <StyledWrapper>
        <StyledP>Unpaid invoices</StyledP>
        <StyledFormattedAmount>{format(totalInvoiced)}</StyledFormattedAmount>
      </StyledWrapper>
      <StyledWrapper>
        <StyledP>Total paid</StyledP>
        <StyledFormattedAmount>{format(totalPaid)}</StyledFormattedAmount>
      </StyledWrapper>
      <StyledWrapper>
        <StyledP>Total quoted</StyledP>
        <StyledFormattedAmount>{format(cost)}</StyledFormattedAmount>
      </StyledWrapper>
    </StyledContainer>
  )
}

export default Overview
