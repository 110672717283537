import { Status } from 'routes/partners/interfaces'

export const isSuccess = (status: Status): boolean => {
  return ['success', 'updated'].includes(status)
}

export const isFailed = (status: Status): boolean => {
  return ['duplicate', 'error'].includes(status)
}

export const isDuplicate = (status: Status): boolean => {
  return status === 'duplicate'
}

export const getImagePath = (status: Status): string | undefined => {
  if (isSuccess(status)) return 'cremation-idea-fireworks'
  if (isFailed(status)) return 'checks-failed'
}

export const getHeadingText = (status: Status): string | undefined => {
  if (isSuccess(status)) {
    return status === 'updated'
      ? 'This charity already existed, we’ve now updated them to be a partner.'
      : 'You’re almost there'
  }
  if (isFailed(status)) return 'Something went wrong'
}
