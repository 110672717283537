import { Grid, H } from '@farewill/ui'

import DocumentsPrepared from './documents-prepared'
import ApplicationSubmitted from './application-submitted'

const ProbateApplication = ({ probateCase, saveProbateCaseField }) => {
  return (
    <Grid gap="XL">
      <Grid.Item span={8}>
        <H size="M">Probate application</H>
        <DocumentsPrepared
          initialValues={probateCase.attributes}
          saveProbateCaseField={saveProbateCaseField}
        />
        <ApplicationSubmitted
          initialValues={probateCase.attributes}
          saveProbateCaseField={saveProbateCaseField}
          probateCaseId={probateCase.id}
          probateEstateId={probateCase.attributes.estate.id}
        />
      </Grid.Item>
    </Grid>
  )
}

export default ProbateApplication
