import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Button, Wrapper } from '@farewill/ui'
import { COLOR } from '@farewill/ui/tokens'

const StyledFooter = styled.footer`
  background-color: ${COLOR.GREY.DARK};
  color: ${COLOR.WHITE};
  flex-shrink: 0;
`

const Footer = ({ isLoggedIn, logout }) => {
  return isLoggedIn ? (
    <StyledFooter>
      <Wrapper container containerPaddingTop="M" containerPaddingBottom="M">
        <Button onClick={logout}>Log out</Button>
      </Wrapper>
    </StyledFooter>
  ) : null
}

Footer.propTypes = {
  logout: PropTypes.func.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
}

export default Footer
