import PropTypes from 'prop-types'
import moment from 'moment'
import Table from './table'
import { formatToHuman } from '../helpers'

import isEmpty from 'lodash/isEmpty'

const NotesItem = ({ event }) => {
  const eventData = event.attributes
  const formattedType = formatToHuman(eventData.type)
  const formattedDate = moment(eventData.happenedAt).format('D MMM YYYY')
  return (
    <Table.Row>
      <Table.Cell>
        <strong>
          {formattedType} by {eventData.adminUser.name}, {formattedDate}
        </strong>
        <br />
        {eventData.metadata.notes}
        <br />
        &nbsp;
      </Table.Cell>
    </Table.Row>
  )
}

const NoNotes = () => (
  <Table.Row>
    <Table.Cell>
      <em>No notes</em>
    </Table.Cell>
  </Table.Row>
)

const Notes = ({ leadEvents, title }) => (
  <Table>
    <Table.Body>
      <Table.Row>
        <Table.Cell>
          <h4>{title}</h4>
        </Table.Cell>
      </Table.Row>
      {isEmpty(leadEvents) ? (
        <NoNotes />
      ) : (
        leadEvents.map((event) => <NotesItem key={event.id} event={event} />)
      )}
    </Table.Body>
  </Table>
)

Notes.propTypes = {
  leadEvents: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      attributes: PropTypes.shape({
        type: PropTypes.string,
        happenedAt: PropTypes.string,
        adminUser: PropTypes.shape({
          name: PropTypes.string,
        }),
        metadata: PropTypes.shape({
          metadata: PropTypes.object,
        }),
      }),
    })
  ),
  title: PropTypes.string,
}

Notes.defaultProps = {
  title: 'Notes',
}

export default Notes
