import PropTypes from 'prop-types'
import { COLOR, GTR } from '@farewill/ui/tokens'
import styled from 'styled-components'

import Tooltip from 'components/tooltip'

import Dinero from 'dinero.js'
Dinero.globalLocale = 'en-GB'
Dinero.defaultCurrency = 'GBP'

const StyledListItem = styled.li`
  display: flex;
  padding: ${GTR.S} 0;
  border-bottom: 1px solid ${COLOR.GREY.LIGHT};
`

const StyledStrong = styled.strong`
  flex-basis: 66%;
`

const StyledParagraph = styled.p`
  flex-basis: 17%;
  margin: 0;
`

const dineroFormatter = (value) => {
  if (typeof value === 'string') return '£'

  return Dinero({ amount: Math.round(value * 100) || 0 }).toFormat('0,0')
}

const FinancialRow = ({ title, tooltip, amount, formBox }) => {
  return (
    <StyledListItem>
      <StyledStrong>
        <Tooltip content={tooltip}>{title}</Tooltip>
      </StyledStrong>
      <StyledParagraph>{dineroFormatter(amount)}</StyledParagraph>
      <StyledParagraph>{formBox}</StyledParagraph>
    </StyledListItem>
  )
}

FinancialRow.propTypes = {
  title: PropTypes.string.isRequired,
  tooltip: PropTypes.string.isRequired,
  amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  formBox: PropTypes.string,
}

FinancialRow.defaultProps = {
  formBox: '-',
}

export default FinancialRow
