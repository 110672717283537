const ERRORS_GLOBAL = 'ADD_ERROR_GLOBAL'

export default function errorsGlobal(state = [], action = {}) {
  switch (action.type) {
    case ERRORS_GLOBAL:
      return action.payload
    case ERRORS_GLOBAL + '_PENDING':
    case ERRORS_GLOBAL + '_REJECTED':
      return []
    default:
      return state
  }
}
