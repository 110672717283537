import Tooltip from 'components/tooltip'

const ConfirmedValueLabel = () => (
  <>
    Confirmed&nbsp;
    <Tooltip content="‘DOD’ = date of death. Select ‘estimate’ unless we have a confirmed date of death valuation. If we have a confirmed value, add detail in notes.">
      DOD value
    </Tooltip>
    &nbsp;(add detail in notes)
  </>
)

export default ConfirmedValueLabel
