import { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Button, Grid, P } from '@farewill/ui'
import { GTR } from '@farewill/ui/tokens'

import store from 'state/create-store'
import { showModal } from 'state/actions'

import useItemState from 'lib/effects/item-state'
import useProbateEstateItemHelpers from 'lib/effects/probate-estate-item-helpers'

import EstateCard from 'components/estate-card'
import InputFloating from 'components/form/input-floating'
import CircularRadioGroup from 'components/form/circular-radio-group'
import GridWithToggle from 'components/grid-with-toggle'

import DeleteModal from '../components/delete-modal'
import TextSpacer from '../components/text-spacer'
import withForm from '../components/with-form'

import { LIFE_INSURANCE_SECTION_ACTIVE } from './field-names'
import { NOT_ASKED_OPTIONS } from 'utils/enums'

import { useFormikContext } from 'formik'
import namespace from 'lib/formik/namespace'
import { getLifeInsuranceTotals } from '../get-totals'
import get from 'lodash/get'

import {
  DECEASED_SHARE_OF_JOINT_OWNERSHIP,
  DESCRIPTION,
  IS_ESTIMATED_VALUE,
  JOINT_OWNER_NAME,
  OWNERSHIP,
  VALUE,
  IS_INSURANCE_PAID_OUT,
  IS_ESTIMATED_VALUE_OPTIONS,
  LUMP_SUM_PAYABLE_TO,
} from '../item-field-constants'
import NotesInput from 'components/notes-input'
import { NOTES } from './field-names'

const Item = ({
  item,
  probateEstateId,
  index,
  listLength,
  deleteItem,
  updateItem,
}) => {
  const { withNamespace, withoutNamespace } = namespace(
    `lifeInsurances.${item.id}.`
  )
  const { values } = useFormikContext()

  const handleSave = ({ name, value }) => {
    updateItem({
      id: item.id,
      attributes: { [withoutNamespace(name)]: value || null },
    })
  }

  const onTrashClick = () => {
    store.dispatch(
      showModal({
        component: DeleteModal,
        headingText: 'Delete life insurance',
        config: {
          deleteCallback: () => {
            deleteItem({
              probateEstateId,
              id: item.id,
            })
          },
          type: 'life insurance',
          resourceId: item.id,
        },
      })
    )
  }

  return (
    <EstateCard
      onTrashClick={onTrashClick}
      listLength={listLength}
      listPosition={index + 1}
      key={item.id}
      type="life insurance"
    >
      <Grid>
        <Grid.Item>
          <InputFloating
            name={withNamespace(DESCRIPTION)}
            label="Provider and description"
            handleSave={handleSave}
          />
        </Grid.Item>
        <Grid.Item span={3}>
          <InputFloating
            name={withNamespace(VALUE)}
            label="Value (£)"
            handleSave={handleSave}
            isCurrency
          />
        </Grid.Item>
        <Grid.Item
          span={9}
          style={{ alignSelf: 'center', justifySelf: 'center' }}
        >
          <CircularRadioGroup
            name={withNamespace(IS_ESTIMATED_VALUE)}
            options={IS_ESTIMATED_VALUE_OPTIONS}
            handleSave={handleSave}
            inline
          />
        </Grid.Item>
        <Grid.Item>
          <CircularRadioGroup
            name={withNamespace(OWNERSHIP)}
            label="Ownership"
            options={[
              { label: 'Not asked', value: '' },
              { label: 'Sole', value: 'sole' },
              { label: 'Joint', value: 'joint' },
            ]}
            handleSave={handleSave}
            inline
          />
        </Grid.Item>
        {get(values, withNamespace(OWNERSHIP)) === 'joint' && (
          <>
            <Grid.Item span={6}>
              <InputFloating
                name={withNamespace(JOINT_OWNER_NAME)}
                label="Name of joint owner"
                handleSave={handleSave}
                hint="If more than one, leave a note below"
              />
            </Grid.Item>
            <Grid.Item span={6}>
              <InputFloating
                name={withNamespace(DECEASED_SHARE_OF_JOINT_OWNERSHIP)}
                label="Share owned by person who's died (%)"
                handleSave={handleSave}
              />
            </Grid.Item>
          </>
        )}
        <Grid.Item>
          <CircularRadioGroup
            name={withNamespace(IS_INSURANCE_PAID_OUT)}
            label="Has this policy paid out?"
            options={NOT_ASKED_OPTIONS}
            handleSave={handleSave}
            inline
          />
        </Grid.Item>
        <Grid.Item>
          <CircularRadioGroup
            name={withNamespace(LUMP_SUM_PAYABLE_TO)}
            label="Who is the lump sum payable to?"
            options={[
              { label: 'Not asked', value: '' },
              { label: 'The estate', value: 'estate' },
              {
                label: 'Written in trust / not part of estate for IHT reasons',
                value: 'not_part_of_estate',
              },
            ]}
            handleSave={handleSave}
            inline
          />
        </Grid.Item>
        <Grid.Item>
          <NotesInput name={withNamespace(NOTES)} handleSave={handleSave} />
        </Grid.Item>
      </Grid>
    </EstateCard>
  )
}

const ItemWithForm = withForm(Item)

const LifeInsurance = ({
  initialItems,
  probateEstateId,
  saveProbateEstateField,
}) => {
  const { items, addItem, removeItem, replaceItem } = useItemState(initialItems)

  const {
    createItem,
    createdItem,
    isCreating,
    deleteItem,
    deletedItem,
    updateItem,
    updatedItem,
  } = useProbateEstateItemHelpers({ probateEstateId })

  useEffect(() => {
    if (createdItem) addItem(createdItem)
  }, [addItem, createdItem])

  useEffect(() => {
    if (deletedItem) removeItem(deletedItem)
  }, [removeItem, deletedItem])

  useEffect(() => {
    if (updatedItem) replaceItem(updatedItem)
  }, [replaceItem, updatedItem])

  const { total, sole, joint, unspecified } = getLifeInsuranceTotals(items)
  return (
    <GridWithToggle
      disabled={items.length > 0}
      name={LIFE_INSURANCE_SECTION_ACTIVE}
      onToggle={saveProbateEstateField}
      scrollId="4.7"
      title="Life insurance"
    >
      <Grid.Item>
        <P size="S">
          Total: {total} <TextSpacer /> Sole: {sole} <TextSpacer /> Joint share:{' '}
          {joint} <TextSpacer /> Unspecified: {unspecified}
        </P>

        {items.map((item, index) => (
          <ItemWithForm
            deleteItem={deleteItem}
            enableReinitialize={false}
            index={index}
            initialValues={{ lifeInsurances: { [item.id]: item.attributes } }}
            item={item}
            key={item.id}
            listLength={items.length}
            probateEstateId={probateEstateId}
            updateItem={updateItem}
          />
        ))}
      </Grid.Item>
      <Grid.Item>
        <Button.Secondary
          style={{ paddingTop: GTR.XXS, paddingBottom: GTR.XXS }}
          loading={isCreating}
          type="button"
          onClick={() => {
            createItem({ itemType: 'life_insurance' })
          }}
        >
          Add life insurance
        </Button.Secondary>
      </Grid.Item>
    </GridWithToggle>
  )
}

LifeInsurance.propTypes = {
  initialItems: PropTypes.array,
  probateEstateId: PropTypes.string.isRequired,
  saveProbateEstateField: PropTypes.func.isRequired,
}

LifeInsurance.defaultProps = {
  initialItems: [],
}

export default LifeInsurance
