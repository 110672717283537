import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useFormikContext } from 'formik'
import { ArrowUpIcon, H, P, Wrapper } from '@farewill/ui'
import { COLOR } from '@farewill/ui/tokens'

import { strToBool } from 'utils/helpers'

import Switch from 'components/switch'
import Input from 'components/form/input'

const StyledWrapper = styled(Wrapper)`
  display: flex;
  align-items: center;
`

const StyledHeading = styled(H)`
  position: relative;
`

const StyledPriorityArrow = styled(ArrowUpIcon)`
  position: absolute;
  left: -40px;
`

const CasePrioritySwitch = ({ label, keyName, notesLabel, notesKeyName }) => {
  const formikContext = useFormikContext()

  if (formikContext === undefined) {
    throw new Error('CasePrioritySwitch must be used within a Formik Form')
  }

  const { handleSubmit, setFieldValue, values } = formikContext

  const handleToggle = () => {
    setFieldValue(keyName, !strToBool(values[keyName]))
    handleSubmit()
  }

  return (
    <>
      <StyledHeading size="S">
        <StyledPriorityArrow
          size="S"
          color={COLOR.WHITE}
          background={COLOR.STATE.WARNING}
        />
        {label}
      </StyledHeading>
      <P>A particularly difficult situation, errors or urgency.</P>
      <StyledWrapper>
        <Switch
          color={COLOR.STATE.WARNING}
          handleToggle={handleToggle}
          isOn={strToBool(values[keyName])}
        />
        <P margin={[0, 0, 0, 'S']}>{label}</P>
      </StyledWrapper>
      {strToBool(values[keyName]) && (
        <Wrapper margin={['S', 0, 0]}>
          <Input
            label={notesLabel}
            name={notesKeyName}
            placeholder={notesLabel}
            hint="Max 200 characters"
            maxLength={200}
            handleSave={handleSubmit}
          />
        </Wrapper>
      )}
    </>
  )
}

CasePrioritySwitch.propTypes = {
  label: PropTypes.string,
  keyName: PropTypes.string.isRequired,
  notesLabel: PropTypes.string,
  notesKeyName: PropTypes.string.isRequired,
}

CasePrioritySwitch.defaultProps = {
  label: 'Priority case',
  notesLabel: 'Reasons for priority',
}

export default CasePrioritySwitch
