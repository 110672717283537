import styled from 'styled-components'
import { Button, P, Wrapper } from '@farewill/ui'

import store from 'state/create-store'
import { hideModal } from 'state/actions'

const StyledWrapper = styled(Wrapper)`
  display: flex;
  justify-content: space-between;
`

type LeadEventModalConfig = {
  config: {
    confirmCallback: () => void
  }
}

const LeadEventModal = ({ config }: LeadEventModalConfig) => {
  const handleContinueClick = async () => {
    await config.confirmCallback()
    store.dispatch(hideModal())
  }

  return (
    <Wrapper>
      <P>
        Marking a customer as Lost and Uncontactable will send the customer an
        email.
      </P>
      <P>
        They’ll be invited to reschedule the call. Are you sure you want to
        continue?
      </P>
      <StyledWrapper>
        <Button.Bordered
          type="button"
          onClick={() => store.dispatch(hideModal())}
        >
          Cancel
        </Button.Bordered>
        <Button.Primary type="button" onClick={handleContinueClick}>
          Continue
        </Button.Primary>
      </StyledWrapper>
    </Wrapper>
  )
}

export default LeadEventModal
