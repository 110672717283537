import styled from 'styled-components'
import { Wrapper } from '@farewill/ui'
import { COLOR, GTR, BORDER_RADIUS } from '@farewill/ui/tokens'

const InfoBox = styled(Wrapper)`
  border: 2px solid ${COLOR.GREY.LIGHT};
  background-color: ${COLOR.WHITE};
  padding: ${GTR.S};
  border-radius: ${BORDER_RADIUS.S};
`
export default InfoBox
