import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import identity from 'lodash/identity'
import partition from 'lodash/partition'
import { P, Wrapper } from '@farewill/ui'
import { COLOR, FONT, GTR } from '@farewill/ui/tokens'

import useApi from 'lib/effects/api'

import Tooltip from 'components/tooltip'
import SimpleList from 'components/styled/simple-list'

const StyledP = styled(P)`
  font-size: ${FONT.SIZE.S};
  margin-bottom: ${GTR.XS};
  color: ${COLOR.GREY.DARK};
`

const getPersonRole = (person) => {
  if (person.attributes.isCustomer) return 'Customer -'
  if (person.attributes.isPointOfContact) return 'Point of contact -'
  if (person.attributes.isPersonalRepresentative) return 'PR -'
  if (person.attributes.isIncludedInComms) return 'CC -'
  return ''
}

const getPersonName = (person) => {
  const firstName =
    person.attributes.preferredName || person.attributes.firstNames
  return [firstName, person.attributes.lastName].filter(identity).join(' ')
}

const PersonList = ({ people }) => {
  return people.map((person) => (
    <StyledP strong key={person.id}>
      {getPersonRole(person)} {getPersonName(person)}
    </StyledP>
  ))
}

const TooltipContent = () => {
  return (
    <Wrapper maxWidth={350}>
      <P size="S">A person is only included in emails if they:</P>
      <SimpleList bullets>
        <li>have an email address</li>
        <li>are marked as a customer or PR</li>
        <li>
          are marked as a point of contact AND we are authorized to deal with
          them
        </li>
        <li>
          are marked as included in all comms AND we are authorized to include
          them
        </li>
      </SimpleList>
    </Wrapper>
  )
}

const EmailRecipients = ({ probateCaseId, probateEstateId }) => {
  const [, fetchProbateCase] = useApi()
  const [
    { data: probateEstatePeople, isLoading: isLoadingPeople },
    fetchProbateEstatePeople,
  ] = useApi({ isLoading: true })

  useEffect(() => {
    const getProbateEstatePeople = async () => {
      let estateId = probateEstateId

      if (!estateId) {
        const probateCase = await fetchProbateCase({
          url: `/api/probate-cases/${probateCaseId}`,
        })
        estateId = probateCase.data.attributes.estate.id
      }

      await fetchProbateEstatePeople({
        url: `/api/probate-estates/${estateId}/people`,
      })
    }
    getProbateEstatePeople()
  }, [
    fetchProbateCase,
    fetchProbateEstatePeople,
    probateCaseId,
    probateEstateId,
  ])

  const [recipients, notIncluded] = partition(
    probateEstatePeople,
    (person) => !!person.attributes.isIncludedInEmails
  )

  if (isLoadingPeople) return <StyledP>Loading...</StyledP>

  return (
    <>
      <StyledP>We’ll email:</StyledP>
      <PersonList people={recipients} />
      {notIncluded.length > 0 && (
        <Wrapper margin={['M', 0, 0]}>
          <StyledP>
            We will <strong>not</strong> email:{' '}
            <Tooltip content={<TooltipContent />} underlined={false}>
              ⓘ
            </Tooltip>
          </StyledP>
          <PersonList people={notIncluded} />
        </Wrapper>
      )}
    </>
  )
}

EmailRecipients.propTypes = {
  probateCaseId: PropTypes.number,
  probateEstateId: PropTypes.number,
}

export default EmailRecipients
