import PropTypes from 'prop-types'
import { Grid, P } from '@farewill/ui'
import { Link } from 'react-router-dom'
import { useFormikContext } from 'formik'

import Tooltip from 'components/tooltip'
import DateSplit from 'components/form/date-split'
import CircularRadioGroup from 'components/form/circular-radio-group'

import { NOT_ASKED_AND_UNSURE_OPTIONS, NOT_ASKED_OPTIONS } from 'utils/enums'

import ChildBeneficiaries from './child-beneficiaries'
import {
  WILL,
  HAS_ORIGINAL_WILL,
  HAS_BOUND_WILL,
  HAS_FOREIGN_WILLS,
  HAS_CODICILS,
  AT_LEAST_ONE_EXECUTOR_ABLE_TO_APPLY,
  WAS_ADOPTED,
  HAS_BENEFICIARIES_UNDER_EIGHTEEN,
} from './field-names'

const Will = ({ probateEstateId, saveProbateEstateField }) => {
  const { values } = useFormikContext()
  const showChildBeneficiaries =
    values[HAS_BENEFICIARIES_UNDER_EIGHTEEN] === 'true'
  const willNotBound = values[HAS_BOUND_WILL] === 'false'

  return (
    <Grid>
      <Grid.Item>
        <DateSplit
          name={WILL}
          label="Date of will"
          handleSave={saveProbateEstateField}
          hint="For example, 24 2 2020"
        />
      </Grid.Item>
      <Grid.Item>
        <CircularRadioGroup
          name={HAS_ORIGINAL_WILL}
          label="Does the customer have the original?"
          options={NOT_ASKED_AND_UNSURE_OPTIONS}
          handleSave={saveProbateEstateField}
          inline
        />
      </Grid.Item>
      <Grid.Item>
        <CircularRadioGroup
          name={HAS_BOUND_WILL}
          label={
            <>
              Is the will{' '}
              <Tooltip content="A bound will has all of its pages fastened together (e.g. with a staple, eyelet, ribbon, or binder). If the will has been unbound at any point since being bound, then answer as 'No'.">
                bound
              </Tooltip>
              ?
            </>
          }
          options={NOT_ASKED_OPTIONS}
          handleSave={saveProbateEstateField}
          inline
        />
        {willNotBound && (
          <P size="S" margin={['S', 0, 0]}>
            The legal team will be in touch to gather more information in order
            to prepare a statement.
          </P>
        )}
      </Grid.Item>
      <Grid.Item>
        <CircularRadioGroup
          name={HAS_FOREIGN_WILLS}
          label="Are there any foreign wills?"
          options={NOT_ASKED_AND_UNSURE_OPTIONS}
          handleSave={saveProbateEstateField}
          inline
        />
      </Grid.Item>
      <Grid.Item>
        <CircularRadioGroup
          name={HAS_CODICILS}
          label="Are there any codicils?"
          options={NOT_ASKED_AND_UNSURE_OPTIONS}
          handleSave={saveProbateEstateField}
          inline
        />
      </Grid.Item>
      <Grid.Item span={10}>
        <CircularRadioGroup
          name={AT_LEAST_ONE_EXECUTOR_ABLE_TO_APPLY}
          label="Are there executors named in the will, and if so is at least one of them able to apply for probate?"
          options={NOT_ASKED_OPTIONS}
          handleSave={saveProbateEstateField}
          inline
        />
      </Grid.Item>
      <Grid.Item>
        <CircularRadioGroup
          name={HAS_BENEFICIARIES_UNDER_EIGHTEEN}
          label="Is anyone who’s receiving a gift in the will or codicil under 18 years old?"
          options={NOT_ASKED_OPTIONS}
          handleSave={saveProbateEstateField}
          inline
        />
      </Grid.Item>
      {showChildBeneficiaries && (
        <Grid.Item>
          <ChildBeneficiaries probateEstateId={probateEstateId} />
        </Grid.Item>
      )}
      <Grid.Item>
        <CircularRadioGroup
          name={WAS_ADOPTED}
          label="Was the person who died adopted?"
          options={NOT_ASKED_OPTIONS}
          handleSave={saveProbateEstateField}
          inline
        />
      </Grid.Item>
      <Grid.Item>
        <P size="S">
          When we’ve received the will, remember to complete the{' '}
          <Link to={`/probate/estates/${probateEstateId}/internal-checks`}>
            internal will checklists
          </Link>
        </P>
      </Grid.Item>
    </Grid>
  )
}

Will.propTypes = {
  probateEstateId: PropTypes.string.isRequired,
  saveProbateEstateField: PropTypes.func.isRequired,
}

export default Will
