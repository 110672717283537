import { Grid } from '@farewill/ui'

import GiftsGivenOver3k from './gifts-over-3k'
import GiftsContinuedBenefit from './gifts-continued-benefit'
import BenefittedFromTrusts from './trusts'
import SectionHeading from '../section-heading'
import LegacyLeadMessageBox from '../legacy-lead-message-box'
import { SECTION_NAMES } from '../helpers/complete-sections'
import { useProbateEstate } from '../helpers/probate-estate-context'

const Complexity = ({
  isComplete,
  checkIfComplete,
}: {
  isComplete: boolean
  checkIfComplete: (name: string) => boolean
}) => {
  const { probateEstateId } = useProbateEstate()
  return (
    <Grid>
      <SectionHeading
        title="8. Complexity"
        scrollId="complexity"
        isComplete={isComplete}
        hideIcon
      />
      {probateEstateId ? (
        <>
          <Grid.Item span={11} startColumn={2}>
            <GiftsGivenOver3k
              isComplete={checkIfComplete(SECTION_NAMES.GIFTS_OVER_3000)}
            />
          </Grid.Item>
          <Grid.Item span={11} startColumn={2}>
            <GiftsContinuedBenefit
              isComplete={checkIfComplete(
                SECTION_NAMES.GIFTS_THEY_CONTINUED_TO_BENEFIT_FROM
              )}
            />
          </Grid.Item>
          <Grid.Item span={11} startColumn={2}>
            <BenefittedFromTrusts
              isComplete={checkIfComplete(SECTION_NAMES.TRUSTS)}
            />
          </Grid.Item>
        </>
      ) : (
        <Grid.Item startColumn={2}>
          <LegacyLeadMessageBox />
        </Grid.Item>
      )}
    </Grid>
  )
}

export default Complexity
