import { Grid } from '@farewill/ui'
import { useFormikContext } from 'formik'
import React from 'react'
import Input from 'components/form/input'
import { QuoteAttributes } from 'components/funeral-quote-form-fields'
import FormArraySection from './form-array-section'

const AdditionalCharges = (): React.ReactElement => {
  const { values } = useFormikContext<{ quoteAttributes: QuoteAttributes }>()

  const requestsCtaLabel =
    values.quoteAttributes?.additionalCharges?.length > 0
      ? 'Add another request'
      : 'Add a request'

  return (
    <FormArraySection
      name="additionalCharges"
      header="Special requests (Optional)"
      description="Any special requests or extra charges. For example, ‘red roses for
    coffin’."
      newRowLabel={requestsCtaLabel}
      renderRow={({ name, handleSubmit }) => (
        <>
          <Grid.Item spanFromL={7}>
            <Input
              name={`${name}.description`}
              label="Request type"
              handleSave={() => handleSubmit()}
            />
          </Grid.Item>
          <Grid.Item spanFromL={4}>
            <Input
              name={`${name}.price`}
              label="Cost (£)"
              isCurrency
              handleSave={() => handleSubmit()}
            />
          </Grid.Item>
        </>
      )}
    />
  )
}

export default AdditionalCharges
