export const getStore = (key) => {
  if (!sessionStorage) return console.error('No access to session storage')

  return JSON.parse(sessionStorage.getItem(key))
}

export const store = (key, data) => {
  if (!sessionStorage) return console.error('No access to session storage')

  return sessionStorage.setItem(key, JSON.stringify(data))
}
