import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Form, Formik } from 'formik'
import { Button, Grid } from '@farewill/ui'

import { RadioGroupYesNoField } from 'components/form/radio-group-yes-no'
import apiRequest from 'lib/axios/api-request'
import { UPDATE_PARTNER_ACCOUNT_SCHEMA } from 'lib/formik/schemata'
import { hideModal } from 'state/actions'

import {
  PARTNER_ACCOUNT_PREFERENCES_FIELDS,
  PARTNER_DATA_SHARING_REPORTS_READ_CONFIG,
  WEEKLY_PLEDGE_EMAIL_CONFIG,
} from './constants'
import {
  UpdatePartnerAccountForm,
  UpdatePartnerAccountModalConfig,
} from './types'

export const UpdateAccountModal = (config: {
  config: UpdatePartnerAccountModalConfig
}) => {
  const { partnerAccount, isPartnerDataSharingEnabledPartner } = config.config

  const dispatch = useDispatch()
  const history = useHistory()

  const initialValues = {
    weeklyPledgeEmail:
      partnerAccount.subscriptions &&
      partnerAccount.subscriptions.find(
        (row) => row.subscription === 'WEEKLY_PLEDGE_EMAIL'
      )
        ? 'true'
        : 'false',
    partnerDataSharingReports: isPartnerDataSharingEnabledPartner
      ? partnerAccount.access &&
        partnerAccount.access.find(
          (row) =>
            row.access === 'PARTNER_DATA_SHARING_REPORTS' &&
            row.scope === 'READ'
        )
        ? 'true'
        : 'false'
      : 'false',
  } as UpdatePartnerAccountForm

  const handleSubmit = async (values: UpdatePartnerAccountForm) => {
    const { weeklyPledgeEmail } = values

    const subscriptions = []
    const access = []

    if (weeklyPledgeEmail === 'true') {
      subscriptions.push(WEEKLY_PLEDGE_EMAIL_CONFIG)
    }

    if (
      isPartnerDataSharingEnabledPartner &&
      values.partnerDataSharingReports === 'true'
    ) {
      access.push(PARTNER_DATA_SHARING_REPORTS_READ_CONFIG)
    }

    try {
      await apiRequest({
        url: `/api/partner-accounts/${partnerAccount.id}`,
        method: 'PATCH',
        data: {
          subscriptions,
          access,
        },
      })
      dispatch(hideModal())
      history.go(0) // Refresh page to re-retrieve accounts after add
    } catch (err) {
      console.error(
        `Updating partnerAccount with email ${partnerAccount.email}`,
        err
      )
    }
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values) => handleSubmit(values)}
      validationSchema={UPDATE_PARTNER_ACCOUNT_SCHEMA}
    >
      {({ values, errors, handleChange, isSubmitting }) => {
        return (
          <Form>
            <Grid>
              <Grid.Item>
                <RadioGroupYesNoField
                  values={values}
                  errors={errors}
                  label={
                    PARTNER_ACCOUNT_PREFERENCES_FIELDS.WEEKLY_PLEDGE_EMAIL.label
                  }
                  name={
                    PARTNER_ACCOUNT_PREFERENCES_FIELDS.WEEKLY_PLEDGE_EMAIL.name
                  }
                  handleChange={handleChange}
                  initialValue={values.weeklyPledgeEmail}
                />
              </Grid.Item>
              {isPartnerDataSharingEnabledPartner && (
                <Grid.Item>
                  <RadioGroupYesNoField
                    values={values}
                    errors={errors}
                    label={
                      PARTNER_ACCOUNT_PREFERENCES_FIELDS
                        .PARTNER_DATA_SHARING_REPORTS.label
                    }
                    name={
                      PARTNER_ACCOUNT_PREFERENCES_FIELDS
                        .PARTNER_DATA_SHARING_REPORTS.name
                    }
                    handleChange={handleChange}
                    initialValue={values.partnerDataSharingReports}
                  />
                </Grid.Item>
              )}
              <Grid.Item>
                <Button.Primary type="submit" stretch disabled={isSubmitting}>
                  Save
                </Button.Primary>
              </Grid.Item>
            </Grid>
          </Form>
        )
      }}
    </Formik>
  )
}
