import { ReactElement, useState } from 'react'
import { Formik, Form } from 'formik'
import { Grid, P, Wrapper } from '@farewill/ui'
import store from 'state/create-store'
import { showModal } from 'state/actions'
import DeleteModal from 'components/form/radio-group-delete-modal'

import { LpaCaseQuoteAttributes } from './types'
import LpaCaseQuoteFormFields from './lpa-case-quote-form-fields'

const LpaCaseQuoteForm = ({
  isLoading,
  lpaCaseId,
}: {
  isLoading: boolean

  lpaCaseId: number
}): ReactElement => {
  return (
    <Form>
      <Wrapper>
        <Grid>
          <LpaCaseQuoteFormFields isLoading={isLoading} lpaCaseId={lpaCaseId} />
        </Grid>
      </Wrapper>
    </Form>
  )
}

const getConfirmationModalConfig = ({
  value,
  onSubmit,
}: {
  value: string
  onSubmit: (attributes: LpaCaseQuoteAttributes) => Promise<void>
}) => ({
  component: DeleteModal,
  headingText: 'You are about to remove some information',
  config: {
    deleteCallback: async () => {
      onSubmit({ type: value as LpaCaseQuoteAttributes['type'] })
    },
    bodyText: (
      <P>
        Changing the answer to this question will clear the partner details. Do
        you want to continue?
      </P>
    ),
    buttonLabel: 'Continue',
  },
})

const EditableQuote = ({
  lpaCaseId,
  values,
  saveQuote,
  exitEditableQuote,
}: {
  lpaCaseId: number
  values: LpaCaseQuoteAttributes
  saveQuote: (attributes: LpaCaseQuoteAttributes) => Promise<void>
  exitEditableQuote: () => void
}): ReactElement => {
  const initialValues = values

  const [isLoading, setIsLoading] = useState(false)
  const onSubmit = async (values: LpaCaseQuoteAttributes) => {
    const quoteAttributesValues = values

    try {
      setIsLoading(true)
      await saveQuote({
        ...quoteAttributesValues,
      })
      exitEditableQuote && exitEditableQuote()
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Wrapper>
      <Formik
        initialValues={initialValues}
        onSubmit={async (values) => {
          if (
            values &&
            'type' in values &&
            ['single'].includes(values?.type as string)
          ) {
            store.dispatch(
              showModal(
                getConfirmationModalConfig({
                  value: values.type as string,
                  onSubmit,
                })
              )
            )
          } else {
            onSubmit({ type: values.type as LpaCaseQuoteAttributes['type'] })
          }
        }}
      >
        <LpaCaseQuoteForm isLoading={isLoading} lpaCaseId={lpaCaseId} />
      </Formik>
    </Wrapper>
  )
}

export default EditableQuote
