import { startCase } from 'lodash'
import { Values } from './interfaces'
import map from 'lodash/map'
import { KEYS_TO_NOT_SEND_TO_API } from './constants'

export const partnerTypeWording = (
  type: string,
  hasExemptCharitableStatus: boolean | undefined
) => {
  return `${startCase(type)}${
    hasExemptCharitableStatus ? ' (with charitable status)' : ''
  }`
}

/**
 * Given FormikValues creates the form data to be sent to the Backstage API
 * @param values Formik Values
 * @param partnerId If the partner already exists, the uuid of the partner as a string
 * @returns FormData to be sent in the POST/PATCH to the API
 */
export function constructPartnerFormData(
  values: Values,
  partnerId?: string
): FormData {
  const formData = new FormData()

  formData.append('data[type]', 'partners')
  if (partnerId) {
    formData.append('data[id]', partnerId)
  }

  let giftPromptImageAction: 'upload' | 'noop' | 'delete'
  if (values.giftPromptImage) {
    giftPromptImageAction = 'upload'
  } else if (!values.giftPromptImageUrl) {
    giftPromptImageAction = 'delete'
  } else {
    giftPromptImageAction = 'noop'
  }
  formData.append(
    'data[attributes][giftPromptImageAction]',
    JSON.stringify(giftPromptImageAction)
  )

  // FIXME: The type signature here is a lie!
  map(values, (value: string, key: string) => {
    if (['charity', 'organisation'].includes(key) && key !== values.type) {
      return
    }
    if (KEYS_TO_NOT_SEND_TO_API.includes(key)) return
    if (['logo', 'giftPromptImage'].includes(key) && value !== null) {
      /* We don't want to stringify image files */
      formData.append(`data[attributes][${key}]`, value)
    } else {
      formData.append(
        `data[attributes][${key}]`,
        JSON.stringify(value === undefined ? null : value)
      )
    }
  })
  return formData
}
