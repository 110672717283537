import PropTypes from 'prop-types'
import Dinero from 'dinero.js'
import Table from './table'
import { formatAnswer } from '../helpers'
import { getFormattedAnswer } from 'routes/lead/form-helpers'
import { DIAGNOSTIC_HEADINGS } from 'routes/lead/will-form/constants'
import { QUESTIONS } from 'routes/lead/will-form/config'
import startCase from 'lodash/startCase'

const DiagnosisRow = ({ Question, attributes }) => {
  const formattedAnswer = getFormattedAnswer(Question, attributes)

  return (
    <Table.Row>
      <Table.Cell>{Question.label}</Table.Cell>
      <Table.Cell>
        {formattedAnswer === null ? (
          'Not asked'
        ) : (
          <strong>{formattedAnswer}</strong>
        )}
      </Table.Cell>
    </Table.Row>
  )
}

const WillCaseSummary = ({ attributes }) => {
  const { isPaymentTaken, paymentReference } = attributes.quoteAttributes
  return (
    <>
      <Table>
        <Table.Body>
          <Table.Row>
            <Table.Cell>
              <strong>Customer's Details</strong>
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Name:</Table.Cell>
            <Table.Cell>
              <strong>
                {attributes.contact.firstName} {attributes.contact.lastName}
              </strong>
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Phone:</Table.Cell>
            <Table.Cell>
              <strong>{attributes.contact.phoneNumber}</strong>
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Email:</Table.Cell>
            <Table.Cell>
              <strong>{attributes.contact.email}</strong>
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Type of will:</Table.Cell>
            <Table.Cell>
              <strong>{attributes.quoteAttributes.willType}</strong>
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Partner type:</Table.Cell>
            <Table.Cell>
              <strong>{startCase(attributes.partnerType)}</strong>
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Partner name:</Table.Cell>
            <Table.Cell>
              {attributes.partnerUtmSource ? (
                <strong>{attributes.partnerUtmSource}</strong>
              ) : (
                'Not from a partner'
              )}
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
      <br />

      {Object.keys(QUESTIONS).map((questionType) => (
        <span key={questionType}>
          <Table>
            <Table.Body>
              <Table.Row>
                <Table.Cell>
                  <strong>{DIAGNOSTIC_HEADINGS[questionType]}</strong>
                </Table.Cell>
              </Table.Row>
              {QUESTIONS[questionType].map((question) => (
                <DiagnosisRow
                  Question={question}
                  attributes={attributes}
                  key={question.name}
                />
              ))}
            </Table.Body>
          </Table>
          <br />
        </span>
      ))}

      <Table>
        <Table.Body>
          <Table.Row>
            <Table.Cell>
              <strong>Payment</strong>
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell width="75%">Price</Table.Cell>
            <Table.Cell nowrap>
              <strong>
                {attributes.quoteAttributes.price
                  ? '£' +
                    Dinero({
                      amount: attributes.quoteAttributes.price,
                    }).toUnit()
                  : 'Not Quoted'}
              </strong>
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Payment taken:</Table.Cell>
            <Table.Cell>
              <strong>
                {formatAnswer(isPaymentTaken || !!paymentReference)}
              </strong>
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    </>
  )
}

WillCaseSummary.propTypes = {
  atributes: PropTypes.shape({
    partnerType: PropTypes.string,
    partnerUtmSource: PropTypes.string,
    contact: PropTypes.object,
    quoteAttributes: PropTypes.object,
  }),
}

export default WillCaseSummary
