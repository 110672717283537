import { Button, ButtonGroup, Divider, P, Wrapper } from '@farewill/ui'
import { GTR } from '@farewill/ui/tokens'
import { Form, Formik } from 'formik'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { useQueryClient } from 'react-query'
import { toast } from 'react-toastify'

import { hideModal } from 'state/actions'
import Input from 'components/form/input'
import ExternalLink from 'components/external-link'
import { EDIT_ACCOUNT_DETAILS_SCHEMA } from 'lib/formik/schemata'
import { RESOURCES, useCreateResource } from 'api/index'

const StyledWrapper = styled(Wrapper)`
  display: flex;
  flex-direction: column;
  gap: ${GTR.M};
`

type FormValues = {
  email: string
  confirmEmail: string
}

type Props = {
  config: {
    uuid: string
    fetchContact: () => void
  }
}

const EditAccountDetailsModal = ({
  config: { uuid, fetchContact },
}: Props): React.ReactElement => {
  const dispatch = useDispatch()
  const cache = useQueryClient()

  /* The endpoint needs the POST method so we use useCreateResource */
  const updateEmailMutation = useCreateResource()

  const handleSubmit = async (values: FormValues): Promise<void> => {
    await updateEmailMutation.mutateAsync({
      resource: RESOURCES.ACCOUNTS_UPDATE_EMAIL,
      endpoint: '/api/accounts/admin/update-email',
      attributes: {
        uuid,
        newEmail: values.email,
        adminRequest: true,
      },
    })

    toast('Account email updated.', {
      toastId: 'account-email-updated',
    })

    /**
     * The email address is part of both of the contact and account so
     * we need to refresh both queries in the background.
     */
    fetchContact()
    cache.invalidateQueries(['accountByUuid', { uuid }])

    dispatch(hideModal())
  }

  return (
    <Wrapper>
      <P>
        <ExternalLink href="https://www.notion.so/farewill/Verifying-someone-s-identity-389a48987c53466a9d66531e6a3aaff2">
          Verify their identity
        </ExternalLink>{' '}
        first. This is the email address that the customer uses to log in to
        Farewill apps online.
      </P>
      <P>
        Let the caller know that they will receive an email confirming the
        change.
      </P>
      <Divider margin={['M', 0]} />
      <Formik
        onSubmit={handleSubmit}
        initialValues={{ email: '', confirmEmail: '' }}
        validationSchema={EDIT_ACCOUNT_DETAILS_SCHEMA}
      >
        <Form>
          <StyledWrapper>
            <Input name="email" label="New email" type="email" />
            <Input name="confirmEmail" label="Confirm email" type="email" />
          </StyledWrapper>
          <ButtonGroup margin={['L', 0, 0]}>
            <Button.Primary
              type="submit"
              loading={updateEmailMutation.isLoading}
            >
              Save
            </Button.Primary>
            <Button.Underline
              type="button"
              onClick={() => dispatch(hideModal())}
            >
              Cancel
            </Button.Underline>
          </ButtonGroup>
        </Form>
      </Formik>
    </Wrapper>
  )
}

export default EditAccountDetailsModal
