import { useEffect } from 'react'
import { useFormikContext } from 'formik'
import moment from 'moment'
import { FILTERS } from './'

import IndentedRadioGroup from 'components/form/indented-radio-group'

const LastActivityFilter = ({ name }: { name: keyof typeof FILTERS }) => {
  const { setFieldValue, values } = useFormikContext<typeof FILTERS>()
  const filter = values[name]
  let option: string | undefined

  if (typeof filter === 'object' && 'option' in filter) {
    option = filter.option
  }

  useEffect(() => {
    if (option === 'any') {
      setFieldValue(`${name}.gte`, '')
      setFieldValue(`${name}.lte`, '')
    }

    if (option === 'last7Days') {
      setFieldValue(
        `${name}.gte`,
        moment().subtract(7, 'days').format('YYYY-MM-DD')
      )
      setFieldValue(`${name}.lte`, 'tomorrow')
    }

    if (option === 'moreThan7Days') {
      setFieldValue(`${name}.gte`, '2019-01-01')
      setFieldValue(
        `${name}.lte`,
        moment().subtract(7, 'days').format('YYYY-MM-DD')
      )
    }
  }, [name, option, values, setFieldValue])

  return (
    <IndentedRadioGroup
      name={`${name}.option`}
      options={[
        { value: 'any', label: 'Any' },
        { value: 'last7Days', label: 'Within last 7 days' },
        { value: 'moreThan7Days', label: '7+ days' },
      ]}
    />
  )
}

export default LastActivityFilter
