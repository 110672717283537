import Dinero from 'dinero.js'
Dinero.globalLocale = 'en-GB'
Dinero.defaultCurrency = 'GBP'

export const formatCurrency = ({
  showPence = true,
  value = 0,
  valueInPence = false,
}: {
  showPence?: boolean
  value?: number
  valueInPence?: boolean
}): string => {
  const amountInPence = valueInPence ? value : Math.round(value * 100)

  const minimumFractionDigits = showPence ? 2 : 0
  const maximumFractionDigits = showPence ? 2 : 0
  const formattedValue = new Intl.NumberFormat('en-GB', {
    style: 'currency',
    currency: 'GBP',
    minimumFractionDigits,
    maximumFractionDigits,
  }).format(amountInPence / 100)

  return formattedValue
}

export const fromDecimal = (value = 0): Dinero.Dinero => {
  const amountInPence = value * 100
  const amount = Math.round(amountInPence)
  return Dinero({ amount, currency: 'GBP' })
}

export const hasDecimals = (value: number): boolean => {
  return value % 1 !== 0
}
