import styled from 'styled-components'
import { Divider, P } from '@farewill/ui'
import { FONT, GTR } from '@farewill/ui/tokens'
import { useFormikContext } from 'formik'

import SectionStatus from 'components/section-status'
import Tooltip from 'components/tooltip'
import { QuoteResponseDataProbate } from 'lib/types'
import EstateAdminQuoteBreakdown from './estate-admin-quote-breakdown'
import EstateAdminCopyToClipboard from './estate-admin-copy-to-clipboard'

import Dinero from 'dinero.js'
import { ProbateLeadAttributes } from 'lib/models/lead'
import { SelectInput } from 'components/form'
import { formatCurrency, hasDecimals } from 'lib/formatting/currency'
Dinero.globalLocale = 'en-GB'
Dinero.defaultCurrency = 'GBP'

const StyledRevertLink = styled.button`
  text-decoration: underline;
`

const StyledList = styled.ul`
  font-size: ${GTR.S};

  li {
    padding: ${GTR.XXS} 0;

    &::before {
      content: '•';
      margin-right: 0.5rem;
    }
  }
`

const StyledPrice = styled(P)`
  font-size: ${FONT.SIZE.XL};
  margin-bottom: 0;
  font-weight: ${FONT.WEIGHT.MEDIUM};
`

const ESTATE_ADMIN_TOOLTIP =
  'We refer Complete Probate cases to New Law. Very broadly, their service includes: 1. Estate assessment, 2. Estate valuation, 3. Grant application, 4. Asset collection, 5. Initial distribution to the beneficiaries, 6. Estate accounts and final distribution'

const EstateAdminQuote = ({
  quote,
}: {
  quote: QuoteResponseDataProbate | null
}) => {
  const { values, setFieldValue, handleSubmit } =
    useFormikContext<ProbateLeadAttributes>()

  const estateAdminQuote = quote?.attributes.estateAdmin
  const estateAdminQuoteLoaded = !!estateAdminQuote
  const estateAdminAmountInPence = estateAdminQuote?.amountInPence
  return (
    <SectionStatus
      heading="Complete Probate"
      incompleteHeading="Complete Probate"
      complete
    >
      {!estateAdminQuoteLoaded ? (
        <P size="S">Loading Complete Probate quote...</P>
      ) : (
        <>
          {estateAdminAmountInPence && (
            <>
              <P size="S">
                If the caller prefers our{' '}
                <Tooltip content={ESTATE_ADMIN_TOOLTIP}>
                  Complete Probate service
                </Tooltip>
                , our quote is:
              </P>
              <StyledPrice>
                {formatCurrency({
                  value: estateAdminAmountInPence,
                  valueInPence: true,
                  showPence: hasDecimals(estateAdminAmountInPence / 100),
                })}
              </StyledPrice>
              <P size="S" margin={[0, 0, 'XS']}>
                This fee includes:
              </P>
              <StyledList>
                <li>VAT</li>
                <li>Standard NL disbursements</li>
              </StyledList>

              <P size="S">
                Let the customer know that this quote is based on the
                information provided, if there are any missing details, the
                price may change as a result
              </P>
              <SelectInput
                options={[
                  { label: 'IHT205', value: 'iht205' },
                  { label: 'IHT217', value: 'iht217' },
                  { label: 'IHT400 (no tax)', value: 'iht400_no_tax' },
                  { label: 'IHT400 (tax)', value: 'iht400_tax' },
                  { label: 'IHT400 (+£1 million)', value: 'iht400_million' },
                ]}
                name="quoteAttributes.ihtForm"
                label="This is an:"
                handleSave={({ value }) => {
                  setFieldValue('quoteAttributes.ihtType', value)
                  handleSubmit()
                }}
              />
              <P size="S" margin={['S', 0, 0]}>
                {values.quoteAttributes.ihtType === '' &&
                values.quoteAttributes.ihtForm ? (
                  'This has been calculated automatically.'
                ) : (
                  <>
                    This has been manually updated.{' '}
                    <StyledRevertLink
                      onClick={() => {
                        setFieldValue('quoteAttributes.ihtType', '')
                        handleSubmit()
                      }}
                    >
                      Revert to automatic calculation.
                    </StyledRevertLink>
                  </>
                )}
              </P>
              <Divider margin={['M', 0]} />
              <EstateAdminQuoteBreakdown quote={quote} />
              <EstateAdminCopyToClipboard quote={quote} />
            </>
          )}
          {!estateAdminAmountInPence && (
            <>
              <P size="S">
                We can’t provide a quote for Complete Probate because{' '}
                {estateAdminQuote.unableToQuoteReasons.join(' and ')}.
              </P>
              <P size="S">Please refer to New Law.</P>
            </>
          )}
        </>
      )}
    </SectionStatus>
  )
}

export default EstateAdminQuote
