import {
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react'

import apiRequest from 'lib/axios/api-request'
import {
  ProbateEstateItemType,
  ProbateEstateItem,
  ProbateEstateItemsAttributes,
} from 'lib/models/probate-estate'
import { groupProbateEstateItems } from 'routes/probate-estate/helpers'

interface ItemsContextProps {
  items: ProbateEstateItem[]
  groupedItems: Record<string, ProbateEstateItem[]>
  createItem: (
    attributes: Partial<ProbateEstateItemsAttributes>
  ) => Promise<void>
  updateItem: (
    item: Partial<ProbateEstateItem>,
    itemType: ProbateEstateItemType
  ) => Promise<void>
  deleteItem: (id: number) => Promise<void>
}

const ItemsContext = createContext<ItemsContextProps>({
  items: [],
  groupedItems: {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  createItem: async () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  updateItem: async () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  deleteItem: async () => {},
})

const ItemsProvider = ({
  children,
  probateEstateId,
}: PropsWithChildren<{
  probateEstateId: number
}>) => {
  const [items, setItems] = useState<ProbateEstateItem[]>([])
  const [groupedItems, setGroupedItems] = useState<
    Record<string, ProbateEstateItem[]>
  >({})

  useEffect(() => {
    if (!probateEstateId) return

    apiRequest({
      url: `/api/probate-estates/${probateEstateId}/items`,
      method: 'GET',
    }).then((response) => {
      setItems(response.data.data)
    })
  }, [probateEstateId])

  useEffect(() => {
    setGroupedItems(groupProbateEstateItems(items))
  }, [items, setGroupedItems])

  const createItem = async (
    attributes: Partial<ProbateEstateItemsAttributes>
  ) => {
    const apiResponse = await apiRequest({
      url: `/api/probate-estates/${probateEstateId}/items`,
      method: 'POST',
      data: {
        data: {
          type: 'probate_estate_items',
          attributes: { ...attributes },
        },
      },
    })
    setItems([...items, apiResponse.data.data])
  }

  const updateItem = async (
    item: Partial<ProbateEstateItem>,
    itemType: ProbateEstateItemType
  ) => {
    return apiRequest({
      url: `/api/probate-estates/${probateEstateId}/items/${item.id}`,
      method: 'PATCH',
      data: {
        data: {
          type: 'probate_estate_items',
          id: item.id,
          attributes: { ...item.attributes, itemType },
        },
      },
    }).then(({ data }) => {
      const updatedItemData: ProbateEstateItem = data.data
      setItems((prevItems) =>
        prevItems.map((item) =>
          item.id === updatedItemData.id ? updatedItemData : item
        )
      )
    })
  }

  const deleteItem = async (id: number) => {
    await apiRequest({
      url: `/api/probate-estates/${probateEstateId}/items/${id}`,
      method: 'DELETE',
    }).then(() => {
      const updatedItems = items.filter((p) => p.id !== id)
      setItems(updatedItems)
    })
  }

  return (
    <ItemsContext.Provider
      value={{ items, groupedItems, createItem, updateItem, deleteItem }}
    >
      {children}
    </ItemsContext.Provider>
  )
}

const useItems = () => useContext(ItemsContext)

export { ItemsProvider, useItems }
