import DateRangeFilter from '../shared/date-range-filter'
import HelpNeededFilter from './help-needed-filter'
import FilterWrapper from '../shared/filter-wrapper'
import SelectInput from 'components/form/select-input'
import { P } from '@farewill/ui'
import Input from 'components/form/input'

const TasksViewFilters = ({
  isTasksView,
  adminUserOptions,
  nextCoreTaskOptions,
  statusOptions,
}) => {
  return (
    <>
      {isTasksView && (
        <FilterWrapper>
          <P size="S" strong>
            Task owner
          </P>
          <SelectInput
            options={adminUserOptions}
            name="currentAssigneeId"
            isMulti
            small
          />
        </FilterWrapper>
      )}

      {nextCoreTaskOptions && (
        <FilterWrapper>
          <P size="S" strong>
            Current core task
          </P>
          <SelectInput
            options={nextCoreTaskOptions}
            name="nextCoreTask"
            isMulti
            small
          />
        </FilterWrapper>
      )}
      <FilterWrapper>
        <P size="S" strong>
          Description
        </P>
        <Input name="title" small />
      </FilterWrapper>
      <FilterWrapper>
        <P size="S" strong>
          Due
        </P>
        <DateRangeFilter name="dueOn" />
      </FilterWrapper>
      <FilterWrapper>
        <HelpNeededFilter />
      </FilterWrapper>
      {statusOptions && (
        <FilterWrapper>
          <P size="S" strong>
            Case status
          </P>
          <SelectInput
            options={statusOptions}
            name="probateCaseStatus"
            isMulti
            small
          />
        </FilterWrapper>
      )}
    </>
  )
}

export default TasksViewFilters
