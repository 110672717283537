import { WillLeadAttributes } from 'lib/models/lead'
import { strToBool } from 'utils/helpers'

import { COUNTRIES } from '../constants'

export const isUnsupportedResidence = (values: WillLeadAttributes) => {
  const unsupportedCountries: string[] = [
    COUNTRIES.SCOTLAND,
    COUNTRIES.NORTHERN_IRELAND,
  ]
  const residence = values.quoteAttributes.residence || ''
  const permanentResidence = values.quoteAttributes.permanentResidence || ''
  const residenceIsPermanentAddress = strToBool(
    values.quoteAttributes.residenceIsPermanentAddress
  )

  // If person lives in Scotland or Northern Ireland and it's their permanent address, they are not eligible
  if (unsupportedCountries.includes(residence) && residenceIsPermanentAddress) {
    return true
  }

  // If person doesn't live permanently in their residence country, but the place they stay permanently is Scotland or Northern Ireland, they are not eligible
  if (
    !residenceIsPermanentAddress &&
    unsupportedCountries.includes(permanentResidence)
  ) {
    return true
  }

  return false
}
