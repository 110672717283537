import { useEffect } from 'react'
import { useQueryClient } from 'react-query'
import { Button, Grid, H, P, Wrapper } from '@farewill/ui'
import { GTR } from '@farewill/ui/tokens'
import { useSelector, useDispatch } from 'react-redux'
import { Formik, Form } from 'formik'
import { Link } from 'react-router-dom'
import isNull from 'lodash/isNull'

import PRICING from 'config/pricing'
import { showModal } from 'state/actions'
import { INVOICE_LINE_ITEMS } from 'utils/enums'
import {
  findCaseBookedInvoice,
  findLineItemByDescription,
  findTask,
} from 'utils/helpers'
import useApi from 'lib/effects/api'
import { formatDate } from 'lib/formatting/dates'
import { fromDecimal } from 'lib/formatting/currency'

import Input from 'components/form/input'
import MessageBox from 'components/message-box'
import { useTasksContext } from 'components/tasks-panel/context'
import FakeInput from 'components/fake-input'

import { useInvoicesContext } from './context'
import DeleteInvoiceModal from './delete-invoice-modal'

import Dinero from 'dinero.js'
Dinero.globalLocale = 'en-GB'
Dinero.defaultCurrency = 'GBP'

const DEFAULT_NUM_UK_GRANTS = 6
const DEFAULT_NUM_OVERSEAS_GRANTS = 0

const EditInvoiceForm = ({ caseBookedInvoice }) => {
  const dispatch = useDispatch()
  const { updateLineItem, deleteInvoice, isDeletingInvoice } =
    useInvoicesContext()
  const probateCase = useSelector((state) => state.probateCase)
  const { estateAdministrationRequired } = probateCase.attributes
  const { tasks, fetchTasks } = useTasksContext()
  const caseBookedTask = findTask(tasks, 'case_booked')

  const serviceFeeLineItem = findLineItemByDescription({
    invoice: caseBookedInvoice,
    description: INVOICE_LINE_ITEMS.SERVICE_FEE,
  })
  const grantCopiesLineItem = findLineItemByDescription({
    invoice: caseBookedInvoice,
    description: INVOICE_LINE_ITEMS.GRANT_COPIES,
  })

  const initialValues = {
    price: Dinero({ amount: serviceFeeLineItem.price })
      .add(
        Dinero({ amount: serviceFeeLineItem.price }).percentage(
          estateAdministrationRequired ? 0 : PRICING.VAT_PERCENTAGE
        )
      )
      .toUnit(),
    quantity: grantCopiesLineItem.quantity,
  }

  const probateEstateId = probateCase.attributes.estate.id
  const factFindLink = `/probate/estates/${probateEstateId}/fact-find#6.5`

  const handleDeleteClick = () => {
    const { probateCaseId } = caseBookedInvoice.attributes
    dispatch(
      showModal({
        component: DeleteInvoiceModal,
        headingText: 'Delete this invoice?',
        config: {
          deleteInvoice: async () => {
            await deleteInvoice(caseBookedInvoice.id)
            return fetchTasks({
              queryParams: {
                'filter[probateCaseId]': probateCaseId,
                'filter[isNeeded]': true,
                'page[size]': 200,
              },
            })
          },
        },
      })
    )
  }

  const handleLineItemUpdate =
    (lineItem) =>
    ({ name, value }) => {
      if (name === 'price') {
        let priceAsDinero = fromDecimal(value)
        if (!estateAdministrationRequired) {
          priceAsDinero = priceAsDinero.allocate([
            PRICING.VAT_PERCENTAGE,
            100,
          ])[1]
        }
        value = priceAsDinero.getAmount()
      }

      updateLineItem(lineItem, { [name]: value })
    }

  return (
    <Formik initialValues={initialValues}>
      <Form>
        <Grid>
          <Grid.Item>
            <P>
              <Link
                to={`/probate/cases/${caseBookedInvoice.attributes.probateCaseId}/progress`}
              >
                <strong>Case booked</strong>{' '}
                {formatDate(caseBookedTask?.attributes?.completedAt)}
              </Link>
            </P>
          </Grid.Item>
          <Grid.Item span={5}>
            <Input
              name="price"
              label="Final price (£)"
              handleSave={handleLineItemUpdate(serviceFeeLineItem)}
            />
          </Grid.Item>
          <Grid.Item span={5}>
            <FakeInput
              label="Total number of additional grants"
              value={grantCopiesLineItem.quantity}
              link={factFindLink}
            />
          </Grid.Item>
          <Grid.Item>
            <Button.Secondary
              type="button"
              onClick={handleDeleteClick}
              loading={isDeletingInvoice}
              style={{ paddingTop: GTR.XXS, paddingBottom: GTR.XXS }}
            >
              Delete invoice
            </Button.Secondary>
          </Grid.Item>
        </Grid>
      </Form>
    </Formik>
  )
}

const NewInvoiceForm = () => {
  const { fetchTasks } = useTasksContext()
  const { createInvoice, isCreatingInvoice } = useInvoicesContext()
  const probateCase = useSelector((state) => state.probateCase)
  const { calculatedPrice, referredTo } = probateCase.attributes

  const [
    { data: probateEstate, isLoading: isLoadingProbateEstate },
    fetchProbateEstate,
  ] = useApi()
  const probateEstateId = probateCase.attributes.estate.id

  useEffect(() => {
    fetchProbateEstate({ url: `/api/probate-estates/${probateEstateId}` })
  }, [fetchProbateEstate, probateEstateId])

  const [{ data: summary }, fetchSummary] = useApi()
  const cache = useQueryClient()
  const constants = cache.getQueryData('constants')
  const probateCourtFeePrice = constants?.attributes?.probateCourtFeePrice || 0

  useEffect(() => {
    const url = `/api/probate-estates/${probateEstateId}/summary`
    fetchSummary({ url })
  }, [fetchSummary, probateEstateId])

  const handleClick = async () => {
    const serviceFeePrice = Dinero({ amount: calculatedPrice || 0 })
      .allocate([PRICING.VAT_PERCENTAGE, 100])[1]
      .getAmount()

    const quantity =
      (probateEstate.attributes.defaultNumberOfGrantsRequested
        ? DEFAULT_NUM_UK_GRANTS
        : probateEstate.attributes.numberOfGrantsRequested) +
      (probateEstate.attributes.overseasGrantsRequested
        ? probateEstate.attributes.numberOfOverseasGrantsRequested
        : DEFAULT_NUM_OVERSEAS_GRANTS)

    const lineItems = [
      {
        description: INVOICE_LINE_ITEMS.SERVICE_FEE,
        price: serviceFeePrice,
        quantity: 1,
        taxRate: PRICING.VAT_RATE,
      },
      {
        description: INVOICE_LINE_ITEMS.GRANT_COPIES,
        price: PRICING.GRANT_COPY_PRICE,
        quantity,
        taxRate: 0,
      },
    ]

    if (
      summary.attributes.financials.netEstateForGrant.value > 5000 ||
      referredTo === 'new_law'
    ) {
      lineItems.push({
        description: INVOICE_LINE_ITEMS.COURT_FEE,
        price: probateCourtFeePrice,
        quantity: 1,
        taxRate: 0,
      })
    }

    await createInvoice({
      probateCaseId: probateCase.id,
      paidTo: referredTo,
      lineItems,
    })

    return fetchTasks({
      queryParams: {
        'filter[probateCaseId]': probateCase.id,
        'filter[isNeeded]': true,
        'page[size]': 200,
      },
    })
  }

  if (isNull(probateCase.attributes.estateAdministrationRequired)) {
    return (
      <MessageBox warning>
        Please select 'Essential Probate' or 'Complete Probate' on the{' '}
        <Link to={`/probate/cases/${probateCase.id}`}>Case details</Link> page
        before marking a case as booked.
      </MessageBox>
    )
  }

  return (
    <Button.Primary
      onClick={handleClick}
      loading={isCreatingInvoice || isLoadingProbateEstate}
    >
      {isLoadingProbateEstate ? 'Loading...' : 'Case booked'}
    </Button.Primary>
  )
}

const Price = () => {
  const { invoices } = useInvoicesContext()
  const caseBookedInvoice = findCaseBookedInvoice(invoices)
  const probateCase = useSelector((state) => state.probateCase)
  const { calculatedPrice } = probateCase.attributes

  return (
    <Wrapper>
      <H size="S">Price</H>
      <P>
        {calculatedPrice
          ? `Price quoted: ${Dinero({ amount: calculatedPrice }).toFormat(
              '$0,0'
            )} (inc VAT)`
          : 'No quote given on initial call'}
      </P>
      {caseBookedInvoice ? (
        <EditInvoiceForm caseBookedInvoice={caseBookedInvoice} />
      ) : (
        <NewInvoiceForm />
      )}
    </Wrapper>
  )
}

export default Price
