export const FUNERAL_BILL_AMOUNT = 'funeralBillAmount'
export const FUNERAL_WAKE_AMOUNT = 'funeralWakeAmount'
export const FUNERAL_FLOWERS_AMOUNT = 'funeralFlowersAmount'
export const FUNERAL_OTHER_AMOUNT = 'funeralOtherAmount'
export const FUNERAL_AMOUNT_NOTES = 'funeralAmountNotes'
export const FUNERAL_HAD_PRE_PAID_PLAN = 'funeralHadPrePaidPlan'
export const FUNERAL_AMOUNT_CONVERED_BY_PLAN = 'funeralAmountCoveredByPlan'

export const FUNERAL_SECTION_ACTIVE = 'funeralSectionActive'
export const MORTGAGES_SECTION_ACTIVE = 'mortgagesSectionActive'
export const CREDIT_CARDS_SECTION_ACTIVE = 'creditCardsSectionActive'
export const LOANS_SECTION_ACTIVE = 'loansSectionActive'
export const UTILITIES_AND_OTHER_DEBTS_SECTION_ACTIVE =
  'utilitiesAndOtherDebtsSectionActive'
