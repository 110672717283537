import { MARITAL_STATUS } from '../people/field-names'
import { MARRIED } from 'utils/enums'
import {
  SPOUSE_SURVIVED_TWENTY_EIGHT_DAYS,
  HAS_LIVING_CHILDREN_OR_GRANDCHILDREN,
  HAS_LIVING_PARENTS,
  HAS_LIVING_SIBLINGS_OR_DESCENDANTS_OF,
  HAS_LIVING_HALF_SIBLINGS_OR_DESCENDANTS_OF,
  HAS_LIVING_GRANDPARENTS,
  HAS_LIVING_AUNTS_OR_UNCLES_OR_DESCENDANTS_OF,
  HAS_LIVING_HALF_AUNTS_OR_UNCLES_OR_CHILDREN_OF,
} from './field-names'

import {
  IS_ALIVE,
  IS_OVER_EIGHTEEN,
  RELATIONSHIP_TO_DECEASED,
  HAS_LIVING_CHILDREN,
} from './relatives/field-names'

export const getVisibleFields = ({ values }) => {
  const showSpouseSurvivedTwentyEightDays = values[MARITAL_STATUS] === MARRIED

  const showExtendedRelationFields =
    values[MARITAL_STATUS] !== MARRIED ||
    values[SPOUSE_SURVIVED_TWENTY_EIGHT_DAYS] === 'false'

  const showLivingParents =
    showExtendedRelationFields &&
    values[HAS_LIVING_CHILDREN_OR_GRANDCHILDREN] === 'false'

  const showLivingSiblings =
    showLivingParents && values[HAS_LIVING_PARENTS] === 'false'

  const showLivingHalfSiblings =
    showLivingParents &&
    showLivingSiblings &&
    values[HAS_LIVING_SIBLINGS_OR_DESCENDANTS_OF] === 'false'

  const showLivingGrandparents =
    showLivingParents &&
    showLivingSiblings &&
    showLivingHalfSiblings &&
    values[HAS_LIVING_HALF_SIBLINGS_OR_DESCENDANTS_OF] === 'false'

  const showLivingAuntsOrUncles =
    showLivingParents &&
    showLivingSiblings &&
    showLivingHalfSiblings &&
    showLivingGrandparents &&
    values[HAS_LIVING_GRANDPARENTS] === 'false'

  const showLivingHalfAuntsOrUncles =
    showLivingParents &&
    showLivingSiblings &&
    showLivingHalfSiblings &&
    showLivingGrandparents &&
    showLivingAuntsOrUncles &&
    values[HAS_LIVING_AUNTS_OR_UNCLES_OR_DESCENDANTS_OF] === 'false'

  const showEstateToCrownWarning =
    showLivingParents &&
    showLivingSiblings &&
    showLivingHalfSiblings &&
    showLivingGrandparents &&
    showLivingAuntsOrUncles &&
    showLivingHalfAuntsOrUncles &&
    values[HAS_LIVING_HALF_AUNTS_OR_UNCLES_OR_CHILDREN_OF] === 'false'

  return {
    showSpouseSurvivedTwentyEightDays,
    showLivingParents,
    showLivingSiblings,
    showLivingHalfSiblings,
    showLivingGrandparents,
    showLivingAuntsOrUncles,
    showLivingHalfAuntsOrUncles,
    showEstateToCrownWarning,
  }
}

export const getRelativeVisibleFields = ({ values }) => {
  const isDeceased = values[IS_ALIVE] === 'false'

  const showIsOverEighteen = !isDeceased

  const showUnderEighteenQuestions =
    !isDeceased && values[IS_OVER_EIGHTEEN] === 'false'

  const showDiedBeforeDeceased = isDeceased

  const showHasLivingChildren =
    isDeceased &&
    !['parent', 'grandparent'].includes(values[RELATIONSHIP_TO_DECEASED])

  const showChildrenGuidance =
    showHasLivingChildren && values[HAS_LIVING_CHILDREN] === 'true'

  return {
    showIsOverEighteen,
    showUnderEighteenQuestions,
    showDiedBeforeDeceased,
    showHasLivingChildren,
    showChildrenGuidance,
  }
}
