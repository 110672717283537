import { connect } from 'react-redux'
import Modal from '../components'
import { hideModal } from '../../../state/actions'

const mapStateToProps = (state) => {
  return state.modal.default
}

const mapDispatchToProps = (dispatch) => {
  return {
    hideModal: () => dispatch(hideModal()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Modal)
