import { Button } from '@farewill/ui'

import useApi from 'lib/effects/api'

type ApiResponse = {
  data: {
    attributes: {
      url: string
    }
  }
}

type Props = {
  externalUserId: string
}

const SimpleTelephoneWillLoginButton = ({
  externalUserId,
}: Props): React.ReactElement => {
  const [{ isLoading }, makeRequest] = useApi()

  const loginTelephoneUser = async () => {
    const res = await makeRequest<ApiResponse>({
      url: '/api/telephone-will-login-urls',
      method: 'POST',
      data: {
        data: {
          attributes: { externalUserId },
          type: 'telephone_will_login_urls',
        },
      },
    })

    const { url } = res.data.attributes
    window.open(url)
  }

  return (
    <Button.Underline
      flush
      onClick={() => loginTelephoneUser()}
      disabled={isLoading}
    >
      Login to account
    </Button.Underline>
  )
}

export default SimpleTelephoneWillLoginButton
