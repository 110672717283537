import startCase from 'lodash/startCase'
import moment from 'moment'
import { ReactElement } from 'react'
import { useQueryClient } from 'react-query'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { P, Wrapper } from '@farewill/ui'
import { COLOR, FONT, GTR } from '@farewill/ui/tokens'

import { AdminUser } from 'lib/models/admin-user'
import { Event } from 'lib/models/event'
import { LeadEvent, PropertyChange } from 'lib/models/lead-event'
import { LEAD_PARTNER_TYPES } from 'utils/enums'
import { formatToHuman } from 'utils/helpers'

import LeadEventIcon from './icon'
import { formatLeadChanges, formatProductName } from './methods'

const LEAD_EVENT_PROPERTY_TYPES = {
  conversionScore: 'Lead score',
  ownedByAdminUserId: 'Lead assigned to',
}

const EventItemWrapper = styled(Wrapper)`
  display: flex;
  margin-bottom: ${GTR.L};
`

const EventSummary = styled(P)`
  font-size: ${FONT.SIZE.S};
  margin-bottom: 0;
  text-decoration: none;
`

const EventNotes = styled(P)`
  white-space: pre-wrap;
`

const StyledGreyText = styled.span`
  font-size: ${FONT.SIZE.S};
  color: ${COLOR.GREY.MEDIUM};
`

const StyledChangeListItem = styled.li`
  font-size: ${FONT.SIZE.S};

  &:not(:last-of-type) {
    margin-bottom: ${GTR.XS};
  }
`

const StyledLink = styled.a`
  font-weight: bold;
  font-size: ${FONT.SIZE.S};
`

const StyledList = styled.ul`
  margin-top: ${GTR.XS};
`

const UtmTermText = styled(P)`
  font-size: ${FONT.SIZE.XS};
`

const LeadEventItem = ({
  event,
}: {
  event: LeadEvent | Event
}): ReactElement => {
  const cache = useQueryClient()
  const adminUsers = cache.getQueryData('adminUsers')
  const eventData = event.attributes
  const isLeadEvent = 'leadId' in eventData

  // event is either of type LeadEvent or Event. Because Event doesn't have `happenedAt` attribute, it will use `updatedAt`.
  const time = isLeadEvent ? eventData.happenedAt : eventData.updatedAt
  // event is either of type LeadEvent or Event. Because Event doesn't have `type` attribute, it will use `name`.
  const type = isLeadEvent ? eventData.type : eventData.name

  const formattedTime = moment(time).format('D MMM YYYY, HH:mm')
  const formattedType = formatToHuman(type)

  const showLeadChanges = isLeadEvent && !!eventData?.leadChanges?.length
  const isPartnerLead =
    isLeadEvent &&
    eventData?.lead?.partnerType === LEAD_PARTNER_TYPES.other_partner
  const isAddedEvent = isLeadEvent && eventData.type === 'added'
  const zendeskTicketUrl =
    isLeadEvent &&
    eventData?.metadata?.zendeskTicketId &&
    `https://farewill.zendesk.com/agent/tickets/${eventData.metadata.zendeskTicketId}`
  const funeralCrossSellCaseId = isLeadEvent && eventData.metadata?.funeralId
  const utmTerm = isLeadEvent && eventData.metadata?.utmTerm
  const adminUserName = eventData.adminUser?.name
  const isContactsMergedEvent = !isLeadEvent && type === 'contacts_merged'

  return (
    <EventItemWrapper key={event.id}>
      <LeadEventIcon type={type} adminUser={event.attributes?.adminUser} />
      <Wrapper>
        <EventSummary>
          {formattedType}{' '}
          {adminUserName ? (
            <>
              by <strong>{eventData.adminUser.name}</strong>
            </>
          ) : (
            ''
          )}
        </EventSummary>
        <StyledGreyText>{formattedTime}</StyledGreyText>{' '}
        {isLeadEvent && (
          <StyledGreyText>
            &bull;{' '}
            <Link to={`/leads/${eventData.leadId}`}>
              View {formatProductName(eventData.lead.product)} lead
            </Link>
          </StyledGreyText>
        )}
        {isLeadEvent && eventData.metadata?.notes && (
          <EventNotes size="S">{eventData.metadata.notes}</EventNotes>
        )}
        {isContactsMergedEvent && (
          <>
            <P size="S">
              Another contact was found with the same email:{' '}
              {eventData.metadata?.deletedContact?.email}. Data from the two
              contacts was merged and the duplicate contact was deleted.
            </P>
            {eventData.metadata?.deletedContact?.phoneNumber && (
              <P size="S">
                Phone number from the deleted contact:{' '}
                {eventData.metadata?.deletedContact?.phoneNumber}
              </P>
            )}
          </>
        )}
        {funeralCrossSellCaseId && (
          <Link to={`/funerals/${funeralCrossSellCaseId}`}>
            <P size="S" strong>
              View funeral case
            </P>
          </Link>
        )}
        {isAddedEvent && isPartnerLead && (
          <EventNotes size="S">
            <strong>Referred by</strong>{' '}
            {eventData.metadata.referredBy || 'Unknown'} (
            {startCase(eventData.lead.partnerUtmSource as string)})
          </EventNotes>
        )}
        {!isLeadEvent && type === 'objected_to_partner_data_sharing' && (
          <EventNotes size="S">
            <P>
              <strong>Data sharing</strong> Do not share future data with
              partners
            </P>
          </EventNotes>
        )}
        {zendeskTicketUrl && (
          <StyledLink
            target="_blank"
            rel="noopener noreferrer"
            href={zendeskTicketUrl as string}
          >
            View ticket on Zendesk
          </StyledLink>
        )}
        {utmTerm && (
          <UtmTermText>
            Search terms: <i>{utmTerm as string}</i>
          </UtmTermText>
        )}
        {showLeadChanges && (
          <StyledList>
            {formatLeadChanges(
              eventData.leadChanges,
              adminUsers as AdminUser[]
            ).map((change: PropertyChange, index) => (
              <StyledChangeListItem key={index}>
                <strong>
                  {LEAD_EVENT_PROPERTY_TYPES[
                    change.property as keyof typeof LEAD_EVENT_PROPERTY_TYPES
                  ] || formatToHuman(change.property)}
                </strong>{' '}
                {change.to} <del>{change.from}</del>
              </StyledChangeListItem>
            ))}
          </StyledList>
        )}
      </Wrapper>
    </EventItemWrapper>
  )
}

export default LeadEventItem
