import { Button, H, P } from '@farewill/ui'
import { useDispatch } from 'react-redux'

import { showModal } from 'state/actions'
import { WILL_CASE_STATUSES } from 'utils/enums'
import { useFetchResource, RESOURCES } from 'api'
import { WillCase } from 'lib/models/will-case'

import CaseStatusModal from './modal'
import { getReasonText } from './helpers'

const CaseStatus = ({ id }: { id: number }): React.ReactElement => {
  const dispatch = useDispatch()
  const willCaseQuery = useFetchResource<WillCase>({
    id,
    resource: RESOURCES.WILL_CASES,
  })

  if (willCaseQuery.isLoading || willCaseQuery.isIdle)
    return <P size="L">Loading...</P>

  if (!willCaseQuery.data) return <P size="L">There is no data to display.</P>

  const mostRecentTransition =
    willCaseQuery.data.attributes.statusTransitions[0]

  const isColdOrCancelled = [
    WILL_CASE_STATUSES.COLD,
    WILL_CASE_STATUSES.CANCELLED,
  ].includes(mostRecentTransition?.toStatus)

  const openModal = () => {
    dispatch(
      showModal({
        component: CaseStatusModal,
        headingText: 'Mark case as cancelled/cold',
        willCaseId: willCaseQuery.data.id,
      })
    )
  }

  return (
    <div data-scroll-id="case-status">
      <H size="S" margin={[0, 0, 'M']}>
        Cold/cancelled
      </H>
      <Button.Underline type="button" onClick={openModal} flush>
        {isColdOrCancelled
          ? `Case ${mostRecentTransition?.toStatus} for reason: ${getReasonText(
              mostRecentTransition
            )}`
          : 'Mark case as cold/cancelled'}
      </Button.Underline>
    </div>
  )
}

export default CaseStatus
