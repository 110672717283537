import { FAREWILL_PRODUCTS } from 'utils/enums'

export const COMPLETED_SECTIONS = 'completedSections'

export type SubSection = { label: string; scrollId: string }

export type Section = {
  label: string
  name: string
  scrollId: string
  subSections?: SubSection[]
}

export const SECTIONS_CONFIG: { [key: string]: Section[] } = {
  [FAREWILL_PRODUCTS.PROBATE]: [
    {
      label: 'People',
      name: 'people',
      scrollId: '1',
      subSections: [
        { label: 'Customers and points of contact', scrollId: '1.1' },
        { label: 'Name of the person who’s died', scrollId: '1.2' },
        { label: 'Identifying the person who’s died', scrollId: '1.3' },
        { label: 'Marital status of the person who’s died', scrollId: '1.4' },
      ],
    },
    {
      label: 'Will or intestacy',
      name: 'will_or_intestacy',
      scrollId: '2',
    },
    {
      label: 'Spouse’s estate',
      name: 'spouses_estate',
      scrollId: '3',
      subSections: [
        { label: 'About the spouse who died', scrollId: '3.1' },
        { label: 'The spouse’s estate', scrollId: '3.2' },
      ],
    },
    {
      label: 'Assets',
      name: 'assets',
      scrollId: '4',
      subSections: [
        { label: 'Property', scrollId: '4.1' },
        { label: 'Bank and building society accounts', scrollId: '4.2' },
        { label: 'Cash', scrollId: '4.3' },
        { label: 'NS&I', scrollId: '4.4' },
        { label: 'Public shareholdings', scrollId: '4.5' },
        { label: 'Investments', scrollId: '4.6' },
        { label: 'Life insurance', scrollId: '4.7' },
        { label: 'Private pensions', scrollId: '4.8' },
        { label: 'Vehicles and possessions', scrollId: '4.9' },
        { label: 'Business and agricultural assets', scrollId: '4.10' },
        { label: 'Overseas assets', scrollId: '4.11' },
        {
          label: 'Refunds, rebates, pay-outs and money owed',
          scrollId: '4.12',
        },
      ],
    },
    {
      label: 'Funeral and debts',
      name: 'funeral_and_debts',
      scrollId: '5',
      subSections: [
        { label: 'Funeral', scrollId: '5.1' },
        { label: 'Mortgages', scrollId: '5.2' },
        { label: 'Credit / store cards', scrollId: '5.3' },
        { label: 'Loans (not including mortgages)', scrollId: '5.4' },
        { label: 'Utilities and other debts', scrollId: '5.5' },
      ],
    },
    {
      label: 'Gifts and other',
      name: 'gifts_and_other',
      scrollId: '6',
      subSections: [
        { label: 'Gifts where they continued to benefit', scrollId: '6.1' },
        { label: 'Gifts over £250 in last 7 years', scrollId: '6.2' },
        { label: 'Trusts', scrollId: '6.3' },
        { label: 'Insurance premiums', scrollId: '6.4' },
        { label: 'Grants', scrollId: '6.5' },
        { label: 'Notes not covered elsewhere', scrollId: '6.6' },
      ],
    },
  ],
}
