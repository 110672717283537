const FETCH_PROBATE_CASE_EVENTS = 'FETCH_PROBATE_CASE_EVENTS'
const FETCH_FUNERAL_EVENTS = 'FETCH_FUNERAL_EVENTS'
const FETCH_WILL_CASE_EVENTS = 'FETCH_WILL_CASE_EVENTS'

const CREATE_EVENT = 'CREATE_EVENT'
const UPDATE_EVENT = 'UPDATE_EVENT'
const DELETE_EVENT = 'DELETE_EVENT'

export default function productEvents(state = [], action = {}) {
  switch (action.type) {
    case FETCH_PROBATE_CASE_EVENTS + '_FULFILLED':
    case FETCH_FUNERAL_EVENTS + '_FULFILLED':
    case FETCH_WILL_CASE_EVENTS + '_FULFILLED':
      return action.payload.data.data

    case FETCH_PROBATE_CASE_EVENTS + '_PENDING':
    case FETCH_PROBATE_CASE_EVENTS + '_REJECTED':
    case FETCH_FUNERAL_EVENTS + '_PENDING':
    case FETCH_FUNERAL_EVENTS + '_REJECTED':
    case FETCH_WILL_CASE_EVENTS + '_PENDING':
    case FETCH_WILL_CASE_EVENTS + '_REJECTED':
      return []

    case CREATE_EVENT + '_FULFILLED': {
      const createdEvent = action.payload.data.data

      return [...state, createdEvent]
    }

    case UPDATE_EVENT + '_FULFILLED': {
      const updatedEvent = action.payload.data.data

      const productEvents = state.map((event) => {
        if (updatedEvent.id !== event.id) return event
        return updatedEvent
      })

      return productEvents
    }

    case DELETE_EVENT + '_FULFILLED': {
      const id = Number(action.meta.id)
      const productEvents = state.filter((event) => event.id !== id)

      return productEvents
    }

    default:
      return state
  }
}
