import { ReactElement } from 'react-markdown/lib/react-markdown'
import styled from 'styled-components'
import { FONT } from '@farewill/ui/tokens'

import StyledGridRow from 'components/list/grid-row'
import StyledLoadingRow from 'components/list/loading-row'
import NoResults from 'components/list/no-results'
import StyledSortableHeader from 'components/list/sortable-header'
import SortArrow from 'components/sort-arrow'
import { FAREWILL_PRODUCTS, FarewillProduct } from 'utils/enums'

import { KeyboardShortcutProvider } from './keyboard-shortcut-manager'
import Row from './row'
import { Case, GridTemplateProducts, SetPage, SetSort, Sort } from './types'

export const gridTemplateColumns = {
  probate: '1fr 1fr 1fr 0.5fr 1fr 56px 64px 18px',
  lpa: '1fr 1fr 0.6fr 1fr 0.6fr 18px',
  funeral: '1fr 1fr 1fr 2fr 0.6fr 0.6fr 18px',
  // customer, type, days in stage, activity, due date, owner, icons, →
  will: '1fr 0.6fr 1fr 0.6fr 1fr 80px 64px 18px',
}

const StyledGridColumn = styled.li`
  font-size: ${FONT.SIZE.S};
`

const Cases = ({
  cases,
  isLoading,
  sort,
  setSort,
  setPage,
  product,
  updateCase,
}: {
  cases: Case[]
  isLoading: boolean
  sort: Sort
  setSort: SetSort
  setPage: SetPage
  product: FarewillProduct
  updateCase: (id: number, attributes: unknown) => Promise<void>
}): ReactElement => {
  const SortableHeader = ({
    label,
    option,
  }: {
    label: string
    option: string
  }) => {
    const options = [option, `-${option}`]
    return (
      <StyledSortableHeader
        onClick={() => {
          const nextOption =
            options[options.indexOf(sort as string) + 1] || null
          setSort(nextOption)
          setPage({})
        }}
      >
        {label}
        <SortArrow
          sorted={options.includes(sort as string)}
          asc={sort === options[1]}
        />
      </StyledSortableHeader>
    )
  }

  const DaysInStageHeader = SortableHeader({
    label: 'Days in stage',
    option: 'daysInStage',
  })

  const LastActivityHeader = SortableHeader({
    label: 'Activity',
    option: 'lastActivityAt',
  })

  const NextTaskDueHeader = SortableHeader({
    label: 'Next task due',
    option: 'nextTaskDueOn',
  })

  const FuneralHeaders = () => (
    <>
      <StyledGridColumn>Customer</StyledGridColumn>
      <StyledGridColumn>Deceased</StyledGridColumn>
      {DaysInStageHeader}
      <StyledGridColumn>Notes</StyledGridColumn>
      {SortableHeader({ label: 'Last contact', option: 'lastCallAt' })}
      <StyledGridColumn>Owner</StyledGridColumn>
    </>
  )

  const ProbateHeaders = () => (
    <>
      <StyledGridColumn>Customer</StyledGridColumn>
      <StyledGridColumn>Estate of</StyledGridColumn>
      {DaysInStageHeader}
      {LastActivityHeader}
      {NextTaskDueHeader}
      <StyledGridColumn>Owner</StyledGridColumn>
    </>
  )

  const WillHeaders = () => (
    <>
      <StyledGridColumn>Customer</StyledGridColumn>
      <StyledGridColumn>Will type</StyledGridColumn>
      {DaysInStageHeader}
      {LastActivityHeader}
      {NextTaskDueHeader}
      <StyledGridColumn>Owner</StyledGridColumn>
    </>
  )

  const LpaHeaders = () => (
    <>
      <StyledGridColumn>Customer</StyledGridColumn>
      {SortableHeader({ label: 'Stage', option: 'nextStage' })}
      {LastActivityHeader}
      {NextTaskDueHeader}
      <StyledGridColumn>Owner</StyledGridColumn>
    </>
  )

  return (
    <>
      <StyledGridRow
        header
        gridTemplateColumns={
          gridTemplateColumns[product as GridTemplateProducts]
        }
      >
        {product === FAREWILL_PRODUCTS.FUNERAL && <FuneralHeaders />}
        {product === FAREWILL_PRODUCTS.PROBATE && <ProbateHeaders />}
        {product === FAREWILL_PRODUCTS.LPA && <LpaHeaders />}
        {product === FAREWILL_PRODUCTS.WILL && <WillHeaders />}
      </StyledGridRow>

      {isLoading && <StyledLoadingRow>Loading...</StyledLoadingRow>}
      {!isLoading && !cases && <NoResults type="cases" />}

      {!isLoading && cases && (
        <KeyboardShortcutProvider enabled={false}>
          {cases.map((row) => (
            <Row
              gridTemplateColumns={
                gridTemplateColumns[product as GridTemplateProducts]
              }
              key={row.id}
              row={row}
              product={product as FarewillProduct}
              updateCase={updateCase}
            />
          ))}
        </KeyboardShortcutProvider>
      )}
    </>
  )
}

export default Cases
