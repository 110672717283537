import React from 'react'
import styled from 'styled-components'

import { P } from '@farewill/ui'
import { COLOR, FONT, GTR } from '@farewill/ui/tokens'
import lowerCase from 'lodash/lowerCase'
import upperFirst from 'lodash/upperFirst'

import { formatDate } from 'lib/formatting/dates'
import {
  findImmediateEmailTrigger,
  hasMultipleStartEmailTriggers,
} from 'components/tasks-panel/helpers'

const StyledLi = styled.li`
  font-size: ${FONT.SIZE.S};
  margin-bottom: ${GTR.XS};
  color: ${COLOR.GREY.DARK};
`

const EmailSentRecipients = ({ task }) => {
  const { emails } = task.attributes
  const recipients = emails.reduce((acc, email) => {
    const sentEmailRecipients = email.recipients.filter(
      (recipient) => !!recipient.sentAt
    )

    return [...acc, ...sentEmailRecipients]
  }, [])
  const sentAt = recipients.length ? recipients[0].sentAt : null
  const hasMultipleTriggers = hasMultipleStartEmailTriggers(task)
  const immediateTrigger = findImmediateEmailTrigger(task)

  const textEmailPending = <P size="S">An email is pending.</P>
  const textEmailSent = (
    <>
      <P size="S">
        We've sent the email '
        {upperFirst(lowerCase(immediateTrigger.template.name))}' to the
        following people on {formatDate(sentAt)}:
      </P>
      <ul>
        {recipients.map((recipient) => (
          <StyledLi key={recipient.id}>
            <strong>{recipient.emailAddress}</strong>
          </StyledLi>
        ))}
      </ul>
    </>
  )

  return (
    <>
      {sentAt ? textEmailSent : textEmailPending}
      {!hasMultipleTriggers && (
        <P size="S">Please send any further communication from Front.</P>
      )}
    </>
  )
}

export default EmailSentRecipients
