import { LeadAttributes } from 'lib/models/lead'
import { FAREWILL_PRODUCTS } from 'utils/enums'
import { getFrontLink, getZendeskUser } from 'utils/helpers'

const LinkToExternalSystem = ({ lead }: { lead: LeadAttributes }) => {
  // Only show Front link for product other than Will
  const frontUrl =
    lead.product !== FAREWILL_PRODUCTS.WILL
      ? getFrontLink(lead.contact.email)
      : null

  // Only show Zendesk link for Will product with externalZendeskId
  const zendeskUrl =
    lead.product === FAREWILL_PRODUCTS.WILL && lead.contact.externalZendeskId
      ? getZendeskUser(lead.contact.externalZendeskId)
      : null

  const FrontLink = frontUrl && (
    <a href={frontUrl} target="_blank" rel="noopener noreferrer">
      View on Front
    </a>
  )
  const ZendeskLink = zendeskUrl && (
    <a href={zendeskUrl} target="_blank" rel="noopener noreferrer">
      View on Zendesk
    </a>
  )

  return FrontLink || ZendeskLink || null
}

export default LinkToExternalSystem
