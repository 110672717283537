import { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Button, Grid, P } from '@farewill/ui'
import { GTR } from '@farewill/ui/tokens'

import store from 'state/create-store'
import { showModal } from 'state/actions'

import useItemState from 'lib/effects/item-state'
import useProbateEstateItemHelpers from 'lib/effects/probate-estate-item-helpers'

import EstateCard from 'components/estate-card'
import InputFloating from 'components/form/input-floating'
import CircularRadioGroup from 'components/form/circular-radio-group'
import Tooltip from 'components/tooltip'
import GridWithToggle from 'components/grid-with-toggle'

import DeleteModal from '../components/delete-modal'
import withForm from '../components/with-form'

import { PRIVATE_PENSIONS_SECTION_ACTIVE } from './field-names'

import { useFormikContext } from 'formik'
import namespace from 'lib/formik/namespace'
import { getPrivatePensionTotal } from '../get-totals'
import get from 'lodash/get'

import {
  DESCRIPTION,
  IS_ESTIMATED_VALUE,
  VALUE,
  IS_ESTIMATED_VALUE_OPTIONS,
  IS_LUMP_SUM_PENSION_PAYOUT,
  LUMP_SUM_PAYABLE_TO,
} from '../item-field-constants'
import {
  CHANGED_PRIVATE_PENSIONS_BEFORE_DEATH,
  PRIVATE_PENSION_CHANGE_NOTES,
  NOTES,
} from './field-names'
import NotesInput from 'components/notes-input'

const Item = ({
  item,
  probateEstateId,
  index,
  listLength,
  deleteItem,
  updateItem,
}) => {
  const { values } = useFormikContext()

  const { withNamespace, withoutNamespace } = namespace(
    `privatePensions.${item.id}.`
  )

  const handleSave = ({ name, value }) => {
    updateItem({
      id: item.id,
      attributes: { [withoutNamespace(name)]: value || null },
    })
  }

  const onTrashClick = () => {
    store.dispatch(
      showModal({
        component: DeleteModal,
        headingText: 'Delete private pension',
        config: {
          deleteCallback: () => {
            deleteItem({
              probateEstateId,
              id: item.id,
            })
          },
          type: 'private pension',
          resourceId: item.id,
        },
      })
    )
  }

  return (
    <EstateCard
      onTrashClick={onTrashClick}
      listLength={listLength}
      listPosition={index + 1}
      key={item.id}
      type="pension"
    >
      <Grid>
        <Grid.Item>
          <InputFloating
            name={withNamespace(DESCRIPTION)}
            label="Provider and description"
            handleSave={handleSave}
          />
        </Grid.Item>
        <Grid.Item span={3}>
          <InputFloating
            name={withNamespace(VALUE)}
            label="Value (£)"
            handleSave={handleSave}
            isCurrency
          />
        </Grid.Item>
        <Grid.Item
          span={9}
          style={{ alignSelf: 'center', justifySelf: 'center' }}
        >
          <CircularRadioGroup
            name={withNamespace(IS_ESTIMATED_VALUE)}
            options={IS_ESTIMATED_VALUE_OPTIONS}
            handleSave={handleSave}
            inline
          />
        </Grid.Item>
        <Grid.Item>
          <CircularRadioGroup
            name={withNamespace(IS_LUMP_SUM_PENSION_PAYOUT)}
            label="Is this a lump sum payout?"
            options={[
              { label: 'Not asked', value: '' },
              { label: 'Yes', value: 'true' },
              { label: 'No', value: 'false' },
            ]}
            handleSave={handleSave}
            inline
          />
        </Grid.Item>
        {get(values, withNamespace(IS_LUMP_SUM_PENSION_PAYOUT)) === 'true' && (
          <Grid.Item>
            <CircularRadioGroup
              name={withNamespace(LUMP_SUM_PAYABLE_TO)}
              label="Who is the lump sum payable to?"
              options={[
                { label: 'Not asked', value: '' },
                { label: 'The estate', value: 'estate' },
                {
                  label: 'Trustees’ discretion / not treated as part of estate',
                  value: 'trustees_discretion',
                },
              ]}
              handleSave={handleSave}
              inline
            />
          </Grid.Item>
        )}
        <Grid.Item>
          <NotesInput name={withNamespace(NOTES)} handleSave={handleSave} />
        </Grid.Item>
      </Grid>
    </EstateCard>
  )
}

const ItemWithForm = withForm(Item)

const PrivatePensions = ({
  initialItems,
  probateEstateId,
  saveProbateEstateField,
}) => {
  const { items, addItem, removeItem, replaceItem } = useItemState(initialItems)

  const {
    createItem,
    createdItem,
    isCreating,
    deleteItem,
    deletedItem,
    updateItem,
    updatedItem,
  } = useProbateEstateItemHelpers({ probateEstateId })

  useEffect(() => {
    if (createdItem) addItem(createdItem)
  }, [addItem, createdItem])

  useEffect(() => {
    if (deletedItem) removeItem(deletedItem)
  }, [removeItem, deletedItem])

  useEffect(() => {
    if (updatedItem) replaceItem(updatedItem)
  }, [replaceItem, updatedItem])

  const toggleDisabled = items.length > 0

  const total = getPrivatePensionTotal(items)
  return (
    <GridWithToggle
      disabled={toggleDisabled}
      name={PRIVATE_PENSIONS_SECTION_ACTIVE}
      onToggle={saveProbateEstateField}
      scrollId="4.8"
      title="Private pensions"
    >
      <Grid.Item>
        <P size="S">Total: {total}</P>

        <P size="S">
          Not including state pension. If there’s an overpayment (a debt), add
          it at section 5.5
        </P>

        {items.map((item, index) => (
          <ItemWithForm
            deleteItem={deleteItem}
            enableReinitialize={false}
            index={index}
            initialValues={{ privatePensions: { [item.id]: item.attributes } }}
            item={item}
            key={item.id}
            listLength={items.length}
            probateEstateId={probateEstateId}
            updateItem={updateItem}
          />
        ))}
      </Grid.Item>
      <Grid.Item>
        <Button.Secondary
          style={{ paddingTop: GTR.XXS, paddingBottom: GTR.XXS }}
          loading={isCreating}
          type="button"
          onClick={() => {
            createItem({ itemType: 'private_pension' })
          }}
        >
          Add pension
        </Button.Secondary>
      </Grid.Item>

      {items.length > 0 && (
        <>
          <Grid.Item>
            <CircularRadioGroup
              name={CHANGED_PRIVATE_PENSIONS_BEFORE_DEATH}
              label={
                <>
                  Did they{' '}
                  <Tooltip content="If they were in normal health for their age, this question is not applicable. And this is just about private pensions, so you can ignore the state pension.">
                    change or transfer any of the pensions above
                  </Tooltip>{' '}
                  in the 2 years before death?
                </>
              }
              options={[
                { label: 'Not asked', value: '' },
                { label: 'Yes', value: 'yes' },
                { label: 'No', value: 'no' },
                { label: 'Unsure', value: 'unsure' },
                { label: 'Not applicable', value: 'not_applicable' },
              ]}
              handleSave={saveProbateEstateField}
              inline
            />
          </Grid.Item>
          <Grid.Item>
            <InputFloating
              name={PRIVATE_PENSION_CHANGE_NOTES}
              label="Details about pension changes and transfers"
              handleSave={saveProbateEstateField}
              component="textarea"
              rows={4}
            />
          </Grid.Item>
        </>
      )}
    </GridWithToggle>
  )
}

PrivatePensions.propTypes = {
  initialItems: PropTypes.array,
  probateEstateId: PropTypes.string.isRequired,
  saveProbateEstateField: PropTypes.func.isRequired,
}

PrivatePensions.defaultProps = {
  initialItems: [],
}

export default PrivatePensions
