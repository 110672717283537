import { useEffect } from 'react'
import { Formik } from 'formik'
import { Divider, Wrapper } from '@farewill/ui'

import useApi from 'lib/effects/api'
import { QuoteResponseDataFuneralPlan } from 'lib/types'
import { isFuneralPlanLead } from 'lib/types/funeral-plans'
import { formatValuesForFormik } from 'utils/helpers'

import { useLeadContext } from '../context'
import QuoteForm from '../quote-form'

import ENV from 'config/environment'
import Brochure from './brochure'
import ContactDetails from './contact-details'
import CouplePlan from './couple-plan'
import CoveredIndividual from './covered-individual'
import NominatedRepresentative from './nominated-representative'
import PaymentQuestions from './payment-questions'
import Plan from './plan'
import QualifyingQuestions from './qualifying-questions'
import RaisingMoneyForCharity from './raising-money-for-charity'
import VulnerabilityCheck from './vulnerability-check'
import Vulnerabilities from './vulnerabilities'

type Props = {
  disabled: boolean
}

const FuneralPlanForm = ({ disabled }: Props): React.ReactElement => {
  const { onSubmit, lead } = useLeadContext()
  const [{ data: quote }, makeRequest] = useApi<QuoteResponseDataFuneralPlan>()

  useEffect(() => {
    if (!isFuneralPlanLead(lead.attributes)) {
      return
    }

    if (lead.id && lead.attributes.quoteAttributes.paymentType) {
      makeRequest({
        url: '/api/quotes',
        method: 'POST',
        data: {
          data: {
            type: 'quotes',
            attributes: {
              product: 'funeral_plan',
              leadId: lead.id,
            },
          },
        },
      })
    }
  }, [lead, lead.attributes, makeRequest])

  if (!isFuneralPlanLead(lead.attributes)) {
    return <></>
  }

  return (
    <Formik
      /** Passing the lead id as a key forces React to remount the component. This was needed
       * because when clicking 'View linked lead' from the first to the second lead, the form
       * was not resetting with the data of the 2nd lead.  */
      key={lead.id}
      initialValues={formatValuesForFormik(lead.attributes)}
      onSubmit={onSubmit}
    >
      <QuoteForm disabled={disabled}>
        <ContactDetails />
        <Divider margin={['L', 0]} />
        <Brochure />
        <Divider margin={['L', 0]} />
        <Vulnerabilities />
        <Divider margin={['L', 0]} />
        <CouplePlan />
        <Divider margin={['L', 0]} />
        <Plan />
        <Divider margin={['L', 0]} />
        <CoveredIndividual dataTestid="covered-individual" />
        <QualifyingQuestions />
        <NominatedRepresentative />
        {ENV.FF_FUNERAL_PLANS_PARTNERSHIPS_ENABLED && (
          <RaisingMoneyForCharity />
        )}
        <Divider margin={['L', 0]} />
        <Wrapper margin={['L', 0]}>
          <VulnerabilityCheck />
        </Wrapper>
        <Divider margin={['L', 0]} />
        <PaymentQuestions
          quote={quote?.attributes}
          reference={lead.attributes.funeralPlanReference || ''}
        />
      </QuoteForm>
    </Formik>
  )
}

export default FuneralPlanForm
