import styled from 'styled-components'
import { GTR, FONT } from '@farewill/ui/tokens'

const SimpleList = styled.ul<{ small?: boolean; bullets?: boolean }>`
  li:not(:last-of-type) {
    margin-bottom: ${GTR.XS};
  }
  ${({ small }) => small && `font-size: ${FONT.SIZE.S}`}

  ${({ bullets }) =>
    bullets &&
    `
    list-style: disc;
    padding-left: ${GTR.M};
  `}
`

export default SimpleList
