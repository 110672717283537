import { Button } from '@farewill/ui'
import { FONT, COLOR } from '@farewill/ui/tokens'
import { CopyToClipboardIcon } from 'components/custom-icons'
import { PropsWithChildren } from 'react'
import { toast } from 'react-toastify'
import styled from 'styled-components'

const StyledButton = styled(Button.Underline)`
  padding: 0;
  font-weight: ${FONT.WEIGHT.REGULAR};

  &:not(:disabled):focus,
  &:not(:disabled):active {
    outline: none;
    box-shadow: none;
  }

  g {
    fill: ${COLOR.GREY.MEDIUM};
  }

  &:hover {
    g {
      fill: ${COLOR.BLACK};
    }
  }
`

const StyledCopyToClipboardIcon = styled(CopyToClipboardIcon)`
  transition: transform 0.2s ease-in-out;

  &:active {
    transform: translateY(-3px);
  }
`

const UserContactDetailWithCopyToClipboard = ({
  value,
  label,
  children,
}: PropsWithChildren<{
  value: string | null
  label: string
}>) => {
  const handleCopyToClipboard = (e: MouseEvent) => {
    e.preventDefault()

    try {
      value && navigator.clipboard.writeText(value)
      toast(`${label} copied to clipboard.`, {
        toastId: `success-copy-user-details-to-clipboard-${value}`,
      })
    } catch (err) {
      toast(`${label} copied to clipboard.`, {
        toastId: `failed-copy-user-details-to-clipboard-${value}`,
      })
    }
  }

  return (
    <>
      {children}{' '}
      {value && (
        <StyledButton onClick={handleCopyToClipboard}>
          <StyledCopyToClipboardIcon
            color={COLOR.BLACK}
            height="16px"
            viewBox="0 0 21 22"
            width="15px"
          />
        </StyledButton>
      )}
    </>
  )
}

export default UserContactDetailWithCopyToClipboard
