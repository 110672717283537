import { ReactNode } from 'react'

import SimpleList from 'components/styled/simple-list'

import { Outcome, ReasonId } from './types'

export const CALENDLY_DOMAIN = 'https://calendly.com'

export const COUNTRIES = {
  SCOTLAND: 'scotland',
  ENGLAND: 'england',
  WALES: 'wales',
  NORTHERN_IRELAND: 'northern_ireland',
  OUTSIDE_UK: 'outside_uk',
} as const

export const PHONE_WILL_COMPLEXITY = {
  COMPLEX: 'complex',
  SIMPLE: 'simple',
} as const

export const TRUST_LEAD_LANDING_PATH = '/landing/wills/property-trusts'

export const FAREWILL_SERVICES = ['phone', 'online_or_phone']

export const BLANK_VALUES = {
  contact: {
    email: '',
    firstName: '',
    lastName: '',
    phoneNumber: '',
  },
  quoteAttributes: {
    willType: '',
    price: '',
    partner: {
      firstName: '',
      lastName: '',
      dateOfBirth: '',
      email: '',
      phoneNumber: '',
      addressFields: {
        lineOne: '',
        lineTwo: '',
        city: '',
        postalCode: '',
        countryCode: '',
      },
    },
    wantsToPutHouseInTrust: false,
    hasBeneficiaryInvolvedInDivorceOrBankruptcy: false,
  },
}

export const PERSONAL_AND_ASSETS = 'PERSONAL_AND_ASSETS'
export const WISHES = 'WISHES'
export const OPTIONAL = 'OPTIONAL'
export const LARGE_PRINT = 'LARGE_PRINT'

export const DIAGNOSTIC_HEADINGS = {
  [PERSONAL_AND_ASSETS]: 'About them and their assets',
  [WISHES]: 'About their wishes',
  [OPTIONAL]:
    'Optional: Have they mentioned they want to include any of the following?',
}

export const DIAGNOSTIC_SUBHEADINGS = {
  [OPTIONAL]: 'Also applies if they’re unsure and need more guidance',
}

/** OUTCOME CONSTANTS */

export const OUTCOME_TYPE = {
  CANNOT_HELP: 'cannot_help',
  INCOMPLETE: 'incomplete',
  ONLINE_OR_PHONE: 'online_or_phone',
  PHONE: 'phone',
} as const

export const OUTCOME_HEADING = {
  CANNOT_HELP: 'We can’t help this customer',
  ONLINE_OR_PHONE_SIMPLE: 'They qualify for both options',
  ONLINE_OR_PHONE_COMPLEX: 'Recommendation: Phone will',
  PHONE: 'Phone will only',
} as const

export const ONLINE_OR_PHONE_WILL = {
  complete: true,
  phoneWillComplexity: PHONE_WILL_COMPLEXITY.SIMPLE,
  type: OUTCOME_TYPE.ONLINE_OR_PHONE,
  heading: OUTCOME_HEADING.ONLINE_OR_PHONE_SIMPLE,
  message:
    'Our online service covers everything they need, but they can make their will over the phone with a specialist if they’d prefer.',
} as const

export const phoneWillRecommendedOutcome = ({
  reasons,
}: {
  reasons: ReactNode[]
}): Outcome => {
  return {
    complete: true,
    phoneWillComplexity: PHONE_WILL_COMPLEXITY.COMPLEX,
    type: OUTCOME_TYPE.ONLINE_OR_PHONE,
    heading: OUTCOME_HEADING.ONLINE_OR_PHONE_COMPLEX,
    message: (
      <SimpleList bullets={reasons.length > 1}>
        {reasons.map((reason, index) => (
          <li key={index}>{reason}</li>
        ))}
      </SimpleList>
    ),
  }
}

export const phoneWillOutcome = ({
  reasons,
}: {
  reasons: ReactNode[]
}): Outcome => {
  return {
    complete: true,
    phoneWillComplexity: PHONE_WILL_COMPLEXITY.COMPLEX,
    type: OUTCOME_TYPE.PHONE,
    heading: OUTCOME_HEADING.PHONE,
    message: (
      <SimpleList bullets={reasons.length > 1}>
        {reasons.map((reason, index) => (
          <li key={index}>{reason}</li>
        ))}
      </SimpleList>
    ),
  }
}

export const complexWillOutcome = ({
  reasons,
}: {
  reasons: ReactNode[]
}): Outcome => {
  return {
    complete: true,
    phoneWillComplexity: PHONE_WILL_COMPLEXITY.COMPLEX,
    type: OUTCOME_TYPE.PHONE,
    heading: OUTCOME_HEADING.PHONE,
    message: (
      <SimpleList bullets={reasons.length > 1}>
        {reasons.map((reason, index) => (
          <li key={index}>{reason}</li>
        ))}
      </SimpleList>
    ),
  }
}

export const REASONS: Record<ReasonId, string> = {
  NO_COMPLEXITIES:
    'Don’t have any complexities that require a ‘trust’ or additional guidance',
  PARTNER_AND_CHILDREN: 'Have a partner and children',
  CHILDREN: 'Have children',
  PARTNER: 'Have a partner',
  PROPERTY: 'Own a property',
  NO_EXTRA_GUIDANCE_NEEDED:
    'Don’t seem to need extra guidance from a specialist',
  TWO_PROPERTIES: 'Own two properties',
  THREE_OR_MORE_PROPERTIES: 'Own three or more properties',
  'CHILDREN_FROM_PREVIOUS_RELATIONSHIP:HAS_PARTNER_AND_NO_PROPERTY':
    'Have children from a previous relationship, a partner and no property',
  'CHILDREN_FROM_PREVIOUS_RELATIONSHIP:NO_PARTNER_AND_HAS_PROPERTY':
    'Have children from a previous relationship, property and no partner',
  'CHILDREN_FROM_PREVIOUS_RELATIONSHIP:HAS_PARTNER_AND_HAS_PROPERTY':
    'Have children from a previous relationship, a partner and property',
  SOLE_TRADER: 'Are a sole trader',
  SOLE_TRADER_BUSINESS_STOP_ON_DEATH:
    'Are a sole trader and are happy for the business to stop operating after they die',
  SCOTLAND: 'Live in Scotland',
  NORTHERN_IRELAND: 'Live in Northern Ireland',
  OVERSEAS_ASSETS: 'Have overseas assets',
  SEPARATED_OR_DIVORCED: 'Are separated or going through a divorce',
  BENEFICIARIES_MANAGING_INHERITANCE:
    'Are leaving something to someone who may need help managing their inheritance',
  BENEFICIARIES_ON_BENEFITS:
    'Are leaving something to someone receiving benefits',
  BENEFICIARIES_GOING_THROUGH_DIVORCE:
    'Are leaving something to someone going through a divorce',
  BANKRUPT_BENEFICIARIES:
    'Are leaving something to someone at risk of bankruptcy',
  CHALLENGE_PREVENTION:
    'May need extra support to prevent their will being challenged later on',
  BUSINESS: 'Own a business',
  // Legacy reasons that will only be associated with old leads:
  MULTIPLE_PROPERTIES: 'Have multiple properties',
  PARTNER_AND_CHILDREN_FROM_PREVIOUS_RELATIONSHIP:
    'Have a partner, and children from a previous relationship',
  NO_PARTNER_AND_CHILDREN_FROM_PREVIOUS_RELATIONSHIP:
    'Have children from a previous relationship',
}
