import { PropsWithChildren } from 'react'
import styled, { css } from 'styled-components'
import { ArrowRightIcon, Wrapper, H, Button, P } from '@farewill/ui'
import { COLOR, GTR } from '@farewill/ui/tokens'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import { FAREWILL_PRODUCTS_OPTIONS, FarewillProduct } from 'utils/enums'
import { showModal } from 'state/actions'

import StatusBadge from 'components/status-badge'
import EstateOfText from 'components/estate-of-text'
import { Lead } from 'lib/models/lead'
import { ProbateCase } from 'lib/models/probate-case'
import { Funeral } from 'lib/models/funeral'
import { WillCase } from 'lib/models/will-case'
import { LpaCase } from 'lib/models/lpa-case'
import { AccountsProducts } from 'lib/models/accounts-products'

import NewLead from '../new-lead'
import { useContactContext } from '../context'
import { getFuneralPlans } from './helpers'

const StyledLink = styled(Link)`
  text-decoration: none;

  &:hover,
  &:focus {
    text-decoration: none;
  }
`

const StyledGridRow = styled.ul<{ $header?: boolean; $isLink?: boolean }>`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 30px;
  margin-bottom: 0;
  margin-top: -1px;
  border-width: 1px 0;
  border-color: ${COLOR.GREY.LIGHT};
  border-style: solid;
  padding: ${GTR.S} ${GTR.XS};
  ${({ $isLink }) =>
    $isLink &&
    css`
      cursor: pointer;
    `}
  align-items: center;

  &:hover,
  &:focus {
    background-color: ${COLOR.WHITE};
  }

  ${({ $header }) =>
    $header &&
    css`
      border-width: 0 0 2px;
      cursor: initial;

      &:hover,
      &:focus {
        background-color: transparent;
      }
    `}
`

const StyledBadgeWrapper = styled.span`
  position: relative;
  top: -2px;
  margin-left: ${GTR.XS};
`

const findProductLabel = (product: FarewillProduct) => {
  const foundProduct = FAREWILL_PRODUCTS_OPTIONS.find(
    ({ value }) => value === product
  )
  return foundProduct?.label
}

const sortByDate = (a: Record<string, string>, b: Record<string, string>) =>
  new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()

const removeConvertedLeads = (row: Record<string, string>) =>
  row.status !== 'converted'

const ProductWrapper = ({
  to,
  children,
}: PropsWithChildren<{ to: string }>) => {
  if (to) {
    return <StyledLink to={to}>{children}</StyledLink>
  } else {
    return <Wrapper>{children}</Wrapper>
  }
}

const Products = () => {
  const { contact, createLead, isCreatingLead } = useContactContext()
  const dispatch = useDispatch()

  const leads = contact.attributes.leads.map((lead: Lead) => ({
    ...lead,
    isLead: true,
    path: `/leads/${lead.id}/history`,
  }))
  const probateCases = contact.attributes.probateCases.map(
    (probateCase: ProbateCase) => ({
      ...probateCase,
      path: `/probate/cases/${probateCase.id}`,
      product: 'probate',
    })
  )
  const funerals = contact.attributes.funerals.map((funeral: Funeral) => ({
    ...funeral,
    path: `/funerals/${funeral.id}`,
    product: 'funeral',
  }))
  const willCases = contact.attributes.willCases.map((willCase: WillCase) => ({
    ...willCase,
    path: `/will-cases/${willCase.id}`,
    product: 'will',
  }))

  const lpaCases = contact.attributes.lpaCases.map((lpaCase: LpaCase) => ({
    ...lpaCase,
    path: `/lpa-cases/${lpaCase.id}`,
    product: 'lpa',
  }))

  const funeralPlans = getFuneralPlans(
    contact.attributes.funeralPlansAsPurchaser,
    contact.attributes.funeralPlansAsCoveredIndividual
  )

  const onlineWills =
    contact.attributes?.account?.products
      .filter(
        (product: AccountsProducts) =>
          product.name === 'online_will' && product.purchasedAt !== null
      )
      .map((product: AccountsProducts) => ({
        product: 'online_will',
        status: 'Purchased',
        /**
         * These might seem counter intuitive, but because we are only
         * interested in Online Will after purchase, and the record
         * is created before purchase, the "createdAt" is actually the
         * date of purchase, and the "lastActivityAt" is the date of the
         * last update..
         */
        lastActivityAt: product.updatedAt,
        createdAt: product.purchasedAt,
      })) || []

  const leadsAndProducts = [
    ...leads,
    ...probateCases,
    ...funerals,
    ...willCases,
    ...lpaCases,
    ...funeralPlans,
    ...onlineWills,
  ]
    .sort(sortByDate)
    .filter(removeConvertedLeads)

  return (
    <Wrapper>
      <H size="S" margin={[0, 0, 'L']}>
        Products
      </H>
      <ul>
        <li>
          <StyledGridRow $header>
            <li>Product</li>
            <li>Status</li>
            <li>Date added</li>
            <li>Last activity</li>
            <li />
          </StyledGridRow>
        </li>
        <li>
          {leadsAndProducts.map((row) => (
            <ProductWrapper key={row.id} to={row.path}>
              <StyledGridRow $isLink={row.path}>
                <li>
                  {findProductLabel(row.product)}
                  {row.product === 'online_will' && <>Online Will</>}
                  {row.product === 'funeral_plan' &&
                    (row.coveredIndividualName || row.purchaserName) && (
                      <P small margin={0}>
                        Purchased{' '}
                        {row.coveredIndividualName
                          ? `for ${row.coveredIndividualName}`
                          : `by  ${row.purchaserName}`}
                      </P>
                    )}
                  {row.product === 'probate' && (
                    <EstateOfText probateCase={row} />
                  )}
                </li>
                <li>
                  {row.isLead ? 'Lead' : 'Customer'}
                  {row.status && (
                    <StyledBadgeWrapper>
                      <StatusBadge small status={row.status} />
                    </StyledBadgeWrapper>
                  )}
                </li>
                <li>{moment(row.createdAt).format('DD MMM YYYY')}</li>
                <li>
                  {moment(row.lastActivityAt).format('DD MMM YYYY, HH:mm')}
                </li>
                {row.path && (
                  <li>
                    <ArrowRightIcon size="S" />
                  </li>
                )}
              </StyledGridRow>
            </ProductWrapper>
          ))}
        </li>
      </ul>
      <Button
        onClick={() =>
          dispatch(
            showModal({
              component: NewLead,
              headingText: 'Add new product',
              config: { contact, createLead, isCreatingLead },
            })
          )
        }
      >
        + New product
      </Button>
    </Wrapper>
  )
}

export default Products
