import axios, { AxiosError, AxiosPromise, AxiosRequestConfig } from 'axios'
import { toast } from 'react-toastify'

import store from 'state/create-store'
import { removeToken } from 'state/actions'
import { ResponseError } from 'lib/types'

import ValidationToast from './validation-toast'
import { ValidationError } from './types'

type Error = string | ResponseError

const formatError = (error: Error) => {
  if (typeof error === 'string') return error
  return error.detail || JSON.stringify(error)
}

const getValidationErrors = (errors: Error[] = []) => {
  return errors.reduce((acc: ValidationError[], error) => {
    if (
      typeof error !== 'string' &&
      error.code === 'invalid_api_request' &&
      error.source?.pointer
    ) {
      const name = error.source.pointer.replace('data.attributes.', '')
      const detail = error.detail.replace(error.source.pointer, '')
      acc.push({ name, detail })
    }
    return acc
  }, [])
}

interface ApiRequestConfig extends AxiosRequestConfig {
  sendToken?: boolean
}

const apiRequest = ({
  url,
  method = 'GET',
  data = undefined,
  headers = {},
  sendToken = true,
}: ApiRequestConfig): AxiosPromise => {
  const { token } = store.getState()
  const requestHeaders = { ...headers }
  if (sendToken) requestHeaders.Authorization = `Bearer ${token}`

  return axios({ url, method, data, headers: requestHeaders }).catch(
    (error: AxiosError) => {
      const defaultErrors = ['Something went wrong. Please try refreshing.']
      const errors = error.response?.data?.errors || defaultErrors
      const validationErrors = getValidationErrors(errors)

      if (validationErrors.length) {
        toast(<ValidationToast errors={validationErrors} />, {
          toastId: `api-request-validation-error-${url}`,
          autoClose: false,
        })
      } else {
        toast(errors.map(formatError).join(', '), {
          toastId: `api-request-no-validation-error-${url}`,
        })
      }

      const status = error.response?.status
      if (status === 401) store.dispatch(removeToken())

      throw error
    }
  )
}

export default apiRequest
