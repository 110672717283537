import sumBy from 'lodash/sumBy'
import groupBy from 'lodash/groupBy'

import { formatCurrency } from 'lib/formatting/currency'
import { ProbateEstateItem } from 'lib/models/probate-estate'

import {
  FUNERAL_BILL_AMOUNT,
  FUNERAL_WAKE_AMOUNT,
  FUNERAL_FLOWERS_AMOUNT,
  FUNERAL_OTHER_AMOUNT,
  FUNERAL_AMOUNT_CONVERED_BY_PLAN,
  FUNERAL_HAD_PRE_PAID_PLAN,
} from './funeral-and-debts/field-names'

import {
  IS_LUMP_SUM_PENSION_PAYOUT,
  LUMP_SUM_PAYABLE_TO,
} from './item-field-constants'

import { TRUST_ASSETS_AMOUNT } from './gifts-and-other/field-names'
import { ProbateLeadAttributes } from 'lib/models/lead'
import { removeCommas } from 'utils/helpers'

const getFloat = (value: string) => {
  if (!value) return 0
  const valueWithoutCommas = removeCommas(value)
  return valueWithoutCommas ? parseFloat(valueWithoutCommas) || 0 : 0
}
const getSum = (values: string[]) => sumBy(values, getFloat)

export const getFuneralTotal = (
  values: ProbateLeadAttributes['quoteAttributes']
) => {
  const hasPrePaidPlan = values[FUNERAL_HAD_PRE_PAID_PLAN] === 'yes'

  const costs = getSum([
    values[FUNERAL_BILL_AMOUNT] || '',
    values[FUNERAL_WAKE_AMOUNT] || '',
    values[FUNERAL_FLOWERS_AMOUNT] || '',
    values[FUNERAL_OTHER_AMOUNT] || '',
  ])
  const total =
    costs -
    (hasPrePaidPlan && values[FUNERAL_AMOUNT_CONVERED_BY_PLAN]
      ? getFloat(values[FUNERAL_AMOUNT_CONVERED_BY_PLAN])
      : 0)
  return formatCurrency({ value: total })
}

export const getTrustsTotal = (values: Record<string, string>) => {
  const total = getFloat(values[TRUST_ASSETS_AMOUNT])
  return formatCurrency({ value: total })
}

export const getItemsTotals = (values: ProbateEstateItem[]) => {
  const groupedItems = groupBy(values, 'attributes.ownership')

  const sole = getSum(
    groupedItems.sole?.map?.((item) => (item.attributes.value || 0).toString())
  )
  const joint = getSum(
    groupedItems.joint?.map?.((item) => {
      const value = item.attributes.value || 0
      const deceasedShare = item.attributes.deceasedShareOfJointOwnership || 0
      return ((value * deceasedShare) / 100).toString()
    })
  )
  const unspecified = getSum(
    groupedItems.null?.map?.((item) => (item.attributes.value || 0)?.toString())
  )

  return {
    total: formatCurrency({ value: sole + joint + unspecified }),
    sole: formatCurrency({ value: sole }),
    joint: formatCurrency({ value: joint }),
    unspecified: formatCurrency({ value: unspecified }),
  }
}

export const getPrivatePensionTotal = (values: ProbateEstateItem[]) => {
  const estateItems = values.filter((item) => {
    const isLumpSumToNominated =
      item.attributes[IS_LUMP_SUM_PENSION_PAYOUT] === true &&
      item.attributes[LUMP_SUM_PAYABLE_TO] === 'trustees_discretion'

    return !isLumpSumToNominated
  })

  const total = getSum(
    estateItems.map((item) => (item.attributes.value || 0).toString())
  )

  return formatCurrency({ value: total })
}

export const getLifeInsuranceTotals = (values: ProbateEstateItem[]) => {
  const estateItems = values.filter((item) => {
    return item.attributes[LUMP_SUM_PAYABLE_TO] !== 'not_part_of_estate'
  })

  return getItemsTotals(estateItems)
}
