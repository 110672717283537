/** Config to send to the BE in order to create the WEEKLY_PLEDGE_EMAIL partner_account_subscriptions row */
export const WEEKLY_PLEDGE_EMAIL_CONFIG = {
  subscription: 'WEEKLY_PLEDGE_EMAIL',
}

/** Config to send to the BE in order to create the PARTNER_DATA_SHARING_REPORTS partner_account_access row with READ scope */
export const PARTNER_DATA_SHARING_REPORTS_READ_CONFIG = {
  access: 'PARTNER_DATA_SHARING_REPORTS',
  scope: 'READ',
}

/** Fields in both AddAccountModal and UpdateAccountModal */
export const PARTNER_ACCOUNT_PREFERENCES_FIELDS = {
  WEEKLY_PLEDGE_EMAIL: {
    label: 'Do they want to receive weekly pledge emails?',
    name: 'weeklyPledgeEmail',
  },
  PARTNER_DATA_SHARING_REPORTS: {
    label: 'Do they need access to monthly partner data sharing reports?',
    name: 'partnerDataSharingReports',
  },
}
