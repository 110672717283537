import PropTypes from 'prop-types'
import { Button, Grid, Wrapper, P } from '@farewill/ui'
import { GTR } from '@farewill/ui/tokens'
import filter from 'lodash/filter'
import { useFormikContext } from 'formik'

import { NOT_ASKED_OPTIONS } from 'utils/enums'

import Tooltip from 'components/tooltip'
import CircularRadioGroup from 'components/form/circular-radio-group'
import AdoptedRelative from './adopted-relative'
import { useRelativesContext } from '../relatives/context'
import { IS_ADOPTED, HAS_ADOPTED_RELATIVES } from './field-names'

const AdoptedRelatives = ({ saveProbateEstateField }) => {
  const { values } = useFormikContext()
  const { relatives, createRelative, isCreatingRelative } =
    useRelativesContext()

  const filteredRelatives = filter(relatives, 'attributes.isAdopted')
  const showAdoptedRelativesCards = values[HAS_ADOPTED_RELATIVES] === 'true'

  return (
    <Grid>
      <Grid.Item>
        <CircularRadioGroup
          name={HAS_ADOPTED_RELATIVES}
          label={
            <>
              Were any of the{' '}
              <Tooltip content="Relatives are considered those who stand to inherit the estate and/or anyone that has been cleared off in intestacy (excluding the spouse).">
                relatives
              </Tooltip>{' '}
              of the person who died adopted in or out of the family?
            </>
          }
          options={NOT_ASKED_OPTIONS}
          handleSave={saveProbateEstateField}
          inline
        />
      </Grid.Item>
      {showAdoptedRelativesCards && (
        <Grid.Item>
          <P>
            If you've added their details above, please add them again here.
          </P>
          <Wrapper margin={filteredRelatives.length ? [0, 0, 'M'] : 0}>
            {filteredRelatives.map((relative, index) => (
              <AdoptedRelative
                relative={relative}
                key={relative.id}
                initialValues={{
                  adoptedRelative: { [relative.id]: relative.attributes },
                }}
                listLength={filteredRelatives.length}
                listPosition={index + 1}
              />
            ))}
          </Wrapper>
          <Button.Secondary
            style={{ paddingTop: GTR.XXS, paddingBottom: GTR.XXS }}
            loading={isCreatingRelative}
            type="button"
            onClick={() => createRelative({ [IS_ADOPTED]: true })}
          >
            Add adopted relative
          </Button.Secondary>
        </Grid.Item>
      )}
    </Grid>
  )
}

AdoptedRelatives.propTypes = {
  saveProbateEstateField: PropTypes.func.isRequired,
}

export default AdoptedRelatives
