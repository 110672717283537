import { useContext, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useFormikContext } from 'formik'
import { Grid, P } from '@farewill/ui'
import styled from 'styled-components'
import get from 'lodash/get'
import { deceasedShareValidation } from 'lib/formik/validation'

import namespace from 'lib/formik/namespace'
import useApi from 'lib/effects/api'

import InputFloating from 'components/form/input-floating'
import CircularRadioGroup from 'components/form/circular-radio-group'
import Tooltip from 'components/tooltip'
import NotesInput from 'components/notes-input'
import Address from '../../components/address'
import withForm from '../../components/with-form'
import { Context } from '../../../context'

import {
  TENURE,
  VALUE,
  IS_ESTIMATED_VALUE,
  REGISTRY_CHECK_STATUS,
  OWNERSHIP,
  INTENTIONS,
  JOINT_OWNERSHIP_TYPE,
  JOINT_OWNER_NAME,
  DECEASED_SHARE_OF_JOINT_OWNERSHIP,
  JOINT_OWNER_RELATIONSHIP,
  NOTES,
  ADDRESS,
} from '../field-names'

const StyledRadioWrapper = styled(Grid.Item)`
  display: flex;
  align-items: center;
`
const TAX_PURPOSES_TOOLTIP =
  'The value of their share for tax purposes is the value of their share minus 10%, if held with someone other than their spouse.'

const Property = ({ probateEstateId, propertyId, replaceProperty }) => {
  const { values } = useFormikContext()
  const [{ data: updatedProperty, isLoading, errors }, makeRequest] = useApi()

  useEffect(() => {
    if (updatedProperty) replaceProperty(updatedProperty)
  }, [updatedProperty, replaceProperty])

  const { setErrors, setIsLoading } = useContext(Context)

  useEffect(() => {
    setErrors(errors)
  }, [setErrors, errors])

  useEffect(() => {
    setIsLoading(isLoading)
  }, [setIsLoading, isLoading])

  const prefix = `properties.${propertyId}.`
  const { withNamespace, withoutNamespace } = namespace(prefix)

  const ownershipIsJoint = get(values, withNamespace(OWNERSHIP)) === 'joint'

  const deceasedShareValue = get(values, withNamespace(VALUE))

  const deceasedSharePercentage = get(
    values,
    withNamespace(DECEASED_SHARE_OF_JOINT_OWNERSHIP)
  )

  const displayedValue =
    deceasedShareValue && deceasedShareValue * (deceasedSharePercentage / 100)

  const relationshipIsOther =
    get(values, withNamespace(JOINT_OWNER_RELATIONSHIP)) === 'other'

  const displayedValueForTaxPurposes = relationshipIsOther
    ? displayedValue * 0.9
    : displayedValue

  const handleSave = ({ name, value }) => {
    makeRequest({
      url: `/api/probate-estates/${probateEstateId}/properties/${propertyId}`,
      method: 'PATCH',
      data: {
        data: {
          type: 'probate_estate_properties',
          id: propertyId,
          attributes: { [withoutNamespace(name)]: value || null },
        },
      },
    })
  }

  return (
    <Grid>
      <Grid.Item>
        <CircularRadioGroup
          name={withNamespace(TENURE)}
          options={[
            { label: 'Freehold', value: 'freehold' },
            { label: 'Leasehold', value: 'leasehold' },
          ]}
          handleSave={handleSave}
          inline
        />
      </Grid.Item>
      <Grid.Item span={9}>
        <Address
          name={withNamespace(ADDRESS)}
          data={get(values, withNamespace(ADDRESS))}
        />
      </Grid.Item>
      <Grid.Item span={4}>
        <InputFloating
          name={withNamespace(VALUE)}
          label="Value (£)"
          handleSave={handleSave}
          isCurrency
        />
      </Grid.Item>
      <StyledRadioWrapper span={6}>
        <CircularRadioGroup
          name={withNamespace(IS_ESTIMATED_VALUE)}
          options={[
            { label: 'Estimate', value: 'true' },
            { label: 'Professional valuation', value: 'false' },
          ]}
          handleSave={handleSave}
          inline
        />
      </StyledRadioWrapper>
      <Grid.Item>
        <CircularRadioGroup
          name={withNamespace(REGISTRY_CHECK_STATUS)}
          label="Have we checked the register entry?"
          options={[
            { label: 'Not yet', value: '' },
            { label: 'Yes', value: 'yes' },
            { label: 'No entry - land is unregistered', value: 'no_entry' },
          ]}
          handleSave={handleSave}
          inline
        />
      </Grid.Item>
      <Grid.Item>
        <CircularRadioGroup
          name={withNamespace(OWNERSHIP)}
          label="Ownership"
          options={[
            { label: 'Sole', value: 'sole' },
            { label: 'Joint', value: 'joint' },
          ]}
          handleSave={handleSave}
          inline
        />
      </Grid.Item>
      {ownershipIsJoint && (
        <>
          <Grid.Item>
            <CircularRadioGroup
              name={withNamespace(JOINT_OWNERSHIP_TYPE)}
              label="Type of joint ownership"
              options={[
                { label: 'Not asked', value: '' },
                { label: 'Joint tenants', value: 'joint_tenants' },
                { label: 'Tenants in common', value: 'tenants_in_common' },
                { label: 'Unsure (leave note)', value: 'unsure' },
              ]}
              handleSave={handleSave}
              inline
            />
          </Grid.Item>
          <Grid.Item span={6}>
            <InputFloating
              name={withNamespace(JOINT_OWNER_NAME)}
              label="Name of joint owner"
              handleSave={handleSave}
              hint="If more than one, leave a note below"
            />
          </Grid.Item>
          <Grid.Item span={6}>
            <InputFloating
              name={withNamespace(DECEASED_SHARE_OF_JOINT_OWNERSHIP)}
              label="Share owned by person who’s died (%)"
              handleSave={handleSave}
              validate={deceasedShareValidation}
            />
          </Grid.Item>
          <Grid.Item>
            <CircularRadioGroup
              name={withNamespace(JOINT_OWNER_RELATIONSHIP)}
              label="Relationship of joint owner"
              options={[
                { label: 'Not asked', value: '' },
                { label: 'Spouse', value: 'spouse' },
                { label: 'Other (not spouse)', value: 'other' },
              ]}
              handleSave={handleSave}
              inline
            />
          </Grid.Item>
          <Grid.Item>
            <P>
              Value of share of person who’s died: £
              {isNaN(displayedValue) ? 0 : displayedValue}
            </P>
          </Grid.Item>
          <Grid.Item>
            <span>
              Value of share of person who’s died{' '}
              <Tooltip content={TAX_PURPOSES_TOOLTIP}>for tax purposes</Tooltip>
              : £{isNaN(displayedValue) ? 0 : displayedValueForTaxPurposes}
            </span>
          </Grid.Item>
        </>
      )}
      <Grid.Item>
        <InputFloating
          name={withNamespace(INTENTIONS)}
          label="Intentions for this property"
          handleSave={handleSave}
        />
      </Grid.Item>
      <Grid.Item>
        <NotesInput name={withNamespace(NOTES)} handleSave={handleSave} />
      </Grid.Item>
    </Grid>
  )
}

Property.propTypes = {
  probateEstateId: PropTypes.string.isRequired,
  propertyId: PropTypes.number.isRequired,
  replaceProperty: PropTypes.func.isRequired,
}

export default withForm(Property)
