import { Link } from 'react-router-dom'
import { COLOR } from '@farewill/ui/tokens'
import styled from 'styled-components'

const StyledLink = styled(Link)`
  display: block;
  text-decoration: none;

  &:hover,
  &:focus {
    text-decoration: none;
    background-color: ${COLOR.WHITE};
    outline: none;
  }
`
export default StyledLink
