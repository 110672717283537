// Regex used in type="email" from WHATWG
// https://html.spec.whatwg.org/multipage/input.html#e-mail-state-(type=email)
const EMAIL_REGEX =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/

// Regex that fulfils rules set out by gov.uk
// https://www.gov.uk/hmrc-internal-manuals/national-insurance-manual/nim39110
const NATIONAL_INSURANCE_REGEX =
  /^[A-CEGHJ-PR-TW-Z]{1}[A-CEGHJ-NPR-TW-Z]{1}[0-9]{6}[A-D]{1}$/

export const email = (
  value: string | null | undefined
): null | string | undefined => {
  if (!value) return null
  const isValid = EMAIL_REGEX.test(value)
  if (!isValid) return 'Email is invalid.'
}

export const nationalInsurance = (value: string): null | string | undefined => {
  const valueWithoutSpaces = value.replace(/ /g, '')
  if (!valueWithoutSpaces) return null
  const isValid = NATIONAL_INSURANCE_REGEX.test(valueWithoutSpaces)
  if (!isValid) return 'National insurance number is invalid.'
}

export const deceasedShareValidation = (
  value: string
): null | string | undefined => {
  const valueWithoutSpaces = value.replace(/ /g, '')
  if (!valueWithoutSpaces) return null
  const isValid =
    Number(valueWithoutSpaces) <= 100 && Number(valueWithoutSpaces) >= 0
  if (!isValid)
    return 'Please enter a number in the range 0-100 without any spaces or special characters.'
}
