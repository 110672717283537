import { useEffect } from 'react'
import PropTypes from 'prop-types'

import { Button, Grid, P, Wrapper } from '@farewill/ui'
import { GTR } from '@farewill/ui/tokens'

import store from 'state/create-store'
import { showModal } from 'state/actions'

import useApi from 'lib/effects/api'
import useItemState from 'lib/effects/item-state'
import useProbateEstateItemHelpers from 'lib/effects/probate-estate-item-helpers'
import { getGiftTaxYears } from 'lib/probate/helpers'

import EstateCard from 'components/estate-card'
import InputFloating from 'components/form/input-floating'
import CircularRadioGroup from 'components/form/circular-radio-group'
import DateSplit from 'components/form/date-split'
import GridWithToggle from 'components/grid-with-toggle'

import DeleteModal from '../components/delete-modal'
import withForm from '../components/with-form'
import SummaryBox from '../components/summary-box'

import namespace from 'lib/formik/namespace'
import { getItemsTotals } from '../get-totals'

import {
  DESCRIPTION,
  IS_ESTIMATED_VALUE,
  VALUE,
  GIFT_RECIPIENT,
  GIFT,
} from '../item-field-constants'
import { GIFTS_SECTION_ACTIVE } from './field-names'
import NotesInput from 'components/notes-input'
import { NOTES } from '../assets/field-names'
import { formatCurrency } from 'lib/formatting/currency'

const Item = ({
  item,
  probateEstateId,
  index,
  listLength,
  deleteItem,
  updateItem,
}) => {
  const { withNamespace, withoutNamespace } = namespace(
    `giftsGiven.${item.id}.`
  )

  const handleSave = ({ name, value }) => {
    updateItem({
      id: item.id,
      attributes: { [withoutNamespace(name)]: value || null },
    })
  }

  const onTrashClick = () => {
    store.dispatch(
      showModal({
        component: DeleteModal,
        headingText: 'Delete gift',
        config: {
          deleteCallback: () => {
            deleteItem({
              probateEstateId,
              id: item.id,
            })
          },
          type: 'gift',
          resourceId: item.id,
        },
      })
    )
  }

  return (
    <EstateCard
      onTrashClick={onTrashClick}
      listLength={listLength}
      listPosition={index + 1}
      key={item.id}
      type="gift"
    >
      <Grid>
        <Grid.Item>
          <InputFloating
            name={withNamespace(DESCRIPTION)}
            label="Description"
            handleSave={handleSave}
          />
        </Grid.Item>
        <Grid.Item>
          <InputFloating
            name={withNamespace(GIFT_RECIPIENT)}
            label="Recipient and their relationship to person who’s died"
            handleSave={handleSave}
          />
        </Grid.Item>
        <Grid.Item span={3}>
          <InputFloating
            name={withNamespace(VALUE)}
            label="Value (£)"
            handleSave={handleSave}
            isCurrency
          />
        </Grid.Item>
        <Grid.Item
          span={9}
          style={{ alignSelf: 'center', justifySelf: 'center' }}
        >
          <CircularRadioGroup
            name={withNamespace(IS_ESTIMATED_VALUE)}
            options={[
              { label: 'Estimate', value: 'true' },
              {
                label: 'Confirmed value (add detail in notes)',
                value: 'false',
              },
            ]}
            handleSave={handleSave}
            inline
          />
        </Grid.Item>
        <Grid.Item>
          <DateSplit
            name={withNamespace(GIFT)}
            label="Date of gift"
            handleSave={handleSave}
            hint="If day and month unknown, enter year"
          />
        </Grid.Item>
        <Grid.Item>
          <NotesInput name={withNamespace(NOTES)} handleSave={handleSave} />
        </Grid.Item>
      </Grid>
    </EstateCard>
  )
}

const ItemWithForm = withForm(Item)

const getSummaryItems = (quoteAttributes) => {
  if (!quoteAttributes) return []

  const {
    giftsGivenOver3kThreshold,
    giftsYearOne,
    giftsYearOneValue,
    giftsYearTwo,
    giftsYearTwoValue,
    giftsYearThree,
    giftsYearThreeValue,
    giftsYearFour,
    giftsYearFourValue,
    giftsYearFive,
    giftsYearFiveValue,
    giftsYearSix,
    giftsYearSixValue,
    giftsYearSeven,
    giftsYearSevenValue,
    giftsYearEight,
    giftsYearEightValue,
  } = quoteAttributes

  if (!giftsGivenOver3kThreshold) return []

  const giftsYearsArray = [
    { giftsGiven: giftsYearOne, giftsValue: giftsYearOneValue },
    { giftsGiven: giftsYearTwo, giftsValue: giftsYearTwoValue },
    { giftsGiven: giftsYearThree, giftsValue: giftsYearThreeValue },
    { giftsGiven: giftsYearFour, giftsValue: giftsYearFourValue },
    { giftsGiven: giftsYearFive, giftsValue: giftsYearFiveValue },
    { giftsGiven: giftsYearSix, giftsValue: giftsYearSixValue },
    { giftsGiven: giftsYearSeven, giftsValue: giftsYearSevenValue },
    { giftsGiven: giftsYearEight, giftsValue: giftsYearEightValue },
  ]

  const giftLabels = getGiftTaxYears(quoteAttributes.dateOfDeath)

  return giftsYearsArray.reduce((acc, curr, i) => {
    if (!curr.giftsGiven) return acc
    return [
      ...acc,
      {
        label: giftLabels[i],
        value: formatCurrency({ value: curr.giftsValue, showPence: false }),
      },
    ]
  }, [])
}

const GiftsGiven = ({
  initialItems,
  probateEstateId,
  saveProbateEstateField,
  leadId,
}) => {
  const { items, addItem, removeItem, replaceItem } = useItemState(initialItems)

  const {
    createItem,
    createdItem,
    isCreating,
    deleteItem,
    deletedItem,
    updateItem,
    updatedItem,
  } = useProbateEstateItemHelpers({ probateEstateId })

  useEffect(() => {
    if (createdItem) addItem(createdItem)
  }, [addItem, createdItem])

  useEffect(() => {
    if (deletedItem) removeItem(deletedItem)
  }, [removeItem, deletedItem])

  useEffect(() => {
    if (updatedItem) replaceItem(updatedItem)
  }, [replaceItem, updatedItem])

  const { total } = getItemsTotals(items)

  const [{ data: lead }, fetchLead] = useApi()

  useEffect(() => {
    fetchLead({ url: `/api/leads/${leadId}` })
  }, [fetchLead, leadId])

  const summaryItems = getSummaryItems(lead?.attributes.quoteAttributes)

  return (
    <GridWithToggle
      disabled={items.length > 0}
      name={GIFTS_SECTION_ACTIVE}
      onToggle={saveProbateEstateField}
      scrollId="6.2"
      title="Gifts over £250 in last 7 years"
    >
      <Grid.Item>
        <P size="S">Total: {total}</P>
        {!!summaryItems.length && (
          <Wrapper margin={['L', 0]}>
            <SummaryBox
              items={summaryItems}
              title="Estimates given (total per tax year)"
            />
          </Wrapper>
        )}
        <P>Gifts worth over £250 in the 7 years before they died</P>

        {items.map((item, index) => (
          <ItemWithForm
            deleteItem={deleteItem}
            enableReinitialize={false}
            index={index}
            initialValues={{ giftsGiven: { [item.id]: item.attributes } }}
            item={item}
            key={item.id}
            listLength={items.length}
            probateEstateId={probateEstateId}
            updateItem={updateItem}
          />
        ))}
      </Grid.Item>
      <Grid.Item>
        <Button.Secondary
          style={{ paddingTop: GTR.XXS, paddingBottom: GTR.XXS }}
          loading={isCreating}
          type="button"
          onClick={() => {
            createItem({ itemType: 'gift_given' })
          }}
        >
          Add gift
        </Button.Secondary>
      </Grid.Item>
    </GridWithToggle>
  )
}

GiftsGiven.propTypes = {
  initialItems: PropTypes.array,
  probateEstateId: PropTypes.string.isRequired,
  saveProbateEstateField: PropTypes.func.isRequired,
}

GiftsGiven.defaultProps = {
  initialItems: [],
}

export default GiftsGiven
