import { useContext, useState, useEffect } from 'react'
import useApi from './api'
import apiRequest from 'lib/axios/api-request'

import { Context } from 'routes/probate-estate/context'
import {
  ProbateEstateItem,
  ProbateEstateItemType,
  ProbateEstateItemsAttributes,
} from 'lib/models/probate-estate'

const useProbateEstateItemHelpers = ({
  probateEstateId,
  itemType,
}: {
  probateEstateId: number
  itemType: ProbateEstateItemType
}) => {
  const [createState, createItem] =
    useState<Partial<ProbateEstateItemsAttributes>>()

  const [{ data: createdItem, isLoading: isCreating }, makeCreateRequest] =
    useApi()

  useEffect(() => {
    if (createState) {
      makeCreateRequest({
        url: `/api/probate-estates/${probateEstateId}/items`,
        method: 'POST',
        data: {
          data: {
            type: 'probate_estate_items',
            attributes: { itemType },
          },
        },
      })

      createItem(undefined)
    }
  }, [createState, makeCreateRequest, probateEstateId, itemType])

  const [deleteState, deleteItem] = useState<Partial<ProbateEstateItem>>()
  const [deletedItem, setDeletedItem] = useState<Partial<ProbateEstateItem>>()
  const [isDeleting, setIsDeleting] = useState(false)

  useEffect(() => {
    if (deleteState) {
      setIsDeleting(true)
      apiRequest({
        url: `/api/probate-estates/${probateEstateId}/items/${deleteState.id}`,
        method: 'DELETE',
      })
        .then(() => {
          setDeletedItem(deleteState)
        })
        .finally(() => {
          setIsDeleting(false)
          deleteItem(undefined)
        })
    }
  }, [deleteState, probateEstateId])

  const [
    { data: updatedItem, isLoading: isUpdating, errors: updateErrors },
    makeUpdateRequest,
  ] = useApi<ProbateEstateItem>()

  const [updateState, updateItem] = useState<Partial<ProbateEstateItem>>()

  useEffect(() => {
    if (updateState) {
      makeUpdateRequest({
        url: `/api/probate-estates/${probateEstateId}/items/${updateState.id}`,
        method: 'PATCH',
        data: {
          data: {
            type: 'probate_estate_items',
            id: updateState.id,
            attributes: { ...updateState.attributes, itemType },
          },
        },
      })

      updateItem(undefined)
    }
  }, [updateState, probateEstateId, makeUpdateRequest, itemType])

  const { setErrors, setIsLoading } = useContext(Context)

  useEffect(() => {
    setErrors?.(updateErrors)
  }, [setErrors, updateErrors])

  useEffect(() => {
    setIsLoading?.(isUpdating)
  }, [setIsLoading, isUpdating])

  return {
    createItem,
    createdItem,
    isCreating,
    deleteItem,
    deletedItem,
    isDeleting,
    updateItem,
    updatedItem,
    isUpdating,
  }
}

export default useProbateEstateItemHelpers
