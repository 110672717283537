import { useFormikContext } from 'formik'
import { useDispatch } from 'react-redux'

import { Grid, P, Wrapper, Button } from '@farewill/ui'
import { showModal, hideModal } from 'state/actions'
import {
  FUNERAL_PROVIDERS,
  DIGNITY_FEATURE_FLAG_CONFIG,
  DIGNITY,
} from 'utils/enums'
import SelectInput from 'components/form/select-input'
import useFeatureFlag from 'hooks/useFeatureFlag'
import { Selected } from 'components/form/select-input/types'

const WarningModal = ({
  isFormsSentForSignature,
  handleSave,
  resetForm,
}: {
  isFormsSentForSignature: boolean
  handleSave: () => void
  resetForm: () => void
}) => {
  const dispatch = useDispatch()

  const handleChangeProvider = () => {
    handleSave()
    dispatch(hideModal())
  }

  const handleCancel = () => {
    resetForm()
    dispatch(hideModal())
  }

  return (
    <Wrapper>
      <Grid>
        <Grid.Item>
          <P>Are you sure you want to change provider?</P>
          {isFormsSentForSignature ? (
            <P>
              Documents have already been sent for signature. You’ll need to
              send new documents to the customer, update the case record in
              Airtable and prepare a new provider form.
            </P>
          ) : (
            <P>
              Fact-find has been completed, you’ll need to update the case
              record in Airtable and prepare a new provider form.
            </P>
          )}
        </Grid.Item>
        <Grid.Item>
          <Button.Primary type="button" onClick={handleChangeProvider} stretch>
            Change provider
          </Button.Primary>
        </Grid.Item>
        <Grid.Item>
          <Button onClick={handleCancel} stretch>
            Cancel
          </Button>
        </Grid.Item>
      </Grid>
    </Wrapper>
  )
}

const Provider = ({
  dispatch,
  handleSave,
  isFactFindCompleted,
  isFormsSentForSignature,
  isDignitySelected,
}: {
  dispatch: ({ type, payload }: { type: string; payload: string }) => void
  handleSave: ({ name, value }: { name: string; value: Selected }) => void
  isFactFindCompleted: boolean
  isFormsSentForSignature: boolean
  isDignitySelected: boolean
}) => {
  const { resetForm } = useFormikContext()

  const isDignityEnabled =
    useFeatureFlag(DIGNITY_FEATURE_FLAG_CONFIG) === 'false' ? false : true

  const funeralProviderOptions =
    isDignityEnabled || isDignitySelected
      ? FUNERAL_PROVIDERS
      : FUNERAL_PROVIDERS.filter((provider) => provider.value !== DIGNITY)

  const saveProvider = (field: { name: string; value: Selected }) => {
    if (isFactFindCompleted || isFormsSentForSignature) {
      dispatch(
        showModal({
          component: WarningModal,
          handleSave: () => handleSave(field),
          resetForm: () => resetForm(),
          headingText: 'Change provider',
          isFormsSentForSignature,
        })
      )
    } else {
      handleSave(field)
    }
  }

  return (
    <Grid.Item span={6}>
      <SelectInput
        label="Provider"
        name="provider"
        options={funeralProviderOptions}
        handleSave={saveProvider}
        isOptionDisabled={(provider: { retired?: boolean }) =>
          !!provider.retired
        }
      />
    </Grid.Item>
  )
}

export default Provider
