import { P } from '@farewill/ui'

import CircularRadioGroup from 'components/form/circular-radio-group'

export const FILTER_OPTIONS = [
  { label: 'Any', value: '' },
  { label: 'I’ve been asked for help', value: 'me' },
  { label: 'All tasks needing help', value: 'any' },
]

const HelpNeededFilter = () => (
  <>
    <P size="S" strong>
      Tasks needing help
    </P>

    <CircularRadioGroup name="helpNeededFrom" options={FILTER_OPTIONS} />
  </>
)

export default HelpNeededFilter
