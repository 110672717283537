import { Link } from 'react-router-dom'
import { Wrapper } from '@farewill/ui'
import UserContactDetailWithCopyToClipboard from 'components/user-contact-detail-with-copy-to-clipboard'
import { Contact } from 'lib/models/contact'

const TelephoneNumberLink = ({
  contact,
  withLink,
  showPhoneAsDoNotContact,
}: {
  contact: Contact | { attributes: Contact }
  withLink?: boolean
  showPhoneAsDoNotContact?: boolean
}) => {
  const contactData = 'attributes' in contact ? contact.attributes : contact
  const contactId = 'id' in contact && contact.id

  const phoneNumber = contactData.phoneNumber

  if (phoneNumber) {
    return (
      <>
        <UserContactDetailWithCopyToClipboard
          label="Phone number"
          value={phoneNumber}
        >
          {showPhoneAsDoNotContact ? (
            <>
              <Wrapper>
                <del>{phoneNumber}</del>
              </Wrapper>
            </>
          ) : (
            <a href={`tel:${phoneNumber}`} rel="noopener noreferrer">
              {phoneNumber}
            </a>
          )}
        </UserContactDetailWithCopyToClipboard>
      </>
    )
  }

  return contactId && withLink ? (
    <Link to={`/contacts/${contactId}/edit`}>Enter telephone number</Link>
  ) : (
    <span>No phone number</span>
  )
}

export default TelephoneNumberLink
