import PropTypes from 'prop-types'
import { Grid, H } from '@farewill/ui'
import { useFormikContext } from 'formik'

import { strToBool } from 'utils/helpers'

import NotesInput from 'components/notes-input'
import CircularRadioGroup from 'components/form/circular-radio-group'

import { NOT_CHECKED_OPTIONS } from 'utils/enums'

import withForm from '../fact-find/components/with-form'
import { HAS_WILL } from '../fact-find/will-or-intestacy/field-names'
import NoWillMessage from './no-will-message'

import {
  EXECUTOR_APPOINTMENT_VOID_FOR_UNCERTAINTY,
  EXECUTOR_IS_PREDECEASED_OR_RENOUNCED_OR_UNABLE_TO_ACT,
  EXECUTOR_WANTS_TO_APPOINT_POWER_OF_ATTORNEY,
  EXECUTOR_IS_SOLE_AND_MINOR,
  EXEUCUTOR_IS_PROFESSIONAL,
  PROFESSIONAL_EXECUTOR_NO_LONGER_EXISTS,
  PROFESSIONAL_EXECUTOR_WILL_RENOUNCE,
  EXECUTOR_NOTES,
} from './field-names'

const Executors = ({ probateEstateId, saveProbateEstateField }) => {
  const { values } = useFormikContext()
  const hasWill = strToBool(values[HAS_WILL])
  const executorIsProfessional = strToBool(values[EXEUCUTOR_IS_PROFESSIONAL])

  return (
    <Grid gap="XL">
      <Grid.Item span={8}>
        <H size="S" margin={[0, 0, 'M']}>
          4. Executors
        </H>
        {hasWill && (
          <>
            <Grid gap={['L', 'M']} margin={[0, 0, 'L']}>
              <Grid.Item span={6}>
                <CircularRadioGroup
                  name={EXECUTOR_APPOINTMENT_VOID_FOR_UNCERTAINTY}
                  label="Appointments of execs void for uncertainty?"
                  options={NOT_CHECKED_OPTIONS}
                  handleSave={saveProbateEstateField}
                  inline
                />
              </Grid.Item>
              <Grid.Item span={6}>
                <CircularRadioGroup
                  name={EXECUTOR_IS_PREDECEASED_OR_RENOUNCED_OR_UNABLE_TO_ACT}
                  label="Predeceased / renounced / unable to act?"
                  options={NOT_CHECKED_OPTIONS}
                  handleSave={saveProbateEstateField}
                  inline
                />
              </Grid.Item>
              <Grid.Item span={6}>
                <CircularRadioGroup
                  name={EXECUTOR_WANTS_TO_APPOINT_POWER_OF_ATTORNEY}
                  label="Does exec want to appoint an attorney?"
                  options={NOT_CHECKED_OPTIONS}
                  handleSave={saveProbateEstateField}
                  inline
                />
              </Grid.Item>
              <Grid.Item span={6}>
                <CircularRadioGroup
                  name={EXECUTOR_IS_SOLE_AND_MINOR}
                  label="Is the sole exec a minor?"
                  options={NOT_CHECKED_OPTIONS}
                  handleSave={saveProbateEstateField}
                  inline
                />
              </Grid.Item>
              <Grid.Item span={6}>
                <CircularRadioGroup
                  name={EXEUCUTOR_IS_PROFESSIONAL}
                  label="Is exec a professional / firm / trust corp?"
                  options={NOT_CHECKED_OPTIONS}
                  handleSave={saveProbateEstateField}
                  inline
                />
              </Grid.Item>
            </Grid>
            {executorIsProfessional && (
              <>
                <H size="XS">Professional executor</H>
                <Grid margin={[0, 0, 'L']}>
                  <Grid.Item span={6}>
                    <CircularRadioGroup
                      name={PROFESSIONAL_EXECUTOR_NO_LONGER_EXISTS}
                      label="Do they no longer exist / no successor?"
                      options={NOT_CHECKED_OPTIONS}
                      handleSave={saveProbateEstateField}
                      inline
                    />
                  </Grid.Item>
                  <Grid.Item span={6}>
                    <CircularRadioGroup
                      name={PROFESSIONAL_EXECUTOR_WILL_RENOUNCE}
                      label="Will they renounce?"
                      options={NOT_CHECKED_OPTIONS}
                      handleSave={saveProbateEstateField}
                      inline
                    />
                  </Grid.Item>
                </Grid>
              </>
            )}
            <NotesInput
              name={EXECUTOR_NOTES}
              handleSave={saveProbateEstateField}
            />
          </>
        )}
        {!hasWill && <NoWillMessage probateEstateId={probateEstateId} />}
      </Grid.Item>
    </Grid>
  )
}

Executors.propTypes = {
  probateEstateId: PropTypes.number.isRequired,
  saveProbateEstateField: PropTypes.func.isRequired,
}

export default withForm(Executors)
