import styled from 'styled-components'
import { P } from '@farewill/ui'

import { scrollToDataId } from 'lib/window/scroll'

const NextLink = styled.span`
  text-decoration: underline;
  cursor: pointer;
`

export interface SkipProps {
  children: React.ReactNode
  next: string
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const Skip = ({ children, next }: SkipProps) => (
  <P onClick={() => scrollToDataId(next)}>
    ↓ <NextLink>{children}</NextLink>
  </P>
)

export default Skip
