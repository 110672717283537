import { ReactElement } from 'react'
import styled from 'styled-components'
import { getWidthOfContainerColumns } from '@farewill/ui/helpers/layout'
import { Grid, P, Wrapper } from '@farewill/ui'
import { COLOR, GTR } from '@farewill/ui/tokens'
import { smallCaps } from '@farewill/ui/helpers/text'

import ENV from 'config/environment'
import { RESOURCES, useFetchResource } from 'api'
import { LpaCase, LpaCaseAttributes } from 'lib/models/lpa-case'
import { WILLSUITE_STATUS } from 'lib/models/willsuite-event'
import { LpaAttributes } from 'lib/models/lpa'
import { formatWillSuiteStatus } from 'utils/helpers'
import MessageBox from 'components/message-box'
import StatusBadge from 'components/status-badge'

import { sortLeadAndPartnerLpas } from './helpers'

const StyledNames = styled(P)`
  ${smallCaps}
`

const StyledLpaRow = styled(Wrapper)`
  display: grid;
  grid-template-columns: 1fr max-content;
  align-items: center;
  justify-content: space-between;
  gap: ${GTR.M};
`

type LpaSummary = {
  name: string
  type: LpaAttributes['type']
  status: string
}

type LpaNamesAndStatuses = {
  leadLpas: LpaSummary[]
  partnerLpas?: LpaSummary[]
}

const getLpaNamesAndStatuses = (
  lpaCase: LpaCaseAttributes
): LpaNamesAndStatuses => {
  const { leadLpas, partnerLpas } = sortLeadAndPartnerLpas(lpaCase)

  let result: LpaNamesAndStatuses = {
    leadLpas: leadLpas.map((lpa) => ({
      name: lpa.contact?.firstName as string,
      type: lpa.type,
      status: formatWillSuiteStatus(
        lpa.willSuiteEvents[0]?.status || WILLSUITE_STATUS.AWAITING_FACT_FIND
      ),
    })),
  }

  if (partnerLpas) {
    result = {
      ...result,
      partnerLpas: partnerLpas.map((lpa) => ({
        name: lpa.contact?.firstName as string,
        type: lpa.type,
        status: formatWillSuiteStatus(
          lpa.willSuiteEvents[0]?.status || WILLSUITE_STATUS.AWAITING_FACT_FIND
        ),
      })),
    }
  }

  return result
}

type Props = {
  lpaCaseId: number
}

const LpasRow = ({ lpa }: { lpa: LpaSummary }) => (
  <StyledLpaRow key={lpa.name} margin={['XS', 0]}>
    <P size="S" margin={0}>
      {lpa.type === 'health' ? 'Health and welfare' : 'Property and finance'}
    </P>
    <StatusBadge status={lpa.status} />
  </StyledLpaRow>
)

const WillSuiteStatusBanner = ({ lpaCaseId }: Props): ReactElement | null => {
  const lpaCaseQuery = useFetchResource<LpaCase>({
    id: lpaCaseId,
    resource: RESOURCES.LPA_CASES,
  })

  const lpaCase = lpaCaseQuery.data?.attributes as LpaCaseAttributes
  const { willSuiteUuid } = lpaCase

  /** If there is no WillSuite UUID, do not show the banner at all. */
  if (!willSuiteUuid) return null

  const { leadLpas, partnerLpas } = getLpaNamesAndStatuses(lpaCase)

  /**
   * 1. LPA case can have 1 or 2 LPAs attached (health and/or property).
   * 2. For a WillSuite case to be closed, all LPAs attached to the LPA case need their latest WillSuiteEvent to have status 'complete'.
   */
  const willSuiteCaseClosed = [...leadLpas]
    .concat(partnerLpas || [])
    .every((lpas) => {
      return lpas.status === WILLSUITE_STATUS.COMPLETE
    })

  return (
    <Wrapper maxWidth={getWidthOfContainerColumns(8, 'L')} margin={[0, 0, 'M']}>
      <MessageBox warning hideIcon>
        <P size="S" color={COLOR.BLACK} margin={0}>
          The details of this case are in WillSuite, and cannot be edited here.
        </P>
        <P
          tag="a"
          href={`${ENV.WILLSUITE_PLATFORM_URL}/${willSuiteUuid}`}
          target="_blank"
          rel="noopener noreferrer"
          size="S"
          strong
        >
          View or update this case in WillSuite
        </P>
        <Grid margin={['L', 0]}>
          <Grid.Item spanFromM={6}>
            <StyledNames>{leadLpas[0].name}'s LPAs</StyledNames>
            {leadLpas.map((lpa) => (
              <LpasRow lpa={lpa} key={`${lpa.name}-${lpa.type}`} />
            ))}
          </Grid.Item>

          {partnerLpas && partnerLpas.length > 0 && (
            <Grid.Item spanFromM={6}>
              <StyledNames>{partnerLpas[0].name}'s LPAs</StyledNames>
              {partnerLpas.map((lpa) => (
                <LpasRow lpa={lpa} key={`${lpa.name}-${lpa.type}`} />
              ))}
            </Grid.Item>
          )}
        </Grid>
        {willSuiteCaseClosed && (
          <P size="S">
            This case is marked as closed in WillSuite. You can still change
            this LPA by clicking on the WillSuite link above, and updating the
            case’s status.
          </P>
        )}
      </MessageBox>
    </Wrapper>
  )
}

export default WillSuiteStatusBanner
