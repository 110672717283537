import styled from 'styled-components'
import PropTypes from 'prop-types'
import { useFormikContext } from 'formik'
import { H, Grid, P, Wrapper } from '@farewill/ui'

import InputFloating from 'components/form/input-floating'
import CircularRadioGroup from 'components/form/circular-radio-group'
import NotesInput from 'components/notes-input'
import DateSplit from 'components/form/date-split'
import Checkbox from 'components/form/checkbox'
import Tooltip from 'components/tooltip'

import { strToBool } from 'utils/helpers'
import {
  MARITAL_STATUS_OPTIONS,
  NOT_ASKED_OPTIONS,
  SEPARATION_DECREE_LABELS,
  SEPARATION_DATE_LABELS,
} from 'utils/enums'

import { getEstateVisibleFields } from '../form-helpers'
import {
  MARITAL_STATUS,
  SPOUSE_FIRST_NAMES,
  SPOUSE_LAST_NAME,
  MARRIAGE,
  SPOUSE_DEATH,
  SPOUSE_IS_PERMANENT_UK_RESIDENT,
  MARRIAGE_CERTIFICATE_REQUESTED,
  DECREE_ABSOLUTE_REQUESTED,
  DECREE_ISSUED_BY,
  SEPARATION,
  MARITAL_STATUS_NOTES,
} from '../field-names'

const MARITAL_DOCS_TOOLTIP =
  'Occasionally, we may need to ask for documents relating to the marriage. If intestacy and divorced, we should ask for the decree absolute (a copy should do). If we’re claiming a spouse exemption, and in some other circumstances, we may want to ask for the marriage certificate.'

const StyledCheckboxWrapper = styled(Wrapper)`
  display: flex;
`

const MaritalStatusOfPersonWhosDied = ({ saveProbateEstateField }) => {
  const { values } = useFormikContext()
  const {
    showSpouseName,
    showMarriageDate,
    showSpouseDateOfDeath,
    showSpouseIsPermanentUkResident,
    showMaritalDocumentsRequested,
    showMarriageCertificateRequested,
    showDivorceAbsoluteRequested,
    showDecreeIssuedByAndDateOfSeperation,
  } = getEstateVisibleFields({ values })

  return (
    <Grid data-scroll-id="1.4" style={{ gridAutoFlow: 'row' }}>
      <Grid.Item>
        <H size="S">1.4 Marital status of the person who’s died</H>
        <P>Any reference to marriage or spouse includes civil partnerships.</P>
        <P>
          This is the last marital status, for example the last marriage or the
          last divorce.
        </P>
      </Grid.Item>
      <Grid.Item span={6}>
        <InputFloating
          name={MARITAL_STATUS}
          label="Marital status at death"
          handleSave={saveProbateEstateField}
          options={MARITAL_STATUS_OPTIONS}
          component="select"
        />
      </Grid.Item>
      {showSpouseName && (
        <>
          <Grid.Item span={6} startColumn={1}>
            <InputFloating
              name={SPOUSE_FIRST_NAMES}
              label="First and middle name of spouse"
              handleSave={saveProbateEstateField}
            />
          </Grid.Item>
          <Grid.Item span={6}>
            <InputFloating
              name={SPOUSE_LAST_NAME}
              label="Last name of spouse"
              handleSave={saveProbateEstateField}
            />
          </Grid.Item>
        </>
      )}
      {showMarriageDate && (
        <Grid.Item span={6} startColumn={1}>
          <DateSplit
            name={MARRIAGE}
            label="Date of marriage"
            handleSave={saveProbateEstateField}
            hint="To check if will is affected by marriage"
          />
        </Grid.Item>
      )}
      {showDecreeIssuedByAndDateOfSeperation && (
        <Grid.Item span={6} startColumn={7}>
          <InputFloating
            name={DECREE_ISSUED_BY}
            label={SEPARATION_DECREE_LABELS[values[MARITAL_STATUS]]}
            handleSave={saveProbateEstateField}
          />
        </Grid.Item>
      )}
      {showDecreeIssuedByAndDateOfSeperation && (
        <Grid.Item span={6} startColumn={1}>
          <DateSplit
            name={SEPARATION}
            label={SEPARATION_DATE_LABELS[values[MARITAL_STATUS]]}
            handleSave={saveProbateEstateField}
          />
        </Grid.Item>
      )}
      {showSpouseDateOfDeath && (
        <Grid.Item span={6} startColumn={1}>
          <DateSplit
            name={SPOUSE_DEATH}
            label="Date of death of first spouse"
            handleSave={saveProbateEstateField}
          />
        </Grid.Item>
      )}
      {showSpouseIsPermanentUkResident && (
        <Grid.Item>
          <CircularRadioGroup
            name={SPOUSE_IS_PERMANENT_UK_RESIDENT}
            label="Is the spouse permanently resident in the UK?"
            options={NOT_ASKED_OPTIONS}
            handleSave={saveProbateEstateField}
            inline
          />
        </Grid.Item>
      )}
      {showMaritalDocumentsRequested && (
        <Grid.Item>
          <Wrapper margin={[0, 0, 'S']}>
            <Tooltip content={MARITAL_DOCS_TOOLTIP}>
              Marital documents requested
            </Tooltip>
          </Wrapper>
          <StyledCheckboxWrapper>
            {showMarriageCertificateRequested && (
              <Wrapper margin={[0, 'L', 0, 0]}>
                <Checkbox
                  name={MARRIAGE_CERTIFICATE_REQUESTED}
                  label="Marriage certificate"
                  checked={strToBool(values[MARRIAGE_CERTIFICATE_REQUESTED])}
                  handleSave={saveProbateEstateField}
                />
              </Wrapper>
            )}
            {showDivorceAbsoluteRequested && (
              <Checkbox
                name={DECREE_ABSOLUTE_REQUESTED}
                label="Decree absolute"
                checked={strToBool(values[DECREE_ABSOLUTE_REQUESTED])}
                handleSave={saveProbateEstateField}
              />
            )}
          </StyledCheckboxWrapper>
        </Grid.Item>
      )}
      <Grid.Item>
        <NotesInput
          name={MARITAL_STATUS_NOTES}
          handleSave={saveProbateEstateField}
        />
      </Grid.Item>
    </Grid>
  )
}

MaritalStatusOfPersonWhosDied.propTypes = {
  saveProbateEstateField: PropTypes.func.isRequired,
}

export default MaritalStatusOfPersonWhosDied
