import styled from 'styled-components'
import { COLOR, FONT } from '@farewill/ui/tokens'

const Badge = styled.span<{
  $state?: 'success' | 'info' | 'error' | 'primary'
}>`
  padding: 4px 10px;
  color: ${({ $state }) =>
    !$state || $state === 'success'
      ? COLOR.STATE.SUCCESS
      : $state === 'info'
      ? COLOR.GREY.DARK
      : $state === 'primary'
      ? COLOR.BLACK
      : COLOR.STATE.ERROR};
  background: ${({ $state }) =>
    !$state || $state === 'success'
      ? COLOR.STATE.SUCCESS_10
      : $state === 'info'
      ? COLOR.GREY.LIGHT
      : $state === 'primary'
      ? COLOR.ACCENT.PRIMARY
      : COLOR.STATE.ERROR_10};
  border-radius: 13px;
  font-size: ${FONT.SIZE.XXS};
  font-weight: ${FONT.WEIGHT.BOLD};
  text-transform: uppercase;
  font-family: ${FONT.FAMILY.BODY};
`

export default Badge
