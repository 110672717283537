import { Grid, P } from '@farewill/ui'

import { Operation } from 'routes/partners/interfaces'
import InputFloating from 'components/form/input-floating'
import ExternalLink from 'components/external-link'
import CircularRadioGroup from 'components/form/circular-radio-group'

const OrganisationKeyDetails = ({
  operation,
}: {
  operation: Operation
}): React.ReactElement => {
  return (
    <>
      <P>
        Double-check the details match with those in the{' '}
        <ExternalLink href="https://find-and-update.company-information.service.gov.uk/">
          Companies House register
        </ExternalLink>
        .
      </P>
      <Grid gap="S">
        <Grid.Item>
          <InputFloating name="organisation.name" label="Name" />
        </Grid.Item>
        <Grid.Item>
          <InputFloating
            name="organisation.number"
            label="Company number (optional)"
          />
        </Grid.Item>
        <Grid.Item>
          <InputFloating
            disabled={operation === 'update'}
            name="organisation.address"
            label="Registered address"
          />
        </Grid.Item>
        <Grid.Item>
          <InputFloating
            disabled={operation === 'update'}
            name="utmSource"
            label="UTM source"
            hint={
              <>
                This value must exactly match the name of the partner in this{' '}
                <ExternalLink href="https://docs.google.com/spreadsheets/d/1xrFZOQ5yjrNGoSDb6chCC6Enyn-abWPM9ZJrlbc5Qec/edit#gid=1280059208">
                  Google Sheet
                </ExternalLink>
                .
              </>
            }
          />
        </Grid.Item>
        <Grid.Item>
          <CircularRadioGroup
            name="organisation.hasExemptCharitableStatus"
            label="Does the organisation have charitable status for all UK tax purposes?"
            options={[
              { label: 'No', value: 'false' },
              { label: 'Yes', value: 'true' },
            ]}
            inline
          />
        </Grid.Item>
      </Grid>
    </>
  )
}

export default OrganisationKeyDetails
