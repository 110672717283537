import { BORDER, COLOR, GTR, FONT, BORDER_RADIUS } from '@farewill/ui/tokens'

const getBorderColor = ({ isFocused, hasHighlight, hasError }) => {
  if (hasError) return COLOR.STATE.ERROR
  if (hasHighlight) return COLOR.STATE.WARNING
  if (isFocused) return COLOR.GREY.MEDIUM
  return COLOR.GREY.LIGHT
}

const getOptionBackgroundColor = (state) => {
  if (state.isSelected) return COLOR.ACCENT.PRIMARY
  if (state.isFocused) return COLOR.STATE.HIGHLIGHT
  return COLOR.WHITE
}

const FLOATING_PADDING_TOP = parseInt(GTR.S)

export const getStyles = ({
  floating,
  hasError,
  hasHighlight,
  small,
  isMulti,
}) => ({
  input: (provided) => ({
    ...provided,
    margin: 0,
    paddingTop: floating ? `${FLOATING_PADDING_TOP + 1}px` : '4px',
    paddingBottom: floating ? 0 : '4px',
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: 2,
  }),
  menuList: (provided) => ({
    ...provided,
    padding: 0,
    borderRadius: BORDER.RADIUS.S,
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: getOptionBackgroundColor(state),
    color: COLOR.GREY.DARK,
    cursor: state.isDisabled ? 'not-allowed' : 'pointer',
    '&:hover': {
      backgroundColor: state.isSelected
        ? COLOR.ACCENT.PRIMARY
        : COLOR.BACKGROUND.FOG,
    },
    textDecoration: state.isDisabled ? 'line-through' : 'auto',
    display: 'flex',
    flexDirection: 'row',
    gap: GTR.XS,
    justifyContent: 'space-between',
    alignItems: 'center',
  }),
  control: (provided, { isFocused }) => ({
    ...provided,
    cursor: 'pointer',
    borderStyle: 'solid',
    borderWidth: '2px',
    borderColor: getBorderColor({ isFocused, hasHighlight, hasError }),
    boxShadow: 'none',
    minHeight: small ? '44px' : '63px',
    '&:hover': {
      borderColor: getBorderColor({ isFocused, hasHighlight, hasError }),
    },
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    backgroundColor: COLOR.GREY.LIGHT,
    width: '2px',
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    color: state.isFocused ? COLOR.BLACK : COLOR.GREY.DARK,
    '&:hover': {
      color: COLOR.BLACK,
    },
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    padding: small && state.hasValue && isMulti ? GTR.XXS : `${GTR.XXS} 14px`,
    overflow: 'visible',
    display: 'flex',
    flexDirection: 'row',
    gap: GTR.XS,
    justifyContent: 'space-between',
    alignItems: 'center',
  }),
  multiValue: (provided) => ({
    ...provided,
    backgroundColor: COLOR.BACKGROUND.FOG,
    border: `1px solid ${COLOR.GREY.LIGHT}`,
    borderRadius: BORDER_RADIUS.M,
    color: COLOR.BLACK,
  }),
  multiValueLabel: (provided, state) => {
    const styles = { ...provided }
    if (state.data?.label?.length > 25) {
      styles.whiteSpace = 'normal'
    }
    return styles
  },
  multiValueRemove: (provided) => ({
    ...provided,
    '&:hover': {
      backgroundColor: COLOR.GREY.LIGHT,
      color: COLOR.BLACK,
    },
    '> svg': {
      fill: COLOR.BLACK,
    },
  }),
  singleValue: (provided) => ({
    ...provided,
    margin: '0',
    paddingTop: floating ? FLOATING_PADDING_TOP : '0',
    color: COLOR.GREY.DARK,
  }),
  placeholder: (provided) => ({
    ...provided,
    fontSize: small ? FONT.SIZE.S : FONT.SIZE.M,
  }),
})
