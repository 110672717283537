import { Wrapper, Divider } from '@farewill/ui'

import RequestStatus from 'components/request-status'
import withForm from 'routes/probate-estate/fact-find/components/with-form'
import Customer from './customer'
import PersonWhoHasDied from './person-who-has-died'
import CircumstancesOfDeath from './circumstances-of-death'
import DoctorsAndMedical from './doctors-and-medical'
import Funeral from './funeral'
import IsProbateNeeded from './is-probate-needed'
import SigningTheForms from './signing-the-forms'
import InternalNotes from './internal-notes'
import { SaveProp } from './types'
import { FuneralAttributes } from 'lib/models/funeral'

const FactFind = ({
  fetchFuneral,
  funeralId,
  saveContactField,
  saveFuneralField,
  saveFuneralFields,
}: {
  fetchFuneral: () => void
  funeralId: number
  saveContactField: SaveProp
  saveFuneralField: SaveProp
  saveFuneralFields: (attributes: FuneralAttributes) => void
}): React.ReactElement => {
  return (
    <Wrapper>
      <RequestStatus />
      <Wrapper>
        <Customer
          saveContactField={saveContactField}
          saveFuneralField={saveFuneralField}
        />
      </Wrapper>
      <Divider separator />
      <Wrapper separator>
        <PersonWhoHasDied handleSave={saveFuneralField} />
      </Wrapper>
      <Divider separator />
      <Wrapper separator>
        <CircumstancesOfDeath handleSave={saveFuneralField} />
      </Wrapper>
      <Divider separator />
      <Wrapper separator>
        <DoctorsAndMedical
          fetchFuneral={fetchFuneral}
          handleSave={saveFuneralField}
        />
      </Wrapper>
      <Divider separator />
      <Wrapper separator>
        <Funeral
          handleSave={saveFuneralField}
          saveFuneralFields={saveFuneralFields}
        />
      </Wrapper>
      <Divider separator />
      <Wrapper separator>
        <IsProbateNeeded funeralId={funeralId} handleSave={saveFuneralField} />
      </Wrapper>
      <Divider separator />
      <Wrapper separator>
        <SigningTheForms handleSave={saveFuneralField} />
      </Wrapper>
      <Divider separator />
      <Wrapper separator>
        <InternalNotes handleSave={saveFuneralField} />
      </Wrapper>
    </Wrapper>
  )
}

export default withForm(FactFind)
