import { useEffect, useReducer } from 'react'
import PropTypes from 'prop-types'
import { Button, H, P, Wrapper } from '@farewill/ui'
import { GTR } from '@farewill/ui/tokens'
import { useFormikContext } from 'formik'

import store from 'state/create-store'
import { showModal } from 'state/actions'

import { strToBool } from 'utils/helpers'
import useApi from 'lib/effects/api'
import apiRequest from 'lib/axios/api-request'

import Checkbox from 'components/form/checkbox'
import EstateCard from 'components/estate-card'

import DeleteModal from '../../components/delete-modal'
import Person from './person'

import { ALL_EXECUTORS_ADDED } from '../field-names'

const peopleReducer = (state, action) => {
  switch (action.type) {
    case 'SET_PEOPLE':
      return { ...state, people: action.payload }
    case 'ADD_PERSON':
      return { ...state, people: [...state.people, action.payload] }
    case 'REMOVE_PERSON': {
      const people = state.people.filter(({ id }) => id !== action.payload)
      return { ...state, people }
    }
    default:
      throw new Error()
  }
}

const CustomersAndPointsOfContact = ({
  probateEstateId,
  saveProbateEstateField,
}) => {
  const [state, dispatch] = useReducer(peopleReducer, {})
  const { values } = useFormikContext()

  const [{ data: existingPeople, isLoading: isGetLoading }, makeGetRequest] =
    useApi({ data: [], isLoading: true })

  useEffect(() => {
    const url = `/api/probate-estates/${probateEstateId}/people`
    makeGetRequest({ url })
  }, [makeGetRequest, probateEstateId])

  useEffect(
    () => dispatch({ type: 'SET_PEOPLE', payload: existingPeople }),
    [dispatch, existingPeople]
  )

  const [{ data: newPerson, isLoading: isCreateLoading }, makeCreateRequest] =
    useApi()

  useEffect(() => {
    if (newPerson) dispatch({ type: 'ADD_PERSON', payload: newPerson })
  }, [dispatch, newPerson])

  const deletePerson = (personId) => {
    const url = `/api/probate-estates/${probateEstateId}/people/${personId}`
    return apiRequest({ url, method: 'DELETE' }).then(() => {
      dispatch({ type: 'REMOVE_PERSON', payload: personId })
    })
  }

  const handleTrashClick = (personId) => {
    store.dispatch(
      showModal({
        component: DeleteModal,
        headingText: 'Delete person',
        config: {
          deleteCallback: deletePerson,
          type: 'person',
          resourceId: personId,
        },
      })
    )
  }

  if (isGetLoading) {
    return (
      <P size="L">
        Loading <strong>people</strong>...
      </P>
    )
  }

  return (
    <Wrapper data-scroll-id="1.1">
      <H size="S" margin={[0, 0, 'L']}>
        1.1 Customers and points of contact
      </H>
      <Wrapper margin={[0, 0, 'M']}>
        {state.people.map((person, index) => (
          <EstateCard
            onTrashClick={() => handleTrashClick(person.id)}
            listLength={state.people.length}
            listPosition={index + 1}
            key={person.id}
            type="person"
          >
            <Person
              hasWill={values.hasWill}
              initialValues={{ people: { [person.id]: person.attributes } }}
              personId={person.id}
              probateEstateId={probateEstateId}
            />
          </EstateCard>
        ))}
      </Wrapper>
      <Wrapper margin={[0, 0, 'M']}>
        <Button.Secondary
          style={{ paddingTop: GTR.XXS, paddingBottom: GTR.XXS }}
          disabled={isCreateLoading}
          type="button"
          onClick={() => {
            makeCreateRequest({
              url: `/api/probate-estates/${probateEstateId}/people`,
              method: 'POST',
              data: {
                data: { type: 'probate_estate_people', attributes: {} },
              },
            })
          }}
        >
          Add person
        </Button.Secondary>
      </Wrapper>
      <Checkbox
        name={ALL_EXECUTORS_ADDED}
        label="Yes, I’ve added every executor named in the will"
        handleSave={saveProbateEstateField}
        checked={strToBool(values[ALL_EXECUTORS_ADDED])}
      />
    </Wrapper>
  )
}

CustomersAndPointsOfContact.propTypes = {
  probateEstateId: PropTypes.string.isRequired,
  saveProbateEstateField: PropTypes.func.isRequired,
}

export default CustomersAndPointsOfContact
