import { H, P, Fieldset, Wrapper } from '@farewill/ui'
import { GTR } from '@farewill/ui/tokens'
import { FieldArray, useFormikContext } from 'formik'
import styled from 'styled-components'

import {
  PARTNER_PRODUCTS,
  CONFIGURATION,
  DATA_SHARING_REPORT_TYPES_OPTIONS,
  ReportType,
} from 'lib/models/partner'

import Checkbox from 'components/form/checkbox'
import { CircularRadioGroup } from 'components/form'

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${GTR.S};
`

const productOptions = [
  {
    name: PARTNER_PRODUCTS.ONLINE_WILL,
    label: 'Online wills',
  },
  {
    name: PARTNER_PRODUCTS.TELEPHONE_WILL,
    label: 'Telephone wills',
  },
]

const onlineWillOptions = [
  {
    name: CONFIGURATION.A11Y_CHECKS,
    label: 'Add accessibility checks',
  },
  {
    name: CONFIGURATION.MAILCHIMP_BESPOKE,
    label: 'Add bespoke Mailchimp journey',
  },
]

const ProductSetup = (): React.ReactElement => {
  const { values, setFieldValue } = useFormikContext<{
    type: 'charity' | 'organisation'
    products: PARTNER_PRODUCTS[]
    onlineWillConfiguration: CONFIGURATION[]
    dataSharingReportType: ReportType
    partnerDataSharing: 'enabled' | 'disabled'
  }>()

  const { products, onlineWillConfiguration, partnerDataSharing } = values

  return (
    <>
      <H size="S">Product setup</H>
      <H size="XS">Legitimate interest data sharing</H>
      <CircularRadioGroup
        name="partnerDataSharing"
        label="Does this partner want a monthly report of customers who used their
        code?"
        options={[
          { label: 'Yes', value: 'enabled' },
          { label: 'No', value: 'disabled' },
        ]}
        handleSave={({ value }) => {
          if (value === 'disabled') {
            setFieldValue('dataSharingReportType', null)
          }
        }}
        inline
      />
      {partnerDataSharing === 'enabled' && (
        <Wrapper margin={['M', 0, 'M']}>
          <H size="XS">What customers do they want to include?</H>
          <CircularRadioGroup
            name="dataSharingReportType"
            options={DATA_SHARING_REPORT_TYPES_OPTIONS}
            inline
          />
        </Wrapper>
      )}
      <Fieldset
        legend="Which product journeys do you want to set up for this partner?"
        horizontal
      >
        <FieldArray
          name="products"
          render={(arrayHelpers) =>
            productOptions.map(({ name, label }) => (
              <Checkbox
                checked={products.includes(name)}
                key={name}
                name={name}
                label={label}
                onChange={(e) => {
                  if (e.target.checked) {
                    arrayHelpers.push(name)
                  } else {
                    const index = products.indexOf(name)
                    arrayHelpers.remove(index)
                  }
                }}
              />
            ))
          }
        />
      </Fieldset>
      {products.includes(PARTNER_PRODUCTS.ONLINE_WILL) && (
        <>
          <P>Select any additional online journey options</P>
          <StyledWrapper>
            <FieldArray
              name="onlineWillConfiguration"
              render={(arrayHelpers) =>
                onlineWillOptions.map(({ name, label }) => (
                  <Checkbox
                    checked={onlineWillConfiguration.includes(name)}
                    key={name}
                    name={name}
                    label={label}
                    onChange={(e) => {
                      if (e.target.checked) {
                        arrayHelpers.push(name)
                      } else {
                        const index = onlineWillConfiguration.indexOf(name)
                        arrayHelpers.remove(index)
                      }
                    }}
                  />
                ))
              }
            />
          </StyledWrapper>
        </>
      )}
    </>
  )
}

export default ProductSetup
