import styled, { css, StyledComponent } from 'styled-components'
import { GTR } from '@farewill/ui/tokens'

type StyledTable = StyledComponent<'table', object, object, never> & {
  Body: StyledComponent<'tbody', object, object, never>
  Row: StyledComponent<'tr', object, object, never>
  Cell: StyledComponent<'td', object, { nowrap?: boolean }, never>
}

const Table = styled.table`
  width: 100%;
` as StyledTable

Table.Body = styled.tbody``

Table.Row = styled.tr``

Table.Cell = styled.td<{ nowrap?: boolean }>`
  padding: ${GTR.XXS} ${GTR.XS};

  ${({ nowrap }) =>
    nowrap &&
    css`
      white-space: nowrap;
    `}
`

export default Table
