import React from 'react'
import styled from 'styled-components'
import { Button, DeleteIcon, Grid, H, P } from '@farewill/ui'
import { QuoteAttributes } from 'components/funeral-quote-form-fields'
import { ArrayHelpers, FieldArray, useFormikContext } from 'formik'

const IconGridItem = styled(Grid.Item)`
  display: flex;
`

type RenderRowProps = {
  name: string
  handleSubmit: () => void
}

type Props = {
  header: string
  description: string
  name: 'additionalCharges'
  renderRow: (props: RenderRowProps) => React.ReactElement
  newRowLabel: string
}

const FormArraySection = ({
  header,
  description,
  name,
  renderRow,
  newRowLabel,
}: Props): React.ReactElement => {
  const { values, handleSubmit } = useFormikContext<{
    quoteAttributes: QuoteAttributes
  }>()

  return (
    <>
      <H>{header}</H>
      <P>{description}</P>
      <FieldArray
        name={`quoteAttributes.${name}`}
        render={(arrayHelpers: ArrayHelpers) => (
          <Grid>
            {values.quoteAttributes[name]?.map((_, index) => (
              <Grid.Item key={index}>
                <Grid>
                  {renderRow({
                    name: `quoteAttributes.${name}.${index}`,
                    handleSubmit,
                  })}
                  <IconGridItem spanFromL={1}>
                    <button
                      type="button"
                      onClick={() => {
                        arrayHelpers.remove(index)
                        handleSubmit()
                      }}
                    >
                      <DeleteIcon inline />
                    </button>
                  </IconGridItem>
                </Grid>
              </Grid.Item>
            ))}
            <Grid.Item>
              <Button.Secondary
                type="button"
                size="S"
                onClick={() =>
                  arrayHelpers.push({
                    description: '',
                    price: '',
                  })
                }
              >
                {newRowLabel}
              </Button.Secondary>
            </Grid.Item>
          </Grid>
        )}
      />
    </>
  )
}

export default FormArraySection
