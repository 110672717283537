import { ReactElement } from 'react'
import { useFormikContext, useField } from 'formik'
import { Grid } from '@farewill/ui'

import ExternalLink from 'components/external-link'
import CircularRadioGroup from 'components/form/circular-radio-group'
import DatetimePicker from 'components/form/datetime-picker'
import Input from 'components/form/input'
import ENV from 'config/environment'
import { getTransactionIdFromSquareUrl } from 'utils/helpers'

type FormType = 'card' | 'invoice'

export type PaymentFormFieldsProps = {
  type: FormType
  namespace?: string
  handleSave?: ({
    name,
    value,
  }: {
    name: string
    value: string | undefined
  }) => void
  invoiceOptions?: { value: string; label: string }[]
}

const name = (prefix: string | undefined, name: string) =>
  prefix ? `${prefix}.${name}` : name

export const PaymentFormFields = ({
  namespace,
  type,
  handleSave,
  invoiceOptions = [
    { value: 'dwp', label: 'DWP' },
    { value: 'other', label: 'Someone else' },
  ],
}: PaymentFormFieldsProps): ReactElement => {
  const { setFieldValue } = useFormikContext()
  const [{ value: paidBy }] = useField(name(namespace, 'paidBy'))

  return (
    <>
      {type === 'invoice' && invoiceOptions.length > 1 && (
        <Grid.Item>
          <CircularRadioGroup
            label="Who will be paying the invoice"
            name={name(namespace, 'paidBy')}
            inline
            options={invoiceOptions}
            handleSave={handleSave}
          />
        </Grid.Item>
      )}
      {type === 'card' && (
        <Grid.Item>
          <ExternalLink href={ENV.SQUARE_DASHBOARD_URL as string}>
            Take payment on Square
          </ExternalLink>
        </Grid.Item>
      )}
      <Grid.Item span={6}>
        <Input
          name={name(namespace, 'amount')}
          label={type === 'card' ? 'Total Amount Paid (£)' : 'Amount (£)'}
          isCurrency
          handleSave={handleSave}
        />
      </Grid.Item>
      <Grid.Item span={6}>
        <DatetimePicker
          label="Date"
          name={name(namespace, 'date')}
          time={false}
          handleSave={handleSave}
        />
      </Grid.Item>
      {type === 'card' && (
        <>
          <Grid.Item>
            <Input
              name={name(namespace, 'squareTransactionUrl')}
              label="Square transaction URL"
              onChange={(e) => {
                if (e?.target?.value != null) {
                  setFieldValue('squareTransactionUrl', e.target.value)
                  setFieldValue(
                    'reference',
                    getTransactionIdFromSquareUrl(e.target.value) || ''
                  )
                }
              }}
            />
          </Grid.Item>
          <Grid.Item>
            <Input
              label="Payment reference"
              name={name(namespace, 'reference')}
              handleSave={handleSave}
            />
          </Grid.Item>
        </>
      )}

      {type === 'invoice' && (
        <>
          <Grid.Item spanFromL={7}>
            <Input
              name={name(namespace, 'reference')}
              label={
                paidBy === 'dwp' ||
                (invoiceOptions?.length === 1 &&
                  invoiceOptions[0].value === 'dwp')
                  ? 'NI Number'
                  : 'Name of person / organisation paying'
              }
              handleSave={handleSave}
            />
          </Grid.Item>
          <Grid.Item spanFromL={7}>
            <Input
              label="Invoice reference (INV-XXXX)"
              name={name(namespace, 'externalReferenceId')}
              handleSave={handleSave}
            />
          </Grid.Item>
        </>
      )}
    </>
  )
}

export default PaymentFormFields
