import styled, { keyframes } from 'styled-components'

const spinAnimation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
`

type StyledLoaderProps = {
  inline?: boolean
  size?: string
  visible?: boolean
  color?: string
}

export type LoaderProps = StyledLoaderProps & {
  className?: string
}

const Loader = ({ className, inline = false, size = '1em' }: LoaderProps) => {
  return (
    <figure
      aria-busy="true"
      aria-label="Loading"
      className={className}
      role="alert"
    />
  )
}

const StyledLoader = styled(Loader)`
  ${({ inline }) =>
    !inline &&
    `
      position: absolute;
      top: 50%;
      left: 50%;
      transform-origin: 50% 50%;
  `}

  font-size: 40px;
  width: ${({ size = '1em' }) => size};
  height: ${({ size = '1em ' }) => size};
  margin: ${({ inline }) => (inline ? 0 : '-0.5em')};
  animation: 0.8s ${spinAnimation} infinite linear;
  transition: 0.1s opacity linear;
  opacity: ${({ visible }) => (visible ? '0.6' : '0')};
  background-image: ${({ color }) =>
    `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' ${
      color ? `fill='${encodeURIComponent(color)}'` : ''
    } xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 20 20'%3E%3Cpath d='M20 10h-2c0 4.4-3.6 8-8 8v2c5.5 0 10-4.5 10-10z'/%3E%3Cdefs%3E%3Cpath id='a' d='M10 2c4.4 0 8 3.6 8 8s-3.6 8-8 8-8-3.6-8-8 3.6-8 8-8m0-2C4.5 0 0 4.5 0 10s4.5 10 10 10 10-4.5 10-10S15.5 0 10 0z'/%3E%3C/defs%3E%3CclipPath id='b'%3E%3Cuse xlink:href='%23a' overflow='visible'/%3E%3C/clipPath%3E%3ClinearGradient id='c' gradientUnits='userSpaceOnUse' x1='9' y1='11' x2='19' y2='1' gradientTransform='rotate(90 15 6)'%3E%3Cstop offset='.1' stop-opacity='.5'/%3E%3Cstop offset='.9'/%3E%3C/linearGradient%3E%3Cpath clip-path='url(%23b)' fill='url(%23c)' d='M10 10V0h10v10z'/%3E%3Cg%3E%3Cdefs%3E%3Cpath id='d' d='M10 2c4.4 0 8 3.6 8 8s-3.6 8-8 8-8-3.6-8-8 3.6-8 8-8m0-2C4.5 0 0 4.5 0 10s4.5 10 10 10 10-4.5 10-10S15.5 0 10 0z'/%3E%3C/defs%3E%3CclipPath id='e'%3E%3Cuse xlink:href='%23d' overflow='visible'/%3E%3C/clipPath%3E%3ClinearGradient id='f' gradientUnits='userSpaceOnUse' x1='0' y1='10' x2='10' y2='0'%3E%3Cstop offset='.05' stop-opacity='0'/%3E%3Cstop offset='.9' stop-opacity='.5'/%3E%3C/linearGradient%3E%3Cpath clip-path='url(%23e)' fill='url(%23f)' d='M0 0h10v10H0z'/%3E%3C/g%3E%3C/svg%3E")`};
`

export default StyledLoader
