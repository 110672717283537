import { ReactElement } from 'react'
import { useFormikContext } from 'formik'
import { Grid, H } from '@farewill/ui'

import { FuneralPlanLeadAttributes } from 'lib/models/lead'
import RadioGroup from 'components/form/radio-group'

const FORM_FIELDS_NAMES = {
  BUYING_FOR_THEMSELVES: 'purchaserIsCoveredIndividual',
} as const

const Plan = (): ReactElement => {
  const { handleSubmit } = useFormikContext<FuneralPlanLeadAttributes>()

  return (
    <>
      <H size="S">Plans</H>
      <Grid>
        <Grid.Item>
          <RadioGroup
            name={`quoteAttributes.${FORM_FIELDS_NAMES.BUYING_FOR_THEMSELVES}`}
            label="Are they buying the plan for themself?"
            options={[
              { label: 'Yes', value: 'true' },
              {
                label: 'No',
                value: 'false',
              },
            ]}
            handleSave={() => handleSubmit()}
          />
        </Grid.Item>
      </Grid>
    </>
  )
}

export default Plan
