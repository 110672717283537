import MessageBox from 'components/message-box'

const LegacyLeadMessageBox = () => {
  return (
    <MessageBox margin={['L', 0, 0]} error>
      Missing `probateEstateId` - Legacy lead, without Probate Estate data.
    </MessageBox>
  )
}

export default LegacyLeadMessageBox
