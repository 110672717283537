import { useEffect, useRef, useState } from 'react'
import { GTR } from '@farewill/ui/tokens'

const useSticky = (element, dataStickyContent, offset = parseInt(GTR.XL)) => {
  const [sticky, setSticky] = useState(false)
  const stickyRef = useRef(false)

  useEffect(() => {
    const handleScroll = () => {
      const elementTop = element.current.getBoundingClientRect().top
      const elementHeight = element.current.offsetHeight
      const pageContentHeight = document.querySelectorAll(
        `[data-sticky-content=${dataStickyContent}]`
      )[0].offsetHeight

      const isScrollingContent = pageContentHeight > elementHeight
      const isSticky = isScrollingContent && elementTop < offset

      if (isSticky !== stickyRef.current) {
        stickyRef.current = isSticky
        setSticky(isSticky)
      }
    }

    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [dataStickyContent, element, offset])

  return [sticky]
}

export default useSticky
