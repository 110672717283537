import { Grid, H } from '@farewill/ui'
import { useQueryClient } from 'react-query'
import { useFormikContext } from 'formik'

import { formatAdminUserOptions } from 'utils/helpers'
import { AdminUser } from '../../../lib/models/admin-user'

import SelectInput from 'components/form/select-input'

const CaseOwner = (): React.ReactElement => {
  const { handleSubmit } = useFormikContext()
  const cache = useQueryClient()
  const adminUsers = cache.getQueryData('adminUsers') as AdminUser[]

  const adminUserOptions = formatAdminUserOptions(adminUsers)

  return (
    <Grid>
      <Grid.Item>
        <H size="S">Case owner</H>
      </Grid.Item>
      <Grid.Item spanFromL={6}>
        <SelectInput
          options={adminUserOptions}
          label="Fact find appointment"
          name="caseOwnerId"
          handleSave={() => handleSubmit()}
          isClearable
        />
      </Grid.Item>
      <Grid.Item spanFromL={6}>
        <SelectInput
          options={adminUserOptions}
          label="Holiday cover"
          name="holidayCoverOwnerId"
          handleSave={() => handleSubmit()}
          isClearable
        />
      </Grid.Item>
    </Grid>
  )
}

export default CaseOwner
