export const CLAIM_TO_TRANSFER_NIL_RATE_BAND_NEEDED =
  'claimToTransferNilRateBandNeeded'

export const SPOUSE_TITLE = 'spouseTitle'
export const SPOUSE_FIRST_NAMES = 'spouseFirstNames'
export const SPOUSE_LAST_NAME = 'spouseLastName'
export const SPOUSE_DEATH = 'spouseDeath'
export const MARRIAGE = 'marriage'
export const PLACE_OF_MARRIAGE = 'placeOfMarriage'
export const SPOUSE_NOTES = 'spouseNotes'

export const SPOUSE_PROBATE_OBTAINED = 'spouseProbateObtained'
export const SPOUSE_PROBATE_REGISTRY = 'spouseProbateRegistry'
export const SPOUSE_GRANT = 'spouseGrant'
export const SPOUSE_HAD_WILL = 'spouseHadWill'
export const SPOUSE_LEFT_ALL_TO_DECEASED = 'spouseLeftAllToDeceased'
export const SPOUSE_WILL_AVAILABLE = 'spouseWillAvailable'
export const SPOUSE_DOMICILED_IN_UK_AT_DEATH = 'spouseDomiciledInUkAtDeath'
export const SPOUSE_ESTATE_TAX_EXEMPT = 'spouseEstateTaxExempt'
export const SPOUSE_HAD_JOINT_ASSETS_WITH_SOMEONE_ELSE =
  'spouseHadJointAssetsWithSomeoneElse'
export const SPOUSE_MADE_NON_IHT_EXEMPT_GIFTS_OR_TRANSFERS =
  'spouseMadeNonIhtExemptGiftsOrTransfers'
export const SPOUSE_HAD_AGRICULTURAL_OR_BUSINESS_RELIEF_DEDUCTED =
  'spouseHadAgriculturalOrBusinessReliefDeducted'
export const SPOUSE_MADE_GIFTS_WITH_RESERVATION_OF_BENEFIT_TO_SOMEONE_ELSE =
  'spouseMadeGiftsWithReservationOfBenefitToSomeoneElse'
export const SPOUSE_BENEFITTED_FROM_TRUST = 'spouseBenefittedFromTrust'
export const SPOUSE_ESTATE_NOTES = 'spouseEstateNotes'
export const SPOUSE_LAST_PERMANENT_ADDRESS = 'spouseLastPermanentAddress'
