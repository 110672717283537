import { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import moment from 'moment'
import styled from 'styled-components'

import { Divider, Grid, H, Wrapper } from '@farewill/ui'

import Notes from 'components/product-summary/components/notes'
import PaymentsSummary from 'components/payments/invoices'
import {
  formatAmountToPence,
  getInitialValuesFromInvoice,
} from 'components/payments/invoices/methods'
import Overview from 'components/payments/overview'
import PaymentModal, {
  FormOutputValues,
} from 'components/payments/payment-modal'
import { useTasksContext } from 'components/tasks-panel/context'
import useInvoices from 'hooks/useInvoices'
import useLeadEvents from 'hooks/useLeadEvents'
import useApiHelpers from 'lib/effects/api-helpers'
import { Funeral, FuneralAttributes } from 'lib/models/funeral'
import { Invoice, InvoiceAttributes } from 'lib/models/invoice'
import { hideModal, showModal } from 'state/actions'

import {
  SlimPrimaryButton,
  SlimSecondaryButton,
  SlimBorderedButton,
} from './buttons'
import EditableQuote from './editable-quote'
import QuoteSummary from './quote'

const HeaderGridItem = styled(Grid.Item)`
  align-self: center;
`

type SaleProps = {
  funeralId: number
  funeral?: Funeral
  lead: {
    id: number
    attributes: {
      calculatedPrice: number
    }
  }
  saveFuneral: (
    attributes: Partial<FuneralAttributes>
  ) => Promise<FuneralAttributes>
}

const MODAL_MAX_WIDTH = 650

const Sale = ({
  funeralId,
  funeral,
  lead,
  saveFuneral,
}: SaleProps): React.ReactNode => {
  const dispatch = useDispatch()
  const { fetchTasks } = useTasksContext()
  const [isEditingQuote, setIsEditingQuote] = useState(false)

  const {
    invoices,
    fetchInvoices,
    isFetchingInvoices,
    createInvoice,
    updateInvoice,
    updateLineItem,
    deleteInvoice,
  } = useInvoices()

  useEffect(() => {
    if (funeralId) {
      fetchInvoices({ queryParams: { 'filter[funeralId]': funeralId } })
    }
  }, [fetchInvoices, funeralId])

  const { leadEvents, fetchLeadEvents } = useLeadEvents(lead.id)

  useEffect(() => {
    if (lead) {
      fetchLeadEvents()
    }
  }, [fetchLeadEvents, lead])

  const { items: quote, fetchItems: fetchQuote } = useApiHelpers({
    baseUrl: `/api/funerals/${funeralId}/quote`,
    type: 'funeral_quote',
  })

  useEffect(() => {
    if (isEditingQuote === false) {
      fetchQuote()
    }
  }, [fetchQuote, funeralId, isEditingQuote, funeral])

  const refetchTasks = useCallback(
    () =>
      fetchTasks({
        queryParams: {
          'filter[funeralCaseId]': funeralId,
          'filter[isNeeded]': true,
          'page[size]': 200,
        },
      }),
    [fetchTasks, funeralId]
  )

  if (isFetchingInvoices || !funeral) {
    return 'Loading...'
  }

  const editPayment = (invoice: Invoice) => {
    const formType =
      invoice.attributes.paymentProvider === 'square' ? 'card' : 'invoice'

    dispatch(
      showModal({
        component: PaymentModal,
        headingText: 'Edit payment',
        maxWidth: MODAL_MAX_WIDTH,
        config: {
          type: formType,
          values: getInitialValuesFromInvoice({ type: formType, invoice }),
          hideModal: () => dispatch(hideModal()),
          deletePayment: async () => {
            await deleteInvoice(invoice.id)
            refetchTasks()
          },
          savePayment: async ({
            reference,
            date,
            amount,
            isPaid,
            externalReferenceId,
            paidBy,
          }: FormOutputValues) => {
            let attributes = {
              reference,
              issuedDate: date,
            } as Partial<InvoiceAttributes>

            if (formType === 'invoice') {
              attributes = {
                ...attributes,
                externalReferenceId,
                paidBy,
              }

              const hasPaidOn = invoice.attributes.paidOn != null

              if (isPaid === 'yes' && !hasPaidOn) {
                attributes.paidOn = moment(new Date()).format('YYYY-MM-DD')
              } else if (isPaid === 'no' && hasPaidOn) {
                attributes.paidOn = null
              }
            } else if (formType === 'card') {
              attributes.paidOn = attributes.issuedDate
            }

            const price = formatAmountToPence(amount)
            await updateLineItem(invoice.attributes.lineItems[0], {
              price,
            })

            await updateInvoice(invoice.id, attributes)

            refetchTasks()
          },
        },
      })
    )
  }

  const addCardPayment = () => {
    dispatch(
      showModal({
        component: PaymentModal,
        headingText: 'Add payment',
        maxWidth: MODAL_MAX_WIDTH,
        config: {
          type: 'card',
          values: getInitialValuesFromInvoice({ type: 'card' }),
          hideModal: () => dispatch(hideModal()),
          savePayment: async ({
            reference,
            date,
            amount,
          }: FormOutputValues) => {
            const price = formatAmountToPence(amount)

            await createInvoice({
              funeralId,
              paidTo: 'farewill',
              reference,
              issuedDate: date,
              paidOn: date,
              paymentProvider: 'square',
              lineItems: [
                {
                  description: 'funeral',
                  price,
                },
              ],
            })

            refetchTasks()
          },
        },
      })
    )
  }

  const addInvoice = () => {
    dispatch(
      showModal({
        component: PaymentModal,
        headingText: 'Add payment',
        maxWidth: MODAL_MAX_WIDTH,
        config: {
          type: 'invoice',
          values: getInitialValuesFromInvoice({ type: 'invoice' }),
          hideModal: () => dispatch(hideModal()),
          savePayment: async ({
            reference,
            date,
            amount,
            externalReferenceId,
            isPaid,
            paidBy,
          }: FormOutputValues) => {
            const price = formatAmountToPence(amount)

            await createInvoice({
              funeralId,
              paidTo: 'farewill',
              reference,
              issuedDate: date,
              paymentProvider: 'bank_transfer',
              externalReferenceType: 'xero',
              externalReferenceId,
              paidBy,
              paidOn: isPaid === 'yes' ? date : null,
              lineItems: [
                {
                  description: 'funeral',
                  price,
                },
              ],
            })

            refetchTasks()
          },
        },
      })
    )
  }

  const editableQuoteControls = (
    <Grid.Item span={3} margin={[0, 0, 0, 'auto']}>
      {isEditingQuote ? (
        <SlimBorderedButton onClick={() => setIsEditingQuote(false)}>
          Cancel
        </SlimBorderedButton>
      ) : (
        <SlimPrimaryButton onClick={() => setIsEditingQuote(true)}>
          Edit quote
        </SlimPrimaryButton>
      )}
    </Grid.Item>
  )

  const handleUpdateQuote = async (attributes: Partial<FuneralAttributes>) => {
    await saveFuneral(attributes)

    setIsEditingQuote(false)
  }

  const quoteContent = (
    <Grid margin={[0, 0, 'XL', 0]}>
      <HeaderGridItem span={9}>
        <H size="S">Quote</H>
      </HeaderGridItem>
      {editableQuoteControls}
      <Grid.Item>
        {isEditingQuote ? (
          <EditableQuote
            funeral={funeral}
            onSave={handleUpdateQuote}
            funeralQuote={quote.attributes}
          />
        ) : (
          <QuoteSummary quote={quote.attributes} />
        )}
      </Grid.Item>
    </Grid>
  )

  return (
    <>
      <H size="M">Sale</H>
      <Overview cost={quote.attributes?.totalCost ?? 0} invoices={invoices} />
      <Divider margin={['L', 0]} />
      <PaymentsSummary
        invoices={invoices}
        onEdit={editPayment}
        actions={
          <>
            <SlimPrimaryButton onClick={addCardPayment}>
              Add card payment
            </SlimPrimaryButton>
            <SlimSecondaryButton onClick={addInvoice}>
              Add invoice
            </SlimSecondaryButton>
          </>
        }
      />

      <Divider margin={['L', 0]} />

      {quoteContent}

      {/* The Notes table is shared so the CSS can't easily be edited */}
      <Wrapper margin={[0, 0, 0, -8]}>
        <Notes leadEvents={leadEvents} title="Sale notes" />
      </Wrapper>
    </>
  )
}

export default Sale
