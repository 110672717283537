export const SINGLE = 'single'
export const COUPLE = 'couple'

const PRICING = {
  GRANT_COPY_PRICE: 150,
  PROBATE_PRICING: {
    SIMPLE: 595_00,
    COMPLEX: 1045_00,
    DEPOSIT: 150_00,
  },
  WILL_PRICING: {
    online: {
      [SINGLE]: 9000,
      [COUPLE]: 14000,
    },
    phone: {
      [SINGLE]: 24000,
      [COUPLE]: 38000,
    },
  },
  LPA_PRICING: {
    REGISTRATION_FEE: 8200,
  },
  VAT_PERCENTAGE: 20,
  VAT_RATE: 0.2,
}

export default PRICING
