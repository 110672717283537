import { P } from '@farewill/ui'
import { CharityAttributes } from 'lib/models/charity'

type Props = { charity: CharityAttributes }

const CharityDetails = ({ charity }: Props): React.ReactElement => (
  <>
    <P strong margin="0">
      {charity.legalName}
    </P>
    {charity.commonName && (
      <P size="S" margin="0">
        {charity.commonName}
      </P>
    )}
    <P size="S" margin={['XS', 0, 0]}>
      {charity.number || <i>Charity number not added</i>}
    </P>
  </>
)

export default CharityDetails
