import { useContext, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Button, P, Wrapper } from '@farewill/ui'
import { GTR } from '@farewill/ui/tokens'

import useApiHelpers from 'lib/effects/api-helpers'
import { Context } from 'routes/probate-estate/context'

import ChildBeneficiary from './child-beneficiary'

const ChildBeneficiaries = ({ probateEstateId }) => {
  const { setErrors, setIsLoading } = useContext(Context)
  const {
    items: childBeneficiaries,
    fetchItems: fetchChildBeneficiaries,
    isFetching,
    createItem: createChildBeneficiary,
    isCreating,
    updateItem: updateChildBeneficiary,
    isUpdating,
    updateErrors,
    deleteItem: deleteChildBeneficiary,
  } = useApiHelpers({
    baseUrl: `/api/probate-estates/${probateEstateId}/child-beneficiaries`,
    type: 'probate_estate_child_beneficiaries',
  })

  useEffect(() => {
    fetchChildBeneficiaries()
  }, [fetchChildBeneficiaries])

  useEffect(() => {
    setErrors(updateErrors)
  }, [setErrors, updateErrors])

  useEffect(() => {
    setIsLoading(isUpdating)
  }, [setIsLoading, isUpdating])

  if (isFetching) {
    return (
      <P size="L">
        Loading <strong>people under 18 receiving a gift</strong>...
      </P>
    )
  }

  return (
    <>
      <Wrapper margin={childBeneficiaries.length ? [0, 0, 'M'] : 0}>
        {childBeneficiaries.map((childBeneficiary, index) => (
          <ChildBeneficiary
            childBeneficiary={childBeneficiary}
            deleteChildBeneficiary={deleteChildBeneficiary}
            key={childBeneficiary.id}
            initialValues={{
              childBeneficiary: {
                [childBeneficiary.id]: childBeneficiary.attributes,
              },
            }}
            listLength={childBeneficiaries.length}
            listPosition={index + 1}
            updateChildBeneficiary={updateChildBeneficiary}
          />
        ))}
      </Wrapper>
      <Button.Secondary
        style={{ paddingTop: GTR.XXS, paddingBottom: GTR.XXS }}
        loading={isCreating}
        type="button"
        onClick={() => createChildBeneficiary({})}
      >
        Add person under 18
      </Button.Secondary>
    </>
  )
}

ChildBeneficiaries.propTypes = {
  probateEstateId: PropTypes.string.isRequired,
}

export default ChildBeneficiaries
