import { Formik, Form } from 'formik'
import { Button, ButtonGroup, Wrapper } from '@farewill/ui'
import { GTR } from '@farewill/ui/tokens'
import { useDispatch, useSelector } from 'react-redux'
import { useUpdateResource, RESOURCES } from 'api'

import { WILL_GRADE_OPTIONS } from 'utils/enums'
import { hideModal } from 'state/actions'
import CircularRadioGroup from 'components/form/circular-radio-group'
import MessageBox from 'components/message-box'
import ExternalLink from 'components/external-link'

const GUIDANCE_URL =
  'https://www.notion.so/farewill/Classification-of-Telephone-Wills-6a9d6b16e59241d69917876b9227f0f1'

type Props = {
  config: {
    grade: string | null
    willCaseId: number
  }
}

const WillGradeModal = ({
  config: { grade, willCaseId },
}: Props): React.ReactElement => {
  const dispatch = useDispatch()
  const isLoading = useSelector((state: { requestsPending: string[] }) =>
    state.requestsPending.includes('UPDATE_WILL_CASE')
  )
  const willCaseMutation = useUpdateResource()

  const handleSubmit = async (values: { grade: string }) => {
    try {
      await willCaseMutation.mutateAsync({
        id: willCaseId,
        attributes: { grade: values.grade || null },
        resource: RESOURCES.WILL_CASES,
      })
      dispatch(hideModal())
    } catch (error) {
      dispatch(hideModal())
    }
  }

  return (
    <>
      <MessageBox margin={[0, 0, GTR.M]} stretch>
        See <ExternalLink href={GUIDANCE_URL}>guidance</ExternalLink> on will
        classification.
      </MessageBox>
      <Formik initialValues={{ grade: grade || '' }} onSubmit={handleSubmit}>
        <Form>
          <Wrapper margin={[0, 0, 'M']}>
            <CircularRadioGroup name="grade" options={WILL_GRADE_OPTIONS} />
          </Wrapper>
          <ButtonGroup>
            <Button.Primary type="submit" loading={isLoading}>
              Save
            </Button.Primary>
            <Button type="button" onClick={() => dispatch(hideModal())}>
              Cancel
            </Button>
          </ButtonGroup>
        </Form>
      </Formik>
    </>
  )
}

export default WillGradeModal
