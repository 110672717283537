import { CrossIcon, Wrapper } from '@farewill/ui'
import styled from 'styled-components'
import { GTR, COLOR, BORDER } from '@farewill/ui/tokens'

const Outer = styled.div`
  position: fixed;
  background-color: rgba(0, 0, 0, 0.3);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 200;
`

const Inner = styled(Wrapper)`
  max-width: ${({ $maxWidth }) => `${$maxWidth}px`};
  margin: ${GTR.XXL} auto 0;
  overflow-y: auto;
  height: calc(100vh - 2 * ${GTR.XXL});
`

const Heading = styled(Wrapper)`
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: ${COLOR.WHITE};
  padding: ${GTR.S};
  border-radius: ${BORDER.RADIUS.S} ${BORDER.RADIUS.S} 0 0;
  display: flex;
  justify-content: space-between;
  align-items: center;

  * {
    margin-bottom: 0;
  }
`

const Body = styled(Wrapper)`
  background-color: ${COLOR.BACKGROUND.FOG};
  padding: ${GTR.M} ${GTR.S};
  border-radius: 0 0 ${BORDER.RADIUS.S} ${BORDER.RADIUS.S};
`

const Close = styled.button`
  font-size: 0;
  color: ${COLOR.GREY.MEDIUM};
`

const Modal = ({
  component: Component,
  headingText,
  hideModal,
  visible,
  maxWidth,
  ...rest
}) => {
  if (!visible) return null

  return (
    <Outer>
      <Inner $maxWidth={maxWidth}>
        <Heading>
          <h5>{headingText}</h5>
          <Close onClick={hideModal} type="button">
            <CrossIcon /> Close
          </Close>
        </Heading>
        <Body>
          <Component {...rest} />
        </Body>
      </Inner>
    </Outer>
  )
}

Modal.defaultProps = {
  maxWidth: 450,
}

export default Modal
