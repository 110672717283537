import { useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Button, Grid, P } from '@farewill/ui'
import { GTR } from '@farewill/ui/tokens'
import identity from 'lodash/identity'

import store from 'state/create-store'
import { showModal } from 'state/actions'

import useItemState from 'lib/effects/item-state'
import useProbateEstateItemHelpers from 'lib/effects/probate-estate-item-helpers'

import EstateCard from 'components/estate-card'
import InputFloating from 'components/form/input-floating'
import CircularRadioGroup from 'components/form/circular-radio-group'
import GridWithToggle from 'components/grid-with-toggle'

import DeleteModal from '../components/delete-modal'
import TextSpacer from '../components/text-spacer'
import withForm from '../components/with-form'

import { MORTGAGES_SECTION_ACTIVE } from './field-names'

import { useFormikContext } from 'formik'
import namespace from 'lib/formik/namespace'
import { getItemsTotals } from '../get-totals'
import get from 'lodash/get'

import apiRequest from 'lib/axios/api-request'

import {
  PROBATE_ESTATE_PROPERTY_ID,
  DECEASED_SHARE_OF_JOINT_OWNERSHIP,
  DESCRIPTION,
  IS_ESTIMATED_VALUE,
  JOINT_OWNER_NAME,
  OWNERSHIP,
  VALUE,
} from '../item-field-constants'
import { NOTES } from '../assets/field-names'

import SelectInput from 'components/form/select-input'
import NotesInput from 'components/notes-input'
import ScrollTo from 'components/scroll-to'

const getAddress = (address) => {
  return [
    address.lineOne,
    address.lineTwo,
    address.lineThree,
    address.city,
    address.postalCode,
  ]
    .filter(identity)
    .join(', ')
}

const Item = ({
  item,
  probateEstateId,
  index,
  listLength,
  deleteItem,
  updateItem,
}) => {
  const { withNamespace, withoutNamespace } = namespace(`mortgages.${item.id}.`)
  const { values } = useFormikContext()

  const handleSave = ({ name, value }) => {
    updateItem({
      id: item.id,
      attributes: { [withoutNamespace(name)]: value || null },
    })
  }

  const onTrashClick = () => {
    store.dispatch(
      showModal({
        component: DeleteModal,
        headingText: 'Delete mortgage',
        config: {
          deleteCallback: () => {
            deleteItem({
              probateEstateId,
              id: item.id,
            })
          },
          type: 'mortgage',
          resourceId: item.id,
        },
      })
    )
  }

  const fetchProperties = useCallback(
    () =>
      apiRequest({
        url: `/api/probate-estates/${probateEstateId}/properties`,
      }).then((res) =>
        res.data.data.map((property) => ({
          value: property.id,
          label: getAddress(property.attributes.address),
        }))
      ),
    [probateEstateId]
  )

  return (
    <EstateCard
      onTrashClick={onTrashClick}
      listLength={listLength}
      listPosition={index + 1}
      key={item.id}
      type="mortgage"
    >
      <Grid>
        <Grid.Item>
          <SelectInput
            fetchOptions={fetchProperties}
            floating
            handleSave={handleSave}
            hint={
              <>
                Select from properties entered in section{' '}
                <ScrollTo scrollId="4.1">4.1</ScrollTo> (add properties first)
              </>
            }
            label="Property"
            name={withNamespace(PROBATE_ESTATE_PROPERTY_ID)}
          />
        </Grid.Item>
        <Grid.Item>
          <InputFloating
            name={withNamespace(DESCRIPTION)}
            label="Description"
            handleSave={handleSave}
          />
        </Grid.Item>
        <Grid.Item span={3}>
          <InputFloating
            name={withNamespace(VALUE)}
            label="Value of debt (£)"
            handleSave={handleSave}
            isCurrency
          />
        </Grid.Item>
        <Grid.Item
          span={9}
          style={{ alignSelf: 'center', justifySelf: 'center' }}
        >
          <CircularRadioGroup
            name={withNamespace(IS_ESTIMATED_VALUE)}
            options={[
              { label: 'Estimate', value: 'true' },
              {
                label: 'Confirmed DOD value (add detail in notes)',
                value: 'false',
              },
            ]}
            handleSave={handleSave}
            inline
          />
        </Grid.Item>
        <Grid.Item>
          <CircularRadioGroup
            name={withNamespace(OWNERSHIP)}
            label="Ownership"
            options={[
              { label: 'Not asked', value: '' },
              { label: 'Sole', value: 'sole' },
              { label: 'Joint', value: 'joint' },
            ]}
            handleSave={handleSave}
            inline
          />
        </Grid.Item>
        {get(values, withNamespace(OWNERSHIP)) === 'joint' && (
          <>
            <Grid.Item span={6}>
              <InputFloating
                name={withNamespace(JOINT_OWNER_NAME)}
                label="Name of joint owner"
                handleSave={handleSave}
                hint="If more than one, leave a note below"
              />
            </Grid.Item>
            <Grid.Item span={6}>
              <InputFloating
                name={withNamespace(DECEASED_SHARE_OF_JOINT_OWNERSHIP)}
                label="Share owned by person who's died (%)"
                handleSave={handleSave}
              />
            </Grid.Item>
          </>
        )}
        <Grid.Item>
          <NotesInput name={withNamespace(NOTES)} handleSave={handleSave} />
        </Grid.Item>
      </Grid>
    </EstateCard>
  )
}

const ItemWithForm = withForm(Item)

const Mortgages = ({
  initialItems,
  probateEstateId,
  saveProbateEstateField,
}) => {
  const { items, addItem, removeItem, replaceItem } = useItemState(initialItems)

  const {
    createItem,
    createdItem,
    isCreating,
    deleteItem,
    deletedItem,
    updateItem,
    updatedItem,
  } = useProbateEstateItemHelpers({ probateEstateId })

  useEffect(() => {
    if (createdItem) addItem(createdItem)
  }, [addItem, createdItem])

  useEffect(() => {
    if (deletedItem) removeItem(deletedItem)
  }, [removeItem, deletedItem])

  useEffect(() => {
    if (updatedItem) replaceItem(updatedItem)
  }, [replaceItem, updatedItem])

  const { total, sole, joint, unspecified } = getItemsTotals(items)
  return (
    <GridWithToggle
      disabled={items.length > 0}
      name={MORTGAGES_SECTION_ACTIVE}
      onToggle={saveProbateEstateField}
      scrollId="5.2"
      title="Mortgages"
    >
      <Grid.Item>
        <P size="S">
          Total: {total} <TextSpacer /> Sole: {sole} <TextSpacer /> Joint share:{' '}
          {joint} <TextSpacer /> Unspecified: {unspecified}
        </P>

        {items.map((item, index) => (
          <ItemWithForm
            deleteItem={deleteItem}
            enableReinitialize={false}
            index={index}
            initialValues={{ mortgages: { [item.id]: item.attributes } }}
            item={item}
            key={item.id}
            listLength={items.length}
            probateEstateId={probateEstateId}
            updateItem={updateItem}
          />
        ))}
      </Grid.Item>
      <Grid.Item>
        <Button.Secondary
          style={{ paddingTop: GTR.XXS, paddingBottom: GTR.XXS }}
          loading={isCreating}
          type="button"
          onClick={() => {
            createItem({ itemType: 'mortgage' })
          }}
        >
          Add mortgage
        </Button.Secondary>
      </Grid.Item>
    </GridWithToggle>
  )
}

Mortgages.propTypes = {
  initialItems: PropTypes.array,
  probateEstateId: PropTypes.string.isRequired,
  saveProbateEstateField: PropTypes.func.isRequired,
}

Mortgages.defaultProps = {
  initialItems: [],
}

export default Mortgages
