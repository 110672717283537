import PropTypes from 'prop-types'
import { Grid } from '@farewill/ui'
import { useFormikContext } from 'formik'

import Input from 'components/form/input'
import FakeInput from 'components/fake-input'

import { getTransactionIdFromSquareUrl } from 'utils/helpers'

const PaymentIdentifierForm = ({ editablePaymentReference }) => {
  const { handleSubmit, values, setFieldValue } = useFormikContext()
  const {
    quoteAttributes: { paymentReference },
  } = values

  return (
    <Grid>
      <Grid.Item>
        <Input
          name="squareTransactionUrl"
          label="Square transaction URL"
          onChange={(e) => {
            setFieldValue('squareTransactionUrl', e.target.value)
            setFieldValue(
              'quoteAttributes.paymentReference',
              getTransactionIdFromSquareUrl(e.target.value) || ''
            )
          }}
          handleSave={handleSubmit}
          allowCopyToClipboard
        />
      </Grid.Item>
      <Grid.Item>
        {editablePaymentReference ? (
          <Input
            label="Payment reference"
            name="quoteAttributes.paymentReference"
            handleSave={handleSubmit}
            allowCopyToClipboard
          />
        ) : (
          <FakeInput
            label="Payment reference"
            name="quoteAttributes.paymentReference"
            value={paymentReference}
          />
        )}
      </Grid.Item>
    </Grid>
  )
}

PaymentIdentifierForm.propTypes = {
  editablePaymentReference: PropTypes.bool,
}

PaymentIdentifierForm.defaultProps = {
  editablePaymentReference: false,
}

export default PaymentIdentifierForm
