import { useEffect } from 'react'
import PropTypes from 'prop-types'
import { P, Grid } from '@farewill/ui'
import { Link } from 'react-router-dom'
import inRange from 'lodash/inRange'

import { CONFIRMED_AND_CHECKED_OPTIONS } from 'utils/enums'
import useApi from 'lib/effects/api'
import { formatCurrency } from 'lib/formatting/currency'

import CircularRadioGroup from 'components/form/circular-radio-group'
import InputFloating from 'components/form/input-floating'
import PinkBox from 'components/styled/pink-box'
import IHT400Warning from './iht-400-warning'

import {
  CONFIRMED_ESTATE_VALUE_BETWEEN_325000_AND_650000,
  CONFIRMED_ESTATE_VALUE_BETWEEN_325000_AND_650000_NOTES,
} from '../field-names'

const IHT_THRESHOLD = 325000
const NIL_RATE_BAND_TRANSFER_THRESHOLD = 650000

const EstateValueChecks = ({ probateEstateId, saveProbateEstateField }) => {
  const summaryUrl = `/probate/estates/${probateEstateId}/summary`

  const [{ data: summary, isLoading }, getProbateEstateSummary] = useApi({
    isLoading: true,
  })

  useEffect(() => {
    getProbateEstateSummary({
      url: `/api/probate-estates/${probateEstateId}/summary`,
    })
  }, [probateEstateId, getProbateEstateSummary])

  if (isLoading) {
    return (
      <P size="L">
        Loading <strong>estate summary</strong>...
      </P>
    )
  }

  const netEstateValue =
    summary.attributes.financials.netEstateForInheritanceTax?.value
  const netEstateValueIsWithinRange = inRange(
    netEstateValue,
    IHT_THRESHOLD,
    NIL_RATE_BAND_TRANSFER_THRESHOLD + 0.01
  )

  return (
    <Grid gap={['S', 'M']}>
      <Grid.Item>
        <P strong>
          Is the estate worth{' '}
          {formatCurrency({ value: IHT_THRESHOLD, showPence: false })} to{' '}
          {formatCurrency({
            value: NIL_RATE_BAND_TRANSFER_THRESHOLD,
            showPence: false,
          })}
          ?
        </P>
      </Grid.Item>
      <Grid.Item>
        <PinkBox>
          According to fact find,{' '}
          <strong>{netEstateValueIsWithinRange ? 'yes' : 'no'}</strong> – the{' '}
          net estate for inheritance tax is{' '}
          {formatCurrency({ value: netEstateValue, showPence: false })} (
          <Link to={summaryUrl}>summary, IHT205 box H</Link>)
        </PinkBox>
      </Grid.Item>
      <Grid.Item>
        <CircularRadioGroup
          name={CONFIRMED_ESTATE_VALUE_BETWEEN_325000_AND_650000}
          options={CONFIRMED_AND_CHECKED_OPTIONS}
          handleSave={saveProbateEstateField}
          inline
        />
      </Grid.Item>
      <Grid.Item span={9}>
        <InputFloating
          name={CONFIRMED_ESTATE_VALUE_BETWEEN_325000_AND_650000_NOTES}
          label="How have you confirmed this?"
          handleSave={saveProbateEstateField}
        />
      </Grid.Item>
      {!netEstateValueIsWithinRange && (
        <Grid.Item span={9}>
          <IHT400Warning />
        </Grid.Item>
      )}
    </Grid>
  )
}

EstateValueChecks.propTypes = {
  probateEstateId: PropTypes.number.isRequired,
  saveProbateEstateField: PropTypes.func.isRequired,
}

export default EstateValueChecks
