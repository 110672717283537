import { FAREWILL_PRODUCTS } from 'utils/enums'
import { DefinedTimeslot } from './types'

export const OUTCOME_TYPE = {
  CANNOT_HELP: 'cannot_help',
  INCOMPLETE: 'incomplete',
  ONLINE_OR_PHONE: 'online_or_phone',
  PHONE: 'phone',
} as const

export const OUTCOME_HEADING = {
  CANNOT_HELP: 'We can’t help this customer',
  ONLINE_OR_PHONE_SIMPLE: 'They qualify for both options',
  ONLINE_OR_PHONE_COMPLEX: 'Recommendation: Phone will',
  PHONE: 'Phone will only',
} as const

export const PHONE_WILL_COMPLEXITY = {
  COMPLEX: 'complex',
  SIMPLE: 'simple',
} as const

export const PRODUCTS = {
  [FAREWILL_PRODUCTS.LPA]: {
    leadRelation: 'lpaCase',
    baseUrl: '/lpa-cases',
  },
  [FAREWILL_PRODUCTS.FUNERAL]: {
    leadRelation: 'funeral',
    baseUrl: '/funerals',
  },
  [FAREWILL_PRODUCTS.PROBATE]: {
    leadRelation: 'probateCase',
    baseUrl: '/probate/cases',
  },
  [FAREWILL_PRODUCTS.WILL]: {
    leadRelation: 'willCase',
    baseUrl: '/will-cases',
  },
  [FAREWILL_PRODUCTS.FUNERAL_PLAN]: {
    leadRelation: 'funeralPlan',
    baseUrl: '/funeral-plans',
  },
} as const

export const DEFINED_TIMESLOTS: DefinedTimeslot[] = [
  {
    value: '11:59:59',
    label: 'Morning',
  },
  {
    value: '13:59:59',
    label: 'Lunch',
  },
  {
    value: '17:59:59',
    label: 'Afternoon',
  },
]
