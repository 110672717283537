import { Wrapper } from '@farewill/ui'
import styled from 'styled-components'
import { COLOR, FONT, GTR } from '@farewill/ui/tokens'
import { screenMax } from '@farewill/ui/helpers/responsive'
import { Link, NavLink, useRouteMatch } from 'react-router-dom'
import { useSelector } from 'react-redux'

import CustomerSearch from 'components/customer-search'
import PAGES, { PATHS } from 'config/routes'
import useAdminUserRoles from 'hooks/useAdminUserRoles'
import { ReactNode } from 'react'

const StyledOuter = styled.header`
  background-color: ${COLOR.ACCENT.PRIMARY_60};
  padding-top: ${GTR.S};
  border-bottom: 1px solid ${COLOR.ACCENT.PRIMARY_120};
`

const StyledInner = styled(Wrapper)`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  min-height: 63px;

  ${screenMax.s`
    flex-direction: column-reverse;
    align-items: flex-start;
  `}
`

const StyledLink = styled(NavLink)`
  display: block;
  text-decoration: none;
  padding: 18px ${GTR.M};
  font-weight: ${FONT.WEIGHT.BOLD};
  color: ${COLOR.BLACK};
  border-width: 0px 1px;
  border-style: solid;
  border-color: transparent;

  &:hover {
    text-decoration: none;
  }

  &:hover:not(.active) {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    background-color: rgba(255, 211, 11, 0.3);
  }

  &.active {
    background-color: ${COLOR.WHITE};
    position: relative;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border: 0;

    &:after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: -1px;
      display: block;
      height: 1px;
      background-color: ${COLOR.WHITE};
    }
  }
`

const StyledList = styled.ul`
  margin-left: ${GTR.M};
  display: flex;
  gap: 5px;
`

const StyledLeftWrapper = styled(Wrapper)`
  display: flex;
  align-items: center;
  flex: 1;
`

const StyledRightWrapper = styled(Wrapper)`
  flex: 1;
  display: flex;
  justify-content: flex-end;

  ${screenMax.s`
    width: 100%;

    && {
      margin: ${GTR.S} 0;
    }
  `}
`

const StyledLogoLink = styled(Link)`
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
`

const StyledLogo = styled.span`
  color: transparent;
  background-image: url('/images/logo-black-compact.svg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  height: 36px;
  width: 24px;
  display: block;
  position: relative;
`

type Routes = Record<
  string,
  {
    PATH: string
    VIEW: ReactNode
  }
>

type Paths = { [key: string]: string }

const getPaths = (routes: Routes) =>
  Object.values(routes).map(({ PATH }) => PATH)

const Header = () => {
  const token = useSelector((state: { token: string }) => state.token)
  const { isUserRole } = useAdminUserRoles()
  const isLoggedIn = !!token

  const isSalesPath = !!useRouteMatch({ path: getPaths(PAGES.SALES) })
  const isCasesPath = !!useRouteMatch({ path: getPaths(PAGES.CASES) })

  const showTabsAndSearch = isLoggedIn

  return (
    <StyledOuter>
      <StyledInner container containerPaddingTop={0} containerPaddingBottom={0}>
        <StyledLeftWrapper>
          <StyledLogoLink to={(PATHS as Paths).LEAD_LIST}>
            <StyledLogo>Farewill</StyledLogo>
          </StyledLogoLink>
          <StyledList>
            {showTabsAndSearch && (
              <>
                <li>
                  <StyledLink
                    to={(PATHS as Paths).LEAD_LIST}
                    isActive={() => isSalesPath}
                    activeClassName="active"
                  >
                    Sales
                  </StyledLink>
                </li>
                <li>
                  <StyledLink
                    to="/customers/probate"
                    isActive={() => isCasesPath}
                    activeClassName="active"
                  >
                    Cases
                  </StyledLink>
                </li>
                {isUserRole('partnerships_admin') && (
                  <li>
                    <StyledLink to="/partners" activeClassName="active">
                      Partners
                    </StyledLink>
                  </li>
                )}
              </>
            )}
          </StyledList>
        </StyledLeftWrapper>
        {showTabsAndSearch && (
          <StyledRightWrapper>
            <CustomerSearch />
          </StyledRightWrapper>
        )}
      </StyledInner>
    </StyledOuter>
  )
}

export default Header
