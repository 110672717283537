import Tooltip from 'components/tooltip'
import { P, Wrapper } from '@farewill/ui'

import SimpleList from 'components/styled/simple-list'

const AdministratorWithWillLabel = () => (
  <>
    Administrator -&nbsp;
    <Tooltip
      content={
        <Wrapper>
          <P size="S">A PR could be an administrator with a will if:</P>
          <SimpleList bullets>
            <li>the appointment of executors is void</li>
            <li>all the executors have died or renounced</li>
            <li>
              the sole executor is mentally incapable of managing their affairs,
              or
            </li>
            <li>the sole executor is a minor</li>
          </SimpleList>
        </Wrapper>
      }
    >
      with a will
    </Tooltip>
  </>
)

export default AdministratorWithWillLabel
