import styled from 'styled-components'
import { BulletSeparator } from '@farewill/ui'
import { useDispatch } from 'react-redux'
import find from 'lodash/find'
import filter from 'lodash/filter'
import { Formik, Form } from 'formik'
import { Divider, Grid, H, Wrapper } from '@farewill/ui'
import { BORDER, COLOR, FONT, GTR } from '@farewill/ui/tokens'
import RequestStatus from 'components/request-status'
import CriticalNotes from 'components/critical-notes'
import { formatAnswer } from 'components/product-summary/helpers'

import {
  formatLocalDateTimeToString,
  formatTimeToDateTimeString,
  formatValuesForFormik,
  strToBool,
} from 'utils/helpers'
import { DIGNITY } from 'utils/enums'

import FuneralOverview from './funeral-overview'
import CaseOwners from './case-owners'
import Celebrant from './celebrant'
import Difficult from './difficult'
import Provider from './provider'
import ImportantDates from './important-dates'
import CaseSummary from './case-summary'
import Lost from './lost'

const spanOf = (text) => <span key={text}>{formatAnswer(text)}</span>

const dateFromDateTime = (dateTime) => {
  return formatLocalDateTimeToString(dateTime, { includeTime: false })
}

const StyledBulletSeparator = styled(BulletSeparator)`
  font-weight: ${FONT.WEIGHT.BOLD};
`

const StyledServicePreferences = styled.div`
  width: 100%;
  background-color: ${COLOR.GREY.LIGHT};
  padding: ${GTR.S};
  border-radius: ${BORDER.RADIUS.S};
  border: 2px solid ${COLOR.GREY.LIGHT};
  color: ${COLOR.BLACK};
  margin-top: ${GTR.XS};
`

const CaseOverview = ({ initialValues, events, lead, saveFields, match }) => {
  const dispatch = useDispatch()

  const isFactFindCompleted = !!find(events, [
    'attributes.name',
    'fact_find_completed',
  ])

  const isFormsSentForSignature = !!find(events, [
    'attributes.name',
    'forms_sent_for_signature',
  ])

  const initialFormValues = {
    ...formatValuesForFormik(initialValues),
    collectionDate: dateFromDateTime(initialValues.collectionDate),
    cremationDate: dateFromDateTime(initialValues.cremationDate),
    cremationTime: formatTimeToDateTimeString(initialValues.cremationTime),
    serviceTime: formatTimeToDateTimeString(initialValues.serviceTime),
    deliveryDate: dateFromDateTime(initialValues.deliveryDate),
  }

  const isDignitySelected = initialFormValues.provider === DIGNITY

  const onSubmit = async (values) => {
    const caseOwnerId =
      values.caseOwnerId === '' || values.caseOwnerId == null
        ? null
        : values.caseOwnerId

    const celebrantId =
      values.celebrantId === '' || values.celebrantId == null
        ? null
        : values.celebrantId

    const holidayCoverOwnerId =
      values.holidayCoverOwnerId === '' || values.holidayCoverOwnerId == null
        ? null
        : values.holidayCoverOwnerId

    const cremationDate = dateFromDateTime(values.cremationDate)
    const cremationTime =
      cremationDate == null
        ? null
        : formatLocalDateTimeToString(values.cremationTime, {
            timeOnly: true,
          })
    const serviceTime = formatLocalDateTimeToString(values.serviceTime, {
      timeOnly: true,
    })

    const patchData = {
      caseOwnerId,
      celebrantId,
      holidayCoverOwnerId,
      provider: values.provider,
      isDifficult: strToBool(values.isDifficult),
      difficultReason: values.difficultReason,
      collectionDate: dateFromDateTime(values.collectionDate),
      criticalNotes: values.criticalNotes,
      cremationDate,
      cremationTime,
      serviceTime,
      deliveryDate: dateFromDateTime(values.deliveryDate),
    }

    saveFields(patchData)
  }

  const importantPoints = filter([
    initialValues.isUrgentCollection && 'Urgent collection',
    initialValues.referredToCoroner &&
      (initialValues.deathCountry?.name === 'Scotland'
        ? 'Referred to procurator fiscal'
        : 'Referred to coroners'),
    initialValues.isBariatric && 'Bariatric',
  ])

  const leadServicePreferences =
    lead?.attributes?.quoteAttributes?.servicePreferences

  return (
    <Formik
      initialValues={initialFormValues}
      onSubmit={onSubmit}
      enableReinitialize
    >
      {({ handleSubmit }) => (
        <Form>
          <Wrapper>
            <RequestStatus />
            <Grid>
              <Grid.Item>
                <H size="M">Case details</H>
              </Grid.Item>
              <Grid.Item>
                <FuneralOverview
                  funeral={initialValues}
                  funeralId={match.params.id}
                />
              </Grid.Item>

              <Grid.Item>
                {leadServicePreferences?.length > 0 && (
                  <>
                    <span>
                      They mentioned these aspects as particularly important:
                    </span>
                    <StyledServicePreferences>
                      {leadServicePreferences.split('\n').map((preference) => (
                        <span key={preference}>
                          {preference}
                          <br />
                        </span>
                      ))}
                    </StyledServicePreferences>
                  </>
                )}

                <Divider margin={['M', 0, 'L', 0]} />
              </Grid.Item>

              {importantPoints.length > 0 && (
                <Grid.Item>
                  <StyledBulletSeparator>
                    {importantPoints.map(spanOf)}
                  </StyledBulletSeparator>
                </Grid.Item>
              )}
              <Grid.Item>
                <CriticalNotes />
              </Grid.Item>
              <Grid.Item>
                <CaseSummary funeral={initialValues} />
              </Grid.Item>
              <CaseOwners handleSave={handleSubmit} />
              <Provider
                handleSave={handleSubmit}
                dispatch={dispatch}
                isFactFindCompleted={isFactFindCompleted}
                isFormsSentForSignature={isFormsSentForSignature}
                isDignitySelected={isDignitySelected}
              />
              <Celebrant handleSave={handleSubmit} />
              <Grid.Item span={12}>
                <Difficult handleSave={handleSubmit} />
              </Grid.Item>
              <Grid.Item span={12}>
                <Divider margin={['M', 0, 'L', 0]} />

                <ImportantDates handleSave={handleSubmit} />
                <Divider margin={['L', 0]} />

                <Lost events={events} funeralId={match.params.id} />
              </Grid.Item>
            </Grid>
          </Wrapper>
        </Form>
      )}
    </Formik>
  )
}

export default CaseOverview
