import { Grid, H } from '@farewill/ui'
import { useFormikContext } from 'formik'

import RadioGroup from 'components/form/radio-group'
import SectionStatus from 'components/section-status'

import { disputesQuestions, FormikValues, outcomeForDisputes } from './helpers'

type QuestionProps = {
  question: {
    label: string
    name: string
    options: Array<{
      label: string
      value: string
      tooltipContent?: string
    }>
  }
  handleSave: (data: { name: string; value: string }) => void
}

const Question = ({
  question,
  handleSave,
}: QuestionProps): React.ReactElement => {
  return (
    <Grid.Item>
      <RadioGroup
        label={question.label}
        name={question.name}
        options={question.options}
        handleSave={handleSave}
      />
    </Grid.Item>
  )
}

const Disputes = (): React.ReactElement => {
  const { values, handleSubmit } = useFormikContext<FormikValues>()
  const outcome = outcomeForDisputes({ values })

  return (
    <Grid margin={['L', 0]}>
      <Grid.Item span={6}>
        <H size="S">Disputes</H>
        {disputesQuestions.map((question) => (
          <Question
            key={question.name}
            question={question}
            handleSave={() => handleSubmit()}
          />
        ))}
      </Grid.Item>

      <Grid.Item span={5} startColumn={8}>
        <SectionStatus complete={outcome.complete} heading={outcome.heading}>
          {outcome.message}
        </SectionStatus>
      </Grid.Item>
    </Grid>
  )
}

export default Disputes
