import styled, { css } from 'styled-components'
import {
  AttachmentIcon,
  Button,
  DeleteIcon,
  ExternalGoogleDriveIcon,
  P,
  PencilIcon,
  Wrapper,
} from '@farewill/ui'
import { COLOR, FONT, GTR } from '@farewill/ui/tokens'
import { Link } from 'react-router-dom'
import groupBy from 'lodash/groupBy'
import sortBy from 'lodash/sortBy'
import { useDispatch } from 'react-redux'

import { showModal } from 'state/actions'
import { formatToHuman } from 'utils/helpers'
import DeleteModal from './delete-modal'
import { useDocumentsContext } from './context'
import { DOCUMENT_CATEGORIES } from 'utils/enums'
import ActivityTimestamp from 'components/activity-timestamp'

const StyledDocumentRow = styled.ul`
  align-items: center;
  display: grid;
  font-size: ${FONT.SIZE.XS};
  grid-template-columns: 60px 1fr 160px;
  margin-bottom: ${GTR.XS};
  padding: ${GTR.XS} 0;
  width: 100%;
`

const StyledIcon = styled.div`
  background-color: white;
  border-radius: 3px;
  border: 2px solid ${COLOR.GREY.LIGHT};
  height: 46px;
  text-decoration: none;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:not(:first-child) {
    left: 3px;
    position: absolute;
    top: -3px;
    z-index: -1;
  }
`

const StyledIconWrapper = styled.div`
  position: relative;
`

const documentLinkStyles = css`
  display: inline-block;
  font-size: ${FONT.SIZE.M};
  line-height: 1;
  margin-bottom: ${GTR.XXS};
`

const StyledDocumentLink = styled(Link)`
  ${documentLinkStyles}
`

const StyledExternalDocumentLink = styled.a`
  ${documentLinkStyles}
`

const StyledActions = styled(Wrapper)`
  display: flex;
  justify-content: space-between;
`

const StyledActionButton = styled(Button)`
  font-size: ${FONT.SIZE.XS};
  font-weight: ${FONT.WEIGHT.REGULAR};

  span {
    display: inline-block;
    margin-right: ${GTR.XXS};
  }
`

const StyledActionText = styled.span`
  text-decoration: underline;
  margin-left: ${GTR.XXS};
`

const StyledActivityTimestamp = styled(ActivityTimestamp)`
  color: ${COLOR.GREY.DARK};
  font-size: ${FONT.SIZE.XS};
`

const CATEGORIES = DOCUMENT_CATEGORIES.map((category) => category.value)

const Document = ({ data, setDocumentIdForEdit }) => {
  const dispatch = useDispatch()
  const { isDeletingDocument, deleteDocument } = useDocumentsContext()
  const { id, attributes } = data

  const onDeleteClick = () => {
    dispatch(
      showModal({
        component: DeleteModal,
        headingText: 'Delete document',
        config: {
          documentId: id,
          title: attributes.title,
          isDeletingDocument,
          deleteDocument,
        },
      })
    )
  }

  const onEditClick = () => {
    setDocumentIdForEdit(data.id)
    window.scrollTo(0, 0)
  }

  const isGoogleDriveDoc = attributes.files[0]?.source === 'google_drive'

  return (
    <StyledDocumentRow key={id}>
      <StyledIconWrapper>
        {attributes.files?.length > 1 && (
          <StyledIcon>
            <AttachmentIcon color={COLOR.GREY.LIGHT} />
          </StyledIcon>
        )}
        {isGoogleDriveDoc ? (
          <StyledIcon>
            <ExternalGoogleDriveIcon color={COLOR.GREY.LIGHT} />
          </StyledIcon>
        ) : (
          <StyledIcon>
            <AttachmentIcon color={COLOR.GREY.LIGHT} />
          </StyledIcon>
        )}
      </StyledIconWrapper>
      <div>
        {isGoogleDriveDoc && (
          <StyledExternalDocumentLink
            href={attributes.files[0].url}
            target="_blank"
            rel="noreferrer noopener"
          >
            {attributes.title}
          </StyledExternalDocumentLink>
        )}
        {!isGoogleDriveDoc && (
          <StyledDocumentLink
            to={`/probate/cases/${attributes.probateCaseId}/documents/${id}`}
          >
            {attributes.title}
          </StyledDocumentLink>
        )}
        <div>
          Added <StyledActivityTimestamp datetime={attributes.createdAt} />
        </div>
      </div>

      <StyledActions>
        <StyledActionButton type="button" onClick={onEditClick}>
          <PencilIcon size="S" />
          <StyledActionText>Edit</StyledActionText>
        </StyledActionButton>

        <StyledActionButton type="button" onClick={onDeleteClick}>
          <DeleteIcon size="S" />
          <StyledActionText>Delete</StyledActionText>
        </StyledActionButton>
      </StyledActions>
    </StyledDocumentRow>
  )
}

const List = ({ documents, isLoading, setDocumentIdForEdit }) => {
  const groupedDocuments = groupBy(documents, 'attributes.category')

  if (isLoading) {
    return (
      <Wrapper>
        <P>Loading documents...</P>
      </Wrapper>
    )
  }

  if (documents.length === 0) {
    return (
      <Wrapper>
        <P>No documents have been uploaded yet</P>
      </Wrapper>
    )
  }

  return (
    <div>
      {CATEGORIES.map((category) => {
        if (!groupedDocuments[category]) return null

        const sortedDocuments = sortBy(
          groupedDocuments[category],
          (doc) => -doc.id
        )

        return (
          <div key={category}>
            <P size="L" margin={['L', 0, 'S']}>
              {formatToHuman(category)}
            </P>
            {sortedDocuments.map((document) => (
              <Document
                key={document.id}
                data={document}
                setDocumentIdForEdit={setDocumentIdForEdit}
              />
            ))}
          </div>
        )
      })}
    </div>
  )
}

export default List
