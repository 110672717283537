import { P } from '@farewill/ui'
import SelectInput from 'components/form/select-input'
import { SelectOptions } from 'lib/types/common'
import { AdminUserSelectOptions } from 'routes/case-list/types'
import { WILL_GRADE_OPTIONS } from 'utils/enums'
import FilterWrapper from '../shared/filter-wrapper'
import CaseFeatureFilters from '../shared/case-feature-filters'
import DateRangeFilter from '../shared/date-range-filter'
import CharityFilter from './charity-filter'

type Props = {
  product: string
  isMyCasesView: boolean
  isWillDraftingView: boolean
  adminUserOptions: AdminUserSelectOptions
  statusOptions: SelectOptions
  nextStageOptions: SelectOptions
}

const WillFilters = ({
  product,
  isMyCasesView,
  isWillDraftingView,
  adminUserOptions,
  statusOptions,
  nextStageOptions,
}: Props): React.ReactElement => (
  <>
    {!isMyCasesView && (
      <>
        <FilterWrapper>
          <P strong margin={[0, 0, 'XS']} size="S">
            Case owner
          </P>
          <SelectInput
            options={adminUserOptions}
            name="caseOwnerId"
            isMulti
            small
          />
        </FilterWrapper>
        <FilterWrapper>
          <P strong margin={[0, 0, 'XS']} size="S">
            Case drafter
          </P>
          <SelectInput
            options={adminUserOptions}
            name="drafterId"
            isMulti
            small
          />
        </FilterWrapper>
      </>
    )}

    {statusOptions && (
      <FilterWrapper>
        <P size="S" strong>
          Case status
        </P>
        <SelectInput options={statusOptions} name="status" isMulti small />
      </FilterWrapper>
    )}

    {isWillDraftingView && (
      <FilterWrapper>
        <P strong margin={[0, 0, 'XS']} size="S">
          Will grade
        </P>
        <SelectInput options={WILL_GRADE_OPTIONS} name="grade" isMulti small />
      </FilterWrapper>
    )}

    <FilterWrapper>
      <CaseFeatureFilters product={product} />
    </FilterWrapper>

    {nextStageOptions && !isWillDraftingView && (
      <FilterWrapper>
        <P strong margin={[0, 0, 'XS']} size="S">
          Current stage
        </P>

        <SelectInput
          name="nextStage"
          isMulti
          options={nextStageOptions}
          small
        />
      </FilterWrapper>
    )}

    <FilterWrapper>
      <P strong size="S">
        Next task due
      </P>
      <DateRangeFilter name="nextTaskDueOn" />
    </FilterWrapper>

    <CharityFilter />
  </>
)

export default WillFilters
