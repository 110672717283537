import groupBy from 'lodash/groupBy'
import lowerCase from 'lodash/lowerCase'
import moment from 'moment'
import { ReactElement } from 'react-markdown/lib/react-markdown'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { Button, P, Wrapper } from '@farewill/ui'
import { BORDER, COLOR, FONT, GTR } from '@farewill/ui/tokens'

import BotIcon from 'components/bot-icon'
import { useTasksContext } from 'components/tasks-panel/context'
import { EMAIL_STATUSES } from 'utils/enums'
import { formatToHuman } from 'utils/helpers'
import { formatDate } from 'lib/formatting/dates'
import { Email, EmailTrigger } from 'lib/models/email'
import { Task } from 'lib/models/task'

import { useActivityContext } from './context'

const StyledWrapper = styled(Wrapper)`
  background-color: ${COLOR.WHITE};
  border: 2px solid ${COLOR.STATE.SUCCESS};
  margin-bottom: ${GTR.L};
  padding: ${GTR.M};
  border-radius: ${BORDER.RADIUS.S};
  display: flex;
`

const StyledP = styled(P)`
  font-size: ${FONT.SIZE.S};
`

const StyledGreyP = styled(StyledP)`
  color: ${COLOR.GREY.MEDIUM};
`

const StyledButton = styled(Button.Primary)`
  && {
    padding-top: ${GTR.XS};
    padding-bottom: ${GTR.XS};
  }
`

const getRepeatCycle = (trigger: EmailTrigger): string => {
  const durationInDays = moment.duration(trigger.repeatCycle).days()
  return durationInDays === 7 ? 'weekly' : `every ${durationInDays} days`
}

const getTaskByTrigger = (
  tasks: Task[],
  triggerTask: string
): Task | undefined => {
  return tasks.find((task) => task.attributes.coreTask === triggerTask)
}

const isComplete = (task: Task) => !!task?.attributes?.completedAt

const ScheduledEmail = ({ email }: { email: Email }): ReactElement | null => {
  const { tasks } = useTasksContext()
  const { actionEmail, isActioningEmail } = useActivityContext()
  const { probateCaseId, taskId, trigger, scheduledToSendAt, status } =
    email.attributes

  if (tasks.length === 0) return <P>Loading...</P>

  const startTask = getTaskByTrigger(tasks, trigger.startCoreTask)
  const stopTask = getTaskByTrigger(tasks, trigger.stopCoreTask)

  if (!isComplete(startTask as Task) || isComplete(stopTask as Task))
    return null

  const isCancelled = status === EMAIL_STATUSES.CANCELLED

  const handleStopEmailsClick = () => {
    actionEmail(email.id, 'cancel')
  }

  return (
    <StyledWrapper key={email.id}>
      <BotIcon />
      <Wrapper margin={[0, 0, 0, 'M']}>
        <StyledP margin={[0, 0, 'XS']}>
          <b>Farewill Bot</b> is scheduled to send{' '}
          <Link to={`/probate/cases/${probateCaseId}/activity#task-${taskId}`}>
            {formatToHuman(trigger.template.name)}
          </Link>
        </StyledP>
        <StyledGreyP>
          Started from: {formatDate((startTask as Task).attributes.completedAt)}
        </StyledGreyP>
        {isCancelled ? (
          <StyledGreyP>Emails stopped</StyledGreyP>
        ) : (
          <>
            <StyledGreyP>
              Next email: {formatDate(scheduledToSendAt)},{' '}
              {getRepeatCycle(trigger)} until {lowerCase(trigger.stopCoreTask)}
            </StyledGreyP>
            <StyledButton
              type="button"
              onClick={handleStopEmailsClick}
              loading={isActioningEmail}
            >
              Stop emails
            </StyledButton>
          </>
        )}
      </Wrapper>
    </StyledWrapper>
  )
}

const ScheduledEmailsSummary = (): ReactElement => {
  const { emails } = useActivityContext()

  const scheduledEmails = emails.filter((email: Email) =>
    [EMAIL_STATUSES.SCHEDULED, EMAIL_STATUSES.CANCELLED].includes(
      email.attributes.status
    )
  )

  // Grouping by emailTriggerId ensures we only display the most recent
  // scheduled or cancelled email per trigger, as there may be multiple
  // cancelled emails per trigger.
  const groupedEmails = groupBy(scheduledEmails, 'attributes.emailTriggerId')

  const groupedEmailsList = Object.values(groupedEmails).map((group) => {
    return <ScheduledEmail email={group[0]} key={group[0].id} />
  })

  return <>{groupedEmailsList}</>
}

export default ScheduledEmailsSummary
