import get from 'lodash/get'

const FETCH_AIRCALL_EVENTS_FOR_CUSTOMER = 'FETCH_AIRCALL_EVENTS_FOR_CUSTOMER'

export default function aircallEvents(
  state = [],
  action: { type?: string } = {}
) {
  switch (action.type) {
    case FETCH_AIRCALL_EVENTS_FOR_CUSTOMER + '_FULFILLED':
      return get(action, 'payload.data.data', [])

    case FETCH_AIRCALL_EVENTS_FOR_CUSTOMER + '_PENDING':
    case FETCH_AIRCALL_EVENTS_FOR_CUSTOMER + '_REJECTED':
      return []

    default:
      return state
  }
}
