import Table from 'components/table'

interface RowProps {
  label: string
  value: React.ReactElement | string | null
}

const Row = ({ label, value }: RowProps): React.ReactElement => (
  <Table.Row gridTemplateCols="1fr 2fr">
    <Table.Col strong>{label}</Table.Col>
    <Table.Col>{value}</Table.Col>
  </Table.Row>
)
export default Row
