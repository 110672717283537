import { P, Wrapper } from '@farewill/ui'
import { scrollToDataId } from 'lib/window/scroll'
import { UseSectionsCompleteResponse } from './helpers/complete-sections/types'
import styled from 'styled-components'
import { GTR } from '@farewill/ui/tokens'

const StyledLink = styled.a`
  text-decoration: underline;
  cursor: pointer;
  display: block;
  padding: ${GTR.XXS} 0;
`

const IncompleteSections = ({
  completedSections,
}: {
  completedSections: Partial<UseSectionsCompleteResponse>
}) => {
  const { sectionsIncomplete } = completedSections
  return (
    <div>
      <P>To see a quote complete:</P>
      {sectionsIncomplete && (
        <ul>
          {sectionsIncomplete.map((section) => (
            <li key={`section-${section.name}`}>
              <StyledLink onClick={() => scrollToDataId(section.scrollId)}>
                {section.name}
              </StyledLink>
              {(section.subSections?.subSectionsIncomplete?.length || 0) >
                0 && (
                <Wrapper tag="ul" margin={[0, 0, 0, 'S']}>
                  {section.subSections?.subSectionsIncomplete.map(
                    (subSection) => (
                      <li key={`sub-section-${subSection.name}`}>
                        <StyledLink
                          onClick={() => scrollToDataId(subSection.scrollId)}
                        >
                          {subSection.name}
                        </StyledLink>
                      </li>
                    )
                  )}
                </Wrapper>
              )}
            </li>
          ))}
        </ul>
      )}
    </div>
  )
}

export default IncompleteSections
