import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Button } from '@farewill/ui'
import { COLOR, FONT } from '@farewill/ui/tokens'
import { useDispatch, useSelector } from 'react-redux'

import { getUserIdFromToken } from 'lib/authentication/token'
import { showModal } from 'state/actions'

import DeleteCommentModal from './delete-comment-modal'
import { useActivityContext } from './context'

const StyledButton = styled(Button)`
  font-size: ${FONT.SIZE.S};
  font-weight: ${FONT.WEIGHT.REGULAR};
  color: ${COLOR.GREY.MEDIUM};

  &:after {
    content: none;
  }
`

const StyledBullet = styled.span`
  color: ${COLOR.GREY.LIGHT};
`

const Bullet = () => <StyledBullet>&bull;</StyledBullet>

const CommentActions = ({
  adminUserId,
  isDeletable,
  commentId,
  setIsEditMode,
  queryParams,
}) => {
  const dispatch = useDispatch()
  const loggedInUserId = useSelector((state) => getUserIdFromToken(state.token))
  const isCommentAuthor = loggedInUserId === adminUserId
  const { fetchComments, deleteComment } = useActivityContext()

  const handleDeleteClick = () => {
    dispatch(
      showModal({
        component: DeleteCommentModal,
        headingText: 'Delete this comment?',
        config: {
          deleteComment: async () => {
            await deleteComment(commentId)
            return fetchComments({ queryParams })
          },
        },
      })
    )
  }

  if (!isCommentAuthor) return null

  return (
    <>
      {' '}
      <Bullet />{' '}
      <StyledButton
        type="button"
        onClick={() => setTimeout(() => setIsEditMode(true))}
      >
        Edit
      </StyledButton>{' '}
      {isDeletable && (
        <>
          <Bullet />{' '}
          <StyledButton type="button" onClick={handleDeleteClick}>
            Delete
          </StyledButton>
        </>
      )}
    </>
  )
}

CommentActions.propTypes = {
  adminUserId: PropTypes.number.isRequired,
  isDeletable: PropTypes.bool.isRequired,
  commentId: PropTypes.number.isRequired,
  setIsEditMode: PropTypes.func.isRequired,
  queryParams: PropTypes.shape({
    probateCaseId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    funeralId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    willCaseId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    taskId: PropTypes.number,
  }).isRequired,
}

export default CommentActions
