import { Button, Grid, H } from '@farewill/ui'
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'

import { showModal } from 'state/actions'
import { findEvent, getLabel } from 'utils/helpers'
import { CASE_LOST_OPTIONS, CASE_DECLINED_OPTIONS } from 'utils/enums'

import LostOrDeclinedModal from './lost-or-declined-modal'

const getReasonText = (reason) => {
  return reason ? `for reason: ${reason}` : ''
}

const getButtonText = ({ caseLostEvent, caseDeclinedEvent }) => {
  if (caseLostEvent) {
    const caseLostReason = caseLostEvent.attributes.metadata?.caseLostReason
    const reason = getLabel(CASE_LOST_OPTIONS, caseLostReason)
    return `Case lost ${getReasonText(reason)}`
  }

  if (caseDeclinedEvent) {
    const caseDeclinedReason =
      caseDeclinedEvent.attributes.metadata?.caseDeclinedReason
    const reason = getLabel(CASE_DECLINED_OPTIONS, caseDeclinedReason)
    return `Case declined ${getReasonText(reason)}`
  }

  return 'Mark as lost or declined'
}

const LostOrDeclined = () => {
  const dispatch = useDispatch()
  const events = useSelector((state) => state.productEvents)
  const caseLostEvent = findEvent(events, 'case_lost')
  const caseDeclinedEvent = findEvent(events, 'case_declined')
  const { id: probateCaseId } = useParams()

  return (
    <>
      <Grid.Item>
        <H size="S">Lost / declined</H>
        <Button.Underline
          flush
          type="button"
          onClick={() =>
            dispatch(
              showModal({
                component: LostOrDeclinedModal,
                headingText: 'Mark as lost or declined',
                config: { probateCaseId },
              })
            )
          }
        >
          {getButtonText({ caseLostEvent, caseDeclinedEvent })}
        </Button.Underline>
      </Grid.Item>
    </>
  )
}

export default LostOrDeclined
