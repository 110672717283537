import styled from 'styled-components'
import { ErrorMessage } from 'formik'
import { FONT, GTR, COLOR } from '@farewill/ui/tokens'

const StyledErrorMessage = styled.div`
  margin-top: ${GTR.XS};
  color: ${COLOR.STATE.ERROR};
  font-size: ${FONT.SIZE.S};
`

const Error = ({ name }: { name: string }): React.ReactElement => (
  <ErrorMessage name={name}>
    {(msg) => <StyledErrorMessage>{msg}</StyledErrorMessage>}
  </ErrorMessage>
)

export default Error
