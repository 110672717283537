import { P, Wrapper } from '@farewill/ui'
import { GTR } from '@farewill/ui/tokens'
import styled from 'styled-components'

import AdminIcon from 'components/admin-icon'
import ActivityTimestamp from 'components/activity-timestamp'
import { CaseStatusTransition as CaseStatusTransitionType } from 'lib/models/case-status-transition'
import { getReasonText } from 'routes/will-case/case-details/case-status/helpers'

const StyledWrapper = styled(Wrapper)`
  display: flex;
  align-items: center;
  margin-bottom: ${GTR.L};
`
type Props = {
  caseStatusTransition: CaseStatusTransitionType
}

const CaseStatusTransition = ({
  caseStatusTransition,
}: Props): React.ReactElement => {
  const { createdAt, adminUser, fromStatus, toStatus } =
    caseStatusTransition.attributes

  return (
    <StyledWrapper>
      <AdminIcon user={adminUser} size="L" />
      <Wrapper>
        <P margin={0} size="S">
          <strong>{adminUser.name}</strong> updated the case status from{' '}
          {fromStatus} to <strong>{toStatus}</strong> with the reason{' '}
          <i>"{getReasonText(caseStatusTransition.attributes)}"</i>.
        </P>
        <ActivityTimestamp datetime={createdAt} />
      </Wrapper>
    </StyledWrapper>
  )
}

export default CaseStatusTransition
