import { css } from 'styled-components'
import { COLOR, GTR, BORDER } from '@farewill/ui/tokens'

export const input = css`
  width: 100%;
  background-color: ${COLOR.WHITE};
  padding: ${GTR.S};
  border-radius: ${BORDER.RADIUS.S};
  border: 2px solid ${COLOR.GREY.LIGHT};
  outline: 0;
  color: ${COLOR.BLACK};
  transition: border-color 0.2s ease-in-out;

  &[type='date'] {
    padding-top: ${parseInt(GTR.S) - 2 + 'px'};
    padding-bottom: ${parseInt(GTR.S) - 2 + 'px'};
  }

  ${(props) =>
    props.disabled &&
    css`
      background-color: ${COLOR.GREY.LIGHT};
    `}

  ${(props) =>
    props.component === 'textarea' &&
    css`
      resize: none;
    `}

  ${(props) =>
    props.component === 'select' &&
    css`
      appearance: none;
      height: 63px;
      cursor: pointer;
      background-image: url('/images/down-arrow.png');
      background-size: 15px;
      background-position: right ${GTR.S} top 50%;
      background-repeat: no-repeat;
    `}

    ${(props) =>
    props.small &&
    `
        padding: 10px ${GTR.S};
      `}

  ${({ $error }) => $error && `border-color: ${COLOR.STATE.ERROR};`}

  &:focus {
    border-color: ${COLOR.GREY.MEDIUM};
    ${({ $error }) => $error && `border-color: ${COLOR.STATE.ERROR};`}
  }
`
