import { Button, Grid, P } from '@farewill/ui'
import { GTR } from '@farewill/ui/tokens'
import { useFormikContext } from 'formik'
import get from 'lodash/get'

import store from 'state/create-store'
import { showModal } from 'state/actions'
import namespace from 'lib/formik/namespace'
import {
  PROBATE_ESTATE_ITEM_TYPES,
  ProbateEstateItem,
} from 'lib/models/probate-estate'
import EstateCard from 'components/estate-card'
import InputFloating from 'components/form/input-floating'
import CircularRadioGroup from 'components/form/circular-radio-group'
import NotesInput from 'components/notes-input'
import DeleteModal from 'routes/lead/probate-form/delete-modal'
import TextSpacer from 'routes/lead/probate-form/text-spacer'
import withForm from 'routes/lead/probate-form/with-form'
import { getItemsTotals } from 'routes/probate-estate/fact-find/get-totals'
import {
  PROBATE_LEAD_FORM_SECTIONS,
  PROBATE_LEAD_FORM_VALUES,
} from 'routes/lead/probate-form/constants'

import { HandleSaveProps, ItemProps } from '../assets/types'
import { DESCRIPTION } from 'routes/probate-estate/fact-find/item-field-constants'
import { useItems } from '../helpers/items-context'
import { useProbateEstate } from '../helpers/probate-estate-context'
import SubsectionHeading from '../subsection-heading'

const Item = ({ item, index, listLength }: ItemProps) => {
  const {
    OWNERSHIP,
    JOINT_OWNER_NAME,
    DECEASED_SHARE_OF_JOINT_OWNERSHIP,
    NOTES,
  } = PROBATE_LEAD_FORM_VALUES
  const { withNamespace, withoutNamespace } = namespace(`utilities.${item.id}.`)
  const { values } = useFormikContext()
  const { updateItem, deleteItem } = useItems()

  const handleSave = ({ name, value }: HandleSaveProps) => {
    updateItem(
      {
        id: item.id,
        attributes: { [withoutNamespace(name)]: value || null },
      },
      PROBATE_ESTATE_ITEM_TYPES.UTILITY_OR_OTHER_DEBT
    )
  }

  const onTrashClick = () => {
    store.dispatch(
      showModal({
        component: DeleteModal,
        headingText: 'Delete utility or other debt',
        config: {
          deleteCallback: () => {
            deleteItem(item.id)
          },
          type: 'utility or debt',
          resourceId: item.id,
        },
      })
    )
  }

  return (
    <EstateCard
      onTrashClick={onTrashClick}
      listLength={listLength}
      listPosition={index + 1}
      key={item.id}
      type="Utilities and other debts"
    >
      <Grid>
        <Grid.Item span={9}>
          <InputFloating
            name={withNamespace(DESCRIPTION)}
            label="Description"
            handleSave={handleSave}
          />
        </Grid.Item>
        <Grid.Item span={12}>
          <CircularRadioGroup
            name={withNamespace(OWNERSHIP)}
            label="Ownership"
            options={[
              { label: 'Not asked', value: '' },
              { label: 'Sole', value: 'sole' },
              { label: 'Joint', value: 'joint' },
            ]}
            handleSave={handleSave}
            inline
          />
        </Grid.Item>
        <Grid.Item span={7}>
          <InputFloating
            name={withNamespace(PROBATE_LEAD_FORM_VALUES.VALUE)}
            label="Total value of the debt"
            hint="For joint liabilities, the £ value of their share will be calculated automatically."
            handleSave={handleSave}
            isCurrency
          />
        </Grid.Item>

        <Grid.Item span={12}>
          <Grid>
            {get(values, withNamespace(OWNERSHIP)) === 'joint' && (
              <>
                <Grid.Item span={6}>
                  <InputFloating
                    name={withNamespace(JOINT_OWNER_NAME)}
                    label="Name of joint owner"
                    handleSave={handleSave}
                    hint="If more than one, leave a note below"
                  />
                </Grid.Item>
                <Grid.Item span={6}>
                  <InputFloating
                    name={withNamespace(DECEASED_SHARE_OF_JOINT_OWNERSHIP)}
                    label="Share owned by person who's died (%)"
                    handleSave={handleSave}
                  />
                </Grid.Item>
              </>
            )}
          </Grid>
        </Grid.Item>
        <Grid.Item>
          <NotesInput name={withNamespace(NOTES)} handleSave={handleSave} />
        </Grid.Item>
      </Grid>
    </EstateCard>
  )
}

const ItemWithForm = withForm(Item)

const Utilities = ({ isComplete }: { isComplete: boolean }) => {
  const { UTILITIES_AND_OTHER_DEBTS_SECTION_ACTIVE } =
    PROBATE_LEAD_FORM_SECTIONS
  const { saveProbateEstateField } = useProbateEstate()
  const { groupedItems, createItem } = useItems()
  const { utilityOrOtherDebt: utilitiesItems = [] } = groupedItems
  const { total, sole, joint, unspecified } = getItemsTotals(utilitiesItems)

  return (
    <SubsectionHeading
      disabled={utilitiesItems.length > 0}
      name={UTILITIES_AND_OTHER_DEBTS_SECTION_ACTIVE}
      onToggle={saveProbateEstateField}
      scrollId="7.2"
      title="Utilities and other debts"
      isComplete={isComplete}
    >
      <Grid.Item span={11}>
        <P size="S" data-testid="items-value-summary">
          Total: {total} <TextSpacer /> Sole: {sole} <TextSpacer /> Joint share:{' '}
          {joint} <TextSpacer /> Unspecified: {unspecified}
        </P>
        <P>
          This could include pension overpayments, loans or cars bought under
          hire-purchase
        </P>

        {utilitiesItems.map((item: ProbateEstateItem, index: number) => (
          <ItemWithForm
            enableReinitialize={false}
            index={index}
            initialValues={{ utilities: { [item.id]: item.attributes } }}
            item={item}
            key={item.id}
            listLength={utilitiesItems.length}
          />
        ))}
      </Grid.Item>
      <Grid.Item>
        <Button.Secondary
          style={{ paddingTop: GTR.XXS, paddingBottom: GTR.XXS }}
          type="button"
          onClick={() => {
            createItem({
              itemType: PROBATE_ESTATE_ITEM_TYPES.UTILITY_OR_OTHER_DEBT,
            })
          }}
        >
          Add utility / other debt
        </Button.Secondary>
      </Grid.Item>
    </SubsectionHeading>
  )
}

export default Utilities
