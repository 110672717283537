import styled from 'styled-components'
import { Wrapper } from '@farewill/ui'
import { COLOR, GTR } from '@farewill/ui/tokens'

import { RESOURCES, useFetchResource } from 'api'
import { Uppercase } from 'components/styled/uppercase'
import TelephoneNumberLink from 'components/telephone-number-link'
import UserContactDetailWithCopyToClipboard from 'components/user-contact-detail-with-copy-to-clipboard'
import { Contact } from 'lib/models/contact'
import EmailLink from 'routes/email-link'

export type TpsResult = {
  isOnTps: boolean
}

const StyledContactInfo = styled(Wrapper)`
  display: flex;
  gap: ${GTR.XS};
`

const StyledWrapper = styled(Wrapper)`
  display: flex;
  flex-direction: column;
`

const PhoneAndEmailContacts = ({
  contact,
  contactId,
  withLink,
}: {
  contact: Contact | { attributes: Contact }
  contactId?: number
  withLink?: boolean
}) => {
  const contactData = 'attributes' in contact ? contact.attributes : contact
  contactId = contactId || contactData.id
  const { phoneNumber, doNotContact, email } = contactData

  const tpsResultQuery = useFetchResource<TpsResult>(
    {
      id: Number(phoneNumber),
      resource: RESOURCES.TPS,
    },
    {
      enabled: Boolean(phoneNumber && !doNotContact),
    }
  ) as { data: TpsResult; isLoading: boolean; isIdle: boolean }

  const tpsResult = tpsResultQuery?.data

  return (
    <>
      <StyledWrapper>
        <StyledContactInfo>
          <TelephoneNumberLink
            contact={contact}
            withLink={withLink}
            showPhoneAsDoNotContact={doNotContact}
          />
          &bull;
          <UserContactDetailWithCopyToClipboard label="Email" value={email}>
            <Wrapper>
              <EmailLink
                email={email}
                contactIdForEditingEmail={withLink ? contactId : undefined}
              />
            </Wrapper>
          </UserContactDetailWithCopyToClipboard>
        </StyledContactInfo>

        {doNotContact && (
          <Uppercase color={COLOR.STATE.ERROR}>Do not contact</Uppercase>
        )}

        {!doNotContact && tpsResult?.isOnTps && (
          <Uppercase color={COLOR.STATE.ERROR}>TPS Registered</Uppercase>
        )}
      </StyledWrapper>
    </>
  )
}

export default PhoneAndEmailContacts
