import PropTypes from 'prop-types'
import styled from 'styled-components'
import { AttachmentIcon, Button, Grid, Wrapper } from '@farewill/ui'
import { BORDER, COLOR, GTR, FONT } from '@farewill/ui/tokens'
import { Formik, Form } from 'formik'
import { DOCUMENT_CATEGORIES } from 'utils/enums'
import Input from 'components/form/input'
import SelectInput from 'components/form/select-input'
import { Uppercase } from 'components/styled/uppercase'
import { SortableContainer, SortableElement } from 'react-sortable-hoc'
import { getGoogleDriveFileIdFromUrl } from 'utils/helpers'

const StyledDocumentCard = styled(Wrapper)`
  box-shadow: ${BORDER.SHADOW.M};
  border-radius: ${BORDER.RADIUS.S};
  padding: ${GTR.M};
  background-color: ${COLOR.WHITE};

  &:not(:last-of-type) {
    margin-bottom: ${GTR.S};
  }
`

const StyledInput = styled(Input)`
  max-height: 59px;
`

const StyledButtonWrapper = styled(Grid)`
  align-items: center;
`

const StyledCancelButton = styled(Button)`
  font-weight: ${FONT.WEIGHT.REGULAR};
  text-decoration: underline;
  align-self: center;
  margin-top: ${GTR.XS};
  &:hover {
    text-decoration: underline;
  }
`

const StyledPreviewImage = styled.img`
  height: 140px;
  filter: drop-shadow(1px 1px 6px rgba(0, 0, 0, 0.25));
  border-radius: 4px;
  margin-right: ${GTR.M};
  margin-bottom: ${GTR.M};
`

const StyledNoPreviewTile = styled.span`
  display: flex;
  align-items: center;
  margin-bottom: ${GTR.M};
`

const StyledIcon = styled.div`
  border-radius: 3px;
  border: 2px solid ${COLOR.GREY.LIGHT};
  height: 46px;
  width: 32px;
  margin-right: ${GTR.S};
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`

const validate = (isGoogleDriveMode) => (values) => {
  const errors = {}

  if (!values.title) {
    errors.title = 'Title is required'
  }

  if (!values.category) {
    errors.category = 'Category is required'
  }

  if (isGoogleDriveMode && !getGoogleDriveFileIdFromUrl(values.url)) {
    errors.url = 'A valid Google Drive link is required'
  }

  return errors
}

const SortablePreviewTile = SortableElement(({ file, isSortable }) => {
  const style = isSortable ? { cursor: 'grab' } : {}

  if (file.type.startsWith('image/')) {
    return (
      <StyledPreviewImage
        key={file.name}
        src={file.preview}
        alt={file.name}
        style={style}
      />
    )
  }

  return (
    <StyledNoPreviewTile style={style}>
      <StyledIcon>
        <AttachmentIcon />
      </StyledIcon>
      {file.name}
    </StyledNoPreviewTile>
  )
})

const SortablePreviewList = SortableContainer(({ files }) => {
  return (
    <Wrapper margin={['M', 0, 0, 0]} noTrim>
      <Uppercase>{files.length > 1 ? 'Arrange Pages' : 'File'}</Uppercase>
      {files.map((file, index) => (
        <SortablePreviewTile
          key={file.name}
          index={index}
          file={file}
          isSortable={files.length > 1}
        />
      ))}
    </Wrapper>
  )
})

const DocumentCard = ({
  onCancel,
  onSubmit,
  isSubmitting,
  title,
  category,
  url,
  files,
  onFileOrderChanged,
  isEditMode,
  isGoogleDriveMode,
}) => {
  const initialValues = { title, category, url }
  const handleSortEnd = ({ oldIndex, newIndex }) => {
    onFileOrderChanged(oldIndex, newIndex)
  }

  return (
    <StyledDocumentCard>
      <Formik
        initialValues={initialValues}
        validate={validate(isGoogleDriveMode)}
        onSubmit={onSubmit}
      >
        <Form>
          <Wrapper margin={[0, 0, 'M', 0]} noTrim>
            <Grid>
              <Grid.Item span={12} spanFromL={6}>
                <StyledInput label="Title" name="title" />
              </Grid.Item>

              <Grid.Item span={12} spanFromL={6}>
                <SelectInput
                  label="Category"
                  name="category"
                  options={DOCUMENT_CATEGORIES}
                />
              </Grid.Item>
              {isGoogleDriveMode && (
                <Grid.Item>
                  <StyledInput
                    label="Google Drive link"
                    name="url"
                    disabled={isEditMode}
                  />
                </Grid.Item>
              )}
            </Grid>
          </Wrapper>
          {!isGoogleDriveMode && (
            <SortablePreviewList
              files={files}
              onSortEnd={handleSortEnd}
              axis="xy"
              shouldCancelStart={() => files.length < 2}
            />
          )}
          <StyledButtonWrapper>
            <Grid.Item span={12} spanFromL={4}>
              <Button.Primary type="submit" loading={isSubmitting}>
                {isEditMode ? 'Save document' : 'Attach document'}
              </Button.Primary>
            </Grid.Item>
            <Grid.Item span={12} spanFromL={3}>
              <StyledCancelButton type="button" onClick={onCancel}>
                Cancel
              </StyledCancelButton>
            </Grid.Item>
          </StyledButtonWrapper>
        </Form>
      </Formik>
    </StyledDocumentCard>
  )
}

DocumentCard.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onFileOrderChanged: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool,
  files: PropTypes.array,
  isEditMode: PropTypes.bool,
  isGoogleDriveMode: PropTypes.bool,
  title: PropTypes.string,
  category: PropTypes.string,
}

DocumentCard.defaultProps = {
  title: '',
  category: '',
  isSubmitting: false,
  files: [],
  isEditMode: false,
  isGoogleDriveMode: false,
}

export default DocumentCard
