import { Grid, P } from '@farewill/ui'
import { useFormikContext } from 'formik'

import { ProbateLeadAttributes, ProbateQuoteAttributes } from 'lib/models/lead'
import { CircularRadioGroup, Input, RadioGroup } from 'components/form'
import { PROBATE_THRESHOLD_LINK, YES_NO_OPTIONS } from 'utils/enums'

import SectionHeading from './section-heading'
import { showModal } from 'state/actions'
import store from 'state/create-store'
import MessageBox from 'components/message-box'
import DeleteModal from 'components/form/radio-group-delete-modal'

type ProbateRequiredQuestion = {
  name: string
  shortLabel?: string
  label?: string
  options?: typeof YES_NO_OPTIONS
  onClick?: (
    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    props: any,
    values: ProbateLeadAttributes
  ) => void
  showIf?: (values: ProbateLeadAttributes) => boolean
  type?: 'number' | 'radio'
  getConfirmationModalConfig?: ({
    value,
    values,
    setFieldValue,
    handleSubmit,
  }: {
    value: string
    values: ProbateLeadAttributes
    setFieldValue: (
      field: string,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      value: any,
      shouldValidate?: boolean | undefined
    ) => void
    handleSubmit: (e?: React.FormEvent<HTMLFormElement> | undefined) => void
  }) => {
    deleteCallback: () => void
    bodyText: JSX.Element
    buttonLabel: string
  }
}

export const QUESTIONS = [
  {
    name: 'quoteAttributes.customerAlreadyKnowsProbateRequired',
    shortLabel: 'Caller knows they need probate',
    label:
      'Has the caller been asked for either grant of probate or letters of administration?',
    options: YES_NO_OPTIONS,

    getConfirmationModalConfig: ({
      value,
      values,
      setFieldValue,
      handleSubmit,
    }) => ({
      component: DeleteModal,
      headingText: 'You are about to remove some information',
      config: {
        deleteCallback: () => {
          if (
            values.quoteAttributes.customerAlreadyKnowsProbateRequired ===
            'true'
          ) {
            setFieldValue('quoteAttributes.aboveAccountOrShareThreshold', '')
            setFieldValue('quoteAttributes.ownedProperty', '')
            setFieldValue('quoteAttributes.propertyOwnershipNeedsProbate', '')
          }
          setFieldValue(
            'quoteAttributes.customerAlreadyKnowsProbateRequired',
            value,
            false
          )
          handleSubmit()
        },
        bodyText: (
          <P>
            Changing the answer to this question will clear the answers to
            questions below. Do you want to continue?
          </P>
        ),
        buttonLabel: 'Continue',
      },
    }),
  },
  {
    name: 'quoteAttributes.aboveAccountOrShareThreshold',
    shortLabel: 'Had bank accounts/shares worth more than probate threshold',
    label: (
      <>
        Did they have any bank accounts or shares worth more than{' '}
        <a
          href={PROBATE_THRESHOLD_LINK}
          target="_blank"
          rel="noopener noreferrer"
        >
          the probate threshold?
        </a>
      </>
    ),
    options: YES_NO_OPTIONS,
    showIf: (values) =>
      values.quoteAttributes.customerAlreadyKnowsProbateRequired === 'false',
    getConfirmationModalConfig: ({
      value,
      values,
      setFieldValue,
      handleSubmit,
    }) => ({
      component: DeleteModal,
      headingText: 'You are about to remove some information',
      config: {
        deleteCallback: () => {
          if (values.quoteAttributes.aboveAccountOrShareThreshold === 'true') {
            setFieldValue('quoteAttributes.ownedProperty', '')
            setFieldValue('quoteAttributes.propertyOwnershipNeedsProbate', '')
          }
          setFieldValue(
            'quoteAttributes.aboveAccountOrShareThreshold',
            value,
            false
          )
          handleSubmit()
        },
        bodyText: (
          <P>
            Changing the answer to this question will clear the answers to
            questions below. Do you want to continue?
          </P>
        ),
        buttonLabel: 'Continue',
      },
    }),
  },
  {
    name: 'quoteAttributes.ownedProperty',
    shortLabel: 'Did they own properties',
    label: 'Did the person who died own any property?',
    options: YES_NO_OPTIONS,
    showIf: (values) =>
      values.quoteAttributes.customerAlreadyKnowsProbateRequired === 'false' &&
      values.quoteAttributes.aboveAccountOrShareThreshold === 'false',

    getConfirmationModalConfig: ({
      value,
      values,
      setFieldValue,
      handleSubmit,
    }) => ({
      component: DeleteModal,
      headingText: 'You are about to remove some information',
      config: {
        deleteCallback: () => {
          if (values.quoteAttributes.ownedProperty === 'true') {
            setFieldValue('quoteAttributes.propertyOwnershipNeedsProbate', '')
          }
          setFieldValue('quoteAttributes.ownedProperty', value, false)
          handleSubmit()
        },
        bodyText: (
          <P>
            Changing the answer to this question will clear the answers to
            questions below. Do you want to continue?
          </P>
        ),
        buttonLabel: 'Continue',
      },
    }),
  },
  {
    name: 'quoteAttributes.propertyOwnershipNeedsProbate',
    shortLabel: 'Type of property ownership',
    label: 'Are any of the properties owned solely, or as tenants in common?',
    options: YES_NO_OPTIONS,
    showIf: (values) =>
      values.quoteAttributes.customerAlreadyKnowsProbateRequired === 'false' &&
      values.quoteAttributes.aboveAccountOrShareThreshold === 'false' &&
      values.quoteAttributes.ownedProperty === 'true',
  },
] as ProbateRequiredQuestion[]

const Question = ({ question }: { question: ProbateRequiredQuestion }) => {
  const { handleSubmit, values, setFieldValue } =
    useFormikContext<ProbateLeadAttributes>()

  const { onClick, label, name, options, type, getConfirmationModalConfig } =
    question

  return (
    <Grid.Item>
      {options ? (
        options === YES_NO_OPTIONS ? (
          <RadioGroup
            label={label}
            name={name}
            options={options}
            handleSave={(value) => {
              onClick?.(setFieldValue, values)
              const nameParts = value.name.split('.') as Array<
                keyof ProbateQuoteAttributes
              >
              // If it's the first time answering the question or if the answer is Yes, save the answer without showing the confirmation modal
              if (
                !getConfirmationModalConfig ||
                (!!getConfirmationModalConfig &&
                  (values.quoteAttributes[nameParts[1]] === '' ||
                    values.quoteAttributes[nameParts[1]] === 'true'))
              ) {
                return handleSubmit()
              }
            }}
            handleChange={(value, setFieldValue) => {
              if (getConfirmationModalConfig) {
                const modalConfig = getConfirmationModalConfig({
                  value,
                  values,
                  setFieldValue,
                  handleSubmit,
                })
                store.dispatch(showModal(modalConfig))
              }
            }}
            confirmationRequired={!!getConfirmationModalConfig}
          />
        ) : (
          <CircularRadioGroup
            label={label}
            name={name}
            options={options}
            handleSave={() => {
              onClick?.(setFieldValue, values)
              return handleSubmit()
            }}
          />
        )
      ) : (
        <Input
          label={label}
          name={name}
          type={type}
          handleSave={() => {
            onClick?.(setFieldValue, values)
            return handleSubmit()
          }}
        />
      )}
    </Grid.Item>
  )
}

const showQuestion = (
  question: ProbateRequiredQuestion,
  values: ProbateLeadAttributes
) => {
  if (!question.showIf) return true
  return question.showIf(values)
}

/* Unfortunately, we use type "any" here as we don't yet have a type for the formatted object returned by formatValuesForFormik  */
/* eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any */
const getIsRequired = (values: any) => {
  const {
    customerAlreadyKnowsProbateRequired,
    aboveAccountOrShareThreshold,
    propertyOwnershipNeedsProbate,
  } = values.quoteAttributes

  return (
    customerAlreadyKnowsProbateRequired === 'true' ||
    aboveAccountOrShareThreshold === 'true' ||
    propertyOwnershipNeedsProbate === 'true'
  )
}
/* Unfortunately, we use type "any" here as we don't yet have a type for the formatted object returned by formatValuesForFormik  */
/* eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any */
const getIsNotRequired = (values: any) => {
  const {
    customerAlreadyKnowsProbateRequired,
    ownedProperty,
    aboveAccountOrShareThreshold,
    propertyOwnershipNeedsProbate,
  } = values.quoteAttributes

  return (
    customerAlreadyKnowsProbateRequired === 'false' &&
    aboveAccountOrShareThreshold === 'false' &&
    (ownedProperty === 'false' || propertyOwnershipNeedsProbate === 'false')
  )
}

const ProbateRequired = ({ isComplete }: { isComplete: boolean }) => {
  const { values } =
    /* Unfortunately, we use type "any" here as we don't yet have a type for the formatted object returned by formatValuesForFormik  */
    /* eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any */
    useFormikContext<ProbateLeadAttributes>()

  const isNotRequired = getIsNotRequired(values)
  const isRequired = getIsRequired(values)

  return (
    <Grid>
      <Grid.Item>
        <Grid id="probate-required">
          <SectionHeading
            title="4. Is probate needed?"
            scrollId="probate-required"
            isComplete={isComplete}
          />

          {QUESTIONS.map(
            (question, index) =>
              showQuestion(question, values) && (
                <Question key={index} question={question} />
              )
          )}
        </Grid>
      </Grid.Item>
      <Grid.Item span={10} maxWidthInColumns={4}>
        {isRequired && (
          <MessageBox bold success stretch>
            It looks like probate is needed.
          </MessageBox>
        )}
        {isNotRequired && (
          <MessageBox bold warning stretch>
            It looks like probate is not needed.
          </MessageBox>
        )}
      </Grid.Item>
    </Grid>
  )
}

export default ProbateRequired
