import { useEffect, useState } from 'react'
import DocumentCard from './document-card'
import { arrayMoveImmutable } from 'array-move'
import { useDocumentsContext } from './context'

const Edit = ({ document, setDocumentIdForEdit }) => {
  const [files, setFiles] = useState([])
  const { updateFile, updateDocument } = useDocumentsContext()

  const filesForPreview = files.map((file, index) => {
    return {
      name: `Page ${index + 1}`,
      preview: file.url,
      type: file.type,
    }
  })

  const isGoogleDriveDoc =
    document.attributes.files[0]?.source === 'google_drive'

  const handleCancel = () => {
    setDocumentIdForEdit(null)
  }

  const handleSubmit = async (values) => {
    if (!isGoogleDriveDoc) {
      await Promise.all(
        files.map((file, orderIndex) => updateFile(file.id, { orderIndex }))
      )
    }

    await updateDocument(document.id, {
      title: values.title,
      category: values.category,
    })

    setDocumentIdForEdit(null)
  }

  const handleFileOrderChanged = (oldIndex, newIndex) => {
    setFiles(arrayMoveImmutable(files, oldIndex, newIndex))
  }

  useEffect(() => {
    setFiles(document.attributes.files)
  }, [document])

  return (
    <DocumentCard
      title={document.attributes.title}
      category={document.attributes.category}
      url={isGoogleDriveDoc && document.attributes.files[0]?.url}
      files={filesForPreview}
      onFileOrderChanged={handleFileOrderChanged}
      onCancel={handleCancel}
      onSubmit={handleSubmit}
      isSubmitting={false}
      isEditMode
      isGoogleDriveMode={isGoogleDriveDoc}
    />
  )
}

export default Edit
