import React from 'react'
import { Wrapper } from '@farewill/ui'
import { GTR } from '@farewill/ui/tokens'

import MessageBox from 'components/message-box'

const Error = (): React.ReactElement => {
  return (
    <Wrapper margin={[0, 0, GTR.S]}>
      <MessageBox error>
        You need to complete all required fields before you can review and set
        up this new partner.
      </MessageBox>
    </Wrapper>
  )
}

export default Error
