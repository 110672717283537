import { Grid, H, P } from '@farewill/ui'
import { useFormikContext } from 'formik'

import { LpaLeadAttributes } from 'lib/models/lead'
import DeleteModal from 'components/form/radio-group-delete-modal'
import RadioGroup from 'components/form/radio-group'
import { Selected } from 'components/form/select-input/types'
import { AddressInput, Input, SelectInput } from 'components/form'
import store from 'state/create-store'
import { showModal } from 'state/actions'

import { allQuestions, partnerWantsLPA, relationshipStatus } from './questions'
import { RELATIONSHIP_STATUS_WITHOUT_PARTNER } from 'utils/enums'
import { LPA_CASE_QUOTE_TYPE } from 'routes/lpa-case/quote/constants'

const getConfirmationModalConfig = ({
  name,
  value,
  setFieldValue,
  handleSubmit,
}: {
  name: string
  value: string
  setFieldValue: (
    field: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    value: any,
    shouldValidate?: boolean | undefined
  ) => void
  handleSubmit: (e?: React.FormEvent<HTMLFormElement> | undefined) => void
}) => ({
  component: DeleteModal,
  headingText: 'You are about to remove some information',
  config: {
    deleteCallback: () => {
      const isRelationshipStatusSingle =
        name === relationshipStatus.name &&
        RELATIONSHIP_STATUS_WITHOUT_PARTNER.includes(value)
      const isLpaTypeSingle =
        name === partnerWantsLPA.name && value === LPA_CASE_QUOTE_TYPE.SINGLE

      if (isRelationshipStatusSingle || isLpaTypeSingle) {
        setFieldValue(`quoteAttributes.partner`, undefined)
      }
      if (isRelationshipStatusSingle) {
        setFieldValue(`quoteAttributes.lpaType`, undefined)
      }
      setFieldValue(name, value, false)
      handleSubmit()
    },
    bodyText: (
      <P>
        Changing the answer to this question will clear the partner details. Do
        you want to continue?
      </P>
    ),
    buttonLabel: 'Continue',
  },
})

const Diagnosis = () => {
  const { values, handleSubmit, setFieldValue } =
    useFormikContext<LpaLeadAttributes>()

  const onHandleSubmit = (value: { name: string; value: Selected }) => {
    /**
     * If the user selects a relationship status that is not with a partner,
     * we need to clear the lpaType as it's no longer relevant, and it impacts
     * the value of the quote.
     */
    if (value.name === relationshipStatus.name) {
      if (RELATIONSHIP_STATUS_WITHOUT_PARTNER.includes(value.value as string)) {
        setFieldValue('quoteAttributes.lpaType', null)
      }
    }
    setFieldValue(value.name, value.value)

    handleSubmit()
  }

  return (
    <Grid margin={['L', 0]} data-testid="lpa-lead-diagnosis">
      <Grid.Item span={5}>
        <H size="S">Diagnosis</H>
      </Grid.Item>

      {allQuestions.map((question, i) => {
        const { type, options, name, label, span, startRow } = question

        const isVisible = question?.isVisible
          ? question.isVisible(values)
          : true

        if (!isVisible) return null

        if (type === 'select' && options) {
          return (
            <Grid.Item key={name} spanFromM={span} startRow={startRow}>
              <SelectInput
                name={name}
                label={label}
                options={options}
                onChange={(data) => {
                  if (
                    data &&
                    'value' in data &&
                    values.quoteAttributes.lpaType === 'couple'
                  ) {
                    store.dispatch(
                      showModal(
                        getConfirmationModalConfig({
                          name: name,
                          value: data.value as string,
                          setFieldValue,
                          handleSubmit,
                        })
                      )
                    )
                  } else {
                    const value =
                      data && 'value' in data && (data.value as string)

                    if (value) {
                      onHandleSubmit({
                        name: name,
                        value: value,
                      })
                    }
                  }
                }}
              />
            </Grid.Item>
          )
        }

        if (type === 'text') {
          return (
            <Grid.Item key={name} spanFromM={span} startRow={startRow}>
              <Input name={name} label={label} handleSave={onHandleSubmit} />
            </Grid.Item>
          )
        }

        if (type === 'radio' && options) {
          return (
            <Grid.Item key={name} spanFromM={span} startRow={startRow}>
              <RadioGroup
                name={name}
                label={label}
                options={options}
                handleSave={(value) => {
                  // If confirmation not required, or if the current lpa type is not couple, we save without showing the modal
                  if (
                    !question.confirmationRequired ||
                    (!!question.confirmationRequired &&
                      values.quoteAttributes.lpaType !== 'couple')
                  ) {
                    return onHandleSubmit(value)
                  }
                }}
                handleChange={(value, setFieldValue) => {
                  if (values.quoteAttributes.lpaType === 'couple') {
                    store.dispatch(
                      showModal(
                        getConfirmationModalConfig({
                          name,
                          value,
                          setFieldValue,
                          handleSubmit,
                        })
                      )
                    )
                  }
                }}
                confirmationRequired={
                  typeof question.confirmationRequired === 'boolean'
                    ? question.confirmationRequired
                    : question.confirmationRequired?.(values)
                }
              />
            </Grid.Item>
          )
        }

        if (type === 'date') {
          return (
            <Grid.Item key={name} spanFromM={span} startRow={startRow}>
              <Input
                label={label}
                handleSave={() => handleSubmit()}
                name={name}
                type="date"
              />
            </Grid.Item>
          )
        }

        if (type === 'address') {
          return (
            <Grid.Item key={name} spanFromM={span} startRow={startRow}>
              <AddressInput
                name={name}
                label={label}
                onAddressChanged={(args) => {
                  setFieldValue(name, args)
                  handleSubmit()
                }}
                addressValues={values.quoteAttributes?.partner?.addressFields}
              />
            </Grid.Item>
          )
        }

        return null
      })}
    </Grid>
  )
}

export default Diagnosis
