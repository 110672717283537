import { ReactNode } from 'react'
import { Grid, P, Wrapper } from '@farewill/ui'
import { useFormikContext } from 'formik'

import { ProbateLeadAttributes, ProbateQuoteAttributes } from 'lib/models/lead'
import { MARRIED_OR_CIVIL_PARTNERSHIP, YES_NO_OPTIONS } from 'utils/enums'
import RadioGroup from 'components/form/radio-group'
import Input from 'components/form/input'
import InfoBox from 'components/styled/info-box'
import Tooltip from 'components/tooltip'
import ExternalLink from 'components/external-link'
import DeleteModal from 'components/form/radio-group-delete-modal'
import store from 'state/create-store'
import { showModal } from 'state/actions'
import { ProbateEstatePerson } from 'lib/models/probate-estate'
import CustomerIsExecutor from './probate-estate-people/customer-is-executor'
import CustomerIsAdministrator from './probate-estate-people/customer-is-administrator'
import willExecutors from './probate-estate-people/will-executors'
import { usePeople } from './helpers/people-context'
import IntestacyAdministrators from './probate-estate-people/intestacy-administrators'
import EstatePassing from './probate-estate-people/estate-passing'
import SectionHeading from './section-heading'
import { useProbateEstate } from './helpers/probate-estate-context'

type WillQuestion = {
  name: string
  shortLabel?: string
  label?: ReactNode
  hint?: string
  options?: typeof YES_NO_OPTIONS
  component?: React.FC
  onClick?: (
    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    props: any,
    values: ProbateLeadAttributes,
    people: ProbateEstatePerson[],
    probateEstateId?: number
  ) => void
  showIf?: (values: ProbateLeadAttributes, probateEstateId?: number) => boolean
  type?: 'text' | 'number'
  getConfirmationModalConfig?: ({
    value,
    values,
    setFieldValue,
    handleSubmit,
    people,
    probateEstateId,
    bulkDeletePeople,
  }: {
    value: string
    values: ProbateLeadAttributes
    setFieldValue: (
      field: string,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      value: any,
      shouldValidate?: boolean | undefined
    ) => void
    handleSubmit: (e?: React.FormEvent<HTMLFormElement> | undefined) => void
    people: ProbateEstatePerson[]
    probateEstateId?: number
    bulkDeletePeople: (personalRepresentativeType: string) => Promise<void>
  }) => {
    deleteCallback: () => void
    bodyText: JSX.Element
    buttonLabel: string
  }
}

const QUESTIONS_IF_HAS_WILL: WillQuestion[] = [
  {
    name: 'quoteAttributes.willDraftedByProfessional',
    shortLabel: 'Will drafted by a professional',
    label: 'Has the will been drafted by a professional?',
    options: YES_NO_OPTIONS,
    showIf: (values) => values.quoteAttributes.hadWill === 'true',
  },
  {
    name: 'quoteAttributes.willHasIssues',
    shortLabel: 'Issues with the will',
    label: 'Are there any issues with the will?',
    hint: 'Eg: undated, alterations, no executors, no residuary gift',
    options: YES_NO_OPTIONS,
    showIf: (values) => values.quoteAttributes.hadWill === 'true',
  },
  {
    name: 'quoteAttributes.willContested',
    shortLabel: 'Concerns in relation to the will or assets',
    label:
      'Has anyone raised concerns in relation to the will, assets or their inheritance, or is anyone likely to make a claim against the estate?',
    options: YES_NO_OPTIONS,
    showIf: (values) => values.quoteAttributes.hadWill === 'true',
  },
  {
    name: 'quoteAttributes.customerIsExecutor',
    shortLabel: 'Executor of the will',
    label: 'Are you an executor of the will?',
    options: YES_NO_OPTIONS,
    showIf: (values) => values.quoteAttributes.hadWill === 'true',
  },
  {
    name: 'customerIsExecutorComponent',
    component: CustomerIsExecutor,
    showIf: (values, probateEstateId) =>
      probateEstateId &&
      values.quoteAttributes.hadWill === 'true' &&
      values.quoteAttributes.customerIsExecutor === 'true',
  },
  {
    name: 'quoteAttributes.customerIsNotExecutorNotes',
    shortLabel: 'Add note (optional)',
    label: 'Add note (optional)',
    showIf: (values) =>
      values.quoteAttributes.hadWill === 'true' &&
      values.quoteAttributes.customerIsExecutor === 'false',
  },
  {
    name: 'quoteAttributes.willNumberOfExecutors',
    shortLabel: 'Total executors',
    label: 'How many executors are there in total?',
    type: 'number',
    showIf: (values) => values.quoteAttributes.hadWill === 'true',
  },
  {
    name: 'willExecutors',
    component: willExecutors,
    showIf: (values, probateEstateId) =>
      probateEstateId &&
      values.quoteAttributes.hadWill === 'true' &&
      (values.quoteAttributes.willNumberOfExecutors || 0) > 0,
  },
  {
    name: 'quoteAttributes.willLeftAllToPartnerOrCharity',
    shortLabel:
      'Left everything to their surviving spouse, civil partner or a charity',
    label:
      'Did the person who died leave everything to their surviving spouse, civil partner or a charity?',
    options: YES_NO_OPTIONS,
    showIf: (values: ProbateLeadAttributes) =>
      values.quoteAttributes.hadWill === 'true' &&
      values.quoteAttributes.maritalStatus === MARRIED_OR_CIVIL_PARTNERSHIP,
  },
  {
    name: 'quoteAttributes.totalBeneficiaries',
    shortLabel: 'How many beneficiaries are mentioned in the will?',
    label: (
      <>
        How many{' '}
        <Tooltip content="Anyone receiving a share of the estate or a specific gift in the will. If there is no will, how many people will inherit through intestacy.">
          beneficiaries
        </Tooltip>{' '}
        are mentioned in the will?
      </>
    ),
    type: 'number',
    showIf: (values) =>
      values.quoteAttributes.hadWill === 'true' &&
      (values.quoteAttributes.willLeftAllToPartnerOrCharity === 'false' ||
        values.quoteAttributes.maritalStatus !== MARRIED_OR_CIVIL_PARTNERSHIP),
  },
  {
    name: 'quoteAttributes.hasBeneficiariesUnder18',
    shortLabel: 'Beneficiaries under the age of 18',
    label: 'Are any beneficiaries under the age of 18?',
    options: YES_NO_OPTIONS,
    showIf: (values) =>
      values.quoteAttributes.hadWill === 'true' &&
      (values.quoteAttributes.willLeftAllToPartnerOrCharity === 'false' ||
        values.quoteAttributes.maritalStatus !== MARRIED_OR_CIVIL_PARTNERSHIP),
  },
  {
    name: 'quoteAttributes.willAmountPassingAbove325k',
    shortLabel: 'Amount passing to the beneficiaries more than £325,000',
    label:
      'Is the amount passing to the beneficiaries other than a spouse, civil partner or charity more than £325,000?',
    options: YES_NO_OPTIONS,
    showIf: (values) =>
      values.quoteAttributes.hadWill === 'true' &&
      values.quoteAttributes.maritalStatus === MARRIED_OR_CIVIL_PARTNERSHIP &&
      values.quoteAttributes.willLeftAllToPartnerOrCharity === 'false',
  },
  {
    name: 'quoteAttributes.willHasTrusts',
    shortLabel: 'Trusts in the will?',
    label: 'Are there any trusts in the will?',
    options: YES_NO_OPTIONS,
    showIf: (values: ProbateLeadAttributes) =>
      values.quoteAttributes.hadWill === 'true',
  },
] as WillQuestion[]

const QUESTIONS_IF_NO_WILL: WillQuestion[] = [
  {
    name: 'quoteAttributes.customerIsAdministrator',
    shortLabel: 'Administrator of the will',
    label: 'Are you an administrator?',
    options: YES_NO_OPTIONS,
    showIf: (values) => values.quoteAttributes.hadWill === 'false',
  },
  {
    name: 'customerIsAdministratorComponent',
    component: CustomerIsAdministrator,
    showIf: (values, probateEstateId) =>
      probateEstateId &&
      values.quoteAttributes.hadWill === 'false' &&
      values.quoteAttributes.customerIsAdministrator === 'true',
  },
  {
    name: 'quoteAttributes.intestacyNumberOfAdministrators',
    shortLabel: 'Total administrators',
    label:
      'How many administrators in total will be actively involved in the administration?',
    type: 'number',
    showIf: (values) => values.quoteAttributes.hadWill === 'false',
  },
  {
    name: 'intestacyAdministrators',
    component: IntestacyAdministrators,
    showIf: (values, probateEstateId) =>
      probateEstateId &&
      values.quoteAttributes.hadWill === 'false' &&
      (values.quoteAttributes.intestacyNumberOfAdministrators || 0) > 0,
  },
  {
    name: 'quoteAttributes.totalBeneficiaries',
    shortLabel: 'Beneficiaries',
    label: 'How many beneficiaries are there?',
    showIf: (values) => values.quoteAttributes.hadWill === 'false',
    type: 'number',
  },
  {
    name: 'quoteAttributes.hasBeneficiariesUnder18',
    shortLabel: 'Beneficiaries under the age of 18',
    label: 'Are any beneficiaries under the age of 18?',
    options: YES_NO_OPTIONS,
    showIf: (values) => values.quoteAttributes.hadWill === 'false',
  },
  {
    name: 'estatePassing',
    component: EstatePassing,
    showIf: (values, probateEstateId) =>
      values.quoteAttributes.hadWill === 'false',
  },
  {
    name: 'quoteAttributes.intestacyAmountPassingToChildrenAbove325k',
    shortLabel: 'Amount passing to children or grandchildren above 325k',
    label:
      'Is the amount passing to children or grandchildren more than £325,000?',
    options: YES_NO_OPTIONS,
    showIf: (values) =>
      values.quoteAttributes.hadWill === 'false' &&
      values.quoteAttributes.maritalStatus === MARRIED_OR_CIVIL_PARTNERSHIP &&
      values.quoteAttributes.intestacyPassingToCloseRelative === 'true',
  },
  {
    name: 'quoteAttributes.intestacyContested',
    shortLabel: 'Possible claim against estate',
    label: 'Is anyone likely to try to make a claim against the estate?',
    options: YES_NO_OPTIONS,
    showIf: (values) => values.quoteAttributes.hadWill === 'false',
  },
] as WillQuestion[]

export const QUESTIONS = [
  {
    name: 'quoteAttributes.hadWill',
    shortLabel: 'Had a will',
    label: 'Is there a will?',
    options: YES_NO_OPTIONS,
    getConfirmationModalConfig: ({
      value,
      setFieldValue,
      handleSubmit,
      probateEstateId,
      bulkDeletePeople,
    }) => ({
      component: DeleteModal,
      headingText: 'You are about to remove some information',
      config: {
        deleteCallback: () => {
          if (value === 'false') {
            // Remove answers from "has a will"
            const fieldsToEmpty = [
              'quoteAttributes.willDraftedByProfessional',
              'quoteAttributes.willHasIssues',
              'quoteAttributes.willContested',
              'quoteAttributes.customerIsExecutor',
              'quoteAttributes.customerIsNotExecutorNotes',
              'quoteAttributes.willNumberOfExecutors',
              'quoteAttributes.willLeftAllToPartnerOrCharity',
              'quoteAttributes.totalBeneficiaries',
              'quoteAttributes.hasBeneficiariesUnder18',
              'quoteAttributes.willAmountPassingAbove325k',
              'quoteAttributes.willHasTrusts',
            ]
            fieldsToEmpty.forEach((fieldName) => {
              setFieldValue(fieldName, '', false)
            })

            probateEstateId && bulkDeletePeople('executor')
          }

          if (value === 'true') {
            const fieldsToEmpty = [
              'quoteAttributes.customerIsAdministrator',
              'quoteAttributes.intestacyNumberOfAdministrators',
              'quoteAttributes.totalBeneficiaries',
              'quoteAttributes.intestacyPassingToCloseRelative',
              'quoteAttributes.intestacyPassingToRemoteRelative',
              'quoteAttributes.intestacyContested',
              'quoteAttributes.intestacyAmountPassingToChildrenAbove325k',
            ]
            fieldsToEmpty.forEach((fieldName) => {
              setFieldValue(fieldName, '', false)
            })

            probateEstateId && bulkDeletePeople('administrator_without_will')
          }
          setFieldValue('quoteAttributes.hadWill', value, false)
          handleSubmit()
        },
        bodyText: (
          <P>
            Changing the answer to this question will clear the information
            about executors/administrators. Are you sure you want to continue?
          </P>
        ),
        buttonLabel: 'Continue',
      },
    }),
  },
  ...QUESTIONS_IF_HAS_WILL,
  ...QUESTIONS_IF_NO_WILL,
] as WillQuestion[]

const IntestacyTooltip = () => (
  <Grid.Item>
    <P size="S">
      Here are the{' '}
      <Tooltip
        content={
          <>
            Farewill’s{' '}
            <ExternalLink href="https://drive.google.com/file/d/18II_0_CK5KoRvbV0vMGC25ZBX6St-hY7">
              intestacy flowchart
            </ExternalLink>
          </>
        }
      >
        rules of intestacy
      </Tooltip>
    </P>
  </Grid.Item>
)

const NotExecutorMessage = () => (
  <Grid.Item>
    <InfoBox>
      <P size="S">
        <strong>
          Please let the caller know that our customer will have to be the
          executor
        </strong>
      </P>
      <P size="S">
        You can continue speaking with the caller and provide a quote for them
        to pass on to the executor. Add the main executor’s details below.
      </P>
    </InfoBox>
  </Grid.Item>
)

const Question = ({ question }: { question: WillQuestion }) => {
  const { handleSubmit, setFieldValue, values } =
    useFormikContext<ProbateLeadAttributes>()
  const { people, bulkDeletePeople } = usePeople()
  const { probateEstateId } = useProbateEstate()
  const { customerIsExecutor, hadWill } = values.quoteAttributes
  const {
    onClick,
    label,
    name,
    options,
    type,
    hint,
    component: Component,
    getConfirmationModalConfig,
  } = question

  const customerIsNotExecutor = customerIsExecutor === 'false'
  const isExecutorQuestion = name === 'quoteAttributes.customerIsExecutor'

  const didNotHaveWill = hadWill === 'false'
  const isHadWillQuestion = name === 'quoteAttributes.hadWill'

  if (Component) return <Component />

  return (
    <>
      <Grid.Item spanFromL={9}>
        {options ? (
          <RadioGroup
            label={label}
            name={name}
            options={options}
            hint={hint}
            handleSave={(value) => {
              onClick?.(setFieldValue, values, people, probateEstateId)
              const nameParts = value.name.split('.') as Array<
                keyof ProbateQuoteAttributes
              >
              if (
                !getConfirmationModalConfig ||
                (!!getConfirmationModalConfig &&
                  values.quoteAttributes[nameParts[1]] === '')
              ) {
                return handleSubmit()
              }
            }}
            handleChange={(value, setFieldValue) => {
              if (getConfirmationModalConfig) {
                const modalConfig = getConfirmationModalConfig({
                  value,
                  values,
                  setFieldValue,
                  handleSubmit,
                  people,
                  probateEstateId,
                  bulkDeletePeople,
                })
                store.dispatch(showModal(modalConfig))
              }
            }}
            confirmationRequired={!!getConfirmationModalConfig}
          />
        ) : (
          <Wrapper
            tag={Input}
            maxWidth={type === 'number' ? '100px' : 'none'}
            type={type}
            label={label}
            name={name}
            handleSave={() => {
              onClick?.(setFieldValue, values, people, probateEstateId)
              return handleSubmit()
            }}
          />
        )}
      </Grid.Item>
      {isExecutorQuestion && customerIsNotExecutor && <NotExecutorMessage />}
      {isHadWillQuestion && didNotHaveWill && <IntestacyTooltip />}
    </>
  )
}

const showQuestion = (
  question: WillQuestion,
  values: ProbateLeadAttributes,
  probateEstateId?: number
) => {
  if (!question.showIf) return true
  return question.showIf(values, probateEstateId)
}

const TheWill = ({ isComplete }: { isComplete: boolean }) => {
  const { values } = useFormikContext<ProbateLeadAttributes>()
  const { probateEstateId } = useProbateEstate()
  return (
    <Grid>
      <Grid.Item span={12}>
        <Grid>
          <SectionHeading
            title="3. Will or intestacy"
            scrollId="will-or-intestacy"
            isComplete={isComplete}
          />

          {QUESTIONS.map(
            (question, index) =>
              showQuestion(question, values, probateEstateId) && (
                <Question key={index} question={question} />
              )
          )}
        </Grid>
      </Grid.Item>
    </Grid>
  )
}

export default TheWill
