import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { BulletSeparator, H } from '@farewill/ui'
import { COLOR, FONT, GTR } from '@farewill/ui/tokens'
import useFeatureFlag from 'hooks/useFeatureFlag'
import { FuneralAttributes } from 'lib/models/funeral'
import {
  getCelebrant,
  getKeyElements,
  getService,
  getSpecialRequests,
  getDeathCountryName,
} from './helpers'
import { SCOTLAND_FEATURE_FLAG_CONFIG } from 'utils/enums'

const Table = styled.table`
  max-width: 100%;
  margin-bottom: ${GTR.L};
`

const TableBody = styled.tbody``

const TableRow = styled.tr``

const TableCell = styled.td`
  padding: ${GTR.XS} 0;
  border-bottom: 1pt solid ${COLOR.GREY.LIGHT};
  white-space: nowrap;
`

const StyledTableCell = styled(TableCell)`
  padding-left: ${GTR.XXL};
  white-space: inherit;
  font-weight: ${FONT.WEIGHT.BOLD};
`

type Props = {
  funeral: FuneralAttributes
  funeralId: number
}

const spanOf = (text: string | undefined) => <span key={text}>{text}</span>

const ServiceOverview = ({ funeral, funeralId }: Props): React.ReactElement => {
  const keyElements = getKeyElements(funeral)
  const specialRequests = getSpecialRequests(funeral.additionalCharges)

  const isScotlandEnabled =
    useFeatureFlag(SCOTLAND_FEATURE_FLAG_CONFIG) === 'false' ? false : true

  return (
    <>
      <H size="S">Funeral overview</H>
      <Table>
        <TableBody>
          <TableRow>
            <TableCell>Service</TableCell>
            <StyledTableCell>{getService()}</StyledTableCell>
          </TableRow>
          <TableRow>
            <TableCell>Celebrant</TableCell>
            <StyledTableCell>{getCelebrant(funeral)}</StyledTableCell>
          </TableRow>
          <TableRow>
            <TableCell>Key elements</TableCell>
            <StyledTableCell>
              <BulletSeparator>{keyElements.map(spanOf)}</BulletSeparator>
            </StyledTableCell>
          </TableRow>
          <TableRow>
            <TableCell>Special requests</TableCell>
            <StyledTableCell>
              <BulletSeparator>{specialRequests.map(spanOf)}</BulletSeparator>
            </StyledTableCell>
          </TableRow>
          {isScotlandEnabled && (
            <TableRow>
              <TableCell>Location</TableCell>
              <StyledTableCell>{getDeathCountryName(funeral)}</StyledTableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      <Link to={`/funerals/${funeralId}/sale`}>
        Change or add additional options
      </Link>
    </>
  )
}

export default ServiceOverview
