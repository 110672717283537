import { Link, useHistory, useParams } from 'react-router-dom'
import { useFormikContext, setNestedObjectValues } from 'formik'
import { isEmpty } from 'lodash'

import { Button, ButtonGroup, Divider, H, Wrapper, Grid, P } from '@farewill/ui'

import InputFloating from 'components/form/input-floating'
import SelectInput from 'components/form/select-input'

import { PARTNER_CAUSE_AREA_OPTIONS } from 'lib/models/partner'
import { PARTNER_TYPE_OPTIONS } from 'routes/partners/constants'
import Error from 'routes/partners/components/error'
import CharityKeyDetails from 'routes/partners/components/charity-key-details'
import Logo from 'routes/partners/components/logo'
import GiftPrompt from 'routes/partners/components/gift-prompt'
import OrganisationKeyDetails from 'routes/partners/components/organisation-key-details'
import ProductSetup from 'routes/partners/components/product-setup'
import SimpleList from 'components/styled/simple-list'
import { Operation, Values } from 'routes/partners/interfaces'
import { GTR } from '@farewill/ui/tokens'
import StyledLoader from 'components/loader'

const getReviewPath = ({
  operation,
  partnerId,
}: {
  operation: Operation
  partnerId: string
}) => {
  return operation === 'update' && partnerId
    ? `/partners/${partnerId}/review`
    : '/partners/new/review'
}

interface UpdateOrCreateFormProps {
  operation: Operation
  showErrorMessage: boolean
  setShowErrorMessage: (showErrorMessage: boolean) => void
  isLoading?: boolean
}

const UpdateOrCreateForm = ({
  operation,
  showErrorMessage,
  setShowErrorMessage,
  isLoading,
}: UpdateOrCreateFormProps) => {
  const { id: partnerId } = useParams<{ id: string }>()
  const history = useHistory()
  const reviewPath = getReviewPath({ operation, partnerId })
  const { values, validateForm, setTouched } = useFormikContext<Values>()

  const handleReviewClick = async () => {
    const errors = await validateForm()
    setTouched(setNestedObjectValues(errors, true))

    if (!isEmpty(errors)) {
      setShowErrorMessage(true)
      return
    }

    // If the gift prompt switch is unset, blank out all the fields
    if (!values.isGiftPrompt) {
      values.giftPromptDescription = undefined
      values.giftPromptImage = null
      values.giftPromptImageUrl = undefined
    }
    return history.push(reviewPath)
  }

  if (isLoading) return <StyledLoader />

  return (
    <Grid padding={[GTR.L, 0, 0]}>
      <Grid.Item spanFromL={7}>
        {/* //// Partner type //// */}
        <Wrapper margin={[0, 0, GTR.M, 0]}>
          {operation === 'create' ? (
            <>
              <H tag="h1" size="M">
                Partner type
              </H>
              <Wrapper maxWidth="50%">
                <SelectInput name="type" options={PARTNER_TYPE_OPTIONS} />
              </Wrapper>
              {values.type === 'organisation' && (
                <Wrapper margin={['S', 'XS']}>
                  <P>An organisation is: </P>
                  <SimpleList bullets>
                    <li>a commercial partner, or</li>
                    <li>
                      an entity that is not a registered charity, regardless of
                      exempt charitable status
                    </li>
                  </SimpleList>
                </Wrapper>
              )}
            </>
          ) : (
            <h2>Partner type: {values.type}</h2>
          )}
        </Wrapper>

        {/* //// Key information //// */}
        <H size="S">Key information</H>
        {values.type === 'charity' && (
          <CharityKeyDetails operation={operation} />
        )}
        {values.type === 'organisation' && (
          <OrganisationKeyDetails operation={operation} />
        )}
        <Divider margin={['L', 0]} />

        {/* //// Additional information //// */}
        <H size="S">Additional information</H>
        <InputFloating name="hubspotUrl" label="HubSpot URL (optional)" />
        <Wrapper maxWidth="50%" margin={['S', 0, 0]}>
          <SelectInput
            floating
            label="Cause area (optional)"
            name="causeArea"
            options={PARTNER_CAUSE_AREA_OPTIONS}
          />
        </Wrapper>
        <Divider margin={['L', 0]} />

        {/* //// Logo //// */}
        <Logo />
        <Divider margin={['L', 0]} />

        {/* //// Gift Prompt //// */}
        <GiftPrompt />
        <Divider margin={['L', 0]} />

        {/* //// Product Setup //// */}
        <ProductSetup />
        <Divider margin={['L', 0]} />
        {showErrorMessage && <Error />}
        <ButtonGroup horizontalFromS>
          <Button.Primary onClick={handleReviewClick} type="button">
            Review information
          </Button.Primary>
          {operation === 'create' ? (
            <Button.Plain tag={Link} back to="/partners">
              Back
            </Button.Plain>
          ) : (
            <Button.Plain back tag={Link} to={`/partners/${partnerId}`}>
              Back
            </Button.Plain>
          )}
        </ButtonGroup>
      </Grid.Item>
    </Grid>
  )
}
export default UpdateOrCreateForm
