import { P, Wrapper } from '@farewill/ui'

import { RESOURCES, useFetchArrayResource } from 'api'
import SelectInput from 'components/form/select-input'
import { Charity } from 'lib/models/charity'
import CharityFormFields from '../../forms/charity'

type Props = {
  isManualEntry: boolean
}

const CharityFields = ({ isManualEntry }: Props): React.ReactElement => {
  const charitiesQuery = useFetchArrayResource<Charity>({
    resource: RESOURCES.CHARITIES,
  })

  if (charitiesQuery.isLoading || charitiesQuery.isIdle) {
    return <P>Loading...</P>
  }

  const options = charitiesQuery?.data?.data.map((c) => {
    let label = c.attributes.legalName
    if (c.attributes.commonName) label += ` ${c.attributes.commonName}`
    return { value: c.id, label }
  })

  return (
    <Wrapper>
      {isManualEntry ? (
        <CharityFormFields namespace="charity" />
      ) : (
        <SelectInput
          floating
          name="charity.willBeneficiaryId"
          options={options}
          label="Charity name"
        />
      )}
    </Wrapper>
  )
}

export default CharityFields
