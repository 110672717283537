import { useCallback } from 'react'

type useNamespaceReturnObject = {
  withNamespace: (fieldName: string) => string
  withoutNamespace: (fieldName: string) => string
}

const useNamespace = (prefix: string): useNamespaceReturnObject => {
  const withNamespace = useCallback(
    (fieldName: string): string => `${prefix}${fieldName}`,
    [prefix]
  )

  const withoutNamespace = useCallback(
    (fieldName: string): string => fieldName.split(prefix)[1],
    [prefix]
  )

  return { withNamespace, withoutNamespace }
}

export default useNamespace
