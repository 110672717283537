import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { Button, P, Wrapper } from '@farewill/ui'
import { FONT, GTR } from '@farewill/ui/tokens'

import { hideModal } from 'state/actions'
import { formatCurrency } from 'lib/formatting/currency'

const StyledWrapper = styled(Wrapper)`
  display: flex;
  flex-direction: column;
`

const StyledCloseButton = styled(Button)`
  font-weight: ${FONT.WEIGHT.REGULAR};
  text-decoration: underline;
  align-self: center;
  margin-top: ${GTR.XS};

  &:hover {
    text-decoration: underline;
  }
`

type ModalProps = {
  config: {
    amountToRefundInPence: number
    cancellationFeeInPence?: number
    handleCancelPlan: () => Promise<void>
  }
}

const CancelPlanModal = ({ config }: ModalProps) => {
  const dispatch = useDispatch()

  const handleContinue = async () => {
    await config.handleCancelPlan()
    dispatch(hideModal())
  }

  const notRefundedYet = config.amountToRefundInPence > 0

  return (
    <Wrapper>
      {notRefundedYet && (
        <>
          <P>
            Confirm the customer wants to cancel. Explain they’ll receive a
            refund of{' '}
            {formatCurrency({
              showPence: true,
              value: config.amountToRefundInPence,
              valueInPence: true,
            })}
            , which is the total amount they’ve paid{' '}
            {config.cancellationFeeInPence
              ? `minus the ${formatCurrency({
                  showPence: true,
                  value: config.cancellationFeeInPence,
                  valueInPence: true,
                })}
            cancellation fee`
              : ''}
            . We’ll send them an email to confirm.
          </P>
          <P>
            You must now close this modal and refund the customer. Click on each
            row's "Card payment" to go to the payment on Square, where you can
            refund it.
          </P>
          <P>
            For instalments customers, remember to cancel the recurring series
            and any unpaid invoices as well.
          </P>
          <P>
            Once you have refunded the customer{' '}
            {formatCurrency({
              showPence: true,
              value: config.amountToRefundInPence,
              valueInPence: true,
            })}
            , return here to cancel the plan.
          </P>
        </>
      )}
      {!notRefundedYet && (
        <P>
          Are you sure you want to cancel the plan? This action cannot be
          undone.
        </P>
      )}
      <StyledWrapper>
        <Button.Primary
          type="button"
          onClick={handleContinue}
          disabled={notRefundedYet}
        >
          Cancel the plan
        </Button.Primary>
        <StyledCloseButton onClick={() => dispatch(hideModal())}>
          Close without cancelling
        </StyledCloseButton>
      </StyledWrapper>
    </Wrapper>
  )
}

export default CancelPlanModal
