import { Grid, H, TickIcon, WarningIcon } from '@farewill/ui'
import { COLOR } from '@farewill/ui/tokens'
import Tooltip from 'components/tooltip'
import styled from 'styled-components'

const StyledHeadingWrapper = styled(Grid.Item)`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

type SectionHeadingProps = {
  title: string
  scrollId?: string
  isComplete: boolean
  hideIcon?: boolean
}

export const TooltipComplete = ({ scrollId }: { scrollId?: string }) => (
  <Tooltip content="All fields required for quote are filled in.">
    <TickIcon
      size="S"
      color={COLOR.WHITE}
      background={COLOR.STATE.SUCCESS}
      inline
      data-testid={`${scrollId}-complete`}
    />
  </Tooltip>
)

export const TooltipIncomplete = ({ scrollId }: { scrollId?: string }) => (
  <Tooltip content="Fields required for a quote are missing in this section.">
    <WarningIcon
      size="S"
      color={COLOR.WHITE}
      background={COLOR.GREY.LIGHT}
      inline
      data-testid={`${scrollId}-incomplete`}
    />
  </Tooltip>
)

const TooltipWrapper = ({
  isComplete,
  scrollId,
}: {
  isComplete: boolean
  scrollId?: string
}) =>
  isComplete ? (
    <TooltipComplete scrollId={scrollId} />
  ) : (
    <TooltipIncomplete scrollId={scrollId} />
  )

const SectionHeading = ({
  title,
  scrollId,
  isComplete,
  hideIcon,
}: SectionHeadingProps) => {
  return (
    <StyledHeadingWrapper data-scroll-id={scrollId}>
      <H size="S" margin={0}>
        {title}{' '}
      </H>
      {hideIcon ? null : (
        <TooltipWrapper isComplete={isComplete} scrollId={scrollId} />
      )}
    </StyledHeadingWrapper>
  )
}

export default SectionHeading
