import { ResponseErrors } from 'lib/types'
import { useCallback, createContext, useReducer } from 'react'

type AssetsContextType = {
  errors: string[]
  highlightEmpty: boolean
  isLoading: boolean
  setErrors?: (payload: ResponseErrors) => void
  setHighlightEmpty?: (payload: boolean) => void
  setIsLoading?: (payload: boolean) => void
}

const initialState: AssetsContextType = {
  errors: [],
  highlightEmpty: false,
  isLoading: false,
}

const Context = createContext<AssetsContextType>(initialState)

type ReducerAction =
  | {
      type: 'SET_ERRORS'
      payload: string[]
    }
  | {
      type: 'SET_HIGHLIGHT_EMPTY'
      payload: boolean
    }
  | {
      type: 'SET_IS_LOADING'
      payload: boolean
    }

type ReducerState = AssetsContextType

const reducer = (state: ReducerState, action: ReducerAction) => {
  switch (action.type) {
    case 'SET_ERRORS':
      return { ...state, errors: action.payload }
    case 'SET_HIGHLIGHT_EMPTY':
      return { ...state, highlightEmpty: action.payload }
    case 'SET_IS_LOADING':
      return { ...state, isLoading: action.payload }
    default:
      return state
  }
}

const withProvider =
  <T,>(Component: React.FC<T>) =>
  (props: T & JSX.IntrinsicAttributes) => {
    const [state, dispatch] = useReducer(reducer, initialState)

    const setErrors = useCallback(
      (payload) => {
        dispatch({ type: 'SET_ERRORS', payload })
      },
      [dispatch]
    )

    const setHighlightEmpty = useCallback(
      (payload) => {
        dispatch({ type: 'SET_HIGHLIGHT_EMPTY', payload })
      },
      [dispatch]
    )

    const setIsLoading = useCallback(
      (payload) => {
        dispatch({ type: 'SET_IS_LOADING', payload })
      },
      [dispatch]
    )

    const value = {
      ...state,
      setErrors,
      setHighlightEmpty,
      setIsLoading,
    }

    return (
      <Context.Provider value={value}>
        <Component {...props} />
      </Context.Provider>
    )
  }

export { Context, withProvider }
