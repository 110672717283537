import PropTypes from 'prop-types'
import { Grid, H } from '@farewill/ui'
import { useFormikContext } from 'formik'

import { getMomentDate } from 'lib/formik/dates'
import { strToBool } from 'utils/helpers'

import NotesInput from 'components/notes-input'
import CircularRadioGroup from 'components/form/circular-radio-group'

import { NOT_CHECKED_OPTIONS } from 'utils/enums'

import withForm from '../fact-find/components/with-form'
import SummaryBox from '../fact-find/components/summary-box'
import NoWillMessage from './no-will-message'
import { HAS_WILL, WILL } from '../fact-find/will-or-intestacy/field-names'

import {
  WILL_BELONGS_TO_DECEASED,
  WILL_DATE_IS_CORRECT,
  WILL_IDENTITY_NOTES,
} from './field-names'

const getDeceasedFullName = (probateCase) => {
  const { deceasedFirstName, deceasedLastName } = probateCase.attributes
  return `${deceasedFirstName} ${deceasedLastName}`
}

const getDateOfWill = (values) => {
  const dateOfWill = getMomentDate({ prefix: WILL, values })
  return dateOfWill?.format('D MMM YYYY')
}

const IdentityOfTheWill = ({
  probateCase,
  probateEstateId,
  saveProbateEstateField,
}) => {
  const { values } = useFormikContext()
  const hasWill = strToBool(values[HAS_WILL])
  const summaryItems = [
    {
      label: 'Full name of person who died',
      value: getDeceasedFullName(probateCase),
      section: '1.2',
      isLink: true,
    },
    {
      label: 'Date of will',
      value: getDateOfWill(values),
      section: '2',
      isLink: true,
    },
  ]
  return (
    <Grid gap="XL">
      <Grid.Item span={8}>
        <H size="S" margin={[0, 0, 'M']}>
          2. Identity of the will
        </H>
        {hasWill && (
          <Grid gap={['L', 'M']}>
            <Grid.Item>
              <SummaryBox
                isTable
                items={summaryItems}
                probateEstateId={probateEstateId}
                stretch
              />
            </Grid.Item>
            <Grid.Item span={6}>
              <CircularRadioGroup
                name={WILL_BELONGS_TO_DECEASED}
                label="Is this the will of the person who died?"
                options={NOT_CHECKED_OPTIONS}
                handleSave={saveProbateEstateField}
                inline
              />
            </Grid.Item>
            <Grid.Item span={6}>
              <CircularRadioGroup
                name={WILL_DATE_IS_CORRECT}
                label="Is the date of the will correct?"
                options={NOT_CHECKED_OPTIONS}
                handleSave={saveProbateEstateField}
                inline
              />
            </Grid.Item>
            <Grid.Item>
              <NotesInput
                name={WILL_IDENTITY_NOTES}
                handleSave={saveProbateEstateField}
              />
            </Grid.Item>
          </Grid>
        )}
        {!hasWill && <NoWillMessage probateEstateId={probateEstateId} />}
      </Grid.Item>
    </Grid>
  )
}

IdentityOfTheWill.propTypes = {
  probateCase: PropTypes.object.isRequired,
  probateEstateId: PropTypes.number.isRequired,
  saveProbateEstateField: PropTypes.func.isRequired,
}

export default withForm(IdentityOfTheWill)
