import PropTypes from 'prop-types'
import { useFormikContext } from 'formik'
import { Grid, P } from '@farewill/ui'
import get from 'lodash/get'

import namespace from 'lib/formik/namespace'

import ScrollTo from 'components/scroll-to'
import PinkBox from 'components/styled/pink-box'
import MessageBox from 'components/message-box'
import InputFloating from 'components/form/input-floating'
import CircularRadioGroup from 'components/form/circular-radio-group'
import AdministratorWithWillLabel from './administrator-with-will-label'

import { getPersonVisibleFields } from '../form-helpers'

import {
  NOT_ASKED_OPTIONS,
  PR_ROLE_OPTIONS,
  POWER_OF_ATTORNEY_OR_ORDER_TYPE_OPTIONS,
  LASTING_POWER_OF_ATTORNEY,
  ENDURING_POWER_OF_ATTORNEY,
} from 'utils/enums'

import {
  PR_ROLE,
  PR_TYPE,
  IS_ACTING_ON_BEHALF_OF_SOMEONE_ELSE,
  IS_ACTING_ON_BEHALF_OF_SOMEONE_WITH_CAPACITY_TO_ACT,
  POWER_OF_ATTORNEY_OR_ORDER_TYPE,
  POWER_OF_ATTORNEY_REGISTERED,
  APPLYING_ON_BEHALF_OF,
} from '../field-names'

const getWillSummaryText = (hasWill) => {
  if (hasWill === 'true') return 'There is a will'
  if (hasWill === 'false') return 'There’s no will - this is an intestacy'
  return 'We haven’t asked if there’s a will or not'
}

const getNotRegisteredText = (type) => {
  if (type === LASTING_POWER_OF_ATTORNEY) {
    return 'Let the person know that they will need to register the Lasting Power of Attorney Property and Affairs with the Office of the Public Guardian.'
  }
  if (type === ENDURING_POWER_OF_ATTORNEY) {
    return 'Let the person know that they will need to register the Enduring Power of Attorney with the Office of the Public Guardian.'
  }
  return 'Let the person know that they will need to obtain a further order from the Court of Protection to further authorise them to apply for the Grant.'
}

const PersonalRespresentativeQuestions = ({
  hasWill,
  personId,
  handleSave,
}) => {
  const { values } = useFormikContext()
  const prefix = `people.${personId}.`
  const { withNamespace } = namespace(prefix)

  const {
    showRoleAndIsInsolvent,
    showDeceasedRoleWarning,
    showCustomerRoleWarning,
    showNonCustomerRoleWarning,
    showIsActingOnBehalfOfSomeoneElse,
    showIsActingOnBehalfOfSomeoneWithCapacity,
    showCapacityWarning,
    showPowerOfAttorneyOrOrderType,
    showNoPowerOfAttorneyOrOrderWarning,
    showPowerOfAttorneyRegistered,
    showNotRegisteredWarning,
    showRegisteredPowerOfAttorneyInfo,
    showRegisteredInfo,
    showApplyingOnBehalfOf,
  } = getPersonVisibleFields({ hasWill, prefix, values })

  return (
    <>
      <Grid.Item>
        <PinkBox>
          {getWillSummaryText(hasWill)} (<ScrollTo scrollId="2">2</ScrollTo>)
        </PinkBox>
      </Grid.Item>
      {showRoleAndIsInsolvent && (
        <>
          <Grid.Item>
            <CircularRadioGroup
              label="What type of PR are they?"
              inline
              name={withNamespace(PR_TYPE)}
              options={[
                { value: '', label: 'Not asked' },
                { value: 'executor', label: 'Executor' },
                {
                  value: 'administrator_without_will',
                  label: 'Administrator - without a will',
                },
                {
                  value: 'administrator_with_will',
                  label: <AdministratorWithWillLabel />,
                },
              ]}
              handleSave={handleSave}
            />
          </Grid.Item>
          <Grid.Item span={6} startColumn={1}>
            <InputFloating
              label="Role..."
              name={withNamespace(PR_ROLE)}
              component="select"
              options={PR_ROLE_OPTIONS}
              handleSave={handleSave}
            />
          </Grid.Item>
          {showDeceasedRoleWarning && (
            <Grid.Item>
              <MessageBox>
                Please ask the person to send a copy of the Death Certificate
              </MessageBox>
            </Grid.Item>
          )}
          {showCustomerRoleWarning && (
            <Grid.Item>
              <MessageBox warning>
                A PR must be acting if they are a customer
              </MessageBox>
            </Grid.Item>
          )}
          {showNonCustomerRoleWarning && (
            <Grid.Item>
              <MessageBox warning>
                A PR cannot be acting if they are not a customer
              </MessageBox>
            </Grid.Item>
          )}
          {showIsActingOnBehalfOfSomeoneElse && (
            <Grid.Item>
              <CircularRadioGroup
                name={withNamespace(IS_ACTING_ON_BEHALF_OF_SOMEONE_ELSE)}
                label="Are they acting on behalf of someone else?"
                options={NOT_ASKED_OPTIONS}
                handleSave={handleSave}
                inline
              />
            </Grid.Item>
          )}
          {showIsActingOnBehalfOfSomeoneWithCapacity && (
            <Grid.Item>
              <CircularRadioGroup
                name={withNamespace(
                  IS_ACTING_ON_BEHALF_OF_SOMEONE_WITH_CAPACITY_TO_ACT
                )}
                label="Does the person they are acting on behalf of have capacity to act?"
                options={NOT_ASKED_OPTIONS}
                handleSave={handleSave}
                inline
              />
            </Grid.Item>
          )}
          {showCapacityWarning && (
            <Grid.Item>
              <MessageBox warning>
                <P size="S">
                  If the person they are acting on behalf of has the capacity to
                  act, but chooses not to and wants this person to step in, then
                  the Legal Team will need to draft a Power of Attorney to take
                  administration of the estate.
                </P>
                <P size="S">
                  Please make sure we have spoken to the person who is going to
                  be giving the Power of Attorney to ensure there are no issues.
                </P>
              </MessageBox>
            </Grid.Item>
          )}
          {showPowerOfAttorneyOrOrderType && (
            <Grid.Item span={9}>
              <InputFloating
                label="Type of power of attorney / order."
                name={withNamespace(POWER_OF_ATTORNEY_OR_ORDER_TYPE)}
                component="select"
                options={POWER_OF_ATTORNEY_OR_ORDER_TYPE_OPTIONS}
                handleSave={handleSave}
              />
            </Grid.Item>
          )}
          {showNoPowerOfAttorneyOrOrderWarning && (
            <Grid.Item>
              <MessageBox warning>
                <P size="S">
                  If there is no power of attorney or deputyship order, and the
                  person they want to act on behalf of lacks capacity, then they
                  may need to make an application for a Deputyship Order with
                  the Court of Protection.
                </P>
                <P size="S">
                  Check with Legal Team as if there are other executors who can
                  apply who have capacity then they should be acting, or there
                  may be someone else who is/are entitled to apply.
                </P>
              </MessageBox>
            </Grid.Item>
          )}
          {showPowerOfAttorneyRegistered && (
            <Grid.Item>
              <CircularRadioGroup
                name={withNamespace(POWER_OF_ATTORNEY_REGISTERED)}
                label="Has the power of attorney been registered (or deputyship order obtained with a further order to confirm that this person can take out the Grant for this estate)? "
                options={NOT_ASKED_OPTIONS}
                handleSave={handleSave}
                inline
              />
            </Grid.Item>
          )}
          {showNotRegisteredWarning && (
            <Grid.Item>
              <MessageBox>
                <P size="S">
                  {getNotRegisteredText(
                    get(values, withNamespace(POWER_OF_ATTORNEY_OR_ORDER_TYPE))
                  )}
                </P>
              </MessageBox>
            </Grid.Item>
          )}
          {showRegisteredInfo && (
            <Grid.Item>
              <MessageBox>
                <P size="S">
                  Let the person know that we will need to have the original or
                  certified copy to submit to the probate registry, but in the
                  meantime ask them to send a copy of it.
                </P>
                {showRegisteredPowerOfAttorneyInfo && (
                  <P size="S">
                    Also let the customer know that we will need a medical
                    certificate from the GP to confirm the capacity of the
                    person they are acting on behalf of. We can provide a
                    template to send to the GP.
                  </P>
                )}
              </MessageBox>
            </Grid.Item>
          )}
          {showApplyingOnBehalfOf && (
            <>
              <Grid.Item>
                <InputFloating
                  label="On whose behalf are they applying? (Full name or names)"
                  name={withNamespace(APPLYING_ON_BEHALF_OF)}
                  handleSave={handleSave}
                />
              </Grid.Item>
              <Grid.Item span={11}>
                <P>
                  Please record details of each of the people listed in the box
                  above separately by clicking ‘Add person’, below.
                </P>
              </Grid.Item>
            </>
          )}
        </>
      )}
    </>
  )
}

PersonalRespresentativeQuestions.propTypes = {
  handleSave: PropTypes.func.isRequired,
  hasWill: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]).isRequired,
  personId: PropTypes.number.isRequired,
}

export default PersonalRespresentativeQuestions
