import { Formik, Form } from 'formik'
import { Grid, Divider, H } from '@farewill/ui'
import { useSelector, useDispatch } from 'react-redux'

import Input from 'components/form/input'
import RequestStatus from 'components/request-status'
import { useTasksContext } from 'components/tasks-panel/context'
import { formatCurrency } from 'lib/formatting/currency'
import { ProbateCase, ProbateCaseAttributes } from 'lib/models/probate-case'
import { updateProbateCase } from 'state/actions'
import { showRelationshipToCustomerWithLegacy } from 'routes/lead/helpers'
import { ESTATE_ADMIN_REQUIRED_OPTIONS, TAX_FORM_OPTIONS } from 'utils/enums'
import { formatValuesForFormik, strToBool } from 'utils/helpers'

import CaseOwners from './case-owners'
import Referral from './referral'
import LostOrDeclined from './lost-or-declined'
import CaseDetailsSummary from './case-details-summary'

const CaseDetails = () => {
  const dispatch = useDispatch()
  const { fetchTasks } = useTasksContext()
  const probateCase: ProbateCase = useSelector(
    (state: { probateCase: ProbateCase }) => state.probateCase
  )
  const { attributes } = probateCase
  const initialValues = formatValuesForFormik(attributes)

  const handleSubmit = async (values: ProbateCaseAttributes) => {
    const patchData = {
      deceasedFirstName: values.deceasedFirstName || null,
      deceasedLastName: values.deceasedLastName || null,
      relationshipToCustomer: values.relationshipToCustomer || null,
      estateAdministrationRequired:
        strToBool(values.estateAdministrationRequired) ?? null,
      taxForm: values.taxForm || null,
      preReferralOwnedByAdminUserId:
        values.preReferralOwnedByAdminUserId || null,
      postReferralOwnedByAdminUserId:
        values.postReferralOwnedByAdminUserId || null,
      criticalNotes: values.criticalNotes || null,
      priorityNotes: values.priorityNotes || null,
      isPriority: strToBool(values.isPriority),
    }

    await dispatch(updateProbateCase(probateCase.id, patchData))
    return fetchTasks({
      queryParams: {
        'filter[probateCaseId]': probateCase.id,
        'filter[isNeeded]': true,
        'page[size]': 200,
      },
    })
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {({ handleSubmit }) => (
        <Form>
          <RequestStatus />
          <Grid>
            <Grid.Item>
              <H size="M">Case details</H>
            </Grid.Item>
            <CaseDetailsSummary probateCase={attributes} />
            <Grid.Item>
              <p>
                {attributes.calculatedPrice ? (
                  <>
                    <strong>
                      {formatCurrency({
                        value: attributes.calculatedPrice,
                        valueInPence: true,
                      })}
                    </strong>{' '}
                    &bull; quote (inc VAT) + court fee
                  </>
                ) : (
                  <>No quote given on initial call</>
                )}
              </p>
            </Grid.Item>
            <Grid.Item margin={['XS', 0]}>
              <Divider />
            </Grid.Item>
            <Grid.Item>
              <H size="S">The person who's died</H>
            </Grid.Item>
            <Grid.Item spanFromM={6}>
              <Input
                label="First name of person who's died"
                name="deceasedFirstName"
                handleSave={() => handleSubmit()}
              />
            </Grid.Item>
            <Grid.Item spanFromM={6}>
              <Input
                label="Last name of person who's died"
                name="deceasedLastName"
                handleSave={() => handleSubmit()}
              />
            </Grid.Item>
            <Grid.Item spanFromL={7}>
              <Input
                label="Relation of person who's died to caller"
                name="relationshipToCustomer"
                component="select"
                options={showRelationshipToCustomerWithLegacy(
                  initialValues.relationshipToCustomer
                )}
                handleSave={() => handleSubmit()}
              />
            </Grid.Item>
            <Grid.Item>
              <Divider />
            </Grid.Item>
            <Grid.Item>
              <H size="S">Case type</H>
            </Grid.Item>
            <Grid.Item spanFromL={6}>
              <Input
                label="Essential Probate or Complete Probate"
                name="estateAdministrationRequired"
                component="select"
                options={ESTATE_ADMIN_REQUIRED_OPTIONS}
                handleSave={() => handleSubmit()}
              />
            </Grid.Item>
            <Grid.Item spanFromL={6}>
              <Input
                label="Tax form"
                name="taxForm"
                component="select"
                options={TAX_FORM_OPTIONS}
                handleSave={() => handleSubmit()}
              />
            </Grid.Item>
            <Grid.Item>
              <Divider />
            </Grid.Item>
            <CaseOwners />
            <Grid.Item>
              <Divider />
            </Grid.Item>
            <Referral />
            <Grid.Item>
              <Divider />
            </Grid.Item>
            <LostOrDeclined />
          </Grid>
        </Form>
      )}
    </Formik>
  )
}

export default CaseDetails
