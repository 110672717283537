import { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Button, Grid, P } from '@farewill/ui'
import { GTR } from '@farewill/ui/tokens'

import store from 'state/create-store'
import { showModal } from 'state/actions'

import useItemState from 'lib/effects/item-state'
import useProbateEstateItemHelpers from 'lib/effects/probate-estate-item-helpers'

import EstateCard from 'components/estate-card'
import InputFloating from 'components/form/input-floating'
import CircularRadioGroup from 'components/form/circular-radio-group'
import NotesInput from 'components/notes-input'
import GridWithToggle from 'components/grid-with-toggle'

import DeleteModal from '../components/delete-modal'
import withForm from '../components/with-form'

import namespace from 'lib/formik/namespace'
import { getItemsTotals } from '../get-totals'

import {
  DESCRIPTION,
  IS_ESTIMATED_VALUE,
  VALUE,
  IS_ESTIMATED_VALUE_OPTIONS,
} from '../item-field-constants'
import { MONEY_OWED_SECTION_ACTIVE, NOTES } from './field-names'

const Item = ({
  item,
  probateEstateId,
  index,
  listLength,
  deleteItem,
  updateItem,
}) => {
  const { withNamespace, withoutNamespace } = namespace(`moneyOwed.${item.id}.`)

  const handleSave = ({ name, value }) => {
    updateItem({
      id: item.id,
      attributes: { [withoutNamespace(name)]: value || null },
    })
  }

  const onTrashClick = () => {
    store.dispatch(
      showModal({
        component: DeleteModal,
        headingText: 'Delete money owed',
        config: {
          deleteCallback: () => {
            deleteItem({
              probateEstateId,
              id: item.id,
            })
          },
          type: 'money owed',
          resourceId: item.id,
        },
      })
    )
  }

  return (
    <EstateCard
      onTrashClick={onTrashClick}
      listLength={listLength}
      listPosition={index + 1}
      key={item.id}
      type="money owed to the person who died"
    >
      <Grid>
        <Grid.Item>
          <InputFloating
            name={withNamespace(DESCRIPTION)}
            label="Description"
            handleSave={handleSave}
          />
        </Grid.Item>
        <Grid.Item span={3}>
          <InputFloating
            name={withNamespace(VALUE)}
            label="Total value (£)"
            handleSave={handleSave}
            isCurrency
          />
        </Grid.Item>
        <Grid.Item
          span={9}
          style={{ alignSelf: 'center', justifySelf: 'center' }}
        >
          <CircularRadioGroup
            name={withNamespace(IS_ESTIMATED_VALUE)}
            options={IS_ESTIMATED_VALUE_OPTIONS}
            handleSave={handleSave}
            inline
          />
        </Grid.Item>
        <Grid.Item>
          <NotesInput name={withNamespace(NOTES)} handleSave={handleSave} />
        </Grid.Item>
      </Grid>
    </EstateCard>
  )
}

const ItemWithForm = withForm(Item)

const MoneyOwed = ({
  initialItems,
  probateEstateId,
  saveProbateEstateField,
}) => {
  const { items, addItem, removeItem, replaceItem } = useItemState(initialItems)

  const {
    createItem,
    createdItem,
    isCreating,
    deleteItem,
    deletedItem,
    updateItem,
    updatedItem,
  } = useProbateEstateItemHelpers({ probateEstateId })

  useEffect(() => {
    if (createdItem) addItem(createdItem)
  }, [addItem, createdItem])

  useEffect(() => {
    if (deletedItem) removeItem(deletedItem)
  }, [removeItem, deletedItem])

  useEffect(() => {
    if (updatedItem) replaceItem(updatedItem)
  }, [replaceItem, updatedItem])

  const { total } = getItemsTotals(items)
  return (
    <GridWithToggle
      disabled={items.length > 0}
      name={MONEY_OWED_SECTION_ACTIVE}
      onToggle={saveProbateEstateField}
      scrollId="4.12"
      title="Refunds, rebates, pay-outs and money owed"
    >
      <Grid.Item>
        <P size="S">Total: {total}</P>

        <P size="S">
          Could include refunds from utilities, Inheritance Tax repayments,
          pension payments (not lump sums) and money owed to the person who's
          died.
        </P>

        {items.map((item, index) => (
          <ItemWithForm
            deleteItem={deleteItem}
            enableReinitialize={false}
            index={index}
            initialValues={{ moneyOwed: { [item.id]: item.attributes } }}
            item={item}
            key={item.id}
            listLength={items.length}
            probateEstateId={probateEstateId}
            updateItem={updateItem}
          />
        ))}
      </Grid.Item>
      <Grid.Item>
        <Button.Secondary
          style={{ paddingTop: GTR.XXS, paddingBottom: GTR.XXS }}
          loading={isCreating}
          type="button"
          onClick={() => {
            createItem({ itemType: 'money_owed' })
          }}
        >
          Add money owed to the person who died
        </Button.Secondary>
      </Grid.Item>
    </GridWithToggle>
  )
}

MoneyOwed.propTypes = {
  initialItems: PropTypes.array,
  probateEstateId: PropTypes.string.isRequired,
  saveProbateEstateField: PropTypes.func.isRequired,
}

MoneyOwed.defaultProps = {
  initialItems: [],
}

export default MoneyOwed
