import find from 'lodash/find'
import styled from 'styled-components'
import { P } from '@farewill/ui'
import { GTR } from '@farewill/ui/tokens'

import { FAREWILL_LEGAL_SERVICES } from 'utils/enums'
import { getFullName, getFrontLink } from 'utils/helpers'
import ExternalLink from 'components/external-link'
import { Contact } from 'lib/models/contact'
import { Task } from 'lib/models/task'
import { FuneralAttributes } from 'lib/models/funeral'
import { ProbateCaseAttributes } from 'lib/models/probate-case'
import { WillCaseAttributes } from 'lib/models/will-case'

const StyledLink = styled(ExternalLink)`
  position: relative;

  &:not(:last-of-type) {
    padding-right: ${GTR.XS};
    margin-right: ${GTR.XS};

    &:after {
      content: '•';
      position: absolute;
      right: 0;
      transform: translateX(50%);
    }
  }
`

const getZendeskSearchLink = (email: Contact['email']) => {
  const query = `email:${email}`
  return `https://farewill.zendesk.com/agent/search/1?q=${encodeURI(query)}`
}

const getProbateGoogleDriveSearchLink = (reference: string) => {
  const query = `title: "${reference}" type:folder parent:16ZRYWmZ0JqgXd88fcSd_3Hv1IWlqhI6p`
  return `https://drive.google.com/drive/u/0/search?ths=true&q=${encodeURI(
    query
  )}`
}

const getWillGoogleDriveSearchLink = (
  primaryContact: Contact,
  reference: string
) => {
  const title = `(title: "${getFullName(
    primaryContact
  )}" OR title: "${reference}")`
  const query = `${title} type:folder`
  return `https://drive.google.com/drive/u/0/search?ths=true&q=${encodeURI(
    query
  )}`
}

const getFuneralGoogleDriveSearchLink = (reference: string) => {
  const query = `title: "${reference}" type:folder parent:1-z80jOv3S0aiB-aWcHhDMH67WTf6zH_m`
  return `https://drive.google.com/drive/u/0/search?ths=true&q=${encodeURI(
    query
  )}`
}

type ExternalLinks = { href: string; text: string }[]

const externalLinks = (links: ExternalLinks) => {
  return (
    <P size="S">
      {links.map(({ href, text }) => (
        <StyledLink href={href} key={text}>
          {text}
        </StyledLink>
      ))}
    </P>
  )
}

export const ProbateExternalLinks = ({
  attributes,
}: {
  attributes: ProbateCaseAttributes
}) => {
  const { referredTo, contact, reference } = attributes

  const isReferredToFWLS = referredTo === FAREWILL_LEGAL_SERVICES

  const email = contact?.email
  let links: ExternalLinks = []

  if (email)
    links = [
      {
        href: getZendeskSearchLink(email),
        text: 'Zendesk',
      },
      {
        href: getFrontLink(email),
        text: 'Front',
      },
      ...links,
    ]

  if (isReferredToFWLS && reference) {
    links = [
      {
        href: getProbateGoogleDriveSearchLink(reference),
        text: 'Google Drive',
      },
      ...links,
    ]
  }

  return externalLinks(links)
}

export const FuneralExternalLinks = ({
  attributes,
  tasks,
}: {
  attributes: FuneralAttributes
  tasks?: Task[]
}) => {
  const { contact, reference } = attributes

  const isFactFindCompleted = !!find(tasks, (task) => {
    return task.attributes.coreTask === 'fact_find_completed'
  })
  const email = contact?.email

  const links = []

  if (isFactFindCompleted && reference) {
    links.push({
      href: getFuneralGoogleDriveSearchLink(reference),
      text: 'Google Drive',
    })
  }

  if (email) {
    links.push({
      href: getFrontLink(email),
      text: 'Front',
    })
  }

  if (links.length === 0) {
    return null
  }

  return externalLinks(links)
}

export const WillExternalLinks = ({
  attributes,
}: {
  attributes: WillCaseAttributes
}) => {
  const { primaryContact, reference } = attributes

  const email = primaryContact?.email

  let links =
    primaryContact && reference
      ? [
          {
            href: getWillGoogleDriveSearchLink(primaryContact, reference),
            text: 'GDrive',
          },
        ]
      : []

  if (email) {
    links = [
      {
        href: getZendeskSearchLink(email),
        text: 'Zendesk',
      },
      ...links,
    ]
  }

  return externalLinks(links)
}
