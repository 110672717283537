import React from 'react'
import { Grid, H, P } from '@farewill/ui'
import { useFormikContext } from 'formik'
import { SCOTLAND_FEATURE_FLAG_CONFIG } from 'utils/enums'
import useFeatureFlag from 'hooks/useFeatureFlag'
import { FormikValues, quoteIsComplete } from './helpers'
import MessageBox from '../../../components/message-box'

const isReadyForConversion = (
  values: FormikValues,
  isScotlandEnabled: boolean
) => {
  const { needsSupportFromDwp, dwpSupportConfirmed } = values.quoteAttributes
  const isDwpCase = needsSupportFromDwp === 'true'

  return (
    quoteIsComplete({ values, isScotlandEnabled }) &&
    (needsSupportFromDwp === 'false' ||
      (isDwpCase && dwpSupportConfirmed === 'true'))
  )
}

const showInvoiceReminderMessage = (values: FormikValues) => {
  const { needsSupportFromDwp, dwpSupportConfirmed } = values.quoteAttributes
  const isDwpCase = needsSupportFromDwp === 'true'
  const confirmed = dwpSupportConfirmed === 'true'
  if (!isDwpCase || !confirmed) return null

  return (
    <Grid.Item margin={['M', 0]}>
      <MessageBox warning={undefined} bold={false} margin={0} stretch={false}>
        Once the invoice is paid you must confirm that payment has been received
        on the case.
      </MessageBox>
    </Grid.Item>
  )
}

const ConvertCase = ({
  isReferredAndPaid,
}: {
  isReferredAndPaid: boolean
}): React.ReactElement => {
  const { values } = useFormikContext<FormikValues>()

  const isScotlandEnabled =
    useFeatureFlag(SCOTLAND_FEATURE_FLAG_CONFIG) === 'false' ? false : true

  const showConversionMessage = isReadyForConversion(values, isScotlandEnabled)

  return (
    <Grid margin={['L', 0]}>
      <Grid.Item span={6}>
        <H size="S">Convert case</H>
        <Grid.Item margin={['M', 0]}>
          {showConversionMessage ? (
            <>
              {isReferredAndPaid && (
                <P>
                  This case is a redeemed funeral plan and no additional payment
                  is required.
                </P>
              )}
              <P>
                If the customer is happy for their loved one to be collected,
                convert the case and fill in fact find.
              </P>
            </>
          ) : (
            '↑ Complete sections above first'
          )}
        </Grid.Item>
        {showInvoiceReminderMessage(values)}
      </Grid.Item>
    </Grid>
  )
}

export default ConvertCase
