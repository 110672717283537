import PropTypes from 'prop-types'

const ConditionalWrapper = ({ children, wrapper, showWrapper }) => {
  return showWrapper ? wrapper(children) : children
}

ConditionalWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  wrapper: PropTypes.func.isRequired,
  showWrapper: PropTypes.bool.isRequired,
}

export default ConditionalWrapper
