import { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import styled, { ThemeProvider } from 'styled-components'
import { Button, ChevronLeftIcon, Grid, SwapIcon, Wrapper } from '@farewill/ui'
import { COLOR, FONT, GTR } from '@farewill/ui/tokens'
import { smallCaps } from '@farewill/ui/helpers/text'
import { useLocation } from 'react-router-dom'

import { COMPLETED_SECTIONS } from 'components/side-navigation/config'
import SideNavigation from 'components/side-navigation'
import StickyWrapper from './sticky-wrapper'
import TasksList from './tasks-list'

import { PRODUCTS_WITH_TASKS } from './constants'

const StyledOuterWrapper = styled.div`
  height: 0;
`

const StyledButtonWrapper = styled(Wrapper)`
  display: flex;
  padding-bottom: ${GTR.S};
  margin-bottom: ${GTR.M};
  border-bottom: 1px solid ${COLOR.GREY.LIGHT};
`

const StyledExpandButton = styled(Button)`
  ${smallCaps}

  color: ${COLOR.GREY.MEDIUM};
  margin: 0;
`

const StyledFactFindMenuButton = styled(Button)`
  color: ${COLOR.GREY.MEDIUM};
  font-size: ${FONT.SIZE.S};
  font-weight: ${FONT.WEIGHT.REGULAR};
  margin-left: auto;
`

const StyledIconChevron = styled(ChevronLeftIcon)`
  margin-right: ${GTR.XS};

  ${({ $rotate }) => $rotate && `transform: rotate(-180deg);`}
`

const StyledIconArrowSwitch = styled(SwapIcon)`
  margin-right: ${GTR.XS};

  ${({ $flip }) => $flip && `transform: scaleX(-1);`}
`

const StyledBackdrop = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  top: 0;
  right: 0;
  background: transparent;
  z-index: 2;
`

const TasksPanel = ({
  queryParams,
  product,
  productId,
  productRow,
  saveProductRow,
  showFactFindMenu,
  stickyContent,
  highlightEmpty,
  setHighlightEmpty,
}) => {
  const panelEl = useRef(null)
  const location = useLocation()
  const [isExpanded, setIsExpanded] = useState(false)
  const [isFactFindMode, setIsFactFindMode] = useState(false)

  useEffect(() => {
    const taskIdInHash = location.hash.includes('#task-')
    if (taskIdInHash) setIsExpanded(true)
  }, [location.hash])

  const saveSections = async (sections) => {
    await saveProductRow({ name: COMPLETED_SECTIONS, value: sections })
  }

  return (
    <ThemeProvider theme={{ isExpanded }}>
      {isExpanded && <StyledBackdrop onClick={() => setIsExpanded(false)} />}
      <Grid gap="XL">
        <Grid.Item span={isExpanded ? 10 : 4} startColumn={isExpanded ? 3 : 9}>
          <StyledOuterWrapper>
            <StickyWrapper
              isExpanded={isExpanded}
              stickyContent={stickyContent}
              ref={panelEl}
            >
              <StyledButtonWrapper>
                {!isFactFindMode && (
                  <StyledExpandButton
                    type="button"
                    onClick={() => setIsExpanded(!isExpanded)}
                  >
                    <StyledIconChevron size="S" $rotate={isExpanded} />
                    {isExpanded ? 'Hide' : 'Expand'}
                  </StyledExpandButton>
                )}
                {showFactFindMenu && (
                  <StyledFactFindMenuButton
                    type="button"
                    onClick={() => {
                      setIsExpanded(false)
                      setIsFactFindMode(!isFactFindMode)
                    }}
                  >
                    <StyledIconArrowSwitch size="S" $flip={isFactFindMode} />
                    {isFactFindMode ? 'Tasks' : 'Fact find menu'}
                  </StyledFactFindMenuButton>
                )}
              </StyledButtonWrapper>
              {isFactFindMode ? (
                <SideNavigation
                  linkTo={`/probate/estates/${productRow.id}/fact-find`}
                  product={product}
                  saveSections={saveSections}
                  sections={productRow.attributes?.completedSections}
                  highlightEmpty={highlightEmpty}
                  setHighlightEmpty={setHighlightEmpty}
                />
              ) : (
                <TasksList
                  queryParams={queryParams}
                  product={product}
                  isPanelExpanded={isExpanded}
                  setPanelExpanded={setIsExpanded}
                  productId={productId}
                />
              )}
            </StickyWrapper>
          </StyledOuterWrapper>
        </Grid.Item>
      </Grid>
    </ThemeProvider>
  )
}

TasksPanel.propTypes = {
  queryParams: PropTypes.object.isRequired,
  product: PropTypes.oneOf(PRODUCTS_WITH_TASKS).isRequired,
  productId: PropTypes.object.isRequired,
  showFactFindMenu: PropTypes.bool,
  productRow: PropTypes.object,
  saveProductRow: PropTypes.func,
  stickyContent: PropTypes.oneOf([
    'probate-case',
    'probate-estate',
    'lpa-case',
    'funeral-case',
    'will-case',
  ]),
  highlightEmpty: PropTypes.bool,
  setHighlightEmpty: PropTypes.func,
}

TasksPanel.defaultProps = {
  showFactFindMenu: false,
  productRow: null,
  saveProductRow: null,
  queryParams: PropTypes.object.isRequired,
  highlightEmpty: false,
  setHighlightEmpty: null,
}

export default TasksPanel
