import ENV from 'config/environment'
import { FAREWILL_PRODUCTS } from 'utils/enums'

export enum BookingType {
  SALES_BOOKING = 'sales_booking',
  SALES_MANAGE = 'sales_manage',
  FACT_FIND_BOOKING = 'fact_find_booking',
  FACT_FIND_MANAGE = 'fact_find_manage',
}

type SalesBooking = {
  type: BookingType.SALES_BOOKING
}

type SalesManage = {
  type: BookingType.SALES_MANAGE
  leadUuid: string
  calendlyEventId: string
  startTime: string
}

type FactFindBooking = {
  type: BookingType.FACT_FIND_BOOKING
  leadUuid: string
  phoneNumber: string
  email: string
  firstName: string
  calendlyUrl: string
}

type FactFindManage = {
  type: BookingType.FACT_FIND_MANAGE
  leadUuid: string
  calendlyEventId: string
  startTime: string
}

type GetBookingURLConfig =
  | SalesBooking
  | SalesManage
  | FactFindBooking
  | FactFindManage

const getWillBookingURL = (config: GetBookingURLConfig) => {
  const baseURL = `${ENV.ACCOUNT_APP_URL}/booking`
  const product = `${FAREWILL_PRODUCTS.WILL}`

  switch (config.type) {
    case BookingType.SALES_BOOKING: {
      return `${baseURL}/${product}`
    }
    case BookingType.SALES_MANAGE: {
      return `${baseURL}/manage/${product}/${config.leadUuid}/${
        config.calendlyEventId
      }?scheduledNextCallAt=${encodeURIComponent(config.startTime)}`
    }

    case BookingType.FACT_FIND_BOOKING: {
      return `${baseURL}/${product}/${config.leadUuid}?phoneNumber=${
        config.phoneNumber
      }&email=${config.email}&name=${
        config.firstName
      }&calendlyUrl=${encodeURIComponent(config.calendlyUrl)}`
    }
    case BookingType.FACT_FIND_MANAGE: {
      return `${baseURL}/manage/${product}/${config.leadUuid}/${
        config.calendlyEventId
      }?callType=fact_find&scheduledNextCallAt=${encodeURIComponent(
        config.startTime
      )}`
    }
  }
}

export default getWillBookingURL
