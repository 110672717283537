import { P } from '@farewill/ui'

import { formatToHuman } from 'utils/helpers'
import { ValidationError } from './types'

const ValidationToast = ({
  errors,
}: {
  errors: ValidationError[]
}): React.ReactElement => (
  <>
    {errors.map((error, index) => (
      <P margin={0} key={index}>
        <strong>{formatToHuman(error.name)} not saved:</strong> {error.detail}
      </P>
    ))}
  </>
)

export default ValidationToast
