import styled from 'styled-components'
import { ArrowUpIcon } from '@farewill/ui'
import { COLOR, GTR } from '@farewill/ui/tokens'
import HelpNeededHand from 'components/help-needed-hand'

const StyledArrowUpIcon = styled(ArrowUpIcon)<{
  $overlap?: boolean
}>`
  ${({ $overlap }) => $overlap && `margin-left: -${GTR.XXS};`}
`

type Props = {
  hasTaskNeedingHelp?: boolean
  isPriority: boolean
}

const CaseIcons = ({
  hasTaskNeedingHelp = false,
  isPriority,
}: Props): React.ReactElement => {
  return (
    <>
      {hasTaskNeedingHelp && <HelpNeededHand />}
      {isPriority && (
        <StyledArrowUpIcon
          size="S"
          color={COLOR.WHITE}
          background={COLOR.STATE.WARNING}
          $overlap={hasTaskNeedingHelp}
        />
      )}
    </>
  )
}

export default CaseIcons
