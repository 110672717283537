import { useEffect } from 'react'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { Grid, P, Wrapper } from '@farewill/ui'
import { GTR } from '@farewill/ui/tokens'
import { RouteComponentProps } from 'react-router-dom'
import styled from 'styled-components'

import { fetchLpaCase, fetchLead, unsetProduct } from 'state/actions'
import { FAREWILL_PRODUCTS } from 'utils/enums'
import ENV from 'config/environment'
import { useFetchResource, RESOURCES } from 'api'

import Header from 'components/product-header'
import NavigationTabs from 'components/navigation-tabs'
import PrivateRoute from 'components/private-route/containers'
import TasksPanel from 'components/tasks-panel'
import { TasksProvider } from 'components/tasks-panel/context'

import Activity from 'components/activity'
import { Lead } from 'lib/models/lead'
import { LpaCase } from 'lib/models/lpa-case'

import Quote from './quote/index'
import CaseOverview from './case-overview'
import WillSuiteStatusBanner from './willsuite-status-banner'

const StyledWrapper = styled(Wrapper)`
  position: relative;
`

const getNavLinks = (lpaCase: LpaCase) => {
  const { id } = lpaCase
  const navLinks = [
    { label: 'Case details', url: `/lpa-cases/${id}` },
    { label: 'Activity', url: `/lpa-cases/${id}/activity` },
    { label: 'Quote', url: `/lpa-cases/${id}/quote` },
  ]

  return navLinks
}

const LpaCaseOverview = ({ match }: Props) => {
  const dispatch = useDispatch()
  const lpaCaseQuery = useFetchResource<LpaCase>({
    id: Number(match.params.id),
    resource: RESOURCES.LPA_CASES,
  })

  const { lead } = useSelector(
    (state: { lead: Lead; lpaCase: LpaCase }) => ({
      lead: state.lead.attributes && state.lead,
    }),
    shallowEqual
  )

  const lpaCase = lpaCaseQuery?.data
  const lpaCaseId = lpaCaseQuery?.data?.id || Number(match.params.id)
  const leadId = lpaCase?.attributes.leadId

  useEffect(() => {
    if (lpaCaseId) dispatch(fetchLpaCase(lpaCaseId))
  }, [dispatch, lpaCaseId])

  useEffect(() => {
    if (leadId) dispatch(fetchLead(leadId))
  }, [dispatch, leadId])

  useEffect(() => {
    return () => {
      dispatch(unsetProduct(FAREWILL_PRODUCTS.LPA))
    }
  }, [dispatch])

  if (!lpaCase || !lead) return <P size="L">Loading...</P>

  return (
    <StyledWrapper data-sticky-content="lpa-case" padding={[GTR.XL, 0, 0]}>
      <TasksPanel
        queryParams={{
          'filter[lpaCaseId]': lpaCase.id,
          'filter[isNeeded]': true,
          'page[size]': 200,
        }}
        product={FAREWILL_PRODUCTS.LPA}
        productId={{ lpaCaseId: lpaCase.id }}
        stickyContent="lpa-case"
      />
      {ENV.FF_WILLSUITE_ENABLED && (
        <WillSuiteStatusBanner
          lpaCaseId={
            typeof lpaCaseId === 'string' ? parseInt(lpaCaseId) : lpaCaseId
          }
        />
      )}
      <Header
        attributes={lpaCase.attributes}
        productType={FAREWILL_PRODUCTS.LPA}
      />
      <Wrapper separator>
        <NavigationTabs links={getNavLinks(lpaCase)} />
      </Wrapper>
      <Grid separator>
        <Grid.Item span={7}>
          <PrivateRoute
            path={match.path}
            component={CaseOverview}
            id={lpaCaseId}
            exact
          />
        </Grid.Item>
        <Grid.Item span={7}>
          <PrivateRoute
            path={`${match.path}/activity`}
            component={Activity}
            leadId={leadId}
            product="lpa"
            showEmailFilter
            showCallsFilter
            showPaymentsFilter
          />
        </Grid.Item>
        <Grid.Item span={7}>
          <PrivateRoute
            path={`${match.path}/quote`}
            component={Quote}
            lpaCaseId={lpaCaseId}
          />
        </Grid.Item>
      </Grid>
    </StyledWrapper>
  )
}

type Props = RouteComponentProps<{
  id: string
}>

const LpaCaseOverviewWithProviders = (props: Props) => (
  <TasksProvider product={FAREWILL_PRODUCTS.LPA}>
    <LpaCaseOverview {...props} />
  </TasksProvider>
)

export default LpaCaseOverviewWithProviders
