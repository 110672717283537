import styled from 'styled-components'
import { Button, DeleteIcon } from '@farewill/ui'
import { GTR } from '@farewill/ui/tokens'
import { useDispatch } from 'react-redux'

import Table from 'components/table'
import { PartnerAccount } from 'lib/models/partner-account'
import { showModal } from 'state/actions'

import { RemoveAccountModal } from './remove-account-modal'
import { UpdateAccountModal } from './update-account-modal'

interface RowProps {
  partnerAccount: PartnerAccount
  isPartnerDataSharingEnabledPartner: boolean
}

const StyledViewPreferencesButton = styled(Button.Underline)`
  padding: ${GTR.XXS} 0;
`

const StyledRemoveButton = styled(Button.Underline)`
  svg {
    height: 16px;
    width: 16px;
  }
`

const Row = ({
  partnerAccount,
  isPartnerDataSharingEnabledPartner,
}: RowProps): React.ReactElement => {
  const { id, email } = partnerAccount
  const dispatch = useDispatch()

  const onOpenPreferences = () => {
    dispatch(
      showModal({
        component: UpdateAccountModal,
        headingText: `Preferences for ${email}`,
        config: { partnerAccount, isPartnerDataSharingEnabledPartner },
      })
    )
  }

  const onRemoveClick = () => {
    dispatch(
      showModal({
        component: RemoveAccountModal,
        headingText: 'Confirm you want to remove a user',
        config: { id, email },
      })
    )
  }

  return (
    <Table.Row gridTemplateCols="6fr 1fr">
      <Table.Col>
        {email}
        <br />
        <StyledViewPreferencesButton type="button" onClick={onOpenPreferences}>
          View preferences
        </StyledViewPreferencesButton>
      </Table.Col>
      <Table.Col>
        <StyledRemoveButton
          iconOnLeft
          icon={DeleteIcon}
          type="button"
          size="S"
          onClick={onRemoveClick}
        >
          Remove
        </StyledRemoveButton>
      </Table.Col>
    </Table.Row>
  )
}
export default Row
