import { P, Wrapper } from '@farewill/ui'

import { formatDate } from 'lib/formatting/dates'
import { getGraphQLClient } from 'api/graphql'
import { Account, useAccountByUuidQuery } from 'api/generated/graphql'

const AccountDetails = ({
  accountUuid,
}: {
  accountUuid: string
}): React.ReactElement => {
  const client = getGraphQLClient()
  const { data, isIdle, isLoading } = useAccountByUuidQuery(
    client,
    { uuid: accountUuid },
    { enabled: !!accountUuid }
  )

  if (!accountUuid) {
    return <P>No account registered.</P>
  }

  if (isIdle || isLoading) {
    return <P>Loading...</P>
  }

  const account = data?.account as Account

  return (
    <Wrapper>
      {'code' in account ? (
        <P>No account registered.</P>
      ) : (
        <P>
          Account registered at <strong>{account.email}</strong> on{' '}
          {formatDate(account.createdAt)}.
        </P>
      )}
    </Wrapper>
  )
}

export default AccountDetails
