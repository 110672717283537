import { Grid, H, Image, P, Wrapper } from '@farewill/ui'
import { COLOR, GTR } from '@farewill/ui/tokens'
import { useFormikContext } from 'formik'

import SectionStatus from 'components/section-status'
import Sticky from '../sticky-section'
import FuneralQuoteFormFields from 'components/funeral-quote-form-fields'
import { formatCurrency } from 'lib/formatting/currency'
import { useLeadContext } from 'routes/lead/context'

import Dinero from 'dinero.js'
import { FormikValues } from './helpers'
Dinero.globalLocale = 'en-GB'
Dinero.defaultCurrency = 'GBP'

const formatPrice = (amountInPence: number) =>
  formatCurrency({
    showPence: false,
    value: amountInPence,
    valueInPence: true,
  })

const formatQuotedPrices = ({ price }: { price: number }) => {
  if (price) {
    return formatPrice(price)
  }

  return ''
}

type QuoteProps = {
  isComplete: boolean
}

const Quote = ({ isComplete }: QuoteProps): React.ReactElement => {
  const { values } = useFormikContext<FormikValues>()
  // TODO refeactor quotedPrices away from an object
  const {
    calculatedPrice,
    quotedPrices: { price },
  } = values

  const quotedPrices = formatQuotedPrices({
    price,
  })

  const { lead } = useLeadContext()
  const isLeadFuneralPlanRedemption = !!lead.attributes.funeralPlanId

  return (
    <Sticky.Wrapper>
      <Grid>
        <Grid.Item span={6}>
          <Grid>
            <Grid.Item>
              <H size="S">Give a quote</H>
            </Grid.Item>
            {quotedPrices !== '' && (
              <Grid.Item>
                <Wrapper
                  background={COLOR.GREY.LIGHT}
                  padding={GTR.S}
                  borderRadius="S"
                >
                  <H size="XS">Cost estimate shown to the customer</H>
                  <P>{quotedPrices}</P>
                </Wrapper>
              </Grid.Item>
            )}
            <FuneralQuoteFormFields
              isLeadFuneralPlanRedemption={isLeadFuneralPlanRedemption}
            />
          </Grid>
        </Grid.Item>
        <Grid.Item span={5} startColumn={8}>
          <Wrapper style={{ textAlign: 'right', marginBottom: GTR.M }}>
            <Image
              path="illustrations/cropped/tortoise-with-coins"
              width={150}
              style={{ margin: '-20px 0px 0px' }}
            />
          </Wrapper>
          <Sticky.Item maxWidth={316}>
            <SectionStatus complete={isComplete} heading="Estimated quote">
              <H size="M">
                {Dinero({ amount: calculatedPrice || 0 }).toFormat('$0,0')}
              </H>
              <P>This is an estimate.</P>
            </SectionStatus>
          </Sticky.Item>
        </Grid.Item>
      </Grid>
    </Sticky.Wrapper>
  )
}

export default Quote
