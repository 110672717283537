import { Grid, H, P } from '@farewill/ui'
import { useFormikContext } from 'formik'
import { Link } from 'react-router-dom'

import withForm from '../fact-find/components/with-form'

import CircularRadioGroup from 'components/form/circular-radio-group'
import NotesInput from 'components/notes-input'
import MessageBox from 'components/message-box'

import {
  FACT_FIND_CHECKED,
  FACT_FIND_ERRORS_DETECTED,
  FACT_FIND_ERRORS_FIXED,
  IS_READY_FOR_REFERRAL,
  REFERRAL_TO_LEGAL_TEAM_NOTES,
} from './field-names'

const CheckAndApprove = ({ saveProbateEstateField }) => {
  const { values } = useFormikContext()
  const errorsDetected = values[FACT_FIND_ERRORS_DETECTED] === 'true'
  const isReadyForReferral = values[IS_READY_FOR_REFERRAL] === 'true'

  return (
    <Grid>
      <Grid.Item>
        <H size="S">2. Check and approve fact find</H>
      </Grid.Item>
      <Grid.Item>
        <CircularRadioGroup
          name={FACT_FIND_CHECKED}
          label="Fact find checked?"
          options={[
            { value: 'false', label: 'No' },
            { value: 'true', label: 'Yes' },
          ]}
          handleSave={saveProbateEstateField}
          inline
        />
      </Grid.Item>
      <Grid.Item>
        <CircularRadioGroup
          name={FACT_FIND_ERRORS_DETECTED}
          label="One or more errors detected?"
          options={[
            { value: '', label: 'Not specified' },
            { value: 'false', label: 'No' },
            { value: 'true', label: 'Yes' },
          ]}
          handleSave={saveProbateEstateField}
          inline
        />
      </Grid.Item>
      {errorsDetected && (
        <>
          <Grid.Item span={10}>
            <MessageBox warning stretch>
              <P size="S" strong>
                Fact find error detected
              </P>
              <P size="S">
                Please log the error on the{' '}
                <Link to={`/probate/cases/${values.probateCaseId}/errors`}>
                  Errors page
                </Link>
                .
              </P>
            </MessageBox>
          </Grid.Item>
          <Grid.Item>
            <CircularRadioGroup
              name={FACT_FIND_ERRORS_FIXED}
              label="Errors fixed?"
              options={[
                { value: '', label: 'Not specified' },
                { value: 'false', label: 'No' },
                { value: 'true', label: 'Yes' },
              ]}
              handleSave={saveProbateEstateField}
              inline
            />
          </Grid.Item>
        </>
      )}
      <Grid.Item>
        <CircularRadioGroup
          name={IS_READY_FOR_REFERRAL}
          label="Fact find approved and ready for referral?"
          options={[
            { label: 'No', value: 'false' },
            { label: 'Yes', value: 'true' },
          ]}
          handleSave={saveProbateEstateField}
          inline
        />
      </Grid.Item>
      <Grid.Item>
        <P size="S">If any notes to add for the legal team, add them below</P>
      </Grid.Item>
      {isReadyForReferral && (
        <Grid.Item span={10}>
          <MessageBox>
            <P size="S" strong>
              Approve and make referral
            </P>
            <P size="S">
              When you’re ready to approve the fact find and make the referral,
              update both sections on the{' '}
              <Link to={`/probate/cases/${values.probateCaseId}/progress`}>
                Progress page
              </Link>
              .
            </P>
          </MessageBox>
        </Grid.Item>
      )}
      <Grid.Item>
        <NotesInput
          name={REFERRAL_TO_LEGAL_TEAM_NOTES}
          handleSave={saveProbateEstateField}
        />
      </Grid.Item>
    </Grid>
  )
}

export default withForm(CheckAndApprove)
