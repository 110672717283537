import PropTypes from 'prop-types'
import { Divider, H, Grid } from '@farewill/ui'

import withForm from '../components/with-form'
import Properties from './properties'
import BankAccounts from './bank-accounts'
import NationalSavingsAndInvestments from './national-savings-and-investments'
import PublicShareholdings from './public-shareholdings'
import Investments from './investments'
import LifeInsurance from './life-insurance'
import PrivatePensions from './private-pensions'
import VehiclesAndPossessions from './vehicles-and-possessions'
import BusinessAndAgriculturalAssets from './business-and-agricultural-assets'
import OverseasAssets from './overseas-assets'
import MoneyOwed from './money-owed'
import Cash from './cash'

const Assets = ({ estateItems, probateEstateId, saveProbateEstateField }) => (
  <Grid gap="XL" data-scroll-id="4">
    <Grid.Item span={8}>
      <H size="M">4. Assets</H>
      <Properties
        probateEstateId={probateEstateId}
        saveProbateEstateField={saveProbateEstateField}
      />

      <Divider margin={['L', 0]} />
      <BankAccounts
        initialItems={estateItems.bankAccount}
        probateEstateId={probateEstateId}
        saveProbateEstateField={saveProbateEstateField}
      />

      <Divider margin={['L', 0]} />
      <Cash
        initialItems={estateItems.cash}
        probateEstateId={probateEstateId}
        saveProbateEstateField={saveProbateEstateField}
      />

      <Divider margin={['L', 0]} />
      <NationalSavingsAndInvestments
        initialItems={estateItems.nationalSavingsAndInvestments}
        probateEstateId={probateEstateId}
        saveProbateEstateField={saveProbateEstateField}
      />

      <Divider margin={['L', 0]} />
      <PublicShareholdings
        initialItems={estateItems.publicShareholding}
        probateEstateId={probateEstateId}
        saveProbateEstateField={saveProbateEstateField}
      />

      <Divider margin={['L', 0]} />
      <Investments
        initialItems={estateItems.investment}
        probateEstateId={probateEstateId}
        saveProbateEstateField={saveProbateEstateField}
      />

      <Divider margin={['L', 0]} />
      <LifeInsurance
        initialItems={estateItems.lifeInsurance}
        probateEstateId={probateEstateId}
        saveProbateEstateField={saveProbateEstateField}
      />

      <Divider margin={['L', 0]} />
      <PrivatePensions
        initialItems={estateItems.privatePension}
        probateEstateId={probateEstateId}
        saveProbateEstateField={saveProbateEstateField}
      />

      <Divider margin={['L', 0]} />
      <VehiclesAndPossessions
        initialItems={estateItems.vehicleOrPossession}
        probateEstateId={probateEstateId}
        saveProbateEstateField={saveProbateEstateField}
      />

      <Divider margin={['L', 0]} />
      <BusinessAndAgriculturalAssets
        initialItems={estateItems.businessOrAgriculturalAsset}
        probateEstateId={probateEstateId}
        saveProbateEstateField={saveProbateEstateField}
      />

      <Divider margin={['L', 0]} />
      <OverseasAssets
        initialItems={estateItems.overseasAsset}
        probateEstateId={probateEstateId}
        saveProbateEstateField={saveProbateEstateField}
      />
      <Divider margin={['L', 0]} />
      <MoneyOwed
        initialItems={estateItems.moneyOwed}
        probateEstateId={probateEstateId}
        saveProbateEstateField={saveProbateEstateField}
      />
    </Grid.Item>
  </Grid>
)

Assets.propTypes = {
  estateItems: PropTypes.object.isRequired,
  probateEstateId: PropTypes.string.isRequired,
  saveProbateEstateField: PropTypes.func.isRequired,
}

export default withForm(Assets)
