import { useEffect } from 'react'
import PropTypes from 'prop-types'
import groupBy from 'lodash/groupBy'
import isEqual from 'lodash/isEqual'
import { P, Wrapper } from '@farewill/ui'
import { useLocation } from 'react-router-dom'

import usePrevious from 'lib/effects/use-previous'

import { useTasksContext } from './context'
import TasksStage from './tasks-stage'
import { CORE_TASKS_IN_ORDER, PRODUCTS_WITH_TASKS } from './constants'
import { FAREWILL_PRODUCTS } from 'utils/enums'

import { getSortedTasks } from './tasks-sorter'

const taskIsComplete = (task) => task.attributes.completedAt

const getStageIsExpanded = ({ prevTasks, currentTasks }) => {
  const prevStageComplete = prevTasks?.every(taskIsComplete)
  const currentStageComplete = currentTasks?.every(taskIsComplete)

  return (!prevTasks || prevStageComplete) && !currentStageComplete
}

const TasksList = ({
  queryParams,
  product,
  isPanelExpanded,
  setPanelExpanded,
  productId,
}) => {
  const { tasks, fetchTasks, isFetchingTasks } = useTasksContext()
  const stages = CORE_TASKS_IN_ORDER[product]
  const prevQueryParams = usePrevious(queryParams)
  const location = useLocation()

  useEffect(() => {
    if (!isEqual(queryParams, prevQueryParams)) {
      fetchTasks({ queryParams })
    }
  }, [fetchTasks, queryParams, prevQueryParams])

  useEffect(() => {
    if (location.hash) {
      setTimeout(() => {
        const scrollId = location.hash.replace('#', '')
        const task = document.querySelector(`[data-scroll-id="${scrollId}"]`)
        if (task) task.scrollIntoView({ behavior: 'smooth' })
      }, 300)
    }
  }, [location.hash, isFetchingTasks])

  const sortedTasks = getSortedTasks(CORE_TASKS_IN_ORDER, tasks, product)
  const groupedTasks = groupBy(sortedTasks, 'attributes.stage')
  const stagesWithTasks = stages.filter(({ name }) => groupedTasks[name])
  const enableCommenting = [
    FAREWILL_PRODUCTS.PROBATE,
    FAREWILL_PRODUCTS.WILL,
  ].includes(product)

  if (isFetchingTasks) {
    return <P>Loading...</P>
  }

  return (
    <Wrapper>
      {stagesWithTasks.map((stage, index) => (
        <TasksStage
          productId={productId}
          tasks={groupedTasks[stage.name]}
          title={stage.title}
          enableCommenting={enableCommenting}
          key={stage.name}
          stage={stage.name}
          isPanelExpanded={isPanelExpanded}
          setPanelExpanded={setPanelExpanded}
          isStageExpanded={
            isPanelExpanded ||
            getStageIsExpanded({
              prevTasks: groupedTasks[stagesWithTasks[index - 1]?.name],
              currentTasks: groupedTasks[stage.name],
            })
          }
        />
      ))}
    </Wrapper>
  )
}

TasksList.propTypes = {
  queryParams: PropTypes.object.isRequired,
  product: PropTypes.oneOf(PRODUCTS_WITH_TASKS).isRequired,
  isPanelExpanded: PropTypes.bool.isRequired,
  setPanelExpanded: PropTypes.func.isRequired,
  productId: PropTypes.object.isRequired,
}

export default TasksList
