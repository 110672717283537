import { ReactElement } from 'react'
import { useFormikContext } from 'formik'
import styled from 'styled-components'
import { P, Wrapper } from '@farewill/ui'
import { COLOR } from '@farewill/ui/tokens'

import { strToBool } from 'utils/helpers'
import Switch from 'components/switch'
import Input from 'components/form/input'

const StyledWrapper = styled(Wrapper)`
  display: flex;
  align-items: center;
`

type FormValues = {
  isDifficult: 'true' | 'false'
  difficultReason: string | null
}

const Difficult = (): ReactElement => {
  const { values, handleSubmit, setFieldValue } = useFormikContext<FormValues>()

  const isDifficult = strToBool(values.isDifficult)

  const handleToggle = () => {
    if (isDifficult) setFieldValue('difficultReason', null) // We want to clear any difficultReason before setting isDifficult to false

    setFieldValue('isDifficult', !isDifficult)
    handleSubmit()
  }

  return (
    <>
      <StyledWrapper margin={[0, 0, 'S']}>
        <Switch
          color={COLOR.STATE.WARNING}
          handleToggle={handleToggle}
          isOn={isDifficult}
        />
        <P margin={[0, 0, 0, 'S']}>Difficult case</P>
      </StyledWrapper>
      {isDifficult && (
        <>
          <P margin={[0, 0, 'XS']}>Reason for difficult case</P>
          <Input name="difficultReason" handleSave={() => handleSubmit()} />
        </>
      )}
    </>
  )
}

export default Difficult
