import { ReactElement, useState } from 'react'
import styled from 'styled-components'
import { Button, Divider, Grid, P } from '@farewill/ui'
import { GTR } from '@farewill/ui/tokens'

import { SelectInput } from 'components/form'
import StatusPanel from 'components/status-panel'
import useApi from 'lib/effects/api'
import { formatCurrency } from 'lib/formatting/currency'

import {
  WILL_CASE_QUOTE_COMPLEXITIES,
  WILL_CASE_QUOTE_WILL_TYPE,
} from '../constants'
import {
  WillCaseCustomerNames,
  WillCaseQuoteAttributes,
  WillCaseQuoteResult,
} from './types'

const QuoteRow = styled.div`
  display: flex;
  justify-content: space-between;
`

const willTypesOptions = [
  { value: WILL_CASE_QUOTE_WILL_TYPE.SINGLE, label: 'Single' },
  { value: WILL_CASE_QUOTE_WILL_TYPE.COUPLE, label: 'Couple' },
]

const getComplexitiesOptions = (customerNames: WillCaseCustomerNames) => {
  const { leadName, partnerName } = customerNames

  const options = [
    { value: '', label: partnerName ? 'Neither will' : 'None' },
    { value: WILL_CASE_QUOTE_COMPLEXITIES.LEAD, label: `${leadName}’s will` },
  ]

  if (partnerName) {
    options.push(
      {
        value: WILL_CASE_QUOTE_COMPLEXITIES.PARTNER,
        label: `${partnerName}’s will`,
      },
      { value: WILL_CASE_QUOTE_COMPLEXITIES.BOTH, label: 'Both wills' }
    )
  }

  return options
}

export const WillCaseQuoteFormFields = ({
  isLoading,
  currentQuote,
  willCaseId,
  customerNames,
}: {
  isLoading: boolean
  currentQuote: WillCaseQuoteResult
  willCaseId: number
  customerNames: WillCaseCustomerNames
}): ReactElement => {
  const [hasMadeChanges, setHasMadeChanges] = useState(false)
  const [complexitiesPriceDifference, setComplexitiesPriceDifference] =
    useState<number>(0)
  const [newQuoteTotal, setNewQuoteTotal] = useState<number | undefined>(
    undefined
  )
  const complexitiesOptions = getComplexitiesOptions(customerNames)

  const [, makeRequest] = useApi()

  const fetchNewQuote = async (attributes: WillCaseQuoteAttributes) => {
    if (
      attributes.complexities ===
      ('' as WillCaseQuoteAttributes['complexities'])
    ) {
      attributes.complexities = null
    }

    const newQuoteData: { data: { attributes: WillCaseQuoteResult } } =
      await makeRequest({
        url: `/api/will-cases/${willCaseId}/quote`,
        method: 'POST',
        data: {
          data: {
            type: 'will_case_quote',
            attributes,
          },
        },
      })

    const newQuote = newQuoteData?.data?.attributes

    setComplexitiesPriceDifference(
      newQuote.complexityPriceInPence - currentQuote.complexityPriceInPence
    )
    setNewQuoteTotal(newQuote?.totalPriceInPence)
    setHasMadeChanges(true)
  }

  return (
    <>
      {/* Though willType is non-editable, we still want to display the willCase's willType */}
      <Grid.Item span={7}>
        <Grid.Item margin={[0, 0, GTR.L]}>
          <SelectInput
            options={willTypesOptions}
            label="Type of will (non-editable)"
            name="willType"
            disabled
          />
        </Grid.Item>
        <Grid.Item>
          <SelectInput
            options={complexitiesOptions}
            label="Complexities"
            name="complexities"
            handleSave={async (e) => {
              await fetchNewQuote({
                complexities:
                  e.value as WillCaseQuoteAttributes['complexities'],
              })
            }}
          />
        </Grid.Item>
      </Grid.Item>
      <Grid.Item span={5}>
        <StatusPanel heading="Edit quote" sticky>
          {hasMadeChanges ? (
            <>
              <P strong>1 change</P>
              <QuoteRow>
                <P>Complexities:</P>
                <P>
                  {complexitiesPriceDifference > 0 && '+'}
                  {formatCurrency({
                    value: complexitiesPriceDifference,
                    valueInPence: true,
                  })}
                </P>
              </QuoteRow>
              <Divider margin={[0, 0, 'M', 0]} />
              <QuoteRow>
                <P>Revised quote:</P>
                <P>
                  {formatCurrency({
                    value: newQuoteTotal,
                    valueInPence: true,
                  })}
                </P>
              </QuoteRow>
            </>
          ) : (
            <P>No changes made</P>
          )}
          <Button.Primary
            stretch
            disabled={!hasMadeChanges}
            loading={isLoading}
          >
            Save quote
          </Button.Primary>
        </StatusPanel>
      </Grid.Item>
    </>
  )
}

export default WillCaseQuoteFormFields
