import { createContext, useContext, useCallback } from 'react'

import useApi from 'lib/effects/api'
import useApiHelpers from 'lib/effects/api-helpers'

const InvoicesContext = createContext()

const useInvoicesContext = () => {
  const context = useContext(InvoicesContext)
  if (context === undefined) {
    throw new Error(
      'useInvoicesContext must be used within an InvoicesProvider'
    )
  }
  return context
}

const InvoicesProvider = ({ children }) => {
  const {
    items: invoices,
    fetchItems: fetchInvoices,
    createItem: createInvoice,
    isCreating: isCreatingInvoice,
    deleteItem: deleteInvoice,
    isDeleting: isDeletingInvoice,
  } = useApiHelpers({
    baseUrl: `/api/invoices`,
    type: 'invoices',
  })

  const [
    { isLoading: isUpdatingLineItem, errors: updateLineItemErrors },
    patchLineItem,
  ] = useApi()

  const updateLineItem = useCallback(
    (lineItem, attributes) => {
      patchLineItem({
        url: `/api/invoices/${lineItem.invoiceId}/line-items/${lineItem.id}`,
        method: 'PATCH',
        data: {
          data: { type: 'invoice_line_items', id: lineItem.id, attributes },
        },
      })
    },
    [patchLineItem]
  )

  const value = {
    invoices,
    fetchInvoices,
    createInvoice,
    isCreatingInvoice,
    deleteInvoice,
    isDeletingInvoice,
    updateLineItem,
    isUpdatingLineItem,
    updateLineItemErrors,
  }

  return (
    <InvoicesContext.Provider value={value}>
      {children}
    </InvoicesContext.Provider>
  )
}

export { InvoicesProvider, useInvoicesContext }
