import { useCallback, useReducer } from 'react'
import { useUpdateEffect } from 'react-use'

import useApi from 'lib/effects/api'

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_ITEMS':
      return action.payload

    case 'ADD_ITEM':
      return [...state, action.payload]

    case 'REMOVE_ITEM':
      return state.filter(({ id }) => id !== action.payload)

    case 'REPLACE_ITEM':
      return state.map((item) => {
        if (item?.id === action.payload?.id) {
          return action.payload
        } else {
          return item
        }
      })

    default:
      throw new Error()
  }
}

const useApiHelpers = ({ baseUrl, type }) => {
  const [state, dispatch] = useReducer(reducer, [])

  const [
    {
      data: fetchData,
      isLoading: isFetching,
      errors: fetchErrors,
      meta: fetchMeta,
    },
    fetch,
  ] = useApi({ data: [] })

  const fetchItems = useCallback(
    async ({ queryParams = {} } = {}) => {
      const url = new URL(baseUrl, window.location.origin)

      for (const key of Object.keys(queryParams)) {
        url.searchParams.append(key, queryParams[key])
      }

      fetch({ url })
    },
    [baseUrl, fetch]
  )

  useUpdateEffect(() => {
    dispatch({ type: 'SET_ITEMS', payload: fetchData })
  }, [fetchData])

  const [
    {
      data: createData,
      isLoading: isCreating,
      errors: createErrors,
      meta: createMeta,
    },
    create,
  ] = useApi()

  const createItem = useCallback(
    async (attributes) => {
      const response = await create({
        url: baseUrl,
        method: 'POST',
        data: { data: { attributes, type } },
      })
      return response.data
    },
    [baseUrl, create, type]
  )

  useUpdateEffect(() => {
    dispatch({ type: 'ADD_ITEM', payload: createData })
  }, [createData])

  const [
    {
      data: updateData,
      isLoading: isUpdating,
      errors: updateErrors,
      meta: updateMeta,
    },
    update,
  ] = useApi()

  const updateItem = useCallback(
    async (id, attributes) => {
      await update({
        url: `${baseUrl}/${id}`,
        method: 'PATCH',
        data: { data: { type, id, attributes } },
      })
    },
    [baseUrl, type, update]
  )

  useUpdateEffect(() => {
    dispatch({ type: 'REPLACE_ITEM', payload: updateData })
  }, [updateData])

  const [
    { isLoading: isDeleting, errors: deleteErrors, meta: deleteMeta },
    del,
  ] = useApi()

  const deleteItem = useCallback(
    async (id) => {
      await del({ url: `${baseUrl}/${id}`, method: 'DELETE' })
      dispatch({ type: 'REMOVE_ITEM', payload: id })
    },
    [baseUrl, del]
  )

  const [
    {
      data: actionData,
      isLoading: isActionLoading,
      errors: actionErrors,
      meta: actionMeta,
    },
    action,
  ] = useApi()

  const actionItem = useCallback(
    async (id, actionName, attributes = {}) => {
      await action({
        url: `${baseUrl}/${id}/actions/${actionName}`,
        method: 'POST',
        data: { data: { type, id, attributes } },
      })
    },
    [baseUrl, type, action]
  )

  useUpdateEffect(() => {
    dispatch({ type: 'REPLACE_ITEM', payload: actionData })
  }, [actionData])

  return {
    items: state,
    fetchItems,
    isFetching,
    fetchErrors,
    fetchMeta,
    createItem,
    isCreating,
    createErrors,
    createMeta,
    updateItem,
    isUpdating,
    updateErrors,
    updateMeta,
    deleteItem,
    isDeleting,
    deleteErrors,
    deleteMeta,
    actionItem,
    isActionLoading,
    actionErrors,
    actionMeta,
  }
}

export default useApiHelpers
