import moment from 'moment'

export const getDateRangePresetOptions = () => {
  return [
    {
      label: 'Overdue',
      name: 'overdue',
      gte: '2019-01-01',
      lte: moment().subtract(1, 'days').format('YYYY-MM-DD'),
    },
    {
      label: 'Today',
      name: 'today',
      gte: moment().format('YYYY-MM-DD'),
      lte: moment().format('YYYY-MM-DD'),
    },
    {
      label: 'Tomorrow',
      name: 'tomorrow',
      gte: moment().add(1, 'days').format('YYYY-MM-DD'),
      lte: moment().add(1, 'days').format('YYYY-MM-DD'),
    },
    {
      label: 'Next 3 days',
      name: 'nextThreeDays',
      gte: moment().format('YYYY-MM-DD'),
      lte: moment().add(3, 'days').format('YYYY-MM-DD'),
    },
    {
      label: 'Next 7 days',
      name: 'nextSevenDays',
      gte: moment().format('YYYY-MM-DD'),
      lte: moment().add(7, 'days').format('YYYY-MM-DD'),
    },
  ]
}

export const getDateRange = (preset) => {
  const selectedOptions = getDateRangePresetOptions().filter(
    (option) => preset[option.name]
  )

  const gte = selectedOptions.reduce((acc, option) => {
    if (!acc) return option.gte
    if (moment(option.gte).isBefore(acc)) return option.gte
    return acc
  }, '')

  const lte = selectedOptions.reduce((acc, option) => {
    if (!acc) return option.lte
    if (moment(option.lte).isAfter(acc)) return option.lte
    return acc
  }, '')

  return { gte, lte }
}

export const getDaysFromToday = (date) => {
  const daysAgo = moment().diff(moment(date), 'days')

  if (daysAgo === 1) return '1 day'
  return `${daysAgo} days`
}

export const formatGraphQLmeta = (result) => {
  if (result) {
    return {
      page: { total: result.totalCount },
      cursors: {
        pageStart: result.pageInfo.startCursor,
        pageEnd: result.pageInfo.endCursor,
      },
    }
  }
}
