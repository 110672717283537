import Dinero from 'dinero.js'
import { toast } from 'react-toastify'
import partition from 'lodash/partition'
import { Button, Wrapper } from '@farewill/ui'
import { EstateAdminBreakdownItem, QuoteResponseDataProbate } from 'lib/types'

const handleCopyClick = (text: string) => {
  navigator.clipboard.writeText(text)

  toast('Copied to clipboard.', {
    toastId: 'estate-admin-copied-to-clipboard',
  })
}

const BreakdownRows = (rows: EstateAdminBreakdownItem[]) =>
  `${rows
    .map(
      ({ amount, reason }) =>
        `${reason}: ${Dinero({ amount }).toFormat('$0,0.00')}`
    )
    .join('\n')}`

const EstateAdminCopyToClipboard = ({
  quote,
}: {
  quote: QuoteResponseDataProbate
}) => {
  const { amountInPence, breakdown } = quote.attributes.estateAdmin
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, noVat] = partition(breakdown, ({ vat = true }) => vat)

  const feeExcVat = `Fee exc. VAT: ${Dinero({
    amount: quote.attributes.estateAdmin.amountBeforeVatInPence,
  }).toFormat('$0,0.00')}`

  const twentyPercentVat = `20% VAT: ${Dinero({
    amount: quote.attributes.estateAdmin.amountBeforeVatInPence,
  })
    .percentage(20)
    .toFormat('$0,0.00')}`

  const noVatRows = BreakdownRows(noVat)

  const totalPrice = `\nTotal: ${Dinero({
    amount: amountInPence,
  }).toFormat('$0,0.00')}`

  const fees = [feeExcVat, twentyPercentVat, noVatRows, totalPrice].join('\n')

  return (
    <Wrapper margin={['L', 0, 0]} centered>
      <Button.Primary
        onClick={() => {
          handleCopyClick(fees)
        }}
      >
        Copy quote to clipboard
      </Button.Primary>
    </Wrapper>
  )
}

export default EstateAdminCopyToClipboard
