import styled from 'styled-components'
import { useFormikContext } from 'formik'
import moment from 'moment'
import { screenMin } from '@farewill/ui/helpers/responsive'
import { H, Grid } from '@farewill/ui'
import { GTR } from '@farewill/ui/tokens'

import InputFloating from 'components/form/input-floating'
import MessageBox from 'components/message-box'
import CircularRadioGroup from 'components/form/circular-radio-group'
import Address from 'routes/probate-estate/fact-find/components/address'
import { FUNERAL_MARITAL_STATUS_OPTIONS } from 'utils/enums'

const StyledGrid = styled(Grid)`
  ${screenMin.l`  
    row-gap: ${GTR.XS};
    `}
`

const PersonWhoHasDied = ({ handleSave }) => {
  const { values } = useFormikContext()

  const dateOfBirth = values.dateOfBirth && moment(values.dateOfBirth)
  const dateOfDeath = values.dateOfDeath && moment(values.dateOfDeath)
  const age =
    dateOfBirth && dateOfDeath && dateOfDeath.diff(dateOfBirth, 'years')

  return (
    <Grid>
      <Grid.Item>
        <H size="M">2. The person who has died</H>
      </Grid.Item>
      <Grid.Item>
        <Grid>
          <Grid.Item span={2}>
            <InputFloating name="title" label="Title" handleSave={handleSave} />
          </Grid.Item>
          <Grid.Item span={5}>
            <InputFloating
              name="firstNames"
              label="First and middle name"
              handleSave={handleSave}
            />
          </Grid.Item>
          <Grid.Item span={5}>
            <InputFloating
              name="lastName"
              label="Last name"
              handleSave={handleSave}
            />
          </Grid.Item>
          <Grid.Item span={7}>
            <InputFloating
              name="knownAs"
              label="They’re informally known as"
              hint="E.g. 'John' instead of Jonathan"
              handleSave={handleSave}
            />
          </Grid.Item>
          <Grid.Item spanFromL={7}>
            <Address
              label="Address"
              name="personWhoHasDiedAddress"
              data={values.personWhoHasDiedAddress}
            />
          </Grid.Item>
          <Grid.Item
            spanFromL={5}
            startColumnFromL={8}
            startRowFromL={3}
            marginFromL={
              values.personWhoHasDiedAddress.lineOne ? ['L', 0, 0] : [0]
            }
          >
            <MessageBox margin={['S', 0, 'M']}>
              Use the Care Home address, if the person was living there at the
              time.
            </MessageBox>
          </Grid.Item>
        </Grid>
      </Grid.Item>
      <Grid.Item>
        <StyledGrid gap="M">
          <Grid.Item spanFromL={7}>
            <Grid>
              <Grid.Item span={5} spanFromL={6}>
                <InputFloating
                  type="date"
                  name="dateOfBirth"
                  label="Date of birth"
                  handleSave={handleSave}
                />
              </Grid.Item>
              <Grid.Item span={5} spanFromL={6} startColumnFromL={7}>
                <InputFloating
                  type="date"
                  name="dateOfDeath"
                  label="Date of death"
                  handleSave={handleSave}
                />
              </Grid.Item>
              <Grid.Item spanFromL={7}> Age at death: {age}</Grid.Item>
            </Grid>
          </Grid.Item>
          <Grid.Item
            startRow={3}
            startRowFromL={1}
            spanFromL={5}
            startColumnFromL={8}
          >
            {values.dateOfDeath ? (
              <MessageBox margin={['S', 0, 'M']}>
                If you change the date of birth, and the person's age at death
                is the same, there's no need to resend Form 1.
              </MessageBox>
            ) : (
              <MessageBox warning margin={['S', 0, 'M']}>
                The date of death is needed to determine which version of Form 1
                to use. Please select a date.
              </MessageBox>
            )}
          </Grid.Item>
          <Grid.Item span={4}>
            <InputFloating
              type="time"
              name="timeOfDeath"
              label="Time of death"
              handleSave={handleSave}
            />
          </Grid.Item>
        </StyledGrid>
      </Grid.Item>
      <Grid.Item>
        <InputFloating
          name="occupation"
          label="Occupation"
          handleSave={handleSave}
        />
      </Grid.Item>
      <Grid.Item>
        <CircularRadioGroup
          name="sex"
          label="Sex"
          options={[
            { label: 'Male', value: 'male' },
            { label: 'Female', value: 'female' },
          ]}
          handleSave={handleSave}
          inline
        />
      </Grid.Item>
      <Grid.Item>
        <CircularRadioGroup
          name="maritalStatus"
          label="Marital status"
          options={[
            { label: 'Not asked', value: '' },
            ...FUNERAL_MARITAL_STATUS_OPTIONS,
          ]}
          handleSave={handleSave}
          inline
        />
      </Grid.Item>
    </Grid>
  )
}

export default PersonWhoHasDied
