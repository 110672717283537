import { Link } from 'react-router-dom'

const EmailLink = ({
  email,
  contactIdForEditingEmail,
}: {
  email: string | null
  contactIdForEditingEmail?: number | undefined
}) => {
  if (email) {
    return <a href={`mailto:${email}`}>{email}</a>
  }
  return contactIdForEditingEmail ? (
    <Link to={`/contacts/${contactIdForEditingEmail}/edit`}>
      enter email address
    </Link>
  ) : (
    <span>No email address</span>
  )
}

export default EmailLink
