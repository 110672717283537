import { ReactElement } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { GTR } from '@farewill/ui/tokens'
import { screenMin } from '@farewill/ui/helpers/responsive'

/*
  For historical reasons a wrapper component is used to denote the
  section that the sticky applies to
*/
const StickyWrapper = ({
  children,
}: {
  children: ReactElement
}): ReactElement => children

const StyledStickyItem = styled.div<{
  $maxWidth: number
  $fromTop: string
}>`
  max-width: ${({ $maxWidth }) => $maxWidth}px;
  width: 100%;

  ${(props) => css`
    ${screenMin.l`
      position: sticky;
      top: ${props.$fromTop};
    `}
  `}
`

const StickyItem = ({
  children,
  fromTop = GTR.L,
  maxWidth,
}: {
  children: ReactElement
  fromTop?: string
  maxWidth: number
}): ReactElement => {
  return (
    <StyledStickyItem $maxWidth={maxWidth} $fromTop={fromTop}>
      {children}
    </StyledStickyItem>
  )
}

// TODO: Remove when UI is full TS
StickyItem.propTypes = {
  children: PropTypes.node.isRequired,
  fromTop: PropTypes.string,
  maxWidth: PropTypes.number.isRequired,
}

const Sticky = {
  Wrapper: StickyWrapper,
  Item: StickyItem,
}

export default Sticky
