import { Grid, H } from '@farewill/ui'
import { useFormikContext } from 'formik'

import withForm from '../fact-find/components/with-form'

import CircularRadioGroup from 'components/form/circular-radio-group'
import NotesInput from 'components/notes-input'
import MessageBox from 'components/message-box'

import {
  LEGAL_TEAM_APPROVAL_REQUIRED,
  LEGAL_TEAM_APPROVAL_REQUIRED_NOTES,
} from './field-names'

const LegalTeamApproval = ({ saveProbateEstateField }) => {
  const { values } = useFormikContext()
  const isAnswered = !!values[LEGAL_TEAM_APPROVAL_REQUIRED]
  const approvalRequired = values[LEGAL_TEAM_APPROVAL_REQUIRED] === 'true'

  return (
    <Grid>
      <Grid.Item>
        <H size="S">1. Legal team approval</H>
      </Grid.Item>
      <Grid.Item>
        <CircularRadioGroup
          name={LEGAL_TEAM_APPROVAL_REQUIRED}
          label="Does the case have to be reviewed by the legal team before referral?"
          options={[
            { value: '', label: 'Not specified' },
            { value: 'false', label: 'No' },
            { value: 'true', label: 'Yes' },
          ]}
          handleSave={saveProbateEstateField}
          inline
        />
      </Grid.Item>
      {isAnswered && (
        <Grid.Item span={10}>
          <MessageBox bold stretch>
            {approvalRequired
              ? 'Please ask the legal team to approve the case for referral'
              : 'The legal team has not been asked to review this case for referral'}
          </MessageBox>
        </Grid.Item>
      )}
      <Grid.Item>
        <NotesInput
          name={LEGAL_TEAM_APPROVAL_REQUIRED_NOTES}
          handleSave={saveProbateEstateField}
        />
      </Grid.Item>
    </Grid>
  )
}

export default withForm(LegalTeamApproval)
