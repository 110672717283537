import { useEffect, useState } from 'react'
import { useFormikContext } from 'formik'

import { Address } from 'lib/models/address'
import useApi from 'lib/effects/api'
import AddressInput from 'components/form/address-input'
import { AddressWithType } from 'lib/models/contact'
import { AddressType } from 'api/generated/graphql'
import { ResponseErrors } from 'lib/types'

type Props = {
  data: Address | null
  label: string
  name: string
  newAddressAttrs?: {
    relatedResource: string
    relatedId: number
    relation: string
  }
  setErrors?: (payload: ResponseErrors) => void
  setIsLoading?: (payload: boolean) => void
  onSave?: () => void
  disabled?: boolean
}

const AddressInputWrapper = ({
  data,
  label,
  name,
  newAddressAttrs,
  setErrors,
  setIsLoading,
  onSave,
  disabled,
}: Props): React.ReactElement => {
  const { status } = useFormikContext()
  const [{ data: updatedAddress, isLoading, errors }, makeRequest] = useApi()
  const [addressId, setAddressId] = useState<number | undefined>(data?.id)

  useEffect(() => {
    if (setErrors) setErrors(errors)
  }, [setErrors, errors])

  useEffect(() => {
    if (setIsLoading) setIsLoading(isLoading)
  }, [setIsLoading, isLoading])

  useEffect(() => {
    if (updatedAddress && 'id' in updatedAddress) {
      setAddressId(updatedAddress.id)
    }
  }, [updatedAddress])

  const handleSave = async (attributes: AddressWithType) => {
    const address = {
      lineOne: attributes.lineOne || null,
      lineTwo: attributes.lineTwo || null,
      city: attributes.city || null,
      postalCode: attributes.postalCode || null,
      countryCode: attributes.countryCode || null,
      type: AddressType.Postal,
    }

    if (addressId) {
      await makeRequest({
        url: `/api/addresses/${addressId}`,
        method: 'PATCH',
        data: {
          data: {
            type: 'addresses',
            id: addressId,
            attributes: address,
          },
        },
      })
    } else {
      await makeRequest({
        url: '/api/addresses',
        method: 'POST',
        data: {
          data: {
            type: 'addresses',
            attributes: { address, ...newAddressAttrs },
          },
        },
      })
    }

    onSave?.()
  }

  return (
    <AddressInput
      /*
        Passing undefined here ensures the default prop values are
        used (empty strings) if data is null. This prevents
        console errors related to uncontrolled form inputs.
      */
      addressValues={data || undefined}
      label={label}
      name={name}
      onAddressChanged={handleSave}
      parentFormStatus={status}
      disabled={disabled}
    />
  )
}

export default AddressInputWrapper
