import { Grid } from '@farewill/ui'
import { useFormikContext } from 'formik'

import { WillLeadAttributes, WillQuoteAttributes } from 'lib/models/lead'
import { strToBool, strToBoolProp } from 'utils/helpers'
import RadioGroup from 'components/form/radio-group'
import Input from 'components/form/input'
import Checkbox from 'components/form/checkbox'
import AddressInput from 'components/form/address-input'
import MessageBox from 'components/message-box'
import SelectInput from 'components/form/select-input'

import {
  AddressQuestion,
  QuestionConfig,
  RadioQuestion,
  SelectQuestion,
  OtherQuestion,
} from '../types'

type handleSubmitType = (
  e?: React.FormEvent<HTMLFormElement> | undefined
) => void

const RadioType = ({
  data,
  values,
  handleSubmit,
}: {
  data: RadioQuestion
  values: WillLeadAttributes
  handleSubmit: handleSubmitType
}) => {
  const allOptions =
    typeof data.options === 'function' ? data.options(values) : data.options

  return (
    <Grid.Item>
      <RadioGroup
        name={data.name}
        label={data.label}
        options={allOptions}
        handleSave={() => handleSubmit()}
      />
    </Grid.Item>
  )
}

const InputType = ({
  data,
  handleSubmit,
}: {
  data: OtherQuestion
  handleSubmit: handleSubmitType
}) => (
  <Grid.Item spanFromL={6}>
    <Input
      name={data.name}
      label={data.label}
      handleSave={() => handleSubmit()}
      type={data.type === 'date' ? 'date' : 'text'}
    />
  </Grid.Item>
)

const CheckboxType = ({
  data,
  values,
  handleSubmit,
}: {
  data: OtherQuestion
  values: WillLeadAttributes
  handleSubmit: handleSubmitType
}) => {
  const quoteAttributesKey = data.name.split(
    '.'
  )[1] as keyof WillQuoteAttributes
  const value = values.quoteAttributes[quoteAttributesKey] as strToBoolProp

  return (
    <Grid.Item>
      <Checkbox
        name={data.name}
        checked={strToBool(value)}
        label={data.label}
        handleSave={() => handleSubmit()}
      />
    </Grid.Item>
  )
}

const AddressType = ({
  data,
  values,
  handleSubmit,
  setFieldValue,
}: {
  data: AddressQuestion
  values: WillLeadAttributes
  handleSubmit: handleSubmitType
  setFieldValue: (
    field: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    value: any,
    shouldValidate?: boolean | undefined
  ) => void
}) => (
  <Grid.Item data-testid={data.name}>
    <AddressInput
      name={data.name}
      label={data.label as string}
      onAddressChanged={(args) => {
        setFieldValue(data.field, args)
        handleSubmit()
      }}
      addressValues={data.value(values)}
    />
  </Grid.Item>
)

const SelectType = ({
  data,
  values,
  handleSubmit,
}: {
  data: SelectQuestion
  values: WillLeadAttributes
  handleSubmit: handleSubmitType
}) => {
  const allOptions =
    typeof data.options === 'function' ? data.options(values) : data.options

  return (
    <Grid.Item>
      <Grid>
        <Grid.Item spanFromL={6}>
          <SelectInput
            name={data.name}
            label={data.label as string}
            options={allOptions}
            handleSave={() => handleSubmit()}
          />
        </Grid.Item>
      </Grid>
    </Grid.Item>
  )
}

const Question = ({ data }: { data: QuestionConfig }) => {
  const { values, setFieldValue, handleSubmit } =
    useFormikContext<WillLeadAttributes>()

  const isVisible = data?.isVisible ? data.isVisible(values) : true

  if (!isVisible) return null

  const questionTypeComponentMap = {
    radio: (
      <RadioType
        data={data as RadioQuestion}
        values={values}
        handleSubmit={handleSubmit}
      />
    ),
    input: (
      <InputType data={data as OtherQuestion} handleSubmit={handleSubmit} />
    ),
    date: (
      <InputType data={data as OtherQuestion} handleSubmit={handleSubmit} />
    ),
    checkbox: (
      <CheckboxType
        data={data as OtherQuestion}
        values={values}
        handleSubmit={handleSubmit}
      />
    ),
    address: (
      <AddressType
        data={data as AddressQuestion}
        handleSubmit={handleSubmit}
        values={values}
        setFieldValue={setFieldValue}
      />
    ),
    select: (
      <SelectType
        data={data as SelectQuestion}
        values={values}
        handleSubmit={handleSubmit}
      />
    ),
  }

  const showInfoMessage = data?.showInfoMessage
    ? data.showInfoMessage(values)
    : false

  return (
    <>
      {questionTypeComponentMap[data.type] || null}
      {showInfoMessage && (
        <Grid.Item>
          <MessageBox margin={['S', 0, 'M']}>{data.infoMessage}</MessageBox>
        </Grid.Item>
      )}
    </>
  )
}

export default Question
