export const MODAL_MAX_WIDTH = 650
// For now, we only take card payments by Square for wills payments
export const PAYMENT_TYPE = 'card'
export const PAYMENT_PROVIDER = 'square'

export const WILL_CASE_QUOTE_COMPLEXITIES = {
  LEAD: 'lead',
  PARTNER: 'partner',
  BOTH: 'both',
} as const

export const WILL_CASE_QUOTE_WILL_TYPE = {
  SINGLE: 'single',
  COUPLE: 'couple',
} as const
