import { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useFormikContext } from 'formik'
import { Grid, P } from '@farewill/ui'
import { Link } from 'react-router-dom'

import { CONFIRMED_AND_CHECKED_OPTIONS } from 'utils/enums'
import useApi from 'lib/effects/api'

import CircularRadioGroup from 'components/form/circular-radio-group'
import InputFloating from 'components/form/input-floating'
import PinkBox from 'components/styled/pink-box'
import IHT400Warning from './iht-400-warning'

import { groupProbateEstateItems } from '../../helpers'

import {
  MADE_GIFTS_WITH_RESERVATION_OF_BENEFIT,
  HAD_RIGHT_TO_BENEFIT_FROM_TRUST_ASSETS,
} from '../../fact-find/gifts-and-other/field-names'

import {
  CONFIRMED_MADE_GIFTS_OR_BENEFITTED_FROM_TRUST,
  CONFIRMED_MADE_GIFTS_OR_BENEFITTED_FROM_TRUST_NOTES,
  CONFIRMED_HAD_BUSINESS_OR_AGRICULTURAL_ASSETS,
  CONFIRMED_HAD_BUSINESS_OR_AGRICULTURAL_ASSETS_NOTES,
} from '../field-names'

const GiftsAndAssetsChecks = ({
  factFindUrl,
  probateEstateId,
  saveProbateEstateField,
}) => {
  const { values } = useFormikContext()
  const [{ data: estateItems, isLoading }, getProbateEstateItems] = useApi({
    isLoading: true,
  })

  useEffect(() => {
    getProbateEstateItems({
      url: `/api/probate-estates/${probateEstateId}/items`,
    })
  }, [getProbateEstateItems, probateEstateId])

  if (isLoading) {
    return (
      <P size="L">
        Loading <strong>Gifts and assets</strong>...
      </P>
    )
  }

  const groupedEstateItems = groupProbateEstateItems(estateItems)

  const hasGiftOrTrustComplexity =
    values[MADE_GIFTS_WITH_RESERVATION_OF_BENEFIT] === 'yes' ||
    values[HAD_RIGHT_TO_BENEFIT_FROM_TRUST_ASSETS] === 'yes' ||
    groupedEstateItems.giftGiven?.length > 0

  const hasAgriculturalOrBusinessProperty =
    groupedEstateItems.businessOrAgriculturalAsset?.length > 0

  return (
    <>
      <Grid gap={['S', 'M']} margin={[0, 0, 'L']}>
        <Grid.Item>
          <P strong>
            Are there any lifetime gifts, gifts with reservation of benefit or
            benefits from trusts?
          </P>
        </Grid.Item>
        <Grid.Item>
          <PinkBox stretch>
            According to fact find,{' '}
            <strong>{hasGiftOrTrustComplexity ? 'yes' : 'no'}</strong> (
            <Link to={factFindUrl + '#6'}>6</Link>)
          </PinkBox>
        </Grid.Item>
        <Grid.Item>
          <CircularRadioGroup
            name={CONFIRMED_MADE_GIFTS_OR_BENEFITTED_FROM_TRUST}
            options={CONFIRMED_AND_CHECKED_OPTIONS}
            handleSave={saveProbateEstateField}
            inline
          />
        </Grid.Item>
        <Grid.Item span={9}>
          <InputFloating
            name={CONFIRMED_MADE_GIFTS_OR_BENEFITTED_FROM_TRUST_NOTES}
            label="How have you confirmed this?"
            handleSave={saveProbateEstateField}
          />
        </Grid.Item>
        {hasGiftOrTrustComplexity && (
          <Grid.Item span={9}>
            <IHT400Warning />
          </Grid.Item>
        )}
      </Grid>
      <Grid gap={['S', 'M']}>
        <Grid.Item>
          <P strong>Is there any agricultural or business property?</P>
        </Grid.Item>
        <Grid.Item>
          <PinkBox stretch>
            According to fact find,{' '}
            <strong>{hasAgriculturalOrBusinessProperty ? 'yes' : 'no'}</strong>{' '}
            (<Link to={factFindUrl + '#4.1'}>4.1</Link>)
          </PinkBox>
        </Grid.Item>
        <Grid.Item>
          <CircularRadioGroup
            name={CONFIRMED_HAD_BUSINESS_OR_AGRICULTURAL_ASSETS}
            options={CONFIRMED_AND_CHECKED_OPTIONS}
            handleSave={saveProbateEstateField}
            inline
          />
        </Grid.Item>
        <Grid.Item span={9}>
          <InputFloating
            name={CONFIRMED_HAD_BUSINESS_OR_AGRICULTURAL_ASSETS_NOTES}
            label="How have you confirmed this?"
            handleSave={saveProbateEstateField}
          />
        </Grid.Item>
        {hasAgriculturalOrBusinessProperty && (
          <Grid.Item span={9}>
            <IHT400Warning />
          </Grid.Item>
        )}
      </Grid>
    </>
  )
}

GiftsAndAssetsChecks.propTypes = {
  factFindUrl: PropTypes.string.isRequired,
  probateEstateId: PropTypes.number.isRequired,
  saveProbateEstateField: PropTypes.func.isRequired,
}

export default GiftsAndAssetsChecks
