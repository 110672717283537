import { useEffect } from 'react'
import styled from 'styled-components'
import { FONT } from '@farewill/ui/tokens'
import { Grid, H, P, Wrapper } from '@farewill/ui'
import Dinero from 'dinero.js'
import { useFormikContext } from 'formik'

import SectionStatus from 'components/section-status'
import { LpaLeadAttributes } from 'lib/models/lead'
import { OUTCOME_TYPE } from 'routes/lead/constants'

import { Outcome } from './types'

const StyledP = styled(P)`
  font-weight: ${FONT.WEIGHT.MEDIUM};
`

const StyledPriceWrapper = styled(Grid.Item)`
  text-align: right;
`

const LPAOutcome = ({
  outcome,
  quotePriceInPence,
}: {
  outcome: Outcome
  quotePriceInPence?: number
}) => {
  const { setFieldValue, handleSubmit } = useFormikContext<LpaLeadAttributes>()

  useEffect(() => {
    if (quotePriceInPence) {
      setFieldValue('quoteAttributes.price', quotePriceInPence / 100)
      handleSubmit()
    }
  }, [handleSubmit, quotePriceInPence, setFieldValue])

  return (
    <Grid.Item>
      <SectionStatus
        complete={outcome.complete}
        heading={
          outcome.type === OUTCOME_TYPE.CANNOT_HELP
            ? outcome.heading
            : 'Quote summary'
        }
        incompleteText="Please complete the questions above"
      >
        {outcome.type !== OUTCOME_TYPE.CANNOT_HELP && (
          <Wrapper margin={[0, 0, 'S']}>
            <StyledP margin={0}>Our quote is:</StyledP>

            <Grid>
              <Grid.Item
                spanFromM={outcome.type !== OUTCOME_TYPE.CANNOT_HELP ? 6 : 12}
              >
                <H tag="h3" size="M">
                  {outcome.heading}
                </H>
              </Grid.Item>

              <StyledPriceWrapper spanFromM={6}>
                <H as="p" size="M">
                  £{Dinero({ amount: quotePriceInPence || 0 }).toUnit()}
                </H>
              </StyledPriceWrapper>
            </Grid>
          </Wrapper>
        )}

        <Wrapper>
          {typeof outcome.message === 'function'
            ? outcome.message()
            : outcome.message}
        </Wrapper>
      </SectionStatus>
    </Grid.Item>
  )
}

export default LPAOutcome
