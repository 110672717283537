import PropTypes from 'prop-types'
import { Grid, H } from '@farewill/ui'
import { useFormikContext } from 'formik'

import { TITLE_OPTIONS } from 'utils/enums'
import { getDateIsOnOrAfter19741113 } from '../../helpers'
import { getMomentDate } from 'lib/formik/dates'

import InputFloating from 'components/form/input-floating'
import DateSplit from 'components/form/date-split'
import NotesInput from 'components/notes-input'
import MessageBox from 'components/message-box'
import Address from '../components/address'

import {
  SPOUSE_TITLE,
  SPOUSE_FIRST_NAMES,
  SPOUSE_LAST_NAME,
  SPOUSE_DEATH,
  MARRIAGE,
  PLACE_OF_MARRIAGE,
  SPOUSE_NOTES,
  SPOUSE_LAST_PERMANENT_ADDRESS,
} from './field-names'

const Spouse = ({ saveProbateEstateField }) => {
  const { values } = useFormikContext()
  const spouseDateOfDeath = getMomentDate({ prefix: SPOUSE_DEATH, values })
  const showDateOfDeathWarning =
    spouseDateOfDeath && !getDateIsOnOrAfter19741113(spouseDateOfDeath)

  return (
    <Grid data-scroll-id="3.1">
      <Grid.Item>
        <H size="S">3.1 About the spouse who died</H>
      </Grid.Item>
      <Grid.Item span={2}>
        <InputFloating
          name={SPOUSE_TITLE}
          label="Title"
          handleSave={saveProbateEstateField}
          options={TITLE_OPTIONS}
          component="select"
        />
      </Grid.Item>
      <Grid.Item span={5}>
        <InputFloating
          name={SPOUSE_FIRST_NAMES}
          label="First and middle names"
          handleSave={saveProbateEstateField}
        />
      </Grid.Item>
      <Grid.Item span={5}>
        <InputFloating
          name={SPOUSE_LAST_NAME}
          label="Last name"
          handleSave={saveProbateEstateField}
        />
      </Grid.Item>
      <Grid.Item>
        <DateSplit
          name={SPOUSE_DEATH}
          label="Date of death"
          handleSave={saveProbateEstateField}
        />
      </Grid.Item>
      {showDateOfDeathWarning && (
        <Grid.Item>
          <MessageBox warning>
            As this date is before 13 Nov 1974, use IHT400 to claim TNRB
          </MessageBox>
        </Grid.Item>
      )}
      <Grid.Item>
        <DateSplit
          name={MARRIAGE}
          label="Date of marriage"
          handleSave={saveProbateEstateField}
        />
      </Grid.Item>
      <Grid.Item span={6}>
        <InputFloating
          name={PLACE_OF_MARRIAGE}
          label="Place of marriage"
          handleSave={saveProbateEstateField}
        />
      </Grid.Item>
      <Grid.Item span={9}>
        <Address
          label="Last permanent (home) address"
          name={SPOUSE_LAST_PERMANENT_ADDRESS}
          data={values[SPOUSE_LAST_PERMANENT_ADDRESS]}
        />
      </Grid.Item>
      <Grid.Item>
        <NotesInput name={SPOUSE_NOTES} handleSave={saveProbateEstateField} />
      </Grid.Item>
    </Grid>
  )
}

Spouse.propTypes = {
  saveProbateEstateField: PropTypes.func.isRequired,
}

export default Spouse
