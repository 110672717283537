import { InfoCircleIcon } from '@farewill/ui'
import { COLOR } from '@farewill/ui/tokens'
import { SIZES } from '@farewill/ui/components/Icon/constants'

import Tooltip from 'components/tooltip'

export type Props = {
  className?: string
  color?: string
  content: string
  maxWidth?: number
  size?: keyof typeof SIZES
}

export const TooltipIcon = ({
  className,
  color = COLOR.GREY.MEDIUM,
  content,
  maxWidth,
  size = 'M',
}: Props): React.ReactElement => {
  return (
    <Tooltip content={content} underlined={false} maxWidth={maxWidth}>
      <InfoCircleIcon size={size} color={color} className={className} inline />
    </Tooltip>
  )
}

export default TooltipIcon
