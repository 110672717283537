import { Button, H, Wrapper } from '@farewill/ui'
import { useDispatch } from 'react-redux'

import { showModal } from 'state/actions'
import AccountDetails from 'components/account-details'

import { useContactContext } from '../context'
import EditAccountDetailsModal from './edit-account-details-modal'

const Account = (): React.ReactElement => {
  const { contact, fetchContact } = useContactContext()
  const dispatch = useDispatch()

  return (
    <Wrapper>
      <H size="S">Account details</H>
      <AccountDetails accountUuid={contact.attributes.accountUuid} />
      <Wrapper margin={['L', 0]}>
        <Button.Bordered
          onClick={() =>
            dispatch(
              showModal({
                component: EditAccountDetailsModal,
                headingText: 'Edit account email',
                config: { uuid: contact.attributes.accountUuid, fetchContact },
              })
            )
          }
        >
          Edit account details
        </Button.Bordered>
      </Wrapper>
    </Wrapper>
  )
}

export default Account
