import { ReactElement } from 'react'
import { Wrapper, P } from '@farewill/ui'
import styled from 'styled-components'

import { FuneralAttributes } from 'lib/models/funeral'
import { LeadQuoteAttributes } from 'routes/lead/funeral-form/helpers'

const StyledP = styled(P)`
  white-space: pre-wrap;
`

type FuneralUnsupportedWarningProps = {
  attributes: FuneralAttributes | LeadQuoteAttributes
}

const FuneralUnsupportedWarning = ({
  attributes,
}: FuneralUnsupportedWarningProps): ReactElement => {
  const replacer = (key: string, value: unknown) => {
    if (key === 'createdAt' || key === 'updatedAt') {
      return undefined
    }
    return value
  }
  const funeralString = JSON.stringify(attributes, replacer, '\t')

  return (
    <Wrapper separator background="pink" padding={['S', 'S', 'S', 'S']}>
      <P size="M">
        Warning:
        <br /> <br />
        This is a burials or an attended funeral. It should not be edited in
        Backstage. To make changes to this case please get in touch with the
        funerals experience product team.
        <br /> <br />
        This is all the information we have on this case:
      </P>
      {funeralString.split('\n').map((line, index) => (
        <StyledP key={`${line}-${index}`}>
          <span>{line}</span>
          <br />
        </StyledP>
      ))}
    </Wrapper>
  )
}

export default FuneralUnsupportedWarning
