import { createContext, useContext } from 'react'

import useApiHelpers from 'lib/effects/api-helpers'

const ErrorsContext = createContext()

const useErrorsContext = () => {
  const context = useContext(ErrorsContext)
  if (context === undefined) {
    throw new Error('useErrorsContext must be used within an ErrorsProvider')
  }
  return context
}

const ErrorsProvider = ({ children, probateCaseId }) => {
  const {
    items: errors,
    fetchItems: fetchErrors,
    isFetching: isFetchingErrors,
    createItem: createError,
    isCreating: isCreatingError,
    createErrors,
    updateItem: updateError,
    isUpdating: isUpdatingError,
    updateErrors,
    deleteItem: deleteError,
  } = useApiHelpers({
    baseUrl: `/api/probate-cases/${probateCaseId}/errors`,
    type: 'probate_case_errors',
  })

  const value = {
    errors,
    fetchErrors,
    isFetchingErrors,
    createError,
    isCreatingError,
    createErrors,
    updateError,
    isUpdatingError,
    updateErrors,
    deleteError,
  }

  return (
    <ErrorsContext.Provider value={value}>{children}</ErrorsContext.Provider>
  )
}

export { ErrorsProvider, useErrorsContext }
