import { strToBool } from 'utils/helpers'
import { UseSectionsCompleteProps } from '.'
import { RequiredArray } from './types'

export const checkIsPropertiesComplete = ({
  values,
  properties,
}: UseSectionsCompleteProps) => {
  const propertiesFiltered = properties.filter((property) => {
    const {
      propertyPassingToDirectDescendant,
      propertyPassingToDirectDescendantValue,
    } = values.quoteAttributes
    const {
      registryCheckStatus,
      value,
      ownership,
      jointOwnershipType,
      hasCharges,
      deceasedShareOfChargesValue,
      deceasedShareOfJointOwnership,
      isMainResidence,
    } = property.attributes

    const requiredFieldsForJointOwnership: RequiredArray =
      ownership === 'joint'
        ? [jointOwnershipType, deceasedShareOfJointOwnership]
        : []

    const requiredFieldsForHasCharges: RequiredArray = strToBool(hasCharges)
      ? [deceasedShareOfChargesValue]
      : []

    const requiredFieldsForMainResidence: RequiredArray = strToBool(
      isMainResidence
    )
      ? [propertyPassingToDirectDescendant]
      : []

    const requiredFieldForPassingToDirectDescendant: RequiredArray =
      strToBool(isMainResidence) && strToBool(propertyPassingToDirectDescendant)
        ? [propertyPassingToDirectDescendantValue]
        : []

    const requiredFields: RequiredArray = [
      registryCheckStatus,
      value,
      ownership,
      hasCharges,
    ]

    const requiredFieldsCombined: RequiredArray = requiredFields.concat(
      requiredFieldsForJointOwnership,
      requiredFieldsForHasCharges,
      requiredFieldsForMainResidence,
      requiredFieldForPassingToDirectDescendant
    )

    return !(
      requiredFieldsCombined.find((field) => field === '' || field === null) ===
      undefined
    )
  })

  return propertiesFiltered.length === 0
}
