import styled from 'styled-components'
import { Button, P, Wrapper } from '@farewill/ui'

import store from 'state/create-store'
import { hideModal } from 'state/actions'
import { Address } from 'lib/models/address'

const StyledWrapper = styled(Wrapper)`
  display: flex;
  justify-content: space-between;
`

type FindATimeModalConfig = {
  config: {
    address: Address
    confirmCallback: () => void
  }
}

const BrochureModal = ({ config }: FindATimeModalConfig) => {
  const formattedAddress = config.address
    ? `${config.address.lineOne} ${config.address.lineTwo}, ${config.address.city}, ${config.address.postalCode} ${config.address.countryCode}`
    : undefined
  const handleContinueClick = async () => {
    await config.confirmCallback()
    store.dispatch(hideModal())
  }

  return (
    <Wrapper>
      {formattedAddress ? (
        <>
          <P>The brochure will be sent to: </P>
          <P>{formattedAddress}</P>
          <P margin={[0, 0, 'M']}>
            Please check the address looks correct before confirming you want to
            send the brochure.
          </P>
          <StyledWrapper>
            <Button.Primary
              type="button"
              onClick={() => store.dispatch(hideModal())}
            >
              Cancel
            </Button.Primary>
            <Button.Primary type="button" onClick={handleContinueClick}>
              Send brochure
            </Button.Primary>
          </StyledWrapper>
        </>
      ) : (
        <>
          <P>We need customer address to send the brochure.</P>
          <P margin={[0, 0, 'M']}>
            Go back to the form and add the address before you try to send the
            brochure.
          </P>

          <Button.Primary
            type="button"
            onClick={() => store.dispatch(hideModal())}
          >
            Go back
          </Button.Primary>
        </>
      )}
    </Wrapper>
  )
}

export default BrochureModal
