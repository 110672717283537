import { Grid, H, P } from '@farewill/ui'
import { useFormikContext } from 'formik'
import Dinero from 'dinero.js'

import CircularRadioGroup from 'components/form/circular-radio-group'
import ProbateOnlyQuote from './probate-only-quote'
import EstateAdminQuote from './estate-admin-quote'
import { UseSectionsCompleteResponse } from './helpers/complete-sections/types'
import { ProbateLeadAttributes } from 'lib/models/lead'
import { QuoteResponseDataProbate } from 'lib/types'
import SectionStatus from 'components/section-status'
import IncompleteSections from './incomplete-sections'
import styled from 'styled-components'

const StyledSectionStatusWrapper = styled(Grid.Item)`
  &:first-child {
    width: 100%;
  }
`

type QuotesProps = {
  quote: QuoteResponseDataProbate | null
  completedSections: Partial<UseSectionsCompleteResponse>
}

const Quotes = ({ quote, completedSections }: QuotesProps) => {
  const { handleSubmit, setFieldValue } =
    useFormikContext<ProbateLeadAttributes>()

  const handleSave = ({ name, value }: { name: string; value: string }) => {
    setFieldValue(name, value)
    handleSubmit()
  }

  return (
    <Grid data-scroll-id="quotes" gap="L" data-testid="quotes">
      <Grid.Item>
        <H size="S">9. Quotes</H>
      </Grid.Item>
      {completedSections.allSectionsComplete ? (
        <>
          {quote?.attributes.estateValues.grossEstateValueInPence ? (
            <Grid.Item>
              <P>
                A typical High Street firm would charge{' '}
                <strong>
                  {Dinero({
                    amount: Math.round(
                      quote?.attributes.estateValues.grossEstateValueInPence *
                        0.02
                    ),
                  }).toFormat('$0,0')}
                </strong>{' '}
                for this case, at 2% of the gross estate value.
              </P>
            </Grid.Item>
          ) : null}
          <Grid.Item span={6} style={{ display: 'flex' }}>
            <ProbateOnlyQuote quote={quote} />
          </Grid.Item>
          <Grid.Item span={6} style={{ display: 'flex' }}>
            <EstateAdminQuote quote={quote} />
          </Grid.Item>{' '}
          <Grid.Item>
            <H size="XS">Which service would the caller prefer?</H>
            <CircularRadioGroup
              handleSave={handleSave}
              inline
              name="quoteAttributes.interestedInEstateAdmin"
              options={[
                { value: '', label: 'Not asked' },
                { value: 'false', label: 'Essential Probate' },
                { value: 'true', label: 'Complete Probate' },
              ]}
            />
          </Grid.Item>
        </>
      ) : (
        <>
          <StyledSectionStatusWrapper span={10}>
            <SectionStatus
              complete={completedSections.allSectionsComplete}
              heading="Complete sections to see quotes"
              incompleteContent={
                <IncompleteSections completedSections={completedSections} />
              }
            />
          </StyledSectionStatusWrapper>
        </>
      )}
    </Grid>
  )
}

export default Quotes
