import moment from 'moment'

export const padNum = (num: number): string => num?.toString()?.padStart(2, '0')

type DatePrefix = 'birth' | 'death' | 'spouseDeath' | 'will'

type DateValues =
  | 'birthYear'
  | 'birthMonth'
  | 'birthDay'
  | 'deathYear'
  | 'deathMonth'
  | 'deathDay'
  | 'spouseDeathYear'
  | 'spouseDeathMonth'
  | 'spouseDeathDay'
  | 'willYear'
  | 'willMonth'
  | 'willDay'

export const getMomentDate = ({
  prefix,
  values,
}: {
  prefix: DatePrefix
  values: { [dateValue in DateValues]?: string | number }
}): moment.Moment | null => {
  const year = values[(prefix + 'Year') as DateValues]
  const month = values[(prefix + 'Month') as DateValues]
  const day = values[(prefix + 'Day') as DateValues]

  if (!year || !month || !day) return null
  if (year?.toString()?.length < 4) return null

  const date = moment(`${year}-${padNum(Number(month))}-${padNum(Number(day))}`)
  return date.isValid() ? date : null
}
