import { ReactElement } from 'react-markdown/lib/react-markdown'
import upperFirst from 'lodash/upperFirst'
import { P } from '@farewill/ui'
import { FormikValues } from 'formik'

import SimpleList from 'components/styled/simple-list'
import { WillLeadAttributes } from 'lib/models/lead'
import { WillAttributes } from 'lib/models/will'
import { WillCaseAttributes } from 'lib/models/will-case'
import axiosRequest from 'lib/axios/api-request'
import {
  FAREWILL_PRODUCTS,
  LEAD_PARTNER_TYPES,
  LEAD_SOURCE_IDENTIFIERS,
  LEAD_SOURCE_TYPES,
} from 'utils/enums'

import { sortLeadAndPartnerWills } from '../quote/editable-quote/methods'

type Wish = {
  text: string
  condition: (lead: WillLeadAttributes) => boolean
}

const WISHES: Wish[] = [
  {
    text: 'They want to leave a business.',
    condition: (lead: WillLeadAttributes) =>
      lead.quoteAttributes.wantsToGiftBusiness === 'yes',
  },
  {
    text: 'They want to leave a property.',
    condition: (lead: WillLeadAttributes) =>
      lead.quoteAttributes.wantsToGiftProperty === 'yes',
  },
  {
    text: 'They want to leave something to a person who is disabled or gets means tested benefits.',
    condition: (lead: WillLeadAttributes) =>
      lead.quoteAttributes.hasDisabledOrMeansTestedBeneficiary === 'yes',
  },
  {
    text: 'They want to put their house in a trust to avoid future care home fees.',
    condition: (lead: WillLeadAttributes) =>
      lead.quoteAttributes.wantsToPutHouseInTrust === 'true',
  },
  {
    text: 'They want to leave something to a person who is currently involved in divorce or bankruptcy.',
    condition: (lead: WillLeadAttributes) =>
      lead.quoteAttributes.hasBeneficiaryInvolvedInDivorceOrBankruptcy ===
      'true',
  },
]

export const getWishTexts = (lead: WillLeadAttributes, wishes = WISHES) =>
  wishes
    .reduce(
      (memo: Wish[], wish) => (wish.condition(lead) ? memo.concat(wish) : memo),
      []
    )
    .map((wish) => wish.text)

export const getComplexityAndType = (
  willCase: WillCaseAttributes
): ReactElement => {
  const { willComplexity, willType, wills } = willCase

  if (!wills || wills.length === 0) {
    return <P>''</P>
  }

  const isLegacyCase = !wills[0].willComplexity

  if (isLegacyCase) {
    return (
      <P>
        {upperFirst(willComplexity as string)} {willType} telephone will.
      </P>
    )
  }

  if (willType === 'single') {
    return (
      <P>
        {upperFirst(wills[0].willComplexity as string)} {willType} telephone
        will.
      </P>
    )
  }

  const { leadWill, partnerWill } = sortLeadAndPartnerWills(willCase)

  const {
    contact: { firstName: leadName },
    willComplexity: leadWillComplexity,
  } = leadWill as WillAttributes
  const {
    contact: { firstName: partnerName },
    willComplexity: partnerWillComplexity,
  } = partnerWill as WillAttributes

  return (
    <>
      <P>{upperFirst(willType)} telephone will</P>
      <SimpleList bullets>
        <li>
          {leadName}’s will: {leadWillComplexity}.
        </li>
        <li>
          {partnerName}’s will: {partnerWillComplexity}.
        </li>
      </SimpleList>
    </>
  )
}

export const createLpaLead = async ({
  willCaseId,
  values,
}: {
  willCaseId: number
  values: FormikValues
}): Promise<{ lpaLeadId: number }> => {
  const {
    contactId,
    knownAs,
    maritalStatus,
    dateOfDeath,
    relationshipToCustomer,
    lead,
  } = values

  const data = {
    data: {
      type: 'leads',
      attributes: {
        contactId,
        product: FAREWILL_PRODUCTS.LPA,
        sourceType: LEAD_SOURCE_TYPES.internal_referral,
        sourceIdentifier: LEAD_SOURCE_IDENTIFIERS.wills_cross_sell,
        partnerType: LEAD_PARTNER_TYPES.none,
        quoteAttributes: {
          willCaseId,
          customerRefersToThemAs: knownAs,
          ...(maritalStatus !== '' && {
            maritalStatus,
          }),
          ...(dateOfDeath && { dateOfDeath }),
          ...(relationshipToCustomer !== '' && {
            relationshipToCustomer,
          }),
          ...(lead?.quoteAttributes?.partner && {
            partner: lead.quoteAttributes.partner,
          }),
        },
      },
    },
  }

  const apiResponse = await axiosRequest({
    url: '/api/leads',
    method: 'POST',
    data,
  })

  return Promise.resolve({ lpaLeadId: apiResponse.data.data.id })
}
