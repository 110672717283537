import styled, { css } from 'styled-components'
import { Field } from 'formik'
import { P, Wrapper } from '@farewill/ui'
import { COLOR, FONT, GTR, BORDER } from '@farewill/ui/tokens'

const FloatingActiveStyles = css`
  top: 6px;
  font-size: ${FONT.SIZE.XS};
`

export const FloatingWrapper = styled(({ error, highlight, ...rest }) => (
  <Wrapper {...rest} />
))`
  position: relative;

  :before {
    content: '';
    display: block;
    position: absolute;
    top: -6px;
    bottom: -6px;
    left: -13px;
    width: 5px;
    background-color: transparent;
    transition: background-color 0.2s ease-in-out;

    ${({ highlight }) =>
      highlight && `background-color: ${COLOR.STATE.WARNING};`}
    ${({ error }) => error && `background-color: ${COLOR.STATE.ERROR};`}
  }
`

export const FloatingLabel = styled.label<{ full: boolean; error?: boolean }>`
  position: absolute;
  color: ${COLOR.GREY.MEDIUM};
  top: 16px;
  left: ${GTR.S};
  pointer-events: none;
  white-space: nowrap;
  max-width: calc(100% - 28px);
  overflow: hidden;

  + * {
    margin-top: ${GTR.XXS};
  }

  ${({ full }) => full && FloatingActiveStyles}

  ${({ error }) => error && `color: ${COLOR.STATE.ERROR};`}
`

export const FloatingInput = styled(
  ({ error, highlight, disabled, ...rest }) => (
    <Field disabled={disabled} {...rest} />
  )
)`
  width: 100%;
  background-color: ${COLOR.WHITE};
  padding: 23px 14px 5px;
  border-radius: ${BORDER.RADIUS.S};
  border: 2px solid ${COLOR.GREY.LIGHT};
  outline: 0;
  color: ${COLOR.GREY.DARK};
  transition: border-color 0.2s ease-in-out;

  ${({ highlight }) => highlight && `border-color: ${COLOR.STATE.WARNING};`}
  ${({ error }) => error && `border-color: ${COLOR.STATE.ERROR};`}
  ${({ disabled }) => disabled && `background-color: ${COLOR.GREY.LIGHT};`}

  &:focus {
    border-color: ${COLOR.GREY.MEDIUM};
    ${({ highlight }) => highlight && `border-color: ${COLOR.STATE.WARNING};`}
    ${({ error }) => error && `border-color: ${COLOR.STATE.ERROR};`}

    & + ${FloatingLabel} {
      ${FloatingActiveStyles}
    }
  }
`

export const FloatingHint = styled(P)`
  margin-left: ${GTR.S};
  margin-bottom: 0;
  color: ${COLOR.GREY.MEDIUM};
  font-size: ${FONT.SIZE.XS};
`
