import PropTypes from 'prop-types'
import { Grid, P } from '@farewill/ui'

import { formatToHuman } from 'utils/helpers'
import { formatCurrency } from 'lib/formatting/currency'
import SimpleList from 'components/styled/simple-list'

import MessageBox from 'components/message-box'

const UnspecifiedWarning = ({ unspecified }) => {
  if (!unspecified.value) return null

  return (
    <Grid.Item>
      <MessageBox warning>
        <P size="S">
          The following amounts are <strong>not included</strong> in the summary
          as ownership has not been specified:
        </P>
        <SimpleList bullets>
          {unspecified.items.map(({ type, value }, index) => (
            <li key={index}>
              {formatCurrency({ value })} ({formatToHuman(type)})
            </li>
          ))}
        </SimpleList>
      </MessageBox>
    </Grid.Item>
  )
}

UnspecifiedWarning.propTypes = {
  unspecified: PropTypes.shape({
    value: PropTypes.number.isRequired,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.string.isRequired,
        value: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
          .isRequired,
      })
    ),
  }).isRequired,
}

export default UnspecifiedWarning
