import { useEffect, useReducer } from 'react'
import PropTypes from 'prop-types'
import { Button, P, Wrapper } from '@farewill/ui'
import { GTR } from '@farewill/ui/tokens'

import store from 'state/create-store'
import { showModal } from 'state/actions'

import useApi from 'lib/effects/api'
import apiRequest from 'lib/axios/api-request'

import Tooltip from 'components/tooltip'
import EstateCard from 'components/estate-card'
import DeleteModal from '../../../components/delete-modal'
import Alias from './alias'

const aliasesReducer = (state, action) => {
  switch (action.type) {
    case 'SET_ALIASES':
      return { ...state, aliases: action.payload }
    case 'ADD_ALIAS':
      return { ...state, aliases: [...state.aliases, action.payload] }
    case 'REMOVE_ALIAS': {
      const aliases = state.aliases.filter(({ id }) => id !== action.payload)
      return { ...state, aliases }
    }
    default:
      throw new Error()
  }
}

const Aliases = ({ probateEstateId }) => {
  const [state, dispatch] = useReducer(aliasesReducer, {})

  const [{ data: existingAliases, isLoading: isGetLoading }, makeGetRequest] =
    useApi({ data: [], isLoading: true })

  useEffect(() => {
    const url = `/api/probate-estates/${probateEstateId}/aliases`
    makeGetRequest({ url })
  }, [makeGetRequest, probateEstateId])

  useEffect(
    () => dispatch({ type: 'SET_ALIASES', payload: existingAliases }),
    [dispatch, existingAliases]
  )

  const [{ data: newAlias, isLoading: isCreateLoading }, makeCreateRequest] =
    useApi()

  useEffect(() => {
    if (newAlias) dispatch({ type: 'ADD_ALIAS', payload: newAlias })
  }, [dispatch, newAlias])

  const deleteAlias = (aliasId) => {
    const url = `/api/probate-estates/${probateEstateId}/aliases/${aliasId}`
    return apiRequest({ url, method: 'DELETE' }).then(() => {
      dispatch({ type: 'REMOVE_ALIAS', payload: aliasId })
    })
  }

  const handleTrashClick = (aliasId) => {
    store.dispatch(
      showModal({
        component: DeleteModal,
        headingText: 'Delete alias',
        config: {
          deleteCallback: deleteAlias,
          type: 'alias',
          resourceId: aliasId,
        },
      })
    )
  }

  if (isGetLoading) {
    return (
      <P size="L">
        Loading <strong>aliases</strong>...
      </P>
    )
  }

  return (
    <>
      <P>
        If they hold assets or have a will in{' '}
        <Tooltip content="Cross check names of deceased on documents on file for any different spellings i.e. death certificate, marriage certificate, land registry title document or any paperwork customer sends in.">
          other names
        </Tooltip>{' '}
        please add the name below
      </P>
      <Wrapper margin={state.aliases.length ? [0, 0, 'M'] : 0}>
        {state.aliases.map((alias, index) => (
          <EstateCard
            onTrashClick={() => handleTrashClick(alias.id)}
            listLength={state.aliases.length}
            listPosition={index + 1}
            key={alias.id}
            type="alias"
          >
            <Alias
              aliasId={alias.id}
              index={index}
              initialValues={{ aliases: { [alias.id]: alias.attributes } }}
              probateEstateId={probateEstateId}
            />
          </EstateCard>
        ))}
      </Wrapper>
      <Button.Secondary
        style={{ paddingTop: GTR.XXS, paddingBottom: GTR.XXS }}
        loading={isCreateLoading}
        type="button"
        onClick={() => {
          makeCreateRequest({
            url: `/api/probate-estates/${probateEstateId}/aliases`,
            method: 'POST',
            data: {
              data: { type: 'probate_estate_aliases', attributes: {} },
            },
          })
        }}
      >
        Add alias
      </Button.Secondary>
    </>
  )
}

Aliases.propTypes = {
  probateEstateId: PropTypes.string.isRequired,
}

export default Aliases
