import PropTypes from 'prop-types'
import { useRouteMatch } from 'react-router'
import styled from 'styled-components'
import { Grid, Wrapper } from '@farewill/ui'
import { COLOR, FONT } from '@farewill/ui/tokens'
import useAdminUserRoles from 'hooks/useAdminUserRoles'

const Banner = styled(Wrapper)`
  background-color: ${COLOR.ACCENT.PRIMARY};
  font-size: ${FONT.SIZE.XS};
  text-align: center;
`

const FWLSBanner = ({ referredToFWLS }) => {
  const { isUserRole } = useAdminUserRoles()
  const isUserAllowed = isUserRole('farewill_legal_services_seconded')
  const match = useRouteMatch({
    path: [
      '/probate/cases/:id/progress',
      '/probate/cases/:id/documents',
      '/probate/cases/:id/errors',
      '/probate/cases/:id/sale',
      '/probate/cases/:id',
      '/probate/estates/:id/summary',
      '/probate/estates/:id/fact-find',
      '/probate/estates/:id/internal-checks',
      '/probate/estates/:id/approve-for-referral',
    ],
    exact: true,
  })
  // Only display the banner if on a restricted route and the probate case is referred
  // to FWLS and the user does not have the FWLS role.
  if (!match || !referredToFWLS || isUserAllowed) return null

  return (
    <Grid margin={['S', 0, 0]}>
      <Grid.Item spanFromM={8}>
        <Banner container containerPaddingTop="XS" containerPaddingBottom="XS">
          You are currently viewing a <strong>read-only</strong> version. If you
          need edit access please contact Farewill Legal Services.
        </Banner>
      </Grid.Item>
    </Grid>
  )
}

FWLSBanner.propTypes = { referredToFWLS: PropTypes.bool.isRequired }

export default FWLSBanner
