import {
  createContext,
  useContext,
  useState,
  Dispatch,
  SetStateAction,
} from 'react'

type WillFactFindContextType = {
  highlightEmpty: boolean
  setHighlightEmpty: Dispatch<SetStateAction<boolean>>
}

const WillFactFindContext = createContext({} as WillFactFindContextType)

const useWillFactFindContext = (): WillFactFindContextType => {
  const context = useContext(WillFactFindContext)
  if (context === undefined) {
    throw new Error(
      'useWillFactFindContext must be used within a WillFactFindProvider'
    )
  }
  return context
}

const WillFactFindProvider = ({
  children,
}: {
  children: React.ReactNode
}): React.ReactElement => {
  const [highlightEmpty, setHighlightEmpty] = useState(false)

  const value = {
    highlightEmpty,
    setHighlightEmpty,
  }

  return (
    <WillFactFindContext.Provider value={value}>
      {children}
    </WillFactFindContext.Provider>
  )
}
export { WillFactFindProvider, useWillFactFindContext }
