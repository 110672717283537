import styled, { css } from 'styled-components'
import { connect, Field, useField, useFormikContext } from 'formik'
import { P, Wrapper } from '@farewill/ui'
import { BORDER, COLOR, FONT, GTR } from '@farewill/ui/tokens'
import Label from '../styled/label'
import InputHint from '../styled/input-hint'
import get from 'lodash/get'
import Error from './error'

import Tooltip from 'components/tooltip'
import ConditionalWrapper from 'components/conditional-wrapper'
import { ReactElement } from 'react'
import * as React from 'react'

const StyledWrapper = styled(Wrapper)`
  display: flex;
  flex-wrap: wrap;
  && {
    margin-bottom: -${GTR.XS};
  }
`

const StyledField = styled(Field)`
  position: absolute;
  inset: 0;
  opacity: 0;
  z-index: 1;

  &:checked {
    + label {
      border-color: ${COLOR.ACCENT.PRIMARY};
      background-color: ${COLOR.ACCENT.PRIMARY};
      color: ${COLOR.BLACK};
    }
  }

  &:focus {
    + label {
      box-shadow: 0 0 0 4px ${COLOR.GREY.LIGHT};
    }
  }

  &:focus:checked {
    + label {
      box-shadow: 0 0 0 4px ${COLOR.STATE.HIGHLIGHT};
    }
  }

  ${({ disabled }) =>
    disabled &&
    css`
      &:checked {
        + label {
          cursor: not-allowed;
        }
      }

      &:not(:checked) {
        + label {
          cursor: not-allowed;
          border-color: ${COLOR.GREY.LIGHT};
          background-color: ${COLOR.WHITE};
          color: ${COLOR.GREY.LIGHT};
        }
      }
    `}
`

const StyledTooltip = styled(Tooltip)`
  padding: ${GTR.XXS};
  background-color: ${COLOR.GREY.DARK};
`

const StyledTooltipP = styled(P)`
  margin: 0;
  color: ${COLOR.WHITE};
  font-size: ${FONT.SIZE.S};
`

const StyledRadio = styled.div<{ small: boolean }>`
  position: relative;
  width: auto;
  margin-bottom: ${GTR.XS};

  &:not(:last-of-type) {
    margin-right: ${GTR.S};

    ${({ small }) => small && `margin-right: ${GTR.XS};`}
  }
`

const StyledLabel = styled.label<{
  small: boolean
  padding: string
  highlight: boolean
}>`
  position: relative;
  font-size: ${FONT.SIZE.M};
  font-weight: ${FONT.WEIGHT.MEDIUM};
  cursor: pointer;
  border-radius: ${BORDER.RADIUS.S};
  border: 2px solid ${COLOR.GREY.LIGHT};
  outline: 0;
  color: ${COLOR.BLACK};
  min-width: 100px;
  text-align: center;

  padding: ${({ padding }) => padding || `12px ${GTR.S}`};

  ${({ highlight }) => highlight && `border-style: dashed;`}

  ${({ small }) =>
    small &&
    css<{ padding: string }>`
      min-width: 40px;
      padding: ${({ padding }) => padding || `${GTR.XS} 10px`};
      font-size: ${FONT.SIZE.S};
      margin-top: 0;
    `}
`

const TooltipWrapper =
  (tooltipContent: string) => (children: React.ReactNode) =>
    (
      <StyledTooltip
        maxWidth={230}
        underlined={false}
        content={<StyledTooltipP strong>{tooltipContent}</StyledTooltipP>}
      >
        {children}
      </StyledTooltip>
    )

export type Option = {
  label: string
  value: string
  tooltipContent?: string
  isOptionDisabled?: boolean
}

export type SaveData = {
  name: string
  value: string
}

export type RadioGroupProps = {
  name: string
  options: Option[]
  handleSave?: (data: SaveData) => void
  allowDeselect?: boolean
  highlightInitialValue?: boolean
  hint?: React.ReactNode
  small?: boolean
  label?: React.ReactNode
  enableTooltip?: boolean
  padding?: string
  disabled?: boolean
  handleChange?: (
    value: string,
    setFieldValue: (
      field: string,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      value: any,
      shouldValidate?: boolean | undefined
    ) => void
  ) => void
  confirmationRequired?: boolean
}

const RadioGroup = ({
  name,
  options,
  handleSave,
  allowDeselect = true,
  highlightInitialValue = false,
  hint = '',
  small = false,
  label = '',
  enableTooltip = false,
  padding = '',
  disabled = false,
  handleChange,
  confirmationRequired,
}: RadioGroupProps): ReactElement => {
  const { setFieldValue, values } = useFormikContext()
  const [field, meta] = useField(name)

  const handleClick = (e: { target: { name: string; value: string } }) => {
    if (disabled) return

    const { name, value } = e.target

    const prevValue = get(values, name)
    if (allowDeselect && !confirmationRequired && prevValue === value) {
      setFieldValue(name, '')
    }

    handleSave && handleSave({ name, value })
  }

  return (
    <fieldset>
      {label && (
        <Label as="legend" $error={!!meta.error && meta.touched}>
          {label}
        </Label>
      )}
      <StyledWrapper>
        {options.map(
          ({ label, value, tooltipContent, isOptionDisabled }, index) => {
            const highlight =
              highlightInitialValue && meta.initialValue === value
            const showTooltip = enableTooltip && !!tooltipContent && !disabled
            return (
              <StyledRadio key={index} small={small}>
                <ConditionalWrapper
                  showWrapper={showTooltip}
                  wrapper={TooltipWrapper(tooltipContent as string)}
                >
                  <StyledField
                    id={name + '-' + value}
                    name={name}
                    value={value}
                    type="radio"
                    onClick={handleClick}
                    onChange={(event: {
                      target: { name: string; value: string }
                    }) => {
                      confirmationRequired && handleChange && meta.value !== ''
                        ? handleChange(event.target.value, setFieldValue)
                        : field.onChange(event)
                    }}
                    disabled={disabled || isOptionDisabled}
                  />
                  <StyledLabel
                    htmlFor={name + '-' + value}
                    small={small}
                    highlight={highlight}
                    padding={padding}
                  >
                    {label}
                  </StyledLabel>
                </ConditionalWrapper>
              </StyledRadio>
            )
          }
        )}
      </StyledWrapper>
      {hint && <InputHint>{hint}</InputHint>}
      <Error name={name} />
    </fieldset>
  )
}

export default connect(RadioGroup)
