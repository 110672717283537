import { Grid, H } from '@farewill/ui'
import { useFormikContext } from 'formik'
import { FormEvent } from 'react'

import AddressInputWrapper from 'components/address'
import { SelectInput } from 'components/form'
import ContactEmailInput from 'components/form/contact-email-input'
import Input from 'components/form/input'
import { Selected } from 'components/form/select-input/types'
import { LeadAttributes } from 'lib/models/lead'
import {
  LEAD_SOURCE_IDENTIFIER_OPTIONS,
  LEAD_SOURCE_TYPES,
  LEAD_SOURCE_TYPE_OPTIONS,
} from 'utils/enums'
import { useLeadContext } from 'routes/lead/context'
import { SelectOptions } from 'lib/types/common'

const ContactDetails = (): React.ReactElement => {
  const { updateContact } = useLeadContext()
  const { values, handleSubmit, setFieldValue } =
    useFormikContext<LeadAttributes>()

  const hasWillCaseId = !!(
    'willCaseId' in values.quoteAttributes && values.quoteAttributes.willCaseId
  )

  const sourceIdentifierOptions: SelectOptions =
    LEAD_SOURCE_IDENTIFIER_OPTIONS[
      hasWillCaseId && values.sourceType === LEAD_SOURCE_TYPES.internal_referral
        ? 'cross_sell'
        : (values.sourceType as keyof typeof LEAD_SOURCE_IDENTIFIER_OPTIONS)
    ]

  const sourceTypeOptions = (
    LEAD_SOURCE_TYPE_OPTIONS as [{ value: string; label: string }]
  ).concat({
    value: 'unknown',
    label: 'Unknown',
  })

  const resetSourceIdentifierAndSubmit = (value: {
    name: string
    value: Selected
  }) => {
    setFieldValue('sourceIdentifier', null)
    handleSubmit(value as unknown as FormEvent<HTMLFormElement>)
  }

  return (
    <>
      <H size="S">The caller</H>
      <Grid style={{ gridAutoFlow: 'row' }}>
        <Grid.Item spanFromL={2} startColumn={1}>
          <Input
            label="Title"
            name="contact.title"
            handleSave={updateContact}
          />
        </Grid.Item>
        <Grid.Item spanFromL={5} startColumnFromL={3}>
          <Input
            label="First name"
            name="contact.firstName"
            handleSave={updateContact}
            allowCopyToClipboard
          />
        </Grid.Item>
        <Grid.Item spanFromL={5}>
          <Input
            label="Last name"
            name="contact.lastName"
            handleSave={updateContact}
            allowCopyToClipboard
          />
        </Grid.Item>
        <Grid.Item spanFromL={6}>
          <Input
            label="Phone number"
            name="contact.phoneNumber"
            handleSave={updateContact}
            allowCopyToClipboard
          />
        </Grid.Item>
        <Grid.Item spanFromL={6}>
          <ContactEmailInput
            contactId={values.contact.id}
            handleSave={async ({ name, value }) => {
              const updatedLeadResponse = await updateContact({ name, value })

              const updatedContact = updatedLeadResponse.data.attributes.contact

              /**
               * This means the email change has been recognised as already in use
               * and triggered the contact to be merged into an existing contact.
               * We need to update Formik's value for the contactId, otherwise it
               * will refer to the deleted contact.
               *
               * TODO: each product has its own version of this component so this
               * implementation is currently duplicated across all of them.
               */
              if (values.contact.id !== updatedContact.id) {
                setFieldValue('contactId', updatedContact.id)
                setFieldValue('contact', updatedContact)
              }
            }}
            hasRelatedAccount={!!values.contact.accountUuid}
            inputName="contact.email"
            allowCopyToClipboard
          />
        </Grid.Item>
        <Grid.Item spanFromL={6}>
          <Input
            label="Date of birth"
            handleSave={updateContact}
            name="contact.dateOfBirth"
            type="date"
          />
        </Grid.Item>
        <Grid.Item spanFromL={6}> </Grid.Item>
        <Grid.Item>
          <AddressInputWrapper
            data={values.contact.addresses[0]}
            label="Address"
            name="contact.addresses"
            newAddressAttrs={{
              relatedResource: 'contact',
              relatedId: values.contactId,
              relation: 'addresses',
            }}
          />
        </Grid.Item>
        <Grid.Item spanFromL={6}>
          <SelectInput
            label="Lead Type"
            name="sourceType"
            handleSave={resetSourceIdentifierAndSubmit}
            options={sourceTypeOptions}
          />
        </Grid.Item>
        <Grid.Item spanFromL={6}>
          {sourceIdentifierOptions && (
            <SelectInput
              label="Source"
              name="sourceIdentifier"
              options={sourceIdentifierOptions}
              handleSave={() => handleSubmit()}
            />
          )}
        </Grid.Item>
      </Grid>
    </>
  )
}

export default ContactDetails
