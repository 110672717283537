import get from 'lodash/get'
const initialState = window.localStorage.getItem('farewill_token')

export default function token(state = initialState, action) {
  switch (action.type) {
    case 'LOGIN_FULFILLED': {
      const token = get(action, 'payload.data.token')
      window.localStorage.setItem('farewill_token', token)

      return token
    }

    case 'REMOVE_TOKEN':
      window.localStorage.removeItem('farewill_token')

      return null

    default:
      return state
  }
}
