import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import get from 'lodash/get'

import { createContact, setCall } from 'state/actions'
import NewContact from '../components'

const mapStateToProps = (state) => {
  return {
    call: state.call,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    handleSubmit: (call) => async (values, actions) => {
      const postData = {
        phoneNumber: call.raw_digits || null,
        interestedInProducts: values.interestedInProducts || [],
        customerAircallId: get(call, 'contact.id'),
        sourceType: values.sourceType || 'inbound_call',
        sourceIdentifier:
          values.sourceIdentifier ||
          get(call, 'number.digits', '').replace(/ /g, ''),
        notes: values.notes || null,
        referredBy: values.referredBy || null,
        scheduledNextCallAt: values.scheduledNextCallAt || null,
        partnerType: values.partnerType,
        partnerId: values.partnerId || null,
      }

      try {
        const response = await dispatch(createContact(postData))
        const contactId = get(response, 'value.data.data.id')
        const leads = get(response, 'value.data.data.attributes.leads', [])

        toast('Success! Details saved.', {
          toastId: 'new-contact-details-saved',
        })

        if (leads.length === 1) {
          ownProps.history.push(`/leads/${leads[0].id}`)
        } else {
          ownProps.history.push(`/contacts/${contactId}`)
        }
      } catch {
        actions.setSubmitting(false)
      }
    },
    unsetCall: () => dispatch(setCall(null)),
  }
}

const mergeProps = (stateProps, dispatchProps) => {
  return {
    ...stateProps,
    ...dispatchProps,
    handleSubmit: dispatchProps.handleSubmit(stateProps.call),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(NewContact)
