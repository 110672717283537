import { PersonIcon } from '@farewill/ui'
import { COLOR, GTR } from '@farewill/ui/tokens'
import styled from 'styled-components'

import AdminIcon from 'components/admin-icon'

const StyledCaseOwnerIcon = styled(AdminIcon)`
  position: relative;
  ${({ $hasHolidayCoverOwner }) =>
    $hasHolidayCoverOwner
      ? `
      filter: grayscale(90%);
      border: 1px solid ${COLOR.BACKGROUND.FOG}
    `
      : null};
`

const StyledHolidayCoverOwnerIcon = styled(AdminIcon)`
  ${({ $hasHolidayCoverOwner }) =>
    $hasHolidayCoverOwner ? `margin-left: -${GTR.M};` : null}
`

const OwnerIcons = ({ caseOwner, holidayCoverOwner }) => {
  return caseOwner == null && holidayCoverOwner == null ? (
    <PersonIcon color={COLOR.WHITE} background={COLOR.GREY.LIGHT} />
  ) : (
    <>
      <StyledCaseOwnerIcon
        $hasHolidayCoverOwner={!!holidayCoverOwner}
        user={caseOwner}
        enableTooltip
      />
      {holidayCoverOwner && (
        <StyledHolidayCoverOwnerIcon
          $hasHolidayCoverOwner={!!holidayCoverOwner}
          user={holidayCoverOwner}
          enableTooltip
          tooltipContent={`${holidayCoverOwner.name} (Holiday cover)`}
          size={holidayCoverOwner ? 'S' : 'M'}
        />
      )}
    </>
  )
}

export default OwnerIcons
