import { Grid, H } from '@farewill/ui'
import DatetimePicker from 'components/form/datetime-picker'
import { useField } from 'formik'
import { useEffect, useRef } from 'react'
import styled from 'styled-components'

const EndAlignedGridItem = styled(Grid.Item)`
  align-self: end;
`

const DatePicker = ({
  label,
  dateFieldName,
  handleSave,
}: {
  label: string
  dateFieldName: string
  handleSave: () => void
}) => {
  const [{ value: dateValue }] = useField(dateFieldName)
  const isFirstDate = useRef(true)

  useEffect(() => {
    if (isFirstDate.current) {
      isFirstDate.current = false
      return
    }
    handleSave()
  }, [dateValue, handleSave])

  return (
    <>
      <Grid.Item span={6}>
        <DatetimePicker name={dateFieldName} label={label} time={false} />
      </Grid.Item>
      <EndAlignedGridItem span={6} />
    </>
  )
}

const ImportantDates = ({ handleSave }: { handleSave: () => void }) => {
  return (
    <Grid>
      <Grid.Item>
        <H size="S">Dates</H>
      </Grid.Item>
      <DatePicker
        label="Collection date"
        dateFieldName="collectionDate"
        handleSave={handleSave}
      />
      <DatePicker
        label="Cremation/service date"
        dateFieldName="cremationDate"
        handleSave={handleSave}
      />
      <DatePicker
        label="Delivery date"
        dateFieldName="deliveryDate"
        handleSave={handleSave}
      />
    </Grid>
  )
}

export default ImportantDates
