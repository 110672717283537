import styled from 'styled-components'
import { Button, P, Wrapper } from '@farewill/ui'
import { FONT, GTR } from '@farewill/ui/tokens'
import { useDispatch } from 'react-redux'

import { hideModal } from 'state/actions'
import { FuneralPlanQuery } from 'api/generated/graphql'

const StyledWrapper = styled(Wrapper)`
  display: flex;
  flex-direction: column;
`

const StyledList = styled.ol`
  list-style: numeric;
  margin-left: 20px;

  li {
    margin: 6px 0;
  }
`

const StyledCancelButton = styled(Button)`
  font-weight: ${FONT.WEIGHT.REGULAR};
  text-decoration: underline;
  align-self: center;
  margin-top: ${GTR.XS};

  &:hover {
    text-decoration: underline;
  }
`

type ModalProps = {
  config: {
    data: FuneralPlanQuery
    redeemPlan: () => Promise<void>
  }
}

const RedeemPlanModal = ({ config }: ModalProps) => {
  const dispatch = useDispatch()

  const handleRedeemPlan = async () => {
    await config.redeemPlan()
    dispatch(hideModal())
  }

  return (
    <Wrapper>
      <P>
        You are about to redeem the funeral plan. Before you continue, make sure
        that:
        <StyledList>
          <li>You have checked the funeral plan number</li>
          <li>The plan is completely paid for (or it’s an accidental death)</li>
          <li>
            The covered individual name matches the one given by the caller
          </li>
        </StyledList>
      </P>

      <StyledWrapper>
        <Button.Primary type="button" onClick={handleRedeemPlan}>
          Continue
        </Button.Primary>
        <StyledCancelButton onClick={() => dispatch(hideModal())}>
          Cancel
        </StyledCancelButton>
      </StyledWrapper>
    </Wrapper>
  )
}

export default RedeemPlanModal
