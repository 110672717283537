import { Grid, Wrapper, P } from '@farewill/ui'
import { COLOR, GTR, FONT } from '@farewill/ui/tokens'
import styled from 'styled-components'

import MessageBox from 'components/message-box'

import Dinero from 'dinero.js'
Dinero.globalLocale = 'en-GB'
Dinero.defaultCurrency = 'GBP'

const StyledListItem = styled.li`
  display: flex;
  padding: ${GTR.S} 0;
  border-bottom: 1px solid ${COLOR.GREY.LIGHT};
`

const StyledStrong = styled.strong`
  flex-basis: 50%;
`

const StyledParagraph = styled(P)`
  font-size: ${FONT.SIZE.S};
`

const CustomerInfoSummary = ({ probateEstate, data }) => {
  const allSectionsCompleted =
    probateEstate.attributes.completedSections.length === 6

  const factFindIsCompleteMessage =
    'This summary is based on information provided so far. Fact find has been marked as complete, but the information may still need to be checked.'

  const factFindIsIncompleteMessage =
    'This summary is based on information provided so far. Fact find isn’t complete so this information may not be final.'

  let hasWill

  if (data.attributes.hasWill === null) {
    hasWill = <i>Not specified</i>
  } else {
    hasWill = data.attributes.hasWill ? 'Yes' : 'No'
  }

  return (
    <Wrapper>
      <Grid>
        <Grid.Item span={9}>
          <Wrapper margin={[0, 0, 'L']}>
            <MessageBox warning>
              <StyledParagraph>
                {allSectionsCompleted
                  ? factFindIsCompleteMessage
                  : factFindIsIncompleteMessage}
              </StyledParagraph>
            </MessageBox>
          </Wrapper>
        </Grid.Item>
        <Grid.Item span={10}>
          <ul>
            <StyledListItem>
              <StyledStrong>Person who died</StyledStrong>
              {data.attributes.name}
            </StyledListItem>
            <StyledListItem>
              <StyledStrong>Fee quoted (£)</StyledStrong>
              {Dinero({ amount: data.attributes.feeQuoted || 0 }).toFormat(
                '0,0.00'
              )}
            </StyledListItem>
            <StyledListItem>
              <StyledStrong>Will</StyledStrong> {hasWill}
            </StyledListItem>
            <StyledListItem>
              <StyledStrong>Tax form</StyledStrong> {data.attributes.taxForm}
            </StyledListItem>
          </ul>
        </Grid.Item>
      </Grid>
    </Wrapper>
  )
}

export default CustomerInfoSummary
