import styled from 'styled-components'
import { RouteComponentProps } from 'react-router-dom'
import { Button, Grid, H, Image, P, Wrapper } from '@farewill/ui'
import { GTR } from '@farewill/ui/tokens'

import ENV from 'config/environment'
import { CreateApiResponse } from 'routes/partners/interfaces'
import {
  getHeadingText,
  getImagePath,
  isDuplicate,
  isFailed,
  isSuccess,
} from './helpers'

const StyledImage = styled(Image)`
  margin-bottom: ${GTR.M};
`

const StyledButton = styled(Button.Primary)`
  margin-top: ${GTR.M};
`

type Props = RouteComponentProps & CreateApiResponse
const NewPartnerResult = ({
  status,
  attributes,
  contentfulId,
}: Props): React.ReactElement => {
  const PartnerName = () => (
    <strong>
      {attributes.charity?.commonName ||
        attributes.charity?.legalName ||
        attributes.organisation?.name}
    </strong>
  )

  /** If a Contentful entry was successfully created for the new partner, we will direct
   * the user to the entry's page. Otherwise, we direct them to the Contentful entries list
   * page where they can create a new entry themself. Engineering will already have been
   * alerted of the failure via Sentry.
   */
  const contentfulUrl = contentfulId
    ? `https://app.contentful.com/spaces/${ENV.CONTENTFUL_SPACE_ID}/environments/${ENV.CONTENTFUL_ENV_ID}/entries/${contentfulId}`
    : `https://app.contentful.com/spaces/${ENV.CONTENTFUL_SPACE_ID}/${ENV.CONTENTFUL_ENV_ID}/views/entries`

  return (
    <Grid padding={[GTR.XL, 0, 0]}>
      <Grid.Item
        span={8}
        startColumn={3}
        spanFromL={6}
        startColumnFromL={4}
        centered
      >
        <Wrapper padding={[0, 'XL', 'XL']}>
          <StyledImage
            path={`illustrations/cropped/${getImagePath(status)}`}
            width={100}
          />
          <H tag="h1" size="M">
            {getHeadingText(status)}
          </H>
          {isFailed(status) && (
            <P>
              Something went wrong in the setup for <PartnerName />. Please
              contact a member of the engineering team to investigate.
            </P>
          )}

          {isDuplicate(status) && (
            <P>
              It seems like the partner you tried to add already exists. If you
              want to edit the setup of an existing partner, please contact a
              member of the engineering team.
            </P>
          )}

          {isSuccess(status) && (
            <>
              <P>
                To complete <PartnerName />
                ’s set up, create their profile on Contentful.
              </P>
              {!contentfulId && (
                <P>
                  Unfortunately, we were unable to auto-create the profile, so
                  you should add it yourself. Engineering has been informed.
                </P>
              )}
              {contentfulUrl && (
                <StyledButton
                  stretch
                  onClick={() =>
                    window.open(contentfulUrl, '_blank', 'noopener noreferrer')
                  }
                >
                  Continue to Contentful
                </StyledButton>
              )}
            </>
          )}
        </Wrapper>
      </Grid.Item>
    </Grid>
  )
}

export default NewPartnerResult
