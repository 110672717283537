import PropTypes from 'prop-types'
import { H, Grid } from '@farewill/ui'

import InputFloating from 'components/form/input-floating'
import NotesInput from 'components/notes-input'
import withForm from '../fact-find/components/with-form'

import {
  DEATH_CERTIFICATE_CHECK_STATUS,
  MARRIAGE_CERTIFICATE_CHECK_STATUS,
  WILL_CHECK_STATUS,
  CODICILS_CHECK_STATUS,
  LASTING_POWER_OF_ATTORNEY_STATUS,
  ENDURING_POWER_OF_ATTORNEY_STATUS,
  DEPUTYSHIP_ORDER_STATUS,
  ADOPTION_CERTIFICATE_STATUS,
  DOCUMENTS_CHECKLIST_NOTES,
} from './field-names'

const DOCUMENT_CHECK_STATUS_OPTIONS = [
  { label: 'Not received', value: 'not_received' },
  { label: 'Copy received', value: 'copy_received' },
  { label: 'Copy received and checked', value: 'copy_received_and_checked' },
  { label: 'Original received', value: 'original_received' },
  {
    label: 'Original received and checked',
    value: 'original_received_and_checked',
  },
  { label: 'Not applicable', value: 'not_applicable' },
]

const DocumentsChecklist = ({ saveProbateEstateField }) => (
  <Grid>
    <Grid.Item>
      <H size="S">1. Documents checklist</H>
    </Grid.Item>
    <Grid.Item span={6}>
      <InputFloating
        component="select"
        handleSave={saveProbateEstateField}
        label="Death certificate"
        name={DEATH_CERTIFICATE_CHECK_STATUS}
        options={DOCUMENT_CHECK_STATUS_OPTIONS}
      />
    </Grid.Item>
    <Grid.Item span={6}>
      <InputFloating
        component="select"
        handleSave={saveProbateEstateField}
        label="Marriage certificate"
        name={MARRIAGE_CERTIFICATE_CHECK_STATUS}
        options={DOCUMENT_CHECK_STATUS_OPTIONS}
      />
    </Grid.Item>
    <Grid.Item span={6}>
      <InputFloating
        component="select"
        handleSave={saveProbateEstateField}
        label="Will"
        name={WILL_CHECK_STATUS}
        options={DOCUMENT_CHECK_STATUS_OPTIONS}
      />
    </Grid.Item>
    <Grid.Item span={6}>
      <InputFloating
        component="select"
        handleSave={saveProbateEstateField}
        label="Codicils"
        name={CODICILS_CHECK_STATUS}
        options={DOCUMENT_CHECK_STATUS_OPTIONS}
      />
    </Grid.Item>
    <Grid.Item span={6}>
      <InputFloating
        component="select"
        handleSave={saveProbateEstateField}
        label="Registered LPA + medical certificate"
        name={LASTING_POWER_OF_ATTORNEY_STATUS}
        options={DOCUMENT_CHECK_STATUS_OPTIONS}
      />
    </Grid.Item>
    <Grid.Item span={6}>
      <InputFloating
        component="select"
        handleSave={saveProbateEstateField}
        label="Registered enduring power of attorney"
        name={ENDURING_POWER_OF_ATTORNEY_STATUS}
        options={DOCUMENT_CHECK_STATUS_OPTIONS}
      />
    </Grid.Item>
    <Grid.Item span={6}>
      <InputFloating
        component="select"
        handleSave={saveProbateEstateField}
        label="Deputyship order"
        name={DEPUTYSHIP_ORDER_STATUS}
        options={DOCUMENT_CHECK_STATUS_OPTIONS}
      />
    </Grid.Item>
    <Grid.Item span={6}>
      <InputFloating
        component="select"
        handleSave={saveProbateEstateField}
        label="Adoption certificate"
        name={ADOPTION_CERTIFICATE_STATUS}
        options={DOCUMENT_CHECK_STATUS_OPTIONS}
      />
    </Grid.Item>
    <Grid.Item>
      <NotesInput
        handleSave={saveProbateEstateField}
        name={DOCUMENTS_CHECKLIST_NOTES}
      />
    </Grid.Item>
  </Grid>
)

DocumentsChecklist.propTypes = {
  saveProbateEstateField: PropTypes.func.isRequired,
}

export default withForm(DocumentsChecklist)
