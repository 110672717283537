import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

type UseFeatureFlagParams = {
  name: string
  acceptedValues: string[]
}

const useFeatureFlag = ({
  name,
  acceptedValues,
}: UseFeatureFlagParams): string | null => {
  const { search } = useLocation()
  const [flagValue, setFlagValue] = useState(null as string | null)

  useEffect(() => {
    const params = new URLSearchParams(search)
    const value = params.get(name)

    if (value != null && acceptedValues.includes(value)) {
      setFlagValue(value)
    } else {
      setFlagValue(null)
    }
  }, [name, acceptedValues, search])

  return flagValue
}

export default useFeatureFlag
