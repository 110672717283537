import styled from 'styled-components'
import { P } from '@farewill/ui'
import { BORDER, COLOR, FONT, GTR } from '@farewill/ui/tokens'

const ErrorBox = styled(P)`
  background: ${COLOR.STATE.ERROR_10};
  border: 2px solid ${COLOR.STATE.ERROR};
  border-radius: ${BORDER.RADIUS.S};
  color: ${COLOR.GREY.DARK};
  font-size: ${FONT.SIZE.S};
  padding: ${GTR.S};
`

export default ErrorBox
