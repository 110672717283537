import { RouteComponentProps } from 'react-router-dom'
import { ArrowRightIcon, PersonIcon } from '@farewill/ui'
import { COLOR, FONT } from '@farewill/ui/tokens'
import styled from 'styled-components'
import upperFirst from 'lodash/upperFirst'

import { WillCase } from 'lib/models/will-case'
import getPartnerContact from 'lib/will/get-partner-contact'
import { getPermanentResidence } from 'lib/will/lead'
import {
  getFullNameWithPreferredName,
  nextTaskDueOnString,
} from 'utils/helpers'
import { getCountryLabelFromValue } from 'routes/lead/will-form/helpers/form-utils'

import AdminIcon from 'components/admin-icon'
import StyledLink from 'components/list/link'
import StyledGridRow from 'components/list/grid-row'
import GridColumn from 'components/list/grid-column'
import StyledLoadingRow from 'components/list/loading-row'
import NoResults from 'components/list/no-results'
import DateBadge from 'components/date-badge'
import SortArrow from 'components/sort-arrow'
import StyledSortableHeader from 'components/list/sortable-header'

import OwnerIcons from '../owner-icons'
import CaseIcons from '../case-icons'
import { Sort, SetSort, SetPage } from '../types'

const StyledSubText = styled.span`
  display: block;
  font-size: ${FONT.SIZE.XS};
`

type Props = {
  cases: WillCase[]
  isLoading: boolean
  sort: Sort
  setSort: SetSort
  setPage: SetPage
}

const GRID_COLUMNS = '2fr 1.5fr 1fr 1fr 0.6fr 0.6fr 68px 16px'

const WillDrafting = ({
  cases,
  isLoading,
  sort,
  setSort,
  setPage,
}: RouteComponentProps & Props): React.ReactElement => {
  return (
    <>
      <StyledGridRow header gridTemplateColumns={GRID_COLUMNS}>
        <GridColumn>Customer</GridColumn>
        <GridColumn>Location</GridColumn>
        <GridColumn>Will grade</GridColumn>
        <GridColumn>
          <StyledSortableHeader
            onClick={() => {
              const options = ['willDueOn', '-willDueOn']
              const nextOption =
                options[options.indexOf(sort as string) + 1] || null
              setSort(nextOption as Sort)
              setPage({})
            }}
          >
            Will due date
            <SortArrow
              sorted={['willDueOn', '-willDueOn'].includes(sort as string)}
              asc={sort === '-willDueOn'}
            />
          </StyledSortableHeader>
        </GridColumn>
        <GridColumn>Owner</GridColumn>
        <GridColumn>Drafter</GridColumn>
      </StyledGridRow>

      {isLoading && <StyledLoadingRow>Loading...</StyledLoadingRow>}
      {!isLoading && !cases && <NoResults type="cases" />}

      {!isLoading &&
        cases?.map((row) => (
          <StyledLink to={`/will-cases/${row.id}`} key={row.id}>
            <StyledGridRow gridTemplateColumns={GRID_COLUMNS}>
              <GridColumn>
                {getFullNameWithPreferredName(row.attributes.primaryContact)}
                <StyledSubText>
                  {getFullNameWithPreferredName(getPartnerContact(row))}
                </StyledSubText>
              </GridColumn>
              <GridColumn>
                {getCountryLabelFromValue(
                  getPermanentResidence(row.attributes.lead)
                )}
              </GridColumn>
              <GridColumn>
                {row.attributes.grade && upperFirst(row.attributes.grade)}
              </GridColumn>
              <GridColumn>
                <DateBadge
                  date={nextTaskDueOnString(row.attributes.willDueOn)}
                />
              </GridColumn>
              <GridColumn>
                <OwnerIcons
                  caseOwner={row.attributes.caseOwner}
                  holidayCoverOwner={row.attributes.holidayCoverOwner}
                />
              </GridColumn>
              <GridColumn>
                {row.attributes.drafter ? (
                  <AdminIcon user={row.attributes.drafter} enableTooltip />
                ) : (
                  <PersonIcon
                    color={COLOR.WHITE}
                    background={COLOR.GREY.LIGHT}
                  />
                )}
              </GridColumn>
              <GridColumn>
                <CaseIcons
                  isPriority={row.attributes.isUrgent}
                  hasTaskNeedingHelp={row.attributes.hasTaskNeedingHelp}
                />
              </GridColumn>
              <GridColumn>
                <ArrowRightIcon size="S" />
              </GridColumn>
            </StyledGridRow>
          </StyledLink>
        ))}
    </>
  )
}

export default WillDrafting
