import styled from 'styled-components'
import { COLOR, FONT, GTR } from '@farewill/ui/tokens'
import { Button, Grid, P } from '@farewill/ui'
import get from 'lodash/get'
import { useFormikContext } from 'formik'

import Address from 'components/address'
import EstateCard from 'components/estate-card'
import { InputFloating, CircularRadioGroup, Checkbox } from 'components/form'
import RadioGroup from 'components/form/radio-group'
import NotesInput from 'components/notes-input'
import {
  ProbateEstate,
  ProbateEstatePropertiesAttributes,
  ProbateEstateProperty,
} from 'lib/models/probate-estate'
import namespace from 'lib/formik/namespace'
import { deceasedShareValidation } from 'lib/formik/validation'
import withForm from 'routes/lead/probate-form/with-form'
import { YES_NO_OPTIONS } from 'utils/enums'
import { strToBool } from 'utils/helpers'
import { HandleSaveProps } from '../assets/types'
import { useProperties } from '../helpers/properties-context'
import { ESTATE_PROPERTIES_ATTRIBUTES } from './constants'
import { ReactNode } from 'react'
import Tooltip from 'components/tooltip'
import Label from 'components/styled/label'
import { ProbateLeadAttributes } from 'lib/models/lead'
import store from 'state/create-store'
import { showModal } from 'state/actions'
import DeleteModal from '../delete-modal'
import SectionHeading from '../section-heading'
import { useProbateEstate } from '../helpers/probate-estate-context'
import LegacyLeadMessageBox from '../legacy-lead-message-box'

const StyledRadioWrapper = styled(Grid.Item)`
  display: flex;
  align-items: center;
`

const StyledHint = styled(P)`
  font-size: ${FONT.SIZE.XS};
  color: ${COLOR.GREY.MEDIUM};
`

export type PropertyProps = {
  property: ProbateEstateProperty
  listLength: number
  listPosition: number
  extraFields?: ReactNode
}

const Property = ({ property }: PropertyProps) => {
  const { values } = useFormikContext<ProbateEstatePropertiesAttributes>()
  const { properties, updateProperty } = useProperties()
  const { withNamespace, withoutNamespace } = namespace(
    `properties.${property.id}.`
  )

  const address = get(
    values,
    withNamespace(ESTATE_PROPERTIES_ATTRIBUTES.ADDRESS)
  )
  const ownershipIsJoint =
    get(values, withNamespace(ESTATE_PROPERTIES_ATTRIBUTES.OWNERSHIP)) ===
    'joint'
  const hasCharges = strToBool(
    get(values, withNamespace(ESTATE_PROPERTIES_ATTRIBUTES.HAS_CHARGES))
  )
  const isMainResidence = strToBool(
    get(values, withNamespace(ESTATE_PROPERTIES_ATTRIBUTES.IS_MAIN_RESIDENCE))
  )

  const handleSave = ({ name, value }: HandleSaveProps) => {
    updateProperty({
      id: property.id,
      attributes: { [withoutNamespace(name)]: value || null },
    })
  }

  return (
    <>
      <Grid.Item>
        <Checkbox
          name={withNamespace(ESTATE_PROPERTIES_ATTRIBUTES.IS_MAIN_RESIDENCE)}
          label="Main residence"
          checked={isMainResidence}
          handleSave={({ name, value }) => {
            // This removes isMainResidence flag from other properties
            // if this property is set to be main residence
            const propertyWithMainResidence = properties.find(
              (singleProperty) =>
                strToBool(singleProperty.attributes.isMainResidence) &&
                property.id !== singleProperty.id
            )

            if (propertyWithMainResidence) {
              updateProperty({
                ...propertyWithMainResidence,
                attributes: { isMainResidence: 'false' },
              })
            }

            handleSave({ name, value })
          }}
        />
      </Grid.Item>
      <Grid.Item span={9}>
        <Address
          label="Address"
          name={withNamespace(
            `${ESTATE_PROPERTIES_ATTRIBUTES.ADDRESS}.${address.id}}`
          )}
          data={address}
        />
      </Grid.Item>
      <Grid.Item span={9}>
        <RadioGroup
          label="Is the property registered?"
          name={withNamespace(
            ESTATE_PROPERTIES_ATTRIBUTES.REGISTRY_CHECK_STATUS
          )}
          options={[
            {
              label: 'Yes',
              value: 'yes',
            },
            {
              label: 'No',
              value: 'no_entry',
            },
          ]}
          handleSave={handleSave}
        />
      </Grid.Item>
      <Grid.Item>
        <Grid style={{ alignItems: 'center' }}>
          <Grid.Item span={4}>
            <InputFloating
              name={withNamespace(ESTATE_PROPERTIES_ATTRIBUTES.VALUE)}
              label="Total value (£)"
              handleSave={handleSave}
              isCurrency
            />
          </Grid.Item>
          <StyledRadioWrapper span={8}>
            <CircularRadioGroup
              name={withNamespace(
                ESTATE_PROPERTIES_ATTRIBUTES.IS_ESTIMATED_VALUE
              )}
              options={[
                { label: 'Estimate', value: 'true' },
                { label: 'Professional valuation', value: 'false' },
              ]}
              handleSave={handleSave}
              inline
            />
          </StyledRadioWrapper>
        </Grid>
        <StyledHint margin={['XS', 0, 0, 'S']}>
          For joint assets, the £ value of their share will be calculated
          automatically.
        </StyledHint>
      </Grid.Item>
      <Grid.Item>
        <CircularRadioGroup
          name={withNamespace(ESTATE_PROPERTIES_ATTRIBUTES.OWNERSHIP)}
          label="Ownership"
          options={[
            { label: 'Sole', value: 'sole' },
            { label: 'Joint', value: 'joint' },
          ]}
          handleSave={handleSave}
          inline
        />
      </Grid.Item>
      {ownershipIsJoint && (
        <>
          <Grid.Item>
            <CircularRadioGroup
              name={withNamespace(
                ESTATE_PROPERTIES_ATTRIBUTES.JOINT_OWNERSHIP_TYPE
              )}
              label="Type of joint ownership"
              options={[
                { label: 'Not asked', value: '' },
                { label: 'Joint tenants', value: 'joint_tenants' },
                { label: 'Tenants in common', value: 'tenants_in_common' },
                { label: 'Unsure (leave note)', value: 'unsure' },
              ]}
              handleSave={handleSave}
            />
          </Grid.Item>
          <Grid.Item span={6}>
            <InputFloating
              name={withNamespace(
                ESTATE_PROPERTIES_ATTRIBUTES.DECEASED_SHARE_OF_JOINT_OWNERSHIP
              )}
              label="Share owned by person who’s died (%)"
              handleSave={handleSave}
              type="number"
              validate={deceasedShareValidation}
            />
          </Grid.Item>
        </>
      )}
      <Grid.Item span={9}>
        <RadioGroup
          label="Are there any charges against this property, like a mortgage, equity release or care fees?"
          name={withNamespace(ESTATE_PROPERTIES_ATTRIBUTES.HAS_CHARGES)}
          options={YES_NO_OPTIONS}
          handleSave={handleSave}
        />
      </Grid.Item>
      {hasCharges && (
        <Grid.Item span={11}>
          <InputFloating
            name={withNamespace(
              ESTATE_PROPERTIES_ATTRIBUTES.DECEASED_SHARE_OF_CHARGES_VALUE
            )}
            label="What was the value of the deceased's share of these charges?"
            handleSave={handleSave}
            type="number"
            isCurrency
          />
        </Grid.Item>
      )}
      <Grid.Item>
        <CircularRadioGroup
          name={withNamespace(ESTATE_PROPERTIES_ATTRIBUTES.INTENTIONS)}
          label="What will happen to this property?"
          options={[
            { label: 'Sold', value: 'sold' },
            { label: 'Transferred', value: 'transferred' },
            { label: 'Unsure', value: 'unsure' },
          ]}
          handleSave={handleSave}
          inline
        />
      </Grid.Item>
    </>
  )
}

const PropertyWithForm = withForm(Property)

const PropertyNotes = ({ property }: PropertyProps) => {
  const { updateProperty } = useProperties()
  const { withNamespace, withoutNamespace } = namespace(
    `properties.${property.id}.`
  )

  const handleSave = ({ name, value }: HandleSaveProps) => {
    updateProperty({
      id: property.id,
      attributes: { [withoutNamespace(name)]: value || null },
    })
  }

  return (
    <Grid.Item>
      <NotesInput
        name={withNamespace(ESTATE_PROPERTIES_ATTRIBUTES.NOTES)}
        handleSave={handleSave}
      />
    </Grid.Item>
  )
}

const PropertyNotesWithForm = withForm(PropertyNotes)

type PropertiesProps = {
  initialValues: ProbateEstate['attributes']
  enableReinitialize?: boolean
  numberOfProperties: number
  isComplete: boolean
}

const Properties = ({ isComplete }: PropertiesProps) => {
  const { properties, createProperty, deleteProperty } = useProperties()
  const { probateEstateId } = useProbateEstate()
  const { values, handleSubmit, setFieldValue } =
    useFormikContext<ProbateLeadAttributes>()

  const clearPropertyPassingToDirectDescendantFields = ({
    property,
    values,
  }: {
    property: ProbateEstateProperty
    values: ProbateLeadAttributes
  }) => {
    if (
      property.attributes.isMainResidence &&
      values.quoteAttributes.propertyPassingToDirectDescendant
    ) {
      setFieldValue('quoteAttributes.propertyPassingToDirectDescendant', null)
      setFieldValue(
        'quoteAttributes.propertyPassingToDirectDescendantValue',
        null
      )
      handleSubmit()
    }
  }

  const onTrashClick = (property: ProbateEstateProperty) => {
    store.dispatch(
      showModal({
        component: DeleteModal,
        headingText: 'Delete property',
        config: {
          deleteCallback: () => {
            clearPropertyPassingToDirectDescendantFields({ property, values })
            deleteProperty(property.id)
          },
          type: 'property',
          resourceId: property.id,
        },
      })
    )
  }

  return (
    <>
      <SectionHeading
        title="5. Properties"
        scrollId="properties"
        isComplete={isComplete}
      />
      {probateEstateId && (
        <Grid.Item margin={['L', 0, 0]}>
          <P>
            Did the person who died own any properties?{' '}
            {properties.length > 0
              ? 'Enter the details below:'
              : 'Add properties below:'}
          </P>
          <Grid margin={['M', 0, 'M', 'M']}>
            {properties.length > 0 && (
              <Grid.Item>
                <Grid margin={['M', 0, 'M', 'M']}>
                  {properties.map(
                    (property: ProbateEstateProperty, index: number) => (
                      <Grid.Item key={property.id} span={11}>
                        <EstateCard
                          onTrashClick={() => onTrashClick(property)}
                          listLength={properties.length}
                          listPosition={index + 1}
                          type="Property"
                        >
                          <Grid style={{ gridAutoFlow: 'row' }}>
                            <PropertyWithForm
                              enableReinitialize
                              index={index}
                              key={property.id}
                              property={property}
                              initialValues={{
                                properties: {
                                  [property.id]: property.attributes,
                                },
                              }}
                            />
                            {strToBool(property.attributes.isMainResidence) && (
                              <>
                                <Grid.Item>
                                  <RadioGroup
                                    label={
                                      <>
                                        Is the property (or part of a property)
                                        passing to a{' '}
                                        <Tooltip
                                          content={
                                            <P>
                                              Including: a child, grandchild or
                                              great-grandchild (including step,
                                              adopted and fostered), or a spouse
                                              or civil partner of any of those.
                                            </P>
                                          }
                                        >
                                          direct descendant
                                        </Tooltip>
                                        , such as a child or grandchild?
                                      </>
                                    }
                                    name="quoteAttributes.propertyPassingToDirectDescendant"
                                    options={YES_NO_OPTIONS}
                                    handleSave={() => handleSubmit()}
                                  />
                                </Grid.Item>
                                {strToBool(
                                  values.quoteAttributes
                                    .propertyPassingToDirectDescendant
                                ) && (
                                  <Grid.Item>
                                    <Label
                                      htmlFor="quoteAttributes.propertyPassingToDirectDescendantValue"
                                      $error={false}
                                    >
                                      What is the value of the property (or part
                                      of the property) that is passing to a
                                      direct descendant?
                                    </Label>
                                    <InputFloating
                                      name="quoteAttributes.propertyPassingToDirectDescendantValue"
                                      hint="Example: if the property is worth £300,000 and 50% is passing to the child, put £150,000"
                                      label="Property value (£)"
                                      handleSave={() => handleSubmit()}
                                      type="number"
                                      isCurrency
                                    />
                                  </Grid.Item>
                                )}
                              </>
                            )}
                            <PropertyNotesWithForm
                              enableReinitialize
                              initialValues={{
                                properties: {
                                  [property.id]: property.attributes,
                                },
                              }}
                              property={property}
                            />
                          </Grid>
                        </EstateCard>
                      </Grid.Item>
                    )
                  )}
                </Grid>
              </Grid.Item>
            )}
          </Grid>
        </Grid.Item>
      )}
      {probateEstateId && (
        <Grid.Item margin={properties.length > 0 ? ['L', 0, 0] : 0}>
          {properties.length > 0 && <P>Are there any more properties?</P>}
          <Button.Secondary
            style={{ paddingTop: GTR.XXS, paddingBottom: GTR.XXS }}
            type="button"
            onClick={() => {
              let attributes = {}
              if (properties.length === 0) {
                attributes = { isMainResidence: true }
              }
              createProperty(attributes)
            }}
          >
            Add property
          </Button.Secondary>
        </Grid.Item>
      )}

      {!probateEstateId && (
        <Grid margin={['L', 0, 0]}>
          <Grid.Item startColumn={2}>
            <LegacyLeadMessageBox />
          </Grid.Item>
        </Grid>
      )}
    </>
  )
}

export default Properties
