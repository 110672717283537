import { useFormikContext } from 'formik'
import { Grid, H } from '@farewill/ui'

import { Input } from 'components/form/index'
import MessageBox from 'components/message-box'
import useAdminUserRoles from 'hooks/useAdminUserRoles'
import { FuneralPlanLeadAttributes } from 'lib/models/lead'

const Vulnerabilities = () => {
  const { isUserRole } = useAdminUserRoles()
  const { handleSubmit } = useFormikContext<FuneralPlanLeadAttributes>()

  return (
    <>
      <H size="S">Caller vulnerabilities</H>
      <MessageBox warning margin={['S', 0, 'M']}>
        If you have explicit consent, note the detail of the vulnerability and
        any reasonable adjustment we should make.
      </MessageBox>
      <Grid style={{ gridAutoFlow: 'row' }}>
        <Grid.Item spanFromL={12}>
          <Input
            name="quoteAttributes.vulnerabilities"
            component="textarea"
            label="Vulnerabilities *"
            rows={6}
            handleSave={() => handleSubmit()}
            disabled={!isUserRole('funeral_plans_editor')}
          />
        </Grid.Item>
      </Grid>
    </>
  )
}

export default Vulnerabilities
