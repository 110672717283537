import { Wrapper, P } from '@farewill/ui'
import { useSelector } from 'react-redux'

import { Lead } from 'lib/models/lead'
import { useFetchResource, RESOURCES } from 'api'
import { WillCase } from 'lib/models/will-case'

import OnlineAccount from './online-account'
import FactFindNotes from './fact-find-notes'

type Props = {
  isPartner: boolean
  willCaseId: number
}

const SimpleTelephoneWill = ({
  isPartner,
  willCaseId,
}: Props): React.ReactElement | null => {
  const lead = useSelector((state: { lead: Lead }) => state.lead)

  const willCaseQuery = useFetchResource<WillCase>({
    id: willCaseId,
    resource: RESOURCES.WILL_CASES,
  })

  if (willCaseQuery.isLoading || willCaseQuery.isIdle) return <P>Loading...</P>

  if (!willCaseQuery.data) return <P size="L">There is no data to display.</P>

  const { partnerUtmSource } = lead.attributes
  const {
    externalPartnerId,
    externalUserId,
    partnerCreatedAt,
    userCreatedAt,
    willComplexity,
  } = willCaseQuery.data.attributes

  if (willComplexity !== 'simple') return null

  return (
    <Wrapper>
      <OnlineAccount
        externalUserId={isPartner ? externalPartnerId : externalUserId}
        onlineAccountCreatedAt={isPartner ? partnerCreatedAt : userCreatedAt}
        partnerUtmSource={partnerUtmSource}
        willCaseId={willCaseQuery.data.id}
        isPartner={isPartner}
      />
      <FactFindNotes id={willCaseQuery.data.id} />
    </Wrapper>
  )
}

export default SimpleTelephoneWill
