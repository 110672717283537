import { RouteComponentProps } from 'react-router-dom'
import { Button, H, Wrapper, P } from '@farewill/ui'
import { useDispatch } from 'react-redux'
import { showModal } from 'state/actions'

import { useFetchResource, RESOURCES } from 'api'
import { WillCase } from 'lib/models/will-case'
import { Will } from 'lib/models/will'

import Table from 'components/table'
import WillGradeModal from './will-grade-modal'
import WillFeaturesModal from './will-features-modal'
import { getLabel } from 'utils/helpers'
import { WILL_GRADE_OPTIONS, WILL_FEATURE_OPTIONS } from 'utils/enums'
import SimpleList from 'components/styled/simple-list'

const GRID_COLS = '1fr 1.5fr 40px'

const Summary = ({
  match,
}: RouteComponentProps<{ id: string; willId: string }>): React.ReactElement => {
  const dispatch = useDispatch()
  const willQuery = useFetchResource<Will>({
    id: Number(match.params.willId),
    resource: RESOURCES.WILLS,
  })
  const willCaseQuery = useFetchResource<WillCase>({
    id: Number(match.params.id),
    resource: RESOURCES.WILL_CASES,
  })

  if (willCaseQuery.isLoading || willCaseQuery.isIdle)
    return <P size="L">Loading...</P>

  if (!willCaseQuery.data) return <P size="L">There is no data to display.</P>

  const { lead, grade } = willCaseQuery.data.attributes
  return (
    <Wrapper>
      <H size="M">Summary</H>
      <Table>
        <Table.Row gridTemplateCols={GRID_COLS}>
          <Table.Col strong>Partner name</Table.Col>
          <Table.Col>{lead.partnerUtmSource || 'Not from a partner'}</Table.Col>
        </Table.Row>
        <Table.Row gridTemplateCols={GRID_COLS}>
          <Table.Col strong>Will grade</Table.Col>
          <Table.Col>
            {getLabel(WILL_GRADE_OPTIONS, grade) || 'Not specified'}
          </Table.Col>
          <Table.Col>
            <Button.Underline
              flush
              onClick={() => {
                dispatch(
                  showModal({
                    component: WillGradeModal,
                    headingText: 'Edit will grade',
                    config: { grade, willCaseId: willCaseQuery.data.id },
                  })
                )
              }}
            >
              Edit
            </Button.Underline>
          </Table.Col>
        </Table.Row>
        <Table.Row gridTemplateCols={GRID_COLS}>
          <Table.Col strong>Will features</Table.Col>
          <Table.Col>
            {willQuery.data && willQuery.data.attributes.features.length > 0 ? (
              <SimpleList bullets>
                {willQuery.data.attributes.features.map((feature, index) => (
                  <li key={index}>{getLabel(WILL_FEATURE_OPTIONS, feature)}</li>
                ))}
              </SimpleList>
            ) : (
              'Not specified'
            )}
          </Table.Col>
          <Table.Col>
            <Button.Underline
              flush
              onClick={() => {
                dispatch(
                  showModal({
                    component: WillFeaturesModal,
                    headingText: 'Edit will features',
                    config: {
                      willId: willQuery.data?.id,
                      willFeatures: willQuery.data
                        ? willQuery.data.attributes.features
                        : [],
                    },
                  })
                )
              }}
            >
              Edit
            </Button.Underline>
          </Table.Col>
        </Table.Row>
      </Table>
    </Wrapper>
  )
}

export default Summary
