import { Grid, H } from '@farewill/ui'
import RadioGroup from 'components/form/radio-group'
import { useFormikContext } from 'formik'
import React from 'react'
import { financialSupportQuestions } from './helpers'

const Dwp = ({ skip }: { skip: boolean }): React.ReactElement => {
  const { handleSubmit } = useFormikContext()

  return (
    <Grid margin={['L', 0]}>
      <Grid.Item span={6}>
        <H size="S">Financial support</H>
        {skip ? (
          <Grid.Item>↑ Complete sections above first</Grid.Item>
        ) : (
          financialSupportQuestions.map((question) => (
            <Grid.Item key={question.name}>
              <RadioGroup
                label={question.label}
                name={question.name}
                options={question.options}
                handleSave={() => handleSubmit()}
              />
            </Grid.Item>
          ))
        )}
      </Grid.Item>
    </Grid>
  )
}

export default Dwp
