import { ReactElement } from 'react'
import { Grid, Wrapper } from '@farewill/ui'
import { useQueryClient } from 'react-query'
import { formatCelebrantOptions } from 'utils/helpers'

import { Celebrant as CelebrantModel } from 'lib/models/celebrant'

import { Props as SelectInputProps } from 'components/form/select-input'

import SelectInput from 'components/form/select-input'

const Celebrant = ({
  handleSave,
}: Pick<SelectInputProps, 'handleSave'>): ReactElement => {
  const cache = useQueryClient()
  const celebrants = cache.getQueryData('celebrants') as CelebrantModel[]
  const celebrantOptions = formatCelebrantOptions(celebrants)

  return (
    <Grid.Item maxWidthInColumns={4}>
      <Wrapper margin={['M', 0]}>
        <SelectInput
          options={celebrantOptions}
          label="Celebrant"
          name="celebrantId"
          handleSave={handleSave}
          isOptionDisabled={(celebrant) => {
            const isCelebrantDeleted = celebrantOptions.find(
              (celebrantOption) => celebrantOption.value === celebrant.value
            )
            return !!isCelebrantDeleted?.isDeleted
          }}
        />
      </Wrapper>
    </Grid.Item>
  )
}

export default Celebrant
