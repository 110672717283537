import { useState } from 'react'
import { P, Button, ButtonGroup } from '@farewill/ui'
import { useDispatch } from 'react-redux'

import { useFetchResource, RESOURCES } from 'api'

import { hideModal } from 'state/actions'
import useApi from 'lib/effects/api'

interface Config {
  willCaseId: string
  customerCallId: number
}

const DeleteCall = ({ config }: { config: Config }): React.ReactElement => {
  const dispatch = useDispatch()
  const { willCaseId, customerCallId } = config
  const [, makeRequest] = useApi()
  const [isSubmitting, setIsSubmitting] = useState(false)

  const willCaseQuery = useFetchResource({
    id: Number(willCaseId),
    resource: RESOURCES.WILL_CASES,
  })

  const deleteCustomerCall = async () => {
    await makeRequest({
      method: 'DELETE',
      url: '/api/customer-calls/' + customerCallId,
    })
    willCaseQuery.refetch()
  }

  const handleClick = async () => {
    setIsSubmitting(true)

    try {
      await deleteCustomerCall()
      dispatch(hideModal())
    } catch {
      setIsSubmitting(false)
    }
  }

  return (
    <>
      <P>
        Are you sure you want to delete this call? All details will be lost.
      </P>
      <ButtonGroup horizontal>
        <Button.Primary
          type="button"
          onClick={handleClick}
          loading={isSubmitting}
        >
          Delete call
        </Button.Primary>
        <Button.Bordered onClick={() => dispatch(hideModal())}>
          Cancel
        </Button.Bordered>
      </ButtonGroup>
    </>
  )
}

export default DeleteCall
