import { P } from '@farewill/ui'
import { Link } from 'react-router-dom'

const NoWillMessage = ({ probateEstateId }) => (
  <>
    <P>
      Not applicable because there’s no will (
      <Link to={`/probate/estates/${probateEstateId}/fact-find#2`}>2</Link>)
    </P>
    <P size="S">↓ Continue to next section</P>
  </>
)

export default NoWillMessage
