import { useEffect } from 'react'
import Dinero from 'dinero.js'
import { useFormikContext } from 'formik'
import styled from 'styled-components'
import {
  Button,
  Divider,
  Grid,
  H,
  P,
  WarningTriangleIcon,
  Wrapper,
} from '@farewill/ui'
import { COLOR, FONT } from '@farewill/ui/tokens'

import { SelectInput } from 'components/form'
import SectionStatus from 'components/section-status'
import SimpleList from 'components/styled/simple-list'
import { COUPLE } from 'config/pricing'
import useApi from 'lib/effects/api'
import { WillLeadAttributes, WillType } from 'lib/models/lead'
import { WillQuoteAPIAttributes } from 'lib/models/quote'
import { formatEmptyStringToUndefined, strToBool } from 'utils/helpers'
import { useLeadContext } from 'routes/lead/context'

import { Outcome } from '../types'

const StyledWarningBox = styled(Wrapper)`
  display: flex;
`

const StyledPriceWrapper = styled(Grid.Item)`
  text-align: right;
`

const StyledPrice = styled(P)`
  font-size: ${FONT.SIZE.XL};
`

const getNumberOfComplexWillsOptions = (willType: WillType) => {
  const isCouple = willType === COUPLE

  const options = [
    {
      value: 0,
      label: isCouple ? 'Both wills are simple' : 'Simple will',
    },
    {
      value: 1,
      label: isCouple ? 'One will is complex' : 'Complex will',
    },
  ]

  if (isCouple) {
    options.push({
      value: 2,
      label: 'Both wills are complex',
    })
  }

  return options
}

const Price = ({
  outcome,
  freeWillEligible,
  isCharity,
}: {
  outcome: Outcome
  freeWillEligible?: boolean
  isCharity?: boolean
}) => {
  const { handleSubmit, setFieldValue, values } =
    useFormikContext<WillLeadAttributes>()
  const [data, getPrice] = useApi<WillQuoteAPIAttributes>()
  const { lead } = useLeadContext()
  const {
    willType,
    stepRelations,
    wantsToGiftBusiness,
    wantsToGiftProperty,
    hasDisabledOrMeansTestedBeneficiary,
    wantsToPutHouseInTrust,
    hasBeneficiaryInvolvedInDivorceOrBankruptcy,
    discountPercentage,
  } = values.quoteAttributes
  const numberOfComplexWillsOptions = getNumberOfComplexWillsOptions(
    willType as WillType
  )
  const numberOfComplexWillsOverwrite = formatEmptyStringToUndefined(
    values.quoteAttributes.numberOfComplexWillsOverwrite
  )

  useEffect(() => {
    if (discountPercentage && discountPercentage < 0) {
      setFieldValue('quoteAttributes.discountPercentage', 0)
      return
    }

    if (discountPercentage && discountPercentage > 100) {
      setFieldValue('voucherType', 'custom_discount')
      setFieldValue('quoteAttributes.discountPercentage', 100)
      return
    }
    if (values.voucherType !== 'custom_discount') {
      setFieldValue('quoteAttributes.discountPercentage', 0)
    }

    if (willType === 'single' && numberOfComplexWillsOverwrite === 2) {
      setFieldValue('quoteAttributes.numberOfComplexWillsOverwrite', 1)
      return
    }

    if (outcome?.complete) {
      getPrice({
        url: '/api/quotes',
        method: 'POST',
        data: {
          data: {
            type: 'quotes',
            attributes: {
              leadId: lead.id,
              product: values.product,
              quoteAttributes: {
                willType,
                stepRelations: strToBool(stepRelations) || false,
                numberOfComplexWillsOverwrite,
                wantsToGiftBusiness,
                wantsToGiftProperty,
                hasDisabledOrMeansTestedBeneficiary,
                wantsToPutHouseInTrust: wantsToPutHouseInTrust
                  ? strToBool(wantsToPutHouseInTrust)
                  : false,
                hasBeneficiaryInvolvedInDivorceOrBankruptcy:
                  hasBeneficiaryInvolvedInDivorceOrBankruptcy
                    ? strToBool(hasBeneficiaryInvolvedInDivorceOrBankruptcy)
                    : false,
                discountPercentage,
              },
            },
          },
        },
      })
    }
  }, [
    values,
    discountPercentage,
    getPrice,
    hasBeneficiaryInvolvedInDivorceOrBankruptcy,
    hasDisabledOrMeansTestedBeneficiary,
    numberOfComplexWillsOverwrite,
    outcome?.complete,
    setFieldValue,
    stepRelations,
    wantsToGiftBusiness,
    wantsToGiftProperty,
    wantsToPutHouseInTrust,
    willType,
    lead.id,
    lead.attributes.voucherCode,
  ])

  useEffect(() => {
    setFieldValue(
      'quoteAttributes.numberOfComplexWills',
      numberOfComplexWillsOverwrite !== undefined
        ? numberOfComplexWillsOverwrite
        : data.data?.attributes.numberOfComplexWills
    )
    handleSubmit()
  }, [
    numberOfComplexWillsOverwrite,
    data.data?.attributes.numberOfComplexWills,
    handleSubmit,
    setFieldValue,
  ])

  useEffect(() => {
    if (data.data?.attributes.payableAmountInPence !== undefined) {
      const { payableAmountInPence, packagePriceInPence } = data.data.attributes
      setFieldValue(
        'quoteAttributes.price',
        Dinero({
          amount: payableAmountInPence,
        }).toUnit()
      )
      setFieldValue('quoteAttributes.willPrice', packagePriceInPence)

      handleSubmit()
    }
  }, [
    data.data?.attributes,
    data.data?.attributes.payableAmountInPence,
    data.data?.attributes.packagePriceInPence,
    handleSubmit,
    setFieldValue,
  ])

  useEffect(() => {
    if (freeWillEligible) {
      setFieldValue('quoteAttributes.discountPercentage', 100)
    } else {
      setFieldValue('quoteAttributes.discountPercentage', 0)
    }
  }, [freeWillEligible, setFieldValue])

  let accountLabel = 'Phone will'
  accountLabel =
    willType === 'couple' ? `${accountLabel} (couples)` : accountLabel

  const handleRevertNumberOfComplexWillsOverwrite = () => {
    setFieldValue(
      'quoteAttributes.numberOfComplexWills',
      data.data?.attributes.numberOfComplexWills
    )
    setFieldValue('quoteAttributes.numberOfComplexWillsOverwrite', undefined)
    handleSubmit()
  }

  return (
    <Grid>
      <Grid.Item>
        <SectionStatus
          complete={outcome?.complete}
          heading="Quote summary"
          incompleteText="Please complete the questions above"
        >
          <>
            <Wrapper margin={[0, 0, 'S']}>
              <SelectInput
                label="Complexity:"
                name="quoteAttributes.numberOfComplexWills"
                options={numberOfComplexWillsOptions}
                handleSave={({ value }) => {
                  setFieldValue(
                    'quoteAttributes.numberOfComplexWillsOverwrite',
                    value
                  )
                  handleSubmit()
                }}
              />
              {numberOfComplexWillsOverwrite !== undefined && (
                <P>
                  This has been manually updated.{' '}
                  <Button.Underline
                    flush
                    onClick={() => handleRevertNumberOfComplexWillsOverwrite()}
                  >
                    Revert to automatic calculation
                  </Button.Underline>
                  .
                </P>
              )}
            </Wrapper>
            {data.data?.attributes.numberOfComplexWills === 1 &&
              numberOfComplexWillsOverwrite === undefined && (
                <>
                  <P>Reason:</P>
                  {typeof outcome?.message === 'function'
                    ? outcome?.message()
                    : outcome?.message}
                </>
              )}
            {data.data?.attributes.payableAmountInPence === 0 ? (
              <>
                <Divider margin={['M', 0]} />
                <Grid.Item>
                  {freeWillEligible && (
                    <P>The organisation is giving this will to the customer.</P>
                  )}
                  <P>No payment necessary.</P>
                </Grid.Item>
              </>
            ) : (
              <>
                <Divider margin={['M', 0]} />
                <H tag="h3" size="XS" color={COLOR.GREY.DARK}>
                  Our quote is:
                </H>
                <Grid gap={['S']}>
                  <Grid.Item>
                    <Grid gap={[0, 'S']}>
                      {data.data?.attributes.payableAmountInPence && (
                        <>
                          <Grid.Item span={8}>
                            <H tag="h2" size="M">
                              {accountLabel}
                            </H>
                          </Grid.Item>
                          <StyledPriceWrapper span={4}>
                            <StyledPrice
                              color={COLOR.BLACK}
                              strong
                              data-testid="outcome"
                            >
                              £
                              {Dinero({
                                amount:
                                  data.data?.attributes.payableAmountInPence,
                              }).toUnit()}
                            </StyledPrice>
                          </StyledPriceWrapper>
                        </>
                      )}
                    </Grid>
                  </Grid.Item>
                  {data.data?.attributes.payableAmountInPence !==
                    data.data?.attributes.packagePriceInPence && (
                    <>
                      <Grid.Item>
                        <Grid gap={[0, 'S']}>
                          {data.data?.attributes.payableAmountInPence && (
                            <>
                              <Grid.Item span={8}>
                                <P strong>Full price</P>
                              </Grid.Item>
                              <StyledPriceWrapper span={4}>
                                <P color={COLOR.BLACK}>
                                  £
                                  {Dinero({
                                    amount:
                                      data.data?.attributes.packagePriceInPence,
                                  }).toUnit()}
                                </P>
                              </StyledPriceWrapper>
                            </>
                          )}
                        </Grid>
                      </Grid.Item>
                      <Grid.Item>
                        <Grid gap={[0, 'S']}>
                          {data.data?.attributes.payableAmountInPence && (
                            <>
                              <Grid.Item span={8}>
                                <P strong>Discount</P>
                              </Grid.Item>
                              <StyledPriceWrapper span={4}>
                                <P color={COLOR.BLACK}>
                                  -£
                                  {Dinero({
                                    amount:
                                      data.data?.attributes
                                        .discountAmountInPence,
                                  }).toUnit()}
                                </P>
                              </StyledPriceWrapper>
                            </>
                          )}
                        </Grid>
                      </Grid.Item>
                    </>
                  )}
                  {data.data?.attributes.numberOfComplexWills === 1 &&
                    values.quoteAttributes.willType === COUPLE && (
                      <Grid.Item spanFromM={11}>
                        <StyledWarningBox>
                          <Wrapper margin={[0, 'XS', 0, 0]}>
                            <WarningTriangleIcon size="S" />
                          </Wrapper>
                          <P>
                            <P>Let the customer know:</P>
                            <SimpleList bullets>
                              <li>we’ve assumed only 1 will is complex</li>
                              <li>
                                if both wills are complex, there will be an
                                additional charge of £200
                              </li>
                            </SimpleList>
                          </P>
                        </StyledWarningBox>
                      </Grid.Item>
                    )}
                </Grid>
              </>
            )}
          </>
        </SectionStatus>
      </Grid.Item>
    </Grid>
  )
}

export default Price
