const FETCH_FUNERAL_DOCUMENTS = 'FETCH_FUNERAL_PLAN_DOCUMENTS'
const GENERATE_FUNERAL_PLAN_DOCUMENTS = 'GENERATE_FUNERAL_PLAN_DOCUMENTS'

export default function funeralPlanDocuments(state = {}, action) {
  switch (action.type) {
    // We only want the newest documents_generated data, hence we only pick first item from array
    case FETCH_FUNERAL_DOCUMENTS + '_FULFILLED':
      return action.payload.data.data?.[0] || {}
    // 'generate-documents' endpoint does not return any data, so we want to perceive the state'
    case GENERATE_FUNERAL_PLAN_DOCUMENTS + '_FULFILLED':
      return state

    case FETCH_FUNERAL_DOCUMENTS + '_PENDING':
    case GENERATE_FUNERAL_PLAN_DOCUMENTS + '_PENDING':
    case FETCH_FUNERAL_DOCUMENTS + '_REJECTED':
    case GENERATE_FUNERAL_PLAN_DOCUMENTS + '_REJECTED':
      return state

    default:
      return state
  }
}
