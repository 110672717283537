import { Formik, Form } from 'formik'
import styled from 'styled-components'
import { COLOR, FONT, GTR } from '@farewill/ui/tokens'
import { toast } from 'react-toastify'

import { useTasksContext } from './context'
import AutosizeInput from 'components/form/autosize-input'

const StyledField = styled(AutosizeInput)`
  background-color: transparent;
  padding: ${GTR.S};
  padding-left: 60px;
  font-size: ${FONT.SIZE.S};
  border-style: solid;
  border-color: ${COLOR.GREY.LIGHT};
  border-width: 0 0 1px;
  width: 100%;

  &:focus {
    outline: none;
  }
`

const NewTask = ({ stage, productId }) => {
  const { createTask, isCreatingTask } = useTasksContext()

  const handleSubmit = async (values, formik) => {
    if (!values.title || isCreatingTask) return

    const attributes = { ...productId, stage, ...values }
    await createTask(attributes)

    toast('Task created.', {
      toastId: 'task-created',
    })
    formik.resetForm()
  }

  const handleKeyPress = (e) => {
    if (e.which === 13) {
      e.target.blur()
      e.target.focus()
      e.preventDefault()
    }
  }

  return (
    <Formik initialValues={{ title: '' }} onSubmit={handleSubmit}>
      {({ handleSubmit }) => (
        <Form>
          <StyledField
            name="title"
            placeholder="New task..."
            onBlur={handleSubmit}
            onKeyPress={handleKeyPress}
            maxLength={100}
          />
        </Form>
      )}
    </Formik>
  )
}

export default NewTask
