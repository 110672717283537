import { useState, useEffect, useRef, ChangeEvent } from 'react'
import styled from 'styled-components'
import { Input, Wrapper, SearchIcon } from '@farewill/ui'
import { COLOR, BORDER, GTR } from '@farewill/ui/tokens'
import { screenMin } from '@farewill/ui/helpers/responsive'

import useDebounce from 'lib/effects/debounce'
import useApi from 'lib/effects/api'

import ResultsList from './results-list'

const StyledWrapper = styled(Wrapper)`
  position: relative;
  width: 100%;

  ${screenMin.m`
    max-width: 400px;
  `}

  ${screenMin.l`
    width: 400px;
  `}
`

const StyledSearchIcon = styled(SearchIcon)`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 10px;
`

const StyledInput = styled(Input)`
  input {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
    border-color: ${COLOR.ACCENT.PRIMARY_120};
  }

  > div > span {
    padding: 12px;
  }
`

const StyledResults = styled.ul`
  position: absolute;
  bottom: -3px;
  left: 0;
  transform: translateY(100%);
  width: 100%;
  border: 1px solid ${COLOR.GREY.LIGHT};
  background-color: ${COLOR.WHITE};
  border-radius: ${BORDER.RADIUS.S};
  padding: 0;
  margin: 0;
  max-height: 500px;
  overflow: auto;
  z-index: 10;
`

const StyledNoResults = styled.li`
  padding: ${GTR.XS} ${GTR.S};
`

type SearchResponse =
  | {
      id: number
      type: string
      attributes: Record<string, unknown>
    }[]
  | null

const CustomerSearch = () => {
  const [focused, setFocused] = useState(false)
  const [query, setQuery] = useState('')
  const searchEl = useRef<HTMLInputElement>(null)
  const debouncedQuery = useDebounce(query.trim(), 300)
  const [{ data, isLoading }, makeRequest] = useApi<SearchResponse>()

  useEffect(() => {
    const handleCloseClick = (e: MouseEvent) => {
      if (searchEl && !searchEl?.current?.contains(e.target as Node)) {
        setFocused(false)
      }
    }

    document.addEventListener('click', handleCloseClick)
    return () => document.removeEventListener('click', handleCloseClick)
  }, [])

  useEffect(() => {
    const url = `/api/search?query=${encodeURI(debouncedQuery)}`
    if (debouncedQuery) makeRequest({ url })
  }, [debouncedQuery, makeRequest])

  const hasResults = !isLoading && data && data?.length > 0
  const hasQuery = query.trim().length > 0
  const showResults = hasQuery && focused

  return (
    <StyledWrapper>
      <div ref={searchEl}>
        <StyledInput
          value={query}
          showClearButton={hasQuery}
          onClear={() => setQuery('')}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            setQuery(e.target.value)
            if (!focused) setFocused(true)
          }}
          onFocus={() => setFocused(true)}
        />
        {!hasQuery && <StyledSearchIcon color={COLOR.GREY.MEDIUM} />}
        {showResults && (
          <StyledResults>
            {hasResults ? (
              <ResultsList
                searchResults={data}
                onLinkClick={() => setFocused(false)}
              />
            ) : (
              <StyledNoResults>
                {isLoading ? 'Loading...' : 'No results'}
              </StyledNoResults>
            )}
          </StyledResults>
        )}
      </div>
    </StyledWrapper>
  )
}

export default CustomerSearch
