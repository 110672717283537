import { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { Divider, H, P } from '@farewill/ui'
import { GTR } from '@farewill/ui/tokens'

import { RESOURCES, useFetchResource } from 'api'
import PaymentsSummary from 'components/payments/invoices'
import {
  formatAmountToPence,
  getInitialValuesFromInvoice,
} from 'components/payments/invoices/methods'
import Overview from 'components/payments/overview'
import PaymentModal, {
  FormOutputValues,
} from 'components/payments/payment-modal'
import { useTasksContext } from 'components/tasks-panel/context'
import useInvoices from 'hooks/useInvoices'
import useApi from 'lib/effects/api'
import useApiHelpers from 'lib/effects/api-helpers'
import { Invoice, InvoiceAttributes } from 'lib/models/invoice'
import { Lead } from 'lib/models/lead'
import { WillAttributes } from 'lib/models/will'
import { WillCaseAttributes } from 'lib/models/will-case'
import {
  SlimBorderedButton,
  SlimPrimaryButton,
} from 'routes/funerals/components/sale/buttons'
import { hideModal, showModal } from 'state/actions'

import CurrentQuote from './editable-quote/current-quote'
import EditableQuote from './editable-quote/editable-quote'
import {
  getInitialWillCaseQuoteFormValues,
  getWillsComplexityFromWillCaseQuoteComplexities,
  sortLeadAndPartnerWills,
} from './editable-quote/methods'
import { WillCaseQuoteAttributes } from './editable-quote/types'

import { MODAL_MAX_WIDTH, PAYMENT_PROVIDER, PAYMENT_TYPE } from './constants'

const Row = styled.div`
  display: grid;
  grid-template-columns: max-content 1fr;
  align-items: center;
`
const Actions = styled.div`
  justify-self: end;
  & > * {
    margin-left: ${GTR.S};
  }
`
const Quote = ({
  lead,
  willCaseId,
}: {
  lead: Lead
  willCaseId: number
}): React.ReactElement => {
  const dispatch = useDispatch()
  const { fetchTasks } = useTasksContext()

  const {
    items: quote,
    fetchItems: fetchQuote,
    isFetching,
  } = useApiHelpers({
    baseUrl: `/api/will-cases/${willCaseId}/quote`,
    type: 'will_case_quote',
  })

  const { data: willCaseData, refetch: refetchWillCase } = useFetchResource({
    id: willCaseId,
    resource: RESOURCES.WILL_CASES,
  })

  useEffect(() => {
    if (willCaseId) {
      fetchQuote()
    }
  }, [fetchQuote, willCaseId])

  const {
    invoices,
    fetchInvoices,
    createInvoice,
    updateInvoice,
    updateLineItem,
    deleteInvoice,
  } = useInvoices()

  useEffect(() => {
    if (willCaseId) {
      fetchInvoices({ queryParams: { 'filter[willCaseId]': willCaseId } })
    }
  }, [fetchInvoices, willCaseId])

  const refetchTasks = useCallback(
    () =>
      fetchTasks({
        queryParams: {
          'filter[willCaseId]': willCaseId,
          'filter[isNeeded]': true,
          'page[size]': 200,
        },
      }),
    [fetchTasks, willCaseId]
  )

  const currentQuote = quote?.attributes
  const cost = currentQuote?.totalPriceInPence
  const willCase = (willCaseData as { attributes: WillCaseAttributes })
    ?.attributes
  const { wills } = willCase
  const { leadWill, partnerWill } = sortLeadAndPartnerWills(willCase)

  const editCardPayment = (invoice: Invoice) => {
    dispatch(
      showModal({
        component: PaymentModal,
        headingText: 'Edit payment',
        maxWidth: MODAL_MAX_WIDTH,
        config: {
          type: PAYMENT_TYPE,
          values: getInitialValuesFromInvoice({ type: PAYMENT_TYPE, invoice }),
          hideModal: () => dispatch(hideModal()),
          deletePayment: async () => {
            await deleteInvoice(invoice.id)
            refetchTasks()
          },
          savePayment: async ({
            reference,
            date,
            amount,
          }: FormOutputValues) => {
            const attributes = {
              reference,
              issuedDate: date,
            } as Partial<InvoiceAttributes>

            attributes.paidOn = attributes.issuedDate

            const price = formatAmountToPence(amount)
            await updateLineItem(invoice.attributes.lineItems[0], {
              price,
            })

            await updateInvoice(invoice.id, attributes)

            refetchTasks()
          },
        },
      })
    )
  }

  const addCardPayment = () => {
    dispatch(
      showModal({
        component: PaymentModal,
        headingText: 'Add payment',
        maxWidth: MODAL_MAX_WIDTH,
        config: {
          type: PAYMENT_TYPE,
          values: getInitialValuesFromInvoice({ type: PAYMENT_TYPE }),
          hideModal: () => dispatch(hideModal()),
          savePayment: async ({
            reference,
            date,
            amount,
          }: FormOutputValues) => {
            const price = formatAmountToPence(amount)

            await createInvoice({
              willCaseId,
              paidTo: 'farewill',
              reference,
              issuedDate: date,
              paidOn: date,
              paymentProvider: PAYMENT_PROVIDER,
              lineItems: [
                {
                  description: 'complexity payment',
                  price,
                },
              ],
            })

            refetchTasks()
          },
        },
      })
    )
  }

  const [, makeRequest] = useApi()

  const updateWill = async ({
    willId,
    attributes,
  }: {
    willId: number
    attributes: Partial<WillAttributes>
  }): Promise<void> => {
    await makeRequest({
      url: `/api/wills/${willId}`,
      method: 'PATCH',
      data: {
        data: {
          type: 'wills',
          id: willId,
          attributes,
        },
      },
    })
  }

  const [isEditingQuote, setIsEditingQuote] = useState(false)

  const saveQuote = async (quoteAttributes: WillCaseQuoteAttributes) => {
    const { complexities } = quoteAttributes
    const { leadWillComplexity, partnerWillComplexity } =
      getWillsComplexityFromWillCaseQuoteComplexities(complexities)

    if (leadWill) {
      await updateWill({
        willId: leadWill.id,
        attributes: { willComplexity: leadWillComplexity },
      })
    }

    if (partnerWill) {
      await updateWill({
        willId: partnerWill.id,
        attributes: { willComplexity: partnerWillComplexity },
      })
    }

    refetchWillCase()
    fetchQuote()
  }

  const editableQuoteControls = (
    <Actions>
      {isEditingQuote ? (
        <SlimBorderedButton onClick={() => setIsEditingQuote(false)}>
          Cancel
        </SlimBorderedButton>
      ) : (
        <SlimPrimaryButton onClick={() => setIsEditingQuote(true)}>
          Edit quote
        </SlimPrimaryButton>
      )}
    </Actions>
  )

  if (isFetching) {
    return <P>Loading...</P>
  }

  return (
    <>
      <H size="M">Sale</H>
      {cost !== undefined && (
        <>
          <Overview cost={cost} invoices={invoices} />
          <Divider margin={['L', 0]} />
        </>
      )}
      <PaymentsSummary
        invoices={invoices}
        onEdit={editCardPayment}
        actions={
          <>
            <SlimPrimaryButton onClick={addCardPayment}>
              Add card payment
            </SlimPrimaryButton>
          </>
        }
      />
      <Divider margin={['L', 0]} />
      {willCase && (
        <>
          <Row>
            <H size="S">Quote</H>
            {editableQuoteControls}
          </Row>
          {isEditingQuote ? (
            <EditableQuote
              customerNames={{
                leadName: leadWill?.contact?.firstName || 'Lead',
                partnerName: partnerWill
                  ? partnerWill.contact?.firstName || 'Partner'
                  : undefined,
              }}
              currentQuote={currentQuote}
              willCaseId={willCaseId}
              values={getInitialWillCaseQuoteFormValues({
                willCase,
                wills: wills as WillAttributes[],
              })}
              saveQuote={(quoteAttributes: WillCaseQuoteAttributes) =>
                saveQuote(quoteAttributes)
              }
              exitEditableQuote={() => setIsEditingQuote(false)}
            />
          ) : (
            <CurrentQuote currentQuote={currentQuote} willCase={willCase} />
          )}
        </>
      )}
    </>
  )
}

export default Quote
