import CommentForm from './comment-form'

import { useActivityContext } from './context'

const ReplyForm = ({ parentId, probateCaseId, taskId, willCaseId }) => {
  const { createComment } = useActivityContext()

  const onSubmit = async (values) => {
    let parentAttributes = { parentId }

    if (taskId && probateCaseId) {
      parentAttributes = { taskId, probateCaseId }
    }

    if (taskId && willCaseId) {
      parentAttributes = { taskId, willCaseId }
    }

    const attributes = { ...parentAttributes, ...values }
    await createComment(attributes)
  }

  return (
    <CommentForm handleSubmit={onSubmit} placeholder="Reply" iconSize="M" />
  )
}

export default ReplyForm
