import styled from 'styled-components'
import { Wrapper } from '@farewill/ui'
import { COLOR, GTR } from '@farewill/ui/tokens'
import { screenMax } from '@farewill/ui/helpers/responsive'
import { useRouteMatch } from 'react-router-dom'

import NavigationTabs from 'components/navigation-tabs'
import { PATHS } from 'config/routes'
import useAdminUserRoles from 'hooks/useAdminUserRoles'

const SALES_PATHS = [PATHS.CALLS, PATHS.LEAD_LIST, PATHS.NEW_CONTACT_FORM]
const CASES_PATHS = [PATHS.CASE_LIST]
const PARTNER_PATHS = [PATHS.ADD_PARTNER, PATHS.LIST_PARTNERS]

const StyledWrapper = styled(Wrapper)`
  margin-bottom: -${GTR.M};
  ${screenMax.m`margin-bottom: 0`}
  ${screenMax.s`margin-bottom: ${GTR.S}`}
`

const StyledOuterWrapper = styled(Wrapper)`
  border-bottom: 1px solid ${COLOR.GREY.LIGHT};
`

const SubNavigation = () => {
  const showSalesNav = useRouteMatch({ path: SALES_PATHS, exact: true })
  const showCasesNav = useRouteMatch({ path: CASES_PATHS })
  const showPartnersNav = useRouteMatch({ path: PARTNER_PATHS })
  const { isUserRole } = useAdminUserRoles()

  if (!showSalesNav && !showCasesNav && !showPartnersNav) return null

  let links = []

  if (showSalesNav) {
    links = [
      { label: 'Manage leads', url: PATHS.LEAD_LIST },
      { label: 'Inbound calls', url: PATHS.CALLS },
      { label: 'Create new lead', url: PATHS.NEW_CONTACT_FORM },
    ]
  }

  if (showPartnersNav) {
    links = [
      {
        label: 'Partners',
        url: '/partners',
        exact: true,
      },
      {
        label: 'Add new partner',
        url: '/partners/new',
        exact: false,
      },
    ]
  }

  if (showCasesNav) {
    links = [
      { label: 'Probate', url: '/customers/probate', exact: false },
      { label: 'LPAs', url: '/customers/lpa', exact: false },
      { label: 'Funerals', url: '/customers/funeral', exact: false },
      { label: 'Telephone wills', url: '/customers/will', exact: false },
    ]

    // Only show Funeral Plans tab when user has Funeral Plans Editor rights
    if (isUserRole('funeral_plans_editor')) {
      links.push({
        label: 'Funeral plans',
        url: '/customers/funeral-plan',
        exact: false,
      })
    }
  }

  return (
    <StyledOuterWrapper>
      <StyledWrapper
        container
        containerPaddingTop={GTR.L}
        containerPaddingBottom={0}
      >
        <NavigationTabs
          paddingBottom={GTR.M}
          borderColor={COLOR.ACCENT.SECONDARY}
          boldActiveLink={false}
          hasBorder={false}
          links={links}
        />
      </StyledWrapper>
    </StyledOuterWrapper>
  )
}

export default SubNavigation
