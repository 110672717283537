import { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Button, P, Wrapper } from '@farewill/ui'
import { useDispatch } from 'react-redux'

import { hideModal } from 'state/actions'

const StyledWrapper = styled(Wrapper)`
  display: flex;
  justify-content: space-between;
`

const Modal = ({ config: { deleteError, errorId } }) => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)

  const handleDeleteClick = async () => {
    try {
      setLoading(true)
      await deleteError(errorId)
      dispatch(hideModal())
    } catch {
      setLoading(false)
    }
  }

  return (
    <Wrapper>
      <P>You should only delete this error if it was created by mistake.</P>
      <P>Are you sure you want to delete this error?</P>
      <StyledWrapper>
        <Button.Bordered type="button" onClick={() => dispatch(hideModal())}>
          Cancel
        </Button.Bordered>
        <Button.Primary
          type="button"
          loading={loading}
          onClick={handleDeleteClick}
        >
          Delete error
        </Button.Primary>
      </StyledWrapper>
    </Wrapper>
  )
}

Modal.propTypes = {
  config: PropTypes.shape({
    deleteError: PropTypes.func.isRequired,
    errorId: PropTypes.number.isRequired,
  }).isRequired,
}

export default Modal
