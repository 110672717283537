import { UseSectionsCompleteProps } from '.'

export const checkIsPersonWhoHasDiedComplete = ({
  values,
}: UseSectionsCompleteProps) => {
  const { dateOfDeath, livedInEnglandOrWales, maritalStatus } =
    values.quoteAttributes

  return ![dateOfDeath, livedInEnglandOrWales, maritalStatus].includes('')
}
