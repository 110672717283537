import { useFormikContext } from 'formik'
import { Grid, H } from '@farewill/ui'

import InputFloating from 'components/form/input-floating'
import Address from 'routes/probate-estate/fact-find/components/address'
import CircularRadioGroup from 'components/form/circular-radio-group'
import CustomerNames from 'components/customer-names'
import { showRelationshipToCustomerWithLegacy } from 'routes/lead/helpers'
import { Fragment } from 'react/jsx-runtime'

const Customer = ({ saveContactField, saveFuneralField }) => {
  const { values } = useFormikContext()
  return (
    <Grid>
      <Grid.Item>
        <H size="M">1. Customer</H>
      </Grid.Item>
      <Grid.Item>
        <CustomerNames
          id={values.contact.id}
          firstName={values.contact.firstName}
          lastName={values.contact.lastName}
          fullLegalName={values.contact.fullLegalName}
          preferredName={values.contact.preferredName}
          pronouns={values.contact.pronouns}
        />
      </Grid.Item>
      <Grid.Item>
        <Address
          label="Address"
          name="customerAddress"
          data={values.customerAddress}
        />
      </Grid.Item>
      <Grid.Item span={8}>
        <InputFloating
          label="The person who died is the customer’s..."
          name="relationshipToCustomer"
          component="select"
          options={showRelationshipToCustomerWithLegacy(
            values.relationshipToCustomer
          )}
          handleSave={saveFuneralField}
        />
      </Grid.Item>
      {values.isCustomerNearRelativeOrExecutor !== '' ? (
        <Grid.Item>
          <CircularRadioGroup
            name="isCustomerNearRelativeOrExecutor"
            label="Is the customer a near relative or executor?"
            options={[
              { label: 'No', value: 'false' },
              { label: 'Yes', value: 'true' },
            ]}
            handleSave={saveFuneralField}
            inline
          />
        </Grid.Item>
      ) : (
        <>
          <Grid.Item>
            <CircularRadioGroup
              name="isCustomerNearRelative"
              label="Is the customer a near relative (partner, parent or child)?"
              options={[
                { label: 'No', value: 'false' },
                { label: 'Yes', value: 'true' },
              ]}
              handleSave={saveFuneralField}
              inline
            />
          </Grid.Item>
          <Grid.Item>
            <CircularRadioGroup
              name="isCustomerExecutor"
              label="Is the customer an executor?"
              options={[
                { label: 'No', value: 'false' },
                { label: 'Yes', value: 'true' },
              ]}
              handleSave={saveFuneralField}
              inline
            />
          </Grid.Item>
        </>
      )}
      {values.isCustomerNearRelative === 'false' &&
        values.isCustomerExecutor === 'false' && (
          <Grid.Item>
            <InputFloating
              name="relationshipNotes"
              label="Nature of relationship and why they’re making the application"
              handleSave={saveFuneralField}
              component="textarea"
              rows={4}
            />
          </Grid.Item>
        )}
    </Grid>
  )
}

export default Customer
