import { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Divider, P } from '@farewill/ui'

import useApi from 'lib/effects/api'
import { scrollToDataId } from 'lib/window/scroll'
import { groupProbateEstateItems } from '../helpers'

import SectionWrapper from './components/section-wrapper'

import People from './people'
import Assets from './assets'
import WillOrIntestacy from './will-or-intestacy'
import SpousesEstate from './spouses-estate'
import FuneralAndDebts from './funeral-and-debts'
import GiftsAndOther from './gifts-and-other'

const FactFind = ({
  location,
  match,
  probateCase,
  probateEstate,
  saveProbateCaseField,
  saveProbateEstateField,
}) => {
  const probateEstateId = match.params.id
  const initialValues = probateEstate.attributes

  const [
    { data: estateItems, isLoading: isEstateItemsLoading },
    getProbateEstateItems,
  ] = useApi({ data: [], isLoading: true })

  const groupedEstateItems = groupProbateEstateItems(estateItems)

  useEffect(() => {
    getProbateEstateItems({
      url: `/api/probate-estates/${probateEstateId}/items`,
    })
  }, [getProbateEstateItems, probateEstateId])

  useEffect(() => {
    if (location.hash) {
      scrollToDataId(location.hash.replace('#', ''))
    }
  }, [location.hash, isEstateItemsLoading])

  const getSectionIsComplete = (section) =>
    probateEstate.attributes.completedSections.includes(section)

  return (
    <>
      <SectionWrapper
        padding={['XL', 0, 'S']}
        isComplete={getSectionIsComplete('people')}
      >
        <People
          initialValues={initialValues}
          probateCase={probateCase}
          probateEstateId={probateEstateId}
          saveProbateCaseField={saveProbateCaseField}
          saveProbateEstateField={saveProbateEstateField}
        />
      </SectionWrapper>
      <Divider />
      <SectionWrapper
        padding={['XL', 0, 'S']}
        isComplete={getSectionIsComplete('will_or_intestacy')}
      >
        <WillOrIntestacy
          initialValues={initialValues}
          probateEstateId={probateEstateId}
          saveProbateEstateField={saveProbateEstateField}
        />
      </SectionWrapper>
      <Divider />
      <SectionWrapper
        padding={['XL', 0, 'S']}
        isComplete={getSectionIsComplete('spouses_estate')}
      >
        <SpousesEstate
          initialValues={initialValues}
          probateEstateId={probateEstateId}
          saveProbateEstateField={saveProbateEstateField}
        />
      </SectionWrapper>
      <Divider />
      {isEstateItemsLoading ? (
        <P size="L">
          Loading <strong>Assets</strong>...
        </P>
      ) : (
        <SectionWrapper
          padding={['XL', 0, 'L']}
          isComplete={getSectionIsComplete('assets')}
        >
          <Assets
            estateItems={groupedEstateItems}
            initialValues={initialValues}
            probateEstateId={probateEstateId}
            saveProbateEstateField={saveProbateEstateField}
          />
        </SectionWrapper>
      )}
      <Divider />
      {isEstateItemsLoading ? (
        <P size="L">
          Loading <strong>Funeral and debts</strong>...
        </P>
      ) : (
        <SectionWrapper
          padding={['XL', 0, 'L']}
          isComplete={getSectionIsComplete('funeral_and_debts')}
        >
          <FuneralAndDebts
            initialValues={initialValues}
            estateItems={groupedEstateItems}
            probateEstateId={probateEstateId}
            saveProbateEstateField={saveProbateEstateField}
          />
        </SectionWrapper>
      )}
      <Divider />
      {isEstateItemsLoading ? (
        <P size="L">
          Loading <strong>Gifts</strong>...
        </P>
      ) : (
        <SectionWrapper
          padding={['XL', 0, 'XXL']}
          isComplete={getSectionIsComplete('gifts_and_other')}
        >
          <GiftsAndOther
            initialValues={initialValues}
            estateItems={groupedEstateItems}
            probateEstateId={probateEstateId}
            leadId={probateCase.attributes.leadId}
            saveProbateEstateField={saveProbateEstateField}
          />
        </SectionWrapper>
      )}
    </>
  )
}

FactFind.propTypes = {
  probateCase: PropTypes.shape({
    attributes: PropTypes.object.isRequired,
  }).isRequired,
  probateEstate: PropTypes.shape({
    attributes: PropTypes.object.isRequired,
  }).isRequired,
  saveProbateCaseField: PropTypes.func.isRequired,
  saveProbateEstateField: PropTypes.func.isRequired,
}

export default FactFind
