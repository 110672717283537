import groupBy from 'lodash/groupBy'
import { ReactElement, useEffect, useState } from 'react'
import { RouteComponentProps, useParams } from 'react-router-dom'
import { H, Wrapper } from '@farewill/ui'
import { shallowEqual, useSelector } from 'react-redux'

import ENV from 'config/environment'
import AircallEventComponent from 'components/aircall-event'
import LeadEventItem from 'components/lead-event'
import { useTasksContext } from 'components/tasks-panel/context'
import useLeadEvents from 'hooks/useLeadEvents'
import useApi from 'lib/effects/api'
import { AircallEvent, AircallEventAttributes } from 'lib/models/aircall-event'
import { CaseStatusTransition } from 'lib/models/case-status-transition'
import { Lead } from 'lib/models/lead'
import { Event } from 'lib/models/event'
import { Invoice } from 'lib/models/invoice'
import { LeadEvent } from 'lib/models/lead-event'
import { Task as TaskItem } from 'lib/models/task'
import { scrollToDataId } from 'lib/window/scroll'
import {
  ErrorsProvider,
  useErrorsContext,
} from 'routes/probate-case/components/errors/context'
import { FAREWILL_PRODUCTS, FAREWILL_PRODUCT_IDS } from 'utils/enums'

import CaseCommentForm from './case-comment-form'
import CommentThread from './comment-thread'
import { ActivityProvider, useActivityContext } from './context'
import Error from './error'
import EventComponent from './event'
import FilterActivity from './filter-activity'
import {
  Activity as ActivityType,
  getCaseCommentThreads,
  getTaskCommentThreads,
  sortByActivityDate,
  useEvents,
} from './helpers'
import Payment from './payment'
import ScheduledEmailsSummary from './scheduled-emails-summary'
import CaseStatusTransitionComponent from './status-transition'
import Task from './task'
import TaskEmail from './task-email'
import AIGeneratedNotesSummary from './ai-summary'

type RouteProps = RouteComponentProps<{ id: string }>

type Props = RouteProps & {
  leadId: number | null
  location: string
  product: string
  showEmailFilter?: boolean
  showCallsFilter?: boolean
  showPaymentsFilter?: boolean
}

const Activity = ({
  leadId,
  location,
  showEmailFilter,
  showCallsFilter,
  showPaymentsFilter,
  product,
}: Props): ReactElement => {
  const {
    comments,
    fetchComments,
    isFetchingComments,
    emails,
    fetchEmails,
    invoices,
    fetchInvoices,
    statusTransitions,
    fetchStatusTransitions,
  } = useActivityContext()
  const lead = useSelector(
    (state: { lead: Lead }) => state.lead.attributes && state.lead,
    shallowEqual
  )
  const { tasks } = useTasksContext()
  const completedTasks = (tasks as TaskItem[]).filter(
    ({ attributes }) => !!attributes.completedAt
  )

  const { errors, fetchErrors } = useErrorsContext()
  const { id: productId } = useParams<{ id: string }>()

  const filterDefault = product === FAREWILL_PRODUCTS.FUNERAL ? false : true

  const [activityFilters, setActivityFilters] = useState({
    comments: true,
    tasks: filterDefault,
    emails: false,
    calls: true,
    payments: filterDefault,
  })

  const queryKey =
    FAREWILL_PRODUCT_IDS[product as keyof typeof FAREWILL_PRODUCT_IDS]

  useEffect(() => {
    fetchComments({ queryParams: { [queryKey]: productId } })
  }, [fetchComments, productId, queryKey])

  const taskCompletedAtDates = (tasks as TaskItem[])
    .map((task) => task.attributes.completedAt)
    .join(' ')

  useEffect(() => {
    if (product === FAREWILL_PRODUCTS.PROBATE) {
      fetchEmails({ queryParams: { 'filter[probateCaseId]': productId } })
    }
  }, [fetchEmails, productId, taskCompletedAtDates, product])

  useEffect(() => {
    if (product === FAREWILL_PRODUCTS.PROBATE) {
      fetchErrors()
    }
  }, [fetchErrors, product])

  useEffect(() => {
    if (product === FAREWILL_PRODUCTS.WILL) {
      fetchStatusTransitions({
        queryParams: { 'filter[willCaseId]': productId },
      })
    }
  }, [fetchStatusTransitions, product, productId])

  useEffect(() => {
    if (product === FAREWILL_PRODUCTS.FUNERAL) {
      fetchInvoices({ queryParams: { 'filter[funeralId]': productId } })
    }
  }, [fetchInvoices, productId, product])

  const events = useEvents({
    product,
    params: `funeralPlanId=${productId}`,
  })

  const { leadEvents, fetchLeadEvents } = useLeadEvents(leadId as number)
  const [{ data: aircallEventsData }, fetchAircallEventsForCustomer] =
    useApi<AircallEventAttributes[]>()

  useEffect(() => {
    if (leadId) {
      fetchLeadEvents()
      fetchAircallEventsForCustomer({
        url: `/api/aircall/get-aircall-events-for-customer?leadId=${leadId}`,
      })
    }
  }, [fetchLeadEvents, leadId, fetchAircallEventsForCustomer])

  const groupedComments = groupBy(comments, 'attributes.taskId')
  let aircallEvents: AircallEvent[] = []
  if (aircallEventsData && aircallEventsData.length > 0) {
    aircallEvents = aircallEventsData.map((event) => ({
      id: event.id,
      type: 'aircall',
      attributes: event,
    }))
  }

  let activities = [
    ...(statusTransitions as CaseStatusTransition[]),
    ...(events as Event[]),
    ...leadEvents.map((event) => ({ ...event, type: 'lead_events' })),
    ...aircallEvents,
  ] as unknown as ActivityType[]

  if (activityFilters.tasks) {
    activities = [...activities, ...completedTasks, ...errors]
  }

  if (activityFilters.payments) {
    activities = [...activities, ...invoices]
  }

  if (activityFilters.comments) {
    activities = [
      ...activities,
      ...getCaseCommentThreads({ groupedComments, isCall: false }),
      ...getTaskCommentThreads(groupedComments),
    ]
  }

  if (activityFilters.emails) {
    activities = [...activities, ...emails]
  }

  if (activityFilters.calls) {
    activities = [
      ...activities,
      ...getCaseCommentThreads({ groupedComments, isCall: true }),
    ]
  }

  useEffect(() => {
    if (location.hash) {
      setTimeout(() => scrollToDataId(location.hash.replace('#', '')))
    }
  }, [location.hash, isFetchingComments])

  return (
    <Wrapper>
      {ENV.FF_AI_NOTES_ENABLED && (
        <AIGeneratedNotesSummary lead={lead} leadEvents={leadEvents} />
      )}
      <H size="M">Activity</H>
      <ScheduledEmailsSummary />
      {product !== FAREWILL_PRODUCTS.FUNERAL_PLAN && (
        <FilterActivity
          activityFilters={activityFilters}
          setActivityFilters={setActivityFilters}
          showEmailFilter={showEmailFilter}
          showCallsFilter={showCallsFilter}
          showPaymentsFilter={showPaymentsFilter}
        />
      )}
      <CaseCommentForm product={product} />
      {activities.sort(sortByActivityDate).map((activity) => {
        if (activity.type === 'emails') {
          return (
            <TaskEmail email={activity} key={`task-email-${activity.id}`} />
          )
        }
        if (activity.type === 'invoices') {
          return (
            <Payment
              invoice={activity as Invoice}
              key={`payment-${activity.id}`}
            />
          )
        }
        if (activity.type === 'tasks') {
          return <Task task={activity} key={`task-${activity.id}`} />
        }
        if (activity.type === 'probate_case_errors') {
          return <Error error={activity} key={`error-${activity.id}`} />
        }
        if (activity.type === 'case_status_transitions') {
          return (
            <CaseStatusTransitionComponent
              caseStatusTransition={activity as CaseStatusTransition}
              key={`case-status-transition-${activity.id}`}
            />
          )
        }
        if (activity.type === 'events') {
          return (
            <EventComponent
              event={activity as Event}
              key={`event-${activity.id}`}
            />
          )
        }
        if (activity.type === 'lead_events') {
          return (
            <LeadEventItem
              event={activity as LeadEvent}
              key={`event-${activity.id}`}
            />
          )
        }
        if (activity.type === 'aircall') {
          return (
            <AircallEventComponent
              event={activity.attributes as AircallEventAttributes}
              leadId={leadId ?? undefined}
              key={`event-${activity.id}`}
            />
          )
        }
        return (
          <CommentThread
            comment={activity}
            key={`comment-${activity.id}`}
            enableCommenting
            queryParams={{ [queryKey]: productId }}
          />
        )
      })}
    </Wrapper>
  )
}

const ActivityWithProvider = (props: Props): React.ReactElement => (
  <ActivityProvider>
    <ErrorsProvider probateCaseId={props.match.params.id}>
      <Activity {...props} />
    </ErrorsProvider>
  </ActivityProvider>
)

export default ActivityWithProvider
