import PropTypes from 'prop-types'
import { BORDER, COLOR, FONT, GTR } from '@farewill/ui/tokens'
import { useField, useFormikContext } from 'formik'
import { MentionsInput, Mention } from 'react-mentions'

const inputStyles = {
  control: {
    backgroundColor: '#fff',
    fontSize: FONT.SIZE.M,
    fontWeight: 'normal',
    minHeight: 100,
  },

  '&multiLine': {
    highlighter: { padding: GTR.S },
    input: {
      padding: GTR.S,
      border: `2px solid ${COLOR.GREY.LIGHT}`,
      borderRadius: BORDER.RADIUS.S,
      outline: 0,
      color: COLOR.BLACK,
      '&:focus': {
        borderColor: COLOR.GREY.MEDIUM,
      },
    },
  },

  suggestions: {
    list: {
      backgroundColor: 'white',
      borderStyle: 'solid',
      borderColor: COLOR.GREY.LIGHT,
      borderWidth: '1px 1px 0',
      fontSize: FONT.SIZE.S,
    },
    item: {
      padding: `${GTR.XXS} ${GTR.XS}`,
      borderBottom: `1px solid ${COLOR.GREY.LIGHT}`,
      '&focused': {
        backgroundColor: COLOR.BACKGROUND.FOG,
      },
    },
  },
}

const Input = ({ data, name, onBlur, ...rest }) => {
  const { setFieldValue, values } = useFormikContext()
  const [field, , helpers] = useField(name)

  return (
    <MentionsInput
      {...field}
      onChange={(_, newValue, __, mentions) => {
        helpers.setValue(newValue)
        const mentionsIds = mentions.map(({ id }) => id)
        setFieldValue('mentions', mentionsIds)
      }}
      allowSpaceInQuery
      style={inputStyles}
      className="react-mentions"
      onBlur={(e) => {
        field.onBlur(e)
        onBlur &&
          onBlur({
            content: values.content,
            mentions: values.mentions,
            isCall: values.isCall,
          })
      }}
      {...rest}
    >
      <Mention
        trigger="@"
        data={data}
        displayTransform={(_, display) => `@${display}`}
      />
    </MentionsInput>
  )
}

Input.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      display: PropTypes.string.isRequired,
    })
  ).isRequired,
  name: PropTypes.string.isRequired,
  onBlur: PropTypes.func,
}

Input.defaultProps = {
  onBlur: null,
}

export default Input
