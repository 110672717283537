import { Grid, H } from '@farewill/ui'
import { useFormikContext } from 'formik'

import Address from 'routes/probate-estate/fact-find/components/address'
import InputFloating from 'components/form/input-floating'
import { DEATH_ADDRESS_TYPES, CREMATION } from 'utils/enums'
import CircularRadioGroup from 'components/form/circular-radio-group'
import MessageBox from 'components/message-box'
import { use2024Form1 as use2024Form1Context } from 'routes/funerals/use2024Form1'

const CircumstancesOfDeath = ({ handleSave }) => {
  const { values } = useFormikContext()
  const use2024Form1 = use2024Form1Context({ dateOfDeath: values.dateOfDeath })

  return (
    <Grid>
      <Grid.Item>
        <H size="M">3. Circumstances of the death</H>
      </Grid.Item>
      <Grid.Item>
        <Address
          label="Address where they died"
          name="deathAddress"
          data={values.deathAddress}
        />
      </Grid.Item>
      <Grid.Item>
        <Grid>
          <Grid.Item span={6}>
            <InputFloating
              label="This address is..."
              name="deathAddressType"
              component="select"
              options={DEATH_ADDRESS_TYPES}
              handleSave={handleSave}
            />
          </Grid.Item>
        </Grid>
      </Grid.Item>
      {values.deathAddressType === 'other' ? (
        <Grid.Item span={6}>
          <InputFloating
            name="deathAddressNotes"
            label="Describe this address"
            handleSave={handleSave}
          />
        </Grid.Item>
      ) : null}
      {values.funeralType === CREMATION && (
        <Grid.Item>
          <CircularRadioGroup
            name="isCollectionDifferentToDeathAddress"
            label="Does the person need to be collected from a different address?"
            options={[
              { label: 'Not asked', value: '' },
              { label: 'No', value: 'false' },
              { label: 'Yes', value: 'true' },
            ]}
            handleSave={handleSave}
            inline
          />
        </Grid.Item>
      )}
      {values.isCollectionDifferentToDeathAddress === 'true' && (
        <Grid.Item>
          <Address
            label="Collection address"
            name="collectionAddress"
            data={values.collectionAddress}
          />
        </Grid.Item>
      )}
      {values.deathCountry.name !== 'Scotland' && (
        <>
          {values.funeralType === CREMATION && use2024Form1 === undefined && (
            <Grid.Item>
              <MessageBox warning>
                We may need to ask if the death was violent or unnatural. Select
                the <strong>date of death</strong> for this funeral so we can
                determine which version of Form 1 paperwork to use.
              </MessageBox>
            </Grid.Item>
          )}
          {values.funeralType === CREMATION && use2024Form1 === false && (
            <Grid.Item>
              <CircularRadioGroup
                name="isUnnaturalDeath"
                label="Was the death violent or unnatural?"
                options={[
                  { label: 'Not asked', value: '' },
                  { label: 'No', value: 'false' },
                  { label: 'Yes', value: 'true' },
                ]}
                handleSave={handleSave}
                inline
              />
            </Grid.Item>
          )}
          {values.isUnnaturalDeath === 'true' && (
            <Grid.Item>
              <InputFloating
                name="unnaturalDeathNotes"
                label="Describe how the death was violent or unnatural"
                handleSave={handleSave}
                component="textarea"
                rows={4}
              />
            </Grid.Item>
          )}
          <Grid.Item>
            <CircularRadioGroup
              name="isExaminationRequired"
              label="Do you think there should be any further examination of the person’s remains?"
              options={[
                { label: 'Not asked', value: '' },
                { label: 'No', value: 'false' },
                { label: 'Yes', value: 'true' },
              ]}
              handleSave={handleSave}
              inline
            />
            <MessageBox warning margin={['S', 0, 'M']}>
              If the person does want further examination of the remains, then
              the funeral cannot go ahead.
            </MessageBox>
          </Grid.Item>
          {values.isExaminationRequired === 'true' && (
            <Grid.Item>
              <InputFloating
                name="examinationRequiredNotes"
                label="Explain why there should be further examination"
                handleSave={handleSave}
                component="textarea"
                rows={4}
              />
            </Grid.Item>
          )}
        </>
      )}
      {values.deathCountry.name === 'Scotland' && (
        <>
          <Grid.Item>
            <CircularRadioGroup
              name="hasProcuratorFiscalInvestigated"
              label="Has the death been investigated by the procurator fiscal?"
              options={[
                { label: 'Not asked', value: '' },
                { label: 'No', value: 'false' },
                { label: 'Yes', value: 'true' },
              ]}
              handleSave={handleSave}
              inline
            />
          </Grid.Item>
          {values.hasProcuratorFiscalInvestigated === 'true' && (
            <Grid.Item>
              <CircularRadioGroup
                name="hasProcuratorFiscalApprovedCremation"
                label="Has the cremation been approved by the procurator fiscal?"
                options={[
                  { label: 'Not asked', value: '' },
                  { label: 'No', value: 'false' },
                  { label: 'Yes', value: 'true' },
                ]}
                handleSave={handleSave}
                inline
              />
            </Grid.Item>
          )}
          <Grid.Item>
            <CircularRadioGroup
              name="hasProcuratorFiscalProvidedForm"
              label="Has the Form E1 been provided by the procurator fiscal?"
              options={[
                { label: 'Not asked', value: '' },
                { label: 'No', value: 'false' },
                { label: 'Yes', value: 'true' },
              ]}
              handleSave={handleSave}
              inline
            />
          </Grid.Item>
        </>
      )}
    </Grid>
  )
}

export default CircumstancesOfDeath
