import { ReactElement } from 'react'
import { useDispatch } from 'react-redux'
import { Formik, Form } from 'formik'

import { Button, ButtonGroup, P, Wrapper } from '@farewill/ui'

import { hideModal } from 'state/actions'
import Checkbox from 'components/form/checkbox'

type ModalProps = {
  config: {
    objectsToPartnerDataSharing: boolean
    handleSubmit: ({
      objectsToPartnerDataSharing,
    }: {
      objectsToPartnerDataSharing: boolean
    }) => void
  }
}

const PartnerDataSharingModal = ({
  config: { objectsToPartnerDataSharing, handleSubmit },
}: ModalProps): ReactElement => {
  const dispatch = useDispatch()

  const handleSave = async ({
    objectsToPartnerDataSharing,
  }: {
    objectsToPartnerDataSharing: boolean
  }) => {
    await handleSubmit({ objectsToPartnerDataSharing })
    return dispatch(hideModal())
  }

  return (
    <>
      <P>
        This person will also be opted out of all data sharing, including
        marketing.
      </P>
      <Formik
        initialValues={{
          objectsToPartnerDataSharing,
        }}
        onSubmit={handleSave}
      >
        {({ isSubmitting, values }) => (
          <Form>
            <Wrapper margin={[0, 0, 'M']}>
              <Checkbox
                name="objectsToPartnerDataSharing"
                label="Do not share future data with partners"
                checked={values.objectsToPartnerDataSharing}
              />
            </Wrapper>
            <ButtonGroup gap="S">
              <Button.Primary type="submit" loading={isSubmitting}>
                Save
              </Button.Primary>
              <Button.Underline onClick={() => dispatch(hideModal())}>
                Cancel
              </Button.Underline>
            </ButtonGroup>
          </Form>
        )}
      </Formik>
    </>
  )
}

export default PartnerDataSharingModal
