import { useFormikContext } from 'formik'
import { Link } from 'react-router-dom'
import { Button, ButtonGroup, H, Wrapper, Grid } from '@farewill/ui'

import { Operation } from 'routes/partners/interfaces'
import { PartnerSummary } from 'lib/models/partner'
import PartnerSummaryTable from '../summary-table'
import { GTR } from '@farewill/ui/tokens'
import StyledLoader from 'components/loader'

interface PartnerReviewProps {
  partnerId?: string
  operation: Operation
  isLoading?: boolean
}

type PartnerReviewType = PartnerSummary & { type: 'charity' | 'organisation' }
const PartnerReview = ({
  partnerId,
  operation,
  isLoading = false,
}: PartnerReviewProps): React.ReactElement => {
  const { values, isSubmitting } = useFormikContext<PartnerReviewType>()

  if (isLoading) return <StyledLoader />

  const { type, charity, organisation } = values

  const backPath =
    operation === 'update' ? `/partners/${partnerId}/update` : '/partners/new'
  const partnerSummary = {
    ...values,
    charity: type === 'charity' ? charity : null,
    organisation: type === 'organisation' ? organisation : null,
  }

  return (
    <Grid padding={[GTR.L, 0, 0]}>
      <Grid.Item spanFromL={7}>
        <Wrapper>
          <H tag="h1" size="M">
            Review and submit
          </H>
          <Wrapper margin={['XL', 0]}>
            <PartnerSummaryTable values={partnerSummary} />
          </Wrapper>
          <ButtonGroup horizontalFromS>
            <Button.Primary type="submit" loading={isSubmitting}>
              Confirm and submit
            </Button.Primary>
            <Button.Plain tag={Link} back to={backPath}>
              Back to edit
            </Button.Plain>
          </ButtonGroup>
        </Wrapper>
      </Grid.Item>
    </Grid>
  )
}
export default PartnerReview
