import { flattenAttributes } from 'utils/helpers'

const FETCH_CONTACT = 'FETCH_CONTACT'
const UPDATE_CONTACT = 'UPDATE_CONTACT'

const CREATE_LEAD = 'CREATE_LEAD'

export default function contact(state = {}, action = {}) {
  switch (action.type) {
    case FETCH_CONTACT + '_FULFILLED':
    case UPDATE_CONTACT + '_FULFILLED':
      return action.payload.data.data

    case CREATE_LEAD + '_FULFILLED': {
      const lead = flattenAttributes(action.payload.data.data)
      const leads = [...state.attributes.leads, lead]
      const attributes = { ...state.attributes, leads }
      return { ...state, attributes }
    }

    case FETCH_CONTACT + '_PENDING':
    case FETCH_CONTACT + '_REJECTED':
    case 'UNSET_CONTACT':
      return {}

    default:
      return state
  }
}
