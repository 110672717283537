import { Button, Grid, P } from '@farewill/ui'
import { GTR } from '@farewill/ui/tokens'
import { useFormikContext } from 'formik'
import get from 'lodash/get'

import store from 'state/create-store'
import { showModal } from 'state/actions'
import namespace from 'lib/formik/namespace'
import {
  PROBATE_ESTATE_ITEM_TYPES,
  ProbateEstateItem,
} from 'lib/models/probate-estate'
import EstateCard from 'components/estate-card'
import InputFloating from 'components/form/input-floating'
import CircularRadioGroup from 'components/form/circular-radio-group'
import NotesInput from 'components/notes-input'
import Tooltip from 'components/tooltip'
import DeleteModal from 'routes/lead/probate-form/delete-modal'
import TextSpacer from 'routes/lead/probate-form/text-spacer'
import withForm from 'routes/lead/probate-form/with-form'
import { getItemsTotals } from 'routes/probate-estate/fact-find/get-totals'
import {
  PROBATE_LEAD_FORM_SECTIONS,
  PROBATE_LEAD_FORM_VALUES,
} from 'routes/lead/probate-form/constants'

import { HandleSaveProps, ItemProps } from './types'
import { useItems } from '../helpers/items-context'
import { useProbateEstate } from '../helpers/probate-estate-context'
import SubsectionHeading from '../subsection-heading'

const VALUE_TOOLTIP_CONTENT = (
  <>
    <P size="S">
      For ordinary shareholdings on the London Stock Exchange we can help find
      the value. Search for the company name and share type here:{' '}
      <a
        href="https://www.hl.co.uk/shares"
        target="_blank"
        rel="noopener noreferrer"
      >
        https://www.hl.co.uk/shares
      </a>
      .
    </P>
    <P size="S">
      For taxable estates we may need to find the ‘quarter up’ value using{' '}
      <a
        href="https://docs.google.com/spreadsheets/d/1Fo1PXsotyKtMMetFX6JwqdSwPzwVzXiTOkYCITN7iSc/edit#gid=1401461292"
        target="_blank"
        rel="noopener noreferrer"
      >
        this spreadsheet
      </a>
      .
    </P>
  </>
)

const Item = ({ item, index, listLength }: ItemProps) => {
  const {
    OWNERSHIP,
    JOINT_OWNER_NAME,
    DECEASED_SHARE_OF_JOINT_OWNERSHIP,
    NOTES,
    VALUE,
  } = PROBATE_LEAD_FORM_VALUES
  const { withNamespace, withoutNamespace } = namespace(
    `publicShareholdings.${item.id}.`
  )
  const { values } = useFormikContext()
  const { updateItem, deleteItem } = useItems()

  const handleSave = ({ name, value }: HandleSaveProps) => {
    updateItem(
      {
        id: item.id,
        attributes: { [withoutNamespace(name)]: value || null },
      },
      PROBATE_ESTATE_ITEM_TYPES.PUBLIC_SHAREHOLDING
    )
  }

  const onTrashClick = () => {
    store.dispatch(
      showModal({
        component: DeleteModal,
        headingText: 'Delete public shareholding',
        config: {
          deleteCallback: () => {
            deleteItem(item.id)
          },
          type: 'public shareholding',
          resourceId: item.id,
        },
      })
    )
  }

  return (
    <EstateCard
      onTrashClick={onTrashClick}
      listLength={listLength}
      listPosition={index + 1}
      key={item.id}
      type="public shareholding"
    >
      <Grid>
        <Grid.Item span={9}>
          <InputFloating
            name={withNamespace(PROBATE_LEAD_FORM_VALUES.DESCRIPTION)}
            label="Description"
            handleSave={handleSave}
          />
        </Grid.Item>
        <Grid.Item span={6}>
          <InputFloating
            name={withNamespace(VALUE)}
            label="Total value (£)"
            handleSave={handleSave}
            hint={
              <>
                <Tooltip content={VALUE_TOOLTIP_CONTENT}>
                  How to find the value.
                </Tooltip>{' '}
                For joint assets, the £ value of their share will be calculated
                automatically.
              </>
            }
            isCurrency
          />
        </Grid.Item>
        <Grid.Item span={12}>
          <CircularRadioGroup
            name={withNamespace(OWNERSHIP)}
            label="Ownership"
            options={[
              { label: 'Not asked', value: '' },
              { label: 'Sole', value: 'sole' },
              { label: 'Joint', value: 'joint' },
            ]}
            handleSave={handleSave}
            inline
          />
        </Grid.Item>
        <Grid.Item span={12}>
          <Grid>
            {get(values, withNamespace(OWNERSHIP)) === 'joint' && (
              <>
                <Grid.Item span={6}>
                  <InputFloating
                    name={withNamespace(JOINT_OWNER_NAME)}
                    label="Name of joint owner"
                    handleSave={handleSave}
                    hint="If more than one, leave a note below"
                  />
                </Grid.Item>
                <Grid.Item span={6}>
                  <InputFloating
                    name={withNamespace(DECEASED_SHARE_OF_JOINT_OWNERSHIP)}
                    label="Share owned by person who's died (%)"
                    handleSave={handleSave}
                  />
                </Grid.Item>
              </>
            )}
          </Grid>
        </Grid.Item>
        <Grid.Item>
          <NotesInput name={withNamespace(NOTES)} handleSave={handleSave} />
        </Grid.Item>
      </Grid>
    </EstateCard>
  )
}

const ItemWithForm = withForm(Item)

const SharesAndInvestments = ({ isComplete }: { isComplete: boolean }) => {
  const { SHARES_AND_INVESTMENTS } = PROBATE_LEAD_FORM_SECTIONS
  const { saveProbateEstateField } = useProbateEstate()
  const { groupedItems, createItem } = useItems()
  const { publicShareholding: publicShareholdingItems = [] } = groupedItems
  const { total, sole, joint, unspecified } = getItemsTotals(
    publicShareholdingItems
  )

  return (
    <SubsectionHeading
      disabled={publicShareholdingItems.length > 0}
      name={SHARES_AND_INVESTMENTS}
      onToggle={saveProbateEstateField}
      scrollId="6.3"
      title="Public shareholdings and investments"
      isComplete={isComplete}
    >
      <Grid.Item span={11}>
        <P size="S" data-testid="items-value-summary">
          Total: {total} <TextSpacer /> Sole: {sole} <TextSpacer /> Joint share:{' '}
          {joint} <TextSpacer /> Unspecified: {unspecified}
        </P>

        {publicShareholdingItems.map(
          (item: ProbateEstateItem, index: number) => (
            <ItemWithForm
              enableReinitialize={false}
              index={index}
              initialValues={{
                publicShareholdings: { [item.id]: item.attributes },
              }}
              item={item}
              key={item.id}
              listLength={publicShareholdingItems.length}
            />
          )
        )}
      </Grid.Item>
      <Grid.Item>
        <Button.Secondary
          style={{ paddingTop: GTR.XXS, paddingBottom: GTR.XXS }}
          type="button"
          onClick={() => {
            createItem({
              itemType: PROBATE_ESTATE_ITEM_TYPES.PUBLIC_SHAREHOLDING,
            })
          }}
        >
          Add shareholding/investment
        </Button.Secondary>
      </Grid.Item>
    </SubsectionHeading>
  )
}

export default SharesAndInvestments
