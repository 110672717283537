import { createContext, useContext, useEffect } from 'react'

import useApiHelpers from 'lib/effects/api-helpers'

import { Context as ProbateEstateContext } from 'routes/probate-estate/context'

const RelativesContext = createContext()

const useRelativesContext = () => {
  const context = useContext(RelativesContext)
  if (context === undefined) {
    throw new Error(
      'useRelativesContext must be used within a RelativesProvider'
    )
  }
  return context
}

const RelativesProvider = ({ children, probateEstateId }) => {
  const { setErrors, setIsLoading } = useContext(ProbateEstateContext)

  const {
    items: relatives,
    fetchItems: fetchRelatives,
    isFetching: isFetchingRelatives,
    createItem: createRelative,
    isCreating: isCreatingRelative,
    updateItem: updateRelative,
    isUpdating: isUpdatingRelative,
    updateErrors: updateRelativeErrors,
    deleteItem: deleteRelative,
  } = useApiHelpers({
    baseUrl: `/api/probate-estates/${probateEstateId}/relatives`,
    type: 'probate_estate_relatives',
  })

  useEffect(() => {
    setErrors(updateRelativeErrors)
  }, [setErrors, updateRelativeErrors])

  useEffect(() => {
    setIsLoading(isUpdatingRelative)
  }, [setIsLoading, isUpdatingRelative])

  const value = {
    relatives,
    fetchRelatives,
    isFetchingRelatives,
    createRelative,
    isCreatingRelative,
    updateRelative,
    isUpdatingRelative,
    deleteRelative,
  }

  return (
    <RelativesContext.Provider value={value}>
      {children}
    </RelativesContext.Provider>
  )
}

export { RelativesProvider, useRelativesContext }
