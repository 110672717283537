import { useEffect, useState } from 'react'
import { useQueryClient } from 'react-query'

import { AdminUser, Role } from 'lib/models/admin-user'
import useApi from 'lib/effects/api'
import ENV from 'config/environment'
import useAdminUserId from './useAdminUserId'

type UseAdminUserRolesResponse = {
  roles: AdminUser['attributes']['roles'] | null
  isUserRole: (role: Role) => boolean
}

const useAdminUserRoles = (): UseAdminUserRolesResponse => {
  const [adminUser, setAdminUser] = useState<AdminUser>()
  const cache = useQueryClient()
  const [, fetchAdminUsers] = useApi()
  const adminUserId = useAdminUserId()
  const isProtectionEnabled =
    ENV.FF_ROLE_BASED_AUTHENTICATION_ENABLED && ENV.APP_ENV !== 'development'

  /** Check if the specified role is applicable for the current user */
  const isUserRole: UseAdminUserRolesResponse['isUserRole'] = (role) =>
    isProtectionEnabled ? !!adminUser?.attributes?.roles.includes(role) : true

  useEffect(() => {
    const fetchAdminUser = async () => {
      const adminUsers = await cache.fetchQuery<AdminUser[]>(
        'adminUsers',
        async () => {
          const response = await fetchAdminUsers<{ data: AdminUser[] }>({
            url: '/api/admin-users',
          })
          return response.data
        }
      )
      setAdminUser(adminUsers.find((user) => user.id === adminUserId))
    }
    fetchAdminUser()
  }, [adminUserId, cache, fetchAdminUsers])

  return {
    roles: adminUser?.attributes?.roles ?? null,
    isUserRole,
  }
}

export default useAdminUserRoles
