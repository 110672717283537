import isBoolean from 'lodash/isBoolean'
import lowerCase from 'lodash/lowerCase'
import upperFirst from 'lodash/upperFirst'

export const formatAnswer = (answer) => {
  if (answer === undefined || answer === null) {
    return 'Not Answered'
  }

  if (isBoolean(answer)) {
    return answer ? 'Yes' : 'No'
  }

  return formatToHuman(answer)
}

export const formatToHuman = (value) => {
  return upperFirst(lowerCase(value))
}

export const formatDateToDMY = (date) => {
  const [year, month, day] = date.split('-')

  return `${day}-${month}-${year}`
}
