import { P } from '@farewill/ui'

import Tooltip from 'components/tooltip'
import { WillLeadAttributes } from 'lib/models/lead'
import { strToBool } from 'utils/helpers'
import { isUnsupportedResidence } from '../helpers/is-unsupported-residence'
import { OUTCOME_TYPE } from '../constants'
import { getCountryLabelFromValue } from '../helpers/form-utils'
import { CompleteOutcome, QuestionConfig } from '../types'

export const HouseInTrust = {
  name: 'quoteAttributes.wantsToPutHouseInTrust',
  label: (
    <Tooltip content="Guidance on setting up a trust to protect their inheritance from future care home fees.">
      They want to put their house in a trust to avoid future care home fees
    </Tooltip>
  ),
  type: 'checkbox',
  isOptional: () => true,
  reasonForPhoneWill: (values: WillLeadAttributes) => {
    if (strToBool(values.quoteAttributes?.wantsToPutHouseInTrust)) {
      return (
        <>
          <P strong>Because they want to put their house in a trust.</P>
          <P>
            We can set up a trust to protect their inheritance from future care
            home fees.
          </P>
        </>
      )
    }
  },
  reasonForComplexWill: (values: WillLeadAttributes) => {
    if (strToBool(values.quoteAttributes?.wantsToPutHouseInTrust)) {
      return (
        <>
          <P strong>Because they want to put their house in a trust.</P>
          <P>
            We can set up a trust to protect their inheritance from future care
            home fees.
          </P>
        </>
      )
    }
  },
  outcome: (values: WillLeadAttributes) => {
    if (
      strToBool(values.quoteAttributes?.wantsToPutHouseInTrust) &&
      isUnsupportedResidence(values)
    ) {
      return {
        complete: true,
        type: OUTCOME_TYPE.CANNOT_HELP,
        message: `they want to put their house in a trust while they live in ${getCountryLabelFromValue(
          values.quoteAttributes.permanentResidence ||
            values.quoteAttributes.residence ||
            ''
        )}`,
      } as CompleteOutcome
    }
  },
} as QuestionConfig

export const BeneficiaryInvolvedInDivorceOrBankruptcy = {
  name: 'quoteAttributes.hasBeneficiaryInvolvedInDivorceOrBankruptcy',
  label: (
    <Tooltip content="Guidance on setting up a trust to protect their inheritance.">
      They want to leave something to a person who is currently involved in
      divorce or bankruptcy
    </Tooltip>
  ),
  type: 'checkbox',
  isOptional: () => true,
  reasonForPhoneWill: (values: WillLeadAttributes) => {
    if (
      strToBool(
        values.quoteAttributes?.hasBeneficiaryInvolvedInDivorceOrBankruptcy
      )
    ) {
      return (
        <>
          <P strong>
            Because they want to leave something to a person who is currently
            involved in divorce or bankruptcy.
          </P>
          <P>
            We can set up a trust to protect their inheritance and give specific
            guidance on their situation.
          </P>
        </>
      )
    }
  },
  reasonForComplexWill: (values: WillLeadAttributes) => {
    if (
      strToBool(
        values.quoteAttributes?.hasBeneficiaryInvolvedInDivorceOrBankruptcy
      )
    ) {
      return (
        <>
          <P strong>
            Because they want to leave something to a person who is currently
            involved in divorce or bankruptcy.
          </P>
          <P>
            We can set up a trust to protect their inheritance and give specific
            guidance on their situation.
          </P>
        </>
      )
    }
  },
  outcome: (values: WillLeadAttributes) => {
    const value = strToBool(
      values.quoteAttributes?.hasBeneficiaryInvolvedInDivorceOrBankruptcy
    )

    const outcomeCondition = value && isUnsupportedResidence(values)

    if (outcomeCondition) {
      return {
        complete: true,
        type: OUTCOME_TYPE.CANNOT_HELP,
        message: `they want to leave something to a person who is currently
        involved in divorce or bankruptcy${
          isUnsupportedResidence(values)
            ? ` while they live in ${getCountryLabelFromValue(
                values.quoteAttributes.permanentResidence ||
                  values.quoteAttributes.residence ||
                  ''
              )}`
            : ''
        }`,
      }
    }
  },
}
