import { GraphQLClient } from 'graphql-request'
import { gql } from 'graphql-tag'

import store from 'state/create-store'

export const getGraphQLClient = (): GraphQLClient => {
  const { token } = store.getState()
  const client = new GraphQLClient('/api/admin')
  client.setHeader('Authorization', `Bearer ${token}`)

  return client
}

export const ACCOUNT_BY_EMAIL = gql`
  query accountByEmail($email: String!) {
    account(email: $email, deactivatedAt: null) {
      id
      uuid
      contact {
        id
      }
      error {
        code
      }
    }
  }
`

export const ACCOUNT_BY_UUID = gql`
  query accountByUuid($uuid: String!) {
    account(uuid: $uuid) {
      id
      email
      createdAt
      contact {
        id
      }
      error {
        code
      }
    }
  }
`

export const PACKAGES = gql`
  query packages($status: [String]) {
    packages(filter: { status: $status }) {
      id
      name
      status
      price {
        id
        currency
        amountInCents
      }
      slug
    }
  }
`

export const FUNERAL_PLANS = gql`
  query funeralPlans(
    $status: [Status]
    $field: FuneralPlanSortableColumn
    $order: SortOrder
    $first: Int
    $before: String
    $after: String
    $hasPhoneNumber: Boolean
    $includesWishes: Boolean
    $includesMessages: Boolean
  ) {
    funeralPlans(
      filter: {
        status: $status
        hasPhoneNumber: $hasPhoneNumber
        includesWishes: $includesWishes
        includesMessages: $includesMessages
      }
      sort: { field: $field, order: $order }
      first: $first
      before: $before
      after: $after
    ) {
      totalCount
      edges {
        node {
          id
          status
          purchaser {
            firstName
            lastName
            preferredName
          }
          package {
            id
            status
            name
          }
          lastActivityAt
        }
      }
      pageInfo {
        startCursor
        endCursor
      }
    }
  }
`

export const FUNERAL_PLAN = gql`
  query funeralPlan($id: String!) {
    funeralPlan(id: $id) {
      id
      status
      criticalNotes
      leadId
      reference
      purchaser {
        id
        firstName
        lastName
        preferredName
        fullLegalName
        dateOfBirth
        phoneNumber
        email
        addresses {
          lineOne
          lineTwo
          city
          postalCode
          countryCode
          type
        }
        account {
          id
          createdAt
          email
          uuid
        }
      }
      package {
        id
        status
        name
        slug
        price {
          amountInCents
        }
      }
      wishes {
        slug
        name
        category
        value
      }
      items {
        name
        description
        category
      }
      error {
        message
        code
      }
    }
  }
`

export const UPDATE_FUNERAL_PLAN = gql`
  mutation updateFuneralPlan($id: String!, $criticalNotes: String) {
    updateFuneralPlan(id: $id, criticalNotes: $criticalNotes) {
      id
      criticalNotes
      error {
        message
        code
      }
    }
  }
`

export const UPDATE_FUNERAL_PLAN_STATUS = gql`
  mutation updateFuneralPlanStatus(
    $id: String!
    $status: Status!
    $reason: String!
  ) {
    updateFuneralPlanStatus(id: $id, status: $status, reason: $reason) {
      status
    }
  }
`

export const UPDATE_FUNERAL_PLAN_PACKAGE = gql`
  mutation updateFuneralPlanPackage($id: String!, $packageId: String!) {
    updateFuneralPlanPackage(id: $id, packageId: $packageId) {
      id
    }
  }
`
