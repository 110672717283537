import { CrematoriaProvider } from './context'

import { CrematoriaList } from './list'

const Crematoria = () => {
  return (
    <CrematoriaProvider>
      <CrematoriaList />
    </CrematoriaProvider>
  )
}

export default Crematoria
