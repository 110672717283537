import styled, { css } from 'styled-components'
import { Button } from '@farewill/ui'

import { paragraphS } from '@farewill/ui/helpers/text'

const narrowButtonStyles = css`
  ${paragraphS}
  line-height: 1;
`

const StyledPrimaryButton = styled(Button.Primary)`
  ${narrowButtonStyles}
`

const StyledSecondaryButton = styled(Button.Secondary)`
  ${narrowButtonStyles}
`

const StyledBorderedButton = styled(Button.Bordered)`
  ${narrowButtonStyles}
`

const ButtonNarrow = {
  Primary: StyledPrimaryButton,
  Secondary: StyledSecondaryButton,
  Bordered: StyledBorderedButton,
}

export default ButtonNarrow
