import { Component } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { ArrowRightIcon, Wrapper, P, Button } from '@farewill/ui'
import { COLOR, GTR, FONT } from '@farewill/ui/tokens'
import moment from 'moment'

import {
  dateIsToday,
  formatSeconds,
  getAircallContactName,
} from 'utils/helpers'

import LiveIndicator from 'components/live-indicator'

const CALLS_IN_VIEW = 8

const StyledGridRow = styled.ul`
  display: grid;
  grid-template-columns: 0.8fr 1fr 1fr 1.4fr 0.8fr 1fr 30px;
  margin-bottom: 0;
  margin-top: -1px;
  border-width: 1px 0;
  border-color: ${COLOR.GREY.LIGHT};
  border-style: solid;
  padding: ${GTR.S} ${GTR.XS};
  cursor: pointer;
  align-items: center;

  &:hover,
  &:focus {
    background-color: ${COLOR.WHITE};
  }

  ${({ header }) =>
    header &&
    css`
      border-width: 0 0 2px;
      cursor: initial;

      &:hover,
      &:focus {
        background-color: transparent;
      }
    `}
`

const StyledViewMoreButton = styled(Button)`
  text-decoration: underline;
  font-weight: ${FONT.WEIGHT.REGULAR};

  &:hover {
    text-decoration: underline;
  }
`

const RefreshButton = styled(Button.Primary)`
  font-size: ${FONT.SIZE.XS};
  font-weight: ${FONT.WEIGHT.BOLD};
  padding: ${GTR.XS} ${GTR.M};
`

class Calls extends Component {
  state = {
    allCalls: [],
    visibleCalls: [],
    page: 0,
  }

  componentDidMount = () => {
    this.getLatestCalls()
  }

  getLatestCalls = async () => {
    this.setState({ allCalls: [], visibleCalls: [], page: 0 })
    this.props.setCall(null)

    await this.props.fetchLatestCalls()

    const calls = this.props.latestCalls
    const answeredCalls = calls.filter((call) => call.answered_at)
    const firstFiveCalls = answeredCalls.slice(0, CALLS_IN_VIEW)

    this.setState({
      allCalls: answeredCalls,
      visibleCalls: firstFiveCalls,
    })
  }

  getNextCalls = (page) => {
    return this.state.allCalls.slice(0, CALLS_IN_VIEW * page + CALLS_IN_VIEW)
  }

  loadNext = () => {
    this.setState((prevState) => {
      const page = prevState.page + 1
      return {
        page,
        visibleCalls: this.getNextCalls(page),
      }
    })
  }

  viewMoreIsVisible = () => {
    return this.state.visibleCalls.length !== this.state.allCalls.length
  }

  handleClick = (call) => {
    const { internalContactId: id } = call
    if (!id) this.props.setCall(call)
    this.props.history.push(`/contacts/${id ? id : 'new'}`)
  }

  render() {
    const { isLoading } = this.props

    return (
      <>
        <Wrapper separator padding={[GTR.L, 0, 0]}>
          <RefreshButton loading={isLoading} onClick={this.getLatestCalls}>
            Refresh Aircall feed
          </RefreshButton>
        </Wrapper>
        <Wrapper separator>
          {isLoading ? (
            <P>Loading latest calls...</P>
          ) : (
            <>
              <ul>
                <li>
                  <StyledGridRow header>
                    <li>Time</li>
                    <li>Name</li>
                    <li>Phone number</li>
                    <li>Line</li>
                    <li>Duration</li>
                    <li>Taken by</li>
                  </StyledGridRow>
                </li>
                {this.state.visibleCalls.map((call) => {
                  const isLive = !call.ended_at
                  const callStartedAt = call.started_at * 1000
                  return (
                    <li key={call.id}>
                      <StyledGridRow onClick={() => this.handleClick(call)}>
                        <li>
                          {isLive ? (
                            <LiveIndicator>
                              Live &bull;{' '}
                              {moment(new Date(callStartedAt)).format(
                                'HH[:]mm'
                              )}
                            </LiveIndicator>
                          ) : (
                            moment(new Date(callStartedAt)).format(
                              dateIsToday({ callStartedAt })
                                ? '[Today], HH[:]mm'
                                : 'ddd, HH[:]mm'
                            )
                          )}
                        </li>
                        <li>
                          {getAircallContactName(call.contact) || 'Unknown'}
                        </li>
                        <li>{call.raw_digits}</li>
                        <li>{call.number.name}</li>
                        <li>{isLive ? '-' : formatSeconds(call.duration)}</li>
                        <li>{call.user?.name}</li>
                        <li>
                          <ArrowRightIcon size="S" />
                        </li>
                      </StyledGridRow>
                    </li>
                  )
                })}
              </ul>
              {this.viewMoreIsVisible() && (
                <StyledViewMoreButton onClick={this.loadNext}>
                  View more
                </StyledViewMoreButton>
              )}
            </>
          )}
        </Wrapper>
      </>
    )
  }
}

Calls.defaultProps = {
  call: {},
  isLoading: false,
}

Calls.propTypes = {
  fetchLatestCalls: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  isLoading: PropTypes.bool,
  setCall: PropTypes.func.isRequired,
}

export default Calls
