import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useFormikContext } from 'formik'
import { Wrapper } from '@farewill/ui'
import { GTR } from '@farewill/ui/tokens'
import inRange from 'lodash/inRange'

import {
  FloatingWrapper,
  FloatingLabel,
  FloatingInput,
  FloatingHint,
} from 'components/styled/helpers/floating'
import ErrorSmall from 'components/form/error-small'
import { getFieldState } from './helpers'

const FIELDS = [
  { suffix: 'Day', maxLength: 2 },
  { suffix: 'Month', maxLength: 2 },
  { suffix: 'Year', maxLength: 4 },
]

const validation = {
  Day: (value) => {
    if (!value) return null

    const isNumber = /^[0-9]*$/.test(value)
    if (!isNumber) return 'Day must be a number.'

    const isValidDay = inRange(parseInt(value), 1, 32)
    if (!isValidDay) return 'Day must be between 1-31.'
  },
  Month: (value) => {
    if (!value) return null

    const isNumber = /^[0-9]*$/.test(value)
    if (!isNumber) return 'Month must be a number.'

    const isValidDay = inRange(parseInt(value), 1, 13)
    if (!isValidDay) return 'Month must be between 1-12.'
  },
  Year: (value) => {
    if (!value) return null

    const isNumber = /^[0-9]{4}$/.test(value)
    if (!isNumber) return 'Year must be a 4 digit number, e.g. 2020.'
  },
}

const StyledLabel = styled.label`
  margin-bottom: ${GTR.XS};
`

const StyledFieldsWrapper = styled(Wrapper)`
  display: flex;
  margin-bottom: ${GTR.XXS};
`

const StyledFieldWrapper = styled(FloatingWrapper)`
  max-width: 85px;

  &:not(:last-of-type) {
    margin-right: 12px;
  }
`

const DateField = ({
  field: { suffix, maxLength },
  formik,
  handleSave,
  name,
}) => {
  const { handleBlur } = formik
  const inputName = name + suffix
  const { hasError, hasHighlight, hasValue } = getFieldState({
    formik,
    name: inputName,
  })

  return (
    <StyledFieldWrapper>
      <FloatingInput
        onBlur={(e) => {
          handleBlur(e)
          const value = e.target.value
          handleSave && handleSave({ name: inputName, value })
        }}
        error={hasError}
        highlight={hasHighlight}
        maxLength={maxLength}
        name={inputName}
        validate={validation[suffix]}
      />
      <FloatingLabel error={hasError} full={hasValue}>
        {suffix}
      </FloatingLabel>
    </StyledFieldWrapper>
  )
}

const DateSplit = ({ handleSave, hint, label, name }) => {
  const formik = useFormikContext()
  const hasSomeError = FIELDS.some(
    ({ suffix }) => getFieldState({ formik, name: name + suffix }).hasError
  )
  const allHaveValue = FIELDS.every(
    ({ suffix }) => getFieldState({ formik, name: name + suffix }).hasValue
  )
  const hasHighlight = formik.status?.highlightEmpty && !allHaveValue

  return (
    <FloatingWrapper error={hasSomeError} highlight={hasHighlight}>
      <StyledLabel>{label}</StyledLabel>
      <StyledFieldsWrapper>
        {FIELDS.map((field) => (
          <DateField
            name={name}
            formik={formik}
            handleSave={handleSave}
            field={field}
            key={field.suffix}
          />
        ))}
      </StyledFieldsWrapper>
      {hint && <FloatingHint>{hint}</FloatingHint>}
      {FIELDS.map((field) => (
        <ErrorSmall name={name + field.suffix} key={field.suffix} />
      ))}
    </FloatingWrapper>
  )
}

DateSplit.propTypes = {
  handleSave: PropTypes.func,
  hint: PropTypes.string,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
}

DateSplit.defaultProps = {
  hint: '',
}

export default DateSplit
