import { H, Image, P, Wrapper } from '@farewill/ui'

const NotFoundMessage = () => (
  <Wrapper centered>
    <H size="L" decorative>
      Not found
    </H>
    <P>Sorry, we can't find the thing you were looking for!</P>
    <Wrapper>
      <Image path="illustrations/big-cat-little-cat" width={300} />
    </Wrapper>
  </Wrapper>
)

export default NotFoundMessage
