import styled from 'styled-components'

import { Grid } from '@farewill/ui'
import Badge from 'components/styled/badge'

import { PARTNER_PRODUCTS } from 'lib/models/partner'

const StyledPillsWrapper = styled.div`
  display: flex;
  gap: 10px;
`

const PRODUCT_PILL = {
  [PARTNER_PRODUCTS.ONLINE_WILL]: {
    name: 'Online Will',
    label: 'macbook',
    img: '💻',
  },
  [PARTNER_PRODUCTS.TELEPHONE_WILL]: {
    name: 'Telephone Will',
    label: 'phone',
    img: '📞',
  },
}

const ProductPills = ({ products }: { products: PARTNER_PRODUCTS[] }) => {
  products.sort()

  return (
    <Grid.Item>
      <StyledPillsWrapper>
        {products.map((product) => (
          <Badge key={product} $state="info">
            <span role="img" aria-label={PRODUCT_PILL[product].label}>
              {PRODUCT_PILL[product].img}
            </span>{' '}
            {PRODUCT_PILL[product].name}
          </Badge>
        ))}
      </StyledPillsWrapper>
    </Grid.Item>
  )
}

export default ProductPills
