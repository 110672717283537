import { Grid, Button, P } from '@farewill/ui'
import { Formik, Form } from 'formik'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'

import { hideModal } from 'state/actions'
import { NEW_LEAD_SCHEMA } from 'lib/formik/schemata'

import SelectInput from 'components/form/select-input'
import PartnerSelect from 'components/partner-select'

import { showPartnerSelect } from 'utils/helpers'

import {
  FAREWILL_PRODUCTS_OPTIONS,
  LEAD_SOURCE_TYPE_OPTIONS,
  LEAD_SOURCE_IDENTIFIER_OPTIONS,
  LEAD_PARTNER_TYPE_OPTIONS,
} from 'utils/enums'

const initialValues = {
  product: '',
  sourceType: '',
  sourceIdentifier: '',
  partnerType: '',
  partnerId: undefined,
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error Implicitly any
const NewLead = ({ config: { contact, createLead, isCreatingLead } }) => {
  const dispatch = useDispatch()

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error Implicitly any
  const handleSubmit = async (values, actions) => {
    const postData = {
      ...values,
      contactId: contact.id,
    }
    try {
      await createLead(postData)
      toast('Success! Product added.', {
        toastId: 'product-added',
      })
      dispatch(hideModal())
    } catch {
      actions.setSubmitting(false)
    }
  }

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={initialValues}
      validationSchema={NEW_LEAD_SCHEMA}
    >
      {({ dirty, values, setFieldValue }) => {
        const { sourceType, partnerType } = values
        const sourceIdentifierOptions =
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error Implicitly any
          LEAD_SOURCE_IDENTIFIER_OPTIONS[sourceType]
        return (
          <Form>
            <Grid>
              <Grid.Item>
                <P>
                  Submitting this form will create a new "open" lead for this
                  contact.
                </P>
              </Grid.Item>
              <Grid.Item>
                <SelectInput
                  label="Product"
                  name="product"
                  options={FAREWILL_PRODUCTS_OPTIONS}
                  floating
                />
              </Grid.Item>
              <Grid.Item>
                <SelectInput
                  label="Type"
                  name="sourceType"
                  options={LEAD_SOURCE_TYPE_OPTIONS}
                  handleSave={() => setFieldValue('sourceIdentifier', '')}
                  floating
                />
              </Grid.Item>
              {sourceIdentifierOptions && (
                <Grid.Item>
                  <SelectInput
                    label="Source"
                    name="sourceIdentifier"
                    options={sourceIdentifierOptions}
                    floating
                  />
                </Grid.Item>
              )}
              <Grid.Item>
                <SelectInput
                  label="Partner type"
                  name="partnerType"
                  options={LEAD_PARTNER_TYPE_OPTIONS}
                  handleSave={() => setFieldValue('partnerId', undefined)}
                  floating
                />
              </Grid.Item>
              {showPartnerSelect(partnerType) && (
                <Grid.Item>
                  <PartnerSelect
                    label="Partner name"
                    name="partnerId"
                    partnerType={partnerType}
                    floating
                  />
                </Grid.Item>
              )}
              <Grid.Item>
                <Button.Primary
                  type="submit"
                  disabled={!dirty}
                  loading={isCreatingLead}
                >
                  Save
                </Button.Primary>
              </Grid.Item>
            </Grid>
          </Form>
        )
      }}
    </Formik>
  )
}

export default NewLead
