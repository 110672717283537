import moment from 'moment'

// From GOV.UK - Inheritance Tax thresholds - from 18 March 1986 to 5 April 2019
// https://www.gov.uk/government/publications/rates-and-allowances-inheritance-tax-thresholds-and-interest-rates/inheritance-tax-thresholds-and-interest-rates
const THRESHOLDS = [
  { from: '6 April 2019', amount: '£325,000' },
  { from: '6 April 2009', to: '5 April 2019', amount: '£325,000' },
  { from: '6 April 2008', to: '5 April 2009', amount: '£312,000' },
  { from: '6 April 2007', to: '5 April 2008', amount: '£300,000' },
  { from: '6 April 2006', to: '5 April 2007', amount: '£285,000' },
  { from: '6 April 2005', to: '5 April 2006', amount: '£275,000' },
  { from: '6 April 2004', to: '5 April 2005', amount: '£263,000' },
  { from: '6 April 2003', to: '5 April 2004', amount: '£255,000' },
  { from: '6 April 2002', to: '5 April 2003', amount: '£250,000' },
  { from: '6 April 2001', to: '5 April 2002', amount: '£242,000' },
  { from: '6 April 2000', to: '5 April 2001', amount: '£234,000' },
  { from: '6 April 1999', to: '5 April 2000', amount: '£231,000' },
  { from: '6 April 1998', to: '5 April 1999', amount: '£223,000' },
  { from: '6 April 1997', to: '5 April 1998', amount: '£215,000' },
  { from: '6 April 1996', to: '5 April 1997', amount: '£200,000' },
  { from: '6 April 1995', to: '5 April 1996', amount: '£154,000' },
  { from: '10 March 1992', to: '5 April 1995', amount: '£150,000' },
  { from: '6 April 1991', to: '9 March 1992', amount: '£140,000' },
  { from: '6 April 1990', to: '5 April 1991', amount: '£128,000' },
  { from: '6 April 1989', to: '5 April 1990', amount: '£118,000' },
  { from: '15 March 1988', to: '5 April 1989', amount: '£110,000' },
  { from: '17 March 1987', to: '14 March 1988', amount: '£90,000' },
  { from: '18 March 1986', to: '16 March 1987', amount: '£71,000' },
  { from: '6 April 1985', to: '17 March 1986', amount: '£67,000' },
  { from: '13 March 1984', to: '5 April 1985', amount: '£64,000' },
  { from: '15 March 1983', to: '12 March 1984', amount: '£60,000' },
  { from: '9 March 1982', to: '14 March 1983', amount: '£55,000' },
  { from: '26 March 1980', to: '8 March 1982', amount: '£50,000' },
  { from: '27 October 1977', to: '25 March 1980', amount: '£25,000' },
  { from: '13 November 1975', to: '26 October 1977', amount: '£15,000' },
]

export const getThreshold = (date) => {
  if (!date) return 'Date is missing or incomplete'

  const foundThreshold = THRESHOLDS.find((threshold) => {
    const from = moment(threshold.from, 'D MMMM YYYY')
    const to = threshold.to && moment(threshold.to, 'D MMMM YYYY').endOf('day')
    return date.isBetween(from, to)
  })

  return foundThreshold?.amount || 'N/A'
}
