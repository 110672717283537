import groupBy from 'lodash/groupBy'
import mapKeys from 'lodash/mapKeys'
import camelCase from 'lodash/camelCase'
import moment from 'moment'

import { getMomentDate } from 'lib/formik/dates'
import { DEATH } from './fact-find/people/field-names'

export const formatDate = (date) => {
  if (!date) return null
  return date?.format('D MMM YYYY')
}

export const getDateOfDeath = (values) => {
  return getMomentDate({ prefix: DEATH, values })
}

export const groupProbateEstateItems = (items) => {
  return mapKeys(groupBy(items, 'attributes.itemType'), (_, key) =>
    camelCase(key)
  )
}

export const getDateOfDeathIsOnOrAfter20200206 = (values) => {
  const dateOfDeath = getDateOfDeath(values)
  if (!dateOfDeath) return null
  return dateOfDeath.isSameOrAfter(moment('2020-02-06'), 'day')
}

export const getDateOfDeathIsOnOrAfter20100406 = (values) => {
  const dateOfDeath = getDateOfDeath(values)
  if (!dateOfDeath) return null
  return dateOfDeath.isSameOrAfter(moment('2010-04-06'), 'day')
}

export const getDateIsOnOrAfter19741113 = (date) => {
  if (!date) return null
  return date.isSameOrAfter(moment('1974-11-13'), 'day')
}

export const getIsOverEighteen = (date) => {
  if (!date) return null
  const years = moment().diff(date, 'years')
  return years >= 18
}
