import { H, Grid } from '@farewill/ui'
import { useFormikContext } from 'formik'
import { useQueryClient } from 'react-query'
import styled from 'styled-components'

import { AdminUser } from '../../../lib/models/admin-user'
import SelectInput from 'components/form/select-input'
import { formatAdminUserOptions } from 'utils/helpers'
import Input from 'components/form/input'

//TODO: replace hardcoded height once we have standardised sizes in backstage
const StyledInput = styled(Input)`
  height: 59px;
`

interface FormFields {
  drafterId: string
  timeSpentDrafting: number
}

const Drafting = (): React.ReactElement => {
  const { handleSubmit } = useFormikContext<FormFields>()
  const cache = useQueryClient()
  const adminUsers = cache.getQueryData('adminUsers') as AdminUser[]
  const adminUserOptions = formatAdminUserOptions(adminUsers)

  return (
    <Grid>
      <Grid.Item>
        <H size="S" margin={[0, 0, 'M']}>
          Drafting
        </H>
      </Grid.Item>
      <Grid.Item spanFromL={6}>
        <SelectInput
          options={adminUserOptions}
          label="Drafter"
          name="drafterId"
          handleSave={() => handleSubmit()}
          isClearable
        />
      </Grid.Item>
      <Grid.Item spanFromL={6}>
        <StyledInput
          name="timeSpentDrafting"
          label="Time spent drafting (minutes)"
          handleSave={() => handleSubmit()}
        />
      </Grid.Item>
    </Grid>
  )
}
export default Drafting
