import { useFormikContext } from 'formik'
import { ReactElement, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Button, Grid, H, P } from '@farewill/ui'

import useApi from 'lib/effects/api'
import { FuneralPlanLeadAttributes } from 'lib/models/lead'
import { showModal } from 'state/actions'
import store from 'state/create-store'
import { FAREWILL_PRODUCTS } from 'utils/enums'

import { useLeadContext } from '../context'
import CouplePlanModal from './couple-plan-modal'

const CouplePlan = (): ReactElement => {
  const { lead, fetchLead } = useLeadContext()
  const {
    contact,
    partnerId,
    partnerType,
    sourceIdentifier,
    sourceType,
    quoteAttributes: {
      coupledLeadId,
      heardAboutFarewillVia,
      isPostalService,
      vulnerabilities,
    },
  } = lead.attributes as FuneralPlanLeadAttributes

  const { setFieldValue, values } = useFormikContext()

  const [, createLead] = useApi()

  const confirmCreateLead = async () => {
    await createLead({
      url: `/api/leads`,
      method: 'POST',
      data: {
        data: {
          type: 'leads',
          attributes: {
            product: FAREWILL_PRODUCTS.FUNERAL_PLAN,
            /* give it the same attributes as the first lead */
            contactId: contact.id,
            partnerId,
            partnerType: partnerType ?? 'none',
            sourceIdentifier,
            sourceType,
            quoteAttributes: {
              heardAboutFarewillVia,
              isPostalService,
              vulnerabilities,
              coupledLeadId: lead.id,
            },
          },
        },
      },
    })
    return fetchLead()
  }

  const handleCreateCoupledLead = async () => {
    store.dispatch(
      showModal({
        component: CouplePlanModal,
        headingText: 'You are about to create another lead',
        config: {
          confirmCreateLead,
        },
      })
    )
  }

  useEffect(() => {
    if (
      coupledLeadId &&
      (values as FuneralPlanLeadAttributes).quoteAttributes.coupledLeadId !==
        coupledLeadId
    ) {
      setFieldValue('quoteAttributes.coupledLeadId', coupledLeadId)
    }
  }, [coupledLeadId, setFieldValue, values])

  return (
    <>
      <H size="S">Buying as a couple</H>
      <Grid>
        {!coupledLeadId && (
          <Grid.Item>
            <P>
              If the customer asks for two plans, create a new lead for their
              partner.
            </P>
            <P>Take each payment separately.</P>
            <Button.Primary onClick={handleCreateCoupledLead}>
              Create new lead
            </Button.Primary>
          </Grid.Item>
        )}
        {coupledLeadId && (
          <Grid.Item spanFromL={6}>
            <P>This lead is part of a couple.</P>
            <P>
              <Link
                to={`/leads/${coupledLeadId}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <strong>[Open partner lead in new tab]</strong>
              </Link>
            </P>
          </Grid.Item>
        )}
      </Grid>
    </>
  )
}

export default CouplePlan
