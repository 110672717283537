import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Form, Formik, useFormikContext } from 'formik'
import {
  Button,
  Checkbox as UiCheckbox,
  ChevronRightIcon,
  CommentsIcon,
  LockIcon,
  P,
  Wrapper,
} from '@farewill/ui'
import { COLOR, FONT, GTR } from '@farewill/ui/tokens'
import { getWidthOfContainerColumns } from '@farewill/ui/helpers/layout'
import styled from 'styled-components'
import moment from 'moment'
import { Link, useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useDispatch } from 'react-redux'
import lowerCase from 'lodash/lowerCase'

import { showModal } from 'state/actions'
import { NEW_LAW } from 'utils/enums'

import {
  TaskEventsProvider,
  useTaskEventsContext,
  useTasksContext,
} from './context'
import AutosizeInput from 'components/form/autosize-input'
import Checkbox from 'components/form/checkbox'
import TaskActivity from './task-activity'
import {
  formatDateTimeToString,
  formatDateTimeToUTC,
  nextTaskDueOnString,
} from 'utils/helpers'
import DateBadge from 'components/date-badge'
import DatetimePicker from 'components/form/datetime-picker'
import Dropdown from 'components/dropdown'
import TaskAssignment from './task-assignment'
import HelpNeededAssignment from './help-needed-assignment'
import TaskEmail from './task-email'
import TaskEmailModal from './task-email-modal'
import TaskEmailSentModal from './task-email-sent-modal'
import TaskEmailStopModal from './task-email-stop-modal'
import TaskCompleteGenericModal from './task-complete-generic-modal'
import SetAutomatedDueDatesModal from './set-automated-due-dates-modal'
import {
  hasEmails,
  hasImmediateEmailTrigger,
  hasMultipleStartEmailTriggers,
  hasScheduledEmail,
  hasScheduledEmailTrigger,
  hasStartEmailTrigger,
  hasStopEmailTrigger,
} from './helpers'
import { CORE_TASKS_IN_ORDER } from './constants'

const getAutocompleteMessage = (task) => {
  const { completedAt, coreTask, probateCaseId, funeralCaseId } =
    task.attributes

  let messages = []
  if (funeralCaseId) {
    messages = {
      receive_payment_in_full: (
        <>
          This task is completed automatically when the outstanding balance
          reaches £0. Take payments on the{' '}
          <Link to={`/funerals/${funeralCaseId}/sale`}>Sale</Link> page.
        </>
      ),
    }
  }
  if (probateCaseId) {
    messages = {
      referral_made: (
        <>
          This task is completed automatically when the 'Referred to' field is
          updated on the{' '}
          <Link to={`/probate/cases/${probateCaseId}`}>Case details</Link> page.
        </>
      ),
      deposit_taken: (
        <>
          This event is completed automatically when the 'Deposit' section is
          updated on the{' '}
          <Link to={`/probate/cases/${probateCaseId}/sale`}>Sale</Link> page.
        </>
      ),
      case_booked: (
        <>
          This event is completed automatically when the 'Price' section is
          updated on the{' '}
          <Link to={`/probate/cases/${probateCaseId}/sale`}>Sale</Link> page.
        </>
      ),
    }
  }

  if (!messages[coreTask]) return null

  return (
    <>
      <strong>
        Cannot mark task as {completedAt ? 'incomplete' : 'complete'}.
      </strong>{' '}
      {messages[coreTask]}
    </>
  )
}

const StyledTaskTitle = styled(P)`
  font-size: ${FONT.SIZE.S};
  margin: 0;
  margin-left: ${GTR.S};

  ${({ theme }) =>
    theme.isExpanded &&
    `
    padding-right: ${GTR.M};
    cursor: default;
  `}
`

const StyledStatusWrapper = styled(Wrapper)`
  display: none;
  font-size: ${FONT.SIZE.S};
  margin: 0;
  cursor: default;
  align-items: center;
  grid-template-columns: 2fr 3fr;
  grid-gap: ${GTR.S};

  ${({ theme }) => theme.isExpanded && `display: grid;`}
  ${({ $isComplete }) =>
    $isComplete &&
    `
      grid-template-columns: 1fr;
      width: auto;
    `}
`

const StyledAssignmentWrapper = styled(Wrapper)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const StyledForm = styled(Form)`
  display: grid;
  grid-gap: ${GTR.S};
  align-items: center;
  grid-template-columns: ${({ theme }) =>
    theme.isExpanded ? '3fr 2fr' : '1fr'};
`

const StyledListItem = styled.li`
  &:not(:last-of-type) {
    border-bottom: 1px solid ${COLOR.GREY.LIGHT};
  }
`

const StyledTaskWrapper = styled(Wrapper)`
  position: relative;
  padding: ${GTR.S};
  transition: background-color 0.5s;

  ${({ theme }) =>
    theme.isExpanded &&
    `
    display: grid;
    grid-template-columns: 1fr 24px auto;
    grid-gap: ${GTR.S};
    align-items: center;
  `}

  ${({ $isExpanded, theme }) =>
    $isExpanded &&
    theme.isExpanded &&
    `
        background-color: ${COLOR.WHITE};
        border-bottom: 1px solid ${COLOR.GREY.LIGHT};
    `}

  ${({ $isComplete }) =>
    $isComplete && `background-color: ${COLOR.STATE.SUCCESS_10};`}

  ${({ $isHighlighted }) =>
    $isHighlighted && `background-color: ${COLOR.STATE.WARNING_10};`}
`

const StyledExpandButton = styled(Button)`
  display: none;

  ${({ theme }) => theme.isExpanded && `display: inline-flex;`}
  && {
    padding: ${GTR.XXS} 0;
  }
`

const StyledCommentsIcon = styled(CommentsIcon)`
  margin-right: ${GTR.XS};
`

const StyledChevronIcon = styled(ChevronRightIcon)`
  ${({ $rotate }) => $rotate && `transform: rotate(90deg);`}
`

const StyledAutocompleteMessage = styled(P)`
  font-size: ${FONT.SIZE.XS};
  color: ${COLOR.STATE.ERROR};
  margin-top: ${GTR.XS};
  margin-bottom: 0;
  max-width: ${getWidthOfContainerColumns(6, 'L')}px;
`

const StyledFormWrapper = styled(Wrapper)`
  display: flex;
  align-items: center;
`

const StyledTitleInputWrapper = styled(Wrapper)`
  margin-left: calc(${GTR.S} - ${GTR.XXS});
  flex-grow: 1;

  ${({ theme }) =>
    !theme.isExpanded &&
    `
    position: relative;

    &:after {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
    }
  `}
`

const StyledTitleInput = styled(AutosizeInput)`
  font-size: ${FONT.SIZE.S};
  background-color: transparent;
  outline: none;
  width: 100%;
  padding: 2px ${GTR.XXS};

  &:focus {
    background-color: ${COLOR.WHITE};
  }
`

const StyledSetDateButton = styled(Button.Secondary)`
  margin-bottom: ${GTR.XS};

  && {
    padding: ${GTR.XS} ${GTR.L};
  }
`

const StyledDatetimePicker = styled(DatetimePicker)`
  margin: -2px -2px 0 -2px;
`

const StyledRemoveDateButton = styled(Button.Underline)`
  font-size: ${FONT.SIZE.S};
`

const StyledEmailIconWrapper = styled(Wrapper)`
  display: none;

  ${({ theme }) => theme.isExpanded && `display: flex;`}
`

const StyledLockIcon = styled(LockIcon)`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: calc(-${GTR.S} + -14px);
`

const StyledSpecialCaseCheckbox = styled(UiCheckbox)`
  font-size: ${FONT.SIZE.S};
`

const EMAIL_MODAL_CONFIG = {
  scheduledEmailStart: {
    action: 'complete',
    component: TaskEmailModal,
    headingText: 'Schedule emails to send customer',
  },
  scheduledEmailStopOnComplete: {
    action: 'complete',
    component: TaskEmailStopModal,
    headingText: 'Stop scheduled emails',
  },
  immediateEmailAlreadySent: {
    action: 'complete',
    component: TaskEmailSentModal,
    headingText: 'Complete task',
  },
  immediateEmailToSend: {
    action: 'complete',
    component: TaskEmailModal,
    headingText: 'Choose email to send customer',
  },
  scheduledEmailStopOnUncomplete: {
    action: 'uncomplete',
    component: TaskEmailStopModal,
    headingText: 'Stop scheduled emails',
  },
  immediateEmailToSendAndScheduledEmailStart: {
    action: 'complete',
    component: TaskEmailModal,
    headingText: 'Send and schedule emails',
  },
  immediateEmailAlreadySentAndScheduleEmail: {
    action: 'complete',
    component: TaskEmailModal,
    headingText: 'Complete task and schedule emails',
  },
}

const getEmailModalConfig = (task) => {
  if (task.attributes.probateCase?.referredTo === NEW_LAW) return null

  const isComplete = !!task.attributes.completedAt

  if (
    !isComplete &&
    hasMultipleStartEmailTriggers(task) &&
    hasScheduledEmailTrigger(task) &&
    hasEmails(task)
  ) {
    return EMAIL_MODAL_CONFIG.immediateEmailAlreadySentAndScheduleEmail
  }

  if (
    !isComplete &&
    hasMultipleStartEmailTriggers(task) &&
    hasScheduledEmailTrigger(task)
  ) {
    return EMAIL_MODAL_CONFIG.immediateEmailToSendAndScheduledEmailStart
  }

  if (isComplete && hasScheduledEmail(task)) {
    return EMAIL_MODAL_CONFIG.scheduledEmailStopOnUncomplete
  }

  if (!isComplete && hasImmediateEmailTrigger(task) && !hasEmails(task)) {
    return EMAIL_MODAL_CONFIG.immediateEmailToSend
  }

  if (!isComplete && hasImmediateEmailTrigger(task) && hasEmails(task)) {
    return EMAIL_MODAL_CONFIG.immediateEmailAlreadySent
  }

  if (!isComplete && hasScheduledEmailTrigger(task)) {
    return EMAIL_MODAL_CONFIG.scheduledEmailStart
  }

  if (!isComplete && hasStopEmailTrigger(task)) {
    return EMAIL_MODAL_CONFIG.scheduledEmailStopOnComplete
  }

  return null
}

const TaskForm = ({ task, isTaskExpanded }) => {
  const dispatch = useDispatch()
  const { initialValues, values } = useFormikContext()
  const { actionTask, updateTask, product, fetchTasks } = useTasksContext()
  const { fetchTaskEvents } = useTaskEventsContext()
  const [showAutocompleteMessage, setShowAutocompleteMessage] = useState(false)
  const autocompleteMessage = getAutocompleteMessage(task)
  const isAutocomplete = !!autocompleteMessage
  const isCoreTask = !!task.attributes.coreTask
  const isComplete = !!task.attributes.completedAt
  const emailModalConfig = getEmailModalConfig(task)
  const hasEmailModal = !!emailModalConfig
  const isFuneralFactFindCompleted =
    task.attributes.coreTask === 'fact_find_completed' &&
    !isComplete &&
    !!task.attributes.funeralCaseId
  const isSendNPSEmail =
    task.attributes.coreTask === 'send_delighted_email' &&
    !isComplete &&
    !!task.attributes.funeralCaseId
  const stages = CORE_TASKS_IN_ORDER[product]
  const taskConfig = stages
    .reduce((acc, stage) => {
      return [...acc, ...stage.tasks]
    }, [])
    .find((config) => config.value === task.attributes.coreTask)

  const hasAutomatedDueDates =
    !!taskConfig?.automateDueDates && !task.attributes.completedAt

  const fetchEventsForTask = () => {
    if (!isTaskExpanded) return false

    return fetchTaskEvents({ queryParams: { 'filter[taskId]': task.id } })
  }

  const handleCheckboxChange = async ({ value }) => {
    if (isAutocomplete) return

    const action = value === 'true' ? 'complete' : 'uncomplete'

    await actionTask(task.id, action)
    await fetchEventsForTask()
  }

  const handleTaskUpdate = async ({ name, value }) => {
    const noChange = initialValues[name] === value?.trim()
    if (noChange) return false

    await updateTask(task.id, { [name]: value })
    await fetchEventsForTask()
    toast('Task updated.', {
      toastId: 'task-updated',
    })
  }

  const handleKeyPress = (e) => {
    if (e.which === 13) {
      e.target.blur()
      e.preventDefault()
    }
  }

  const showEmailModal = () => {
    dispatch(
      showModal({
        component: emailModalConfig.component,
        config: {
          actionTask: (values) =>
            actionTask(task.id, emailModalConfig.action, values),
          updateTask,
          task,
        },
        headingText: emailModalConfig.headingText,
      })
    )
  }

  const showFuneralFactFindCompletedModal = () => {
    dispatch(
      showModal({
        component: TaskCompleteGenericModal,
        config: {
          actionTask,
          task,
          toastText: 'Fact find marked as complete.',
          description: (
            <P>
              Marking the fact find as complete will create a customer folder in
              GDrive with form templates and add the customer to Airtable.
            </P>
          ),
        },
        headingText: 'Complete fact-find',
      })
    )
  }

  const showSendNPSEmailModal = () => {
    dispatch(
      showModal({
        component: TaskCompleteGenericModal,
        config: {
          actionTask,
          task,
          toastText: 'Task marked as complete.',
          description: (
            <>
              <P>
                Marking this task as complete will send a Delighted email to the
                customer.
              </P>
              <P>
                If this task has been completed previously, we will not send
                another Delighted email.
              </P>
            </>
          ),
        },
        headingText: 'Send Delighted email',
      })
    )
  }

  const showAutomatedDueDateModal = () => {
    const taskNames = taskConfig.automateDueDates
      .map((task) => `‘${lowerCase(task.coreTask)}’`)
      .join(' and ')

    dispatch(
      showModal({
        component: SetAutomatedDueDatesModal,
        config: { actionTask, task, taskConfig, fetchTasks, product },
        headingText: `Confirm ${taskNames} due date`,
      })
    )
  }

  const handleSpecialCases = (e) => {
    e.stopPropagation()
    if (isAutocomplete) setShowAutocompleteMessage(true)
    if (hasEmailModal && !isAutocomplete) showEmailModal()
    if (isFuneralFactFindCompleted) showFuneralFactFindCompletedModal()
    if (isSendNPSEmail) showSendNPSEmailModal()
    if (hasAutomatedDueDates) showAutomatedDueDateModal()
  }

  const isSpecialCase =
    isAutocomplete ||
    hasEmailModal ||
    isFuneralFactFindCompleted ||
    isSendNPSEmail ||
    hasAutomatedDueDates

  return (
    <StyledForm>
      <StyledFormWrapper>
        {isSpecialCase ? (
          <StyledSpecialCaseCheckbox
            name="isComplete"
            onChange={handleSpecialCases}
            label={task.attributes.title}
            checked={values.isComplete ? true : false}
            color={COLOR.STATE.SUCCESS}
          />
        ) : (
          <>
            <Checkbox
              color="green"
              name="isComplete"
              handleSave={handleCheckboxChange}
            />
            {isCoreTask ? (
              <StyledTaskTitle>{task.attributes.title}</StyledTaskTitle>
            ) : (
              <StyledTitleInputWrapper>
                <StyledTitleInput
                  name="title"
                  onBlur={(e) =>
                    handleTaskUpdate({ name: 'title', value: e.target.value })
                  }
                  placeholder="Task must have a title"
                  onKeyPress={handleKeyPress}
                  maxLength={100}
                />
              </StyledTitleInputWrapper>
            )}
          </>
        )}
      </StyledFormWrapper>
      <StyledStatusWrapper $isComplete={isComplete}>
        {isComplete && (
          <Dropdown
            alignment="left"
            updatedAt={task.attributes.updatedAt}
            trigger={
              <>
                Completed{' '}
                {moment(task.attributes.completedAt).format('D MMM YYYY')} by{' '}
                {task.attributes.completedBy.name}
              </>
            }
            content={
              <>
                <StyledDatetimePicker name="completedAt" />
                {values.completedAt && (
                  <Wrapper padding={GTR.S} centered>
                    <Button.Secondary
                      style={{ padding: `${GTR.XS} ${GTR.L}` }}
                      type="button"
                      onClick={() => {
                        const value = formatDateTimeToUTC(values.completedAt)
                        handleTaskUpdate({ name: 'completedAt', value })
                      }}
                    >
                      Set completed date
                    </Button.Secondary>
                  </Wrapper>
                )}
              </>
            }
          />
        )}
        {!isComplete && (
          <>
            <Dropdown
              updatedAt={task.attributes.updatedAt}
              trigger={
                <>
                  {nextTaskDueOnString(task.attributes.dueOn) && (
                    <DateBadge
                      date={nextTaskDueOnString(task.attributes.dueOn)}
                    />
                  )}
                  {!nextTaskDueOnString(task.attributes.dueOn) &&
                    'set due date'}
                </>
              }
              content={
                <>
                  <StyledDatetimePicker
                    name="dueOn"
                    type="date"
                    time={false}
                    hideClearButton
                  />
                  <Wrapper padding={GTR.S} centered>
                    <StyledSetDateButton
                      type="button"
                      onClick={() => {
                        const value = values.dueOn
                        handleTaskUpdate({ name: 'dueOn', value })
                      }}
                    >
                      Set due date
                    </StyledSetDateButton>
                    {task.attributes.dueOn && (
                      <StyledRemoveDateButton
                        type="button"
                        onClick={() => {
                          handleTaskUpdate({ name: 'dueOn', value: null })
                        }}
                      >
                        Remove due date
                      </StyledRemoveDateButton>
                    )}
                  </Wrapper>
                </>
              }
            />
            <StyledAssignmentWrapper>
              <TaskAssignment
                task={task}
                fetchEventsForTask={fetchEventsForTask}
              />
              <HelpNeededAssignment
                task={task}
                fetchEventsForTask={fetchEventsForTask}
              />
            </StyledAssignmentWrapper>
          </>
        )}
      </StyledStatusWrapper>
      {showAutocompleteMessage && (
        <StyledAutocompleteMessage>
          {autocompleteMessage}
        </StyledAutocompleteMessage>
      )}
    </StyledForm>
  )
}

const Task = ({ isPanelExpanded, task, productId, enableCommenting }) => {
  const [isTaskExpanded, setIsTaskExpanded] = useState(false)
  const [isHighlighted, setIsHighlighted] = useState(false)
  const location = useLocation()

  const showEmailIcon =
    hasStartEmailTrigger(task) &&
    task.attributes.probateCase?.referredTo !== NEW_LAW

  const initialValues = {
    isComplete: !!task.attributes.completedAt,
    dueOn: task.attributes.dueOn,
    title: task.attributes.title,
    currentAssigneeId: task.attributes.currentAssigneeId,
    currentHelperId: task.attributes.currentHelperId,
    completedAt: formatDateTimeToString(task.attributes.completedAt),
  }

  const addHighlight = () => {
    setIsHighlighted(true)
    setTimeout(() => setIsHighlighted(false), 3000)
  }

  useEffect(() => {
    const taskIdInHash = location.hash === `#task-${task.id}`
    if (taskIdInHash) addHighlight()
  }, [task.id, location.hash])

  return (
    <StyledListItem key={task.id} data-scroll-id={`task-${task.id}`}>
      <StyledTaskWrapper
        $isCoreTask={task.attributes.coreTask}
        $isComplete={task.attributes.completedAt}
        $isExpanded={isTaskExpanded}
        $isHighlighted={isHighlighted}
        onClick={() => {
          if (!isPanelExpanded) addHighlight()
        }}
      >
        {task.attributes.coreTask && isPanelExpanded && (
          <StyledLockIcon size="S" background={COLOR.BACKGROUND.SMOKE} />
        )}
        <Formik initialValues={initialValues} enableReinitialize>
          <TaskForm task={task} isTaskExpanded={isTaskExpanded} />
        </Formik>
        <StyledEmailIconWrapper>
          {showEmailIcon && <TaskEmail task={task} />}
        </StyledEmailIconWrapper>
        <StyledExpandButton
          type="button"
          onClick={() => setIsTaskExpanded(!isTaskExpanded)}
        >
          <StyledCommentsIcon
            color={
              task.attributes.comments?.length
                ? COLOR.GREY.DARK
                : COLOR.GREY.LIGHT
            }
          />
          <StyledChevronIcon
            size="S"
            $rotate={isTaskExpanded}
            color={COLOR.GREY.MEDIUM}
          />
        </StyledExpandButton>
      </StyledTaskWrapper>
      {isTaskExpanded && (
        <TaskActivity
          task={task}
          productId={productId}
          enableCommenting={enableCommenting}
        />
      )}
    </StyledListItem>
  )
}

Task.propTypes = {
  isPanelExpanded: PropTypes.bool.isRequired,
  task: PropTypes.object.isRequired,
  productId: PropTypes.object.isRequired,
  enableCommenting: PropTypes.bool.isRequired,
}

const TaskWithProvider = (props) => (
  <TaskEventsProvider>
    <Task {...props} />
  </TaskEventsProvider>
)

export default TaskWithProvider
