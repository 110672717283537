import find from 'lodash/find'
import { useQueryClient } from 'react-query'
import { P, Grid, Wrapper } from '@farewill/ui'

import InputFloating from 'components/form/input-floating'
import { Input } from 'components/form'
import CircularRadioGroup from 'components/form/circular-radio-group'
import MessageBox from 'components/message-box'
import { Constants } from 'lib/models/constants'
import { FUNERAL_PROVIDERS, SCATTER, YES_NO_OPTIONS } from 'utils/enums'
import {
  StringValueFormOption,
  formatAshesInstructionsOptions,
  formatUrnOptions,
} from 'utils/helpers'
import { SelectInput } from 'components/form'
import RadioGroup from 'components/form/radio-group'
import { FuneralAttributes } from 'lib/models/funeral'
import { use2024Form1 as use2024Form1Context } from 'routes/funerals/use2024Form1'

import { FormikValues, FuneralProps, SaveProp } from '../types'
import { getIndexFromUrnTypesFieldName } from './helpers'

const getUrnsQuestions = ({
  urnOptions,
  ashesSplitting,
}: {
  urnOptions: StringValueFormOption[]
  ashesSplitting: boolean
}) => {
  const urnTypeFirstQuestionLabel = ashesSplitting
    ? 'Which urn would they like for portion 1?'
    : 'Which urn would they like?'
  return [
    {
      name: 'urnTypes[0]',
      label: urnTypeFirstQuestionLabel,
      options: urnOptions,
      component: SelectInput,
    },

    {
      name: 'urnTypes[1]',
      label: 'Which urn would they like for portion 2?',
      options: urnOptions,
      showIf: (values: FormikValues) => values?.ashesSplitting === 'true',
      component: SelectInput,
    },
    {
      name: 'urnTypes[2]',
      label: 'Which urn would they like for portion 3?',
      options: urnOptions,
      showIf: (values: FormikValues) =>
        values?.ashesSplittingPortions && values.ashesSplittingPortions >= 3,
      component: SelectInput,
    },
    {
      name: 'urnTypes[3]',
      label: 'Which urn would they like for portion 4?',
      options: urnOptions,
      showIf: (values: FormikValues) =>
        values?.ashesSplittingPortions && values?.ashesSplittingPortions >= 4,
      component: SelectInput,
    },
  ]
}

const FuneralCremation = ({
  saveFuneralFields,
  handleSave,
  values,
}: FuneralProps): React.ReactElement => {
  const use2024Form1 = use2024Form1Context({ dateOfDeath: values.dateOfDeath })

  const provider: (typeof FUNERAL_PROVIDERS)[number] | undefined = find(
    FUNERAL_PROVIDERS,
    { value: values.provider }
  )

  const handleSetAshesInstruction = ({ value }: { value: string }) => {
    const resetAttributes =
      value === SCATTER
        ? [
            'urnType',
            'destinationCountry',
            'travelMethod',
            'urnTypes',
            'ashesSplitting',
          ]
        : []
    handleSave({ name: 'ashesInstruction', value }, resetAttributes)
  }

  const handleAshesSplittingSave = async ({ value }: { value: string }) => {
    // let resetAttributes: string[] = []

    if (value === 'false') {
      /* if ashesSplitting is changing to false and additional urns have been
         added, we need to remove the excess urns and just retain the first urn **/
      const urnTypes = values.urnTypes.length > 0 ? [values.urnTypes[0]] : []

      saveFuneralFields({
        ashesSplitting: false,
        ashesSplittingPortions: null,
        urnTypes,
      } as FuneralAttributes)
    } else {
      // ashesSplitting is changing to true, so set default value of 2 on ashesSplittingPortions
      saveFuneralFields({
        ashesSplitting: true,
        ashesSplittingPortions: 2,
      } as FuneralAttributes)
    }
  }

  /* if the number of urnTypes is greater than the ashes splitting portions, 
     we need to clear the excess urns from urnTypes and save the new urnTypes **/
  const handleAshesSplittingPortionsSave = async ({
    value,
  }: {
    value: string
  }) => {
    const ashesSplittingPortions = Number(value)

    const urnTypes = [...values.urnTypes]

    if (ashesSplittingPortions < values.urnTypes.length) {
      urnTypes.splice(ashesSplittingPortions)
    }
    saveFuneralFields({ ashesSplittingPortions, urnTypes } as FuneralAttributes)
  }

  const handleUrnsSave = async ({
    name,
    value,
  }: {
    name: string
    value: string
  }) => {
    const currentUrnTypes = [...values.urnTypes]
    const index = getIndexFromUrnTypesFieldName(name)

    if (!isNaN(index)) {
      currentUrnTypes[index] = value
    }

    /* As part of updating urnTypes, we should set urnType to null, 
    to ensure urnTypes is the single source of truth */
    const resetAttributes = ['urnType']
    await handleSave(
      {
        name: 'urnTypes',
        value: currentUrnTypes,
      },
      resetAttributes
    )
  }

  const isNotScatter = values.ashesInstruction !== SCATTER
  const isTravelUrn = isNotScatter && values.urnType === 'travel'
  const ashesSplitting = values.ashesSplitting === 'true'

  const cache = useQueryClient()
  const constants = cache.getQueryData('constants') as Constants
  const ashesInstructionsOptions = formatAshesInstructionsOptions({
    ashesDeliveryPrice: constants?.attributes?.ashesDeliveryPrice,
    ashesFastTrackDeliveryPrice:
      constants?.attributes?.ashesFastTrackDeliveryPrice,
  })
  const urnOptions = formatUrnOptions(constants?.attributes?.urnPrices)

  const urnsQuestions = getUrnsQuestions({
    urnOptions,
    ashesSplitting,
  })

  return (
    <>
      <Grid.Item>
        <P>
          Instructions to the company fulfilling the cremation (
          {provider?.label})
        </P>
      </Grid.Item>
      <Grid.Item>
        <CircularRadioGroup
          name="ashesInstruction"
          label="What should we do with the ashes?"
          options={ashesInstructionsOptions}
          handleSave={handleSetAshesInstruction}
          inline
        />
      </Grid.Item>
      <Grid.Item>
        <InputFloating
          name="ashesNotes"
          label="Additional notes for the handling of the ashes"
          hint="For example, who should the ashes be delivered to"
          handleSave={handleSave}
        />
      </Grid.Item>
      <Grid.Item>
        <InputFloating
          name="providerInstructions"
          label="Other notes for company fulfilling the cremation"
          hint="For example, if this is a COVID-19 case"
          handleSave={handleSave}
        />
      </Grid.Item>
      {isNotScatter && (
        <>
          <Grid.Item>
            <RadioGroup
              name="ashesSplitting"
              label="Would they need the ashes split in 2 or more portions?"
              options={YES_NO_OPTIONS}
              handleSave={handleAshesSplittingSave}
            />
          </Grid.Item>
          {ashesSplitting && (
            <Grid.Item>
              <Wrapper
                tag={Input}
                maxWidth="100px"
                name="ashesSplittingPortions"
                label="How many portions do they need the ashes to be split into?"
                options={YES_NO_OPTIONS}
                handleSave={handleAshesSplittingPortionsSave}
              />
            </Grid.Item>
          )}
          {urnsQuestions
            .filter(({ showIf }) => !showIf || showIf({ ...values }))
            .map((question) => {
              return (
                <Grid.Item key={question.name} span={10}>
                  <SelectInput
                    label={question.label}
                    name={question.name}
                    options={question.options}
                    handleSave={handleUrnsSave as unknown as SaveProp}
                  />
                </Grid.Item>
              )
            })}
        </>
      )}
      {isTravelUrn && (
        <>
          <Grid.Item span={6}>
            <InputFloating
              name="destinationCountry"
              label="Destination country of the urn"
              handleSave={handleSave}
            />
          </Grid.Item>
          <Grid.Item span={6}>
            <InputFloating
              name="travelMethod"
              label="How is the urn being transported?"
              component="select"
              options={[
                { label: 'Air', value: 'air' },
                { label: 'Boat', value: 'boat' },
                { label: 'Car', value: 'car' },
                { label: 'Train', value: 'train' },
              ]}
              handleSave={handleSave}
            />
          </Grid.Item>
        </>
      )}
      {use2024Form1 === undefined && (
        <Grid.Item spanFromM={10}>
          <MessageBox warning>
            We may need to ask if implanted or scrap metals should be returned
            after the cremation. Select the <strong>date of death</strong> for
            this funeral so we can determine which version of Form 1 paperwork
            to use.
          </MessageBox>
        </Grid.Item>
      )}
      {use2024Form1 === true && (
        <Grid.Item>
          <CircularRadioGroup
            name="shouldReturnMetalsPostCremation"
            label="Return implanted or scrap metals after the cremation?"
            options={[
              { label: 'Yes', value: 'true' },
              { label: 'No', value: 'false' },
            ]}
            handleSave={handleSave}
            inline
          />
        </Grid.Item>
      )}
    </>
  )
}

export default FuneralCremation
