import styled from 'styled-components'
import { toast } from 'react-toastify'

import { QuoteProps, QuoteItem } from 'lib/models/funerals/quote'
import { formatCurrency } from 'lib/formatting/currency'
import { formatToHuman, formatTreeName } from 'utils/helpers'

import { COLOR, GTR } from '@farewill/ui/tokens'
import { Button, P, Wrapper } from '@farewill/ui'
import Tooltip from 'components/tooltip'

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`

const TableBody = styled.tbody``

const TableHead = styled.thead``

const TableHeader = styled.th`
  text-align: left;
  border-bottom: 1pt solid ${COLOR.GREY.LIGHT};
  padding: ${GTR.XS} 0;
  white-space: nowrap;
`

const AmountHeader = styled(TableHeader)`
  text-align: right;
`

const TableRow = styled.tr``

const TableCell = styled.td`
  border-bottom: 1pt solid ${COLOR.GREY.LIGHT};
  padding: ${GTR.XS} 0;
  white-space: nowrap;
`

const AmountCell = styled(TableCell)`
  text-align: right;
  font-feature-settings: 'tnum';
`

const HiddenElements = styled.div`
  display: none;
`

const StyledTotalPriceContainer = styled.div`
  text-align: right;
`

const getQuoteAnswer = (quoteItem: QuoteItem) => {
  const { answer, description } = quoteItem
  if (answer === true) return 'Yes'
  if (answer === false) return 'No'
  if (answer === null || answer === '' || answer === undefined)
    return 'Not Answered'
  if (
    description === 'Location' ||
    description === 'Crematorium' ||
    description === 'Hearse Notes' ||
    description === 'Voucher'
  )
    return answer
  if (description === 'Time slot') {
    const timeSlot =
      answer === 'regular'
        ? 'During peak service hours'
        : 'Off-peak service hours'
    return timeSlot
  }
  if (description === 'Tree type') {
    return formatToHuman(formatTreeName(answer))
  }
  return formatToHuman(answer)
}

const QuoteRow = ({ item }: { item: QuoteItem }): JSX.Element => {
  return (
    <TableRow>
      <TableCell>
        {item.id === 'referred_to_coroner' ? (
          <Tooltip
            underlined
            content="If the person died in England or Wales, and was not referred to the coroner, then a doctor’s fee is applied – a standard charge by doctors for filling out paperwork to confirm the cause of death. This is not needed if the person died in Scotland, was referred to the coroner, or if the date of death is on/after 9/9/2024."
          >
            {item.description}
          </Tooltip>
        ) : (
          item.description
        )}
      </TableCell>
      <TableCell>{getQuoteAnswer(item)}</TableCell>
      <AmountCell>
        {item.cost
          ? formatCurrency({
              value: item.cost,
              valueInPence: true,
            })
          : '-'}
      </AmountCell>
    </TableRow>
  )
}

const QuoteSummary = ({ quote }: QuoteProps): JSX.Element => {
  return (
    <>
      <Wrapper id="lead-details">
        <HiddenElements>
          <h3>Farewill Quote</h3>
          <br />
        </HiddenElements>

        <Table>
          <TableHead>
            <TableRow>
              <TableHeader>
                <strong>Description</strong>
              </TableHeader>
              <TableHeader>
                <strong>Customer's choice</strong>
              </TableHeader>
              <AmountHeader>
                <strong>Amount</strong>
              </AmountHeader>
            </TableRow>
          </TableHead>
          <TableBody>
            {quote &&
              quote.items.map(
                (item: QuoteItem) =>
                  !item.hideInSummary && (
                    <QuoteRow item={item} key={item.description} />
                  )
              )}
          </TableBody>
        </Table>

        <StyledTotalPriceContainer>
          <P strong color={COLOR.GREY.DARK} margin={['S', 0, 0]}>
            Total quoted{' '}
            {quote &&
              formatCurrency({
                value: quote.totalCost || 0,
                valueInPence: true,
              })}
          </P>
        </StyledTotalPriceContainer>
      </Wrapper>
      <Wrapper separator>
        <Button.Secondary
          style={{ padding: `${GTR.XS} ${GTR.M}` }}
          type="button"
          onClick={() => {
            const text = document.getElementById('lead-details')?.innerHTML

            if (!text) {
              return alert('Oops! There is no quote to copy.')
            }

            function listener(e: ClipboardEvent) {
              e.clipboardData?.setData('text/html', text || '')
              e.clipboardData?.setData('text/plain', text || '')
              e.preventDefault()
            }

            document.addEventListener('copy', listener)
            document.execCommand('copy')
            document.removeEventListener('copy', listener)

            toast('Quote copied to clipboard.', {
              toastId: 'quote-copied-to-clipboard',
            })
          }}
        >
          Copy quote to clipboard
        </Button.Secondary>
      </Wrapper>
    </>
  )
}

export default QuoteSummary
