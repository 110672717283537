import { Address } from './address'
import { StringBoolean } from 'lib/types/common'

export const PROBATE_ESTATE_ITEM_TYPES = {
  BANK_ACCOUNT: 'bank_account',
  BUSINESS_OR_AGRICULTURAL_ASSET: 'business_or_agricultural_asset',
  CASH: 'cash',
  CREDIT_CARD: 'credit_card',
  INVESTMENT: 'investment',
  GIFT_GIVEN: 'gift_given',
  LIFE_INSURANCE: 'life_insurance',
  LOAN: 'loan',
  MONEY_OWED: 'money_owed',
  MORTGAGE: 'mortgage',
  NATIONAL_SAVINGS_AND_INVESTMENTS: 'national_savings_and_investments',
  OVERSEAS_ASSET: 'overseas_asset',
  PRIVATE_PENSION: 'private_pension',
  PUBLIC_SHAREHOLDING: 'public_shareholding',
  UTILITY_OR_OTHER_DEBT: 'utility_or_other_debt',
  VEHICLE_OR_POSSESSION: 'vehicle_or_possession',
  GIFT_BENEFITTED: 'gift_benefitted',
  TRUST_BENEFITTED: 'trust_benefitted',
} as const

export type ProbateEstateItemType =
  (typeof PROBATE_ESTATE_ITEM_TYPES)[keyof typeof PROBATE_ESTATE_ITEM_TYPES]

export type ProbateEstateAttributes = {
  createdAt: Date
  updatedAt: Date
  completedSections: string[]
}

export type ProbateEstate = {
  id: number
  attributes: ProbateEstateAttributes
}

export type ProbateEstateItemsAttributes = {
  itemType: ProbateEstateItemType
  probateEstateId: number
  probateEstatePropertyId: number
  description: string
  value: '' | number
  isEstimatedValue: boolean
  ownership: string
  jointOwnerName: string
  deceasedShareOfJointOwnership: number
  funeralHadPrePaidPlan: 'yes' | 'no' | 'unsure' | undefined
  funeralBillAmount: string
  funeralWakeAmount: string
  funeralFlowersAmount: string
  funeralOtherAmount: string
  funeralAmountCoveredByPlan: string
  funeralAmountNotes: string
  notes: string
  isDeleted: boolean
  providerName: string
  isInsurancePaidOut: boolean
  isLumpSumPensionPayout: boolean
  lumpSumPayableTo: string
  giftRecipient: string
  giftYear: number
  giftMonth: number
  giftDay: number
  probateEstate: ProbateEstate
}

export type ProbateEstateItem = {
  id: number
  type: 'probate_estate_items'
  attributes: Partial<ProbateEstateItemsAttributes>
}

export type ProbateEstatePeopleAttributes = {
  probateEstateId: number
  isCustomer: boolean
  isPointOfContact: boolean
  isPersonalRepresentative: boolean
  isIncludedInComms: boolean
  firstNames: string
  lastName: string
  preferredName: string
  nameMatchesWill: boolean
  nameMismatchNotes: string
  addressMatchesWill: boolean
  addressMismatchNotes: string
  phoneNumber: string
  email: string
  deceasedRelationshipToPerson: string
  dateOfBirth: string
  /**
   * @TODO The split date of birth fields will be dropped once any reporting
   * has been updated. Any changes to the old values will be copied
   * over to the new date_of_birth column via a PostgresQL trigger.
   */
  birthYear: number
  birthMonth: number
  birthDay: number
  personalRepresentativeRole: string
  isInsolvent: boolean
  authorizedToDealWithCase: boolean
  authorizedToBeIncludedInComms: boolean
  isDeleted: boolean
  addressId: number
  text: string
  customDeceasedRelationshipToPerson: string
  personalRepresentativeType: string
  isActingOnBehalfOfSomeoneElse: boolean
  powerOfAttorneyOrOrderType: string
  powerOfAttorneyRegistered: boolean
  applyingOnBehalfOf: string
  isActingOnBehalfOfSomeoneWithCapacityToAct: boolean
  probateEstate: ProbateEstate
  address: Address
  reference: string
}

export type ProbateEstatePerson = {
  id: number
  type: 'probate_estate_people'
  attributes: Partial<ProbateEstatePeopleAttributes>
}

export type ProbateEstatePropertiesAttributes = {
  itemType: string
  isMainResidence: StringBoolean
  deceasedShareOfJointOwnership: string
  registryCheckStatus: string
  value: string
  ownership: string
  jointOwnershipType: string
  hasCharges: StringBoolean
  deceasedShareOfChargesValue: '' | number
}

export type ProbateEstateProperty = {
  id: number
  type: 'probate_estate_properties'
  attributes: Partial<ProbateEstatePropertiesAttributes>
}
