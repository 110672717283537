import { Grid, P, Wrapper } from '@farewill/ui'
import SectionStatus from 'components/section-status'

const VulnerabilityCheck = (): React.ReactElement => {
  return (
    <>
      <Grid>
        <Grid.Item>
          <SectionStatus
            complete
            heading="Before you continue, have you checked for vulnerabilities?"
          >
            <P>
              Remember to keep an eye out for signs of vulnerability, those
              might include:
            </P>
            <Wrapper padding={[0, 0, 'S', 'L']}>
              <ul style={{ listStyleType: 'disc' }}>
                <li> Health (e.g. hearing or sight difficulties)</li>
                <li> Life events (e.g. bereavement)</li>
                <li> Resilience (e.g. low income)</li>
                <li> Capability (e.g. low financial understanding)</li>
              </ul>
            </Wrapper>

            <P>
              If you suspect a vulnerability, follow our vulnerable customer
              procedure.
            </P>
          </SectionStatus>
        </Grid.Item>
      </Grid>
    </>
  )
}

export default VulnerabilityCheck
