import { Divider, Grid } from '@farewill/ui'

import withForm from 'routes/lead/probate-form/with-form'
import CreditCards from './credit-cards'
import Utilities from './utilities'
import SectionHeading from '../section-heading'
import LegacyLeadMessageBox from '../legacy-lead-message-box'
import { useProbateEstate } from '../helpers/probate-estate-context'
import { SECTION_NAMES } from '../helpers/complete-sections'

const Liabilities = ({
  isComplete,
  checkIfComplete,
}: {
  isComplete: boolean
  checkIfComplete: (name: string) => boolean
}) => {
  const { probateEstateId } = useProbateEstate()
  return (
    <Grid>
      <SectionHeading
        title="7. Liabilities"
        scrollId="liabilities"
        isComplete={isComplete}
        hideIcon
      />
      {probateEstateId ? (
        <Grid.Item span={11} startColumn={2}>
          <CreditCards
            isComplete={checkIfComplete(
              SECTION_NAMES.CREDIT_CARDS_AND_STORECARDS
            )}
          />
          <Divider margin={['L', 0]} />
          <Utilities
            isComplete={checkIfComplete(
              SECTION_NAMES.UTILITIES_AND_OTHER_DEBTS
            )}
          />
        </Grid.Item>
      ) : (
        <Grid.Item startColumn={2}>
          <LegacyLeadMessageBox />
        </Grid.Item>
      )}
    </Grid>
  )
}

export default withForm(Liabilities)
