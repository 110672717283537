import styled from 'styled-components'
import { COLOR, GTR } from '@farewill/ui/tokens'

const TextSpacer = styled.span`
  color: ${COLOR.GREY.MEDIUM};
  margin: ${GTR.XS};

  &:before {
    content: '|';
  }
`

export default TextSpacer
