import Assets from './assets'
import { useProbateEstate } from '../helpers/probate-estate-context'

const AssetsWrapper = ({
  isComplete,
  checkIfComplete,
}: {
  isComplete: boolean
  checkIfComplete: (name: string) => boolean
}) => {
  const { probateEstate } = useProbateEstate()
  const initialValues = probateEstate.attributes

  return (
    <Assets
      initialValues={initialValues}
      isComplete={isComplete}
      checkIfComplete={checkIfComplete}
    />
  )
}

export default AssetsWrapper
