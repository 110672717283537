import PropTypes from 'prop-types'
import { Divider, Grid, H, P } from '@farewill/ui'
import { useFormikContext } from 'formik'

import { WIDOWED, NOT_ASKED_AND_UNSURE_OPTIONS } from 'utils/enums'
import { formatToHuman } from 'utils/helpers'

import { MARITAL_STATUS } from '../people/field-names'
import {
  CLAIM_TO_TRANSFER_NIL_RATE_BAND_NEEDED,
  SPOUSE_NOTES,
} from './field-names'

import ScrollTo from 'components/scroll-to'
import CircularRadioGroup from 'components/form/circular-radio-group'
import NotesInput from 'components/notes-input'
import withForm from '../components/with-form'
import SummaryBox from '../components/summary-box'
import Spouse from './spouse'
import Estate from './estate'

const SpousesEstate = ({ probateEstateId, saveProbateEstateField }) => {
  const { values } = useFormikContext()
  const isWidowed = values[MARITAL_STATUS] === WIDOWED
  const showSpouseQuestions =
    isWidowed &&
    ['yes', 'unsure'].includes(values[CLAIM_TO_TRANSFER_NIL_RATE_BAND_NEEDED])

  const summaryItems = [
    {
      label: 'Marital status at death',
      value: formatToHuman(values[MARITAL_STATUS]),
      section: '1.4',
    },
    {
      label: 'Gross estate based on information so far',
      // TODO: calculate total once we have assets
      value: null,
    },
    {
      label: 'Net estate based on information so far',
      // TODO: calculate total once we have assets
      value: null,
    },
  ]

  return (
    <Grid gap="XL" data-scroll-id="3">
      <Grid.Item span={8}>
        <Grid>
          <Grid.Item>
            <H size="M">3. Spouse’s estate (transfer of a nil-rate band)</H>
          </Grid.Item>
          {isWidowed ? (
            <>
              <Grid.Item>
                <P>
                  Any reference to marriage or spouse includes civil
                  partnerships
                </P>
              </Grid.Item>
              <Grid.Item>
                <SummaryBox items={summaryItems} />
              </Grid.Item>
              <Grid.Item>
                <P>
                  A transfer of a nil-rate band is important because if the net
                  estate is over the inheritance tax threshold, it could mean
                  there would be no tax to pay.
                </P>
              </Grid.Item>
              <Grid.Item>
                <CircularRadioGroup
                  label="Might there be a need to claim to transfer the first spouse’s nil-rate band?"
                  name={CLAIM_TO_TRANSFER_NIL_RATE_BAND_NEEDED}
                  options={NOT_ASKED_AND_UNSURE_OPTIONS}
                  handleSave={saveProbateEstateField}
                  inline
                />
              </Grid.Item>
            </>
          ) : (
            <>
              <Grid.Item>
                The marital status is not ‘widowed’, so this section doesn’t
                apply (see <ScrollTo scrollId="1.4">1.4</ScrollTo>)
              </Grid.Item>
              <Grid.Item>
                <NotesInput
                  name={SPOUSE_NOTES}
                  handleSave={saveProbateEstateField}
                />
              </Grid.Item>
            </>
          )}
        </Grid>
        {showSpouseQuestions && (
          <>
            <Divider margin={['L', 0]} />
            <Spouse saveProbateEstateField={saveProbateEstateField} />
            <Divider margin={['S', 0, 'L']} />
            <Estate
              probateEstateId={probateEstateId}
              saveProbateEstateField={saveProbateEstateField}
            />
          </>
        )}
      </Grid.Item>
    </Grid>
  )
}

SpousesEstate.propTypes = {
  probateEstateId: PropTypes.string.isRequired,
  saveProbateEstateField: PropTypes.func.isRequired,
}

export default withForm(SpousesEstate)
