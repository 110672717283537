/**
 * The design of the sales call means that we store residence information in
 * three separate fields. Unfortunately, it's not straightforward to decouple
 * the shape of the data structure from the sales call. This helper function
 * should always be used to get the lead's permanent residence, as the values
 * of `permanentResidence` or `residence` alone are not a guarantee of
 * permanent residence.
 */
export const getPermanentResidence = (quoteAttributes) => {
  if (quoteAttributes.residenceIsPermanentAddress === undefined) {
    return quoteAttributes.residence
  }

  return quoteAttributes.residenceIsPermanentAddress
    ? quoteAttributes.residence
    : quoteAttributes.permanentResidence
}

export const getTemporaryResidence = (quoteAttributes) =>
  quoteAttributes.residenceIsPermanentAddress ? null : quoteAttributes.residence
