import { Button, Divider, Grid, H, P, Wrapper } from '@farewill/ui'

import ENV from 'config/environment'
import { useLeadContext } from './context'
import { useVisibilityChange } from 'hooks/useVisibilityChange'
import { useEffect } from 'react'
import { LpaLeadAttributes, WillLeadAttributes } from 'lib/models/lead'
import MessageBox from 'components/message-box'
import { formatDate } from 'lib/formatting/dates'
import getWillBookingURL, { BookingType } from 'lib/will/get-will-booking-url'
import { showModal } from 'state/actions'
import store from 'state/create-store'
import BookFactFind from './book-fact-find'
import FindATimeModal from './find-a-time-modal'
import { useFormikContext } from 'formik'
import { FAREWILL_PRODUCTS } from 'utils/enums'
import { formatValuesForFormik } from 'utils/helpers'
import { formatAttributesForForm } from './will-form/helpers/form-utils'

const FindATime = ({
  calendlyUrl,
  handleTrustFactFind,
  showPropertyTrustFactFindBookingButton,
  setShowPropertyTrustFactFindBookingButton,
}: {
  calendlyUrl: string
  handleTrustFactFind: boolean
  showPropertyTrustFactFindBookingButton: boolean
  setShowPropertyTrustFactFindBookingButton: (
    isPropertyTrustFactFind: boolean
  ) => void
}) => {
  const { lead, fetchLead } = useLeadContext()
  const { handleSubmit, setFieldValue, setValues } = useFormikContext()
  const isTabVisible = useVisibilityChange()
  const {
    contact: { phoneNumber, email, firstName },
    customerCalls,
  } = lead.attributes as WillLeadAttributes | LpaLeadAttributes
  const factFindCall = customerCalls?.find(
    (call) => call.type === 'fact_find' && call.status === 'scheduled'
  )

  const handleAddFactFindCallAt = async () => {
    const lead = await fetchLead()
    const formattedValues = formatValuesForFormik(
      formatAttributesForForm(lead.data.attributes as WillLeadAttributes)
    )

    setValues(formattedValues)
  }

  // Sales team member is being redirected to account app to book a callback,
  // after the callback is booked, it updates the lead. We want to make sure that
  // when the user comes back to the lead page, the lead is updated and it displayes
  // the date of the callback.
  useEffect(() => {
    if (isTabVisible) {
      fetchLead()
    }
  }, [isTabVisible, fetchLead])

  // When the user books a fact find call, we want to update the form with the date
  // of the call, so that the user can safely convert the lead to a case.
  // This should only happen, when its a will lead and the user does not have
  // factFindCallAt date set.
  useEffect(() => {
    if (
      lead.attributes.product === FAREWILL_PRODUCTS.WILL &&
      lead.attributes.quoteAttributes.factFindCallAt === null &&
      factFindCall?.startTime
    ) {
      setFieldValue('quoteAttributes.factFindCallAt', factFindCall?.startTime)
      handleSubmit()
    }
  }, [
    factFindCall?.startTime,
    handleSubmit,
    lead.attributes.product,
    lead.attributes.quoteAttributes,
    setFieldValue,
  ])

  // As when booking a fact find we are interfering externally with the data of the form
  // in order to avoid edge cases, where the data is not updated, we are forcing a reload
  const handleModalOpen = () => {
    store.dispatch(
      showModal({
        component: FindATimeModal,
        headingText: 'Booking a fact find call',
        config: {
          confirmCallback: handleAddFactFindCallAt,
        },
      })
    )
  }

  return ENV.FF_WILLS_BOOKED_CALL_EMAILS_ENABLED ? (
    <>
      <Divider margin={['XL', 0]} />
      <H size="S" margin={[0, 0, 'M']}>
        {factFindCall
          ? 'Book a fact-find call'
          : showPropertyTrustFactFindBookingButton
          ? 'Book a fact-find call with a specific will writer'
          : `Book a fact-find call ${
              handleTrustFactFind ? 'via round robin' : ''
            }`.trim()}
      </H>

      <Grid>
        <Grid.Item span={10}>
          {factFindCall ? (
            <MessageBox success stretch>
              <H size="XS" margin={[0, 0, 'XS']}>
                Call booked for:
              </H>

              <P size="L" margin={0}>
                {formatDate(factFindCall.startTime, true)}
              </P>
              <P margin={['M', 0, 0]}>
                <a
                  href={getWillBookingURL({
                    type: BookingType.FACT_FIND_MANAGE,
                    leadUuid: lead.attributes.uuid,
                    calendlyEventId: factFindCall.calendlyEventId,
                    startTime: factFindCall.startTime as unknown as string,
                  })}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Update or cancel this call
                </a>
              </P>
            </MessageBox>
          ) : (
            <Wrapper>
              <P>
                Opens in a new tab. Return to this page when you’ve completed
                the booking.
              </P>
              <Button.Secondary
                onClick={handleModalOpen}
                href={getWillBookingURL({
                  type: BookingType.FACT_FIND_BOOKING,
                  leadUuid: lead.attributes.uuid,
                  phoneNumber,
                  email: email || '',
                  firstName: firstName || '',
                  calendlyUrl,
                })}
                target="_blank"
                rel="noopener noreferrer"
              >
                Open booking page
              </Button.Secondary>
              {/* User can only switch between the 2 booking options when handleTrustFactFind is true */}
              {handleTrustFactFind && (
                <P margin={['S', 0]}>
                  <Button.Underline
                    flush
                    onClick={() =>
                      setShowPropertyTrustFactFindBookingButton(
                        !showPropertyTrustFactFindBookingButton
                      )
                    }
                  >
                    {showPropertyTrustFactFindBookingButton
                      ? 'Switch to booking via round robin'
                      : 'Switch to booking a specific will writer'}
                  </Button.Underline>
                </P>
              )}
            </Wrapper>
          )}
        </Grid.Item>
      </Grid>
    </>
  ) : (
    <>
      <Divider margin={['XL', 0]} />
      <Wrapper>
        <H size="S" margin={[0, 0, 'M']}>
          Find a time
        </H>
        <Button.Secondary
          href={calendlyUrl}
          target="_blank"
          rel="noopener noreferrer"
        >
          Open Calendly
        </Button.Secondary>
      </Wrapper>
      <Divider margin={['XL', 0]} />
      <BookFactFind />
    </>
  )
}

export default FindATime
