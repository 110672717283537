import PropTypes from 'prop-types'

import styled from 'styled-components'

import { ArrowRightIcon, ArrowLeftIcon, Button } from '@farewill/ui'
import { COLOR, GTR } from '@farewill/ui/tokens'

const StyledContainer = styled.div`
  width: 100%;
  text-align: right;
`

const StyledButton = styled(Button.White)`
  border: 2px solid ${COLOR.GREY.LIGHT};
  margin: ${GTR.M} 0;
  padding: ${GTR.S} ${GTR.S};

  &:first-of-type {
    margin-right: ${GTR.S};
  }
`

const PageButtons = ({ disabled, cursors, setPage }) => (
  <StyledContainer>
    <StyledButton
      type="button"
      aria-label="Previous page"
      disabled={!cursors.pageStart}
      onClick={() => {
        setPage({ before: cursors.pageStart })
      }}
    >
      <ArrowLeftIcon size="S" />
    </StyledButton>

    <StyledButton
      type="button"
      aria-label="Next page"
      disabled={disabled || !cursors.pageEnd}
      onClick={() => {
        setPage({ after: cursors.pageEnd })
      }}
    >
      <ArrowRightIcon size="S" />
    </StyledButton>
  </StyledContainer>
)

PageButtons.propTypes = {
  setPage: PropTypes.func.isRequired,
  cursors: PropTypes.shape({
    pageStart: PropTypes.string,
    pageEnd: PropTypes.string,
  }),
}

PageButtons.defaultProps = {
  cursors: {},
  disabled: false,
}

export default PageButtons
