import { H, Grid } from '@farewill/ui'
import { useFormikContext } from 'formik'

import { FuneralAttributes } from 'lib/models/funeral'
import FuneralCremation from './funeral-cremation/funeral-cremation'
import { FormikValues, SaveProp } from './types'

const Funeral = ({
  handleSave,
  saveFuneralFields,
}: {
  handleSave: SaveProp
  saveFuneralFields: (attributes: FuneralAttributes) => void
}) => {
  const { values } = useFormikContext()

  return (
    <Grid>
      <Grid.Item>
        <H size="M">5. The funeral</H>
      </Grid.Item>

      <FuneralCremation
        handleSave={handleSave}
        values={values as FormikValues}
        saveFuneralFields={saveFuneralFields}
      />
    </Grid>
  )
}

export default Funeral
