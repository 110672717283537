export const SOLE_ASSETS =
  'Assets in sole name or share of assets held as tenants in common in the UK (not gifts in 7 years, trusts or overseas assets).'

export const SHARE_OF_JOINT_ASSETS =
  'Share of assets in joint names in the UK (not property held as tenants in common, gifts in 7 years, trusts or overseas assets).'

export const GROSS_ESTATE_NOT_REQUIRING_GRANT =
  'The total value of the deceased’s share of any assets (section 4) marked as joint, but only including property if ‘joint tenants’, plus the value of lifetime gifts (6.2), trusts (6.3) and overseas assets (4.10). IHT205 box A.'

export const DEBTS_PAYABLE_OUT_OF_JOINT_ASSETS =
  'The total value of the deceased’s share of joint debts in section 5 (not including funeral). IHT205 box B.'

export const NET_ESTATE_NOT_REQUIRING_GRANT =
  'Gross estate that does not require a grant (box A) minus debts payable out of joint assets (box B). IHT205 box C.'

export const GROSS_ESTATE_FOR_GRANT =
  'The total value of assets marked as sole (or with no option to own jointly), also including property if ‘tenants in common’ (section 4). IHT205 box D.'

export const SOLE_DEBTS =
  'The total value of ‘sole debt’ and funeral values in section 5. IHT205 box E.'

export const NET_ESTATE_FOR_GRANT =
  'Gross estate for the grant (box D) minus debts (sole) (box E). IHT205 box F.'

export const NET_ESTATE_FOR_INHERITANCE_TAX =
  'Net estate that does not require a grant (box C) plus net estate for the grant (box F). IHT205 box G.'

export const GROSS_ESTATE_FOR_INHERITANCE_TAX =
  'Gross estate that does not require a grant (box A) plus gross estate for the grant (box D). IHT205 box H.'
