import { ReactNode } from 'react'

import { WillLeadAttributes } from 'lib/models/lead'
import { Outcome } from 'routes/lead/types'
import { MORE_DETAILS_NEEDED } from 'routes/lead/form-helpers'

import { QuestionConfig } from '../types'
import { complexWillOutcome } from '../constants'

/**
 * Check if the question is answered. To find quoteAttribute that should be used
 * to check if the value was provided, we split the attributeName, and then shift it
 * to remove `quoteAttribute` part, as it's not needed.
 */
const isQuestionAnswered = (
  question: QuestionConfig,
  values: WillLeadAttributes
) => {
  const attributes = values.quoteAttributes || {}
  const attributeName = question.name.split('.')
  attributeName.shift()
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let result: any = attributes

  if (!attributeName) return result

  for (const segment of attributeName) {
    result = result[segment] // Access the nested property based on each segment
  }

  return !!result
}

type OutcomeForQuestionsType = {
  questions: QuestionConfig[]
  values: WillLeadAttributes
  outcomeForPersonalAndAssetQuestions?: Outcome
}

/**
 * Based on configuration of questions, some of them will have early outcome, that
 * doesn't allow to continue the questionare after them. This function checks if
 * any of the questions has early outcome, and if so, returns outcome of certain type.
 */
export const getOutcomeForQuestions = ({
  questions,
  values,
  outcomeForPersonalAndAssetQuestions,
}: OutcomeForQuestionsType): Outcome => {
  const earlyOutcome = questions.reduce<Outcome | undefined>(
    (result, Question) => result || Question.outcome?.(values),
    null
  )

  if (earlyOutcome) {
    return earlyOutcome
  }

  const allQuestionsAnswered = questions.every(
    (Question) =>
      isQuestionAnswered(Question, values) ||
      Question.isOptional?.(outcomeForPersonalAndAssetQuestions)
  )

  if (!allQuestionsAnswered) {
    return MORE_DETAILS_NEEDED
  }

  const reasonsForComplexWill: ReactNode[] = []
  questions.forEach((Question) => {
    const reason = Question.reasonForComplexWill?.(values)

    reason && reasonsForComplexWill.push(reason)
  })

  return complexWillOutcome({ reasons: reasonsForComplexWill })
}

export default getOutcomeForQuestions
