const FETCH_LEAD = 'FETCH_LEAD'
const UPDATE_LEAD = 'UPDATE_LEAD'

export default function contact(state = {}, action = {}) {
  switch (action.type) {
    case FETCH_LEAD + '_FULFILLED':
    case UPDATE_LEAD + '_FULFILLED':
      return { ...action.payload.data.data }

    case FETCH_LEAD + '_REJECTED':
    case 'UNSET_LEAD':
      return {}

    default:
      return state
  }
}
