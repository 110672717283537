import {
  SPOUSE_PROBATE_OBTAINED,
  SPOUSE_HAD_WILL,
  SPOUSE_LEFT_ALL_TO_DECEASED,
} from './field-names'

const isYesOrUnsure = (value) => {
  return ['yes', 'unsure'].includes(value)
}

export const getVisibleFields = ({ values }) => {
  const showSpouseGrantAndSpouseProbateRegistry = isYesOrUnsure(
    values[SPOUSE_PROBATE_OBTAINED]
  )

  const showSpouseLeftAllToDeceased = isYesOrUnsure(values[SPOUSE_HAD_WILL])

  const showSpouseWillAvailable =
    showSpouseLeftAllToDeceased &&
    isYesOrUnsure(values[SPOUSE_LEFT_ALL_TO_DECEASED])

  return {
    showSpouseGrantAndSpouseProbateRegistry,
    showSpouseLeftAllToDeceased,
    showSpouseWillAvailable,
  }
}
