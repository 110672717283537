import { P, Wrapper } from '@farewill/ui'
import { COLOR } from '@farewill/ui/tokens'
import styled from 'styled-components'

import Switch from 'components/switch'

import { useWillFactFindContext } from './context'

const StyledWrapper = styled(Wrapper)`
  display: flex;
`

const HighlightEmptySwitch = (): React.ReactElement => {
  const { highlightEmpty, setHighlightEmpty } = useWillFactFindContext()
  return (
    <StyledWrapper>
      <Switch
        color={COLOR.STATE.WARNING}
        handleToggle={() => setHighlightEmpty(!highlightEmpty)}
        isOn={highlightEmpty}
      />
      <P margin={[0, 0, 0, 'XS']}>Highlight missing details</P>
    </StyledWrapper>
  )
}

export default HighlightEmptySwitch
