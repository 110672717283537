import { ReactElement } from 'react'
import styled from 'styled-components'
import {
  ArrowUpIcon,
  PhoneSolidIcon,
  PencilIcon,
  PersonIcon,
  WarningIcon,
  Wrapper,
  PhoneMissedIcon,
  PhoneInboundIcon,
  PhoneOutboundIcon,
  InfoCircleIcon,
} from '@farewill/ui'
import { COLOR, GTR } from '@farewill/ui/tokens'

import AdminIcon from 'components/admin-icon'
import AircallIcon from 'components/aircall-event/aircall-icon'
import { AdminUserAttributes } from 'lib/models/admin-user'

const LEAD_EVENT_TYPE_IMAGES = {
  added: ArrowUpIcon,
  call_made: PhoneSolidIcon,
  contact_requested: PhoneSolidIcon,
  comment_added: PencilIcon,
  funeral_plan_guide_generated: InfoCircleIcon,
  funeral_plan_guide_posted: InfoCircleIcon,
  owner_changed: PersonIcon,
  objected_to_partner_data_sharing: WarningIcon,
}

const AircallLogo = styled(AircallIcon)`
  margin-right: ${GTR.M};
  flex-shrink: 0;
`

const IconsWrapper = styled(Wrapper)`
  display: flex;
  justify-content: center;
  align-items: top;
`

const EventIconWrapper = styled(Wrapper)`
  margin-left: -40px;
  margin-top: -8px;
  margin-right: ${GTR.S};
`

const StyledAircallEventIcon = styled(
  ({
    direction,
    isAnswered,
  }: {
    direction: 'inbound' | 'outbound'
    isAnswered: boolean
  }) => {
    const CallIcon = isAnswered
      ? direction === 'inbound'
        ? PhoneInboundIcon
        : PhoneOutboundIcon
      : PhoneMissedIcon
    return <CallIcon inline background={COLOR.ACCENT.PRIMARY_60} size="S" />
  }
)``

const StyledEventIcon = styled(
  ({
    type,
    inline,
  }: {
    type: keyof typeof LEAD_EVENT_TYPE_IMAGES
    inline: boolean
  }) => {
    const Component = LEAD_EVENT_TYPE_IMAGES[type] || PhoneSolidIcon
    return (
      <Component
        inline={inline}
        background={COLOR.ACCENT.PRIMARY_60}
        size="S"
      />
    )
  }
)``

const LeadEventIcon = ({
  type,
  adminUser,
  direction = 'inbound',
  isAnswered = false,
}: {
  type?: string
  adminUser?: AdminUserAttributes
  direction?: 'inbound' | 'outbound'
  isAnswered?: boolean
}): ReactElement => {
  return (
    <IconsWrapper>
      {adminUser ? <AdminIcon user={adminUser} size="L" /> : <AircallLogo />}
      <EventIconWrapper>
        {type ? (
          <StyledEventIcon
            inline
            type={type as keyof typeof LEAD_EVENT_TYPE_IMAGES}
          />
        ) : (
          <StyledAircallEventIcon
            direction={direction}
            isAnswered={isAnswered}
          />
        )}
      </EventIconWrapper>
    </IconsWrapper>
  )
}

export default LeadEventIcon
