import { useState } from 'react'
import { useParams } from 'react-router-dom'

import { FAREWILL_PRODUCT_IDS } from 'utils/enums'
import { useActivityContext } from './context'
import CommentForm from './comment-form'

const CaseCommentForm = ({ product }) => {
  const queryKey = FAREWILL_PRODUCT_IDS[product]
  const { createComment } = useActivityContext()
  const { id: productId } = useParams()
  const localStorageKey = `farewill_${product}_case_${productId}_activity_form_values`
  const [localStorageValues, setLocalStorageValues] = useState(
    window.localStorage.getItem(localStorageKey)
      ? JSON.parse(window.localStorage.getItem(localStorageKey))
      : undefined
  )

  const onSubmit = async (values) => {
    const attributes = { [queryKey]: productId, ...values }

    await createComment(attributes)

    window.localStorage.removeItem(localStorageKey)
    setLocalStorageValues(undefined)
  }

  const onBlur = (values) => {
    window.localStorage.setItem(localStorageKey, JSON.stringify(values))
    setLocalStorageValues(values)
  }

  return (
    <CommentForm
      handleSubmit={onSubmit}
      hasDraftState={!!localStorageValues?.content}
      placeholder="Write a comment..."
      onBlur={onBlur}
      initialValues={localStorageValues}
      showIsCallSwitch
    />
  )
}

export default CaseCommentForm
