import { Button, Wrapper } from '@farewill/ui'
import { BORDER, COLOR, GTR } from '@farewill/ui/tokens'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'

import { hideModal } from 'state/actions'
import { GiftBeneficiaryAttributes } from 'lib/models/gift-beneficiary'

import CharityDetails from './charity'
import OrganisationDetails from './organisation'

const StyledWrapper = styled(Wrapper)`
  background-color: ${COLOR.WHITE};
  padding: ${GTR.S};
  border-radius: ${BORDER.RADIUS.S};
  border: 1px solid ${COLOR.GREY.LIGHT};
  margin-bottom: ${GTR.S};
`

type Props = { config: { beneficiary: GiftBeneficiaryAttributes } }

const ViewBeneficiaryModal = ({
  config: { beneficiary },
}: Props): React.ReactElement => {
  const dispatch = useDispatch()

  return (
    <>
      <StyledWrapper>
        {beneficiary.charity && (
          <CharityDetails charity={beneficiary.charity} />
        )}
        {beneficiary.organisation && (
          <OrganisationDetails organisation={beneficiary.organisation} />
        )}
      </StyledWrapper>
      <Button.Primary
        stretch
        type="button"
        onClick={() => dispatch(hideModal())}
      >
        Close
      </Button.Primary>
    </>
  )
}

export default ViewBeneficiaryModal
