import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { Wrapper } from '@farewill/ui'
import { COLOR, GTR } from '@farewill/ui/tokens'

import { Contact } from 'lib/models/contact'

const StyledWrapper = styled(Wrapper)`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: ${GTR.L};
`

const StyledTable = styled.table`
  & td:first-child {
    padding-right: ${GTR.L};
  }
`

const CustomerNames = ({
  id,
  firstName,
  lastName,
  fullLegalName,
  preferredName,
  pronouns,
  disabled,
}: Partial<Contact> & { disabled?: boolean }): React.ReactElement => (
  <StyledWrapper padding="S" background={COLOR.ACCENT.SECONDARY_10}>
    <StyledTable>
      <tbody>
        <tr>
          <td>
            <strong>First and last name</strong>
          </td>
          <td>
            {firstName} {lastName}
          </td>
        </tr>

        <tr>
          <td>
            <strong>Preferred name (optional)</strong>
          </td>
          <td>{preferredName || '-'}</td>
        </tr>

        <tr>
          <td>
            <strong>Pronouns (optional)</strong>
          </td>
          <td>{pronouns || '-'}</td>
        </tr>

        <tr>
          <td>
            <strong>Full legal name</strong>
          </td>
          <td>{fullLegalName || '-'}</td>
        </tr>
      </tbody>
    </StyledTable>

    {!disabled && (
      <Link to={`/contacts/${id}/edit`}>
        <strong>Edit</strong>
      </Link>
    )}
  </StyledWrapper>
)

export default CustomerNames
