import { P } from '@farewill/ui'
import SelectInput from 'components/form/select-input'
import { SelectOptions } from 'lib/types/common'
import {
  AdminUserSelectOptions,
  NextCoreTaskSelectOptions,
} from 'routes/case-list/types'
import FilterWrapper from '../shared/filter-wrapper'

type Props = {
  product: string
  isMyCasesView: boolean
  adminUserOptions: AdminUserSelectOptions
  statusOptions: SelectOptions
  nextStageOptions: SelectOptions
  nextCoreTaskOptions: NextCoreTaskSelectOptions
}

const LpaFilters = ({
  isMyCasesView,
  adminUserOptions,
  nextStageOptions,
}: Props): React.ReactElement => (
  <>
    {!isMyCasesView && (
      <FilterWrapper>
        <P strong margin={[0, 0, 'XS']} size="S">
          Case owner
        </P>
        <SelectInput
          options={adminUserOptions}
          name="currentOwnerId"
          isMulti
          small
        />
      </FilterWrapper>
    )}

    {nextStageOptions && (
      <FilterWrapper>
        <P strong margin={[0, 0, 'XS']} size="S">
          Current stage
        </P>

        <SelectInput
          name="nextStage"
          isMulti
          options={nextStageOptions}
          small
        />
      </FilterWrapper>
    )}
  </>
)

export default LpaFilters
