import * as yup from 'yup'
import { WILL_BENEFICIARY_TYPE } from '../../constants'

export const GIFT_BENEFICIARY_SCHEMA = yup.object().shape({
  isManualEntry: yup.boolean().required(),
  beneficiaryType: yup.string().required(),
  charity: yup.object().when(['beneficiaryType', 'isManualEntry'], {
    is: (beneficiaryType: string, isManualEntry: boolean): boolean =>
      isManualEntry && beneficiaryType === WILL_BENEFICIARY_TYPE.CHARITY,
    then: yup
      .object()
      .shape({
        legalName: yup.string().required('Legal name is required.'),
      })
      .required(),
  }),
  organisation: yup.object().when(['beneficiaryType', 'isManualEntry'], {
    is: (beneficiaryType: string, isManualEntry: boolean): boolean =>
      isManualEntry && beneficiaryType === WILL_BENEFICIARY_TYPE.ORGANISATION,
    then: yup
      .object()
      .shape({
        name: yup.string().required('Name is required.'),
      })
      .required(),
  }),
})
