import React from 'react'
import { useDispatch } from 'react-redux'
import { Formik, Form } from 'formik'
import { Grid, P, Wrapper } from '@farewill/ui'

import EstateCard from 'components/estate-card'
import SelectInput from 'components/form/select-input'
import Input from 'components/form/input'
import { showModal } from 'state/actions'
import { formatDate } from 'lib/formatting/dates'
import { WillUpdate, WillUpdateReason } from 'lib/models/will-update'
import {
  RESOURCES,
  UseDeleteArrayResourceResult,
  UseUpdateArrayResourceResult,
} from 'api'

import DeleteWillUpdateModal from './delete-will-update.modal'
import { Selected } from 'components/form/select-input/types'

type WillUpdateReasonOptions = {
  label: string
  value: WillUpdateReason
}[]

const willUpdateReasonOptions: Readonly<WillUpdateReasonOptions> = [
  { label: 'Farewill error', value: 'farewill_error' },
  { label: 'Customer error', value: 'customer_error' },
  { label: 'Post office error', value: 'post_office_error' },
  { label: 'Print house error', value: 'print_house_error' },
  { label: 'Customer change of instructions', value: 'change_of_instructions' },
  { label: 'Other (describe in notes)', value: 'other' },
] as const

interface Props {
  willUpdate: WillUpdate
  listPosition: number
  listLength: number
  willUpdatesUpdateMutation: UseUpdateArrayResourceResult<WillUpdate>
  willUpdatesDeleteMutation: UseDeleteArrayResourceResult
}

const WillUpdateCard = ({
  willUpdate,
  listPosition,
  listLength,
  willUpdatesUpdateMutation,
  willUpdatesDeleteMutation,
}: Props): React.ReactElement => {
  const { reasons, notes, createdAt, adminUser } = willUpdate.attributes
  const dispatch = useDispatch()

  const updateWillUpdate = (data: { name: string; value: Selected }) => {
    willUpdatesUpdateMutation.mutate({
      id: willUpdate.id,
      attributes: { [data.name]: data.value },
      resource: RESOURCES.WILL_UPDATES,
    })
  }

  const deleteWillUpdate = () =>
    dispatch(
      showModal({
        component: DeleteWillUpdateModal,
        headingText: 'Delete will update',
        config: {
          willUpdateId: willUpdate.id,
          willUpdatesDeleteMutation,
        },
      })
    )

  return (
    <Wrapper margin={['L', 0, 0]} key={willUpdate.id}>
      <EstateCard
        type="update"
        listPosition={listPosition}
        listLength={listLength}
        onTrashClick={deleteWillUpdate}
      >
        <Formik
          enableReinitialize
          initialValues={{ reasons: reasons || [], notes: notes || '' }}
          onSubmit={() => undefined}
        >
          <Form>
            <Grid>
              <Grid.Item>
                <SelectInput
                  name="reasons"
                  label="Reason for will update"
                  isMulti
                  options={willUpdateReasonOptions}
                  handleSave={updateWillUpdate}
                />
              </Grid.Item>
              <Grid.Item>
                <Input
                  name="notes"
                  label="Notes"
                  component="textarea"
                  rows={3}
                  handleSave={updateWillUpdate}
                />
              </Grid.Item>
              <Grid.Item>
                <P>
                  Logged on {formatDate(createdAt)} by {adminUser.name}.
                </P>
              </Grid.Item>
            </Grid>
          </Form>
        </Formik>
      </EstateCard>
    </Wrapper>
  )
}

export default WillUpdateCard
