const FETCH_LPA_CASE = 'FETCH_LPA_CASE'
const UPDATE_LPA_CASE = 'UPDATE_LPA_CASE'

export default function lpaCase(state = {}, action = {}) {
  switch (action.type) {
    case 'UNSET_PRODUCT':
      return action.payload === 'lpaCase' ? {} : state

    case FETCH_LPA_CASE + '_FULFILLED':
    case UPDATE_LPA_CASE + '_FULFILLED':
      return action.payload.data.data

    case FETCH_LPA_CASE + '_PENDING':
    case FETCH_LPA_CASE + '_REJECTED':
      return {}

    default:
      return state
  }
}
