import { Button, ButtonGroup } from '@farewill/ui'
import { useDispatch } from 'react-redux'
import mapValues from 'lodash/mapValues'
import { Formik, Form } from 'formik'
import { useQueryClient } from 'react-query'

import { hideModal } from 'state/actions'
import { Charity, CharityAttributes } from 'lib/models/charity'
import { formatValuesForFormik } from 'utils/helpers'
import { RESOURCES, useUpdateResource } from 'api'
import MessageBox from 'components/message-box'

import CharityFormFields from '../forms/charity'

type Props = { config: { charity: CharityAttributes; willId?: number } }

const EditCharityModal = ({
  config: { charity, willId },
}: Props): React.ReactElement => {
  const cache = useQueryClient()
  const dispatch = useDispatch()
  const charityMutation = useUpdateResource<Charity>()

  const handleSubmit = async (values: CharityAttributes) => {
    const attributes = mapValues(values, (value) => value || null)
    await charityMutation.mutateAsync(
      {
        id: charity.id,
        attributes,
        resource: RESOURCES.CHARITIES,
      },
      {
        onSuccess: () => {
          /* In the future we may want to invalidate other queries that contain charity relations */
          if (willId) {
            const queryKey = [RESOURCES.GIFTS, `filter[willId]=${willId}`]
            cache.invalidateQueries(queryKey)
          }
        },
      }
    )
    dispatch(hideModal())
  }

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={formatValuesForFormik(charity)}
    >
      <Form>
        <MessageBox warning margin={[0, 0, 'M']}>
          Editing these details will update all the instances in the fact find
          form.
        </MessageBox>
        <CharityFormFields />
        <ButtonGroup margin={['M', 0, 0]}>
          <Button.Primary type="submit">Save</Button.Primary>
          <Button.Underline
            stretch
            type="button"
            onClick={() => dispatch(hideModal())}
          >
            Close
          </Button.Underline>
        </ButtonGroup>
      </Form>
    </Formik>
  )
}

export default EditCharityModal
