import { connect } from 'react-redux'
import { removeToken, logout } from '../../../state/actions'
import Footer from '../components'

const mapStateToProps = (state) => {
  return {
    isLoggedIn: !!state.token,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => {
      dispatch(logout())
      dispatch(removeToken())
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Footer)
