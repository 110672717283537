import { Fragment, useState, useEffect, useCallback } from 'react'
import ReactDOM from 'react-dom'
import styled, { css } from 'styled-components'
import { useHistory, useParams } from 'react-router-dom'
import { COLOR, FONT, GTR } from '@farewill/ui/tokens'
import useApi from 'lib/effects/api'
import { H } from '@farewill/ui'
import moment from 'moment'

const ZOOM_MULTIPLIER = 1.25
const ZOOM_MINIMUM = 0.512

const StyledOverlay = styled.div`
  position: fixed;
  background-color: rgba(0, 0, 0, 0.85);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 200;
  text-align: center;
`

const StyledButtonContainer = styled.div`
  display: flex;
  position: absolute;
  justify-content: flex-end;
  top: 0;
  right: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.85);
  z-index: 10;
`

const StyledButton = styled.button`
  font-size: ${FONT.SIZE.XL};
  width: 60px;
  height: 60px;
  line-height: 1;
  transition-duration: 0.15s;
  z-index: 10;
  color: ${({ disabled }) => (disabled ? COLOR.GREY.MEDIUM : COLOR.GREY.LIGHT)};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};

  &:last-of-type {
    margin-right: ${GTR.M};
  }

  ${({ disabled }) =>
    !disabled &&
    css`
      &:hover {
        &:before {
          transition-duration: 0.15s;
          display: inline-block;
          transform: scale(1.2);
        }

        color: ${COLOR.WHITE};
        background-color: ${COLOR.GREY.DARK};
      }
    `}
`

const StyledRotateButton = styled(StyledButton)`
  &:before {
    content: '↻';
    position: relative;
    top: -3px;
  }

  &:hover {
    &:before {
      transform: translate3d(1px, 0px, 0px) rotate(15deg);
    }
  }
`

const StyledZoomInButton = styled(StyledButton)`
  &:before {
    content: '+';
  }
`

const StyledZoomOutButton = styled(StyledButton)`
  &:before {
    content: '-';
  }
`

const StyledCloseButton = styled(StyledButton)`
  font-size: ${FONT.SIZE.M};

  &:before {
    font-weight: ${FONT.WEIGHT.BOLD};
    content: '✕';
  }
`

const StyledDocumentInfo = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  color: ${COLOR.WHITE};
  background-color: rgba(0, 0, 0, 0.85);
  padding: ${GTR.S} 0;

  display: flex;
  flex-direction: column;
  justify-content: center;
`

const StyledDocumentTitle = styled(H)`
  color: ${COLOR.WHITE};
  margin-bottom: 0;
`

const StyledDocumentDate = styled.div`
  font-size: ${FONT.SIZE.S};
`

const StyledDocumentLinks = styled.div`
  font-size: ${FONT.SIZE.S};
  display: flex;
  justify-content: center;

  & > :not(:last-child) {
    margin-right: ${GTR.M};
  }
`

const StyledPreviewContainer = styled.div`
  height: 100vh;
  overflow: scroll;
  margin: 0 ${GTR.XXL};

  display: flex;
  justify-content: center;
`

const StyledPreviewImage = styled.img`
  max-height: calc(100vh - 170px);
  max-width: 100%;
  margin-top: 60px;
  margin-bottom: 110px;
  align-self: center;
  cursor: grab;

  visibility: ${(props) => (props.loaded ? 'visible' : 'hidden')};

  transform: ${({ $zoom, $rotation, $position }) =>
    css`
      translate3d(${$position.x}px, ${$position.y}px, 0px)
      scale(${$zoom})
      rotate(${$rotation}deg)
    `};
`

const StyledNavigationButton = styled.button`
  position: absolute;
  ${(props) => (props.type === 'prev' ? 'left: 0' : 'right: 0')};

  width: ${GTR.XXL};
  height: 100%;
  font-size: 100px;
  color: hsla(0, 0%, 100%, 0.6);
  transition-duration: 0.15s;

  &:before {
    font-family: 'Arial';
    content: ${(props) => (props.type === 'prev' ? "'‹'" : "'›'")};
  }

  &:hover {
    color: ${COLOR.WHITE};
    font-size: 150px;
  }
`

const StyledPreviewImageWrapper = styled.div`
  display: flex;
  justify-content: space-around;
`

const StyledLoadingIndiciator = styled.div`
  color: white;
  height: 1em;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
`

const StyledPreviewFrame = styled.iframe`
  flex-grow: 1;
  margin-top: 60px;
  margin-bottom: 110px;
  border: 0;
`

const formatDate = (dateString) => {
  const date = moment(dateString)
  const isThisYear = date.year() === moment().year()

  return date.format(`Mo MMM ${isThisYear ? '' : 'YYYY '}[at] HH:mm`)
}

const PreviewOverlay = () => {
  const { id: documentId, probateCaseId } = useParams()
  const [{ data }, makeRequest] = useApi()
  const history = useHistory()
  const [fileIndex, setFileIndex] = useState(0)
  const [imageLoaded, setImageLoaded] = useState(false)
  const [zoom, setZoom] = useState(1)
  const [rotation, setRotation] = useState(0)
  const [position, setPosition] = useState({ x: 0, y: 0 })
  const [isMoving, setIsMoving] = useState(false)

  const files = data?.attributes?.files
  const file = data?.attributes?.files[fileIndex]

  const reset = useCallback(() => {
    setIsMoving(false)
    setZoom(1)
    setRotation(0)
    resetPosition()
  }, [])

  const handleNextFile = useCallback(() => {
    reset()
    setFileIndex(fileIndex >= files.length - 1 ? 0 : fileIndex + 1)
  }, [fileIndex, files, reset])

  const handlePrevFile = useCallback(() => {
    reset()
    setFileIndex(fileIndex <= 0 ? files.length - 1 : fileIndex - 1)
  }, [fileIndex, files, reset])

  const zoomIn = useCallback(() => {
    setZoom(zoom * ZOOM_MULTIPLIER)
  }, [zoom])

  const zoomOut = useCallback(() => {
    setZoom(Math.max(zoom / ZOOM_MULTIPLIER, ZOOM_MINIMUM))
  }, [zoom])

  const rotate = useCallback(() => {
    setZoom(1)
    resetPosition()
    setRotation(rotation + 90)
  }, [rotation])

  const handleClose = useCallback(() => {
    history.push(`/probate/cases/${probateCaseId}/documents`)
  }, [history, probateCaseId])

  const handleEscape = useCallback(() => {
    const shouldReset =
      zoom !== 1 || position.x || position.y || rotation % 360 !== 0

    if (shouldReset) {
      reset()
    } else {
      handleClose()
    }
  }, [handleClose, position.x, position.y, reset, rotation, zoom])

  const handleMouseMove = (e) => {
    if (isMoving) {
      setPosition({
        x: position.x + e.movementX,
        y: position.y + e.movementY,
      })
    }
  }

  const resetPosition = () => {
    setPosition({ x: 0, y: 0 })
  }

  const handleMouseDown = (e) => {
    setIsMoving(true)
    e.preventDefault()
  }

  const handleMouseUp = () => {
    setIsMoving(false)
  }

  const handleKeyUp = useCallback(
    (e) => {
      switch (e.key) {
        case 'Escape':
          handleEscape()
          break

        case 'ArrowLeft':
          handlePrevFile()
          break

        case 'ArrowRight':
          handleNextFile()
          break

        case '+':
        case '=':
          zoomIn()
          break

        case '-':
        case '_':
          zoomOut()
          break

        default:
          break
      }
    },
    [handleEscape, handleNextFile, handlePrevFile, zoomIn, zoomOut]
  )

  const handleImageLoaded = () => {
    setImageLoaded(true)
  }

  const showInPreviewFrame = file?.type === 'application/pdf'

  useEffect(() => {
    setImageLoaded(false)
    makeRequest({ url: `/api/documents/${documentId}` })
  }, [makeRequest, documentId, fileIndex])

  useEffect(() => {
    document.addEventListener('keyup', handleKeyUp)
    return () => document.removeEventListener('keyup', handleKeyUp)
  }, [handleKeyUp])

  return ReactDOM.createPortal(
    <Fragment>
      <StyledOverlay>
        <StyledButtonContainer>
          {!showInPreviewFrame && (
            <>
              <StyledRotateButton
                onClick={rotate}
                aria-label="Rotate clockwise"
                title="Rotate clockwise"
              />
              <StyledZoomInButton
                onClick={zoomIn}
                aria-label="Zoom in"
                title="Zoom in"
              />
              <StyledZoomOutButton
                onClick={zoomOut}
                aria-label="Zoom out"
                title="Zoom out"
                disabled={zoom === ZOOM_MINIMUM}
              />
            </>
          )}
          <StyledCloseButton
            onClick={handleClose}
            aria-label="Close preview"
            title="Close preview"
          />
        </StyledButtonContainer>

        <StyledPreviewContainer>
          {files?.length > 1 && (
            <StyledNavigationButton
              aria-label="Previous file"
              title="Previous file"
              type="prev"
              onClick={handlePrevFile}
            />
          )}
          {showInPreviewFrame ? (
            <StyledPreviewFrame src={file?.url} />
          ) : (
            <StyledPreviewImageWrapper>
              <StyledPreviewImage
                src={file?.url}
                onLoad={handleImageLoaded}
                loaded={imageLoaded}
                $zoom={zoom}
                $rotation={rotation}
                $position={position}
                onMouseDown={handleMouseDown}
                onMouseUp={handleMouseUp}
                onMouseLeave={handleMouseUp}
                onMouseMove={handleMouseMove}
              />
              {!imageLoaded && (
                <StyledLoadingIndiciator>Loading...</StyledLoadingIndiciator>
              )}
            </StyledPreviewImageWrapper>
          )}
          {files?.length > 1 && (
            <StyledNavigationButton
              type="next"
              aria-label="Next file"
              title="Next file"
              onClick={handleNextFile}
            />
          )}
        </StyledPreviewContainer>

        <StyledDocumentInfo>
          <StyledDocumentTitle size="S">
            {data?.attributes?.title}
          </StyledDocumentTitle>

          <StyledDocumentDate>
            Added {formatDate(data?.attributes?.createdAt)}
          </StyledDocumentDate>

          <StyledDocumentLinks>
            {file?.url && (
              <a href={file.url} target="_blank" rel="noopener noreferrer">
                Open in a new tab
              </a>
            )}
            {file?.downloadUrl && <a href={file.downloadUrl}>Download</a>}
          </StyledDocumentLinks>
        </StyledDocumentInfo>
      </StyledOverlay>
    </Fragment>,
    document.body
  )
}

export default PreviewOverlay
