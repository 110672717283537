import { ReactElement, useState } from 'react'
import { Formik, Form } from 'formik'
import styled from 'styled-components'
import * as yup from 'yup'
import { Button, Grid, Wrapper } from '@farewill/ui'
import { COLOR } from '@farewill/ui/tokens'

import CircularRadioGroup from 'components/form/circular-radio-group'
import PaymentFormFields from 'components/payment-form-fields'
import {
  invoiceReferenceValidation,
  paymentReferenceValidation,
} from 'lib/formik/schemata'
import { removeCommas } from 'utils/helpers'

const commonValidationSchema = yup.object({
  amount: yup
    .number()
    .transform(function (currentValue: string | number, originalValue) {
      return this.isType(currentValue)
        ? currentValue
        : Number(removeCommas(originalValue))
    })
    .min(0)
    .required('required'),
  date: yup.date().required('required'),
})

const paymentValidationSchema = commonValidationSchema.shape({
  reference: yup
    .string()
    .required('required')
    .test(...paymentReferenceValidation),
})

const invoiceValidationSchema = commonValidationSchema.shape({
  paidBy: yup
    .string()
    .oneOf(['dwp', 'other'], 'required')
    .required('required')
    .nullable(),
  reference: yup.string().required('required'),
  externalReferenceId: yup
    .string()
    .required('required')
    .test(...invoiceReferenceValidation),

  isPaid: yup.string().oneOf(['yes', 'no']).required('required'),
})

const DeleteButton = styled(Button.Bordered)`
  color: ${COLOR.STATE.ERROR};
  border-color: ${COLOR.STATE.ERROR};
`

type FormValues = {
  amount: number | ''
  date: string | null
  reference: string
  externalReferenceId: string
  isPaid: 'yes' | 'no'
  paidBy: 'dwp' | 'other'
}

export type FormInitialValues = FormValues & {
  amount: number | ''
  date: string | null
}

export type FormOutputValues = FormValues & {
  amount: number
  date: string
}

type FormType = 'card' | 'invoice'

type PaymentModalProps = {
  config: {
    type: FormType
    values: FormValues
    deletePayment?: () => Promise<void>
    savePayment: (data: FormValues) => Promise<void>
    hideModal: () => void
  }
}

type PaymentFormProps = {
  onDelete?: () => void
  isLoading: boolean
  type: FormType
}

const PaymentForm = ({
  onDelete,
  isLoading,
  type,
}: PaymentFormProps): ReactElement => {
  return (
    <Form>
      <Wrapper>
        <Grid>
          <PaymentFormFields type={type} />

          {type === 'invoice' && (
            <Grid.Item>
              <CircularRadioGroup
                label="Has the invoice been paid?"
                name="isPaid"
                inline
                options={[
                  { value: 'no', label: 'No' },
                  { value: 'yes', label: 'Yes' },
                ]}
              />
            </Grid.Item>
          )}

          <Grid.Item span={onDelete ? 6 : 12} margin={['M', 0, 0, 0]}>
            <Button.Primary type="submit" loading={isLoading} stretch>
              Save payment
            </Button.Primary>
          </Grid.Item>
          {onDelete && (
            <Grid.Item span={6} margin={['M', 0, 0, 0]}>
              <DeleteButton
                type="button"
                onClick={onDelete}
                loading={isLoading}
                stretch
              >
                Delete payment
              </DeleteButton>
            </Grid.Item>
          )}
        </Grid>
      </Wrapper>
    </Form>
  )
}

const PaymentModal = ({
  config: { values, deletePayment, savePayment, hideModal, type },
}: PaymentModalProps): ReactElement => {
  const initialValues = {
    squareTransactionUrl: '',
    ...values,
  }

  const [isLoading, setIsLoading] = useState(false)

  const onSubmit = async (values: FormValues) => {
    const amount = validationSchema.validateSyncAt('amount', values)

    if (typeof amount !== 'number') {
      return
    }

    try {
      setIsLoading(true)
      await savePayment({
        ...values,
        amount,
      })

      setIsLoading(false)
      hideModal && hideModal()
    } catch (error) {
      setIsLoading(false)
    }
  }

  const onDelete = deletePayment
    ? async () => {
        try {
          setIsLoading(true)
          await deletePayment()
          setIsLoading(false)
          hideModal && hideModal()
        } catch (error) {
          setIsLoading(false)
        }
      }
    : undefined

  const validationSchema =
    type === 'card' ? paymentValidationSchema : invoiceValidationSchema

  return (
    <Wrapper>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        <PaymentForm onDelete={onDelete} isLoading={isLoading} type={type} />
      </Formik>
    </Wrapper>
  )
}

export default PaymentModal
