import PropTypes from 'prop-types'
import { P, Grid } from '@farewill/ui'
import { useDispatch } from 'react-redux'
import { useFormikContext } from 'formik'
import get from 'lodash/get'

import { showModal } from 'state/actions'
import namespace from 'lib/formik/namespace'
import { getMomentDate } from 'lib/formik/dates'

import DateSplit from 'components/form/date-split'
import InputFloating from 'components/form/input-floating'
import NotesInput from 'components/notes-input'
import EstateCard from 'components/estate-card'
import MessageBox from 'components/message-box'

import { getIsOverEighteen } from '../../../helpers'
import DeleteModal from '../../components/delete-modal'
import withForm from '../../components/with-form'

import {
  BIRTH,
  FULL_NAME,
  NOTES,
  PARENTAL_RESPONSIBILITY_OF,
} from './field-names'

const OverEighteenWarning = () => (
  <Grid.Item span={7} style={{ display: 'flex', alignItems: 'flex-end' }}>
    <MessageBox warning>
      <P size="S">Only people under 18 should be added here.</P>
    </MessageBox>
  </Grid.Item>
)

const ChildBeneficiary = ({
  childBeneficiary,
  deleteChildBeneficiary,
  listLength,
  listPosition,
  updateChildBeneficiary,
}) => {
  const dispatch = useDispatch()
  const childBeneficiaryId = childBeneficiary.id
  const prefix = `childBeneficiary.${childBeneficiaryId}`
  const { withNamespace, withoutNamespace } = namespace(prefix + '.')
  const { values } = useFormikContext()

  const handleSave = ({ name, value }) => {
    const attributes = { [withoutNamespace(name)]: value || null }
    return updateChildBeneficiary(childBeneficiaryId, attributes)
  }

  const handleDeleteClick = () => {
    dispatch(
      showModal({
        component: DeleteModal,
        headingText: 'Delete person under 18',
        config: {
          deleteCallback: () => deleteChildBeneficiary(childBeneficiaryId),
          type: 'person under 18',
          resourceId: childBeneficiaryId,
        },
      })
    )
  }

  const isOverEighteen = getIsOverEighteen(
    getMomentDate({ prefix: BIRTH, values: get(values, prefix) })
  )

  return (
    <EstateCard
      onTrashClick={() => handleDeleteClick(childBeneficiaryId)}
      listLength={listLength}
      listPosition={listPosition}
      type="person under 18"
    >
      <Grid>
        <Grid.Item span={6}>
          <InputFloating
            name={withNamespace(FULL_NAME)}
            label="Full name"
            handleSave={handleSave}
          />
        </Grid.Item>
        <Grid.Item span={6}>
          <InputFloating
            name={withNamespace(PARENTAL_RESPONSIBILITY_OF)}
            label="Person with parental responsibility"
            handleSave={handleSave}
          />
        </Grid.Item>
        <Grid.Item span={5}>
          <DateSplit
            name={withNamespace(BIRTH)}
            label="Date of birth"
            handleSave={handleSave}
          />
        </Grid.Item>
        {isOverEighteen && <OverEighteenWarning />}
        <Grid.Item>
          <NotesInput name={withNamespace(NOTES)} handleSave={handleSave} />
        </Grid.Item>
      </Grid>
    </EstateCard>
  )
}

ChildBeneficiary.propTypes = {
  childBeneficiary: PropTypes.object.isRequired,
  deleteChildBeneficiary: PropTypes.func.isRequired,
  listLength: PropTypes.number.isRequired,
  listPosition: PropTypes.number.isRequired,
  updateChildBeneficiary: PropTypes.func.isRequired,
}

export default withForm(ChildBeneficiary)
