import { COLOR, FONT, GTR } from '@farewill/ui/tokens'
import ReactMarkdown from 'react-markdown'
import gfm from 'remark-gfm'
import styled from 'styled-components'

const StyledReactMarkdown = styled(ReactMarkdown)`
  margin-bottom: ${GTR.S};

  > :first-child {
    margin-top: 0;
  }

  > :last-child {
    margin-bottom: 0;
  }

  * {
    font-size: ${({ $fontSize }) => FONT.SIZE[$fontSize]};
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: ${GTR.S};
    color: ${COLOR.GREY.DARK};
  }

  ul,
  ol {
    padding-left: 20px;
  }

  ul {
    list-style: disc;
  }

  ol {
    list-style: decimal;
  }

  blockquote {
    margin: 0 0 ${GTR.S};
    padding-left: ${GTR.XS};
    border-left: 4px solid ${COLOR.GREY.LIGHT};
  }

  img {
    max-width: 100%;
  }

  p {
    white-space: pre-wrap;
  }
`

const CommentContent = ({ content, fontSize = 'S' }) => {
  const parts = content.split(/(@\[.+?]\([0-9]+\))/g)

  const markdownString = parts
    .map((part) => {
      const isMention = /@\[.+?]\([0-9]+\)/.test(part)
      if (!isMention) return part

      const name = part.match(/\[(.*)\]/)[1]
      return `**@${name}**`
    })
    .join('')

  return (
    <StyledReactMarkdown
      $fontSize={fontSize}
      plugins={[gfm]}
      skipHtml
      components={{
        a: (props) => (
          <a href={props.href} target="_blank" rel="noopener noreferrer">
            {props.children}
          </a>
        ),
      }}
    >
      {markdownString}
    </StyledReactMarkdown>
  )
}

export default CommentContent
