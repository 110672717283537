import { PartnerAccount } from 'lib/models/partner-account'

import Table from 'components/table'
import Row from './row'

const AccountsTable = ({
  accounts,
  isPartnerDataSharingEnabledPartner,
}: {
  accounts: PartnerAccount[]
  isPartnerDataSharingEnabledPartner: boolean
}): React.ReactElement => {
  if (!accounts || accounts.length === 0) {
    return <p>No Spotlight users linked to this partner.</p>
  }

  return (
    <Table striped>
      {accounts.map((account) => {
        return (
          <Row
            key={account.id}
            partnerAccount={account}
            isPartnerDataSharingEnabledPartner={
              isPartnerDataSharingEnabledPartner
            }
          />
        )
      })}
    </Table>
  )
}

export default AccountsTable
