import ReactDOM from 'react-dom'
import App from './App'
import * as serviceWorker from './serviceWorker'
import * as Sentry from '@sentry/react'
import ENV from 'config/environment'

if (ENV.APP_ENV === 'production' || ENV.APP_ENV === 'staging') {
  Sentry.init({
    ignoreErrors: ['ChunkLoadError'],
    dsn: ENV.SENTRY_DSN,
    environment: ENV.APP_ENV,
  })
}

ReactDOM.render(<App />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
