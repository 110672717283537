import { Wrapper } from '@farewill/ui'
import { GTR, COLOR } from '@farewill/ui/tokens'
import styled from 'styled-components'

const StyledLoadingRow = styled(Wrapper)`
  background-color: ${COLOR.WHITE};
  padding: ${GTR.L} ${GTR.XS};
  text-align: center;
`

export default StyledLoadingRow
