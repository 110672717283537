export const CANCELLED_REASONS = [
  { value: 'changed_mind', label: 'Changed mind' },
  { value: 'new_law_referral', label: 'New Law Referral' },
  {
    value: 'multi_jurisdiction_referral',
    label: 'Multi-jurisdiction referral',
  },
  {
    value: 'capacity_or_influence_concerns',
    label: 'Capacity concerns/influence concerns',
  },
  {
    value: 'other',
    label: 'Other',
  },
]

export const COLD_REASONS = [
  { value: 'customer_drop_off', label: 'Customer drop-off' },
  { value: 'other', label: 'Other' },
]
