import PropTypes from 'prop-types'
import styled from 'styled-components'

const StyledImage = styled.img`
  width: 48px;
  height: 48px;
  object-fit: cover;
  border-radius: 50%;
`

const BotIcon = ({ className }) => {
  return (
    <StyledImage
      src="/images/farewill-bot.png"
      role="presentation"
      className={className}
    />
  )
}

BotIcon.propTypes = {
  className: PropTypes.string,
}

BotIcon.defaultProps = {
  className: '',
}

export default BotIcon
