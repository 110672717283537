import { Formik, Form } from 'formik'
import { Button, ButtonGroup, Wrapper } from '@farewill/ui'
import { useDispatch } from 'react-redux'

import { hideModal } from 'state/actions'
import SelectInput from 'components/form/select-input'
import { WILL_FEATURE_OPTIONS } from 'utils/enums'
import { useUpdateResource, RESOURCES } from 'api'
import { WillAttributes } from 'lib/models/will'

type Props = {
  config: {
    willFeatures: string[] | null
    willId: number
  }
}

const WillFeaturesModal = ({
  config: { willFeatures, willId },
}: Props): React.ReactElement => {
  const dispatch = useDispatch()
  const willMutation = useUpdateResource()

  const handleSubmit = async (attributes: Partial<WillAttributes>) => {
    await willMutation.mutateAsync({
      id: willId,
      attributes,
      resource: RESOURCES.WILLS,
    })
    dispatch(hideModal())
  }

  return (
    <Formik
      initialValues={{ features: willFeatures || [] }}
      onSubmit={handleSubmit}
    >
      <Form>
        <Wrapper margin={[0, 0, 'M']}>
          <SelectInput name="features" options={WILL_FEATURE_OPTIONS} isMulti />
        </Wrapper>
        <ButtonGroup>
          <Button.Primary type="submit" loading={willMutation.isLoading}>
            Save
          </Button.Primary>
          <Button.Underline type="button" onClick={() => dispatch(hideModal())}>
            Cancel
          </Button.Underline>
        </ButtonGroup>
      </Form>
    </Formik>
  )
}

export default WillFeaturesModal
