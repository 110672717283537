import { ChangeEvent, ReactElement } from 'react'
import styled, { css } from 'styled-components'
import { useField, useFormikContext } from 'formik'
import { RadioGroup } from '@farewill/ui'
import { FONT, COLOR, GTR } from '@farewill/ui/tokens'
import type { MainControlProps } from '@farewill/ui/components/Form/common/Control/types'

import Error from './error'
import {
  FloatingHint,
  FloatingWrapper,
} from 'components/styled/helpers/floating'
import { getFieldState } from './helpers'

type Option = {
  label: string
  value: string
}

type CircularRadioGroupProps = {
  className?: string
  handleSave?: ({ name, value }: { name: string; value: string }) => void
  hint?: MainControlProps['hint']
  inline?: boolean
  label?: string | React.ReactElement
  name: string
  options: Readonly<Option[]>
  size?: 'S' | 'M'
}

const StyledRadioGroup = styled(RadioGroup)`
  & legend {
    font-weight: ${FONT.WEIGHT.REGULAR};
    color: unset;

    ${({ $hasError }) =>
      $hasError
        ? css`
            color: ${COLOR.STATE.ERROR};
          `
        : ''}
  }
`

const StyledFloatingHint = styled(FloatingHint)`
  margin: -${GTR.S} 0 ${GTR.M};
`

const CircularRadioGroup = ({
  label,
  handleSave,
  inline = false,
  name,
  options,
  size = 'M',
  className = '',
  hint,
}: CircularRadioGroupProps): ReactElement => {
  const { errors, touched, status, values, handleChange } = useFormikContext()
  const [{ value }, meta] = useField(name)
  const { hasHighlight } = getFieldState({
    formik: { errors, touched, status, values },
    name,
  })
  const hasError = meta.error && meta.touched

  return (
    <FloatingWrapper highlight={hasHighlight} className={className}>
      <StyledRadioGroup
        $hasError={hasError}
        name={name}
        legend={label}
        options={options.map((option) => ({ error: hasError, ...option }))}
        horizontal={inline}
        size={size}
        onChange={(event: ChangeEvent<HTMLInputElement>) => {
          handleChange(event)
          const value = event.target.value
          handleSave && handleSave({ name, value })
        }}
        value={value}
      />
      {hint && <StyledFloatingHint>{hint}</StyledFloatingHint>}
      <Error name={name} />
    </FloatingWrapper>
  )
}

export default CircularRadioGroup
