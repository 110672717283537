import { Grid, InfoCircleIcon, Wrapper } from '@farewill/ui'
import { useFormikContext } from 'formik'

import AddressInputWrapper from 'components/address'

import { Address } from 'lib/models/address'
import CustomerNames from 'components/customer-names'
import { FuneralPlanAttributes } from 'lib/models/funeral-plan'
import { Checkbox } from 'components/form'
import Tooltip from 'components/tooltip'
import { strToBool } from 'utils/helpers'
import { useSelector } from 'react-redux'
import { useEffect } from 'react'

const ContactDetails = (): React.ReactElement => {
  const { errors } = useSelector(
    (state: { errors: (string | { code: string; detail: string })[] }) => state
  )
  const { values, setFieldValue, handleSubmit } =
    useFormikContext<FuneralPlanAttributes>()

  // If the funeral plan has not been updated because the customer does not have an email address
  // reverse the checkbox click and submit the form again
  useEffect(() => {
    if (
      errors.find(
        (error) =>
          typeof error !== 'string' &&
          error.code === 'funeral_plan_not_updated_error'
      )
    ) {
      setFieldValue('isPostalService', 'true')
      handleSubmit()
    }
  }, [errors, handleSubmit, setFieldValue])

  return (
    <>
      <CustomerNames
        id={values?.purchaser?.id as number}
        firstName={values.purchaser?.firstName}
        lastName={values.purchaser?.lastName}
        fullLegalName={values.purchaser?.fullLegalName}
        preferredName={values.purchaser?.preferredName}
        pronouns={values.purchaser?.pronouns}
      />
      <Grid.Item spanFromL={6} margin={['M', 0, 0]}>
        <AddressInputWrapper
          data={
            values.purchaser?.addresses?.[
              values.purchaser.addresses.length - 1
            ] as Address
          }
          label="Address"
          name="purchaser.addresses"
          newAddressAttrs={{
            relatedResource: 'contact',
            relatedId: values.purchaser?.id as number,
            relation: 'addresses',
          }}
        />
        <Wrapper margin={['M', 0, 0]}>
          <Checkbox
            handleSave={() => handleSubmit()}
            label={
              <>
                <span>Customer requested postal service</span>
                <Tooltip content="Check this if the customer does not have an email address and wants all communication to be sent via post">
                  <Wrapper
                    padding={[0, 0, 0, 'XS']}
                    as={InfoCircleIcon}
                    size="S"
                  />
                </Tooltip>
              </>
            }
            name="isPostalService"
            checked={strToBool(values.isPostalService)}
          />
        </Wrapper>
      </Grid.Item>
    </>
  )
}

export default ContactDetails
