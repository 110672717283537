import PropTypes from 'prop-types'
import { Divider, H, Grid } from '@farewill/ui'

import withForm from '../components/with-form'
import CustomersAndPointsOfContact from './customers-and-points-of-contact'
import NameOfPersonWhosDied from './name-of-person-whos-died'
import IdentifyingPersonWhosDied from './identifying-person-whos-died'
import MaritalStatusOfPersonWhosDied from './marital-status-of-person-whos-died'
import ChildrenOfThePersonWhosDied from './children-of-the-person-whos-died'

const People = ({
  probateCase,
  probateEstateId,
  saveProbateCaseField,
  saveProbateEstateField,
}) => (
  <Grid gap="XL" data-scroll-id="1">
    <Grid.Item span={8}>
      <H size="M">1. People</H>
      <CustomersAndPointsOfContact
        saveProbateEstateField={saveProbateEstateField}
        probateEstateId={probateEstateId}
      />
      <Divider margin={['L', 0]} />
      <NameOfPersonWhosDied
        probateCase={probateCase}
        probateEstateId={probateEstateId}
        saveProbateCaseField={saveProbateCaseField}
        saveProbateEstateField={saveProbateEstateField}
      />
      <Divider margin={['S', 0, 'L']} />
      <IdentifyingPersonWhosDied
        saveProbateEstateField={saveProbateEstateField}
      />
      <Divider margin={['S', 0, 'L']} />
      <MaritalStatusOfPersonWhosDied
        saveProbateEstateField={saveProbateEstateField}
      />
      <Divider margin={['S', 0, 'L']} />
      <ChildrenOfThePersonWhosDied
        probateEstateId={probateEstateId}
        saveProbateEstateField={saveProbateEstateField}
      />
    </Grid.Item>
  </Grid>
)

People.propTypes = {
  probateCase: PropTypes.object.isRequired,
  probateEstateId: PropTypes.string.isRequired,
  saveProbateCaseField: PropTypes.func.isRequired,
  saveProbateEstateField: PropTypes.func.isRequired,
}

export default withForm(People)
