import { useMemo, useState, useCallback, useEffect, useRef } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { COLOR, FONT, GTR } from '@farewill/ui/tokens'
import moment from 'moment'
import Tippy from '@tippy.js/react'

const StyledSpan = styled.span`
  color: ${COLOR.GREY.MEDIUM};
  font-size: ${FONT.SIZE.S};
`

const StyledTimeTooltip = styled(Tippy)`
  font-size: ${FONT.SIZE.XXS};
  padding: 0;
`

const DATE_FORMAT = 'D MMM YYYY'
const DATETIME_FORMAT = 'D MMM YYYY, HH:mm'

const formatTime = (datetime, isWithinPastHour) => {
  return isWithinPastHour
    ? datetime.fromNow()
    : datetime.format(DATETIME_FORMAT)
}

const ActivityTimestamp = ({ datetime, isDate, isEdited, className }) => {
  const momentDatetime = useMemo(() => moment(datetime), [datetime])
  const isWithinPastHour = moment().diff(momentDatetime, 'hours') < 1

  const [, setStateForRefresh] = useState()
  const refreshCallback = useRef()

  const scheduleRefresh = useCallback(() => {
    if (isWithinPastHour) {
      refreshCallback.current = setTimeout(() => {
        setStateForRefresh({})
        scheduleRefresh()
      }, 30000)
    }
  }, [isWithinPastHour])

  useEffect(() => {
    scheduleRefresh()
    return () => {
      clearTimeout(refreshCallback.current)
    }
  }, [scheduleRefresh])

  return (
    <StyledTimeTooltip
      arrow
      delay={[350, 0]}
      duration={150}
      distance={GTR.XS}
      enabled={isWithinPastHour}
      appendTo={document.body}
      placement="bottom"
      content={momentDatetime.format('DD MMM YYYY, HH:mm')}
    >
      <StyledSpan className={className}>
        {isDate
          ? momentDatetime.format(DATE_FORMAT)
          : formatTime(momentDatetime, isWithinPastHour)}
        {isEdited ? ' (edited)' : ''}
      </StyledSpan>
    </StyledTimeTooltip>
  )
}

ActivityTimestamp.propTypes = {
  className: PropTypes.string,
  datetime: PropTypes.string.isRequired,
  isDate: PropTypes.bool,
  isEdited: PropTypes.bool,
}

ActivityTimestamp.defaultProps = {
  isDate: false,
  isEdited: false,
  className: '',
}

export default ActivityTimestamp
