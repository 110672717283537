import { Button, ButtonGroup, P, Wrapper } from '@farewill/ui'
import React from 'react'
import { useDispatch } from 'react-redux'

import { RESOURCES, UseDeleteArrayResourceResult } from 'api'
import { hideModal } from 'state/actions'

type Props = {
  config: { giftId: number; giftsDeleteMutation: UseDeleteArrayResourceResult }
}

const DeleteGiftModal = ({
  config: { giftId, giftsDeleteMutation },
}: Props): React.ReactElement => {
  const dispatch = useDispatch()

  const deleteGift = async () => {
    await giftsDeleteMutation.mutateAsync({
      id: Number(giftId),
      resource: RESOURCES.GIFTS,
    })
    dispatch(hideModal())
  }

  return (
    <Wrapper>
      <P>
        Are you sure you want to delete this gift? All details will be lost.
      </P>
      <ButtonGroup horizontal>
        <Button.Primary onClick={() => deleteGift()}>
          Delete gift
        </Button.Primary>
        <Button.Bordered onClick={() => dispatch(hideModal())}>
          Cancel
        </Button.Bordered>
      </ButtonGroup>
    </Wrapper>
  )
}

export default DeleteGiftModal
