import { Link } from 'react-router-dom'

import Input from 'components/form/input'

type Props = {
  contactId: number
  hasRelatedAccount: boolean
  handleSave: ({ name, value }: { name: string; value: string }) => void
  inputName?: string
  disabled?: boolean
  allowCopyToClipboard?: boolean
}

const ContactEmailInput = ({
  contactId,
  hasRelatedAccount,
  inputName = 'email',
  handleSave,
  disabled,
  allowCopyToClipboard,
}: Props): React.ReactElement => {
  const hint = hasRelatedAccount ? (
    <>
      Edit email in{' '}
      <Link to={`/contacts/${contactId}/account`}>Account details</Link>
    </>
  ) : undefined

  return (
    <Input
      type="email"
      label="Email"
      name={inputName}
      handleSave={handleSave}
      disabled={disabled || hasRelatedAccount}
      hint={hint}
      allowCopyToClipboard={allowCopyToClipboard}
    />
  )
}

export default ContactEmailInput
