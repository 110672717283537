import styled from 'styled-components'
import { FONT } from '@farewill/ui/tokens'

const StyledSortableHeader = styled.li`
  display: flex;
  align-items: center;
  font-size: ${FONT.SIZE.S};

  &:hover,
  &:focus {
    cursor: pointer;
  }
`

export default StyledSortableHeader
