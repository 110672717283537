import { ProbateEstateItem } from 'lib/models/probate-estate'
import { UseSectionsCompleteProps } from '.'
import { RequiredArray } from './types'
import { strToBool } from 'utils/helpers'

const checkIsStandardLiabilitySectionComplete = (
  items: ProbateEstateItem[] = []
) => {
  const propertiesFiltered = items.filter((item) => {
    const { value, ownership, deceasedShareOfJointOwnership } = item.attributes
    const requiredFieldsForJointOwnership =
      ownership === 'joint' ? [deceasedShareOfJointOwnership] : []

    const requiredFields = [value, ownership].concat(
      requiredFieldsForJointOwnership
    )

    return !(
      requiredFields.find((field) => field === '' || field === null) ===
      undefined
    )
  })

  return propertiesFiltered.length === 0
}

export const checkIsFuneralCostsSectionComplete = ({
  values,
}: Partial<UseSectionsCompleteProps>) => {
  if (!values) return false

  const {
    hasMadeFuneralArrangements,
    funeralCostKnown,
    funeralBillAmount,
    funeralHadPrePaidPlan,
    funeralAmountCoveredByPlan,
  } = values.quoteAttributes
  const requiredFieldsForFuneralCostKnown: RequiredArray =
    strToBool(funeralCostKnown) && strToBool(hasMadeFuneralArrangements)
      ? [funeralBillAmount, funeralHadPrePaidPlan]
      : []
  const requiredFieldsForFuneralPlanPrePaid: RequiredArray =
    strToBool(funeralCostKnown) && funeralHadPrePaidPlan === 'yes'
      ? [funeralAmountCoveredByPlan]
      : []

  const requiredFieldForFuneralArrangements: RequiredArray = strToBool(
    hasMadeFuneralArrangements
  )
    ? [funeralCostKnown]
    : []

  const requiredFields: RequiredArray = [hasMadeFuneralArrangements]

  const requiredFieldsCombined: RequiredArray = requiredFields.concat(
    requiredFieldForFuneralArrangements,
    requiredFieldsForFuneralCostKnown,
    requiredFieldsForFuneralPlanPrePaid
  )

  return (
    requiredFieldsCombined.find((field) => field === '' || field === null) ===
    undefined
  )
}

export const checkIsCreditCardsComplete = ({
  groupedItems,
}: Partial<UseSectionsCompleteProps>) => {
  return checkIsStandardLiabilitySectionComplete(groupedItems?.creditCard)
}

export const checkIsUtilitiesComplete = ({
  groupedItems,
}: Partial<UseSectionsCompleteProps>) => {
  return checkIsStandardLiabilitySectionComplete(
    groupedItems?.utilityOrOtherDebt
  )
}

export const checkIsLiabilitiesComplete = ({
  values,
  groupedItems,
}: UseSectionsCompleteProps) => {
  const creditCardsComplete = checkIsCreditCardsComplete({ groupedItems })
  if (!creditCardsComplete) return false

  const utilitiesComplete = checkIsUtilitiesComplete({ groupedItems })
  if (!utilitiesComplete) return false

  const funeralCostsComplete = checkIsFuneralCostsSectionComplete({ values })
  if (!funeralCostsComplete) return false

  return true
}
