import PropTypes from 'prop-types'
import { H, Grid, P, Wrapper } from '@farewill/ui'
import { useFormikContext } from 'formik'
import { Link } from 'react-router-dom'

import { getMomentDate } from 'lib/formik/dates'
import { WIDOWED } from 'utils/enums'

import Tooltip from 'components/tooltip'
import NotesInput from 'components/notes-input'
import withForm from '../../fact-find/components/with-form'

import { getThreshold } from '../inheritance-tax-thresholds'
import DateOfDeathChecks from './date-of-death-checks'
import EstateValueChecks from './estate-value-checks'
import GiftsAndAssetsChecks from './gifts-and-assets-checks'
import SpouseChecks from './spouse-checks'

import { CLAIM_TO_TRANSFER_NIL_RATE_BAND_NOTES } from '../field-names'
import {
  MARITAL_STATUS,
  SPOUSE_DEATH,
} from '../../fact-find/people/field-names'
import { CLAIM_TO_TRANSFER_NIL_RATE_BAND_NEEDED } from '../../fact-find/spouses-estate/field-names'

const SPOUSE_IHT_TOOLTIP = (
  <P size="S" margin={0}>
    Calculated using:{' '}
    <a
      href="https://www.gov.uk/government/publications/rates-and-allowances-inheritance-tax-thresholds-and-interest-rates/inheritance-tax-thresholds-and-interest-rates"
      target="_blank"
      rel="noopener noreferrer"
    >
      this table
    </a>
    .
  </P>
)

const ClaimToTransferNilRateBand = ({
  probateEstateId,
  saveProbateEstateField,
}) => {
  const { values } = useFormikContext()
  const factFindUrl = `/probate/estates/${probateEstateId}/fact-find`

  const spouseDateOfDeath = getMomentDate({ prefix: SPOUSE_DEATH, values })
  const spouseInheritanceTaxThreshold = getThreshold(spouseDateOfDeath)

  const isWidowed = values[MARITAL_STATUS] === WIDOWED
  const claimToTransferNRBNeeded =
    values[CLAIM_TO_TRANSFER_NIL_RATE_BAND_NEEDED] === 'yes'

  const showQuestions = isWidowed && claimToTransferNRBNeeded

  return (
    <Grid gap="XL">
      <Grid.Item span={8}>
        <H size="S" margin={[0, 0, 'M']}>
          5. Claim to transfer a nil-rate band
        </H>
        {showQuestions && (
          <>
            <Grid gap={['L', 'M']} margin={[0, 0, 'M']}>
              <Grid.Item>
                <DateOfDeathChecks
                  factFindUrl={factFindUrl}
                  saveProbateEstateField={saveProbateEstateField}
                />
              </Grid.Item>
              <Grid.Item>
                <EstateValueChecks
                  probateEstateId={probateEstateId}
                  saveProbateEstateField={saveProbateEstateField}
                />
              </Grid.Item>
              <Grid.Item>
                <GiftsAndAssetsChecks
                  factFindUrl={factFindUrl}
                  probateEstateId={probateEstateId}
                  saveProbateEstateField={saveProbateEstateField}
                />
              </Grid.Item>
              <Grid.Item>
                <SpouseChecks
                  factFindUrl={factFindUrl}
                  saveProbateEstateField={saveProbateEstateField}
                />
              </Grid.Item>
            </Grid>
            <Wrapper margin={[0, 0, 'M']}>
              <P>
                Inheritance tax threshold at spouse’s death:{' '}
                <Tooltip content={SPOUSE_IHT_TOOLTIP}>
                  {spouseInheritanceTaxThreshold}
                </Tooltip>
              </P>
              <P>
                If you have further information about the transfer of the
                nil-rate band, add it to the notes below.
              </P>
            </Wrapper>
            <NotesInput
              name={CLAIM_TO_TRANSFER_NIL_RATE_BAND_NOTES}
              handleSave={saveProbateEstateField}
            />
          </>
        )}
        {!showQuestions && (
          <>
            <P>
              Not applicable because{' '}
              {!isWidowed ? (
                <>
                  marital status is not widowed (
                  <Link to={factFindUrl + '#1.4'}>1.4</Link>)
                </>
              ) : (
                <>
                  there is no need to claim to transfer the spouse's nil-rate
                  band (<Link to={factFindUrl + '#3'}>3</Link>)
                </>
              )}
            </P>
            <P size="S">↓ Continue to next section</P>
          </>
        )}
      </Grid.Item>
    </Grid>
  )
}

ClaimToTransferNilRateBand.propTypes = {
  probateEstateId: PropTypes.number.isRequired,
  saveProbateEstateField: PropTypes.func.isRequired,
}

export default withForm(ClaimToTransferNilRateBand)
