import { Route, Redirect } from 'react-router'
import { useDispatch } from 'react-redux'

import useAdminUserRoles from 'hooks/useAdminUserRoles'
import { addGlobalError } from 'state/actions'
import ENV from 'config/environment'
import { Lead, LeadAttributes } from 'lib/models/lead'

type PrivateRouteProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  component: React.ComponentType<any>
  token?: string | null
  path: string
  exact?: boolean
  requiredRoles?: string[]
  id?: number
  product?: string
  leadId?: number | string
  productId?: Record<string, number>
  contactId?: number
  showPaymentsFilter?: boolean
  showEmailFilter?: boolean
  showCallsFilter?: boolean
  attributes?: LeadAttributes
  disabled?: boolean
  productType?: string
  lead?: Lead
  willCaseId?: number
  lpaCaseId?: number
}

const PrivateRoute = ({
  component: Component,
  token,
  path,
  exact,
  requiredRoles,
  ...rest
}: PrivateRouteProps) => {
  const { roles } = useAdminUserRoles()
  const dispatch = useDispatch()
  const isProtectionEnabled =
    ENV.FF_ROLE_BASED_AUTHENTICATION_ENABLED && ENV.APP_ENV !== 'development'

  if (!isProtectionEnabled) {
    return (
      <Route
        exact={exact}
        path={path}
        render={(props) =>
          token ? (
            <Component {...props} {...rest} />
          ) : (
            <Redirect to={{ pathname: '/', from: props.location }} />
          )
        }
      />
    )
  }

  const isBackstageUser = roles?.includes('backstage_user')
  const hasRequiredRole =
    isBackstageUser &&
    (requiredRoles ? roles?.some((role) => requiredRoles.includes(role)) : true)

  if (roles !== null && !hasRequiredRole) {
    dispatch(
      addGlobalError(
        `You are not allowed to access this page. You need one of the following roles to access:
          ${
            requiredRoles?.join(', ') || 'backstage_user'
          }. If you require access, please contact IT.`
      )
    )
  }

  return (
    <Route
      exact={exact}
      path={path}
      render={(props) =>
        token ? (
          hasRequiredRole ? (
            <Component {...props} {...rest} />
          ) : null
        ) : (
          <Redirect to={{ pathname: '/', from: props.location }} />
        )
      }
    />
  )
}

export default PrivateRoute
