import { ReactElement } from 'react-markdown/lib/react-markdown'
import upperFirst from 'lodash/upperFirst'
import styled from 'styled-components'
import { FONT } from '@farewill/ui/tokens'

import { Contact } from 'lib/models/contact'
import { Funeral, FuneralAttributes } from 'lib/models/funeral'
import { LpaCaseAttributes } from 'lib/models/lpa-case'
import { ProbateCase, ProbateCaseAttributes } from 'lib/models/probate-case'
import { WillCase, WillCaseAttributes } from 'lib/models/will-case'
import getPartnerContact from 'lib/will/get-partner-contact'
import {
  FAREWILL_PRODUCTS,
  FarewillProduct,
  LPA_CASE_NEXT_STAGE,
} from 'utils/enums'
import { getFullNameWithPreferredName } from 'utils/helpers'

import CaseIcons from '../case-icons'
import { getDaysFromToday } from '../helpers'
import OwnerAssignment from '../owner-assignment'
import OwnerIcons from '../owner-icons'
import { Case } from '../types'

const StyledGridColumn = styled.li`
  font-size: ${FONT.SIZE.S};
`

const StyledIconListItem = styled.li`
  display: flex;
`

const StyledSubText = styled.span`
  display: block;
  font-size: ${FONT.SIZE.XS};
`

const StyledTruncatedGridColumn = styled(StyledGridColumn)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const getCaseIconColumn = (row: ProbateCase | WillCase) => {
  const { attributes } = row

  return (
    <StyledIconListItem>
      <CaseIcons
        hasTaskNeedingHelp={
          (attributes as WillCaseAttributes).hasTaskNeedingHelp
        }
        isPriority={
          (attributes as ProbateCaseAttributes).isPriority ||
          (attributes as WillCaseAttributes).isUrgent
        }
      />
    </StyledIconListItem>
  )
}

export const getDeceasedNameColumn = (row: Funeral | ProbateCase) => {
  const { attributes } = row
  return (
    <StyledGridColumn>
      {[
        attributes.deceasedFirstName ||
          (attributes as FuneralAttributes).firstNames,
        attributes.deceasedLastName ||
          (attributes as FuneralAttributes).lastName,
      ].join(' ')}
    </StyledGridColumn>
  )
}

export const getNameColumn = ({
  product,
  row,
}: {
  product: FarewillProduct
  row: Case
}) => {
  let contact
  if ('primaryContact' in row.attributes) {
    contact = row.attributes?.primaryContact
  } else if ('contact' in row.attributes) {
    contact = row.attributes?.contact
  }

  const partnerContact = getPartnerContact(row)

  return (
    <StyledGridColumn>
      {getFullNameWithPreferredName(contact as Contact)}
      {product === FAREWILL_PRODUCTS.WILL && partnerContact && (
        <StyledSubText>
          {getFullNameWithPreferredName(partnerContact)}
        </StyledSubText>
      )}
    </StyledGridColumn>
  )
}

export const getOwnerAssignmentColumn = ({
  autoFocusField,
  loggedInAdminUserId,
  onCaseOwnershipOpenChange,
  row,
  updateCase,
}: {
  autoFocusField: string | null
  loggedInAdminUserId: number
  onCaseOwnershipOpenChange: () => void
  row: Funeral | WillCase
  updateCase: (id: number, attributes: unknown) => Promise<void>
}) => {
  const { id, attributes } = row
  return (
    <li>
      <OwnerAssignment
        caseAttributes={attributes}
        loggedInAdminUserId={loggedInAdminUserId}
        updateCase={() => updateCase(id, attributes)}
        autoFocusField={autoFocusField}
        onOpenChange={onCaseOwnershipOpenChange}
      >
        <OwnerIcons
          caseOwner={attributes.caseOwner}
          holidayCoverOwner={attributes.holidayCoverOwner}
        />
      </OwnerAssignment>
    </li>
  )
}

export const getWillComplexityColumn = (row: WillCase) => {
  const { attributes } = row
  const wills = attributes.wills

  if (!wills || wills.length === 0) return <StyledGridColumn />

  // we will only fall back to displaying the willCase's willComplexity if it is not populated on the will
  const isLegacyCase = !wills[0].willComplexity
  // there should either be 1 or 2 wills
  const isCoupleCase = wills.length === 2

  if (isLegacyCase) {
    return (
      <StyledGridColumn>
        {upperFirst(attributes.willComplexity as string)}
      </StyledGridColumn>
    )
  }

  const leadWill = isCoupleCase
    ? wills.find((will) => will.contactId === attributes.contactId)
    : wills[0]
  let partnerWill
  if (isCoupleCase) {
    partnerWill = wills.find((will) => will.id !== leadWill?.id)
  }

  return (
    <StyledGridColumn>
      {leadWill && <>{upperFirst(leadWill.willComplexity as string)}</>}
      {partnerWill && (
        <StyledSubText>
          {upperFirst(partnerWill.willComplexity as string)}
        </StyledSubText>
      )}
    </StyledGridColumn>
  )
}

export const getFuneralColumns = ({
  row,
  DaysInStageColumn,
  DeceasedNameColumn,
  NameColumn,
  OwnerAssignmentColumn,
}: {
  row: Case
  DaysInStageColumn: ReactElement
  DeceasedNameColumn: ReactElement
  NameColumn: ReactElement
  OwnerAssignmentColumn: ReactElement
}): ReactElement => {
  return (
    <>
      {NameColumn}
      {DeceasedNameColumn}
      {DaysInStageColumn}
      <StyledTruncatedGridColumn>
        {(row.attributes as FuneralAttributes).criticalNotes || ''}
      </StyledTruncatedGridColumn>
      <StyledGridColumn>
        {(row.attributes as FuneralAttributes).lastCallAt && (
          <>
            {getDaysFromToday((row.attributes as FuneralAttributes).lastCallAt)}
          </>
        )}
      </StyledGridColumn>
      {OwnerAssignmentColumn}
    </>
  )
}

export const getLpaColumns = ({
  row,
  AdminIconColumn,
  LastActivityColumn,
  NameColumn,
}: {
  row: Case
  AdminIconColumn: ReactElement
  LastActivityColumn: ReactElement
  NameColumn: ReactElement
}): ReactElement => {
  return (
    <>
      {NameColumn}
      <StyledGridColumn>
        {
          LPA_CASE_NEXT_STAGE[
            (row.attributes as LpaCaseAttributes)
              .nextStage as keyof typeof LPA_CASE_NEXT_STAGE
          ]
        }
      </StyledGridColumn>
      {LastActivityColumn}
      {AdminIconColumn}
    </>
  )
}

export const getProbateColumns = ({
  AdminIconColumn,
  CaseIconColumn,
  DaysInStageColumn,
  DeceasedNameColumn,
  LastActivityColumn,
  NameColumn,
}: {
  AdminIconColumn: ReactElement
  CaseIconColumn: ReactElement
  DaysInStageColumn: ReactElement
  DeceasedNameColumn: ReactElement
  LastActivityColumn: ReactElement
  NameColumn: ReactElement
}): ReactElement => {
  return (
    <>
      {NameColumn}
      {DeceasedNameColumn}
      {DaysInStageColumn}
      {LastActivityColumn}
      {AdminIconColumn}
      {CaseIconColumn}
    </>
  )
}

export const getWillColumns = ({
  CaseIconColumn,
  DaysInStageColumn,
  LastActivityColumn,
  NameColumn,
  OwnerAssignmentColumn,
  WillComplexityColumn,
}: {
  CaseIconColumn: ReactElement
  DaysInStageColumn: ReactElement
  LastActivityColumn: ReactElement
  NameColumn: ReactElement
  OwnerAssignmentColumn: ReactElement
  WillComplexityColumn: ReactElement
}) => {
  return (
    <>
      {NameColumn}
      {WillComplexityColumn}
      {DaysInStageColumn}
      {LastActivityColumn}
      {OwnerAssignmentColumn}
      {CaseIconColumn}
    </>
  )
}
