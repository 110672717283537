import { useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Grid, P } from '@farewill/ui'
import { COLOR, GTR, FONT } from '@farewill/ui/tokens'
import { useFormikContext } from 'formik'

import Tooltip from 'components/tooltip'
import SummaryBox from '../components/summary-box'
import CircularRadioGroup from 'components/form/circular-radio-group'
import MessageBox from 'components/message-box'
import Relatives from './relatives'
import AdoptedRelatives from './adopted-relatives'
import { RelativesProvider, useRelativesContext } from './relatives/context'

import {
  MARITAL_STATUS,
  SPOUSE_FIRST_NAMES,
  SPOUSE_LAST_NAME,
} from '../people/field-names'
import { MARRIED, NOT_ASKED_OPTIONS } from 'utils/enums'
import { formatToHuman } from 'utils/helpers'

import { getVisibleFields } from './form-helpers'
import {
  SPOUSE_SURVIVED_TWENTY_EIGHT_DAYS,
  HAS_LIVING_CHILDREN_OR_GRANDCHILDREN,
  HAS_LIVING_PARENTS,
  HAS_LIVING_SIBLINGS_OR_DESCENDANTS_OF,
  HAS_LIVING_HALF_SIBLINGS_OR_DESCENDANTS_OF,
  HAS_LIVING_GRANDPARENTS,
  HAS_LIVING_AUNTS_OR_UNCLES_OR_DESCENDANTS_OF,
  HAS_LIVING_HALF_AUNTS_OR_UNCLES_OR_CHILDREN_OF,
} from './field-names'

export const StyledHint = styled.div`
  margin-top: ${GTR.XXS};
  margin-left: ${GTR.S};
  color: ${COLOR.GREY.MEDIUM};
  font-size: ${FONT.SIZE.XS};
`

const Intestacy = ({ saveProbateEstateField }) => {
  const { values } = useFormikContext()
  const { fetchRelatives, isFetchingRelatives } = useRelativesContext()

  useEffect(() => {
    fetchRelatives()
  }, [fetchRelatives])

  const maritalSummaryItems = [
    {
      label: 'Marital status at death',
      value: formatToHuman(values[MARITAL_STATUS]),
      section: '1.4',
    },
    {
      label: 'Full name of spouse',
      value: values[SPOUSE_FIRST_NAMES] + ' ' + values[SPOUSE_LAST_NAME],
      section: '1.4',
      showIf: () => values[MARITAL_STATUS] === MARRIED,
    },
  ]

  const {
    showSpouseSurvivedTwentyEightDays,
    showLivingParents,
    showLivingSiblings,
    showLivingHalfSiblings,
    showLivingGrandparents,
    showLivingAuntsOrUncles,
    showLivingHalfAuntsOrUncles,
    showEstateToCrownWarning,
  } = getVisibleFields({ values })

  if (isFetchingRelatives) {
    return (
      <P size="L">
        Loading <strong>relatives</strong>...
      </P>
    )
  }

  return (
    <Grid>
      <Grid.Item>
        <SummaryBox items={maritalSummaryItems} />
      </Grid.Item>
      {showSpouseSurvivedTwentyEightDays && (
        <Grid.Item>
          <CircularRadioGroup
            name={SPOUSE_SURVIVED_TWENTY_EIGHT_DAYS}
            label="Has the living spouse survived 28 days?"
            options={NOT_ASKED_OPTIONS}
            handleSave={saveProbateEstateField}
            inline
          />
        </Grid.Item>
      )}
      <Grid.Item>
        <CircularRadioGroup
          label="Are there living children, or children who've died leaving children of their own?"
          name={HAS_LIVING_CHILDREN_OR_GRANDCHILDREN}
          options={NOT_ASKED_OPTIONS}
          handleSave={saveProbateEstateField}
          inline
        />
      </Grid.Item>
      {values[HAS_LIVING_CHILDREN_OR_GRANDCHILDREN] === 'true' && (
        <Grid.Item>
          <Relatives relationshipToDeceased="child" />
        </Grid.Item>
      )}
      {showLivingParents && (
        <>
          <Grid.Item>
            <CircularRadioGroup
              name={HAS_LIVING_PARENTS}
              label="Are there living parents?"
              options={NOT_ASKED_OPTIONS}
              handleSave={saveProbateEstateField}
              inline
            />
          </Grid.Item>
          {values[HAS_LIVING_PARENTS] === 'true' && (
            <Grid.Item>
              <Relatives relationshipToDeceased="parent" />
            </Grid.Item>
          )}
        </>
      )}
      {showLivingSiblings && (
        <>
          <Grid.Item>
            <CircularRadioGroup
              name={HAS_LIVING_SIBLINGS_OR_DESCENDANTS_OF}
              label={
                <>
                  Are there living{' '}
                  <Tooltip content="Full siblings are those who share the same mother and father. If there are only half siblings please select 'No'.">
                    full siblings
                  </Tooltip>
                  , or any who have died leaving children or grandchildren?
                </>
              }
              options={NOT_ASKED_OPTIONS}
              handleSave={saveProbateEstateField}
              inline
            />
          </Grid.Item>
          {values[HAS_LIVING_SIBLINGS_OR_DESCENDANTS_OF] === 'true' && (
            <Grid.Item>
              <Relatives relationshipToDeceased="sibling" />
            </Grid.Item>
          )}
        </>
      )}
      {showLivingHalfSiblings && (
        <>
          <Grid.Item>
            <CircularRadioGroup
              label={
                <>
                  Are there living{' '}
                  <Tooltip content="Who share one parent with the person who's died">
                    half brothers or sisters
                  </Tooltip>
                  , or any who have died leaving children or grandchildren?
                </>
              }
              name={HAS_LIVING_HALF_SIBLINGS_OR_DESCENDANTS_OF}
              options={NOT_ASKED_OPTIONS}
              handleSave={saveProbateEstateField}
              inline
            />
          </Grid.Item>
          {values[HAS_LIVING_HALF_SIBLINGS_OR_DESCENDANTS_OF] === 'true' && (
            <Grid.Item>
              <Relatives relationshipToDeceased="half_sibling" />
            </Grid.Item>
          )}
        </>
      )}
      {showLivingGrandparents && (
        <>
          <Grid.Item>
            <CircularRadioGroup
              name={HAS_LIVING_GRANDPARENTS}
              label="Are there living grandparents?"
              options={NOT_ASKED_OPTIONS}
              handleSave={saveProbateEstateField}
              inline
            />
          </Grid.Item>
          {values[HAS_LIVING_GRANDPARENTS] === 'true' && (
            <Grid.Item>
              <Relatives relationshipToDeceased="grandparent" />
            </Grid.Item>
          )}
        </>
      )}
      {showLivingAuntsOrUncles && (
        <>
          <Grid.Item>
            <CircularRadioGroup
              label={
                <>
                  Are there living{' '}
                  <Tooltip content="Who share two grandparents with the person who died">
                    aunts and uncles
                  </Tooltip>
                  , or any who have died leaving children or grandchildren?
                </>
              }
              name={HAS_LIVING_AUNTS_OR_UNCLES_OR_DESCENDANTS_OF}
              options={NOT_ASKED_OPTIONS}
              handleSave={saveProbateEstateField}
              inline
            />
          </Grid.Item>
          {values[HAS_LIVING_AUNTS_OR_UNCLES_OR_DESCENDANTS_OF] === 'true' && (
            <Grid.Item>
              <Relatives relationshipToDeceased="aunt_or_uncle" />
            </Grid.Item>
          )}
        </>
      )}
      {showLivingHalfAuntsOrUncles && (
        <>
          <Grid.Item>
            <CircularRadioGroup
              label={
                <>
                  Are there living{' '}
                  <Tooltip content="Who share one grandparent with the person who died">
                    half aunts and uncles
                  </Tooltip>
                  , or any who have died leaving children of their own?
                </>
              }
              name={HAS_LIVING_HALF_AUNTS_OR_UNCLES_OR_CHILDREN_OF}
              options={NOT_ASKED_OPTIONS}
              handleSave={saveProbateEstateField}
              inline
            />
          </Grid.Item>
          {values[HAS_LIVING_HALF_AUNTS_OR_UNCLES_OR_CHILDREN_OF] ===
            'true' && (
            <Grid.Item>
              <Relatives relationshipToDeceased="half_aunt_or_uncle" />
            </Grid.Item>
          )}
        </>
      )}
      {showEstateToCrownWarning && (
        <Grid.Item padding={['S', 0]}>
          <MessageBox warning>
            Everything passes to the 'Crown' (the government) - refer to legal
            team for next steps
          </MessageBox>
        </Grid.Item>
      )}
      <Grid.Item>
        <AdoptedRelatives saveProbateEstateField={saveProbateEstateField} />
      </Grid.Item>
    </Grid>
  )
}

const IntestacyWithProvider = ({ probateEstateId, ...rest }) => (
  <RelativesProvider probateEstateId={probateEstateId}>
    <Intestacy {...rest} />
  </RelativesProvider>
)

IntestacyWithProvider.propTypes = {
  probateEstateId: PropTypes.string.isRequired,
  saveProbateEstateField: PropTypes.func.isRequired,
}

export default IntestacyWithProvider
