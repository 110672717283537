import styled from 'styled-components'
import { ArrowRightIcon } from '@farewill/ui'
import { SvgIconType } from '@farewill/ui/components/Icon/types'
import { GTR } from '@farewill/ui/tokens'
import React from 'react'

const StyledLi = styled.li`
  position: relative;
  padding-left: ${GTR.M};
  line-height: 1.5;

  &:not(:last-of-type) {
    margin-bottom: ${GTR.S};
  }
`

const IconWrapper = styled.span`
  position: absolute;
  left: 0;
  top: 0;
`

export type Props = {
  icon?: SvgIconType
  items: (string | React.ReactElement)[]
}

export const IconList = ({
  icon = ArrowRightIcon,
  items,
}: Props): React.ReactElement => {
  const IconComponent = icon
  return (
    <ul>
      {items.map((item, index: number) => (
        <StyledLi key={index}>
          <IconWrapper>
            <IconComponent size="S" inline />
          </IconWrapper>
          {item}
        </StyledLi>
      ))}
    </ul>
  )
}

export default IconList
