import {
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react'

import apiRequest from 'lib/axios/api-request'
import { ProbateEstate } from 'lib/models/probate-estate'
import { isAxiosError } from 'lib/effects/api'

interface ProbateEstateContextProps {
  probateEstate: ProbateEstate
  updateProbateEstate: (probateEstate: Partial<ProbateEstate>) => Promise<void>
  saveProbateEstateField: ({
    name,
    value,
  }: {
    name: string
    value: string | number | null
  }) => void
  isLoading: boolean
  errors: string[]
  probateEstateId?: number
}

const ProbateEstateContext = createContext<ProbateEstateContextProps>({
  probateEstate: {} as ProbateEstate,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  updateProbateEstate: async () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  saveProbateEstateField: async () => {},
  isLoading: false,
  errors: [],
  probateEstateId: undefined,
})

const ProbateEstateProvider = ({
  children,
  probateEstateId,
}: PropsWithChildren<{
  probateEstateId: number
}>) => {
  const [probateEstate, setProbateEstate] = useState<ProbateEstate>(
    {} as ProbateEstate
  )
  const [isLoading, setIsLoading] = useState(false)
  const [errors, setErrors] = useState([])

  useEffect(() => {
    if (!probateEstateId) return

    setIsLoading(true)
    setErrors([])
    apiRequest({
      url: `/api/probate-estates/${probateEstateId}`,
      method: 'GET',
    })
      .then((response) => {
        setProbateEstate(response.data.data)
      })
      .catch((error) => {
        const errors = isAxiosError(error) ? error.response?.data?.errors : []
        setErrors(errors)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [probateEstateId])

  const updateProbateEstate = async (probateEstate: Partial<ProbateEstate>) => {
    return apiRequest({
      url: `/api/probate-estates/${probateEstateId}`,
      method: 'PATCH',
      data: {
        data: {
          type: 'probate_estates',
          id: probateEstate.id,
          attributes: { ...probateEstate.attributes },
        },
      },
    }).then(({ data }) => {
      setProbateEstate(data.data as ProbateEstate)
    })
  }

  const saveProbateEstateField = ({
    name,
    value,
  }: {
    name: string
    value: string | number | null
  }) => {
    apiRequest({
      url: `/api/probate-estates/${probateEstateId}`,
      method: 'PATCH',
      data: {
        data: {
          type: 'probate_estates',
          id: probateEstate.id,
          attributes: { [name]: value || null },
        },
      },
    }).then(({ data }) => {
      setProbateEstate(data.data as ProbateEstate)
    })
  }

  return (
    <ProbateEstateContext.Provider
      value={{
        probateEstate,
        isLoading,
        errors,
        updateProbateEstate,
        saveProbateEstateField,
        probateEstateId,
      }}
    >
      {children}
    </ProbateEstateContext.Provider>
  )
}

const useProbateEstate = () => useContext(ProbateEstateContext)

export { ProbateEstateProvider, useProbateEstate }
