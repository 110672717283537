import styled from 'styled-components'
import { Divider, Grid, P } from '@farewill/ui'
import Dinero from 'dinero.js'
import { QuoteResponseDataProbate } from 'lib/types'
import { UseSectionsCompleteResponse } from '../helpers/complete-sections/types'
import QuoteBox from './quote-box'
import EstateValueBox from './estate-value-box'

const StyledItemWrapper = styled(Grid.Item)`
  display: flex;

  & > div {
    width: 100%;
  }
`

type QuotesProps = {
  quote: QuoteResponseDataProbate | null
  completedSections: Partial<UseSectionsCompleteResponse>
  vertical?: boolean
}

const QuoteSummary = ({ quote, completedSections, vertical }: QuotesProps) => {
  return completedSections.allSectionsComplete ? (
    <>
      <Grid margin={vertical ? 0 : [0, 0, 'L']}>
        <StyledItemWrapper span={12} spanFromM={vertical ? 12 : 6}>
          <QuoteBox quote={quote} />
        </StyledItemWrapper>
        <StyledItemWrapper span={12} spanFromM={vertical ? 12 : 6}>
          <EstateValueBox quote={quote} />
        </StyledItemWrapper>
        {!vertical && quote?.attributes.estateValues.grossEstateValueInPence ? (
          <Grid.Item>
            <P>
              A typical High Street firm would charge{' '}
              <strong>
                {Dinero({
                  amount: Math.round(
                    quote?.attributes.estateValues.grossEstateValueInPence *
                      0.02
                  ),
                }).toFormat('$0,0')}
              </strong>{' '}
              for this case, at 2% of the gross estate value.
            </P>
          </Grid.Item>
        ) : null}
      </Grid>
      {!vertical && <Divider margin={['XL', 0]} />}
    </>
  ) : null
}

export default QuoteSummary
