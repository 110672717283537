import { Button, Grid, P } from '@farewill/ui'
import { GTR } from '@farewill/ui/tokens'
import { useFormikContext } from 'formik'
import get from 'lodash/get'

import store from 'state/create-store'
import { showModal } from 'state/actions'
import namespace from 'lib/formik/namespace'
import { PROBATE_ESTATE_ITEM_TYPES } from 'lib/models/probate-estate'
import EstateCard from 'components/estate-card'
import InputFloating from 'components/form/input-floating'
import CircularRadioGroup from 'components/form/circular-radio-group'
import NotesInput from 'components/notes-input'
import DeleteModal from 'routes/lead/probate-form/delete-modal'
import TextSpacer from 'routes/lead/probate-form/text-spacer'
import withForm from 'routes/lead/probate-form/with-form'
import { getItemsTotals } from 'routes/probate-estate/fact-find/get-totals'
import {
  PROBATE_LEAD_FORM_SECTIONS,
  PROBATE_LEAD_FORM_VALUES,
} from 'routes/lead/probate-form/constants'

import { HandleSaveProps, ItemProps } from './types'
import { useItems } from '../helpers/items-context'
import { useProbateEstate } from '../helpers/probate-estate-context'
import SubsectionHeading from '../subsection-heading'

const Item = ({ item, index, listLength }: ItemProps) => {
  const {
    DECEASED_SHARE_OF_JOINT_OWNERSHIP,
    DESCRIPTION,
    JOINT_OWNER_NAME,
    OWNERSHIP,
    VALUE,
    NOTES,
  } = PROBATE_LEAD_FORM_VALUES
  const { withNamespace, withoutNamespace } = namespace(
    `nationalSavingsAndInvestments.${item.id}.`
  )
  const { values } = useFormikContext()
  const { updateItem, deleteItem } = useItems()

  const handleSave = ({ name, value }: HandleSaveProps) => {
    updateItem(
      {
        id: item.id,
        attributes: { [withoutNamespace(name)]: value || null },
      },
      PROBATE_ESTATE_ITEM_TYPES.NATIONAL_SAVINGS_AND_INVESTMENTS
    )
  }

  const onTrashClick = () => {
    store.dispatch(
      showModal({
        component: DeleteModal,
        headingText: 'Delete NS&I account',
        config: {
          deleteCallback: () => {
            deleteItem(item.id)
          },
          type: 'NS&I account',
          resourceId: item.id,
        },
      })
    )
  }

  return (
    <EstateCard
      onTrashClick={onTrashClick}
      listLength={listLength}
      listPosition={index + 1}
      key={item.id}
      type="NS&amp;I account"
    >
      <Grid style={{ gridAutoFlow: 'row' }}>
        <Grid.Item span={9}>
          <InputFloating
            name={withNamespace(DESCRIPTION)}
            label="Description"
            handleSave={handleSave}
            hint="If IHT400, we’ll need sort code and account number"
          />
        </Grid.Item>
        <Grid.Item span={6}>
          <InputFloating
            name={withNamespace(VALUE)}
            label="Total value (£)"
            hint="For joint assets, the £ value of their share will be calculated automatically."
            handleSave={handleSave}
            isCurrency
          />
        </Grid.Item>

        <Grid.Item>
          <CircularRadioGroup
            name={withNamespace(OWNERSHIP)}
            label="Ownership"
            options={[
              { label: 'Not asked', value: '' },
              { label: 'Sole', value: 'sole' },
              { label: 'Joint', value: 'joint' },
            ]}
            handleSave={handleSave}
            inline
          />
        </Grid.Item>
        {get(values, withNamespace(OWNERSHIP)) === 'joint' && (
          <>
            <Grid.Item span={6}>
              <InputFloating
                name={withNamespace(JOINT_OWNER_NAME)}
                label="Name of joint owner"
                handleSave={handleSave}
                hint="If more than one, leave a note below"
              />
            </Grid.Item>
            <Grid.Item span={6}>
              <InputFloating
                name={withNamespace(DECEASED_SHARE_OF_JOINT_OWNERSHIP)}
                label="Share owned by person who's died (%)"
                handleSave={handleSave}
              />
            </Grid.Item>
          </>
        )}
        <Grid.Item>
          <NotesInput name={withNamespace(NOTES)} handleSave={handleSave} />
        </Grid.Item>
      </Grid>
    </EstateCard>
  )
}

const ItemWithForm = withForm(Item)

const NationalSavingsAndInvestments = ({
  isComplete,
}: {
  isComplete: boolean
}) => {
  const { NATIONAL_SAVINGS_AND_INVESTMENTS } = PROBATE_LEAD_FORM_SECTIONS
  const { groupedItems, createItem } = useItems()
  const { saveProbateEstateField } = useProbateEstate()
  const { nationalSavingsAndInvestments: investmentItems = [] } = groupedItems
  const { total, sole, joint, unspecified } = getItemsTotals(investmentItems)

  return (
    <SubsectionHeading
      disabled={investmentItems.length > 0}
      name={NATIONAL_SAVINGS_AND_INVESTMENTS}
      onToggle={saveProbateEstateField}
      scrollId="6.2"
      title="NS&amp;I"
      isComplete={isComplete}
    >
      <Grid.Item span={11}>
        <P size="S" data-testid="items-value-summary">
          Total: {total} <TextSpacer /> Sole: {sole} <TextSpacer /> Joint share:{' '}
          {joint} <TextSpacer /> Unspecified: {unspecified}
        </P>

        {investmentItems.map((item, index) => (
          <ItemWithForm
            enableReinitialize={false}
            index={index}
            initialValues={{
              nationalSavingsAndInvestments: { [item.id]: item.attributes },
            }}
            item={item}
            key={item.id}
            listLength={investmentItems.length}
          />
        ))}
      </Grid.Item>
      <Grid.Item>
        <Button.Secondary
          style={{ paddingTop: GTR.XXS, paddingBottom: GTR.XXS }}
          type="button"
          onClick={() => {
            createItem({
              itemType:
                PROBATE_ESTATE_ITEM_TYPES.NATIONAL_SAVINGS_AND_INVESTMENTS,
            })
          }}
        >
          Add NS&amp;I account
        </Button.Secondary>
      </Grid.Item>
    </SubsectionHeading>
  )
}

export default NationalSavingsAndInvestments
