import { Formik, Form } from 'formik'
import styled from 'styled-components'
import { Grid, H, Wrapper, P, Divider } from '@farewill/ui'

import AddressInputWrapper from 'components/address'
import ContactBirthDate from 'components/contact-birth-date'
import Checkbox from 'components/form/checkbox'
import ContactEmailInput from 'components/form/contact-email-input'
import Input from 'components/form/input'
import MessageBox from 'components/message-box'
import RequestStatus from 'components/request-status'
import { CONTACT_SCHEMA } from 'lib/formik/schemata'
import { AddressWithType, Contact } from 'lib/models/contact'
import {
  LIMC_CONTACT_TYPE,
  LIMC_CONSENT_VALUE,
} from 'lib/models/legitimate-interest-marketing-consent'
import { formatValuesForFormik, strToBool, strToBoolProp } from 'utils/helpers'
import type { StringKeyOf } from 'utils/types/string-key-of'

import { useContactContext } from './context'
import MarketingConsents from './marketing-consents'
import PartnerDataSharing from './partner-data-sharing'

const StyledCheckboxGridItem = styled(Grid.Item)`
  display: flex;
`

type Values = Pick<
  Contact,
  | 'title'
  | 'firstName'
  | 'lastName'
  | 'preferredName'
  | 'fullLegalName'
  | 'pronouns'
  | 'email'
  | 'phoneNumber'
  | 'doNotContact'
> & {
  marketingConsents: {
    general: {
      email: boolean
      phoneNumber: boolean
      postal: boolean
    }
  }
}

const getConsentValue = (value: strToBoolProp) =>
  strToBool(value)
    ? LIMC_CONSENT_VALUE.subscribed
    : LIMC_CONSENT_VALUE.unsubscribed

const KeyInformation = () => {
  const {
    contact,
    updateContact,
    isUpdatingContact,
    updateContactErrors,
    updateContactMeta,
  } = useContactContext()

  const { addresses } = contact.attributes

  let mostRecentlyUpdatedAddress = addresses[0]
  let showMultipleAddressesMessage: boolean

  if (addresses.length > 1) {
    showMultipleAddressesMessage = true
    mostRecentlyUpdatedAddress = addresses.reduce(
      (a: AddressWithType, b: AddressWithType) =>
        new Date(b.updatedAt) > new Date(a.updatedAt) ? b : a
    )
  }

  /**
   * This object maps consent values to booleans for checkbox state
   */
  const marketingConsentFormValues = {
    general: Object.keys(LIMC_CONTACT_TYPE).reduce(
      (consents, contactType) => ({
        ...consents,
        [contactType]:
          contact.attributes.marketingConsents?.general?.[contactType] !==
          LIMC_CONSENT_VALUE.unsubscribed,
      }),
      {} as Record<StringKeyOf<typeof LIMC_CONTACT_TYPE>, boolean>
    ),
  }

  const onSubmit = (values: Values) => {
    return updateContact({
      title: values.title || null,
      firstName: values.firstName || null,
      lastName: values.lastName || null,
      preferredName: values.preferredName || null,
      fullLegalName: values.fullLegalName || null,
      pronouns: values.pronouns || null,
      email: values.email || null,
      phoneNumber: values.phoneNumber || null,
      doNotContact: strToBool(values.doNotContact),
      marketingConsents: {
        general: {
          email: getConsentValue(values.marketingConsents.general.email),
          phoneNumber: getConsentValue(
            values.marketingConsents.general.phoneNumber
          ),
          postal: getConsentValue(values.marketingConsents.general.postal),
        },
      },
    })
  }

  return (
    <Formik
      initialValues={formatValuesForFormik({
        ...contact.attributes,
        marketingConsents: marketingConsentFormValues,
      })}
      onSubmit={onSubmit}
      validationSchema={CONTACT_SCHEMA}
    >
      {({ errors, handleSubmit, values }) => (
        <Form>
          <Wrapper maxWidthInColumns={8}>
            <Wrapper separator>
              <H size="S">Key information</H>
              <P>
                Editing these fields will update the customer details on all
                products. Please let the other specialists know before making
                any major updates as it’ll also update their case details.
              </P>
            </Wrapper>
            <Grid separator>
              <Grid.Item>
                <H>Display name</H>
              </Grid.Item>
              <Grid.Item spanFromM={2}>
                <Input
                  label="Title"
                  name="title"
                  handleSave={() => handleSubmit()}
                />
              </Grid.Item>
              <Grid.Item spanFromM={4}>
                <Input
                  label="First name"
                  name="firstName"
                  handleSave={() => handleSubmit()}
                />
              </Grid.Item>
              <Grid.Item spanFromM={6}>
                <Input
                  label="Last name"
                  name="lastName"
                  handleSave={() => handleSubmit()}
                />
              </Grid.Item>
            </Grid>
            <Divider margin={['M', 0]} />
            <Grid separator>
              <Grid.Item spanFromM={6}>
                <Input
                  label="Preferred name"
                  name="preferredName"
                  handleSave={() => handleSubmit()}
                  hint="How the person likes to be addressed"
                />
              </Grid.Item>
              <Grid.Item spanFromM={6}>
                <Input
                  label="Pronouns"
                  name="pronouns"
                  handleSave={() => handleSubmit()}
                />
              </Grid.Item>
              <Grid.Item spanFromM={12}>
                <Input
                  label="Full legal name"
                  name="fullLegalName"
                  handleSave={() => handleSubmit()}
                  hint="Include middle names. This is often the name on their passport or drivers licence"
                />
              </Grid.Item>
              <Grid.Item>
                <ContactBirthDate
                  contactId={contact.id}
                  dateOfBirth={contact.attributes.dateOfBirth}
                />
              </Grid.Item>
              <Grid.Item margin={['S', 0, 0, 0]}>
                <H size="XS">Contact Information</H>
              </Grid.Item>
              <Grid.Item spanFromM={6} data-testId="key-info-phone-number">
                <Input
                  label="Telephone number"
                  name="phoneNumber"
                  handleSave={() => handleSubmit()}
                />
              </Grid.Item>
              <Grid.Item spanFromM={6} data-testId="key-info-email">
                <ContactEmailInput
                  contactId={contact.id}
                  handleSave={() => handleSubmit()}
                  hasRelatedAccount={!!contact.attributes.accountUuid}
                />
              </Grid.Item>
              <Grid.Item spanFromM={6} data-testId="key-info-address">
                <AddressInputWrapper
                  data={mostRecentlyUpdatedAddress}
                  label="Address"
                  name="contact.addresses"
                  newAddressAttrs={{
                    relatedResource: 'contact',
                    relatedId: contact.id,
                    relation: 'addresses',
                  }}
                  onSave={() => handleSubmit()}
                />
                {showMultipleAddressesMessage && (
                  <Grid.Item
                    spanFromM={6}
                    margin={['S', 0, 0]}
                    data-testId="key-info-email"
                  >
                    <MessageBox>
                      This contact has multiple addresses. The most recently
                      updated address is shown here.
                    </MessageBox>
                  </Grid.Item>
                )}
              </Grid.Item>
              <StyledCheckboxGridItem>
                <Checkbox
                  name="doNotContact"
                  label="Don’t contact this person under any circumstances"
                  checked={contact.attributes.doNotContact}
                  handleSave={() => handleSubmit()}
                />
              </StyledCheckboxGridItem>
              <MarketingConsents
                contact={contact.attributes}
                consents={marketingConsentFormValues}
                handleSubmit={() => handleSubmit()}
              />
              <PartnerDataSharing />
            </Grid>
            <RequestStatus
              apiErrors={updateContactErrors}
              apiMessages={updateContactMeta?.messages}
              isSaving={isUpdatingContact}
              validationErrors={errors}
            />
          </Wrapper>
        </Form>
      )}
    </Formik>
  )
}

export default KeyInformation
