import { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Button, P, Wrapper } from '@farewill/ui'

import ErrorBox from 'components/form/error-box'

import { formatApiError } from 'lib/formatting/errors'
import store from 'state/create-store'
import { hideModal } from 'state/actions'
import { AxiosError } from 'axios'

const StyledWrapper = styled(Wrapper)`
  display: flex;
  justify-content: space-between;
`

export type DeleteModalProps = {
  config: {
    deleteCallback: (id: string) => void
    type: string
    resourceId: string
  }
}

const DeleteModal = ({ config }: DeleteModalProps) => {
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState([])

  const handleDeleteClick = async () => {
    try {
      setLoading(true)
      await config.deleteCallback(config.resourceId)
      store.dispatch(hideModal())
    } catch (error) {
      setErrors((error as AxiosError).response?.data.errors)
      setLoading(false)
    }
  }

  return (
    <Wrapper>
      <P>Are you sure you want to delete this {config.type}?</P>
      <P>All details will be lost.</P>
      {errors.map((error) => (
        <ErrorBox key={error}>{formatApiError(error)}</ErrorBox>
      ))}
      <StyledWrapper>
        <Button.Bordered
          type="button"
          onClick={() => store.dispatch(hideModal())}
        >
          Cancel
        </Button.Bordered>
        <Button.Primary
          type="button"
          loading={loading}
          onClick={handleDeleteClick}
        >
          Delete {config.type}
        </Button.Primary>
      </StyledWrapper>
    </Wrapper>
  )
}

DeleteModal.propTypes = {
  config: PropTypes.shape({
    deleteCallback: PropTypes.func.isRequired,
    type: PropTypes.string.isRequired,
    resourceId: PropTypes.number.isRequired,
  }).isRequired,
}

export default DeleteModal
