import { SvgIcon } from '@farewill/ui'
import { COLOR } from '@farewill/ui/tokens'

const AIIcon = ({
  width = 15,
  height = 19,
  disabled = false,
}: {
  width?: number
  height?: number
  disabled?: boolean
}) => (
  <SvgIcon
    width={width}
    height={height}
    viewBox="0 0 15 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.04595 4.04825C7.14323 4.04825 7.1919 3.98993 7.21622 3.8983C7.46755 2.50724 7.45136 2.47393 8.86216 2.19903C8.95944 2.1824 9.01621 2.12408 9.01621 2.02411C9.01621 1.92417 8.95944 1.86585 8.86216 1.84918C7.45946 1.55768 7.5 1.52434 7.21622 0.149943C7.1919 0.058321 7.14323 0 7.04595 0C6.94863 0 6.89999 0.058321 6.87567 0.149943C6.59189 1.52434 6.64053 1.55768 5.22973 1.84918C5.14055 1.86585 5.07568 1.92417 5.07568 2.02411C5.07568 2.12408 5.14055 2.1824 5.22973 2.19903C6.64053 2.4906 6.62431 2.50724 6.87567 3.8983C6.89999 3.98993 6.94863 4.04825 7.04595 4.04825ZM3.12164 9.77906C3.27569 9.77906 3.3811 9.67909 3.39729 9.52918C3.6892 7.30513 3.76216 7.30513 6.00001 6.86368C6.14597 6.83866 6.25134 6.73873 6.25134 6.58047C6.25134 6.43052 6.14597 6.32223 6.00001 6.29725C3.76216 5.98073 3.68107 5.90574 3.39729 3.64007C3.3811 3.49016 3.27569 3.38187 3.12164 3.38187C2.97569 3.38187 2.87028 3.49016 2.85405 3.64842C2.5865 5.88076 2.47296 5.87244 0.251364 6.29725C0.10541 6.33059 0 6.43052 0 6.58047C0 6.74704 0.10541 6.83866 0.283779 6.86368C2.48918 7.23017 2.5865 7.28849 2.85405 9.51251C2.87028 9.67909 2.97569 9.77906 3.12164 9.77906ZM8.61893 19C8.82975 19 8.9838 18.8417 9.02431 18.6168C9.59999 14.0522 10.2243 13.3608 14.6189 12.861C14.846 12.836 15 12.6611 15 12.4446C15 12.228 14.846 12.0614 14.6189 12.0281C10.2243 11.5283 9.59999 10.8369 9.02431 6.27226C8.9838 6.04737 8.82975 5.89742 8.61893 5.89742C8.40811 5.89742 8.25406 6.04737 8.22164 6.27226C7.64596 10.8369 7.01353 11.5283 2.62701 12.0281C2.3919 12.0614 2.23785 12.228 2.23785 12.4446C2.23785 12.6611 2.3919 12.836 2.62701 12.861C7.0054 13.4524 7.61351 14.0605 8.22164 18.6168C8.25406 18.8417 8.40811 19 8.61893 19Z"
      fill={disabled ? COLOR.GREY.LIGHT : COLOR.BLACK}
    />
  </SvgIcon>
)

export default AIIcon
