import styled, { css } from 'styled-components'
import { COLOR } from '@farewill/ui/tokens'

import { WILLSUITE_STATUS } from 'lib/models/willsuite-event'
import { formatWillSuiteStatus } from 'utils/helpers'
import Badge from '../styled/badge'

type StatusProps = {
  name: string
  small: boolean
}

const Status = styled(Badge)`
  ${(props: StatusProps) =>
    [
      'referred',
      'booked',
      'active',
      formatWillSuiteStatus(WILLSUITE_STATUS.APPROVED_BY_LEGAL),
      formatWillSuiteStatus(WILLSUITE_STATUS.DRAFT_SENT_TO_CUSTOMER),
    ].includes(props.name) &&
    css`
      color: ${COLOR.ACCENT.SECONDARY};
      background: rgba(62, 72, 125, 0.12);
    `}

  ${(props: StatusProps) =>
    [
      'lost',
      'declined',
      'cancelled',
      'cold',
      'blocked',
      formatWillSuiteStatus(WILLSUITE_STATUS.UPDATES_REQUIRED),
    ].includes(props.name) &&
    css`
      color: ${COLOR.STATE.ERROR};
      background: rgba(234, 76, 60, 0.12);
    `}

  ${(props: StatusProps) =>
    ['closed', formatWillSuiteStatus(WILLSUITE_STATUS.COMPLETE)].includes(
      props.name
    ) &&
    css`
      color: ${COLOR.GREY.DARK};
      background: ${COLOR.GREY.LIGHT};
    `}

  ${(props: StatusProps) =>
    props.small &&
    css`
      font-size: 10px;
      padding: 3px 6px;
    `}
`

const StatusBadge = ({
  status,
  small = false,
}: {
  status: string
  small?: boolean
}) => {
  if (!status) return null

  return (
    <Status name={status} small={small}>
      {status}
    </Status>
  )
}

export default StatusBadge
