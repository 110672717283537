import { LpaLeadAttributes, WillLeadAttributes } from 'lib/models/lead'
import { OUTCOME_TYPE } from './constants'
import { Outcome } from './types'
import { QuestionConfig } from './will-form/types'

export const MORE_DETAILS_NEEDED = {
  complete: false,
  type: OUTCOME_TYPE.INCOMPLETE,
  heading: 'More details needed',
  message: 'Fill in the diagnostic questions above',
} as const

type LeadAttributes = WillLeadAttributes | LpaLeadAttributes

type Question = {
  answered: (values: LeadAttributes) => boolean
  attributeName: string
  options: { value: string; label: string }[]
  isVisible: (values?: LeadAttributes) => boolean
  outcome: (values: LeadAttributes) => Outcome
  formattedAnswer: (
    attributes: LeadAttributes
  ) => string | number | boolean | null
  reasonForPhoneWill?: (values: LeadAttributes) => null | JSX.Element
  phoneWillOnly?: () => boolean
  isOptional?: (outcome?: Outcome) => boolean
}

export const getFormattedAnswer = (
  question: QuestionConfig,
  values: WillLeadAttributes
) => {
  const attributes = values.quoteAttributes || {}
  const attributeName = question.name.split('.')
  attributeName.shift()
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let result: any = attributes

  if (!attributeName) return result

  for (const segment of attributeName) {
    result = result?.[segment] // Access the nested property based on each segment
  }

  if (result === undefined || result === null) {
    return null
  }

  // Address Field is a special field that has nested data structure
  if (question.name === 'quoteAttributes.partner.addressFields') {
    return result.lineOne
      ? `${result.lineOne}, ${result.lineTwo}, ${result.postalCode}, ${result.city}`
      : null
  }

  if ('options' in question) {
    if (!question.options) {
      return result
    }

    const allOptions =
      typeof question.options === 'function'
        ? question.options(values)
        : question.options

    const selectedOption = allOptions.find(
      (option) => option.value === result.toString()
    )

    return selectedOption?.label
  }

  return result
}

export const visibleQuestions = ({
  questions,
  values,
}: {
  questions: Question[]
  values: LeadAttributes
}) => {
  let earlyOutcome: Outcome = null
  return questions.filter((Question) => {
    if (!earlyOutcome && Question.isVisible(values)) {
      earlyOutcome = Question.outcome(values)
      return true
    } else {
      return false
    }
  })
}
