import { useEffect, lazy, Suspense } from 'react'
import styled from 'styled-components'
import { Grid, P, Wrapper } from '@farewill/ui'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { Switch } from 'react-router-dom'

import {
  fetchProbateCase,
  fetchProbateCaseEvents,
  fetchLead,
  unsetProduct,
} from 'state/actions'
import { FAREWILL_PRODUCTS } from 'utils/enums'

import Header from 'components/product-header'
import NavigationTabs from 'components/navigation-tabs'
import PrivateRoute from 'components/private-route/containers'
import NotFoundMessage from 'components/not-found-message'
import TasksPanel from 'components/tasks-panel'
import { TasksProvider } from 'components/tasks-panel/context'
import FWLSBanner from 'components/fwls-banner'

import CaseDetails from './case-details'
import Documents from './documents'
import Errors from './errors'
import Activity from 'components/activity'
import Sale from './sale'
import { GTR } from '@farewill/ui/tokens'

const Forms = lazy(() => import('./forms'))

const StyledWrapper = styled(Wrapper)`
  position: relative;
`

const getNavLinks = (probateCase) => {
  const { id, attributes } = probateCase
  const navLinks = [
    { label: 'Case details', url: `/probate/cases/${id}` },
    { label: 'Activity', url: `/probate/cases/${id}/activity` },
    { label: 'The estate', url: `/probate/estates/${attributes.estate.id}` },
    { label: 'Documents', url: `/probate/cases/${id}/documents` },
    { label: 'Errors', url: `/probate/cases/${id}/errors` },
    { label: 'Forms', url: `/probate/cases/${id}/forms` },
    { label: 'Sale', url: `/probate/cases/${id}/sale` },
  ]

  return navLinks
}

const ProbateCase = ({ match }) => {
  const dispatch = useDispatch()
  const { lead, probateCase } = useSelector(
    (state) => ({
      lead: state.lead.attributes && state.lead,
      probateCase: state.probateCase.attributes && state.probateCase,
    }),
    shallowEqual
  )

  const probateCaseId = match.params.id
  const leadId = probateCase?.attributes.leadId

  useEffect(() => {
    dispatch(fetchProbateCase(probateCaseId))
  }, [dispatch, probateCaseId])

  useEffect(() => {
    dispatch(fetchProbateCaseEvents(probateCaseId))
  }, [dispatch, probateCaseId])

  useEffect(() => {
    if (leadId) dispatch(fetchLead(leadId))
  }, [dispatch, leadId])

  useEffect(() => {
    return () => dispatch(unsetProduct('probate'))
  }, [dispatch])

  if (!probateCase || !lead) return <P size="L">Loading...</P>

  return (
    <StyledWrapper data-sticky-content="probate-case" padding={[GTR.XL, 0, 0]}>
      <Header
        attributes={probateCase.attributes}
        productType="probate"
        showLastCallAt
      />
      <TasksPanel
        queryParams={{
          'filter[probateCaseId]': probateCase.id,
          'filter[isNeeded]': true,
          'page[size]': 200,
        }}
        product={FAREWILL_PRODUCTS.PROBATE}
        productId={{ probateCaseId: probateCase.id }}
        stickyContent="probate-case"
      />
      <Wrapper separator>
        <NavigationTabs links={getNavLinks(probateCase)} />
        <FWLSBanner
          referredToFWLS={
            probateCase.attributes.referredTo === 'farewill_legal_services'
          }
        />
      </Wrapper>
      <Grid separator>
        <Grid.Item span={7}>
          <Suspense fallback={<div>Loading...</div>}>
            <Switch>
              <PrivateRoute path={match.path} component={CaseDetails} exact />
              <PrivateRoute path={`${match.path}/errors`} component={Errors} />
              <PrivateRoute path={`${match.path}/forms`} component={Forms} />
              <PrivateRoute
                path={`${match.path}/documents`}
                component={Documents}
              />
              <PrivateRoute
                path={`${match.path}/activity`}
                component={Activity}
                leadId={leadId}
                product="probate"
                showEmailFilter
                showCallsFilter
                showPaymentsFilter
              />
              <PrivateRoute path={`${match.path}/sale`} component={Sale} />
              <PrivateRoute component={NotFoundMessage} />
            </Switch>
          </Suspense>
        </Grid.Item>
      </Grid>
    </StyledWrapper>
  )
}

const ProbateCaseWithProvider = (props) => (
  <TasksProvider product={FAREWILL_PRODUCTS.PROBATE}>
    <ProbateCase {...props} />
  </TasksProvider>
)

export default ProbateCaseWithProvider
