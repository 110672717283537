import { useDispatch, useSelector } from 'react-redux'
import { Link, useParams } from 'react-router-dom'
import { Divider, H, Grid, Wrapper } from '@farewill/ui'
import { Form, Formik } from 'formik'
import merge from 'lodash/merge'

import { updateFuneralPlan } from 'state/actions'
import { formatValuesForFormik, strToBool } from 'utils/helpers'
import { FuneralPlan, FuneralPlanAttributes } from 'lib/models/funeral-plan'

import CriticalNotes from 'components/critical-notes'
import ContactDetails from './contact-details'
import Vulnerabilities from './vulnerabilities'
import CoveredIndividual from './covered-individual'
import { SaveFuneralPlanField } from './types'
import MessageBox from 'components/message-box'

/* eslint-disable @typescript-eslint/no-explicit-any */
function transformObject(obj: { [key: string]: any }): { [key: string]: any } {
  const result: { [key: string]: any } = {}

  for (const key of Object.keys(obj)) {
    let current: { [key: string]: any } = result
    const parts = key.split('.')

    for (const part of parts) {
      if (!(part in current)) {
        current[part] = {}
      }
      if (parts[parts.length - 1] === part) {
        current[part] = obj[key] !== '' ? obj[key] : null
      } else {
        current = current[part]
      }
    }
  }
  return result
}
/* eslint-enable @typescript-eslint/no-explicit-any */

const CaseDetails = (): React.ReactElement => {
  const { id: funeralPlanId } = useParams<{ id: string }>()
  const dispatch = useDispatch()
  const data = useSelector(
    (state: { funeralPlanCase: FuneralPlan }) => state.funeralPlanCase
  )

  const onSubmit = async (values: FuneralPlanAttributes) =>
    dispatch(
      updateFuneralPlan(funeralPlanId, {
        criticalNotes: values.criticalNotes || null,
        isPostalService: strToBool(values.isPostalService),
      })
    )

  const saveFuneralPlanField: SaveFuneralPlanField = ({ name, value }) => {
    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    let transformedAttributes: { [key: string]: any } = {}

    if (name.split('.').length === 1) {
      transformedAttributes = { [name]: value !== '' ? value : null }
    } else {
      transformedAttributes = transformObject({ [name]: value })
      Object.keys(transformedAttributes).forEach((attrKey) => {
        transformedAttributes[attrKey] = merge(
          data.attributes[attrKey as keyof FuneralPlan['attributes']],
          transformedAttributes[attrKey]
        )
      })
    }

    dispatch(updateFuneralPlan(funeralPlanId, transformedAttributes))
  }

  return (
    <Wrapper>
      <H size="M">Plan details</H>
      <Grid>
        {data.attributes.coupledPlanId && (
          <Grid.Item>
            <MessageBox>
              This plan was purchased as part of a couple.{' '}
              <Link to={`/funeral-plans/${data.attributes.coupledPlanId}`}>
                Go to the linked plan.
              </Link>
            </MessageBox>
          </Grid.Item>
        )}
        <Grid.Item>
          <Formik
            initialValues={formatValuesForFormik(data.attributes)}
            onSubmit={onSubmit}
            enableReinitialize
          >
            <Form>
              <CriticalNotes />
              <Divider margin={['L', 0]} />
              <ContactDetails />
              <Divider margin={['L', 0]} />
              <Vulnerabilities handleSave={saveFuneralPlanField} />
              <Divider margin={['L', 0]} />
              <CoveredIndividual handleSave={saveFuneralPlanField} />
            </Form>
          </Formik>
        </Grid.Item>
      </Grid>
      <Divider margin={['L', 0]} />
    </Wrapper>
  )
}

export default CaseDetails
