import styled from 'styled-components'
import {
  InfoIcon,
  WarningIcon,
  Wrapper,
  CrossIcon,
  TickIcon,
} from '@farewill/ui'
import { COLOR, FONT, GTR, BORDER } from '@farewill/ui/tokens'

type WrapperProps = {
  $warning?: boolean
  $error?: boolean
  $success?: boolean
}

const getBorderColor = ({ $success, $warning, $error }: WrapperProps) => {
  if ($error) return COLOR.STATE.ERROR
  if ($warning) return COLOR.STATE.WARNING
  if ($success) return COLOR.STATE.SUCCESS
  return COLOR.ACCENT.SECONDARY
}

const StyledMessageWrapper = styled(Wrapper)<WrapperProps>`
  display: ${({ $stretch }) => ($stretch ? 'flex' : 'inline-flex')};
  background-color: ${COLOR.WHITE};
  justify-content: space-between;
  border: 2px solid ${(props) => getBorderColor(props)};
  border-radius: ${BORDER.RADIUS.S};
  padding: ${GTR.S};
  font-size: ${FONT.SIZE.S};
  ${({ $bold }) => $bold && `font-weight: ${FONT.WEIGHT.BOLD}`}
`

const StyledIconWrapper = styled.div`
  && {
    margin: -2px -2px -2px ${GTR.M};
  }
`

type BaseProps = {
  children: React.ReactNode
  bold?: boolean
  stretch?: boolean
  margin?: string | number | (string | number)[]
  hideIcon?: boolean
}

type InfoProps = BaseProps & {
  warning?: never
  error?: never
  success?: never
}

type WarningProps = BaseProps & {
  warning: true
  error?: never
  success?: never
}

type ErrorProps = BaseProps & {
  error: true
  warning?: never
  success?: never
}

type SuccessProps = BaseProps & {
  success: true
  warning?: never
  error?: never
}

const MessageBox = ({
  children,
  warning,
  error,
  success,
  bold,
  stretch,
  margin,
  hideIcon,
}:
  | InfoProps
  | WarningProps
  | ErrorProps
  | SuccessProps): React.ReactElement => {
  return (
    <StyledMessageWrapper
      $warning={warning}
      $error={error}
      $bold={bold}
      $success={success}
      $stretch={stretch}
      margin={margin}
    >
      <Wrapper>{children}</Wrapper>
      {!hideIcon && (
        <StyledIconWrapper>
          {error && (
            <CrossIcon
              color={COLOR.WHITE}
              background={COLOR.STATE.ERROR}
              size="S"
            />
          )}
          {warning && <WarningIcon background={COLOR.STATE.WARNING} size="S" />}
          {success && (
            <TickIcon
              background={COLOR.STATE.SUCCESS}
              size="S"
              color={COLOR.WHITE}
            />
          )}
          {!error && !warning && !success && (
            <InfoIcon
              color={COLOR.WHITE}
              background={COLOR.ACCENT.SECONDARY}
              size="S"
            />
          )}
        </StyledIconWrapper>
      )}
    </StyledMessageWrapper>
  )
}

export default MessageBox
