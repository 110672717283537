import { useCallback, useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { useFormikContext, useField } from 'formik'
import Select, { components } from 'react-select'
import type {
  MultiValueRemoveProps,
  DropdownIndicatorProps,
  OptionProps,
  ValueContainerProps,
} from 'react-select'
import uniqueId from 'lodash/uniqueId'
import isArray from 'lodash/isArray'

import Label from 'components/styled/label'
import {
  FloatingWrapper,
  FloatingLabel,
  FloatingHint,
} from 'components/styled/helpers/floating'
import ErrorSmall from 'components/form/error-small'
import Error from 'components/form/error'
import { getFieldState } from 'components/form/helpers'

import { getStyles } from './styles'
import type {
  Option,
  SelectedSingle,
  SelectedMulti,
  Selected,
  SelectInputOptions,
} from './types'
import { ChevronDownIcon, CrossIcon } from '@farewill/ui'
import styled from 'styled-components'

const StyledIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 14px;
  padding-top: 2px;
`

const DropdownIndicator = (props: DropdownIndicatorProps<Option, boolean>) => {
  return (
    <components.DropdownIndicator {...props}>
      <ChevronDownIcon width={12} height={12} />
    </components.DropdownIndicator>
  )
}

const MultiValueRemove = (props: MultiValueRemoveProps<Option>) => {
  return (
    <components.MultiValueRemove {...props}>
      <StyledIconWrapper>
        <CrossIcon width={12} height={12} />
      </StyledIconWrapper>
    </components.MultiValueRemove>
  )
}

const SelectOption = (props: OptionProps<Option>) => (
  <components.Option {...props}>
    {props.children} {props.data.suffix}
  </components.Option>
)

const ValueContainer = (props: ValueContainerProps<Option>) => (
  <components.ValueContainer {...props}>
    {props.children}
    {props.isMulti === false && props.getValue()[0]?.suffix}
  </components.ValueContainer>
)

const flattenOptions = (options: SelectInputOptions): SelectInputOptions => {
  return options.reduce((acc: SelectInputOptions, option: Option) => {
    if (option.options) return [...acc, ...option.options]
    return [...acc, option]
  }, [])
}

const findSingle = ({
  options,
  selected = '',
}: {
  options: SelectInputOptions
  selected: SelectedSingle
}): Option | null => {
  return (
    flattenOptions(options).find(
      (option: Option) => option.value === selected
    ) || null
  )
}

const findMultiple = ({
  options,
  selected = [],
}: {
  options: SelectInputOptions
  selected: SelectedMulti
}) => {
  if (!isArray(selected)) return []

  return flattenOptions(options).filter((option: Option) =>
    selected.includes(option.value)
  )
}

export type Props = {
  disabled?: boolean
  fetchOptions?: () => Promise<SelectInputOptions>
  floating?: boolean
  hint?: string
  label?: string
  name: string
  isMulti?: boolean
  handleSave?: ({ name, value }: { name: string; value: Selected }) => void
  options?: SelectInputOptions
  small?: boolean
  isClearable?: boolean
  noOptionsMessage?: () => string
  autoFocus?: boolean
  isOptionDisabled?: (option: Option) => boolean
  onChange?: (selected: Option | SelectInputOptions | null) => void
}

export const SelectInput = ({
  disabled = false,
  fetchOptions,
  floating = false,
  hint,
  label,
  name,
  isMulti = false,
  handleSave,
  options: optionsFromProps,
  small = false,
  isClearable = false,
  noOptionsMessage,
  autoFocus = false,
  isOptionDisabled,
  onChange,
}: Props): React.ReactElement => {
  const [hasFocus, setHasFocus] = useState(false)
  const [options, setOptions] = useState<SelectInputOptions>([])
  const { errors, setFieldValue, status, touched, values } = useFormikContext()
  const [field] = useField(name)

  const { hasError, hasHighlight, hasValue } = getFieldState({
    formik: { errors, touched, status, values },
    name,
  })

  const styles = getStyles({
    floating,
    hasError,
    hasHighlight,
    small,
    isMulti,
  })

  const makeFetchRequest = useCallback(async () => {
    if (!fetchOptions) return
    const fetchedOptions = await fetchOptions()
    setOptions(fetchedOptions)
  }, [fetchOptions])

  const handleChange = (selected: Option | SelectInputOptions | null): void => {
    let newValue

    if (!selected) {
      newValue = null
    } else if (selected instanceof Array) {
      newValue = selected.map((option: Option) => option.value)
    } else {
      newValue = selected?.value
    }

    setFieldValue(name, newValue)
    if (handleSave) handleSave({ name, value: newValue })
  }

  useEffect(() => {
    if (fetchOptions) {
      makeFetchRequest()
    } else if (optionsFromProps) {
      setOptions(optionsFromProps)
    }
  }, [fetchOptions, makeFetchRequest, optionsFromProps])

  const selectedOption = isMulti
    ? findMultiple({ options, selected: field.value })
    : findSingle({ options, selected: field.value })

  const labelId = useRef(uniqueId(`${name}-label`))

  return (
    <FloatingWrapper highlight={hasHighlight}>
      {!floating && (
        <Label id={labelId.current} $error={hasError}>
          {label}
        </Label>
      )}
      <Select
        aria-labelledby={labelId.current}
        autoFocus={autoFocus}
        isDisabled={disabled}
        menuPlacement="auto"
        onChange={onChange || handleChange}
        onBlur={() => setHasFocus(false)}
        onFocus={() => {
          setHasFocus(true)
          fetchOptions && makeFetchRequest()
        }}
        options={options}
        value={selectedOption}
        styles={styles}
        placeholder={floating ? '' : 'Select...'}
        isMulti={isMulti}
        classNamePrefix="react-select"
        isClearable={isClearable}
        noOptionsMessage={noOptionsMessage}
        isOptionDisabled={isOptionDisabled}
        components={{
          MultiValueRemove,
          DropdownIndicator,
          Option: SelectOption,
          ValueContainer,
        }}
      />

      {floating && (
        <FloatingLabel full={hasValue || hasFocus}>{label}</FloatingLabel>
      )}
      {hint && <FloatingHint>{hint}</FloatingHint>}
      {floating ? <ErrorSmall name={name} /> : <Error name={name} />}
    </FloatingWrapper>
  )
}

SelectInput.propTypes = {
  autoFocus: PropTypes.bool,
  disabled: PropTypes.bool,
  fetchOptions: PropTypes.func,
  floating: PropTypes.bool,
  handleSave: PropTypes.func,
  hint: PropTypes.string,
  isClearable: PropTypes.bool,
  isMulti: PropTypes.bool,
  isOptionDisabled: PropTypes.func,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  noOptionsMessage: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      options: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string.isRequired,
          value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
            .isRequired,
        })
      ),
    })
  ),
  small: PropTypes.bool,
}

export default SelectInput
