import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Button, DeleteIcon, P, Wrapper } from '@farewill/ui'
import { BORDER, COLOR, FONT, GTR } from '@farewill/ui/tokens'

const StyledCard = styled(Wrapper)`
  box-shadow: ${BORDER.SHADOW.M};
  border-radius: ${BORDER.RADIUS.S};
  padding: 18px 20px ${GTR.M};
  margin: 0 -20px;
  background-color: ${COLOR.WHITE};

  &:not(:last-of-type) {
    margin-bottom: ${GTR.S};
  }
`

const StyledHeading = styled(P)`
  font-weight: ${FONT.WEIGHT.BOLD};
  text-transform: uppercase;
  font-size: ${FONT.SIZE.XS};
  letter-spacing: 1.2px;
  color: ${COLOR.GREY.MEDIUM};
  margin-bottom: 0;
`

const StyledHeadingWrapper = styled(Wrapper)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${GTR.S};
`

const StyledButton = styled(Button)`
  font-size: 0;
`

export type Props = {
  children: React.ReactNode
  listLength: number
  listPosition: number
  onTrashClick?: () => void
  type: string
}

export const EstateCard = ({
  children,
  listLength,
  listPosition,
  onTrashClick,
  type,
}: Props): React.ReactElement => (
  <StyledCard
    data-testid={`${type.replace(/ /g, '-').toLowerCase()}-${listPosition}`}
  >
    <StyledHeadingWrapper>
      <StyledHeading>
        {type} {listPosition} of {listLength}
      </StyledHeading>
      {onTrashClick && (
        <StyledButton
          type="button"
          onClick={onTrashClick}
          data-testid="delete-card-button"
        >
          <DeleteIcon size="S" />
          Delete
        </StyledButton>
      )}
    </StyledHeadingWrapper>
    {children}
  </StyledCard>
)

EstateCard.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  listLength: PropTypes.number.isRequired,
  listPosition: PropTypes.number.isRequired,
  onTrashClick: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
}

export default EstateCard
