import { Grid, H } from '@farewill/ui'
import { useFormikContext } from 'formik'
import { useQueryClient } from 'react-query'

import SelectInput from 'components/form/select-input'
import { AdminUser } from 'lib/models/admin-user'
import { formatAdminUserOptions } from 'utils/helpers'

const CaseOwners = () => {
  const { handleSubmit } = useFormikContext()
  const cache = useQueryClient()
  const adminUsers = cache.getQueryData('adminUsers') as AdminUser[]
  const adminUserOptions = formatAdminUserOptions(adminUsers)

  return (
    <>
      <Grid.Item>
        <H size="S">Case owner</H>
      </Grid.Item>
      <Grid.Item spanFromL={6}>
        <SelectInput
          options={adminUserOptions}
          label="Case owner - pre-referral"
          name="preReferralOwnedByAdminUserId"
          handleSave={() => handleSubmit()}
          isClearable
        />
      </Grid.Item>
    </>
  )
}

export default CaseOwners
