import styled from 'styled-components'
import { COLOR, FONT, GTR } from '@farewill/ui/tokens'

export const Label = styled.label<{ $error: boolean }>`
  margin-bottom: ${GTR.XS};

  ${({ $error }) =>
    $error &&
    `
    color: ${COLOR.STATE.ERROR};
    font-weight: ${FONT.WEIGHT.MEDIUM};
  `}
`

export default Label
