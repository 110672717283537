import { useFormikContext } from 'formik'
import { Link } from 'react-router-dom'
import { Grid } from '@farewill/ui'

import Input from 'components/form/input'
import MessageBox from 'components/message-box'
import { ProbateLeadAttributes } from 'lib/models/lead'
import { strToBool } from 'utils/helpers'

import Tooltip from 'components/tooltip'
import {
  PROBATE_MARITAL_STATUS_OPTIONS,
  WIDOWED,
  YES_NO_OPTIONS,
} from 'utils/enums'
import { RadioGroup, SelectInput } from 'components/form'
import SectionHeading from './section-heading'
import { GTR } from '@farewill/ui/tokens'
import styled from 'styled-components'
import { useEffect } from 'react'

const StyledList = styled.ul`
  font-size: ${GTR.S};
  padding-top: ${GTR.XXS};

  li {
    padding: ${GTR.XXS} 0;

    &::before {
      content: '•';
      margin-right: 0.5rem;
    }
  }
`

const PersonWhoDied = ({
  isComplete,
}: {
  isComplete: boolean
}): React.ReactElement => {
  const { handleSubmit, setFieldValue, values } =
    useFormikContext<ProbateLeadAttributes>()

  const { contact } = values
  const {
    dateOfDeath,
    deceasedFirstName,
    deceasedLastName,
    funeralId,
    maritalStatus,
    widowSpouseLeftEverythingInWill,
  } = values.quoteAttributes
  const diedAfter2021 = strToBool(values.quoteAttributes?.diedAfter2021)

  const dateOfDeathMessage =
    diedAfter2021 === null || diedAfter2021 === undefined || dateOfDeath
      ? null
      : `Customer gave date of death as ${
          diedAfter2021 ? 'on or after' : 'before'
        } 1 January 2022.`

  useEffect(() => {
    if (maritalStatus !== WIDOWED) {
      setFieldValue('quoteAttributes.widowSpouseLeftEverythingInWill', '')
    }
  }, [setFieldValue, maritalStatus])

  return (
    <Grid>
      <Grid.Item>
        <Grid>
          <SectionHeading
            title="2. The person who has died"
            scrollId="person-who-died"
            isComplete={isComplete}
          />

          <Grid.Item>
            What was the{' '}
            <Tooltip content="Legal name is the name on passports, driving licenses, etc.">
              legal name
            </Tooltip>{' '}
            of the person who died?
          </Grid.Item>
          <Grid.Item spanFromL={2}>
            <Input
              label="Title"
              name="quoteAttributes.deceasedTitle"
              handleSave={() => handleSubmit()}
            />
          </Grid.Item>
          <Grid.Item spanFromL={5}>
            <Input
              label="First + middle names"
              name="quoteAttributes.deceasedFirstName"
              handleSave={() => handleSubmit()}
              allowCopyToClipboard
            />
          </Grid.Item>
          <Grid.Item spanFromL={5}>
            <Input
              label="Last name"
              name="quoteAttributes.deceasedLastName"
              handleSave={() => handleSubmit()}
              allowCopyToClipboard
            />
          </Grid.Item>
          {funeralId && (
            <Grid.Item>
              <MessageBox>
                ⚱️ {contact.firstName} booked this probate callback while
                arranging {deceasedFirstName} {deceasedLastName}’s funeral with
                us.{' '}
                <Link to={`/funerals/${funeralId}`}>Go to funeral case</Link>.
              </MessageBox>
            </Grid.Item>
          )}
          <Grid.Item spanFromL={4}>
            <Input
              label="Date of birth"
              name="quoteAttributes.dateOfBirth"
              type="date"
              handleSave={() => handleSubmit()}
            />
          </Grid.Item>
          {dateOfDeathMessage && (
            <Grid.Item>
              <MessageBox>{dateOfDeathMessage}</MessageBox>
            </Grid.Item>
          )}
          <Grid.Item startColumn={1} spanFromL={4}>
            <Input
              label="Date of death"
              name="quoteAttributes.dateOfDeath"
              type="date"
              handleSave={() => handleSubmit()}
            />
          </Grid.Item>
        </Grid>
      </Grid.Item>
      <Grid.Item>
        <RadioGroup
          name="quoteAttributes.livedInEnglandOrWales"
          label={
            <>
              Was their permanent home in{' '}
              <Tooltip content="Answer ‘no’ if Scotland, Northern Ireland, Ireland, Isle of Man, Channel Islands or elsewhere.">
                England or Wales
              </Tooltip>
              ?
            </>
          }
          options={YES_NO_OPTIONS}
          handleSave={() => handleSubmit()}
        />
      </Grid.Item>
      <Grid.Item span={5}>
        <SelectInput
          label="What was their legal marital status?"
          name="quoteAttributes.maritalStatus"
          options={PROBATE_MARITAL_STATUS_OPTIONS}
          handleSave={() => handleSubmit()}
        />
      </Grid.Item>
      {maritalStatus === WIDOWED && (
        <Grid.Item>
          <RadioGroup
            label="Did the first spouse to die leave everything to the surviving spouse?"
            name="quoteAttributes.widowSpouseLeftEverythingInWill"
            options={YES_NO_OPTIONS}
            handleSave={() => {
              handleSubmit()
            }}
          />
        </Grid.Item>
      )}
      {widowSpouseLeftEverythingInWill === 'false' && (
        <Grid.Item>
          <MessageBox margin={['L', 0, 0]}>
            You need to calculate whether there's a Transferable Nil Rate Band:
            <StyledList>
              <li>
                Make notes on the breakdown of who inherited what on the first
                spouse's death.
              </li>
              <li>
                Ask whether there was a life interest trust in the first
                spouse's will.
              </li>
              <li>
                Check if there's sufficient Transferrable Nil Rate Band
                available and if not, manually change the tax form to be IHT400.
              </li>
            </StyledList>
          </MessageBox>
        </Grid.Item>
      )}
    </Grid>
  )
}

export default PersonWhoDied
