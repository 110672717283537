import styled from 'styled-components'
import { H, P, Wrapper } from '@farewill/ui'
import { COLOR, FONT, GTR } from '@farewill/ui/tokens'

import AIIcon from 'components/ai-icon'
import Badge from 'components/styled/badge'

import { Lead } from 'lib/models/lead'
import { GeneratedNotes, LeadEvent } from 'lib/models/lead-event'

const StyledNotes = styled(Wrapper)`
  padding: ${GTR.M};
  border-radius: ${GTR.S};
  background: linear-gradient(180deg, ${COLOR.ACCENT.PRIMARY_10}, #ffffff);
  border: 1px solid ${COLOR.ACCENT.PRIMARY};
  margin-bottom: ${GTR.XS};
  position: relative;
`

const StyledH = styled(H)`
  font-size: ${FONT.SIZE.S};
`

const StyledBadge = styled(Badge)`
  position: absolute;
  top: ${GTR.M};
  right: ${GTR.M};
`

const StyledExclaimer = styled(P)`
  padding: 0 ${GTR.M};
  font-size: ${FONT.SIZE.XS};
`

export type OpenAIResponse = {
  type: string
  attributes: {
    aiNotes: GeneratedNotes
  }
}

const AIGeneratedNotesSummary = ({
  lead,
  leadEvents,
}: {
  lead: Lead
  leadEvents: LeadEvent[]
}) => {
  const notes = lead?.attributes?.aiNotesSummary
  const unansweredCallAttempts = leadEvents?.filter(
    (event) => event.attributes?.metadata?.disposition === 'no_answer'
  )?.length

  return notes ? (
    <div>
      <StyledNotes>
        <H tag="h2" size="XS">
          <AIIcon /> AI Notes Summary
        </H>

        {Object.entries(notes || {}).map(
          ([key, value], index, array) =>
            value?.length > 0 && (
              <Wrapper
                key={key}
                margin={index === array?.length - 1 ? 0 : [0, 0, 'S']}
              >
                <StyledH tag="h3" size="XS" margin={[0, 0, 'XS']}>
                  {key}
                </StyledH>
                {/**
                 * Personal details section is a special case where we want to
                 * display title and body in the same line. For that reason we are
                 * using a different layout for this section.
                 */}
                {value.map((item, index) =>
                  key === 'Personal Details' ? (
                    <Wrapper key={index} padding={[0, 0, 0, 'XS']}>
                      <P size="S">
                        {item?.title && item.title !== '' && `${item.title}:`}{' '}
                        {item?.body}
                      </P>
                    </Wrapper>
                  ) : (
                    <Wrapper key={index} padding={[0, 0, 0, 'XS']}>
                      {item?.title && item.title !== '' && (
                        <P size="S" margin={['S', 0, 'XS']} strong>
                          {item.title}
                        </P>
                      )}
                      <P size="S">{item?.body}</P>
                    </Wrapper>
                  )
                )}
              </Wrapper>
            )
        )}
        {unansweredCallAttempts > 0 && (
          <Wrapper margin={['S', 0, 0]}>
            <StyledH tag="h3" size="XS" margin={[0, 0, 'XS']}>
              Call attempts with no answer
            </StyledH>
            <Wrapper padding={[0, 0, 0, 'XS']}>
              <P size="S">
                We have made {unansweredCallAttempts} unsuccessful{' '}
                {unansweredCallAttempts > 1 ? 'attempts' : 'attempt'} to reach
                the customer via phone.
              </P>
            </Wrapper>
          </Wrapper>
        )}
        <StyledBadge $state="primary">Beta</StyledBadge>
      </StyledNotes>
      <StyledExclaimer color={COLOR.GREY.MEDIUM}>
        The AI summary of notes may contain inaccuracies or incomplete
        information. Please use it as a reference and verify critical details
        independently.
      </StyledExclaimer>
    </div>
  ) : null
}

export default AIGeneratedNotesSummary
