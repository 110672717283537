import { RadioGroup } from '@farewill/ui'
import { StringBoolean } from 'lib/types/common'
import Error from './error'

/** A simple radio group for Yes or No questions */
export const RadioGroupYesNoField = ({
  values,
  errors,
  label,
  name,
  handleChange,
  initialValue,
}: {
  values: Record<string, string>
  errors: Record<string, string>
  label: string
  name: string
  handleChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  initialValue?: StringBoolean
}) => {
  return (
    <>
      <RadioGroup
        legend={label}
        name={name}
        horizontal
        options={[
          { value: 'true' as StringBoolean, label: 'Yes' },
          { value: 'false' as StringBoolean, label: 'No' },
        ]}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          handleChange && handleChange(e)
          values[name] = e.target.value as StringBoolean
        }}
        value={initialValue}
      />
      {errors[name] && <Error name={name} />}
    </>
  )
}
