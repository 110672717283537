import { PropsWithChildren, useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { AttachmentIcon, Button, Divider, H, P, Wrapper } from '@farewill/ui'
import { COLOR, FONT, GTR } from '@farewill/ui/tokens'
import {
  fetchFuneralPlanDocuments,
  generateFuneralPlanDocuments,
} from 'state/actions'
import { Event } from 'lib/models/event'
import { formatToHuman } from 'utils/helpers'
import ActivityTimestamp from 'components/activity-timestamp'
import MessageBox from 'components/message-box'
import { Download } from './documents-download'

const StyledDocumentRow = styled.ul`
  align-items: center;
  display: grid;
  font-size: ${FONT.SIZE.XS};
  grid-template-columns: 60px 1fr;
  margin-bottom: ${GTR.XS};
  padding: ${GTR.XS} 0;
  width: 100%;
`

const StyledIconWrapper = styled.div`
  position: relative;
`

const StyledIcon = styled.div`
  background-color: white;
  border-radius: 3px;
  border: 2px solid ${COLOR.GREY.LIGHT};
  height: 46px;
  text-decoration: none;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:not(:first-child) {
    left: 3px;
    position: absolute;
    top: -3px;
    z-index: -1;
  }
`

const documentLinkStyles = css`
  display: inline-block;
  font-size: ${FONT.SIZE.M};
  line-height: 1;
  margin-bottom: ${GTR.XXS};
`

const StyledExternalDocumentLink = styled(Button.Underline)`
  ${documentLinkStyles}
  padding-top: 0;
  padding-bottom: ${GTR.XXS};
`

const StyledActivityTimestamp = styled(ActivityTimestamp)`
  color: ${COLOR.GREY.DARK};
  font-size: ${FONT.SIZE.XS};
`

const SectionWrapper = ({
  children,
  handleGenerateDocuments,
}: PropsWithChildren<{ handleGenerateDocuments?: () => void }>) => (
  <div>
    <H size="M">Documents</H>
    {children}
    <>
      {handleGenerateDocuments && (
        <Wrapper margin={['M', 0]}>
          <Divider />
          <MessageBox margin={['M', 0]}>
            If there are documents missing or if the details of this funeral
            plan have changed you can generate a new set of documents by
            clicking the button below.
          </MessageBox>
          <Button.Primary onClick={handleGenerateDocuments}>
            Generate documents
          </Button.Primary>
        </Wrapper>
      )}
    </>
  </div>
)

const Documents = () => {
  const { id: funeralPlanId } = useParams<{ id: string }>()
  const [showDownloadIframe, setShowDownloadIframe] = useState<boolean>(false)
  const [fileTitle, setFileTitle] = useState<string>()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const dispatch = useDispatch()
  const document = useSelector(
    (state: { funeralPlanDocuments: Event }) => state.funeralPlanDocuments
  )

  useEffect(() => {
    dispatch(fetchFuneralPlanDocuments(funeralPlanId))
  }, [dispatch, funeralPlanId])

  const triggerDocumentDownload = (title: string) => {
    // We can ensure every button click triggers a new download, even if the
    // title stays the same, by removing and remounting the
    // download iframe. Because there is no reliable way to check if a document
    // download is complete, we can hide the download iframe on a new button
    // click and then trigger the remounting with the useEffect hook below.
    setShowDownloadIframe(false)
    setFileTitle(title)
  }

  const handleGenerateDocuments = async () => {
    setIsLoading(true)
    try {
      await dispatch(generateFuneralPlanDocuments(funeralPlanId))
      dispatch(fetchFuneralPlanDocuments(funeralPlanId))
    } catch {
      throw Error('Error generating documents')
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (!showDownloadIframe) {
      setShowDownloadIframe(true)
    }
  }, [showDownloadIframe])

  if (isLoading) {
    return <SectionWrapper>Loading...</SectionWrapper>
  }

  if (
    !document?.attributes?.metadata?.titles ||
    document.attributes.metadata.titles.length === 0
  ) {
    return (
      <SectionWrapper handleGenerateDocuments={handleGenerateDocuments}>
        <Wrapper>
          <P>No documents have been generated yet</P>
        </Wrapper>
      </SectionWrapper>
    )
  }

  return (
    <SectionWrapper handleGenerateDocuments={handleGenerateDocuments}>
      {document.attributes.metadata.titles.map(
        (title) =>
          title && (
            <StyledDocumentRow key={title}>
              <StyledIconWrapper>
                <StyledIcon>
                  <AttachmentIcon color={COLOR.GREY.LIGHT} />
                </StyledIcon>
              </StyledIconWrapper>
              <div>
                <StyledExternalDocumentLink
                  flush
                  onClick={() => triggerDocumentDownload(title)}
                >
                  {formatToHuman(title)}
                </StyledExternalDocumentLink>
                <div>
                  Added{' '}
                  <StyledActivityTimestamp
                    datetime={document.attributes.createdAt}
                  />
                </div>
              </div>
            </StyledDocumentRow>
          )
      )}
      {showDownloadIframe && fileTitle && <Download title={fileTitle} />}
    </SectionWrapper>
  )
}

export default Documents
