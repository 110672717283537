import omit from 'lodash/omit'
import partition from 'lodash/partition'
import moment from 'moment'
import { useEffect } from 'react'

import { RESOURCES, useFetchArrayResource } from 'api'
import { AircallEvent } from 'lib/models/aircall-event'
import { Comment, CommentAttributes } from 'lib/models/comment'
import { Event } from 'lib/models/event'
import { Task } from 'lib/models/task'
import { FAREWILL_PRODUCTS } from 'utils/enums'
import { Response, flattenAttributes } from 'utils/helpers'

type GroupedComments = {
  [key: string]: Response[]
}

export type Activity = { attributes: object; id?: number; type?: string }

export const isEdited = (
  createdAt: string,
  updatedAt: string
): { edited: boolean; lastInteractedWith: string } => {
  const edited = updatedAt > createdAt

  return {
    edited,
    lastInteractedWith: edited ? updatedAt : createdAt,
  }
}

const getActivityDate = (activity: Activity): string | null => {
  if (activity.type === 'tasks')
    return (activity as Task).attributes.completedAt

  if (activity.type === 'aircall')
    // convert the Unix timestamp to an ISO string
    return new Date(
      (activity as AircallEvent).attributes.started_at * 1000
    ).toISOString()

  if ((activity as Comment).attributes?.replies?.length) {
    const [lastReply] = (activity as Comment).attributes.replies.slice(-1)
    return lastReply.createdAt
  }

  return (activity as { attributes: { createdAt: string } }).attributes
    .createdAt
}

export const sortByActivityDate = (a: Activity, b: Activity): 1 | -1 => {
  return moment(getActivityDate(a)).isBefore(getActivityDate(b)) ? 1 : -1
}

export const useEvents = ({
  product,
  params,
}: {
  product: string
  params: string
}): Event[] => {
  const { data: events, refetch: refetchEvents } = useFetchArrayResource(
    {
      resource: RESOURCES.EVENTS,
      params,
    },
    { enabled: false, initialData: { data: [] } }
  )

  useEffect(() => {
    if (product === FAREWILL_PRODUCTS.FUNERAL_PLAN) {
      refetchEvents()
    }
  }, [product, refetchEvents])

  return events?.data as Event[]
}

const isNewReply = ({
  attributes,
}: {
  attributes: CommentAttributes
}): boolean => !!attributes.parentId

export const getCaseCommentThreads = ({
  groupedComments,
  isCall,
}: {
  groupedComments: GroupedComments
  isCall: boolean
}): { attributes: object }[] => {
  const [newReplies, comments] = partition(groupedComments.null, isNewReply)

  const filteredComments = (comments as Comment[]).filter(
    (comment) => comment.attributes.isCall === isCall
  )

  return filteredComments.map((comment) => {
    const replies = [
      ...comment.attributes.replies,
      ...(newReplies as Response[])
        .filter(
          ({ attributes }) =>
            'parentId' in attributes && attributes.parentId === comment.id
        )
        .map(flattenAttributes),
    ]

    return {
      ...comment,
      attributes: { ...comment.attributes, replies },
    }
  })
}

export const getTaskCommentThreads = (
  groupedComments: GroupedComments
): { attributes: object }[] => {
  return Object.values(omit(groupedComments, 'null')).map((group) => {
    const parent = group[0]
    const replies = group.slice(1).map(flattenAttributes)

    return {
      ...parent,
      attributes: { ...parent.attributes, replies },
    }
  })
}
