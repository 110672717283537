import React from 'react'
import { useParams } from 'react-router-dom'
import { Button, Divider, H, P, Wrapper } from '@farewill/ui'

import { RESOURCES, useArrayResource } from 'api'
import { WillUpdate } from 'lib/models/will-update'
import RequestStatus from 'components/request-status'

import WillUpdateCard from './will-update-card'

const WillUpdates = (): React.ReactElement => {
  const { willId } = useParams<{ willId: string }>()

  const {
    query: willUpdatesQuery,
    updateMutation: willUpdatesUpdateMutation,
    createMutation: willUpdatesCreateMutation,
    deleteMutation: willUpdatesDeleteMutation,
    isLoading: willUpdatesIsLoading,
  } = useArrayResource<WillUpdate>({
    resource: RESOURCES.WILL_UPDATES,
    params: `filter[willId]=${willId}`,
  })

  const createWillUpdate = (willId: number) => {
    willUpdatesCreateMutation.mutate({
      attributes: { willId },
      resource: RESOURCES.WILL_UPDATES,
    })
  }

  if (willUpdatesQuery.isLoading || willUpdatesQuery.isIdle)
    return <P>Loading...</P>

  const willUpdates = willUpdatesQuery.data?.data

  return (
    <Wrapper>
      <H size="M">Will Updates</H>
      <P>
        Record reasons when a new version of the will is sent to the customer.
      </P>
      <Divider />
      {willUpdates?.map((willUpdate, index) => (
        <WillUpdateCard
          key={willUpdate.id}
          willUpdate={willUpdate}
          listPosition={index + 1}
          listLength={willUpdates.length}
          willUpdatesUpdateMutation={willUpdatesUpdateMutation}
          willUpdatesDeleteMutation={willUpdatesDeleteMutation}
        />
      ))}
      <Wrapper margin={['M', 0, 0]}>
        <Button.Secondary wide onClick={() => createWillUpdate(Number(willId))}>
          Add will update
        </Button.Secondary>
      </Wrapper>
      <RequestStatus isSaving={willUpdatesIsLoading} />
    </Wrapper>
  )
}

export default WillUpdates
