import upperFirst from 'lodash/upperFirst'

import { CaseStatusTransitionAttributes } from 'lib/models/case-status-transition'
import { COLD_REASONS, CANCELLED_REASONS } from './constants'
import { NewTransitionFormFields, CaseStatusFields } from './types'

const ALL_REASONS = [...COLD_REASONS, ...CANCELLED_REASONS]

export const getReasonText = (
  transition: CaseStatusTransitionAttributes
): string => {
  const { reason, reasonDescription } = transition
  const label = ALL_REASONS.find(({ value }) => value === reason)?.label
  return reasonDescription || label || upperFirst(reason)
}

export const getTransitionAttributes = (
  values: NewTransitionFormFields,
  willCaseId: number
): {
  reason: string
  reasonDescription: string | null
  toStatus: string
  willCaseId: number
} => {
  const toStatus = values.status as keyof CaseStatusFields
  return {
    reason: values[toStatus].reason,
    reasonDescription: values[toStatus].reasonDescription || null,
    toStatus,
    willCaseId,
  }
}
