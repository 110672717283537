import { useFormikContext } from 'formik'
import { H, Wrapper, P } from '@farewill/ui'

import Input from 'components/form/input'
import SimpleList from 'components/styled/simple-list'

import Preview from './image-upload/preview'
import DropZone from './image-upload/drop-zone'
import { Values } from '../interfaces'
import Switch from 'components/switch'
import { COLOR, GTR } from '@farewill/ui/tokens'
import styled from 'styled-components'

const StyledSwitchWrapper = styled(Wrapper)`
  display: flex;
  align-items: flex-end;
`

const GiftPrompt = (): React.ReactElement => {
  const { values, setFieldValue } = useFormikContext<Values>()

  return (
    <Wrapper>
      <Wrapper margin={[0, 0, 'M']}>
        <H size="S">Gift Prompt</H>
        <StyledSwitchWrapper>
          <Switch
            color={COLOR.ACCENT.SECONDARY}
            handleToggle={() => {
              setFieldValue('isGiftPrompt', !values.isGiftPrompt)
            }}
            isOn={values.isGiftPrompt}
          />
          <P size="M" margin={[0, 0, 0, GTR.XS]}>
            Show a bespoke gift prompt for this {values.type}
          </P>
        </StyledSwitchWrapper>
      </Wrapper>
      {values.isGiftPrompt && (
        <>
          <Wrapper margin={[GTR.M, 0]}>
            <H size="XS">Bespoke gift prompt text</H>
            <Wrapper margin={[0, 0, 'M']}>
              <Input
                name="giftPromptDescription"
                component="textarea"
                label="Add up to a maximum of 160 characters. Add two asterisks before and after a word or phrase for **bold text**."
                rows={4}
                maxCharacters={160}
                type="text"
              />
            </Wrapper>
            <H size="XS">Image</H>
            <P>
              Add a high resolution image exactly 400px x 400px in size.
              Acceptable image formats:
            </P>
            <SimpleList bullets>
              <li>JPEG</li>
              <li>PNG with a non-transparent background</li>
            </SimpleList>
          </Wrapper>

          {values.giftPromptImage || values.giftPromptImageUrl ? (
            <Preview
              blob={values.giftPromptImage}
              existingUploadUrl={values.giftPromptImageUrl}
              name="image"
              onDelete={(isUnsavedImage: boolean) => {
                if (isUnsavedImage) {
                  setFieldValue('giftPromptImage', null)
                } else {
                  setFieldValue('giftPromptImageUrl', null)
                }
              }}
            />
          ) : (
            <DropZone
              formikField="giftPromptImage"
              name="image"
              imageWidth={400}
              imageHeight={400}
            />
          )}
        </>
      )}
    </Wrapper>
  )
}

export default GiftPrompt
