import { toast } from 'react-toastify'
import { Button, Wrapper } from '@farewill/ui'
import styled from 'styled-components'
import { nanoid } from 'nanoid'
const StyledHidden = styled.div`
  display: none;
`

type Properties = 'innerHTML' | 'textContent'

const handleCopyClick = (copyHash: string, propertyToCopy: Properties) => {
  const hiddenText = document.getElementById(`${copyHash}-hidden`)?.[
    propertyToCopy
  ]
  const text = document.getElementById(copyHash)?.[propertyToCopy]
  const allText = `${hiddenText}${hiddenText && text ? '\n' : ''}${text}`
  const htmlBlob = new Blob([allText], { type: 'text/html' })
  const textBlob = new Blob([allText], { type: 'text/plain' })
  navigator.clipboard.write([
    new ClipboardItem({
      'text/html': htmlBlob,
      'text/plain': textBlob,
    }),
  ])

  toast('Copied to clipboard.', {
    toastId: 'copied-to-clipboard',
  })
}

const CopyToClipboard = ({
  buttonText = 'Copy to clipboard',
  CustomButton,
  className,
  hiddenChildren,
  visibleChildren,
  propertyToCopy = 'innerHTML',
}: {
  buttonText?: string
  CustomButton?: React.FunctionComponent<{ onClick: () => void }>
  className?: string
  hiddenChildren?: React.ReactElement | string
  visibleChildren?: React.ReactElement
  propertyToCopy?: Properties
}) => {
  const copyHash = `copy-to-clipboard-${nanoid(10)}`
  return (
    <Wrapper className={className}>
      <Wrapper separator>
        {CustomButton ? (
          <CustomButton
            onClick={() => handleCopyClick(copyHash, propertyToCopy)}
          />
        ) : (
          <Button.Primary
            onClick={() => handleCopyClick(copyHash, propertyToCopy)}
          >
            {buttonText}
          </Button.Primary>
        )}
      </Wrapper>
      <StyledHidden id={`${copyHash}-hidden`}>{hiddenChildren}</StyledHidden>
      <Wrapper separator id={copyHash}>
        {visibleChildren}
      </Wrapper>
    </Wrapper>
  )
}
export default CopyToClipboard
