import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { BORDER, COLOR, GTR } from '@farewill/ui/tokens'

const StyledDropdownWrapper = styled.div`
  position: relative;
  display: inline-flex;
`

const StyledTriggerWrapper = styled.div`
  cursor: pointer;
`

const StyledContentWrapper = styled.div<{
  $alignment: Alignment
}>`
  position: absolute;
  bottom: -${GTR.XS};
  transform: translateY(100%);
  z-index: 3;
  min-width: 276px;
  background-color: ${COLOR.WHITE};
  border-radius: ${BORDER.RADIUS.S};
  border: 2px solid ${COLOR.GREY.LIGHT};
  box-shadow: ${BORDER.SHADOW.M};

  ${({ $alignment }) => $alignment === 'left' && `left: 0;`}

  ${({ $alignment }) => $alignment === 'right' && `right: 0;`}

  ${({ $alignment }) =>
    $alignment === 'center' &&
    `
    left: 50%;
    transform: translate(-50%, 100%);
  `}
`

const StyledBackdrop = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  top: 0;
  right: 0;
  background: transparent;
  z-index: 2;
`

type Alignment = 'left' | 'right' | 'center'

interface Props {
  alignment?: Alignment
  content: React.ReactElement
  toggleCallback?: () => void
  trigger: React.ReactElement
  updatedAt?: unknown
  isVisible?: boolean
}

const Dropdown = ({
  alignment = 'center',
  content,
  toggleCallback,
  trigger,
  updatedAt,
  isVisible,
}: Props): React.ReactElement => {
  const [dropdownVisible, setDropdownVisible] = useState(false)
  const isDropdownVisible =
    isVisible !== undefined ? isVisible : dropdownVisible

  useEffect(() => {
    setDropdownVisible(false)
  }, [updatedAt])

  const toggleDropdownVisibility = () => {
    setDropdownVisible((prevVisible) => !prevVisible)
    if (toggleCallback) toggleCallback()
  }

  return (
    <>
      {isDropdownVisible && (
        <StyledBackdrop onClick={toggleDropdownVisibility} />
      )}
      <StyledDropdownWrapper>
        <StyledTriggerWrapper onClick={toggleDropdownVisibility}>
          {trigger}
        </StyledTriggerWrapper>
        {isDropdownVisible && (
          <StyledContentWrapper $alignment={alignment}>
            {content}
          </StyledContentWrapper>
        )}
      </StyledDropdownWrapper>
    </>
  )
}

Dropdown.propTypes = {
  alignment: PropTypes.oneOf(['center', 'left', 'right']),
  content: PropTypes.node.isRequired,
  toggleCallback: PropTypes.func,
  trigger: PropTypes.node.isRequired,
  updatedAt: PropTypes.any,
  isVisible: PropTypes.bool,
}

export default Dropdown
