import styled, { css } from 'styled-components'
import { useFormikContext } from 'formik'
import { COLOR, GTR } from '@farewill/ui/tokens'
import NumberFormat from 'react-number-format'
import get from 'lodash/get'

import {
  FloatingWrapper,
  FloatingLabel,
  FloatingInput,
  FloatingHint,
} from 'components/styled/helpers/floating'
import ErrorSmall from 'components/form/error-small'
import CopyToClipboard from './copy-to-clipboard'
import { getFieldState } from './helpers'

const StyledField = styled(FloatingInput)`
  ${({ disabled }) => disabled && `background-color: ${COLOR.GREY.LIGHT};`}

  ${({ component }) => component === 'textarea' && `resize: none;`}

  ${({ component }) =>
    component === 'select' &&
    css`
      appearance: none;
      height: 59px;
      cursor: pointer;
      background-image: url('/images/down-arrow.png');
      background-size: 15px;
      background-position: right ${GTR.S} top 50%;
      background-repeat: no-repeat;
    `}

  ${({ type }) =>
    type === 'date' &&
    css`
      &::-webkit-calendar-picker-indicator {
        right: 0;
        width: auto;
      }
    `}

  &::-webkit-calendar-picker-indicator {
    position: absolute;
    top: 0;
    left: 0;
    right: auto;
    bottom: 0;
    width: 100px;
    height: auto;
    color: transparent;
    background: transparent;
  }
`

type Props = {
  component?: string
  handleSave?: (event: { name: string; value: string | number | null }) => void
  hint?: string | React.ReactElement
  isCurrency?: boolean
  label: string
  name: string
  options?: { label: string; value: string }[]
  type?: string
  validate?: (value: string) => null | string | undefined
  disabled?: boolean
  allowCopyToClipboard?: boolean
}

const InputFloating = ({
  component = 'input',
  handleSave,
  hint,
  isCurrency = false,
  label,
  name,
  options,
  type = 'text',
  validate,
  disabled,
  allowCopyToClipboard,
  ...rest
}: Props): React.ReactElement => {
  const formik = useFormikContext()
  const { handleBlur, handleChange, initialValues } = formik
  const { hasError, hasHighlight, hasValue } = getFieldState({ formik, name })
  const initialValue = get(initialValues, name)

  const isSelect = component === 'select'
  const isText = !isSelect && !isCurrency
  const isDateOrTime = type === 'date' || type === 'time'

  return (
    <FloatingWrapper error={hasError} highlight={hasHighlight}>
      <CopyToClipboard
        allowCopyToClipboard={allowCopyToClipboard}
        name={name}
        label={label}
      >
        {isSelect && options && (
          <StyledField
            {...rest}
            id={name}
            name={name}
            component="select"
            error={hasError}
            highlight={hasHighlight}
            disabled={disabled}
            onChange={(e: React.FormEvent<HTMLSelectElement>) => {
              handleChange(e)
              const target = e.target as HTMLSelectElement
              const value = target.value
              handleSave && handleSave({ name, value })
            }}
          >
            <option value=""></option>
            {options.map((option, index) => (
              <option value={option.value} key={index}>
                {option.label}
              </option>
            ))}
          </StyledField>
        )}
        {isCurrency && (
          <NumberFormat
            {...rest}
            customInput={StyledField}
            decimalScale={2}
            defaultValue={initialValue}
            error={hasError}
            highlight={hasHighlight}
            disabled={disabled}
            id={name}
            name={name}
            onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
              handleBlur(e)
              const value = e.target.value?.replace(/,/g, '')
              handleSave && handleSave({ name, value })
            }}
            onChange={handleChange}
            thousandSeparator
            // We want to disable suggestion to use 1password on Input fields
            data-1p-ignore
          />
        )}
        {isText && (
          <StyledField
            {...rest}
            type={type}
            id={name}
            name={name}
            component={component}
            error={hasError}
            highlight={hasHighlight}
            disabled={disabled}
            onBlur={(e: React.FormEvent<HTMLSelectElement>) => {
              handleBlur(e)
              const target = e.target as HTMLSelectElement
              const value = target.value
              handleSave && handleSave({ name, value })
            }}
            // We want to disable suggestion to use 1password on Input fields
            data-1p-ignore
          />
        )}
      </CopyToClipboard>
      <FloatingLabel
        htmlFor={name}
        error={hasError}
        full={hasValue || isDateOrTime}
      >
        {label}
      </FloatingLabel>
      {hint && <FloatingHint>{hint}</FloatingHint>}
      <ErrorSmall name={name} />
    </FloatingWrapper>
  )
}

export default InputFloating
