import { ArrowLeftIcon, Button, ButtonGroup, Divider, P } from '@farewill/ui'
import { FONT, GTR } from '@farewill/ui/tokens'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { Formik, Form } from 'formik'
import mapValues from 'lodash/mapValues'

import { RESOURCES, useFetchArrayResource, useCreateResource } from 'api'
import SelectInput from 'components/form/select-input'
import { hideModal } from 'state/actions'
import { GiftBeneficiary } from 'lib/models/gift-beneficiary'
import { Gift } from 'lib/models/gift'
import { Organisation } from 'lib/models/organisation'
import { Charity } from 'lib/models/charity'

import {
  willBeneficiaryTypeOptions,
  WILL_BENEFICIARY_TYPE,
} from '../../constants'
import CharityFields from './charity-fields'
import OrganisationFields from './organisation-fields'
import { GIFT_BENEFICIARY_SCHEMA } from './schemata'

const RESOURCE_MAPPING = {
  [WILL_BENEFICIARY_TYPE.CHARITY]: RESOURCES.CHARITIES,
  [WILL_BENEFICIARY_TYPE.ORGANISATION]: RESOURCES.ORGANISATIONS,
}

const InlineButton = styled(Button.Underline)`
  font-size: ${FONT.SIZE.S};
`

type FormValues = {
  isManualEntry: boolean
  beneficiaryType:
    | typeof WILL_BENEFICIARY_TYPE.CHARITY
    | typeof WILL_BENEFICIARY_TYPE.ORGANISATION
    | ''
  charity: {
    legalName: string
    commonName: string
    number: string
    willBeneficiaryId: string
  }
  organisation: {
    name: string
    number: string
    address: string
    notes: string
    willBeneficiaryId: string
  }
}

type Props = {
  config: { giftId: number; willId: number; giftBeneficiaryType: string }
}

const AddGiftBeneficiaryModal = ({
  config: { giftId, willId, giftBeneficiaryType },
}: Props): React.ReactElement => {
  const dispatch = useDispatch()
  const willBeneficiaryMutation = useCreateResource<Charity | Organisation>()
  const giftBeneficiaryMutation = useCreateResource<GiftBeneficiary>()
  const giftsQuery = useFetchArrayResource<Gift>({
    resource: RESOURCES.GIFTS,
    params: `filter[willId]=${willId}`,
  })

  const createGiftBeneficiary = async ({
    willBeneficiaryId,
  }: {
    willBeneficiaryId: number | string
  }) => {
    await giftBeneficiaryMutation.mutateAsync(
      {
        attributes: {
          type: giftBeneficiaryType,
          willBeneficiaryIds: [willBeneficiaryId],
          giftId,
        },
        resource: RESOURCES.GIFT_BENEFICIARIES,
      },
      {
        onSuccess: async () => {
          await giftsQuery.refetch()
        },
      }
    )
    dispatch(hideModal())
  }

  const handleSubmit = (values: FormValues) => {
    const { beneficiaryType, isManualEntry } = values

    if (beneficiaryType === '') return

    if (!isManualEntry) {
      const willBeneficiaryId = values[beneficiaryType].willBeneficiaryId
      return createGiftBeneficiary({ willBeneficiaryId })
    }

    /**
     * Convert any empty string values to null so that we don't get
     * unique constraint violations on multiple rows with an empty
     * string value in a given column (charity number or org name).
     */
    const attributes = mapValues(
      values[beneficiaryType],
      (value) => value || null
    )

    willBeneficiaryMutation.mutate(
      {
        attributes,
        resource: RESOURCE_MAPPING[beneficiaryType],
      },
      {
        onSuccess: (data) => {
          createGiftBeneficiary({ willBeneficiaryId: data.id })
        },
      }
    )
  }

  return (
    <Formik
      validationSchema={GIFT_BENEFICIARY_SCHEMA}
      onSubmit={handleSubmit}
      initialValues={{
        isManualEntry: false,
        beneficiaryType: '',
        willBeneficiaryId: '',
        charity: {
          legalName: '',
          commonName: '',
          number: '',
          willBeneficiaryId: '',
        },
        organisation: {
          name: '',
          number: '',
          address: '',
          notes: '',
          willBeneficiaryId: '',
        },
      }}
    >
      {({ values, setFieldValue }) => (
        <Form>
          <SelectInput
            floating
            name="beneficiaryType"
            label="Who do you want to add?"
            options={willBeneficiaryTypeOptions}
          />
          {values.beneficiaryType && <Divider margin={['M', 0]} />}
          {values.beneficiaryType && values.isManualEntry && (
            <P size="S" margin="0">
              <ArrowLeftIcon size="S" inline /> Or{' '}
              <InlineButton
                flush
                type="button"
                onClick={() => setFieldValue('isManualEntry', false)}
              >
                search for the {values.beneficiaryType}
              </InlineButton>
            </P>
          )}
          {values.beneficiaryType === WILL_BENEFICIARY_TYPE.CHARITY && (
            <CharityFields isManualEntry={values.isManualEntry} />
          )}
          {values.beneficiaryType === WILL_BENEFICIARY_TYPE.ORGANISATION && (
            <OrganisationFields isManualEntry={values.isManualEntry} />
          )}
          {values.beneficiaryType && !values.isManualEntry && (
            <P size="S" margin={[0, 0, `-${GTR.S}`]}>
              Start typing and choose from the list or{' '}
              <InlineButton
                flush
                type="button"
                onClick={() => setFieldValue('isManualEntry', true)}
              >
                enter manually
              </InlineButton>
            </P>
          )}
          <ButtonGroup margin={['M', 0, 0]}>
            <Button.Primary
              type="submit"
              disabled={
                !values.beneficiaryType ||
                (!values.isManualEntry &&
                  !values.charity.willBeneficiaryId &&
                  !values.organisation.willBeneficiaryId)
              }
              loading={
                willBeneficiaryMutation.isLoading ||
                giftBeneficiaryMutation.isLoading
              }
            >
              Save
            </Button.Primary>
            <Button.Underline
              type="button"
              onClick={() => dispatch(hideModal())}
            >
              Cancel
            </Button.Underline>
          </ButtonGroup>
        </Form>
      )}
    </Formik>
  )
}

export default AddGiftBeneficiaryModal
