import styled from 'styled-components'
import { ArrowRightIcon, ArrowUpIcon, PersonIcon } from '@farewill/ui'
import { COLOR, FONT, GTR } from '@farewill/ui/tokens'

import StyledGridRow from 'components/list/grid-row'
import StyledLink from 'components/list/link'
import StyledSortableHeader from 'components/list/sortable-header'
import SortArrow from 'components/sort-arrow'
import DateBadge from 'components/date-badge'
import StyledLoadingRow from 'components/list/loading-row'
import NoResults from 'components/list/no-results'
import { CORE_TASKS_IN_ORDER } from 'components/tasks-panel/constants'
import AdminIcon from 'components/admin-icon'
import HelpNeededHand from 'components/help-needed-hand'
import EstateOfText from 'components/estate-of-text'

import { nextTaskDueOnString } from 'utils/helpers'

const SORT = {
  DUE_ON: ['dueOn', '-dueOn'],
}

const StyledGridColumn = styled.li`
  font-size: ${FONT.SIZE.S};
`

const StyledIconListItem = styled.li`
  display: flex;
  align-items: center;
`

const StyledPriorityArrow = styled(ArrowUpIcon)`
  ${({ $hasCurrentHelper }) => $hasCurrentHelper && `margin-left: -${GTR.XXS};`}
`

const findStageLabel = ({ product, stage }) =>
  CORE_TASKS_IN_ORDER[product].find(({ name }) => name === stage)?.title ||
  stage

const TaskRow = ({ gridTemplateColumns, row, product }) => {
  let dueOnString = nextTaskDueOnString(row.attributes.dueOn)
  if (!dueOnString) dueOnString = 'no due date'

  return (
    <StyledLink
      to={`/probate/cases/${row.attributes.probateCaseId}#task-${row.id}`}
    >
      <StyledGridRow gridTemplateColumns={gridTemplateColumns}>
        <StyledGridColumn>
          {row.attributes.currentAssignee ? (
            <AdminIcon
              user={row.attributes.currentAssignee}
              enableTooltip
              size="S"
            />
          ) : (
            <PersonIcon
              size="S"
              color={COLOR.WHITE}
              background={COLOR.GREY.LIGHT}
            />
          )}
        </StyledGridColumn>
        <StyledGridColumn>
          {row.attributes.probateCase?.contact.firstName}{' '}
          {row.attributes.probateCase?.contact.lastName}
          <EstateOfText probateCase={row.attributes.probateCase} />
        </StyledGridColumn>
        <StyledGridColumn>{row.attributes.title}</StyledGridColumn>
        <StyledGridColumn>
          {findStageLabel({ stage: row.attributes.stage, product })}
        </StyledGridColumn>
        <li>
          <DateBadge date={dueOnString} />
        </li>
        <StyledIconListItem>
          {row.attributes.currentHelperId && (
            <HelpNeededHand
              enableTooltip
              currentHelper={row.attributes.currentHelper.name}
            />
          )}
          {row.attributes.probateCase?.isPriority && (
            <StyledPriorityArrow
              size="S"
              color={COLOR.WHITE}
              background={COLOR.STATE.WARNING}
              $hasCurrentHelper={row.attributes.currentHelperId}
            />
          )}
        </StyledIconListItem>
        <li>
          <ArrowRightIcon size="S" />
        </li>
      </StyledGridRow>
    </StyledLink>
  )
}

const TaskList = ({ tasks, isLoading, sort, setSort, setPage, product }) => {
  const gridTemplateColumns = '42px 1.5fr 1.5fr 1fr 1fr 64px 18px'
  return (
    <>
      <StyledGridRow header gridTemplateColumns={gridTemplateColumns}>
        <li />
        <StyledGridColumn>Customer</StyledGridColumn>
        <StyledGridColumn>Description</StyledGridColumn>
        <StyledGridColumn>Stage</StyledGridColumn>
        <StyledSortableHeader
          onClick={() => {
            const nextOption =
              SORT.DUE_ON[SORT.DUE_ON.indexOf(sort) + 1] || null
            setSort(nextOption)
            setPage({})
          }}
        >
          Due
          <SortArrow
            sorted={SORT.DUE_ON.includes(sort)}
            asc={sort === '-dueOn'}
          />
        </StyledSortableHeader>
        <li />
      </StyledGridRow>

      {isLoading && <StyledLoadingRow>Loading...</StyledLoadingRow>}
      {!isLoading && !tasks && <NoResults type="tasks" />}

      {tasks &&
        !isLoading &&
        tasks.map((row) => (
          <TaskRow
            gridTemplateColumns={gridTemplateColumns}
            key={row.id}
            row={row}
            product={product}
          />
        ))}
    </>
  )
}

export default TaskList
