import moment from 'moment'
import get from 'lodash/get'

export const formatDate = (
  date: moment.MomentInput,
  withTime?: boolean,
  formatOverwrite?: string
): string | null => {
  if (!date) return null

  const format =
    formatOverwrite || (withTime ? 'D MMMM YYYY, HH:mm' : 'D MMM YYYY')

  return moment(date).format(format)
}

export const sortByDate = (
  array: Array<moment.MomentInput>,
  path: string | string[],
  order: 'asc' | 'desc' = 'asc'
): Array<moment.MomentInput> => {
  if (!['asc', 'desc'].includes(order)) {
    throw new Error('Date order must be "asc" or "desc"')
  }

  const asc = array.sort((a, b) =>
    moment(get(a, path)).diff(moment(get(b, path)))
  )

  if (order === 'desc') return asc.reverse()

  return asc
}
