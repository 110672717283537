export const notionSpotlightUrl =
  'https://www.notion.so/farewill/Spotlight-2b283cbaa5454d72841e732ce22cf48b?pvs=4#d78e21871f474c118f5bfd97e86feaa4'

export const PARTNER_TYPE_OPTIONS = [
  { label: 'Charity', value: 'charity' },
  { label: 'Organisation', value: 'organisation' },
]

export const CREATE_INITIAL_VALUES = {
  type: 'charity',
  logo: null,
  giftPromptImage: null,
  partnerDataSharing: 'disabled', // used for the checkbox state but not included in the payload to create / update partners
  dataSharingReportType: null, // null is the default value and means that the partner would have no report created for them
  products: [],
  onlineWillConfiguration: [],
  isGiftPrompt: true,
}

export const PROMOTED_INDEX_OPTIONS = [
  { label: '1', value: 1 },
  { label: '2', value: 2 },
  { label: '3', value: 3 },
  { label: '4', value: 4 },
  { label: '5', value: 5 },
  { label: '6', value: 6 },
  { label: '7', value: 7 },
  { label: '8', value: 8 },
]

export const KEYS_TO_NOT_SEND_TO_API = [
  'partnerDataSharing',
  'isGiftPrompt',
  'logoUrl',
  'giftPromptImageUrl',
]
