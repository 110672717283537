import styled from 'styled-components'
import { Button, P, Wrapper } from '@farewill/ui'
import { FONT, GTR } from '@farewill/ui/tokens'
import flatMap from 'lodash/flatMap'
import {
  FarewillProduct,
  FUNERAL_SERVICE_TYPES,
  WILL_GRADE_OPTIONS,
} from 'utils/enums'
import { formatDate } from 'lib/formatting/dates'
import AdminUser from 'components/admin-user'

import { FILTER_OPTIONS as HELP_NEEDED_FILTER_OPTIONS } from './filter-panel/tasks/help-needed-filter'
import { optionsForProduct } from './filter-panel'

type FilterId = string
type FilterValueDateRange = { gte: string; lte: string }
type FilterValue = boolean | string | Array<string> | FilterValueDateRange
type FilterLabeller = {
  [key in FilterId]: string | ((value: FilterValue) => React.ReactNode)
}
type FilterOption = { label: string; value: string }

type FilterSummaryProps = {
  product: FarewillProduct
  filters: { [key in FilterId]: FilterValue }
  onReset: () => void
}

const StyledFilterWrapper = styled(Wrapper)`
  display: flex;
`
const StyledFilterCount = styled.li`
  display: inline-flex;
  margin: 0 ${GTR.XS} 0 0;
  font-weight: ${FONT.WEIGHT.BOLD};
`
const StyledFilterItem = styled.li`
  display: inline-flex;
  &:after {
    content: '•';
    padding: 0 ${GTR.XS};
  }
`

const renderFilter = (
  filterLabels: FilterLabeller,
  id: FilterId,
  value: FilterValue
): React.ReactNode => {
  const renderer = filterLabels[id]
  if (typeof renderer === 'string') {
    return renderer
  } else if (typeof renderer === 'function') {
    return renderer(value)
  }

  return value
}

const formatDateRange = (values: FilterValue): React.ReactNode => {
  if (
    Array.isArray(values) ||
    typeof values === 'string' ||
    typeof values === 'boolean'
  ) {
    return null
  }

  const after = formatDate(values?.gte)
  const before = formatDate(values?.lte)

  if (after == null || before == null) {
    return null
  }

  return `${after}—${before}`
}

const formatOption =
  (options: Array<FilterOption> = []) =>
  (values: FilterValue) => {
    if (!Array.isArray(values)) {
      return null
    }

    return options
      .filter((option: FilterOption) => values.includes(option.value))
      .map((option: FilterOption) => option.label)
  }

const formatAdminUser = (values: FilterValue) => {
  if (!Array.isArray(values)) {
    return null
  }
  return values.map((id: string) => <AdminUser key={id} userId={id} />)
}

const FilterSummary = ({
  product,
  filters,
  onReset,
}: FilterSummaryProps): React.ReactElement => {
  const { statusOptions, nextStageOptions, nextCoreTaskOptions } =
    optionsForProduct(product)

  const nextCoreTaskOptionsFlattened = flatMap(nextCoreTaskOptions, 'options')

  // TODO for a future PR: is there a way to refactor the filterLabels so we don't have to remember to manually add new filters here?
  const filterLabels = {
    isPriority: 'Priority cases',
    helpNeeded: 'Tasks needing help',
    isDifficult: 'Difficult case',
    hasGreenFormPending: 'Green / form 6 pending',
    hasPaperworkPending: 'Prepare paperwork',
    hasFeedbackPending: 'Feedback pending',
    hasServiceWithinSevenDays: '< 7 days until service',
    hasSixDaysWithoutContact: '> 6 days without contact',
    hideNewLawReferrals: 'Not NewLaw referral',
    isUrgent: 'Urgent case',
    serviceType: formatOption(FUNERAL_SERVICE_TYPES),
    currentOwnerId: formatAdminUser,
    caseOwnerId: formatAdminUser,
    drafterId: formatAdminUser,
    nextStage: formatOption(nextStageOptions),
    nextCoreTask: formatOption(nextCoreTaskOptionsFlattened),
    status: formatOption(statusOptions),
    nextTaskDueOn: formatDateRange,
    dueOn: formatDateRange,
    currentAssigneeId: formatAdminUser,
    title: (values: FilterValue) => `"${values}"`,
    helpNeededFrom: formatOption(HELP_NEEDED_FILTER_OPTIONS),
    probateCaseStatus: formatOption(statusOptions),
    grade: formatOption(WILL_GRADE_OPTIONS),
    hasPhoneNumber: 'Has phone number',
    includesMessages: 'Includes messages',
    includesWishes: 'Includes wishes',
  }

  const filterItems = flatMap(filters, (value: FilterValue, id: FilterId) =>
    renderFilter(filterLabels, id, value)
  )

  const content =
    filterItems.length === 0 ? (
      <P>No filters applied</P>
    ) : (
      <>
        <ul>
          <StyledFilterCount>
            {filterItems.length} filter{filterItems.length === 1 ? '' : 's'}{' '}
            applied:{' '}
          </StyledFilterCount>
          {filterItems.map((item, index) => (
            <StyledFilterItem key={index}>{item}</StyledFilterItem>
          ))}
          <Button.Underline flush onClick={onReset}>
            Reset
          </Button.Underline>
        </ul>
      </>
    )

  return <StyledFilterWrapper>{content}</StyledFilterWrapper>
}

export default FilterSummary
