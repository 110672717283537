import PropTypes from 'prop-types'
import { Grid, H } from '@farewill/ui'

import NotesInput from 'components/notes-input'

import { NOTES_NOT_COVERED_ELSEWHERE } from './field-names'

const NotesNotCoveredElsewhere = ({ saveProbateEstateField }) => {
  return (
    <Grid data-scroll-id="6.6">
      <Grid.Item>
        <H size="S">6.6 Notes not covered elsewhere</H>
      </Grid.Item>
      <Grid.Item>
        <NotesInput
          name={NOTES_NOT_COVERED_ELSEWHERE}
          handleSave={saveProbateEstateField}
        />
      </Grid.Item>
    </Grid>
  )
}

NotesNotCoveredElsewhere.propTypes = {
  saveProbateEstateField: PropTypes.func.isRequired,
}

export default NotesNotCoveredElsewhere
