import { Grid, P } from '@farewill/ui'

import { Operation } from 'routes/partners/interfaces'
import InputFloating from 'components/form/input-floating'
import ExternalLink from 'components/external-link'

const CharityKeyDetails = ({
  operation,
}: {
  operation: Operation
}): React.ReactElement => {
  return (
    <>
      <P>
        Double-check the details match with those on the{' '}
        <ExternalLink href="https://www.gov.uk/find-charity-information">
          Charity Commissions website
        </ExternalLink>
        .
      </P>
      <Grid gap="S">
        <Grid.Item>
          <InputFloating name="charity.legalName" label="Legal name" />
        </Grid.Item>
        <Grid.Item>
          <InputFloating
            name="charity.commonName"
            label="Brand name (optional)"
          />
        </Grid.Item>
        <Grid.Item>
          <InputFloating
            disabled={operation === 'update'}
            name="charity.number"
            label="Charity number (6 or 7 digits)"
          />
        </Grid.Item>
        <Grid.Item>
          <InputFloating
            disabled={operation === 'update'}
            name="utmSource"
            label="UTM source"
          />
        </Grid.Item>
      </Grid>
    </>
  )
}

export default CharityKeyDetails
