import { Button, Wrapper } from '@farewill/ui'
import { Link } from 'react-router-dom'

import NotFoundMessage from 'components/not-found-message'
import { GTR } from '@farewill/ui/tokens'

const NotFound = () => (
  <Wrapper centered padding={[GTR.XL, 0, 0]}>
    <NotFoundMessage />
    <Button.Primary tag={Link} to="/leads">
      Manage leads
    </Button.Primary>
  </Wrapper>
)

export default NotFound
