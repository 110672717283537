import { useEffect } from 'react'
import styled from 'styled-components'
import get from 'lodash/get'
import { useFormikContext } from 'formik'
import { Button, Grid, Wrapper } from '@farewill/ui'
import { GTR } from '@farewill/ui/tokens'

import Address from 'components/address'
import EstateCard from 'components/estate-card'
import { Input, InputFloating, SelectInput } from 'components/form'
import NotesInput from 'components/notes-input'
import Checkbox from 'components/form/checkbox'
import MessageBox from 'components/message-box'
import { ProbateLeadAttributes } from 'lib/models/lead'
import { email as isValidEmail } from 'lib/formik/validation'
import { ProbateEstatePerson } from 'lib/models/probate-estate'
import { strToBool } from 'utils/helpers'
import { PR_ROLE_OPTIONS } from 'utils/enums'
import { showModal } from 'state/actions'
import store from 'state/create-store'
import DeleteModal from '../delete-modal'
import withForm from '../with-form'
import { ESTATE_PEOPLE_ATTRIBUTES } from './constants'
import { usePeople } from '../helpers/people-context'
import { Address as AddressType } from 'lib/models/address'
const StyledCheckboxesWrapper = styled(Wrapper)`
  display: flex;
  flex-wrap: wrap;

  && {
    margin-top: -${GTR.S};
  }
`

const StyledCheckboxWrapper = styled(Wrapper)`
  margin-right: ${GTR.L};

  && {
    margin-top: ${GTR.S};
  }
`

const Administrator = ({
  person,
  listLength,
  listPosition,
}: {
  person: ProbateEstatePerson
  listLength: number
  listPosition: number
}) => {
  const { deletePerson, updatePerson } = usePeople()
  const { values } = useFormikContext<{
    isPointOfContact: boolean
    personalRepresentativeType: string
    address: AddressType
  }>()

  const isPointOfContact = strToBool(
    get(values, ESTATE_PEOPLE_ATTRIBUTES.IS_POINT_OF_CONTACT)
  )
  const isAdministrator =
    get(values, ESTATE_PEOPLE_ATTRIBUTES.PERSONAL_REPRESENTATIVE_TYPE) ===
    'administrator_without_will'

  const address = get(values, ESTATE_PEOPLE_ATTRIBUTES.ADDRESS)

  const onTrashClick = () => {
    store.dispatch(
      showModal({
        component: DeleteModal,
        headingText: 'Delete person',
        config: {
          deleteCallback: () => {
            deletePerson(person.id)
          },
          type: 'person',
          resourceId: person.id,
        },
      })
    )
  }

  const handleSave = ({
    name,
    value,
  }: {
    name: string
    value: string | number | null | object
  }) => {
    updatePerson({
      id: person.id,
      attributes: { [name]: value || null },
    })
  }

  return (
    <EstateCard
      onTrashClick={onTrashClick}
      listLength={listLength}
      listPosition={listPosition}
      type="Administrator"
    >
      <Grid style={{ gridAutoFlow: 'row' }}>
        <Grid.Item>
          <StyledCheckboxesWrapper>
            <StyledCheckboxWrapper>
              <Checkbox
                name={ESTATE_PEOPLE_ATTRIBUTES.PERSONAL_REPRESENTATIVE_TYPE}
                label="Administrator"
                checked={isAdministrator}
                disabled
              />
            </StyledCheckboxWrapper>
            <StyledCheckboxWrapper>
              <Checkbox
                name={ESTATE_PEOPLE_ATTRIBUTES.IS_POINT_OF_CONTACT}
                label="Point of contact"
                checked={isPointOfContact}
                handleSave={handleSave}
              />
            </StyledCheckboxWrapper>
          </StyledCheckboxesWrapper>
        </Grid.Item>
        <Grid.Item span={2}>
          <InputFloating
            name={ESTATE_PEOPLE_ATTRIBUTES.TITLE}
            label="Title"
            handleSave={handleSave}
          />
        </Grid.Item>
        <Grid.Item span={5}>
          <InputFloating
            name={ESTATE_PEOPLE_ATTRIBUTES.FIRST_NAMES}
            label="First + middle names"
            handleSave={handleSave}
          />
        </Grid.Item>
        <Grid.Item span={5}>
          <InputFloating
            name={ESTATE_PEOPLE_ATTRIBUTES.LAST_NAME}
            label="Last name"
            handleSave={handleSave}
          />
        </Grid.Item>
        <Grid.Item>
          <SelectInput
            name={ESTATE_PEOPLE_ATTRIBUTES.PERSONAL_REPRESENTATIVE_ROLE}
            label="Their role"
            options={PR_ROLE_OPTIONS}
            handleSave={handleSave}
          />
        </Grid.Item>
        <Grid.Item>
          <Input
            name={ESTATE_PEOPLE_ATTRIBUTES.DATE_OF_BIRTH}
            label="Date of birth"
            type="date"
            handleSave={handleSave}
          />
        </Grid.Item>
        <Grid.Item span={6} startColumn={1}>
          <InputFloating
            name={ESTATE_PEOPLE_ATTRIBUTES.EMAIL}
            label="Email"
            type="email"
            handleSave={handleSave}
            validate={isValidEmail}
          />
        </Grid.Item>
        <Grid.Item span={6}>
          <InputFloating
            name={ESTATE_PEOPLE_ATTRIBUTES.PHONE_NUMBER}
            label="Phone number"
            handleSave={handleSave}
          />
        </Grid.Item>
        <Grid.Item span={9}>
          <Address
            label="Address"
            name={`${ESTATE_PEOPLE_ATTRIBUTES.ADDRESS}.${address.id}}`}
            data={address}
          />
        </Grid.Item>
        <Grid.Item>
          <NotesInput
            name={ESTATE_PEOPLE_ATTRIBUTES.NOTES}
            handleSave={handleSave}
          />
        </Grid.Item>
      </Grid>
    </EstateCard>
  )
}

const AdministratorWithForm = withForm(Administrator)

const IntestacyAdministrators = () => {
  const { people, createPerson } = usePeople()
  const { values, setFieldValue } = useFormikContext<ProbateLeadAttributes>()

  // 1. How many administrators is needed in total?
  const totalAdministratosAmount =
    values.quoteAttributes.intestacyNumberOfAdministrators || 0
  // 2. Is customer administrator?
  const isCustomerAdministrator = strToBool(
    values.quoteAttributes.customerIsAdministrator
  )
  // 3. How many administrators are already added?
  const administrators = people.filter(
    (person) =>
      person.attributes[
        ESTATE_PEOPLE_ATTRIBUTES.PERSONAL_REPRESENTATIVE_TYPE
      ] === 'administrator_without_will' &&
      !person.attributes[ESTATE_PEOPLE_ATTRIBUTES.IS_CUSTOMER]
  )
  // 4. How many executors is still needed?
  const howManyAdministratorsIsStillNeeded =
    totalAdministratosAmount -
    (isCustomerAdministrator ? 1 : 0) -
    administrators.length

  useEffect(() => {
    if (!values.quoteAttributes.intestacyNumberOfAdministrators) return

    if (howManyAdministratorsIsStillNeeded < 0) {
      setFieldValue(
        'quoteAttributes.intestacyNumberOfAdministrators',
        values.quoteAttributes.intestacyNumberOfAdministrators + 1
      )
    }
  }, [
    people,
    values.quoteAttributes.intestacyNumberOfAdministrators,
    values.quoteAttributes.customerIsAdministrator,
    administrators,
    howManyAdministratorsIsStillNeeded,
    setFieldValue,
  ])

  return (
    <>
      {administrators && administrators.length > 0 && (
        <Grid.Item>
          <Grid margin={[0, 0, 'M', 'M']}>
            {administrators.map((administrator, i) => (
              <Grid.Item key={i} span={11}>
                <AdministratorWithForm
                  initialValues={administrator.attributes}
                  enableReinitialize={false}
                  person={administrator}
                  listPosition={i + (isCustomerAdministrator ? 2 : 1)}
                  listLength={totalAdministratosAmount}
                />
              </Grid.Item>
            ))}
          </Grid>
        </Grid.Item>
      )}
      {howManyAdministratorsIsStillNeeded > 0 && (
        <>
          <Grid.Item>
            <MessageBox warning>
              We need details of {howManyAdministratorsIsStillNeeded} more{' '}
              {howManyAdministratorsIsStillNeeded > 1
                ? 'administrators'
                : 'administrator'}
            </MessageBox>
          </Grid.Item>
          <Grid.Item>
            <Button.Secondary
              style={{ paddingTop: GTR.XXS, paddingBottom: GTR.XXS }}
              type="button"
              onClick={() => {
                createPerson({
                  personalRepresentativeType: 'administrator_without_will',
                })
              }}
            >
              Add administrator details
            </Button.Secondary>
          </Grid.Item>
        </>
      )}
    </>
  )
}

export default IntestacyAdministrators
