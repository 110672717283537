import { useEffect } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { RouteComponentProps } from 'react-router-dom'
import styled from 'styled-components'
import { Grid, P, Wrapper } from '@farewill/ui'

import { useFetchResource, RESOURCES } from 'api'
import Activity from 'components/activity'
import NavigationTabs from 'components/navigation-tabs'
import PrivateRoute from 'components/private-route/containers'
import Header from 'components/product-header'
import TasksPanel from 'components/tasks-panel'
import { TasksProvider } from 'components/tasks-panel/context'
import ENV from 'config/environment'
import { Lead } from 'lib/models/lead'
import { WillAttributes } from 'lib/models/will'
import { WillCase } from 'lib/models/will-case'
import getPartnerContact from 'lib/will/get-partner-contact'
import { fetchLead, unsetProduct } from 'state/actions'
import { FAREWILL_PRODUCTS } from 'utils/enums'

import CaseDetails from './case-details'
import CaseStatusBanner from './case-status-banner'
import SimpleTelephoneWill from './simple-telephone-will'
import TelephoneWillStatusBanner from './telephone-will-status-banner'
import Quote from './quote'
import Will from './will'
import { GTR } from '@farewill/ui/tokens'

const StyledWrapper = styled(Wrapper)`
  position: relative;
`

const PRODUCT = 'will'

const findWillIdByContactId = (wills: WillAttributes[], contactId: number) => {
  return wills.find((will) => will.contactId === contactId)?.id
}

const getWillLink = ({
  willCaseId,
  wills,
  contactId,
  firstName = '',
}: {
  willCaseId: number
  wills: WillAttributes[]
  contactId: number
  firstName: string
}) => {
  const willId = findWillIdByContactId(wills, contactId)
  const baseUrl = `/will-cases/${willCaseId}/wills/${willId}`
  return {
    label: `${firstName}’s will`,
    url: `${baseUrl}/fact-find`,
    isActive: () => window.location.pathname.startsWith(baseUrl),
  }
}

const getNavLinks = ({ willCase }: { willCase: WillCase }) => {
  const { id, attributes } = willCase
  const { accountType, primaryContact, wills } = attributes

  const navLinks = [
    { label: 'Case details', url: `/will-cases/${id}` },
    { label: 'Activity', url: `/will-cases/${id}/activity` },
    { label: 'Quote', url: `/will-cases/${id}/quote` },
  ]

  if (accountType === 'telephone') {
    navLinks.push(
      getWillLink({
        willCaseId: id,
        wills: wills as WillAttributes[],
        contactId: primaryContact?.id,
        firstName: primaryContact?.firstName as string,
      })
    )

    const partner = getPartnerContact(willCase)
    if (partner) {
      navLinks.push(
        getWillLink({
          willCaseId: id,
          wills: wills as WillAttributes[],
          contactId: partner.id,
          firstName: partner.firstName || 'Partner',
        })
      )
    }
  }

  return navLinks
}

const WillCaseOverview = ({ match }: Props) => {
  const dispatch = useDispatch()
  const willCaseQuery = useFetchResource({
    id: Number(match.params.id),
    resource: RESOURCES.WILL_CASES,
  }) as {
    data: WillCase
    isLoading: boolean
    isIdle: boolean
  }

  const { lead } = useSelector(
    (state: { lead: Lead }) => ({
      lead: state.lead.attributes && state.lead,
    }),
    shallowEqual
  )

  const willCaseId = willCaseQuery?.data?.id || Number(match.params.id)
  const attributes = willCaseQuery?.data?.attributes
  const leadId = attributes?.lead?.id

  useEffect(() => {
    if (leadId) dispatch(fetchLead(leadId))
  }, [dispatch, leadId])

  useEffect(() => {
    dispatch(unsetProduct(PRODUCT))
  }, [dispatch])

  if (
    willCaseQuery?.isLoading ||
    willCaseQuery?.isIdle ||
    !willCaseQuery?.data ||
    !lead
  )
    return <P size="L">Loading...</P>

  return (
    <StyledWrapper data-sticky-content="will-case" padding={[GTR.XL, 0, 0]}>
      <TasksPanel
        queryParams={{
          'filter[willCaseId]': willCaseId,
          'filter[isNeeded]': true,
          'page[size]': 200,
        }}
        product="will"
        productId={{ willCaseId }}
        stickyContent="will-case"
      />
      {ENV.FF_WILLSUITE_ENABLED && (
        <TelephoneWillStatusBanner willCaseId={willCaseId as number} />
      )}
      <Header attributes={attributes} productType="will" />
      <Wrapper separator>
        <NavigationTabs
          links={getNavLinks({ willCase: willCaseQuery?.data })}
        />

        <CaseStatusBanner willCaseId={willCaseId as number} />
      </Wrapper>
      <Grid separator>
        <Grid.Item span={7}>
          <PrivateRoute
            path={match.path}
            component={CaseDetails}
            id={willCaseId}
            exact
          />
          <PrivateRoute
            path={`${match.path}/activity`}
            component={Activity}
            product={PRODUCT}
            leadId={leadId}
            productId={{ willCaseId }}
            showCallsFilter
          />
          <PrivateRoute
            path={`${match.path}/quote`}
            component={Quote}
            productType={PRODUCT}
            lead={lead}
            willCaseId={willCaseQuery?.data.id}
          />
          <PrivateRoute
            path={`${match.path}/simple-telephone-will`}
            component={SimpleTelephoneWill}
          />
          <PrivateRoute path={`${match.path}/wills/:willId`} component={Will} />
        </Grid.Item>
      </Grid>
    </StyledWrapper>
  )
}

type Props = RouteComponentProps<{
  id: string
  willCaseId: string
}>

const WillCaseOverviewWithProviders = (props: Props) => (
  <TasksProvider product={FAREWILL_PRODUCTS.WILL}>
    <WillCaseOverview {...props} />
  </TasksProvider>
)

export default WillCaseOverviewWithProviders
