import { Button, Grid, H, P, Wrapper } from '@farewill/ui'
import { COLOR, GTR } from '@farewill/ui/tokens'

import { useDispatch } from 'react-redux'

import IconList from 'components/icon-list'
import ENV from 'config/environment'

import { createPaymentProviderCustomer } from 'state/actions'
import { useEffect, useState } from 'react'
import { useVisibilityChange } from 'hooks/useVisibilityChange'
import apiRequest from 'lib/axios/api-request'
import MessageBox from 'components/message-box'
import Loader from 'components/loader'
import styled from 'styled-components'
import Dinero from 'dinero.js'

const FlexWrapper = styled(Wrapper)`
  align-items: center;
  display: flex;
  gap: ${GTR.M};
  position: relative;
  justify-content: flex-start;
`

export type CaptureCardDetailsForLaterProps = {
  contactId: number
  titleText: string
}

const CaptureCardDetailsForLater = ({
  contactId,
  titleText,
}: CaptureCardDetailsForLaterProps) => {
  const isTabVisible = useVisibilityChange()
  const dispatch = useDispatch()

  const [isError, setIsError] = useState<boolean>(false)
  const [isFormDirty, setIsFormDirty] = useState<boolean>(false)
  const [isLoading, setisLoading] = useState<boolean>(false)
  const [isCardSet, setIsCardSet] = useState<boolean>(false)
  const [externalCustomerId, setExternalCustomerId] = useState<string>()

  // Sales team member is being redirected to square, and then when they return we want to check for a card
  useEffect(() => {
    const getCardPresent = async (contactId: number) =>
      apiRequest({
        url: `/api/payment-provider-customers/square/card/exists/${contactId}`,
        method: 'GET',
      })
    setisLoading(true)
    getCardPresent(contactId)
      .then(({ data }) => {
        setIsError(false)
        setIsCardSet(data.isCardExists)
        setisLoading(false)
      })
      .catch((e) => {
        setIsError(true)
        setisLoading(false)
      })
  }, [contactId, isTabVisible])

  return (
    <Grid>
      <Grid.Item span={10}>
        <Grid.Item>
          <H size="S">{titleText}</H>
        </Grid.Item>
        {isCardSet && !isLoading && (
          <Grid.Item margin={['S', 0, 0, 0]} data-testid="success-box">
            <MessageBox stretch success>
              <P size="M" strong>
                Card details have been captured for this customer
              </P>
            </MessageBox>
          </Grid.Item>
        )}
        <Grid margin={['M', 0]}>
          <Grid.Item>
            <IconList
              items={[
                `The cancellation fee is ${Dinero({
                  amount: ENV.TELEPHONE_WILL_CANCELLATION_FEE_IN_PENCE,
                  currency: 'GBP',
                }).toFormat('$0,0.00')}`,
                'Pause call recording before taking payment details',
              ]}
            />
            <FlexWrapper>
              <Button.Primary
                data-testid="capture-card-details"
                type="button"
                onClick={async () => {
                  setIsError(false)
                  let customerId
                  try {
                    if (!externalCustomerId) {
                      const response = await dispatch(
                        createPaymentProviderCustomer({
                          contactId: contactId,
                          paymentProvider: 'square',
                        })
                      )

                      // @ts-expect-error Response comes back as 'any'
                      customerId = response.value.data.data.id
                      setExternalCustomerId(customerId)
                    }
                    const url = `${
                      ENV.SQUARE_DASHBOARD_URL
                    }/customers/directory/customer/${
                      //  externalCustomerId is not guaranteed be set in time if the API call happened during this onClickHandler
                      // so we fallback to customerId in that case
                      externalCustomerId || customerId
                    }`
                    window.open(url, '_blank')
                    setIsFormDirty(true)
                  } catch (error) {
                    setIsError(true)
                    setIsFormDirty(true)
                  }
                }}
              >
                Capture card details
              </Button.Primary>
              {isLoading && <Loader inline visible />}
            </FlexWrapper>
            {isError && (
              <Wrapper
                margin={[GTR.XXS, 0]}
                data-testid="something-went-wrong-error"
              >
                <P color={COLOR.STATE.ERROR} size="S">
                  Something went wrong - please try again
                </P>
              </Wrapper>
            )}
            {!isCardSet && !isLoading && isFormDirty && (
              <Wrapper
                margin={[GTR.XXS, 0]}
                data-testid="no-card-details-error"
              >
                <P color={COLOR.STATE.ERROR} size="S">
                  Card details not captured
                </P>
              </Wrapper>
            )}
          </Grid.Item>
        </Grid>
      </Grid.Item>
    </Grid>
  )
}

export default CaptureCardDetailsForLater
