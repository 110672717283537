import MessageBox from 'components/message-box'

const IHT400Warning = () => (
  <MessageBox warning>
    This may mean we have to use form IHT400 to make a claim for transfer of a
    nil-rate band. Legal team to review.
  </MessageBox>
)

export default IHT400Warning
