import { Grid, Wrapper } from '@farewill/ui'
import { GTR } from '@farewill/ui/tokens'
import { useFormikContext } from 'formik'
import styled from 'styled-components'

import EstateCard from 'components/estate-card'
import Address from 'components/address'
import { Input } from 'components/form'
import Checkbox from 'components/form/checkbox'

import { useLeadContext } from 'routes/lead/context'
import { ProbateLeadAttributes } from 'lib/models/lead'

const StyledCheckboxesWrapper = styled(Wrapper)`
  display: flex;
  flex-wrap: wrap;

  && {
    margin-top: -${GTR.S};
  }
`

const StyledCheckboxWrapper = styled(Wrapper)`
  margin-right: ${GTR.L};

  && {
    margin-top: ${GTR.S};
  }
`

const CustomerIsExecutor = () => {
  const { values } = useFormikContext<ProbateLeadAttributes>()
  const { updateContact } = useLeadContext()

  return (
    <Grid.Item span={11}>
      <Grid margin={[0, 0, 'M', 'M']}>
        <Grid.Item>
          <EstateCard listLength={1} listPosition={1} type="Caller">
            <Grid style={{ gridAutoFlow: 'row' }}>
              <Grid.Item>
                <StyledCheckboxesWrapper>
                  <StyledCheckboxWrapper>
                    <Checkbox
                      name="isCustomer"
                      label="Customer"
                      checked
                      disabled
                    />
                  </StyledCheckboxWrapper>
                  <StyledCheckboxWrapper>
                    <Checkbox
                      name="customerIsExecutor"
                      label="Executor"
                      checked
                      disabled
                    />
                  </StyledCheckboxWrapper>
                </StyledCheckboxesWrapper>
              </Grid.Item>
              <Grid.Item span={9}>
                <Address
                  data={values.contact.addresses[0]}
                  label="Address"
                  name="contact.addresses"
                  newAddressAttrs={{
                    relatedResource: 'contact',
                    relatedId: values.contactId,
                    relation: 'addresses',
                  }}
                />
              </Grid.Item>

              <Grid.Item span={6} startColumn={1}>
                <Input
                  name="contact.dateOfBirth"
                  label="Date of birth"
                  type="date"
                  handleSave={updateContact}
                />
              </Grid.Item>
            </Grid>
          </EstateCard>
        </Grid.Item>
      </Grid>
    </Grid.Item>
  )
}

export default CustomerIsExecutor
