import { Button, ButtonGroup, Wrapper } from '@farewill/ui'
import { useDispatch, useSelector } from 'react-redux'
import camelCase from 'lodash/camelCase'
import { Formik, Form } from 'formik'

import { hideModal, createEvent, updateEvent, deleteEvent } from 'state/actions'
import { INLINE_PROBATE_CASE_EVENTS } from 'utils/enums'
import { findEvent } from 'utils/helpers'
import { LOST_OR_DECLINED_SCHEMA } from 'lib/formik/schemata'

import Checkbox from 'components/form/checkbox'
import Input from 'components/form/input'

const validate = (values) => {
  const errors = {}

  if (values.caseLost.complete && values.caseDeclined.complete) {
    errors.caseLost = {
      complete: 'Can’t be set if case is marked as declined',
    }
    errors.caseDeclined = {
      complete: 'Can’t be set if case is marked as lost',
    }
  }

  return errors
}

const getInitialValues = (events) => {
  return INLINE_PROBATE_CASE_EVENTS.reduce((formatted, event) => {
    const existingEvent = findEvent(events, event.name)
    const eventName = camelCase(event.name)

    if (existingEvent) {
      const value = {
        id: existingEvent.id,
        complete: true,
        metadata: existingEvent.attributes.metadata || {},
      }
      return { ...formatted, [eventName]: value }
    } else {
      const metadata = event.metadata.reduce(
        (acc, { name }) => ({ ...acc, [name]: '' }),
        {}
      )
      const value = { complete: false, date: new Date(), metadata }
      return { ...formatted, [eventName]: value }
    }
  }, {})
}

const Modal = ({ config: { probateCaseId } }) => {
  const events = useSelector((state) => state.productEvents)
  const dispatch = useDispatch()

  const handleSubmit = async (values) => {
    await Promise.all(
      INLINE_PROBATE_CASE_EVENTS.map(async ({ name }) => {
        const { id, date, complete, metadata } = values[camelCase(name)]
        const attributes = { date, metadata, name, probateCaseId }

        if (complete && !id) {
          await dispatch(createEvent(attributes))
        } else if (complete && !!id) {
          await dispatch(updateEvent(id, attributes))
        } else if (!complete && !!id) {
          await dispatch(deleteEvent(id))
        }
      })
    )
    return dispatch(hideModal())
  }

  return (
    <Formik
      initialValues={getInitialValues(events)}
      validate={validate}
      validationSchema={LOST_OR_DECLINED_SCHEMA}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting, values }) => (
        <Form>
          {INLINE_PROBATE_CASE_EVENTS.map((event, index) => {
            const eventName = camelCase(event.name)
            const checked = values[eventName].complete
            return (
              <Wrapper key={index} margin={[0, 0, 'M']}>
                <Checkbox
                  name={`${eventName}.complete`}
                  label={event.label}
                  // eslint-disable-next-line react/jsx-boolean-value
                  value={true}
                  checked={checked}
                />
                {checked &&
                  event.metadata.map(({ name, label, options }, index) => (
                    <Wrapper key={index} margin={['S', 0]}>
                      <Input
                        label={label}
                        name={`${eventName}.metadata.${name}`}
                        component="select"
                        options={options}
                      />
                    </Wrapper>
                  ))}
              </Wrapper>
            )
          })}
          <ButtonGroup gap="S">
            <Button.Primary type="submit" loading={isSubmitting}>
              Save
            </Button.Primary>
            <Button.Underline onClick={() => dispatch(hideModal())}>
              Cancel
            </Button.Underline>
          </ButtonGroup>
        </Form>
      )}
    </Formik>
  )
}

export default Modal
