import { useEffect } from 'react'
import { Formik, useFormikContext } from 'formik'
import styled from 'styled-components'
import { Wrapper } from '@farewill/ui'
import { GTR } from '@farewill/ui/tokens'
import { useDispatch } from 'react-redux'
import moment from 'moment'

import { updateContact } from 'state/actions'

import FakeInput from 'components/fake-input'
import Input from 'components/form/input'

type Values = {
  dateOfBirth: string
}

type Props = {
  dateOfBirth: string | null
  contactId: number
  highlightEmpty?: boolean
  disabled?: boolean
}

const StyledWrapper = styled(Wrapper)`
  display: flex;
`

const StyledFakeInput = styled(FakeInput)`
  margin-left: ${GTR.XL};
  width: 85px;
`

const getAge = (values: Values) => {
  if (!values.dateOfBirth) return null
  return moment().diff(values.dateOfBirth, 'years')
}

const FormFields = ({
  highlightEmpty,
  disabled,
}: {
  highlightEmpty?: boolean
  disabled?: boolean
}): React.ReactElement => {
  const { values, handleSubmit, setStatus } = useFormikContext<Values>()

  useEffect(() => {
    setStatus({ highlightEmpty })
  }, [highlightEmpty, setStatus])

  return (
    <StyledWrapper>
      <Input
        name="dateOfBirth"
        type="date"
        label="Date of birth"
        handleSave={() => handleSubmit()}
        disabled={disabled}
      />

      <StyledFakeInput value={getAge(values)} label="Age" />
    </StyledWrapper>
  )
}

const ContactBirthDate = ({
  dateOfBirth,
  contactId,
  highlightEmpty,
  disabled,
}: Props): React.ReactElement => {
  const dispatch = useDispatch()

  const updateContactBirthDate = (values: Values) => {
    dispatch(
      updateContact(contactId, {
        dateOfBirth: values.dateOfBirth || null,
      })
    )
  }

  return (
    <Formik
      initialValues={{ dateOfBirth: dateOfBirth || '' }}
      initialStatus={{ highlightEmpty }}
      onSubmit={updateContactBirthDate}
      enableReinitialize
    >
      <FormFields highlightEmpty={highlightEmpty} disabled={disabled} />
    </Formik>
  )
}

export default ContactBirthDate
