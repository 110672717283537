import PropTypes from 'prop-types'
import { Grid } from '@farewill/ui'
import { useDispatch } from 'react-redux'

import { showModal } from 'state/actions'
import namespace from 'lib/formik/namespace'

import InputFloating from 'components/form/input-floating'
import NotesInput from 'components/notes-input'
import EstateCard from 'components/estate-card'
import CircularRadioGroup from 'components/form/circular-radio-group'

import { NOT_ASKED_OPTIONS } from 'utils/enums'

import DeleteModal from '../../components/delete-modal'
import withForm from '../../components/with-form'

import { useRelativesContext } from '../relatives/context'
import {
  FULL_NAME,
  RELATIONSHIP_TO_DECEASED,
  ADOPTION_TYPE,
  HAS_ADOPTION_CERTIFICATE,
  NOTES,
} from './field-names'

const AdoptedRelative = ({ relative, listLength, listPosition }) => {
  const dispatch = useDispatch()
  const relativeId = relative.id
  const prefix = `adoptedRelative.${relativeId}`
  const { withNamespace, withoutNamespace } = namespace(prefix + '.')

  const { updateRelative, deleteRelative } = useRelativesContext()

  const handleSave = ({ name, value }) => {
    const attributes = { [withoutNamespace(name)]: value || null }
    return updateRelative(relativeId, attributes)
  }

  const handleDeleteClick = () => {
    dispatch(
      showModal({
        component: DeleteModal,
        headingText: 'Delete adopted relative',
        config: {
          deleteCallback: () => deleteRelative(relativeId),
          type: 'adopted relative',
          resourceId: relativeId,
        },
      })
    )
  }

  return (
    <EstateCard
      onTrashClick={() => handleDeleteClick(relativeId)}
      listLength={listLength}
      listPosition={listPosition}
      type="adopted relative"
    >
      <Grid style={{ gridAutoFlow: 'row' }}>
        <Grid.Item span={6}>
          <InputFloating
            name={withNamespace(FULL_NAME)}
            label="Full name"
            handleSave={handleSave}
          />
        </Grid.Item>
        <Grid.Item span={6}>
          <InputFloating
            name={withNamespace(RELATIONSHIP_TO_DECEASED)}
            label="Relationship to person who died"
            handleSave={handleSave}
          />
        </Grid.Item>
        <Grid.Item>
          <CircularRadioGroup
            name={withNamespace(ADOPTION_TYPE)}
            label="Were they adopted in to or out of the family?"
            options={[
              { label: 'Not asked', value: '' },
              { label: 'In', value: 'in_to_family' },
              { label: 'Out', value: 'out_of_family' },
            ]}
            handleSave={handleSave}
            inline
          />
        </Grid.Item>
        <Grid.Item>
          <CircularRadioGroup
            name={withNamespace(HAS_ADOPTION_CERTIFICATE)}
            label="Do you have the adoption certificate?"
            options={NOT_ASKED_OPTIONS}
            handleSave={handleSave}
            inline
          />
        </Grid.Item>
        <Grid.Item>
          <NotesInput name={withNamespace(NOTES)} handleSave={handleSave} />
        </Grid.Item>
      </Grid>
    </EstateCard>
  )
}

AdoptedRelative.propTypes = {
  relative: PropTypes.object.isRequired,
  listLength: PropTypes.number.isRequired,
  listPosition: PropTypes.number.isRequired,
}

export default withForm(AdoptedRelative)
