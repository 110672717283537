import { useState } from 'react'
import { Formik, Form } from 'formik'
import { Button, ButtonGroup, Grid } from '@farewill/ui'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import isNumber from 'lodash/isNumber'
import isString from 'lodash/isString'
import * as yup from 'yup'

import { hideModal } from 'state/actions'
import { removeCommas } from 'utils/helpers'
import Input from 'components/form/input'

const CREMATORIUM_PRICE_SCHEMA = yup.object().shape({
  earlyMorningPrice: yup.string().nullable(),
  regularPrice: yup.string().required(`is required`),
  earlyMorningServiceLength: yup
    .number()
    .typeError('must be a number')
    .nullable(),
  regularServiceLength: yup
    .number()
    .typeError('must be a number')
    .required(`is required`),
})

const EditModal = ({ config: { crematorium, updateCrematorium } }) => {
  const [isLoading, setIsLoading] = useState(false)
  const dispatch = useDispatch()

  const handleSubmit = async (values) => {
    setIsLoading(true)
    try {
      await updateCrematorium({
        earlyMorningPrice: formToApiValue(values.earlyMorningPrice),
        regularPrice: formToApiValue(values.regularPrice),
        earlyMorningServiceLength: values.earlyMorningServiceLength
          ? Number(values.earlyMorningServiceLength)
          : null,
        regularServiceLength: Number(values.regularServiceLength),
      })
      toast(`${values.name} updated.`, {
        toastId: `${values.name}-updated`,
      })
      dispatch(hideModal())
    } catch (error) {
      setIsLoading(false)
      throw error
    }
  }

  const initialValues = {
    ...crematorium.attributes,
    earlyMorningPrice: apiToFormValue(crematorium.attributes.earlyMorningPrice),
    regularPrice: apiToFormValue(crematorium.attributes.regularPrice),
    earlyMorningServiceLength: crematorium.attributes.earlyMorningServiceLength,
    regularServiceLength: crematorium.attributes.regularServiceLength,
  }

  return (
    <Formik
      validationSchema={CREMATORIUM_PRICE_SCHEMA}
      validateOnMount
      initialValues={initialValues}
      onSubmit={handleSubmit}
    >
      {({ isValid }) => (
        <Form>
          <Grid>
            <Grid.Item>
              <Input label="Name of crematorium" name="name" disabled />
            </Grid.Item>

            <Grid.Item span={6}>
              <Input
                isCurrency
                label="Morning price (£)"
                name="earlyMorningPrice"
              />
            </Grid.Item>

            <Grid.Item span={6}>
              <Input isCurrency label="Regular price (£)" name="regularPrice" />
            </Grid.Item>

            <Grid.Item span={6}>
              <Input
                label="Morning service length (minutes)"
                name="earlyMorningServiceLength"
              />
            </Grid.Item>

            <Grid.Item span={6}>
              <Input
                label="Regular service length (minutes)"
                name="regularServiceLength"
              />
            </Grid.Item>

            <Grid.Item>
              <ButtonGroup gap="S">
                <Button.Primary
                  type="submit"
                  stretch
                  loading={isLoading}
                  disabled={!isValid}
                >
                  Save crematorium
                </Button.Primary>
                <Button.Underline
                  type="button"
                  onClick={() => dispatch(hideModal())}
                >
                  Cancel
                </Button.Underline>
              </ButtonGroup>
            </Grid.Item>
          </Grid>
        </Form>
      )}
    </Formik>
  )
}

export default EditModal

const apiToFormValue = (value) => {
  return isNumber(value) ? value / 100 : value
}

const formToApiValue = (value) => {
  if (value === '' || value == null) {
    return null
  }

  const number = isString(value) ? removeCommas(value) : value

  return Math.round(number * 100)
}
