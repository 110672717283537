import { Contact } from 'lib/models/contact'
import { WillAttributes } from 'lib/models/will'
import { WillCaseAttributes } from 'lib/models/will-case'
import { PHONE_WILL_COMPLEXITY } from 'routes/lead/constants'
import { PhoneWillComplexity } from 'routes/lead/types'

import { WILL_CASE_QUOTE_COMPLEXITIES } from '../constants'
import { WillCaseQuoteAttributes, WillCaseQuoteFormValues } from './types'

/** Returns the initial values that should populate the EditableQuote form */
export const getInitialWillCaseQuoteFormValues = ({
  willCase,
  wills,
}: {
  willCase: WillCaseAttributes
  wills: WillAttributes[]
}): WillCaseQuoteFormValues => {
  const willCaseQuoteComplexities = getWillCaseQuoteComplexities({
    willCase,
  })

  return {
    complexities: willCaseQuoteComplexities || '',
    willType: willCase.willType || '',
  }
}

/** Returns one of 'both', 'lead', 'partner' or null, which are valid values to send
 * to the API as 'complexities' to get a new will case quote
 */
export const getWillCaseQuoteComplexities = ({
  willCase,
}: {
  willCase: WillCaseAttributes
}): WillCaseQuoteAttributes['complexities'] => {
  const { willComplexity: willCaseWillComplexity, wills } = willCase

  if (!wills || wills.length === 0) {
    return null
  }

  /** Legacy cases will not have willComplexity populated on the wills, only on the willCase */
  const isLegacyCase = !wills[0].willComplexity

  const { leadWill, partnerWill } = sortLeadAndPartnerWills(willCase)

  /** For legacy cases, willComplexity on the willCase strictly applies to the lead will only */
  const isLeadWillComplex: boolean = isLegacyCase
    ? willCaseWillComplexity === PHONE_WILL_COMPLEXITY.COMPLEX
    : leadWill?.willComplexity === PHONE_WILL_COMPLEXITY.COMPLEX
  const isPartnerWillComplex: boolean = partnerWill
    ? partnerWill.willComplexity === PHONE_WILL_COMPLEXITY.COMPLEX
    : false

  if (isLeadWillComplex && isPartnerWillComplex) {
    return WILL_CASE_QUOTE_COMPLEXITIES.BOTH
  }

  if (isLeadWillComplex) {
    return WILL_CASE_QUOTE_COMPLEXITIES.LEAD
  }

  if (isPartnerWillComplex) {
    return WILL_CASE_QUOTE_COMPLEXITIES.PARTNER
  }

  return null
}

/** Based on the WillCaseQuoteComplexities (one of 'both', 'lead', 'partner' or null), we can
 * determine the individual willComplexity of the lead and partner wills.
 */
export const getWillsComplexityFromWillCaseQuoteComplexities = (
  complexities: WillCaseQuoteAttributes['complexities']
): {
  leadWillComplexity: PhoneWillComplexity
  partnerWillComplexity: PhoneWillComplexity
} => {
  let leadWillComplexity: PhoneWillComplexity = PHONE_WILL_COMPLEXITY.SIMPLE
  let partnerWillComplexity: PhoneWillComplexity = PHONE_WILL_COMPLEXITY.SIMPLE

  if (complexities === WILL_CASE_QUOTE_COMPLEXITIES.BOTH) {
    leadWillComplexity = PHONE_WILL_COMPLEXITY.COMPLEX
    partnerWillComplexity = PHONE_WILL_COMPLEXITY.COMPLEX
  } else if (complexities === WILL_CASE_QUOTE_COMPLEXITIES.LEAD) {
    leadWillComplexity = PHONE_WILL_COMPLEXITY.COMPLEX
  } else if (complexities === WILL_CASE_QUOTE_COMPLEXITIES.PARTNER) {
    partnerWillComplexity = PHONE_WILL_COMPLEXITY.COMPLEX
  }

  return { leadWillComplexity, partnerWillComplexity }
}

/** Given the willCase, we can compare the linked wills with the contactId on the willCase (which is always shared with the lead will),
 * and determine which will in the array is the lead and which the partner, without relying on position in the array. We can also
 * take the contacts linked to the willCase and assign them to the wills.
 */
export const sortLeadAndPartnerWills = (
  willCase: WillCaseAttributes
): {
  leadWill: WillAttributes | undefined
  partnerWill: WillAttributes | undefined
} => {
  const { contactId: leadContactId, contacts, wills } = willCase

  if (!contacts || !wills) {
    return { leadWill: undefined, partnerWill: undefined }
  }

  const leadWill = wills.find((will) => will.contactId === leadContactId)

  if (leadWill) {
    leadWill.contact = contacts.find(
      (contact) => contact.id === leadWill.contactId
    ) as Contact
  }

  const partnerWill =
    wills.length > 1
      ? wills.find((will) => will.id !== leadWill?.id)
      : undefined

  if (partnerWill) {
    partnerWill.contact = contacts.find(
      (contact) => contact.id === partnerWill.contactId
    ) as Contact
  }

  return { leadWill, partnerWill }
}
