import { Grid, P } from '@farewill/ui'
import { formatAnswer } from 'components/product-summary/helpers'
import { FuneralAttributes } from 'lib/models/funeral'

type Props = {
  funeral: FuneralAttributes
}

const CaseSummary = ({ funeral }: Props): React.ReactElement => {
  const { firstNames, lastName, knownAs, relationshipToCustomer } = funeral

  return (
    <Grid gap="XS">
      <Grid.Item>
        <P>
          The deceased is{' '}
          <b>
            {firstNames} {lastName}
          </b>
          , the customer’s <b>{formatAnswer(relationshipToCustomer)}</b>
          {knownAs !== null ? ` (${knownAs})` : null}
        </P>
      </Grid.Item>
    </Grid>
  )
}

export default CaseSummary
