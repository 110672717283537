import styled from 'styled-components'
import { useField } from 'formik'

import { Wrapper, Radio } from '@farewill/ui'
import { GTR, COLOR } from '@farewill/ui/tokens'

const StyledRadio = styled(Radio)`
  margin: 0;
`

const StyledIndentedContent = styled(Wrapper)`
  margin-left: calc(${GTR.M} / 2);
  border-left: ${GTR.XXS} solid ${COLOR.GREY.LIGHT};
`

const IndentedRadio = ({ value, radioValue, name, label, indentedContent }) => {
  const [field] = useField({ name, type: 'radio', value: radioValue })

  return (
    <Wrapper key={radioValue} margin={['S', 0]}>
      <StyledRadio
        checked={value === radioValue}
        label={label}
        size="M"
        {...field}
      />

      {value === radioValue && indentedContent && (
        <StyledIndentedContent
          margin={['XS', 0, 0]}
          padding={['XXS', 0, 'XXS', 'XS']}
        >
          {indentedContent}
        </StyledIndentedContent>
      )}
    </Wrapper>
  )
}

const IndentedRadioGroup = ({ name, options }) => {
  const [field] = useField(name)
  return (
    <Wrapper>
      {options.map(({ value: radioValue, label, indentedContent }) => {
        return (
          <IndentedRadio
            value={field.value}
            name={name}
            label={label}
            indentedContent={indentedContent}
            radioValue={radioValue}
            key={radioValue}
          />
        )
      })}
    </Wrapper>
  )
}

export default IndentedRadioGroup
