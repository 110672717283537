import styled from 'styled-components'
import { COLOR, GTR } from '@farewill/ui/tokens'

type TableProps = {
  children: React.ReactNode
  striped?: boolean
  withHeaders?: boolean
}

const StyledTable = styled.div<{
  $striped?: boolean
  $withHeaders?: boolean
}>`
  border-width: 1px 0;
  border-style: solid;
  border-color: ${COLOR.GREY.LIGHT};

  ${({ $withHeaders }) =>
    $withHeaders &&
    `
    border-top: none;
  `}

  ${({ $striped, $withHeaders }) =>
    $striped &&
    `
      > div:nth-child(${$withHeaders ? '2n' : '2n - 1'}) {
        background-color: ${COLOR.BACKGROUND.SMOKE};
      }
    `}
`

const StyledRow = styled.div<{ $gridTemplateCols: string }>`
  display: grid;
  grid-template-columns: ${({ $gridTemplateCols }) => $gridTemplateCols};
  grid-column-gap: ${GTR.L};
  padding: ${GTR.S} 0;

  &:not(:last-of-type) {
    border-bottom: 1px solid ${COLOR.GREY.LIGHT};
  }
`

const StyledCol = styled.div<{ $strong?: boolean }>`
  white-space: pre-wrap;
  ${({ $strong }) => $strong && `font-weight: bold;`}
`

const Table = ({
  children,
  striped,
  withHeaders,
}: TableProps): React.ReactElement => {
  return (
    <StyledTable $striped={striped} $withHeaders={withHeaders}>
      {children}
    </StyledTable>
  )
}

type RowProps = {
  children: React.ReactNode
  gridTemplateCols: string
}

const Row = ({ children, gridTemplateCols }: RowProps): React.ReactElement => {
  return <StyledRow $gridTemplateCols={gridTemplateCols}>{children}</StyledRow>
}

type ColProps = {
  children: React.ReactNode
  strong?: boolean
}

const Col = ({ children, strong }: ColProps): React.ReactElement => {
  return <StyledCol $strong={strong}>{children}</StyledCol>
}

Table.Row = Row
Table.Col = Col

export default Table
