import styled from 'styled-components'
import { Wrapper, P } from '@farewill/ui'
import { COLOR, GTR, FONT } from '@farewill/ui/tokens'
import partition from 'lodash/partition'

import { formatCurrency, hasDecimals } from 'lib/formatting/currency'
import { EstateAdminBreakdownItem, QuoteResponseDataProbate } from 'lib/types'

import Dinero from 'dinero.js'
Dinero.globalLocale = 'en-GB'
Dinero.defaultCurrency = 'GBP'

const StyledBreakdownRow = styled(Wrapper)`
  display: grid;
  grid-template-columns: 7fr 3fr;

  &:not(:last-of-type) {
    border-bottom: 1px solid ${COLOR.GREY.LIGHT};
  }
`

const StyledBreakdownCol = styled(Wrapper)`
  font-size: ${FONT.SIZE.XS};
  padding: ${GTR.XXS} 0;

  &:nth-child(odd) {
    border-right: 1px solid ${COLOR.GREY.LIGHT};
    padding-right: ${GTR.XS};
  }

  &:nth-child(even) {
    padding-left: ${GTR.XS};
  }

  ${({ $strong }) => $strong && `font-weight: bold;`}
`

const BreakdownRows = ({ rows }: { rows: EstateAdminBreakdownItem[] }) => {
  return (
    <>
      {rows.map(({ amount, reason }, index) => (
        <StyledBreakdownRow key={index}>
          <StyledBreakdownCol>{reason}</StyledBreakdownCol>
          <StyledBreakdownCol>
            {formatCurrency({
              value: amount,
              valueInPence: true,
              showPence: hasDecimals(amount / 100),
            })}
          </StyledBreakdownCol>
        </StyledBreakdownRow>
      ))}
    </>
  )
}

const EstateAdminQuoteBreakdown = ({
  quote,
}: {
  quote: QuoteResponseDataProbate
}) => {
  const { estateAdmin: estateAdminQuote } = quote.attributes
  const { amountInPence, amountBeforeVatInPence, breakdown } = estateAdminQuote
  const [yesVat, noVat] = partition(breakdown, ({ vat = true }) => vat)

  return (
    <>
      <P size="S" margin={[0, 0, 'XS']}>
        Breakdown (internal use only)
      </P>
      <BreakdownRows rows={yesVat} />
      <StyledBreakdownRow>
        <StyledBreakdownCol $strong>Fee exc. VAT</StyledBreakdownCol>
        <StyledBreakdownCol $strong>
          {formatCurrency({
            value: amountBeforeVatInPence,
            valueInPence: true,
            showPence: hasDecimals(amountBeforeVatInPence / 100),
          })}
        </StyledBreakdownCol>
      </StyledBreakdownRow>
      <StyledBreakdownRow>
        <StyledBreakdownCol>20% VAT</StyledBreakdownCol>
        <StyledBreakdownCol>
          {formatCurrency({
            value: estateAdminQuote.vatInPence,
            valueInPence: true,
            showPence: hasDecimals(estateAdminQuote.vatInPence / 100),
          })}
        </StyledBreakdownCol>
      </StyledBreakdownRow>
      <BreakdownRows rows={noVat} />
      <StyledBreakdownRow>
        <StyledBreakdownCol $strong>Total</StyledBreakdownCol>
        <StyledBreakdownCol $strong>
          {formatCurrency({
            value: amountInPence,
            valueInPence: true,
            showPence: hasDecimals(amountInPence / 100),
          })}
        </StyledBreakdownCol>
      </StyledBreakdownRow>
    </>
  )
}

export default EstateAdminQuoteBreakdown
