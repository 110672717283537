import styled from 'styled-components'
import { Wrapper } from '@farewill/ui'
import { GTR } from '@farewill/ui/tokens'

const StyledFilterWrapper = styled(Wrapper)`
  margin-bottom: ${GTR.L};
`

type Props = {
  children: React.ReactElement | React.ReactElement[]
}

const FilterWrapper = ({ children }: Props): React.ReactElement => (
  <StyledFilterWrapper>{children}</StyledFilterWrapper>
)

export default FilterWrapper
