import MessageBox from 'components/message-box'

const LegacyLpaCaseMessageBox = () => {
  return (
    <MessageBox margin={['L', 0, 0]}>
      This is a Legacy LPA case. If you require further information on this
      case, please contact the Engineering team.
    </MessageBox>
  )
}

export default LegacyLpaCaseMessageBox
