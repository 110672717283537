import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Button, Divider, P, LockIcon, Wrapper } from '@farewill/ui'
import { COLOR, FONT, GTR } from '@farewill/ui/tokens'
import { useDispatch } from 'react-redux'

import { showModal } from 'state/actions'
import { flattenAttributes } from 'utils/helpers'
import { sortByDate } from 'lib/formatting/dates'

import {
  useActivityContext,
  ActivityProvider,
} from 'components/activity/context'
import CommentForm from 'components/activity/comment-form'
import CommentThread from 'components/activity/comment-thread'

import { useTaskEventsContext, useTasksContext } from './context'
import TaskEvent from './task-event'
import DeleteTaskModal from './delete-task-modal'

const StyledWrapper = styled(Wrapper)`
  display: none;
  background-color: ${COLOR.WHITE};
  padding: ${GTR.S};

  ${({ theme }) => theme.isExpanded && `display: block;`}
`

const StyledCoreTaskP = styled(P)`
  color: ${COLOR.GREY.MEDIUM};
  font-size: ${FONT.SIZE.S};
`

const StyledPadlockIcon = styled(LockIcon)`
  margin-right: ${GTR.XS};
`

const StyledActionsWrapper = styled(Wrapper)`
  display: flex;
  justify-content: flex-end;
`

const StyledDeleteButton = styled(Button.Underline)`
  font-size: ${FONT.SIZE.S};
`

const getThread = (comments) => {
  if (comments.length === 0) return null

  const parent = comments[0]
  const replies = comments.slice(1).map(flattenAttributes)

  return {
    ...parent,
    attributes: { ...parent?.attributes, replies },
  }
}

const CoreTaskMessage = ({ task }) => {
  if (!task.attributes.coreTask) return null

  return (
    <StyledCoreTaskP>
      <StyledPadlockIcon size="S" inline />
      This is a core task - it can’t be edited or deleted
    </StyledCoreTaskP>
  )
}

const OrdinaryTaskActions = ({ task }) => {
  const dispatch = useDispatch()
  const { deleteTask } = useTasksContext()

  if (task.attributes.coreTask) return null

  return (
    <StyledActionsWrapper>
      <StyledDeleteButton
        type="button"
        onClick={() =>
          dispatch(
            showModal({
              component: DeleteTaskModal,
              headingText: 'Delete task',
              config: { task, deleteTask },
            })
          )
        }
      >
        Delete task
      </StyledDeleteButton>
    </StyledActionsWrapper>
  )
}

const TaskActivity = ({ task, productId, enableCommenting }) => {
  const taskId = task.id
  const [isInitialRender, setIsInitialRender] = useState(true)
  const { taskEvents, fetchTaskEvents } = useTaskEventsContext()
  const { comments, fetchComments, createComment, isFetchingComments } =
    useActivityContext()
  const thread = getThread(comments)

  useEffect(() => {
    fetchTaskEvents({ queryParams: { 'filter[taskId]': task.id } })
  }, [fetchTaskEvents, task.id])

  useEffect(() => {
    fetchComments({ queryParams: { taskId } })
  }, [fetchComments, taskId])

  useEffect(() => {
    setIsInitialRender(false)
  }, [setIsInitialRender])

  const onSubmit = async (values) => {
    const parentAttributes = { taskId, ...productId }
    const attributes = { ...parentAttributes, ...values }
    await createComment(attributes)
  }

  const sortedTaskEvents = sortByDate(
    taskEvents,
    'attributes.createdAt',
    'desc'
  )

  if (isInitialRender || isFetchingComments)
    return (
      <StyledWrapper>
        <P size="S">Loading...</P>
      </StyledWrapper>
    )

  return (
    <StyledWrapper>
      {!thread && enableCommenting && (
        <CommentForm handleSubmit={onSubmit} placeholder="Write a comment..." />
      )}
      {thread && (
        <CommentThread
          enableCommenting={enableCommenting}
          comment={thread}
          queryParams={{ taskId }}
        />
      )}
      {sortedTaskEvents.map((taskEvent) => (
        <TaskEvent key={taskEvent.id} taskEvent={taskEvent} />
      ))}
      <Divider margin={['M', 0, 'S']} />
      <CoreTaskMessage task={task} />
      <OrdinaryTaskActions task={task} />
    </StyledWrapper>
  )
}

TaskActivity.propTypes = {
  task: PropTypes.object.isRequired,
  productId: PropTypes.object.isRequired,
  enableCommenting: PropTypes.bool.isRequired,
}

const TasksActivityWithProvider = (props) => (
  <ActivityProvider>
    <TaskActivity {...props} />
  </ActivityProvider>
)

export default TasksActivityWithProvider
