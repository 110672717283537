import styled from 'styled-components'
import { P } from '@farewill/ui'
import { COLOR, FONT, GTR } from '@farewill/ui/tokens'

const InputHint = styled(P)`
  margin-top: ${GTR.XXS};
  margin-bottom: 0;
  color: ${COLOR.GREY.MEDIUM};
  font-size: ${FONT.SIZE.S};
`

export default InputHint
