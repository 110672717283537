import { Formik, Form } from 'formik'
import { useQueryClient } from 'react-query'
import { toast } from 'react-toastify'
import styled from 'styled-components'
import { Button, Wrapper } from '@farewill/ui'
import { FONT, GTR } from '@farewill/ui/tokens'

import Dropdown from 'components/dropdown'
import SelectInput from 'components/form/select-input'
import Label from 'components/styled/label'
import useApiHelpers from 'lib/effects/api-helpers'
import { AdminUser, AdminUserAttributes } from 'lib/models/admin-user'
import { Lead } from 'lib/models/lead'
import { LEAD_EVENT_TYPES } from 'utils/enums'
import { formatAdminUserOptions } from 'utils/helpers'

const StyledButton = styled.button`
  padding: 0;
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
`

const StyledAssignLeadButton = styled(Button.Secondary)`
  margin-bottom: ${GTR.XS};

  && {
    padding: ${GTR.XS} ${GTR.L};
  }
`

const StyledLabel = styled(Label)`
  display: flex;
  justify-content: space-between;
`

const StyledTakeLeadButton = styled(Button.Underline)`
  font-size: ${FONT.SIZE.S};
  padding: 0;
`

const showAssignButton = ({
  owner,
  values,
}: {
  owner: AdminUserAttributes | undefined
  values: { ownedByAdminUserId: number | null }
}) => owner?.id !== values.ownedByAdminUserId

const OwnerAssignment = ({
  lead,
  loggedInAdminUserId,
  setEnabled,
}: {
  lead: Lead
  loggedInAdminUserId: number
  setEnabled: React.Dispatch<React.SetStateAction<boolean>>
}) => {
  const {
    owner,
    conversionScore,
    status,
    scheduledNextCallAtTimeSet,
    scheduledNextCallAt,
    unsuitableReason,
    blockedReason,
    updatedAt,
  } = lead.attributes
  const initialValues = { ownedByAdminUserId: owner?.id || null }
  const showTakeLeadButton = owner?.id !== loggedInAdminUserId
  const cache = useQueryClient()
  const adminUsers = cache.getQueryData('adminUsers')

  const adminUserOptions = formatAdminUserOptions(
    adminUsers as AdminUser[],
    loggedInAdminUserId
  )

  const { createItem: createLeadEvent, isCreating: isCreatingLeadEvent } =
    useApiHelpers({
      baseUrl: `/api/lead-events`,
      type: 'lead_events',
    })

  const handleCreateClick = async ({
    loggedInAdminUserId,
    values,
  }: {
    loggedInAdminUserId?: number
    values: { ownedByAdminUserId: number | null }
  }) => {
    await createLeadEvent({
      leadId: lead.id,
      type: LEAD_EVENT_TYPES.OWNER_CHANGED,
      metadata: {},
      leadChanges: {
        status,
        scheduledNextCallAtTimeSet,
        scheduledNextCallAt,
        unsuitableReason,
        conversionScore,
        blockedReason,
        ownedByAdminUserId: loggedInAdminUserId || values.ownedByAdminUserId,
      },
    })
    cache.invalidateQueries('leads')
    setEnabled(true)
    toast('Lead assigned.', {
      toastId: 'lead-assigned',
    })
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={() => {
        //
      }}
    >
      {({ values }) => (
        <Form
          style={{ cursor: 'default' }}
          onClick={(e) => {
            e.preventDefault()
          }}
        >
          <Dropdown
            updatedAt={updatedAt}
            trigger={
              <StyledButton>{owner ? owner.name : 'Unassigned'}</StyledButton>
            }
            toggleCallback={() =>
              setEnabled((prevEnabled: boolean) => !prevEnabled)
            }
            content={
              <Wrapper padding="S">
                <StyledLabel $error={false}>
                  <span>Select owner</span>
                  {showTakeLeadButton && (
                    <StyledTakeLeadButton
                      type="button"
                      onClick={() => {
                        handleCreateClick({ loggedInAdminUserId, values })
                      }}
                    >
                      Assign yourself
                    </StyledTakeLeadButton>
                  )}
                </StyledLabel>
                <SelectInput
                  name="ownedByAdminUserId"
                  options={adminUserOptions}
                  small
                />
                <Wrapper centered margin={['S', 0, 0]}>
                  {showAssignButton({ owner, values }) && (
                    <StyledAssignLeadButton
                      type="button"
                      wide
                      onClick={() => {
                        handleCreateClick({
                          loggedInAdminUserId: undefined,
                          values,
                        })
                      }}
                      loading={isCreatingLeadEvent}
                    >
                      Assign
                    </StyledAssignLeadButton>
                  )}
                </Wrapper>
              </Wrapper>
            }
          />
        </Form>
      )}
    </Formik>
  )
}

export default OwnerAssignment
