import PropTypes from 'prop-types'
import TextareaAutosize from 'react-autosize-textarea'
import styled from 'styled-components'
import { useField } from 'formik'

const StyledTextareaAutosize = styled(TextareaAutosize)`
  resize: none;
`

const AutosizeInput = ({ className, name, ...rest }) => {
  const [field] = useField(name)
  return <StyledTextareaAutosize className={className} {...field} {...rest} />
}

AutosizeInput.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
}

AutosizeInput.defaultProps = {
  className: '',
}

export default AutosizeInput
