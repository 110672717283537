import { ReactElement } from 'react'
import { useFormikContext } from 'formik'
import { Grid, H } from '@farewill/ui'

import { FuneralPlanLeadAttributes } from 'lib/models/lead'
import { CircularRadioGroup, Input } from 'components/form'
import { getYearsElapsed } from 'utils/helpers'

import { useLeadContext } from '../context'

const CoveredIndividual = ({
  dataTestid,
}: {
  dataTestid: string
}): ReactElement => {
  const { values, handleSubmit } = useFormikContext<FuneralPlanLeadAttributes>()
  const { updateContact } = useLeadContext()

  const purchaserIsCoveredIndividual =
    values.quoteAttributes.purchaserIsCoveredIndividual === 'true'

  /** if purchaser is covered individual, the date of birth is stored on the purchaser
   * contact rather than in the covered individual object on quoteAttributes. */
  const coveredIndividualDateOfBirth = purchaserIsCoveredIndividual
    ? values.contact?.dateOfBirth
    : values.quoteAttributes['coveredIndividual']?.dateOfBirth

  return (
    <Grid.Item data-testId={dataTestid}>
      <>
        <H size="S">Covered individual’s details</H>
        <Grid>
          {!purchaserIsCoveredIndividual && (
            <>
              <Grid.Item spanFromL={6} startColumn={1}>
                <Input
                  label="First name"
                  name="quoteAttributes.coveredIndividual.firstName"
                  handleSave={() => handleSubmit()}
                  allowCopyToClipboard
                />
              </Grid.Item>
              <Grid.Item spanFromL={6}>
                <Input
                  label="Last name"
                  name="quoteAttributes.coveredIndividual.lastName"
                  handleSave={() => handleSubmit()}
                  allowCopyToClipboard
                />
              </Grid.Item>
            </>
          )}
          <Grid.Item spanFromL={6}>
            <Input
              type="date"
              label="Date of birth"
              name={
                purchaserIsCoveredIndividual
                  ? 'contact.dateOfBirth'
                  : `quoteAttributes.coveredIndividual.dateOfBirth`
              }
              handleSave={
                purchaserIsCoveredIndividual
                  ? updateContact
                  : () => handleSubmit()
              }
              hint={
                coveredIndividualDateOfBirth &&
                `Age: ${getYearsElapsed({
                  dateOfBirth: coveredIndividualDateOfBirth,
                })}`
              }
            />
          </Grid.Item>
          <Grid.Item>
            <CircularRadioGroup
              label="Sex"
              name="quoteAttributes.coveredIndividual.sex"
              inline
              options={[
                { label: 'Male', value: 'male' },
                { label: 'Female', value: 'female' },
              ]}
              handleSave={() => handleSubmit()}
            />
          </Grid.Item>
        </Grid>
      </>
    </Grid.Item>
  )
}

export default CoveredIndividual
