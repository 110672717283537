import PropTypes from 'prop-types'
import { H, Grid, P } from '@farewill/ui'
import { useFormikContext } from 'formik'

import { strToBool } from 'utils/helpers'
import { TITLE_OPTIONS } from 'utils/enums'

import InputFloating from 'components/form/input-floating'
import CircularRadioGroup from 'components/form/circular-radio-group'
import NotesInput from 'components/notes-input'
import Tooltip from 'components/tooltip'

import FirstAndLastName from './first-and-last-name'
import Aliases from './aliases'

import {
  TITLE,
  NAME_MATCHES_DEATH_CERTIFICATE,
  NAME_MATCHES_WILL,
  KNOWN_AS,
  NAME_NOTES,
} from '../field-names'

import { HAS_WILL } from '../../will-or-intestacy/field-names'

const NameOfPersonWhosDied = ({
  probateCase,
  probateEstateId,
  saveProbateCaseField,
  saveProbateEstateField,
}) => {
  const { values } = useFormikContext()
  const hasWill = strToBool(values[HAS_WILL])

  return (
    <Grid data-scroll-id="1.2">
      <Grid.Item>
        <H size="S">1.2 Name of the person who’s died</H>
      </Grid.Item>
      <Grid.Item>
        <P>
          What is the{' '}
          <Tooltip content="Legal name is the name on passports, driving licenses, etc.">
            legal name
          </Tooltip>{' '}
          of the person who died?
        </P>
        <Grid>
          <Grid.Item span={2}>
            <InputFloating
              name={TITLE}
              label="Title"
              handleSave={saveProbateEstateField}
              component="select"
              options={TITLE_OPTIONS}
            />
          </Grid.Item>
          <Grid.Item span={10}>
            <FirstAndLastName
              initialValues={probateCase.attributes}
              saveProbateCaseField={saveProbateCaseField}
            />
          </Grid.Item>
        </Grid>
      </Grid.Item>
      <Grid.Item span={5}>
        <InputFloating
          name={KNOWN_AS}
          label="They’re known informally as"
          handleSave={saveProbateEstateField}
          hint="E.g. ‘John’ instead of Jonathan"
        />
      </Grid.Item>
      <Grid.Item>
        <CircularRadioGroup
          name={NAME_MATCHES_DEATH_CERTIFICATE}
          label="Is the full name above the same as the name on the death certificate?"
          options={[
            { label: 'Not asked', value: '' },
            { label: 'Yes', value: 'true' },
            { label: 'No', value: 'false' },
          ]}
          handleSave={saveProbateEstateField}
          inline
        />
      </Grid.Item>
      {hasWill && (
        <Grid.Item>
          <CircularRadioGroup
            name={NAME_MATCHES_WILL}
            label="Is the full name above the same as the name on the will?"
            options={[
              { label: 'Not asked', value: '' },
              { label: 'Yes', value: 'true' },
              {
                label: 'No (add as alias and explain in a note)',
                value: 'false',
              },
            ]}
            handleSave={saveProbateEstateField}
            inline
          />
        </Grid.Item>
      )}
      <Grid.Item>
        <Aliases probateEstateId={probateEstateId} />
      </Grid.Item>
      <Grid.Item>
        <NotesInput name={NAME_NOTES} handleSave={saveProbateEstateField} />
      </Grid.Item>
    </Grid>
  )
}

NameOfPersonWhosDied.propTypes = {
  probateCase: PropTypes.object.isRequired,
  probateEstateId: PropTypes.string.isRequired,
  saveProbateCaseField: PropTypes.func.isRequired,
  saveProbateEstateField: PropTypes.func.isRequired,
}

export default NameOfPersonWhosDied
