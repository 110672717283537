import { P } from '@farewill/ui'

import { RESOURCES, useFetchArrayResource } from 'api'
import SelectInput from 'components/form/select-input'
import { Partner } from 'lib/models/partner'

import FilterWrapper from '../shared/filter-wrapper'

const CharityNameFilter = (): React.ReactElement => {
  const partnersQuery = useFetchArrayResource<Partner>({
    resource: RESOURCES.PARTNERS,
  })

  if (!partnersQuery.data) return <P size="S">Loading charity partners</P>

  const options = partnersQuery.data.data
    .filter(({ attributes }) => attributes.type === 'charity')
    .map(({ attributes }) => ({
      value: attributes.id,
      label: attributes.name,
    }))

  return (
    <FilterWrapper>
      <P size="S" strong>
        Charity
      </P>
      <SelectInput options={options} name="partnerId" isMulti small />
    </FilterWrapper>
  )
}

export default CharityNameFilter
