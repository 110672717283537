import { Button, ButtonGroup, Wrapper, P } from '@farewill/ui'
import { useDispatch } from 'react-redux'
import { Formik, Form } from 'formik'
import { toast } from 'react-toastify'

import { useFetchResource, RESOURCES } from 'api'
import { WillCase } from 'lib/models/will-case'

import { hideModal } from 'state/actions'
import { WILL_CASE_STATUSES } from 'utils/enums'
import useApi from 'lib/effects/api'
import { CASE_STATUS_TRANSITION_SCHEMA } from 'lib/formik/schemata'

import IndentedRadioGroup from 'components/form/indented-radio-group'
import Input from 'components/form/input'

import { COLD_REASONS, CANCELLED_REASONS } from './constants'
import CancelledOrColdReasonFields from './cancelled-or-cold-reason-fields'
import { NewTransitionFormFields, CaseStatusFields } from './types'
import { getTransitionAttributes } from './helpers'

const INITIAL_VALUES = {
  open: { reason: 'other', reasonDescription: '' },
  cancelled: { reason: '', reasonDescription: '' },
  cold: { reason: '', reasonDescription: '' },
}

const OpenReasonFields = () => (
  <Wrapper margin={['S', 0, 0]}>
    <Input name="open.reasonDescription" label="Reason for case open/active" />
  </Wrapper>
)

const CaseStatusModal = ({
  willCaseId,
}: {
  willCaseId: number
}): React.ReactElement => {
  const [{ isLoading }, makeRequest] = useApi()
  const dispatch = useDispatch()
  const willCaseQuery = useFetchResource<WillCase>({
    id: willCaseId,
    resource: RESOURCES.WILL_CASES,
  })

  if (willCaseQuery.isLoading || willCaseQuery.isIdle)
    return <P size="L">Loading...</P>

  if (!willCaseQuery.data) return <P size="L">There is no data to display.</P>

  const mostRecentTransition =
    willCaseQuery.data.attributes?.statusTransitions[0]
  const initialStatus = (mostRecentTransition?.toStatus ||
    WILL_CASE_STATUSES.OPEN) as keyof CaseStatusFields

  const handleSubmit = async (values: NewTransitionFormFields) => {
    await makeRequest({
      url: '/api/case-status-transitions',
      method: 'POST',
      data: {
        data: {
          type: 'case_status_transitions',
          attributes: getTransitionAttributes(values, willCaseQuery.data.id),
        },
      },
    })
    willCaseQuery.refetch()
    dispatch(hideModal())
    toast('Case status updated.', {
      toastId: 'case-status-updated',
    })
  }

  return (
    <Formik
      initialValues={{ ...INITIAL_VALUES, status: initialStatus }}
      onSubmit={handleSubmit}
      validationSchema={CASE_STATUS_TRANSITION_SCHEMA}
    >
      {({ dirty }) => (
        <Form>
          <IndentedRadioGroup
            name="status"
            options={[
              {
                label: 'Case open/active',
                value: WILL_CASE_STATUSES.OPEN,
                indentedContent: initialStatus !== WILL_CASE_STATUSES.OPEN && (
                  <OpenReasonFields />
                ),
              },
              {
                label: 'Case cancelled',
                value: WILL_CASE_STATUSES.CANCELLED,
                indentedContent: initialStatus !==
                  WILL_CASE_STATUSES.CANCELLED && (
                  <CancelledOrColdReasonFields
                    status={
                      WILL_CASE_STATUSES.CANCELLED as keyof CaseStatusFields
                    }
                    options={CANCELLED_REASONS}
                  />
                ),
              },
              {
                label: 'Case cold',
                value: WILL_CASE_STATUSES.COLD,
                indentedContent: initialStatus !== WILL_CASE_STATUSES.COLD && (
                  <CancelledOrColdReasonFields
                    status={WILL_CASE_STATUSES.COLD as keyof CaseStatusFields}
                    options={COLD_REASONS}
                  />
                ),
              },
            ]}
          />
          <ButtonGroup margin={['M', 0, 0]}>
            <Button.Primary type="submit" disabled={!dirty} loading={isLoading}>
              Save
            </Button.Primary>
            <Button.Underline
              type="button"
              onClick={() => dispatch(hideModal())}
            >
              Cancel
            </Button.Underline>
          </ButtonGroup>
        </Form>
      )}
    </Formik>
  )
}

export default CaseStatusModal
