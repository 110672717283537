import { Grid } from '@farewill/ui'

import useNamespace from 'lib/formik/namespace'
import InputFloating from 'components/form/input-floating'
import SelectInput from 'components/form/select-input'

import { UpdateGiftFn } from './types'
import {
  GIFT_BENEFICIARY_TYPE,
  GIFT_BENEFICIARY_TYPE_OPTIONS,
} from '../constants'

type Props = {
  namespace: string
  updateGift: UpdateGiftFn
  hasGiftBeneficiary: boolean
  isCouplesWillCase: boolean
  disabled?: boolean
}

const MoneyGift = ({
  namespace,
  updateGift,
  hasGiftBeneficiary,
  isCouplesWillCase,
  disabled,
}: Props): React.ReactElement => {
  const { withNamespace } = useNamespace(`${namespace}.`)

  const options = [...GIFT_BENEFICIARY_TYPE_OPTIONS]

  if (isCouplesWillCase) {
    options.push({
      label: 'Second death',
      value: GIFT_BENEFICIARY_TYPE.SECOND_DEATH,
    })
  }

  const handleGiftBeneficiaryTypeChange = ({
    value,
  }: {
    value: string | number | null | (string | number)[]
  }) => {
    updateGift({
      name: withNamespace('money.isConditionalOnPartnerDeath'),
      value: value === GIFT_BENEFICIARY_TYPE.SECOND_DEATH,
    })
  }

  return (
    <Grid>
      <Grid.Item span={7}>
        <InputFloating
          type="number"
          name={withNamespace('money.amount')}
          label="Gift value (£)"
          handleSave={updateGift}
          isCurrency
          disabled={disabled}
        />
      </Grid.Item>
      <Grid.Item span={7}>
        <SelectInput
          name={withNamespace('giftBeneficiaryType')}
          label="Gift level"
          floating
          options={options}
          disabled={disabled || hasGiftBeneficiary}
          handleSave={handleGiftBeneficiaryTypeChange}
        />
      </Grid.Item>
    </Grid>
  )
}

export default MoneyGift
