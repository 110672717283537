import { useState } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { Button, P, Wrapper } from '@farewill/ui'
import { FONT, GTR } from '@farewill/ui/tokens'

import Tooltip from 'components/tooltip'
import { formatCurrency } from 'lib/formatting/currency'
import { hideModal } from 'state/actions'

const StyledTooltip = styled(Tooltip)`
  padding: ${GTR.XXS};
`

const StyledWrapper = styled(Wrapper)`
  display: flex;
  flex-direction: column;
`

const StyledCancelButton = styled(Button)`
  font-weight: ${FONT.WEIGHT.REGULAR};
  text-decoration: underline;
  align-self: center;
  margin-top: ${GTR.XS};

  &:hover {
    text-decoration: underline;
  }
`

type ModalProps = {
  config: {
    reference: string
    amountInPence: number
    handlePaymentClick: () => Promise<void>
  }
}

const PaymentModal = ({ config }: ModalProps) => {
  const dispatch = useDispatch()
  const [referenceCopied, setReferenceCopied] = useState(false)

  const handleCopy = (reference: string) => {
    navigator.clipboard.writeText(reference)
    setReferenceCopied(true)
  }

  const handleContinue = async () => {
    await config.handlePaymentClick()
    dispatch(hideModal())
  }

  return (
    <Wrapper>
      <P>
        Before clicking "Continue to Square", please copy the following
        reference:
      </P>
      <Wrapper centered padding={[0]}>
        <StyledTooltip underlined={false} content="Click to copy">
          <P size="L" strong onClick={() => handleCopy(config.reference)}>
            {config.reference}
          </P>
        </StyledTooltip>
      </Wrapper>
      <P>
        On Square, paste it in the "Invoice ID" field. Not doing so may cause a
        delay in assigning the payment to the correct Funeral Plan.
      </P>
      {config.reference.includes('BALANCE') && (
        <P>
          For instalments customers, remember to cancel the recurring series and
          any unpaid invoices as well.
        </P>
      )}
      <Wrapper centered padding={['0', '0', 'M']}>
        <P strong>
          You should charge the customer{' '}
          {formatCurrency({
            showPence: true,
            value: config.amountInPence,
            valueInPence: true,
          })}
        </P>
      </Wrapper>

      <StyledWrapper>
        <Button.Primary
          type="button"
          onClick={handleContinue}
          disabled={!referenceCopied}
        >
          Continue to Square
        </Button.Primary>
        <StyledCancelButton onClick={() => dispatch(hideModal())}>
          Cancel
        </StyledCancelButton>
      </StyledWrapper>
    </Wrapper>
  )
}

export default PaymentModal
