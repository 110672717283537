const FETCH_FUNERAL = 'FETCH_FUNERAL'
const UPDATE_FUNERAL = 'UPDATE_FUNERAL'

export default function funeral(state = {}, action = {}) {
  switch (action.type) {
    case 'UNSET_PRODUCT':
      return action.payload === 'funeral' || action.payload === 'cremation'
        ? {}
        : state

    case FETCH_FUNERAL + '_FULFILLED':
    case UPDATE_FUNERAL + '_FULFILLED':
      return action.payload.data.data

    case FETCH_FUNERAL + '_PENDING':
      return state
    case FETCH_FUNERAL + '_REJECTED':
      return {}

    default:
      return state
  }
}
