import { Formik, Form } from 'formik'
import { Wrapper, P } from '@farewill/ui'
import { toast } from 'react-toastify'
import { useFetchResource, useUpdateResource, RESOURCES } from 'api'
import { WillCase } from 'lib/models/will-case'

import Input from 'components/form/input'

const FactFindNotes = ({ id }: { id: number }): React.ReactElement => {
  const willCaseQuery = useFetchResource<WillCase>({
    id,
    resource: RESOURCES.WILL_CASES,
  })
  const willCaseMutation = useUpdateResource()

  if (willCaseQuery.isLoading || willCaseQuery.isIdle)
    return <P size="L">Loading...</P>

  if (!willCaseQuery.data) return <P size="L">There is no data to display.</P>

  const handleUpdateWillCase = async ({ notes }: { notes: string }) => {
    const attributes = { notes: notes.trim() || null }
    await willCaseMutation.mutateAsync({
      id: willCaseQuery.data.id,
      attributes,
      resource: RESOURCES.WILL_CASES,
    })
    toast('Success! Notes saved.', {
      toastId: 'notes-saved-for-will-case',
    })
  }

  return (
    <Formik
      onSubmit={handleUpdateWillCase}
      initialValues={{ notes: willCaseQuery.data.attributes?.notes || '' }}
    >
      {({ handleSubmit }) => (
        <Form>
          <Wrapper margin={['M', 0]}>
            <Input
              name="notes"
              component="textarea"
              label="Fact find notes"
              rows={3}
              handleSave={() => handleSubmit()}
            />
          </Wrapper>
        </Form>
      )}
    </Formik>
  )
}

export default FactFindNotes
