import sortBy from 'lodash/sortBy'
import partition from 'lodash/partition'
import { sortByDate } from 'lib/formatting/dates'

const sortById = (tasks) => tasks.sort((a, b) => a.id - b.id)

export const getSortedTasks = (allCoreTasks, tasks, product) => {
  const coreTasksInOrder =
    allCoreTasks[product]?.reduce(
      (acc, stage) => [...acc, ...stage.tasks.map(({ value }) => value)],
      []
    ) ?? []

  const [coreTasks, ordinaryTasks] = partition(
    tasks,
    (task) => !!task.attributes.coreTask
  )

  const sortedCoreTasks = coreTasks.sort(
    (a, b) =>
      coreTasksInOrder.indexOf(a.attributes.coreTask) -
      coreTasksInOrder.indexOf(b.attributes.coreTask)
  )

  const sortedOrdinaryTasksById = sortById(ordinaryTasks)
  const sortedOrdinaryTasksByDate = sortByDate(
    sortedOrdinaryTasksById,
    'attributes.createdAt'
  )

  const sorted = [...sortedCoreTasks, ...sortedOrdinaryTasksByDate]

  return sortBy(sorted, ['attributes.stage', 'attributes.order'])
}
