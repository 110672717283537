import { AdminUser } from 'lib/models/admin-user'
import { useQueryClient } from 'react-query'
import useAdminUserId from './useAdminUserId'

const useAdminUser = (): AdminUser | null => {
  const cache = useQueryClient()
  const adminUsers = cache.getQueryData('adminUsers') as AdminUser[]
  const adminUserId = useAdminUserId()
  const adminUser = adminUsers.find((user) => user.id === adminUserId)

  return adminUser ?? null
}

export default useAdminUser
