import { Grid } from '@farewill/ui'
import { COLOR, GTR } from '@farewill/ui/tokens'
import styled from 'styled-components'

import {
  SOLE_ASSETS,
  SHARE_OF_JOINT_ASSETS,
  GROSS_ESTATE_NOT_REQUIRING_GRANT,
  DEBTS_PAYABLE_OUT_OF_JOINT_ASSETS,
  NET_ESTATE_NOT_REQUIRING_GRANT,
  GROSS_ESTATE_FOR_GRANT,
  SOLE_DEBTS,
  NET_ESTATE_FOR_GRANT,
  GROSS_ESTATE_FOR_INHERITANCE_TAX,
  NET_ESTATE_FOR_INHERITANCE_TAX,
} from '../financial-summary-tooltip-content'

import FinancialRow from './FinancialRow'
import UnspecifiedWarning from './UnspecifiedWarning'

const StyledListItem = styled.li`
  display: flex;
  padding: ${GTR.S} 0;
  border-bottom: 1px solid ${COLOR.GREY.LIGHT};
`

const StyledStrong = styled.strong`
  flex-basis: 50%;
`

const FinancialSummary = ({ data }) => {
  const financials = data.attributes.financials

  return (
    <Grid>
      <Grid.Item span={10}>
        <ul>
          <FinancialRow formBox="IHT205 box" amount="£" tooltip="" title="" />
          <FinancialRow
            title="Sole assets"
            tooltip={SOLE_ASSETS}
            amount={financials.soleAssets.value}
          />
          <FinancialRow
            title="Share of joint assets"
            tooltip={SHARE_OF_JOINT_ASSETS}
            amount={financials.shareOfJointAssets.value}
          />
          <FinancialRow
            title="Gross estate that does not require grant"
            tooltip={GROSS_ESTATE_NOT_REQUIRING_GRANT}
            amount={financials.grossEstateNotRequiringGrant.value}
            formBox="A"
          />
          <FinancialRow
            title="Debts payable out of joint assets"
            tooltip={DEBTS_PAYABLE_OUT_OF_JOINT_ASSETS}
            amount={financials.debtsPayableOutOfJointAssets.value}
            formBox="B"
          />
          <FinancialRow
            title="Net estate that does not require a grant"
            tooltip={NET_ESTATE_NOT_REQUIRING_GRANT}
            amount={financials.netEstateNotRequiringGrant.value}
            formBox="C"
          />
          <FinancialRow
            title="Gross estate for the grant"
            tooltip={GROSS_ESTATE_FOR_GRANT}
            amount={financials.grossEstateForGrant.value}
            formBox="D"
          />
          <FinancialRow
            title="Debts (sole)"
            tooltip={SOLE_DEBTS}
            amount={financials.soleDebts.value}
            formBox="E"
          />
          <FinancialRow
            title="Net estate for the grant"
            tooltip={NET_ESTATE_FOR_GRANT}
            amount={financials.netEstateForGrant.value}
            formBox="F"
          />
          <FinancialRow
            title="Net estate for inheritance tax"
            tooltip={NET_ESTATE_FOR_INHERITANCE_TAX}
            amount={financials.netEstateForInheritanceTax.value}
            formBox="G"
          />
          <FinancialRow
            title="Gross estate for inheritance tax"
            tooltip={GROSS_ESTATE_FOR_INHERITANCE_TAX}
            amount={financials.grossEstateForInheritanceTax.value}
            formBox="H"
          />
        </ul>
      </Grid.Item>
      <UnspecifiedWarning unspecified={financials.unspecified} />
      <Grid.Item span={9}>
        <StyledListItem>
          <StyledStrong>Inheritance Tax</StyledStrong>
          {/* TODO: Inheritance tax answer once available in api */}
        </StyledListItem>
        <StyledListItem>
          <StyledStrong>Transfer of nil-rate band</StyledStrong>
          {/* TODO: Transfer of nil-rate band answer once available in api */}
        </StyledListItem>
      </Grid.Item>
    </Grid>
  )
}

export default FinancialSummary
